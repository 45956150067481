import { Component, Input, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { LocalStorageServiceService } from 'src/app/services/local-storage-service.service';
import { PoSettingsComponent } from '../po-settings/po-settings.component';
import { ToasterserviceService } from 'src/app/services/toasterservice.service';
import { POAddPersonComponent } from '../po-addperson/add-person.component';
import { SearchCriteriaPopupComponent } from '../search-criteria-popup/search-criteria-popup.component';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MdRegionService } from 'src/app/services/md-region.service';
import { MdServiceService } from 'src/app/services/md-service.service';
import { ProcessOwnersService } from '../process-owners.service';
import { EmployeeListService } from 'src/app/services/employee-list.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-admin-processowners',
  templateUrl: './admin-processowners.component.html',
  styleUrls: ['./admin-processowners.component.css']
})
export class AdminProcessownersComponent implements OnInit {
  @Input('flagFromSearch') flagFromSearch:boolean = false;
  poData:any = [];
  poDataOrdered:any = [];
  criteria:any = {};
  criteriaInfo:any = {};
  hasCriteriaSelected:boolean = false;
  canDownload:boolean = false;
  descrHtml:any = '';
  newPersonScope:any = {};
  poPageSize:any = this.localStorageService.getFromLocalStorage("poGroupsPageSize") || 10;
  order_by:any = 'scope.div';
  order_mode:string =  'asc';
  isSearching:boolean = false;
  processOwnerForm!: FormGroup;
  processFormData: any = [];
  recievedEmps:any =[];



  constructor(private localStorageService:LocalStorageServiceService,
    private dialogService: DialogService,
    private ref: DynamicDialogRef,
    private toaster:ToasterserviceService,
    private fb: FormBuilder,
    private mdData: MdServiceService,
    private mdRegionService: MdRegionService,
    private processOwnersService:ProcessOwnersService,
    private employeeService: EmployeeListService,
    private spinner:NgxSpinnerService,
    private breadcrumbService: BreadcrumbService,
    private router:Router) { }

  ngOnInit(): void {

    this.processOwnerForm = this.fb.group({

    });

    const CurrentURL= this.router.url;
    if(CurrentURL != '/posearch'){
      this.breadcrumbService.menuItems= [
        { label: 'ADMIN PANEL', routerLink: 'admin'},
        { label: 'MANAGE PROCESS OWNERS'}
      ]
    }

  }

  setOrderMode(mode) {
    this.order_mode = mode;
    this.refreshView();
  };

  refreshView = function () {
    this.poDataOrdered = [];
    this.poDataOrdered =  _.orderBy(this.poData, this.order_by, (this.order_mode === 'desc') ? 'desc' : 'asc');
  };

  poSettings() {
    this.ref = this.dialogService.open(PoSettingsComponent , {
      header: 'Process Owner search settings',
      width: '30%',
      position: 'top',
      keepInViewport: true,
      autoZIndex: true,
      styleClass: 'poSettings settingsPopUp_ProcessOwner'
    });

    this.ref.onClose.subscribe((receivedValue: any) => {
      if (receivedValue) {
        this.poPageSize = receivedValue;
       this.toaster.showSuccess('Settings saved');
      }
    })

  };

  addNewPersonWindow() {

    this.ref = this.dialogService.open(POAddPersonComponent , {
      header: 'Add person ',
      width: '50%',
      position: 'top',
      keepInViewport: true,
      autoZIndex: true,
      styleClass: 'dialogue-popup addPopUpProcessOwner'
    });
    this.ref.onClose.subscribe((receivedValue: any) => {
      if (receivedValue) {
       //this.toaster.showSuccess('Person saved');
       if(this.criteria){
        this.reloadSearchResults();
      }
      }

    })
  };

 criteriaWindow() {
  this.ref = this.dialogService.open(SearchCriteriaPopupComponent , {
    header: 'Process Owner search',
    width: '50%',
    position: 'top',
    keepInViewport: true,
    autoZIndex: true,
    styleClass: 'dialogue-popup process-owner-popup',
    data:{searchquery: this.criteria, selectedemps: this.recievedEmps}
  });

  this.ref.onClose.subscribe((data: any) => {
    if (data) {
      this.criteria = data.query;
      this.recievedEmps = data.emps;
      if (data.query.pgs.length > 0) {
          this.order_by = 'scope.pg';
      }
      else if (data.query.bus.length > 0) {
          this.order_by = 'scope.bu';
      }
      else if (data.query.divisions.length > 0) {
          this.order_by = 'scope.div';
      }
      else if (data.query.regions.length > 0) {
          this.order_by = 'scope.regionName';
      }
      else if (data.query.countries.length > 0) {
          this.order_by = 'scope.countryName';
      }

      this.search(data.query);
    }
  })
 }

 prepareSearchObject(query) {
  let searchQ = query;
  let criteria = {
      divisions: [],
      bus: [],
      pgs: [],
      countries: [],
      regions: [],
      employees: []
  };

  let promises = [];

  promises.push(this.calculateunits(searchQ, criteria));
  promises.push(this.calculatlocation(searchQ, criteria));
  promises.push(this.copyEmployees(searchQ, criteria));

  return Promise.all(promises).then((results)=> {
      return criteria;
  });

};

calculateunits(searchQuery, searchCriteria) {
  if (searchQuery.pgs && searchQuery.pgs.length > 0) {

      searchQuery.pgs.forEach((pg)=> {
          if (pg.indexOf('ALL') == -1) {
              searchCriteria.pgs.push(pg);
          }
          else if (pg.indexOf('ALL') == 2) {
              var div = pg.substring(0, 2);
              this.mdData.pg().then((pgs) => {
                  pgs.forEach((p)=>{
                      if (p.divCode == div && p.id.indexOf('ALL') == -1) {
                          searchCriteria.pgs.push(p.id);
                      }
                  });

              });
          }
          else {
              var bu = pg.substring(0, 4);
              this.mdData.pg().then((pgs) => {
                  pgs.forEach((p)=>{

                      if (p.buCode == bu && p.id.indexOf('ALL') == -1) {
                          searchCriteria.pgs.push(p.id);
                      }
                  });

              });
          }
      });
  } else if (searchQuery.bus && searchQuery.bus.length > 0) {
      searchQuery.bus.forEach( (bu)=> {
          if (bu.indexOf('ALL') == -1) {
              searchCriteria.bus.push(bu);
          } else {
              var div = bu.substring(0, 2);
              this.mdData.bu().then((bus) => {
                  bus.forEach((b)=> {
                      if (b.divCode == div && b.id.indexOf('ALL') == -1) {
                          searchCriteria.bus.push(b.id);
                      }
                  });

              });
          }
      });
  } else if (searchQuery.divisions && searchQuery.divisions.length > 0) {
      searchQuery.divisions.forEach((div)=> {
          searchCriteria.divisions.push(div);
      });
  }
};

calculatlocation(searchQuery, searchCriteria) {

  if (searchQuery.countries && searchQuery.countries.length > 0) {
      searchQuery.countries.forEach((co)=> {
          if (co.indexOf('ALL') == -1) {
              searchCriteria.countries.push(co);
          } else {

              var reg = co.substring(0, co.indexOf('ALL'));
              this.mdRegionService.countries().then(function (countries) {

                  countries.forEach((c)=> {
                      if (c.rCode == reg && c.id.indexOf('ALL') == -1) {
                          searchCriteria.countries.push(c.id);
                      }
                  });

              });
          }
      });

  }
  else if (searchQuery.regions && searchQuery.regions.length > 0) {
      searchQuery.regions.forEach((r)=> {
          searchCriteria.regions.push(r);
      });
  }
};
copyEmployees(searchQuery, searchCriteria) {
  if(searchQuery.employees && searchQuery.employees.length > 0) {
      searchQuery.employees.forEach( (e)=> {
          searchCriteria.employees.push(e);
      });
  }
};

fillQueryObject(query) {
  let promises = [];
  let names = [];
  let addToPromise = function (mtd, argsArray) {
      var func = function (method, name) {
          if (query[name] !== undefined && query[name].length > 0 && !query[name][0]) {
              promises.push(mtd[method](query[name]));
              names.push(name);
          }
      };
      argsArray.forEach((e)=> { func(e[0], e[1]); });
  };

     addToPromise(this.processOwnersService, [
             ['getCountriesDetails', 'countries']
     ]);
     addToPromise(this.mdRegionService, [
             ['regionsByCode', 'regions']
     ]);


  addToPromise(this.employeeService, [
          ['findByGeids', 'employees']
  ]);


  return Promise.all(promises).then(function (results) {
      var obj = query;
      for (var i = 0; i < results.length; i++) {
          obj[names[i]] = results[i];
      }
      return obj;
  });
}

search(query) {

  this.hasCriteriaSelected = true;
  //usSpinnerService.spin('main-spinner');
  this.fillQueryObject(query).then(
      (info) => {
          this.criteriaInfo = info;
          this.watchCritieria(this.criteriaInfo);
          this.prepareSearchObject(query).then( (queryObject) => {
              if (this.criteriaInfo.divisions || this.criteriaInfo.bus || this.criteriaInfo.pgs
                   || this.criteriaInfo.regions || this.criteriaInfo.countries || this.criteriaInfo.employees) {
                    this.spinner.show();
                  this.isSearching = true;
                  this.processOwnersService.searchForProcessOwners(queryObject).then((data:any) => {
                  this.hasCriteriaSelected = true;
                    this.poData = data;
                      this.poDataOrdered = _.orderBy(this.poData, this.order_by, (this.order_mode == 'desc'));
                      this.isSearching = false;
                      this.canDownload = data.length > 0;
                      this.spinner.hide();
                  });
              }

          });


      },
      function (xhr) {
      });
  }

reloadSearchResults() {
    this.search(this.criteria);
}

downloadProcessOwners(){
   this.canDownload = false;
            var query = this.criteria;
            this.fillQueryObject(query).then(
              (info) => {
               this.criteriaInfo = info;

                this.prepareSearchObject(query).then((queryObject) => {

                  this.processOwnersService.downloadProcessOwners(queryObject).subscribe((response: ArrayBuffer) => {
                    const blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                    const downloadLink = document.createElement('a');
                    downloadLink.href = window.URL.createObjectURL(blob);
                    downloadLink.download = 'Process Owners Report';
                    downloadLink.click();
                  });


                });
              }).then(() =>{
              this.canDownload = true;
            });
}

  watchCritieria(criteriaInfo:any) {
  let criteriaSelected = false;
  if (this.criteriaInfo) {
      this.descrHtml = '';
      if (this.criteriaInfo.divisions && this.criteriaInfo.divisions.length > 0) {
          this.descrHtml += '<strong> Division: </strong>' + this.criteriaInfo.divisions.join(',');
          criteriaSelected = true;
      }
      if (this.criteriaInfo.bus && this.criteriaInfo.bus.length > 0) {
          this.descrHtml += '<strong> BU: </strong>' + this.criteriaInfo.bus.join(',');
          criteriaSelected = true;
      }
      if (this.criteriaInfo.pgs && this.criteriaInfo.pgs.length > 0) {
          this.descrHtml += '<strong> PG: </strong>' + this.criteriaInfo.pgs.join(',');
          criteriaSelected = true;
      }
      if (this.criteriaInfo.regions && this.criteriaInfo.regions.length > 0) {
          this.descrHtml += '<strong> Region: </strong>' + _.map(this.criteriaInfo.regions, (r) => { return r; }).join(',');
          criteriaSelected = true;
      }
      if (this.criteriaInfo.countries && this.criteriaInfo.countries.length > 0) {
          this.descrHtml += '<strong> Country: </strong>' + _.map(this.criteriaInfo.countries,  (c) => { return c; }).join(',');
          criteriaSelected = true;
      }

      if (this.recievedEmps.length > 0) {
          this.descrHtml += '<strong> Employee: </strong>' + _.map(this.recievedEmps,
               (e) => {
                  return e.email;
          }).join(',');
          criteriaSelected = true;
      }
  }
  this.hasCriteriaSelected = criteriaSelected;

}

getPlainText(html:string){
  const divs = document.createElement('div');
  divs.innerHTML = html;
  return divs.textContent || divs.innerText || '';
}

}
