import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ReportsService } from 'src/app/services/reports.service';
import { ExportAsConfig, ExportAsService } from 'ngx-export-as';
import { ExternalpageEmitService } from '../../external/externalpage-emit.service';
import * as xls from 'xlsx';
import { isEmpty } from 'lodash';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-view-report',
  templateUrl: './view-report.component.html',
  styleUrls: ['./view-report.component.css']
})
export class ViewReportComponent implements OnInit {
  viewReportData:any;
  reportGenerating:boolean=false;
  outerUrl:boolean=false;
  hiii:any;
  users:any;
  resp:any;
  constructor( private route: ActivatedRoute,
    private router: Router,
    private reportService: ReportsService,
    private externalPageService:ExternalpageEmitService,
    private exportAsService: ExportAsService,
    private spinnerService: NgxSpinnerService) {
   }

   exportAsPDF: ExportAsConfig = {
    type: 'pdf',
    elementIdOrContent: 'unique',
    options: {
      orientation: 'landscape',
      margins: {
        top: '20'
      },
      footer:{
        text:'Ramprasad',
        alignment:'center',
        fontSize:10,
        pageNumber:true,
        color:'#000000',
        fontStyle:'italic'
      }
    }
  }
  ngOnInit(): void {
    this.externalPageService.setExternalPageFunction(true);
    //console. log(this.router.url);
    // console.log(this.route.snapshot.paramMap.get('id1'));
    this.viewReportData = localStorage.getItem('viewReportData');
    if(this.viewReportData) {
      // console.log(JSON.parse(this.viewReportData));
    }
    this.getApi();
  }


  getApi(){
    this.spinnerService.show();
    this.reportGenerating = true;

    if(!isEmpty(this.route.snapshot.queryParams)){
      this.outerUrl=true;
      this.reportService.getReportbyId(this.router.url,'html').subscribe((response: ArrayBuffer)=>{
        const blob = new Blob([response], { type: 'text/html' });
        let BlobText:any;
        blob.text().then(text =>{
          BlobText= text;
          document.getElementById('unique').innerHTML=BlobText;
          this.spinnerService.hide();
        })
        this.reportGenerating = false;
      });
    }
    else {
      this.reportService.getReportHTMLView(JSON.parse(this.viewReportData)).subscribe((response: ArrayBuffer)=>{
        this.reportGenerating = false;
        this.resp= response;
        const blob = new Blob([response], { type: 'text/html' });
        let BlobText:any;
        blob.text().then(text =>{
          BlobText= text;
          document.getElementById('unique').innerHTML=BlobText;
        })
        this.reportGenerating = false;
        this.spinnerService.hide();
      });
    }
  }


  getExcelDownloaded(){
    if(this.outerUrl ==true){
      this.spinnerService.show();
      this.reportService.getReportbyId(this.router.url,'excel').subscribe((response: ArrayBuffer)=>{
        const blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(blob);
        //downloadLink.download = 'AllInOne.xlsx';
        this.spinnerService.hide();
        downloadLink.click();
      });
    }
    else{
        // const blob = new Blob([this.resp], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        // const downloadLink = document.createElement('a');
        // downloadLink.href = window.URL.createObjectURL(blob);
        // downloadLink.download = 'AllInOne.xlsx';
        // downloadLink.click();
        this.spinnerService.show();
        this.reportService.getExcelReport(JSON.parse(this.viewReportData)).subscribe((response: ArrayBuffer)=>{
          const blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          const downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(blob);
          this.spinnerService.hide();
          downloadLink.click();
        });
    }
  }

  downloadPDF(){
    this.exportAsService.save(this.exportAsPDF, 'AllinOne').subscribe(() => {
      // save started
    });
  }
}
