import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, FormControl } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef,DialogService } from 'primeng/dynamicdialog';
import { AdminAccountablePersonService } from 'src/app/services/admin-accountable-person.service';
import { MessageService } from 'primeng/api';
import { ConfirmationComponent } from '../confirmation/confirmation.component';
@Component({
  selector: 'app-admin-accountable-edit-popup',
  templateUrl: './admin-accountable-edit-popup.component.html',
  styleUrls: ['./admin-accountable-edit-popup.component.css']
})
export class AdminAccountableEditPopupComponent implements OnInit {
  acccounableEdittreeForm!: FormGroup;

  @ViewChild('myForm', { static: false }) MyForm: NgForm;
  data: any;
valcount:number=0;
  getacountablePerson: any;
  updateacountablePerson: any;
  checkselected:boolean=true;
  constructor(private ref: DynamicDialogRef,public config: DynamicDialogConfig,
    private adminAccountablePersonService: AdminAccountablePersonService,
    private readonly dialogRef: DynamicDialogRef,private messageService: MessageService,
    private fb: FormBuilder,
    private dialogService: DialogService,)
   {
    this.data=this.config.data;
  }

  ngOnInit(): void {
    this.acccounableEdittreeForm = this.fb.group({})
    const ruIds = this.data.ruId.map((r:any)=> (r.id));
    const ruSelectedIds = ruIds[this.config.data?.cardIndex];
    const processStepId = this.config.data?.cardData?.id;
    let formatData={
      "ruIds":  ruSelectedIds,
      "processStepIds": processStepId
    }
    this.adminAccountablePersonService.getAccoutablePerson(formatData).subscribe((response) => {
      this.getacountablePerson = response;
      this.getacountablePerson.map((a) => {
        this.addCheckBoxes(a.geid, a.isActive);
      })
    })
  }
  checkval(event)
  {
    
    if(event.target.checked==false)
    {
      this.valcount=this.valcount+1;
      this.checkselected=false;
    }
    else
    {
      this.valcount=this.valcount-1;
    }

  }

  onClicksave(){
    
      const ruIds = this.data.ruId.map((r:any)=> (r.id));
      const ruSelectedIds = ruIds[this.config.data?.cardIndex];
      const processStepId = this.config.data?.cardData?.id;
      const accountablegeid = this.getacountablePerson.map((acc:any) => (acc.geid));

      let custData=[];
      accountablegeid.forEach(geid => {
        custData.push({
          ruIds:  ruSelectedIds,
          processStepIds: processStepId,
          accountablePersonGeids:geid,
          isActive: this.acccounableEdittreeForm?.controls[geid]?.value
        })
      })
// let formatData={
//   "ruIds":  ruSelectedIds,
//   "processStepIds": processStepId,
//   "accountablePersonGeids":accountablegeid,
//   "isActive": true
// }

  // if(custData.length==this.valcount){
  //   this.ref = this.dialogService.open( ConfirmationComponent, {
  //     header: 'Edit Accountable Person ',
  //     width: '45%',
  //     position: 'top',
  //     keepInViewport: true,
  //     autoZIndex: true,
  //     styleClass: 'addtreePopup',
      
  //   });
  
  // }

if(custData.length==1  && this.valcount==1 && this.checkselected==false || custData.length==this.valcount)
{
  this.ref = this.dialogService.open( ConfirmationComponent, {
    header: 'Attention! ',
    width: '40%',
    position: 'top',
    keepInViewport: true,
    autoZIndex: true,
    styleClass: 'attentionPopup',
    
  });
}
else
{
this.adminAccountablePersonService.updateAccontablePerson(custData).subscribe((response) => {
  this.updateacountablePerson = response;
  // console.log('data',custData)
})
this.messageService.add({ severity: 'success', summary: 'Accountable Person(s) node has been Updated', detail: ' ' });
    setTimeout(() => { 
      
    }, 1000)
    this.dialogRef.close('response');
  }
  }
  addCheckBoxes(value: string, setValue) {
    const control = new FormControl(setValue);
    this.acccounableEdittreeForm.addControl(value, control);
  }

  onClose(){
    this.dialogRef.close();
  }

  confirmationPopUp(){
    this.ref = this.dialogService.open( ConfirmationComponent, {
      header: 'Attention!',
      width: '45%',
      position: 'top',
      keepInViewport: true,
      autoZIndex: true,
      styleClass: '',
      
    });
  }


}
