import { Component, OnInit } from '@angular/core';
import { ExternalpageEmitService } from 'src/app/components/external/externalpage-emit.service';

@Component({
  selector: 'app-maintenance-error',
  templateUrl: './maintenance-error.component.html',
  styleUrls: ['./maintenance-error.component.css']
})
export class MaintenanceErrorComponent implements OnInit {

  constructor(private externalPageService: ExternalpageEmitService) { }

  ngOnInit(): void {
    this.externalPageService.setHRGTPageFunction(true);
  }

}
