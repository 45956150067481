<div class="copq-category-pop">
  <div class="popup ">

    <p-tree [value]="files1" scrollHeight="290px" selectionMode="single" [(selection)]="selectedNode"
      filterPlaceholder="Search by Name (Type at least 3 character)" [loading]="loading" (onNodeExpand)="nodeSelect($event)">
    </p-tree>

    <div class="modal-footer mt-2">
      <button (click)="selectAndClose()" [ngClass]="
        selectedNode && selectedNode.label
          ? ' widgetButtonLargeSelectClose red_button'
          : ' widgetButtonLargeSelectClose'
      ">
        Select & Close
      </button>
      <button class="closeBtn ms-1" type="button" (click)="onClose()">{{ "cc_opqcategory_adv_modal_close_btn_lb" | translate }}</button>
    </div>
  </div>
</div>
