import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { ComplaintModel } from 'src/app/components/new-issue/Model/ComplaintModel';
import { AbstractControl } from '@angular/forms';
import { formatDate } from '@angular/common';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ComplaintDetailsService {

  public checkTimer = new BehaviorSubject<boolean>(null);
  isTimeUp = this.checkTimer.asObservable();

  constructor(private http: HttpClient) { }

  setTimerFlag(time){
    this.checkTimer.next(time);
  }

  getComplaintDetails() {
    return this.http.get<any>(`${environment.API_URL}/complaints`)
  }

  issueCaptureAttachmentSave(section, formData) {
    return this.http.post<any>(`${environment.azure.baseUrl}api/v1/Attachment/save/?section=${section}`, formData);
  }

  siteActionManualEntryAttachmentSave(formData) {
    return this.http.post<any>(`${environment.azure.baseUrl}api/v1/files/upload`,formData);
  }

  siteActionManualEntryRemoveAttachments(filename,type) {
    return this.http.delete<any>(`${environment.azure.baseUrl}api/v1/files?type=${type}&id=${filename}`);
  }

  sendMailAttachmentSave(formData) {
    return this.http.post<any>(`${environment.azure.baseUrl}api/v1/Attachment/save`, formData);
  }

  removeAttachments(filename) {
    return this.http.post<any>(`${environment.azure.baseUrl}api/v1/Attachment/remove`, filename);
  }
  sendMailRemoveAttachments(filename) {
    return this.http.post<any>(`${environment.azure.baseUrl}api/v1/Attachment/remove`, filename);
  }

  deleteDraft(complaintformatedData) {
    return this.http.post<any>(`${environment.azure.baseUrl}api/v1/Complaint/DeleteDraft`, complaintformatedData);
  }

  saveAsDraft(saveData) {
    return this.http.post<any>(`${environment.azure.baseUrl}api/v1/Complaint/SaveAsDraft`, saveData);
  }

  onSave(saveData) {
    return this.http.post<any>(`${environment.azure.baseUrl}api/v1/Complaint/SaveComplaintCaptureSection`, saveData);
  }

  submit(saveData) {
    return this.http.post<any>(`${environment.azure.baseUrl}api/v1/Complaint/Submit`, saveData);
  }

  submitToSAP(saveData) {
    return this.http.post<any>(`${environment.azure.baseUrl}api/v1/Complaint/SubmitToSAPNew`, saveData);
  }

  complaintValidation(flag:boolean) {
    let params = new HttpParams()
    .set('inculdeNCRchange', flag);
    return this.http.get<any>(`${environment.azure.baseUrl}api/v1/Complaint/complaintvalidation`,{params});
  }

  //Issue Assignment API's

  assign(saveData) {
    return this.http.post<any>(`${environment.azure.baseUrl}api/v1/Complaint/Assign`, saveData);
  }


  returnToCO(saveData) {
    return this.http.post<any>(`${environment.azure.baseUrl}api/v1/Complaint/ReturnToCo`, saveData);
  }

  saveAssignmentSectionComplaint(saveData) {
    return this.http.post<any>(`${environment.azure.baseUrl}api/v1/Complaint/SaveAssignmentSectionComplaint`, saveData);
  }

  //Issue Resolution API's
  revertInProcToNotAssignedStatus(saveData) {
    return this.http.post<any>(`${environment.azure.baseUrl}api/v1/Complaint/RevertInProcToNotAssignedStatus`, saveData);
  }

  coordinatorRequestsReturnToRo(saveData) {
    return this.http.post<any>(`${environment.azure.baseUrl}api/v1/Complaint/CoordinatorRequestsReturnToRo`, saveData);
  }

  saveResolutionSectionComplaint(saveData) {
    return this.http.post<any>(`${environment.azure.baseUrl}api/v1/Complaint/SaveResolutionSectionComplaint`, saveData);
  }

  verifyResolution(saveData) {
    return this.http.post<any>(`${environment.azure.baseUrl}api/v1/Complaint/VerifyResolution`, saveData);
  }

  resolve(saveData) {
    return this.http.post<any>(`${environment.azure.baseUrl}api/v1/Complaint/Resolve`, saveData);
  }

  containmentActionExecuted(saveData) {
    return this.http.post<any>(`${environment.azure.baseUrl}api/v1/Complaint/ContainmentActionExecuted`, saveData);
  }

    // Issue Closed API's
    saveIssueClosed(saveData){
      return this.http.post<any>(`${environment.azure.baseUrl}api/v1/Complaint/SaveResolutionSectionComplaint`,saveData);
    }

    IssueClosedCompleted(saveData){
      return this.http.post<any>(`${environment.azure.baseUrl}api/v1/Complaint/Close`,saveData);
    }

    //Issue Closed Execution Pending button functionality (External)
    IssueClosedCloseAfterExecutionPending(saveData){
      return this.http.post<any>(`${environment.azure.baseUrl}api/v1/Complaint/CloseAfterExecutionPending`,saveData);
    }

  //Issue Effectiveness API's
  saveEffectivenessSectionComplaint(saveData) {
    return this.http.post<any>(`${environment.azure.baseUrl}api/v1/Complaint/SaveEffectivenessSectionComplaint`, saveData);
  }

  reopenIssue(saveData) {
    return this.http.post<any>(`${environment.azure.baseUrl}api/v1/Complaint/ReopenIssue`, saveData);
  }

  closureConfirmation(saveData) {
    return this.http.post<any>(`${environment.azure.baseUrl}api/v1/Complaint/ClosureConfirmation`, saveData);
  }

  returnToRO(saveData) {
    return this.http.post<any>(`${environment.azure.baseUrl}api/v1/Complaint/CORequestsReturnToRO`, saveData);
  }

  close(saveData) {
    return this.http.post<any>(`${environment.azure.baseUrl}api/v1/Complaint/Close`, saveData);
  }

  downloadComplaintInfoPPT(complaintId:number){
    return this.http.get(`${environment.azure.baseUrl}api/v1/issuepowerpoint/${complaintId}`,{
      responseType: 'arraybuffer'
    });
  }

  downloadIssueSummaryReport(complaintId:number){
    return this.http.get(`${environment.azure.baseUrl}api/v1/site/summaryreport?id=${complaintId}`);
  }

  sendMailForNotification(data){
    return this.http.post<any>(`${environment.azure.baseUrl}api/v1/SendMail/send`, data);
  }

  setComplaintLock(data){
    return this.http.post<any>(`${environment.azure.baseUrl}api/v1/Complaint/SetComplaintLock`, data);
  }

  deleteComplaintLock(data){
    return this.http.post<any>(`${environment.azure.baseUrl}api/v1/Complaint/DeleteComplaintLock`, data);
  }

  updateComplaintLock(data){
    return this.http.post<any>(`${environment.azure.baseUrl}api/v1/Complaint/UpdateComplaintLock`, data);
  }

  reject(saveData) {
    return this.http.post<any>(`${environment.azure.baseUrl}api/v1/Complaint/RejectCCRPClass`, saveData);
  }

  verifyResolutionByBUProcessOwner(saveData) {
    return this.http.post<any>(`${environment.azure.baseUrl}api/v1/Complaint/VerifyResolutionByBuProcessOwner`, saveData);
  }

dateValidatorForPastDate(control: AbstractControl,today):  boolean {
  if (control?.value) {
   today = formatDate(new Date(today), 'yyyy-MM-dd', 'en-US');
      let dateToCheck = formatDate(new Date(control.value), 'yyyy-MM-dd', 'en-US');
     // console.log("=====",dateToCheck , today,dateToCheck < today);
      if (dateToCheck < today) {
          return true;
      }
      else
      return false;
  }
  else
  return false;
}

  checkEffectiveness(inputEffectivess){
    let isEffectivnessOption;
      if(inputEffectivess == 'NotApplicable' ){
        isEffectivnessOption = 2;
      }else if(inputEffectivess == 'Yes' ){
        isEffectivnessOption = 0;
      }else if(inputEffectivess == 'No' ){
        isEffectivnessOption = 1;
      }
      return isEffectivnessOption
  }

  getComplainData(formValues, userInfo): any {
    const allUserInfo = {
      permissions: userInfo.user.permissions.map((p) => ({ id: p, code: p })),
      geId: userInfo.geid,
      email: userInfo.email,
      countryCode: userInfo.user.userCountryCode
    }
    const allFormValue = {
      headerSection: formValues.controls?.headerSection?.controls,
      issueCapture: formValues.controls?.issueCaptureForm?.controls,
      issueAssignment: formValues.controls?.issueAssignmentForm?.controls,
      issueDescription: formValues.controls?.issueDescriptionForm?.controls,
      issueClosed: formValues.controls?.issueClosedForm?.controls,
      issueResolution: formValues.controls?.issueResolutionForm?.controls,
      issueRating: formValues.controls?.ratingForm?.controls,
      additionalSubject: formValues.controls?.additionalSubjectForm?.controls,
      effectiveNess: formValues.controls?.effectivenessForm?.controls,
      id: formValues.controls['id']?.value
    }

    return {
      "id": allFormValue?.id ? allFormValue?.id : null,
      "complaintDataAccess": {
        "isReadOnly": false,
        "isLocked": false,
        "selectedRole": {
          "id": 3,
          "name": "Issue Owner",
          "code": 3,
          "permissions": allUserInfo.permissions
        }
      },
      "header": {
        "id": allFormValue?.id ? allFormValue?.id : null,
        "complaintNumber": allFormValue?.headerSection['complaintNumber']?.value ? allFormValue?.headerSection['complaintNumber']?.value : '',
        "complaintStatus": allFormValue.headerSection['complaintStatusId']?.value,
        "complaintStatusName": allFormValue.headerSection['issueComplaintStatus']?.value,
        "lastUpdate": new Date(),
        "updatedFields": [
          "AreaComplaintCollection.CustomerSelectionSection.CustomerType:Issue Type",
          "AreaComplaintCollection.ComplaintInformationSection.Subject:Subject",
          "AreaComplaintCollection.ComplaintInformationSection.DescriptionSection.Reason:Summarize the reason for the issue and what customer wants/expects (In English for issues sent to other countries):",
          "AreaComplaintCollection.ComplaintInformationSection.TargetResolvingDate:Target Resolution &amp; Verification date",
          "tb_AreaComplaintCollection_ResolvingUnitSection_ResolvingUnit:Resolving Unit",
          "tb_AreaComplaintCollection_ComplaintCustomerComplainingSection_ABBProductCategory:Product",
       //   "8:undefined",
          "tb_AreaComplaintCollection_ComplaintCustomerComplainingSection_FailureMode:Failure mode"
        ],
        "editorGEID": allUserInfo.geId,
        "buCode": allFormValue.additionalSubject['BuCode']?.value ? allFormValue.additionalSubject['BuCode']?.value : '',
        "shouldTopQiBeUsed": false,
        "isConfidential": allFormValue?.headerSection['isConfidential']?.value ? allFormValue?.headerSection['isConfidential']?.value : false,
        "editorEmail": allUserInfo.email,
        "deleteReason": allFormValue.headerSection['deleteReason']?.value,
        "rowversion": allFormValue.headerSection['rowVersion']?.value,
        "rowversionString": allFormValue.headerSection['rowVersionString']?.value,
        "createDate": allFormValue.headerSection['createDate']?.value,
        "salesForceId": allFormValue.headerSection['salesForceId']?.value
      },
      "areaComplaintCollection": {
        "complaintOwner": {
          "primaryComplaintOwner": {
            "geid": allFormValue.issueCapture['PrimaryComplaintOwner'].value ? allFormValue.issueCapture['PrimaryComplaintOwner'].value[0]?.geid : '',
            "email": allFormValue.issueCapture['PrimaryComplaintOwner'].value ? allFormValue.issueCapture['PrimaryComplaintOwner'].value[0]?.selectedLabel : '',
            "countryCode": allFormValue.issueCapture['PrimaryComplaintOwner'].value ? allFormValue.issueCapture['PrimaryComplaintOwner'].value[0]?.countryCode : '',
            "level2OrgCode": allFormValue.issueCapture['PrimaryComplaintOwner'].value ? allFormValue.issueCapture['PrimaryComplaintOwner'].value[0]?.level2OrgCode : '',
            "level3OrgCode": allFormValue.issueCapture['PrimaryComplaintOwner'].value ? allFormValue.issueCapture['PrimaryComplaintOwner'].value[0]?.level3OrgCode : '',
            "identification": {
              "geid": allFormValue.issueCapture['PrimaryComplaintOwner'].value ? allFormValue.issueCapture['PrimaryComplaintOwner'].value[0]['identification']?.geid : '',
              "email": allFormValue.issueCapture['PrimaryComplaintOwner'].value ? allFormValue.issueCapture['PrimaryComplaintOwner'].value[0]['identification']?.email : ''
            },
            "displayLabel": allFormValue.issueCapture['PrimaryComplaintOwner'].value ? allFormValue.issueCapture['PrimaryComplaintOwner'].value[0]?.promptLabel : '',
            "tooltipInfo": allFormValue.issueCapture['PrimaryComplaintOwner'].value ? allFormValue.issueCapture['PrimaryComplaintOwner'].value[0]?.tooltipInfo : '',
            "isValid": true
          },
          "supervisor": {
            "geid": allFormValue.issueCapture['supervisor'].value ? allFormValue.issueCapture['supervisor'].value?.Geid : '',
            "identification": {
              "geid": allFormValue.issueCapture['supervisor'].value ? allFormValue.issueCapture['supervisor'].value?.Identification?.Geid : ''
            },
            "displayLabel": allFormValue.issueCapture['supervisor'].value ? allFormValue.issueCapture['supervisor'].value?.DisplayLabel : '',
            "tooltipInfo": allFormValue.issueCapture['supervisor'].value ? allFormValue.issueCapture['supervisor'].value?.TooltipInfo : '',
            "isValid": true
          },
          "complaintOwnerSubstitutes": allFormValue.issueCapture['substitute']?.value && allFormValue.issueCapture['substitute']?.value?.length>0 ? allFormValue.issueCapture['substitute']?.value : null
        },
        "customerSelectionSection": {
          "customerType": allFormValue.issueCapture['CustomerType'].value == "External" ? 0 : 1,
          "customer": allFormValue.issueCapture['CustomerType'].value == "External" ? allFormValue.issueCapture['customerNameHidden']?.value : null,
          "customerContact": allFormValue.issueCapture['CustomerType'].value == "External" ? allFormValue.issueCapture['customerContactNameHidden']?.value : null,
          "referredCustomer": allFormValue.issueCapture['CustomerType'].value == "Internal" ? allFormValue.issueCapture['affectedCustomerHidden']?.value : null,
          "wasRefferedCustomerSelected": allFormValue.issueCapture['CustomerType'].value == "Internal" ? true : false,
          "sendConfirmationToCustomer": false,
          "supplier": allFormValue.additionalSubject['supplierData']?.value ? allFormValue.additionalSubject['supplierData']?.value : null
        },
        "complaintInformationSection": {
          "descriptionSection": {
            "isComplaintDescriptionSectionMandatory": allFormValue.issueRating['IsComplaintDescriptionSectionMandatory']?.value ? allFormValue.issueRating['IsComplaintDescriptionSectionMandatory']?.value : false,
            "reason": allFormValue.issueDescription['Reason']?.value ? allFormValue.issueDescription['Reason']?.value : '',
            "whatIssueDescription": allFormValue.issueDescription['WhatIssueDescription']?.value ? allFormValue.issueDescription['WhatIssueDescription']?.value : '',
            "whyIssueDescription": allFormValue.issueDescription['whyIssueDescription']?.value ? allFormValue.issueDescription['whyIssueDescription']?.value : '',
            "howIssueDescription": allFormValue.issueDescription['howIssueDescription']?.value ? allFormValue.issueDescription['howIssueDescription']?.value : '',
            "inWhichProcessStep": allFormValue.issueDescription['inWhichProcessStep']?.value ? allFormValue.issueDescription['inWhichProcessStep']?.value : null,
            "whereIssueDescription": allFormValue.issueDescription['whereIssueDescription']?.value ? allFormValue.issueDescription['whereIssueDescription']?.value : '',
            "whenIssueDescription": allFormValue.issueDescription['whenIssueDescription']?.value ? allFormValue.issueDescription['whenIssueDescription']?.value : '',
            "customerExpectedResolutionDescription": allFormValue.issueDescription['customerExpectedResolutionDescription']?.value ? allFormValue.issueDescription['customerExpectedResolutionDescription']?.value : ''
          },
          "subject": allFormValue.issueCapture['Subject'].value ? allFormValue.issueCapture['Subject'].value : '',
          "highImpact": {
            "highImpactCategoryId": 0,
            "hasHightImpactOnABB": allFormValue.issueRating['HasHightImpactOnABB']?.value ? allFormValue.issueRating['HasHightImpactOnABB']?.value : false,
            "riskToHealthAndSafety": false,
            "enviromentalRisk": false,
            "negativeImpactToCustomer": false,
            "negativeImpactToAbb": false,
            "negativeImpactOnAbbBrandImage": false,
            "lossOfCustomer": false,
            "repeatebleIssuesOrProductRecall": false,
            "potentialBusinessImpactView": ""
          },
          "eightDRCAIsRequiredByCustomer": allFormValue.issueDescription['EightDRCAIsRequiredByCustomer'].value,
          "targetResolvingDate": allFormValue.issueDescription['targetResolutionHiddenDate'].value ? allFormValue.issueDescription['targetResolutionHiddenDate'].value : null,
          "informationAttachments": allFormValue.issueDescription['uploadedAttachments']?.value ? allFormValue.issueDescription['uploadedAttachments']?.value : [],
          "isContainmentActionRequired": allFormValue.issueDescription['IsContainmentActionRequired'].value,
          "containmentAction": allFormValue.issueDescription['IsContainmentActionRequired'].value ? {
            "id": allFormValue.issueDescription['ContainmentActionsId']?.value ? allFormValue.issueDescription['ContainmentActionsId']?.value : 0,
            "execution": allFormValue.issueResolution['ContainmentActionsExecution']?.value ? allFormValue.issueResolution['ContainmentActionsExecution']?.value : null,
            "deadline": allFormValue.issueDescription['deadline']?.value ? allFormValue.issueDescription['deadline']?.value : '',
            "containmentActionDescription": allFormValue.issueDescription['ContainmentActionDescription']?.value ? allFormValue.issueDescription['ContainmentActionDescription']?.value : '',
            //      "initialDeadline": "string"
          } : {},
          "targetResolvingDateChangeCount": allFormValue.issueDescription['targetResolvingDateChangeCount']?.value ? allFormValue.issueDescription['targetResolvingDateChangeCount']?.value : 0,
          "targetResolvingDateInitialValue": allFormValue.issueDescription['targetResolvingDateInitialValue'].value ? allFormValue.issueDescription['targetResolvingDateInitialValue'].value : null,
          "noOfTargetDateLogs": 0,
          "highImpactValidationViewModel": {
            "isValid": false,
            "deadlineDate": "0001-01-01T00:00:00",
            "isConfirm": true,
            "reviewRequired":allFormValue.issueResolution['ReviewRequired']?.value ? allFormValue.issueResolution['ReviewRequired']?.value : '',
          },
          "matrixOriginalRatingViewModel": {
            "matrixSelection": [
              {
                "category": {
                  "id": 1,
                  "name": "Availability",
                  "domain": 1,
                  "sortOrder": 1
                },
                "severities": [
                  {
                    "id": 1,
                    "description": "Category not applicable",
                    "weight": 0,
                    "sortOrder": 1,
                    "dataValue": "1",
                    "dataText": "Category not applicable"
                  },
                  {
                    "id": 2,
                    "description": "No impact to availability",
                    "weight": 1,
                    "sortOrder": 2,
                    "dataValue": "2",
                    "dataText": "No impact to availability"
                  },
                  {
                    "id": 3,
                    "description": "Installed / delivered product or system unavailable for <1 day",
                    "weight": 3,
                    "sortOrder": 2,
                    "dataValue": "3",
                    "dataText": "Installed / delivered product or system unavailable for <1 day"
                  },
                  {
                    "id": 4,
                    "description": "1. Installed / delivered product or system unavailable for > 1 day but < 1 week 2. Unexpected service black out affecting public service (< 10 mins)",
                    "weight": 7,
                    "sortOrder": 3,
                    "dataValue": "4",
                    "dataText": "1. Installed / delivered product or system unavailable for > 1 day but < 1 week 2. Unexpected service black out affecting public service (< 10 mins)"
                  },
                  {
                    "id": 5,
                    "description": "1. Installed / delivered product or system unavailable for more than 1 week 2. Unexpected service black out affect public service significantly (> 10 mins)",
                    "weight": 10,
                    "sortOrder": 4,
                    "dataValue": "5",
                    "dataText": "1. Installed / delivered product or system unavailable for more than 1 week 2. Unexpected service black out affect public service significantly (> 10 mins)"
                  }
                ],
                "selectionId": allFormValue.issueRating['availabilityRating'].value,
                "sortOrder": 1
              },
              {
                "category": {
                  "id": 2,
                  "name": "Functionality",
                  "domain": 1,
                  "sortOrder": 2
                },
                "severities": [
                  {
                    "id": 6,
                    "description": "Category not applicable",
                    "weight": 0,
                    "sortOrder": 1,
                    "dataValue": "6",
                    "dataText": "Category not applicable"
                  },
                  {
                    "id": 7,
                    "description": "No significant effect",
                    "weight": 1,
                    "sortOrder": 2,
                    "dataValue": "7",
                    "dataText": "No significant effect"
                  },
                  {
                    "id": 8,
                    "description": "1. Quality of appearance unacceptable to customer 2. product performance decreased less than 10%",
                    "weight": 3,
                    "sortOrder": 2,
                    "dataValue": "8",
                    "dataText": "1. Quality of appearance unacceptable to customer 2. product performance decreased less than 10%"
                  },
                  {
                    "id": 9,
                    "description": "Loss or degradation of essential function necessary for normal work before end of warranty. (performance decreased by 10% or more but less than 30%)",
                    "weight": 7,
                    "sortOrder": 3,
                    "dataValue": "9",
                    "dataText": "Loss or degradation of essential function necessary for normal work before end of warranty. (performance decreased by 10% or more but less than 30%)"
                  },
                  {
                    "id": 10,
                    "description": "Loss or degradation of essential function necessary to normal work before ending of warranty (performance decreased more than 30%)",
                    "weight": 10,
                    "sortOrder": 4,
                    "dataValue": "10",
                    "dataText": "Loss or degradation of essential function necessary to normal work before ending of warranty (performance decreased more than 30%)"
                  },
                  {
                    "id": 71,
                    "description": "Loss or degradation of essential function necessary for normal work out of warranty time and before end of service life",
                    "weight": 5,
                    "sortOrder": 2,
                    "dataValue": "71",
                    "dataText": "Loss or degradation of essential function necessary for normal work out of warranty time and before end of service life"
                  }
                ],
                "selectionId": allFormValue.issueRating['functionalityRating'].value,
                "sortOrder": 2
              },
              {
                "category": {
                  "id": 3,
                  "name": "On Time Delivery",
                  "domain": 1,
                  "sortOrder": 3
                },
                "severities": [
                  {
                    "id": 11,
                    "description": "Category not applicable",
                    "weight": 0,
                    "sortOrder": 1,
                    "dataValue": "11",
                    "dataText": "Category not applicable"
                  },
                  {
                    "id": 12,
                    "description": "1. No risk to OTD 2. Components are delayed being delivered to a project",
                    "weight": 1,
                    "sortOrder": 2,
                    "dataValue": "12",
                    "dataText": "1. No risk to OTD 2. Components are delayed being delivered to a project"
                  },
                  {
                    "id": 13,
                    "description": "Non-essential components / products are delivered late to a customer",
                    "weight": 3,
                    "sortOrder": 2,
                    "dataValue": "13",
                    "dataText": "Non-essential components / products are delivered late to a customer"
                  },
                  {
                    "id": 14,
                    "description": "Essential components / products are delivered late to a customer impacting customer performance negatively and / or triggering contractual consequences",
                    "weight": 7,
                    "sortOrder": 3,
                    "dataValue": "14",
                    "dataText": "Essential components / products are delivered late to a customer impacting customer performance negatively and / or triggering contractual consequences"
                  },
                  {
                    "id": 15,
                    "description": "Essential components / products are delivered late to a customer impacting customer performance severely financially / operational and / or triggering severe contractual consequences",
                    "weight": 10,
                    "sortOrder": 4,
                    "dataValue": "15",
                    "dataText": "Essential components / products are delivered late to a customer impacting customer performance severely financially / operational and / or triggering severe contractual consequences"
                  },
                  {
                    "id": 72,
                    "description": "Essential products are not delivered on time causing customer dissatisfaction",
                    "weight": 5,
                    "sortOrder": 2,
                    "dataValue": "72",
                    "dataText": "Essential products are not delivered on time causing customer dissatisfaction"
                  }
                ],
                "selectionId": allFormValue.issueRating['onTimeDeliveryRating'].value,
                "sortOrder": 3
              },
              {
                "category": {
                  "id": 4,
                  "name": "Cyber Security",
                  "domain": 1,
                  "sortOrder": 4
                },
                "severities": [
                  {
                    "id": 16,
                    "description": "Category not applicable",
                    "weight": 0,
                    "sortOrder": 1,
                    "dataValue": "16",
                    "dataText": "Category not applicable"
                  },
                  {
                    "id": 17,
                    "description": "Low impact incident. Remedial action may be required but not as a matter of urgency",
                    "weight": 1,
                    "sortOrder": 2,
                    "dataValue": "17",
                    "dataText": "Low impact incident. Remedial action may be required but not as a matter of urgency"
                  },
                  {
                    "id": 18,
                    "description": "Requires prompt remediation but is unlikely to result in business operations disruption, loss of sensitive data or impact on Human life",
                    "weight": 3,
                    "sortOrder": 2,
                    "dataValue": "18",
                    "dataText": "Requires prompt remediation but is unlikely to result in business operations disruption, loss of sensitive data or impact on Human life"
                  },
                  {
                    "id": 19,
                    "description": "Requires immediate action. Sensitive data, human life and/or major business operations are likely to be affected",
                    "weight": 7,
                    "sortOrder": 3,
                    "dataValue": "19",
                    "dataText": "Requires immediate action. Sensitive data, human life and/or major business operations are likely to be affected"
                  },
                  {
                    "id": 20,
                    "description": "Requires immediate action. Sensitive data, human life and/or major business operations are already, or highly likely to be, affected",
                    "weight": 10,
                    "sortOrder": 4,
                    "dataValue": "20",
                    "dataText": "Requires immediate action. Sensitive data, human life and/or major business operations are already, or highly likely to be, affected"
                  }
                ],
                "selectionId": allFormValue.issueRating['cyberSecurityRating'].value,
                "sortOrder": 4
              },
              {
                "category": {
                  "id": 5,
                  "name": "Product Compliance",
                  "domain": 1,
                  "sortOrder": 5
                },
                "severities": [
                  {
                    "id": 21,
                    "description": "Category not applicable",
                    "weight": 0,
                    "sortOrder": 1,
                    "dataValue": "21",
                    "dataText": "Category not applicable"
                  },
                  {
                    "id": 24,
                    "description": "Products adhere to standard but customers complained",
                    "weight": 7,
                    "sortOrder": 3,
                    "dataValue": "24",
                    "dataText": "Products adhere to standard but customers complained"
                  },
                  {
                    "id": 25,
                    "description": "1. Product or process modification requested by public authorities due to product or process violation of regulatory requirements,  like national, regional or international product regulations 2. Product compliance issue by continuously shipping products which are not complying to customer requirements",
                    "weight": 10,
                    "sortOrder": 4,
                    "dataValue": "25",
                    "dataText": "1. Product or process modification requested by public authorities due to product or process violation of regulatory requirements,  like national, regional or international product regulations 2. Product compliance issue by continuously shipping products which are not complying to customer requirements"
                  }
                ],
                "selectionId": allFormValue.issueRating['productComplianceRating'].value,
                "sortOrder": 5
              },
              {
                "category": {
                  "id": 6,
                  "name": "OHS Risk",
                  "domain": 1,
                  "sortOrder": 6
                },
                "severities": [
                  {
                    "id": 27,
                    "description": "No risk of injury",
                    "weight": 1,
                    "sortOrder": 2,
                    "dataValue": "27",
                    "dataText": "No risk of injury"
                  },
                  {
                    "id": 28,
                    "description": "Low risk of injury",
                    "weight": 3,
                    "sortOrder": 2,
                    "dataValue": "28",
                    "dataText": "Low risk of injury"
                  },
                  {
                    "id": 29,
                    "description": "1.High risk of injury with potential for hospitalization 2.Injury has already occurred (no hospitalization necessary)",
                    "weight": 7,
                    "sortOrder": 3,
                    "dataValue": "29",
                    "dataText": "1.High risk of injury with potential for hospitalization 2.Injury has already occurred (no hospitalization necessary)"
                  },
                  {
                    "id": 30,
                    "description": "Injury has already occurred (People hospitalized)",
                    "weight": 10,
                    "sortOrder": 4,
                    "dataValue": "30",
                    "dataText": "Injury has already occurred (People hospitalized)"
                  }
                ],
                "selectionId": allFormValue.issueRating['ohsRiskRating'].value,
                "sortOrder": 6
              },
              {
                "category": {
                  "id": 7,
                  "name": "Margin",
                  "domain": 1,
                  "sortOrder": 7
                },
                "severities": [
                  {
                    "id": 31,
                    "description": "Category not applicable",
                    "weight": 0,
                    "sortOrder": 1,
                    "dataValue": "31",
                    "dataText": "Category not applicable"
                  },
                  {
                    "id": 32,
                    "description": "COPQ or penalties below 200 kUSD",
                    "weight": 1,
                    "sortOrder": 2,
                    "dataValue": "32",
                    "dataText": "COPQ or penalties below 200 kUSD"
                  },
                  {
                    "id": 33,
                    "description": "COPQ or penalties from 200 kUSD to 850 kUSD",
                    "weight": 3,
                    "sortOrder": 2,
                    "dataValue": "33",
                    "dataText": "COPQ or penalties from 200 kUSD to 850 kUSD"
                  },
                  {
                    "id": 73,
                    "description": "COPQ or penalties higher than 850 kUSD",
                    "weight": 5,
                    "sortOrder": 2,
                    "dataValue": "73",
                    "dataText": "COPQ or penalties higher than 850 kUSD"
                  }
                ],
                "selectionId": allFormValue.issueRating['marginRating'].value,
                "sortOrder": 7
              },
              {
                "category": {
                  "id": 8,
                  "name": "Reputation",
                  "domain": 1,
                  "sortOrder": 8
                },
                "severities": [
                  {
                    "id": 37,
                    "description": "No Impact to PG Reputation",
                    "weight": 1,
                    "sortOrder": 2,
                    "dataValue": "37",
                    "dataText": "No Impact to PG Reputation"
                  },
                  {
                    "id": 38,
                    "description": "Risk of Customer Complaint",
                    "weight": 3,
                    "sortOrder": 2,
                    "dataValue": "38",
                    "dataText": "Risk of Customer Complaint"
                  },
                  {
                    "id": 39,
                    "description": "Written evidence (letter / minutes of meeting) that customer executive considers 1.it a severe incident 2.Withdrawing future orders",
                    "weight": 7,
                    "sortOrder": 3,
                    "dataValue": "39",
                    "dataText": "Written evidence (letter / minutes of meeting) that customer executive considers 1.it a severe incident 2.Withdrawing future orders"
                  },
                  {
                    "id": 40,
                    "description": "1.Removed from customer´s \"approved vendor list“ 2.Loss of orders 3.Request by Senior BU Quality Manager (Hitachi)",
                    "weight": 10,
                    "sortOrder": 4,
                    "dataValue": "40",
                    "dataText": "1.Removed from customer´s \"approved vendor list“ 2.Loss of orders 3.Request by Senior BU Quality Manager (Hitachi)"
                  },
                  {
                    "id": 74,
                    "description": "1.Risk of Loss of Orders 2.Nonconformance causes significant loss of trust",
                    "weight": 5,
                    "sortOrder": 2,
                    "dataValue": "74",
                    "dataText": "1.Risk of Loss of Orders 2.Nonconformance causes significant loss of trust"
                  }
                ],
                "selectionId": allFormValue.issueRating['reputationRating'].value,
                "sortOrder": 8
              },
              {
                "category": {
                  "id": 9,
                  "name": "Mass media",
                  "domain": 1,
                  "sortOrder": 9
                },
                "severities": [
                  {
                    "id": 41,
                    "description": "Category not applicable",
                    "weight": 0,
                    "sortOrder": 1,
                    "dataValue": "41",
                    "dataText": "Category not applicable"
                  },
                  {
                    "id": 44,
                    "description": "Incident reported by national wide media - tier 2 acc. to PG-CC-01",
                    "weight": 7,
                    "sortOrder": 3,
                    "dataValue": "44",
                    "dataText": "Incident reported by national wide media - tier 2 acc. to PG-CC-01"
                  },
                  {
                    "id": 45,
                    "description": "Incident reported by global or national wide media - tier 1 level acc. to PG-CC-01",
                    "weight": 10,
                    "sortOrder": 4,
                    "dataValue": "45",
                    "dataText": "Incident reported by global or national wide media - tier 1 level acc. to PG-CC-01"
                  }
                ],
                "selectionId": allFormValue.issueRating['massMediaRating'].value,
                "sortOrder": 9
              },
              {
                "category": {
                  "id": 10,
                  "name": "Design change",
                  "domain": 1,
                  "sortOrder": 10
                },
                "severities": [
                  {
                    "id": 46,
                    "description": "Category not applicable",
                    "weight": 0,
                    "sortOrder": 1,
                    "dataValue": "46",
                    "dataText": "Category not applicable"
                  },
                  {
                    "id": 48,
                    "description": "Screening, or a part of inspection",
                    "weight": 3,
                    "sortOrder": 2,
                    "dataValue": "48",
                    "dataText": "Screening, or a part of inspection"
                  },
                  {
                    "id": 49,
                    "description": "Design change is needed, and selective tests must be repeated",
                    "weight": 7,
                    "sortOrder": 3,
                    "dataValue": "49",
                    "dataText": "Design change is needed, and selective tests must be repeated"
                  },
                  {
                    "id": 50,
                    "description": "Design change is needed which makes new/repeated type test necessary",
                    "weight": 10,
                    "sortOrder": 4,
                    "dataValue": "50",
                    "dataText": "Design change is needed which makes new/repeated type test necessary"
                  }
                ],
                "selectionId": allFormValue.issueRating['designChangeRating'].value,
                "sortOrder": 10
              },
              {
                "category": {
                  "id": 11,
                  "name": "Instances",
                  "domain": 2,
                  "sortOrder": 11
                },
                "severities": [
                  {
                    "id": 52,
                    "description": "Single instance of the nonconformity",
                    "weight": 1,
                    "sortOrder": 2,
                    "dataValue": "52",
                    "dataText": "Single instance of the nonconformity"
                  },
                  {
                    "id": 53,
                    "description": "Multiple instance of the nonconformity",
                    "weight": 3,
                    "sortOrder": 2,
                    "dataValue": "53",
                    "dataText": "Multiple instance of the nonconformity"
                  },
                  {
                    "id": 54,
                    "description": "1.Mass volume products: Nonconformity is recurring regularly 2.Projects: Same customer had same nonconformity in more than 1 project",
                    "weight": 7,
                    "sortOrder": 3,
                    "dataValue": "54",
                    "dataText": "1.Mass volume products: Nonconformity is recurring regularly 2.Projects: Same customer had same nonconformity in more than 1 project"
                  },
                  {
                    "id": 55,
                    "description": "1.Mass volume products: Nonconformity is in every or nearly every delivery 2.Projects: Several customers had the same nonconformity in more than one project",
                    "weight": 10,
                    "sortOrder": 4,
                    "dataValue": "55",
                    "dataText": "1.Mass volume products: Nonconformity is in every or nearly every delivery 2.Projects: Several customers had the same nonconformity in more than one project"
                  },
                  {
                    "id": 75,
                    "description": "Nonconformity is recurring irregularly",
                    "weight": 5,
                    "sortOrder": 2,
                    "dataValue": "75",
                    "dataText": "Nonconformity is recurring irregularly"
                  }
                ],
                "selectionId": allFormValue.issueRating['instancesRating'].value,
                "sortOrder": 11
              },
              {
                "category": {
                  "id": 12,
                  "name": "Resolution",
                  "domain": 2,
                  "sortOrder": 12
                },
                "severities": [
                  {
                    "id": 56,
                    "description": "Category not applicable",
                    "weight": 0,
                    "sortOrder": 1,
                    "dataValue": "56",
                    "dataText": "Category not applicable"
                  },
                  {
                    "id": 57,
                    "description": "Correction known, corrective action known and planned or corrective action not necessary",
                    "weight": 1,
                    "sortOrder": 2,
                    "dataValue": "57",
                    "dataText": "Correction known, corrective action known and planned or corrective action not necessary"
                  },
                  {
                    "id": 58,
                    "description": "Correction known, corrective action known but not planned",
                    "weight": 3,
                    "sortOrder": 2,
                    "dataValue": "58",
                    "dataText": "Correction known, corrective action known but not planned"
                  },
                  {
                    "id": 59,
                    "description": "Correction known, corrective action unknown for systemic / repetitive fault",
                    "weight": 7,
                    "sortOrder": 3,
                    "dataValue": "59",
                    "dataText": "Correction known, corrective action unknown for systemic / repetitive fault"
                  },
                  {
                    "id": 60,
                    "description": "Neither correction nor Corrective action known for systemic / repetitive fault",
                    "weight": 10,
                    "sortOrder": 4,
                    "dataValue": "60",
                    "dataText": "Neither correction nor Corrective action known for systemic / repetitive fault"
                  }
                ],
                "selectionId": allFormValue.issueRating['resolutionRating'].value,
                "sortOrder": 12
              },
              {
                "category": {
                  "id": 13,
                  "name": "Affected customer",
                  "domain": 2,
                  "sortOrder": 13
                },
                "severities": [
                  {
                    "id": 61,
                    "description": "Category not applicable",
                    "weight": 0,
                    "sortOrder": 1,
                    "dataValue": "61",
                    "dataText": "Category not applicable"
                  },
                  {
                    "id": 64,
                    "description": "Multiple customers are highly affected by non-conformity",
                    "weight": 7,
                    "sortOrder": 3,
                    "dataValue": "64",
                    "dataText": "Multiple customers are highly affected by non-conformity"
                  },
                  {
                    "id": 65,
                    "description": "Recall of products needed",
                    "weight": 10,
                    "sortOrder": 4,
                    "dataValue": "65",
                    "dataText": "Recall of products needed"
                  },
                  {
                    "id": 76,
                    "description": "One customer is highly affected by the repair of its installed base",
                    "weight": 5,
                    "sortOrder": 2,
                    "dataValue": "76",
                    "dataText": "One customer is highly affected by the repair of its installed base"
                  }
                ],
                "selectionId": allFormValue.issueRating['affectedCustomerRating'].value,
                "sortOrder": 13
              },
              {
                "category": {
                  "id": 14,
                  "name": "Control",
                  "domain": 3,
                  "sortOrder": 14
                },
                "severities": [
                  {
                    "id": 67,
                    "description": "Automatic detection (as part of the process) of Non-Conformance",
                    "weight": 1,
                    "sortOrder": 2,
                    "dataValue": "67",
                    "dataText": "Automatic detection (as part of the process) of Non-Conformance"
                  },
                  {
                    "id": 68,
                    "description": "Manual detection (by process Operator) of Non-Conformance",
                    "weight": 3,
                    "sortOrder": 2,
                    "dataValue": "68",
                    "dataText": "Manual detection (by process Operator) of Non-Conformance"
                  },
                  {
                    "id": 69,
                    "description": "Control detection (per plan) of Non-conformance",
                    "weight": 7,
                    "sortOrder": 3,
                    "dataValue": "69",
                    "dataText": "Control detection (per plan) of Non-conformance"
                  },
                  {
                    "id": 70,
                    "description": "No opportunity for detection of Non-Conformance",
                    "weight": 10,
                    "sortOrder": 4,
                    "dataValue": "70",
                    "dataText": "No opportunity for detection of Non-Conformance"
                  }
                ],
                "selectionId": allFormValue.issueRating['controlRating'].value,
                "sortOrder": 14
              }
            ],
            "rpn": allFormValue.issueRating['rpn'].value ? allFormValue.issueRating['rpn'].value : 0,
            "class": allFormValue.issueRating['classValue']?.value ? allFormValue.issueRating['classValue']?.value : 0,
            "classFriendlyResult": allFormValue.issueRating['impactRating'].value ? allFormValue.issueRating['impactRating'].value : '',
            "shouldScoringMatrixBeVisible": true,
            "shouldScoringMatrixHeaderInfoBeVisible": false,
            "verificationInformation": {
              "isVisible": false
            }
          },
          "targetResolvingDateLogViewModel": {
            "changeReason": [],
            "changeReasonId": allFormValue.issueDescription['changeReasonId']?.value ? allFormValue.issueDescription['changeReasonId']?.value : null,
            "isReasonRequired": allFormValue.issueDescription['changeReasonId']?.value == 7 ? true: false,
            "reason": allFormValue.issueDescription['changeDescription']?.value ? allFormValue.issueDescription['changeDescription']?.value : null
          },
          "noReportHitachiViewModel": {
            "noReportToHitachi": false
          }
        },
        "resolvingUnitSection": {
          "hasPGCategory": false,
          "isPgCategoryRequired": false,
          "ruChangeCount": 0,
          "resolvingUnit": allFormValue.additionalSubject['resolvingUnitArray']?.value ? allFormValue.additionalSubject['resolvingUnitArray']?.value : null,
          "coordinators": allFormValue.additionalSubject['Coordinators']?.value ? allFormValue.additionalSubject['Coordinators']?.value : [],
          "proposedResolutionOwner": allFormValue.additionalSubject['proposedResolutionOwner']?.value?.length > 0 ? allFormValue.additionalSubject['proposedResolutionOwner']?.value[0] : {}
        },
        "complaintCustomerComplainingSection": {
          "failureMode": allFormValue.additionalSubject['failureModeBind']?.value ? allFormValue.additionalSubject['failureModeBind']?.value : null,
          "abbProductCategory": allFormValue.additionalSubject['issueProductArray']?.value ? allFormValue.additionalSubject['issueProductArray']?.value : null,
          "complaintStatus": 0
        },
        "resolvingSubjectInformationSection": {
          "isBUExceptionFieldsMandatory": allFormValue.additionalSubject['IsBUExceptionFieldsMandatory'].value ? allFormValue.additionalSubject['IsBUExceptionFieldsMandatory'].value : false,
          "abbProjectNumber": allFormValue.additionalSubject['abbProjectNumber']?.value ? allFormValue.additionalSubject['abbProjectNumber']?.value : '',
          "abbProjectName": allFormValue.additionalSubject['abbProjectName']?.value ? allFormValue.additionalSubject['abbProjectName']?.value : '',
          "purchaseOrderNo": allFormValue.additionalSubject['purchaseOrderNo']?.value ? allFormValue.additionalSubject['purchaseOrderNo']?.value : '',
          "abbProductSerialNo": allFormValue.additionalSubject['abbProductSerialNo']?.value ? allFormValue.additionalSubject['abbProductSerialNo']?.value : '',
          "commissioningDate": allFormValue.additionalSubject['commissionDate']?.value ? allFormValue.additionalSubject['commissionDate']?.value : null,
          "manufacturingDate": allFormValue.additionalSubject['ManufacturingDate']?.value ? allFormValue.additionalSubject['ManufacturingDate']?.value : null,
          "abbCustomerReference": allFormValue.additionalSubject['abbCustomerReference']?.value ? allFormValue.additionalSubject['abbCustomerReference']?.value : '',
          "warrantyReference": allFormValue.additionalSubject['warrantyReference']?.value ? allFormValue.additionalSubject['warrantyReference']?.value : '',
          "sendCopyToParties": allFormValue.additionalSubject['SendCopyToParties']?.value,
          "partiesReference": allFormValue.additionalSubject['PartiesReference']?.value ? allFormValue.additionalSubject['PartiesReference']?.value : [],
          "warrantyStartDate": allFormValue.additionalSubject['warrantyStartDate']?.value ? allFormValue.additionalSubject['warrantyStartDate']?.value : null,
          "warrantyEndDate": allFormValue.additionalSubject['warrantyEndDate']?.value ? allFormValue.additionalSubject['warrantyEndDate']?.value : null,
          "quantity": allFormValue.additionalSubject['quantity']?.value ? allFormValue.additionalSubject['quantity']?.value : null,
          "otherDocumentId": allFormValue.additionalSubject['documentID']?.value ? allFormValue.additionalSubject['documentID']?.value : '',
          "itemDesignation": allFormValue.additionalSubject['itemDesignation']?.value ? allFormValue.additionalSubject['itemDesignation']?.value : '',
          "articleNumber": allFormValue.additionalSubject['articleNumber']?.value ? allFormValue.additionalSubject['articleNumber']?.value : '',
          "countryCodeWhereTheIssueHappen": allFormValue.issueDescription['CountryCodeWhereTheIssueHappen']?.value ? allFormValue.issueDescription['CountryCodeWhereTheIssueHappen']?.value?.id : '',
          "qNumberIssuingOrganization": allFormValue.additionalSubject['qNumberIssue']?.value ? allFormValue.additionalSubject['qNumberIssue']?.value : '',
          "noOfUnitsShipped": allFormValue.issueResolution['NoOfUnitsShipped']?.value ? allFormValue.issueResolution['NoOfUnitsShipped']?.value : '',
          "productAlreadyDelivered": allFormValue.issueResolution['ProductAlreadyDelivered']?.value ? allFormValue.issueResolution['ProductAlreadyDelivered']?.value : null,
          "deliveryDate": allFormValue.additionalSubject['deliveryDate']?.value ? allFormValue.additionalSubject['deliveryDate']?.value : null,
          "issueCaptureDataPercentage": allFormValue.headerSection['issueCaptureDataPercentage']?.value ? allFormValue.headerSection['issueCaptureDataPercentage']?.value : 36,
          "warrantyLifeCycleId": allFormValue.additionalSubject['warrantyLifeCycleId']?.value ? allFormValue.additionalSubject['warrantyLifeCycleId']?.value : null
        },
        "site": {}
      },
      "areaComplaintAssignment": {
        "caResolutionOwner": {
          "resolutionOwnerTeam": allFormValue.issueAssignment['resolutionOwnerTeam']?.value ? allFormValue.issueAssignment['resolutionOwnerTeam'].value : [],
          "processOwnersVerificationTeamList": allFormValue.additionalSubject['verificationTeam']?.value ? allFormValue.additionalSubject['verificationTeam']?.value : [],
          "accountableVerifiedUsersList": allFormValue.issueAssignment['AddNominatedPerson']?.value ? allFormValue.issueAssignment['AddNominatedPerson'].value : [],
          "primaryResolutionOwner": allFormValue.issueAssignment['PrimaryResolutionOwner']?.value?.length > 0 ? allFormValue.issueAssignment['PrimaryResolutionOwner'].value[0] : null
        },
        "sectionQuickFix": {
          "quickFixVariant": Number(allFormValue.issueAssignment['quickFixVariant']?.value ? allFormValue.issueAssignment['quickFixVariant']?.value : 2),
          "quickFixComplaint": allFormValue.issueAssignment['quickFixComplaint']?.value ? allFormValue.issueAssignment['quickFixComplaint']?.value : null,
          "quickFixRCANotReqDescription": null
        },
        "solutionWillBeValidatedByCoordinator":
          this.lockSolutionWillBeValidatedByCoordinator(allFormValue.issueRating['HasHightImpactOnABB']?.value, allFormValue.issueDescription['EightDRCAIsRequiredByCustomer'].value,
            allFormValue.issueResolution['impactRating']?.value
              ? allFormValue.issueResolution[
                'classValue'
              ]?.value : allFormValue.issueRating[
                'classValue'
              ]?.value, allFormValue.issueAssignment['solutionWillBeValidatedByCoordinator']?.value)
      },
      "areaComplaintLessonLearnt": {
        "isActive": false,
        "createDate": new Date()
      },
      "areaComplaintResolution": {
        "matrixCurrentRatingViewModel": {
          "matrixSelection": [
            {
              "category": {
                "id": 1,
                "name": "Availability",
                "domain": 1,
                "sortOrder": 1
              },
              "sortOrder": 1,
              "selectionId": allFormValue.issueResolution['availabilityRating']?.value ? allFormValue.issueResolution['availabilityRating']?.value : allFormValue.issueRating['availabilityRating']?.value
            },
            {
              "category": {
                "id": 2,
                "name": "Functionality",
                "domain": 1,
                "sortOrder": 2
              },
              "sortOrder": 2,
              "selectionId": allFormValue.issueResolution['functionalityRating']?.value ? allFormValue.issueResolution['functionalityRating']?.value : allFormValue.issueRating['functionalityRating']?.value
            },
            {
              "category": {
                "id": 3,
                "name": "On Time Delivery",
                "domain": 1,
                "sortOrder": 3
              },
              "sortOrder": 3,
              "selectionId": allFormValue.issueResolution['onTimeDeliveryRating']?.value ? allFormValue.issueResolution['onTimeDeliveryRating']?.value : allFormValue.issueRating['onTimeDeliveryRating']?.value
            },
            {
              "category": {
                "id": 4,
                "name": "Cyber Security",
                "domain": 1,
                "sortOrder": 4,
              },
              "sortOrder": 4,
              "selectionId": allFormValue.issueResolution['cyberSecurityRating']?.value ? allFormValue.issueResolution['cyberSecurityRating']?.value : allFormValue.issueRating['cyberSecurityRating']?.value
            },
            {
              "category": {
                "id": 5,
                "name": "Product Compliance",
                "domain": 1,
                "sortOrder": 5
              },
              "sortOrder": 5,
              "selectionId": allFormValue.issueResolution['productComplianceRating']?.value ? allFormValue.issueResolution['productComplianceRating']?.value : allFormValue.issueRating['productComplianceRating']?.value
            },
            {
              "category": {
                "id": 6,
                "name": "OHS Risk",
                "domain": 1,
                "sortOrder": 6
              },
              "sortOrder": 6,
              "selectionId": allFormValue.issueResolution['ohsRiskRating']?.value ? allFormValue.issueResolution['ohsRiskRating']?.value : allFormValue.issueRating['ohsRiskRating']?.value
            },
            {
              "category": {
                "id": 7,
                "name": "Margin",
                "domain": 1,
                "sortOrder": 7
              },
              "sortOrder": 7,
              "selectionId": allFormValue.issueResolution['marginRating']?.value ? allFormValue.issueResolution['marginRating']?.value : allFormValue.issueRating['marginRating']?.value
            },
            {
              "category": {
                "id": 8,
                "name": "Reputation",
                "domain": 1,
                "sortOrder": 8
              },
              "sortOrder": 8,
              "selectionId": allFormValue.issueResolution['reputationRating']?.value ? allFormValue.issueResolution['reputationRating']?.value : allFormValue.issueRating['reputationRating']?.value
            },
            {
              "category": {
                "id": 9,
                "name": "Mass media",
                "domain": 1,
                "sortOrder": 9
              },
              "sortOrder": 9,
              "selectionId": allFormValue.issueResolution['massMediaRating']?.value ? allFormValue.issueResolution['massMediaRating']?.value : allFormValue.issueRating['massMediaRating']?.value
            },
            {
              "category": {
                "id": 10,
                "name": "Design change",
                "domain": 1,
                "sortOrder": 10
              },
              "sortOrder": 10,
              "selectionId": allFormValue.issueResolution['designChangeRating']?.value ? allFormValue.issueResolution['designChangeRating']?.value : allFormValue.issueRating['designChangeRating']?.value
            },
            {
              "category": {
                "id": 11,
                "name": "Instances",
                "domain": 2,
                "sortOrder": 11
              },
              "sortOrder": 11,
              "selectionId": allFormValue.issueResolution['instancesRating']?.value ? allFormValue.issueResolution['instancesRating']?.value : allFormValue.issueRating['instancesRating']?.value
            },
            {
              "category": {
                "id": 12,
                "name": "Resolution",
                "domain": 2,
                "sortOrder": 12
              },
              "sortOrder": 12,
              "selectionId": allFormValue.issueResolution['resolutionRating']?.value ? allFormValue.issueResolution['resolutionRating']?.value : allFormValue.issueRating['resolutionRating']?.value
            },
            {
              "category": {
                "id": 13,
                "name": "Affected customer",
                "domain": 2,
                "sortOrder": 13
              },
              "sortOrder": 13,
              "selectionId": allFormValue.issueResolution['affectedCustomerRating']?.value ? allFormValue.issueResolution['affectedCustomerRating']?.value : allFormValue.issueRating['affectedCustomerRating']?.value
            },
            {
              "category": {
                "id": 14,
                "name": "Control",
                "domain": 3,
                "sortOrder": 14
              },
              "sortOrder": 14,
              "selectionId": allFormValue.issueResolution['controlRating']?.value ? allFormValue.issueResolution['controlRating']?.value : allFormValue.issueRating['controlRating']?.value
            }
          ],
          "rpn": allFormValue.issueResolution['rpn']?.value ? allFormValue.issueResolution['rpn']?.value : 1,
          "class":  allFormValue.issueResolution['classValue']?.value ? allFormValue.issueResolution['classValue']?.value : 0,
          "classFriendlyResult": allFormValue.issueResolution['impactRating'].value ? allFormValue.issueResolution['impactRating'].value : 'Class C',
          "shouldScoringMatrixBeVisible": false,
          "shouldScoringMatrixHeaderInfoBeVisible": false,
          "verificationInformation": {
            "isVisible": false
          }
        },
        "impactOnInstalledBase": allFormValue.issueResolution['ImpactOnInstalledBase']?.value ? allFormValue.issueResolution['ImpactOnInstalledBase']?.value : null,
        "totalInstalledBase": allFormValue.issueResolution['TotalInstalledBase']?.value ? allFormValue.issueResolution['TotalInstalledBase']?.value : null,
        "isEstimatedCostRequired": false,
        "isEightDRCARequiredCurrent": allFormValue.issueResolution['RCACorrectiveActions']?.value,
        "categoryKeyword": allFormValue.issueResolution['CategoryKeyword']?.value ? allFormValue.issueResolution['CategoryKeyword']?.value : [""],
        "actualCostView": allFormValue.issueResolution['ActualRUCost']?.value ? allFormValue.issueResolution['ActualRUCost']?.value?.replace(/\,/g,'').toString() : null,
        "specificationName": allFormValue.issueResolution['SpecificationName']?.value ? allFormValue.issueResolution['SpecificationName']?.value : null,
        "howProductFailMeetSpecification": allFormValue.issueResolution['howProductFailMeetSpecification']?.value ? allFormValue.issueResolution['howProductFailMeetSpecification']?.value : null,
        "WhatContainmentActionsWhereTaken": allFormValue.issueResolution['WhatContainmentActionsWhereTaken']?.value ? allFormValue.issueResolution['WhatContainmentActionsWhereTaken']?.value : null,
        "whatWasTheRootCauseOfThisIssue": allFormValue.issueResolution['whatWasTheRootCauseOfThisIssue']?.value ? allFormValue.issueResolution['whatWasTheRootCauseOfThisIssue']?.value : null,
        "howDidWeResolveThisIssue": allFormValue.issueResolution['howDidWeResolveThisIssue']?.value ? allFormValue.issueResolution['howDidWeResolveThisIssue']?.value : null,
        "issueOwnerCostView": "",
        "isHVDCComplaint": allFormValue.additionalSubject['hiddenIsHVDCComplaint']?.value ? allFormValue.additionalSubject['hiddenIsHVDCComplaint']?.value : false,
        "manualChangeClass": allFormValue.issueResolution['manualChangeClass']?.value ? allFormValue.issueResolution['manualChangeClass']?.value : null,
        "qNumberResolvingUnit": allFormValue.issueResolution['QNumberResolvingUnit']?.value ? allFormValue.issueResolution['QNumberResolvingUnit']?.value : null,
        "estimatedCost": allFormValue.issueResolution['EstimatedCostView']?.value ? +allFormValue.issueResolution['EstimatedCostView']?.value.replace(/\,/g,'') : null,
        "sectionCategorisation": {
          "copqCategory": allFormValue.issueResolution['COPQCategoryAllData']?.value ? allFormValue.issueResolution['COPQCategoryAllData']?.value : null,
          "pgCategory": allFormValue.issueResolution['PGRCACategoryAllData']?.value ? allFormValue.issueResolution['PGRCACategoryAllData']?.value : null
        },
        "uploadRCAAttachments": allFormValue.issueResolution['UploadRCAAttachments']?.value ? allFormValue.issueResolution['UploadRCAAttachments']?.value : [],
        "resolutionAttachments": allFormValue.issueResolution['resolutionAttachments']?.value ? allFormValue.issueResolution['resolutionAttachments']?.value : [],
        "reviewRequired":allFormValue.issueResolution['ReviewRequired']?.value ? allFormValue.issueResolution['ReviewRequired']?.value : '',
        "reviewRequiredJustification": allFormValue.issueResolution['reviewRequiredJustification']?.value ? allFormValue.issueResolution['reviewRequiredJustification']?.value : null,
        "eightDReportRequired":allFormValue.issueResolution['eightDReportRequired']?.value ? allFormValue.issueResolution['eightDReportRequired']?.value : 'Yes',
        "eightDJustificationCurrent":allFormValue.issueResolution['EightDJustificationCurrent']?.value ? allFormValue.issueResolution['EightDJustificationCurrent']?.value : '',
        "justificationCurrent": allFormValue.issueResolution['JustificationCurrent']?.value ? allFormValue.issueResolution['JustificationCurrent']?.value : null,
        // "justificationCurrent": allFormValue.issueResolution['justificationCurrent']?.value ? allFormValue.issueResolution['justificationCurrent']?.value : null,
        "customerFeedbackRequired": allFormValue.issueClosed['sendFeedback']?.value,
        "customerFeedbackJustification": allFormValue.issueClosed['issueClosedJustification']?.value ? allFormValue.issueClosed['issueClosedJustification']?.value : null,
      },
      "areaAcceptance": {
        "awaitingCustomerExecution": allFormValue.issueClosed['AwaitingCustomerExecution']?.value ? allFormValue.issueClosed['AwaitingCustomerExecution']?.value : false,
        "expectedExecutionDate": allFormValue.issueClosed['ExpectedExecutionHiddenDate']?.value ?  allFormValue.issueClosed['ExpectedExecutionHiddenDate']?.value :null,
        "customerFeedback": allFormValue.issueClosed['customerComments']?.value ? allFormValue.issueClosed['customerComments']?.value : '',
        "customerContactStatus":allFormValue.issueClosed['customerContactStatus']?.value ? allFormValue.issueClosed['customerContactStatus']?.value : null,
        "expectedExecutionDateInitialDate":allFormValue.issueClosed['']?.value ? allFormValue.issueClosed['']?.value : null,
        "acceptanceAttachments":allFormValue.issueClosed['uploadedAcceptanceAttachments']?.value ? allFormValue.issueClosed['uploadedAcceptanceAttachments']?.value : [],
        "customerFeedbackRequired": allFormValue.issueClosed['sendFeedback']?.value ? allFormValue.issueClosed['sendFeedback']?.value?.toString() : null,
        "customerFeedbackJustification": allFormValue.issueClosed['issueClosedJustification']?.value ? allFormValue.issueClosed['issueClosedJustification']?.value : null,
      },
      "areaComplaintEffectiveness": {
        "attachments":allFormValue.effectiveNess['uploadedEffectivenessAttachments']?.value ? allFormValue.effectiveNess['uploadedEffectivenessAttachments']?.value : [],
        "id": allFormValue.effectiveNess['id']?.value ? allFormValue.effectiveNess['id']?.value : 0,
        "complaintId": allFormValue?.id ? allFormValue?.id : 0,
        "confirmDate": new Date(),
        "displayName":null,
        "effectivenessReason": allFormValue.effectiveNess['EffectivenessReason']?.value ? allFormValue.effectiveNess['EffectivenessReason']?.value : '',
        "isActive":true,
        "reopenReason":allFormValue.effectiveNess['reopenReason']?.value ? allFormValue.effectiveNess['reopenReason']?.value : '',
        "deadlineDate": allFormValue.effectiveNess['DeadlineDate']?.value ? allFormValue.effectiveNess['DeadlineDate']?.value : '',
        "lowestPossibleDeadlineDate": allFormValue.effectiveNess['DeadlineDate']?.value ? allFormValue.effectiveNess['DeadlineDate']?.value : '',
        "isEffective": this.checkEffectiveness(allFormValue.effectiveNess['IsEffective']?.value ? allFormValue.effectiveNess['IsEffective']?.value : ''),
        "isReadOnly": true,
        "isThirdOptionAvailable": false
      },
      "survey": {
        "surveyStatus": allFormValue.issueClosed['hiddenClosureConfirmationStatusCode']?.value ? allFormValue.issueClosed['hiddenClosureConfirmationStatusCode']?.value : 1,
        "surveyResult": allFormValue.issueClosed['customerResponse']?.value ? allFormValue.issueClosed['customerResponse']?.value : null
      },
      "isPopupAboutSurveyShouldShow": false,
      "sapDefectCause": allFormValue.additionalSubject['isQnNumberGeneratedCheck']?.value ? {
        "id": allFormValue.additionalSubject['sapDefectCauseIdAddnSub']?.value ? allFormValue.additionalSubject['sapDefectCauseIdAddnSub']?.value : null,
        "defectcausecodegroup": allFormValue.additionalSubject['defectCauseHiddenFieldAdnSub']?.value?.parentId ? allFormValue.additionalSubject['defectCauseHiddenFieldAdnSub']?.value?.parentId : null,
        "codegroupdefecttype": allFormValue.additionalSubject['defectTypeHiddenField']?.value?.parentId? allFormValue.additionalSubject['defectTypeHiddenField']?.value?.parentId: null,
        "defectCause": allFormValue.additionalSubject['defectCauseHiddenFieldAdnSub']?.value?.id ? allFormValue.additionalSubject['defectCauseHiddenFieldAdnSub']?.value?.id : null,
        "defectType": allFormValue.additionalSubject['defectTypeHiddenField']?.value?.id ? allFormValue.additionalSubject['defectTypeHiddenField']?.value?.id : null,
        "DefectTypeandDefectCodeGroupType": allFormValue.additionalSubject['sapDefectCauseId']?.value?.DefectTypeandDefectCodeGroupType? allFormValue.additionalSubject['sapDefectCauseId']?.value?.DefectTypeandDefectCodeGroupType : null,
        "DefectCauseDefectCauseCodeGroup": allFormValue.additionalSubject['sapDefectCauseId']?.value?.DefectCauseDefectCauseCodeGroup ? allFormValue.additionalSubject['sapDefectCauseId']?.value?.DefectCauseDefectCauseCodeGroup : null,
        "causeCodeGroupText": allFormValue.additionalSubject['defectCauseHiddenFieldAdnSub']?.value?.parentName ? allFormValue.additionalSubject['defectCauseHiddenFieldAdnSub']?.value?.parentName : null,
        "defectCodeGroupText": allFormValue.additionalSubject['defectTypeHiddenField']?.value?.parentName? allFormValue.additionalSubject['defectTypeHiddenField']?.value?.parentName : null,
        "defectTypeText": allFormValue.additionalSubject['defectTypeHiddenField']?.value?.name? allFormValue.additionalSubject['defectTypeHiddenField']?.value?.name: null,
        "defectCauseTextField": allFormValue.additionalSubject['defectCauseHiddenFieldAdnSub']?.value?.name ? allFormValue.additionalSubject['defectCauseHiddenFieldAdnSub']?.value?.name: null,
        "buCode": allFormValue.additionalSubject['defectCauseHiddenFieldAdnSub']?.value?.buCode ? allFormValue.additionalSubject['defectCauseHiddenFieldAdnSub']?.value?.buCode : null
      } : null,
      "sapComplaintInfo": allFormValue.additionalSubject['isQnNumberGeneratedCheck']?.value ? {
        "id": allFormValue.additionalSubject['sapComplaintInfoDetails']?.value?.id? allFormValue.additionalSubject['sapComplaintInfoDetails']?.value?.id: null,
        "qnNumber": allFormValue.additionalSubject['sapComplaintInfoDetails']?.value?.qnNumber? allFormValue.additionalSubject['sapComplaintInfoDetails']?.value?.qnNumber : null,
        "isQnNumberGenerated": allFormValue.additionalSubject['sapComplaintInfoDetails']?.value?.isQnNumberGenerated? allFormValue.additionalSubject['sapComplaintInfoDetails']?.value?.isQnNumberGenerated : false,
        "materialPlant": null,
        "projectNumber": allFormValue.additionalSubject['abbProjectNumber']?.value? allFormValue.additionalSubject['abbProjectNumber']?.value: null ,
        "projectName": allFormValue.additionalSubject['abbProjectName']?.value? allFormValue.additionalSubject['abbProjectName']?.value: null,
        "actualCost": "0",
        "copqCode": null,
        "copqCodeGroup": null,
        "sapContactId": null,
        "workCentre": allFormValue.issueDescription['workCentreHidden']?.value?.workcentre? allFormValue.issueDescription['workCentreHidden']?.value?.workcentre : "",
        "plant": allFormValue.issueDescription['workCentreHidden']?.value?.plant? allFormValue.issueDescription['workCentreHidden']?.value?.plant : null,
        "isActive": false,
        "WorkCentreandPlant": {
            "identification": null,
            "displayLabel": allFormValue.issueDescription['workCentreHidden']?.value?.workcentre? allFormValue.issueDescription['workCentreHidden']?.value?.workcentre : "",
            "tooltipInfo": null,
            "isValid": true
        },
        "sapLockStatus": allFormValue.additionalSubject['sapLockStatus']?.value
    }: null,
      //"whereWasTheIssueDetectedId": allFormValue.additionalSubject['defectWorkCenterHidden']?.value?.whereWasTheIssueDetectedId ? allFormValue.additionalSubject['defectWorkCenterHidden']?.value?.whereWasTheIssueDetectedId : null,
      "whereWasTheIssueDetectedId": allFormValue.issueDescription['whereWasTheIssueDetectedId']?.value ? allFormValue.issueDescription['whereWasTheIssueDetectedId']?.value : null,
      "isProMIS": false,
      "HiddenIsHVDCComplaint": allFormValue.additionalSubject['hiddenIsHVDCComplaint']?.value ? allFormValue.additionalSubject['hiddenIsHVDCComplaint']?.value : false,
      "hiddenPreviousClass":  allFormValue.additionalSubject['hiddenPreviousClass']?.value ?  allFormValue.additionalSubject['hiddenPreviousClass']?.value : null,
      "manualClassUpgrade": allFormValue.issueResolution['manualClassUpgrade']?.value ? allFormValue.issueResolution['manualClassUpgrade']?.value : '',
      "unAssignClassConfirmation": allFormValue.issueResolution['unAssignClassConfirmation']?.value ? allFormValue.issueResolution['unAssignClassConfirmation']?.value : null,
      "modifiedSerialNoDPS": allFormValue.additionalSubject['BuCode']?.value! == 'PGHV' && allFormValue.additionalSubject['modificationFlagForDps']?.value == true  ? {
        "ccrpAppId":allFormValue.additionalSubject['dpsCCRPAppId']?.value ?  allFormValue.additionalSubject['dpsCCRPAppId']?.value : null,
        "emailId":allFormValue.additionalSubject['dpsEmailIdOfUser']?.value ?  allFormValue.additionalSubject['dpsEmailIdOfUser']?.value : null,
        "ccrpDetails":this.modifiedSerialsArrayData(allFormValue?.headerSection['complaintNumber']?.value ? allFormValue?.headerSection['complaintNumber']?.value : null,allFormValue.additionalSubject['oldSerialNosList']?.value ? allFormValue.additionalSubject['oldSerialNosList']?.value : [],allFormValue.additionalSubject['updatedSerialNosList']?.value ? allFormValue.additionalSubject['updatedSerialNosList']?.value : [],allFormValue.additionalSubject['articleNumber']?.value ? allFormValue.additionalSubject['articleNumber']?.value :'',allFormValue.additionalSubject['supplierData']?.value ? allFormValue.additionalSubject['supplierData']?.value?.identifier : '' )
        } : null
    }
  }

  getComplaintActivityDates(geid:string,ccrpnumber:string) {
    let params = new HttpParams()
    .set('userGeid',geid)
    .set('complaintNumber', ccrpnumber);

    return this.http.get<any>(`${environment.azure.baseUrl}api/v1/messages/complaintdates`,{params});
  }

  lockSolutionWillBeValidatedByCoordinator(hasHighImpact=false, checkRCA=false, matrixClass = 0, currentValue) {
    if (hasHighImpact || checkRCA || matrixClass == 1 || matrixClass == 2 || matrixClass == 3) {
      return true;
    }
    else{
      return currentValue ? currentValue : false;
    }
  }

  modifiedSerialsArrayData(ccrpNo:any = null,oldSerialList:any,latestSerialList:any,articleNumber:any,supplierguId:any) {
    let modifiedNewArray = Array.from(new Set(latestSerialList.concat(oldSerialList)));
    let finalArray = [];

    modifiedNewArray.forEach(
      item => {
        finalArray.push({
          "ccrpNumber": ccrpNo,
          "serialNumber": item,
          "productionGroupID":"",
          "articleNumber":articleNumber,
          "supplierguId":supplierguId,
          "isDeleted": latestSerialList.includes(item) ? 0 : 1
        })
      });
    return finalArray;
  }


}
