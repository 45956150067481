import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FailuremodeService {

  constructor(private http: HttpClient) { }

  loadTree(pgCode) {
    return this.http.get<any>(`${environment.azure.baseUrl}api/v1/failuremode/pg/${pgCode}`);
  }

  deactivate(treeItem, pgCode) {
    return this.http.patch(`${environment.azure.baseUrl}api/v1/failuremode/pg/${pgCode}`, treeItem);
  }

  save(treeItem, pgCode) {
    return this.http.put(`${environment.azure.baseUrl}api/v1/failuremode/pg/${pgCode}`, treeItem);
  }

  addItem(treeItem, pgCode) {
    return this.http.post(`${environment.azure.baseUrl}api/v1/failuremode/pg/${pgCode}`, treeItem)
  }

  getChildren(pgCode, nodeId) {
    return this.http.get<any>(`${environment.azure.baseUrl}api/v1/failuremode/pg/${pgCode}/${nodeId}`);
  }
}
