import { Component, OnInit } from '@angular/core';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { TransalationAuthService } from '../transalation-auth.service';
import { MessageRecipientsService } from '../message-recipients.service';
import { TransalationserviceService } from '../transalationservice.service';
import { ToasterserviceService } from 'src/app/services/toasterservice.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-translation-message-templatedit',
  templateUrl: './translation-message-templatedit.component.html',
  styleUrls: ['./translation-message-templatedit.component.css']
})
export class TranslationMessageTemplateditComponent implements OnInit {

  isDisabled: boolean = false;
  message: any;
  canEditRecipients: boolean;
  msgTemplateForm!: FormGroup;


  constructor(private readonly dialogRef: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private translationAuthService: TransalationAuthService,
    private messageRecipientsService: MessageRecipientsService,
    private tansalationService: TransalationserviceService,
    private toaster:ToasterserviceService,
    private fb: FormBuilder) {
    this.message = this.config.data;
  }

  ngOnInit(): void {
    this.canEditRecipients = this.translationAuthService.canEditMessageRecipients();
    this.messageRecipientsService.getDefaultMessageRecipients()
      .then((data) => {
        this.message.allRecipients = data;
      }, (error) => {

      });

    this.msgTemplateForm = this.fb.group({
        subject: ['',[Validators.required,Validators.maxLength(170)]],
        body:['',Validators.required],
        toRecipients:[],
        ccRecipients:[]
    });

    // console.log(this.message);

    this.msgTemplateForm.patchValue({
      subject: this.message.subject,
      body: this.message.body

    })

  }

  closeModal() {
    this.dialogRef.close();
  };

  isBodyInvalid() {
    let msg = this.message.body.replace(/<\/?[^>]+(>|$)/g, "").trim();
    return !msg.length;
  }

  shouldShowEngTranslation() {
    return this.message.culture !== this.message.engTranslation.culture;
}

checkBlank(content) {
  if (content == undefined || content == null) {
    return null;
  } else {
    return content;
  }
}

update() {
  this.isDisabled = true;
  this.message.subject = this.checkBlank(this.msgTemplateForm.controls['subject'].value);
  this.message.body = this.checkBlank(this.msgTemplateForm.controls['body'].value);
  this.message.defaultRecipients = this.checkBlank(this.msgTemplateForm.controls['toRecipients'].value);
  this.message.defaultCC = this.checkBlank(this.msgTemplateForm.controls['ccRecipients'].value);
  this.tansalationService.addOrUpdateMessageTemplate(this.message)
      .subscribe((data:any) => {
      if (this.isDisabled) {
          if (data.success) {
              this.isDisabled = false;
              this.toaster.showSuccess('successfully saved message');
              this.dialogRef.close({
                  result: 'ok',
                  model: this.message
              });
          } else {
              this.isDisabled = false;
              this.dialogRef.close({
                  result: 'failure',
                  msg: data.failureMsg
              });
              this.toaster.showError(data.failureMsg);
          }
      }
  }, (error) => {

  });
};

}
