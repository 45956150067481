<button
  type="button"
  class="mdc-icon-button user-profile-info mdc-ripple-surface--primary clear-white-space-border ng-scope"
  style="
    cursor: pointer;
    border-radius: 90%;
    width: 40px;
    height: 40px;
    background: black;
    color: white;
    font-size: 18px;
  "
  [autoClose]="true"
  (resize)="(true)"
  container="body"
  [ngbPopover]="popContent"
  [popoverTitle]="popoverTitle"
  [placement]="'bottom-right'">
  <span>{{ this.userData?.employeeDetails?.firstName?.substring(0, 1)}}{{ this.userData?.employeeDetails?.lastName?.substring(0, 1) }}</span>
</button>

<ng-template #popoverTitle>
  <span class="popover-title">
    {{ userData?.employeeDetails?.name }} ({{ userData?.geid }})
  </span>
</ng-template>

<ng-template #popContent style="position: relative">
  <div class="popover-content">
    {{ userData?.employeeDetails?.address.countryCode }},{{userData?.employeeDetails?.address.city}},{{ userData?.employeeDetails?.address.address }}
    <span
      ><a
        href="https://maps.google.pl/?q={{ userData?.employeeDetails?.address.countryCode}}, {{ userData?.employeeDetails?.address.city }}, {{userData?.employeeDetails?.address.address}}"
        target="_blank"
        class="gray-link">
        <i class="hitachiicon-location"> </i> </a
    ></span>
    <br />
    <small>
      <i class="glyphicon-mail"></i><a href="mailto:{{ userData?.email }}" class="admin-icon-a">{{userData?.email}}</a>
      <br />
      <i *ngIf="userData?.employeeDetails?.phone !== null && userData?.employeeDetails?.phone !== ''" class="fa-solid fa-phone"></i><a *ngIf="userData?.employeeDetails?.phone !== null && userData?.employeeDetails?.phone !== ''" href="tel:{{userData?.employeeDetails?.phone}}" class="admin-icon-a">{{userData?.employeeDetails?.phone}}</a>
    </small>
    <br *ngIf="userData?.employeeDetails?.phone !== null && userData?.employeeDetails?.phone !== ''">
    <hr *ngIf="userData?.isResourceEditor || userData?.isSupportDeskMember || userData?.isGlobalCcrpProcOwner || userData?.isManagementRole || userData?.isRuProcessOwner || userData?.hubProcessOwnerScope.length > 0 || userData?.processOwnerScopes.length > 0" style="margin-top: 5px; margin-bottom: 0px;">
    <p *ngIf="userData?.isResourceEditor || userData?.isSupportDeskMember || userData?.isGlobalCcrpProcOwner || userData?.isManagementRole || userData?.isRuProcessOwner || userData?.hubProcessOwnerScope.length > 0 || userData?.processOwnerScopes.length > 0" style="font-weight:bold; margin-bottom: 5px;">
        <label>Roles in system</label>
        <ul style="font-weight:bold; margin-bottom: 5px;">
            <li *ngIf="userData?.isResourceEditor"><label>Resource translator</label></li>
            <li *ngIf="userData?.isSupportDeskMember"><label>Support desk</label></li>
            <li *ngIf="userData?.isGlobalCcrpProcOwner"><label>Global CCRP Process Owner</label></li>
            <li *ngIf="userData?.isManagementRole"><label>Management Role</label></li>
            <li *ngIf="(userData?.processOwnerScopes | filter: 'unit' : true).length > 0">
                <label>Process owner</label>
                <ul class="list-unstyled" style="font-weight: 600;">
                    <li *ngFor="let u of userData?.processOwnerScopes | filter: 'unit' : true | order : ['div', 'bu', 'pg']">
                        <small>{{u.div}} {{u.bu}} {{u.pg}} {{u.region}} {{u.countryName}}</small>
                    </li>
                </ul>
            </li>
            <li *ngIf="(userData?.processOwnerScopes | filter : 'location' :true).length > 0">
                <label>Region/Country Process owner</label>
                <ul class="list-unstyled">
                    <li *ngFor="let l of userData?.processOwnerScopes | filter : 'location' :true | order: ['region','country']">
                        <small> {{l.region}} {{l.countryName}}</small>
                    </li>
                </ul>
            </li>
            <li *ngIf="userData?.isRuProcessOwner"><label>RU process owner</label></li>
            <li *ngIf="userData?.hubProcessOwnerScope.length > 0"><label>Hub process owner</label></li>
        </ul>

  </div>
</ng-template>
