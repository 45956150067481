<div class="issue-assigment-section ">
  <form [formGroup]="issueAssignment">

    <div class="row forROTextBox">
      <div class="col-md-2 col-sm-3 ps-0 pe-0 styleRO">
        <label>{{ 'Roles_ResolutionOwner' | translate }}<span class="asteriskRequired"></span></label>
      </div>
      <div class="multiselect-field_resolutionOwner col-md-8 col-sm-9">
        <p-autoComplete [forceSelection]="true" [pTooltip]="resoultionOwnerText" [escape]="false" tooltipPosition="top"
          tooltipStyleClass="toolTipClass" class="resolutionOwnerCustom border-0 text-width1 mt-2"
          [ngClass]="{'error-autocomplete' : (isSubmitted && this.issueAssignment.controls['PrimaryResolutionOwner'].invalid) , 'readonlyclass' : readonlyObj?.PrimaryResolutionOwnerReadOnly}"
          formControlName="PrimaryResolutionOwner" [multiple]="true" type="text" [suggestions]="filteredRecord"
          [required]="true" (completeMethod)="onRecipientChange($event)" field="selectedLabel"
          [readonly]="this.issueAssignment.controls['PrimaryResolutionOwner'].value?.length==1 || readonlyObj?.PrimaryResolutionOwnerReadOnly ? true : false">
          <ng-template let-filteredRecord pTemplate="selectedItem">
            <div (mouseover)="resolutionOwnerGotFocus(filteredRecord.tooltipInfo)"
              (mouseout)="resolutionOwnerLostFocus()">
              {{filteredRecord.selectedLabel }}</div>
            <span *ngIf="!readonlyObj?.PrimaryResolutionOwnerReadOnly" class="p-autocomplete-token-icon pi pi-times-circle ng-tns-c68-7 ng-star-inserted"
              (click)="clearOwner()"></span>
          </ng-template>
          <ng-template let-filteredRecord pTemplate="item">
            <div class="flex align-items-center gap-2">
              <div>{{ filteredRecord.promptLabel }}</div>
            </div>
          </ng-template>
        </p-autoComplete>
      </div>
    </div>

    <div class="row forROTTextBox">
      <div class="col-md-2 col-sm-3 ps-0 pe-0 ">
        <label>{{ 'Roles_ResolutionOwnerTeam' | translate }}
          <span class="asteriskRequired" *ngIf="this.issueAssignment.controls['resolutionOwnerTeam']?.validator"></span>
        </label>
      </div>
      <div class="multiselect-field_resolutionOwnerTeam col-md-8 col-sm-9">
        <p-autoComplete formControlName="resolutionOwnerTeam" [forceSelection]="true" [showClear]="true"
          class="border-0 resolutionOwnerTeamCustom text-width2" [pTooltip]="resoultionOwnerTeamText" [escape]="false"
          [ngClass]="{'error-autocomplete' : isSubmitted && this.issueAssignment.controls['resolutionOwnerTeam'].invalid , 'readonlyclass' : readonlyObj?.resolutionOwnerTeamReadOnly}"
          tooltipPosition="top" tooltipStyleClass="toolTipClass" type="email" [suggestions]="filteredRecordTeam"
          (completeMethod)="onRecipientTeamChange($event)" field="selectedLabel" [multiple]="true" [readonly]="readonlyObj?.resolutionOwnerTeamReadOnly"
          >
          <ng-template let-filteredRecordTeam pTemplate="item">
            <div class="flex align-items-center gap-2">
              <div>{{ filteredRecordTeam?.promptLabel }}</div>
            </div>
          </ng-template>
          <ng-template let-filteredRecordTeam pTemplate="selectedItem">
            <div (mouseover)="resolutionOwnerTeamGotFocus(filteredRecordTeam.tooltipInfo)"
              (mouseout)="resolutionOwnerTeamLostFocus()">
              {{filteredRecordTeam?.selectedLabel }}</div>
          </ng-template>
        </p-autoComplete>
      </div>
    </div>
    <div>
      <div class="issue-type radio-style">
        <div [pTooltip]="createRCAToolTipInfo" tooltipPosition="top" tooltipStyleClass="toolTipClass">
          <input type="radio" class="widgetRadioButton" value='0' (change)="variantChange()" formControlName="quickFixVariant">
          <span>{{ 'rptv_create_rca' | translate }} </span>
        </div>
      </div>

      <div class="issue-type">
        <div [pTooltip]="linkToIssueToolTipInfo" tooltipPosition="top" tooltipStyleClass="toolTipClass">
          <input type="radio" class="widgetRadioButton" value='1' (change)="variantChange()" formControlName="quickFixVariant">
          <span> {{ 'rptv_link_with_same_rca' | translate }} </span>
          <span class="asteriskRequired" *ngIf="this.issueAssignment.controls['quickFixComplaint']?.validator"></span>
        </div>

        <div class="spaceRCAToInputBox">
          <input type="hidden" formControlName="quickFixComplaint">
          <input class="inputBoxRCA" [pTooltip]="textBoxToolTipInfo" tooltipPosition="top"
            tooltipStyleClass="toolTipClass" type="text" formControlName="ccrpNo" readonly="true"
            [ngClass]="{'error-inputbox' : (isSubmitted && this.issueAssignment.controls['quickFixComplaint'].invalid)}">
          <button class="col-sm-1 ms-2 mt-2" (click)="showIssueSelectionPopup()"
            [ngClass]="!disableBtn['ccrpNo']?'grayButton': ''" [disabled]="!disableBtn['ccrpNo']"
            [disabled]="disabledActions()"
            [ngClass]="this.issueAssignment.controls['quickFixVariant'].value != '1' ? 'widgetButtoSmall grayButton' : 'widgetButtoSmall'">
            {{this.issueAssignment.controls['ccrpNo'].value ? "Change" : "Select"}}</button>
        </div>
      </div>
    </div>
    <!-- *ngIf="radioCheckBtnEnable || classDetails" -->
    <div
      *ngIf="!complaintData?.areaComplaintCollection?.complaintInformationSection?.highImpact?.hasHightImpactOnABB && !complaintData?.areaComplaintResolution?.isEightDRCARequiredCurrent">
      <div class="issue-type" [pTooltip]="actionsNotRequiredToolTipInfo" tooltipPosition="top"
        tooltipStyleClass="toolTipClass" style="padding-bottom: 15px;">
        <input type="radio" class="widgetRadioButton" value='2' (change)="variantChange()" formControlName="quickFixVariant">
        <span> {{ 'rptv_rca_not_required' | translate }} </span>
      </div>
    </div>
    <!-- *ngIf="radioCheckBtnEnable || classDetails" -->
    <div class="select-checkbox" *ngIf="solutionWillBeValidatedByCoordinatorSection">
        <div class="check-beforelabel">
          <input class="form-check-input"
            [attr.disabled]="this.complaintData?.areaComplaintAssignment?.solutionWillBeValidatedByCoordinator"
            formControlName="solutionWillBeValidatedByCoordinator" type="checkbox"
            [ngClass]="isSubmitted && this.issueAssignment.controls['solutionWillBeValidatedByCoordinator'].invalid ? 'error-inputbox' : ''"
            [pTooltip]="solutionToolTipInfo" tooltipPosition="top" tooltipStyleClass="toolTipClass" />&nbsp;
          <span class="solution_text" [pTooltip]="solutionToolTipInfo" tooltipPosition="top" tooltipStyleClass="toolTipClass">{{
            'Complaint_SolutionWillBeValidatedByCoordinator' | translate }}
          </span>
        </div>
        <div class="row multiselect-field_remove issue-coordinator">
          <p-autoComplete [forceSelection]="true" formControlName="coordinatorList"
            class="form-control coordinator-email border-0 readonlyclass" [showClear]="false" [pTooltip]="issueCoordinatorText"
            [escape]="false" tooltipPosition="top"
            [ngClass]="{'error-autocomplete' : isSubmitted && this.issueAssignment.controls['coordinatorList'].invalid }"
            tooltipStyleClass="toolTipClass" field="selectedLabel" [multiple]="true" [readonly]="true"
            [suggestions]="coordinators">
            <ng-template let-coordinators pTemplate="selectedItem">
              <div (mouseover)="coordinatorGotFocus(coordinators)" (mouseout)="coordinatorLostFocus()">
                {{coordinators?.selectedLabel }}</div>
            </ng-template>
          </p-autoComplete>
        </div>
      </div>
    <div *ngIf="classDetails" class="row addPersonTextBox mt-2">
      <span class="nominated-person ms-0">{{ 'Complaint_AccountableVerifiedUsers' | translate }}
        <span class="asteriskRequired" *ngIf="this.issueAssignment.controls['AddNominatedPerson']?.validator"></span>
        <i [pTooltip]="addPersonToolTipInfo" [escape]="false" tooltipPosition="top" tooltipStyleClass="toolTipClass"
          class="pi pi-info-circle ms-1 infoIcon iCon">
        </i>
      </span>
      <div class="multiselect-field_AddPerson">
        <p-autoComplete [forceSelection]="true" formControlName="AddNominatedPerson"
          [ngClass]="{'error-autocomplete' : isSubmitted && this.issueAssignment.controls['AddNominatedPerson'].invalid , 'readonlyclass' : readonlyObj?.AddNominatedPersonReadOnly}"
          class="border-0 addTextBoxCustom " [pTooltip]="substituteText" [escape]="false" tooltipPosition="top"
          tooltipStyleClass="toolTipClass" [suggestions]="filteredRecordNominatedPerson" (completeMethod)="onRecipientChangeNominatedPerson($event)"
          field="selectedLabel" [multiple]="true" [readonly]="readonlyObj?.AddNominatedPersonReadOnly">
          <ng-template let-filteredRecordNominatedPerson pTemplate="item">
            <div class="flex align-items-center gap-2">
              <div>{{ filteredRecordNominatedPerson?.promptLabel }}</div>
            </div>
          </ng-template>
          <ng-template let-filteredRecordNominatedPerson pTemplate="selectedItem">
            <div (mouseover)="addNominatedPersonGotFocus(filteredRecordNominatedPerson.tooltipInfo)"
              (mouseout)="addNominatedPersonLostFocus()">
              {{filteredRecordNominatedPerson?.selectedLabel }}</div>
          </ng-template>
        </p-autoComplete>
      </div><br>
    </div>

    <div class="btn-container btnspace-mt">
      <button class="col-sm-1 widgetButtoSmall ms-2 mt-2" [pTooltip]="saveBtnToolTipInfo" tooltipPosition="top"
        *ngIf="setButtonVisibility([3,4,5,6,7,8,9,10,11,12,13])" [disabled]="setButtonVisibility([8,13])"
        [ngClass]="!disableBtn['saveBtn_issueAssign']?'grayButton': ''" [disabled]="!disableBtn['saveBtn_issueAssign']"
        [ngClass]="setButtonVisibility([8,13] ) == true || !disableBtn['saveBtn_issueAssign'] ? 'widgetButtonMedium grayButton' : 'widgetButtonMedium'"
        tooltipStyleClass="toolTipClass" (click)="onSubmit($event)">{{ 'ButtonSave' | translate }}</button>
      <button class="col-sm-2 widgetButtonLarge ms-2 mt-2" [pTooltip]="returnToIssueOwnerBtnToolTipInfo"
        *ngIf="setButtonVisibility([3])" [ngClass]="!disableBtn['returnCO_issueAssign']?'grayButton': ''"
        [disabled]="!disableBtn['returnCO_issueAssign']" tooltipPosition="top" tooltipStyleClass="toolTipClass"
        (click)="showEmailTemplatePopup($event)">
        {{ "ButtonReturnToCO" | translate }}</button>
      <button class="col-sm-1 widgetButtoSmall ms-2 mt-2"
        [ngClass]="!disableBtn['assignBtn_issueAssign'] || isResolvingUnitModified ?'grayButton': ''"
        [disabled]="!disableBtn['assignBtn_issueAssign'] || isResolvingUnitModified" [pTooltip]="assignBtnTipInfo" tooltipPosition="top"
        *ngIf="setButtonVisibility([3])" tooltipStyleClass="toolTipClass-assign" (click)="onAssign($event)">{{
        'ButtonAssign' | translate }}</button>
    </div>

  </form>
</div>
