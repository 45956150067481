import * as i0 from '@angular/core';
import { EventEmitter, Component, ChangeDetectionStrategy, ViewEncapsulation, Input, Output, ContentChildren, NgModule } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import * as i1 from '@angular/common';
import { CommonModule } from '@angular/common';
import { PrimeTemplate, SharedModule } from 'primeng/api';
import * as i2 from 'primeng/ripple';
import { RippleModule } from 'primeng/ripple';
import { PlusIcon } from 'primeng/icons/plus';
import { MinusIcon } from 'primeng/icons/minus';
const _c0 = ["*", [["p-header"]]];
const _c1 = ["*", "p-header"];
const _c2 = (a0, a1) => ({
  "p-fieldset p-component": true,
  "p-fieldset-toggleable": a0,
  "p-fieldset-expanded": a1
});
const _c3 = a0 => ({
  transitionParams: a0,
  height: "0"
});
const _c4 = a0 => ({
  value: "hidden",
  params: a0
});
const _c5 = a0 => ({
  transitionParams: a0,
  height: "*"
});
const _c6 = a0 => ({
  value: "visible",
  params: a0
});
function Fieldset_ng_container_2_ng_container_2_PlusIcon_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "PlusIcon", 11);
  }
  if (rf & 2) {
    i0.ɵɵproperty("styleClass", "p-fieldset-toggler");
  }
}
function Fieldset_ng_container_2_ng_container_2_span_2_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function Fieldset_ng_container_2_ng_container_2_span_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span", 12);
    i0.ɵɵtemplate(1, Fieldset_ng_container_2_ng_container_2_span_2_ng_container_1_Template, 1, 0, "ng-container", 6);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext(3);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r2.expandIconTemplate);
  }
}
function Fieldset_ng_container_2_ng_container_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, Fieldset_ng_container_2_ng_container_2_PlusIcon_1_Template, 1, 1, "PlusIcon", 9)(2, Fieldset_ng_container_2_ng_container_2_span_2_Template, 2, 1, "span", 10);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !ctx_r2.expandIconTemplate);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r2.expandIconTemplate);
  }
}
function Fieldset_ng_container_2_ng_container_3_MinusIcon_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "MinusIcon", 11);
  }
  if (rf & 2) {
    i0.ɵɵproperty("styleClass", "p-fieldset-toggler");
  }
}
function Fieldset_ng_container_2_ng_container_3_span_2_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function Fieldset_ng_container_2_ng_container_3_span_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span", 12);
    i0.ɵɵtemplate(1, Fieldset_ng_container_2_ng_container_3_span_2_ng_container_1_Template, 1, 0, "ng-container", 6);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext(3);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r2.collapseIconTemplate);
  }
}
function Fieldset_ng_container_2_ng_container_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, Fieldset_ng_container_2_ng_container_3_MinusIcon_1_Template, 1, 1, "MinusIcon", 9)(2, Fieldset_ng_container_2_ng_container_3_span_2_Template, 2, 1, "span", 10);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !ctx_r2.collapseIconTemplate);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r2.collapseIconTemplate);
  }
}
function Fieldset_ng_container_2_ng_container_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function Fieldset_ng_container_2_Template(rf, ctx) {
  if (rf & 1) {
    const _r2 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵelementStart(1, "a", 7);
    i0.ɵɵlistener("click", function Fieldset_ng_container_2_Template_a_click_1_listener($event) {
      i0.ɵɵrestoreView(_r2);
      const ctx_r2 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r2.toggle($event));
    })("keydown.enter", function Fieldset_ng_container_2_Template_a_keydown_enter_1_listener($event) {
      i0.ɵɵrestoreView(_r2);
      const ctx_r2 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r2.toggle($event));
    });
    i0.ɵɵtemplate(2, Fieldset_ng_container_2_ng_container_2_Template, 3, 2, "ng-container", 8)(3, Fieldset_ng_container_2_ng_container_3_Template, 3, 2, "ng-container", 8)(4, Fieldset_ng_container_2_ng_container_4_Template, 1, 0, "ng-container", 6);
    i0.ɵɵelementEnd();
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext();
    const legendContent_r4 = i0.ɵɵreference(4);
    i0.ɵɵadvance();
    i0.ɵɵattribute("aria-controls", ctx_r2.id + "-content")("aria-expanded", !ctx_r2.collapsed);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r2.collapsed);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !ctx_r2.collapsed);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", legendContent_r4);
  }
}
function Fieldset_ng_template_3_ng_container_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function Fieldset_ng_template_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span", 13);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
    i0.ɵɵprojection(2, 1);
    i0.ɵɵtemplate(3, Fieldset_ng_template_3_ng_container_3_Template, 1, 0, "ng-container", 6);
  }
  if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(ctx_r2.legend);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r2.headerTemplate);
  }
}
function Fieldset_ng_container_8_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
let idx = 0;
class Fieldset {
  constructor(el) {
    this.el = el;
    this.collapsed = false;
    this.collapsedChange = new EventEmitter();
    this.onBeforeToggle = new EventEmitter();
    this.onAfterToggle = new EventEmitter();
    this.transitionOptions = '400ms cubic-bezier(0.86, 0, 0.07, 1)';
    this.id = `p-fieldset-${idx++}`;
  }
  ngAfterContentInit() {
    this.templates.forEach(item => {
      switch (item.getType()) {
        case 'header':
          this.headerTemplate = item.template;
          break;
        case 'expandicon':
          this.expandIconTemplate = item.template;
          break;
        case 'collapseicon':
          this.collapseIconTemplate = item.template;
          break;
        case 'content':
          this.contentTemplate = item.template;
          break;
      }
    });
  }
  toggle(event) {
    if (this.animating) {
      return false;
    }
    this.animating = true;
    this.onBeforeToggle.emit({
      originalEvent: event,
      collapsed: this.collapsed
    });
    if (this.collapsed) this.expand(event);else this.collapse(event);
    this.onAfterToggle.emit({
      originalEvent: event,
      collapsed: this.collapsed
    });
    event.preventDefault();
  }
  expand(event) {
    this.collapsed = false;
    this.collapsedChange.emit(this.collapsed);
  }
  collapse(event) {
    this.collapsed = true;
    this.collapsedChange.emit(this.collapsed);
  }
  getBlockableElement() {
    return this.el.nativeElement.children[0];
  }
  onToggleDone() {
    this.animating = false;
  }
}
Fieldset.ɵfac = function Fieldset_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || Fieldset)(i0.ɵɵdirectiveInject(i0.ElementRef));
};
Fieldset.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: Fieldset,
  selectors: [["p-fieldset"]],
  contentQueries: function Fieldset_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, PrimeTemplate, 4);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.templates = _t);
    }
  },
  hostAttrs: [1, "p-element"],
  inputs: {
    legend: "legend",
    toggleable: "toggleable",
    collapsed: "collapsed",
    style: "style",
    styleClass: "styleClass",
    transitionOptions: "transitionOptions"
  },
  outputs: {
    collapsedChange: "collapsedChange",
    onBeforeToggle: "onBeforeToggle",
    onAfterToggle: "onAfterToggle"
  },
  ngContentSelectors: _c1,
  decls: 9,
  vars: 23,
  consts: [["legendContent", ""], [3, "ngClass", "ngStyle"], [1, "p-fieldset-legend"], [4, "ngIf", "ngIfElse"], ["role", "region", 1, "p-toggleable-content"], [1, "p-fieldset-content"], [4, "ngTemplateOutlet"], ["tabindex", "0", "pRipple", "", 3, "click", "keydown.enter"], [4, "ngIf"], [3, "styleClass", 4, "ngIf"], ["class", "p-fieldset-toggler", 4, "ngIf"], [3, "styleClass"], [1, "p-fieldset-toggler"], [1, "p-fieldset-legend-text"]],
  template: function Fieldset_Template(rf, ctx) {
    if (rf & 1) {
      const _r1 = i0.ɵɵgetCurrentView();
      i0.ɵɵprojectionDef(_c0);
      i0.ɵɵelementStart(0, "fieldset", 1)(1, "legend", 2);
      i0.ɵɵtemplate(2, Fieldset_ng_container_2_Template, 5, 5, "ng-container", 3)(3, Fieldset_ng_template_3_Template, 4, 2, "ng-template", null, 0, i0.ɵɵtemplateRefExtractor);
      i0.ɵɵelementEnd();
      i0.ɵɵelementStart(5, "div", 4);
      i0.ɵɵlistener("@fieldsetContent.done", function Fieldset_Template_div_animation_fieldsetContent_done_5_listener() {
        i0.ɵɵrestoreView(_r1);
        return i0.ɵɵresetView(ctx.onToggleDone());
      });
      i0.ɵɵelementStart(6, "div", 5);
      i0.ɵɵprojection(7);
      i0.ɵɵtemplate(8, Fieldset_ng_container_8_Template, 1, 0, "ng-container", 6);
      i0.ɵɵelementEnd()()();
    }
    if (rf & 2) {
      const legendContent_r4 = i0.ɵɵreference(4);
      i0.ɵɵclassMap(ctx.styleClass);
      i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction2(12, _c2, ctx.toggleable, !ctx.collapsed && ctx.toggleable))("ngStyle", ctx.style);
      i0.ɵɵattribute("id", ctx.id);
      i0.ɵɵadvance(2);
      i0.ɵɵproperty("ngIf", ctx.toggleable)("ngIfElse", legendContent_r4);
      i0.ɵɵadvance(3);
      i0.ɵɵproperty("@fieldsetContent", ctx.collapsed ? i0.ɵɵpureFunction1(17, _c4, i0.ɵɵpureFunction1(15, _c3, ctx.transitionOptions)) : i0.ɵɵpureFunction1(21, _c6, i0.ɵɵpureFunction1(19, _c5, ctx.animating ? ctx.transitionOptions : "0ms")));
      i0.ɵɵattribute("id", ctx.id + "-content")("aria-labelledby", ctx.id)("aria-hidden", ctx.collapsed);
      i0.ɵɵadvance(3);
      i0.ɵɵproperty("ngTemplateOutlet", ctx.contentTemplate);
    }
  },
  dependencies: () => [i1.NgClass, i1.NgIf, i1.NgTemplateOutlet, i1.NgStyle, i2.Ripple, MinusIcon, PlusIcon],
  styles: [".p-fieldset-legend>a,.p-fieldset-legend>span{display:flex;align-items:center;justify-content:center}.p-fieldset-toggleable .p-fieldset-legend a{cursor:pointer;-webkit-user-select:none;user-select:none;overflow:hidden;position:relative}.p-fieldset-legend-text{line-height:1}.p-fieldset-toggleable.p-fieldset-expanded>.p-toggleable-content:not(.ng-animating){overflow:visible}.p-fieldset-toggleable .p-toggleable-content{overflow:hidden}\n"],
  encapsulation: 2,
  data: {
    animation: [trigger('fieldsetContent', [state('hidden', style({
      height: '0'
    })), state('visible', style({
      height: '*'
    })), transition('visible <=> hidden', [animate('{{transitionParams}}')]), transition('void => *', animate(0))])]
  },
  changeDetection: 0
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(Fieldset, [{
    type: Component,
    args: [{
      selector: 'p-fieldset',
      template: `
        <fieldset [attr.id]="id" [ngClass]="{ 'p-fieldset p-component': true, 'p-fieldset-toggleable': toggleable, 'p-fieldset-expanded': !collapsed && toggleable }" [ngStyle]="style" [class]="styleClass">
            <legend class="p-fieldset-legend">
                <ng-container *ngIf="toggleable; else legendContent">
                    <a tabindex="0" (click)="toggle($event)" (keydown.enter)="toggle($event)" [attr.aria-controls]="id + '-content'" [attr.aria-expanded]="!collapsed" pRipple>
                        <ng-container *ngIf="collapsed">
                            <PlusIcon [styleClass]="'p-fieldset-toggler'" *ngIf="!expandIconTemplate" />
                            <span *ngIf="expandIconTemplate" class="p-fieldset-toggler">
                                <ng-container *ngTemplateOutlet="expandIconTemplate"></ng-container>
                            </span>
                        </ng-container>
                        <ng-container *ngIf="!collapsed">
                            <MinusIcon [styleClass]="'p-fieldset-toggler'" *ngIf="!collapseIconTemplate" />
                            <span *ngIf="collapseIconTemplate" class="p-fieldset-toggler">
                                <ng-container *ngTemplateOutlet="collapseIconTemplate"></ng-container>
                            </span>
                        </ng-container>
                        <ng-container *ngTemplateOutlet="legendContent"></ng-container>
                    </a>
                </ng-container>
                <ng-template #legendContent>
                    <span class="p-fieldset-legend-text">{{ legend }}</span>
                    <ng-content select="p-header"></ng-content>
                    <ng-container *ngTemplateOutlet="headerTemplate"></ng-container>
                </ng-template>
            </legend>
            <div
                [attr.id]="id + '-content'"
                class="p-toggleable-content"
                [@fieldsetContent]="collapsed ? { value: 'hidden', params: { transitionParams: transitionOptions, height: '0' } } : { value: 'visible', params: { transitionParams: animating ? transitionOptions : '0ms', height: '*' } }"
                [attr.aria-labelledby]="id"
                [attr.aria-hidden]="collapsed"
                (@fieldsetContent.done)="onToggleDone()"
                role="region"
            >
                <div class="p-fieldset-content">
                    <ng-content></ng-content>
                    <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
                </div>
            </div>
        </fieldset>
    `,
      animations: [trigger('fieldsetContent', [state('hidden', style({
        height: '0'
      })), state('visible', style({
        height: '*'
      })), transition('visible <=> hidden', [animate('{{transitionParams}}')]), transition('void => *', animate(0))])],
      changeDetection: ChangeDetectionStrategy.OnPush,
      encapsulation: ViewEncapsulation.None,
      host: {
        class: 'p-element'
      },
      styles: [".p-fieldset-legend>a,.p-fieldset-legend>span{display:flex;align-items:center;justify-content:center}.p-fieldset-toggleable .p-fieldset-legend a{cursor:pointer;-webkit-user-select:none;user-select:none;overflow:hidden;position:relative}.p-fieldset-legend-text{line-height:1}.p-fieldset-toggleable.p-fieldset-expanded>.p-toggleable-content:not(.ng-animating){overflow:visible}.p-fieldset-toggleable .p-toggleable-content{overflow:hidden}\n"]
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }];
  }, {
    legend: [{
      type: Input
    }],
    toggleable: [{
      type: Input
    }],
    collapsed: [{
      type: Input
    }],
    collapsedChange: [{
      type: Output
    }],
    onBeforeToggle: [{
      type: Output
    }],
    onAfterToggle: [{
      type: Output
    }],
    style: [{
      type: Input
    }],
    styleClass: [{
      type: Input
    }],
    transitionOptions: [{
      type: Input
    }],
    templates: [{
      type: ContentChildren,
      args: [PrimeTemplate]
    }]
  });
})();
class FieldsetModule {}
FieldsetModule.ɵfac = function FieldsetModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || FieldsetModule)();
};
FieldsetModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: FieldsetModule
});
FieldsetModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [CommonModule, RippleModule, MinusIcon, PlusIcon, SharedModule]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FieldsetModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, RippleModule, MinusIcon, PlusIcon],
      exports: [Fieldset, SharedModule],
      declarations: [Fieldset]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { Fieldset, FieldsetModule };

