import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OriginalConfigurationService {

  constructor(private http: HttpClient) { }

  ratingConfiguration(): Observable<any> {
    return this.http.get(`${environment.azure.baseUrl}api/v1/Data/GetMatrixTreeActive`);
  }

  classifyOriginalRating(formatedData): Observable<any> {
    return this.http.post(`${environment.azure.baseUrl}api/v1/scoringmatrix/classify`, formatedData);
  }

  getIsManualChange(id:any): Observable<any> {
    return this.http.get(`${environment.azure.baseUrl}api/v1/scoringmatrix/getIsManualChange?complaintId=${id}`);
  }

}
