import { Component, Input, OnInit, ViewChild, AfterViewInit, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroupDirective, FormGroup, Validators } from '@angular/forms';
import { PrimeNGConfig } from "primeng/api";
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { FailureModePopupComponent } from '../failure-mode-popup/failure-mode-popup.component';
import { ProMISProjectSelectPopUpComponent } from '../pro-misproject-select-pop-up/pro-misproject-select-pop-up.component';
import { ProductPopupComponent } from '../product-popup/product-popup.component';
import { ResolvingUnitSelectPopUpComponent } from '../resolving-unit-select-pop-up/resolving-unit-select-pop-up.component';
import { SupplierSelectionPopupComponent } from '../supplier-selection-popup/supplier-selection-popup.component';
import { formatDate } from '@angular/common';
import { CountriesServiceService } from 'src/app/services/countries--service.service';
import { SharedDataService } from 'src/app/services/shared-data.service';
import { MessageService } from 'primeng/api';
import { StaticvalueConstants } from 'src/app/reusables/constants/staticvalue.constants';
import { UsersService } from 'src/app/services/users/users.service';
import { LocalStorageServiceService } from 'src/app/services/local-storage-service.service';
import { MdRegionService } from 'src/app/services/md-region.service';
import { NewIssueService } from 'src/app/services/newIssue/new-issue.service';
import { AuthorizationService } from 'src/app/services/authorization.service';
import { ComplaintStatuses, PermissionCode, RoleCode } from 'src/app/enum';
import { DefectTypePopupComponent } from '../defect-type-popup/defect-type-popup.component';
import { ToasterserviceService } from 'src/app/services/toasterservice.service';
import { Console } from 'console';
import { maxLengthConstants } from 'src/app/reusables/constants/maxlength.constants';
import { environment } from 'src/environments/environment';
import { MatrixClass } from 'src/app/models/new-issue/MatrixClass';
import { ButtonVisibilityServiceService } from 'src/app/services/button-visibility-service.service';
import { NgxSpinnerService } from 'ngx-spinner';


@Component({
  selector: 'app-additional-subject',
  templateUrl: './additional-subject.component.html',
  styleUrls: ['./additional-subject.component.css']
})
export class AdditionalSubjectComponent implements OnInit, OnChanges {
  @ViewChild("calendar1", { static: false }) private calendarDeliveryDate: any;
  @ViewChild("calendar2", { static: false }) private calendarCommissioningDate: any;
  @ViewChild("calendar3", { static: false }) private calendarManufactureDate: any;
  @ViewChild("calendar4", { static: false }) private calendarWarrantyStart: any;
  @ViewChild("calendar5", { static: false }) private calendarWarrantyEnd: any;
  @Input() users: any[] = [];
  @Input() formGroupName!: string;
  @Input() isSubmitted: boolean = false;
  @Input() complaintData !: any;
  @Input() issueCaptureMainForm: FormGroup;
  @Input() impactData !: any;
  @Input() complaintValidations: any = [];
  @Input() ActionNameForComplaints: string = "Submit";
  @Input() fieldNameForFailureMode: any = "None";
  @Input() isEnableSAPQN: boolean;
  @Output() countryEmit = new EventEmitter();
  @Output() failureMode = new EventEmitter();
  @Input() readonlyObj !:any;

  additionalSubjectForm: FormGroup;
  serialNoArray: any = [];
  dateText: string;
  coordinators: any[] = [];
  filteredOwner: any[] = [];
  filteredPartiesConnected: any[] = [];
  partiesConnectedText = 'Related Parties';
  proposedResolutionOwnerText = "Proposed resolution owner. The name should be entered in the following format: firstname.lastname@hitachienergy.com";
  countries: any[] = [];
  customerCountry: any;
  resolvingUnitId: Number;
  proMISCountryName: any;
  verificationTeamData: any;
  deliveryDateText = "Delivery Date. To insert a date, click on the select button and pick the date from the Calendar. The date should be entered in the following format: YYYY-MM-DD.";
  manufacturingDateText = "Manufacturing Date. To insert a date, click on the select button and pick the date from the Calendar. The date should be entered in the following format: YYYY-MM-DD.";
  commissioningDateText = "Commissioning Date. To insert a date, click on the select button and pick the date from the Calendar. The date should be entered in the following format: YYYY-MM-DD.";
  warrantyStartDateText = "Warranty Start Date. To insert a date, click on the select button and pick the date from the Calendar. The date should be entered in the following format: YYYY-MM-DD.";
  warrantyEndDateText = "Warranty End Date. To insert a date, click on the select button and pick the date from the Calendar. The date should be entered in the following format: YYYY-MM-DD.";
  deliveryDateBtnText = "Delivery Date. To insert a date, click on the select button and pick the date from the Calendar. The date should be entered in the following format: YYYY-MM-DD.";
  manufacturingDateBtnText = "Manufacturing Date. To insert a date, click on the select button and pick the date from the Calendar. The date should be entered in the following format: YYYY-MM-DD.";
  commissioningDateBtnText = "Commissioning Date. To insert a date, click on the select button and pick the date from the Calendar. The date should be entered in the following format: YYYY-MM-DD.";
  warrantyStartDateBtnText = "Warranty Start Date. To insert a date, click on the select button and pick the date from the Calendar. The date should be entered in the following format: YYYY-MM-DD.";
  warrantyEndDateBtnText = "Warranty End Date. To insert a date, click on the select button and pick the date from the Calendar. The date should be entered in the following format: YYYY-MM-DD.";
  fillInText = 'Use "Fill in" button to set the country based on the Customer';
  proMISfillInText = 'Use "Fill in from ProMIS" button to set the country based on ProMIS';
  QnumberText = "Use this field to provide Q-field of issuing organization";
  otherDocumentIDText = "Use this field to provide Other/Document ID";
  productShippedTooltip = "Please indicate, if the affected product / service has already been delivered to the customer";
  noOfUnitShippedTooltip = "Please indicate, how many affected products have already been delivered to the customer? For affected services please select N/A.";
  supplierExternalOnlyText = 'If the issue is related to an external supplier, use the "Select" button to provide the information.';
  customerReferenceText = "If customer has a reference number he wishes this issue to be linked to, please enter it here.";
  itemDesignationText = "Use this field to provide Item designation";

  serialNoText = 'Serial # field become mandatory when any child element of "Product/System/Service/ Issues", “Mechanical/Hv Dielectric/Electrical/ functionality/performance” , “Resistance measurement failure” , “Sealing/Gas/Oil Quality” Failure Mode tree branch is selected';
  purchaseOrderText = 'Purchase Order # field becomes mandatory when one of the following Failure Modes is selected: "Lead time", "On-time delivery", "Delivery incomplete/missing parts", "Delivery damaged", "Delivery shipped to the wrong location", "Incorrect billing", "Incorrect/insufficient/missing/late documentation" , “ Late delivery”, ”Incorrect /missing/damaged material, “missing/Incomplete/wrong documentation” ';
  projectText = 'Project # field becomes mandatory when one of the following Failure Modes is selected: "Unsatisfactory consideration of Health, Safety, Environmental", "Unsatisfactory Project Management", "Unsatisfactory communication", "Unsatisfactory site management", "Unsatisfactory engineering & design capability", "Unsatisfactory installation/commissioning support", "Unsatisfactory dispute/claims handling", "Unsatisfactory management of Customer concessions", "Failed factory acceptance test", "Unsatisfactory sub-contractor management" ,Incomplete documentation”, “ Wrong documentation”, “Documentation not according to specific”, “Late delivery”, “Lead time” or any child item of “Transportation & Packaging quality”'
  projectNameText = 'Project Name field becomes mandatory when one of the following Failure Modes is selected: "Unsatisfactory consideration of Health, Safety, Environmental", "Unsatisfactory Project Management", "Unsatisfactory communication", "Unsatisfactory site management", "Unsatisfactory engineering & design capability", "Unsatisfactory installation/commissioning support", "Unsatisfactory dispute/claims handling", "Unsatisfactory management of Customer concessions", "Failed factory acceptance test", "Unsatisfactory sub-contractor management", Incomplete documentation”, “ Wrong documentation”, “Documentation not according to specific”, “Late delivery”, “Lead time” or any child item of “Transportation & Packaging quality”'
  quantityText = 'Please use this field provide the quantity of failed product.';
  articleNumberText = 'Use this field to provide Article number';
  projectNameTooltipText = "If the issue is related to a specific project, please provide the name here.  This will help accelerate resolution.";
  projectTooltipText = "If the issue is related to a specific project, please provide the number here. This will help accelerate resolution.;"
  purchaseOrderTooltipText = "If the issue is related to a specific purchase order, please provide the number here. This will help accelerate resolution.";
  serialTooltipText = "If you can provide product serial number, this will help accelerate resolution. More than one serial number can be entered separated by commas.";
  productTooltipText = 'If issue is related to a product, click on "Select" to add the product description.';
  failureModeTooltipText = "Please select the Failure Mode category related to this issue. This information will be used for more Global analysis of Customer Issues. Failure Modes are defined by the PG OPEX Manager."
  resolvingUnitTooltipText = "Use the 'Select' button to identify the unit most capable of resolving the Customer's issue. Selection of the correct Resolving Unit is critical to the effectiveness of the process. If you are not sure call the unit first to confirm they are the right unit for this issue.";
  issueCoordinatorText = '';
  partiesConnectedCheckboxText = 'Check this box if you wish to copy other people on this issue. Use the Select button to add the names.';
  productButtonText = 'Before you can edit this field, you must select the Resolving Unit';
  productInputText = 'If issue is related to a product, click on "Select" to add the product description.'
  failureModeInputText = 'Please select the Failure Mode category related to this issue. This information will be used for more Global analysis of Customer Issues. Failure Modes are defined by the PG OPEX Manager.';
  projectITooltip = "Project # field becomes mandatory when one of the following Failure Modes is selected: 'Unsatisfactory consideration of Health, Safety & Environmental', 'Unsatisfactory Project Management', 'Unsatisfactory communication', 'Unsatisfactory site management', 'Unsatisfactory engineering & design capability', 'Unsatisfactory installation/commissioning support', 'Unsatisfactory dispute/claims handling', 'Unsatisfactory management of Customer concessions', 'Failed factory acceptance test', 'Unsatisfactory sub-contractor management', Incomplete documentation”, “ Wrong documentation”, “Documentation not according to specific”, “Late delivery”, “Lead time” or any child item of “Transportation & Packaging quality”.";
  defectTypeTooltip = 'Use this field to provide defect type/defect code type details'
  InvalidSerialNoText = 'Invalid serial no in DPS';
  ValidSerialNoText = 'Valid serial no in DPS';
  RemoveSerialNoText = 'Click here to remove serial no';
  alreadyMappedSerialNosText = 'Serial Number already mapped with more than one record in DPS';
  customerNameData: any;
  getDPSLinkURLData: any;
  noOfUnitShipped = [
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    '10',
    '11-100',
    '101-1000',
    'Over 1000',
    'N/A',
  ];
  productShippedValues = [
    { value: 'Yes' },
    { value: 'No' },
  ];


  enumPermission = PermissionCode;
  enumRoleCode = RoleCode;
  enumComplaintStatuses = ComplaintStatuses;
  userAccessData = null
  disableBtn: any = {};

  headerSectionData: any;
  issueStatus: any;
  invalidDates: Date;
  balIdPass: any;
  @Input() selectedProcessStepId!: any;
  alreadyPatched: boolean = false;
  readonly maxLength = maxLengthConstants;
  complaintCreatedDate:any = new Date();
  ncrLiveDate:any = new Date(environment.ncrLiveDate);
  WarrentyLifeCycleOptions:any =[
    { number: 'Before Warranty', value: 1 },
    { number: 'Under Warranty', value: 2 },
    { number: 'After warranty', value: 3 }
  ];
  isVerified: boolean = false;



  // customPattern = '^\d+(?:[,.]\d+)?$';
  constructor(private fb: FormBuilder,
    private primengConfig: PrimeNGConfig,
    private dialogService: DialogService,
    private rootFormGroup: FormGroupDirective,
    private ref: DynamicDialogRef,
    private countryService: CountriesServiceService,
    private sharedDataService: SharedDataService,
    private usersService: UsersService,
    private messageService: MessageService,
    private localStorageService: LocalStorageServiceService,
    private mdRegionService: MdRegionService,
    private newIssueService: NewIssueService,
    private authorizationService: AuthorizationService,
    private toasterService: ToasterserviceService,
    private spinner: NgxSpinnerService,
  ) { }

  ngOnInit(): void {
    this.additionalSubjectForm = this.rootFormGroup.control.get(this.formGroupName) as FormGroup;
    this.headerSectionData = this.rootFormGroup.control.get('headerSection') as FormGroup;
    if (this.localStorageService.getFromLocalStorage('mdregion_20200117') === null) {
      this.countryService.getCountries().subscribe((Response) => {
        this.countries = Response;
      });
    }
    else {
      this.countries = this.localStorageService.getFromLocalStorage('mdregion_20200117').countries;
    }
    this.newIssueService.getDPSLinkURLs().subscribe((Res) => {
      this.getDPSLinkURLData = Res;
      this.additionalSubjectForm.patchValue({
        dpsCCRPAppId:Res.appId,
        dpsEmailIdOfUser:Res.email
      })
    })
    this.sharedDataService.country.subscribe(data => {
      this.customerCountry = data;
    });
    this.primengConfig.ripple = true;
    this.additionalSubjectForm.controls['warrantyStartDate'].valueChanges.subscribe((start) => {
      this.invalidDates = start
    })
  }
  productAlreadyDeliveredChanged() {
    this.additionalSubjectForm.patchValue({
      isNoofUnitsShippedRequired: (this.issueCaptureMainForm.controls["ratingForm"].get('IsComplaintDescriptionSectionMandatory')?.value == true && this.additionalSubjectForm.controls['productAlreadyDelivered']?.value?.value == 'Yes' ? true : false)
    });
    const buCode = this.additionalSubjectForm.controls['BuCode']?.value;
    const productDeliverValue = this.additionalSubjectForm.controls['productAlreadyDelivered']?.value;
    if(buCode == 'PGHV' || buCode == 'PGTR') {
      if(productDeliverValue?.value == 'Yes' && this.issueCaptureMainForm.controls["ratingForm"].get('HasHightImpactOnABB')?.value) {
        this.additionalSubjectForm.patchValue({
          IsBUExceptionFieldsMandatory: true,
          isNoofUnitsShippedRequired: true
        })
        this.setFieldValidationSeparate(this.ActionNameForComplaints);
      }
      else if(productDeliverValue?.value == 'No'){
        this.additionalSubjectForm.patchValue({
            IsBUExceptionFieldsMandatory: false,
            isNoofUnitsShippedRequired: false
        })
        this.setFieldValidationSeparate(this.ActionNameForComplaints);
      }
      else if(!productDeliverValue?.value)
      {
        this.additionalSubjectForm.patchValue({
          IsBUExceptionFieldsMandatory: false
        })
        this.setFieldValidationSeparate(this.ActionNameForComplaints);
      }
    }
    else {
      this.setFieldsValidation(this.ActionNameForComplaints);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.complaintValidations.length > 0) {
      this.setFieldsValidation(this.ActionNameForComplaints);
    }
    this.issueStatus = this.headerSectionData?.controls['issueComplaintStatus']?.value;

      if (this.complaintData != undefined && this.alreadyPatched==false) {
        //console.log(this.complaintData?.header?.createDate);
        //console.log(new Date(this.complaintData?.header?.createDate));
        this.complaintCreatedDate = new Date(this.complaintData?.header?.createDate);
        if (this.complaintData?.areaComplaintCollection?.resolvingUnitSection?.resolvingUnit != null) {
          this.setResolvingUnit(this.complaintData?.areaComplaintCollection?.resolvingUnitSection?.resolvingUnit);
        }
        if (this.complaintData?.areaComplaintCollection?.complaintCustomerComplainingSection?.abbProductCategory != null) {
          this.setProduct(this.complaintData?.areaComplaintCollection?.complaintCustomerComplainingSection?.abbProductCategory);
        }
        this.filteredOwner = this.complaintData?.areaComplaintCollection?.resolvingUnitSection?.proposedResolutionOwner ? [{ selectedLabel: this.complaintData?.areaComplaintCollection?.resolvingUnitSection?.proposedResolutionOwner?.displayLabel, promptLabel: this.complaintData?.areaComplaintCollection?.resolvingUnitSection?.proposedResolutionOwner?.email, tooltipInfo: this.complaintData?.areaComplaintCollection?.resolvingUnitSection?.proposedResolutionOwner?.tooltipInfo, geid: this.complaintData?.areaComplaintCollection?.resolvingUnitSection?.proposedResolutionOwner?.geid, identification: this.complaintData?.areaComplaintCollection?.resolvingUnitSection?.proposedResolutionOwner?.identification, role: this.complaintData?.areaComplaintCollection?.resolvingUnitSection?.proposedResolutionOwner?.role }] : [];

        this.filteredPartiesConnected = [];
        this.complaintData?.areaComplaintCollection?.resolvingSubjectInformationSection?.partiesReference.map(parties => {
          this.filteredPartiesConnected.push({ selectedLabel: parties?.displayLabel, promptLabel: parties?.email, tooltipInfo: parties?.tooltipInfo, geid: parties?.geid, identification: parties?.identification, role: parties?.role });
        })
        if (this.complaintData?.areaComplaintCollection?.complaintCustomerComplainingSection?.failureMode != null) {
          this.setFailureMode(this.complaintData?.areaComplaintCollection?.complaintCustomerComplainingSection?.failureMode);
        }
        if (this.complaintData?.areaComplaintCollection?.customerSelectionSection?.supplier != null) {
          this.setSuplier(this.complaintData?.areaComplaintCollection?.customerSelectionSection?.supplier);
        }

        const sapComplaintInfo = this.complaintData?.sapComplaintInfo;
        const productData = this.complaintData?.areaComplaintCollection?.complaintCustomerComplainingSection?.abbProductCategory;
        const productArrayData = { cid: productData?.cid, name: productData?.name, isActive: productData?.isActive, identification: { id: productData?.id, cid: productData?.cid }, displayLabel: productData?.displayLabel, tooltipInfo: productData?.tooltipInfo, isValid: true };
        const sapDefectMain = this.complaintData?.sapDefectCause;
        const sapDefectType = {id : sapDefectMain?.defectType, name : sapDefectMain?.defectTypeText, parentId: sapDefectMain?.codegroupdefecttype, parentName: sapDefectMain?.defectCodeGroupText};
        const sapDefectCause = {id: sapDefectMain?.defectCause, name: sapDefectMain?.defectCauseTextField, parentName: sapDefectMain?.causeCodeGroupText, parentId: sapDefectMain?.defectcausecodegroup, buCode: sapDefectMain?.buCode};
        this.additionalSubjectForm?.patchValue({
          CountryCodeWhereTheIssueHappen: (this.countries.filter(country => country?.id?.toLowerCase() == this.complaintData?.areaComplaintCollection?.resolvingSubjectInformationSection?.countryCodeWhereTheIssueHappen?.toLowerCase())[0]),
          Coordinators: this.complaintData?.areaComplaintCollection?.resolvingUnitSection?.coordinators,
          verificationTeam: this.complaintData?.areaComplaintAssignment?.caResolutionOwner?.processOwnersVerificationTeamList,
          proposedResolutionOwner: this.filteredOwner,
          abbProductSerialNo: this.checkBlank(this.complaintData?.areaComplaintCollection?.resolvingSubjectInformationSection?.abbProductSerialNo),
          purchaseOrderNo: this.checkBlank(this.complaintData?.areaComplaintCollection?.resolvingSubjectInformationSection?.purchaseOrderNo),
          abbProjectNumber: this.checkBlank(this.complaintData?.areaComplaintCollection?.resolvingSubjectInformationSection?.abbProjectNumber),
          abbProjectName: this.checkBlank(this.complaintData?.areaComplaintCollection?.resolvingSubjectInformationSection?.abbProjectName),
          quantity: this.checkBlank(this.complaintData?.areaComplaintCollection?.resolvingSubjectInformationSection?.quantity),
          articleNumber: this.checkBlank(this.complaintData?.areaComplaintCollection?.resolvingSubjectInformationSection?.articleNumber),
          itemDesignation: this.checkBlank(this.complaintData?.areaComplaintCollection?.resolvingSubjectInformationSection?.itemDesignation),
          abbCustomerReference: this.checkBlank(this.complaintData?.areaComplaintCollection?.resolvingSubjectInformationSection?.abbCustomerReference),
          deliveryDate: this.checkBlank(this.complaintData?.areaComplaintCollection?.resolvingSubjectInformationSection?.deliveryDate?.split("T", 1)[0]),
          commissionDate: this.checkBlank(this.complaintData?.areaComplaintCollection?.resolvingSubjectInformationSection?.commissioningDate?.split("T", 1)[0]),
          ManufacturingDate: this.checkBlank(this.complaintData?.areaComplaintCollection?.resolvingSubjectInformationSection?.manufacturingDate?.split("T", 1)[0]),
          warrantyReference: this.checkBlank(this.complaintData?.areaComplaintCollection?.resolvingSubjectInformationSection?.warrantyReference),
          warrantyStartDate: this.checkBlank(this.complaintData?.areaComplaintCollection?.resolvingSubjectInformationSection?.warrantyStartDate?.split("T", 1)[0]),
          warrantyEndDate: this.checkBlank(this.complaintData?.areaComplaintCollection?.resolvingSubjectInformationSection?.warrantyEndDate?.split("T", 1)[0]),
          documentID: this.complaintData?.areaComplaintCollection?.resolvingSubjectInformationSection?.otherDocumentId,
          qNumberIssue: this.complaintData?.areaComplaintCollection?.resolvingSubjectInformationSection?.qNumberIssuingOrganization,
          productAlreadyDelivered: { value: this.complaintData?.areaComplaintCollection?.resolvingSubjectInformationSection?.productAlreadyDelivered },
          NoOfUnitsShipped: this.complaintData?.areaComplaintCollection?.resolvingSubjectInformationSection?.noOfUnitsShipped,
          PartiesReference: this.filteredPartiesConnected,
          SendCopyToParties: this.checkBlank(this.complaintData?.areaComplaintCollection?.resolvingSubjectInformationSection?.sendCopyToParties),
          ABBProductCategory: this.checkBlank(this.complaintData?.areaComplaintCollection?.complaintCustomerComplainingSection?.abbProductCategory?.name),
          issueProductArray: productArrayData,
          hiddenIsHVDCComplaint: this.checkBlank(this.complaintData?.areaComplaintResolution?.isHVDCComplaint),
          hiddenPreviousClass: this.checkBlank(MatrixClass[this.complaintData?.areaComplaintResolution?.matrixCurrentRatingViewModel?.class]),
          defectTypeHiddenField: sapDefectType,
          DefectTypeandDefectCodeGroupType: this.checkBlank(this.complaintData?.sapDefectCause?.defectTypeText),
          isQnNumberGeneratedCheck: sapComplaintInfo?.isQnNumberGenerated,
          sapComplaintInfoDetails: {id: sapComplaintInfo?.id, isQnNumberGenerated: sapComplaintInfo?.isQnNumberGenerated, qnNumber: sapComplaintInfo?.qnNumber},
          sapDefectCauseIdAddnSub : this.complaintData?.sapDefectCause?.id,
          sapLockStatus: this.complaintData?.sapComplaintInfo?.sapLockStatus,
          IsQnNumberGenerated: sapComplaintInfo?.isQnNumberGenerated? sapComplaintInfo?.isQnNumberGenerated: false,
          defectCauseHiddenFieldAdnSub: sapDefectCause,
          sapDefectCauseId : {id: this.complaintData?.sapDefectCause?.id, DefectCauseDefectCauseCodeGroup: this.complaintData?.sapDefectCause?.DefectCauseDefectCauseCodeGroup, DefectTypeandDefectCodeGroupType: this.complaintData?.sapDefectCause?.DefectTypeandDefectCodeGroupType},
          warrantyLifeCycleId : this.complaintData?.areaComplaintCollection?.resolvingSubjectInformationSection?.warrantyLifeCycleId,
          oldSerialNosList: this.complaintData?.areaComplaintCollection?.resolvingSubjectInformationSection?.abbProductSerialNo?.split(","),
          updatedSerialNosList: this.complaintData?.areaComplaintCollection?.resolvingSubjectInformationSection?.abbProductSerialNo?.split(","),
          isVerifiedSerial:true
        })
        this.splitSerialNoForPageLoad();
        this.deliveryDateOnSelect();
        this.manfacturingDateOnSelect();
        this.commissioningDateOnSelect();
        this.warrantyStartOnSelect();
        this.warrantyEndOnSelect();
        this.productAlreadyDeliveredChanged();
        this.apiCallforValidatingDPSNos();
        this.alreadyPatched=true;

        if(this.complaintData?.sapDefectCause?.DefectTypeandDefectCodeGroupType?.tooltipInfo != null){
          this.defectTypeTooltip = this.complaintData?.sapDefectCause?.DefectTypeandDefectCodeGroupType?.tooltipInfo
        }
      }
      this.authorizationService.userAccessDetail.subscribe(async detail => {
        this.userAccessData = detail;
        this.authorizationBtnDisable();
      });
  }

  setFieldsValidation(action) {
    // console.log("if")
    for (const controlName in this.issueCaptureMainForm?.get('issueCaptureForm')['controls']) {
      let validationData = this.complaintValidations.find(complaintValidation => complaintValidation.property?.toLowerCase() == controlName?.toLowerCase() && complaintValidation.complaintAction == action && complaintValidation?.complaintStatusIdsArray?.includes(this.issueCaptureMainForm?.get('headerSection').get('complaintStatusId')?.value?.toString()) && (this.userAccessData == null ? this.userAccessData == null : complaintValidation?.roleIdsArray?.includes(this.userAccessData!['roleCode']?.toString())))
      // console.log(this.complaintValidations,validationData,controlName);
      if (validationData) {
        // console.log(">>>>",this.issueCapture);
        const control = this.issueCaptureMainForm?.get('issueCaptureForm').get(controlName);
        const validationRules = this.complaintValidations.filter(complaintValidation => complaintValidation.property?.toLowerCase() == controlName?.toLowerCase() && complaintValidation.complaintAction == action && complaintValidation?.complaintStatusIdsArray?.includes(this.issueCaptureMainForm?.get('headerSection').get('complaintStatusId')?.value?.toString()) && (this.userAccessData == null ? this.userAccessData == null : complaintValidation?.roleIdsArray?.includes(this.userAccessData!['roleCode']?.toString())));

        control.setValidators(this.getValidators(validationRules, this.fieldNameForFailureMode));
        control.updateValueAndValidity(); // Update the validation status
      }
      else {
        // console.log("else for: ", controlName);
        this.issueCaptureMainForm?.get('issueCaptureForm').get(controlName).clearValidators();
        this.issueCaptureMainForm?.get('issueCaptureForm').get(controlName).updateValueAndValidity();
      }
    }
    for (const controlName in this.issueCaptureMainForm?.get('issueDescriptionForm')['controls']) {
      let validationData = this.complaintValidations.find(complaintValidation => complaintValidation.property?.toLowerCase() == controlName?.toLowerCase() && complaintValidation.complaintAction == action && complaintValidation?.complaintStatusIdsArray?.includes(this.issueCaptureMainForm?.get('headerSection').get('complaintStatusId')?.value?.toString()) && (this.userAccessData == null ? this.userAccessData == null : complaintValidation?.roleIdsArray?.includes(this.userAccessData!['roleCode']?.toString())))
      // console.log(this.complaintValidations,validationData,controlName);
      if (validationData) {
        // console.log(">>>>",this.issueCapture);
        const control = this.issueCaptureMainForm?.get('issueDescriptionForm').get(controlName);
        const validationRules = this.complaintValidations.filter(complaintValidation => complaintValidation.property?.toLowerCase() == controlName?.toLowerCase() && complaintValidation.complaintAction == action && complaintValidation?.complaintStatusIdsArray?.includes(this.issueCaptureMainForm?.get('headerSection').get('complaintStatusId')?.value?.toString()) && (this.userAccessData == null ? this.userAccessData == null : complaintValidation?.roleIdsArray?.includes(this.userAccessData!['roleCode']?.toString())));

        control.setValidators(this.getValidators(validationRules, this.fieldNameForFailureMode));
        control.updateValueAndValidity(); // Update the validation status
      }
      else {
        this.issueCaptureMainForm?.get('issueDescriptionForm').get(controlName).clearValidators();
        this.issueCaptureMainForm?.get('issueDescriptionForm').get(controlName).updateValueAndValidity();
      }
    }
    for (const controlName in this.issueCaptureMainForm?.get('ratingForm')['controls']) {
      let validationData = this.complaintValidations.find(complaintValidation => complaintValidation.property?.toLowerCase() == controlName?.toLowerCase() && complaintValidation.complaintAction == action && complaintValidation?.complaintStatusIdsArray?.includes(this.issueCaptureMainForm?.get('headerSection').get('complaintStatusId')?.value?.toString()) && (this.userAccessData == null ? this.userAccessData == null : complaintValidation?.roleIdsArray?.includes(this.userAccessData!['roleCode']?.toString())))
      // console.log(this.complaintValidations,validationData,controlName);
      if (validationData) {
        // console.log(">>>>",this.issueCapture);
        const control = this.issueCaptureMainForm?.get('ratingForm').get(controlName);
        const validationRules = this.complaintValidations.filter(complaintValidation => complaintValidation.property?.toLowerCase() == controlName?.toLowerCase() && complaintValidation.complaintAction == action && complaintValidation?.complaintStatusIdsArray?.includes(this.issueCaptureMainForm?.get('headerSection').get('complaintStatusId')?.value?.toString()) && (this.userAccessData == null ? this.userAccessData == null : complaintValidation?.roleIdsArray?.includes(this.userAccessData!['roleCode']?.toString())));

        control.setValidators(this.getValidators(validationRules, this.fieldNameForFailureMode));
        control.updateValueAndValidity(); // Update the validation status
      }
      else {
        // console.log("else for: ", controlName);
        this.issueCaptureMainForm?.get('ratingForm').get(controlName).clearValidators();
        this.issueCaptureMainForm?.get('ratingForm').get(controlName).updateValueAndValidity();
      }
    }
    for (const controlName in this.issueCaptureMainForm?.get('additionalSubjectForm')['controls']) {
      let validationData = this.complaintValidations.find(complaintValidation => complaintValidation.property?.toLowerCase() == controlName?.toLowerCase() && complaintValidation.complaintAction == action && complaintValidation?.complaintStatusIdsArray?.includes(this.issueCaptureMainForm?.get('headerSection').get('complaintStatusId')?.value?.toString()) && (this.userAccessData == null ? this.userAccessData == null : complaintValidation?.roleIdsArray?.includes(this.userAccessData!['roleCode']?.toString())))
      // console.log(this.complaintValidations,validationData,controlName,this.issueCaptureMainForm?.get('headerSection').get('complaintStatusId')?.value?.toString(),this.userAccessData);
      // console.log("))))))))))))))))))))",controlName , validationData, this.fieldNameForFailureMode);
      if (validationData) {
        const control = this.issueCaptureMainForm?.get('additionalSubjectForm').get(controlName);
        const validationRules = this.complaintValidations.filter(complaintValidation => complaintValidation.property?.toLowerCase() == controlName?.toLowerCase() && complaintValidation.complaintAction == action && complaintValidation?.complaintStatusIdsArray?.includes(this.issueCaptureMainForm?.get('headerSection').get('complaintStatusId')?.value?.toString()) && (this.userAccessData == null ? this.userAccessData == null : complaintValidation?.roleIdsArray?.includes(this.userAccessData!['roleCode']?.toString())));
        control.setValidators(this.getValidators(validationRules, this.fieldNameForFailureMode));
        control.updateValueAndValidity(); // Update the validation status
      }
      else {
        // console.log("=========",controlName,this.fieldNameForFailureMode);
        if((controlName=='abbProductSerialNo' && this.fieldNameForFailureMode.includes('SerialNo')) || (controlName=='purchaseOrderNo' && this.fieldNameForFailureMode.includes('PurchaseOrder')) || ((controlName=='ABBProjectNumber' || controlName=='ABBProjectName') && this.fieldNameForFailureMode.includes('ABBProject'))){
          this.issueCaptureMainForm?.get('additionalSubjectForm').get(controlName).setValidators([Validators.required]);
          this.issueCaptureMainForm?.get('additionalSubjectForm').get(controlName).updateValueAndValidity();
          // console.log("<><><><>",this.issueCaptureMainForm?.get('additionalSubjectForm').get(controlName),this.issueCaptureMainForm?.get('additionalSubjectForm').get(controlName)?.validator);
        }
        else{
          this.issueCaptureMainForm?.get('additionalSubjectForm').get(controlName).clearValidators();
          this.issueCaptureMainForm?.get('additionalSubjectForm').get(controlName).updateValueAndValidity();
        }

      }
    }
  }

  setFieldValidationSeparate(action) {
    for (const controlName in this.issueCaptureMainForm?.get('additionalSubjectForm')['controls']) {
      if(controlName == 'productAlreadyDelivered' || controlName == 'ManufacturingDate' || controlName == 'warrantyEndDate'|| controlName=='abbProductSerialNo'|| controlName=='quantity' || controlName=='NoOfUnitsShipped') {
        let validationData = this.complaintValidations.find(complaintValidation => complaintValidation.property?.toLowerCase() == controlName?.toLowerCase() && complaintValidation.complaintAction == action && complaintValidation?.complaintStatusIdsArray?.includes(this.issueCaptureMainForm?.get('headerSection').get('complaintStatusId')?.value?.toString()) && (this.userAccessData == null ? this.userAccessData == null : complaintValidation?.roleIdsArray?.includes(this.userAccessData!['roleCode']?.toString())))
        if (validationData) {
          const control = this.issueCaptureMainForm?.get('additionalSubjectForm').get(controlName);
          const validationRules = this.complaintValidations.filter(complaintValidation => complaintValidation.property?.toLowerCase() == controlName?.toLowerCase() && complaintValidation.complaintAction == action && complaintValidation?.complaintStatusIdsArray?.includes(this.issueCaptureMainForm?.get('headerSection').get('complaintStatusId')?.value?.toString()) && (this.userAccessData == null ? this.userAccessData == null : complaintValidation?.roleIdsArray?.includes(this.userAccessData!['roleCode']?.toString())));
          control.setValidators(this.getValidators(validationRules, this.fieldNameForFailureMode));
          control.updateValueAndValidity(); // Update the validation status
        }
        else {
            this.issueCaptureMainForm?.get('additionalSubjectForm').get(controlName).clearValidators();
            this.issueCaptureMainForm?.get('additionalSubjectForm').get(controlName).updateValueAndValidity();
        }
      }
    }
}

  private getValidators(rules: any[], fieldFailureModeValidation): any {
    let validators: Validators[] = [];
    let index = 0;
    for (const rule of rules) {
      index = index + 1;
      if (rule.depends != null) {
        if (!fieldFailureModeValidation.includes('None') && rule.depends == "Validation" && fieldFailureModeValidation.includes(rule.value)) {
          validators.push(Validators.required);
          return validators;
        }
        else if ((this.issueCaptureMainForm.controls["issueCaptureForm"].get(rule.depends)?.value != undefined && this.issueCaptureMainForm.controls["issueCaptureForm"].get(rule.depends)?.value?.toString()?.toLowerCase() == rule.value?.toLowerCase()) || (this.issueCaptureMainForm.controls["issueDescriptionForm"].get(rule.depends)?.value != undefined && this.issueCaptureMainForm.controls["issueDescriptionForm"].get(rule.depends)?.value?.toString()?.toLowerCase() == rule.value?.toLowerCase()) || (this.issueCaptureMainForm.controls["ratingForm"].get(rule.depends)?.value != undefined && this.issueCaptureMainForm.controls["ratingForm"].get(rule.depends)?.value?.toString()?.toLowerCase() == rule.value?.toLowerCase()) || (this.issueCaptureMainForm.controls["additionalSubjectForm"].get(rule.depends)?.value != undefined && this.issueCaptureMainForm.controls["additionalSubjectForm"].get(rule.depends)?.value?.toString()?.toLowerCase() == rule.value?.toLowerCase())) {
          validators.push(Validators.required);
          return validators;
        }
        else {
          if (index == rules.length) {
            return validators;
          }

        }
      }
      else {
        validators.push(Validators.required);
        return validators;
      }
    }
  }

  checkBlank(content) {
    if (content == undefined || content == null) {
      return '';
    }
    else {
      return content;
    }
  }

  splitSerialNoForPageLoad() {
    if (this.additionalSubjectForm.controls['BuCode']?.value! == 'PGHV') {
      this.serialData = false;
      // this.isVerified = false;
      this.additionalSubjectForm.patchValue({
        isVerifiedSerial: true
      })
    }
    if (this.additionalSubjectForm.controls['abbProductSerialNo'].value) {
      this.serialNoArray = this.additionalSubjectForm.controls['abbProductSerialNo'].value.split(",").map((tag) => tag.trim()).filter((tag) => tag.length !== 0);;
    }
  }

  splitSerialNo() {
    if (this.additionalSubjectForm.controls['BuCode']?.value! == 'PGHV') {
      this.serialData = false;
      this.isVerified = false;
      this.additionalSubjectForm.patchValue({
        isVerifiedSerial: false,
        modificationFlagForDps : true
      })
      if (this.complaintData != undefined) {
        if (this.additionalSubjectForm.controls['abbProductSerialNo'].value === this.complaintData?.areaComplaintCollection?.resolvingSubjectInformationSection?.abbProductSerialNo) {
          this.additionalSubjectForm.patchValue({
            isVerifiedSerial: true
          })
        }
      }
    }
    if (this.additionalSubjectForm.controls['abbProductSerialNo'].value) {
      this.serialNoArray = this.additionalSubjectForm.controls['abbProductSerialNo'].value.split(",").map((tag) => tag.trim()).filter((tag) => tag.length !== 0);;
    }
    if(this.additionalSubjectForm.controls['abbProductSerialNo'].value == ""){
      this.additionalSubjectForm.patchValue({
        updatedSerialNosList:[]
      });
      this.serialNoArray = [];
    }
  }

  serialData: boolean = false;
  serialNumberDisable() {
    return !this.additionalSubjectForm.controls['abbProductSerialNo'].value
    || this.complaintData?.areaComplaintCollection?.resolvingSubjectInformationSection?.abbProductSerialNo == this.additionalSubjectForm.controls['abbProductSerialNo'].value
    || this.serialData;
  }

  buttonVisible() {
    return this.additionalSubjectForm.controls['BuCode']?.value! == 'PGHV' && ((this.complaintData == undefined) ? true : this.complaintData?.header?.complaintStatus !== 8);
  }

  validSerialNos:any;
  inValidSerialNos:any;
  alreadyMappedSerialNos:any;

  serialNumberVerify() {
    this.validSerialNos = [];
    this.inValidSerialNos = [];
    this.alreadyMappedSerialNos = [];
    this.serialNoArray = [];
    if (this.fieldNameForFailureMode == 'SerialNo') {
      this.failureMode.emit('SerialNo');
    }
    this.serialData = true;
    let serialarray = this.additionalSubjectForm.controls['abbProductSerialNo'].value.split(",").map((tag) => tag.trim()).filter((tag) => tag.length !== 0);
    let articleNumber = this.additionalSubjectForm.controls['articleNumber']?.value;
    let supplierGEID = this.additionalSubjectForm.controls['supplierData']?.value;
    if(serialarray.length>0){
    this.spinner.show();
    let validatedSerialArray = serialarray.map(function (item) {
      return {
        "serialNumber": item,
        "articleNumber": articleNumber,
        "supplierguId": supplierGEID ? supplierGEID.identifier : '',
        "productionGroupID": ""
      }
    }
    );
    let serialVerifyObj={
      "ccrpDetails":validatedSerialArray
    }

    this.newIssueService.ValidateSerialNoFromDPS(serialVerifyObj).subscribe(
      (response:any) =>{
        this.spinner.hide();
        this.validSerialNos= response.filter(item=>item.isValid==1).map(a=>a.serialNumber);
        this.inValidSerialNos = response.filter(item=>item.isValid==0).map(a=>a.serialNumber);
        this.alreadyMappedSerialNos = response.filter(item => item.isValid == 2).map(a => a.serialNumber);

        if(this.inValidSerialNos.length>0){
          this.toasterService.showError(`Invalid Serial Nos : ${this.inValidSerialNos.toString()}`)
        }
        if(this.validSerialNos.length>0){
          this.toasterService.showSuccess(`Valid Serial Nos : ${this.validSerialNos.toString()}`)
        }
        if(this.alreadyMappedSerialNos.length>0){
          this.toasterService.showError(`Below Serial Numbers are mapped with more than one record in DPS : ${this.alreadyMappedSerialNos.toString()}`)
        }

        // Here I am assume this return as true from api.
         this.isVerified = true;
         this.additionalSubjectForm.patchValue({
           isVerifiedSerial: true,
           modificationFlagForDps: true,
           abbProductSerialNo:this.additionalSubjectForm.controls['abbProductSerialNo'].value.split(",").map((tag) => tag.trim()).filter((tag) => tag.length !== 0).join(),
           updatedSerialNosList:this.additionalSubjectForm.controls['abbProductSerialNo'].value.split(",")
         })
         this.splitSerialNoForPageLoad();
         this.serialData = true
      },
      (err)=>{
        this.spinner.hide();
        if(this.additionalSubjectForm.controls['abbProductSerialNo'].value !== ''){
          this.toasterService.showError(`Error occured while verifying serial no's`);
        }
        this.isVerified = false
        this.serialData = false
      }
    )
    }else{
      this.isVerified = false
    }

  }

  apiCallforValidatingDPSNos() {
    if (this.additionalSubjectForm.controls['BuCode']?.value! == 'PGHV') {
      let serialarray = this.additionalSubjectForm.controls['abbProductSerialNo'].value.split(",").map((tag) => tag.trim()).filter((tag) => tag.length !== 0);
      let articleNumber = this.additionalSubjectForm.controls['articleNumber']?.value;
      let supplierGEID = this.additionalSubjectForm.controls['supplierData']?.value;
      if(serialarray.length>0){
        let validatedSerialArray = serialarray.map(function (item) {
          return {
            "serialNumber": item,
            "articleNumber": articleNumber,
            "supplierguId": supplierGEID ? supplierGEID.identifier : '',
            "productionGroupID": ""
          }
        }
        );
        let serialVerifyObj = {
          "ccrpDetails": validatedSerialArray
        }

        this.newIssueService.ValidateSerialNoFromDPS(serialVerifyObj).subscribe(
          (response: any) => {
            this.validSerialNos = response.filter(item => item.isValid == 1).map(a => a.serialNumber);
            this.inValidSerialNos = response.filter(item => item.isValid == 0).map(a => a.serialNumber);
            this.alreadyMappedSerialNos = response.filter(item => item.isValid == 2).map(a => a.serialNumber);
            // Here I am assume this return as true from api.
            if(response.length > 0){
              this.isVerified = true
            }
            this.additionalSubjectForm.patchValue({
              isVerifiedSerial: true,
              abbProductSerialNo: this.additionalSubjectForm.controls['abbProductSerialNo'].value.split(",").map((tag) => tag.trim()).filter((tag) => tag.length !== 0).join(),
              updatedSerialNosList: this.additionalSubjectForm.controls['abbProductSerialNo'].value.split(",")
            })
            this.splitSerialNoForPageLoad();
          },
          (err) => {
          }
        )
      }
    }else{
      this.validSerialNos = this.additionalSubjectForm.controls['abbProductSerialNo'].value.split(",");
    }
  }

  serialNoRedirection(e) {
    window.open(this.getDPSLinkURLData?.dpsWebsiteURL + "?&ccrpnumber=" + btoa(this.complaintData?.header?.id) + "&serialnumber=" + btoa(e.trim()) + "&appid=" + btoa(this.getDPSLinkURLData?.appId) + "&EmailId=" + btoa(this.getDPSLinkURLData?.email), '_blank');
  }

  checkNumeric($event) {
    // console.log('serial No :',$event, window )
    if (window.event) // IE
    {
      if (($event.keyCode < 48 || $event.keyCode > 57 || ($event.key == '!' || $event.key == '@' || $event.key == '#' || $event.key == '$' || $event.key == '%' || $event.key == '^' || $event.key == '&' || $event.key == '*' || $event.key == '(' || $event.key == ')')) && $event.keyCode != 188 && $event.keyCode != 46 && $event.keyCode != 8 && $event.keyCode != 16) {
        // console.log('check 1st IF ==>')
        // this.additionalSubjectForm.patchValue({
        //   abbProductSerialNo:this.additionalSubjectForm.controls['abbProductSerialNo'].value.replace($event.key,'')
        //   serialNo:this.additionalSubjectForm.controls['serialNo'].value.replace($event.key,'')
        // })
        $event.returnValue = false;
        //  return false;
      }
    }
    else { // Fire Fox
      if (($event.which < 48 || $event.which > 57 || ($event.key == '!' || $event.key == '@' || $event.key == '#' || $event.key == '$' || $event.key == '%' || $event.key == '^' || $event.key == '&' || $event.key == '*' || $event.key == '(' || $event.key == ')')) && $event.which != 188 && $event.which != 46 && $event.which != 8 && $event.which != 16) {
        // console.log('check 2nd IF ==>')
        // this.additionalSubjectForm.patchValue({
        //   abbProductSerialNo:this.additionalSubjectForm.controls['abbProductSerialNo'].value.replace($event.key,'')
        //   serialNo:this.additionalSubjectForm.controls['serialNo'].value.replace($event.key,'')
        // })
        $event.preventDefault();
        //  return false;
      }
    }
  }




  authorizationBtnDisable() {
    if (this.userAccessData) {
      switch (this.userAccessData['roleCode']) {
        case this.enumRoleCode.Complaint_Owner:
          // this.disableBtn['resolvingUnit'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [1, 2], [214], this.userAccessData);
          //  this.disableBtn['issueProduct'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [1,2,3,4,5,11,12,7], [209], this.userAccessData);
          //  this.disableBtn['failureMode'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [1,2,3,4,5,11,12,7], [209], this.userAccessData);
          //  this.disableBtn['project'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [1,2,3,4,5,11,12,7], [209], this.userAccessData);
          //  this.disableBtn['supplier'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [1,2,3,4,5,11,12,7], [209], this.userAccessData);
          break;
        case this.enumRoleCode.Complaint_Coordinator:
          break;
        case this.enumRoleCode.Global_CCRP_Process_Owner:
          break;
        case this.enumRoleCode.Support_Desk:
          break;
        case this.enumRoleCode.Complaint_Owner_Supervisor:
          break;
        case this.enumRoleCode.Complaint_Owner_Team:
          break;
        case this.enumRoleCode.Resolution_Owner:
          break;
        case this.enumRoleCode.Resolution_Owner_Team:
          break;
        case this.enumRoleCode.Ru_Process_Owner:
          break;
        case this.enumRoleCode.Resource_Editor:
          break;
        case this.enumRoleCode.Div_Process_Owner:
          break;
        case this.enumRoleCode.Bu_Process_Owner:
          break;
        case this.enumRoleCode.Pg_Process_Owner:
          break;
        case this.enumRoleCode.Hub_Process_Owner:
          break;
      }
      // return this.disableSection
    }
  }

  @Output() isResolvingUnitSelectionPerfomred = new EventEmitter();

  showResolvingUnitComponentPopup() {
    this.ref = this.dialogService.open(ResolvingUnitSelectPopUpComponent, {
      header: 'Resolving Unit selection ',
      width: '70%',
      position: 'top',
      keepInViewport: true,
      autoZIndex: true, data: { matrxiData: this.impactData, processStep: this.selectedProcessStepId },
      styleClass: 'resolving-unit-pop'
    });

    this.ref.onClose.subscribe((receivedValue: any) => {
      if (receivedValue) {
        this.coordinators = receivedValue?.coordinatorData;
        this.verificationTeamData = receivedValue?.vTeamData;
        this.setResolvingUnit(receivedValue?.selectedData);
        this.additionalSubjectForm.patchValue({
          hiddenIsHVDCComplaint: receivedValue.HVDCFlag
        })
      this.isResolvingUnitSelectionPerfomred.emit({ resolvingUnitChanged: true  });
      }
    })
  }

  /*set resolving unit data */
  setResolvingUnit(receivedValue) {
    /* receivedValue?.mail?.forEach(coordinator => {
       let filteredCoordinator = this.users.filter(user => user.geid == coordinator.Geid);
       this.coordinators.push(filteredCoordinator[0]);
     });*/
    this.resolvingUnitId = receivedValue?.dbId ? receivedValue?.dbId : receivedValue?.id;
    let resolvingUnit = receivedValue?.displayLabel ? receivedValue?.displayLabel : (this.checkBlank(receivedValue?.balId) + " " + this.checkBlank(receivedValue?.abacus) + " " + this.checkBlank(receivedValue?.activity) + " " + this.checkBlank(receivedValue?.mgmtOrg)
      + " " + this.checkBlank(receivedValue?.productGroup) + " " + this.checkBlank(receivedValue?.address));
    const resolvingUnitArray = { isActive: receivedValue?.isAvtive, id: this.resolvingUnitId, pgCode: receivedValue?.pgCode, identification: { id: (this.resolvingUnitId)?.toString() }, displayLabel: receivedValue?.displayLabel, tooltipInfo: receivedValue?.tooltipInfo, isValid: receivedValue?.isValid, value: resolvingUnit }
 // console.log("=========",receivedValue?.buCode ? receivedValue?.buCode : receivedValue?.bu,StaticvalueConstants?.ExceptionValidationRequiredBUCodes?.indexOf(receivedValue?.buCode ? receivedValue?.buCode : receivedValue?.bu),StaticvalueConstants?.ExceptionValidationRequiredBUCodes?.indexOf(receivedValue?.buCode ? receivedValue?.buCode : receivedValue?.bu) === -1);
    this.additionalSubjectForm.patchValue({
      resolvingUnit: resolvingUnit,
      Coordinators: this.coordinators,
      verificationTeam: this.verificationTeamData,
      resolvingUnitArray: resolvingUnitArray,
      BuCode: receivedValue?.buCode ? receivedValue?.buCode : receivedValue?.bu,
      IsBUExceptionFieldsMandatory: StaticvalueConstants?.ExceptionValidationRequiredBUCodes?.indexOf(receivedValue?.buCode ? receivedValue?.buCode : receivedValue?.bu) === -1 ? false : true
    });
    // console.log("=====",this.additionalSubjectForm);
    this.resolvingUnitTooltipText = this.checkBlank(receivedValue?.tooltipInfo);
    this.setFieldsValidation(this.ActionNameForComplaints);
  }

  productPopupDialog() {
    this.ref = this.dialogService.open(ProductPopupComponent, {
      header: 'Select Product category',
      width: '70%',
      position: 'top',
      keepInViewport: true,
      autoZIndex: true,
      styleClass: 'product-pop',
      data: this.resolvingUnitId
    });

    this.ref.onClose.subscribe((receivedValue: any) => {
      if (receivedValue) {
        this.setProduct(receivedValue);
      }
    })
  }
  /* Set Product data */
  setProduct(receivedValue) {
    const productArrayBind = receivedValue ? { cid: receivedValue?.cid, name: receivedValue?.name, isActive: receivedValue?.isActive, identification: { id: receivedValue?.id, cid: receivedValue?.cid }, displayLabel: receivedValue?.displayLabel, tooltipInfo: receivedValue?.tooltipInfo, isValid: true } : null
    setTimeout(() => {
      this.additionalSubjectForm.patchValue({
        ABBProductCategory: receivedValue?.name,
        issueProductArray: productArrayBind
      })
    }, 500);

    this.productInputText = receivedValue?.tooltipInfo;
  }

  /* Supplier selection pop up */
  supplierSelectionPopUp() {
    this.ref = this.dialogService.open(SupplierSelectionPopupComponent, {
      header: 'Supplier selection',
      width: '70%',
      position: 'top',
      keepInViewport: true,
      autoZIndex: true,
      styleClass: 'supplier-pop'
    });

    this.ref.onClose.subscribe((receivedValueSupplier: any) => {

      if (receivedValueSupplier) {
        this.setSuplier(receivedValueSupplier);
      }
    })
  }

  /* Set suplier */
  setSuplier(receivedValueSupplier) {
    // console.log(receivedValueSupplier);
    if (receivedValueSupplier?.dbId && receivedValueSupplier?.dbId != -1) {
      receivedValueSupplier.id = receivedValueSupplier?.dbId;
    }
    else if (receivedValueSupplier?.dbId == -1) {
      receivedValueSupplier.id = null
    }
    else { }
    if (!receivedValueSupplier?.identification) {
      let identification = {
        "id": receivedValueSupplier.id,
        "geid": receivedValueSupplier?.identifier,
        "email": null,
        "cid": null,
        "gis_GUID": receivedValueSupplier?.identifier,
        "email_Alias": null
      };
      receivedValueSupplier.identification = identification;
    }

    const concatValue1 = receivedValueSupplier?.displayLabel;// receivedValueSupplier?.supplierFullName ? (receivedValueSupplier?.supplierFullName + " " + receivedValueSupplier?.supplierAddress) : '';
    this.additionalSubjectForm.patchValue({
      supplier: concatValue1,
      supplierData: receivedValueSupplier
    })
    this.supplierExternalOnlyText = receivedValueSupplier?.tooltipInfo;
  }

  /* pro MIS project selction pop up */
  proMISSelectionPopUp() {
    this.ref = this.dialogService.open(ProMISProjectSelectPopUpComponent, {
      header: 'ProMIS Project selections',
      width: '70%',
      position: 'top',
      keepInViewport: true,
      autoZIndex: true,
      styleClass: 'proMis-project-selection-pop'
    });

    this.ref.onClose.subscribe((receivedValueProMIS: any) => {

      if (receivedValueProMIS) {
        this.setProject(receivedValueProMIS);
      }
    })
  }

  setProject(receivedValueProMIS) {
    const concatValue2 = receivedValueProMIS.business + " " + receivedValueProMIS.bu + " " + receivedValueProMIS.pg;

    this.proMISCountryName = receivedValueProMIS.siteCountry;

    this.additionalSubjectForm.patchValue({
      abbProjectNumber: receivedValueProMIS.projectNumber,
      abbProjectName: receivedValueProMIS.projectName
    })
    this.projectTooltipText = receivedValueProMIS.projectNumber;
  }

  showComponent() {
    this.ref = this.dialogService.open(FailureModePopupComponent, {
      header: 'Select Failure mode',
      width: '70%',
      position: 'top',
      keepInViewport: true,
      autoZIndex: true,
      styleClass: 'failure-mode-pop',
      data: { resolvingUnitId: this.resolvingUnitId, selectedBuCode: this.additionalSubjectForm.controls['BuCode']?.value }
    });

    this.ref.onClose.subscribe((receivedValue: any) => {
      if (receivedValue) {
        //  console.log(receivedValue);
        this.setFailureMode(receivedValue);
        // this.changeValidationOnFailureModeChange(receivedValue);
      }
    })
  }

  showDefectTypePopup() {
    //this.balIdPass = this.complaintData?.areaComplaintCollection?.complaintInformationSection?.descriptionSection?.whereIssueDescription.split(',')[0];
    this.balIdPass = this.complaintData?.whereWasTheIssueDetectedId;
    if(this.balIdPass == null){
      this.balIdPass = this.issueCaptureMainForm?.get('issueDescriptionForm')?.get('whereWasTheIssueDetectedId')?.value
    }
    const defectTypePayload = { parentId: '' , resolvingUnitId: this.resolvingUnitId, balId: this.balIdPass, isDefectType: true }
    this.ref = this.dialogService.open(DefectTypePopupComponent, {
      header: 'Defect Type Selection',
      width: '60%',
      position: 'top',
      keepInViewport: true,
      autoZIndex: true,
      styleClass: 'defect-type-pop',
      data: { defect: defectTypePayload }
    });
    this.ref.onClose.subscribe((receivedValue: any) => {
      if (receivedValue) {
        this.additionalSubjectForm.patchValue({
          DefectTypeandDefectCodeGroupType: receivedValue?.name,
          defectTypeHiddenField: receivedValue,
          isQnNumberGeneratedCheck: true
        })
        this.defectTypeTooltip = receivedValue?.tooltipInfo;
      }
    })
  }
  /*Set failure Mode */
  async setFailureMode(receivedValue) {
    const failureModeData = { id: receivedValue?.id, displayLabel: receivedValue?.displayLabel, tooltipInfo: receivedValue?.tooltipInfo, isValid: true, identification: receivedValue?.identification, description: receivedValue?.description ? receivedValue?.description : null };
    this.additionalSubjectForm.patchValue({
      failureMode: receivedValue?.displayLabel,
      failureModeBind: failureModeData
    })
    this.fieldNameForFailureMode = receivedValue?.validation?.split('_');
    this.failureMode.emit(receivedValue?.validation?.split('_'));
    // console.log("<><><>",this.additionalSubjectForm);
    this.failureModeInputText = receivedValue?.tooltipInfo;
    await this.setFieldsValidation(this.ActionNameForComplaints);
  }

  openCalendarDeliveryDate(event: any, check: any) {
    this.calendarDeliveryDate.inputfieldViewChild.nativeElement.click();
    event.stopPropagation();
  }

  openCalendarCommissioningDate(event: any, check: any) {
    this.calendarCommissioningDate.inputfieldViewChild.nativeElement.click();
    event.stopPropagation();
    this.commissioningDateText = this.additionalSubjectForm.controls['commissionDate'].value;
  }

  openCalendarManufactureDate(event: any, check: any) {
    this.calendarManufactureDate.inputfieldViewChild.nativeElement.click();
    event.stopPropagation();
    this.manufacturingDateText = this.additionalSubjectForm.controls['ManufacturingDate'].value;
  }

  openCalendarWarrantyStart(event: any, check: any) {
    this.calendarWarrantyStart.inputfieldViewChild.nativeElement.click();
    event.stopPropagation();
    this.warrantyStartDateText = this.additionalSubjectForm.controls['warrantyStartDate'].value;
  }

  openCalendarWarrantyEnd(event: any, check: any) {
    this.calendarWarrantyEnd.inputfieldViewChild.nativeElement.click();
    event.stopPropagation();
    this.warrantyEndDateText = this.additionalSubjectForm.controls['warrantyEndDate'].value;
  }

  deliveryDateOnSelect() {
    //  console.log("this.additionalSubjectForm.controls['deliveryDate'].value ))))))",this.additionalSubjectForm.controls['deliveryDate'].value)

    this.deliveryDateText = this.additionalSubjectForm.controls['deliveryDate']?.value ? 'Local: ' + formatDate(new Date(this.additionalSubjectForm.controls['deliveryDate'].value), 'yyyy-MM-dd 05:29:59', 'en') : '';
  }

  manfacturingDateOnSelect() {
    this.manufacturingDateText = this.additionalSubjectForm.controls['ManufacturingDate']?.value ? 'Local: ' + formatDate(new Date(this.additionalSubjectForm.controls['ManufacturingDate'].value), 'yyyy-MM-dd 05:29:59', 'en') : '';
  }

  commissioningDateOnSelect() {
    this.commissioningDateText = this.additionalSubjectForm.controls['commissionDate']?.value ? 'Local: ' + formatDate(new Date(this.additionalSubjectForm.controls['commissionDate'].value), 'yyyy-MM-dd 05:29:59', 'en') : '';
  }

  warrantyStartOnSelect() {
    this.warrantyStartDateText = this.additionalSubjectForm.controls['warrantyStartDate']?.value ? 'Local: ' + formatDate(new Date(this.additionalSubjectForm.controls['warrantyStartDate'].value), 'yyyy-MM-dd 05:29:59', 'en') : '';
  }

  warrantyEndOnSelect() {
    this.warrantyEndDateText = this.additionalSubjectForm.controls['warrantyEndDate']?.value ? 'Local: ' + formatDate(new Date(this.additionalSubjectForm.controls['warrantyEndDate'].value), 'yyyy-MM-dd 05:29:59', 'en') : '';
  }

  country() {
    this.countryEmit.emit();
    // const customerDataValue = this.customerNameData.get('customerNameData').value;
    // const guID = customerDataValue?.guid;
    // if (guID) {
    //   this.countryService.setCountryFillIn(guID).subscribe((response) => {
    //     if (response) {
    //       const allResponse = response;
    //       this.additionalSubjectForm.patchValue({
    //         countryhappened: allResponse.countryCode,
    //       });
    //     }
    //   })
    // }
    // else {
    //   this.messageService.add({
    //     severity: 'warn',
    //     summary: 'Please select Customer to use "Fill in" button'
    //   })
    // }
  }

  proMISCountry() {
    let countryCode = this.proMISCountryName.substring(this.proMISCountryName.indexOf('(') + 1, this.proMISCountryName.indexOf(')'));
    //let proMISfilteredOwner;
    //proMISfilteredOwner = this.countries.filter(country => country.name?.toLowerCase() == this.proMISCountryName.toLowerCase());
    this.additionalSubjectForm.patchValue({
      CountryCodeWhereTheIssueHappen: this.countries.filter(country => country?.id?.toLowerCase() == countryCode?.toLowerCase())[0],
    });
    this.toasterService.showSuccess('Country where the issue happened updated successfully');
  }

  /* Fiter Owner */
  filterOwner(query) {
    this.usersService.getFilteredUser(query).subscribe((res) => {
      this.filteredOwner = res
    })
    // let query = event.query;
    // this.filteredOwner = this.users.filter(user => user.promptLabel?.toLowerCase()?.includes(query.toLowerCase()));
  }
  clearOwner() {
    this.additionalSubjectForm.patchValue({
      proposedResolutionOwner: [],
    })
  }
  ownerGotFocus(value) {
    this.proposedResolutionOwnerText = value;
  }
  /* When issue owner lost focus */
  ownerLostFocus() {
    this.proposedResolutionOwnerText = '';
  }
  /* Fiter Owner */
  filterPartiesConnected(query) {
    // this.filteredPartiesConnected = this.users.filter(user => user.promptLabel?.toLowerCase()?.includes(query.toLowerCase()));
    this.usersService.getFilteredUser(query).subscribe((res) => {
      this.filteredPartiesConnected = res
    })
  }
  coordinatorGotFocus(coordinator) {
    this.issueCoordinatorText = coordinator;
  }
  coordinatorLostFocus() {
    this.issueCoordinatorText = '';
  }
  partiesConectedGotFocus(partiesConected) {
    this.partiesConnectedText = partiesConected;
  }
  partiesConectedLostFocus() {
    this.partiesConnectedText = 'Related Parties';
  }
  projectCheck() {
    if (this.additionalSubjectForm.controls['abbProjectName'].value != '' && this.additionalSubjectForm.controls['abbProjectNumber'].value != '') {
      this.newIssueService.checkProjectExists(this.additionalSubjectForm.controls['abbProjectNumber'].value, this.additionalSubjectForm.controls['abbProjectName'].value).subscribe((res) => {
        // console.log("???",res);
        if (res?.proMISItem) {
          this.setProject(res?.proMISItem);
        }
        else {
          this.messageService.add({
            severity: 'warn',
            summary: 'Project ID and Project name does not match.'
          });
        }
      })
    }
  }

  disableDefectType(){
    if(this.complaintData?.sapComplaintInfo?.isQnNumberGenerated == true){
      return false;
    }
    else if(this.issueCaptureMainForm?.get('headerSection')?.get('complaintStatusId')?.value < 4){
      return true;
    }
    else if(this.issueCaptureMainForm?.get('issueDescriptionForm')?.get('whereIssueDescription')?.value == '' || this.issueCaptureMainForm?.get('issueDescriptionForm')?.get('whereIssueDescription')?.value == null){
      return true;
    }
    else{
      return false;
    }
  }

  removeInvalidSerailNo(serialNoIndex:any){
    const removedSerialValue = this.inValidSerialNos[serialNoIndex];
    let  currentSerialNoArray = [];
    this.inValidSerialNos.splice(serialNoIndex, 1);
    if (this.additionalSubjectForm.controls['abbProductSerialNo'].value) {
      currentSerialNoArray = this.additionalSubjectForm.controls['abbProductSerialNo'].value.split(",");
      const index = currentSerialNoArray.indexOf(removedSerialValue);
      if (index !== -1) {
        currentSerialNoArray.splice(index, 1);
        this.additionalSubjectForm.patchValue({
          isVerifiedSerial: false,
          abbProductSerialNo: currentSerialNoArray.toString(),
          updatedSerialNosList:this.additionalSubjectForm.controls['abbProductSerialNo'].value.split(",")
        })
        this.serialNumberVerify();
      }

    }

  }

  removeAlreadyMappedSerailNo(serialNoIndex:any){
    const removedSerialValue = this.alreadyMappedSerialNos[serialNoIndex];
    let  currentSerialNoArray = [];
    this.alreadyMappedSerialNos.splice(serialNoIndex, 1);
    if (this.additionalSubjectForm.controls['abbProductSerialNo'].value) {
      currentSerialNoArray = this.additionalSubjectForm.controls['abbProductSerialNo'].value.split(",");
      const index = currentSerialNoArray.indexOf(removedSerialValue);
      if (index !== -1) {
        currentSerialNoArray.splice(index, 1);
        this.additionalSubjectForm.patchValue({
          isVerifiedSerial: false,
          abbProductSerialNo: currentSerialNoArray.toString(),
          updatedSerialNosList:this.additionalSubjectForm.controls['abbProductSerialNo'].value.split(",")
        })
        this.serialNumberVerify();
      }

    }

  }
}
