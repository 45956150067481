import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-access-restriction-confidential',
  templateUrl: './access-restriction-confidential.component.html',
  styleUrls: ['./access-restriction-confidential.component.css']
})
export class AccessRestrictionConfidentialComponent implements OnInit {
  constructor(public ref: DynamicDialogRef, public configData: DynamicDialogConfig) { }

  ngOnInit(): void {}

  onClose(){
    this.ref.close(this.configData?.data?.confidentialSetting);
  }

  confirm(){
    this.ref.close('confirm');
  }

}
