import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ReportColumnService {
  constructor(private http: HttpClient) { }
  issueStatus=[
    {id: '2', name: "Draft"},
    {id: '3', name: "Not Assigned"},
    {id: '4', name: "In Process"},
    {id: '5', name:"In Process Overdue"},
    {id: '11', name: "In Process Verification"},
    {id: '12', name: "In Process Verification Overdue"},
    {id: '7', name: "Resolved"},
    {id: '13', name: "Completed (Execution Pending)"},
    {id: '8', name:"Completed"}

  ]
  getIssueStatus() {
    return this.issueStatus
  }

  getColumn(){
    return this.columns
  }

  columns = [

    { code: 'All', name: "All"},
    { code: '01', name: "CCRP No." },
    { code: '02', name: "Issue Status" },
    { code: '03', name: "Creation Date" },
    { code: '04', name: "Submit Date" },
    { code: '05', name: "Assigned Date" },
    { code: '06', name: "Resolved Date" },
    { code: '07', name: "Completion Date" },
    { code: '08', name: "Update Date" },
    { code: '09', name: "Return To Issue Owner Date" },
    { code: '10', name: "Return To RO Date (UTC+5)" },
    { code: '11', name: "Resolution Verified Date" },
    { code: '12', name: "Issue Owner" },
    { code: '13', name: "Issue Owner Mngr Lv1" },
    { code: '14', name: "Issue Owner Mngr Lv2" },
    { code: '15', name: "Issue Owner City" },
    { code: '16', name: "Issue Owner Country" },
    { code: '17', name: "Issue Owner Substitutes" },
    { code: '18', name: "Issue Owner Supervisor" },
    { code: '19', name: "Verification Required" },
    { code: '20', name: "Issue Type" },
    { code: '21', name: "Customer name" },
    { code: '22', name: "Customer contact name" },
    { code: '23', name: "Issue Summary" },
    { code: '24', name: "High Impact" },
    { code: '25', name: "Impact Reason" },
    { code: '26', name: "Create RCA and corrective actions" },
    { code: '27', name: "Target Resolution / Verification Date(UTC)" },
    { code: '28', name: "Abacus Code" },
    { code: '29', name: "Resolving Unit Country" },
    { code: '30', name: "Resolving Unit City" },
    { code: '31', name: "Resolving Unit Business" },
    { code: '32', name: "Resolving Unit BU" },
    { code: '33', name: "Resolving Unit PG" },
    { code: '34', name: "Resolving Unit Descriptive Name" },
    { code: '35', name: "Proposed Resolution Owner" },
    { code: '36', name: "Issue Coordinators" },
    { code: '37', name: "Project ID" },
    { code: '38', name: "Project Name" },
    { code: '39', name: "Customer Reference" },
    { code: '40', name: "Days left to Target Resolution / Verification Date" },
    { code: '41', name: "Purchase Order No." },
    { code: '42', name: "Product Name" },
    { code: '43', name: "Serial No." },
    { code: '44', name: "Resolution Owner" },
    { code: '45', name: "Resolution Owner Team" },
    { code: '46', name: "What containment actions were taken?" },
    { code: '47', name: "Effectiveness and Sustainability Check Date" },
    { code: '48', name: "Quantity" },
    { code: '56', name: "Opq Category" },
    { code: '57', name: "RCA Category Name" },
    { code: '58', name: "Closure Comments" },
    { code: '59', name: "Issue Reason from NPS" },
    { code: '60', name: "Customer Location" },
    { code: '61', name: "Issue Subject" },
    { code: '62', name: "Warranty Reference" },
    { code: '63', name: "Warranty Start Date" },
    { code: '64', name: "Warranty End Date" },
    { code: '65', name: "Commissioning Date" },
    { code: '66', name: "Manufacturing Date" },
    { code: '67', name: "Solution sent to Issue Owner date" },
    { code: '68', name: "Resolving Unit BA Type" },
    { code: '69', name: "Resolving Unit Activity" },
    { code: '70', name: "Resolving Unit Region" },
    { code: '71', name: "Resolving Unit BALID" },
    //commented this based on story US-3200
    /*{ code: '72', name: "Resolving Unit Address" },*/
    { code: '73', name: "What is the problem?" },
    { code: '74', name: "Why is this a problem?" },
    { code: '75', name: "How do we know we have a problem?" },
    { code: '76', name: "Where did the issue occur?" },
    { code: '77', name: "What are the customer’s expectations with respect to the resolution of this case?" },
    { code: '78', name: "What was the root cause of this issue?" },
    { code: '79', name: "Details of Correction provided?" },
    { code: '81', name: "Customer Leading Business Partner Segment" },
    { code: '82', name: "Customer Industry Usage" },
    { code: '83', name: "Customer Channel Class" },
    { code: '84', name: "Potential Business Impact" },
    { code: '85', name: "Customer HQ" },
    { code: '86', name: "Customer Satisfaction Score" },
    { code: '87', name: "Customer Effort Score" },
    { code: '88', name: "Customer Feedback" },
    { code: '89', name: "Failure Mode Lvl_1-6" },
    { code: '90', name: "Failure Mode description" },
    { code: '91', name: "Containment Actions Deadline" },
    { code: '92', name: "Containment Actions Execution Date" },
    { code: '93', name: "High Impact Category" },
    { code: '94', name: "Customer Contact Email" },
    { code: '95', name: "Issue Owner Mngr Lv3" },
    { code: '96', name: "Please explain what containment actions are required?" },
    { code: '97', name: "When did the issue occur?" },
    { code: '98', name: "Expected Execution Date" },
    { code: '99', name: "Completion After Customer Execution Date" },
    { code: '51', name: "Original Rating RPN" },
    //commented this based on story US-3200
    /*{ code: '52', name: "Original Rating Category" },*/
    { code: '53', name: "Original Rating Class" },
    { code: '50', name: "Current Rating RPN" },
    //commented this based on story US-3200
  /*  { code: '54', name: "Current Rating Category" },*/
    { code: '55', name: "Current Rating Class" },

    { code: '(1', name: "Impact on the Installed Base" },
    { code: '(2', name: "Total Installed Base" },
    { code: '(3', name: "Estimated cost kUSD$" },
    { code: '(4', name: "Process Step levels" },
    { code: '(5', name: "COPQ Category" },
    { code: '(6', name: "Country where the issue happened" },
    { code: '(7', name: "Other/Document ID" },
    { code: '(8', name: "Part Number" },
    { code: '(9', name: "Item Designation" },
    { code: '$1', name: "Q-Number Issuing Organization" },
    { code: '$2', name: "Q-Number Resolving Unit" },
    { code: '$3', name: "Product already delivered?" },
    { code: '$4', name: "Number of units already shipped" },
    { code: '$5', name: "Specification Name" },
    { code: '$6', name: "How does the product fail to meet the specification?" },
    { code: '$7', name: "Confidential" },
    { code: '$8', name: "Issue Source" },
    { code: '$9', name: "Complaint Attachments" },
    { code: '*1', name: "Containment Actions Required" },
    { code: '*2', name: "No Report to Hitachi" },
    { code: '*3', name: "Justification" },
    { code: '*4', name: "Supplier" },
    { code: '*5', name: "Send a copy to all interested parties connected with this issue" },
    { code: '*6', name: "RCA and corrective actions required" },
    { code: '*7', name: "Link to issue with the same RCA" },
    { code: '*8', name: "RCA and corrective actions not required" },
    { code: '*9', name: "Is solution in-process verification required?" },
    { code: '#1', name: "In-process solution verification done by" },
    { code: '#2', name: "Upload RCA" },
    { code: '#3', name: "The solution is waiting for execution by the customer" },
    { code: '#4', name: "Attachment Summary Overview" },
    { code: '#5', name: "Customer Closure Comments" },
    { code: '#6', name: "(Closure) Attachments" },
    { code: '#7', name: "Corrective Action Effective and Sustainable" },
    { code: '#8', name: "Effectiveness Deadline Date" },
    { code: '#9', name: "Describe objective evidence as to why the corrective / preventive action was not effective or not required?" },
    { code: '@1', name: "(Objective Evidence) Attachment" },
    { code: '@2', name: "Reopen Issue Date" },
    { code: '@3', name: "Customer Confirmed Date" },
    { code: '@4', name: "Delivery Date" },
    { code: '@5', name: "Category Keyword" },
    { code: '@6', name: "Issue Capture Data Percentage" }
];

}
