<div class="row mb-5 mt-3 escalation-section">
  <div class="col-5">
    <label style="float: right; font-weight: bolder;">{{item.divisionName}}</label>
  </div>
  <div class="col-1">
    <div *ngIf="status === 'draft'">
      <select [disabled]="!isInEditMode" class="form-control escalation-settings-control" [disabled]="!isInEditMode" [(ngModel)]="item.draft">
        <option *ngFor="let h of hours" value="{{h}}">
          {{h}}
        </option>
      </select>
    </div>
    <div *ngIf="status === 'notAssigned'">
      <select  [disabled]="!isInEditMode" class="form-control escalation-settings-control" [(ngModel)]="item.notAssigned">
        <option *ngFor="let h of hours" value="{{h}}">
          {{h}}
        </option>
      </select>
    </div>
  </div>
  <div class="col-1 row-gap-top-indraft">
    <span style="padding-left: 0; float: left; font-weight: bolder;" class="lbl-align-responsive"><label>(h)</label></span>
  </div>
  <div class="col">
    <button *ngIf="!isInEditMode && allowEdit" class="btn widgetButtonSmall" (click)="edit(item)">Edit</button>
    <button *ngIf="isInEditMode" class="btn widgetButtonSmall save-row-gap" (click)="save(item)">Save</button>
    <button *ngIf="isInEditMode" class="btn widgetButtonSmall cancel-row-gap" (click)="cancel()">Cancel</button>
  </div>
</div>
