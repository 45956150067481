<div class="accessRestriction-popup">
    <div class="alert-box">
          <div class="popup-content" [innerHtml]="configData?.data?.restrictionKey | translate"></div>

          <div class="row">
            <div class="col text-end mt-3">
                <span>
                    <button class="widgetButtoSmall me-2" (click)="confirm()">{{ "ButtonConfirm" | translate }}</button>
                    <button class="widgetButtoSmall" (click)="onClose()">{{ "ButtonCancel" | translate }}</button>
                </span>
            </div>
          </div>
        </div>
      </div>
