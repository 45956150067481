import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AdminGobalRuService {

  constructor(private http: HttpClient) { }

  // getGlobalDetails(): Observable<any> {
  //   return this.http.get(`${environment.azure.baseUrl}/api/v1/globalBALs/all`)
  // }

  // getCreate(data): Observable<any>{
  //   return this.http.post(`${environment.azure.baseUrl}api/v1/globalBALs/create`, data)
  // }

  // getUpdate(id): Observable<any>{
  //   return this.http.post(`${environment.azure.baseUrl}api/v1/globalBALs/update`, id)
  // }

  getGlobalRus(){
    return this.http.get<any>(`${environment.azure.baseUrl}api/v1/globalBALs/all`)
  }

  createGlobalRu(globalRuScope){
    return this.http.post<any>(`${environment.azure.baseUrl}api/v1/globalBALs/create`, {divId: globalRuScope.divId, buId: globalRuScope.buId, pgId: globalRuScope.pgId})
  }

  updateGlobalRu(globalRuScope, isActive){
    return this.http.post<any>(`${environment.azure.baseUrl}api/v1/globalBALs/update`, { divId: globalRuScope.divId, buId: globalRuScope.buId, pgId: globalRuScope.pgId, isActive: isActive })
  }
}
