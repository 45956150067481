import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { WorkCenterService } from 'src/app/services/work-center.service';

@Component({
  selector: 'app-work-center-selection-pop-up',
  templateUrl: './work-center-selection-pop-up.component.html',
  styleUrls: ['./work-center-selection-pop-up.component.css']
})

export class WorkCenterSelectionPopUpComponent implements OnInit {
  workCenterDetails:Array<any>=[];

  first: number = 0;
  firstIndex: number = 0;
  rows: number = 6;
  loading: boolean = false;

  constructor(private ref: DynamicDialogRef, private workCenterSvc: WorkCenterService, private config: DynamicDialogConfig) { }

  ngOnInit(): void {
    const balId = this.config.data.balId
    const payload = {
      UserData: { balId },
      group: [],
      page: 1,
      pageSize: 6,
      skip: 0,
      take: 6
    };
    this.loading = true;
    this.workCenterSvc.workCenterSapQ(payload).subscribe((response) => {
      if(response) {
        this.workCenterDetails = response?.data
        this.loading = false;
      }
    }, (error)=>{
      this.loading = false;
    })
  }

  next() {
    this.first = this.first + this.rows;
  }

  prev() {
    this.first = this.first - this.rows;
  }

  refresh() {
    // this.firstIndex = 0;
    this.first = 0;
  }

  isLastPage(): boolean {
    return this.workCenterDetails
      ? this.first === this.workCenterDetails.length - this.rows
      : true;
  }

  isFirstPage(): boolean {
    return this.workCenterDetails ? this.first === 0 : true;
  }

  onPageChange(event: any) {
    event.first = 0;
    event.rows = 5;
    event.page = 1;
    event.pageCount = 5;
  }

  selectedIndex = -1;
  selectedRow(index: number) {
    this.selectedIndex = index;
  }

  selectAndClose() {
    this.ref.close(this.workCenterDetails[this.selectedIndex]);
   }

  onClose() {
    this.ref.close();
  }
}
