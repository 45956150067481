import { Component, OnInit } from '@angular/core';
import { AdminEscalationTimeServiceService } from '../admin-escalation-time-service.service';
import { ToasterserviceService } from 'src/app/services/toasterservice.service';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';

@Component({
  selector: 'app-admin-escalation',
  templateUrl: './admin-escalation.component.html',
  styleUrls: ['./admin-escalation.component.css']
})
export class AdminEscalationComponent implements OnInit {

  isInProcessViewSelected:boolean = true;
  isDraftViewSelected:boolean = false;
  isNotAssignedViewSelected:boolean = false;
  escalationTimes:any = [];
  constructor(private escalationService:AdminEscalationTimeServiceService,
    private toasterService:ToasterserviceService,
    private breadcrumbService: BreadcrumbService) { }

  ngOnInit(): void {
    this.breadcrumbService.menuItems= [

      { label: 'ADMIN PANEL', routerLink: 'admin'},
      { label: 'MANAGE ESCALATION TIMES'}

    ]
    this.escalationService.getAllEscalations().subscribe(
      (data)=>{
        this.escalationTimes=data;
      },
      err=>{
       this.toasterService.showError('Error While fetching data')
      }
    )
  }

  showInprocessView(){
   this.isInProcessViewSelected = true;
   this.isDraftViewSelected=false;
   this.isNotAssignedViewSelected=false;
  }

  showDraftView(){
   this.isDraftViewSelected=true;
   this.isInProcessViewSelected = false;
   this.isNotAssignedViewSelected=false;
  }

  showNotAssignedView(){
    this.isNotAssignedViewSelected=true;
    this.isInProcessViewSelected = false;
    this.isDraftViewSelected=false;
  }



}
