import { Component, OnInit } from '@angular/core';
import { DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-system-timeout-warning-modal',
  templateUrl: './system-timeout-warning-modal.component.html',
  styleUrls: ['./system-timeout-warning-modal.component.css']
})
export class SystemTimeoutWarningModalComponent implements OnInit {

  constructor(public ref: DynamicDialogRef) { }

  ngOnInit(): void {
  }

  cancel(){
    this.ref.close({result : 'none'});
  }

  ok(){
    this.ref.close({result : 'ok'});
  }

}
