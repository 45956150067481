import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MessageService, PrimeNGConfig } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { SendEmailIssueAssignService } from 'src/app/services/send-email-issue-assign.service';
import { FileUpload } from 'primeng/fileupload';
import { NgxSpinnerService } from 'ngx-spinner';
import { UsersService } from 'src/app/services/users/users.service';
import { ComplaintDetailsService } from 'src/app/services/complaintDetails/complaint-details.service';
import { SessionStorageServiceService } from 'src/app/services/session-storage-service.service';

@Component({
  selector: 'app-send-email-issue-assign',
  templateUrl: './send-email-issue-assign.component.html',
  styleUrls: ['./send-email-issue-assign.component.css'],
  providers: [MessageService],
})

export class SendEmailIssueAssignComponent implements OnInit {

  emailForm!: FormGroup;
  emailList: any = [];
  filteredRecord: any;
  issueSendMailAttachment: any = [];

  removeText= 'Remove'
  getUserMessageVariable: any;
  paramForAttachment: any;
  isSendProcess = false;
  complaintNo !: any;
  role !: any;
  complaintId !: number;
  toText = '';
  ccText = '';
  from: string = ''
  toArray = [];
  ccArray = [];
  isSendButtonEnabled=false;
  disableBtn: any = {};
  constructor(private fb: FormBuilder,
    private primengConfig: PrimeNGConfig,
    private readonly dialogRef: DynamicDialogRef,
    private emailService: SendEmailIssueAssignService,
    private messageService: MessageService,
    private ref: DynamicDialogConfig,
    private spinnerService: NgxSpinnerService,
    private usersService: UsersService,
    private sessionStorage: SessionStorageServiceService,
    private complaintDetailsService : ComplaintDetailsService  
  ) { } 

  ngOnInit(): void { 
    this.spinnerService.show();
    this.disableBtn={CreateEmailForm_SendEmailDialogTitle:false};
    this.paramForAttachment = {0: '30 MB'};
    this.complaintNo = this.ref.data.complaintNo;
    this.complaintId = this.ref.data?.complaintId;
    this.from = this.ref.data?.from;
    this.primengConfig.ripple = true;
    this.emailForm = this.fb.group({
      to: [[], [Validators.required]],
      cc: [[]/*, [Validators.required]*/],
      subject: ['', [Validators.required]],
      template: ['4', [Validators.required]],
      attachment: [null/*,[Validators.required]*/],
     // format: ['', [Validators.required]],
     defaultto: [],
     defaultcc: [],
      textareaBody: ['', []],  
    });

   // this.subjectInc(this.complaintNo);

    // this.emailService.getTemplateOfIssueAssign(11, 'IN-1026072').subscribe((resp)=>{
    //   console.log(resp)
    // })

    //Commented below code as its not required
    // this.emailService.getEmpDetails().subscribe((res: any) => {
    //   let onlyPromptLabel = [];
    //   res.forEach((data) => {
    //     onlyPromptLabel.push({"promptLabel":data?.promptLabel,"selectedLabel":data?.selectedLabel})
    //   })
    //   this.emailList = onlyPromptLabel;
    // });

    let roleId = 2; //to be updated when role based authorization is done
    let messageTemplateType = 3; //to be updated when role based authorization is done
    this.emailService.getUserMessageTemplates(roleId,messageTemplateType).subscribe((res:any) => {
      this.getUserMessageVariable = res.data;
      if(this.from == 'RO') {
        this.subjectInc(res.data[2]?.key,this.complaintNo);
        this.emailForm.patchValue({
          template: '16'
        })
      }
      else {
        this.subjectInc(res.data[0]?.key,this.complaintNo);
      }
      this.spinnerService.hide();
    })
  }
  selectto(event){
    this.toArray.push({"identification":{"email_alias":event?.selectedLabel},"displayLabel":event?.selectedLabel,"tooltipInfo":event?.selectedLabel,"isValid":true,"isCustom":true})
  }
  removeto(event){
    this.toArray = this.toArray.filter((todata) => todata?.displayLabel!=event?.selectedLabel)
  }
  selectcc(event){
    this.ccArray.push({"identification":{"geid":null,"email":event?.selectedLabel},"displayLabel":event?.selectedLabel,"tooltipInfo":event?.selectedLabel})
  }
  removecc(event){
    this.ccArray = this.ccArray.filter((todata) => todata?.displayLabel!=event?.selectedLabel)
  }
  toGotFocus(value) {
    this.toText = value;
  }
  toLostFocus() {
    this.toText = "";
  }
  ccGotFocus(value) {
    this.ccText = value;
  }
  ccLostFocus() {
    this.ccText = "";
  }
  onRecipientChange(event: any) {
    //let filtered: any[] = [];
    let query = event.query;

    // for (let i = 0; i < this.emailList.length; i++) {
    //   let country = this.emailList[i];
    //   if (country.promptLabel && country.promptLabel?.toLowerCase().indexOf(query.toLowerCase()) == 0) {
    //     filtered.push(country);
    //   }
    // }

    if(query.length > 1)
    {
      this.usersService.getFilteredUser(query).subscribe((res)=>{
        this.filteredRecord = res
      })
    }
    //this.filteredRecord = filtered;
  }

  subjectInc(templateId:number=4,complaintNo) {
    this.emailService.getTemplate(templateId, complaintNo).subscribe((resp)=>{
      let selectedValue2 = [];
      let defaultto = [];
      if(resp.defaultRecipients.length > 0){
        resp.defaultRecipients.forEach((data) => {selectedValue2.push({"promptLabel":data,"selectedLabel":data});defaultto.push({"identification":{"email_alias":data},"displayLabel":data,"tooltipInfo":data,"isValid":true,"isCustom":true})});
        this.emailForm.get('to')?.patchValue(selectedValue2);
        this.emailForm.get('defaultto')?.patchValue(defaultto);
      }
      else
      {
        this.emailForm.get('to')?.patchValue([]);
        this.emailForm.get('defaultto')?.patchValue([]);
      }

      let selectedValue3 = [];
      let defaultcc = [];
      if(resp.defaultCC.length > 0){
        resp.defaultCC.forEach((data) => {selectedValue3.push({"promptLabel":data,"selectedLabel":data});defaultcc.push({"identification":{"email_alias":data},"displayLabel":data,"tooltipInfo":data,"isValid":true,"isCustom":true})});
        this.emailForm.get('cc')?.patchValue(selectedValue3);
        this.emailForm.get('defaultcc')?.patchValue(defaultcc);
      }
      this.emailForm.get('subject')?.patchValue(resp.subject);
      this.emailForm.get('textareaBody').patchValue(resp.body);
    })

    //this.emailService.getSubject(this.complaintId).subscribe((res: any) => {
      //console.log("mail send. response " + res);
      //console.log(res);
      // let selectedValue2 = [];
      // if(res.defaultRecipients.length > 0){
      //   res.defaultRecipients.forEach((data) => selectedValue2.push({promptLabel: data}));
      //   this.emailForm.get('to')?.patchValue(selectedValue2);
      // }

      // let selectedValue3 = [];
      // if(res.defaultCC.length > 0){
      //   res.defaultCC.forEach((data) => selectedValue3.push({promptLabel: data}));
      //   this.emailForm.get('cc')?.patchValue(selectedValue3);
      // }
      // this.emailForm.get('subject')?.patchValue(res.subject);
      // this.emailForm.get('textareaBody').patchValue(res.body)
    //});
  }

  template(temp: any) {
    this.spinnerService.show();
    this.emailService.getTemplate(temp.target.value, this.complaintNo).subscribe((resp)=>{
      let selectedValue = [];
      let defaultto = [];
      if(resp.defaultRecipients.length > 0){
        resp.defaultRecipients.forEach((data) => {selectedValue.push({"promptLabel":data,"selectedLabel":data});defaultto.push({"identification":{"email_alias":data},"displayLabel":data,"tooltipInfo":data,"isValid":true,"isCustom":true});});
        this.emailForm.get('to')?.patchValue(selectedValue);
        this.emailForm.get('defaultto')?.patchValue(defaultto);
      }
      else
      {
        this.emailForm.get('to')?.patchValue([]);
        this.emailForm.get('defaultto')?.patchValue([]);
      }

      let selectedValue1 = [];
      let defaultcc = [];
      if(resp.defaultCC.length > 0){
        resp.defaultCC.forEach((data) => {selectedValue1.push({"promptLabel":data,"selectedLabel":data});defaultcc.push({"identification":{"email_alias":data},"displayLabel":data,"tooltipInfo":data,"isValid":true,"isCustom":true})});
        this.emailForm.get('cc')?.patchValue(selectedValue1);
        this.emailForm.get('defaultcc')?.patchValue(defaultcc);
      }
      else
      {
        this.emailForm.get('cc')?.patchValue([]);
        this.emailForm.get('defaultcc')?.patchValue([]);
      }
      this.emailForm.get('subject')?.patchValue(resp.subject);
      this.emailForm.get('textareaBody')?.patchValue(resp.body);

      this.spinnerService.hide();
    })

    // this.emailService._getTemplate(temp.target.value)?.subscribe((res: any) => {
    //   console.log(res);

    //   let selectedValue = [];
    //   if(res.defaultRecipients.length > 0){
    //     res.defaultRecipients.forEach((data) => selectedValue.push({promptLabel: data}));
    //     this.emailForm.get('to')?.patchValue(selectedValue);
    //   }

    //   let selectedValue1 = [];
    //   if(res.defaultCC.length > 0){
    //     res.defaultCC.forEach((data) => selectedValue1.push({promptLabel: data}));
    //     this.emailForm.get('cc')?.patchValue(selectedValue1);
    //   }
    //   this.emailForm.get('subject')?.patchValue(res.subject);
    //   this.emailForm.get('textareaBody')?.patchValue(res.body);
    // });
  }

  myUploader(createUploader,event) {
    // console.log("event file",event.files);
    for  (let i =  0; i <  event.files.length; i++)  {
      if(i>=this.issueSendMailAttachment?.length){
        // console.log("inside If",i,event.files[i]);
      let formData =  new  FormData();
      formData.append("files",  event.files[i]);
      this.spinnerService.show();
      this.complaintDetailsService.sendMailAttachmentSave(formData).subscribe((response:any)=>{
        let attachIdChange = response[0];
        attachIdChange.id = (attachIdChange?.id)?.toString();
        this.spinnerService.hide();

        // console.log("attachIdChange==>",attachIdChange);
        this.issueSendMailAttachment.push(attachIdChange);
          this.emailForm.patchValue({
            attachment: this.issueSendMailAttachment
          });
      }, err => {
          this.spinnerService.hide();
      })
    }
  }
  }

  removeFile(file: File, uploader: FileUpload ) {
    const index = uploader.files.indexOf(file);
    uploader.remove(event,index);
    let filename = [this.issueSendMailAttachment[Number(index)]?.storeFileName]
    this.complaintDetailsService.removeAttachments(filename).subscribe((res)=>{
     this.issueSendMailAttachment = this.issueSendMailAttachment.filter(attachment => attachment?.storeFileName !== filename[0]);
     this.emailForm.patchValue({
      attachment: this.issueSendMailAttachment
     });
    })

  }
  
   OnBodyChangeEvent(event:any) {
    this.isSendButtonEnabled=true;
    this.disableBtn={CreateEmailForm_SendEmailDialogTitle:true};
  }
  sendMail()
  {
    if (this.isSendButtonEnabled) {
      this.isSendProcess = true;
      this.spinnerService.show();
      // console.log(this.emailForm);
      var bodyContent = this.emailForm.controls['textareaBody']?.value;
      if (this.emailForm.invalid) {
        this.spinnerService.hide();
        this.messageService.add({
          severity: 'error',
          summary: 'Fill all the required Fields.'
        });
      }
      else {
        let formdata = {
          "sender": this.sessionStorage.getData('userInfo')?.email,
          "recipients": [...this.emailForm.controls['defaultto']?.value, ...this.toArray],
          "ccRecipients": [...this.emailForm.controls['defaultcc']?.value, ...this.ccArray],
          "subject": this.emailForm.controls['subject']?.value,
          "body": this.emailForm.controls['textareaBody']?.value,
          /*"defaultRecipients": this.emailForm.controls['defaultto']?.value,
          "defaultCC": this.emailForm.controls['defaultcc']?.value, */
          "templateId": this.emailForm.controls['template']?.value,
          "attachments": this.emailForm.controls['attachment']?.value,
          /* "roles": [
             "string"
           ],*/
          "contextRole": this.role,
          "complaintId": this.complaintId,
          "rowVersion": null,
          "disableEditor": false
        }
        // console.log("277",formdata);
        this.complaintDetailsService.sendMailForNotification(formdata).subscribe(res => {
          this.spinnerService.hide();
          this.dialogRef.close('result');
        },
          (error) => {
            this.spinnerService.hide();
            this.messageService.add({
              severity: 'error',
              summary: error
            });
          });
      }
    }
    else {
      this.messageService.add({
        severity: 'error',
        summary: 'User needs to make change in the body.'
      });
    }
  }

  onConfirm() {
    this.messageService.clear();
  }

  onReject() {
    this.messageService.clear();
  }

  clear() {
    this.messageService.clear();
  }

  onClose() {
    this.dialogRef.close();
  }

}
