import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { AdminSystemusersServiceService } from '../admin-systemusers-service.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToasterserviceService } from 'src/app/services/toasterservice.service';

@Component({
  selector: 'app-delete-systemuser-role',
  templateUrl: './delete-systemuser-role.component.html',
  styleUrls: ['./delete-systemuser-role.component.css']
})
export class DeleteSystemuserRoleComponent implements OnInit {

  suScope!:any;
  suRoleId!:number;
  isDeleted:boolean=false;

  constructor(private readonly dialogRef: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private systemUserService:AdminSystemusersServiceService,
    private spinner:NgxSpinnerService,
    private toaster:ToasterserviceService) {
    this.suScope = config.data.user.suScope;
    this.suRoleId = config.data.user.roleId;
  }

  ngOnInit(): void {
  }

  cancel() {
    this.dialogRef.close();
  }

  ok(){
     this.spinner.show();
     this.systemUserService.deleteSystemUserRole(this.suScope.id,this.suRoleId).subscribe(
      (data:any)=>{
        if (data.success) {
          this.toaster.showSuccess('System role deleted');
          this.isDeleted=true;
          this.dialogRef.close(this.isDeleted);
       } else {
        this.isDeleted=false;
        this.toaster.showSuccess(data.failureMsg);
        this.dialogRef.close();
      }
      },
      (err)=>{
        this.isDeleted=false
        this.toaster.showSuccess('Delete operation failed');
      }
     )
     this.spinner.hide();
     this.dialogRef.close(this.isDeleted);
   }

}
