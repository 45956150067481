import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AppInsightsService } from './app-insights.service';
import { MessageService } from 'primeng/api';
@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private appInsights: AppInsightsService,
      private messageService:MessageService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            catchError(err => {
                if(err?.error?.complaintWorkflowResult?.errorExcception[0]?.message){
                  this.messageService.add({ severity: 'error', summary: `<b>Error :</b> <br/> <b>${err?.error?.complaintWorkflowResult?.errorExcception[0]?.message}</b>` , sticky: true });
                }
                if(err?.error){
                  if(Object.values(err.error.errors)[0][0].toString().indexOf("areaComplaintCollection.resolvingSubjectInformationSection.quantity") > -1){
                    this.messageService.add({ severity: 'error', summary: `<b>Error :</b> <br/> <b>Quantity field must be greater than zero and less than 2147483648</b>` , sticky: false });
                  }else{
                    this.messageService.add({ severity: 'error', summary: `<b>Error :</b> <br/> <b>${Object.values(err.error.errors)[0][0]}</b>` , sticky: false });
                  }
                }
                this.appInsights.trackTrace(err.message + ' - ' + err.error.title);
                const error = err.error?.title;
                return throwError(error);
            }
            ))
    }
}
