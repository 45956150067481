import { Component, OnInit } from '@angular/core';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';

@Component({
  selector: 'app-processowner-search',
  templateUrl: './processowner-search.component.html',
  styleUrls: ['./processowner-search.component.css']
})
export class ProcessownerSearchComponent implements OnInit {

  constructor( private breadcrumbService: BreadcrumbService) { }

  ngOnInit(): void {
    this.breadcrumbService.menuItems= [
      { label: 'PROCESS OWNER SEARCH'}
    ]
  }

}
