import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import * as _ from 'lodash';

@Component({
  selector: 'app-po-infosection',
  templateUrl: './po-infosection.component.html',
  styleUrls: ['./po-infosection.component.css']
})
export class PoInfosectionComponent implements OnInit, OnChanges {

  @Input('items') items: any;
  @Input('itemsPerPage') itemsPerPage: any;
  @Input('datanoitemsmsg') datanoitemsmsg: any;
  @Output() reloadResults: EventEmitter<any> = new EventEmitter<any>();


  itemsToPresent: any = [];
  itemUnits: any = [];
  itemLocations: any = [];

  ngOnChanges(changes: SimpleChanges): void {
    this.ngOnInit();
  }

  constructor() {

  }

  ngOnInit(): void {
    this.resetNavigation();
    this.refreshItemsToPresent();
  }

  refreshItemsToPresent() {

    if (this.items) {
      this.itemsToPresent = this.items.slice(this.currentPage == 1 ? 0 : (this.currentPage - 1) * this.itemsPerPage,
        (this.currentPage == 1 ? this.itemsPerPage : (this.currentPage - 1) * this.itemsPerPage + parseInt(this.itemsPerPage)));
    }

    this.itemUnits = _.filter(this.itemsToPresent,
      function (item) {
        return item.type == 1; //Unit
      });
    this.itemLocations = _.filter(this.itemsToPresent,
      function (item) {
        return item.type == 2; //Local
      });
  };


  resetNavigation() {
    this.firstPage = 1;
    this.currentPage = 1;
    this.pagesCount = (this.items != null && this.items.length > 0)
      ? Math.ceil(this.items.length / this.itemsPerPage) : 1;

  };

  firstPage = 1;
  currentPage = 1;
  pagesCount = 1;
  previousPage() {
    if (this.hasPrevious()) {
      return this.currentPage - 1;
    }
    return 0;
  };

  hasPrevious() {
    return this.currentPage > 1;
  };
  nextPage() {
    if (this.hasNext()) {
      return this.currentPage + 1;
    }
    return 0;
  };

  hasNext() {
    return this.currentPage < this.pagesCount;
  };
  showFirstPage() {
    return this.hasPrevious() && this.previousPage() > 1;
  };
  showFirstPageSeparator() {
    return this.currentPage > 3;
  };
  showLastPage() {
    return this.hasNext() && this.nextPage() < this.pagesCount;
  };
  showLastPageSeparator() {
    var result = this.hasNext() && this.nextPage() < this.pagesCount - 1;
    return result;
  };


  nextClicked() {
    if (this.hasNext()) {
      this.currentPage++;
      this.refreshItemsToPresent();
    }
  };
  previousClicked() {
    if (this.hasPrevious()) {
      this.currentPage--;
      this.refreshItemsToPresent();
    }
  };
  gotoPage(page) {
    this.currentPage = page;
    this.refreshItemsToPresent();
  };


}
