import * as i0 from '@angular/core';
import { EventEmitter, Component, ChangeDetectionStrategy, ViewEncapsulation, Input, Output, ContentChildren, NgModule } from '@angular/core';
import * as i1 from '@angular/common';
import { CommonModule } from '@angular/common';
import { PrimeTemplate, SharedModule } from 'primeng/api';
import * as i2 from '@angular/router';
import { RouterModule } from '@angular/router';
import * as i3 from 'primeng/tooltip';
import { TooltipModule } from 'primeng/tooltip';
import { ChevronRightIcon } from 'primeng/icons/chevronright';
import { HomeIcon } from 'primeng/icons/home';
const _c0 = a0 => ({
  "p-breadcrumb-home": true,
  "p-disabled": a0
});
const _c1 = () => ({
  exact: false
});
const _c2 = a0 => ({
  "p-disabled": a0
});
function Breadcrumb_li_2_a_1_span_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "span", 15);
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(3);
    i0.ɵɵproperty("ngClass", ctx_r1.home.icon)("ngStyle", ctx_r1.home.iconStyle);
  }
}
function Breadcrumb_li_2_a_1_HomeIcon_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "HomeIcon", 16);
  }
  if (rf & 2) {
    i0.ɵɵproperty("styleClass", "p-menuitem-icon");
  }
}
function Breadcrumb_li_2_a_1_ng_container_3_span_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span", 18);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(4);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(ctx_r1.home.label);
  }
}
function Breadcrumb_li_2_a_1_ng_container_3_ng_template_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "span", 19);
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(4);
    i0.ɵɵproperty("innerHTML", ctx_r1.home.label, i0.ɵɵsanitizeHtml);
  }
}
function Breadcrumb_li_2_a_1_ng_container_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, Breadcrumb_li_2_a_1_ng_container_3_span_1_Template, 2, 1, "span", 17)(2, Breadcrumb_li_2_a_1_ng_container_3_ng_template_2_Template, 1, 1, "ng-template", null, 0, i0.ɵɵtemplateRefExtractor);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const htmlHomeLabel_r3 = i0.ɵɵreference(3);
    const ctx_r1 = i0.ɵɵnextContext(3);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r1.home.escape !== false)("ngIfElse", htmlHomeLabel_r3);
  }
}
function Breadcrumb_li_2_a_1_Template(rf, ctx) {
  if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "a", 11);
    i0.ɵɵlistener("click", function Breadcrumb_li_2_a_1_Template_a_click_0_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r1.itemClick($event, ctx_r1.home));
    });
    i0.ɵɵtemplate(1, Breadcrumb_li_2_a_1_span_1_Template, 1, 2, "span", 12)(2, Breadcrumb_li_2_a_1_HomeIcon_2_Template, 1, 1, "HomeIcon", 13)(3, Breadcrumb_li_2_a_1_ng_container_3_Template, 4, 2, "ng-container", 14);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("href", ctx_r1.home.url ? ctx_r1.home.url : null, i0.ɵɵsanitizeUrl)("target", ctx_r1.home.target);
    i0.ɵɵattribute("aria-label", ctx_r1.homeAriaLabel)("title", ctx_r1.home.title)("id", ctx_r1.home.id)("tabindex", ctx_r1.home.disabled ? null : "0");
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r1.home.icon);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !ctx_r1.home.icon);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r1.home.label);
  }
}
function Breadcrumb_li_2_a_2_span_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "span", 15);
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(3);
    i0.ɵɵproperty("ngClass", ctx_r1.home.icon)("ngStyle", ctx_r1.home.iconStyle);
  }
}
function Breadcrumb_li_2_a_2_HomeIcon_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "HomeIcon", 16);
  }
  if (rf & 2) {
    i0.ɵɵproperty("styleClass", "p-menuitem-icon");
  }
}
function Breadcrumb_li_2_a_2_ng_container_3_span_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span", 18);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(4);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(ctx_r1.home.label);
  }
}
function Breadcrumb_li_2_a_2_ng_container_3_ng_template_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "span", 19);
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(4);
    i0.ɵɵproperty("innerHTML", ctx_r1.home.label, i0.ɵɵsanitizeHtml);
  }
}
function Breadcrumb_li_2_a_2_ng_container_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, Breadcrumb_li_2_a_2_ng_container_3_span_1_Template, 2, 1, "span", 17)(2, Breadcrumb_li_2_a_2_ng_container_3_ng_template_2_Template, 1, 1, "ng-template", null, 1, i0.ɵɵtemplateRefExtractor);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const htmlHomeRouteLabel_r5 = i0.ɵɵreference(3);
    const ctx_r1 = i0.ɵɵnextContext(3);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r1.home.escape !== false)("ngIfElse", htmlHomeRouteLabel_r5);
  }
}
function Breadcrumb_li_2_a_2_Template(rf, ctx) {
  if (rf & 1) {
    const _r4 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "a", 20);
    i0.ɵɵlistener("click", function Breadcrumb_li_2_a_2_Template_a_click_0_listener($event) {
      i0.ɵɵrestoreView(_r4);
      const ctx_r1 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r1.itemClick($event, ctx_r1.home));
    });
    i0.ɵɵtemplate(1, Breadcrumb_li_2_a_2_span_1_Template, 1, 2, "span", 12)(2, Breadcrumb_li_2_a_2_HomeIcon_2_Template, 1, 1, "HomeIcon", 13)(3, Breadcrumb_li_2_a_2_ng_container_3_Template, 4, 2, "ng-container", 14);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("routerLink", ctx_r1.home.routerLink)("queryParams", ctx_r1.home.queryParams)("routerLinkActive", "p-menuitem-link-active")("routerLinkActiveOptions", ctx_r1.home.routerLinkActiveOptions || i0.ɵɵpureFunction0(18, _c1))("target", ctx_r1.home.target)("fragment", ctx_r1.home.fragment)("queryParamsHandling", ctx_r1.home.queryParamsHandling)("preserveFragment", ctx_r1.home.preserveFragment)("skipLocationChange", ctx_r1.home.skipLocationChange)("replaceUrl", ctx_r1.home.replaceUrl)("state", ctx_r1.home.state);
    i0.ɵɵattribute("aria-label", ctx_r1.homeAriaLabel)("title", ctx_r1.home.title)("id", ctx_r1.home.id)("tabindex", ctx_r1.home.disabled ? null : "0");
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r1.home.icon);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !ctx_r1.home.icon);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r1.home.label);
  }
}
function Breadcrumb_li_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "li", 8);
    i0.ɵɵtemplate(1, Breadcrumb_li_2_a_1_Template, 4, 9, "a", 9)(2, Breadcrumb_li_2_a_2_Template, 4, 19, "a", 10);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵclassMap(ctx_r1.home.styleClass);
    i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction1(7, _c0, ctx_r1.home.disabled))("ngStyle", ctx_r1.home.style)("tooltipOptions", ctx_r1.home.tooltipOptions);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !ctx_r1.home.routerLink);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r1.home.routerLink);
  }
}
function Breadcrumb_li_3_ChevronRightIcon_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "ChevronRightIcon");
  }
}
function Breadcrumb_li_3_2_ng_template_0_Template(rf, ctx) {}
function Breadcrumb_li_3_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, Breadcrumb_li_3_2_ng_template_0_Template, 0, 0, "ng-template");
  }
}
function Breadcrumb_li_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "li", 21);
    i0.ɵɵtemplate(1, Breadcrumb_li_3_ChevronRightIcon_1_Template, 1, 0, "ChevronRightIcon", 14)(2, Breadcrumb_li_3_2_Template, 1, 0, null, 22);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !ctx_r1.separatorTemplate);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r1.separatorTemplate);
  }
}
function Breadcrumb_ng_template_4_a_1_span_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "span", 15);
  }
  if (rf & 2) {
    const item_r7 = i0.ɵɵnextContext(2).$implicit;
    i0.ɵɵproperty("ngClass", item_r7.icon)("ngStyle", item_r7.iconStyle);
  }
}
function Breadcrumb_ng_template_4_a_1_ng_container_2_span_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span", 18);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const item_r7 = i0.ɵɵnextContext(3).$implicit;
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(item_r7.label);
  }
}
function Breadcrumb_ng_template_4_a_1_ng_container_2_ng_template_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "span", 19);
  }
  if (rf & 2) {
    const item_r7 = i0.ɵɵnextContext(3).$implicit;
    i0.ɵɵproperty("innerHTML", item_r7.label, i0.ɵɵsanitizeHtml);
  }
}
function Breadcrumb_ng_template_4_a_1_ng_container_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, Breadcrumb_ng_template_4_a_1_ng_container_2_span_1_Template, 2, 1, "span", 17)(2, Breadcrumb_ng_template_4_a_1_ng_container_2_ng_template_2_Template, 1, 1, "ng-template", null, 2, i0.ɵɵtemplateRefExtractor);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const htmlLabel_r8 = i0.ɵɵreference(3);
    const item_r7 = i0.ɵɵnextContext(2).$implicit;
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", item_r7.escape !== false)("ngIfElse", htmlLabel_r8);
  }
}
function Breadcrumb_ng_template_4_a_1_Template(rf, ctx) {
  if (rf & 1) {
    const _r6 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "a", 25);
    i0.ɵɵlistener("click", function Breadcrumb_ng_template_4_a_1_Template_a_click_0_listener($event) {
      i0.ɵɵrestoreView(_r6);
      const item_r7 = i0.ɵɵnextContext().$implicit;
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.itemClick($event, item_r7));
    });
    i0.ɵɵtemplate(1, Breadcrumb_ng_template_4_a_1_span_1_Template, 1, 2, "span", 12)(2, Breadcrumb_ng_template_4_a_1_ng_container_2_Template, 4, 2, "ng-container", 14);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const item_r7 = i0.ɵɵnextContext().$implicit;
    i0.ɵɵproperty("target", item_r7.target);
    i0.ɵɵattribute("href", item_r7.url ? item_r7.url : null, i0.ɵɵsanitizeUrl)("title", item_r7.title)("id", item_r7.id)("tabindex", item_r7.disabled ? null : "0");
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", item_r7.icon);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", item_r7.label);
  }
}
function Breadcrumb_ng_template_4_a_2_span_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "span", 15);
  }
  if (rf & 2) {
    const item_r7 = i0.ɵɵnextContext(2).$implicit;
    i0.ɵɵproperty("ngClass", item_r7.icon)("ngStyle", item_r7.iconStyle);
  }
}
function Breadcrumb_ng_template_4_a_2_ng_container_2_span_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span", 18);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const item_r7 = i0.ɵɵnextContext(3).$implicit;
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(item_r7.label);
  }
}
function Breadcrumb_ng_template_4_a_2_ng_container_2_ng_template_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "span", 19);
  }
  if (rf & 2) {
    const item_r7 = i0.ɵɵnextContext(3).$implicit;
    i0.ɵɵproperty("innerHTML", item_r7.label, i0.ɵɵsanitizeHtml);
  }
}
function Breadcrumb_ng_template_4_a_2_ng_container_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, Breadcrumb_ng_template_4_a_2_ng_container_2_span_1_Template, 2, 1, "span", 17)(2, Breadcrumb_ng_template_4_a_2_ng_container_2_ng_template_2_Template, 1, 1, "ng-template", null, 3, i0.ɵɵtemplateRefExtractor);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const htmlRouteLabel_r10 = i0.ɵɵreference(3);
    const item_r7 = i0.ɵɵnextContext(2).$implicit;
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", item_r7.escape !== false)("ngIfElse", htmlRouteLabel_r10);
  }
}
function Breadcrumb_ng_template_4_a_2_Template(rf, ctx) {
  if (rf & 1) {
    const _r9 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "a", 20);
    i0.ɵɵlistener("click", function Breadcrumb_ng_template_4_a_2_Template_a_click_0_listener($event) {
      i0.ɵɵrestoreView(_r9);
      const item_r7 = i0.ɵɵnextContext().$implicit;
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.itemClick($event, item_r7));
    });
    i0.ɵɵtemplate(1, Breadcrumb_ng_template_4_a_2_span_1_Template, 1, 2, "span", 12)(2, Breadcrumb_ng_template_4_a_2_ng_container_2_Template, 4, 2, "ng-container", 14);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const item_r7 = i0.ɵɵnextContext().$implicit;
    i0.ɵɵproperty("routerLink", item_r7.routerLink)("queryParams", item_r7.queryParams)("routerLinkActive", "p-menuitem-link-active")("routerLinkActiveOptions", item_r7.routerLinkActiveOptions || i0.ɵɵpureFunction0(16, _c1))("target", item_r7.target)("fragment", item_r7.fragment)("queryParamsHandling", item_r7.queryParamsHandling)("preserveFragment", item_r7.preserveFragment)("skipLocationChange", item_r7.skipLocationChange)("replaceUrl", item_r7.replaceUrl)("state", item_r7.state);
    i0.ɵɵattribute("title", item_r7.title)("id", item_r7.id)("tabindex", item_r7.disabled ? null : "0");
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", item_r7.icon);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", item_r7.label);
  }
}
function Breadcrumb_ng_template_4_li_3_ChevronRightIcon_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "ChevronRightIcon");
  }
}
function Breadcrumb_ng_template_4_li_3_2_ng_template_0_Template(rf, ctx) {}
function Breadcrumb_ng_template_4_li_3_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, Breadcrumb_ng_template_4_li_3_2_ng_template_0_Template, 0, 0, "ng-template");
  }
}
function Breadcrumb_ng_template_4_li_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "li", 21);
    i0.ɵɵtemplate(1, Breadcrumb_ng_template_4_li_3_ChevronRightIcon_1_Template, 1, 0, "ChevronRightIcon", 14)(2, Breadcrumb_ng_template_4_li_3_2_Template, 1, 0, null, 22);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !ctx_r1.separatorTemplate);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r1.separatorTemplate);
  }
}
function Breadcrumb_ng_template_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "li", 23);
    i0.ɵɵtemplate(1, Breadcrumb_ng_template_4_a_1_Template, 3, 7, "a", 24)(2, Breadcrumb_ng_template_4_a_2_Template, 3, 17, "a", 10);
    i0.ɵɵelementEnd();
    i0.ɵɵtemplate(3, Breadcrumb_ng_template_4_li_3_Template, 3, 2, "li", 6);
  }
  if (rf & 2) {
    const item_r7 = ctx.$implicit;
    const end_r11 = ctx.last;
    i0.ɵɵclassMap(item_r7.styleClass);
    i0.ɵɵproperty("ngStyle", item_r7.style)("ngClass", i0.ɵɵpureFunction1(8, _c2, item_r7.disabled))("tooltipOptions", item_r7.tooltipOptions);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !item_r7.routerLink);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", item_r7.routerLink);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !end_r11);
  }
}
class Breadcrumb {
  constructor() {
    this.onItemClick = new EventEmitter();
  }
  itemClick(event, item) {
    if (item.disabled) {
      event.preventDefault();
      return;
    }
    if (!item.url && !item.routerLink) {
      event.preventDefault();
    }
    if (item.command) {
      item.command({
        originalEvent: event,
        item: item
      });
    }
    this.onItemClick.emit({
      originalEvent: event,
      item: item
    });
  }
  onHomeClick(event) {
    if (this.home) {
      this.itemClick(event, this.home);
    }
  }
  ngAfterContentInit() {
    this.templates.forEach(item => {
      switch (item.getType()) {
        case 'separator':
          this.separatorTemplate = item.template;
          break;
      }
    });
  }
}
Breadcrumb.ɵfac = function Breadcrumb_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || Breadcrumb)();
};
Breadcrumb.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: Breadcrumb,
  selectors: [["p-breadcrumb"]],
  contentQueries: function Breadcrumb_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, PrimeTemplate, 4);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.templates = _t);
    }
  },
  hostAttrs: [1, "p-element"],
  inputs: {
    model: "model",
    style: "style",
    styleClass: "styleClass",
    home: "home",
    homeAriaLabel: "homeAriaLabel"
  },
  outputs: {
    onItemClick: "onItemClick"
  },
  decls: 5,
  vars: 7,
  consts: [["htmlHomeLabel", ""], ["htmlHomeRouteLabel", ""], ["htmlLabel", ""], ["htmlRouteLabel", ""], [3, "ngStyle", "ngClass"], ["pTooltip", "", 3, "class", "ngClass", "ngStyle", "tooltipOptions", 4, "ngIf"], ["class", "p-breadcrumb-chevron", 4, "ngIf"], ["ngFor", "", 3, "ngForOf"], ["pTooltip", "", 3, "ngClass", "ngStyle", "tooltipOptions"], ["class", "p-menuitem-link", 3, "href", "target", "click", 4, "ngIf"], ["class", "p-menuitem-link", 3, "routerLink", "queryParams", "routerLinkActive", "routerLinkActiveOptions", "target", "fragment", "queryParamsHandling", "preserveFragment", "skipLocationChange", "replaceUrl", "state", "click", 4, "ngIf"], [1, "p-menuitem-link", 3, "click", "href", "target"], ["class", "p-menuitem-icon", 3, "ngClass", "ngStyle", 4, "ngIf"], [3, "styleClass", 4, "ngIf"], [4, "ngIf"], [1, "p-menuitem-icon", 3, "ngClass", "ngStyle"], [3, "styleClass"], ["class", "p-menuitem-text", 4, "ngIf", "ngIfElse"], [1, "p-menuitem-text"], [1, "p-menuitem-text", 3, "innerHTML"], [1, "p-menuitem-link", 3, "click", "routerLink", "queryParams", "routerLinkActive", "routerLinkActiveOptions", "target", "fragment", "queryParamsHandling", "preserveFragment", "skipLocationChange", "replaceUrl", "state"], [1, "p-breadcrumb-chevron"], [4, "ngTemplateOutlet"], ["pTooltip", "", 3, "ngStyle", "ngClass", "tooltipOptions"], ["class", "p-menuitem-link", 3, "target", "click", 4, "ngIf"], [1, "p-menuitem-link", 3, "click", "target"]],
  template: function Breadcrumb_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelementStart(0, "div", 4)(1, "ul");
      i0.ɵɵtemplate(2, Breadcrumb_li_2_Template, 3, 9, "li", 5)(3, Breadcrumb_li_3_Template, 3, 2, "li", 6)(4, Breadcrumb_ng_template_4_Template, 4, 10, "ng-template", 7);
      i0.ɵɵelementEnd()();
    }
    if (rf & 2) {
      i0.ɵɵclassMap(ctx.styleClass);
      i0.ɵɵproperty("ngStyle", ctx.style)("ngClass", "p-breadcrumb p-component");
      i0.ɵɵadvance(2);
      i0.ɵɵproperty("ngIf", ctx.home);
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngIf", ctx.model && ctx.home);
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngForOf", ctx.model);
    }
  },
  dependencies: () => [i1.NgClass, i1.NgForOf, i1.NgIf, i1.NgTemplateOutlet, i1.NgStyle, i2.RouterLink, i2.RouterLinkActive, i3.Tooltip, ChevronRightIcon, HomeIcon],
  styles: [".p-breadcrumb{overflow-x:auto}.p-breadcrumb ul{margin:0;padding:0;list-style-type:none;display:flex;align-items:center;flex-wrap:nowrap}.p-breadcrumb .p-menuitem-text{line-height:1}.p-breadcrumb .p-menuitem-link{text-decoration:none;cursor:pointer}.p-breadcrumb::-webkit-scrollbar{display:none}\n"],
  encapsulation: 2,
  changeDetection: 0
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(Breadcrumb, [{
    type: Component,
    args: [{
      selector: 'p-breadcrumb',
      template: `
        <div [class]="styleClass" [ngStyle]="style" [ngClass]="'p-breadcrumb p-component'">
            <ul>
                <li [class]="home.styleClass" [ngClass]="{ 'p-breadcrumb-home': true, 'p-disabled': home.disabled }" [ngStyle]="home.style" *ngIf="home" pTooltip [tooltipOptions]="home.tooltipOptions">
                    <a
                        *ngIf="!home.routerLink"
                        [attr.aria-label]="homeAriaLabel"
                        [href]="home.url ? home.url : null"
                        class="p-menuitem-link"
                        (click)="itemClick($event, home)"
                        [target]="home.target"
                        [attr.title]="home.title"
                        [attr.id]="home.id"
                        [attr.tabindex]="home.disabled ? null : '0'"
                    >
                        <span *ngIf="home.icon" class="p-menuitem-icon" [ngClass]="home.icon" [ngStyle]="home.iconStyle"></span>
                        <HomeIcon *ngIf="!home.icon" [styleClass]="'p-menuitem-icon'"/>
                        <ng-container *ngIf="home.label">
                            <span *ngIf="home.escape !== false; else htmlHomeLabel" class="p-menuitem-text">{{ home.label }}</span>
                            <ng-template #htmlHomeLabel><span class="p-menuitem-text" [innerHTML]="home.label"></span></ng-template>
                        </ng-container>
                    </a>
                    <a
                        *ngIf="home.routerLink"
                        [routerLink]="home.routerLink"
                        [attr.aria-label]="homeAriaLabel"
                        [queryParams]="home.queryParams"
                        [routerLinkActive]="'p-menuitem-link-active'"
                        [routerLinkActiveOptions]="home.routerLinkActiveOptions || { exact: false }"
                        class="p-menuitem-link"
                        (click)="itemClick($event, home)"
                        [target]="home.target"
                        [attr.title]="home.title"
                        [attr.id]="home.id"
                        [attr.tabindex]="home.disabled ? null : '0'"
                        [fragment]="home.fragment"
                        [queryParamsHandling]="home.queryParamsHandling"
                        [preserveFragment]="home.preserveFragment"
                        [skipLocationChange]="home.skipLocationChange"
                        [replaceUrl]="home.replaceUrl"
                        [state]="home.state"
                    >
                        <span *ngIf="home.icon" class="p-menuitem-icon" [ngClass]="home.icon" [ngStyle]="home.iconStyle"></span>
                        <HomeIcon *ngIf="!home.icon" [styleClass]="'p-menuitem-icon'"/>
                        <ng-container *ngIf="home.label">
                            <span *ngIf="home.escape !== false; else htmlHomeRouteLabel" class="p-menuitem-text">{{ home.label }}</span>
                            <ng-template #htmlHomeRouteLabel><span class="p-menuitem-text" [innerHTML]="home.label"></span></ng-template>
                        </ng-container>
                    </a>
                </li>
                <li *ngIf="model && home" class="p-breadcrumb-chevron">
                    <ChevronRightIcon *ngIf="!separatorTemplate" />
                    <ng-template *ngTemplateOutlet="separatorTemplate"></ng-template>
                </li>
                <ng-template ngFor let-item let-end="last" [ngForOf]="model">
                    <li [class]="item.styleClass" [ngStyle]="item.style" [ngClass]="{ 'p-disabled': item.disabled }" pTooltip [tooltipOptions]="item.tooltipOptions">
                        <a
                            *ngIf="!item.routerLink"
                            [attr.href]="item.url ? item.url : null"
                            class="p-menuitem-link"
                            (click)="itemClick($event, item)"
                            [target]="item.target"
                            [attr.title]="item.title"
                            [attr.id]="item.id"
                            [attr.tabindex]="item.disabled ? null : '0'"
                        >
                            <span *ngIf="item.icon" class="p-menuitem-icon" [ngClass]="item.icon" [ngStyle]="item.iconStyle"></span>
                            <ng-container *ngIf="item.label">
                                <span *ngIf="item.escape !== false; else htmlLabel" class="p-menuitem-text">{{ item.label }}</span>
                                <ng-template #htmlLabel><span class="p-menuitem-text" [innerHTML]="item.label"></span></ng-template>
                            </ng-container>
                        </a>
                        <a
                            *ngIf="item.routerLink"
                            [routerLink]="item.routerLink"
                            [queryParams]="item.queryParams"
                            [routerLinkActive]="'p-menuitem-link-active'"
                            [routerLinkActiveOptions]="item.routerLinkActiveOptions || { exact: false }"
                            class="p-menuitem-link"
                            (click)="itemClick($event, item)"
                            [target]="item.target"
                            [attr.title]="item.title"
                            [attr.id]="item.id"
                            [attr.tabindex]="item.disabled ? null : '0'"
                            [fragment]="item.fragment"
                            [queryParamsHandling]="item.queryParamsHandling"
                            [preserveFragment]="item.preserveFragment"
                            [skipLocationChange]="item.skipLocationChange"
                            [replaceUrl]="item.replaceUrl"
                            [state]="item.state"
                        >
                            <span *ngIf="item.icon" class="p-menuitem-icon" [ngClass]="item.icon" [ngStyle]="item.iconStyle"></span>
                            <ng-container *ngIf="item.label">
                                <span *ngIf="item.escape !== false; else htmlRouteLabel" class="p-menuitem-text">{{ item.label }}</span>
                                <ng-template #htmlRouteLabel><span class="p-menuitem-text" [innerHTML]="item.label"></span></ng-template>
                            </ng-container>
                        </a>
                    </li>
                    <li *ngIf="!end" class="p-breadcrumb-chevron">
                        <ChevronRightIcon *ngIf="!separatorTemplate" />
                        <ng-template *ngTemplateOutlet="separatorTemplate"></ng-template>
                    </li>
                </ng-template>
            </ul>
        </div>
    `,
      changeDetection: ChangeDetectionStrategy.OnPush,
      encapsulation: ViewEncapsulation.None,
      host: {
        class: 'p-element'
      },
      styles: [".p-breadcrumb{overflow-x:auto}.p-breadcrumb ul{margin:0;padding:0;list-style-type:none;display:flex;align-items:center;flex-wrap:nowrap}.p-breadcrumb .p-menuitem-text{line-height:1}.p-breadcrumb .p-menuitem-link{text-decoration:none;cursor:pointer}.p-breadcrumb::-webkit-scrollbar{display:none}\n"]
    }]
  }], null, {
    model: [{
      type: Input
    }],
    style: [{
      type: Input
    }],
    styleClass: [{
      type: Input
    }],
    home: [{
      type: Input
    }],
    homeAriaLabel: [{
      type: Input
    }],
    onItemClick: [{
      type: Output
    }],
    templates: [{
      type: ContentChildren,
      args: [PrimeTemplate]
    }]
  });
})();
class BreadcrumbModule {}
BreadcrumbModule.ɵfac = function BreadcrumbModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || BreadcrumbModule)();
};
BreadcrumbModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: BreadcrumbModule
});
BreadcrumbModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [CommonModule, RouterModule, TooltipModule, ChevronRightIcon, HomeIcon, SharedModule, RouterModule, TooltipModule, SharedModule]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BreadcrumbModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, RouterModule, TooltipModule, ChevronRightIcon, HomeIcon, SharedModule],
      exports: [Breadcrumb, RouterModule, TooltipModule, SharedModule],
      declarations: [Breadcrumb]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { Breadcrumb, BreadcrumbModule };

