import { Component, OnInit,Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-systemuser-itemdetails',
  templateUrl: './systemuser-itemdetails.component.html',
  styleUrls: ['./systemuser-itemdetails.component.css']
})
export class SystemuserItemdetailsComponent implements OnInit {

  @Input('itemScope') itemScope!:any;
  @Output() deleteSystemRoleOfUser = new EventEmitter();
  @Output() addSystemRoleOfUser = new EventEmitter();


  constructor() { }

  ngOnInit(): void {
  }

  deleteSystemRoleUser(userData:any){
    this.deleteSystemRoleOfUser.emit(userData);
  }

  addSystemRoleUser(suScope:any){
    this.addSystemRoleOfUser.emit(suScope);
  }
}
