import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ReportsService {

  constructor(private http: HttpClient) {}

  getComplaintSearch(searchData):Observable<any>{
    return this.http.get(environment.azure.baseUrl + 'api/v1/complaintSearch/'+searchData);
  }

  getEmployeesReports(passedData): Observable<any>{
    return this.http.get(environment.azure.baseUrl + 'api/v1/employee/findpagebywildcard?wildcard='+ passedData.wildcard + '&onlyActive=' + passedData.onlyActive + '&page=' + passedData.page + '&size=' +passedData.size );
  }

  getAllBuPg(): Observable<any> {
    return this.http.get(environment.azure.baseUrl + 'api/v1/divisions/all' );
  }

  getAllMdRegions(): Observable<any>{
    return this.http.get(environment.azure.baseUrl + 'api/v1/mdregion/all' );
  }


  getCustomer(passedData): Observable<any>{
    return this.http.get(environment.azure.baseUrl + 'api/v1/customer/findbywildcard?wildcard=' + passedData.wildcard +
    '&accountType=' + passedData.accountType + '&customerhq' + passedData.customerhq + '&customerregion=' +passedData.customerregion
    +'&customercity='+passedData.customercity + '&page='+ passedData.page+'&size='+passedData.size);

  }

  gethrgtAll():Observable<any>{
    return this.http.get(environment.azure.baseUrl + 'api/v1/hrgt/all');
  }

  getLevel2(data):Observable<any>{
    return this.http.post(`${environment.azure.baseUrl}api/v1/hrgt/findLevel2`,data);
  }

  getLevel3(data):Observable<any>{
    return this.http.post(`${environment.azure.baseUrl}api/v1/hrgt/findLevel3`,data);
  }
  //save report setting
  saveReportSetting(postData): Observable<any>{
    return this.http.post(environment.azure.baseUrl + 'api/v1/reports/settings/', postData);
  }
  //get reports details
  getReportSetting(): Observable<any>{
    return this.http.get(environment.azure.baseUrl + 'api/v1/reports/settings');
  }

  //Delete report by Id
  reportSettingsDelete(reportId, id): Observable<any>{
    return this.http.delete(`${environment.azure.baseUrl}api/v1/reports/settings/${reportId}/${id}`);
  }

  //savesettingpopup related api's
  getCurrentUser(): Observable<any>{
    return this.http.get(environment.azure.baseUrl + 'api/v1/employee/currentUser');
  }

  getLevel2byCodes(postData): Observable<any>{
    return this.http.post(`${environment.azure.baseUrl}api/v1/hrgt/findLevel2ByCodes`,postData);
  }

  getLevel3byCodes(postData): Observable<any>{
    return this.http.post(`${environment.azure.baseUrl}api/v1/hrgt/findLevel3ByCodes`,postData);
  }

  findbyGeids(geidData): Observable<any>{
    return this.http.post(`${environment.azure.baseUrl}api/v1/employee/findByGeids`,geidData);
  }

  findCustomerbyGeids(geidData): Observable<any>{
    return this.http.post(`${environment.azure.baseUrl}api/v1/customer/findByGuid`,geidData);
  }

  getExcelReport(data): Observable<any>{
    return this.http.post(`${environment.azure.baseUrl}api/v1/reportgenerator/GetAllInOne`,data, {
      responseType: 'arraybuffer'
    });
  }


  getReportHTMLView(data): Observable<any>{
    return this.http.post(`${environment.azure.baseUrl}api/v1/reportgenerator/GetAllInOneView`,data, {
      responseType: 'arraybuffer'
    });
  }
  // https://hpg-webapp-000088.azurewebsites.net/api/v1/reports/GetreportParametersById?Url=test
  //https://hpg-webapp-000088.azurewebsites.net/api/reportgenerator/GetAllInOneByURL?Url=test&exportType=html
  getReportbyId(data, type): Observable<any>{
    return this.http.get(environment.azure.baseUrl + 'api/v1/reportgenerator/GetAllInOneByURL?Url='+data+'&exportType='+type, {
      responseType: 'arraybuffer'
    });
  }
}
