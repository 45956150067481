<div class="border-0">
  <div class="effectiveness">
    <form [formGroup]="effectivenessForm">
      <input type="hidden" formControlName="id">
      <p-toast [style]="{ marginTop: '5px' }"></p-toast>
      <div class="row margin-space">
        <label class="ps-0"> {{ "Complaint_EffectivenessRadio" | translate }} </label>

        <div class="form-check">
          <label [pTooltip]="effectivenesYesText" [escape]="false" tooltipPosition="top"
            tooltipStyleClass="toolTipClass">
            <input class="form-check-input" type="radio" value="Yes" (change)="changeIsEffective()" formControlName="IsEffective" id="Yes"
              checked="checked">
            {{ "Effectiveness_RadioButton_Yes" | translate }}
          </label>
        </div>
        <div class="form-check">
          <label [pTooltip]="effectivenesNoText" [escape]="false" tooltipPosition="top"
            tooltipStyleClass="toolTipClass">
            <input class="form-check-input" type="radio" value="No" (change)="changeIsEffective()" formControlName="IsEffective" id="No">
            {{ "Effectiveness_RadioButton_No" | translate }}
          </label>
        </div>
        <div class="form-check ">
          <label [pTooltip]="effectivenesNAText" [escape]="false" tooltipPosition="top"
            tooltipStyleClass="toolTipClass">
            <input class="form-check-input" type="radio" (change)="changeIsEffective()" value="NotApplicable" formControlName="IsEffective" id="N/A">
            {{ "Effectiveness_RadioButton_NotApplicable" | translate }}
          </label>
        </div>
      </div>

      <!-- Based on sellecton Yes radio button  -->
      <ng-container *ngIf="this.effectivenessForm.controls['IsEffective'].value=='Yes'">
        <!-- <div class="row mb-1 mt-2">
                    <div class="col">
                        <div>
                            <label class="float-start me-2">Effectiveness Deadline date</label>
                        </div>
                        <div class="calendar-inputfield">
                            <p-calendar dateFormat="yy-mm-dd" [(ngModel)]="myDate1 "
                                (onSelect)="effectivenessDateOnSelect()" #calendar class="customtextfield-s"
                                formControlName="DeadlineDate" [pTooltip]="effectivenessCalendarText"
                                [escape]="true" tooltipPosition="top" tooltipStyleClass="toolTipClass"
                                [ngClass]="{'error': submitted && fectch['DeadlineDate'].value < myDate2 || submitted && fectch['DeadlineDate'].value.myDate1 }">
                                >
                            </p-calendar>
                        </div>
                    </div>
                </div> -->
      </ng-container>

      <ng-container *ngIf="this.effectivenessForm.controls['IsEffective'].value=='No'">
        <div class="row mb-1 mt-2">
          <div class="col">
            <!-- <div class="text-end">
                            <label class="float-start me-2">Effectiveness deadline date</label>
                        </div>
                        <div class="calendar-inputfield">
                            <p-calendar class="customtextfield-s" formControlName="DeadlineDate"
                                [ngClass]="{'error': submitted && fectch['DeadlineDate'].errors}"></p-calendar>
                        </div> -->
          </div>
          <div class="row remove-ps remove-ps-tab">
            <p class="mb-0 mt-1 remove-px">
              <label class="desc-label">{{ "Complaint_EffectivenessReason" | translate }}
                <span class="asteriskRequired"
                  *ngIf="this.effectivenessForm.controls['EffectivenessReason']?.validator"></span></label>
            </p>
            <textarea pInputTextarea [autoResize]="true" class="form-control customtextfield-l"
              formControlName="EffectivenessReason"
              [ngClass]="isSubmitted && this.effectivenessForm.controls['EffectivenessReason'].invalid ? 'error-inputbox' : ''" maxlength="{{maxLength.EFFECTIVENESS_REASON}}"></textarea>

          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="this.effectivenessForm.controls['IsEffective'].value=='NotApplicable'">
        <div class="row mb-1 mt-2">
          <div class="col">
            <!-- <div>
                            <label class="float-start me-2">Effectiveness Deadline date</label>
                        </div>
                        <div class="calendar-inputfield">
                            <p-calendar class="customtextfield-s" formControlName="DeadlineDate"
                                [ngClass]="{'error': submitted && fectch['DeadlineDate'].errors}"></p-calendar>
                        </div> -->
          </div>
            <div class="row remove-ps remove-ps-tab">
              <p class="mb-0 mt-1 remove-px"><label class="desc-label">{{ "Complaint_EffectivenessReason" |
                  translate }}
                  <span class="asteriskRequired"
                    *ngIf="this.effectivenessForm.controls['EffectivenessReason']?.validator"></span></label></p>
              <textarea pInputTextarea [autoResize]="true" class="form-control customtextfield-l"
                [ngClass]="isSubmitted && this.effectivenessForm.controls['EffectivenessReason'].invalid ? 'error-inputbox' : ''"
                formControlName="EffectivenessReason" maxlength="{{maxLength.EFFECTIVENESS_REASON}}"></textarea>
            </div>
        </div>
      </ng-container>
      <div class="row mb-1 mt-3">
        <div class="col mb-1  md-1">
          <div class="col  text-end margin-space">
            <label class="float-start me-2 mt-1">{{ "rptv_effectiveness_deadline_dt" | translate }} <span
                class="asteriskRequired"></span></label>
          </div>
          <!-- <div class=" calendar-inputfield"> -->
          <div class="col-md-7 col-sm-5 calendar-inputfield">
            <p-calendar dateFormat="yy-mm-dd" (onSelect)="effectivenessDateOnSelect()" #calendar
              class="customtextfield-s" formControlName="DeadlineDate" [readonlyInput]="true"
              [pTooltip]="effectivenessCalendarText" [escape]="true" tooltipPosition="top"
              tooltipStyleClass="toolTipClass"
              [ngClass]="isSubmitted && this.effectivenessForm.controls['DeadlineDate'].invalid ? 'error-calendarInput' : ''">
            </p-calendar>
            <div>
              <input type="hidden" formControlName="effectiveChangedValue">
              <!-- <button type="button" class="widgetButtoMediumView ms-2  calendar-btn"
                                [pTooltip]="effectivenessCalendarText" [escape]="true" tooltipPosition="top"
                                tooltipStyleClass="toolTipClass"
                                (click)="openCalendar($event, 'selection')">
                                {{this.effectivenessForm.controls["DeadlineDate"].value == ""
                                    ? "Select"
                                    : "Change"
                                    }}</button> -->
            </div>
            <!-- <div  class=""  style="color: red;"
                        *ngIf="fectch['effectivenessText'].touched && fectch['effectivenessText'].invalid">Field
                        Describe objective evidence as to why the corrective/preventive action was not effective
                        or not required. is required</div> -->
          </div>

        </div>
      </div>

      <div class="row">
          <h6 class="inner-heading1-Effectiveness-Sustainability">Attachments</h6>
          <div class="row mb-1 attachments-section margin-space">
            <div class="col-md-3 col-sm-3 ps-0 pe-0 ms-3 me-3 label-d-left-m-right" *ngIf="uploadedAttachments?.length > 0">
              <label class="mt-2">{{ "ComplaintForm_UploadedAttachments" | translate }}</label>
            </div>
            <div class="col-md-8 col-sm-9 p-0 selectfile" *ngIf="uploadedAttachments?.length > 0">
              <div class="already-uploaded-attachments-main">
                <div class="row file-row" *ngFor="let uploadedAttachment of uploadedAttachments; let attachmentIndex = index;">
                  <div class="col-md-11 col-sm-11 filenamewidth1"><a class="fileInfo"
                      [ngClass]="checkRemoved(attachmentIndex) ? 'to-be-removed' : ''"
                      (click)="downloadAttachementFromAPI(uploadedAttachment?.storeFileName,uploadedAttachment?.displayName)"
                      style="cursor: pointer;" target="_blank">{{uploadedAttachment?.displayName}}</a>
                  </div>
                  <div class="col-md-1 col-sm-1 filenamewidth2">
                    <i class="fa fa-window-close closebtn" aria-hidden="true"
                    (click)="uploadedAttachmentRemove(attachmentIndex)"> </i>
                    <!-- <button type="button" class="widgetButtoSmall attachButton"
                      (click)="uploadedAttachmentRemove(attachmentIndex)"> X </button> -->
                  </div>
                </div>

              </div>
              <div class="row">
                <span class="downloadAllLink">
                  <a href="{{downloadAllUrl}}" target="_blank">Download All</a>
                </span>
              </div>
            </div>
            <div class="col-md-3 col-sm-3 ps-0 pe-0 mt-1 label-d-left-m-right">
              <label class="mt-2  me-3">{{ "ComplaintForm_SpecifyAttachment" |
                translate:paramForAttachment
                }}</label>
            </div>

            <div class="col-md-8 col-sm-9 selectfile remove-px">
              <p-fileUpload chooseLabel="Select Files" chooseIcon="null" removeStyleClass="p-overlaypanel"
                [multiple]="true" chooseStyleClass="p-overlaypanel" [maxFileSize]="60000000" [auto]="true"
                #createUploaderEffectiveness [customUpload]="true"
                (uploadHandler)="myUploaderEffectiveness(createUploaderEffectiveness,$event)"
                [disabled]="!disableSection || disableBtn['file_upload_btn']">
                <ng-template let-fileEffectiveness pTemplate="file">
                  <ul class="p-upload-files p-reset">
                    <li class="p-file row">
                      <div class="col-md-11 col-sm-11 filenamewidth1" [pTooltip]="fileEffectiveness?.name" [escape]="false"
                        tooltipPosition="top" tooltipStyleClass="toolTipClass">
                        <span class="p-filename">{{fileEffectiveness?.name}}</span>
                      </div>
                      <div class="col-md-1 col-sm-1 filenamewidth2" [pTooltip]="removeText" [escape]="false" tooltipPosition="top"
                        tooltipStyleClass="toolTipClass">
                        <!-- <button type="button" class="widgetButtoSmall attachButton"
                                                    (click)="removeFileEffectiveness(fileEffectiveness, createUploaderEffectiveness)">
                                                    X </button> -->

                        <i class="fa fa-window-close closebtn" aria-hidden="true"
                          (click)="removeFileEffectiveness(fileEffectiveness, createUploaderEffectiveness)">
                        </i>
                      </div>
                    </li>
                  </ul>
                </ng-template>
              </p-fileUpload>
            </div>
          </div>
      </div>
        <input type="hidden" formControlName="uploadedEffectivenessAttachments">
        <input type="hidden" formControlName="reopenReason">

        <div class="mb-5 effectivenssbuttons">
          <button class="input widgetButtoMediumView me-0" type="button" style="float: right;"
            [pTooltip]="effectivenesconifirmText" [escape]="true" tooltipPosition="left"
            tooltipStyleClass="toolTipClass"
            [ngClass]="!disableBtn['confirm_effectiveness'] || complaintData?.areaComplaintEffectiveness?.isReadOnly ?'grayButton': ''"
            [disabled]="!disableBtn['confirm_effectiveness'] || complaintData?.areaComplaintEffectiveness?.isReadOnly"
            (click)="Confirmed($event)">{{
            "ButtonClosureConfirmation" |
            translate }}</button>
          <!-- {{!disableBtn['reOpen_effectiveness']}}
                            {{complaintData?.areaComplaintEffectiveness?.isReadOnly}}
                            {{!isSupportDesk}} -->
          <button class="input widgetButtoMediumView" type="button" style="float: right;" (click)="reopenIssue($event)"
            [disabled]="!disableBtn['reOpen_effectiveness'] || complaintData?.areaComplaintEffectiveness?.isReadOnly && !isSupportDesk"
            [ngClass]="!disableBtn['reOpen_effectiveness'] || complaintData?.areaComplaintEffectiveness?.isReadOnly && !isSupportDesk ?'grayButton': ''"
            [pTooltip]="effectivenesReopenText" [escape]="true" tooltipPosition="top"
            tooltipStyleClass="toolTipClass">{{ "ButtonReopenIssue" | translate
            }}</button>
          <!-- {{!disableBtn['saveText_effectiveness']}}
                        {{complaintData?.areaComplaintEffectiveness?.isReadOnly}} -->
          <button class="input widgetButtoMediumView" type="button" style="float: right;"
            [pTooltip]="effectivenesSaveText"
            [disabled]="!disableBtn['saveText_effectiveness'] || complaintData?.areaComplaintEffectiveness?.isReadOnly"
            [ngClass]="!disableBtn['saveText_effectiveness'] || complaintData?.areaComplaintEffectiveness?.isReadOnly ?'grayButton': ''"
            [escape]="true" tooltipPosition="top" tooltipStyleClass="toolTipClass" (click)="onSubmit($event)">{{
            "ButtonSave" | translate }}
          </button>
        </div>
    </form>
  </div>
</div>
