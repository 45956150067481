import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { AuthTokenService } from './auth-token.service';

@Injectable()
export class APIParameterInterceptor implements HttpInterceptor {
    constructor(private authTokenUser: AuthTokenService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

      const parameter1 = 'User';
      const parameter1Value = `${this.authTokenUser.getAuthUserEmail()}`;
      const parameter2 = 'Language';
      const parameter2Value = `${this.authTokenUser.getBrowserLanguage()}`;

      const modifiedRequest = request.clone({
        setHeaders : {
          [parameter1] : parameter1Value,
          [parameter2] : parameter2Value
        }
      });

      return next.handle(modifiedRequest);

    }
}
