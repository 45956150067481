import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TreeNode } from 'primeng/api';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CopqCategoryService {

  constructor(private http: HttpClient) { }

  getFiles() {
    return this.http
      .get<TreeNode>(`${environment.azure.baseUrl}api/v1/copqcategories`)
      .toPromise()
      .then((res) => <TreeNode[]>res);
  }
  getCopqSearch() {
    // const parendId = {parentId: ''}
    return this.http
      .get<TreeNode>(`${environment.azure.baseUrl}api/v1/ResolutionSummary/copqcategorysearch`)
      .toPromise()
      .then((res) => <TreeNode[]>res);
  }

  getCopqSearchbyId(id) {
    // const parendId = {parentId: ''}
    return this.http
      .get<TreeNode>(`${environment.azure.baseUrl}api/v1/copqcategories/` + id)
      .toPromise()
      .then((res) => <TreeNode[]>res);
  }
}
