
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DialogService, DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { AdminService } from 'src/app/services/admin.service';
import { ToasterserviceService } from 'src/app/services/toasterservice.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { RuAuthService } from '../ru-auth.service';

@Component({
  selector: 'app-resolving-add-person-popup',
  templateUrl: './resolving-add-person-popup.component.html',
  styleUrls: ['./resolving-add-person-popup.component.css']
})
export class ResolvingAddPersonPopupComponent implements OnInit {
  resolvingunitaddpersonForm!: FormGroup
  filteredSubstitude: any;
  getRupersons: Object;
  id: any;
  userinfo: any;
  selectedbu: any = [];
  chkRuPerson: boolean;
  selectedHubs: any = [];
  cardData: Array<any> = [];
  data: any = { guids: [] };
  gethubidcard:any=[];
  datacordinator: any = { guidcordinator: [] };

  constructor(
    private fb: FormBuilder,
    private readonly dialogRef: DynamicDialogRef,
    private adminService: AdminService,
    public config: DynamicDialogConfig,
    public toasterService: ToasterserviceService,
    private spinner: NgxSpinnerService,
    private ruAuthService: RuAuthService
  ) {

  }

  ngOnInit(): void {
    this.resolvingunitaddpersonForm = this.fb.group({
      'selectedRu': [],
      'processOwner': [],
      'coordinator': [],
      'hubIds': ['',Validators.required]
    })
    this.userinfo = this.config.data;

    this.selectedHubs = this.config.data?.selectedHubs;

    this.resolvingunitaddpersonForm.patchValue({

      hubIds: this.selectedHubs

    })

    this.userinfo.hubArray.forEach(element => {
      if(element?.leadCountryName) {
        this.cardData.push({ id: element.id, value: element.divCode + ', ' + element.buCode })
      }
      else {
        this.cardData.push({ id: element.id, value: element.divCode + ', ' + element.buCode })
      }
    });
  }


  customerSelect(event) {
    this.resolvingunitaddpersonForm.controls['processOwner'].value.forEach(customer => {
      if (!this.data.guids.includes(customer?.id)) {
        this.data.guids.push(customer?.id)
      }
    });
  }
  customerGuidsUnselect(event) {

    const unselectedGuids = event.id;
    const index = this.data.guids.findIndex(item => item === unselectedGuids);
    if (index !== -1) {
      this.data.guids.splice(index, 1)
    }
  }


  removecardid(event,cardData) {

    this.userinfo;
    const unselecteremovedGuids = event.id;

    // const index = this.data.selectedHubs.findIndex(item => item === unselecteremovedGuids);
    // if (index !== -1) {
    //   this.data.guids.splice(index, 1)
    // }
    //console.log(this.userinfo.selectedHubs,'selectedHubs');
    //console.log(unselecteremovedGuids,'to be removed');


        const index =  this.selectedHubs.findIndex(c=> c.id == unselecteremovedGuids);
        //console.log(index);

        if (index !== -1) {
          this.userinfo.selectedHubs.splice(index, 1)
        }


  }

  customerSelectcoordinator(event) {
    this.resolvingunitaddpersonForm.controls['coordinator'].value.forEach(customer => {
      if (!this.datacordinator.guidcordinator.includes(customer?.id)) {
        this.datacordinator.guidcordinator.push(customer?.id)
      }
    });
  }
  customerGuidsUnselectcoordinator(event) {
    const unselectedGuids = event.id;
    const index = this.datacordinator.guidcordinator.findIndex(item => item === unselectedGuids);
    if (index !== -1) {
      this.datacordinator.guidcordinator.splice(index, 1)
    }
  }

  add() {
    this.spinner.show();

    let formatData =
    {
      "ruIds": this.selectedHubs.map((a) => (a.id)),

      "ruProcessOwnerGeids": this.data.guids
      ,
      "coordinatorGeids":
        this.datacordinator.guidcordinator
    }

    let selectedRus: any = [];
    this.userinfo.cardinfo.forEach(item => {
      if (item.id > -1) {
        selectedRus.push(item);
      }
    });

    if(this.ruAuthService.canManageRus(selectedRus)){
      this.adminService.getRupersons(formatData).subscribe((res) => {
        this.getRupersons = res;
        this.dialogRef.close(this.getRupersons);
        this.toasterService.showSuccess('Resolving Unit person has been successfully created');
        this.spinner.hide();
      })
    }
    else{
      this.toasterService.showError('Cannot add this Ru Persons. Insufficient permissions.');
      this.spinner.hide();
    }
  }

  onClose() {
    this.dialogRef.close();
    this.spinner.hide();
  }

  filterSubstitude(event) {
    if (event.originalEvent.target.value.length > 2) {
      const dataPasses = { wildcard: event.originalEvent.target.value, onlyActive: true, page: 1, size: 50 };
      this.adminService.getFilteredUser(dataPasses).subscribe((response) => {
        this.filteredSubstitude = response;
        // this.filteredSubstitude.map((data) => {
        //   data.name = data.name + '<' + data?.email + '>';
        // })
      })
      this.chkRuPerson = true;
    }

  }
}
