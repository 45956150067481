export enum PermissionCode {
    NONE = 0,

    /// <summary>
    /// Can ADD/EDIT/DELETE Languages.
    /// </summary>
    ADD_EDIT_DELETE_Languages = 101,

    /// <summary>
    /// Can ADD/EDIT/DELETE Resource Editors.
    /// </summary>
    ADD_EDIT_DELETE_ResourceEditors = 102,

    /// <summary>
    /// Can ADD/EDIT/DELETE Support Desk people.
    /// </summary>
    ADD_EDIT_DELETE_SupportDescPeople = 103,

    /// <summary>
    /// Can ADD/EDIT/DELETE Coordinators.
    /// </summary>
    ADD_EDIT_DELETE_Coordinators = 104,

    /// <summary>
    /// Can ASSIGN/REMOVE Resolving Units from a Coordinator.
    /// </summary>
    ASSIGN_REMOVE_ResolvingUnits_from_Coordinators = 105,

    /// <summary>
    /// Can ASSIGN/REMOVE Coordinators from Resolving Units.
    /// </summary>
    ASSIGN_REMOVE_Coordinators_from_ResolvingUnits = 106,

    /// <summary>
    /// Can ADD/EDIT/DELETE Local Opex Managers.
    /// </summary>
    ADD_EDIT_DELETE_LocalOpexManagers = 107,

    /// <summary>
    /// Can ADD/EDIT/DELETE FAQ Categories/Questions/Answers.
    /// </summary>
    ADD_EDIT_DELETE_FAQ_Categories_Questions_Answers = 108,

    /// <summary>
    ///  Can EDIT system messages.
    /// </summary>
    EDIT_System_Messages = 109,

    /// <summary>
    /// Can EDIT who can edit which field
    /// </summary>
    CAN_Manage_Permissions = 110,

    /// <summary>
    /// Can ADD/EDIT/SET ACTIVE/INACTIVE Customer Contacts.
    /// </summary>
    ADD_EDIT_SET_ACTIVE_INACTIVE_CustomerContacts = 111,

    /// <summary>
    ///  Can EDIT the time before level 1 and level 2 escalation.
    /// </summary>
    EDIT_time_before_level_1_and_2_Escalation = 112,

    /// <summary>
    /// Can EDIT the time before level 1 and level 2 draft complaint notification.
    /// </summary>
    EDIT_time_before_level_1_and_2_draft_complaint_notification = 113,

    /// <summary>
    /// Can set the number of hours before non-assignment notification.
    /// </summary>
    EDIT_number_of_hours_before_non_assignment_notification = 114,

    /// <summary>
    ///  Can ADD/EDIT/SET INACTIVE/ACTIVE PG Categories.
    /// </summary>
    ADD_EDIT_SET_INACTIVE_ACTIVE_PgCategories = 117,

    /// <summary>
    /// Can ADD/EDIT/Set Active/Inactive OPQ Categories.
    /// </summary>
    ADD_EDIT_SET_INACTIVE_ACTIVE_OpqCategories = 118,

    /// <summary>
    /// Can ADD/EDIT/DELETE Global CCRP Process Owners.
    /// </summary>
    ADD_EDIT_DELETE_Global_CCRP_Process_Owners = 119,

    /// <summary>
    /// Can ADD Back up Resource Editors to their Language.
    /// </summary>
    ADD_BackUp_Resource_Editors = 120,

    /// <summary>
    /// Can browse reports.
    /// </summary>
    CAN_BROWSE_Reports = 121,

    /// <summary>
    /// Can access admin page.
    /// </summary>
    HAS_ACCESS_TO_ADMIN_PAGE = 122,

    /// <summary>
    /// Can access to search page
    /// </summary>
    CAN_ACCESS_TO_SEARCH_PAGE = 123,

    /// <summary>
    /// Can ADD/DELETE DIV Opex Mangers.
    /// </summary>
    ADD_EDIT_G_OPEX_DIV_Managers = 131,

    /// <summary>
    /// Can ADD/DELETE BU Opex Mangers.
    /// </summary>
    ADD_EDIT_G_OPEX_BU_Managers = 132,

    /// <summary>
    /// Can ADD/DELETE PG Opex Mangers.
    /// </summary>
    ADD_EDIT_G_OPEX_PG_Managers = 133,

    /// <summary>
    /// Can mangage Employee Manager RU association.
    /// </summary>
    CAN_MANAGE_EMPLOYEEMANAGER_RU_ASSOCIATION = 134,

    /// <summary>
    /// Can mangage Country Managers.
    /// </summary>
    CAN_MANAGE_COUNTRY_MANAGERS = 135,

    /// <summary>
    /// Can mangage Global RUs.
    /// </summary>
    CAN_MANAGE_GLOBAL_RUs = 136,

    /// <summary>
    /// Can edit the complaint capture close sections
    /// </summary>
    EDIT_Complaint_Capture_Section = 201,

    /// <summary>
    /// Can edit the complaint assignment section
    /// </summary>
    EDIT_Complaint_Assignment_Section = 202,

    /// <summary>
    /// Can edit the complaint resolution sections
    /// </summary>
    EDIT_Complaint_Resolution_Section = 203,

    /// <summary>
    /// Can edit the complaint close section
    /// </summary>
    EDIT_Complaint_Close_Section = 204,

    /// <summary>
    /// Can verify resolved complaint when required (coordinator)
    /// </summary>
    Verify_Complaint_After_Resolution_When_Required = 205,

    /// <summary>
    /// Can edit Resolution Owner Team members
    /// </summary>
    Can_EDIT_Resolution_Owner_Team_members = 206,

    /// <summary>
    /// Can edit Resolution Owner
    /// </summary>
    Can_EDIT_Resolution_Owner = 207,

    /// <summary>
    /// Can return complaint to Complaint Owner
    /// </summary>
    Can_ReturnTo_Complaint_Owner = 208,

    /// <summary>
    /// Can edit complaint additional subject information section
    /// </summary>
    EDIT_Complaint_Addition_Subject_Info_Section = 209,

    /// <summary>
    /// Can edit the complaint description section
    /// </summary>
    EDIT_Complaint_Description_Section = 210,

    /// <summary>
    /// Can edit the complaint customer contact section
    /// </summary>
    EDIT_Complaint_Customer_Contact_Section = 211,

    /// <summary>
    /// Can edit the complaint customer section
    /// </summary>
    EDIT_Complaint_Customer_Section = 212,

    /// <summary>
    /// Can revert InProc complaints to Not Assigned status
    /// </summary>
    Revert_InProc_to_NotAssigned = 213,

    /// <summary>
    /// Can edit ResolvingUnit
    /// </summary>
    Can_EDIT_ResolvingUnit = 214,

    /// <summary>
    /// Can add related parties persons
    /// </summary>
    Can_EDIT_Related_Parties = 215,

    /// <summary>
    /// Can add related parties persons
    /// </summary>
    Can_CONFIRM_REJECT_HighImpact = 216,

    /// <summary>
    ///     Can edit Current Rating Scoring Matrix
    /// </summary>
    EDIT_Complaint_Current_Rating_Scoring_Matrix = 217,

    ///<summary>
    /// Can edit No Report To Hitachi Fields
    ///</summary>
    Can_EDIT_Complaint_No_Report_Hitachi = 218,

    /// <summary>
    /// Can access admin section
    /// </summary>
    Access_Admin_Section = 300,

    /// <summary>
    /// Can access admin manage RU section
    /// </summary>
    Access_Admin_ManageRU_Section = 301,

    /// <summary>
    /// Can access admin process owners section
    /// </summary>
    Access_Admin_Manage_ProcessOwners_Section = 302,

    /// <summary>
    /// Can access admin escalation times section
    /// </summary>
    Access_Admin_Manage_EscalationTimes_Section = 303,

    /// <summary>
    /// Can access admin pg categories section
    /// </summary>
    Access_Admin_Manage_PGCategories_Section = 304,

    /// <summary>
    /// Can access admin customer contacts section
    /// </summary>
    Access_Admin_Manage_CustomerContacts_Section = 305,

    /// <summary>
    /// Can access admin traslations section
    /// </summary>
    Access_Admin_Manage_Translations_Section = 306,

    /// <summary>
    /// Can access admin manage permissions section
    /// </summary>
    Access_Admin_Manage_Permissions_Section = 307,

    /// <summary>
    /// Can edit the complaint effectiveness section
    /// </summary>
    EDIT_Complaint_Effectiveness_Section = 311,

    EDIT_Complaint_Confidential = 320,

    Access_WeeklyReport_Section = 321,

    Access_Admin_ActiveUsers_Section = 322
}

export const ComplaintStatuses: any = {
    "Empty": 0,
    "New": 1,
    "Draft": 2,
    "NotAssigned": 3,
    "InProcess": 4,
    "InProcessOverdue": 5,
    // "In Process ReOpen": 6,
    "Resolved": 7,
    "Completed": 8,
    // "Cancelled": 9,
    "InProcessVerification": 11,
    "InProcessVerificationOverdue": 12,
    "CompletedExecutionPending": 13
}

export enum RoleCode {

    Global_CCRP_Process_Owner = 1,

    Support_Desk = 2,

    Complaint_Owner = 3,

    Complaint_Owner_Supervisor = 4,

    Complaint_Owner_Team = 5,

    Complaint_Coordinator = 6,

    Resolution_Owner = 7,

    Resolution_Owner_Team = 8,

    LBU_Manager = 9,

    Ru_Process_Owner = 10,

    Div_Opex_Manager = 11,

    BU_Opex_Manager = 12,

    PG_Opex_Manager = 13,

    Resource_Editor = 14,

    Country_Manager = 15,

    Process_Owner = 16,

    Div_Process_Owner = 17,

    Bu_Process_Owner = 18,

    Pg_Process_Owner = 19,

    Hub_Process_Owner = 20,

    Management_Role = 21
}
