import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-choose-role-pop-up',
  templateUrl: './choose-role-pop-up.component.html',
  styleUrls: ['./choose-role-pop-up.component.css'],
})
export class ChooseRolePopUpComponent implements OnInit {

receivedRoles!:any;
roleSelect: FormGroup;
  constructor(
  private readonly dialogRef: DynamicDialogRef,
  public config: DynamicDialogConfig,
  private fb: FormBuilder,
  ){
    this.receivedRoles = config.data.complaintRoles;
  }

  ngOnInit(): void {
    this.roleSelect = this.fb.group({
      'role': new FormControl(this.receivedRoles[0]?.id),
    });

  }

  popupClose() {
    let selectedRole = this.receivedRoles.filter(
      (item) =>{
      return item.code == this.roleSelect?.controls['role'].value
      });
    this.dialogRef.close(selectedRole);
  }

}
