<div class="deleteSystemUser deactivate_adminSystemUser">
<div class="row">
      <div class="col-sm-12">
          <p class="control-label">
              <span>Are you sure you want to remove </span>
              <strong>{{suScope.name}}</strong>
              <span> from being </span>
              <strong *ngIf="suRoleId==1">{{'system_user_sr_globalpo_lbl'|translate}}</strong>
              <strong *ngIf="suRoleId==2">{{'system_user_sr_supportdesk_lbl'|translate}}</strong>
              <strong *ngIf="suRoleId==14">{{'system_user_sr_langeditor_lbl'|translate}}</strong>
              <strong *ngIf="suRoleId==21">{{'system_user_sr_management_role_lbl'|translate}}</strong>
              <strong> ?</strong>
            </p>
      </div>
  </div>
<div class="modal-footer mb-3">
  <!-- <div class="col-sm-9"></div>
  <div class="col-sm-3"> -->
    <button class="widgetButtoSmall" (click)="cancel()">{{'podelete_mod_cancel_btn'|translate}}</button>
    <button class="widgetButtoSmall ms-1" (click)="ok()">{{'podelete_mod_ok_btn'|translate}}</button>
  <!-- </div> -->

</div>
</div>
