export class ActionPlanCard {
    id?: number;
    statusId?: number;
    type?: string;
    typeName?: string;
    
    targetDate?: string;
    lastUpdateDate?: string; //added by Manoj
    // createDate?: string;//added by Manoj
    // rejectReason?: string; //added by Manoj
    description?: string; //added by Manoj
    // activities?: string; //added by Manoj
    files?: any[] //added by Manoj
    permission?: any;

    actionOwnerGeid?: string;
    name: string;
    complaintId?: number;
    complaintNo?: string;
    interestedPartiesGeids?: string[];
    ownerName: string;
    interestedParties?: {};
    
    creatorGeid?: string;
    rowVersion?: string;
    status?: string;
    isRejected?: boolean;
    isReopened?: boolean;
    actionOwner?: any;
    actionCreator?: any;
  } 

  //added by Manoj
  // export class Permission{
  //   isCreator: boolean;
  //   isActionOwner: boolean;
  // }