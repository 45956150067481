import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { MessageService } from 'primeng/api';
import { Router } from '@angular/router';

@Component({
  selector: 'app-reopen-issue-popup',
  templateUrl: './reopen-issue-popup.component.html',
  styleUrls: ['./reopen-issue-popup.component.css'],

})
export class ReopenIssuePopupComponent implements OnInit {
  reopenIssueForm!: FormGroup;
  flagForRejectComplaint:boolean=false;

  constructor( private readonly dialogRef: DynamicDialogRef,
    private messageService: MessageService,  private router: Router,
    private fb:FormBuilder,
    public config: DynamicDialogConfig) {
      this.flagForRejectComplaint = this.config?.data;
     }

  ngOnInit(): void {

    this.reopenIssueForm = this.fb.group({

      reopenIssueText: ['',Validators.required]
  })
}
  // close button
  onClose() {
    this.dialogRef.close();
  }
  confirm(){
   // this.messageService.add({ severity: 'warn', summary: '', detail: 'Please enter required fields' });
    // setTimeout(() => {
    //   this.router.navigate(['/mydashboard']);
    // }, 10000)
    this.dialogRef.close(this.reopenIssueForm.controls['reopenIssueText'].value);
  }
}
