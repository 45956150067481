import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ProcessStepService } from '../process-step.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-admin-edit-process-steps-modal',
  templateUrl: './admin-edit-process-steps-modal.component.html',
  styleUrls: ['./admin-edit-process-steps-modal.component.css']
})
export class AdminEditProcessStepsModalComponent implements OnInit {

  processStepModal !: FormGroup;
  item;
  text;
  isSaving = false;
  validationItem ={
    hasErrors: false,
    isActiveItem: true
  }

  constructor(public config: DynamicDialogConfig,
    private formBuilder: FormBuilder,
    private ref: DynamicDialogRef,
    private processStepService: ProcessStepService) { }

  ngOnInit(): void {
    this.item = this.config.data;
    this.text = this.config.data.name

    this.processStepModal = this.formBuilder.group({
      itemName : new FormControl('', [Validators.required, Validators.maxLength(100)]),
      isActiveCheck : new FormControl()
    })

    if(this.config.data.id !== undefined){
      this.processStepModal.patchValue({
        itemName : this.config.data.name,
        isActiveCheck : this.config.data.isActive
      })
    }

    this.processStepModal.get('itemName').valueChanges.subscribe(val =>{
      this.validationItem.isActiveItem = true;
      let foundItem : any = [];
      foundItem = _.filter(this.item.duplicateScope, (sc)=> {
        return sc.name.toUpperCase() === val.toUpperCase();
      });

      if(foundItem.length){
        if(foundItem.length === 1){
          let process = foundItem[0];
          if(!process.isActive){
            this.validationItem.isActiveItem = false;
          }
        }
        this.validationItem.hasErrors = true;
      }
      else{
        this.validationItem.hasErrors = false;
      }
    })

  }

  cancel(){
    this.ref.close({result : 'none'});
  }

  save(){
    this.isSaving = true;
    if(this.item.id != undefined){
      this.item.isActive = this.processStepModal.get('isActiveCheck').value;
      this.item.name = this.processStepModal.get('itemName').value;
      this.processStepService.save(this.item).subscribe((data)=>{
        if(data){
          this.isSaving = false;
          this.ref.close({
            result: 'ok',
            model: this.item,
            results: data
          });
        } else{
          this.isSaving = false;
          this.ref.close({result : 'none'});
        }
      },
      ()=>{
        this.isSaving = false;
      })
    }
    else{
      this.item.name = this.processStepModal.get('itemName').value;
      this.processStepService.addProcessStepsItem(this.item).subscribe((data)=>{
        this.isSaving = false;
        this.ref.close({
          result: 'ok',
          model: data,
        });
      },
      ()=>{
        this.isSaving = false;
      })
    }
  }

}
