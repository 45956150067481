import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UniqueActionNameWithinBoardService {

  constructor(private http: HttpClient) { }

  checkUniqueActionName(complaintNo: string, actionName: string){
      return this.http.post(`${environment.azure.baseUrl}api/v1/actionplan/checkactionnameuniqueness?actionName=` + actionName + `&complaintNo=` + complaintNo, null);
  }

  actionPlanSave(saveData) {
      return this.http.post(`${environment.azure.baseUrl}api/v1/actionplan`, saveData);
  }

  getComplaintData(cNumber) {
    return this.http.get(`${environment.azure.baseUrl}api/v1/complaint?complaintNumber=` + cNumber);
  }

  getSaveData(formData, complaintNo, complaintId, statusId): any {
    const interestedParties = formData['interestedParties']?.value;
    let partyData;
    if(interestedParties?.length > 0) {
      partyData = interestedParties.map((res) => {
        const iso = res.countryCode;
        const city = res.city;
        const name = res.selectedLabel;
        const id = res.geid;
        const displayGeid = res.geid;
        const buCode = res?.level2OrgCode;
        const selectedLabel = res?.selectedLabel;
        const email = res?.selectedLabel;
        const deactivateDate = res?.deactivateDate;
        return { id, displayGeid, buCode, name, iso, city, email, deactivateDate, selectedLabel }
      })
    }

    let actionOwnerData =
          {
            "id": formData['actionOwner']?.value[0]?.geid ? formData['actionOwner']?.value[0]?.geid : '',
            "displayGeid": formData['actionOwner']?.value[0]?.geid ? formData['actionOwner']?.value[0]?.geid : '',
            "name": formData['actionOwner']?.value[0]?.selectedLabel ? formData['actionOwner']?.value[0]?.selectedLabel : '',
            "iso": formData['actionOwner']?.value[0]?.iso ? formData['actionOwner']?.value[0]?.iso : '',
            "city": formData['actionOwner']?.value[0]?.city ? formData['actionOwner']?.value[0]?.city : '',
            "email": formData['actionOwner']?.value[0]?.selectedLabel ? formData['actionOwner']?.value[0]?.selectedLabel : '',
            "deactivateDate": '2023-06-06T10:55:32.437Z',
            "buCode": null
          }

    if(!actionOwnerData.id) {
      actionOwnerData = null
    }

    return {
      "id": formData['id']?.value ? Number(formData['id']?.value) : 0,
      "statusId": statusId,
      "type": formData['actionType']?.value?.id?.toString() ? formData['actionType']?.value?.id?.toString() : '',
      "typeName": formData['actionType']?.value?.name ? formData['actionType']?.value?.name : '',
      "name": formData['actionName']?.value ? formData['actionName']?.value : '',
      "targetDate": formData['targetDate']?.value ? formData['targetDate']?.value : null,
      "lastUpdateDate": new Date(),
      "createDate": new Date(),
      "actionOwner": actionOwnerData,
      "actionCreator": {
        "id": formData['actionCreator']?.value[0]?.geid ? formData['actionCreator']?.value[0]?.geid : '',
        "displayGeid": formData['actionCreator']?.value[0]?.geid ? formData['actionCreator']?.value[0]?.geid : '',
        "name": formData['actionCreator']?.value[0]?.selectedLabel ? formData['actionCreator']?.value[0]?.selectedLabel : '',
        "iso": formData['actionCreator']?.value[0]?.iso ? formData['actionCreator']?.value[0]?.iso : '',
        "city": formData['actionCreator']?.value[0]?.city ? formData['actionCreator']?.value[0]?.city : '',
        "email": formData['actionCreator']?.value[0]?.selectedLabel ? formData['actionCreator']?.value[0]?.selectedLabel : '',
        "deactivateDate": '2023-06-06T10:55:32.437Z',
        "buCode": null
      },
      "ownerName": formData['actionOwner']?.value?.selectedLabel,
      "complaintId": complaintId,
      "complaintNo": complaintNo,
      "interestedParties": partyData,
      "creatorGeid": formData['actionCreator']?.value[0]?.geid,
      "rejectReason": null,
      "isRejected": true,
      "isReopened": true,
      "description": formData['description']?.value,
      "activities": formData['activities']?.value,
      "rowVersion": formData['rowVersion']?.value ? formData['rowVersion']?.value : '',
      "status": formData['status']?.value ? formData['status']?.value : '',
      "files": formData['files']?.value ? formData['files']?.value : [],
      "permission": null
    }
  }
}
