<form [formGroup]="deletePersonForm">
    <div class="deletePersonPopup">

        <div class="row">
            <div class="panel panel-default">
                <div class="panel-heading">
                    <h6 class="panel-title">
                        <div>
                            <span class="glyphicon glyphicon-home"></span>&nbsp;
                            <strong class="unitNameDeleteHub">{{userinfo.cardinfo.divCode}}, {{userinfo.cardinfo.buCode}},
                                {{userinfo.cardinfo.leadCountryName}}</strong>
                        </div>
                    </h6>
                </div>
            </div>
        </div>

        <div class="row personNameDeleteHub">
            <div class="col-md-10 col-md-offset-1">
                <span>{{'hubpersondelete_question_part_mod_lbl'|translate}}</span><br>
                <strong class="processOwnerHover"  (click)="gethubDataProcess()"  [ngbPopover]="popContentHubProcessOwner"
                [popoverTitle]="popoverTitleHubProcessOwner">{{userinfo.empinfo.name}} </strong>
            </div>
        </div>

        <div class="row text-left navbar-left admin-padding-left">
            <div class="control-label admin-error-msg " *ngIf="!canDelete">Cannot delete the only Hub Process Owner
            </div>
        </div>
        <div class="mt-2 d-grid gap-2 d-md-flex justify-content-md-end deactiveBtnDivHub">
            <button class="input widgetButtonLarge btnDeletePopUpHUB" type="button"
                (click)="onClose()">{{'rupersondelete_mod_cancel_btn'|translate}}</button>
            <button class="input widgetButtonLarge btnDeletePopUpHUB mx-2" type="button"
                (click)="delete()">{{'rupersondelete_mod_ok_btn'|translate}}
            </button>
        </div>

        <ng-template #popoverTitleHubProcessOwner>
          <span class="popover-title popoverTitle">
              {{ this.getrubsObjinfo?.name }} ({{this.getrubsObjinfo?.displayGeid}})
          </span>
      </ng-template>
      <ng-template #popContentHubProcessOwner style="position: relative">
          <div>
              <div style="margin-top: -12px;">
                  <span class="popoverCountry">
                      {{this.getrubsObjinfo.address.countryCode}},
                      {{this.getrubsObjinfo.address.city}},
                      {{this.getrubsObjinfo.address.address}}
                  </span>
                  <span>
                      <a href="https://maps.google.pl/?q={{ this.getrubsObjinfo.address.countryCode}}, {{ this.getrubsObjinfo.address.city}}, {{this.getrubsObjinfo.address.address}}"
                          target="_blank" class="gray-link">
                          <i class="hitachiicon-location"> </i>
                      </a>
                  </span>
                  <br>
                  <span>
                      <i class="glyphicon glyphicon-envelope"></i>&nbsp;
                      <a class="mail popoverMail" onMouseOver="this.style.color='#ff0000'"
                          onMouseOut="this.style.color='#000000'"
                          href="mailto:{{ this.getrubsObjinfo.email}}">{{this.getrubsObjinfo?.email}}</a>
                  </span>
                  <br>

                  <span *ngIf="this.getrubsObjinfo.phone !== '' && this.getrubsObjinfo.phone !== null">
                      <i class="glyphicon glyphicon-phone"></i>&nbsp;
                      <a class="phone popoverPhone" onMouseOver="this.style.color='#ff0000'"
                          onMouseOut="this.style.color='#000000'"
                          href="tel:{{this.getrubsObjinfo.phone}}">{{this.getrubsObjinfo.phone}}</a>
                  </span>

              </div>
              <strong class="associatedHubs mb-1">
                  {{'admin_hub_person_associatedrus_lbl' |translate}}
              </strong>

              <div class="row mt-2">
                  <div class="col-sm-12 process-owner" *ngIf="this.hubsForProcessOwner.length > 0">
                      <span style="background-color: #f7f7f7;">
                          {{'admin_hub_person_hubprocessowner_lbl'|translate}}
                          ({{this.iscountprocessorowner}})
                      </span>
                  </div>
                  <div>
                      <div *ngFor="let hub of hubsForProcessOwner" class="associatedhub-list">
                          <small pTooltip="{{hub}}" tooltipPosition="top" placement="top">
                              {{hub}}
                          </small>
                      </div>
                  </div>
              </div>
          </div>

      </ng-template>

    </div>
</form>
