import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TreeNode } from 'primeng/api';
import { DefectPopupService } from 'src/app/services/defect-popup.service';
import { PrimeNGConfig } from "primeng/api";
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-defect-type-popup',
  templateUrl: './defect-type-popup.component.html',
  styleUrls: ['./defect-type-popup.component.css']
})
export class DefectTypePopupComponent implements OnInit {

  files5: any[] = [];
  selectedNode!: TreeNode;
  loading: boolean = false;

  constructor(private http: HttpClient,
    private defectService: DefectPopupService,
    private config: DynamicDialogConfig,
    private readonly dialogRef: DynamicDialogRef) { }

  ngOnInit(): void {
    const payload = this.config?.data?.defect;
    this.loading = true;
    this.defectService.getFiles(payload).then(files => {
      if(files[0]?.id == null){
        this.files5 = [];
        this.loading = false;
      }
      else{
        this.files5 = files;
        this.loading = false;
        this.files5.map((res) => {
          res.label = res.name,
          res.data = res.flatName,
          // res.expandedIcon = 'pi pi-folder-open',
          // res.collapsedIcon = 'pi pi-folder',
          res.children = [],
          res.id = res.id,
          res.leaf = res.hasSubClasses == true ? false : true,
          res.selectable = res.hasSubClasses ? false : true
        });
      }
    }, (error)=>{
      this.loading = false;
    })
  }

  nodeSelect(event: any) {
    const nodeselection = event.node;
    if(nodeselection.hasSubClasses) {
      //this.loading = true;
      const payload = this.config?.data?.defect;
      payload.parentId = event?.node?.id;
      // this.defectService.getFiles(payload).then((res) => {
      //   if(res){
      //     this.files5 = this.newH(res)
      //     this.loading = false;
      //   }
      // })
      this.files5 = this.newH(nodeselection.subClasses)
    }
  }

  newH(newData: any, h = this.files5 ){
    h.forEach((node) => {
        if(node.id == newData[0]?.parentId){
        const New = []
         newData.map((res) => {
                  res.label = res.name,
                  res.data = res.flatName,
                  // res.expandedIcon = 'pi pi-folder-open',
                  // res.collapsedIcon = 'pi pi-folder',
                  res.children = res.hasSubClasses,
                  res.id = res.id,
                  res.leaf = res.hasSubClasses == true ? false : true
                });
          node.children = [...newData]
        } else if(node.children.length) {
           this.newH(newData, node.children)
        }
    })
    return h
  }

  // code for Data should be disabled
  enableAll() {
    this.files5.forEach((node: any) => {
      this.enableRecursive(node, false);
    })
  }

  private enableRecursive(node: any, isSelectable: boolean) {
    node.selectable = node.disabled;
    if (node.children) {
      node.children.forEach((childNode: any) => {
        this.enableRecursive(childNode, childNode.disabled);
      });
    }
  }

  selectAndClose() {
    this.dialogRef.close(this.selectedNode);
  }

}
