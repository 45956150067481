import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActionPlanCard } from 'src/app/models/new-issue/actionplan/ActionPlanCard';
import { environment } from 'src/environments/environment';

export interface IActionPlanService {
  getActionCards(complaintNo: string, selectedRole: string);
  assignActionCard(card: ActionPlanCard);
  getNewCardType();
  storeNewCardType(type);
  deleteCard(id: number);
  getActionItem(id: number);
  updateCard(card: ActionPlanCard); //commented
  rejectItem(card: ActionPlanCard);
  completeItem(card: ActionPlanCard);
  reopenItem(id: number, reason: string);
  saveItem(card: ActionPlanCard);
  getComplaintInformation(number: string);
}

@Injectable({
  providedIn: 'root'
})
export class ActionPlanService implements IActionPlanService{
  public static serviceId: string = "actionPlanService";
  private newCardType: string;

  constructor(private http: HttpClient) { }

  updateCard(card: ActionPlanCard) {

  }

  saveItem(card: ActionPlanCard) {

  }

  getActionItem(id: number) {
    return this.http.get<any>(`${environment.azure.baseUrl}api/v1/actionplan/action?id=` + id)
  }

  getComplaintInformation(number: string) {
    //return this.http.get<any>(`${environment.API_URL}/targetResolvingDate`)
    //targetResolvingDate

    let params = new HttpParams()
    .set('number', number)

    return this.http.get<any>(`${environment.azure.baseUrl}api/v1/actionplan/complaintInformation`, {params})
  }

  deleteCard(id: number) {
    return this.http.delete(`${environment.azure.baseUrl}api/v1/actionplan/delete?actionId=` + id)
  }

  reopenItem(id: number, reason: string) {
    let params = new HttpParams()
    .set('actionId', id)
    .set('reason', reason)

    return this.http.put(`${environment.azure.baseUrl}api/v1/actionplan/reopen`, null, {params : params})
  }

  // getActionCards(complaintNo: string, selectedRole: string) {
  //   return this.http.get<any>(`http://localhost:3000/allActions`);
  // }`${environment.API_URL}/allActions`
  // getActionCards() {
  //   return this.http.get<any>(`${environment.API_URL}/allActions`);
  //   //return this.http.get<any>(`http://localhost:3000/allActions`);
  // }

  getActionCards(complaintNo: string, selectedRole: string){
    let params = new HttpParams()
    .set('complaintNo', complaintNo)
    .set('selectedRole', selectedRole)

    return this.http.get<any>(`${environment.azure.baseUrl}api/v1/actionplan/board`, {params})
  }

  assignActionCard(card) {
    return this.http.post(`${environment.azure.baseUrl}api/v1/actionplan/assign`,card )
  }

  rejectItem(card: ActionPlanCard) {
    return this.http.post(`${environment.azure.baseUrl}api/v1/actionplan/reject`, card)
  }

  completeItem(card: ActionPlanCard) {
    return this.http.post(`${environment.azure.baseUrl}api/v1/actionplan/complete`,  card)
  }

  storeNewCardType(type: any) {
    if (!type) return;
    this.newCardType = type;
  }

  getNewCardType() {
    let type = this.newCardType;
    this.newCardType = null;
    return type;
  }

  actionBoardAttachmentSave(formData) {
    return this.http.post<any>(`${environment.azure.baseUrl}api/v1/actionfiles`, formData);
  }

  actionBoardRemoveAttachments(filename) {
    return this.http.delete<any>(`${environment.azure.baseUrl}api/v1/actionfiles?fileName=${filename}`);
  }

  getActionBoardAttachments(sectionId,actionId){
    return this.http.get<any>(`${environment.azure.baseUrl}api/v1/actionfiles/meta?actionId=${actionId}&sectionId=${sectionId}`);
  }
}
