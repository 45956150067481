<div>
  <div class="mdc-layout-grid">
    <div class="mdc-layout-grid__cell mdc-elevation--z3 reports-elevation">
      <h3><strong>CCRP Reports</strong>
      </h3>

      <ul class="report-list">
        <div *ngIf="!userInfo?.isManagementRole">
          <li>
            <a [routerLink]="['AllInOne']" title="{{'rep_menu_viewreport_tooltip' | translate}}">
              <strong>All In One (Refreshed every Four Hours)</strong>
            </a>
          </li>
          <!-- <li>
            <a href="../../../../assets/{{analyzerOldReportPath}}" title="{{'rep_menu_viewreport_tooltip' | translate}}">
              <strong>CCRP Analyzer</strong>
            </a>
          </li> -->
          <!-- <li>
            <a href="../../../../assets/{{analyzerArchiveReportPath}}" title="{{'rep_menu_viewreport_tooltip' | translate}}">
              <strong>CCRP Analyzer Archive</strong>
            </a>
          </li> -->
          <li style="display: flex;">
            <div><a href="../../../../assets/{{analyzerReportPath}}" title="{{'rep_menu_viewreport_tooltip' | translate}}">
                <strong>CCRP Analyzer</strong> <img src="../assets/images/NewGif.gif"
                  style="width: 50px; height: 25px; margin-left: 10px;" />
              </a></div>
            <div style="font-size: 11px; margin-left: 10px;">
              <a style="text-decoration: underline;cursor: pointer;" href="https://hitachipowergrids.sharepoint.com/:p:/r/sites/QCIO/ccrp/_layouts/15/Doc.aspx?sourcedoc=%7BB3129A78-0472-4F28-BE31-7C00095F0631%7D&file=Analyzer_Help_Document.pptx&action=edit&mobileredirect=true"
              target="_blank"><b>Help Document</b></a>
              <br>
              <a style="text-decoration: underline;cursor: pointer;" href="https://teams.microsoft.com/l/channel/19%3aLdeMGu_SGI7j6nllgDZq_f7wR2QFPJz-3b7YDyQOiFs1%40thread.tacv2/General?groupId=786f4ebb-81cc-4041-a8ba-0a90c641e2ee&tenantId=7831e6d9-dc6c-4cd1-9ec6-1dc2b4133195"
              target="_blank"><b>CCRP Analyzer Hyper Care Channel</b></a>
            </div>
          </li>
          <li>
            <a href="https://app.powerbi.com/groups/998fe880-3b6f-4013-9cf8-8ce9902ccbe4/reports/c7812aeb-5dbc-4267-b0e9-54769c1f9edb/ReportSection?ctid=7831e6d9-dc6c-4cd1-9ec6-1dc2b4133195"
              target="_blank" title="{{'rep_powerbi_tootlip' | translate}}">
              <strong>{{'rep_powerbi_lb' | translate}}</strong>
            </a>
          </li>
        </div>
        <div *ngIf="checkAccessPermission('321')">
          <li>
            <a [routerLink]="['weeklyreport']" title="">
              <strong>{{'Weekly Report'|translate}}</strong>
            </a>
          </li>
        </div>
      </ul>
    </div>
  </div>
</div>
