<div>
    <h2 class="widgetH2">{{ "FailureModeSearch_SelectInfo" | translate }}</h2>

    <p-tree [value]="files5" scrollHeight="290px" selectionMode="single" [(selection)]="selectedNode"
        filterPlaceholder="Search by Name (Type at least 3 character)" (onNodeExpand)="nodeSelect($event)" [loading]="loading">
    </p-tree>

    <div class="d-grid gap-2 d-md-flex justify-content-md-end">
        <button (click)="selectAndClose()" [disabled]="!selectedNode"
            [ngClass]="selectedNode ? ' widgetButtonLargeSelectClose red_button' : ' widgetButtonLargeSelectClose'">
            {{ "select_ru_popup_btn_select" | translate }}</button>
    </div>
</div>
