<!-- <div class="modal-header">
    <h5></h5>
</div> -->
<div class="action-dialog-pop">
    <div class="modal-body">
        <div style="margin-bottom: 10px; margin-top: 10px;">
            <label for="entry-subject" class="font-weight-bold">{{'al_modme_subject_lbl' | translate}}</label>
            <input class="form-control" [(ngModel)]="manualEntryObj.subject" type="text" id="entry-subject"
                placeholder="{{'al_modme_subject_ph' | translate}}" required>
        </div>
        <div class="form-group" style="margin-bottom: 10px;">
            <label for="entry-body" class="font-weight-bold">Body</label>
            <textarea class="form-control" [(ngModel)]="manualEntryObj.body" id="entry-body" rows="6"
                required></textarea>
        </div>
        <form id="fileupload">
            <!-- <div class="row fileupload-buttonbar">
            <div class="col-lg-12">
                
                <span class="btn btn-sm btn-success fileinput-button">
                    <i class="file_c"></i>
                    <span>+Add files</span>
                    <input type="file" name="files[]" multiple [(ngModel)]="manualEntryObj.files">
                    <input type="reset" value="Remove">
                </span>
            </div>
            
            <div class="col-lg-5 fade">
                
                <div class="progress progress-striped active">
                    <div class="progress-bar progress-bar-success"></div>
                </div>
                
                <div class="progress-extended">&nbsp;</div>
            </div>
        </div> -->
            <!-- The table listing the files available for upload/download -->
            <div class="col-md-10 col-sm-10 selectfile">
                <p-fileUpload #createUploaderManualEntry name="files[]" chooseLabel="Select Files"
                    [showCancelButton]="true" chooseIcon="null" removeStyleClass="p-overlaypanel1" [multiple]="true"
                    chooseStyleClass="p-overlaypanel" [maxFileSize]="30000000" [auto]="true" class=""
                    [customUpload]="true" (uploadHandler)="myUploader(createUploaderManualEntry,$event)">
                    <ng-template let-file let-index pTemplate="file">
                        <ul class="p-upload-files p-reset">
                            <li class="p-file row">
                                <div class="col-md-10 col-sm-10" [pTooltip]="file?.name" [escape]="false"
                                    tooltipPosition="top" tooltipStyleClass="toolTipClass">
                                    <span class="p-filename">{{file?.name}}</span>
                                </div>
                                <div class="col-md-2 col-sm-2" [pTooltip]="removeText" [escape]="false"
                                    tooltipPosition="top" tooltipStyleClass="toolTipClass">
                                    <button type="button" class="widgetButtoSmall attachButton"
                                        (click)="removeFile(file ,createUploaderManualEntry)"> X </button>
                                </div>
                            </li>
                        </ul>
                    </ng-template>
                </p-fileUpload>
            </div>
            <table class="table table-striped files ">
                <tr>

                    <td class="col-sm-4">
                        <div class="progress progress-striped active fade">
                            <div class="progress-bar progress-bar-success" data-ng-style="{width: num + '%'}"></div>
                        </div>
                    </td>
                    <td class="col-sm-1">
                        <!-- <button type="button" class="btn btn-xs btn-warning cancel" >
                                    <i class="glyphicon glyphicon-ban-circle"></i>
                                    <span>c</span>
                                </button> -->

                        <!-- <button type="button" class="btn btn-xs btn-danger destroy">
                                    <i class="glyphicon glyphicon-trash"></i>
                                    <span>cancel</span>
                                </button> -->
                    </td>
                </tr>
            </table>


        </form>
        <!-- <pre>{{manualEntryObj | json}}</pre> -->
        <div class="modal-footer">
            <button class="btn widgetButtoSmall" (click)="exit()">{{ "al_modme_cancel_btn" | translate }}</button>
            <button class="btn btn-primary widgetButtonSmall"
                [disabled]="!manualEntryObj.subject || !manualEntryObj.body" (click)="saveEntry(manualEntryObj)">{{
                "al_modme_save_btn" | translate }}</button>
        </div>
    </div>

</div>