<div class="globalSearchMainDiv">
<nav class="navbar navbar-default px-2 mt-3" style="background-color: #ffffff;" role="navigation">
    <!-- <div class="collapse navbar-collapse" id="bs-example-navbar-collapse-1"> -->
      
      <div class="form-group" style="display:inline;">
              <div class="input-group">
                  <input [(ngModel)]="searchText"   (keyup.enter)="search()" class="form-control inputClass" name="search" placeholder="{{'gsearch_search_ph'|translate}}" autocomplete="off" autofocus="autofocus" type="text" [disabled]="isSearching">
                  <span class="input-group-btn">
                      <button (click)="search()" class="btn btn-default search-btn qwqw" type="submit" [disabled]="isSearching"><span class="hitachiicon-magnifying_glass"></span></button>
                  </span>
              </div>
          </div>

  <!-- </div> -->
</nav>

<div class="well well-lg" *ngIf="noResults">
  {{'noresult_globalsearch' | translate}}
</div>
<div infiniteScroll (scrolled)="nextPage()" [infiniteScrollDisabled]="isLoadingMoreData" [infiniteScrollDistance]="1">
  <div *ngFor="let item of viewModel.results">
    <app-globalsearch-details [item] = "item" ></app-globalsearch-details>
  </div>
  <br/>
  <div *ngIf="isLoadingMoreData" class="text-center"><i class="fa fa-circle-o-notch fa-spin"></i>&nbsp;Loading more data...</div>
</div>
</div>
