import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

export interface WeeklyReportViewCount {
  weeklyReportList: any,
  approvedCount: number,
  totalCount: number,
  pendingCount: number
}


@Injectable({
  providedIn: 'root'
})
export class WeeklyreportserviceService {

  constructor(private http: HttpClient) { }

  getReportCount(selected: any) {
    return this.http.post(`${environment.azure.baseUrl}api/v1/weeklyReport/getCount`,selected);
  }

  exportToExcel = function (selected) {
   return this.http.post(`${environment.azure.baseUrl}api/v1/weeklyReport/exportToExcel`,selected,{
      responseType: 'arraybuffer'
    });
  }

  autoConfirmedCCRP = function (selected) {
   return this.http.post(`${environment.azure.baseUrl}api/v1/weeklyReport/autoConfirmedCCRPExport`,selected,{
      responseType: 'arraybuffer'
    });
  }

}
