import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'status'
})
export class StatusPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    if(value === 1){
      return 'Clearing';
    }
    else if(value === 2){
      return 'Cleared';
    }
    else if(value ===3){
      return 'ClearingFailed';
    }
    else if(value ===4){
      return 'ValidationFailed';
    }
    else if(value ===5){
      return 'Other';
    }
    else if(value ===6){
      return 'ABBLocation';
    }
    else if(value === 7){
      return 'Error';
    }
    return null;
  }

}