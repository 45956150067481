<div class="row surveypage">
  <div class="row">
    <div class="col-1"></div>
    <div class="col-10">
      <form [formGroup]="surveyForm">
        <p-card>
          <div class="row">
            <h1>{{'Survey_PageTitle' | translate}}</h1>
            <p>{{ 'Survey_HeaderDescription'| translate}}</p>
          </div>
          <div class="row mt-2">
            <span style="font-weight: bold;">How satisfied were you with the manner in which we handled your
              issue?</span>
            <small class="error" *ngIf="surveyForm.get('satisfactionRating').errors?.['required']">
                  {{'SurveyQuestion_NotValid' | translate}}
            </small>
          </div>
          <div class="row mt-2">
            <input type="hidden" formControlName="question1"/>
            <section class="btn-group" id="btngroup1">
              <input type="radio" class="btn-check" name="satisfactionRating" value="1"
                formControlName="satisfactionRating" id="satisfactionRating1">
              <label class="btn btn-outline-secondary" for="satisfactionRating1">
                1
              </label>

              <input type="radio" class="btn-check ms-1" name="satisfactionRating" value="2"
                formControlName="satisfactionRating" id="satisfactionRating2">
              <label class="btn btn-outline-secondary ms-1" for="satisfactionRating2">
                2
              </label>

              <input type="radio" class="btn-check ms-1" name="satisfactionRating" value="3"
                formControlName="satisfactionRating" id="satisfactionRating3">
              <label class="btn btn-outline-secondary ms-1" for="satisfactionRating3">
                3
              </label>

              <input type="radio" class="btn-check ms-1" name="satisfactionRating" value="4"
                formControlName="satisfactionRating" id="satisfactionRating4">
              <label class="btn btn-outline-secondary ms-1" for="satisfactionRating4">
                4
              </label>

              <input type="radio" class="btn-check ms-1" name="satisfactionRating" value="5"
                formControlName="satisfactionRating" id="satisfactionRating5">
              <label class="btn btn-outline-secondary ms-1" for="satisfactionRating5">
                5
              </label>
            </section>
            <div class="row">
              <div class="col-6">{{'SurveyQuestion_Satisfaction_NotSatisfied' | translate}}</div>
              <div class="col-6 text-end">{{'SurveyQuestion_Satisfaction_Satisfied' | translate}}</div>
            </div>
          </div>
          <div class="row mt-3">
            <span style="font-weight: bold;">How much effort did you have to make in order to have your issue
              resolved?</span>
          </div>
          <div class="row mt-2">
            <input type="hidden" formControlName="question2"/>
            <section class="btn-group" id="btngroup2">
              <input type="radio" class="btn-check" name="effortRating" value="1" formControlName="effortRating"
                id="effortRating1">
              <label class="btn btn-outline-secondary" for="effortRating1">
                1
              </label>

              <input type="radio" class="btn-check ms-1" name="effortRating" value="2" formControlName="effortRating"
                id="effortRating2">
              <label class="btn btn-outline-secondary ms-1" for="effortRating2">
                2
              </label>

              <input type="radio" class="btn-check ms-1" name="effortRating" value="3" formControlName="effortRating"
                id="effortRating3">
              <label class="btn btn-outline-secondary ms-1" for="effortRating3">
                3
              </label>

              <input type="radio" class="btn-check ms-1" name="effortRating" value="4" formControlName="effortRating"
                id="effortRating4">
              <label class="btn btn-outline-secondary ms-1" for="effortRating4">
                4
              </label>

              <input type="radio" class="btn-check ms-1" name="effortRating" value="5" formControlName="effortRating"
                id="effortRating5">
              <label class="btn btn-outline-secondary ms-1" for="effortRating5">
                5
              </label>
            </section>
            <div class="row">
              <div class="col-6">{{'SurveyQuestion_Effort_IntenseEffort' | translate}}</div>
              <div class="col-6 text-end">{{'SurveyQuestion_Effort_NoEffort' | translate}}</div>
            </div>
          </div>
          <div class="row mt-3">
            <span style="font-weight: bold;">Please feel free to leave a comment and/or make suggestions as to how we
              can
              improve your
              experience(optional). Please avoid using personal data (individual names,phone#,etc.) in free text
              fields.</span>
            <div class="row mt-2">
              <input type="hidden" formControlName="question3"/>
              <textarea formControlName="feedbackComments" class="form-control ms-2" cols="20"
                fieldlabel="CustomerFeedback" rows="2" placement="top" tooltipStyleClass="toolTipClass">
        </textarea>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-10"></div>
            <div class="col-2">
              <button type="button" [disabled]="surveyForm.invalid"
              [ngClass]=" surveyForm.invalid ? 'widgetButtoSmall grayButton' : 'widgetButtoSmall'"
              style="float: right !important;" (click)="submitSurvey()">{{'Survey_SubmitButton'|translate}}</button>
            </div>
          </div>
        </p-card>
      </form>
    </div>
    <div class="col-1"></div>
  </div>
</div>
