export class StaticvalueConstants {
    public static  PROCESS_STEP_ISSUE_DETECTED = "Sales/Tender Management";
    public static ARTICLE_NUMBER = "62D872";
    public static ITEM_DESIGNATION = "Test Designation";
    public static DELIVERY_DATE = "2023-04-18T23:59:59";
    public static DOCUMENT_ID = "Test-doc1, Test-Doc2";
    public static Q_NUMBER_ISSUE = "73Q37";
    public static PRODUCT_ALREADY_DELIVERED = 'Yes';
    public static NO_OF_UNITS_SHIPPED = '3';
    public static PARTIES_REFERENCE = [
        {
            "geid": "9D10244107"
        },
        {
            "geid": "9D10900705"
        },
    ];
    public static RESOLUTION_COST = 30;
    public static IMPACT_ON_INSTALL_BASE = '5% - 10%';
    public static TOTAL_INSTALLED_BASE = '1 - 100';
    public static Q_NUMBER_RESOLVING_UNIT = "73Q37";
    public static RCA_CORRECTIVE_ACTION = "Test Action";
    public static SPECIFICATION_NAME = "Test Specification";
    public static PRODUCT_FAIL = "Product Fail";
    public static WHAT_CONTAINMENT_ACTION_WHERE_TAKEN = "Containment Action";
    public static CHIP_DATA = 'Lessons Learned';
    public static ACTUAL_RU_COST = 50;
    public static COPQ_CATEGORY = "TEST COPQ";
    public static CLOSE_STATUS = false;
    public static CUSTOMER_COMMENT = "Test closing comment";
    public static CUSTOMER_CONTACT_STATUS = "active";
    public static CUSTOMER_RESPONSE = "active";
    public static CLASS_VALUE = "Unconfirmed";
    public static ExceptionValidationRequiredBUCodes=["PGHV","PGTR"];
}