<form [formGroup]="translationSettingsForm">
  <div class="issue-footer translation-settings-section pt-2">
    <div class="row">
      <div class="col-sm-3">
        <label class="label-align-right  mt-1 translation-settings-label" style="font-size: 14px;">
          Page size
        </label>
      </div>
      <div class="col-sm-9 translation-settings-value">
        <select class="form-control form-control-sm" formControlName="pageSize" (change)="updatePageSize(pageSize)">
          <option value="10">10</option>
          <option value="15">15</option>
          <option value="20">20</option>
          <option value="25">25</option>
          <option value="50">50</option>
        </select>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-sm-4"></div>
      <div class="col-sm-8 text-end mt-1">
        <button class="widgetButtonMedium" type="button" (click)="cancel()">{{'dashb_settings_cancel_btn' | translate}}</button>
        <button class="widgetButtonMedium ms-1" [ngClass]="this.translationSettingsForm.dirty? '': 'grayButton'" type="button" (click)="save()">{{'dashb_settings_ok_btn' |
          translate}}</button>
      </div>
    </div>
  </div>
</form>
