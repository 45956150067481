import { HttpClient, HttpParams } from "@angular/common/http";
import { TranslateLoader } from "@ngx-translate/core";
import { Observable, map } from "rxjs";
import { environment } from "src/environments/environment";

export class TranslationHttpLoader implements TranslateLoader{

    constructor(private httpClient: HttpClient){
    }

    public getTranslation(lang : string): Observable<any> {

    return this.httpClient.get<any>(`${environment.azure.baseUrl}api/external/translations/${lang}/resourcesasdictionary`)
      .pipe(map((res:any) =>{
        return res;
      }))
    }
}
