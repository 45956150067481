import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filesize'
})
export class FilesizePipe implements PipeTransform {

  transform(value: number): any {
    let size = value/1024;
    let toKB = size.toFixed(2) + ' KB';
    return toKB;
  }

}
