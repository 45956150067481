import { Component, Input, OnInit } from '@angular/core';
import { MessageService, PrimeNGConfig } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { AdminCustomerContactsService } from 'src/app/services/admin-customer-contacts.service';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-deactivate-contact-popup',
  templateUrl: './deactivate-contact-popup.component.html',
  styleUrls: ['./deactivate-contact-popup.component.css']
})
export class DeactivateContactPopupComponent implements OnInit {

  @Input() sendSearchObj: any;
  receivedCustomerId: any;
  deletedContact: any;

  constructor(
    private primengConfig: PrimeNGConfig,
    private dialogService: DialogService,
    private ref: DynamicDialogRef,
    private messageService: MessageService,
    private admService: AdminCustomerContactsService,
    private sharedService: SharedService
  ) { }

  ngOnInit(): void {

    this.receivedCustomerId = this.sharedService.getDeactivatedObject();
  }

  deleteConfirmation() {
    this.admService.deleteContact(this.receivedCustomerId).subscribe((response) => {
      if (response == null) {
        this.deletedContact = response;
        this.ref.close(this.receivedCustomerId);
      }
    })
  };

  cancel() {
    this.ref.close();
  };
}

