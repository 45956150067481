<div class="pgrcaCatMainDiv">
  <form [formGroup]="PGRCACategory">
    <div class="pgrcaContent">
      <div class="pgrcaSubDiv">
        <div class="pgrcaHeader resp-pgrcaCat">
        <!-- <div class="col-md-12 mt-2">
          <div class="input-group">
            <label class="control-label col-sm-1 mx-4 mt-2">{{
              "admin_pg_categories_search_dv_lb" | translate
            }}</label>
            <div class="justify-content-center">
              <p-dropdown
                [options]="division"
                optionValue="key"
                placeholder="{{'rusearch_mod_div_ph'| translate}}"
                optionLabel="value"
                [filter]="true"
                filterBy="key"
                [showClear]="true"
                formControlName="business"
              ></p-dropdown>
            </div>
          </div>
          </div> -->
          <div class="row mt-2">
            <div class="col-sm-1 pgrcaLabel">
              <label class="label-align-right label-bold">{{'admin_item_search_dv_lb' | translate}}</label>
            </div>
            <div class="col-sm-10">
              <p-dropdown [options]="division" optionValue="key" formControlName="business" optionLabel="value"
                [filter]="true" [resetFilterOnHide]="true" filterBy="key" [showClear]="true" placeholder="{{'rusearch_mod_div_ph'| translate}}">
              </p-dropdown>
            </div>
            <div class="col-sm-1"></div>
          </div>

        <!-- <div class="col-md-12 mt-2">
          <div class="input-group">
            <label class="control-label col-sm-1 mx-4 mt-2">{{
              "admin_pg_categories_search_bu_lb" | translate
            }}</label>
            <div class="justify-content-center">
              <p-dropdown
                [options]="bu"
                optionValue="key"
                placeholder="{{'rusearch_mod_bu_ph'| translate}}"
                optionLabel="value"
                [filter]="true"
                filterBy="value"
                [showClear]="true"
                formControlName="bu"
              ></p-dropdown>
            </div>
          </div>
        </div> -->
        <div class="row mt-2">
          <div class="col-sm-1 pgrcaLabel">
            <label class="label-align-right label-bold">{{'admin_item_search_bu_lb' | translate}}</label>
          </div>
          <div class="col-sm-10 bu-overlay">
            <p-dropdown [options]="bu" optionValue="key" formControlName="bu" optionLabel="value" [resetFilterOnHide]="true" [filter]="true"
              filterBy="value" [showClear]="true" placeholder="{{'rusearch_mod_bu_ph'| translate}}" >
            </p-dropdown>
          </div>
          <div class="col-sm-1"></div>
        </div>
        <!-- <div class="col-md-12 mt-2 mb-2">
          <div class="input-group">
            <label class="control-label col-sm-1 mx-4 mt-2">{{
              "admin_pg_categories_search_pg_lb" | translate
            }}</label>
            <div class="justify-content-center">
              <p-dropdown
                [options]="pg"
                optionValue="key"
                placeholder="{{'rusearch_mod_pg_ph'| translate}}"
                optionLabel="value"
                [filter]="true"
                filterBy="key"
                [showClear]="true"
                formControlName="pg"
              ></p-dropdown>
            </div>
            <div class="col-sm-1">
              <button class="iconHitachi bg-transparent mx-5" (click)="showPGPopUp(this.pgrcaCatProcessOwner,this.pgrcaCatEmployeeInfo)">
                <i class="hitachiicon-users bg-transparent"></i>
              </button>
            </div>
          </div>
        </div> -->
        <div class="row mt-2 mb-2">
          <div class="col-sm-1 pgrcaLabel">
            <label class="label-align-right label-bold">{{'admin_item_search_pg_lb' | translate}}</label>
          </div>
          <div class="col-sm-10">
            <p-dropdown [options]="pg" optionValue="key" formControlName="pg" optionLabel="value" [resetFilterOnHide]="true" [filter]="true"
              filterBy="value" [showClear]="true" placeholder="{{'rusearch_mod_pg_ph'| translate}}" >
            </p-dropdown>
          </div>
          <div class="col-sm-1">
            <button class="btn btn-default btn-align-resp iconHitachi bg-transparent mx-2" (click)="showPGPopUp(this.pgrcaCatProcessOwner,this.pgrcaCatEmployeeInfo)"
            [ngClass]="{'disabled':this.PGRCACategory.get('pg').value === null || this.PGRCACategory.get('pg').value === undefined || this.PGRCACategory.get('pg').value === ''}"
            [disabled]="this.PGRCACategory.get('pg').value === null || this.PGRCACategory.get('pg').value === undefined || this.PGRCACategory.get('pg').value === ''">
              <i class="hitachiicon-users bg-transparent"></i>
            </button>
          </div>
        </div>
      </div>
        <div class="card mt-3">
          <p-tree
            [value]="files1"
            selectionMode="single"
            [(selection)]="selectedNode"
            [pTooltip]="desiredTooltip"
            emptyMessage="{{'admin_pg_categories_search_msg' | translate}}"
            (onNodeExpand)="nodeSelect($event)"
            >
            <!--
              (mouseover)="nodeTooltipOver($event)"
              (mouseout)="nodeTooltipOut($event)"
              (onNodeSelect)="nodeSelectTooltip($event)"
              (mouseover)="nodeTooltipOver($event)"
              (mouseout)="nodeTooltipOut($event)"
              (onNodeUnselect)="nodeUnselect($event)"
            -->
            <ng-template let-node let-index pTemplate="default">
              <span>
                <span class="nodeData" [ngClass]="!node.isActive ? 'disabledNode': 'enableNode'" pTooltip="{{ desiredTooltip }}">{{node.label}}</span>
                <a *ngIf="node.isActive==true && allowManage" class="btn nodeButtons" (click)="addChildPopUp(node,index)"
                  [pTooltip]="addTooltipInfo" [escape]="false" tooltipPosition="top" tooltipStyleClass="toolTipClass"><i class="fa fa-plus iconStyle" ></i></a>
                <a *ngIf="node.id>0 && allowManage" class="btn nodeButtons" (click)="editChildPopUp(index)"
                  [pTooltip]="editTooltipInfo" [escape]="false" tooltipPosition="top" tooltipStyleClass="toolTipClass"><i class="fa fa-edit iconStyle"></i></a>
                <a *ngIf="node.id>0 && node.isActive==true && allowManage" class="btn nodeButtons" (click)="deactivatePgrcaPopUp(index)"
                  [pTooltip]="deactivateTooltipInfo" [escape]="false" tooltipPosition="top" tooltipStyleClass="toolTipClass"><i class="fa fa-trash-o iconStyle"></i></a>
                  <!-- <p-toast></p-toast> -->
                  <!--
                    pTooltip="{{'admin_item_add_tp' | translate}}" tooltipPosition="top"  [escape]="false"
                    pTooltip="{{'admin_item_edit_tp' | translate}}" tooltipPosition="top"   [escape]="false"
                    pTooltip="{{'admin_item_deactivate_tp' | translate}}" tooltipPosition="top"  [escape]="false"
                  -->
                </span>
              </ng-template>
          </p-tree>
        </div>
      </div>
    </div>
  </form>
</div>
