<div class="failure-mode-pop">
  <form [formGroup]="contactDetailsForm">
    <h2 class="widgetH2 mb-1 mt-1">{{ "FailureModeSearch_SelectInfo" | translate }}</h2>
    <input type="text" (keyup)="filterFailureMode($event)" class="form-control" placeholder="Search by Name (Type at least 3 character)">
    <p-tree [value]="files1" scrollHeight="290px" selectionMode="single" [(selection)]="selectedNode"
      (onNodeExpand)="nodeSelect($event)" [loading]="loading">
    </p-tree>

    <div *ngIf="selectedNode?.requiresDescription" class="mt-2">
      <div>
        <label for="others">{{ "FailureModeSearch_ProvideInfoOthers" | translate }}</label>
        <input class="form-control customtextfield-s" type="text" id="others" formControlName="others">
      </div><br>
    </div><br>

    <div class="modal-footer my-2" *ngIf="this.isButtonVisible" >
      <button type="button" [disabled]="!disableSelectClose()" class="widgetButtonLargeSelectClose"
        [ngClass]="disableSelectClose() ? '' : 'grayButton'"
        (click)="selectAndClose()">{{ "select_ru_popup_btn_select" | translate }}</button>
    </div>
  </form>
 </div>
