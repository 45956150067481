<div class="bg-white1 admin-systemUser-MainClass">
  <div class="row">
    <div class="col-sm-1 mt-1">
      <button type="button" class="widgetButtoSmall ms-2 addButtonSystemUser" (click)="addSystemRoleUser()">
        {{'admin_po_addperson_btn'|translate}}
      </button>
    </div>

    <div class="col-sm-9">
      <div class="row">
        <div class="col-sm-2"><label class="label-align-right label-bold mt-2">{{'system_user_systemrole_lbl' |
            translate}}</label></div>
        <div class="col-sm-9 inputDivSystemUser">
          <span class="btn-group btnMainClassSystemUsers" role="search">
            <button type="button" class="btn btn-default systemUserBtnClass" (click)="isGlobalProcessOwnerClicked()">
              <span class="fa"
                [ngClass]="{'fa-square-o ':!criteria.isGlobalProcessOwner, 'fa-check-square-o ':criteria.isGlobalProcessOwner }"></span>
              {{'system_user_sr_globalpo_lbl'|translate}}
            </button>
            <button type="button" class="btn btn-default systemUserBtnClass" (click)="isSupportDeskClicked()">
              <span class="fa"
                [ngClass]="{'fa-square-o ':!criteria.isSupportDeskMember, 'fa-check-square-o ':criteria.isSupportDeskMember }"></span>
              {{'system_user_sr_supportdesk_lbl'|translate}}

            </button>
            <button type="button" class="btn btn-default systemUserBtnClass" (click)="isResourceEditorClicked()">
              <span class="fa"
                [ngClass]="{'fa-square-o ':!criteria.isResourceEditor, 'fa-check-square-o ':criteria.isResourceEditor }"></span>
              {{'system_user_sr_langeditor_lbl'|translate}}
            </button>
            <button type="button" class="btn btn-default systemUserBtnClass" (click)="isManagementRoleClicked()">
              <span class="fa"
                [ngClass]="{'fa-square-o ':!criteria.isManagementRole, 'fa-check-square-o ':criteria.isManagementRole }"></span>
              {{'system_user_sr_management_role_lbl'|translate}}
            </button>
          </span>
        </div>
        <div class="col-sm-1"></div>
      </div>



    </div>
    <div class="col-sm-2"></div>
     <!-- <div class="col-sm-2 sortfielddata">
      <span class="input-group dashboard-sort-control button-set" style="display: flex; text-align: left;">
        <input type="text" class="form-control sortInputSystemUser" value="{{'system_user_sort_name' | translate}}" readonly />
        <span class="input-group-btn">
          <button *ngIf="order_mode=='asc'" (click)="setOrderMode('desc')" class="input-group-addon button-set">
            <i class="hitachiicon-sort_up"></i>
          </button>
          <button *ngIf="order_mode=='desc'" (click)="setOrderMode('asc')" class="input-group-addon button-set">
            <i class="hitachiicon-sort_down"></i>
          </button>
        </span>

      </span> -->
      <!-- <span class="input-group">
      <input type="text" class="form-control" value="{{'system_user_sort_name' | translate}}" readonly />
      <span class="input-group-btn">
        <button *ngIf="order_mode=='asc'" [disabled]="isSearching" (click)="setOrderMode('desc')"
          class="btn btn-default button-set icon-sort-btn" title="{{'admin_cc_sort_asc' | translate}}">
          <i class="hitachiicon-sort_up"></i>
        </button>
        <button *ngIf="order_mode=='desc'" [disabled]="isSearching" (click)="setOrderMode('asc')"
          class="btn btn-default button-set icon-sort-btn" title="{{'admin_cc_sort_desc' | translate}}">
          <i class="hitachiicon-sort_down"></i>
        </button>
      </span>
    </span> -->
    <!-- </div>  -->
  </div>

  <div class="row mt-1 userNameDiv">
    <div class="col-sm-1">
    </div>
    <div class="col-sm-9">
      <div class="row">
        <div class="col-sm-2"><label class="label-align-right label-bold mt-2">{{'system_user_username_lbl' |
            translate}}</label></div>
        <div class="col-sm-10 systemuser-serach">
          <span class="input-group" role="search">
            <input [(ngModel)]="search_keyword" (ngModelChange)="onSearchValueChange()" class="form-control inputDivSystemUser"
              placeholder="{{'system_user_username_ph'|translate}}" autocomplete="off" type="text">
            <span class="input-group-addon button-set">
              <span class="hitachiicon-magnifying_glass"></span>
            </span>
          </span>
        </div>
      </div>


    </div>
    <!-- <div class="col-sm-2"></div> -->
    <div class="col-sm-2 sortfielddata">
      <span class="input-group dashboard-sort-control button-set" style="display: flex; text-align: left;">
        <input type="text" class="form-control sortInputSystemUser" value="{{'system_user_sort_name' | translate}}" readonly />
        <span class="input-group-btn">
          <button *ngIf="order_mode=='asc'" (click)="setOrderMode('desc')" class="input-group-addon button-set">
            <i class="hitachiicon-sort_up"></i>
          </button>
          <button *ngIf="order_mode=='desc'" (click)="setOrderMode('asc')" class="input-group-addon button-set">
            <i class="hitachiicon-sort_down"></i>
          </button>
        </span>

      </span>
    </div>
  </div>

  <div class="row mt-4" *ngFor="let su of [] | pawel:(systemUsersFiltered.length/3)+1">
    <div class="col-sm-12 col-md-4" *ngFor="let i of systemUsersFiltered">
      <div>
        <app-systemuser-itemdetails (addSystemRoleOfUser)="addSystemRoleUser($event)"
          (deleteSystemRoleOfUser)="deleteSystemRoleUser($event)" [itemScope]="i"></app-systemuser-itemdetails>
      </div>
    </div>
  </div>
</div>
