  <div class="row" *ngFor="let i of itemUnits">
    <app-po-itemunit [itemScope]="i"></app-po-itemunit>
  </div>
  <div class="row" *ngFor="let i of itemLocations">
    <app-po-itemlocation [itemScope]="i"></app-po-itemlocation>
  </div>
  <div class="row text-center" *ngIf="items.length > 0" >
    <span>
        <ul class="pagination">
            <li class="{{hasPrevious()?'':'disabled'}}" (click)="previousClicked()"><a >&laquo;</a></li>

            <li *ngIf="showFirstPage()" (click)="gotoPage(firstPage)"><a >{{firstPage}}</a></li>
            <li *ngIf="showFirstPageSeparator()"><span>...</span></li>

            <li *ngIf="hasPrevious()" (click)="gotoPage(previousPage())"><a >{{previousPage()}}</a></li>
            <!-- <li *ngIf="!hasPrevious()"><span>&nbsp;</span></li> -->
            <li class="active"><a >{{currentPage}}</a></li>
            <li *ngIf="hasNext()" (click)="gotoPage(nextPage())"><a >{{nextPage()}}</a></li>
            <!-- <li *ngIf="!hasNext()"><span>&nbsp;</span></li> -->

            <li *ngIf="showLastPageSeparator()"><span>...</span></li>
            <li *ngIf="showLastPage()" (click)="gotoPage(pagesCount)"><a >{{pagesCount}}</a></li>

            <li class="{{hasNext()?'':'disabled'}}"><a  (click)="nextClicked()">&raquo;</a></li>
        </ul>
      </span>
</div>
<div class="row" *ngIf='items.length == 0'>
    <p class="dashboard-p dashboard-info-msg" style="text-align: center;">
        <span class="">{{'admin_po_no_items' | translate}}</span>
    </p>
</div>
