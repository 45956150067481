<div class="weeklyreport bg-white1">
  <div class="row my-3">
    <h3 class="report-title ms-3 mt-3"> Weekly Report</h3>
  </div>
  <div class="row mb-5 weekly-report-firefox-align-border">
    <form class="form-horizontal" role="form" #reportForm="ngForm">

      <div class="row">
        <div class="col-sm-2">
          <label class="label-align-right mt-1">Date Type</label>
        </div>
        <div class="col-sm-6 pe-0 date-type-mobile-align date-type-tab-align">
          <select class="form-select form-select-sm" ngModel [(ngModel)]="selected.dateType" name="dateType">
            <option value="SubmitDate">{{'rpt_dt_kind_submit'|translate}}</option>
            <option value="ApprovedDate">{{'rpt_dt_kind_verify'|translate}}</option>
          </select>
        </div>
      </div>
      <div class="row my-2">
        <div class="col-sm-2">
          <label class="label-align-right  mt-1">Date Range</label>
        </div>
        <div class="col-sm-5 tab-align-datefield">
          <div class="row">
            <div class="col-sm-6 addingPaddingMobile">
              <div class="datefield-report2 datefield-align-firefox datefield-align-firefox2">
                <input type="text"
                       class="form-control"
                       [bsConfig]="{containerClass: 'theme-default'}"
                       placement="top"
                       #dp="bsDatepicker"
                       bsDatepicker [(bsValue)]="selected.startDate">
              </div>
            </div>
            <!-- <div class="col-sm-1"></div> -->
            <div class="col-sm-6 pe-0 date-align-mobile mobileMargin">
              <div class="datefield-report2 ms-5 weekly-report-date-field date-field-small-screen">
                <input type="text"
                       class="form-control"
                       [bsConfig]="{containerClass: 'theme-default'}"
                       placement="top"
                       #dp="bsDatepicker"
                       bsDatepicker [(bsValue)]="selected.endDate">
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-2">
          <label class="label-align-right  mt-1 enterCCRPlabel"> Class
            <span class="asteriskRequired"> </span>
          </label>
        </div>
        <div class="col-sm-5 tab-align-high-imp-btn">
          <div class="row" style="display: flex;">
            <div class="col-sm-4 align-landscape-btn mobileMargin">
              <button type="button" class="btn classbtnwidth class-btn-align" (click)="hasHighImpactAClicked()">
                <span class="fa"
                  [ngClass]="{'fa-check-square-o ':!selected.hasHighImpactClassA, 'fa-square-o ':selected.hasHighImpactClassA }"></span>
                {{'High Impact Class A'|translate}}
              </button>
            </div>
            <div class="col-sm-4 align-landscape-btn">
              <button type="button"  class="btn classbtnwidth class-btn-align mobileMargin" (click)="hasHighImpactBClicked()">
                <span class="fa"
                  [ngClass]="{'fa-check-square-o ':!selected.hasHighImpactClassB, 'fa-square-o ':selected.hasHighImpactClassB }"></span>
                {{'High Impact Class B'|translate}}
              </button>
            </div>
            <div class="col-sm-4 align-landscape-btn btn-padding btn-high-impact-i pe-0 mobileMargin">
              <button type="button" class="btn classbtnwidth class-btn-align btn-high-i-small" (click)="hasHighImpactIClicked()">
                <span class="fa"
                  [ngClass]="{'fa-check-square-o ': !selected.hasHighImpactClassI,'fa-square-o ':selected.hasHighImpactClassI }"></span>
                {{'High Impact Class I'|translate}}
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-1" *ngIf="incorrectDateRange()">
        <div class="col-sm-2">
        </div>
        <div class="col-sm-4">
          <p class="error mb-0">{{'rpt_info_start_date_lower'|translate}}</p>
        </div>
      </div>

      <div class="row my-4">
        <div class="col-sm-6">
          <div *ngIf="showCount">
            <label class="label-bold ms-2">Total Approved Issues : {{reportData.totalCount}}</label>
          </div>
        </div>
        <div class="col-sm-1"></div>
        <div class="col-sm-12 float-end text-end">
          <button class="widgetButtonLarge" (click)="clear()">{{'rpt_nav_clear'|translate}}</button>
          <button class="widgetButtonLarge ms-1"
            [disabled]="( !reportForm.valid || ( selected.hasHighImpactClassA && selected.hasHighImpactClassB && selected.hasHighImpactClassI))"
            [disabled]="(selected.hasHighImpactClassA && selected.hasHighImpactClassB && selected.hasHighImpactClassI)"
            (click)="exportToExcel()">{{'rpt_nav_export'|translate}}</button>
          <button class="widgetButtonLarge ms-1"
            [disabled]="(!reportForm.valid || ( selected.hasHighImpactClassA && selected.hasHighImpactClassB && selected.hasHighImpactClassI))"
            [disabled]="(selected.hasHighImpactClassA && selected.hasHighImpactClassB && selected.hasHighImpactClassI)"
            (click)="autoConfirmedCCRP()">{{'rpt_nav_auto_confirmed_export'|translate}}</button>
          <button class="widgetButtonLarge ms-1"
            [disabled]="(!reportForm.valid || ( selected.hasHighImpactClassA && selected.hasHighImpactClassB && selected.hasHighImpactClassI))"
            [disabled]="( selected.hasHighImpactClassA && selected.hasHighImpactClassB && selected.hasHighImpactClassI)"
            (click)="viewCount()">{{'View Count'}}</button>
        </div>
      </div>
    </form>

  </div>
</div>
