import { trigger, state, style, transition, animate } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { NgxSpinnerService } from 'ngx-spinner';
import { PrimeNGConfig } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ActionDialogPopupComponent } from 'src/app/components/new-issue/action-dialog-popup/action-dialog-popup.component';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { EmailServiceTempService } from 'src/app/services/email-service-temp.service';
import { SessionStorageServiceService } from 'src/app/services/session-storage-service.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-actionlogentries-internal',
  templateUrl: './actionlogentries-internal.component.html',
  styleUrls: ['./actionlogentries-internal.component.css'],
  providers: [DialogService],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class ActionlogentriesInternalComponent implements OnInit {

  inputComplaintNo:any;
  disableInput:boolean = false;
  actionLogForm!:FormGroup;
  actionLoginForm!: FormGroup;
  loginSuccess:boolean = false;
  errorMessage:boolean = false;
  logdata: any[] = [];
  logDataToShow: any[] = [];
  searchKeyword: any;
  openManualEntry = false;
  orderBy = 'email';
  orderMode = 'asc';
  bodyFlag = false;
  showAllBody = false;
  checkVisibility = true;
  selectedIndex: number;
  complaintNo: any;
  userGeid: any;

  logParams = [
    {
      "name": "System Action",
      "type": 1,
      "backColor": "#80c4e1",
      "count": 0,
      "selected": true
    },
    {
      "name": "User notification",
      "type": 8,
      "backColor": "#5cb85c;",
      "count": 0,
      "selected": true
    },
    {
      "name": "System notification",
      "type": 2,
      "backColor": "#093fb4",
      "count": 0,
      "selected": true
    },
    {
      "name": "Manual entry",
      "type": 4,
      "backColor": "red",
      "count": 0,
      "selected": true
    },
    {
      "name": "Action item",
      "type": 16,
      "backColor": "yellow",
      "count": 0,
      "selected": true
    }
  ];


  constructor(private fb:FormBuilder,
    private msalService: MsalService,
    private EmailServiceTempService: EmailServiceTempService,
    private primengConfig: PrimeNGConfig,
     private dialogService: DialogService,
     private ref: DynamicDialogRef,
    private route: ActivatedRoute,
     private sessionStorage: SessionStorageServiceService,
    private spinnerService: NgxSpinnerService,
    private breadcrumbService: BreadcrumbService) { }

  ngOnInit(): void {
    this.actionLogForm = this.fb.group({
      complaintNo: ['',Validators.required]
  })

  this.actionLoginForm = this.fb.group({
    username: ['',Validators.required],
    password: ['',Validators.required]
  })

  this.loginSuccess = environment.displayActionNotification;

  }

  searchActions(){
    this.disableInput = true;
    this.complaintNo = this.actionLogForm.controls['complaintNo'].value;
    this.logDataToShow = [];
      this.logdata = [];
     // this.complaintNo = this.InputCompalintNo;
      this.userGeid = this.sessionStorage.getData('userInfo').geid;

      this.EmailServiceTempService.getActionLogNotificationsDetails(this.complaintNo, this.userGeid).subscribe((resp) => {
        this.spinnerService.show();
        this.logdata = resp;
        this.logdata.forEach(l => {
          l.formattedDate = l.date; // needs to be formatted later
          l.searchableString = l.createdBy.email + l.subject + l.body + l.formattedDate;
        })
        this.logdata.forEach(i => {
          let logObj = this.logParams.find(j => i.type == j.type);
          i.typeLabel = logObj.name;
          i.backColor = logObj.backColor;
        });
        this.logDataToShow = this.logdata.slice();
        this.logParams.forEach(i => {
          i.count = this.logDataToShow.filter(j => i.type == j.type).length;
        });
        this.spinnerService.hide();
      })
  }

  login(){
    let usernamevalue = this.actionLoginForm.controls['username'].value;
    let passwordvalue = this.actionLoginForm.controls['password'].value;
    if(usernamevalue === 'ccrprewrite' && passwordvalue === 'ccrprewrite'){
      this.loginSuccess=true;
      this.errorMessage = false;
    }else{
      this.loginSuccess=false;
      this.errorMessage = true;
    }
  }

    //Code for magnifier/filtering
    onlogSelection(log: any) {
      this.logParams.forEach(i => {
        if (i.type == log.type) {
          i.selected = !log.selected;
        }
      });
      this.logDataToShow = [];
      this.logParams.forEach(l => {
        if (l.selected) {
          let logsToAdd = this.logdata.filter(z => z.type == l.type);
          this.logDataToShow = this.logDataToShow.concat(logsToAdd);
        }
      })
    }
    //Code for magnifier/filtering
    filterLogData(e) {
      this.searchKeyword = e;
      let searchKeywordRegExp = new RegExp(this.searchKeyword, 'i');
      this.logDataToShow = this.logdata.filter(s => {
        return s.searchableString.search(searchKeywordRegExp) > -1;
      })
    }

    showDropdown = false;
    // openDropdown() {
    //   this.showDropdown = !this.showDropdown;
    // }

    openManualEntryPopup(e) {
      this.openManualEntry = true;
      this.showDropdown = !this.showDropdown;
    }
    //Code for sorting data respective view.
    sortData() {
      if (this.orderBy.startsWith('-')) {
        let orderByParam = this.orderBy.substring(1);
        this.logDataToShow.sort((a, b) => {
          return (b[orderByParam]).toString().localeCompare(a[orderByParam]).toString();
        })
      } else {
        this.logDataToShow.sort((a, b) => {
          return (a[this.orderBy]).toString().localeCompare(b[this.orderBy]).toString();
        })
      }
    }
    //backup Code for sorting data respective view.
    // sortData2() {
    //   // this.logDataToShow.sort((a, b) => a.type - b.type);
    //   this.logDataToShow.sort((a, b) => {
    //     if(this.orderBy == 'createdBy') {
    //       return 0;
    //       // if(this.orderMode = 'asc') {
    //       //   return a[this.orderBy]['email'] - b[this.orderBy]['email'];
    //       // } else {
    //       //   return b[this.orderBy]['email'] - a[this.orderBy]['email'];
    //       // }
    //     } else {
    //       if(this.orderMode = 'asc') {
    //         return a[this.orderBy] - b[this.orderBy];
    //       } else {
    //         return b[this.orderBy] - a[this.orderBy];
    //       }
    //     }
    //   })
    // }

    setOrderMode(mode) {
      this.orderMode = mode;
      this.sortData();
    }

    //Code for manualentry dilog-popup
    openDropdown() {
      this.ref = this.dialogService.open(ActionDialogPopupComponent,
        {
          header: 'Add Manual Entry',
          width: '50%',
          position: 'top',
          keepInViewport: true,
          autoZIndex: true,
          styleClass: 'action-dialog-pop',
        });

      this.ref.onClose.subscribe((manualEntry: any) => {
        if (manualEntry) {
          this.spinnerService.show();
          this.EmailServiceTempService.addManualEntry(this.complaintNo, this.userGeid, manualEntry).subscribe((resp) => {
            this.ngOnInit();
            this.spinnerService.hide();
          });
          // let obj: any = {
          //   createdBy: {
          //     name: "Alok Nath Pandey",
          //     // email: this.msalService.instance.getActiveAccount()?.username
          //     email: "alok-nath.pandey@hitachienergy.com"
          //   },
          //   subject: manualEntry.subject,
          //   body: manualEntry.body,
          //   attachments: manualEntry.attachments,
          //   date: new Date(),
          //   type: 16
          // }
          //code for assigning/pushing data to main array
          // this.logdata.push(obj);
          // this.logdata.forEach(l => {
          //   l.formattedDate = l.date; // needs to be formatted later
          //   l.searchableString = l.createdBy.email + l.subject + l.body + l.formattedDate;
          // })
          // this.logdata.forEach(i => {
          //   let logObj = this.logParams.find(j => i.type == j.type);
          //   i.typeLabel = logObj.name;
          //   i.backColor = logObj.backColor;
          // });
          // this.logDataToShow = this.logdata.slice();
          // this.logParams.forEach(i => {
          //   i.count = this.logDataToShow.filter(j => i.type == j.type).length;
          // });
        }
      });
    }

    //Code for printing logDataToShow
    onPrint(divName) {
      //console.log(this.logDataToShow);
      const printContents = document.getElementById(divName).innerHTML;
      printContents.replace("divName", "visible");
      const originalContents = document.body.innerHTML; document.body.innerHTML = printContents;
      window.print();
      document.body.innerHTML = originalContents;
    }

    expandRow(index) {
      if (this.selectedIndex != index) {
        this.selectedIndex = index;
      }
    }

    collapseRow(index, event) {
      if (this.selectedIndex == index) {
        this.selectedIndex = null;
      }
      event.stopPropagation();
    }

    expandAllRows() {
      this.showAllBody = !this.showAllBody;
    }

    expandAll() {
      this.bodyFlag = !this.bodyFlag;
      this.showAllBody = this.bodyFlag;
    }
    btnclicks(x) {
      this.selectedIndex = this.selectedIndex != x ? x : null;
    }

}
