<div class="product-pop">
  <h2 class="widgetH2 mb-1 mt-1">{{ "ProcessStepSearch_SelectInfo" | translate }}</h2>
  <input type="text" (keyup)="filterProcessStep($event)" class="form-control" placeholder="Search by Name (Type at least 3 character)">
  <p-tree [value]="files1" scrollHeight="290px" selectionMode="single" [(selection)]="selectedNode" class="border-1"
          [loading]="loading" (onNodeExpand)="nodeSelect($event)">
  </p-tree>

  <div class="modal-footer my-2">
    <button [disabled]="!selectedNode" class="widgetButtonLargeSelectClose"
      [ngClass]="selectedNode ? '' : ' grayButton'"
      (click)="selectAndClose()">{{ "select_ru_popup_btn_select" | translate }}</button>
    <button class="closeBtn ms-1" type="button" (click)="onClose()">{{ "select_ru_popup_btn_close" | translate }}</button>
  </div>

</div>
