import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class IssueSelectionService {
  constructor(private http: HttpClient) { }

  getBuList(division): Observable<any> {
    return this.http.post(`${environment.azure.baseUrl}api/v1/Data/GetDivisionBUList?division=` + division, division);
  }

  getBuPgList(division, bu): Observable<any> {
    return this.http.post(`${environment.azure.baseUrl}api/v1/Data/GetDivisionBUPGList?division=` + division + ` &bu=` + bu, division);
  }

  getAllCountry(): Observable<any> {
    return this.http.get(`${environment.azure.baseUrl}api/v1/mdregion/countries`);
  }

  getAllCity(cCode): Observable<any> {
    return this.http.get(`${environment.azure.baseUrl}api/v1/mdregion/cities?countries=` + cCode);
  }

  getActivity(pg): Observable<any> {
    return this.http.get(`${environment.azure.baseUrl}api/v1/Activity?pg=`  + pg);
  }

  getComplaintWithRCA(data){
    return this.http.post(`${environment.azure.baseUrl}api/v1/Data/GetComplaintsWithRCA`,data);
  }

  getDivision():Observable<any> {
    return this.http.get(`${environment.azure.baseUrl}api/v1/divisions`);
  }
}
