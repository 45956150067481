import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dropdown-component',
  templateUrl: './dropdown-component.component.html',
  styleUrls: ['./dropdown-component.component.css']
})
export class DropdownComponentComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
