import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

import { ImpactDetailsModel } from '../models/new-issue/impact-details.model';
import { IncidentReviewerAndImpactDataModel } from '../models/new-issue/incident-reviewer-and-impact-data.model';

// export enum ImpactRating {
//   NotAvailable = 'NotAvailable',
//   // A = 'Class A',
//   // B = 'Class B',
//   // C = 'Class C',
//   // I = 'Class I'
//   A = 'High Impact Class A',
//   B = 'Class B',
//   C = 'Class C',
//   I = 'Class I'
// }

@Injectable({
  providedIn: 'root'
})
export class IssueResolutionCurrentRatingDataService {

  constructor(private http: HttpClient) { }

  ratingConfiguration(): Observable<any> {
    return this.http.get(`${environment.azure.baseUrl}api/v1/Data/GetMatrixTreeActive`);
  }

  classifyOriginalRating(formatedData): Observable<any> {
    return this.http.post(`${environment.azure.baseUrl}api/v1/scoringmatrix/classify`, formatedData);
  }


  // public static calculateRpnAndImpactRating(incident: IncidentReviewerAndImpactDataModel): ImpactDetailsModel{
  //   let response: ImpactDetailsModel= {
  //     Rpn: null,
  //     ImpactRating: ImpactRating.NotAvailable,
  //     originalClassValue: 'NotAvailable'
  //   };
  //   if (!this.CanRatingBeCalculated(incident)) {

  //     response.ImpactRating = ImpactRating.NotAvailable;
  //     response.originalClassValue = 'NotAvailable';
  //     return response;
  //   }

  //   let maxSeverityValue = Number(this.GetMaxSeverityValue(incident));
  //   let maxOccurrenceValue = Number(this.GetMaxOcurrenceValue(incident));
  //   let maxDetectionValue = Number(this.GetMaxDetectionValue(incident));
  //   let rpn = this.CalculateRpn(incident);
  //   response.Rpn = rpn;

  //   if ( (rpn && rpn >= 343) || maxSeverityValue === 10 || maxOccurrenceValue === 10 || maxDetectionValue === 10 ) {
  //     response.ImpactRating = ImpactRating.A;
  //     return response;
  //   } else if (maxSeverityValue === 7 || maxOccurrenceValue === 7 || maxDetectionValue === 7) {
  //     response.ImpactRating = ImpactRating.B;
  //     return response;
  //   } else if (maxSeverityValue === 5 || maxOccurrenceValue === 5 || maxDetectionValue === 5) {
  //     response.ImpactRating = ImpactRating.I;
  //     return response;
  //   }
  //   else{
  //     response.ImpactRating = ImpactRating.C;
  //   }

  //   return response;
  // }
  // private static CalculateRpn(incident: IncidentReviewerAndImpactDataModel): (number | null) {
  //     if (!this.CanRatingBeCalculated(incident)) {
  //         return null;
  //     }
  //     let maxSeverityValue = this.GetMaxSeverityValue(incident);
  //     let maxOccurrenceValue = this.GetMaxOcurrenceValue(incident);
  //     let maxDetectionValue = this.GetMaxDetectionValue(incident);
  //     return (maxSeverityValue != null ? maxSeverityValue : 1) * (maxOccurrenceValue != null ? maxOccurrenceValue : 1) * (maxDetectionValue != null ? maxDetectionValue : 1);
  // }
  // private static GetMaxSeverityValue(incident: IncidentReviewerAndImpactDataModel): (number | null) {
  //    //console.log(incident);

  //    let severityRatings: Array<number | null> = [
  //         incident.AvailabilityRating, incident.FunctionalityRating, incident.OnTimeDeliveryRating,
  //         incident.CyberSecurityRating, incident.OhsRiskRating, incident.ProductComplianceRating,
  //         incident.MarginRating, incident.ReputationRating, incident.MassMediaRating, incident.DesignChangeRating
  //     ];
  //     severityRatings = severityRatings.filter((rating) => rating != null);
  //     severityRatings.sort((a, b) => a! - b!);
  //     return severityRatings[severityRatings.length - 1];
  // }
  // private static GetMaxOcurrenceValue(incident: IncidentReviewerAndImpactDataModel): (number | null) {
  //   let occurenceRatings: Array<number | null> = [
  //     incident.InstancesRating, incident.ResolutionRating, incident.AffectedCustomerRating
  //   ];
  //   occurenceRatings = occurenceRatings.filter((rating) => rating != null);
  //   occurenceRatings.sort((a, b) => a! - b!);
  //   return occurenceRatings[occurenceRatings.length - 1];
  // }
  // private static GetMaxDetectionValue(incident: IncidentReviewerAndImpactDataModel): (number | null) {
  //     return incident.ControlRating;
  // }
  // private static CanRatingBeCalculated(incident: IncidentReviewerAndImpactDataModel): boolean {
  //     return incident.OhsRiskRating != null
  //         && incident.ReputationRating != null
  //         && incident.InstancesRating != null
  //         && incident.ControlRating != null;
  // }
}


