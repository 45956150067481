import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ResolvingUnitServiceService {


  constructor(private http: HttpClient) { }

  getUsers(): Observable<any> {
    return this.http.get(`${environment.API_URL}/companyDetailsData`);
  }

  getDivision():Observable<any> {
   return this.http.get(`${environment.azure.baseUrl}api/v1/divisions`);
   }


  getBuList(division): Observable<any> {
    return this.http.post(`${environment.azure.baseUrl}api/v1/Data/GetDivisionBUList?division=` + division, division);
  }

  getBuPgList(division, bu): Observable<any> {
    return this.http.post(`${environment.azure.baseUrl}api/v1/Data/GetDivisionBUPGList?division=` + division + ` &bu=` + bu, division);
  }

  getActivityBal(pg): Observable<any> {
    return this.http.get(`${environment.azure.baseUrl}api/v1/Activity?pg=`  + pg);
  }

  getCountryList(searchTerm): Observable<any> {
    return this.http.get(`${environment.azure.baseUrl}/api/v1/mdregion/countries?searchTerm=` + searchTerm);
  }

  getActivity(data): Observable<any> {
    return this.http.get(`${environment.azure.baseUrl}api/v1/Activity?pg=`+data);
  }

  getPG(data):Observable<any> {
    return this.http.get(`${environment.azure.baseUrl}api/v1/divisions/${data}/bu`);
  }

  getBU(data,data1):Observable<any> {
    return this.http.get(`${environment.azure.baseUrl}api/v1/divisions/${data}/bu/${data1}/pg`);
  }

  getPgForDivisionAndBu(division, bu) {
    division = 'PG'
    if (bu != '' && bu != null) {
      return this.http.get(`${environment.azure.baseUrl}api/v1/divisions/${division}/bu/pg?bu=${bu}`);
    }
    else{
      return this.http.get(`${environment.azure.baseUrl}api/v1/divisions/${division}/bu/pg`);
    }
    // else
    //   return this.http.get(`${environment.azure.baseUrl}api/v1/divisions/PG/bu/pg`);
  }

  getAllCountry(): Observable<any> {
    return this.http.get(`${environment.azure.baseUrl}api/v1/mdregion/countries`);
  }

  getAllCity(cCode): Observable<any> {
    return this.http.get(`${environment.azure.baseUrl}api/v1/mdregion/cities?countries=` + cCode);
  }

  copyRCASection(sourceId, destinationId): Observable<any> {
      return this.http.get(environment.azure.baseUrl + 'api/v1/Data/CopyRcaSection?sourceId=' + sourceId + '&&destinationId=' + destinationId);
  }



  getAllCitybyresion(cCode): Observable<any> {
    return this.http.get(`${environment.azure.baseUrl}api/v1/mdregion/cities?regions=` + cCode);
  }

  getCoOrdinator(resolvingProcessId): Observable<any> {
    return this.http.get(`${environment.azure.baseUrl}api/v1/Data/GetCoordinatorsForResolvingUnit?resolvingUnitId=` +resolvingProcessId.resolvingUnitId + `&processStepId=` +resolvingProcessId.processStepId );
  }

  getVerificationTeam(resolvingProcessId): Observable<any> {
    return this.http.get(`${environment.azure.baseUrl}api/v1/Data/GetVerificationTeamForResolvingUnit?resolvingUnitId=`+resolvingProcessId.resolvingUnitId+`&isHightImpact=`+resolvingProcessId.isHightImpact+`&matrixClass=`+resolvingProcessId.matrixClass+`&processStepId=`+resolvingProcessId.processStepId );
  }

  getResolvingUnit(data){
    return this.http.post(`${environment.azure.baseUrl}api/v1/Data/GetResolvingUnits`,data);
  }

  //Data/GetResolvingUnitsRecentlySelected
  getResolvingUnitsRecentlySelected(geid): Observable<any> {
    return this.http.get(`${environment.azure.baseUrl}api/v1/Data/GetResolvingUnitsRecentlySelected?userGeid=` + geid);
  }
}


