import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { PrimeNGConfig } from "primeng/api";
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-manual-entry',
  templateUrl: './manual-entry.component.html',
  styleUrls: ['./manual-entry.component.css']
})
export class ManualEntryComponent implements OnInit {

  manualEntryFrom: FormGroup;
  subject = '';
  body = '';
  attachment: any;

  constructor(private fb: FormBuilder,
    private primengConfig: PrimeNGConfig,
    private dialogService: DialogService,
    private ref: DynamicDialogRef) { }

  ngOnInit(): void {
    this.manualEntryFrom = this.fb.group({
      customerName: [''],
      resolvingInput: [''],
      resolvingEmail: ['']
    })
  }

  

}




