import { Component, OnInit, EventEmitter, Output, Input, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CustomerContactService } from 'src/app/services/customercontact/customercontact.service';
import { contacts } from 'src/app/reusables/modals/contact.modal';
import { User } from 'src/app/reusables/modals/user';
import { PrimeNGConfig, LazyLoadEvent } from 'primeng/api';
import { DialogService, DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-customer-contact-selection-popup',
  templateUrl: './customer-contact-selection-popup.component.html',
  styleUrls: ['./customer-contact-selection-popup.component.css']
})
export class CustomerContactSelectionPopupComponent implements OnInit {

  user!: User[];
  errorMessage: any;
  coustmercontact: Array<any> = [];
  filteredCompanyDetails: any;
  contactDetailsForm!: FormGroup;
  // selectedIndex!: number;
  selectedRowData: any;
  first: number = 0;
  // totalRecords2: number = 10;
  firstIndex: number = 0;
  rows: number = 5;
  selectedCustomerData: any;
  totalRecords: any = 0 ;
  loading: boolean = false;
  viewClicked: boolean = false;
  selectedProductData: any;

  constructor(private customercontactService: CustomerContactService,
     private fb: FormBuilder,
     private primengConfig: PrimeNGConfig,
     public ref: DynamicDialogRef,
     public dialogService: DialogService,
     private spinnerService: NgxSpinnerService,
     public config: DynamicDialogConfig) {
     this.selectedCustomerData = this.config.data;
    //  console.log(">>>>>>>>",this.selectedCustomerData);
    this.contactDetailsForm = this.fb.group({
      selectedCutomer: [''],
      Firstname: [''],
      Lastname: [''],
      Email: [''],
      Phone: [''],
    })
  }

  ngOnInit(): void {
    this.primengConfig.ripple = true;

    this.contactDetailsForm.patchValue({
     // selectedCutomer: this.selectedCustomer.name+' ('+this.selectedCustomer.Guid+')'
      selectedCutomer: this.selectedCustomerData.displayLabel
    })
    this.CustomerContactDataView();
    this.contactDetailsForm.controls['selectedCutomer'].disable()

  }
  onModifyCCRPContacts() {
    this.ref.close({FirstName: 'modify'});
  }

  loadMoreResolvingData(event: any) {
    if(this.viewClicked==true){
      let skipRecord = 0;
      const pageNumber = (event.first + 5) / 5;
      skipRecord += event.first;
      this.loading = true;
      this.CustomerContactDataView(pageNumber, skipRecord);
  }
  }

  CustomerContactDataView(pageNumber=0, skipRecord=0, viewClicked = false) {
    // this.spinnerService.show();
    this.viewClicked = true;
    let customerContactData = {
      "take":5,
      "skip":skipRecord,
      "page":pageNumber,
      "pageSize":5,
      "group":[],
      "filter":
      {
        "logic":"and",
        "filters":[
          {"field": "LocalCustomerId", "value": this.selectedCustomerData.localCustomerId ? (this.selectedCustomerData.localCustomerId).toString() : this.selectedCustomerData.id.toString()},
          {"field": "GisGuid", "value": this.selectedCustomerData?.guid ? this.selectedCustomerData?.guid : this.selectedCustomerData?.gisIdentifier },
          {"field": "FirstName", "value": this.checkBlank(this.contactDetailsForm.controls['Firstname'].value)},
          {"field": "Surname", "value": this.checkBlank(this.contactDetailsForm.controls['Lastname'].value)},
          {"field": "Email", "value": this.checkBlank(this.contactDetailsForm.controls['Email'].value)},
          {"field": "Phone", "value": this.checkBlank(this.contactDetailsForm.controls['Phone'].value)}
        ],
      }
    }
    this.loading = true;
    this.customercontactService.getCustomerContacts(customerContactData)
      .subscribe((res: any) => {
        if(res){
          this.filteredCompanyDetails = res?.data;
          this.totalRecords = res.count;
         // this.spinnerService.hide();
          this.loading = false;
          //this.viewClicked = viewClicked;
        }
      });

  }
  checkBlank(content) {
    if (content == undefined || content == null) {
      return '';
    } else {
      return content;
    }
  }

  selectedIndex = -1
  selectedRow(index: number) {
    this.selectedIndex = index;
    this.selectedRowData = this.selectedProductData;
  }
  reset() {
    this.contactDetailsForm.get('Email').reset();
    this.contactDetailsForm.get('Firstname').reset();
    this.contactDetailsForm.get('Lastname').reset();
    this.contactDetailsForm.get('Phone').reset();
    this.filteredCompanyDetails = null;
    this.selectedIndex = -1;
  }

  selectAndClose() {
    this.ref.close(this.selectedProductData);
  }
  /* functionality of Paginator from primeng starts */
  next() {
    this.first = this.first + this.rows;
  }

  prev() {
    this.first = this.first - this.rows;
  }

  refresh() {
    // this.firstIndex = 0;
    this.first = 0;

  }

  isLastPage(): boolean {
    return this.filteredCompanyDetails
      ? this.first === this.filteredCompanyDetails.length - this.rows
      : true;
  }

  isFirstPage(): boolean {
    return this.filteredCompanyDetails ? this.first === 0 : true;
  }

  onPageChange(event: any) {
    event.first = 0;
    event.rows = 5;
    event.page = 1;
    event.pageCount = 5;
  }

  /*  functionality of Paginator from primeng End */

}
