import * as i1 from '@angular/common';
import { DOCUMENT, CommonModule } from '@angular/common';
import * as i0 from '@angular/core';
import { Directive, Inject, Input, EventEmitter, Component, ChangeDetectionStrategy, ViewEncapsulation, ContentChildren, Output, NgModule } from '@angular/core';
import { PrimeTemplate, SharedModule } from 'primeng/api';
import { DomHandler } from 'primeng/dom';
import * as i2 from 'primeng/ripple';
import { RippleModule } from 'primeng/ripple';
import { ObjectUtils } from 'primeng/utils';
import { SpinnerIcon } from 'primeng/icons/spinner';
const _c0 = ["*"];
function Button_ng_container_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function Button_ng_container_3_ng_container_1_span_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "span", 8);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(3);
    i0.ɵɵclassMap("p-button-loading-icon" + ctx_r0.icon);
    i0.ɵɵproperty("ngClass", ctx_r0.iconClass());
  }
}
function Button_ng_container_3_ng_container_1_SpinnerIcon_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "SpinnerIcon", 9);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(3);
    i0.ɵɵproperty("styleClass", ctx_r0.iconClass() + " p-button-loading-icon")("spin", true);
  }
}
function Button_ng_container_3_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, Button_ng_container_3_ng_container_1_span_1_Template, 1, 3, "span", 6)(2, Button_ng_container_3_ng_container_1_SpinnerIcon_2_Template, 1, 2, "SpinnerIcon", 7);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r0.loadingIcon);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !ctx_r0.loadingIcon);
  }
}
function Button_ng_container_3_span_2_1_ng_template_0_Template(rf, ctx) {}
function Button_ng_container_3_span_2_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, Button_ng_container_3_span_2_1_ng_template_0_Template, 0, 0, "ng-template");
  }
}
function Button_ng_container_3_span_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span", 10);
    i0.ɵɵtemplate(1, Button_ng_container_3_span_2_1_Template, 1, 0, null, 1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r0.loadingIconTemplate);
  }
}
function Button_ng_container_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, Button_ng_container_3_ng_container_1_Template, 3, 2, "ng-container", 2)(2, Button_ng_container_3_span_2_Template, 2, 1, "span", 5);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !ctx_r0.loadingIconTemplate);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r0.loadingIconTemplate);
  }
}
function Button_ng_container_4_span_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "span", 8);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(2);
    i0.ɵɵclassMap(ctx_r0.icon);
    i0.ɵɵproperty("ngClass", ctx_r0.iconClass());
  }
}
function Button_ng_container_4_span_2_1_ng_template_0_Template(rf, ctx) {}
function Button_ng_container_4_span_2_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, Button_ng_container_4_span_2_1_ng_template_0_Template, 0, 0, "ng-template", 12);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(3);
    i0.ɵɵproperty("ngIf", !ctx_r0.icon);
  }
}
function Button_ng_container_4_span_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span", 8);
    i0.ɵɵtemplate(1, Button_ng_container_4_span_2_1_Template, 1, 1, null, 1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("ngClass", ctx_r0.iconClass());
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r0.iconTemplate);
  }
}
function Button_ng_container_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, Button_ng_container_4_span_1_Template, 1, 3, "span", 6)(2, Button_ng_container_4_span_2_Template, 2, 2, "span", 11);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r0.icon && !ctx_r0.iconTemplate);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !ctx_r0.icon && ctx_r0.iconTemplate);
  }
}
function Button_span_5_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span", 13);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵattribute("aria-hidden", ctx_r0.icon && !ctx_r0.label);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(ctx_r0.label);
  }
}
function Button_span_6_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span", 8);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵclassMap(ctx_r0.badgeClass);
    i0.ɵɵproperty("ngClass", ctx_r0.badgeStyleClass());
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(ctx_r0.badge);
  }
}
const INTERNAL_BUTTON_CLASSES = {
  button: 'p-button',
  component: 'p-component',
  iconOnly: 'p-button-icon-only',
  disabled: 'p-disabled',
  loading: 'p-button-loading',
  labelOnly: 'p-button-loading-label-only'
};
class ButtonDirective {
  constructor(el, document) {
    this.el = el;
    this.document = document;
    this.iconPos = 'left';
    this._loading = false;
    this._internalClasses = Object.values(INTERNAL_BUTTON_CLASSES);
    this.spinnerIcon = `<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" class="p-icon-spin">
        <g clip-path="url(#clip0_417_21408)">
            <path
                d="M6.99701 14C5.85441 13.999 4.72939 13.7186 3.72012 13.1832C2.71084 12.6478 1.84795 11.8737 1.20673 10.9284C0.565504 9.98305 0.165424 8.89526 0.041387 7.75989C-0.0826496 6.62453 0.073125 5.47607 0.495122 4.4147C0.917119 3.35333 1.59252 2.4113 2.46241 1.67077C3.33229 0.930247 4.37024 0.413729 5.4857 0.166275C6.60117 -0.0811796 7.76026 -0.0520535 8.86188 0.251112C9.9635 0.554278 10.9742 1.12227 11.8057 1.90555C11.915 2.01493 11.9764 2.16319 11.9764 2.31778C11.9764 2.47236 11.915 2.62062 11.8057 2.73C11.7521 2.78503 11.688 2.82877 11.6171 2.85864C11.5463 2.8885 11.4702 2.90389 11.3933 2.90389C11.3165 2.90389 11.2404 2.8885 11.1695 2.85864C11.0987 2.82877 11.0346 2.78503 10.9809 2.73C9.9998 1.81273 8.73246 1.26138 7.39226 1.16876C6.05206 1.07615 4.72086 1.44794 3.62279 2.22152C2.52471 2.99511 1.72683 4.12325 1.36345 5.41602C1.00008 6.70879 1.09342 8.08723 1.62775 9.31926C2.16209 10.5513 3.10478 11.5617 4.29713 12.1803C5.48947 12.7989 6.85865 12.988 8.17414 12.7157C9.48963 12.4435 10.6711 11.7264 11.5196 10.6854C12.3681 9.64432 12.8319 8.34282 12.8328 7C12.8328 6.84529 12.8943 6.69692 13.0038 6.58752C13.1132 6.47812 13.2616 6.41667 13.4164 6.41667C13.5712 6.41667 13.7196 6.47812 13.8291 6.58752C13.9385 6.69692 14 6.84529 14 7C14 8.85651 13.2622 10.637 11.9489 11.9497C10.6356 13.2625 8.85432 14 6.99701 14Z"
                fill="currentColor"
            />
        </g>
        <defs>
            <clipPath id="clip0_417_21408">
                <rect width="14" height="14" fill="white" />
            </clipPath>
        </defs>
    </svg>`;
  }
  get label() {
    return this._label;
  }
  set label(val) {
    this._label = val;
    if (this.initialized) {
      this.updateLabel();
      this.updateIcon();
      this.setStyleClass();
    }
  }
  get icon() {
    return this._icon;
  }
  set icon(val) {
    this._icon = val;
    if (this.initialized) {
      this.updateIcon();
      this.setStyleClass();
    }
  }
  get loading() {
    return this._loading;
  }
  set loading(val) {
    this._loading = val;
    if (this.initialized) {
      this.updateIcon();
      this.setStyleClass();
    }
  }
  get htmlElement() {
    return this.el.nativeElement;
  }
  ngAfterViewInit() {
    DomHandler.addMultipleClasses(this.htmlElement, this.getStyleClass().join(' '));
    this.createIcon();
    this.createLabel();
    this.initialized = true;
  }
  getStyleClass() {
    const styleClass = [INTERNAL_BUTTON_CLASSES.button, INTERNAL_BUTTON_CLASSES.component];
    if (this.icon && !this.label && ObjectUtils.isEmpty(this.htmlElement.textContent)) {
      styleClass.push(INTERNAL_BUTTON_CLASSES.iconOnly);
    }
    if (this.loading) {
      styleClass.push(INTERNAL_BUTTON_CLASSES.disabled, INTERNAL_BUTTON_CLASSES.loading);
      if (!this.icon && this.label) {
        styleClass.push(INTERNAL_BUTTON_CLASSES.labelOnly);
      }
    }
    return styleClass;
  }
  setStyleClass() {
    const styleClass = this.getStyleClass();
    this.htmlElement.classList.remove(...this._internalClasses);
    this.htmlElement.classList.add(...styleClass);
  }
  createLabel() {
    if (this.label) {
      let labelElement = this.document.createElement('span');
      if (this.icon && !this.label) {
        labelElement.setAttribute('aria-hidden', 'true');
      }
      labelElement.className = 'p-button-label';
      labelElement.appendChild(this.document.createTextNode(this.label));
      this.htmlElement.appendChild(labelElement);
    }
  }
  createIcon() {
    if (this.icon || this.loading) {
      let iconElement = this.document.createElement('span');
      iconElement.className = 'p-button-icon';
      iconElement.setAttribute('aria-hidden', 'true');
      let iconPosClass = this.label ? 'p-button-icon-' + this.iconPos : null;
      if (iconPosClass) {
        DomHandler.addClass(iconElement, iconPosClass);
      }
      let iconClass = this.getIconClass();
      if (iconClass) {
        DomHandler.addMultipleClasses(iconElement, iconClass);
      }
      if (!this.loadingIcon && this.loading) {
        iconElement.innerHTML = this.spinnerIcon;
      }
      this.htmlElement.insertBefore(iconElement, this.htmlElement.firstChild);
    }
  }
  updateLabel() {
    let labelElement = DomHandler.findSingle(this.htmlElement, '.p-button-label');
    if (!this.label) {
      labelElement && this.htmlElement.removeChild(labelElement);
      return;
    }
    labelElement ? labelElement.textContent = this.label : this.createLabel();
  }
  updateIcon() {
    let iconElement = DomHandler.findSingle(this.htmlElement, '.p-button-icon');
    if (!this.icon && !this.loading) {
      iconElement && this.htmlElement.removeChild(iconElement);
      return;
    }
    if (iconElement) {
      if (this.iconPos) iconElement.className = 'p-button-icon p-button-icon-' + this.iconPos + ' ' + this.getIconClass();else iconElement.className = 'p-button-icon ' + this.getIconClass();
    } else {
      this.createIcon();
    }
  }
  getIconClass() {
    return this.loading ? 'p-button-loading-icon ' + (this.loadingIcon ? this.loadingIcon : 'p-icon') : this._icon;
  }
  ngOnDestroy() {
    this.initialized = false;
  }
}
ButtonDirective.ɵfac = function ButtonDirective_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || ButtonDirective)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(DOCUMENT));
};
ButtonDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: ButtonDirective,
  selectors: [["", "pButton", ""]],
  hostAttrs: [1, "p-element"],
  inputs: {
    iconPos: "iconPos",
    loadingIcon: "loadingIcon",
    label: "label",
    icon: "icon",
    loading: "loading"
  }
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ButtonDirective, [{
    type: Directive,
    args: [{
      selector: '[pButton]',
      host: {
        class: 'p-element'
      }
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: Document,
      decorators: [{
        type: Inject,
        args: [DOCUMENT]
      }]
    }];
  }, {
    iconPos: [{
      type: Input
    }],
    loadingIcon: [{
      type: Input
    }],
    label: [{
      type: Input
    }],
    icon: [{
      type: Input
    }],
    loading: [{
      type: Input
    }]
  });
})();
class Button {
  constructor() {
    this.type = 'button';
    this.iconPos = 'left';
    this.loading = false;
    this.onClick = new EventEmitter();
    this.onFocus = new EventEmitter();
    this.onBlur = new EventEmitter();
  }
  iconClass() {
    return {
      'p-button-icon': true,
      'p-button-icon-left': this.iconPos === 'left' && this.label,
      'p-button-icon-right': this.iconPos === 'right' && this.label,
      'p-button-icon-top': this.iconPos === 'top' && this.label,
      'p-button-icon-bottom': this.iconPos === 'bottom' && this.label
    };
  }
  buttonClass() {
    return {
      'p-button p-component': true,
      'p-button-icon-only': this.icon && !this.label,
      'p-button-vertical': (this.iconPos === 'top' || this.iconPos === 'bottom') && this.label,
      'p-disabled': this.disabled || this.loading,
      'p-button-loading': this.loading,
      'p-button-loading-label-only': this.loading && !this.icon && this.label
    };
  }
  ngAfterContentInit() {
    this.templates.forEach(item => {
      switch (item.getType()) {
        case 'content':
          this.contentTemplate = item.template;
          break;
        case 'icon':
          this.iconTemplate = item.template;
          break;
        case 'loadingicon':
          this.loadingIconTemplate = item.template;
          break;
        default:
          this.contentTemplate = item.template;
          break;
      }
    });
  }
  badgeStyleClass() {
    return {
      'p-badge p-component': true,
      'p-badge-no-gutter': this.badge && String(this.badge).length === 1
    };
  }
}
Button.ɵfac = function Button_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || Button)();
};
Button.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: Button,
  selectors: [["p-button"]],
  contentQueries: function Button_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, PrimeTemplate, 4);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.templates = _t);
    }
  },
  hostAttrs: [1, "p-element"],
  inputs: {
    type: "type",
    iconPos: "iconPos",
    icon: "icon",
    badge: "badge",
    label: "label",
    disabled: "disabled",
    loading: "loading",
    loadingIcon: "loadingIcon",
    style: "style",
    styleClass: "styleClass",
    badgeClass: "badgeClass",
    ariaLabel: "ariaLabel"
  },
  outputs: {
    onClick: "onClick",
    onFocus: "onFocus",
    onBlur: "onBlur"
  },
  ngContentSelectors: _c0,
  decls: 7,
  vars: 12,
  consts: [["pRipple", "", 3, "click", "focus", "blur", "ngStyle", "disabled", "ngClass"], [4, "ngTemplateOutlet"], [4, "ngIf"], ["class", "p-button-label", 4, "ngIf"], [3, "ngClass", "class", 4, "ngIf"], ["class", "p-button-loading-icon", 4, "ngIf"], [3, "class", "ngClass", 4, "ngIf"], [3, "styleClass", "spin", 4, "ngIf"], [3, "ngClass"], [3, "styleClass", "spin"], [1, "p-button-loading-icon"], [3, "ngClass", 4, "ngIf"], [3, "ngIf"], [1, "p-button-label"]],
  template: function Button_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵelementStart(0, "button", 0);
      i0.ɵɵlistener("click", function Button_Template_button_click_0_listener($event) {
        return ctx.onClick.emit($event);
      })("focus", function Button_Template_button_focus_0_listener($event) {
        return ctx.onFocus.emit($event);
      })("blur", function Button_Template_button_blur_0_listener($event) {
        return ctx.onBlur.emit($event);
      });
      i0.ɵɵprojection(1);
      i0.ɵɵtemplate(2, Button_ng_container_2_Template, 1, 0, "ng-container", 1)(3, Button_ng_container_3_Template, 3, 2, "ng-container", 2)(4, Button_ng_container_4_Template, 3, 2, "ng-container", 2)(5, Button_span_5_Template, 2, 2, "span", 3)(6, Button_span_6_Template, 2, 4, "span", 4);
      i0.ɵɵelementEnd();
    }
    if (rf & 2) {
      i0.ɵɵclassMap(ctx.styleClass);
      i0.ɵɵproperty("ngStyle", ctx.style)("disabled", ctx.disabled || ctx.loading)("ngClass", ctx.buttonClass());
      i0.ɵɵattribute("type", ctx.type)("aria-label", ctx.ariaLabel);
      i0.ɵɵadvance(2);
      i0.ɵɵproperty("ngTemplateOutlet", ctx.contentTemplate);
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngIf", ctx.loading);
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngIf", !ctx.loading);
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngIf", !ctx.contentTemplate && ctx.label);
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngIf", !ctx.contentTemplate && ctx.badge);
    }
  },
  dependencies: () => [i1.NgClass, i1.NgIf, i1.NgTemplateOutlet, i1.NgStyle, i2.Ripple, SpinnerIcon],
  encapsulation: 2,
  changeDetection: 0
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(Button, [{
    type: Component,
    args: [{
      selector: 'p-button',
      template: `
        <button
            [attr.type]="type"
            [attr.aria-label]="ariaLabel"
            [class]="styleClass"
            [ngStyle]="style"
            [disabled]="disabled || loading"
            [ngClass]="buttonClass()"
            (click)="onClick.emit($event)"
            (focus)="onFocus.emit($event)"
            (blur)="onBlur.emit($event)"
            pRipple
        >
            <ng-content></ng-content>
            <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
            <ng-container *ngIf="loading">
                <ng-container *ngIf="!loadingIconTemplate">
                    <span *ngIf="loadingIcon" [class]="'p-button-loading-icon' + icon" [ngClass]="iconClass()"></span>
                    <SpinnerIcon *ngIf="!loadingIcon" [styleClass]="iconClass() + ' p-button-loading-icon'" [spin]="true" />
                </ng-container>
                <span *ngIf="loadingIconTemplate" class="p-button-loading-icon">
                    <ng-template *ngTemplateOutlet="loadingIconTemplate"></ng-template>
                </span>
            </ng-container>
            <ng-container *ngIf="!loading">
                <span *ngIf="icon && !iconTemplate" [class]="icon" [ngClass]="iconClass()"></span>
                <span *ngIf="!icon && iconTemplate" [ngClass]="iconClass()">
                    <ng-template [ngIf]="!icon" *ngTemplateOutlet="iconTemplate;"></ng-template>
                </span>
            </ng-container>
            <span class="p-button-label" [attr.aria-hidden]="icon && !label" *ngIf="!contentTemplate && label">{{ label }}</span>
            <span [ngClass]="badgeStyleClass()" [class]="badgeClass" *ngIf="!contentTemplate && badge">{{ badge }}</span>
        </button>
    `,
      changeDetection: ChangeDetectionStrategy.OnPush,
      encapsulation: ViewEncapsulation.None,
      host: {
        class: 'p-element'
      }
    }]
  }], null, {
    type: [{
      type: Input
    }],
    iconPos: [{
      type: Input
    }],
    icon: [{
      type: Input
    }],
    badge: [{
      type: Input
    }],
    label: [{
      type: Input
    }],
    disabled: [{
      type: Input
    }],
    loading: [{
      type: Input
    }],
    loadingIcon: [{
      type: Input
    }],
    style: [{
      type: Input
    }],
    styleClass: [{
      type: Input
    }],
    badgeClass: [{
      type: Input
    }],
    ariaLabel: [{
      type: Input
    }],
    templates: [{
      type: ContentChildren,
      args: [PrimeTemplate]
    }],
    onClick: [{
      type: Output
    }],
    onFocus: [{
      type: Output
    }],
    onBlur: [{
      type: Output
    }]
  });
})();
class ButtonModule {}
ButtonModule.ɵfac = function ButtonModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || ButtonModule)();
};
ButtonModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: ButtonModule
});
ButtonModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [CommonModule, RippleModule, SharedModule, SpinnerIcon, SharedModule]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ButtonModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, RippleModule, SharedModule, SpinnerIcon],
      exports: [ButtonDirective, Button, SharedModule],
      declarations: [ButtonDirective, Button]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { Button, ButtonDirective, ButtonModule };

