<div class="col-md-12">
  <div class="panel panel-default">
    <div class="panel-heading">
      <div class="row">
        <div class="col-sm-11">
          <span class="panel-title" [ngClass]="{manage: itemScope.canManage, simple: !itemScope.canManage}">
            <span class="input-group">{{itemScope.scope.locationdescr}} </span>
          </span>
        </div>
        <div class="col-sm-1">
          <div class="row">
            <div class="col-sm-6">
              <span *ngIf="itemScope.canManage">
                <!-- <button type="button" class="btn btn-default">
                  {{'admin_po_addperson_btn'|translate}}
                </button> Commented this as per discussion with ajay-->
                <button type="button" (click)="collapse()"
                  *ngIf="expanded">
                  <i class="fa fa-angle-double-up" title="Collapse"></i>
                </button>
                <button type="button" (click)="expand()" *ngIf="!expanded">
                  <i class="fa fa-angle-double-down" title="Expand"></i>
                </button>
              </span>
            </div>
            <div class="col-sm-6 ml-2">
              <span class="input-group" *ngIf="!itemScope.canManage">
                <button type="button" (click)="collapse()" class="btn btn-default btn-sm"
                  *ngIf="expanded">
                  <i class="fa fa-angle-double-up" title="Collapse"></i>
                </button>
                <button type="button" (click)="expand()" class="btn btn-default btn-sm" *ngIf="!expanded">
                  <i class="fa fa-angle-double-down" title="Expand"></i>
                </button>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="panel-body" *ngIf="expanded">
      <div>
        <div class="row whitewell">
          <div class="col-sm-4" *ngIf="itemScope.scope.regions  && itemScope.scope.regions.length > 0">
            <span>{{'admin_po_region_po_lbl' | translate}}:</span>
          </div>
          <div class="col-sm-4" *ngIf="itemScope.scope.countries  && itemScope.scope.countries.length > 0">
            <span>{{'admin_po_country_po_lbl' | translate}}:</span>
          </div>
          <div class="col-sm-4">
            <a class="admin-link" *ngIf="!itemScope.showAllLocationManagers && itemScope.locationManagers.length > 0"
              (click)="onExpandLocationManagers()">({{itemScope.locationManagers.length}}
              {{'admin_po_x_people_seemore_lbl' |translate}})</a>
            <span *ngIf="loadingManagers.locationManagers">
              <small>{{'admin_po_loading_managers' | translate}} </small>
              <i class="fa fa-spinner fa-spin" aria-hidden="true"></i>
            </span>
          </div>
          <div class="col-sm-4">
            <span class="btn btn-default btn-sm" *ngIf="itemScope.showAllLocationManagers">
              <i class="fa fa-angle-double-up admin-link"
                (click)="itemScope.showAllLocationManagers = false" title="Collapse"></i>
            </span>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-12 col-sm-6 col-md-4">
            <span
              *ngIf="!itemScope.locationManagers.length || itemScope.locationManagers.length == 0">{{'admin_po_info_none_lbl'
              | translate}}</span>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12" *ngIf="itemScope.showAllLocationManagers">
            <div class="rows">
              <div class="column" *ngFor="let items of  itemScope.locationManagersOrderedVertically">
                <div class="element" *ngFor="let lm of items">
                  <span class="glyphicon glyphicon-user"></span>
                  <!-- <span data-cc-process-owner-details po-scope="lm"></span> -->
                  <span><app-po-details [poScope]="lm"></app-po-details></span>

                  <a class="admin-link" *ngIf="lm.canManage">
                    <span *ngIf="!deleteButtonHideForSearch"> <i class="fa fa-trash-o admin-icon-a" *ngIf="lm.canManage" (click)="deletePerson({geid: lm.id, scope: lm.scope})"></i></span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <hr />

      <div>
        <div class="row whitewell">
          <div class="col-sm-4" *ngIf="itemScope.scope.regions && itemScope.scope.regions.length > 0">
            <strong> {{'admin_po_regionunit_po_lbl' | translate}}: </strong>
          </div>
          <div class="col-sm-4" *ngIf="itemScope.scope.countries  && itemScope.scope.countries.length > 0">
            <strong>{{'admin_po_countryunit_po_lbl' | translate}}: </strong>
          </div>
          <div class="col-sm-4">
            <a class="admin-link"
              *ngIf="!itemScope.showAllUnitLocationManagers && itemScope.unitLocationManagers.length > 0"
              (click)="onExpandUnitLocationManagers()">({{itemScope.unitLocationManagers.length}}
              {{'admin_po_x_people_seemore_lbl' |translate}})</a>
            <span *ngIf="loadingManagers.unitLocationManagers">
              <small>{{'admin_po_loading_managers' | translate}} </small>
              <i class="fa fa-spinner fa-spin" aria-hidden="true"></i>
            </span>
          </div>
          <div class="col-sm-4">
            <span class="btn btn-default btn-sm" *ngIf="itemScope.showAllUnitLocationManagers">
              <i class="fa fa-angle-double-up admin-link"
                (click)="itemScope.showAllUnitLocationManagers = false" title="Collapse"></i>
            </span>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12"
            *ngIf="null != itemScope.unitLocationManagers && undefined != itemScope.unitLocationManagers">
            <span *ngIf="!itemScope.unitLocationManagers.length">{{'admin_po_info_none_lbl' | translate}}</span>
          </div>
          <div class="col-sm-12" *ngIf="itemScope.showAllUnitLocationManagers"></div>
          <div class="rows">
            <div class="column" *ngFor="let items of itemScope.unitLocationManagersOrderedVertically">
              <div class="element" *ngFor="let ulm of items">
                <span class="glyphicon glyphicon-user"></span>
                <!-- <span data-cc-process-owner-details po-scope="ulm"></span> -->
                <span><app-po-details [poScope]="ulm"></app-po-details></span>
                <a class="admin-link" *ngIf="ulm.canManage" (click)="deletePerson({geid: ulm.id, scope: ulm.scope})">
                <span *ngIf="!deleteButtonHideForSearch"><i class="fa fa-trash-o admin-icon-a" *ngIf="ulm.canManage"></i></span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
