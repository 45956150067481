<form [formGroup]="issueSelectionForm">
    <div class="issue-selection-pop">

        <div class="container">

            <div class="form-check row" style="padding-bottom: 1%;">
                <h5 class="boldText">Find issue by CCRP Number</h5>
                <div class="col headerText">
                    <label class="N1">{{ "Word_ComplaintNo" | translate }}</label>
                    <input type="text" class=" widgetTextBoxMedium" formControlName="CCRPNo">
                </div>
            </div>

            <div class="form-check" style="margin-top:6px">
                <h5 class="boldText">Find Issue by business activity location</h5>
            </div>

            <div class="row inp mb-1 mt-1">
                <div class="divColoumnSpan tagColumn ">Business</div>
                <div class="col selectgap">
                    <select type="text" class="form-select form-control widgetTextBoxMedium"
                        aria-label="Default select example" formControlName="business">
                        <option *ngFor="let div of divisionData" value={{div?.key}} selected> {{ div?.value }} </option>
                    </select>
                </div>

                <div class="divColoumnSpan tagColumn">{{ "select_ru_popup_country" | translate }}</div>
                <div class="col pe-0 selectgap">
                    <select type="text" class="form-select form-control widgetTextBoxMedium" formControlName="country"
                        aria-labelledby="country">
                        <option value="" selected>--Select one--</option>
                        <option *ngFor="let country of getCountryList" value="{{ country.id }}"> {{ country.name }} </option>
                    </select>
                </div>
            </div>

            <div class="row inp mb-1">
                <div class="divColoumnSpan tagColumn">{{ "select_ru_popup_bu" | translate }}</div>
                <div class="col selectgap">
                    <select type="text" class="form-select form-control widgetTextBoxMedium" formControlName="bu"
                        aria-labelledby="bu" (change)="pgChangeData ($event)">
                        <option value="" selected>--Select one--</option>
                        <option *ngFor="let count of buPgList" value="{{count?.key}}">{{count.name}} </option>
                    </select>
                </div>

                <div class="divColoumnSpan tagColumn">{{ "select_ru_popup_city" | translate }}</div>
                <div class="col pe-0 selectgap">
                    <select class="form-select form-control widgetTextBoxMedium" formControlName="city" name="city"
                        [(ngModel)]="city">
                        <option value="" selected>--Select one--</option>
                        <option *ngFor="let city of getCityList"> {{ city.name }} </option>
                    </select>
                </div>
            </div>

            <div class="row inp mb-4">
                <div class="divColoumnSpan tagColumn">{{ "select_ru_popup_pg" | translate }}</div>
                <div class="col selectgap">
                    <select type="text" class="form-select form-control widgetTextBoxMedium"
                        aria-label="Default select example" formControlName="pg">
                        <option value="" selected>--Select one--</option>
                        <option *ngFor="let pgDetails of pgName | keyvalue" value="{{pgDetails?.key}}">{{pgDetails?.value}} </option>
                    </select>
                </div>

                <div class="divColoumnSpan tagColumn">{{ "select_ru_popup_activity" | translate }}</div>
                <div class="col pe-0 selectgap">
                    <select type="text" class="form-select form-control widgetTextBoxMedium"
                        aria-label="Default select example" formControlName="activity">
                        <option value="" selected>--Select one--</option>
                        <option *ngFor="let b of activityDropdownData" value="{{b?.key}}">{{ b.value }}</option>
                    </select>
                </div>
            </div>
            <div class="modal-footer my-2 pe-2">
                <button class="input widgetButtonMedium" type="button" (click)="viewRuPopUpDetails()">{{ "select_ru_popup_btn_view" | translate }}</button>
                <button class="input widgetButtonMedium ms-1" type="button" (click)="reset()">{{ "select_ru_popup_btn_reset" | translate }}</button>
            </div>
        </div>

        <div class="tablediv">
            <h5 class="mt-2 mx-4 boldText">{{ "SearchForm_ComplaintSimilarSearchGridHeadline" | translate }}</h5>
            <div class="tableMargin">
                <div class="card">
                    <p-table [value]="filteredCompanyDetails" [lazy]="true" [totalRecords]="totalRecords" [loading]="loading" (onLazyLoad)="loadMoreComplaintRCAData($event)" selectionMode="single" [paginator]="true" [rows]="rows"
                        [showCurrentPageReport]="true" [(first)]="first" [tableStyle]="{'min-width': '50rem'}"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries">

                        <ng-template pTemplate="header" styleClass="table table-bordered table-striped table-hove">
                            <tr>
                                <th pSortableColumn="CCRPNo" style="width:5%">{{ "Complaint_ComplaintNumber" | translate }}
                                    <p-sortIcon field="CCRPNo"></p-sortIcon>
                                </th>
                                <th pSortableColumn="FailureMode" style="width:19%">{{ "Complaint_FailureMode" | translate }}
                                    <p-sortIcon field="FailureMode"></p-sortIcon>
                                </th>
                                <th pSortableColumn="Customer" style="width:20%">{{ "CustomerContactSearch_Customer" | translate }}
                                    <p-sortIcon field="Customer"></p-sortIcon>
                                </th>
                                <th pSortableColumn="Status" style="width:15%">{{ "dashb_sort_status" | translate }}
                                    <p-sortIcon field="Status"></p-sortIcon>
                                </th>
                                <th pSortableColumn="Description" style="width:41%">{{ "issuedescription_globalsearch" | translate }}
                                    <p-sortIcon field="Description"></p-sortIcon>
                                </th>
                            </tr>
                        </ng-template>

                        <ng-template pTemplate="body" let-c let-rowIndex="rowIndex">
                            <tr [ngClass]="{ selectedBalIdRow: rowIndex == selectedIndex }"
                                (click)="selectedRow(rowIndex)">
                                <td><a [routerLink]="['/', 'complaint', c.complaintNumber]" target="_blank">{{ c.complaintNumber }}</a></td>
                                <td>{{ c.failureMode }}</td>
                                <td>{{ c.customer }}</td>
                                <td>{{ c.complaintStatusFormatted }}</td>
                                <td>{{ c.description | shortenComplaintSubject : 40}}</td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="paginatorright">

                            <p-button type="button" icon="pi pi-refresh" (click)="refresh()"
                                styleClass="p-button-text"></p-button>
                        </ng-template>
                    </p-table>
                    <p class="px-2 pt-1">{{ "SearchForm_ComplaintSimilarSearchFormDescription" | translate }}
                    </p>
                </div>
            </div>
        </div>

        <div class="modal-footer my-2 pe-3">
            <button
                [ngClass]="selectedIndex !== -1 ? 'widgetButtonLargeSelectClose red_button' : ' widgetButtonLargeSelectClose'"
                type="button" (click)="selectAndClose()"> {{ "select_ru_popup_btn_select" | translate }}
            </button>
            <button class="input widgetButtonLarge ms-1" type="button" (click)="onClose()">{{ "select_ru_popup_btn_close" | translate }}</button>
        </div>
    </div>
</form>
