import * as i1 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i0 from '@angular/core';
import { forwardRef, EventEmitter, Component, ChangeDetectionStrategy, ViewEncapsulation, Input, ViewChild, Output, ContentChildren, NgModule } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ObjectUtils } from 'primeng/utils';
import { PrimeTemplate, SharedModule } from 'primeng/api';
import { CheckIcon } from 'primeng/icons/check';
const _c0 = ["cb"];
const _c1 = (a0, a1, a2) => ({
  "p-checkbox p-component": true,
  "p-checkbox-checked": a0,
  "p-checkbox-disabled": a1,
  "p-checkbox-focused": a2
});
const _c2 = (a0, a1, a2) => ({
  "p-highlight": a0,
  "p-disabled": a1,
  "p-focus": a2
});
const _c3 = (a0, a1, a2) => ({
  "p-checkbox-label": true,
  "p-checkbox-label-active": a0,
  "p-disabled": a1,
  "p-checkbox-label-focus": a2
});
function Checkbox_ng_container_5_ng_container_1_span_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "span", 10);
  }
  if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext(3);
    i0.ɵɵproperty("ngClass", ctx_r2.checkboxIcon);
  }
}
function Checkbox_ng_container_5_ng_container_1_CheckIcon_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "CheckIcon", 11);
  }
  if (rf & 2) {
    i0.ɵɵproperty("styleClass", "p-checkbox-icon");
  }
}
function Checkbox_ng_container_5_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, Checkbox_ng_container_5_ng_container_1_span_1_Template, 1, 1, "span", 8)(2, Checkbox_ng_container_5_ng_container_1_CheckIcon_2_Template, 1, 1, "CheckIcon", 9);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r2.checkboxIcon);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !ctx_r2.checkboxIcon);
  }
}
function Checkbox_ng_container_5_span_2_1_ng_template_0_Template(rf, ctx) {}
function Checkbox_ng_container_5_span_2_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, Checkbox_ng_container_5_span_2_1_ng_template_0_Template, 0, 0, "ng-template");
  }
}
function Checkbox_ng_container_5_span_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span", 12);
    i0.ɵɵtemplate(1, Checkbox_ng_container_5_span_2_1_Template, 1, 0, null, 13);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r2.checkboxIconTemplate);
  }
}
function Checkbox_ng_container_5_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, Checkbox_ng_container_5_ng_container_1_Template, 3, 2, "ng-container", 5)(2, Checkbox_ng_container_5_span_2_Template, 2, 1, "span", 7);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !ctx_r2.checkboxIconTemplate);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r2.checkboxIconTemplate);
  }
}
function Checkbox_label_6_Template(rf, ctx) {
  if (rf & 1) {
    const _r4 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "label", 14);
    i0.ɵɵlistener("click", function Checkbox_label_6_Template_label_click_0_listener($event) {
      i0.ɵɵrestoreView(_r4);
      const ctx_r2 = i0.ɵɵnextContext();
      const cb_r2 = i0.ɵɵreference(3);
      return i0.ɵɵresetView(ctx_r2.onClick($event, cb_r2, true));
    });
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵclassMap(ctx_r2.labelStyleClass);
    i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction3(5, _c3, ctx_r2.checked(), ctx_r2.disabled, ctx_r2.focused));
    i0.ɵɵattribute("for", ctx_r2.inputId);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(ctx_r2.label);
  }
}
const CHECKBOX_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => Checkbox),
  multi: true
};
class Checkbox {
  constructor(cd) {
    this.cd = cd;
    this.trueValue = true;
    this.falseValue = false;
    this.onChange = new EventEmitter();
    this.onModelChange = () => {};
    this.onModelTouched = () => {};
    this.focused = false;
  }
  ngAfterContentInit() {
    this.templates.forEach(item => {
      switch (item.getType()) {
        case 'icon':
          this.checkboxIconTemplate = item.template;
          break;
      }
    });
  }
  onClick(event, checkbox, focus) {
    event.preventDefault();
    if (this.disabled || this.readonly) {
      return;
    }
    this.updateModel(event);
    if (focus) {
      checkbox.focus();
    }
  }
  updateModel(event) {
    let newModelValue;
    if (!this.binary) {
      if (this.checked()) newModelValue = this.model.filter(val => !ObjectUtils.equals(val, this.value));else newModelValue = this.model ? [...this.model, this.value] : [this.value];
      this.onModelChange(newModelValue);
      this.model = newModelValue;
      if (this.formControl) {
        this.formControl.setValue(newModelValue);
      }
    } else {
      newModelValue = this.checked() ? this.falseValue : this.trueValue;
      this.model = newModelValue;
      this.onModelChange(newModelValue);
    }
    this.onChange.emit({
      checked: newModelValue,
      originalEvent: event
    });
  }
  handleChange(event) {
    if (!this.readonly) {
      this.updateModel(event);
    }
  }
  onFocus() {
    this.focused = true;
  }
  onBlur() {
    this.focused = false;
    this.onModelTouched();
  }
  focus() {
    this.inputViewChild.nativeElement.focus();
  }
  writeValue(model) {
    this.model = model;
    this.cd.markForCheck();
  }
  registerOnChange(fn) {
    this.onModelChange = fn;
  }
  registerOnTouched(fn) {
    this.onModelTouched = fn;
  }
  setDisabledState(val) {
    this.disabled = val;
    this.cd.markForCheck();
  }
  checked() {
    return this.binary ? this.model === this.trueValue : ObjectUtils.contains(this.value, this.model);
  }
}
Checkbox.ɵfac = function Checkbox_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || Checkbox)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef));
};
Checkbox.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: Checkbox,
  selectors: [["p-checkbox"]],
  contentQueries: function Checkbox_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, PrimeTemplate, 4);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.templates = _t);
    }
  },
  viewQuery: function Checkbox_Query(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵviewQuery(_c0, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.inputViewChild = _t.first);
    }
  },
  hostAttrs: [1, "p-element"],
  inputs: {
    value: "value",
    name: "name",
    disabled: "disabled",
    binary: "binary",
    label: "label",
    ariaLabelledBy: "ariaLabelledBy",
    ariaLabel: "ariaLabel",
    tabindex: "tabindex",
    inputId: "inputId",
    style: "style",
    styleClass: "styleClass",
    labelStyleClass: "labelStyleClass",
    formControl: "formControl",
    checkboxIcon: "checkboxIcon",
    readonly: "readonly",
    required: "required",
    trueValue: "trueValue",
    falseValue: "falseValue"
  },
  outputs: {
    onChange: "onChange"
  },
  features: [i0.ɵɵProvidersFeature([CHECKBOX_VALUE_ACCESSOR])],
  decls: 7,
  vars: 26,
  consts: [["cb", ""], [3, "ngStyle", "ngClass"], [1, "p-hidden-accessible"], ["type", "checkbox", 3, "focus", "blur", "change", "readonly", "value", "checked", "disabled"], [1, "p-checkbox-box", 3, "click", "ngClass"], [4, "ngIf"], [3, "class", "ngClass", "click", 4, "ngIf"], ["class", "p-checkbox-icon", 4, "ngIf"], ["class", "p-checkbox-icon", 3, "ngClass", 4, "ngIf"], [3, "styleClass", 4, "ngIf"], [1, "p-checkbox-icon", 3, "ngClass"], [3, "styleClass"], [1, "p-checkbox-icon"], [4, "ngTemplateOutlet"], [3, "click", "ngClass"]],
  template: function Checkbox_Template(rf, ctx) {
    if (rf & 1) {
      const _r1 = i0.ɵɵgetCurrentView();
      i0.ɵɵelementStart(0, "div", 1)(1, "div", 2)(2, "input", 3, 0);
      i0.ɵɵlistener("focus", function Checkbox_Template_input_focus_2_listener() {
        i0.ɵɵrestoreView(_r1);
        return i0.ɵɵresetView(ctx.onFocus());
      })("blur", function Checkbox_Template_input_blur_2_listener() {
        i0.ɵɵrestoreView(_r1);
        return i0.ɵɵresetView(ctx.onBlur());
      })("change", function Checkbox_Template_input_change_2_listener($event) {
        i0.ɵɵrestoreView(_r1);
        return i0.ɵɵresetView(ctx.handleChange($event));
      });
      i0.ɵɵelementEnd()();
      i0.ɵɵelementStart(4, "div", 4);
      i0.ɵɵlistener("click", function Checkbox_Template_div_click_4_listener($event) {
        i0.ɵɵrestoreView(_r1);
        const cb_r2 = i0.ɵɵreference(3);
        return i0.ɵɵresetView(ctx.onClick($event, cb_r2, true));
      });
      i0.ɵɵtemplate(5, Checkbox_ng_container_5_Template, 3, 2, "ng-container", 5);
      i0.ɵɵelementEnd()();
      i0.ɵɵtemplate(6, Checkbox_label_6_Template, 2, 9, "label", 6);
    }
    if (rf & 2) {
      i0.ɵɵclassMap(ctx.styleClass);
      i0.ɵɵproperty("ngStyle", ctx.style)("ngClass", i0.ɵɵpureFunction3(18, _c1, ctx.checked(), ctx.disabled, ctx.focused));
      i0.ɵɵadvance(2);
      i0.ɵɵproperty("readonly", ctx.readonly)("value", ctx.value)("checked", ctx.checked())("disabled", ctx.disabled);
      i0.ɵɵattribute("id", ctx.inputId)("name", ctx.name)("tabindex", ctx.tabindex)("aria-labelledby", ctx.ariaLabelledBy)("aria-label", ctx.ariaLabel)("aria-checked", ctx.checked())("required", ctx.required);
      i0.ɵɵadvance(2);
      i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction3(22, _c2, ctx.checked(), ctx.disabled, ctx.focused));
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngIf", ctx.checked());
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngIf", ctx.label);
    }
  },
  dependencies: () => [i1.NgClass, i1.NgIf, i1.NgTemplateOutlet, i1.NgStyle, CheckIcon],
  styles: [".p-checkbox{display:inline-flex;cursor:pointer;-webkit-user-select:none;user-select:none;vertical-align:bottom;position:relative}.p-checkbox-disabled{cursor:default!important;pointer-events:none}.p-checkbox-box{display:flex;justify-content:center;align-items:center}p-checkbox{display:inline-flex;vertical-align:bottom;align-items:center}.p-checkbox-label{line-height:1}\n"],
  encapsulation: 2,
  changeDetection: 0
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(Checkbox, [{
    type: Component,
    args: [{
      selector: 'p-checkbox',
      template: `
        <div [ngStyle]="style" [ngClass]="{ 'p-checkbox p-component': true, 'p-checkbox-checked': checked(), 'p-checkbox-disabled': disabled, 'p-checkbox-focused': focused }" [class]="styleClass">
            <div class="p-hidden-accessible">
                <input
                    #cb
                    type="checkbox"
                    [attr.id]="inputId"
                    [attr.name]="name"
                    [readonly]="readonly"
                    [value]="value"
                    [checked]="checked()"
                    (focus)="onFocus()"
                    (blur)="onBlur()"
                    (change)="handleChange($event)"
                    [disabled]="disabled"
                    [attr.tabindex]="tabindex"
                    [attr.aria-labelledby]="ariaLabelledBy"
                    [attr.aria-label]="ariaLabel"
                    [attr.aria-checked]="checked()"
                    [attr.required]="required"
                />
            </div>
            <div class="p-checkbox-box" (click)="onClick($event, cb, true)" [ngClass]="{ 'p-highlight': checked(), 'p-disabled': disabled, 'p-focus': focused }">
                
            <ng-container *ngIf="checked()">
                <ng-container *ngIf="!checkboxIconTemplate"> 
                    <span *ngIf="checkboxIcon" class="p-checkbox-icon" [ngClass]="checkboxIcon"></span>
                    <CheckIcon *ngIf="!checkboxIcon" [styleClass]="'p-checkbox-icon'"/>
                </ng-container>
                <span *ngIf="checkboxIconTemplate" class="p-checkbox-icon">
                    <ng-template *ngTemplateOutlet="checkboxIconTemplate"></ng-template>
                </span>
            </ng-container>
            </div>
        </div>
        <label
            (click)="onClick($event, cb, true)"
            [class]="labelStyleClass"
            [ngClass]="{ 'p-checkbox-label': true, 'p-checkbox-label-active': checked(), 'p-disabled': disabled, 'p-checkbox-label-focus': focused }"
            *ngIf="label"
            [attr.for]="inputId"
            >{{ label }}</label
        >
    `,
      providers: [CHECKBOX_VALUE_ACCESSOR],
      changeDetection: ChangeDetectionStrategy.OnPush,
      encapsulation: ViewEncapsulation.None,
      host: {
        class: 'p-element'
      },
      styles: [".p-checkbox{display:inline-flex;cursor:pointer;-webkit-user-select:none;user-select:none;vertical-align:bottom;position:relative}.p-checkbox-disabled{cursor:default!important;pointer-events:none}.p-checkbox-box{display:flex;justify-content:center;align-items:center}p-checkbox{display:inline-flex;vertical-align:bottom;align-items:center}.p-checkbox-label{line-height:1}\n"]
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }];
  }, {
    value: [{
      type: Input
    }],
    name: [{
      type: Input
    }],
    disabled: [{
      type: Input
    }],
    binary: [{
      type: Input
    }],
    label: [{
      type: Input
    }],
    ariaLabelledBy: [{
      type: Input
    }],
    ariaLabel: [{
      type: Input
    }],
    tabindex: [{
      type: Input
    }],
    inputId: [{
      type: Input
    }],
    style: [{
      type: Input
    }],
    styleClass: [{
      type: Input
    }],
    labelStyleClass: [{
      type: Input
    }],
    formControl: [{
      type: Input
    }],
    checkboxIcon: [{
      type: Input
    }],
    readonly: [{
      type: Input
    }],
    required: [{
      type: Input
    }],
    trueValue: [{
      type: Input
    }],
    falseValue: [{
      type: Input
    }],
    inputViewChild: [{
      type: ViewChild,
      args: ['cb']
    }],
    onChange: [{
      type: Output
    }],
    templates: [{
      type: ContentChildren,
      args: [PrimeTemplate]
    }]
  });
})();
class CheckboxModule {}
CheckboxModule.ɵfac = function CheckboxModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || CheckboxModule)();
};
CheckboxModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: CheckboxModule
});
CheckboxModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [CommonModule, CheckIcon, SharedModule]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CheckboxModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, CheckIcon],
      exports: [Checkbox, SharedModule],
      declarations: [Checkbox]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { CHECKBOX_VALUE_ACCESSOR, Checkbox, CheckboxModule };

