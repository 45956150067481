export class DisableModel {
    constructor() {
      this.actionName = this.actionOwner = this.actionType = this.activities = this.targetDate =
        this.interestedParties = this.description = this.activities = this.assign = this.actionCreator = true;
    }
    actionName: boolean;
    actionType: boolean;
    targetDate: boolean;
    actionOwner: boolean;
    actionCreator: boolean;
    interestedParties: boolean;
    description: boolean;
    activities: boolean;
    assign: boolean;
  }