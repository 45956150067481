import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { AdminHubsService } from 'src/app/services/admin-hubs.service';

@Component({
  selector: 'app-hub-structure-popup',
  templateUrl: './hub-structure-popup.component.html',
  styleUrls: ['./hub-structure-popup.component.css']
})

export class HubStructurePopupComponent implements OnInit {

  @Input() state: any;

  hubStructureForm!: FormGroup;
  data: any;
  receivingbuCode: any;
  changeHubUsage: any;
  changesToPg: boolean;

  constructor(
    private fb: FormBuilder,
    private readonly dialogRef: DynamicDialogRef,
    private adminHubsService: AdminHubsService,
    private dynamicDialogConfig: DynamicDialogConfig
  ) { }

  ngOnInit(): void {
    this.hubStructureForm = this.fb.group({
    });

    this.receivingbuCode = this.dynamicDialogConfig.data.state
    this.changesToPg = this.dynamicDialogConfig.data.buCode;
  }

  confirm() {

    let data={
      "buCode": this.receivingbuCode.buCode,
      "changeTo": !this.dynamicDialogConfig.data.buCode
    }

    this.adminHubsService.getChangeHubUsage(data).subscribe((res) => {
      this.changeHubUsage = res;
    })
    this.dialogRef.close(data);
  }

  onClose() {
    this.dialogRef.close('cancel');
  }

}
