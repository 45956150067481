import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sumFloat'
})
export class SumFloatPipe implements PipeTransform {

  transform(value: any[]): any {

    let totalSize = 0;
    for(let i=0; i<value.length; i++)
    {
      totalSize = totalSize + value[i].size;
    }

    return totalSize;
  }

}
