import { Pipe, PipeTransform } from '@angular/core';
import { orderBy } from 'lodash';

@Pipe({
  name: 'orderByCards'
})
export class OrderByCardsPipe implements PipeTransform {

  transform(data: any[], key: string): any[] {
    if(key === '-isRejected')
    {
      return orderBy(data, 'isRejected', 'desc');
    }
    if(key === '-isReopened')
    {
      return orderBy(data, 'isReopened', 'desc');
    }
    return data;
  }

}
