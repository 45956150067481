import { Component, OnInit } from '@angular/core';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { FailuremodeService } from '../failuremode.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-failuremode-deactivatemodal',
  templateUrl: './failuremode-deactivatemodal.component.html',
  styleUrls: ['./failuremode-deactivatemodal.component.css']
})
export class FailuremodeDeactivatemodalComponent implements OnInit {

  isSaving:boolean = false;
  item:any;
  pgCode:any;

  constructor(public ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private failureModeService:FailuremodeService){
    }

  ngOnInit(): void {
    this.item = this.config.data.pgCategoryItem;
    this.pgCode = this.config.data.pgCode;
  }

  cancel(){
    this.ref.close({result : 'none'});
  }

  ok() {
   this.isSaving = true;
   this.item.isActive = false;
  this.failureModeService.deactivate(this.item,this.pgCode).subscribe(
    (data:any)=>{
      if (!_.isNull(data) && !data.isError) {
        this.isSaving = false;
        this.ref.close({
            result: 'ok',
            model: this.item,
            results: data.queryResults
        });
    } else {
        this.isSaving = false;
        this.ref.close({
            msg: data.message
        });
    }
    },
    (err)=>{

    }
  )

};

}
