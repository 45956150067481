<form [formGroup]="acccounableEdittreeForm">
    <div class="editPopup">
    <div class="row div-align-items-right">
        <div class="col-sm-4 mt-2 accountablePersonEditPopupLabel label-bold">
            {{'rupersonadd_rucselectedrus_mod_lbl'|translate}}
        </div>
        <div class="col-sm-8">
            <input readonly=true type="text" class="disabled form-control accountablePersonEditPopupInput"
                value=" {{data.cardinfo.balId}}, {{data.cardinfo.divCode}}, {{data.cardinfo.buCode}},{{data.cardinfo.pgCode}}, {{data.cardinfo.address.countryCode}} " />
        </div>

    </div>

    <div class="row mt-2 div-align-items-right">
        <div class="col-sm-4 mt-2 accountablePersonEditPopupLabel label-bold">
            Selected Process steps
        </div>
        <div class="col-sm-8">
            <input readonly=true type="text" class="disabled form-control accountablePersonEditPopupInput" value="{{data.cardData.name}}" />
        </div>
    </div>

    <div class="row mt-2">
        <div class="col-sm-12 accountablePersonFont">
            <strong>Added Accountable Person(s):</strong>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-12 accountablePersonFont">
            <div>
                <ul style="list-style-type:none" *ngFor="let c of getacountablePerson; index as i">
                    <li style="margin-left:10px;margin-top:10px;">
                        <input type="checkbox" [formControlName]="c.geid" (change)="checkval($event)"/>
                        {{c.accoutablePersonFirstName}} {{c.accoutablePersonLastName}}
                    </li>
                </ul>
            </div>
        </div>
    </div>


    <div class="buton" style="text-align: right;">
        <button type="button" class="widgetButtonLarge"  (click)="onClose()"
        style="float: right; margin-left: 5px;">CANCEL</button>

        <button type="button" class="widgetButtonLarge "   (click)="onClicksave()"
            style="float: right; margin-left: 5px;">{{'admin_process_steps_addedit_save_btn_lb' | translate |uppercase}}</button>
    </div>
  </div>
</form>
