import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TreeNode } from 'primeng/api';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FailuremodeService {
  constructor(private http: HttpClient) {}

  // getFiles(data): Observable<any> {
  //   return this.http
  //     .post<TreeNode>(`${environment.azure.baseUrl}Data/GetFailureModes`,data);
  // }
  getFailureMode(data) {
    return this.http
      .post<TreeNode>(`${environment.azure.baseUrl}api/v1/Data/GetFailureModes?resolvingUnitId=${data.resolvingUnitId}`,data)
      .toPromise()
      .then((res) => <TreeNode[]>res);
  }
  getFailureModeSearch() {
    // const parendId = {parentId: ''}
    return this.http
      .get<TreeNode>(`${environment.azure.baseUrl}api/v1/Popups/failuremodesearch`)
      .toPromise()
      .then((res) => <TreeNode[]>res);
  }

  getfailureModebyId(parentId, resolvingUnitId) {
    return this.http
      .post<TreeNode>(`${environment.azure.baseUrl}api/v1/Data/GetFailureModes?parentId=` + parentId + `&resolvingUnitId=` + resolvingUnitId, parentId)
      .toPromise()
      .then((res) => <TreeNode[]>res);
  }

  getFilteredProduct(failureModeData) {
      return this.http.post(`${environment.azure.baseUrl}api/v1/Data/GetFilteredFailureModeTree?filterText=` + failureModeData?.filterText + `&resolvingUnitId=` + failureModeData?.resolvingUnitId, failureModeData?.parentId);
  }

}
