import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { HubStructurePopupComponent } from '../hub-structure-popup/hub-structure-popup.component';
import { HubAddPersonPopupComponent } from '../hub-add-person-popup/hub-add-person-popup.component';
import { HubDeletePersonPopupComponent } from '../hub-delete-person-popup/hub-delete-person-popup.component';
import { AdminHubsService } from 'src/app/services/admin-hubs.service';
import { ResolvingUnitServiceService } from 'src/app/services/resolving-unit-service.service';
import { forEach, sortBy } from 'lodash';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import * as _ from 'lodash';

@Component({
  selector: 'app-hubs',
  templateUrl: './hubs.component.html',
  styleUrls: ['./hubs.component.css']
})
export class HubsComponent implements OnInit {
  @Input('flagFromSearch') flagFromSearch: boolean = true;
  @Output() sendBuCode: EventEmitter<any> = new EventEmitter<any>();

  HubincativeTooltipInfo = 'Hub Inactive';
  showProcessOwnerTooltip = 'Show Process Owner'
  deleteProcessOwnerTooltipInfo = 'Delete Process Owner'
  cantAbleToDeleteProcessOwner = 'Cannot delete the only Hub Process Owner'

  hubForm!: FormGroup;
  filteredCustomer: any;
  division: any;
  bu: any;
  pg: any;
  country: any;
  divisionAll: any;
  collapsed = false
  accButtonText = 'Expand All';
  private _isExpanded = false;
  expandIndex: any = [0, 1];
  filteredHubDetails: any;
  processOwnerAssigned: any;
  hubStatus: any;
  hubPersonInfo: any;
  findProcessOwner: any;
  IsHubsExcelGenerating: boolean = false;
  objdata: Array<any> = [];
  employeeInfo: any;
  getrubsObjinfo: any;
  hideshow: boolean;
  filteredprocessownersDetails: any = [];
  filteredGeId: any = [];
  employeeAllDetails: any = [];
  BUHubUsage: any;
  radioPOAssignedStatus: boolean = null;
  showExportToExcel: boolean = false;
  radioHubStatus: boolean = true;
  hubsForProcessOwner: Array<any> = []
  iscountprocessorowner: number;
  getRes: any;
  hubPushedData: any = [];
  hubDataArray: any = [];
  indexedArray: any = [];
  noResult = false;
  noResultInfo: any;
  filterSearchFinalRecord: any;
  totalRecords: number;
  enableSearchButton = true;

  currentPage = 1;
  pagesCount = 0;
  numberOfCardsPerPage = 10;
  hubDetailsAsPerPagination: any = [];

  btnColorChange0 = true;
  btnColorChange1 = false;
  btnColorChange2 = false;
  btnColorChange3 = false;
  btnColorChange4 = true;
  btnColorChange5 = false;
  getProcesOwnerhoverdata: any;
  updatedFilteredDetails: any = [];
  showSelectHub: boolean = false;

  constructor(
    private fb: FormBuilder,
    private dialogService: DialogService,
    private ref: DynamicDialogRef,
    private resolvingUnitService: ResolvingUnitServiceService,
    private adminHubService: AdminHubsService,
    private breadcrumService: BreadcrumbService,
    private spinner: NgxSpinnerService,
    private router: Router) { }

  ngOnInit(): void {
    const CurrentURL = this.router.url;
    if (CurrentURL != '/search/hubProcessOwner') {
      this.breadcrumService.menuItems = [
        { label: 'ADMIN PANEL', routerLink: 'admin' },
        { label: 'MANAGE HUBS' }
      ];
    }

    this.hubForm = this.fb.group({
      'toggleHubStructure': [''],
      'business': ['PG'],
      'country': [''],
      'hubProcessOwner': [''],
      'bu': [''],
      'belongCountries': [''],
      'isAnyPOAssigned': [''],
      'pg': [''],
      'hubStatus': [''],
      'checkBox': [''],
      "hubIds": this.fb.array([]),
      "hubProcessOwnerGeids": this.fb.array([])
    })

    this.adminHubService.getBuHubUsage().subscribe((res) => {
      this.BUHubUsage = res;
      this.BUHubUsage.map((rr) => {
        rr.busInfo.map((a) => {
          this.addCheckBoxes(a.buCode, a.usesPgHubs);
        })
      })
    })

    this.adminHubService.getDivisionsAllList().subscribe((res) => {
      this.divisionAll = res;
    })

    this.resolvingUnitService.getDivision().subscribe((response) => {
      if (response) {
        this.division = response;
      }
    })

    const division = 'PG';
    this.resolvingUnitService.getBuList(division).subscribe((res) => {
      if (res) {
        this.bu = res?.data.sort((a, b) => a.key.localeCompare(b.key));
        this.bu.map((rr: any) => {
          rr.name = rr?.value
          rr.pgName = rr?.name
        })
      }
    })

    this.resolvingUnitService.getPgForDivisionAndBu(this.hubForm.get('business').value, '').subscribe((response:any) => {
      if (response) {
        this.pg = response.sort((a, b) => a.key.localeCompare(b.key));
      }
    })

    this.hubForm.get('bu').valueChanges.subscribe((res) => {
      this.hubForm.controls['pg'].reset();
      this.resolvingUnitService.getPgForDivisionAndBu('PG', res).subscribe((response) => {
        if (response) {
          this.pg = response;
          this.showSelectHub = false;
        }
      })
    })

    this.resolvingUnitService.getAllCountry().subscribe((response) => {
      this.country = response;
      this.country.map((data) => {
        data.name = '(' + data.id + ')' + ' ' + '-' + ' ' + data.name;
      })
    })
  }

  showSelectedHubCriteria() {
    this.hubForm.controls['bu'].reset();
    this.hubForm.controls['pg'].reset();
    this.showSelectHub = false
    if (this.hubForm.controls['business'].value == '' || this.hubForm.controls['business'].value == null) {
      this.showSelectHub = true;
    }
  }

  addCheckBoxes(value: string, setValue) {
    const control = new FormControl(setValue);
    this.hubForm.addControl(value, control);
  }

  public get isExpanded() {
    return this._isExpanded;
  }

  public set isExpanded(value: boolean) {
    this._isExpanded = value;
  }

  toggleAllAccordions() {
    if (this.expandIndex.length == 0) {
      this.expandIndex = [0, 1];
    } else {
      this.expandIndex = [];
    }
    this.accButtonText = this.expandIndex.length ? 'Collapse All' : 'Expand All';
  }

  hubCheckBox(state) {
    if (state.hasHubsEnabled == true) {
      return true;
    }
    else {
      return false;
    }
  }

  hugExclamationSign(state) {
    if (state.hasHubsEnabled == false) {
      return true;
    }
    else {
      return false;
    }
  }

  showHubStructurePopup(state: any) {
    this.ref = this.dialogService.open(HubStructurePopupComponent, {
      header: 'HUB Structure',
      width: '50%',
      position: 'top',
      keepInViewport: true,
      autoZIndex: true,
      styleClass: 'structure-pop',
      data: { state, buCode: this.hubForm.controls[state.buCode]?.value }
    });

    this.ref.onClose.subscribe((receivedValue: any) => {
      if (receivedValue == undefined) {
        this.sendBuCode.emit(state);
        const buCodeValue = this.hubForm.controls[state.buCode]?.value;
        this.hubForm.controls[state.buCode].patchValue(!buCodeValue);
        this.adminHubService.setObject(state);
      }
      else if (receivedValue == 'cancel') {
        this.sendBuCode.emit(state);
        const buCodeValue = this.hubForm.controls[state.buCode]?.value;
        this.hubForm.controls[state.buCode].patchValue(!buCodeValue);
        this.adminHubService.setObject(state);
      }
      else if (receivedValue?.buCode) { }
    })
  }

  get hubIds(): FormArray {
    return this.hubForm.get('hubIds') as FormArray
  }

  get hubProcessOwnerGeids(): FormArray {
    return this.hubForm.get('hubProcessOwnerGeids') as FormArray
  }

  checkselectedval(event: any, data: any, ind: any) {
    if (event.target.checked == false) {
      data.selectedCheck = false;
      const index: number = this.hubPushedData.findIndex((e) => e.id == data.id);
      if (index !== -1) {
        this.hubPushedData.splice(index, 1);
      }

      const indexHubData: number = this.hubDataArray.findIndex((e)=> e.id == data.id);
      if(indexHubData !== -1){
        this.hubDataArray.splice(indexHubData, 1);
      }
    }
    if (event.target.checked == true) {
      data.selectedCheck = true;
      this.hubIds.push(new FormControl(data.id))
      const selectedIndex = this.hubPushedData.findIndex((h) => h.id == data.id);
      if (selectedIndex == -1) {
        this.indexedArray.push(selectedIndex);
        this.hubPushedData.push({ hubsData: data.divCode + ', ' + data.buCode + ', ' + data.leadCountryName, id: data.id });
      }

      const selectIndexHubData = this.hubDataArray.findIndex((h)=> h.id == data.id);
      if(selectIndexHubData == -1){
        this.hubDataArray.push(data);
      }
    }
  }

  showHubAddPersonPopup(hubData, filteredHubDetails, index) {
    hubData.selectedCheck = true;
    this.hubIds.push(new FormControl(hubData.id))
    const selectedIndex = this.hubPushedData.findIndex((h) => h.id == hubData.id);
    if (selectedIndex == -1) {
      this.indexedArray.push(selectedIndex);
      this.hubPushedData.push({ hubsData: hubData.divCode + ', ' + hubData.buCode + ', ' + hubData.leadCountryName, id: hubData.id });
    }

    const selectedIndexHubDataArray = this.hubDataArray.findIndex((h)=> h.id == hubData.id);
    if(selectedIndexHubDataArray == -1){
      this.hubDataArray.push(hubData);
    }

    this.ref = this.dialogService.open(HubAddPersonPopupComponent, {
      header: 'Add Hub person(s)',
      width: '68%',
      position: 'top',
      keepInViewport: true,
      autoZIndex: true,
      styleClass: 'addPersonPopup',
      data: {
        //cardinfo: hubData,
        cardinfo: this.hubDataArray,
        hubIds: _.uniq(this.hubIds.value),
        hubArray: filteredHubDetails,
        selectedHubs: this.hubPushedData
      }
    });
    this.ref.onClose.subscribe((recevied) => {
      if (recevied) {
        this.hubForm.controls['checkBox'].reset();
        this.getSearchDetails()
      }
    })
  }

  showHubDeletePersonPopup(po, hubData) {
    this.ref = this.dialogService.open(HubDeletePersonPopupComponent, {
      header: 'Delete person',
      width: '40%',
      position: 'top',
      keepInViewport: true,
      autoZIndex: true,
      styleClass: 'deletePersonPopup',
      data: {
        empinfo: po,
        cardinfo: hubData,
        hubsData: this.filteredHubDetails
      }
    });
    this.ref.onClose.subscribe((recevied) => {
      if (recevied) {
        this.getSearchDetails()
      }
    })
  }

  filterCustomerDetails(event) {
    const dataPasses = {
      wildcard: event.originalEvent.target.value,
      onlyActive: false,
      page: 1,
      size: 26
    };
    if (event.originalEvent.target.value.length > 1) {
      this.adminHubService.getHubPOUser(dataPasses).subscribe((response) => {
        this.filteredCustomer = response;
      })
    }
  }

  onClickIrrelevant() {
    this.radioPOAssignedStatus = null;
    this.btnColorChange0 = true;
    this.btnColorChange1 = false;
    this.btnColorChange2 = false;
  }

  onClickYes() {
    this.radioPOAssignedStatus = true;
    this.btnColorChange0 = false;
    this.btnColorChange1 = true;
    this.btnColorChange2 = false;
  }
  onClickNo() {
    this.radioPOAssignedStatus = false;
    this.btnColorChange0 = false;
    this.btnColorChange1 = false;
    this.btnColorChange2 = true;
  }
  onClickAll() {
    this.radioHubStatus = null;
    this.btnColorChange3 = true;
    this.btnColorChange4 = false;
    this.btnColorChange5 = false;
  }
  onClickActive() {
    this.radioHubStatus = true;
    this.btnColorChange3 = false;
    this.btnColorChange4 = true;
    this.btnColorChange5 = false;
  }
  onClickInActive() {
    this.radioHubStatus = false;
    this.btnColorChange3 = false;
    this.btnColorChange4 = false;
    this.btnColorChange5 = true;
  }

  reset() {
    this.hubForm.controls['business'].setValue('PG')
    this.hubForm.controls['country'].reset()
    this.hubForm.controls['hubProcessOwner'].reset()
    this.hubForm.controls['bu'].reset()
    this.hubForm.controls['belongCountries'].reset()
    this.hubForm.controls['pg'].reset()
    this.btnColorChange0 = true;
    this.btnColorChange1 = false;
    this.btnColorChange2 = false;
    this.btnColorChange3 = false;
    this.btnColorChange4 = true;
    this.btnColorChange5 = false;
    this.showExportToExcel = false;
    this.noResult = false;
    this.hubDetailsAsPerPagination = [];
    this.hubPushedData = [];
    this.showSelectHub = false;
  }

  getSearchDetails() {
    this.hubDataArray = [];
    this.hubIds.clear();
    this.hubPushedData = [];
    this.hubForm.controls['checkBox'].reset();

    this.spinner.show();
    let formatedValue = {
      "division": this.checkBlank(this.hubForm.controls['business'].value),
      "bu": this.checkBlank(this.hubForm.controls['bu'].value),
      "pg": this.checkBlank(this.hubForm.controls['pg'].value),
      "leadCountryCode": this.checkBlank(this.hubForm.controls['country'].value),
      "belongingCountryCodes": this.checkBlankForBelongCountries(this.hubForm.controls['belongCountries'].value),
      "hubProcessOwner": this.hubForm.controls['hubProcessOwner'].value ? this.hubForm.controls['hubProcessOwner'].value : "",
      "processOwnerAssigned": this.radioPOAssignedStatus == null ? null : this.radioPOAssignedStatus,
      "hubStatus": this.radioHubStatus == null ? null : this.radioHubStatus
    }

    this.adminHubService.getSearchHubDetails1(formatedValue).then((res: any) => {

      this.noResult = false;
      this.totalRecords = res.hubInfo.length;
      this.hubPushedData = [];

      // res.hubInfo.forEach(item => {
      //   item.scope = { div: item.divCode, bu: item.buCode, leadCountryName: item.leadCountryName }
      //   item.canManage = this.hubAuthService.canManageHub(item.scope, item.hubProcessOwnerGeids);
      //   item.isSelected = false;
      // });

      // const geid = [];
      // res.hubInfo.forEach(element => {
      //   element.hubProcessOwnerGeids.forEach((allGeid) => {
      //     geid.push(allGeid)
      //   })
      // });

      // this.adminHubService.getHubPersonInfo(_.uniq(geid)).subscribe((res1) => {
      //   debugger
      //   res.hubInfo.forEach(item => {
      //     let hubProcessOwners : any = [];
      //     item.hubProcessOwnerGeids.forEach(itemGeid => {
      //       let processOwner = _.find(res1, function(item: any){
      //         return itemGeid == item.id;
      //       })
      //       hubProcessOwners.push(processOwner);
      //     });
      //     item.hubProcessOwners = hubProcessOwners;
      //   });
      //   this.hubPersonInfo = res1
      //   this.hubPersonInfo = sortBy(this.hubPersonInfo, ['name']);
      //   this.spinner.hide();
      // })

      this.filteredHubDetails = res.hubInfo;
      this.updatedFilteredDetails = this.filteredHubDetails;
      this.updatedCards();
      this.pagesCount = Math.round(this.totalRecords / this.numberOfCardsPerPage);

      if (this.totalRecords != 0) {
        this.showExportToExcel = true;
      }

      if (this.filteredHubDetails == '' || this.filteredHubDetails == null) {
        this.noResult = true;
        this.showExportToExcel = false;
        this.noResultInfo = 'No results found';
      }

      for (let i = 0; i < this.filteredHubDetails.length; i++) {
        this.filteredHubDetails[i]["showContacts"] = false;
        this.filteredHubDetails[i]["selectedCheck"] = false;
      }

      const geid = [];
      res.hubInfo.forEach(element => {
        element.hubProcessOwnerGeids.forEach((allGeid) => {
          geid.push(allGeid)
        })
      });

      this.adminHubService.getHubPersonInfo(geid).subscribe((res) => {
        this.hubPersonInfo = res
        this.hubPersonInfo = sortBy(this.hubPersonInfo, ['name']);
        this.spinner.hide();
      })

      this.getProcessOwnerData();

    })
  }

  getProcessOwnerData() {
    this.spinner.show();
    try {
      for (let i = 0; i < this.hubDetailsAsPerPagination.length; i++) {
        let objdata = {
          "div": this.hubDetailsAsPerPagination[i].divCode,
          "bu": this.hubDetailsAsPerPagination[i].buCode
        }
        this.adminHubService.getFindProcessOwner(objdata).subscribe(async res => {
          this.filteredprocessownersDetails = [...this.filteredprocessownersDetails, { res: res, index: i }]

          if (this.filteredprocessownersDetails.length == this.hubDetailsAsPerPagination.length) {
            this.filteredprocessownersDetails?.map((r, i) => {
              this.filteredGeId = [...this.filteredGeId, { geid: r.res.map((rer) => rer.geid), index: r.index }]
            })
            await this.filteredGeId.map((value, ind) => {
              this.adminHubService.getEmployeeInfo(value.geid).subscribe(async res => {
                await this.employeeData(res, value);
              })
            })
          }
        })
        this.spinner.hide();
      }
    }
    catch (e) {
      this.spinner.hide();
    }
  }

  employeeData(res, value) {
    this.employeeAllDetails = [...this.employeeAllDetails, { res: res, index: value.index }];
  }

  hubEmployeeInfo(index) {
    const find = this.employeeAllDetails.find((f, i) => f.index == index)
    return find?.res
  }

  getprocessownersDetails(value) {
    if (value?.div != null) {
      return value?.div;
    }
    else if (value?.bu != null) {
      return value?.bu;
    }
    else {
      return value?.pg;
    }
  }

  hubDataProcess(hubsObj, hubsId) {
    if (hubsId.includes(hubsObj.displayGeid) || hubsId.includes(hubsObj.id)) {
      return hubsObj.name;
    }
    else {
      return ''
    }
  }

  hubDataIconCheck(hubsObj, hubsId) {
    if (hubsId.includes(hubsObj.displayGeid) || hubsId.includes(hubsObj.id)) {
      return true;
    }
    else {
      return false;
    }
  }

  hubPersonDelete(po, hubData) {
    if (hubData.hubProcessOwnerGeids.includes(po.displayGeid)) {
      if (hubData.hubProcessOwnerGeids.length == 1) {
        return true;
      }
      else {
        return false;
      }
    }
    else {
      return false;
    }
  }

  hubDataIconbinCheck(po, hubData) {
    if (hubData.hubProcessOwnerGeids.includes(po.displayGeid) || hubData.hubProcessOwnerGeids.includes(po.id)) {
      if (hubData.hubProcessOwnerGeids.length > 1) {
        return true;
      }
      else {
        return false;
      }
    }
    else {
      return false;
    }
  }

  hubDataPgCodeCheck(hubData) {
    if (hubData.pgCodes != '') {
      return true;
    }
    else {
      return false;
    }
  }

  showProcessOwners(hubData) {
    if (hubData.showContacts == false) {
      hubData.showContacts = true;
    }
    else {
      hubData.showContacts = false;
    }
  }

  checkBlank(content) {
    if (content == undefined || content == null) {
      return null;
    } else {
      return content;
    }
  }

  checkBlankForBelongCountries(content) {
    if (content == undefined || content == null || content == '') {
      return [];
    } else {
      return content;
    }
  }

  clickExportToExcel() {
    this.IsHubsExcelGenerating = true;
    this.adminHubService.getExportHubs({
      "division": this.checkBlank(this.hubForm.controls['business'].value),
      "bu": this.checkBlank(this.hubForm.controls['bu'].value),
      "pg": this.checkBlank(this.hubForm.controls['pg'].value),
      "leadCountryCode": this.checkBlank(this.hubForm.controls['country'].value),
      "belongingCountryCodes": this.checkBlankForBelongCountries(this.hubForm.controls['belongCountries'].value),
      "hubProcessOwner": this.checkBlank(this.hubForm.controls['hubProcessOwner'].value),
      "processOwnerAssigned": this.radioPOAssignedStatus == null ? null : this.radioPOAssignedStatus,
      "hubStatus": this.radioHubStatus == null ? null : this.radioHubStatus
    }).subscribe((response: ArrayBuffer) => {
      const blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const downloadLink = document.createElement('a');
      downloadLink.href = window.URL.createObjectURL(blob);
      downloadLink.download = 'HubProcessOwners';
      downloadLink.click();
      this.IsHubsExcelGenerating = false;
    });
  }

  // code for tooltip
  gethubDataProcess(empInfo, cardInfo) {
    this.getrubsObjinfo = empInfo;
    //this.iscountprocessorowner = empInfo.hubProcessOwnerIds.length;
    this.hubsForProcessOwner = [];
    empInfo.hubProcessOwnerIds.forEach(hubId => {
      this.filteredHubDetails.forEach(element => {
        if (element.id == hubId) {
          this.hubsForProcessOwner.push(element.hubCode)
        }
      });
    });

    if (this.hubsForProcessOwner.length > 0) {
      this.iscountprocessorowner = this.hubsForProcessOwner.length;
    }
  }

  getProcessOwnerclick(id, index) {
    this.getProcesOwnerhoverdata = '';
    const find = this.employeeAllDetails.find((f) => f.index == index)
    if (find) {
      for (let i = 0; i < find.res.length; i++) {
        if (find.res[i].id == id) {
          this.getProcesOwnerhoverdata = (find.res[i]);
        }
      }
    }
  }

  // code for pagination

  updatedCards() {
    const startIndex = (this.currentPage - 1) * this.numberOfCardsPerPage;
    const endIndex = startIndex + this.numberOfCardsPerPage;
    this.hubDetailsAsPerPagination = this.updatedFilteredDetails.slice(startIndex, endIndex);
    this.getProcessOwnerData();
  }

  previousPage() {
    if (this.hasPrevious()) {
      return this.currentPage - 1;
    }
    return 0;
  };

  hasPrevious() {
    return this.currentPage > 1;
  };

  previousClicked() {
    if (this.hasPrevious()) {
      this.currentPage--;
      this.updatedCards();
    }
  };

  gotoPage(page) {
    this.currentPage = page;
    this.updatedCards();
  }

  showFirstPageSeparator() {
    return this.currentPage > 3;
  };

  hasNext() {
    return this.currentPage < this.pagesCount;
  };

  nextPage() {
    if (this.hasNext()) {
      return this.currentPage + 1;
    }
    return 0;
  };

  showFirstPage() {
    return this.hasPrevious() && this.previousPage() > 1;
  };

  showLastPageSeparator() {
    var result = this.hasNext() && this.nextPage() < this.pagesCount - 1;
    return result;
  };

  showLastPage() {
    return this.hasNext() && this.nextPage() < this.pagesCount;
  };

  nextClicked() {
    if (this.hasNext()) {
      this.currentPage++;
      this.updatedCards();
    }
  };

}

