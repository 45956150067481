import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { DialogService, DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-submit-confirmation-popup',
  templateUrl: './submit-confirmation-popup.component.html',
  styleUrls: ['./submit-confirmation-popup.component.css']
})
export class SubmitConfirmationPopupComponent implements OnInit {
  public classData: String;
  confidentialFlag:boolean = false;

  constructor(
    public config: DynamicDialogConfig,
    public ref: DynamicDialogRef,
    public dialogService: DialogService,)
    {


  }

  ngOnInit(): void {
      this.classData = this.config.data.classData.matrixClass;
      this.confidentialFlag = this.config.data?.confidential;
  }
  //closing the popup/cancel button
  onClose() {
    this.ref.close();
  }

  confirm() {
    this.ref.close(true);
  }

}
