import { Injectable } from '@angular/core';
import { SessionStorageServiceService } from 'src/app/services/session-storage-service.service';

@Injectable({
  providedIn: 'root'
})
export class TransalationAuthService {

  userInfo: any;

  constructor(private sessionStorageService: SessionStorageServiceService) {
    this.userInfo = this.sessionStorageService.getData('userInfo');
  }

  canManageTranslations() {
    if (this.userInfo.hasGlobalPermissions || this.userInfo.isSupportDeskMember || this.userInfo.isResourceEditor) {
      return true;
    }

    return false;
  }
  canEditMessageRecipients() {
    if (this.userInfo.hasGlobalPermissions || this.userInfo.isSupportDeskMember) {
      return true;
    }

    return false;
  };
}
