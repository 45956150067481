import { Component, OnInit } from '@angular/core';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { AdminCustomerContactsService } from 'src/app/services/admin-customer-contacts.service';
import { SessionStorageServiceService } from 'src/app/services/session-storage-service.service';

@Component({
  selector: 'app-adminhome',
  templateUrl: './adminhome.component.html',
  styleUrls: ['./adminhome.component.css']
})
export class AdminhomeComponent implements OnInit {
  userInfo: any;
  constructor(
  public breadcrumbService: BreadcrumbService,
  private admLoadService:AdminCustomerContactsService,
  private sessionStorage: SessionStorageServiceService
  ) {
  }

  ngOnInit(): void {
    this.breadcrumbService.menuItems = [
      {label: 'ADMIN'}
    ];

    this.admLoadService.getMetadata().subscribe((response)=>{
      const metaData = response;
    })

    setTimeout(() => {
        this.userInfo = this.sessionStorage.getData('userInfo');
    }, 1000)
  }

  checkAccessPermission(accessCode) {
    return this.userInfo?.accessPermissions?.includes(accessCode);
  }


}
