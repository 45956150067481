import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { LazyLoadEvent, PrimeNGConfig } from 'primeng/api';
//import { IssueService } from './issue.service';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { IssueSelectionService } from 'src/app/services/issue-selection.service';

@Component({
  selector: 'app-issue-selection',
  templateUrl: './issue-selection.component.html',
  styleUrls: ['./issue-selection.component.css']
})
export class IssueSelectionComponent implements OnInit {
  @Output() classOriginalValue = new EventEmitter();
  first: number = 0;
  firstIndex: number = 0;
  rows: number = 10;

  issueSelectionForm!: FormGroup;
  textboxEnabled = false;
  searchText: any;
  radioValues = 'Search by business activity location';
  isChecked: boolean = false;

  filteredCompanyDetails: any;
  newArray: any = [];
  selectedIndex = -1;
  selectedCountry: String = "";
  states: Array<any> = [];
  cities: Array<any> = [];
  companyDetails: Array<any> = [];
  selectedBu: String = "";
  pgName: Array<any> = [];
  country!: string;
  city!: string;
  buPgList: any;
  getCityList: any;
  getCountryList: any;
  activityDropdownData: any;
  totalRecords: any = 0;
  dynamicPageNumber: any;
  loading: boolean = false;
  divisionData: any;
  selectedRCAData: any;
  complaintNo : any;
  resolvingUnit: any;
  constructor(
    private fb: FormBuilder,
    private issueSelectionService: IssueSelectionService,
    private primengConfig: PrimeNGConfig,
    private ref: DynamicDialogConfig,
    private readonly dialogRef: DynamicDialogRef) {
      this.complaintNo = this.ref.data.complaintNo;
      this.resolvingUnit = this.ref.data?.resolvingUnit;
    }

  ngOnInit(): void {
    this.issueSelectionForm = this.fb.group({
      CCRPNo: [''],
      business: ['PG'],
      country: [''],
      bu: [''],
      city: [''],
      pg: [''],
      activity: ['']
    });

    let loadData = {
      "UserData": {
        "complaintNo": this.complaintNo,
        "resolvingUnit": this.resolvingUnit
      },
      "take": 1000,
      "skip": 0,
      "page": 1,
      "pageSize": 10,
      "group": []
    }

    const division = 'PG';

    this.issueSelectionService.getDivision().subscribe((response) => {
      this.divisionData = response;
    });

    this.issueSelectionService.getBuList(division).subscribe((res) => {
      if (res) {
        this.buPgList = res?.data;
        this.buPgList.map((rr: any) => {
          rr.name = rr?.value
          rr.pgName = rr?.name
        })
      }
    })

    this.issueSelectionService.getAllCountry().subscribe((country) => {
      if (country) {
        this.getCountryList = country;
      }
    });

    this.issueSelectionForm.get('country')?.valueChanges.subscribe((newValue) => {
      const countryCode = newValue;
      this.issueSelectionService.getAllCity(countryCode).subscribe((cities) => {
        if (cities) {
          this.getCityList = cities;
        }
      });
    })

    const pg = 'PG';
    this.issueSelectionService.getActivity(pg).subscribe((resp) => {
      if (resp) {
        this.activityDropdownData = resp;
      }
    });

    this.primengConfig.ripple = true;
    this.issueSelectionForm.get('country')?.valueChanges.subscribe((value) => {
      this.issueSelectionForm.get('city')?.reset();
    })


    this.issueSelectionService.getComplaintWithRCA(loadData).subscribe((res: any) => {
      this.filteredCompanyDetails = res?.data
      this.totalRecords = res.count;
    })
  }

  pgChangeData(count: any) {
    this.issueSelectionForm.controls['pg'].reset();
    if (this.selectedBu == "") {
      this.pgName = [];
    }

    const division = 'PG';
    const buValue = this.issueSelectionForm.controls['bu'].value;
    this.issueSelectionService.getBuPgList(division, buValue).subscribe((res) => {
      if (res) {
        this.pgName = res.data;
      }
    })
  }

  loadMoreComplaintRCAData(event: any) {
    let skipRecord = 0;
    const pageNumber = (event.first + 10) / 10;
    skipRecord += event.first;
    this.loading = true;
    this.viewRuPopUpDetails(pageNumber, skipRecord);
  }

  viewRuPopUpDetails(pageNumber = 1, skipRecord = 0) {
    //const bailIdValue = this.issueSelectionForm.get('balIdRadioBtn').value == 'balRadio' ? this.issueSelectionForm.controls['balId'].value : ''
    let formatedData = {
      "UserData": {
        "complaintNo": this.complaintNo,
        "resolvingUnit": this.resolvingUnit
      },
      "take": 10,
      "skip": skipRecord,
      "page": pageNumber,
      "pageSize": 10,
      "filter": {
        "logic": "and",
        "filters": [
          {
            "field": "DivisionCode",
            "operator": "eq",
            "value": "PG"
          },
          {
            "field": "BUCode",
            "operator": "eq",
            "value": this.checkBlank(this.issueSelectionForm.controls['bu'].value)
          },
          {
            "field": "PGCode",
            "operator": "eq",
            "value": this.checkBlank(this.issueSelectionForm.controls['pg'].value)
          },
          {
            "field": "ActivityCode",
            "operator": "eq",
            "value": this.checkBlank(this.issueSelectionForm.controls['activity'].value)
          },
          {
            "field": "CountryCode",
            "operator": "eq",
            "value": this.checkBlank(this.issueSelectionForm.controls['country'].value)
          },
          {
            "field": "City",
            "operator": "eq",
            "value": this.checkBlank(this.issueSelectionForm.controls['city'].value)
          }
        ]
      },
      "group": []
    };
    if(this.checkBlank(this.issueSelectionForm.controls['CCRPNo'].value)!=''){
      formatedData?.filter?.filters.push({
        "field": "ComplaintNumber",
        "operator": "eq",
        "value": this.checkBlank(this.issueSelectionForm.controls['CCRPNo'].value)
      });
    }

    this.issueSelectionService.getComplaintWithRCA(formatedData).subscribe((res: any) => {
      this.filteredCompanyDetails = res?.data
      this.totalRecords = res.count;
      this.loading = false;

    })
  }

  checkBlank(content) {
    if (content == undefined || content == null) {
      return '';
    } else {
      return content;
    }
  }

  private map = new Map<string, string[]>([
    ['India', ['Delhi', 'Bangalore']],
    ['Qatar', ['Doha']],
    ['Afghanistan', ['Kabul']],
    ['Bahrain', ['Manama']],
    ['Bhutan', ['Kabul']],
  ])

  get countries(): string[] {
    return Array.from(this.map.keys());
  }

  get citiesData(): string[] | undefined {
    return this.map.get(this.country);
  }

  selectedRow(index: number) {
    this.selectedIndex = index;
  }

  selectAndClose() {
    this.dialogRef.close(this.filteredCompanyDetails[this.selectedIndex]);
    this.selectedRCAData.emit({ value: this.filteredCompanyDetails[this.selectedIndex] })
  }

  /* functionality for Paginator from primeng starts */
  next() {
    this.first = this.first + this.rows;
  }

  prev() {
    this.first = this.first - this.rows;
  }

  refresh() {
    this.first = 0;
  }

  isLastPage(): boolean {
    return this.filteredCompanyDetails
      ? this.first === this.filteredCompanyDetails.length - this.rows
      : true;
  }

  isFirstPage(): boolean {
    return this.filteredCompanyDetails ? this.first === 0 : true;
  }

  onPageChange(event: any) {
    event.first = 0;
    event.rows = 10;
    event.page = 1;
    event.pageCount = 10;
  }

  // For resetting the form
  reset() {
    this.issueSelectionForm.reset();
    this.totalRecords = 0;
    this.pgName = [];
    this.selectedIndex = -1;
    this.filteredCompanyDetails = null;
    this.issueSelectionForm.patchValue({
      business: '',
      bu: '',
      pg: '',
      country: '',
      city: '',
      activity: '',
    });
    this.issueSelectionForm.controls['business'].patchValue('PG');
  }

  // for closing the dialog
  onClose() {
    this.dialogRef.close();
  }

}
