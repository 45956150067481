import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { ExceptionsService } from 'src/app/services/exceptions/exceptions.service';

@Component({
  selector: 'app-complaint-not-found',
  templateUrl: './complaint-not-found.component.html',
  styleUrls: ['./complaint-not-found.component.css']
})
export class ComplaintNotFoundComponent implements OnInit {

  complaintNo : any;
  exceptionObject : any;
  exceptionObjectText: any;

  constructor(private breadcrumbService: BreadcrumbService,
    private exceptionService: ExceptionsService,
    private route: ActivatedRoute,
    private router: Router,
    private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.breadcrumbService.menuItems = [
      { label: 'NEW ISSUE'},
    ];

    this.complaintNo = this.route.snapshot.paramMap.get('id');

    if(this.router.url.includes('ComplaintNotFound')){
      this.exceptionService.complaintNotFound(this.complaintNo).subscribe((response)=>{
        this.exceptionObjectText = response?.exceptionDescription;
      })
    }

    if(this.router.url.includes('ConfidentialNotAuthorizedOperation')){
      this.exceptionService.confidentialNotAuthorizedOperation().subscribe((response)=>{
        this.exceptionObject = response;
        const splitText = response?.exceptionDescription.split('Issue');
        this.exceptionObjectText = 'Issue ' + this.complaintNo + splitText[1];
      })
    }

    this.spinner.hide();
  }

}
