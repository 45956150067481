import { trigger, state, style, transition, animate } from '@angular/animations';
import * as i1 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i0 from '@angular/core';
import { EventEmitter, forwardRef, Component, ChangeDetectionStrategy, ViewEncapsulation, Inject, Input, Output, ContentChildren, NgModule } from '@angular/core';
import { Header, PrimeTemplate, SharedModule } from 'primeng/api';
import { ChevronRightIcon } from 'primeng/icons/chevronright';
import { ChevronDownIcon } from 'primeng/icons/chevrondown';
const _c0 = ["*", [["p-header"]]];
const _c1 = ["*", "p-header"];
const _c2 = a0 => ({
  $implicit: a0
});
const _c3 = a0 => ({
  transitionParams: a0
});
const _c4 = a0 => ({
  value: "visible",
  params: a0
});
const _c5 = a0 => ({
  value: "hidden",
  params: a0
});
function AccordionTab_ng_container_3_ng_container_1_span_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "span", 11);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(3);
    i0.ɵɵclassMap(ctx_r0.accordion.collapseIcon);
    i0.ɵɵproperty("ngClass", ctx_r0.iconClass);
  }
}
function AccordionTab_ng_container_3_ng_container_1_ChevronDownIcon_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "ChevronDownIcon", 12);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(3);
    i0.ɵɵproperty("styleClass", ctx_r0.iconClass);
  }
}
function AccordionTab_ng_container_3_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, AccordionTab_ng_container_3_ng_container_1_span_1_Template, 1, 3, "span", 9)(2, AccordionTab_ng_container_3_ng_container_1_ChevronDownIcon_2_Template, 1, 1, "ChevronDownIcon", 10);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r0.accordion.collapseIcon);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !ctx_r0.accordion.collapseIcon);
  }
}
function AccordionTab_ng_container_3_ng_container_2_span_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "span", 11);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(3);
    i0.ɵɵclassMap(ctx_r0.accordion.expandIcon);
    i0.ɵɵproperty("ngClass", ctx_r0.iconClass);
  }
}
function AccordionTab_ng_container_3_ng_container_2_ChevronRightIcon_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "ChevronRightIcon", 12);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(3);
    i0.ɵɵproperty("styleClass", ctx_r0.iconClass);
  }
}
function AccordionTab_ng_container_3_ng_container_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, AccordionTab_ng_container_3_ng_container_2_span_1_Template, 1, 3, "span", 9)(2, AccordionTab_ng_container_3_ng_container_2_ChevronRightIcon_2_Template, 1, 1, "ChevronRightIcon", 10);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r0.accordion.expandIcon);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !ctx_r0.accordion.expandIcon);
  }
}
function AccordionTab_ng_container_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, AccordionTab_ng_container_3_ng_container_1_Template, 3, 2, "ng-container", 3)(2, AccordionTab_ng_container_3_ng_container_2_Template, 3, 2, "ng-container", 3);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r0.selected);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !ctx_r0.selected);
  }
}
function AccordionTab_4_ng_template_0_Template(rf, ctx) {}
function AccordionTab_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, AccordionTab_4_ng_template_0_Template, 0, 0, "ng-template");
  }
}
function AccordionTab_span_5_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span", 13);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" ", ctx_r0.header, " ");
  }
}
function AccordionTab_ng_container_6_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function AccordionTab_ng_content_7_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵprojection(0, 1, ["*ngIf", "hasHeaderFacet"]);
  }
}
function AccordionTab_ng_container_11_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function AccordionTab_ng_container_11_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, AccordionTab_ng_container_11_ng_container_1_Template, 1, 0, "ng-container", 6);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r0.contentTemplate);
  }
}
const _c6 = ["*"];
let idx = 0;
class AccordionTab {
  constructor(accordion, changeDetector) {
    this.changeDetector = changeDetector;
    this.cache = true;
    this.selectedChange = new EventEmitter();
    this.transitionOptions = '400ms cubic-bezier(0.86, 0, 0.07, 1)';
    this.iconPos = 'start';
    this.id = `p-accordiontab-${idx++}`;
    this.accordion = accordion;
  }
  get selected() {
    return this._selected;
  }
  set selected(val) {
    this._selected = val;
    if (!this.loaded) {
      if (this._selected && this.cache) {
        this.loaded = true;
      }
      this.changeDetector.detectChanges();
    }
  }
  get iconClass() {
    if (this.iconPos === 'end') {
      return 'p-accordion-toggle-icon-end';
    } else {
      return 'p-accordion-toggle-icon';
    }
  }
  ngAfterContentInit() {
    this.templates.forEach(item => {
      switch (item.getType()) {
        case 'content':
          this.contentTemplate = item.template;
          break;
        case 'header':
          this.headerTemplate = item.template;
          break;
        case 'icon':
          this.iconTemplate = item.template;
          break;
        default:
          this.contentTemplate = item.template;
          break;
      }
    });
  }
  toggle(event) {
    if (this.disabled) {
      return false;
    }
    let index = this.findTabIndex();
    if (this.selected) {
      this.selected = false;
      this.accordion.onClose.emit({
        originalEvent: event,
        index: index
      });
    } else {
      if (!this.accordion.multiple) {
        for (var i = 0; i < this.accordion.tabs.length; i++) {
          if (this.accordion.tabs[i].selected) {
            this.accordion.tabs[i].selected = false;
            this.accordion.tabs[i].selectedChange.emit(false);
            this.accordion.tabs[i].changeDetector.markForCheck();
          }
        }
      }
      this.selected = true;
      this.loaded = true;
      this.accordion.onOpen.emit({
        originalEvent: event,
        index: index
      });
    }
    this.selectedChange.emit(this.selected);
    this.accordion.updateActiveIndex();
    this.changeDetector.markForCheck();
    event.preventDefault();
  }
  findTabIndex() {
    let index = -1;
    for (var i = 0; i < this.accordion.tabs.length; i++) {
      if (this.accordion.tabs[i] == this) {
        index = i;
        break;
      }
    }
    return index;
  }
  get hasHeaderFacet() {
    return this.headerFacet && this.headerFacet.length > 0;
  }
  onKeydown(event) {
    if (event.which === 32 || event.which === 13) {
      this.toggle(event);
      event.preventDefault();
    }
  }
  ngOnDestroy() {
    this.accordion.tabs.splice(this.findTabIndex(), 1);
  }
}
AccordionTab.ɵfac = function AccordionTab_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || AccordionTab)(i0.ɵɵdirectiveInject(forwardRef(() => Accordion)), i0.ɵɵdirectiveInject(i0.ChangeDetectorRef));
};
AccordionTab.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: AccordionTab,
  selectors: [["p-accordionTab"]],
  contentQueries: function AccordionTab_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, Header, 4);
      i0.ɵɵcontentQuery(dirIndex, PrimeTemplate, 4);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.headerFacet = _t);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.templates = _t);
    }
  },
  hostAttrs: [1, "p-element"],
  inputs: {
    header: "header",
    headerStyle: "headerStyle",
    tabStyle: "tabStyle",
    contentStyle: "contentStyle",
    tabStyleClass: "tabStyleClass",
    headerStyleClass: "headerStyleClass",
    contentStyleClass: "contentStyleClass",
    disabled: "disabled",
    cache: "cache",
    transitionOptions: "transitionOptions",
    iconPos: "iconPos",
    selected: "selected"
  },
  outputs: {
    selectedChange: "selectedChange"
  },
  ngContentSelectors: _c1,
  decls: 12,
  vars: 38,
  consts: [[1, "p-accordion-tab", 3, "ngClass", "ngStyle"], [1, "p-accordion-header"], ["role", "tab", 1, "p-accordion-header-link", 3, "click", "keydown", "ngClass"], [4, "ngIf"], [4, "ngTemplateOutlet", "ngTemplateOutletContext"], ["class", "p-accordion-header-text", 4, "ngIf"], [4, "ngTemplateOutlet"], ["role", "region", 1, "p-toggleable-content"], [1, "p-accordion-content", 3, "ngClass", "ngStyle"], [3, "class", "ngClass", 4, "ngIf"], [3, "styleClass", 4, "ngIf"], [3, "ngClass"], [3, "styleClass"], [1, "p-accordion-header-text"]],
  template: function AccordionTab_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef(_c0);
      i0.ɵɵelementStart(0, "div", 0)(1, "div", 1)(2, "a", 2);
      i0.ɵɵlistener("click", function AccordionTab_Template_a_click_2_listener($event) {
        return ctx.toggle($event);
      })("keydown", function AccordionTab_Template_a_keydown_2_listener($event) {
        return ctx.onKeydown($event);
      });
      i0.ɵɵtemplate(3, AccordionTab_ng_container_3_Template, 3, 2, "ng-container", 3)(4, AccordionTab_4_Template, 1, 0, null, 4)(5, AccordionTab_span_5_Template, 2, 1, "span", 5)(6, AccordionTab_ng_container_6_Template, 1, 0, "ng-container", 6)(7, AccordionTab_ng_content_7_Template, 1, 0, "ng-content", 3);
      i0.ɵɵelementEnd()();
      i0.ɵɵelementStart(8, "div", 7)(9, "div", 8);
      i0.ɵɵprojection(10);
      i0.ɵɵtemplate(11, AccordionTab_ng_container_11_Template, 2, 1, "ng-container", 3);
      i0.ɵɵelementEnd()()();
    }
    if (rf & 2) {
      i0.ɵɵclassProp("p-accordion-tab-active", ctx.selected);
      i0.ɵɵproperty("ngClass", ctx.tabStyleClass)("ngStyle", ctx.tabStyle);
      i0.ɵɵadvance();
      i0.ɵɵclassProp("p-highlight", ctx.selected)("p-disabled", ctx.disabled);
      i0.ɵɵadvance();
      i0.ɵɵstyleMap(ctx.headerStyle);
      i0.ɵɵproperty("ngClass", ctx.headerStyleClass);
      i0.ɵɵattribute("tabindex", ctx.disabled ? null : 0)("id", ctx.id)("aria-controls", ctx.id + "-content")("aria-expanded", ctx.selected);
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngIf", !ctx.iconTemplate);
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngTemplateOutlet", ctx.iconTemplate)("ngTemplateOutletContext", i0.ɵɵpureFunction1(28, _c2, ctx.selected));
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngIf", !ctx.hasHeaderFacet);
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngTemplateOutlet", ctx.headerTemplate);
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngIf", ctx.hasHeaderFacet);
      i0.ɵɵadvance();
      i0.ɵɵproperty("@tabContent", ctx.selected ? i0.ɵɵpureFunction1(32, _c4, i0.ɵɵpureFunction1(30, _c3, ctx.transitionOptions)) : i0.ɵɵpureFunction1(36, _c5, i0.ɵɵpureFunction1(34, _c3, ctx.transitionOptions)));
      i0.ɵɵattribute("id", ctx.id + "-content")("aria-hidden", !ctx.selected)("aria-labelledby", ctx.id);
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngClass", ctx.contentStyleClass)("ngStyle", ctx.contentStyle);
      i0.ɵɵadvance(2);
      i0.ɵɵproperty("ngIf", ctx.contentTemplate && (ctx.cache ? ctx.loaded : ctx.selected));
    }
  },
  dependencies: () => [i1.NgClass, i1.NgIf, i1.NgTemplateOutlet, i1.NgStyle, ChevronRightIcon, ChevronDownIcon],
  styles: [".p-accordion-header-link{cursor:pointer;display:flex;align-items:center;-webkit-user-select:none;user-select:none;position:relative;text-decoration:none}.p-accordion-header-link:focus{z-index:1}.p-accordion-header-text{line-height:1}.p-accordion .p-toggleable-content{overflow:hidden}.p-accordion .p-accordion-tab-active>.p-toggleable-content:not(.ng-animating){overflow:inherit}.p-accordion-toggle-icon-end{order:1;margin-left:auto}.p-accordion-toggle-icon{order:0}\n"],
  encapsulation: 2,
  data: {
    animation: [trigger('tabContent', [state('hidden', style({
      height: '0'
    })), state('visible', style({
      height: '*'
    })), transition('visible <=> hidden', [animate('{{transitionParams}}')]), transition('void => *', animate(0))])]
  },
  changeDetection: 0
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AccordionTab, [{
    type: Component,
    args: [{
      selector: 'p-accordionTab',
      template: `
        <div class="p-accordion-tab" [class.p-accordion-tab-active]="selected" [ngClass]="tabStyleClass" [ngStyle]="tabStyle">
            <div class="p-accordion-header" [class.p-highlight]="selected" [class.p-disabled]="disabled">
                <a
                    [ngClass]="headerStyleClass"
                    [style]="headerStyle"
                    role="tab"
                    class="p-accordion-header-link"
                    (click)="toggle($event)"
                    (keydown)="onKeydown($event)"
                    [attr.tabindex]="disabled ? null : 0"
                    [attr.id]="id"
                    [attr.aria-controls]="id + '-content'"
                    [attr.aria-expanded]="selected"
                >
                    <ng-container *ngIf="!iconTemplate">
                        <ng-container *ngIf="selected">
                            <span *ngIf="accordion.collapseIcon" [class]="accordion.collapseIcon" [ngClass]="iconClass"></span>
                            <ChevronDownIcon *ngIf="!accordion.collapseIcon" [styleClass]="iconClass"/>
                        </ng-container>
                        <ng-container *ngIf="!selected">
                            <span *ngIf="accordion.expandIcon" [class]="accordion.expandIcon" [ngClass]="iconClass"></span>
                            <ChevronRightIcon *ngIf="!accordion.expandIcon" [styleClass]="iconClass"/>
                        </ng-container>
                    </ng-container>
                    <ng-template *ngTemplateOutlet="iconTemplate; context: { $implicit: selected }"></ng-template>
                    <span class="p-accordion-header-text" *ngIf="!hasHeaderFacet">
                        {{ header }}
                    </span>
                    <ng-container *ngTemplateOutlet="headerTemplate"></ng-container>
                    <ng-content select="p-header" *ngIf="hasHeaderFacet"></ng-content>
                </a>
            </div>
            <div
                [attr.id]="id + '-content'"
                class="p-toggleable-content"
                [@tabContent]="selected ? { value: 'visible', params: { transitionParams: transitionOptions } } : { value: 'hidden', params: { transitionParams: transitionOptions } }"
                role="region"
                [attr.aria-hidden]="!selected"
                [attr.aria-labelledby]="id"
            >
                <div class="p-accordion-content" [ngClass]="contentStyleClass" [ngStyle]="contentStyle">
                    <ng-content></ng-content>
                    <ng-container *ngIf="contentTemplate && (cache ? loaded : selected)">
                        <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
                    </ng-container>
                </div>
            </div>
        </div>
    `,
      animations: [trigger('tabContent', [state('hidden', style({
        height: '0'
      })), state('visible', style({
        height: '*'
      })), transition('visible <=> hidden', [animate('{{transitionParams}}')]), transition('void => *', animate(0))])],
      changeDetection: ChangeDetectionStrategy.OnPush,
      encapsulation: ViewEncapsulation.None,
      host: {
        class: 'p-element'
      },
      styles: [".p-accordion-header-link{cursor:pointer;display:flex;align-items:center;-webkit-user-select:none;user-select:none;position:relative;text-decoration:none}.p-accordion-header-link:focus{z-index:1}.p-accordion-header-text{line-height:1}.p-accordion .p-toggleable-content{overflow:hidden}.p-accordion .p-accordion-tab-active>.p-toggleable-content:not(.ng-animating){overflow:inherit}.p-accordion-toggle-icon-end{order:1;margin-left:auto}.p-accordion-toggle-icon{order:0}\n"]
    }]
  }], function () {
    return [{
      type: undefined,
      decorators: [{
        type: Inject,
        args: [forwardRef(() => Accordion)]
      }]
    }, {
      type: i0.ChangeDetectorRef
    }];
  }, {
    header: [{
      type: Input
    }],
    headerStyle: [{
      type: Input
    }],
    tabStyle: [{
      type: Input
    }],
    contentStyle: [{
      type: Input
    }],
    tabStyleClass: [{
      type: Input
    }],
    headerStyleClass: [{
      type: Input
    }],
    contentStyleClass: [{
      type: Input
    }],
    disabled: [{
      type: Input
    }],
    cache: [{
      type: Input
    }],
    selectedChange: [{
      type: Output
    }],
    transitionOptions: [{
      type: Input
    }],
    iconPos: [{
      type: Input
    }],
    headerFacet: [{
      type: ContentChildren,
      args: [Header]
    }],
    templates: [{
      type: ContentChildren,
      args: [PrimeTemplate]
    }],
    selected: [{
      type: Input
    }]
  });
})();
class Accordion {
  constructor(el, changeDetector) {
    this.el = el;
    this.changeDetector = changeDetector;
    this.onClose = new EventEmitter();
    this.onOpen = new EventEmitter();
    this.activeIndexChange = new EventEmitter();
    this.tabs = [];
  }
  ngAfterContentInit() {
    this.initTabs();
    this.tabListSubscription = this.tabList.changes.subscribe(_ => {
      this.initTabs();
    });
  }
  initTabs() {
    this.tabs = this.tabList.toArray();
    this.updateSelectionState();
    this.changeDetector.markForCheck();
  }
  getBlockableElement() {
    return this.el.nativeElement.children[0];
  }
  get activeIndex() {
    return this._activeIndex;
  }
  set activeIndex(val) {
    this._activeIndex = val;
    if (this.preventActiveIndexPropagation) {
      this.preventActiveIndexPropagation = false;
      return;
    }
    this.updateSelectionState();
  }
  updateSelectionState() {
    if (this.tabs && this.tabs.length && this._activeIndex != null) {
      for (let i = 0; i < this.tabs.length; i++) {
        let selected = this.multiple ? this._activeIndex.includes(i) : i === this._activeIndex;
        let changed = selected !== this.tabs[i].selected;
        if (changed) {
          this.tabs[i].selected = selected;
          this.tabs[i].selectedChange.emit(selected);
          this.tabs[i].changeDetector.markForCheck();
        }
      }
    }
  }
  updateActiveIndex() {
    let index = this.multiple ? [] : null;
    this.tabs.forEach((tab, i) => {
      if (tab.selected) {
        if (this.multiple) {
          index.push(i);
        } else {
          index = i;
          return;
        }
      }
    });
    this.preventActiveIndexPropagation = true;
    this.activeIndexChange.emit(index);
  }
  ngOnDestroy() {
    if (this.tabListSubscription) {
      this.tabListSubscription.unsubscribe();
    }
  }
}
Accordion.ɵfac = function Accordion_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || Accordion)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.ChangeDetectorRef));
};
Accordion.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: Accordion,
  selectors: [["p-accordion"]],
  contentQueries: function Accordion_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, AccordionTab, 4);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.tabList = _t);
    }
  },
  hostAttrs: [1, "p-element"],
  inputs: {
    multiple: "multiple",
    style: "style",
    styleClass: "styleClass",
    expandIcon: "expandIcon",
    collapseIcon: "collapseIcon",
    activeIndex: "activeIndex"
  },
  outputs: {
    onClose: "onClose",
    onOpen: "onOpen",
    activeIndexChange: "activeIndexChange"
  },
  ngContentSelectors: _c6,
  decls: 2,
  vars: 4,
  consts: [["role", "tablist", 3, "ngClass", "ngStyle"]],
  template: function Accordion_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵelementStart(0, "div", 0);
      i0.ɵɵprojection(1);
      i0.ɵɵelementEnd();
    }
    if (rf & 2) {
      i0.ɵɵclassMap(ctx.styleClass);
      i0.ɵɵproperty("ngClass", "p-accordion p-component")("ngStyle", ctx.style);
    }
  },
  dependencies: [i1.NgClass, i1.NgStyle],
  encapsulation: 2,
  changeDetection: 0
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(Accordion, [{
    type: Component,
    args: [{
      selector: 'p-accordion',
      template: `
        <div [ngClass]="'p-accordion p-component'" [ngStyle]="style" [class]="styleClass" role="tablist">
            <ng-content></ng-content>
        </div>
    `,
      changeDetection: ChangeDetectionStrategy.OnPush,
      host: {
        class: 'p-element'
      }
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i0.ChangeDetectorRef
    }];
  }, {
    multiple: [{
      type: Input
    }],
    onClose: [{
      type: Output
    }],
    onOpen: [{
      type: Output
    }],
    style: [{
      type: Input
    }],
    styleClass: [{
      type: Input
    }],
    expandIcon: [{
      type: Input
    }],
    collapseIcon: [{
      type: Input
    }],
    activeIndexChange: [{
      type: Output
    }],
    tabList: [{
      type: ContentChildren,
      args: [AccordionTab]
    }],
    activeIndex: [{
      type: Input
    }]
  });
})();
class AccordionModule {}
AccordionModule.ɵfac = function AccordionModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || AccordionModule)();
};
AccordionModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: AccordionModule
});
AccordionModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [CommonModule, ChevronRightIcon, ChevronDownIcon, SharedModule]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AccordionModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, ChevronRightIcon, ChevronDownIcon],
      exports: [Accordion, AccordionTab, SharedModule],
      declarations: [Accordion, AccordionTab]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { Accordion, AccordionModule, AccordionTab };

