import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ActionPlanCard } from 'src/app/models/new-issue/actionplan/ActionPlanCard';
import { DeleteCardModalComponent } from '../delete-card-modal/delete-card-modal.component';

@Component({
  selector: 'app-action-plan-card',
  templateUrl: './action-plan-card.component.html',
  styleUrls: ['./action-plan-card.component.css']
})
export class ActionPlanCardComponent implements OnInit {
  ref: DynamicDialogRef;

  @Input() item: ActionPlanCard;
  @Input() selectedRole: string;
  @Output() deleteCard: EventEmitter<number> = new EventEmitter<number>();

  constructor(public dialogService: DialogService) { }

  ngOnInit(): void {
    //console.log(this.item.permission.isCreator);
  }

  ondeleteClicked(id: number){
    this.ref = this.dialogService.open(DeleteCardModalComponent, {
      header: 'Delete Action',
      width: '35%',
      position: 'top',
      styleClass: 'delete-card-modal'
    })

    this.ref.onClose.subscribe((data) => {
      if(data) {
        if(data.result)
        {
          this.deleteCard.emit(id);
        }
      }
    })

    //this.deleteCard.emit(id);
  }

}
