import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedDataService {
  country= new Subject();

  constructor() { }

  setCountry(country){
    this.country.next(country);
    // console.log('country in service', country);
  }
  
}
