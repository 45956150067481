<div class="accountablePersonMainDiv">
<form  [formGroup]="acccounableForm">
    <div class="accountable-person-section">
        <div class=" ms-1 mb-3 lable-bold header">
            {{'admin_ru_rufilter_h' | translate}}
        </div>
        <div class=" ms-2 me-2">
            <!-- 1st row -->
            <div class="row">
                <div class="col-sm-6 accountable_person_marginClass">
                    <div class="lable-bold">{{'admin_rufilter_division_lbl'|translate}}</div>
                    <div class=".drop-selection-div">
                        <p-dropdown [options]="division" optionValue="key" formControlName="business" optionLabel="value"
                        [resetFilterOnHide]="true" [filter]="true" filterBy="value" [showClear]="true" class="disabled-dropdownInput"
                            placeholder="Click or type to select Business" [disabled]="true" >
                        </p-dropdown>
                    </div>
                </div>
                <div class="col-sm-6 accountable_person_marginClass">
                    <div class="lable-bold">{{'admin_rufilter_ba_lbl'|translate}}</div>
                    <div class="">
                        <button class="btn btn-default btn-sm borderleft"  value="null" role="button" (click)="onAll()"
                            [style.backgroundColor]="this.btnColorChange0 ? '#d9d9d9': '#ffffff'">
                            <span class="fa"
                                [ngClass]="this.btnColorChange0 ?  'fa-check-circle-o' : 'fa-circle-o' "></span>
                            {{'admin_rufilter_ba_all_lbl'|translate}}
                        </button>
    
                        <button class="btn btn-default btn-sm " value="null" ole="button" (click)="onLocal()"
                            [style.backgroundColor]="this.btnColorChange1 ? '#d9d9d9': '#ffffff'">
                            <span class="fa"
                                [ngClass]="this.btnColorChange1 ?  'fa-check-circle-o' : 'fa-circle-o' "></span>
                            {{'admin_rufilter_ba_local_lbl'|translate}}
                        </button>
    
                        <button class="btn btn-default btn-sm borderright" value="null" role="button" (click)="onGlobal()"
                            [style.backgroundColor]="this.btnColorChange2 ? '#d9d9d9': '#ffffff'">
                            <span class="fa"
                                [ngClass]="this.btnColorChange2 ?  'fa-check-circle-o' : 'fa-circle-o' "></span>
                            {{'admin_rufilter_ba_global_lbl'|translate}}
                        </button>
                    </div>
                </div>
            </div>
            <!-- <div class="row">
                <div class="col-sm-6 lable-bold">{{'admin_rufilter_division_lbl'|translate}}</div>
                <div class="col-sm-6 lable-bold">{{'admin_rufilter_ba_lbl'|translate}}</div>
            </div>
            <div class="row">
                <div class="col-sm-6 .drop-selection-div">
                    <p-dropdown [options]="division" optionValue="key" formControlName="business" optionLabel="value"
                    [resetFilterOnHide]="true" [filter]="true" filterBy="value" [showClear]="true" class="disabled-dropdownInput"
                        placeholder="Click or type to select Business" [disabled]="true" >
                    </p-dropdown>
                </div>
                <div class="col-sm-6 ">
                    <button class="btn btn-default btn-sm borderleft"  value="null" role="button" (click)="onAll()"
                        [style.backgroundColor]="this.btnColorChange0 ? '#d9d9d9': '#ffffff'">
                        <span class="fa"
                            [ngClass]="this.btnColorChange0 ?  'fa-check-circle-o' : 'fa-circle-o' "></span>
                        {{'admin_rufilter_ba_all_lbl'|translate}}
                    </button>

                    <button class="btn btn-default btn-sm " value="null" ole="button" (click)="onLocal()"
                        [style.backgroundColor]="this.btnColorChange1 ? '#d9d9d9': '#ffffff'">
                        <span class="fa"
                            [ngClass]="this.btnColorChange1 ?  'fa-check-circle-o' : 'fa-circle-o' "></span>
                        {{'admin_rufilter_ba_local_lbl'|translate}}
                    </button>

                    <button class="btn btn-default btn-sm borderright" value="null" role="button" (click)="onGlobal()"
                        [style.backgroundColor]="this.btnColorChange2 ? '#d9d9d9': '#ffffff'">
                        <span class="fa"
                            [ngClass]="this.btnColorChange2 ?  'fa-check-circle-o' : 'fa-circle-o' "></span>
                        {{'admin_rufilter_ba_global_lbl'|translate}}
                    </button>
                </div>

            </div> -->
            <!-- second row -->
            <div class="row accountable_person_rowMargin">
                <div class="col-sm-6 accountable_person_marginClass">
                    <div class="lable-bold">{{'admin_rufilter_bu_lbl'|translate}}</div>
                    <div class="">
                        <p-dropdown [options]="bu" optionValue="key" formControlName="bu" optionLabel="value"
                        [resetFilterOnHide]="true"  [filter]="true" filterBy="value" [showClear]="true" placeholder="Click or type to select BU"
                            [disabled]="true">
                        </p-dropdown>
                    </div>
                </div>
                <div class="col-sm-6 accountable_person_marginClass">
                    <div class="lable-bold">{{'admin_rufilter_region_lbl'|translate}}</div>
                    <div class="">
                        <p-dropdown [options]="regions" optionValue="key" formControlName="regions" optionLabel="value"
                        [resetFilterOnHide]="true"  [filter]="true" filterBy="value" [showClear]="true"
                            placeholder="Click or type to select Region">
                        </p-dropdown>
                    </div>
                </div>
            </div>
            <!-- <div class="row mt-2">
                <div class="col-sm-6 lable-bold">{{'admin_rufilter_bu_lbl'|translate}}</div>
                <div class="col-sm-6 lable-bold">{{'admin_rufilter_region_lbl'|translate}}</div>

            </div>
            <div class="row">
                <div class="col-sm-6">
                    <p-dropdown [options]="bu" optionValue="key" formControlName="bu" optionLabel="value"
                    [resetFilterOnHide]="true"  [filter]="true" filterBy="value" [showClear]="true" placeholder="Click or type to select BU"
                        [disabled]="true">
                    </p-dropdown>
                </div>
                <div class="col-sm-6">
                    <p-dropdown [options]="regions" optionValue="key" formControlName="regions" optionLabel="value"
                    [resetFilterOnHide]="true"  [filter]="true" filterBy="value" [showClear]="true"
                        placeholder="Click or type to select Region">
                    </p-dropdown>
                </div>
            </div> -->
            <!-- Third row -->
            <div class="row accountable_person_rowMargin">
                <div class="col-sm-6 accountable_person_marginClass">
                    <div class="lable-bold">{{'admin_rufilter_pg_lbl'|translate}} </div>
                    <div class="">
                        <p-dropdown [options]="pg" optionValue="key" formControlName="pg" optionLabel="value"
                        [resetFilterOnHide]="true"  [filter]="true" filterBy="value" [showClear]="true" placeholder="Click or type to select PG">
                        </p-dropdown>
                    </div>
                </div>
                <div class="col-sm-6 accountable_person_marginClass">
                    <div class="lable-bold">{{'admin_rufilter_country_lbl'|translate}}</div>
                    <div class="">
                        <p-dropdown [options]="country"  optionValue="id" formControlName="country" optionLabel="name"
                        [resetFilterOnHide]="true"   [filter]="true" filterBy="name" [showClear]="true" placeholder="Click or type to select Country">
                        </p-dropdown>
                    </div>
                </div>
            </div>
            <!-- <div class="row mt-2">
                <div class="col-sm-6 lable-bold">{{'admin_rufilter_pg_lbl'|translate}} </div>
                <div class="col-sm-6 lable-bold">{{'admin_rufilter_country_lbl'|translate}}</div>
            </div>
            <div class="row">
                <div class="col-sm-6">
                    <p-dropdown [options]="pg" optionValue="key" formControlName="pg" optionLabel="value"
                    [resetFilterOnHide]="true"  [filter]="true" filterBy="value" [showClear]="true" placeholder="Click or type to select PG">
                    </p-dropdown>
                </div>
                <div class="col-sm-6">
                    <p-dropdown [options]="country"  optionValue="id" formControlName="country" optionLabel="name"
                    [resetFilterOnHide]="true"   [filter]="true" filterBy="name" [showClear]="true" placeholder="Click or type to select Country">

                    </p-dropdown>




                </div>
            </div> -->
            <!-- Fourth row -->
            <div class="row accountable_person_rowMargin">
                <div class="col-sm-6 accountable_person_marginClass">
                    <div class="lable-bold">{{'admin_rufilter_assigned_lbl'|translate}}</div>
                    <div class="">
                        <button class="btn btn-default btn-sm borderleft btnRUpersonAccountablePerson" value="null" role="button" (click)="onClickAll()"
                            [style.backgroundColor]="this.btnColorChange3 ? '#d9d9d9': '#ffffff'">
                            <span class="fa"
                                [ngClass]="this.btnColorChange3 ?  'fa-check-circle-o' : 'fa-circle-o' "></span>
                            {{'admin_rufilter_assig_all_lbl'|translate}}
                        </button>
    
    
                        <button class="btn btn-default btn-sm btnRUpersonAccountablePerson" value="true" type="button" (click)="onAssigned()"
                            [style.backgroundColor]="this.btnColorChange4 ? '#d9d9d9': '#ffffff'">
                            <span class="fa"
                                [ngClass]="this.btnColorChange4 ?  'fa-check-circle-o' : 'fa-circle-o' "></span>
                            {{'admin_rufilter_assig_withpeople_lbl'|translate}}
                        </button>
    
                        <button class="btn btn-default btn-sm borderright btnRUpersonAccountablePerson" value="false" type="button" (click)="onNotAssigned()"
                            [style.backgroundColor]="this.btnColorChange5 ? '#d9d9d9': '#ffffff'">
                            <span class="fa"
                                [ngClass]="this.btnColorChange5 ?  'fa-check-circle-o' : 'fa-circle-o' "></span>
                            {{'admin_rufilter_assig_nothavasoc_lbl'|translate}}
                        </button>
                    </div>
                </div>
                <div class="col-sm-6 accountable_person_marginClass">
                    <div class="lable-bold">{{'admin_rufilter_city_lbl'|translate}}</div>
                    <div class="">
                        <p-dropdown formControlName="city" [options]="getCityList" optionLabel="name" optionValue="id"
                        [resetFilterOnHide]="true"  [filter]="true" [autofocus]="false" filterBy="name" [showClear]="true"
                            placeholder="Select a city"></p-dropdown>
                    </div>
                </div>
            </div>
            <!-- <div class="row mt-2">
                <div class="col-sm-6 lable-bold">{{'admin_rufilter_assigned_lbl'|translate}}</div>
                <div class="col-sm-6 lable-bold">{{'admin_rufilter_city_lbl'|translate}}</div>
            </div>
            <div class="row">
                <div class="col-sm-6 ">
                    <button class="btn btn-default btn-sm borderleft" value="null" role="button" (click)="onClickAll()"
                        [style.backgroundColor]="this.btnColorChange3 ? '#d9d9d9': '#ffffff'">
                        <span class="fa"
                            [ngClass]="this.btnColorChange3 ?  'fa-check-circle-o' : 'fa-circle-o' "></span>
                        {{'admin_rufilter_assig_all_lbl'|translate}}
                    </button>


                    <button class="btn btn-default btn-sm " value="true" type="button" (click)="onAssigned()"
                        [style.backgroundColor]="this.btnColorChange4 ? '#d9d9d9': '#ffffff'">
                        <span class="fa"
                            [ngClass]="this.btnColorChange4 ?  'fa-check-circle-o' : 'fa-circle-o' "></span>
                        {{'admin_rufilter_assig_withpeople_lbl'|translate}}
                    </button>

                    <button class="btn btn-default btn-sm borderright" value="false" type="button" (click)="onNotAssigned()"
                        [style.backgroundColor]="this.btnColorChange5 ? '#d9d9d9': '#ffffff'">
                        <span class="fa"
                            [ngClass]="this.btnColorChange5 ?  'fa-check-circle-o' : 'fa-circle-o' "></span>
                        {{'admin_rufilter_assig_nothavasoc_lbl'|translate}}
                    </button>

                </div>
                <div class="col-sm-6">
                    <p-dropdown formControlName="city" [options]="getCityList" optionLabel="name" optionValue="id"
                    [resetFilterOnHide]="true"  [filter]="true" [autofocus]="false" filterBy="name" [showClear]="true"
                        placeholder="Select a city"></p-dropdown>
                </div>

            </div> -->
            <!-- Fifth row -->
            <div class="row accountable_person_rowMargin">
                <div class="col-sm-6">
                    <div class=""></div>
                    <div class=""></div>
                </div>
                <div class="col-sm-6">
                    <div class="lable-bold">{{'admin_rufilter_activity_lbl'|translate}}</div>
                    <div class="">
                        <p-dropdown [options]="activityDropdownData" optionValue="key" formControlName="activity"
                        [resetFilterOnHide]="true"  optionLabel="value" [filter]="true" filterBy="value" [showClear]="true"
                            placeholder="Click or type to select activity"></p-dropdown>
                    </div>
                </div>
            </div>
            <!-- <div class="row mt-2">
                <div class="col-sm-6"></div>
                <div class="col-sm-6 lable-bold">{{'admin_rufilter_activity_lbl'|translate}}</div>
            </div>
            <div class="row">
                <div class="col-sm-6"></div>
                <div class="col-sm-6">
                    <p-dropdown [options]="activityDropdownData" optionValue="key" formControlName="activity"
                    [resetFilterOnHide]="true"  optionLabel="value" [filter]="true" filterBy="value" [showClear]="true"
                        placeholder="Click or type to select activity"></p-dropdown>
                </div>
            </div> -->
            <div class="row mt-2">
                <div class="col-sm-9"></div>
                <div class="col-sm-3 div-align-items-right widgetBtnDiv">
                    <button
                    class="widgetButtonLarge" (click)="clearFilters()"
                        ng-disabled="isSearching">{{'admin_ru_search_clear_btn' |
                        translate |uppercase}}</button>

                    <button
                    [disabled]="clicked==true && !(this.acccounableForm.get('regions').value != null && this.acccounableForm.get('regions').value != '') &&
                    !(this.acccounableForm.get('pg').value != null && this.acccounableForm.get('pg').value != '') &&
                    !(this.acccounableForm.get('country').value != null && this.acccounableForm.get('country').value != '') &&
                    !(this.acccounableForm.get('city').value != null && this.acccounableForm.get('city').value != '') &&
                    !(this.acccounableForm.get('activity').value != null && this.acccounableForm.get('activity').value != '')"
                    class="widgetButtonLarge ms-1" (click)="getsearch()"
                        ng-disabled="criteriaEmpty || isSearching">{{'admin_ru_search_search_btn' |
                        translate |uppercase}}</button>
                </div>
            </div>
        </div>

    </div>

    <div>
        <div class="flex justify-content-center">
            <div *ngIf="noResult" class="forNoResultText">
                {{noResultInfo}}
            </div>
            <div  *ngIf="showrumessage" class="forNoResultText">
                Select Resolving Unit search criteria
              </div>

            <div class="row">
                <div class="forCard mt-3" *ngFor="let obj of hubDetailsAsPerPagination; let i=index">
                    <p-card class="p-card  p-component " [style]="{ width: '100%' }">
                        <ng-template pTemplate="header">
                            <div class="panel-heading">
                                <h4 class="panel-title">
                                    <div class="cardFirstLine">
                                        <span class="fa admin-link"></span>
                                        <div>
                                            <div class="">
                                                <span>
                                                    <i *ngIf="obj.isInTransitionPeriod"
                                                        class="glyphicon glyphicon-info-sign" onmouseover=""
                                                        style="cursor: default; color: #ffb500;"
                                                        [pTooltip]="admin_ru_details_intransitionperiod_tooltip"
                                                        [escape]="false" tooltipPosition="top"
                                                        tooltipStyleClass="toolTipClass" tooltip-trigger="mouseenter"
                                                        tooltip-placement="top"></i>
                                                </span>
                                                <span>&nbsp;</span>
                                                <input *ngIf="obj.canManage" type="checkbox" id="check" [checked]="">
                                                <span class="glyphicon glyphicon-home ms-1"></span>
                                                <span><strong> {{ obj.divCode }},{{ obj.buCode
                                                        }},{{obj.pgCode}},{{obj.pgName}} </strong></span>
                                            </div>
                                            <div class="row cardClassDiv">
                                                <span>
                                                    <strong>{{obj.balId}}, {{obj.abacusCode}},
                                                        {{obj.laName}}</strong>
                                                </span>
                                            </div>
                                            <div class="row cardClassDiv mb-2">
                                                <span>
                                                    <small> {{obj.address.city}}, {{obj.address.address}},
                                                        {{obj.address.countryCode}} <a [pTooltip]="googlemap" [escape]="false" tooltipPosition="top"
                                                        tooltipStyleClass="tooltippgrca"
                                                            href="https://maps.google.pl/?q={{ this.obj.address.countryCode}}, {{ this.obj.address.city}}, {{this.obj.address.address}}"
                                                            target="_blank" class="gray-link">
                                                            <i class="hitachiicon-location"> </i> </a></small>
                                                </span>
                                            </div>



                                        </div>
                                    </div>
                                </h4>
                            </div>
                        </ng-template>
                        <!-- <ng-template pTemplate="body"> -->
                        <div class="process-step-pop">
                            <p-tree [value]="obj?.tree" selectionMode="single" [(selection)]="selectedNode"
                                (onNodeExpand)="nodeSelect($event,i)" (onNodeCollapse)="nodeCollapse($event, i)">
                              <ng-template let-node let-index pTemplate="default">
                                    <span class="text-muted" style="cursor: context-menu;">
                                        {{node.label}}
                                        <a *ngIf="node.isActive==true" style="cursor: pointer;" [ngClass]="!node.isActive ? 'disabledNode': 'enableNode'" [pTooltip]="addTooltipInfo" [escape]="false" tooltipPosition="top"
                                            tooltipStyleClass="tooltippgrca" (click)="addChildPopUp(index,obj, i)"><i
                                                class="fa fa-plus iconStyle"></i></a>
                                        <a *ngIf="node.id>0" style="cursor: pointer;"  [pTooltip]="editTooltipInfo" [escape]="false" tooltipPosition="top"
                                            tooltipStyleClass="tooltippgrca" (click)="editChildPopUp(index,obj,i)"><i
                                                class="fa fa-edit iconStyle ms-2"></i></a>

                                    </span>

                                </ng-template>
                            </p-tree>

                        </div>

                        <!-- </ng-template> -->
                    </p-card>
                </div>

            </div>
            <div class="row " *ngIf="hubDetailsAsPerPagination.length>0">
                <div class="col-sm-4"></div>
                <div class="col-sm-4 div-align-items-center">
                    <ul class="pagination">
                        <li class="{{hasPrevious()?'':'disabled'}}" (click)="previousClicked()"><a>&laquo;</a></li>
                        <li *ngIf="showFirstPage()" (click)="gotoPage(1)"><a>1</a></li>
                        <li *ngIf="showFirstPageSeparator()"><span>...</span></li>
                        <li *ngIf="hasPrevious()" (click)="gotoPage(previousPage())"><a>{{previousPage()}}</a></li>
                        <li class="active"><a>{{currentPage}}</a></li>
                        <li *ngIf="hasNext()" (click)="gotoPage(nextPage())"><a>{{nextPage()}}</a></li>
                        <li *ngIf="showLastPageSeparator()"><span>...</span></li>
                        <li *ngIf="showLastPage()" (click)="gotoPage(pagesCount)"><a>{{pagesCount}}</a></li>
                        <li class="{{hasNext()?'':'disabled'}}"><a (click)="nextClicked()">&raquo;</a></li>
                    </ul>
                </div>
                <div class="col-sm-4"></div>
            </div>
        </div>
    </div>
</form>
</div>