<div class="workCenterPopup">
      {{'SearchForm_BalRecentlySelected'| translate}}
      <div class="tableMargin">
        <div class="card">
          <p-table
            [value]="workCenterDetails"
            selectionMode="single"
            [paginator]="true"
            [rows]="rows"
            [showCurrentPageReport]="true"
            [(first)]="first"
            [loading]="loading"
            [tableStyle]="{ 'min-width': '50rem' }"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
            styleClass="p-datatable-gridlines"
          >
            <ng-template
              pTemplate="header"
              styleClass="table table-bordered table-striped table-hove"
            >
              <tr>
                <th pSortableColumn="balId" style="width: 33%">
                  BAL Id<p-sortIcon field="balId"></p-sortIcon>
                </th>
                <th pSortableColumn="plant" style="width: 33%">
                  Plant<p-sortIcon field="plant"></p-sortIcon>
                </th>
                <th pSortableColumn="workcentre" style="width: 33%">
                  Work Center<p-sortIcon field="workcentre"></p-sortIcon>
                </th>
              </tr>
            </ng-template>

            <ng-template pTemplate="body" let-filter let-rowIndex="rowIndex">
              <tr
                [ngClass]="{ selectedRowIndex: rowIndex == selectedIndex }"
                (click)="selectedRow(rowIndex)"
              >
                <td>{{ filter.balId }}</td>
                <td>{{ filter.plant }}</td>
                <td>{{ filter.workcentre }}</td>
              </tr>
            </ng-template>

            <ng-template pTemplate="paginatorright">
              <p-button
                type="button"
                icon="pi pi-refresh"
                (click)="refresh()"
                styleClass="p-button-text"
              ></p-button>
            </ng-template>
            <ng-template pTemplate="emptymessage">
              <h6>No Results found</h6>
            </ng-template>
          </p-table>
        </div>
      </div>

    <div class="modal-footer mt-2 mb-2">
      <button type="button" [disabled]="selectedIndex == -1"
      [ngClass]="selectedIndex !== -1 ? ' widgetButtonLargeSelectClose red_button' : ' widgetButtonLargeSelectClose'" (click)="selectAndClose()">
        {{'ButtonSelectClose'| translate}}
      </button>
      <button class="widgetButtonLargeSelectClose red_button ms-2" type="button" (click)="onClose()">
        {{'ButtonClose'| translate}}
      </button>
    </div>
</div>
