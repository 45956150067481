<div class="row bg-white1">
  <div class="col-5 text-end">
    <img alt="" src="../../../assets/images/errorpage_robot.jpg" />
  </div>
  <div class="col-7">
    <div class="row">
      <div class="row mt-4">
        <h3 style="color: #004b7a;">404 - Oh no!
          <br />We can't find that page.
        </h3>
      </div>
      <div class="row mt-3">
        <h6>The requested page cannot be found or has been removed. We apologize!</h6>
      </div>
      <!-- <div class="row mt-3">
        <h6>Please <a (click)="goToLogin()">click here</a> to login</h6>
      </div> -->
      <div class="row mt-3">
        <h5>Browse CCRP pages</h5>
        <div class="column1">
          <ul>
            <li><a routerLink="/">Home page</a>
            </li>
            <li><a routerLink="/reports">Reports</a>
            </li>
            <li><a routerLink="/complaint">New complaint</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
