<div class="process-step-pop process-step-section">
    <h2 class="widgetH2 mb-1 mt-1 ps-0">{{ "ProcessStepSearch_SelectInfo" | translate }}</h2>
    <input type="text" (keyup)="filterProcessStep($event)" class="form-control" placeholder="Search by Name (Type at least 3 character)">
    <p-tree [value]="files1" scrollHeight="290px" selectionMode="single" [(selection)]="selectedNode" [loading]="loading" (onNodeExpand)="nodeSelect($event)">
    </p-tree>

    <div class="modal-footer my-2">
        <button class="widgetButtonLargeSelectClose"
            [ngClass]="selectedNode && selectedNode?.label ? '' : 'grayButton'"
            [disabled]="!(selectedNode && selectedNode?.label)"
            (click)="selectAndClose()">{{ "select_ru_popup_btn_select" | translate }}
        </button>

    </div>
</div>
