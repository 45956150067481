import { DomHandler } from 'primeng/dom';
import { CommonModule } from '@angular/common';
import * as i0 from '@angular/core';
import { Directive, Input, HostListener, NgModule } from '@angular/core';
class FocusTrap {
  constructor(el) {
    this.el = el;
  }
  onkeydown(e) {
    if (this.pFocusTrapDisabled !== true) {
      e.preventDefault();
      const focusableElement = DomHandler.getNextFocusableElement(this.el.nativeElement, e.shiftKey);
      if (focusableElement) {
        focusableElement.focus();
        focusableElement.select?.();
      }
    }
  }
}
FocusTrap.ɵfac = function FocusTrap_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || FocusTrap)(i0.ɵɵdirectiveInject(i0.ElementRef));
};
FocusTrap.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: FocusTrap,
  selectors: [["", "pFocusTrap", ""]],
  hostAttrs: [1, "p-element"],
  hostBindings: function FocusTrap_HostBindings(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵlistener("keydown.tab", function FocusTrap_keydown_tab_HostBindingHandler($event) {
        return ctx.onkeydown($event);
      })("keydown.shift.tab", function FocusTrap_keydown_shift_tab_HostBindingHandler($event) {
        return ctx.onkeydown($event);
      });
    }
  },
  inputs: {
    pFocusTrapDisabled: "pFocusTrapDisabled"
  }
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FocusTrap, [{
    type: Directive,
    args: [{
      selector: '[pFocusTrap]',
      host: {
        class: 'p-element'
      }
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }];
  }, {
    pFocusTrapDisabled: [{
      type: Input
    }],
    onkeydown: [{
      type: HostListener,
      args: ['keydown.tab', ['$event']]
    }, {
      type: HostListener,
      args: ['keydown.shift.tab', ['$event']]
    }]
  });
})();
class FocusTrapModule {}
FocusTrapModule.ɵfac = function FocusTrapModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || FocusTrapModule)();
};
FocusTrapModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: FocusTrapModule
});
FocusTrapModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [CommonModule]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FocusTrapModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule],
      exports: [FocusTrap],
      declarations: [FocusTrap]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { FocusTrap, FocusTrapModule };

