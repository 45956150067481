import { Component, OnInit } from '@angular/core';
import { DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-reject-card-modal',
  templateUrl: './reject-card-modal.component.html',
  styleUrls: ['./reject-card-modal.component.css']
})
export class RejectCardModalComponent implements OnInit {
  reason: string;
  constructor(public ref: DynamicDialogRef) { }

  ngOnInit(): void {
  }

  ok() {
    this.ref.close({result: this.reason});
  }

  cancel() {
    this.ref.close({result: false});
  }

}
