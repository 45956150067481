import { Component, Input, OnInit, OnChanges, AfterContentInit, EventEmitter, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroupDirective, FormGroup, Validators } from '@angular/forms';
import { MessageService, PrimeNGConfig } from "primeng/api";
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { IssueSelectionComponent } from '../issue-selection/issue-selection.component';
import { SendEmailIssueAssignComponent } from '../send-email-issue-assign/send-email-issue-assign.component';
// import { EmpolyeeListService } from 'src/app/services/empolyee-list.service';
import { EmployeeListService } from 'src/app/services/employee-list.service';
import { UsersService } from 'src/app/services/users/users.service';
import { ButtonVisibilityServiceService } from 'src/app/services/button-visibility-service.service';
import { AuthorizationService } from 'src/app/services/authorization.service';
import { ComplaintStatuses, PermissionCode, RoleCode } from 'src/app/enum';
import { ResolvingUnitServiceService } from 'src/app/services/resolving-unit-service.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ViewportScroller } from '@angular/common';

@Component({
  selector: 'app-issue-assign',
  templateUrl: './issue-assign.component.html',
  styleUrls: ['./issue-assign.component.css'],
  providers: [DialogService]
})
export class IssueAssignComponent implements OnInit, AfterContentInit, OnChanges {

  @Input() isSubmitted: boolean = false;
  @Input() classDetails: boolean;
  @Input() radioCheckBtnEnable: boolean;
  @Input() formGroupName!: string;
  @Input() coordinators: [];
  @Input() issueCaptureMainForm: FormGroup;
  @Output() assignEventPassed = new EventEmitter();
  @Output() saveEventPassed = new EventEmitter();
  @Output() returnToIssueOwnerEventPassed = new EventEmitter();
  @Input() complaintData!: any
  @Input() users: any[] = [];
  @Input() complaintValidations: any = [];
  @Input() ActionNameForComplaints: string = "Assign";
  @Input() fieldNameForFailureMode: string = "None";
  @Input() readonlyObj !:any;
  solutionWillBeValidatedByCoordinatorSection: boolean = false;
  @Output() VerifySerialEventPassed = new EventEmitter();

  issueAssignment!: FormGroup;
  issueResolutionData!: FormGroup;
  textboxEnabled = false;
  verifiedCheckBox = '';
  coordinatorName = '';
  AddNominatedPerson = '';
  // radioEnable = '0';
  textBox: boolean = true;
  coordinatorList: any;
  // coordinators: any;
  proposedResolutionOwnerText = '';
  mailList: any;
  filteredRecord: any = [];
  filteredRecordNominatedPerson: any = [];
  filteredRecordTeam: any = [];
  radioChange: boolean;


  //code For Tool Tip in Issue Assignment Section
  resoultionOwnerText = 'Type to search and add the name of a person already working on the issue or who has agreed to work on the issue. This will be a proposal to be confirmed by the Resolving Unit Coordinator or the proposed person. The name should be entered in the following format: firstname.lastname@hitachienergy.com';
  resoultionOwnerTeamText = 'Resolution Owner or Coordinator can add people to the Resolution Team for this issue. Any person added to the team will have the same access rights as the Resolution Owner. The name should be entered in the following format: firstname.lastname@hitachienergy.com'
  substituteText = "You can add the name(s) of people who can act in your name in case you are or will not be available to respond to the Resolution Team. The name should be entered in the following format: firstname.lastname@hitachienergy.com";
  issueCoordinatorText = '';
  resolutionOwnerToolTipInfo = 'Type to search and add the name of a person already working on the issue or who has agreed to work on the issue. This will be a proposal to be confirmed by the Resolving Unit Coordinator or the proposed person. The name should be entered in the following format: firstname.lastname@hitachienergy.com'
  resolutionOwnerTeamToolTipInfo = 'Resolution Owner or Coordinator can add people to the Resolution Team for this issue. Any person added to the team will have the same access rights as the Resolution Owner. The name should be entered in the following format: firstname.lastname@hitachienergy.com'
  createRCAToolTipInfo = 'Select this button if you intend to follow all steps of the Root Cause Analysis Process. This button will be set by default in cases of "High Impact", "RCA required by Customer".'
  linkToIssueToolTipInfo = 'Select this option in cases where RCA for exact same issue is already in process. This issue will then be linked to the other issue and no new RCA will be required.'
  actionsNotRequiredToolTipInfo = 'Select this option if RCA is not required at this moment for this type of issue and you intend to review it along with others for possible recurring issue RCA.'
  textBoxToolTipInfo = ''
  solutionToolTipInfo = 'Check this box if you the Coordinator wish to verify and validate solution before it is sent to the Issue Owner and Customer. If selected you will receive a notification when Resolution Owner submits the issue as "Resolved"'
  addPersonToolTipInfo = 'Before the solution for "Class C" CCRP can be sent to the Issue Owner/Customer it must be verified by one of the additional nominated person(s)'
  saveBtnToolTipInfo = 'Saves the issue.'
  returnToIssueOwnerBtnToolTipInfo = 'Use this button in cases where issue has been submitted to the wrong Resolving Unit. You will be prompted to explain why you are returning the issue.'
  assignBtnTipInfo = 'Assigns issue to the selected Resolution Unit.'

  enumPermission = PermissionCode;
  enumRoleCode = RoleCode;
  enumComplaintStatuses = ComplaintStatuses;
  userAccessData = null
  disableBtn: any = {};
  @Input() isResolvingUnitModified:any;

  constructor(
    private fb: FormBuilder,
    private primengConfig: PrimeNGConfig,
    private employeeService: EmployeeListService,
    private messageService: MessageService,
    private dialogService: DialogService,
    private ref: DynamicDialogRef,
    private rootFormGroup: FormGroupDirective,
    private usersService: UsersService,
    public authorizationService: AuthorizationService,
    private buttonVisibilityService: ButtonVisibilityServiceService,
    private resolvingUnitservice: ResolvingUnitServiceService,
    private spinner:NgxSpinnerService,
    private scroller: ViewportScroller) { }


  ngOnInit(): void {
    this.issueAssignment = this.rootFormGroup.control?.get(this.formGroupName) as FormGroup;
    this.issueResolutionData = this.rootFormGroup.control?.get('issueResolutionForm') as FormGroup;
    this.primengConfig.ripple = true;

    // code for reset the text box


    this.authorizationService.userAccessDetail.subscribe(async detail => {
      if (detail) {
        this.userAccessData = detail;
        this.authorizationBtnDisable();
      }
    });

    this.issueAssignment.controls['quickFixVariant'].valueChanges.subscribe((actionType) => {
      if(this.issueAssignment.controls['quickFixVariant'].value==1){
        this.issueAssignment.controls['quickFixComplaint'].setValidators([Validators.required]);
        this.issueAssignment.controls['quickFixComplaint'].updateValueAndValidity();
      }
      else{
        this.issueAssignment.controls['quickFixComplaint'].clearValidators();
        this.issueAssignment.controls['quickFixComplaint'].updateValueAndValidity();
      }
    })

  }
  ngOnChanges(changes: SimpleChanges): void {
    this.setFieldsValidation(this.ActionNameForComplaints);

    const isConfirm = this.complaintData?.areaComplaintCollection?.complaintInformationSection?.highImpactValidationViewModel?.isConfirm;
    const isHighImpactData = this.complaintData?.areaComplaintCollection?.complaintInformationSection?.highImpact?.hasHightImpactOnABB;
    if(isHighImpactData && isConfirm){
      this.issueAssignment?.controls['solutionWillBeValidatedByCoordinator'].disable();
      this.issueAssignment?.controls['coordinatorList'].disable();
    }
  }
  variantChange(){
      if (this.issueAssignment.get('quickFixVariant').value != '1') {
        this.issueAssignment.controls['ccrpNo'].reset();
        this.issueAssignment.controls['quickFixComplaint'].reset();
      }
  }

  // code for coordinator List
  ngAfterContentInit() {
    setTimeout(() => {
      this.setFieldsValidation(this.ActionNameForComplaints);
    }, 3000);
    if (this.complaintData != undefined && this.issueAssignment) {
      let ruId = this.complaintData?.areaComplaintCollection?.resolvingUnitSection?.resolvingUnit?.id;
      let highClass = this.complaintData?.areaComplaintCollection?.complaintInformationSection?.highImpact?.hasHightImpactOnABB;
      let matClass = this.complaintData?.areaComplaintResolution?.matrixCurrentRatingViewModel?.classFriendlyResult;
      let procesStep = this.complaintData?.areaComplaintCollection?.complaintInformationSection?.descriptionSection?.inWhichProcessStep?.id;
      const resolvingProcessId = { resolvingUnitId: ruId, processStepId: procesStep ? procesStep : '', isHightImpact: highClass ? highClass : false, matrixClass: matClass ? matClass : '' }
      this.resolvingUnitservice.getVerificationTeam(resolvingProcessId).subscribe(
        (RUdata: any) => {
          let RUCOList = [];
          RUCOList = RUdata.data;
          RUCOList.map((res) => { res.selectedLabel = res.displayLabel })
          this.issueAssignment.patchValue({
            coordinatorList: RUCOList
          });
        }
      )
    }
    if (this.complaintData != undefined && this.issueAssignment) {
     // console.log("====",this.complaintData);
      this.filteredRecord = this.complaintData?.areaComplaintAssignment?.caResolutionOwner?.primaryResolutionOwner ?
        [{ displayLabel: this.complaintData?.areaComplaintAssignment?.caResolutionOwner?.primaryResolutionOwner?.displayLabel, selectedLabel: this.complaintData?.areaComplaintAssignment?.caResolutionOwner?.primaryResolutionOwner?.displayLabel, promptLabel: this.complaintData?.areaComplaintAssignment?.caResolutionOwner?.primaryResolutionOwner?.email, tooltipInfo: this.complaintData?.areaComplaintAssignment?.caResolutionOwner?.primaryResolutionOwner?.tooltipInfo, geid: this.complaintData?.areaComplaintAssignment?.caResolutionOwner?.primaryResolutionOwner?.geid, identification: this.complaintData?.areaComplaintAssignment?.caResolutionOwner?.primaryResolutionOwner?.identification, role: this.complaintData?.areaComplaintAssignment?.caResolutionOwner?.primaryResolutionOwner?.role }] : [];
      this.complaintData?.areaComplaintAssignment?.caResolutionOwner?.resolutionOwnerTeam.map((team) => {
        this.filteredRecordTeam.push({ displayLabel: team?.displayLabel, selectedLabel: team?.displayLabel, promptLabel: team?.email, tooltipInfo: team?.tooltipInfo, geid: team?.geid, identification: team?.identification, role: team?.role })
      })
      let radioEnableValueConversion: string;
      let linkToRCAComp: string;
        // console.log("<><><><>",this.complaintData?.areaComplaintCollection?.complaintInformationSection?.highImpact?.hasHightImpactOnABB , this.complaintData?.areaComplaintAssignment?.sectionQuickFix?.quickFixVariant);
        radioEnableValueConversion = '' + ((this.complaintData?.areaComplaintResolution?.isEightDRCARequiredCurrent && this.complaintData?.areaComplaintAssignment?.sectionQuickFix?.quickFixVariant==2) || (this.complaintData?.areaComplaintCollection?.complaintInformationSection?.highImpact?.hasHightImpactOnABB==true && this.complaintData?.areaComplaintAssignment?.sectionQuickFix?.quickFixVariant==2) ? 0 : this.complaintData?.areaComplaintAssignment?.sectionQuickFix?.quickFixVariant);
       /* if (radioEnableValueConversion == "1") { */
          linkToRCAComp = this.checkBlank(this.complaintData?.areaComplaintAssignment?.sectionQuickFix?.quickFixComplaint?.displayLabel);
       /* } else {
          linkToRCAComp = '';
        }*/

      this.issueAssignment.patchValue({
        solutionWillBeValidatedByCoordinator: this.complaintData?.areaComplaintAssignment?.solutionWillBeValidatedByCoordinator,
        quickFixVariant: radioEnableValueConversion,
        PrimaryResolutionOwner: this.filteredRecord,
        resolutionOwnerTeam: this.filteredRecordTeam,
        ccrpNo: linkToRCAComp,
        quickFixComplaint: this.complaintData?.areaComplaintAssignment?.sectionQuickFix?.quickFixComplaint,
        //AddNominatedPerson: this.filteredRecordNominatedPerson
      })
    }
    /* if (this.complaintData != undefined && this.issueAssignment) {
       this.issueAssignment.patchValue({
         PrimaryResolutionOwner: this.users.filter(user => user.geid == this.complaintData?.proposedResolutionOwnerGeid)[0],
         resolutionOwnerTeam: this.coordinators,
          ccrpNo: this.checkBlank(this.complaintData?.complaintAssignment?.complaintId),
          AddNominatedPerson: this.coordinators
       })
     }*/
    //  console.log("=====",this.issueAssignment);
    this.lockSolutionWillBeValidatedByCoordinator();
  }

  authorizationBtnDisable() {
    if(this.complaintData?.sapComplaintInfo?.sapLockStatus == true && this.complaintData?.sapComplaintInfo?.isQnNumberGenerated == true){
      this['saveBtn_issueAssign'] = true;
      this['returnCO_issueAssign'] = true;
      this['assignBtn_issueAssign'] = true;
      return;
    }
    if (this.userAccessData) {
      switch (this.userAccessData['roleCode']) {

        case this.enumRoleCode.Complaint_Owner:
          this.disableBtn['saveBtn_issueAssign'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [3, 4, 5, 11, 12], [206], this.userAccessData);
          this.disableBtn['returnCO_issueAssign'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [3], [208], this.userAccessData);
          this.disableBtn['assignBtn_issueAssign'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [3], [202], this.userAccessData);
          break;
        case this.enumRoleCode.Complaint_Owner_Supervisor:
          this.disableBtn['saveBtn_issueAssign'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [3, 4, 5, 11, 12], [206], this.userAccessData);
          this.disableBtn['returnCO_issueAssign'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [3], [208], this.userAccessData);
          this.disableBtn['assignBtn_issueAssign'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [3], [202], this.userAccessData);
          break;
        case this.enumRoleCode.Complaint_Coordinator:
          this.disableBtn['saveBtn_issueAssign'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [3, 4, 5, 11, 12], [206], this.userAccessData);
          this.disableBtn['returnCO_issueAssign'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [3], [208], this.userAccessData);
          this.disableBtn['assignBtn_issueAssign'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [3], [202], this.userAccessData);

          break;
          case this.enumRoleCode.Resolution_Owner:
          this.disableBtn['saveBtn_issueAssign'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [3, 4, 5, 11, 12], [206], this.userAccessData);
          this.disableBtn['returnCO_issueAssign'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [3], [208], this.userAccessData);
          this.disableBtn['assignBtn_issueAssign'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [3], [202], this.userAccessData);

          break;
        case this.enumRoleCode.Support_Desk:
          this.disableBtn['saveBtn_issueAssign'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [3, 4, 5, 11, 12], [206], this.userAccessData);
          this.disableBtn['returnCO_issueAssign'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [3], [208], this.userAccessData);
          this.disableBtn['assignBtn_issueAssign'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [3], [202], this.userAccessData);

          break;
        case this.enumRoleCode.Complaint_Owner_Team:
          this.disableBtn['saveBtn_issueAssign'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [3, 4, 5, 11, 12], [206], this.userAccessData);
          this.disableBtn['returnCO_issueAssign'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [3], [208], this.userAccessData);
          this.disableBtn['assignBtn_issueAssign'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [3], [202], this.userAccessData);

          break;
        case this.enumRoleCode.Resolution_Owner_Team:
          this.disableBtn['saveBtn_issueAssign'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [3, 4, 5, 11, 12], [206], this.userAccessData);
          this.disableBtn['returnCO_issueAssign'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [3], [208], this.userAccessData);
          this.disableBtn['assignBtn_issueAssign'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [3], [202], this.userAccessData);

          break;
        case this.enumRoleCode.Bu_Process_Owner:
          this.disableBtn['saveBtn_issueAssign'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [3, 4, 5, 11, 12], [206], this.userAccessData);
          this.disableBtn['returnCO_issueAssign'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [3], [208], this.userAccessData);
          this.disableBtn['assignBtn_issueAssign'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [3], [202], this.userAccessData);

          break;
        case this.enumRoleCode.Pg_Process_Owner:
          this.disableBtn['saveBtn_issueAssign'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [3, 4, 5, 11, 12], [206], this.userAccessData);
          this.disableBtn['returnCO_issueAssign'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [3], [208], this.userAccessData);
          this.disableBtn['assignBtn_issueAssign'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [3], [202], this.userAccessData);

          break;
        case this.enumRoleCode.Hub_Process_Owner:
          this.disableBtn['saveBtn_issueAssign'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [3, 4, 5, 11, 12], [206], this.userAccessData);
          this.disableBtn['returnCO_issueAssign'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [3], [208], this.userAccessData);
          this.disableBtn['assignBtn_issueAssign'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [3], [202], this.userAccessData);

          break;
        case this.enumRoleCode.Global_CCRP_Process_Owner:
          this.disableBtn['saveBtn_issueAssign'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [3, 4, 5, 11, 12], [206], this.userAccessData);
          this.disableBtn['returnCO_issueAssign'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [3], [208], this.userAccessData);
          this.disableBtn['assignBtn_issueAssign'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [3], [202], this.userAccessData);

          break;
        case this.enumRoleCode.Div_Process_Owner:
          this.disableBtn['saveBtn_issueAssign'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [3, 4, 5, 11, 12], [206], this.userAccessData);
          this.disableBtn['returnCO_issueAssign'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [3], [208], this.userAccessData);
          this.disableBtn['assignBtn_issueAssign'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [3], [202], this.userAccessData);

          break;
      }
    }
  }

  lockSolutionWillBeValidatedByCoordinator() {
    let checkboxHightImpact = this.complaintData?.AreaComplaintCollection?.ComplaintInformationSection?.HighImpact?.HasHightImpactOnABB;
    let checkboxRCA = this.complaintData?.areaComplaintResolution?.IsEightDRCARequiredCurrent;
    let matrixClass = this.complaintData?.areaComplaintResolution?.matrixCurrentRatingViewModel?.classFriendlyResult;
    let hiConfirm = this.complaintData?.areaComplaintCollection?.complaintInformationSection?.highImpactValidationViewModel?.isConfirm

    if (matrixClass == 'Class C') {
      this.solutionWillBeValidatedByCoordinatorSection = true;
    } else if (matrixClass == null || this.complaintData?.header?.complaintStatus < 4) {
      this.solutionWillBeValidatedByCoordinatorSection = false;
    } else if(!hiConfirm){
      this.solutionWillBeValidatedByCoordinatorSection = false;
    }
    else {
      this.solutionWillBeValidatedByCoordinatorSection = true;
    }
  }

  checkBlank(content) {
    if (content == undefined || content == null) {
      return '';
    }
    else {
      return content;
    }
  }
  clearOwner() {
    this.issueAssignment.patchValue({
      PrimaryResolutionOwner: []
    })
  }

  // code for disable the text box and select button
  disabledActions() {
    if (this.issueAssignment.controls['quickFixVariant']?.value == '1') {
      return false;
    }
    else {
      return true;
    }
  }

  // code for open Issue selection popup
  showIssueSelectionPopup() {
    this.ref = this.dialogService.open(IssueSelectionComponent, {
      header: 'Issue selection form ',
      width: '70%',
      position: 'center',
      keepInViewport: true,
      autoZIndex: true,
      styleClass: 'issue-selection-pop',
      data: {
        complaintNo: this.complaintData?.header?.complaintNumber,
        resolvingUnit: this.issueCaptureMainForm?.get('additionalSubjectForm').get('resolvingUnitArray')?.value?.id
      }
    });

    this.ref.onClose.subscribe((receivedValueSelection: any) => {
      if (receivedValueSelection) {
        this.setIssueSelection(receivedValueSelection);
      }
    })
  }
  // code for Issue Selection Popup Tool Tip
  setIssueSelection(receivedValueSelection) {
    // receivedValueSelection.CCRPNo;
    let linkToCompRCA: any = {};

    linkToCompRCA.id = '' + receivedValueSelection?.id;
    linkToCompRCA.identification = {
      "id": '' + receivedValueSelection?.id,
      "geid": null,
      "email": null,
      "cid": null,
      "giS_GUID": null,
      "email_Alias": null
    };
    linkToCompRCA.displayLabel = receivedValueSelection?.complaintNumber;
    linkToCompRCA.tooltipInfo = receivedValueSelection?.tooltipInfo;
    linkToCompRCA.isValid = true;
    // console.log(receivedValueSelection,'selected Complaint');
    if(receivedValueSelection?.id) {
      const sourceId = receivedValueSelection?.id;
      const destinationId = this.complaintData?.header?.id;
      this.resolvingUnitservice.copyRCASection(sourceId, destinationId).subscribe((rcaData) => {
        if(rcaData) {
          const rootCauseData = rcaData?.areaComplaintResolution?.whatWasTheRootCauseOfThisIssue;
          const resolveThisIssue = rcaData?.areaComplaintResolution?.howDidWeResolveThisIssue;
          this.issueResolutionData.patchValue({
            whatWasTheRootCauseOfThisIssue: rootCauseData,
            howDidWeResolveThisIssue: resolveThisIssue
          })
        }
      })
    }
    this.issueAssignment.patchValue({
      ccrpNo: linkToCompRCA.displayLabel,
      quickFixComplaint: linkToCompRCA
    })
    if (this.issueAssignment.controls['ccrpNo'].value == '') {
      this.textBoxToolTipInfo = 'Issue with the same RCA that will be linked.';
    }
    else {
      this.textBoxToolTipInfo = receivedValueSelection.complaintNumber;
    }
  }




  // code for open send email popup
  showEmailTemplatePopup($event: Event) {
    //console.log(this.complaintData.header)
    if (this.issueCaptureMainForm.controls["additionalSubjectForm"].get('isVerifiedSerial')?.value == false &&
      this.issueCaptureMainForm.controls["additionalSubjectForm"].get('BuCode')?.value == 'PGHV' &&
      this.issueCaptureMainForm.controls["additionalSubjectForm"].get('abbProductSerialNo')?.value) {
      this.spinner.hide();
      this.VerifySerialEventPassed.emit({ actionName: "ReturnToCO" });
    } else {
      this.ref = this.dialogService.open(SendEmailIssueAssignComponent, {
        header: 'Send Email',
        width: '80%',
        position: 'center',
        keepInViewport: true,
        autoZIndex: true,
        styleClass: 'send-email-issue-assign-pop',
        data: { complaintNo: this.complaintData.header.complaintNumber, complaintId: this.complaintData.header.id, role: this.userAccessData['roleCode'] }
      });
      this.ref.onClose.subscribe((result) => {
        if (result) {
          this.messageService.add({ severity: 'success', summary: 'Message has been sent!' });
          this.isSubmitted = true;
          this.returnToIssueOwnerEventPassed.emit({ submitted: true })
        }
      })
    }
  }

  onRecipientChange(event: any) {
    //let filtered: any[] = [];
    let query = event.query;
    // for (let i = 0; i < this.users.length; i++) {
    //   let user = this.users[i];
    //   if (user.promptLabel && user.promptLabel?.toLowerCase().indexOf(query.toLowerCase()) == 0) {
    //     filtered.push(user);
    //   }
    // }
    if (query.length > 1) {
      this.usersService.getFilteredUser(query).subscribe((res) => {
        const changesResponse = res.map(item => ({
          ...item,
          displayLabel: item?.selectedLabel,
          identification: {
            ...item?.identification,
            email: item?.selectedLabel
          }
        }))
        this.filteredRecord = changesResponse;
      })
    }
    //this.filteredRecord = filtered;
  }
  onRecipientChangeNominatedPerson(event: any) {
    let query = event.query;
    if (query.length > 1) {
      this.usersService.getFilteredUser(query).subscribe((res) => {
        this.filteredRecordNominatedPerson = res
      })
    }
  }
  onRecipientTeamChange(event: any) {
    //let filtered: any[] = [];
    let query = event.query;
    // for (let i = 0; i < this.users.length; i++) {
    //   let user = this.users[i];
    //   if (user.promptLabel && user.promptLabel?.toLowerCase().indexOf(query.toLowerCase()) == 0) {
    //     filtered.push(user);
    //   }
    // }
    if (query.length > 1) {
      this.usersService.getFilteredUser(query).subscribe((res) => {
        const changesResponse = res.map(item => ({
          ...item,
          displayLabel: item?.selectedLabel,
          identification: {
            ...item?.identification,
            email: item?.selectedLabel
          }
        }))
        this.filteredRecordTeam = changesResponse;
      })
    }
    //this.filteredRecord = filtered;
  }

  /* When owner Got focus */
  resolutionOwnerGotFocus(value) {
    this.resoultionOwnerText = value;
  }
  /* When owner lost focus */
  resolutionOwnerLostFocus() {
    this.resoultionOwnerText = "Type to search and add the name of a person already working on the issue or who has agreed to work on the issue. This will be a proposal to be confirmed by the Resolving Unit Coordinator or the proposed person. The name should be entered in the following format: firstname.lastname@hitachienergy.com";
  }

  /* When owner Got focus */
  resolutionOwnerTeamGotFocus(value) {
    this.resoultionOwnerTeamText = value;
  }
  /* When owner lost focus */
  resolutionOwnerTeamLostFocus() {
    this.resoultionOwnerTeamText = "Resolution Owner or Coordinator can add people to the Resolution Team for this issue. Any person added to the team will have the same access rights as the Resolution Owner. The name should be entered in the following format: firstname.lastname@hitachienergy.com";
  }

  /* When owner Got focus */
  coordinatorGotFocus(coordinator) {
    this.issueCoordinatorText = coordinator?.tooltipInfo;
  }

  /* When owner lost focus */
  coordinatorLostFocus() {
    this.issueCoordinatorText = '';
  }

  /* When owner Got focus */
  addNominatedPersonGotFocus(value: any) {
    this.substituteText = value;
  }

  /* When owner lost focus */
  addNominatedPersonLostFocus() {
    this.substituteText = "Type to search and add the name of a person already working on the issue or who has agreed to work on the issue. This will be a proposal to be confirmed by the Resolving Unit Coordinator or the proposed person. The name should be entered in the following format: firstname.lastname@hitachienergy.com";
  }

  onAssign($event: Event) {
    this.isSubmitted = true;
    this.setFieldsValidation(this.ActionNameForComplaints);
    this.assignEventPassed.emit({ submitted: true })
  }

  onSubmit($event: Event) {
    this.isSubmitted = true;
    this.saveEventPassed.emit({ submitted: true })
    if (this.issueCaptureMainForm.invalid) {
      $event.preventDefault();
    }
  }


  setButtonVisibility(statusCodes: any) {
    let currentComplaintStatus = this.complaintData?.header?.complaintStatus;
    return this.buttonVisibilityService.setButtonVisibility(statusCodes, currentComplaintStatus);
  }

  async setFieldsValidation(action) {
   //  console.log("if")
    for (const controlName in this.issueAssignment?.controls) {
      let validationData = this.complaintValidations.find(complaintValidation => complaintValidation.property?.toLowerCase() == controlName?.toLowerCase() && complaintValidation.complaintAction == action && complaintValidation?.complaintStatusIdsArray?.includes(this.issueCaptureMainForm?.get('headerSection').get('complaintStatusId')?.value?.toString()) && (this.userAccessData == null ? this.userAccessData == null : complaintValidation?.roleIdsArray?.includes(this.userAccessData!['roleCode']?.toString())))
      // console.log(this.complaintValidations,validationData,controlName);
      if (validationData) {
        // console.log(">>>>",this.issueCapture);
        const control = this.issueAssignment.get(controlName);
        const validationRules = this.complaintValidations.filter(complaintValidation => complaintValidation.property?.toLowerCase() == controlName?.toLowerCase() && complaintValidation.complaintAction == action && complaintValidation?.complaintStatusIdsArray?.includes(this.issueCaptureMainForm?.get('headerSection').get('complaintStatusId')?.value?.toString()) && (this.userAccessData == null ? this.userAccessData == null : complaintValidation?.roleIdsArray?.includes(this.userAccessData!['roleCode']?.toString())));

        control.setValidators(this.getValidators(validationRules, this.fieldNameForFailureMode));
        control.updateValueAndValidity(); // Update the validation status
      }
      else {
        // console.log("else for: ", controlName);
        this.issueAssignment.get(controlName).clearValidators();
        this.issueAssignment.get(controlName).updateValueAndValidity();
      }
    }
  }
  private getValidators(rules: any[], fieldFailureModeValidation): any {
    let validators: Validators[] = [];
    let index =0;
    for (const rule of rules) {
      index = index+1;
      if (rule.depends != null) {
        // console.log("<><><><<><>",rule.property,rule.depends,this.issueCapture.controls![rule.depends]?.value?.toString()?.toLowerCase() , rule.value?.toLowerCase() , this.issueDescription.controls![rule.depends]?.value?.toString()?.toLowerCase() , rule.value?.toLowerCase(), this.issueDescription.controls![rule.depends]?.value?.toString()?.toLowerCase() == rule.value?.toLowerCase() , this.issueRating.controls![rule.depends]?.value?.toString()?.toLowerCase() , rule.value?.toLowerCase() , this.additionalSubject.controls![rule.depends]?.value?.toString()?.toLowerCase() , rule.value?.toLowerCase())
        if (fieldFailureModeValidation != "None" && rule.depends == "Validation" && rule.value == fieldFailureModeValidation) {
          validators.push(Validators.required);
         //  console.log("=======>>", rule.property, rule.depends, rule.value,);
          return validators;
        }
        else if ((this.issueCaptureMainForm.controls["issueCaptureForm"].get(rule.depends)?.value != undefined && this.issueCaptureMainForm.controls["issueCaptureForm"].get(rule.depends)?.value?.toString()?.toLowerCase() == rule.value?.toLowerCase()) || (this.issueCaptureMainForm.controls["issueDescriptionForm"].get(rule.depends)?.value != undefined && this.issueCaptureMainForm.controls["issueDescriptionForm"].get(rule.depends)?.value?.toString()?.toLowerCase() == rule.value?.toLowerCase()) || (this.issueCaptureMainForm.controls["ratingForm"].get(rule.depends)?.value != undefined && this.issueCaptureMainForm.controls["ratingForm"].get(rule.depends)?.value?.toString()?.toLowerCase() == rule.value?.toLowerCase()) || (this.issueCaptureMainForm.controls["additionalSubjectForm"].get(rule.depends)?.value != undefined && this.issueCaptureMainForm.controls["additionalSubjectForm"].get(rule.depends)?.value?.toString()?.toLowerCase() == rule.value?.toLowerCase()) || (this.issueCaptureMainForm.controls["issueAssignmentForm"].get(rule.depends)?.value != undefined && this.issueCaptureMainForm.controls["issueAssignmentForm"].get(rule.depends)?.value?.toString()?.toLowerCase() == rule.value?.toLowerCase())) {
          validators.push(Validators.required);
         //  console.log("=======", rule.property, rule.depends, rule.value,);
          return validators;
        }
        else {
        //   console.log("======= blank", validators);
        if(index==rules.length){
          return validators;
         }
        }
      }
      else {
        validators.push(Validators.required);
        return validators;
      }
    }
  }

}
