import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormGroupDirective } from '@angular/forms';
import { sortBy } from 'lodash';
import { NgxSpinnerService } from 'ngx-spinner';
import { map } from 'rxjs';
import { AdminGobalRuService } from 'src/app/services/admin-gobal-ru.service';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { GlobalRuAuthServiceService } from 'src/app/services/global-ru-auth-service.service';
import { ToasterserviceService } from 'src/app/services/toasterservice.service';

@Component({
  selector: 'app-global-ru',
  templateUrl: './global-ru.component.html',
  styleUrls: ['./global-ru.component.css']
})
export class GlobalRUComponent implements OnInit {

  globalRus;
  isLoading = false;

  constructor(
    private adminGobalRuService: AdminGobalRuService,
    private globalRuAuthService: GlobalRuAuthServiceService,
    private toasterService: ToasterserviceService,
    private breadcrumbService: BreadcrumbService,
    private spinnerService: NgxSpinnerService) { }

  ngOnInit(): void {
    this.spinnerService.show();

    this.breadcrumbService.menuItems = [
      { label: 'ADMIN PANEL', routerLink: 'admin'},
      { label: "MANAGE GLOBAL RU'S" }
    ];

    this.loadGlobalRus();

    //this.spinnerService.hide();

  }

  loadGlobalRus(){
    this.isLoading = true;
    this.adminGobalRuService.getGlobalRus().subscribe((data)=>{
      data.forEach(item => {
        item.canManage = this.globalRuAuthService.canManageGlobalRu(item);
        item.isBuBAL = item.divId != null && item.buId == null;
        item.isBuBAL = item.buId != null && item.pgId == null;
        item.isPgBAL = item.pgId != null;
        item.isSelected = item.isGlobalRuActive;
      });

      this.globalRus = data;
      this.isLoading = false;
      this.spinnerService.hide();
    },
    ()=>{
      this.spinnerService.hide();
    });
  }


  markSelection(item){
    item.isSelected = !item.isSelected;
    item.isDirty = item.isSelected != item.isGlobalRuActive;
  }

  updateActivity(item){
    this.spinnerService.show();
    this.adminGobalRuService.updateGlobalRu(item, item.isSelected).subscribe((resp)=>{
      item.isDirty = false;
      item.isGlobalRuActive = item.isSelected;

      this.toasterService.showSuccess('Changes have been done.');
      this.spinnerService.hide();
    },
    ()=>{
      this.spinnerService.hide();
    })
  }

  createBAL(item){
    this.spinnerService.show();
    this.adminGobalRuService.createGlobalRu(item).subscribe((resp)=>{
      item.isGlobalRuActive = true;
      item.isSelected = true;
      this.updateActivity(item);
      this.reloadGlobalRus();

      this.spinnerService.hide();
    },
    ()=>{
      this.spinnerService.hide();
    })
  }

  reloadGlobalRus() {
    this.loadGlobalRus();
  }
}
