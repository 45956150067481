import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthTokenService {

  private authEmail : string;
  private currentCulture : string;
  constructor() { }

  setAuthToken(userEmail: string, CurrentCulture: string){
    this.authEmail = userEmail;
    this.currentCulture = CurrentCulture;
  }

  getAuthUserEmail(){
    return this.authEmail;
  }

  getBrowserLanguage(){
    return this.currentCulture;
  }
}
