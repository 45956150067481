<div class="row">
  <!-- for high impact confirmation if already responded -->
  <div class="row" *ngIf="routeURL == 'AlreadyRespondedPage'">
    <h2 class="text-center">
      <strong>{{'ManualConfirm_AlreadyLogged' | translate}}</strong>
    </h2>
    <p>{{'ManualConfirm_AlreadyLogged_Text' | translate}}</p>
  </div>


  <div class="row" *ngIf="routeURL == 'NotExistPage'">
    <h2 class="text-center">
      <strong>{{'ManualConfirm_InvalidParameters'| translate}}</strong>
    </h2>
  </div>

  <!-- for high impact issues are confirmed -->
  <div class="row" *ngIf="routeURL == 'ThankYouPage'">
    <h2 class="text-center">
      <strong>{{'ManualConfirm_Submitted' | translate}}</strong>
    </h2>
    <p>{{'ManualConfirm_Submitted_Text' | translate}}</p>
  </div>


  <div class="row" *ngIf="routeURL == 'ErrorPage'">
    <h2 class="text-center">
      <strong>{{'TooManyAttempts_Error'| translate}}</strong>
    </h2>
  </div>
  <!-- <div class="row" *ngIf="routeURL == 'ErrorPage'">
    <h2 class="text-center">
      <strong>{{'ManualConfirm_Error' | translate}}</strong>
    </h2>
    <p>{{'ManualConfirm_Error_Text' | translate}}</p>
  </div> -->

  <!-- after submitted survey -->
  <div class="row" *ngIf="routeURL == 'Submitted'">
    <h2 class="text-center">
      <strong>{{'Survey_Status_Submitted' | translate}}</strong>
    </h2>
    <p class="text-center mb-0">{{'Survey_Status_Submitted2' | translate}}</p><br>
    <p class="text-center">{{'Survey_Team' | translate}}</p>
  </div>

  <!-- survey not exist -->
  <div class="row" *ngIf="routeURL == 'SurveyNotExist'">
    <h2 class="text-center">
      <strong>{{'Survey_NotExistHeader' | translate}}</strong>
    </h2>
    <p class="text-center">{{'Survey_NotExistsMessage' | translate}}</p><br>
  </div>

  <!-- survey already taken -->
  <div class="row" *ngIf="routeURL == 'AlreadyTaken'">
    <h2 class="text-center">
      <strong>{{'Survey_Status_AlreadyTaken' | translate}}</strong>
    </h2>
  </div>

    <!-- survey no longer active -->
    <div class="row" *ngIf="routeURL == 'NoLongerActive'">
      <h2 class="text-center">
        <strong>{{'Survey_Status_Expired' | translate}}</strong>
      </h2>
    </div>

    <!-- validation error -->
    <div class="row" *ngIf="routeURL == 'ValidationError'">
      <h2 class="text-center">
        <strong>{{'Survey_Status_Validation_Error' | translate}}</strong>
      </h2>
    </div>

    <!-- error page -->
    <div class="row" *ngIf="routeURL == 'Error'">
      <h2 class="text-center">
        <strong>{{'ExternalPage_Status_Error' | translate}}</strong>
      </h2>
    </div>
</div>
