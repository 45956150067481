import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { PrimeNGConfig, LazyLoadEvent } from 'primeng/api';
import { LocalStorageServiceService } from 'src/app/services/local-storage-service.service';

import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { ProjectSelectionServiceService } from 'src/app/services/project-selection-service.service';
import { ResolvingUnitServiceService } from 'src/app/services/resolving-unit-service.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-pro-misproject-select-pop-up',
  templateUrl: './pro-misproject-select-pop-up.component.html',
  styleUrls: ['./pro-misproject-select-pop-up.component.css'],
})
export class ProMISProjectSelectPopUpComponent implements OnInit {

  proMISProjectForm!: FormGroup;
  projectDetails: Array<any> = [];
  filteredProjectDetails: any;

  first: number = 0;
  rows: number = 10;
  totalRecords: any = 0;
  selectedrow: any;

  proMISBU: Array<any> = [];
  proMISPG: Array<any> = [];
  proMISBusinessArray: any;
  buList:any;
  puList:any;
  getCountryList: any;
  tableheaders: string[] = [
    'Project Number',
    'Project Name',
    'Project Manager',
    'Project Manager',
    'Site Country',
    'Address',
  ];

  selectedBusiness: String = '';
  viewClicked=false;
  loading: boolean = false;

  constructor(private fb: FormBuilder, private primengConfig: PrimeNGConfig,
    private projectService:ProjectSelectionServiceService,
    private readonly dialogRef: DynamicDialogRef,
    private resolvingUnitService: ResolvingUnitServiceService,
    private spinnerService: NgxSpinnerService,
    private localStorageService: LocalStorageServiceService
    ) {}

  ngOnInit(): void {
    this.proMISProjectForm = this.fb.group({
      project: [''],
      Business: ['PG'],
      projectName: [''],
      Bu: [''],
      country: [''],
      Pg: [''],
    });
    this.projectService.getProject().subscribe((Response) => {
       this.projectDetails = Response;
       this.loading = false;
    })

    this.resolvingUnitService.getDivision().subscribe((response)=>{
         this.proMISBusinessArray =response;
         this.changeBU();
    })
    this.getCountryList = this.localStorageService.getFromLocalStorage('mdregion');
  }

  changeBU(bu?: any) {

    let businessText = this.proMISProjectForm.controls['Business'].value;
    const businessArr = businessText.split("(").pop().split(")");
    // console.log('text',businessArr[0]);

    this.resolvingUnitService.getBuList(businessArr[0]).subscribe((res) => {
      if(res) {
        this.buList = res?.data;
        this.buList.map((rr: any) => {
          rr.name =  rr?.value
          rr.pgName = rr?.name
        })
      }
      })


    // this.proMISBU = this.proMISBusinessArray.find(
    //   (cntry: any) => cntry.name == bu.target.value
    // ).proMISBU;
  }

  changePG(pg: any) {
    this.proMISProjectForm.controls['Pg'].reset()
    let businessText = this.proMISProjectForm.controls['Business'].value;
    const businessArr = businessText.split("(").pop().split(")");
    // console.log('text',businessArr[0]);

    let buText = this.proMISProjectForm.controls['Bu'].value;
    const myArray = buText.split("(").pop().split(")");
    // console.log('text',myArray[0]);

    this.resolvingUnitService.getBuPgList(businessArr[0], myArray[0]).subscribe((res) => {
      if(res) {
        this.puList = res.data;
      }
    })


    this.proMISPG = this.proMISBusinessArray.find(
      (cntry: any) => cntry.name == this.selectedBusiness
    ).proMISBU.find((pgA: any) => pgA.name == pg.target.value).proMISPG;
  }

  reset() {
    this.proMISProjectForm.reset();
    this.selectedIndex=-1
    this.filteredProjectDetails = null
    this.proMISBU = []
    this.proMISPG = []

    this.proMISProjectForm.patchValue({
      project: '',
      Business: 'PG',
      projectName: '',
      Bu: '',
      country: '',
      Pg: '',
    });
   this.filteredProjectDetails = [];
   this.totalRecords = 0;
  }

  /* functionality of Paginator from primeng starts */
  next() {
    this.first = this.first + this.rows;
  }

  prev() {
    this.first = this.first - this.rows;
  }

  refresh() {
    this.first = 0;
  }

  isLastPage(): boolean {
    return this.filteredProjectDetails
      ? this.first === this.filteredProjectDetails.length - this.rows
      : true;
  }

  isFirstPage(): boolean {
    return this.filteredProjectDetails ? this.first === 0 : true;
  }

  onPageChange(event: any) {
    event.first = 0;
    event.rows = 10;
    event.page = 1;
    event.pageCount = 5;
  }

  /*  functionality of Paginator from primeng End */

  onClose() {
    this.dialogRef.close();
  }

  /* for selcting the table row data */
selectedIndex = -1;
  selectedRow(index: number,rowdata) {
    this.selectedIndex = index;
    this.selectedrow = rowdata;
    // console.log('selected Data', this.selectedIndex);
  }
  selectAndClose() {
    this.dialogRef.close(this.selectedrow);

  }

  checkBlank(content) {
    if (content == undefined || content == null) {
      return '';
    }
    else {
      return content;
    }
  }

  loadMoreResolvingData(event: any) {
    if(this.viewClicked==true){
    let skipRecord = 0;
    const pageNumber = (event.first + 10) / 10;
    skipRecord += event.first;
    this.loading = true;
    this.viewProjectDetails(pageNumber, skipRecord);
  }
  }

/* for filtering the data based on form fields value */
  viewProjectDetails(pageNumber=0, skipRecord=0) {
    this.spinnerService.show();
    // this.recentlySelected = false;
    // const bailIdValue = this.resolvingPopForm.get('balIdRadioBtn').value == 'balRadio' ? this.resolvingPopForm.controls['balId'].value : ''
    this.viewClicked = true;

    let formatedData = {
      "take": 10,
      "skip": skipRecord,
      "page": pageNumber,
      "pageSize":10 ,
      "sort": [
      {
         "field": "ProjectManagerEmail",
          "dir": "asc"
      }
      ],
      "filter": {
      "logic": "and",
      "filters" : [
      {"field": "ProjectName", "operator": "eq", "value": this.checkBlank(this.proMISProjectForm.controls['projectName'].value),"siteId": "9AAS436180L3298", "siteName": "RWE"},
      {"field": "ProjectNumber", "operator": "eq", "value": this.checkBlank(this.proMISProjectForm.controls['project'].value),"siteId": "9AAS436180L3298", "siteName": "RWE"},
      {"field": "SiteCountry", "operator": "eq", "value": this.checkBlank(this.proMISProjectForm.controls['country'].value),"siteId": "9AAS436180L3298", "siteName": "RWE"},
      {"field": "Business", "operator": "eq", "value": this.checkBlank(this.proMISProjectForm.controls['Business'].value),"siteId": "9AAS436180L3298", "siteName": "RWE"},
      {"field": "BusinessLine", "operator": "eq", "value": this.checkBlank(this.proMISProjectForm.controls['Bu'].value),"siteId": "9AAS436180L3298", "siteName": "RWE"},
      {"field": "ProductGroup", "operator": "eq", "value": this.checkBlank(this.proMISProjectForm.controls['Pg'].value),"siteId": "9AAS436180L3298", "siteName": "RWE"}
      ]
      },
      "group": [],
      "userData": {
        "complaintNo": "",
        "resolvingUnit": 0
      }
      };
  this.projectService.getProMISDetails(formatedData).subscribe((response)=>{
    this.filteredProjectDetails = response?.data;
    this.totalRecords = response?.count;
    if(pageNumber==0 && skipRecord==0){
      this.first = 0;
    }
    this.spinnerService.hide();
    this.loading = false;
  })

  }


}
