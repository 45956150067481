<div class="pgrcaCatAdd">
    <form [formGroup]="pgrcaAddPopForm" (ngSubmit)="onSubmit()">
        <div>
            <div class="input-group mt-3 mobile-display-cls">
            <label class="control-label col-sm-2 me-0 pe-3 mt-1" for=""><b>{{'admin_process_steps_addedit_name_lb' | translate}}</b></label>
            <div class="justify-content-center col-sm-10">
                <input class="form-control addInputField w-100" type="text" name="" value=""
                formControlName="addName" placeholder="{{'admin_pg_categories_addedit_name_ph' | translate}}"
                [ngClass]="{'form-control-error': (this.pgrcaAddPopForm.get('addName').touched && !this.pgrcaAddPopForm.get('addName').valid) || validationItem.hasErrors}">
                <span class="error" *ngIf="this.pgrcaAddPopForm.get('addName').touched && this.pgrcaAddPopForm.get('addName').errors?.['required']">{{'admin_pg_categories_addedit_name_msg_req' | translate}}<br></span>
                <span class="error" *ngIf="this.pgrcaAddPopForm.get('addName').errors?.['maxlength']">{{'admin_pg_categories_addedit_name_msg_maxlength' | translate}}<br></span>
               <span *ngIf="this.pgrcaAddPopForm.get('addName').dirty && validationItem.hasErrors">
                    <span class="error" *ngIf="validationItem.isActiveItem">{{'admin_process_steps_addedit_iefp_active_msg' | translate}}</span>
                    <span class="error" *ngIf="!validationItem.isActiveItem">{{'admin_process_steps_addedit_iefp_notactive_msg' | translate}}</span>
                </span>
                <!-- [class.is-invalid]="f['addName'].invalid && (f['addName'].dirty || f['addName'].touched)"                 -->
                <!-- <div *ngIf="f['addName'].invalid && (f['addName'].dirty || f['addName'].touched)" class="invalid-feedback">
                    <div *ngIf="f['addName'].errors?.['required']">
                        PG RCA Category name is required.
                    </div>
                </div> -->
                
            </div>
        </div>
        
        
        <div class="modal-footer" >
           
            <button class="widgetButtoSmall mx-2" type="button" (click)="close()">{{'admin_process_steps_addedit_cancel_btn_lb' | translate}}</button>
            <button (click)="onSubmit()" type="button" class="widgetButtoSmall"
            [disabled]="this.pgrcaAddPopForm.invalid || !this.pgrcaAddPopForm.dirty || validationItem.hasErrors" 
            >{{'admin_process_steps_addedit_save_btn_lb' | translate}}</button>
            </div>
        </div>
    </form>
</div>