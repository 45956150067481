import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { TreeNode } from 'primeng/api';
import { AccPerAuthService } from '../components/admin/accountablePerson/acc-per-auth.service';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class AdminAccountablePersonService {


  constructor(private http: HttpClient, private apAuthService: AccPerAuthService) { }
  getAccountbleSearchDetails(data){
    return this.http.post(`${environment.azure.baseUrl}api/v1/manageru/searchchecklimit`,data);
  }
  getAccountbleSearchDetails1(data){
    return new Promise<any>((resolve, reject)=>{
      this.http.post(`${environment.azure.baseUrl}api/v1/manageru/searchchecklimit`,data).subscribe((response: any)=>{
        let geids = [];
        response.ruInfo.forEach(item => {
          item.scope = { div: item.divCode, bu: item.buCode, pg: item.pgCode, country: (item?.address) ? item.address.countryCode : null, isGlobal: item.isGlobal }
          item.canManage = this.apAuthService.canManageRu(item.scope, item.ruProcessOwnerGeids);
          item.isSelected = false;

          item.ruProcessOwnerGeids.forEach(geid => {
            geids.push(geid);
          });
          item.coordinatorGeids.forEach(geid => {
            geids.push(geid);
          });
        });

        this.getAccountbleruInfoDetails(_.uniq(geids)).subscribe((ruPersonsInfo: any)=>{
          response.ruInfo.forEach(item => {
            let ruProcessOwners : any = [];
            item.ruProcessOwnerGeids.forEach(ruPoGeid => {
              let ruProcessOwner = ruPersonsInfo.find(ruP => ruPoGeid == ruP.id);
              ruProcessOwners.push(ruProcessOwner);
            });

            let coordinators : any = [];

            item.coordinatorGeids.forEach(coordGeid => {
              let coordinator = ruPersonsInfo.find(ruP=> coordGeid == ruP.id);
              coordinators.push(coordinator);
            });

            item.ruProcessOwners = ruProcessOwners;
            item.coordinators = coordinators;
          });

          resolve(response);
        })
      },
      (error)=>{
        reject(error);
      })
    })
  }

  getAccountbleruInfoDetails(data){
    return this.http.post(`${environment.azure.baseUrl}api/v1/manageru/rupersonsinfo`,data);
  }
  getprocessownersDetails(data){
    return this.http.post(`${environment.azure.baseUrl}api/v1/managehub/findprocessowners`,data);
  }
  getEmployeeInfo(geids){
    let params = new HttpParams();
    let ab = '';
    geids.forEach((element, index, array) => {
      if (index == 0) {
        ab += '' + element
      }
      else {
        ab += ',' + element
      }
    })
    params = params.append('geids', ab);
    return this.http.post(`${environment.azure.baseUrl}api/v1/dashboard/employeeinfo`, { geids: ab});
  }
  getProcessSteps(){
    return this.http.get(`${environment.azure.baseUrl}api/v1/process-steps`);
  }
  getProcessStepId(id) {
    // const parendId = {parentId: ''}
    return this.http
      .get<TreeNode>(`${environment.azure.baseUrl}api/v1/process-steps/` + id)
      .toPromise()
      .then((res) => <TreeNode[]>res);
  }
  addAccountablePerson(data){
    return this.http.post(`${environment.azure.baseUrl}api/v1/manageru/addaccountablepersons`,data);
  }
  getAccoutablePerson(data){
    return this.http.post(`${environment.azure.baseUrl}api/v1/manageru/getaccountablepersons`,data);
  }
  updateAccontablePerson(data){
    return this.http.put(`${environment.azure.baseUrl}api/v1/manageru/updateaccountablepersons`,data);

  }
}
