import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { AbstractControl, FormBuilder, FormControlName, FormGroup, Validators } from '@angular/forms';
import { ResolvingUnitServiceService } from 'src/app/services/resolving-unit-service.service';
import * as _ from 'lodash';
import { PgrcaAdminServiceService } from '../pgrca-admin-service.service';
@Component({
  selector: 'app-pgrca-cat-edit-pop-up',
  templateUrl: './pgrca-cat-edit-pop-up.component.html',
  styleUrls: ['./pgrca-cat-edit-pop-up.component.css'],
})
export class PgrcaCatEditPopUpComponent implements OnInit {
  pgrcaEditPopForm!: FormGroup;
  PGRCACatData: any;
  pgrcaCatDataContent: any;
  addContentdata: any;
  editdata: any;
  pgrcaLoopedData: any;
  submitted: boolean=false;
  validationItem ={
    hasErrors: false,
    isActiveItem: true
  }
  concatEditeddata: any;
  constructor(
    private ref: DynamicDialogRef,
    private fb: FormBuilder,
    private ir: ResolvingUnitServiceService,
    private pgrcaAdminEditService: PgrcaAdminServiceService,
    public config: DynamicDialogConfig
  ) {
    this.PGRCACatData = this.config.data;
    for(let i=0;i<this.PGRCACatData.content.length;i++){
      this.pgrcaLoopedData= this.PGRCACatData.content[i]
    }
    // console.log('add data config', this.PGRCACatData);
    this.pgrcaCatDataContent = this.PGRCACatData.content.parent.children;
  }

  ngOnInit(): void {
    this.pgrcaEditPopForm = this.fb.group({
      editName: ['', [Validators.required, Validators.maxLength(100)]],
      checkBoxEdit: [''],
    });
    this.pgrcaEditPopForm.patchValue({
      editName: this.PGRCACatData.content.name,
      checkBoxEdit: this.PGRCACatData.content.isActive,
    });
    this.pgrcaEditPopForm.get('editName').valueChanges.subscribe(val =>{
      this.validationItem.isActiveItem = true;
      let foundItem = _.filter( this.addContentdata, (sc)=> {
        return sc.name.toUpperCase() === val.toUpperCase();
      });
      if(foundItem.length){
        if(foundItem.length === 1){
          let process = foundItem[0];
          if(!process.isActive){
            this.validationItem.isActiveItem = false;
          }
        }
        this.validationItem.hasErrors = true;
      }
      else{
        this.validationItem.hasErrors = false;
      }
    })

  }

  get f(): { [key: string]: AbstractControl } {
    return this.pgrcaEditPopForm.controls;
  }
  editData() {
    this.submitted = true;
    if (this.pgrcaEditPopForm.invalid) {
      return;
    }
    let payload: any = {
      id: this.PGRCACatData.content.id,
      parentId: this.PGRCACatData.content.parentId,
      name: this.pgrcaEditPopForm.controls['editName'].value,
      isExpanded: this.PGRCACatData.content.isExpanded,
      hasChildren: this.PGRCACatData.content.hasChildren,
      areChildrenLoaded: this.PGRCACatData.content.areChildrenLoaded,
      shouldBeExpanded: this.PGRCACatData.content.shouldBeExpanded,
      isActive: this.pgrcaEditPopForm.controls['checkBoxEdit'].value,
      isAdditionalActionRequired: this.PGRCACatData.content.isAdditionalActionRequired,
      validation: this.PGRCACatData.content.validation,
      isGeneric: this.PGRCACatData.content.isGeneric,
      tag:
      {
        id: this.PGRCACatData.content.tag?.id,
        pgId: this.PGRCACatData.content.tag?.pgId,
        name: this.PGRCACatData.content.tag?.name,
        parentId: this.PGRCACatData.content.tag?.parentId,
        isActive: this.PGRCACatData.content.tag?.isActive,
        productGroupCode: this.PGRCACatData.content.tag?.productGroupCode,
        parents: this.PGRCACatData.content.tag?.parents,
      },
      children:
      this.PGRCACatData.content.children.length > 0 ?
      this.PGRCACatData.content?.children?.map((r) => ({
        areChildrenLoaded: r?.areChildrenLoaded,
        children: r?.children,
        hasChildren: r?.hasChildren,
        id: r?.id,
        isActive: r?.isActive,
        isAdditionalActionRequired: r?.isAdditionalActionRequired,
        isExpanded: r?.isExpanded,
        name : r?.name,
        label: r?.name,
        parentId : r?.parentId,
        shouldBeExpanded : r?.shouldBeExpanded,
        tag : null,
        validation : r?.validation
      })) : [],
      duplicateScope: [],
    };

    for (let i = 0; i < this.pgrcaCatDataContent.length; i++) {
      this.addContentdata = this.pgrcaCatDataContent[i];
      // console.log('for loop data', this.addContentdata);
      payload.duplicateScope?.push({
        name: this.addContentdata?.name,
        isActive: this.addContentdata?.isActive,
        id: this.addContentdata?.id,
      });
      if (i == this.pgrcaCatDataContent.length - 1) {
        const pg = this.PGRCACatData.pgValue;
        this.pgrcaAdminEditService.editChildPgrcaCat(pg, payload).subscribe((editedData) => {
          this.editdata = editedData;
          // console.log('editedData',this.editdata)
          if(editedData){
          payload.nodesToUpdate = editedData.nodesToUpdate;
          payload.state = editedData.state;
          let concatEditeddata = payload;
          this.PGRCACatData.content.label =  this.pgrcaEditPopForm.controls['editName'].value,
          this.PGRCACatData.content.name =  this.pgrcaEditPopForm.controls['editName'].value,
          payload;
          // this.ref.close(payload);
          // this.ref.close(editedData);
          this.ref.close(concatEditeddata);
        }
        // this.ref.close(editedData);
        });
      }
    }
    /* const pg = this.PGRCACatData.pgValue;
    this.ir.editChildPgrcaCat(pg, payload).subscribe((edit) => {
      return edit;
    }); */
  }
  close() {
    this.ref.close();
  }
}
