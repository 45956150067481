import { Component, OnInit } from '@angular/core';
import { ExternalpageEmitService } from 'src/app/components/external/externalpage-emit.service';

@Component({
  selector: 'app-hrgt-error',
  templateUrl: './hrgt-error.component.html',
  styleUrls: ['./hrgt-error.component.css']
})
export class HrgtErrorComponent implements OnInit {

  constructor(private externalPageService: ExternalpageEmitService) { }

  ngOnInit(): void {
    this.externalPageService.setHRGTPageFunction(true);
  }

}
