import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { tap } from 'rxjs';
import { User } from 'src/app/reusables/modals/user';
import { FormGroup } from '@angular/forms';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CustomerContactService {
  contactDetailsForm!:FormGroup;

  constructor(private http: HttpClient) { }

  getUsers(): Observable<any>{
    return this.http.get(`${environment.API_URL}/Users`);
    }

    getCustomerContacts(Data): Observable<any> {
      return this.http.post(`${environment.azure.baseUrl}api/v1/Data/GetCustomerContacts`,Data)
    }

  }

