<div class="row">
    <div class="col-md-10 col-md-offset-1">
        <p class="control-label admin-error-msg">
            <strong style="color: red">Cannot delete the only active Accountable Person.</strong>
        </p>
        <!-- <span data-cc-ru-person-details ru-person-scope="ruPerson"></span>
        <small ng-show="ruPersonType == 1">{{'admin_rudetails_ruprocowner_lbl' | translate}}</small>
        <small ng-show="ruPersonType == 2">{{'admin_rudetails_rucoord_lbl' | translate}}</small> -->
    </div>
    <div class="buton" style="text-align: right;">
        <button type="button" class="widgetButtoMediumView "  (click)="onClose()" 
        style="float: right; margin-left: 5px;">Ok</button>
</div>
