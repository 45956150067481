import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControlName, FormGroup } from '@angular/forms';
import { customerDetails } from 'src/app/reusables/modals/customerSelect.modal';
import { LazyLoadEvent, PrimeNGConfig } from 'primeng/api';
import { CustomerSelectionServiceService } from 'src/app/services/customer-selection/customer-selection-service.service';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { SharedDataService } from 'src/app/services/shared-data.service';
import { SessionStorageServiceService } from 'src/app/services/session-storage-service.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-customer-name-selection-popup',
  templateUrl: './customer-name-selection-popup.component.html',
  styleUrls: ['./customer-name-selection-popup.component.css']
})
export class CustomerNameSelectionPopupComponent implements OnInit {
  countryName: string;
  companyDetails: Array<any> = [];
  companyDetailsForm!: FormGroup;
  filteredCompanyDetails: any;
  first: number = 0;
  firstIndex: number = 0;
  rows: number = 10;
  tableheaders: string[] = [
    'GUID',
    'FullName',
    'ShortName',
    'Address',
    'Customer Type',
  ];
  getcustomerSearchFiltersData: any;
  GetCustomersRecentlySelected: any;
  totalRecords: any = 0 ;
  loading: boolean = false;
  viewClicked: boolean = false;
  recentlySelectedFlag: boolean = true;
  errorSiteIdSelection: boolean = false;
  selectedrow;

  constructor(
    private fb: FormBuilder,
    private primengConfig: PrimeNGConfig,
    private customerService: CustomerSelectionServiceService,
    public ref: DynamicDialogRef,
    private sharedDataService: SharedDataService,
    private spinnerService: NgxSpinnerService,
    private sessionService: SessionStorageServiceService
  ) {  }

  //US - 7559: Customer Selection - External | Show Customer - Type accordingly - Implementation-- to check if customer type is external or internal--added filter custtype customer type to check for customer is external or internal
  ngOnInit(): void {
    this.spinnerService.show();
    this.recentlySelectedFlag = true;
    this.companyDetailsForm = this.fb.group({
      companyName: [''],
      companyAddress: [''],
      country: [''],
      city: [''],
      globalHQGuid: [''],
      siteID: [''],
      businessPartnerGUID: [''],
      custtype:['']
    });
    this.primengConfig.ripple = true;
   //  console.log('countryCode',this.getcustomerSearchFiltersData)
   // console.log('countryCodeestore',this.companyDetailsForm.controls['country'])

    this.customerService.getCustomerSearchFiltersDetails().subscribe((Res) => {
      this.getcustomerSearchFiltersData = Res.countries;
      this.getcustomerSearchFiltersData=Res.countries;
    })
    const CurrentUserGeid = this.sessionService.getData('userInfo').geid;
    this.loading = true;
    this.customerService.GetCustomersRecentlySelected(CurrentUserGeid).subscribe((Resp) => {
      if(Resp) {
        this.filteredCompanyDetails = Resp.data;
        this.totalRecords = Resp?.count;
        this.spinnerService.hide();
        this.loading = false;
      }
    })
  }



  /* functionality of Paginator from primeng starts */
  next() {
    this.first = this.first + this.rows;
  }

  prev() {
    this.first = this.first - this.rows;
  }

  refresh() {
    this.first = 0;

  }

  isLastPage(): boolean {
    return this.filteredCompanyDetails
      ? this.first === this.filteredCompanyDetails.length - this.rows
      : true;
  }

  isFirstPage(): boolean {
    return this.filteredCompanyDetails ? this.first === 0 : true;
  }

  onPageChange(event: any) {
    event.first = 0;
    event.rows = 9;
    event.page = 1;
    event.pageCount = 9;
  }

  /*  functionality of Paginator from primeng End */
  //US - 7559: Customer Selection -External | Show Customer - Type accordingly - Implementation   -- to check if customer type is external or internal   --added filter custtype customer type to check for customer is external or internal
  reset() {
    this.recentlySelectedFlag = true;
    this.companyDetailsForm.reset();
    this.filteredCompanyDetails = null;
    this.companyDetailsForm.patchValue({
      companyName: '',
      companyAddress: '',
      country: '',
      city: '',
      globalHQGuid: '',
      siteID: '',
      businessPartnerGUID: '',
      custtype:''
    });
    this.selectedIndex = -1
    this.loading = true;
    const CurrentUserGeid = this.sessionService.getData('userInfo').geid
    this.customerService.GetCustomersRecentlySelected(CurrentUserGeid).subscribe((Resp) => {
      this.filteredCompanyDetails = Resp.data;
      this.totalRecords = Resp?.count;
      this.loading = false;
    })
  }
  selectedIndex = -1;
  selectedRow(index: number, rowdata: any) {
    this.selectedIndex = index;
    this.selectedrow = rowdata;
  }
  selectAndClose() {
    // console.log('++++++',this.filteredCompanyDetails[this.selectedIndex],this.selectedrow);
    this.ref.close(this.selectedrow);
    this.countryName = this.selectedrow?.guid;
  }

  siteIdSelect(){
    this.errorSiteIdSelection = true;
  }

  onClose() {
    this.ref.close('modify');
  }

  // onView() {
  //   this.filteredCompanyDetails = this.companyDetails.slice().filter(
  //     (details) =>
  //       details.name === this.companyDetailsForm.controls['country'].value
  //   );
  // }

  /*  {

    "field": "SelectedFilters",
     "value": "{\"guid\":null,\"tuid\":null,\"legalName\":null,\"street\":null,\"city\":null,\"zipCode\":null,\"state\":null,\"globalHqGuid\":null,\"companyRelation\":\"customer,customerAndSupplier,undesignated\"}", "siteId": "", "siteName": ""
   }, */
  //           "value":"{\"guid\: " + this.checkBlank(this.companyDetailsForm.controls['businessPartnerGUID'].value), \"tuid\": null ,\"legalName\": + this.checkBlank(this.companyDetailsForm.controls['companyName'].value),
  //           "street":null,"country": + this.checkBlank(this.companyDetailsForm.controls['country'].value),
  //           "city":null,"zipCode":null,"state":null,"globalHqGuid":null,
  //           "companyRelation":"customer,customerAndSupplier,undesignated\"}"

  loadMoreResolvingData(event: any) {
    if(this.viewClicked==true){
    let skipRecord = 0;
    const pageNumber = (event.first + 10) / 10;
    skipRecord += event.first;
    this.loading = true;
    this.dataView(pageNumber, skipRecord);
  }
  }

  dataView(pageNumber=1, skipRecord=0) {
    this.spinnerService.show();
    this.recentlySelectedFlag = false;
    this.viewClicked=true;
    let customerData = {
      "take":10,
      "skip":skipRecord,
      "page":pageNumber,
      "pageSize":10,
      "custtype":  this.companyDetailsForm.controls["custtype"].value,
      "filter":
        {
        "logic":"and",
          "filters":
          [
            {
              "field": "SelectedFilters",
// US - 7559: Customer Selection -External | Show Customer - Type accordingly - Implementation   -- to check if customer type is external or internal   --added filter custtype to check for customer is external or internal
// Also removed \"isInternalBusinessPartner\":false filter from the below array to fetch all the customer types from the API response
              // "value":"{\"guid\":\""+ this.checkBlank(this.companyDetailsForm.controls['businessPartnerGUID'].value) + "\",\"legalName\":\""+ this.checkBlank(this.companyDetailsForm.controls['companyName'].value) + "\",\"street\":null,\"country\": \""+ this.checkBlank(this.companyDetailsForm.controls['country'].value) + "\" ,\"city\":null,\"zipCode\":null,\"state\":null,\"globalHqGuid\":null,\"companyRelation\":\"supplier,customerAndSupplier,undesignated\",\"isInternalBusinessPartner\":false}",
              "value": "{\"guid\":\"" + this.checkBlank(this.companyDetailsForm.controls['businessPartnerGUID'].value) + "\",\"legalName\":\"" + this.checkBlank(this.companyDetailsForm.controls['companyName'].value) + "\",\"street\":\"" + this.checkBlank(this.companyDetailsForm.controls['companyAddress'].value) + "\",\"country\": \"" + this.checkBlank(this.companyDetailsForm.controls['country'].value) + "\" ,\"city\": \"" + this.checkBlank(this.companyDetailsForm.controls['city'].value) + "\" ,\"zipCode\":null,\"state\":null,\"globalHqGuid\":\"" + this.checkBlank(this.companyDetailsForm.controls['globalHQGuid'].value) + "\",\"isInternalBusinessPartner\":\"" + this.companyDetailsForm.controls["custtype"].value + "\",\"companyRelation\":\"customer,customerAndSupplier,undesignated\"}",

              "siteId":null,
              "siteName":null
            }
          ]
        },
        "group":[]

  }

    this.loading = true;
    this.customerService.GetCustomers(customerData)
      .subscribe((res: any) => {
        if(res) {
          this.filteredCompanyDetails = res?.data;
          this.totalRecords = res?.count;
          this.spinnerService.hide();
          this.loading = false;
        }
      });

  }
  checkBlank(content) {
    if (content == undefined || content == null) {
      return '';
    } else {
      return content;
    }
  }

//   viewCustomerContact() {
//     this.filteredCompanyDetails =
//       Array.isArray(this.companyDetails) &&
//       this.companyDetails.filter((details) => {
//         //based on 7 fields
//         // for companyName, country, globalHQGuid, businessPartnerGUID, companyAddress, city, siteId

// if(
//   details.FullName && this.companyDetailsForm.controls['companyName'].value &&
//   details.name && this.companyDetailsForm.controls['country'].value &&
//   details.GlobalHQGuid && this.companyDetailsForm.controls['globalHQGuid'].value &&
//   details.Guid && this.companyDetailsForm.controls['businessPartnerGUID'].value &&
//   details.Address && this.companyDetailsForm.controls['companyAddress'].value &&
//   details.City && this.companyDetailsForm.controls['city'].value &&
//   details.SiteID && this.companyDetailsForm.controls['siteID'].value
// ) {

//   return (

//     details.FullName.toLowerCase().includes(this.companyDetailsForm.controls['companyName'].value.toLowerCase()) &&
//     details.name.toLowerCase().includes(this.companyDetailsForm.controls['country'].value.toLowerCase()) &&
//     details.GlobalHQGuid.toLowerCase().includes(this.companyDetailsForm.controls['globalHQGuid'].value.toLowerCase()) &&
//     details.Guid.toLowerCase().includes(this.companyDetailsForm.controls['businessPartnerGUID'].value.toLowerCase()) &&
//     details.Address.toLowerCase().includes(this.companyDetailsForm.controls['companyAddress'].value.toLowerCase()) &&
//     details.City.toLowerCase().includes(this.companyDetailsForm.controls['city'].value.toLowerCase()) &&
//     details.SiteID.toLowerCase().includes(this.companyDetailsForm.controls['siteID'].value.toLowerCase()) == true

//   );
// }

//         //All 7 Empty field condition

//         else if (
//           (details.name &&
//             this.companyDetailsForm.controls['country'].value) === '' &&
//           (details.Guid &&
//             this.companyDetailsForm.controls['businessPartnerGUID'].value) ===
//           '' &&
//           (details.Address &&
//             this.companyDetailsForm.controls['companyAddress'].value) === '' &&
//           (details.FullName &&
//             this.companyDetailsForm.controls['companyName'].value) === '' &&
//           (details.City && this.companyDetailsForm.controls['city'].value) ===
//           '' &&
//           (details.GlobalHQGuid &&
//             this.companyDetailsForm.controls['globalHQGuid'].value) === '' &&
//           (details.SiteID &&
//             this.companyDetailsForm.controls['siteID'].value) === ''
//         ) {
//           return (
//             (details.name ===
//               this.companyDetailsForm.controls['country'].value) ===
//             null &&
//             (details.Guid ===
//               this.companyDetailsForm.controls['businessPartnerGUID'].value) ===
//             null &&
//             (details.Address ===
//               this.companyDetailsForm.controls['companyAddress'].value) ===
//             null &&
//             (details.FullName ===
//               this.companyDetailsForm.controls['companyName'].value) ===
//             null &&
//             (details.City ===
//               this.companyDetailsForm.controls['city'].value) ===
//             null &&
//             (details.GlobalHQGuid ===
//               this.companyDetailsForm.controls['globalHQGuid'].value) ===
//             null &&
//             (details.SiteID ===
//               this.companyDetailsForm.controls['siteID'].value) ===
//             null

//           );
//         }

//         /* based on 6 fields */
//         // for country, businessPartnerGUID, companyAddress, companyName, city, globalHQGuid


//         else if (
//           details.name && this.companyDetailsForm.controls['country'].value &&
//           details.Guid && this.companyDetailsForm.controls['businessPartnerGUID'].value &&
//           details.Address && this.companyDetailsForm.controls['companyAddress'].value &&
//           details.FullName && this.companyDetailsForm.controls['companyName'].value &&
//           details.City && this.companyDetailsForm.controls['city'].value &&
//           details.GlobalHQGuid && this.companyDetailsForm.controls['globalHQGuid'].value
//         ) {

//           return (

//             details.name.toLowerCase().includes(this.companyDetailsForm.controls['country'].value.toLowerCase()) &&
//             details.Guid.toLowerCase().includes(this.companyDetailsForm.controls['businessPartnerGUID'].value.toLowerCase()) &&
//             details.Address.toLowerCase().includes(this.companyDetailsForm.controls['companyAddress'].value.toLowerCase()) &&
//             details.FullName.toLowerCase().includes(this.companyDetailsForm.controls['companyName'].value.toLowerCase()) &&
//             details.City.toLowerCase().includes(this.companyDetailsForm.controls['city'].value.toLowerCase()) &&
//             details.GlobalHQGuid.toLowerCase().includes(this.companyDetailsForm.controls['globalHQGuid'].value.toLowerCase()) == true

//           );
//         }
//         //based on 6 fields
//         // for companyName, country, globalHQGuid, companyAddress, city, siteId
//         else if (
//           details.FullName && this.companyDetailsForm.controls['companyName'].value &&
//           details.name && this.companyDetailsForm.controls['country'].value &&
//           details.GlobalHQGuid && this.companyDetailsForm.controls['globalHQGuid'].value &&
//           details.Address && this.companyDetailsForm.controls['companyAddress'].value &&
//           details.City && this.companyDetailsForm.controls['city'].value &&
//           details.SiteID && this.companyDetailsForm.controls['siteID'].value
//         ) {

//           return (

//             details.FullName.toLowerCase().includes(this.companyDetailsForm.controls['companyName'].value.toLowerCase()) &&
//             details.name.toLowerCase().includes(this.companyDetailsForm.controls['country'].value.toLowerCase()) &&
//             details.GlobalHQGuid.toLowerCase().includes(this.companyDetailsForm.controls['globalHQGuid'].value.toLowerCase()) &&
//             details.Address.toLowerCase().includes(this.companyDetailsForm.controls['companyAddress'].value.toLowerCase()) &&
//             details.City.toLowerCase().includes(this.companyDetailsForm.controls['city'].value.toLowerCase()) &&
//             details.SiteID.toLowerCase().includes(this.companyDetailsForm.controls['siteID'].value.toLowerCase()) == true

//           );
//         }

//         // for companyName, country, businessPartnerGUID, companyAddress, city, siteId

//         else if (
//           details.FullName && this.companyDetailsForm.controls['companyName'].value &&
//           details.name && this.companyDetailsForm.controls['country'].value &&
//           details.Guid && this.companyDetailsForm.controls['businessPartnerGUID'].value &&
//           details.Address && this.companyDetailsForm.controls['companyAddress'].value &&
//           details.City && this.companyDetailsForm.controls['city'].value &&
//           details.SiteID && this.companyDetailsForm.controls['siteID'].value
//         ) {

//           return (

//             details.FullName.toLowerCase().includes(this.companyDetailsForm.controls['companyName'].value.toLowerCase()) &&
//             details.name.toLowerCase().includes(this.companyDetailsForm.controls['country'].value.toLowerCase()) &&
//             details.Guid.toLowerCase().includes(this.companyDetailsForm.controls['businessPartnerGUID'].value.toLowerCase()) &&
//             details.Address.toLowerCase().includes(this.companyDetailsForm.controls['companyAddress'].value.toLowerCase()) &&
//             details.City.toLowerCase().includes(this.companyDetailsForm.controls['city'].value.toLowerCase()) &&
//             details.SiteID.toLowerCase().includes(this.companyDetailsForm.controls['siteID'].value.toLowerCase()) == true

//           );
//         }
//         // for country, globalHQGuid, businessPartnerGUID, companyAddress, city, siteId

//         else if (
//           details.name && this.companyDetailsForm.controls['country'].value &&
//           details.GlobalHQGuid && this.companyDetailsForm.controls['globalHQGuid'].value &&
//           details.Guid && this.companyDetailsForm.controls['businessPartnerGUID'].value &&
//           details.Address && this.companyDetailsForm.controls['companyAddress'].value &&
//           details.City && this.companyDetailsForm.controls['city'].value &&
//           details.SiteID && this.companyDetailsForm.controls['siteID'].value
//         ) {

//           return (

//             details.name.toLowerCase().includes(this.companyDetailsForm.controls['country'].value.toLowerCase()) &&
//             details.GlobalHQGuid.toLowerCase().includes(this.companyDetailsForm.controls['globalHQGuid'].value.toLowerCase()) &&
//             details.Guid.toLowerCase().includes(this.companyDetailsForm.controls['businessPartnerGUID'].value.toLowerCase()) &&
//             details.Address.toLowerCase().includes(this.companyDetailsForm.controls['companyAddress'].value.toLowerCase()) &&
//             details.City.toLowerCase().includes(this.companyDetailsForm.controls['city'].value.toLowerCase()) &&
//             details.SiteID.toLowerCase().includes(this.companyDetailsForm.controls['siteID'].value.toLowerCase()) == true

//           );
//         }
//         // for companyName, globalHQGuid, businessPartnerGUID, companyAddress, city, siteId

//         else if (
//           details.FullName && this.companyDetailsForm.controls['companyName'].value &&
//           details.GlobalHQGuid && this.companyDetailsForm.controls['globalHQGuid'].value &&
//           details.Guid && this.companyDetailsForm.controls['businessPartnerGUID'].value &&
//           details.Address && this.companyDetailsForm.controls['companyAddress'].value &&
//           details.City && this.companyDetailsForm.controls['city'].value &&
//           details.SiteID && this.companyDetailsForm.controls['siteID'].value
//         ) {

//           return (

//             details.FullName.toLowerCase().includes(this.companyDetailsForm.controls['companyName'].value.toLowerCase()) &&
//             details.GlobalHQGuid.toLowerCase().includes(this.companyDetailsForm.controls['globalHQGuid'].value.toLowerCase()) &&
//             details.Guid.toLowerCase().includes(this.companyDetailsForm.controls['businessPartnerGUID'].value.toLowerCase()) &&
//             details.Address.toLowerCase().includes(this.companyDetailsForm.controls['companyAddress'].value.toLowerCase()) &&
//             details.City.toLowerCase().includes(this.companyDetailsForm.controls['city'].value.toLowerCase()) &&
//             details.SiteID.toLowerCase().includes(this.companyDetailsForm.controls['siteID'].value.toLowerCase()) == true

//           );
//         }
//         // for companyName, country, globalHQGuid, businessPartnerGUID, city, siteId

//         else if (
//           details.FullName && this.companyDetailsForm.controls['companyName'].value &&
//           details.name && this.companyDetailsForm.controls['country'].value &&
//           details.GlobalHQGuid && this.companyDetailsForm.controls['globalHQGuid'].value &&
//           details.Guid && this.companyDetailsForm.controls['businessPartnerGUID'].value &&
//           details.City && this.companyDetailsForm.controls['city'].value &&
//           details.SiteID && this.companyDetailsForm.controls['siteID'].value
//         ) {

//           return (

//             details.FullName.toLowerCase().includes(this.companyDetailsForm.controls['companyName'].value.toLowerCase()) &&
//             details.name.toLowerCase().includes(this.companyDetailsForm.controls['country'].value.toLowerCase()) &&
//             details.GlobalHQGuid.toLowerCase().includes(this.companyDetailsForm.controls['globalHQGuid'].value.toLowerCase()) &&
//             details.Guid.toLowerCase().includes(this.companyDetailsForm.controls['businessPartnerGUID'].value.toLowerCase()) &&
//             details.City.toLowerCase().includes(this.companyDetailsForm.controls['city'].value.toLowerCase()) &&
//             details.SiteID.toLowerCase().includes(this.companyDetailsForm.controls['siteID'].value.toLowerCase()) == true

//           );
//         }
//         // for companyName, country, globalHQGuid, businessPartnerGUID, companyAddress, siteId

//         else if(
//           details.FullName && this.companyDetailsForm.controls['companyName'].value &&
//           details.name && this.companyDetailsForm.controls['country'].value &&
//           details.GlobalHQGuid && this.companyDetailsForm.controls['globalHQGuid'].value &&
//           details.Guid && this.companyDetailsForm.controls['businessPartnerGUID'].value &&
//           details.Address && this.companyDetailsForm.controls['companyAddress'].value &&
//           details.SiteID && this.companyDetailsForm.controls['siteID'].value
//         ) {

//           return (

//             details.FullName.toLowerCase().includes(this.companyDetailsForm.controls['companyName'].value.toLowerCase()) &&
//             details.name.toLowerCase().includes(this.companyDetailsForm.controls['country'].value.toLowerCase()) &&
//             details.GlobalHQGuid.toLowerCase().includes(this.companyDetailsForm.controls['globalHQGuid'].value.toLowerCase()) &&
//             details.Guid.toLowerCase().includes(this.companyDetailsForm.controls['businessPartnerGUID'].value.toLowerCase()) &&
//             details.Address.toLowerCase().includes(this.companyDetailsForm.controls['companyAddress'].value.toLowerCase()) &&
//             details.SiteID.toLowerCase().includes(this.companyDetailsForm.controls['siteID'].value.toLowerCase()) == true

//           );
//         }

//         /* based on 5 conditions */
//         // for companyName, country, globalHQGuid, businessPartnerGUID, companyAddress,

//         else if (
//           details.FullName && this.companyDetailsForm.controls['companyName'].value &&
//           details.name && this.companyDetailsForm.controls['country'].value &&
//           details.GlobalHQGuid && this.companyDetailsForm.controls['globalHQGuid'].value &&
//           details.Guid && this.companyDetailsForm.controls['businessPartnerGUID'].value &&
//           details.Address && this.companyDetailsForm.controls['companyAddress'].value
//         ) {

//           return (

//             details.FullName.toLowerCase().includes(this.companyDetailsForm.controls['companyName'].value.toLowerCase()) &&
//             details.name.toLowerCase().includes(this.companyDetailsForm.controls['country'].value.toLowerCase()) &&
//             details.GlobalHQGuid.toLowerCase().includes(this.companyDetailsForm.controls['globalHQGuid'].value.toLowerCase()) &&
//             details.Guid.toLowerCase().includes(this.companyDetailsForm.controls['businessPartnerGUID'].value.toLowerCase()) &&
//             details.Address.toLowerCase().includes(this.companyDetailsForm.controls['companyAddress'].value.toLowerCase())== true

//           );
//         }
//         // for companyName, country, globalHQGuid, businessPartnerGUID, city

//         else if (
//           details.FullName && this.companyDetailsForm.controls['companyName'].value &&
//           details.name && this.companyDetailsForm.controls['country'].value &&
//           details.GlobalHQGuid && this.companyDetailsForm.controls['globalHQGuid'].value &&
//           details.Guid && this.companyDetailsForm.controls['businessPartnerGUID'].value &&
//           details.City && this.companyDetailsForm.controls['city'].value
//         ) {

//           return (

//             details.FullName.toLowerCase().includes(this.companyDetailsForm.controls['companyName'].value.toLowerCase()) &&
//             details.name.toLowerCase().includes(this.companyDetailsForm.controls['country'].value.toLowerCase()) &&
//             details.GlobalHQGuid.toLowerCase().includes(this.companyDetailsForm.controls['globalHQGuid'].value.toLowerCase()) &&
//             details.Guid.toLowerCase().includes(this.companyDetailsForm.controls['businessPartnerGUID'].value.toLowerCase()) &&
//             details.City.toLowerCase().includes(this.companyDetailsForm.controls['city'].value.toLowerCase())  == true

//           );
//         }
//         // for companyName, country, globalHQGuid, businessPartnerGUID, siteID

//         else if (
//           details.FullName && this.companyDetailsForm.controls['companyName'].value &&
//           details.name && this.companyDetailsForm.controls['country'].value &&
//           details.GlobalHQGuid && this.companyDetailsForm.controls['globalHQGuid'].value &&
//           details.Guid && this.companyDetailsForm.controls['businessPartnerGUID'].value &&
//           details.SiteID && this.companyDetailsForm.controls['siteID'].value
//         ) {

//           return (

//             details.FullName.toLowerCase().includes(this.companyDetailsForm.controls['companyName'].value.toLowerCase()) &&
//             details.name.toLowerCase().includes(this.companyDetailsForm.controls['country'].value.toLowerCase()) &&
//             details.GlobalHQGuid.toLowerCase().includes(this.companyDetailsForm.controls['globalHQGuid'].value.toLowerCase()) &&
//             details.Guid.toLowerCase().includes(this.companyDetailsForm.controls['businessPartnerGUID'].value.toLowerCase()) &&
//             details.SiteID.toLowerCase().includes(this.companyDetailsForm.controls['siteID'].value.toLowerCase()) == true

//           );
//         }
//         // for companyName, country, globalHQGuid, companyAddress, city

//         else if (
//           details.FullName && this.companyDetailsForm.controls['companyName'].value &&
//           details.name && this.companyDetailsForm.controls['country'].value &&
//           details.GlobalHQGuid && this.companyDetailsForm.controls['globalHQGuid'].value &&
//           details.Address && this.companyDetailsForm.controls['companyAddress'].value &&
//           details.City && this.companyDetailsForm.controls['city'].value
//         ) {

//           return (

//             details.FullName.toLowerCase().includes(this.companyDetailsForm.controls['companyName'].value.toLowerCase()) &&
//             details.name.toLowerCase().includes(this.companyDetailsForm.controls['country'].value.toLowerCase()) &&
//             details.GlobalHQGuid.toLowerCase().includes(this.companyDetailsForm.controls['globalHQGuid'].value.toLowerCase()) &&
//             details.Address.toLowerCase().includes(this.companyDetailsForm.controls['companyAddress'].value.toLowerCase()) &&
//             details.City.toLowerCase().includes(this.companyDetailsForm.controls['city'].value.toLowerCase())  == true

//           );
//         }
//         // for companyName, country, businessPartnerGUID, companyAddress, city

//         else if (
//           details.FullName && this.companyDetailsForm.controls['companyName'].value &&
//           details.name && this.companyDetailsForm.controls['country'].value &&
//           details.Guid && this.companyDetailsForm.controls['businessPartnerGUID'].value &&
//           details.Address && this.companyDetailsForm.controls['companyAddress'].value &&
//           details.City && this.companyDetailsForm.controls['city'].value
//         ) {

//           return (

//             details.FullName.toLowerCase().includes(this.companyDetailsForm.controls['companyName'].value.toLowerCase()) &&
//             details.name.toLowerCase().includes(this.companyDetailsForm.controls['country'].value.toLowerCase()) &&
//             details.Guid.toLowerCase().includes(this.companyDetailsForm.controls['businessPartnerGUID'].value.toLowerCase()) &&
//             details.Address.toLowerCase().includes(this.companyDetailsForm.controls['companyAddress'].value.toLowerCase()) &&
//             details.City.toLowerCase().includes(this.companyDetailsForm.controls['city'].value.toLowerCase()) == true

//           );
//         }
//         // for country, globalHQGuid, businessPartnerGUID, companyAddress, city

//         else if (
//           details.name && this.companyDetailsForm.controls['country'].value &&
//           details.GlobalHQGuid && this.companyDetailsForm.controls['globalHQGuid'].value &&
//           details.Guid && this.companyDetailsForm.controls['businessPartnerGUID'].value &&
//           details.Address && this.companyDetailsForm.controls['companyAddress'].value &&
//           details.City && this.companyDetailsForm.controls['city'].value
//         ) {

//           return (

//             details.name.toLowerCase().includes(this.companyDetailsForm.controls['country'].value.toLowerCase()) &&
//             details.GlobalHQGuid.toLowerCase().includes(this.companyDetailsForm.controls['globalHQGuid'].value.toLowerCase()) &&
//             details.Guid.toLowerCase().includes(this.companyDetailsForm.controls['businessPartnerGUID'].value.toLowerCase()) &&
//             details.Address.toLowerCase().includes(this.companyDetailsForm.controls['companyAddress'].value.toLowerCase()) &&
//             details.City.toLowerCase().includes(this.companyDetailsForm.controls['city'].value.toLowerCase())  == true

//           );
//         }
//         // for companyName, globalHQGuid, businessPartnerGUID, companyAddress, city

//         else if(
//           details.FullName && this.companyDetailsForm.controls['companyName'].value &&
//           details.GlobalHQGuid && this.companyDetailsForm.controls['globalHQGuid'].value &&
//           details.Guid && this.companyDetailsForm.controls['businessPartnerGUID'].value &&
//           details.Address && this.companyDetailsForm.controls['companyAddress'].value &&
//           details.City && this.companyDetailsForm.controls['city'].value
//         ) {

//           return (

//             details.FullName.toLowerCase().includes(this.companyDetailsForm.controls['companyName'].value.toLowerCase()) &&
//             details.GlobalHQGuid.toLowerCase().includes(this.companyDetailsForm.controls['globalHQGuid'].value.toLowerCase()) &&
//             details.Guid.toLowerCase().includes(this.companyDetailsForm.controls['businessPartnerGUID'].value.toLowerCase()) &&
//             details.Address.toLowerCase().includes(this.companyDetailsForm.controls['companyAddress'].value.toLowerCase()) &&
//             details.City.toLowerCase().includes(this.companyDetailsForm.controls['city'].value.toLowerCase())  == true

//           );
//         }
//         // for companyName, country, companyAddress, city, siteID

//         else if (
//           details.FullName && this.companyDetailsForm.controls['companyName'].value &&
//           details.name && this.companyDetailsForm.controls['country'].value &&
//           details.Address && this.companyDetailsForm.controls['companyAddress'].value &&
//           details.City && this.companyDetailsForm.controls['city'].value &&
//           details.SiteID && this.companyDetailsForm.controls['siteID'].value
//         ) {

//           return (

//             details.FullName.toLowerCase().includes(this.companyDetailsForm.controls['companyName'].value.toLowerCase()) &&
//             details.name.toLowerCase().includes(this.companyDetailsForm.controls['country'].value.toLowerCase()) &&
//             details.Address.toLowerCase().includes(this.companyDetailsForm.controls['companyAddress'].value.toLowerCase()) &&
//             details.City.toLowerCase().includes(this.companyDetailsForm.controls['city'].value.toLowerCase()) &&
//             details.SiteID.toLowerCase().includes(this.companyDetailsForm.controls['siteID'].value.toLowerCase()) == true

//           );
//         }
//         // for globalHQGuid, businessPartnerGUID, companyAddress, city, siteID

//         else if (
//           details.GlobalHQGuid && this.companyDetailsForm.controls['globalHQGuid'].value &&
//           details.Guid && this.companyDetailsForm.controls['businessPartnerGUID'].value &&
//           details.Address && this.companyDetailsForm.controls['companyAddress'].value &&
//           details.City && this.companyDetailsForm.controls['city'].value &&
//           details.SiteID && this.companyDetailsForm.controls['siteID'].value
//         ) {

//           return (

//             details.GlobalHQGuid.toLowerCase().includes(this.companyDetailsForm.controls['globalHQGuid'].value.toLowerCase()) &&
//             details.Guid.toLowerCase().includes(this.companyDetailsForm.controls['businessPartnerGUID'].value.toLowerCase()) &&
//             details.Address.toLowerCase().includes(this.companyDetailsForm.controls['companyAddress'].value.toLowerCase()) &&
//             details.City.toLowerCase().includes(this.companyDetailsForm.controls['city'].value.toLowerCase()) &&
//             details.SiteID.toLowerCase().includes(this.companyDetailsForm.controls['siteID'].value.toLowerCase()) == true

//           );
//         }
//         // for companyName, globalHQGuid, companyAddress, city, siteID

//         else if (
//           details.FullName && this.companyDetailsForm.controls['companyName'].value &&
//           details.GlobalHQGuid && this.companyDetailsForm.controls['globalHQGuid'].value &&
//           details.Address && this.companyDetailsForm.controls['companyAddress'].value &&
//           details.City && this.companyDetailsForm.controls['city'].value &&
//           details.SiteID && this.companyDetailsForm.controls['siteID'].value
//         ) {

//           return (

//             details.FullName.toLowerCase().includes(this.companyDetailsForm.controls['companyName'].value.toLowerCase()) &&
//             details.GlobalHQGuid.toLowerCase().includes(this.companyDetailsForm.controls['globalHQGuid'].value.toLowerCase()) &&
//             details.Address.toLowerCase().includes(this.companyDetailsForm.controls['companyAddress'].value.toLowerCase()) &&
//             details.City.toLowerCase().includes(this.companyDetailsForm.controls['city'].value.toLowerCase()) &&
//             details.SiteID.toLowerCase().includes(this.companyDetailsForm.controls['siteID'].value.toLowerCase()) == true

//           );
//         }
//         // for companyName, businessPartnerGUID, companyAddress, city, siteID

//         else if (
//           details.FullName && this.companyDetailsForm.controls['companyName'].value &&
//           details.Guid && this.companyDetailsForm.controls['businessPartnerGUID'].value &&
//           details.Address && this.companyDetailsForm.controls['companyAddress'].value &&
//           details.City && this.companyDetailsForm.controls['city'].value &&
//           details.SiteID && this.companyDetailsForm.controls['siteID'].value
//         ) {

//           return (

//             details.FullName.toLowerCase().includes(this.companyDetailsForm.controls['companyName'].value.toLowerCase()) &&
//             details.Guid.toLowerCase().includes(this.companyDetailsForm.controls['businessPartnerGUID'].value.toLowerCase()) &&
//             details.Address.toLowerCase().includes(this.companyDetailsForm.controls['companyAddress'].value.toLowerCase()) &&
//             details.City.toLowerCase().includes(this.companyDetailsForm.controls['city'].value.toLowerCase()) &&
//             details.SiteID.toLowerCase().includes(this.companyDetailsForm.controls['siteID'].value.toLowerCase()) == true

//           );
//         }
//         // for country, globalHQGuid, companyAddress, city, siteID

//         else if (
//           details.name && this.companyDetailsForm.controls['country'].value &&
//           details.GlobalHQGuid && this.companyDetailsForm.controls['globalHQGuid'].value &&
//           details.Address && this.companyDetailsForm.controls['companyAddress'].value &&
//           details.City && this.companyDetailsForm.controls['city'].value &&
//           details.SiteID && this.companyDetailsForm.controls['siteID'].value
//         ) {

//           return (

//             details.name.toLowerCase().includes(this.companyDetailsForm.controls['country'].value.toLowerCase()) &&
//             details.GlobalHQGuid.toLowerCase().includes(this.companyDetailsForm.controls['globalHQGuid'].value.toLowerCase()) &&
//             details.Address.toLowerCase().includes(this.companyDetailsForm.controls['companyAddress'].value.toLowerCase()) &&
//             details.City.toLowerCase().includes(this.companyDetailsForm.controls['city'].value.toLowerCase()) &&
//             details.SiteID.toLowerCase().includes(this.companyDetailsForm.controls['siteID'].value.toLowerCase()) == true

//           );
//         }
//         // for country, businessPartnerGUID, companyAddress, city, siteID
//         else if (
//           details.name && this.companyDetailsForm.controls['country'].value &&
//           details.Guid && this.companyDetailsForm.controls['businessPartnerGUID'].value &&
//           details.Address && this.companyDetailsForm.controls['companyAddress'].value &&
//           details.City && this.companyDetailsForm.controls['city'].value &&
//           details.SiteID && this.companyDetailsForm.controls['siteID'].value
//         ) {

//           return (

//             details.name.toLowerCase().includes(this.companyDetailsForm.controls['country'].value.toLowerCase()) &&
//             details.Guid.toLowerCase().includes(this.companyDetailsForm.controls['businessPartnerGUID'].value.toLowerCase()) &&
//             details.Address.toLowerCase().includes(this.companyDetailsForm.controls['companyAddress'].value.toLowerCase()) &&
//             details.City.toLowerCase().includes(this.companyDetailsForm.controls['city'].value.toLowerCase()) &&
//             details.SiteID.toLowerCase().includes(this.companyDetailsForm.controls['siteID'].value.toLowerCase()) == true

//           );
//         }

//         /* search based on 4 fields */
//         // for companyName, country, globalHQGuid, businessPartnerGUID

//         else if (
//           details.FullName && this.companyDetailsForm.controls['companyName'].value &&
//           details.name && this.companyDetailsForm.controls['country'].value &&
//           details.GlobalHQGuid && this.companyDetailsForm.controls['globalHQGuid'].value &&
//           details.Guid && this.companyDetailsForm.controls['businessPartnerGUID'].value
//         ) {

//           return (

//             details.FullName.toLowerCase().includes(this.companyDetailsForm.controls['companyName'].value.toLowerCase()) &&
//             details.name.toLowerCase().includes(this.companyDetailsForm.controls['country'].value.toLowerCase()) &&
//             details.GlobalHQGuid.toLowerCase().includes(this.companyDetailsForm.controls['globalHQGuid'].value.toLowerCase()) &&
//             details.Guid.toLowerCase().includes(this.companyDetailsForm.controls['businessPartnerGUID'].value.toLowerCase()) &&
//             details.Address.toLowerCase().includes(this.companyDetailsForm.controls['companyAddress'].value.toLowerCase())  == true

//           );
//         }
//         // for companyName, country, companyAddress, city

//         else if(
//           details.FullName && this.companyDetailsForm.controls['companyName'].value &&
//           details.name && this.companyDetailsForm.controls['country'].value &&
//           details.Address && this.companyDetailsForm.controls['companyAddress'].value &&
//           details.City && this.companyDetailsForm.controls['city'].value
//         ) {

//           return (

//             details.FullName.toLowerCase().includes(this.companyDetailsForm.controls['companyName'].value.toLowerCase()) &&
//             details.name.toLowerCase().includes(this.companyDetailsForm.controls['country'].value.toLowerCase()) &&
//             details.Address.toLowerCase().includes(this.companyDetailsForm.controls['companyAddress'].value.toLowerCase()) &&
//             details.City.toLowerCase().includes(this.companyDetailsForm.controls['city'].value.toLowerCase())  == true

//           );
//         }
//         // for globalHQGuid, businessPartnerGUID, companyAddress, city

//         else if (
//           details.GlobalHQGuid && this.companyDetailsForm.controls['globalHQGuid'].value &&
//           details.Guid && this.companyDetailsForm.controls['businessPartnerGUID'].value &&
//           details.Address && this.companyDetailsForm.controls['companyAddress'].value &&
//           details.City && this.companyDetailsForm.controls['city'].value
//         ) {

//           return (

//             details.GlobalHQGuid.toLowerCase().includes(this.companyDetailsForm.controls['globalHQGuid'].value.toLowerCase()) &&
//             details.Guid.toLowerCase().includes(this.companyDetailsForm.controls['businessPartnerGUID'].value.toLowerCase()) &&
//             details.Address.toLowerCase().includes(this.companyDetailsForm.controls['companyAddress'].value.toLowerCase()) &&
//             details.City.toLowerCase().includes(this.companyDetailsForm.controls['city'].value.toLowerCase()) == true

//           );
//         }
//         // for companyName, globalHQGuid, companyAddress, city

//         else if (
//           details.FullName && this.companyDetailsForm.controls['companyName'].value &&
//           details.GlobalHQGuid && this.companyDetailsForm.controls['globalHQGuid'].value &&
//           details.Address && this.companyDetailsForm.controls['companyAddress'].value &&
//           details.City && this.companyDetailsForm.controls['city'].value
//         ) {

//           return (

//             details.FullName.toLowerCase().includes(this.companyDetailsForm.controls['companyName'].value.toLowerCase()) &&
//             details.GlobalHQGuid.toLowerCase().includes(this.companyDetailsForm.controls['globalHQGuid'].value.toLowerCase()) &&
//             details.Address.toLowerCase().includes(this.companyDetailsForm.controls['companyAddress'].value.toLowerCase()) &&
//             details.City.toLowerCase().includes(this.companyDetailsForm.controls['city'].value.toLowerCase())  == true

//           );
//         }
//         // for companyName, businessPartnerGUID, companyAddress, city

//         else if (
//           details.FullName && this.companyDetailsForm.controls['companyName'].value &&
//           details.Guid && this.companyDetailsForm.controls['businessPartnerGUID'].value &&
//           details.Address && this.companyDetailsForm.controls['companyAddress'].value &&
//           details.City && this.companyDetailsForm.controls['city'].value
//         ) {

//           return (

//             details.FullName.toLowerCase().includes(this.companyDetailsForm.controls['companyName'].value.toLowerCase()) &&
//             details.Guid.toLowerCase().includes(this.companyDetailsForm.controls['businessPartnerGUID'].value.toLowerCase()) &&
//             details.Address.toLowerCase().includes(this.companyDetailsForm.controls['companyAddress'].value.toLowerCase()) &&
//             details.City.toLowerCase().includes(this.companyDetailsForm.controls['city'].value.toLowerCase()) == true

//           );
//         }
//         // for country, globalHQGuid, companyAddress, city

//         else if (
//           details.name && this.companyDetailsForm.controls['country'].value &&
//           details.GlobalHQGuid && this.companyDetailsForm.controls['globalHQGuid'].value &&
//           details.Address && this.companyDetailsForm.controls['companyAddress'].value &&
//           details.City && this.companyDetailsForm.controls['city'].value
//         ) {

//           return (

//             details.name.toLowerCase().includes(this.companyDetailsForm.controls['country'].value.toLowerCase()) &&
//             details.GlobalHQGuid.toLowerCase().includes(this.companyDetailsForm.controls['globalHQGuid'].value.toLowerCase()) &&
//             details.Address.toLowerCase().includes(this.companyDetailsForm.controls['companyAddress'].value.toLowerCase()) &&
//             details.City.toLowerCase().includes(this.companyDetailsForm.controls['city'].value.toLowerCase()) == true

//           );
//         }
//         // for country, businessPartnerGUID, companyAddress, city

//         else if (
//           details.name && this.companyDetailsForm.controls['country'].value &&
//           details.Guid && this.companyDetailsForm.controls['businessPartnerGUID'].value &&
//           details.Address && this.companyDetailsForm.controls['companyAddress'].value &&
//           details.City && this.companyDetailsForm.controls['city'].value
//         ) {

//           return (

//             details.name.toLowerCase().includes(this.companyDetailsForm.controls['country'].value.toLowerCase()) &&
//             details.Guid.toLowerCase().includes(this.companyDetailsForm.controls['businessPartnerGUID'].value.toLowerCase()) &&
//             details.Address.toLowerCase().includes(this.companyDetailsForm.controls['companyAddress'].value.toLowerCase()) &&
//             details.City.toLowerCase().includes(this.companyDetailsForm.controls['city'].value.toLowerCase())  == true

//           );
//         }
//         //with siteID
//         // for companyName, companyAddress, city, siteID

//         else if (
//           details.FullName && this.companyDetailsForm.controls['companyName'].value &&
//           details.Address && this.companyDetailsForm.controls['companyAddress'].value &&
//           details.City && this.companyDetailsForm.controls['city'].value &&
//           details.SiteID && this.companyDetailsForm.controls['siteID'].value
//         ) {

//           return (

//             details.FullName.toLowerCase().includes(this.companyDetailsForm.controls['companyName'].value.toLowerCase()) &&
//             details.Address.toLowerCase().includes(this.companyDetailsForm.controls['companyAddress'].value.toLowerCase()) &&
//             details.City.toLowerCase().includes(this.companyDetailsForm.controls['city'].value.toLowerCase()) &&
//             details.SiteID.toLowerCase().includes(this.companyDetailsForm.controls['siteID'].value.toLowerCase()) == true

//           );
//         }
//         // for country, companyAddress, city, siteID

//         else if(
//           details.name && this.companyDetailsForm.controls['country'].value &&
//           details.Address && this.companyDetailsForm.controls['companyAddress'].value &&
//           details.City && this.companyDetailsForm.controls['city'].value &&
//           details.SiteID && this.companyDetailsForm.controls['siteID'].value
//         ) {

//           return (

//             details.name.toLowerCase().includes(this.companyDetailsForm.controls['country'].value.toLowerCase()) &&
//             details.Address.toLowerCase().includes(this.companyDetailsForm.controls['companyAddress'].value.toLowerCase()) &&
//             details.City.toLowerCase().includes(this.companyDetailsForm.controls['city'].value.toLowerCase()) &&
//             details.SiteID.toLowerCase().includes(this.companyDetailsForm.controls['siteID'].value.toLowerCase()) == true

//           );
//         }
//         // for globalHQGuid, companyAddress, city, siteID

//         else if(
//           details.GlobalHQGuid && this.companyDetailsForm.controls['globalHQGuid'].value &&
//           details.Address && this.companyDetailsForm.controls['companyAddress'].value &&
//           details.City && this.companyDetailsForm.controls['city'].value &&
//           details.SiteID && this.companyDetailsForm.controls['siteID'].value
//         ) {

//           return (

//             details.GlobalHQGuid.toLowerCase().includes(this.companyDetailsForm.controls['globalHQGuid'].value.toLowerCase()) &&
//             details.Address.toLowerCase().includes(this.companyDetailsForm.controls['companyAddress'].value.toLowerCase()) &&
//             details.City.toLowerCase().includes(this.companyDetailsForm.controls['city'].value.toLowerCase()) &&
//             details.SiteID.toLowerCase().includes(this.companyDetailsForm.controls['siteID'].value.toLowerCase()) == true

//           );
//         }
//         // for businessPartnerGUID, companyAddress, city, siteID

//         else if(
//           details.Guid && this.companyDetailsForm.controls['businessPartnerGUID'].value &&
//           details.Address && this.companyDetailsForm.controls['companyAddress'].value &&
//           details.City && this.companyDetailsForm.controls['city'].value &&
//           details.SiteID && this.companyDetailsForm.controls['siteID'].value
//         ) {

//           return (

//             details.Guid.toLowerCase().includes(this.companyDetailsForm.controls['businessPartnerGUID'].value.toLowerCase()) &&
//             details.Address.toLowerCase().includes(this.companyDetailsForm.controls['companyAddress'].value.toLowerCase()) &&
//             details.City.toLowerCase().includes(this.companyDetailsForm.controls['city'].value.toLowerCase()) &&
//             details.SiteID.toLowerCase().includes(this.companyDetailsForm.controls['siteID'].value.toLowerCase()) == true

//           );
//         }
//         // for companyName, country, city, siteID

//         else if (
//           details.FullName && this.companyDetailsForm.controls['companyName'].value &&
//           details.name && this.companyDetailsForm.controls['country'].value &&
//           details.City && this.companyDetailsForm.controls['city'].value &&
//           details.SiteID && this.companyDetailsForm.controls['siteID'].value
//         ) {

//           return (

//             details.FullName.toLowerCase().includes(this.companyDetailsForm.controls['companyName'].value.toLowerCase()) &&
//             details.name.toLowerCase().includes(this.companyDetailsForm.controls['country'].value.toLowerCase()) &&
//             details.City.toLowerCase().includes(this.companyDetailsForm.controls['city'].value.toLowerCase()) &&
//             details.SiteID.toLowerCase().includes(this.companyDetailsForm.controls['siteID'].value.toLowerCase()) == true

//           );
//         }
//         // for globalHQGuid, businessPartnerGUID, city, siteID

//         else if (
//           details.GlobalHQGuid && this.companyDetailsForm.controls['globalHQGuid'].value &&
//           details.Guid && this.companyDetailsForm.controls['businessPartnerGUID'].value &&
//           details.City && this.companyDetailsForm.controls['city'].value &&
//           details.SiteID && this.companyDetailsForm.controls['siteID'].value
//         ) {

//           return (

//             details.GlobalHQGuid.toLowerCase().includes(this.companyDetailsForm.controls['globalHQGuid'].value.toLowerCase()) &&
//             details.Guid.toLowerCase().includes(this.companyDetailsForm.controls['businessPartnerGUID'].value.toLowerCase()) &&
//             details.City.toLowerCase().includes(this.companyDetailsForm.controls['city'].value.toLowerCase()) &&
//             details.SiteID.toLowerCase().includes(this.companyDetailsForm.controls['siteID'].value.toLowerCase()) == true

//           );
//         }
//         // for companyName, globalHQGuid, city, siteID

//         else if (
//           details.FullName && this.companyDetailsForm.controls['companyName'].value &&
//           details.GlobalHQGuid && this.companyDetailsForm.controls['globalHQGuid'].value &&
//           details.City && this.companyDetailsForm.controls['city'].value &&
//           details.SiteID && this.companyDetailsForm.controls['siteID'].value
//         ) {

//           return (

//             details.FullName.toLowerCase().includes(this.companyDetailsForm.controls['companyName'].value.toLowerCase()) &&
//             details.GlobalHQGuid.toLowerCase().includes(this.companyDetailsForm.controls['globalHQGuid'].value.toLowerCase()) &&
//             details.City.toLowerCase().includes(this.companyDetailsForm.controls['city'].value.toLowerCase()) &&
//             details.SiteID.toLowerCase().includes(this.companyDetailsForm.controls['siteID'].value.toLowerCase()) == true

//           );
//         }
//         // for companyName,  businessPartnerGUID, city, siteID

//         else if (
//           details.FullName && this.companyDetailsForm.controls['companyName'].value &&
//           details.Guid && this.companyDetailsForm.controls['businessPartnerGUID'].value &&
//           details.City && this.companyDetailsForm.controls['city'].value &&
//           details.SiteID && this.companyDetailsForm.controls['siteID'].value
//         ) {

//           return (

//             details.FullName.toLowerCase().includes(this.companyDetailsForm.controls['companyName'].value.toLowerCase()) &&
//             details.Guid.toLowerCase().includes(this.companyDetailsForm.controls['businessPartnerGUID'].value.toLowerCase()) &&
//             details.City.toLowerCase().includes(this.companyDetailsForm.controls['city'].value.toLowerCase()) &&
//             details.SiteID.toLowerCase().includes(this.companyDetailsForm.controls['siteID'].value.toLowerCase()) == true

//           );
//         }
//         // for country, globalHQGuid, city, siteID

//         else if (
//           details.name && this.companyDetailsForm.controls['country'].value &&
//           details.GlobalHQGuid && this.companyDetailsForm.controls['globalHQGuid'].value &&
//           details.City && this.companyDetailsForm.controls['city'].value &&
//           details.SiteID && this.companyDetailsForm.controls['siteID'].value
//         ) {

//           return (

//             details.name.toLowerCase().includes(this.companyDetailsForm.controls['country'].value.toLowerCase()) &&
//             details.GlobalHQGuid.toLowerCase().includes(this.companyDetailsForm.controls['globalHQGuid'].value.toLowerCase()) &&
//             details.City.toLowerCase().includes(this.companyDetailsForm.controls['city'].value.toLowerCase()) &&
//             details.SiteID.toLowerCase().includes(this.companyDetailsForm.controls['siteID'].value.toLowerCase()) == true

//           );
//         }
//         // for country, businessPartnerGUID, city, siteID

//         else if(
//           details.name && this.companyDetailsForm.controls['country'].value &&
//           details.Guid && this.companyDetailsForm.controls['businessPartnerGUID'].value &&
//           details.City && this.companyDetailsForm.controls['city'].value &&
//           details.SiteID && this.companyDetailsForm.controls['siteID'].value
//         ) {

//           return (

//             details.name.toLowerCase().includes(this.companyDetailsForm.controls['country'].value.toLowerCase()) &&
//             details.Guid.toLowerCase().includes(this.companyDetailsForm.controls['businessPartnerGUID'].value.toLowerCase()) &&
//             details.City.toLowerCase().includes(this.companyDetailsForm.controls['city'].value.toLowerCase()) &&
//             details.SiteID.toLowerCase().includes(this.companyDetailsForm.controls['siteID'].value.toLowerCase()) == true

//           );
//         }

//         // for companyName, country, companyAddress, siteID

//         else if (
//           details.FullName && this.companyDetailsForm.controls['companyName'].value &&
//           details.name && this.companyDetailsForm.controls['country'].value &&
//           details.Address && this.companyDetailsForm.controls['companyAddress'].value &&
//           details.SiteID && this.companyDetailsForm.controls['siteID'].value
//         ) {

//           return (

//             details.FullName.toLowerCase().includes(this.companyDetailsForm.controls['companyName'].value.toLowerCase()) &&
//             details.name.toLowerCase().includes(this.companyDetailsForm.controls['country'].value.toLowerCase()) &&
//             details.Address.toLowerCase().includes(this.companyDetailsForm.controls['companyAddress'].value.toLowerCase()) &&
//             details.SiteID.toLowerCase().includes(this.companyDetailsForm.controls['siteID'].value.toLowerCase()) == true

//           );
//         }
//         // for globalHQGuid, businessPartnerGUID, companyAddress, siteID

//         else if (
//           details.GlobalHQGuid && this.companyDetailsForm.controls['globalHQGuid'].value &&
//           details.Guid && this.companyDetailsForm.controls['businessPartnerGUID'].value &&
//           details.Address && this.companyDetailsForm.controls['companyAddress'].value &&
//           details.SiteID && this.companyDetailsForm.controls['siteID'].value
//         ) {

//           return (

//             details.GlobalHQGuid.toLowerCase().includes(this.companyDetailsForm.controls['globalHQGuid'].value.toLowerCase()) &&
//             details.Guid.toLowerCase().includes(this.companyDetailsForm.controls['businessPartnerGUID'].value.toLowerCase()) &&
//             details.Address.toLowerCase().includes(this.companyDetailsForm.controls['companyAddress'].value.toLowerCase()) &&
//             details.SiteID.toLowerCase().includes(this.companyDetailsForm.controls['siteID'].value.toLowerCase()) == true

//           );
//         }
//         // for companyName, globalHQGuid, companyAddress, siteID

//         else if (
//           details.FullName && this.companyDetailsForm.controls['companyName'].value &&
//           details.GlobalHQGuid && this.companyDetailsForm.controls['globalHQGuid'].value &&
//           details.Address && this.companyDetailsForm.controls['companyAddress'].value &&
//           details.SiteID && this.companyDetailsForm.controls['siteID'].value
//         ) {

//           return (

//             details.FullName.toLowerCase().includes(this.companyDetailsForm.controls['companyName'].value.toLowerCase()) &&
//             details.GlobalHQGuid.toLowerCase().includes(this.companyDetailsForm.controls['globalHQGuid'].value.toLowerCase()) &&
//             details.Address.toLowerCase().includes(this.companyDetailsForm.controls['companyAddress'].value.toLowerCase()) &&
//             details.SiteID.toLowerCase().includes(this.companyDetailsForm.controls['siteID'].value.toLowerCase()) == true

//           );
//         }
//         // for companyName,  businessPartnerGUID, companyAddress, siteID

//         else if (
//           details.FullName && this.companyDetailsForm.controls['companyName'].value &&
//           details.Guid && this.companyDetailsForm.controls['businessPartnerGUID'].value &&
//           details.Address && this.companyDetailsForm.controls['companyAddress'].value &&
//           details.SiteID && this.companyDetailsForm.controls['siteID'].value
//         ) {

//           return (

//             details.FullName.toLowerCase().includes(this.companyDetailsForm.controls['companyName'].value.toLowerCase()) &&
//             details.Guid.toLowerCase().includes(this.companyDetailsForm.controls['businessPartnerGUID'].value.toLowerCase()) &&
//             details.Address.toLowerCase().includes(this.companyDetailsForm.controls['companyAddress'].value.toLowerCase()) &&
//             details.SiteID.toLowerCase().includes(this.companyDetailsForm.controls['siteID'].value.toLowerCase()) == true

//           );
//         }
//         // for country, globalHQGuid, companyAddress, siteID

//         else if (
//           details.name && this.companyDetailsForm.controls['country'].value &&
//           details.GlobalHQGuid && this.companyDetailsForm.controls['globalHQGuid'].value &&
//           details.Address && this.companyDetailsForm.controls['companyAddress'].value &&
//           details.SiteID && this.companyDetailsForm.controls['siteID'].value
//         ) {

//           return (

//             details.name.toLowerCase().includes(this.companyDetailsForm.controls['country'].value.toLowerCase()) &&
//             details.GlobalHQGuid.toLowerCase().includes(this.companyDetailsForm.controls['globalHQGuid'].value.toLowerCase()) &&
//             details.Address.toLowerCase().includes(this.companyDetailsForm.controls['companyAddress'].value.toLowerCase()) &&
//             details.SiteID.toLowerCase().includes(this.companyDetailsForm.controls['siteID'].value.toLowerCase()) == true

//           );
//         }
//         // for country, businessPartnerGUID, companyAddress, siteID

//         else if (
//           details.name && this.companyDetailsForm.controls['country'].value &&
//           details.Guid && this.companyDetailsForm.controls['businessPartnerGUID'].value &&
//           details.Address && this.companyDetailsForm.controls['companyAddress'].value &&
//           details.SiteID && this.companyDetailsForm.controls['siteID'].value
//         ) {

//           return (

//             details.name.toLowerCase().includes(this.companyDetailsForm.controls['country'].value.toLowerCase()) &&
//             details.Guid.toLowerCase().includes(this.companyDetailsForm.controls['businessPartnerGUID'].value.toLowerCase()) &&
//             details.Address.toLowerCase().includes(this.companyDetailsForm.controls['companyAddress'].value.toLowerCase()) &&
//             details.SiteID.toLowerCase().includes(this.companyDetailsForm.controls['siteID'].value.toLowerCase()) == true

//           );
//         }


//         /* Search based on 3 fields */

//         // for companyName, country, companyAddress

//         else if(
//           details.FullName && this.companyDetailsForm.controls['companyName'].value &&
//           details.name && this.companyDetailsForm.controls['country'].value &&
//           details.Address && this.companyDetailsForm.controls['companyAddress'].value
//         ) {

//           return (

//             details.FullName.toLowerCase().includes(this.companyDetailsForm.controls['companyName'].value.toLowerCase()) &&
//             details.name.toLowerCase().includes(this.companyDetailsForm.controls['country'].value.toLowerCase()) &&
//             details.Address.toLowerCase().includes(this.companyDetailsForm.controls['companyAddress'].value.toLowerCase()) == true

//           );
//         }
//         // for companyName, country, city

//         else if(
//           details.FullName && this.companyDetailsForm.controls['companyName'].value &&
//           details.name && this.companyDetailsForm.controls['country'].value &&
//           details.City && this.companyDetailsForm.controls['city'].value
//         ) {

//           return (

//             details.FullName.toLowerCase().includes(this.companyDetailsForm.controls['companyName'].value.toLowerCase()) &&
//             details.name.toLowerCase().includes(this.companyDetailsForm.controls['country'].value.toLowerCase()) &&
//             details.City.toLowerCase().includes(this.companyDetailsForm.controls['city'].value.toLowerCase())  == true

//           );
//         }
//         // for companyName, country, siteID

//         else if (
//           details.FullName && this.companyDetailsForm.controls['companyName'].value &&
//           details.name && this.companyDetailsForm.controls['country'].value &&
//           details.SiteID && this.companyDetailsForm.controls['siteID'].value
//         ) {

//           return (

//             details.FullName.toLowerCase().includes(this.companyDetailsForm.controls['companyName'].value.toLowerCase()) &&
//             details.name.toLowerCase().includes(this.companyDetailsForm.controls['country'].value.toLowerCase()) &&
//             details.SiteID.toLowerCase().includes(this.companyDetailsForm.controls['siteID'].value.toLowerCase()) == true

//           );
//         }

//         // for companyName, globalHQGuid, country

//         else if(
//           details.FullName && this.companyDetailsForm.controls['companyName'].value &&
//           details.GlobalHQGuid && this.companyDetailsForm.controls['globalHQGuid'].value &&
//           details.name && this.companyDetailsForm.controls['country'].value
//         ) {

//           return (

//             details.FullName.toLowerCase().includes(this.companyDetailsForm.controls['companyName'].value.toLowerCase()) &&
//             details.GlobalHQGuid.toLowerCase().includes(this.companyDetailsForm.controls['globalHQGuid'].value.toLowerCase()) &&
//             details.name.toLowerCase().includes(this.companyDetailsForm.controls['country'].value.toLowerCase())  == true

//           );
//         }
//         // for companyName, globalHQGuid, businessPartnerGUID

//         else if(
//           details.FullName && this.companyDetailsForm.controls['companyName'].value &&
//           details.GlobalHQGuid && this.companyDetailsForm.controls['globalHQGuid'].value &&
//           details.Guid && this.companyDetailsForm.controls['businessPartnerGUID'].value
//         ) {

//           return (

//             details.FullName.toLowerCase().includes(this.companyDetailsForm.controls['companyName'].value.toLowerCase()) &&
//             details.GlobalHQGuid.toLowerCase().includes(this.companyDetailsForm.controls['globalHQGuid'].value.toLowerCase()) &&
//             details.Guid.toLowerCase().includes(this.companyDetailsForm.controls['businessPartnerGUID'].value.toLowerCase()) == true

//           );
//         }
//         // for companyName, globalHQGuid, companyAddress

//         else if (
//           details.FullName && this.companyDetailsForm.controls['companyName'].value &&
//           details.GlobalHQGuid && this.companyDetailsForm.controls['globalHQGuid'].value &&
//           details.Address && this.companyDetailsForm.controls['companyAddress'].value
//         ) {

//           return (

//             details.FullName.toLowerCase().includes(this.companyDetailsForm.controls['companyName'].value.toLowerCase()) &&
//             details.GlobalHQGuid.toLowerCase().includes(this.companyDetailsForm.controls['globalHQGuid'].value.toLowerCase()) &&
//             details.Address.toLowerCase().includes(this.companyDetailsForm.controls['companyAddress'].value.toLowerCase()) == true

//           );
//         }
//         // for companyName, globalHQGuid, city

//         else if (
//           details.FullName && this.companyDetailsForm.controls['companyName'].value &&
//           details.GlobalHQGuid && this.companyDetailsForm.controls['globalHQGuid'].value &&
//           details.City && this.companyDetailsForm.controls['city'].value
//         ) {

//           return (

//             details.FullName.toLowerCase().includes(this.companyDetailsForm.controls['companyName'].value.toLowerCase()) &&
//             details.GlobalHQGuid.toLowerCase().includes(this.companyDetailsForm.controls['globalHQGuid'].value.toLowerCase()) &&
//             details.City.toLowerCase().includes(this.companyDetailsForm.controls['city'].value.toLowerCase()) == true

//           );
//         }
//         // for companyName, globalHQGuid, siteID

//         else if (
//           details.FullName && this.companyDetailsForm.controls['companyName'].value &&
//           details.GlobalHQGuid && this.companyDetailsForm.controls['globalHQGuid'].value &&
//           details.SiteID && this.companyDetailsForm.controls['siteID'].value
//         ) {

//           return (

//             details.FullName.toLowerCase().includes(this.companyDetailsForm.controls['companyName'].value.toLowerCase()) &&
//             details.GlobalHQGuid.toLowerCase().includes(this.companyDetailsForm.controls['globalHQGuid'].value.toLowerCase()) &&
//             details.SiteID.toLowerCase().includes(this.companyDetailsForm.controls['siteID'].value.toLowerCase()) == true

//           );
//         }

//         // for companyName, businessPartnerGUID, country

//         else if (
//           details.FullName && this.companyDetailsForm.controls['companyName'].value &&
//           details.Guid && this.companyDetailsForm.controls['businessPartnerGUID'].value &&
//           details.name && this.companyDetailsForm.controls['country'].value
//         ) {

//           return (

//             details.FullName.toLowerCase().includes(this.companyDetailsForm.controls['companyName'].value.toLowerCase()) &&
//             details.Guid.toLowerCase().includes(this.companyDetailsForm.controls['businessPartnerGUID'].value.toLowerCase()) &&
//             details.name.toLowerCase().includes(this.companyDetailsForm.controls['country'].value.toLowerCase()) == true

//           );
//         }
//         // for companyName, businessPartnerGUID, globalHQGuid

//         else if (
//           details.FullName && this.companyDetailsForm.controls['companyName'].value &&
//           details.Guid && this.companyDetailsForm.controls['businessPartnerGUID'].value &&
//           details.GlobalHQGuid && this.companyDetailsForm.controls['globalHQGuid'].value
//         ) {

//           return (

//             details.FullName.toLowerCase().includes(this.companyDetailsForm.controls['companyName'].value.toLowerCase()) &&
//             details.Guid.toLowerCase().includes(this.companyDetailsForm.controls['businessPartnerGUID'].value.toLowerCase()) &&
//             details.GlobalHQGuid.toLowerCase().includes(this.companyDetailsForm.controls['globalHQGuid'].value.toLowerCase()) == true

//           );
//         }
//         // for companyName, businessPartnerGUID, companyAddress

//         else if(
//           details.FullName && this.companyDetailsForm.controls['companyName'].value &&
//           details.Guid && this.companyDetailsForm.controls['businessPartnerGUID'].value &&
//           details.Address && this.companyDetailsForm.controls['companyAddress'].value
//         ) {

//           return (

//             details.FullName.toLowerCase().includes(this.companyDetailsForm.controls['companyName'].value.toLowerCase()) &&
//             details.Guid.toLowerCase().includes(this.companyDetailsForm.controls['businessPartnerGUID'].value.toLowerCase()) &&
//             details.Address.toLowerCase().includes(this.companyDetailsForm.controls['companyAddress'].value.toLowerCase())  == true

//           );
//         }
//         // for companyName, businessPartnerGUID, city

//         else if (
//           details.FullName && this.companyDetailsForm.controls['companyName'].value &&
//           details.Guid && this.companyDetailsForm.controls['businessPartnerGUID'].value &&
//           details.City && this.companyDetailsForm.controls['city'].value
//         ) {

//           return (

//             details.FullName.toLowerCase().includes(this.companyDetailsForm.controls['companyName'].value.toLowerCase()) &&
//             details.Guid.toLowerCase().includes(this.companyDetailsForm.controls['businessPartnerGUID'].value.toLowerCase()) &&
//             details.City.toLowerCase().includes(this.companyDetailsForm.controls['city'].value.toLowerCase()) == true

//           );
//         }
//         // for companyName, businessPartnerGUID, siteID

//         else if (
//           details.FullName && this.companyDetailsForm.controls['companyName'].value &&
//           details.Guid && this.companyDetailsForm.controls['businessPartnerGUID'].value &&
//           details.SiteID && this.companyDetailsForm.controls['siteID'].value
//         ) {

//           return (

//             details.FullName.toLowerCase().includes(this.companyDetailsForm.controls['companyName'].value.toLowerCase()) &&
//             details.Guid.toLowerCase().includes(this.companyDetailsForm.controls['businessPartnerGUID'].value.toLowerCase()) &&
//             details.SiteID.toLowerCase().includes(this.companyDetailsForm.controls['siteID'].value.toLowerCase()) == true

//           );
//         }

//         // for companyName, companyAddress, city

//         else if (
//           details.FullName && this.companyDetailsForm.controls['companyName'].value &&
//           details.Address && this.companyDetailsForm.controls['companyAddress'].value &&
//           details.City && this.companyDetailsForm.controls['city'].value
//         ) {

//           return (

//             details.FullName.toLowerCase().includes(this.companyDetailsForm.controls['companyName'].value.toLowerCase()) &&
//             details.Address.toLowerCase().includes(this.companyDetailsForm.controls['companyAddress'].value.toLowerCase()) &&
//             details.City.toLowerCase().includes(this.companyDetailsForm.controls['city'].value.toLowerCase())  == true

//           );
//         }
//         //with siteID
//         // for companyName, companyAddress, siteID

//         else if (
//           details.FullName && this.companyDetailsForm.controls['companyName'].value &&
//           details.Address && this.companyDetailsForm.controls['companyAddress'].value &&
//           details.SiteID && this.companyDetailsForm.controls['siteID'].value
//         ) {

//           return (

//             details.FullName.toLowerCase().includes(this.companyDetailsForm.controls['companyName'].value.toLowerCase()) &&
//             details.Address.toLowerCase().includes(this.companyDetailsForm.controls['companyAddress'].value.toLowerCase()) &&
//             details.SiteID.toLowerCase().includes(this.companyDetailsForm.controls['siteID'].value.toLowerCase()) == true

//           );
//         }
//         // for companyName, city, siteID

//         else if (
//           details.FullName && this.companyDetailsForm.controls['companyName'].value &&
//           details.City && this.companyDetailsForm.controls['city'].value &&
//           details.SiteID && this.companyDetailsForm.controls['siteID'].value
//         ) {

//           return (

//             details.FullName.toLowerCase().includes(this.companyDetailsForm.controls['companyName'].value.toLowerCase()) &&
//             details.City.toLowerCase().includes(this.companyDetailsForm.controls['city'].value.toLowerCase()) &&
//             details.SiteID.toLowerCase().includes(this.companyDetailsForm.controls['siteID'].value.toLowerCase()) == true

//           );
//         }

//         // for country, globalHQGuid, businessPartnerGUID

//         else if (
//           details.name && this.companyDetailsForm.controls['country'].value &&
//           details.GlobalHQGuid && this.companyDetailsForm.controls['globalHQGuid'].value &&
//           details.Guid && this.companyDetailsForm.controls['businessPartnerGUID'].value
//         ) {

//           return (

//             details.name.toLowerCase().includes(this.companyDetailsForm.controls['country'].value.toLowerCase()) &&
//             details.GlobalHQGuid.toLowerCase().includes(this.companyDetailsForm.controls['globalHQGuid'].value.toLowerCase()) &&
//             details.Guid.toLowerCase().includes(this.companyDetailsForm.controls['businessPartnerGUID'].value.toLowerCase()) == true

//           );
//         }
//         // for country, globalHQGuid, companyAddress

//         else if (
//           details.name && this.companyDetailsForm.controls['country'].value &&
//           details.GlobalHQGuid && this.companyDetailsForm.controls['globalHQGuid'].value &&
//           details.Address && this.companyDetailsForm.controls['companyAddress'].value
//         ) {

//           return (

//             details.name.toLowerCase().includes(this.companyDetailsForm.controls['country'].value.toLowerCase()) &&
//             details.GlobalHQGuid.toLowerCase().includes(this.companyDetailsForm.controls['globalHQGuid'].value.toLowerCase()) &&
//             details.Address.toLowerCase().includes(this.companyDetailsForm.controls['companyAddress'].value.toLowerCase())  == true

//           );
//         }
//         // for country, globalHQGuid, city

//         else if (
//           details.name && this.companyDetailsForm.controls['country'].value &&
//           details.GlobalHQGuid && this.companyDetailsForm.controls['globalHQGuid'].value &&
//           details.City && this.companyDetailsForm.controls['city'].value
//         ) {

//           return (

//             details.name.toLowerCase().includes(this.companyDetailsForm.controls['country'].value.toLowerCase()) &&
//             details.GlobalHQGuid.toLowerCase().includes(this.companyDetailsForm.controls['globalHQGuid'].value.toLowerCase()) &&
//             details.City.toLowerCase().includes(this.companyDetailsForm.controls['city'].value.toLowerCase()) == true

//           );
//         }
//         // for country, globalHQGuid, siteID

//         else if (
//           details.name && this.companyDetailsForm.controls['country'].value &&
//           details.GlobalHQGuid && this.companyDetailsForm.controls['globalHQGuid'].value &&
//           details.SiteID && this.companyDetailsForm.controls['siteID'].value
//         ) {

//           return (

//             details.name.toLowerCase().includes(this.companyDetailsForm.controls['country'].value.toLowerCase()) &&
//             details.GlobalHQGuid.toLowerCase().includes(this.companyDetailsForm.controls['globalHQGuid'].value.toLowerCase()) &&
//             details.SiteID.toLowerCase().includes(this.companyDetailsForm.controls['siteID'].value.toLowerCase()) == true

//           );
//         }

//         // for globalHQGuid, businessPartnerGUID, companyAddress

//         else if (
//           details.GlobalHQGuid && this.companyDetailsForm.controls['globalHQGuid'].value &&
//           details.Guid && this.companyDetailsForm.controls['businessPartnerGUID'].value &&
//           details.Address && this.companyDetailsForm.controls['companyAddress'].value
//         ) {

//           return (

//             details.GlobalHQGuid.toLowerCase().includes(this.companyDetailsForm.controls['globalHQGuid'].value.toLowerCase()) &&
//             details.Guid.toLowerCase().includes(this.companyDetailsForm.controls['businessPartnerGUID'].value.toLowerCase()) &&
//             details.Address.toLowerCase().includes(this.companyDetailsForm.controls['companyAddress'].value.toLowerCase()) == true

//           );
//         }
//         // for globalHQGuid, businessPartnerGUID, city

//         else if (
//           details.GlobalHQGuid && this.companyDetailsForm.controls['globalHQGuid'].value &&
//           details.Guid && this.companyDetailsForm.controls['businessPartnerGUID'].value &&
//           details.City && this.companyDetailsForm.controls['city'].value
//         ) {

//           return (

//             details.GlobalHQGuid.toLowerCase().includes(this.companyDetailsForm.controls['globalHQGuid'].value.toLowerCase()) &&
//             details.Guid.toLowerCase().includes(this.companyDetailsForm.controls['businessPartnerGUID'].value.toLowerCase()) &&
//             details.City.toLowerCase().includes(this.companyDetailsForm.controls['city'].value.toLowerCase()) == true

//           );
//         }
//         // for globalHQGuid, businessPartnerGUID, siteID

//         else if (
//           details.GlobalHQGuid && this.companyDetailsForm.controls['globalHQGuid'].value &&
//           details.Guid && this.companyDetailsForm.controls['businessPartnerGUID'].value &&
//           details.SiteID && this.companyDetailsForm.controls['siteID'].value
//         ) {

//           return (

//             details.GlobalHQGuid.toLowerCase().includes(this.companyDetailsForm.controls['globalHQGuid'].value.toLowerCase()) &&
//             details.Guid.toLowerCase().includes(this.companyDetailsForm.controls['businessPartnerGUID'].value.toLowerCase()) &&
//             details.SiteID.toLowerCase().includes(this.companyDetailsForm.controls['siteID'].value.toLowerCase()) == true

//           );
//         }


//         // for businessPartnerGUID, companyAddress, country

//         else if (
//           details.name && this.companyDetailsForm.controls['country'].value &&
//           details.Guid && this.companyDetailsForm.controls['businessPartnerGUID'].value &&
//           details.Address && this.companyDetailsForm.controls['companyAddress'].value
//         ) {

//           return (

//             details.name.toLowerCase().includes(this.companyDetailsForm.controls['country'].value.toLowerCase()) &&
//             details.Guid.toLowerCase().includes(this.companyDetailsForm.controls['businessPartnerGUID'].value.toLowerCase()) &&
//             details.Address.toLowerCase().includes(this.companyDetailsForm.controls['companyAddress'].value.toLowerCase())  == true

//           );
//         }
//         // for businessPartnerGUID, companyAddress, globalHQGuid

//         else if(
//           details.GlobalHQGuid && this.companyDetailsForm.controls['globalHQGuid'].value &&
//           details.Guid && this.companyDetailsForm.controls['businessPartnerGUID'].value &&
//           details.Address && this.companyDetailsForm.controls['companyAddress'].value
//         ) {

//           return (

//             details.GlobalHQGuid.toLowerCase().includes(this.companyDetailsForm.controls['globalHQGuid'].value.toLowerCase()) &&
//             details.Guid.toLowerCase().includes(this.companyDetailsForm.controls['businessPartnerGUID'].value.toLowerCase()) &&
//             details.Address.toLowerCase().includes(this.companyDetailsForm.controls['companyAddress'].value.toLowerCase()) == true

//           );
//         }
//         // for businessPartnerGUID, companyAddress, city

//         else if (
//           details.Guid && this.companyDetailsForm.controls['businessPartnerGUID'].value &&
//           details.Address && this.companyDetailsForm.controls['companyAddress'].value &&
//           details.City && this.companyDetailsForm.controls['city'].value
//         ) {

//           return (

//             details.Guid.toLowerCase().includes(this.companyDetailsForm.controls['businessPartnerGUID'].value.toLowerCase()) &&
//             details.Address.toLowerCase().includes(this.companyDetailsForm.controls['companyAddress'].value.toLowerCase()) &&
//             details.City.toLowerCase().includes(this.companyDetailsForm.controls['city'].value.toLowerCase()) == true

//           );
//         }
//         // for businessPartnerGUID, companyAddress, siteID


//         else if (
//           details.Guid && this.companyDetailsForm.controls['businessPartnerGUID'].value &&
//           details.Address && this.companyDetailsForm.controls['companyAddress'].value &&
//           details.SiteID && this.companyDetailsForm.controls['siteID'].value
//         ) {

//           return (

//             details.Guid.toLowerCase().includes(this.companyDetailsForm.controls['businessPartnerGUID'].value.toLowerCase()) &&
//             details.Address.toLowerCase().includes(this.companyDetailsForm.controls['companyAddress'].value.toLowerCase()) &&
//             details.SiteID.toLowerCase().includes(this.companyDetailsForm.controls['siteID'].value.toLowerCase()) == true

//           );
//         }


//         // for companyAddress, city, country

//         else if (
//           details.name && this.companyDetailsForm.controls['country'].value &&
//           details.Address && this.companyDetailsForm.controls['companyAddress'].value &&
//           details.City && this.companyDetailsForm.controls['city'].value
//         ) {

//           return (

//             details.name.toLowerCase().includes(this.companyDetailsForm.controls['country'].value.toLowerCase()) &&
//             details.Address.toLowerCase().includes(this.companyDetailsForm.controls['companyAddress'].value.toLowerCase()) &&
//             details.City.toLowerCase().includes(this.companyDetailsForm.controls['city'].value.toLowerCase())  == true

//           );
//         }
//         // for companyAddress, city, globalHQGuid

//         else if (
//           details.GlobalHQGuid && this.companyDetailsForm.controls['globalHQGuid'].value &&
//           details.Address && this.companyDetailsForm.controls['companyAddress'].value &&
//           details.City && this.companyDetailsForm.controls['city'].value
//         ) {

//           return (

//             details.GlobalHQGuid.toLowerCase().includes(this.companyDetailsForm.controls['globalHQGuid'].value.toLowerCase()) &&
//             details.Address.toLowerCase().includes(this.companyDetailsForm.controls['companyAddress'].value.toLowerCase()) &&
//             details.City.toLowerCase().includes(this.companyDetailsForm.controls['city'].value.toLowerCase()) == true

//           );
//         }
//         // for companyAddress, city, siteID


//         else if(
//           details.Address && this.companyDetailsForm.controls['companyAddress'].value &&
//           details.City && this.companyDetailsForm.controls['city'].value &&
//           details.SiteID && this.companyDetailsForm.controls['siteID'].value
//         ) {

//           return (

//             details.Address.toLowerCase().includes(this.companyDetailsForm.controls['companyAddress'].value.toLowerCase()) &&
//             details.City.toLowerCase().includes(this.companyDetailsForm.controls['city'].value.toLowerCase()) &&
//             details.SiteID.toLowerCase().includes(this.companyDetailsForm.controls['siteID'].value.toLowerCase()) == true

//           );
//         }


//         // for city, siteID, country

//         else if (
//           details.name && this.companyDetailsForm.controls['country'].value &&
//           details.City && this.companyDetailsForm.controls['city'].value &&
//           details.SiteID && this.companyDetailsForm.controls['siteID'].value
//         ) {

//           return (

//             details.name.toLowerCase().includes(this.companyDetailsForm.controls['country'].value.toLowerCase()) &&
//             details.City.toLowerCase().includes(this.companyDetailsForm.controls['city'].value.toLowerCase()) &&
//             details.SiteID.toLowerCase().includes(this.companyDetailsForm.controls['siteID'].value.toLowerCase()) == true

//           );
//         }
//         // for city, siteID, globalHQGuid

//         else if (
//           details.GlobalHQGuid && this.companyDetailsForm.controls['globalHQGuid'].value &&
//           details.City && this.companyDetailsForm.controls['city'].value &&
//           details.SiteID && this.companyDetailsForm.controls['siteID'].value
//         ) {

//           return (

//             details.GlobalHQGuid.toLowerCase().includes(this.companyDetailsForm.controls['globalHQGuid'].value.toLowerCase()) &&
//             details.City.toLowerCase().includes(this.companyDetailsForm.controls['city'].value.toLowerCase()) &&
//             details.SiteID.toLowerCase().includes(this.companyDetailsForm.controls['siteID'].value.toLowerCase()) == true

//           );
//         }
//         // for city, siteID, businessPartnerGUID


//         else if(
//           details.Guid && this.companyDetailsForm.controls['businessPartnerGUID'].value &&
//           details.City && this.companyDetailsForm.controls['city'].value &&
//           details.SiteID && this.companyDetailsForm.controls['siteID'].value
//         ) {

//           return (

//             details.Guid.toLowerCase().includes(this.companyDetailsForm.controls['businessPartnerGUID'].value.toLowerCase()) &&
//             details.City.toLowerCase().includes(this.companyDetailsForm.controls['city'].value.toLowerCase()) &&
//             details.SiteID.toLowerCase().includes(this.companyDetailsForm.controls['siteID'].value.toLowerCase()) == true

//           );
//         }
//         // for companyName, country, globalHQGuid, businessPartnerGUID, companyAddress, city, siteID
//         // search based on multiple 2 fields
//         //for companyName, country

//         else if(
//           details.FullName && this.companyDetailsForm.controls['companyName'].value &&
//           details.name && this.companyDetailsForm.controls['country'].value
//         ) {

//           return (

//             details.FullName.toLowerCase().includes(this.companyDetailsForm.controls['companyName'].value.toLowerCase()) &&
//             details.name.toLowerCase().includes(this.companyDetailsForm.controls['country'].value.toLowerCase())  == true

//           );
//         }
//         //for companyName, globalHQGuid

//         else if(
//           details.FullName && this.companyDetailsForm.controls['companyName'].value &&
//           details.GlobalHQGuid && this.companyDetailsForm.controls['globalHQGuid'].value
//         ) {

//           return (

//             details.FullName.toLowerCase().includes(this.companyDetailsForm.controls['companyName'].value.toLowerCase()) &&
//             details.GlobalHQGuid.toLowerCase().includes(this.companyDetailsForm.controls['globalHQGuid'].value.toLowerCase())  == true

//           );
//         }
//         //for companyName, businessPartnerGUID

//         else if(
//           details.FullName && this.companyDetailsForm.controls['companyName'].value &&
//           details.Guid && this.companyDetailsForm.controls['businessPartnerGUID'].value
//         ) {

//           return (

//             details.FullName.toLowerCase().includes(this.companyDetailsForm.controls['companyName'].value.toLowerCase()) &&
//             details.Guid.toLowerCase().includes(this.companyDetailsForm.controls['businessPartnerGUID'].value.toLowerCase())  == true

//           );
//         }
//         //for companyName, companyAddress

//         else if(
//           details.FullName && this.companyDetailsForm.controls['companyName'].value &&
//           details.Address && this.companyDetailsForm.controls['companyAddress'].value
//         ) {

//           return (

//             details.FullName.toLowerCase().includes(this.companyDetailsForm.controls['companyName'].value.toLowerCase()) &&
//             details.Address.toLowerCase().includes(this.companyDetailsForm.controls['companyAddress'].value.toLowerCase()) == true

//           );
//         }
//         //for companyName, city

//         else if(
//           details.FullName && this.companyDetailsForm.controls['companyName'].value &&
//           details.City && this.companyDetailsForm.controls['city'].value
//         ) {

//           return (

//             details.FullName.toLowerCase().includes(this.companyDetailsForm.controls['companyName'].value.toLowerCase()) &&
//             details.City.toLowerCase().includes(this.companyDetailsForm.controls['city'].value.toLowerCase())  == true

//           );
//         }
//         //for companyName, siteID

//         else if (
//           details.FullName && this.companyDetailsForm.controls['companyName'].value &&
//           details.SiteID && this.companyDetailsForm.controls['siteID'].value
//         ) {

//           return (

//             details.FullName.toLowerCase().includes(this.companyDetailsForm.controls['companyName'].value.toLowerCase()) &&
//             details.SiteID.toLowerCase().includes(this.companyDetailsForm.controls['siteID'].value.toLowerCase()) == true

//           );
//         }

//         //for country, globalHQGuid

//         else if(
//           details.name && this.companyDetailsForm.controls['country'].value &&
//           details.GlobalHQGuid && this.companyDetailsForm.controls['globalHQGuid'].value
//         ) {

//           return (

//             details.name.toLowerCase().includes(this.companyDetailsForm.controls['country'].value.toLowerCase()) &&
//             details.GlobalHQGuid.toLowerCase().includes(this.companyDetailsForm.controls['globalHQGuid'].value.toLowerCase())  == true

//           );
//         }
//         //for country, businessPartnerGUID

//         else if(
//           details.name && this.companyDetailsForm.controls['country'].value &&
//           details.Guid && this.companyDetailsForm.controls['businessPartnerGUID'].value
//         ) {

//           return (

//             details.name.toLowerCase().includes(this.companyDetailsForm.controls['country'].value.toLowerCase()) &&
//             details.Guid.toLowerCase().includes(this.companyDetailsForm.controls['businessPartnerGUID'].value.toLowerCase())  == true

//           );
//         }
//         //for country, companyAddress

//         else if(
//           details.name && this.companyDetailsForm.controls['country'].value &&
//           details.Address && this.companyDetailsForm.controls['companyAddress'].value
//         ) {

//           return (

//             details.name.toLowerCase().includes(this.companyDetailsForm.controls['country'].value.toLowerCase()) &&
//             details.Address.toLowerCase().includes(this.companyDetailsForm.controls['companyAddress'].value.toLowerCase()) == true

//           );
//         }
//         //for country, city

//         else if(
//           details.name && this.companyDetailsForm.controls['country'].value &&
//           details.City && this.companyDetailsForm.controls['city'].value
//         ) {

//           return (

//             details.name.toLowerCase().includes(this.companyDetailsForm.controls['country'].value.toLowerCase())  &&
//             details.City.toLowerCase().includes(this.companyDetailsForm.controls['city'].value.toLowerCase()) == true

//           );
//         }
//         //for country, siteID

//         else if (
//           details.name && this.companyDetailsForm.controls['country'].value &&
//           details.SiteID && this.companyDetailsForm.controls['siteID'].value
//         ) {

//           return (

//             details.name.toLowerCase().includes(this.companyDetailsForm.controls['country'].value.toLowerCase()) &&
//             details.SiteID.toLowerCase().includes(this.companyDetailsForm.controls['siteID'].value.toLowerCase()) == true

//           );
//         }

//         //for globalHQGuid, businessPartnerGUID

//         else if(
//           details.GlobalHQGuid && this.companyDetailsForm.controls['globalHQGuid'].value &&
//           details.Guid && this.companyDetailsForm.controls['businessPartnerGUID'].value
//         ) {

//           return (

//             details.GlobalHQGuid.toLowerCase().includes(this.companyDetailsForm.controls['globalHQGuid'].value.toLowerCase()) &&
//             details.Guid.toLowerCase().includes(this.companyDetailsForm.controls['businessPartnerGUID'].value.toLowerCase())  == true

//           );
//         }
//         //for globalHQGuid, companyAddress

//         else if(
//           details.GlobalHQGuid && this.companyDetailsForm.controls['globalHQGuid'].value &&
//           details.Address && this.companyDetailsForm.controls['companyAddress'].value
//         ) {

//           return (

//             details.GlobalHQGuid.toLowerCase().includes(this.companyDetailsForm.controls['globalHQGuid'].value.toLowerCase()) &&
//             details.Address.toLowerCase().includes(this.companyDetailsForm.controls['companyAddress'].value.toLowerCase()) == true

//           );
//         }
//         //for globalHQGuid, city

//         else if(
//           details.GlobalHQGuid && this.companyDetailsForm.controls['globalHQGuid'].value &&
//           details.City && this.companyDetailsForm.controls['city'].value
//         ) {

//           return (

//             details.GlobalHQGuid.toLowerCase().includes(this.companyDetailsForm.controls['globalHQGuid'].value.toLowerCase()) &&
//             details.City.toLowerCase().includes(this.companyDetailsForm.controls['city'].value.toLowerCase())  == true

//           );
//         }
//         //for globalHQGuid, siteID

//         else if(
//           details.GlobalHQGuid && this.companyDetailsForm.controls['globalHQGuid'].value &&
//           details.SiteID && this.companyDetailsForm.controls['siteID'].value
//         ) {

//           return (

//             details.GlobalHQGuid.toLowerCase().includes(this.companyDetailsForm.controls['globalHQGuid'].value.toLowerCase()) &&
//             details.SiteID.toLowerCase().includes(this.companyDetailsForm.controls['siteID'].value.toLowerCase()) == true

//           );
//         }
//         //for businessPartnerGUID, companyAddress

//         else if(
//           details.Guid && this.companyDetailsForm.controls['businessPartnerGUID'].value &&
//           details.Address && this.companyDetailsForm.controls['companyAddress'].value
//         ) {

//           return (

//             details.Guid.toLowerCase().includes(this.companyDetailsForm.controls['businessPartnerGUID'].value.toLowerCase()) &&
//             details.Address.toLowerCase().includes(this.companyDetailsForm.controls['companyAddress'].value.toLowerCase())  == true

//           );
//         }
//         //for businessPartnerGUID, city

//         else if(
//           details.Guid && this.companyDetailsForm.controls['businessPartnerGUID'].value &&
//           details.City && this.companyDetailsForm.controls['city'].value
//         ) {

//           return (

//             details.Guid.toLowerCase().includes(this.companyDetailsForm.controls['businessPartnerGUID'].value.toLowerCase()) &&
//             details.City.toLowerCase().includes(this.companyDetailsForm.controls['city'].value.toLowerCase()) == true

//           );
//         }
//         //for businessPartnerGUID, siteID

//         else if(
//           details.Guid && this.companyDetailsForm.controls['businessPartnerGUID'].value &&
//           details.SiteID && this.companyDetailsForm.controls['siteID'].value
//         ) {

//           return (

//             details.Guid.toLowerCase().includes(this.companyDetailsForm.controls['businessPartnerGUID'].value.toLowerCase()) &&
//             details.SiteID.toLowerCase().includes(this.companyDetailsForm.controls['siteID'].value.toLowerCase()) == true

//           );
//         }
//         //for companyAddress, city

//         else if(
//           details.Address && this.companyDetailsForm.controls['companyAddress'].value &&
//           details.City && this.companyDetailsForm.controls['city'].value
//         ) {

//           return (

//             details.Address.toLowerCase().includes(this.companyDetailsForm.controls['companyAddress'].value.toLowerCase()) &&
//             details.City.toLowerCase().includes(this.companyDetailsForm.controls['city'].value.toLowerCase())  == true

//           );
//         }
//         //for companyAddress, siteID

//         else if(
//           details.Address && this.companyDetailsForm.controls['companyAddress'].value &&
//           details.SiteID && this.companyDetailsForm.controls['siteID'].value
//         ) {

//           return (

//             details.Address.toLowerCase().includes(this.companyDetailsForm.controls['companyAddress'].value.toLowerCase()) &&
//             details.SiteID.toLowerCase().includes(this.companyDetailsForm.controls['siteID'].value.toLowerCase()) == true

//           );
//         }

//         //for city, siteID

//         else if(
//           details.City && this.companyDetailsForm.controls['city'].value &&
//           details.SiteID && this.companyDetailsForm.controls['siteID'].value
//         ) {

//           return (

//             details.City.toLowerCase().includes(this.companyDetailsForm.controls['city'].value.toLowerCase()) &&
//             details.SiteID.toLowerCase().includes(this.companyDetailsForm.controls['siteID'].value.toLowerCase()) == true

//           );
//         }

//         // search based on single fields.
//         else if (
//           details.name &&
//           this.companyDetailsForm.controls['country'].value
//         ) {
//           // console.log('country single field triggering before entering return');
//           return (
//             details.name
//               .toLowerCase()
//               .includes(
//                 this.companyDetailsForm.controls['country'].value.toLowerCase()
//               ) == true
//           );
//         } else if (
//           details.Address &&
//           this.companyDetailsForm.controls['companyAddress'].value
//         ) {
//           return (

//             details.Address.toLowerCase().includes(
//               this.companyDetailsForm.controls[
//                 'companyAddress'
//               ].value.toLowerCase()
//             ) == true
//           );
//         } else if (
//           details.Guid &&
//           this.companyDetailsForm.controls['businessPartnerGUID'].value
//         ) {
//           return (
//             details.Guid.toLowerCase().includes(
//               this.companyDetailsForm.controls[
//                 'businessPartnerGUID'
//               ].value.toLowerCase()
//             ) == true
//           );
//         } else if (
//           details.FullName &&
//           this.companyDetailsForm.controls['companyName'].value
//         ) {
//           console.log('company name field triggering');
//           return details.FullName.toLowerCase().includes(
//             this.companyDetailsForm.controls['companyName'].value.toLowerCase()
//           );
//         }
//         //for city
//         else if (
//           details.City &&
//           this.companyDetailsForm.controls['city'].value
//         ) {
//           return (
//             details.City.toLowerCase().includes(
//               this.companyDetailsForm.controls['city'].value.toLowerCase()
//             ) == true
//           );
//         }
//         //for  global hq guid
//         else if (
//           details.GlobalHQGuid &&
//           this.companyDetailsForm.controls['globalHQGuid'].value
//         ) {
//           return (
//             details.GlobalHQGuid.toLowerCase().includes(
//               this.companyDetailsForm.controls[
//                 'globalHQGuid'
//               ].value.toLowerCase()
//             ) == true
//           );
//         }
//         //  for siteid
//         else if (
//           details.SiteID &&
//           this.companyDetailsForm.controls['siteID'].value
//         ) {
//           return (
//             details.SiteID.toLowerCase().includes(
//               this.companyDetailsForm.controls['siteID'].value.toLowerCase()
//             ) == true
//           );
//         } else {
//           // console.log('else data');
//           return 'their is no such data you searched,try again..!';
//         }
//       });
//   }
}

