import { HttpClient } from '@angular/common/http';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { TreeNode } from 'primeng/api';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { DefectPopupService } from 'src/app/services/defect-popup.service';

import { PgrcacategoryServiceService } from 'src/app/services/pgrcacategory-service.service';
import { SessionStorageServiceService } from 'src/app/services/session-storage-service.service';

@Component({
  selector: 'app-pgrcacategory-pop-up',
  templateUrl: './pgrcacategory-pop-up.component.html',
  styleUrls: ['./pgrcacategory-pop-up.component.css']
})
export class PGRCACategoryPopUpComponent implements OnInit {

  files3: any[] = [];
  selectedNode!: TreeNode;
  codevariable: any;
  isActiveVariable: any;
  geidVariable: any;
  childData: any;
  loading: boolean = false;

  constructor(
    private http: HttpClient,
    private pgrcService: PgrcacategoryServiceService,
    private readonly dialogRef: DynamicDialogRef,
    private sessionService : SessionStorageServiceService,
    public config: DynamicDialogConfig,
    private defectPopupService: DefectPopupService
  ) { }

  ngOnInit(): void {
     //In line no 34 code we need to send the value of complaint resolving unit PG Code
      //const code = { code: this.config?.data?.pgCode, isActive: 'True' }
      const payload = this.config?.data?.pgrca
      this.loading = true;
      this.defectPopupService.getDefectCause(payload).then(files2 => {
        if (files2) {
          this.loading = false;
          this.files3 = files2;
          this.files3.map((res) => {
          res.label = res.name,
          res.data = res.flatName,
          // res.expandedIcon = 'pi pi-folder-open',
          // res.collapsedIcon = 'pi pi-folder',
          res.children = [],
          res.id = res.id,
          // res.codeKey = code.code,
          // res.codeKeyActive = code.isActive,
          res.leaf = res.hasSubClasses == true ? false : true,
          res.selectable = res.hasSubClasses ? false : true
          });
        }
      }, error=>{
        this.loading = false;
      });

  }
  createNode(i: number, children: number): TreeNode {
    let node: TreeNode = {
      label: 'Node ' + i,
      data: 'Node ' + i,
      // expandedIcon: 'pi pi-folder-open',
      // collapsedIcon: 'pi pi-folder',
      children: Array.from({ length: children }).map((_, j) => {
        return {
          label: 'Node ' + i + '.' + j,
          data: 'Node ' + i + '.' + j,
          icon: 'pi pi-file-o'
        }
      })
    };
    return node;
  }

  nodeSelect(event: any) {
    let parentList;
    if(event.node?.parent?.data) {
      parentList = event.node?.parent?.data + " / ";
    }
    else {
      parentList = '';
    }
    // this.selectedNode?.data =  parentList +  event.originalEvent.target.innerText;
    const payload = this.config?.data?.pgrca;
    payload.parentId = event?.node?.id;
    const nodeselection = event.node;
    if(nodeselection.hasSubClasses) {
      this.loading = true;
      //const isActive = nodeselection.codeKeyActive ? nodeselection.codeKeyActive : 'True'
      this.defectPopupService.getDefectCauseChildren(payload).then((res) => {
        if(res){
          this.files3 = this.newH(res, this.files3, nodeselection.id)
          this.loading = false;
        }
      }, error=>{
        this.loading = false;
      })
    }
  }

  newH(newData: any, h ,id){
      h.forEach((node, index) => {
          if(node.id == id){
          const New = []
           newData.map((res) => {
                    res.label = res.name,
                    res.data = res.flatName,
                    // res.expandedIcon = 'pi pi-folder-open',
                    // res.collapsedIcon = 'pi pi-folder',
                    res.children = res.hasSubClasses,
                    // res.codeKey = res.codeKey,
                    res.id = res.id,
                    // res.codeKeyActive = res.codeKeyActive,
                    res.leaf = res.hasSubClasses == true ? false : true
                  });
            node.children = [...newData]
          } else if(node.children.length) {
             this.newH(newData, node.children,id)
          }
      })

      return h
    }


  // Data are disabled
  enableAll() {
    this.files3.forEach((node: any) => {
      this.enableRecursive(node, false);
    })
  }

  private enableRecursive(node: any, isSelectable: boolean) {
    node.selectable = node.disabled;
    if (node.children) {
      node.children.forEach((childNode: any) => {
        this.enableRecursive(childNode, childNode.disabled);
      });
    }
  }

  selectAndClose() {
    this.dialogRef.close(this.selectedNode);
  }

  onClose() {
    this.dialogRef.close();
  }

}
