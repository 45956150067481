import { Component, OnInit } from '@angular/core';
import moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { WeeklyReportViewCount, WeeklyreportserviceService } from 'src/app/services/weeklyreportservice.service';

@Component({
  selector: 'app-weekly-report',
  templateUrl: './weekly-report.component.html',
  styleUrls: ['./weekly-report.component.css']
})
export class WeeklyReportComponent implements OnInit {

  isFullDateVisible:boolean = true;
  hideDate = false;
  active = [];
  reportData:WeeklyReportViewCount;
  showCount = false;
  selected = {
    hasHighImpactClassA: null,
    hasHighImpactClassB: null,
    hasHighImpactClassI: null,
    classType: null,
    dateType: 'ApprovedDate',
    startDate: moment().add('week', -1).toDate(),
    endDate: moment().add('week', 0).toDate()
   };
   dateRange = {
    endMinDate: null,
    startMaxDate: null
  };

  constructor(private wklyService:WeeklyreportserviceService,
    public breadcrumbService: BreadcrumbService,
    private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.breadcrumbService.menuItems = [
      {label: 'REPORTS', routerLink: '/reports'},
      {label: 'WEEKLY REPORT'}
    ];
  }
    hasHighImpactAClicked = function () {
    this.selected.hasHighImpactClassA = !this.selected.hasHighImpactClassA;
   };

   hasHighImpactBClicked = function () {
   this.selected.hasHighImpactClassB = !this.selected.hasHighImpactClassB;
   };

  hasHighImpactIClicked = function () {
    this.selected.hasHighImpactClassI = !this.selected.hasHighImpactClassI;
  };

  startDateChanged = function (val) {
    if (val && moment(val))
        this.dateRange.endMinDate = moment(val).startOf('day').format();
  };

endDateChanged = function (val) {
    if (val && moment(val))
        this.dateRange.startMaxDate = moment(val).endOf('day').format();

};

calculateClassType = function () {
  let arrayclass = ['hasHighImpactClassA','hasHighImpactClassB','hasHighImpactClassI']
  let arrayclassabc = ['A', 'B', 'I']
  let charvalue = '';
  if (!this.selected.hasHighImpactClassA && !this.selected.hasHighImpactClassB && !this.selected.hasHighImpactClassI)
      this.selected.classType = null;
  else
  {
      for (var i = 0; i < arrayclass.length; i++) {
          if (!this.selected[arrayclass[i]] == true) {
              if (charvalue != '') {
                  charvalue = charvalue + ',' + arrayclassabc[i];
              }
              else {
                 charvalue = arrayclassabc[i]
              }
          }
      }
      this.selected.classType = charvalue;
  }
};

formatdatetime = function () {
  this.selected.startDate = moment(this.selected.startDate).format('YYYY-MM-DD');
  this.selected.endDate = moment(this.selected.endDate).format('YYYY-MM-DD');
};

viewCount()  {
  this.spinner.show();
  this.calculateClassType();
  this.formatdatetime();

  this.wklyService.getReportCount(this.selected).subscribe(
    (data:WeeklyReportViewCount) =>{
      this.showCount = true;
      this.reportData = data;
      this.spinner.hide();
    },
    (err)=>{
      this.spinner.hide();
    }
  )

};

exportToExcel = function () {
  this.calculateClassType();
  this.formatdatetime();
  this.wklyService.exportToExcel(this.selected).subscribe((response: ArrayBuffer) => {
    const blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const downloadLink = document.createElement('a');
    downloadLink.href = window.URL.createObjectURL(blob);
    downloadLink.download = 'WeeklyReport';
    downloadLink.click();
    this.viewCount()
  });
};

autoConfirmedCCRP() {
  this.spinner.show();
  this.calculateClassType();
  this.formatdatetime();
  this.wklyService.autoConfirmedCCRP(this.selected).subscribe((response: ArrayBuffer) => {
    const blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const downloadLink = document.createElement('a');
    downloadLink.href = window.URL.createObjectURL(blob);
    downloadLink.download = 'AutoConfirmedCCRPReport';
    downloadLink.click();
    this.viewCount()
    this.spinner.hide();
  },
  (err)=>{
    this.spinner.hide();
  });
};

clear = function () {
  this.reportData = [];
  this.showCount = false;
  this.selected.classType = null;
  this.selected.hasHighImpactClassB = false;
  this.selected.hasHighImpactClassA = false;
  this.selected.hasHighImpactClassI = false;
  this.selected.startDate = moment().add('week', -1).toDate(),
  this.selected.endDate = moment().add('week', 0).toDate();
  this.selected.dateType = 'ApprovedDate';
};

incorrectDateRange(){
  if(moment(this.selected.startDate).format('YYYY-MM-DD') > moment(this.selected.endDate).format('YYYY-MM-DD')){
    return true;
  }
  else{
    return false;
  }
}

}
