import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { MessageService } from 'primeng/api';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-issue-closure-confirmation-dialog',
  templateUrl: './issue-closure-confirmation-dialog.component.html',
  styleUrls: ['./issue-closure-confirmation-dialog.component.css']
})
export class IssueClosureConfirmationDialogComponent implements OnInit {

  // @Input() classDetailsCompleted: boolean;
  @Input() parentForm : FormGroup;
  userExists = false;
  issueClosureConfirmForm!: FormGroup;

  constructor(private readonly dialogRef: DynamicDialogRef,
    private sharedService: SharedService,
    private messageService: MessageService) {
  }

  ngOnInit(): void {
  }

  issueClose() {
    this.sharedService.enableDiv = true;
    this.dialogRef.close('result');
  }

  onClose() {
    this.dialogRef.close();
  }


}

