import { Component, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ExportAsConfig, ExportAsService } from 'ngx-export-as';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { ComplaintDetailsService } from 'src/app/services/complaintDetails/complaint-details.service';
import { ToasterserviceService } from 'src/app/services/toasterservice.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-issue-summaryreport',
  templateUrl: './issue-summaryreport.component.html',
  styleUrls: ['./issue-summaryreport.component.css']
})
export class IssueSummaryreportComponent implements OnInit {

  complaintData:any;
  complaintId:any;

  exportAsPDF: ExportAsConfig = {
    type: 'pdf',
    elementIdOrContent: 'SummaryReport',
    options: {
      orientation: 'landscape',
      margins: {
        top: '20'
      },
      footer:{
        text:'Ramprasad',
        alignment:'center',
        fontSize:10,
        pageNumber:true,
        color:'#000000',
        fontStyle:'italic'
      }
    }
  }

  complaintCreatedDate:any = new Date();
  ncrLiveDate:any = new Date(environment.ncrLiveDate);

  constructor(private exportAsService: ExportAsService,
    private route: ActivatedRoute,
    public router: Router,
    private complaintDetails:ComplaintDetailsService,
    private toaster:ToasterserviceService,
    private renderer:Renderer2,
    private breadcrumbService: BreadcrumbService) { }

  ngOnInit(): void {
    this.breadcrumbService.menuItems = [
      { label: 'SUMMARY REPORT' }
    ];
    if(this.route.snapshot.paramMap.get('id')){
      this.complaintId = this.route.snapshot.paramMap.get('id');
      this.complaintDetails.downloadIssueSummaryReport(this.complaintId).subscribe(
        (data:any)=>{
          this.complaintData = data;
          this.complaintCreatedDate = new Date(this.complaintData?.creationDate);
        },
        (err)=>{
          this.toaster.showError('Something went wrong');
        }
      )

    }
  }

  exportToPDF(){
    this.exportAsService.save(this.exportAsPDF, 'CCRP Summary').subscribe(() => {
      // save started
    });
  }


  exportToWord(){
   const styles = `
   th,td{
      font-family: Helvetica Neue LT Pro, Arial, sans-serif;
      border: 1px solid #000000;
      padding-left: 5px;
      padding-top: 3px;
      padding-bottom: 3px;
    }

    h4 {
     font-size: 26px !important;
     text-align:center;
     font-weight: bold;
    }

    th{
      font-size: 16px;
      font-weight: bold;
    }

    table{
      width: 100%;

    }

    .table {
      border: 1px solid #000000;
    }

    .abb-logo {
      margin: 0px;
      padding: 0px;
      background: url(./assets/images/hitachiNewlogo.png) no-repeat right;
      background-size: 145px 25px;
      width: 170px;
      height: 26px;
      display: inline-block;
    }


    .complaintdata{
      color: rgb(88, 88, 229);
    }

    .headertd{
      font-weight: bold;
      width: 35%;
    }


    .widgetButtoSmall {
      min-width: 100px;
      /* max-width: 100px; */
      cursor: pointer;
      margin-top: 3px;
      margin-bottom: 3px;
      background: no-repeat scroll 2px -38px #FF0026;
      color: #FFF;
      border: #FF0026;
      border: none;
      font-family: Helvetica Neue LT Pro, Arial, sans-serif;
      font-size: 14px;
      min-height: 20px;
      position: relative;
      align-items: center; padding: 2px 8px 3px 8px !important;border-radius: 0px;
    }

    a {
      text-decoration: none;
      color: inherit;
      cursor: pointer;
    }

    a.exportcls {
      font-weight: 500;margin-top: -15px;
      float: left;
      }

    a.exportcls:hover {
      color: #ff0026;
    }
    .export-btn{float: left;margin-top: 2px;}

    b{
      padding: 0px;
    }

    table tr td:first-child::after {
      content: "";
      display: inline-block;
      vertical-align: top;
      min-height: 22px;
    }
    span.complaintdata.whiteCls{
      background: none !important;
    }

    .table>:not(caption)>*>* {
      background-color:none !important;
    }

    .footer-data{
      display:inline-block !important;
    }

    `;

   const css = (
      `<style>
         ${styles}
      </style>`
    );

    var html = document.querySelector('#SummaryReport').innerHTML;
    var blob = new Blob(['\ufeff', css + html], {
      type: 'application/msword'
    });
      const downloadLink = document.createElement('a');
      downloadLink.href = window.URL.createObjectURL(blob);
      downloadLink.download = 'Summary Report';
      downloadLink.click();
  }

 }
