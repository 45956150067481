import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { ToasterserviceService } from 'src/app/services/toasterservice.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-active-employees',
  templateUrl: './active-employees.component.html',
  styleUrls: ['./active-employees.component.css']
})
export class ActiveEmployeesComponent implements OnInit {

  IsExcelGenerating:boolean = false;

  constructor(private http:HttpClient,
     private toster:ToasterserviceService,
     private breadcrumbService: BreadcrumbService
     ) { }

  ngOnInit(): void {
    this.breadcrumbService.menuItems= [

      { label: 'ADMIN PANEL', routerLink: 'admin'},
      { label: 'ACTIVE EMPLOYEE'}

    ]
  }

  exportActiveEmployee(){

    this.IsExcelGenerating = true;

    this.http.get(`${environment.azure.baseUrl}api/v1/employee/activeGEID`,{
      responseType: 'arraybuffer'
    }).subscribe((response: ArrayBuffer) => {
      const blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const downloadLink = document.createElement('a');
      downloadLink.href = window.URL.createObjectURL(blob);
      downloadLink.download = 'ActiveEmployeeReport';
      downloadLink.click();
      this.IsExcelGenerating = false;
    },
     (err)=>{
      this.toster.showError('While Generating Report');
      this.IsExcelGenerating = false;
     });

  }
}
