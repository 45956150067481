<form [formGroup]="addPersonForm">
    <div class="addPersonPopup">
        <p-toast></p-toast>
        <div class="row mb-2">

            <div class="col-sm-3 div-align-items-right addPopLabelHub label-bold">
                <span>{{'hubpersonadd_hubcselectedhubs_mod_lbl'|translate}}</span> &nbsp;&nbsp;
            </div>

            <div class="col-sm-9 inputAddHub">
                <span class="multiselect-field_remove">
                    <p-autoComplete class="substituteCustom" [forceSelection]="true" formControlName="hubIds"
                        [multiple]="true" type="text" [suggestions]="this.cardData" (onUnselect)="removecardid($event)"
                        field="hubsData">
                    </p-autoComplete>
                </span>
            </div>
        </div>

        <div class="row mb-2">

            <div class="col-sm-3 div-align-items-right addPopLabelHub  label-bold">
                <span>{{'hubpersonadd_hubprocowners_mod_lbl'|translate}}</span> &nbsp;&nbsp;
            </div>

            <div class="col-sm-9 inputAddHub">
                <span class="multiselect-field_remove">
                    <p-autoComplete class="border-0 substituteCustom" [forceSelection]="true"
                        formControlName="processOwner" [multiple]="true" type="text" [suggestions]="filteredHubPO"
                        [dropdownIcon]="'none'" (onSelect)="customerSelect($event)"
                        (onUnselect)="customerGuidsUnselect($event)" (completeMethod)="filterHubProcessOwner($event)"
                        field="name" placeholder="{{'hubpersonadd_hubprocowners_mod_ph'|translate}}">
                        <ng-template let-filteredHubPO pTemplate="item">
                          <div class="flex align-items-center gap-2">
                                <div class="row">
                                  <div class="col-8"><strong>{{ filteredHubPO?.name }} {{ filteredHubPO?.isActive ? '':'(Inactive)' }}</strong></div>
                                  <div class="col-4 text-end"><i>{{filteredHubPO?.city}}({{filteredHubPO.iso}})</i></div>
                                </div>
                                <div class="row">
                                  <div class="col">{{filteredHubPO?.email}}</div>
                                </div>
                          </div>
                      </ng-template>
                    </p-autoComplete>
                </span>
            </div>
        </div>

        <div class="row mt-2">
            <div class="col-sm-4"></div>
            <div class="col-sm-4"></div>
            <div class="col-sm-4 div-align-items-right firefoxalign">
                <button class="input widgetButtonLarge btnAddPopupHub" type="button"
                    (click)="onClose()">{{'admin_ru_addpeson_close_h'|translate}}</button>
                <button class="input ms-1 btnAddPopupHub" type="button" [disabled]="addPersonForm.invalid"
                    [ngClass]="addPersonForm.valid ? 'widgetButtonLarge' : ' widgetButtonLargeDisabled'"
                    (click)="add()">{{'admin_ru_addpeson_ok_btn'|translate}}
                </button>
            </div>
        </div>

    </div>
</form>
