import * as i0 from '@angular/core';
import { Injectable, EventEmitter, PLATFORM_ID, Component, ViewEncapsulation, Inject, Input, Output, ChangeDetectionStrategy, ContentChildren, ViewChild, NgModule } from '@angular/core';
import * as i1 from '@angular/common';
import { isPlatformBrowser, DOCUMENT, CommonModule } from '@angular/common';
import { ZIndexUtils } from 'primeng/utils';
import * as i5 from 'primeng/api';
import { PrimeTemplate, SharedModule } from 'primeng/api';
import * as i2 from '@angular/router';
import { RouterModule } from '@angular/router';
import * as i3 from 'primeng/ripple';
import { RippleModule } from 'primeng/ripple';
import * as i4 from 'primeng/tooltip';
import { TooltipModule } from 'primeng/tooltip';
import { Subject, debounce, interval, filter } from 'rxjs';
import { BarsIcon } from 'primeng/icons/bars';
import { AngleDownIcon } from 'primeng/icons/angledown';
import { AngleRightIcon } from 'primeng/icons/angleright';
const _c0 = (a0, a1) => ({
  "p-submenu-list": a0,
  "p-menubar-root-list": a1
});
const _c1 = a0 => ({
  "p-hidden": a0
});
const _c2 = (a0, a1) => ({
  "p-menuitem": true,
  "p-menuitem-active": a0,
  "p-hidden": a1
});
const _c3 = a0 => ({
  "p-menuitem-link": true,
  "p-disabled": a0
});
const _c4 = () => ({
  exact: false
});
function MenubarSub_ng_template_1_li_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "li", 7);
  }
  if (rf & 2) {
    const child_r1 = i0.ɵɵnextContext().$implicit;
    i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction1(1, _c1, child_r1.visible === false));
  }
}
function MenubarSub_ng_template_1_li_1_a_2_span_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "span", 17);
  }
  if (rf & 2) {
    const child_r1 = i0.ɵɵnextContext(3).$implicit;
    i0.ɵɵproperty("ngClass", child_r1.icon)("ngStyle", child_r1.iconStyle);
  }
}
function MenubarSub_ng_template_1_li_1_a_2_span_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span", 18);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const child_r1 = i0.ɵɵnextContext(3).$implicit;
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(child_r1.label);
  }
}
function MenubarSub_ng_template_1_li_1_a_2_ng_template_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "span", 19);
  }
  if (rf & 2) {
    const child_r1 = i0.ɵɵnextContext(3).$implicit;
    i0.ɵɵproperty("innerHTML", child_r1.label, i0.ɵɵsanitizeHtml);
  }
}
function MenubarSub_ng_template_1_li_1_a_2_span_5_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span", 20);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const child_r1 = i0.ɵɵnextContext(3).$implicit;
    i0.ɵɵproperty("ngClass", child_r1.badgeStyleClass);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(child_r1.badge);
  }
}
function MenubarSub_ng_template_1_li_1_a_2_ng_container_6_ng_container_1_AngleDownIcon_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "AngleDownIcon", 23);
  }
  if (rf & 2) {
    i0.ɵɵproperty("styleClass", "p-submenu-icon");
  }
}
function MenubarSub_ng_template_1_li_1_a_2_ng_container_6_ng_container_1_AngleRightIcon_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "AngleRightIcon", 23);
  }
  if (rf & 2) {
    i0.ɵɵproperty("styleClass", "p-submenu-icon");
  }
}
function MenubarSub_ng_template_1_li_1_a_2_ng_container_6_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, MenubarSub_ng_template_1_li_1_a_2_ng_container_6_ng_container_1_AngleDownIcon_1_Template, 1, 1, "AngleDownIcon", 22)(2, MenubarSub_ng_template_1_li_1_a_2_ng_container_6_ng_container_1_AngleRightIcon_2_Template, 1, 1, "AngleRightIcon", 22);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext(5);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r2.root);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !ctx_r2.root);
  }
}
function MenubarSub_ng_template_1_li_1_a_2_ng_container_6_2_ng_template_0_Template(rf, ctx) {}
function MenubarSub_ng_template_1_li_1_a_2_ng_container_6_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, MenubarSub_ng_template_1_li_1_a_2_ng_container_6_2_ng_template_0_Template, 0, 0, "ng-template");
  }
}
function MenubarSub_ng_template_1_li_1_a_2_ng_container_6_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, MenubarSub_ng_template_1_li_1_a_2_ng_container_6_ng_container_1_Template, 3, 2, "ng-container", 16)(2, MenubarSub_ng_template_1_li_1_a_2_ng_container_6_2_Template, 1, 0, null, 21);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext(4);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !ctx_r2.menubar.submenuIconTemplate);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r2.menubar.submenuIconTemplate);
  }
}
function MenubarSub_ng_template_1_li_1_a_2_Template(rf, ctx) {
  if (rf & 1) {
    const _r2 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "a", 12);
    i0.ɵɵlistener("click", function MenubarSub_ng_template_1_li_1_a_2_Template_a_click_0_listener($event) {
      i0.ɵɵrestoreView(_r2);
      const child_r1 = i0.ɵɵnextContext(2).$implicit;
      const ctx_r2 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r2.onItemClick($event, child_r1));
    })("mouseenter", function MenubarSub_ng_template_1_li_1_a_2_Template_a_mouseenter_0_listener($event) {
      i0.ɵɵrestoreView(_r2);
      const child_r1 = i0.ɵɵnextContext(2).$implicit;
      const ctx_r2 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r2.onItemMouseEnter($event, child_r1));
    })("mouseleave", function MenubarSub_ng_template_1_li_1_a_2_Template_a_mouseleave_0_listener($event) {
      i0.ɵɵrestoreView(_r2);
      const child_r1 = i0.ɵɵnextContext(2).$implicit;
      const ctx_r2 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r2.onItemMouseLeave($event, child_r1));
    });
    i0.ɵɵtemplate(1, MenubarSub_ng_template_1_li_1_a_2_span_1_Template, 1, 2, "span", 13)(2, MenubarSub_ng_template_1_li_1_a_2_span_2_Template, 2, 1, "span", 14)(3, MenubarSub_ng_template_1_li_1_a_2_ng_template_3_Template, 1, 1, "ng-template", null, 1, i0.ɵɵtemplateRefExtractor)(5, MenubarSub_ng_template_1_li_1_a_2_span_5_Template, 2, 2, "span", 15)(6, MenubarSub_ng_template_1_li_1_a_2_ng_container_6_Template, 3, 2, "ng-container", 16);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const htmlLabel_r4 = i0.ɵɵreference(4);
    const child_r1 = i0.ɵɵnextContext(2).$implicit;
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵproperty("target", child_r1.target)("ngClass", i0.ɵɵpureFunction1(14, _c3, child_r1.disabled));
    i0.ɵɵattribute("href", child_r1.url, i0.ɵɵsanitizeUrl)("data-automationid", child_r1.automationId)("title", child_r1.title)("id", child_r1.id)("tabindex", child_r1.disabled ? null : "0")("aria-haspopup", ctx_r2.item.items != null)("aria-expanded", ctx_r2.item === ctx_r2.activeItem);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", child_r1.icon);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", child_r1.escape !== false)("ngIfElse", htmlLabel_r4);
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("ngIf", child_r1.badge);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", child_r1.items);
  }
}
function MenubarSub_ng_template_1_li_1_a_3_span_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "span", 17);
  }
  if (rf & 2) {
    const child_r1 = i0.ɵɵnextContext(3).$implicit;
    i0.ɵɵproperty("ngClass", child_r1.icon)("ngStyle", child_r1.iconStyle);
  }
}
function MenubarSub_ng_template_1_li_1_a_3_span_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span", 18);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const child_r1 = i0.ɵɵnextContext(3).$implicit;
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(child_r1.label);
  }
}
function MenubarSub_ng_template_1_li_1_a_3_ng_template_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "span", 19);
  }
  if (rf & 2) {
    const child_r1 = i0.ɵɵnextContext(3).$implicit;
    i0.ɵɵproperty("innerHTML", child_r1.label, i0.ɵɵsanitizeHtml);
  }
}
function MenubarSub_ng_template_1_li_1_a_3_span_5_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span", 20);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const child_r1 = i0.ɵɵnextContext(3).$implicit;
    i0.ɵɵproperty("ngClass", child_r1.badgeStyleClass);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(child_r1.badge);
  }
}
function MenubarSub_ng_template_1_li_1_a_3_ng_container_6_ng_container_1_AngleDownIcon_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "AngleDownIcon", 23);
  }
  if (rf & 2) {
    i0.ɵɵproperty("styleClass", "p-submenu-icon");
  }
}
function MenubarSub_ng_template_1_li_1_a_3_ng_container_6_ng_container_1_AngleRightIcon_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "AngleRightIcon", 23);
  }
  if (rf & 2) {
    i0.ɵɵproperty("styleClass", "p-submenu-icon");
  }
}
function MenubarSub_ng_template_1_li_1_a_3_ng_container_6_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, MenubarSub_ng_template_1_li_1_a_3_ng_container_6_ng_container_1_AngleDownIcon_1_Template, 1, 1, "AngleDownIcon", 22)(2, MenubarSub_ng_template_1_li_1_a_3_ng_container_6_ng_container_1_AngleRightIcon_2_Template, 1, 1, "AngleRightIcon", 22);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext(5);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r2.root);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !ctx_r2.root);
  }
}
function MenubarSub_ng_template_1_li_1_a_3_ng_container_6_2_ng_template_0_Template(rf, ctx) {}
function MenubarSub_ng_template_1_li_1_a_3_ng_container_6_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, MenubarSub_ng_template_1_li_1_a_3_ng_container_6_2_ng_template_0_Template, 0, 0, "ng-template");
  }
}
function MenubarSub_ng_template_1_li_1_a_3_ng_container_6_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, MenubarSub_ng_template_1_li_1_a_3_ng_container_6_ng_container_1_Template, 3, 2, "ng-container", 16)(2, MenubarSub_ng_template_1_li_1_a_3_ng_container_6_2_Template, 1, 0, null, 21);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext(4);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !ctx_r2.menubar.submenuIconTemplate);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r2.menubar.submenuIconTemplate);
  }
}
function MenubarSub_ng_template_1_li_1_a_3_Template(rf, ctx) {
  if (rf & 1) {
    const _r5 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "a", 24);
    i0.ɵɵlistener("click", function MenubarSub_ng_template_1_li_1_a_3_Template_a_click_0_listener($event) {
      i0.ɵɵrestoreView(_r5);
      const child_r1 = i0.ɵɵnextContext(2).$implicit;
      const ctx_r2 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r2.onItemClick($event, child_r1));
    })("mouseenter", function MenubarSub_ng_template_1_li_1_a_3_Template_a_mouseenter_0_listener($event) {
      i0.ɵɵrestoreView(_r5);
      const child_r1 = i0.ɵɵnextContext(2).$implicit;
      const ctx_r2 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r2.onItemMouseEnter($event, child_r1));
    })("mouseleave", function MenubarSub_ng_template_1_li_1_a_3_Template_a_mouseleave_0_listener($event) {
      i0.ɵɵrestoreView(_r5);
      const child_r1 = i0.ɵɵnextContext(2).$implicit;
      const ctx_r2 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r2.onItemMouseLeave($event, child_r1));
    });
    i0.ɵɵtemplate(1, MenubarSub_ng_template_1_li_1_a_3_span_1_Template, 1, 2, "span", 13)(2, MenubarSub_ng_template_1_li_1_a_3_span_2_Template, 2, 1, "span", 14)(3, MenubarSub_ng_template_1_li_1_a_3_ng_template_3_Template, 1, 1, "ng-template", null, 2, i0.ɵɵtemplateRefExtractor)(5, MenubarSub_ng_template_1_li_1_a_3_span_5_Template, 2, 2, "span", 15)(6, MenubarSub_ng_template_1_li_1_a_3_ng_container_6_Template, 3, 2, "ng-container", 16);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const htmlRouteLabel_r6 = i0.ɵɵreference(4);
    const child_r1 = i0.ɵɵnextContext(2).$implicit;
    i0.ɵɵproperty("routerLink", child_r1.routerLink)("queryParams", child_r1.queryParams)("routerLinkActive", "p-menuitem-link-active")("routerLinkActiveOptions", child_r1.routerLinkActiveOptions || i0.ɵɵpureFunction0(21, _c4))("target", child_r1.target)("ngClass", i0.ɵɵpureFunction1(22, _c3, child_r1.disabled))("fragment", child_r1.fragment)("queryParamsHandling", child_r1.queryParamsHandling)("preserveFragment", child_r1.preserveFragment)("skipLocationChange", child_r1.skipLocationChange)("replaceUrl", child_r1.replaceUrl)("state", child_r1.state);
    i0.ɵɵattribute("data-automationid", child_r1.automationId)("title", child_r1.title)("id", child_r1.id)("tabindex", child_r1.disabled ? null : "0");
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", child_r1.icon);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", child_r1.escape !== false)("ngIfElse", htmlRouteLabel_r6);
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("ngIf", child_r1.badge);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", child_r1.items);
  }
}
function MenubarSub_ng_template_1_li_1_p_menubarSub_4_Template(rf, ctx) {
  if (rf & 1) {
    const _r7 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "p-menubarSub", 25);
    i0.ɵɵlistener("leafClick", function MenubarSub_ng_template_1_li_1_p_menubarSub_4_Template_p_menubarSub_leafClick_0_listener() {
      i0.ɵɵrestoreView(_r7);
      const ctx_r2 = i0.ɵɵnextContext(3);
      return i0.ɵɵresetView(ctx_r2.onLeafClick());
    });
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const child_r1 = i0.ɵɵnextContext(2).$implicit;
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵproperty("parentActive", child_r1 === ctx_r2.activeItem)("item", child_r1)("mobileActive", ctx_r2.mobileActive)("autoDisplay", ctx_r2.autoDisplay);
  }
}
function MenubarSub_ng_template_1_li_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "li", 8, 0);
    i0.ɵɵtemplate(2, MenubarSub_ng_template_1_li_1_a_2_Template, 7, 16, "a", 9)(3, MenubarSub_ng_template_1_li_1_a_3_Template, 7, 24, "a", 10)(4, MenubarSub_ng_template_1_li_1_p_menubarSub_4_Template, 1, 4, "p-menubarSub", 11);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const child_r1 = i0.ɵɵnextContext().$implicit;
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵclassMap(child_r1.styleClass);
    i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction2(8, _c2, child_r1 === ctx_r2.activeItem, child_r1.visible === false))("ngStyle", child_r1.style)("tooltipOptions", child_r1.tooltipOptions);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngIf", !child_r1.routerLink);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", child_r1.routerLink);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", child_r1.items);
  }
}
function MenubarSub_ng_template_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, MenubarSub_ng_template_1_li_0_Template, 1, 3, "li", 5)(1, MenubarSub_ng_template_1_li_1_Template, 5, 11, "li", 6);
  }
  if (rf & 2) {
    const child_r1 = ctx.$implicit;
    i0.ɵɵproperty("ngIf", child_r1.separator);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !child_r1.separator);
  }
}
const _c5 = ["menubutton"];
const _c6 = ["rootmenu"];
const _c7 = ["*"];
const _c8 = a0 => ({
  "p-menubar p-component": true,
  "p-menubar-mobile-active": a0
});
function Menubar_div_1_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function Menubar_div_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 8);
    i0.ɵɵtemplate(1, Menubar_div_1_ng_container_1_Template, 1, 0, "ng-container", 9);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r1.startTemplate);
  }
}
function Menubar_a_2_BarsIcon_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "BarsIcon");
  }
}
function Menubar_a_2_3_ng_template_0_Template(rf, ctx) {}
function Menubar_a_2_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, Menubar_a_2_3_ng_template_0_Template, 0, 0, "ng-template");
  }
}
function Menubar_a_2_Template(rf, ctx) {
  if (rf & 1) {
    const _r3 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "a", 10, 2);
    i0.ɵɵlistener("click", function Menubar_a_2_Template_a_click_0_listener($event) {
      i0.ɵɵrestoreView(_r3);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.toggle($event));
    });
    i0.ɵɵtemplate(2, Menubar_a_2_BarsIcon_2_Template, 1, 0, "BarsIcon", 11)(3, Menubar_a_2_3_Template, 1, 0, null, 9);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngIf", !ctx_r1.menuIconTemplate);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r1.menuIconTemplate);
  }
}
function Menubar_div_5_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function Menubar_div_5_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 12);
    i0.ɵɵtemplate(1, Menubar_div_5_ng_container_1_Template, 1, 0, "ng-container", 9);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r1.endTemplate);
  }
}
function Menubar_ng_template_6_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 12);
    i0.ɵɵprojection(1);
    i0.ɵɵelementEnd();
  }
}
class MenubarService {
  constructor() {
    this.mouseLeaves = new Subject();
    this.mouseLeft$ = this.mouseLeaves.pipe(debounce(() => interval(this.autoHideDelay)), filter(mouseLeft => this.autoHide && mouseLeft));
  }
}
MenubarService.ɵfac = function MenubarService_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || MenubarService)();
};
MenubarService.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: MenubarService,
  factory: MenubarService.ɵfac
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MenubarService, [{
    type: Injectable
  }], null, null);
})();
class MenubarSub {
  constructor(document, platformId, el, renderer, cd, menubarService, menubar) {
    this.document = document;
    this.platformId = platformId;
    this.el = el;
    this.renderer = renderer;
    this.cd = cd;
    this.menubarService = menubarService;
    this.menubar = menubar;
    this.autoZIndex = true;
    this.baseZIndex = 0;
    this.leafClick = new EventEmitter();
    this.menuHoverActive = false;
  }
  get parentActive() {
    return this._parentActive;
  }
  set parentActive(value) {
    if (!this.root) {
      this._parentActive = value;
      if (!value) this.activeItem = null;
    }
  }
  ngOnInit() {
    this.mouseLeaveSubscriber = this.menubarService.mouseLeft$.subscribe(() => {
      this.activeItem = null;
      this.cd.markForCheck();
      this.unbindDocumentClickListener();
    });
  }
  onItemClick(event, item) {
    if (item.disabled) {
      event.preventDefault();
      return;
    }
    if (!item.url && !item.routerLink) {
      event.preventDefault();
    }
    if (item.command) {
      item.command({
        originalEvent: event,
        item: item
      });
    }
    if (item.items) {
      if (this.activeItem && item === this.activeItem) {
        this.activeItem = null;
        this.unbindDocumentClickListener();
      } else {
        this.activeItem = item;
        if (this.root) {
          this.bindDocumentClickListener();
        }
      }
    }
    if (!item.items) {
      this.onLeafClick();
    }
  }
  onItemMouseLeave(event, item) {
    this.menubarService.mouseLeaves.next(true);
  }
  onItemMouseEnter(event, item) {
    this.menubarService.mouseLeaves.next(false);
    if (item.disabled || this.mobileActive) {
      event.preventDefault();
      return;
    }
    if (this.root) {
      if (this.activeItem || this.autoDisplay) {
        this.activeItem = item;
        this.bindDocumentClickListener();
      }
    } else {
      this.activeItem = item;
      this.bindDocumentClickListener();
    }
  }
  onLeafClick() {
    this.activeItem = null;
    if (this.root) {
      this.unbindDocumentClickListener();
    }
    this.leafClick.emit();
  }
  bindDocumentClickListener() {
    if (isPlatformBrowser(this.platformId)) {
      if (!this.documentClickListener) {
        this.documentClickListener = this.renderer.listen(this.document, 'click', event => {
          if (this.el && !this.el.nativeElement.contains(event.target)) {
            this.activeItem = null;
            this.cd.markForCheck();
            this.unbindDocumentClickListener();
          }
        });
      }
    }
  }
  unbindDocumentClickListener() {
    if (this.documentClickListener) {
      this.documentClickListener();
      this.documentClickListener = null;
    }
  }
  ngOnDestroy() {
    this.mouseLeaveSubscriber.unsubscribe();
    this.unbindDocumentClickListener();
  }
}
MenubarSub.ɵfac = function MenubarSub_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || MenubarSub)(i0.ɵɵdirectiveInject(DOCUMENT), i0.ɵɵdirectiveInject(PLATFORM_ID), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(MenubarService), i0.ɵɵdirectiveInject(Menubar));
};
MenubarSub.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: MenubarSub,
  selectors: [["p-menubarSub"]],
  hostAttrs: [1, "p-element"],
  inputs: {
    item: "item",
    root: "root",
    autoZIndex: "autoZIndex",
    baseZIndex: "baseZIndex",
    mobileActive: "mobileActive",
    autoDisplay: "autoDisplay",
    parentActive: "parentActive"
  },
  outputs: {
    leafClick: "leafClick"
  },
  decls: 2,
  vars: 6,
  consts: [["listItem", ""], ["htmlLabel", ""], ["htmlRouteLabel", ""], [3, "ngClass"], ["ngFor", "", 3, "ngForOf"], ["class", "p-menu-separator", "role", "separator", 3, "ngClass", 4, "ngIf"], ["role", "none", "pTooltip", "", 3, "ngClass", "ngStyle", "class", "tooltipOptions", 4, "ngIf"], ["role", "separator", 1, "p-menu-separator", 3, "ngClass"], ["role", "none", "pTooltip", "", 3, "ngClass", "ngStyle", "tooltipOptions"], ["role", "menuitem", "pRipple", "", 3, "target", "ngClass", "click", "mouseenter", "mouseleave", 4, "ngIf"], ["role", "menuitem", "pRipple", "", 3, "routerLink", "queryParams", "routerLinkActive", "routerLinkActiveOptions", "target", "ngClass", "fragment", "queryParamsHandling", "preserveFragment", "skipLocationChange", "replaceUrl", "state", "click", "mouseenter", "mouseleave", 4, "ngIf"], [3, "parentActive", "item", "mobileActive", "autoDisplay", "leafClick", 4, "ngIf"], ["role", "menuitem", "pRipple", "", 3, "click", "mouseenter", "mouseleave", "target", "ngClass"], ["class", "p-menuitem-icon", 3, "ngClass", "ngStyle", 4, "ngIf"], ["class", "p-menuitem-text", 4, "ngIf", "ngIfElse"], ["class", "p-menuitem-badge", 3, "ngClass", 4, "ngIf"], [4, "ngIf"], [1, "p-menuitem-icon", 3, "ngClass", "ngStyle"], [1, "p-menuitem-text"], [1, "p-menuitem-text", 3, "innerHTML"], [1, "p-menuitem-badge", 3, "ngClass"], [4, "ngTemplateOutlet"], [3, "styleClass", 4, "ngIf"], [3, "styleClass"], ["role", "menuitem", "pRipple", "", 3, "click", "mouseenter", "mouseleave", "routerLink", "queryParams", "routerLinkActive", "routerLinkActiveOptions", "target", "ngClass", "fragment", "queryParamsHandling", "preserveFragment", "skipLocationChange", "replaceUrl", "state"], [3, "leafClick", "parentActive", "item", "mobileActive", "autoDisplay"]],
  template: function MenubarSub_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelementStart(0, "ul", 3);
      i0.ɵɵtemplate(1, MenubarSub_ng_template_1_Template, 2, 2, "ng-template", 4);
      i0.ɵɵelementEnd();
    }
    if (rf & 2) {
      i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction2(3, _c0, !ctx.root, ctx.root));
      i0.ɵɵattribute("role", ctx.root ? "menubar" : "menu");
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngForOf", ctx.root ? ctx.item : ctx.item.items);
    }
  },
  dependencies: () => [i1.NgClass, i1.NgForOf, i1.NgIf, i1.NgTemplateOutlet, i1.NgStyle, i2.RouterLink, i2.RouterLinkActive, i3.Ripple, i4.Tooltip, AngleDownIcon, AngleRightIcon, MenubarSub],
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MenubarSub, [{
    type: Component,
    args: [{
      selector: 'p-menubarSub',
      template: `
        <ul [ngClass]="{ 'p-submenu-list': !root, 'p-menubar-root-list': root }" [attr.role]="root ? 'menubar' : 'menu'">
            <ng-template ngFor let-child [ngForOf]="root ? item : item.items">
                <li *ngIf="child.separator" class="p-menu-separator" [ngClass]="{ 'p-hidden': child.visible === false }" role="separator"></li>
                <li
                    *ngIf="!child.separator"
                    #listItem
                    [ngClass]="{ 'p-menuitem': true, 'p-menuitem-active': child === activeItem, 'p-hidden': child.visible === false }"
                    [ngStyle]="child.style"
                    [class]="child.styleClass"
                    role="none"
                    pTooltip
                    [tooltipOptions]="child.tooltipOptions"
                >
                    <a
                        *ngIf="!child.routerLink"
                        [attr.href]="child.url"
                        [attr.data-automationid]="child.automationId"
                        [target]="child.target"
                        [attr.title]="child.title"
                        [attr.id]="child.id"
                        role="menuitem"
                        (click)="onItemClick($event, child)"
                        (mouseenter)="onItemMouseEnter($event, child)"
                        (mouseleave)="onItemMouseLeave($event, child)"
                        [ngClass]="{ 'p-menuitem-link': true, 'p-disabled': child.disabled }"
                        [attr.tabindex]="child.disabled ? null : '0'"
                        [attr.aria-haspopup]="item.items != null"
                        [attr.aria-expanded]="item === activeItem"
                        pRipple
                    >
                        <span class="p-menuitem-icon" *ngIf="child.icon" [ngClass]="child.icon" [ngStyle]="child.iconStyle"></span>
                        <span class="p-menuitem-text" *ngIf="child.escape !== false; else htmlLabel">{{ child.label }}</span>
                        <ng-template #htmlLabel><span class="p-menuitem-text" [innerHTML]="child.label"></span></ng-template>
                        <span class="p-menuitem-badge" *ngIf="child.badge" [ngClass]="child.badgeStyleClass">{{ child.badge }}</span>
                        <ng-container *ngIf="child.items">
                            <ng-container *ngIf="!menubar.submenuIconTemplate">
                                <AngleDownIcon [styleClass]="'p-submenu-icon'" *ngIf="root"/>
                                <AngleRightIcon [styleClass]="'p-submenu-icon'" *ngIf="!root"/>
                            </ng-container>
                            <ng-template *ngTemplateOutlet="menubar.submenuIconTemplate"></ng-template>
                        </ng-container>
                    </a>
                    <a
                        *ngIf="child.routerLink"
                        [routerLink]="child.routerLink"
                        [attr.data-automationid]="child.automationId"
                        [queryParams]="child.queryParams"
                        [routerLinkActive]="'p-menuitem-link-active'"
                        [routerLinkActiveOptions]="child.routerLinkActiveOptions || { exact: false }"
                        [target]="child.target"
                        [attr.title]="child.title"
                        [attr.id]="child.id"
                        [attr.tabindex]="child.disabled ? null : '0'"
                        role="menuitem"
                        (click)="onItemClick($event, child)"
                        (mouseenter)="onItemMouseEnter($event, child)"
                        (mouseleave)="onItemMouseLeave($event, child)"
                        [ngClass]="{ 'p-menuitem-link': true, 'p-disabled': child.disabled }"
                        [fragment]="child.fragment"
                        [queryParamsHandling]="child.queryParamsHandling"
                        [preserveFragment]="child.preserveFragment"
                        [skipLocationChange]="child.skipLocationChange"
                        [replaceUrl]="child.replaceUrl"
                        [state]="child.state"
                        pRipple
                    >
                        <span class="p-menuitem-icon" *ngIf="child.icon" [ngClass]="child.icon" [ngStyle]="child.iconStyle"></span>
                        <span class="p-menuitem-text" *ngIf="child.escape !== false; else htmlRouteLabel">{{ child.label }}</span>
                        <ng-template #htmlRouteLabel><span class="p-menuitem-text" [innerHTML]="child.label"></span></ng-template>
                        <span class="p-menuitem-badge" *ngIf="child.badge" [ngClass]="child.badgeStyleClass">{{ child.badge }}</span>
                        <ng-container *ngIf="child.items">
                            <ng-container *ngIf="!menubar.submenuIconTemplate">
                                <AngleDownIcon [styleClass]="'p-submenu-icon'" *ngIf="root"/>
                                <AngleRightIcon [styleClass]="'p-submenu-icon'" *ngIf="!root"/>
                            </ng-container>
                            <ng-template *ngTemplateOutlet="menubar.submenuIconTemplate"></ng-template>
                        </ng-container>
                    </a>
                    <p-menubarSub [parentActive]="child === activeItem" [item]="child" *ngIf="child.items" [mobileActive]="mobileActive" [autoDisplay]="autoDisplay" (leafClick)="onLeafClick()"></p-menubarSub>
                </li>
            </ng-template>
        </ul>
    `,
      encapsulation: ViewEncapsulation.None,
      host: {
        class: 'p-element'
      }
    }]
  }], function () {
    return [{
      type: Document,
      decorators: [{
        type: Inject,
        args: [DOCUMENT]
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [PLATFORM_ID]
      }]
    }, {
      type: i0.ElementRef
    }, {
      type: i0.Renderer2
    }, {
      type: i0.ChangeDetectorRef
    }, {
      type: MenubarService
    }, {
      type: Menubar
    }];
  }, {
    item: [{
      type: Input
    }],
    root: [{
      type: Input
    }],
    autoZIndex: [{
      type: Input
    }],
    baseZIndex: [{
      type: Input
    }],
    mobileActive: [{
      type: Input
    }],
    autoDisplay: [{
      type: Input
    }],
    parentActive: [{
      type: Input
    }],
    leafClick: [{
      type: Output
    }]
  });
})();
class Menubar {
  constructor(document, platformId, el, renderer, cd, config, menubarService) {
    this.document = document;
    this.platformId = platformId;
    this.el = el;
    this.renderer = renderer;
    this.cd = cd;
    this.config = config;
    this.menubarService = menubarService;
    this.autoZIndex = true;
    this.baseZIndex = 0;
    this.autoHideDelay = 100;
  }
  ngOnInit() {
    this.menubarService.autoHide = this.autoHide;
    this.menubarService.autoHideDelay = this.autoHideDelay;
    this.mouseLeaveSubscriber = this.menubarService.mouseLeft$.subscribe(() => this.unbindOutsideClickListener());
  }
  ngAfterContentInit() {
    this.templates.forEach(item => {
      switch (item.getType()) {
        case 'start':
          this.startTemplate = item.template;
          break;
        case 'end':
          this.endTemplate = item.template;
          break;
        case 'menuicon':
          this.menuIconTemplate = item.template;
          break;
        case 'submenuicon':
          this.submenuIconTemplate = item.template;
          break;
      }
    });
  }
  toggle(event) {
    if (this.mobileActive) {
      this.hide();
      ZIndexUtils.clear(this.rootmenu.el.nativeElement);
    } else {
      this.mobileActive = true;
      ZIndexUtils.set('menu', this.rootmenu.el.nativeElement, this.config.zIndex.menu);
    }
    this.bindOutsideClickListener();
    event.preventDefault();
  }
  bindOutsideClickListener() {
    if (isPlatformBrowser(this.platformId)) {
      if (!this.outsideClickListener) {
        this.outsideClickListener = this.renderer.listen(this.document, 'click', event => {
          if (this.mobileActive && this.rootmenu.el.nativeElement !== event.target && !this.rootmenu.el.nativeElement.contains(event.target) && this.menubutton.nativeElement !== event.target && !this.menubutton.nativeElement.contains(event.target)) {
            this.hide();
          }
        });
      }
    }
  }
  hide() {
    this.mobileActive = false;
    this.cd.markForCheck();
    ZIndexUtils.clear(this.rootmenu.el.nativeElement);
    this.unbindOutsideClickListener();
  }
  onLeafClick() {
    this.hide();
  }
  unbindOutsideClickListener() {
    if (this.outsideClickListener) {
      this.outsideClickListener();
      this.outsideClickListener = null;
    }
  }
  ngOnDestroy() {
    this.mouseLeaveSubscriber.unsubscribe();
    this.unbindOutsideClickListener();
  }
}
Menubar.ɵfac = function Menubar_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || Menubar)(i0.ɵɵdirectiveInject(DOCUMENT), i0.ɵɵdirectiveInject(PLATFORM_ID), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i5.PrimeNGConfig), i0.ɵɵdirectiveInject(MenubarService));
};
Menubar.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: Menubar,
  selectors: [["p-menubar"]],
  contentQueries: function Menubar_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, PrimeTemplate, 4);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.templates = _t);
    }
  },
  viewQuery: function Menubar_Query(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵviewQuery(_c5, 5);
      i0.ɵɵviewQuery(_c6, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.menubutton = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.rootmenu = _t.first);
    }
  },
  hostAttrs: [1, "p-element"],
  inputs: {
    model: "model",
    style: "style",
    styleClass: "styleClass",
    autoZIndex: "autoZIndex",
    baseZIndex: "baseZIndex",
    autoDisplay: "autoDisplay",
    autoHide: "autoHide",
    autoHideDelay: "autoHideDelay"
  },
  features: [i0.ɵɵProvidersFeature([MenubarService])],
  ngContentSelectors: _c7,
  decls: 8,
  vars: 15,
  consts: [["rootmenu", ""], ["legacy", ""], ["menubutton", ""], [3, "ngClass", "ngStyle"], ["class", "p-menubar-start", 4, "ngIf"], ["tabindex", "0", "class", "p-menubar-button", 3, "click", 4, "ngIf"], ["root", "root", 3, "leafClick", "item", "baseZIndex", "autoZIndex", "mobileActive", "autoDisplay"], ["class", "p-menubar-end", 4, "ngIf", "ngIfElse"], [1, "p-menubar-start"], [4, "ngTemplateOutlet"], ["tabindex", "0", 1, "p-menubar-button", 3, "click"], [4, "ngIf"], [1, "p-menubar-end"]],
  template: function Menubar_Template(rf, ctx) {
    if (rf & 1) {
      const _r1 = i0.ɵɵgetCurrentView();
      i0.ɵɵprojectionDef();
      i0.ɵɵelementStart(0, "div", 3);
      i0.ɵɵtemplate(1, Menubar_div_1_Template, 2, 1, "div", 4)(2, Menubar_a_2_Template, 4, 2, "a", 5);
      i0.ɵɵelementStart(3, "p-menubarSub", 6, 0);
      i0.ɵɵlistener("leafClick", function Menubar_Template_p_menubarSub_leafClick_3_listener() {
        i0.ɵɵrestoreView(_r1);
        return i0.ɵɵresetView(ctx.onLeafClick());
      });
      i0.ɵɵelementEnd();
      i0.ɵɵtemplate(5, Menubar_div_5_Template, 2, 1, "div", 7)(6, Menubar_ng_template_6_Template, 2, 0, "ng-template", null, 1, i0.ɵɵtemplateRefExtractor);
      i0.ɵɵelementEnd();
    }
    if (rf & 2) {
      const legacy_r4 = i0.ɵɵreference(7);
      i0.ɵɵclassMap(ctx.styleClass);
      i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction1(13, _c8, ctx.mobileActive))("ngStyle", ctx.style);
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngIf", ctx.startTemplate);
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngIf", ctx.model && ctx.model.length > 0);
      i0.ɵɵadvance();
      i0.ɵɵproperty("item", ctx.model)("baseZIndex", ctx.baseZIndex)("autoZIndex", ctx.autoZIndex)("mobileActive", ctx.mobileActive)("autoDisplay", ctx.autoDisplay);
      i0.ɵɵadvance(2);
      i0.ɵɵproperty("ngIf", ctx.endTemplate)("ngIfElse", legacy_r4);
    }
  },
  dependencies: () => [i1.NgClass, i1.NgIf, i1.NgTemplateOutlet, i1.NgStyle, BarsIcon, MenubarSub],
  styles: [".p-menubar{display:flex;align-items:center}.p-menubar ul{margin:0;padding:0;list-style:none}.p-menubar .p-menuitem-link{cursor:pointer;display:flex;align-items:center;text-decoration:none;overflow:hidden;position:relative}.p-menubar .p-menuitem-text{line-height:1}.p-menubar .p-menuitem{position:relative}.p-menubar-root-list{display:flex;align-items:center;flex-wrap:wrap}.p-menubar-root-list>li ul{display:none;z-index:1}.p-menubar-root-list>.p-menuitem-active>p-menubarsub>.p-submenu-list{display:block}.p-menubar .p-submenu-list{display:none;position:absolute;z-index:2}.p-menubar .p-submenu-list>.p-menuitem-active>p-menubarsub>.p-submenu-list{display:block;left:100%;top:0}.p-menubar .p-submenu-list .p-menuitem-link .p-submenu-icon:not(svg){margin-left:auto}.p-menubar .p-submenu-list .p-menuitem-link .p-icon-wrapper{margin-left:auto}.p-menubar .p-menubar-custom,.p-menubar .p-menubar-end{margin-left:auto;align-self:center}.p-menubar-button{display:none;cursor:pointer;align-items:center;justify-content:center}\n"],
  encapsulation: 2,
  changeDetection: 0
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(Menubar, [{
    type: Component,
    args: [{
      selector: 'p-menubar',
      template: `
        <div [ngClass]="{ 'p-menubar p-component': true, 'p-menubar-mobile-active': mobileActive }" [class]="styleClass" [ngStyle]="style">
            <div class="p-menubar-start" *ngIf="startTemplate">
                <ng-container *ngTemplateOutlet="startTemplate"></ng-container>
            </div>
            <a #menubutton tabindex="0" *ngIf="model && model.length > 0" class="p-menubar-button" (click)="toggle($event)">
                <BarsIcon *ngIf="!menuIconTemplate"/>
                <ng-template *ngTemplateOutlet="menuIconTemplate"></ng-template>
            </a>
            <p-menubarSub #rootmenu [item]="model" root="root" [baseZIndex]="baseZIndex" (leafClick)="onLeafClick()" [autoZIndex]="autoZIndex" [mobileActive]="mobileActive" [autoDisplay]="autoDisplay"></p-menubarSub>
            <div class="p-menubar-end" *ngIf="endTemplate; else legacy">
                <ng-container *ngTemplateOutlet="endTemplate"></ng-container>
            </div>
            <ng-template #legacy>
                <div class="p-menubar-end">
                    <ng-content></ng-content>
                </div>
            </ng-template>
        </div>
    `,
      changeDetection: ChangeDetectionStrategy.OnPush,
      encapsulation: ViewEncapsulation.None,
      host: {
        class: 'p-element'
      },
      providers: [MenubarService],
      styles: [".p-menubar{display:flex;align-items:center}.p-menubar ul{margin:0;padding:0;list-style:none}.p-menubar .p-menuitem-link{cursor:pointer;display:flex;align-items:center;text-decoration:none;overflow:hidden;position:relative}.p-menubar .p-menuitem-text{line-height:1}.p-menubar .p-menuitem{position:relative}.p-menubar-root-list{display:flex;align-items:center;flex-wrap:wrap}.p-menubar-root-list>li ul{display:none;z-index:1}.p-menubar-root-list>.p-menuitem-active>p-menubarsub>.p-submenu-list{display:block}.p-menubar .p-submenu-list{display:none;position:absolute;z-index:2}.p-menubar .p-submenu-list>.p-menuitem-active>p-menubarsub>.p-submenu-list{display:block;left:100%;top:0}.p-menubar .p-submenu-list .p-menuitem-link .p-submenu-icon:not(svg){margin-left:auto}.p-menubar .p-submenu-list .p-menuitem-link .p-icon-wrapper{margin-left:auto}.p-menubar .p-menubar-custom,.p-menubar .p-menubar-end{margin-left:auto;align-self:center}.p-menubar-button{display:none;cursor:pointer;align-items:center;justify-content:center}\n"]
    }]
  }], function () {
    return [{
      type: Document,
      decorators: [{
        type: Inject,
        args: [DOCUMENT]
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [PLATFORM_ID]
      }]
    }, {
      type: i0.ElementRef
    }, {
      type: i0.Renderer2
    }, {
      type: i0.ChangeDetectorRef
    }, {
      type: i5.PrimeNGConfig
    }, {
      type: MenubarService
    }];
  }, {
    model: [{
      type: Input
    }],
    style: [{
      type: Input
    }],
    styleClass: [{
      type: Input
    }],
    autoZIndex: [{
      type: Input
    }],
    baseZIndex: [{
      type: Input
    }],
    autoDisplay: [{
      type: Input
    }],
    autoHide: [{
      type: Input
    }],
    autoHideDelay: [{
      type: Input
    }],
    templates: [{
      type: ContentChildren,
      args: [PrimeTemplate]
    }],
    menubutton: [{
      type: ViewChild,
      args: ['menubutton']
    }],
    rootmenu: [{
      type: ViewChild,
      args: ['rootmenu']
    }]
  });
})();
class MenubarModule {}
MenubarModule.ɵfac = function MenubarModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || MenubarModule)();
};
MenubarModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: MenubarModule
});
MenubarModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [CommonModule, RouterModule, RippleModule, TooltipModule, SharedModule, BarsIcon, AngleDownIcon, AngleRightIcon, RouterModule, TooltipModule, SharedModule]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MenubarModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, RouterModule, RippleModule, TooltipModule, SharedModule, BarsIcon, AngleDownIcon, AngleRightIcon],
      exports: [Menubar, RouterModule, TooltipModule, SharedModule],
      declarations: [Menubar, MenubarSub]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { Menubar, MenubarModule, MenubarService, MenubarSub };

