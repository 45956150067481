import { Component, OnInit } from '@angular/core';
import { PrimeNGConfig, TreeNode } from 'primeng/api';
import { CopqCategoryService } from 'src/app/services/copq-category.service';
import { DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-copqcategory-pop-up',
  templateUrl: './copqcategory-pop-up.component.html',
  styleUrls: ['./copqcategory-pop-up.component.css']
})
export class COPQCategoryPopUpComponent implements OnInit {

  files1: any[] = [];
  selectedNode!: TreeNode;
  loading: boolean = false;

  constructor(private copqService: CopqCategoryService,
    private readonly dialogRef: DynamicDialogRef,
    private primengConfig: PrimeNGConfig,) { }

  ngOnInit(): void {
    this.loading = true;
    this.copqService.getFiles().then(files => {
      if(files) {
        this.loading = false;
        this.files1 = files;
        this.files1.map((res) => {
          res.label = res.name,
          res.data = res.flatName,
          // res.expandedIcon = 'pi pi-folder-open',
          // res.collapsedIcon = 'pi pi-folder',
          res.children = [],
          res.id = res.id,
          res.leaf = res.hasSubClasses == true ? false : true,
          res.selectable = res.hasSubClasses ? false : true
        });
      }
    });

  }
  createNode(i: number, children: number): TreeNode {
    let node: TreeNode = {
      label: 'Node ' + i,
      data: 'Node ' + i,
      // expandedIcon: 'pi pi-folder-open',
      // collapsedIcon: 'pi pi-folder',
      children: Array.from({ length: children }).map((_, j) => {
        return {
          label: 'Node ' + i + '.' + j,
          data: 'Node ' + i + '.' + j,
          icon: 'pi pi-file-o'
        }
      })
    };
    return node;
  }

  // For search box purpose
  nodeSelect(event: any) {
   //  console.log(event);
    let parentList;
    if(event.node?.parent?.data) {
      parentList = event.node?.parent?.data + " / ";
    }
    else {
      parentList = '';
    }
    const nodeselection = event.node;
    if(nodeselection.hasSubClasses) {
      this.loading = true;
      this.copqService.getCopqSearchbyId(nodeselection.id).then((res) => {
        if(res){
          this.files1 = this.newH(res)
          this.loading = false;
        }
      })
    }
  }

  newH(newData: any, h = this.files1 ){
    h.forEach((node) => {
        if(node.id == newData[0].parentId){
        const New = []
         newData.map((res) => {
                  res.label = res.name,
                  res.data = res.flatName,
                  // res.expandedIcon = 'pi pi-folder-open',
                  // res.collapsedIcon = 'pi pi-folder',
                  res.children = res.hasSubClasses,
                  res.id = res.id,
                  res.leaf = res.hasSubClasses == true ? false : true
                });
          node.children = [...newData]
        } else if(node.children.length) {
           this.newH(newData, node.children)
        }
    })

    return h
  }

  // Data are disabled
  enableAll() {
    this.files1.forEach((node: any) => {
      this.enableRecursive(node, false);
    })
  }

  private enableRecursive(node: any, isSelectable: boolean) {
    node.selectable = node.disabled;
    if (node.children) {
      node.children.forEach((childNode: any) => {
        this.enableRecursive(childNode, childNode.disabled);
      });
    }
  }

  selectAndClose() {
    this.dialogRef.close(this.selectedNode);
  }

  onClose() {
    this.dialogRef.close();
  }
}
