import * as i0 from '@angular/core';
import { EventEmitter, Directive, Input, Output, HostListener, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DomHandler } from 'primeng/dom';
class Draggable {
  constructor(el, zone, renderer) {
    this.el = el;
    this.zone = zone;
    this.renderer = renderer;
    this.onDragStart = new EventEmitter();
    this.onDragEnd = new EventEmitter();
    this.onDrag = new EventEmitter();
  }
  get pDraggableDisabled() {
    return this._pDraggableDisabled;
  }
  set pDraggableDisabled(_pDraggableDisabled) {
    this._pDraggableDisabled = _pDraggableDisabled;
    if (this._pDraggableDisabled) {
      this.unbindMouseListeners();
    } else {
      this.el.nativeElement.draggable = true;
      this.bindMouseListeners();
    }
  }
  ngAfterViewInit() {
    if (!this.pDraggableDisabled) {
      this.el.nativeElement.draggable = true;
      this.bindMouseListeners();
    }
  }
  bindDragListener() {
    if (!this.dragListener) {
      this.zone.runOutsideAngular(() => {
        this.dragListener = this.renderer.listen(this.el.nativeElement, 'drag', this.drag.bind(this));
      });
    }
  }
  unbindDragListener() {
    if (this.dragListener) {
      this.zone.runOutsideAngular(() => {
        this.dragListener();
        this.dragListener = null;
      });
    }
  }
  bindMouseListeners() {
    if (!this.mouseDownListener && !this.mouseUpListener) {
      this.zone.runOutsideAngular(() => {
        this.mouseDownListener = this.renderer.listen(this.el.nativeElement, 'mousedown', this.mousedown.bind(this));
        this.mouseUpListener = this.renderer.listen(this.el.nativeElement, 'mouseup', this.mouseup.bind(this));
      });
    }
  }
  unbindMouseListeners() {
    if (this.mouseDownListener && this.mouseUpListener) {
      this.zone.runOutsideAngular(() => {
        this.mouseDownListener();
        this.mouseUpListener();
        this.mouseDownListener = null;
        this.mouseUpListener = null;
      });
    }
  }
  drag(event) {
    this.onDrag.emit(event);
  }
  dragStart(event) {
    if (this.allowDrag() && !this.pDraggableDisabled) {
      if (this.dragEffect) {
        event.dataTransfer.effectAllowed = this.dragEffect;
      }
      event.dataTransfer.setData('text', this.scope);
      this.onDragStart.emit(event);
      this.bindDragListener();
    } else {
      event.preventDefault();
    }
  }
  dragEnd(event) {
    this.onDragEnd.emit(event);
    this.unbindDragListener();
  }
  mousedown(event) {
    this.handle = event.target;
  }
  mouseup(event) {
    this.handle = null;
  }
  allowDrag() {
    if (this.dragHandle && this.handle) return DomHandler.matches(this.handle, this.dragHandle);else return true;
  }
  ngOnDestroy() {
    this.unbindDragListener();
    this.unbindMouseListeners();
  }
}
Draggable.ɵfac = function Draggable_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || Draggable)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone), i0.ɵɵdirectiveInject(i0.Renderer2));
};
Draggable.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: Draggable,
  selectors: [["", "pDraggable", ""]],
  hostAttrs: [1, "p-element"],
  hostBindings: function Draggable_HostBindings(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵlistener("dragstart", function Draggable_dragstart_HostBindingHandler($event) {
        return ctx.dragStart($event);
      })("dragend", function Draggable_dragend_HostBindingHandler($event) {
        return ctx.dragEnd($event);
      });
    }
  },
  inputs: {
    scope: [0, "pDraggable", "scope"],
    dragEffect: "dragEffect",
    dragHandle: "dragHandle",
    pDraggableDisabled: "pDraggableDisabled"
  },
  outputs: {
    onDragStart: "onDragStart",
    onDragEnd: "onDragEnd",
    onDrag: "onDrag"
  }
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(Draggable, [{
    type: Directive,
    args: [{
      selector: '[pDraggable]',
      host: {
        class: 'p-element'
      }
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }, {
      type: i0.Renderer2
    }];
  }, {
    scope: [{
      type: Input,
      args: ['pDraggable']
    }],
    dragEffect: [{
      type: Input
    }],
    dragHandle: [{
      type: Input
    }],
    onDragStart: [{
      type: Output
    }],
    onDragEnd: [{
      type: Output
    }],
    onDrag: [{
      type: Output
    }],
    pDraggableDisabled: [{
      type: Input
    }],
    dragStart: [{
      type: HostListener,
      args: ['dragstart', ['$event']]
    }],
    dragEnd: [{
      type: HostListener,
      args: ['dragend', ['$event']]
    }]
  });
})();
class Droppable {
  constructor(el, zone, renderer) {
    this.el = el;
    this.zone = zone;
    this.renderer = renderer;
    this.onDragEnter = new EventEmitter();
    this.onDragLeave = new EventEmitter();
    this.onDrop = new EventEmitter();
  }
  ngAfterViewInit() {
    if (!this.pDroppableDisabled) {
      this.bindDragOverListener();
    }
  }
  bindDragOverListener() {
    if (!this.dragOverListener) {
      this.zone.runOutsideAngular(() => {
        this.dragOverListener = this.renderer.listen(this.el.nativeElement, 'dragover', this.dragOver.bind(this));
      });
    }
  }
  unbindDragOverListener() {
    if (this.dragOverListener) {
      this.zone.runOutsideAngular(() => {
        this.dragOverListener();
        this.dragOverListener = null;
      });
    }
  }
  dragOver(event) {
    event.preventDefault();
  }
  drop(event) {
    if (this.allowDrop(event)) {
      DomHandler.removeClass(this.el.nativeElement, 'p-draggable-enter');
      event.preventDefault();
      this.onDrop.emit(event);
    }
  }
  dragEnter(event) {
    event.preventDefault();
    if (this.dropEffect) {
      event.dataTransfer.dropEffect = this.dropEffect;
    }
    DomHandler.addClass(this.el.nativeElement, 'p-draggable-enter');
    this.onDragEnter.emit(event);
  }
  dragLeave(event) {
    event.preventDefault();
    DomHandler.removeClass(this.el.nativeElement, 'p-draggable-enter');
    this.onDragLeave.emit(event);
  }
  allowDrop(event) {
    let dragScope = event.dataTransfer.getData('text');
    if (typeof this.scope == 'string' && dragScope == this.scope) {
      return true;
    } else if (Array.isArray(this.scope)) {
      for (let j = 0; j < this.scope.length; j++) {
        if (dragScope == this.scope[j]) {
          return true;
        }
      }
    }
    return false;
  }
  ngOnDestroy() {
    this.unbindDragOverListener();
  }
}
Droppable.ɵfac = function Droppable_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || Droppable)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone), i0.ɵɵdirectiveInject(i0.Renderer2));
};
Droppable.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: Droppable,
  selectors: [["", "pDroppable", ""]],
  hostAttrs: [1, "p-element"],
  hostBindings: function Droppable_HostBindings(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵlistener("drop", function Droppable_drop_HostBindingHandler($event) {
        return ctx.drop($event);
      })("dragenter", function Droppable_dragenter_HostBindingHandler($event) {
        return ctx.dragEnter($event);
      })("dragleave", function Droppable_dragleave_HostBindingHandler($event) {
        return ctx.dragLeave($event);
      });
    }
  },
  inputs: {
    scope: [0, "pDroppable", "scope"],
    pDroppableDisabled: "pDroppableDisabled",
    dropEffect: "dropEffect"
  },
  outputs: {
    onDragEnter: "onDragEnter",
    onDragLeave: "onDragLeave",
    onDrop: "onDrop"
  }
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(Droppable, [{
    type: Directive,
    args: [{
      selector: '[pDroppable]',
      host: {
        class: 'p-element'
      }
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }, {
      type: i0.Renderer2
    }];
  }, {
    scope: [{
      type: Input,
      args: ['pDroppable']
    }],
    pDroppableDisabled: [{
      type: Input
    }],
    dropEffect: [{
      type: Input
    }],
    onDragEnter: [{
      type: Output
    }],
    onDragLeave: [{
      type: Output
    }],
    onDrop: [{
      type: Output
    }],
    drop: [{
      type: HostListener,
      args: ['drop', ['$event']]
    }],
    dragEnter: [{
      type: HostListener,
      args: ['dragenter', ['$event']]
    }],
    dragLeave: [{
      type: HostListener,
      args: ['dragleave', ['$event']]
    }]
  });
})();
class DragDropModule {}
DragDropModule.ɵfac = function DragDropModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DragDropModule)();
};
DragDropModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: DragDropModule
});
DragDropModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [CommonModule]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DragDropModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule],
      exports: [Draggable, Droppable],
      declarations: [Draggable, Droppable]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { DragDropModule, Draggable, Droppable };

