<div class="rounded-1 reopen-card-modal" style="height: auto;">
    <div class="modal-body">
        <span class="asteriskRequired">{{'cc_action_plan_reopen_modal_body' | translate}}</span>
        <textarea id="ap-rejection-reason" class="form-control" [(ngModel)]="reason"></textarea>
    </div>
    
    <!-- <hr /> -->
  
    <div class="modal-footer">
        <button class="btn widgetButtoSmall" (click)="cancel()">{{'cc_action_plan_delete_modal_button_cancel' | translate}}</button>
        <button class="btn btn-primary widgetButtonSmall" [disabled]="!reason" (click)="ok()">{{'cc_action_plan_reopen_modal_button_reopen' | translate}}</button>
    </div>
</div>

