<div class="pgrca-section">
  <div class="popup pgrca-category-pop">

    <p-tree [value]="files3" scrollHeight="290px" selectionMode="single" [(selection)]="selectedNode"
      filterPlaceholder="Search by Name (Type at least 3 character)" [loading]="loading" (onNodeExpand)="nodeSelect($event)">
    </p-tree>

    <div class="modal-footer mt-2">
      <button (click)="selectAndClose()" [ngClass]="
        selectedNode && selectedNode.label
          ? ' widgetButtonLargeSelectClose red_button'
          : ' widgetButtonLargeSelectClose'
      " [disabled]="!(selectedNode && selectedNode.label)">
        {{ "select_ru_popup_btn_select" | translate }}
      </button>
      <button class="closeBtn ms-1" type="button" (click)="onClose()">{{ "select_ru_popup_btn_close" | translate }}</button>
    </div>
  </div>
</div>
