import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef,DialogService } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.css']
})
export class ConfirmationComponent implements OnInit {

  constructor(private ref: DynamicDialogRef,public config: DynamicDialogConfig,
    private dialogService: DialogService,
    private readonly dialogRef: DynamicDialogRef) { }

  ngOnInit(): void {
  }
  onClose(){
    this.dialogRef.close();
  }


}
