import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { ActionPlanCard } from 'src/app/models/new-issue/actionplan/ActionPlanCard';
import { ActionPlanRows } from 'src/app/models/new-issue/actionplan/ActionPlanRows';
import { ActionPlanService } from 'src/app/services/actionplan/action-plan.service';
import { ActionTypesService } from 'src/app/services/actionplan/action-types.service';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DeleteCardModalComponent } from '../delete-card-modal/delete-card-modal.component';
import { ActionItemValidationService, IActionItemValidationService } from 'src/app/services/actionplan/action-item-validation.service';
import { DatePipe } from '@angular/common';
import moment from 'moment';
import { ReopenCardModalComponent } from '../reopen-card-modal/reopen-card-modal.component';
import { RejectCardModalComponent } from '../reject-card-modal/reject-card-modal.component';
import { LocalStorageServiceService } from 'src/app/services/local-storage-service.service';
import { ActionPlanPermissionsService } from 'src/app/services/actionplan/action-plan-permissions.service';
import { MessageService } from 'primeng/api';
import { ActionItemStatus } from 'src/app/components/my-issue/myissue-action-plan-dashboard/myissue-action-plan-dashboard.component';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { UniqueActionNameWithinBoardService } from 'src/app/services/actionplan/unique-action-name-within-board.service';
import { NgxSpinnerService } from 'ngx-spinner';

export interface IActionPlanScope{
  ctrl: any;
  //spinnerFn: any;
  cardData: ActionPlanRows;
};

@Component({
  selector: 'app-action-board',
  templateUrl: './action-board.component.html',
  styleUrls: ['./action-board.component.css']
})
export class ActionBoardComponent implements OnInit, IActionPlanScope {
  @ViewChild('id', { static: false }) public droppableDiv: ElementRef
  public newActionTypes?;
  public canAddNewActions = false;
  private loadInProgress: boolean = false;
  private tokenForUser = null;
  targetResolvingDate;
  ref: DynamicDialogRef;

  ctrl: any;
  spinnerFn: any;
  cardData: ActionPlanRows;
  data:any
  selectedRole;
  complaintNumber;
  complaintId;

  constructor(private actionPlanService:ActionPlanService,
    private actionTypesService:ActionTypesService,
    private route: ActivatedRoute,
    public dialogService: DialogService,
    public validationService: ActionItemValidationService,
    private datePipe: DatePipe,
    private router: Router,
    private permissionsService:ActionPlanPermissionsService,
    private lclStorageService: LocalStorageServiceService,
    private messageService: MessageService,
    public breadcrumbService: BreadcrumbService,
    public checkUniqueness: UniqueActionNameWithinBoardService,
    public spinnerService: NgxSpinnerService) {

      this.complaintNumber = this.route.snapshot.paramMap.get('complaintNumber');
      this.selectedRole = this.route.snapshot.paramMap.get('selectedRole');
      this.validationService.canAddNewAction(this.complaintNumber).subscribe((resp) =>{
        this.canAddNewActions = resp;
      });

    }

  ngOnInit(): void {
    //console.log(this.newActionTypes);
    this.spinnerService.show();
    this.breadcrumbService.menuItems = [
      {label: 'ACTION PLAN ' + this.complaintNumber}
    ];
    this.onGetComplaintInformation();
    this.loadData();

    this.checkUniqueness.getComplaintData(this.complaintNumber).subscribe((resp: any) => {
      if(resp) {
        this.complaintId = resp?.id
      }
    })

    this.spinnerService.hide();
  }


  //card = { "id": 7089, "statusId": 2, "type": "27", "typeName": "Preventive Action", "targetDate": "2023-04-29T23:59:59.9999999", "lastUpdateDate": "2023-04-18T08:24:02.7821558", "createDate": "2023-04-18T08:24:02.7821558", "actionOwner": { "id": "9X70978090", "displayGeid": "9X70978090", "name": "Manoj Desai", "iso": "IN", "city": "Bangalore", "email": "manoj.desai@hitachienergy.com", "deactivateDate": null, "buCode": "PGGB", "isActive": true }, "actionCreator": { "id": "9X70978090", "displayGeid": "9X70978090", "name": "Manoj Desai", "iso": "IN", "city": "Bangalore", "email": "manoj.desai@hitachienergy.com", "deactivateDate": null, "buCode": "PGGB", "isActive": true }, "ownerName": "Manoj Desai", "name": "test test test test test test test test", "complaintId": 679160, "complaintNo": "IN-1022659", "interestedParties": null, "creatorGeid": "9X70978090", "rejectReason": null, "isRejected": false, "isReopened": false, "description": null, "activities": null, "rowVersion": "00-00-00-00-00-60-6C-3D", "files": [], "permission": { "isCreator": true, "isActionOwner": true }, "status": "action-date-ok" }

  loadData(){
    //need to update selected role in below method
    this.data = this.actionPlanService.getActionCards(this.complaintNumber, this.selectedRole ? this.selectedRole : null).subscribe(
      (data) =>{
        this.cardData = new ActionPlanRows();
        for (let i = 0; i < data.length; i++) {
          if (data[i].statusId == 1) this.cardData.todo.push(data[i]);
          if (data[i].statusId == 2 || data[i].statusId == 4) this.cardData.inProgress.push(data[i]);
          if (data[i].statusId == 3) this.cardData.closed.push(data[i]);
      }
      }
    )

    this.actionTypesService.getActionTypes(moment(new Date()).format('YYYY-MM-DD')).subscribe(
      (data) =>{
        this.newActionTypes = data
      }
    )
  }

  refreshItems(){
  this.actionPlanService.getActionCards(this.complaintNumber, this.selectedRole ? this.selectedRole : null).subscribe(
    (data) =>{
      this.cardData = new ActionPlanRows();
      for (let i = 0; i < data.length; i++) {
        if (data[i].statusId == 1) this.cardData.todo.push(data[i]);
        if (data[i].statusId == 2 || data[i].statusId == 4) this.cardData.inProgress.push(data[i]);
        if (data[i].statusId == 3) this.cardData.closed.push(data[i]);
      }
    })
  }

  onGetComplaintInformation(){
    this.actionPlanService.getComplaintInformation(this.complaintNumber).subscribe(
      (resp) =>{
        if(resp)
        {
          this.targetResolvingDate = moment(resp.targetResolvingDate).format('YYYY-MM-DD');
        }
      }
    )
  }

  goToCreateNewCard(type?) {
    this.actionPlanService.storeNewCardType(type);
   // console.log(type);
    this.router.navigateByUrl('/issues/' + this.complaintNumber +'/'+this.selectedRole+ '/actionplan/card/');
  }

  onBackLocation() {
    this.router.navigateByUrl('/complaint/' + this.complaintId);
  }

  draggedCard: ActionPlanCard;
  sourceId = '';
  // dragStartFromClosed(card: ActionPlanCard){
  //   this.draggedCard = card;
  //   //console.log(this.draggedCard);
  // }

  // dragStartFromInProgress(card: ActionPlanCard){
  //   this.draggedCard = card;
  // }

  dragStart(card: ActionPlanCard, source: string){
    this.draggedCard = card;
    this.sourceId = source;
    this.permissionsService.getUserPermissionToken(this.draggedCard.id, this.selectedRole ? this.selectedRole : null).subscribe((resp)=>{
      this.tokenForUser = resp;
      //console.log(this.tokenForUser);
    })
    setTimeout(() => {

    }, 1000);
    //console.log(card.id);
    // console.log(source);
  }

  dropFromToDoAndClosed(){
    if(this.draggedCard)
    {
      if(this.draggedCard.statusId == 1)
      {
        this.spinnerService.show();
        this.permissionsService.getUserPermissionToken(this.draggedCard.id, this.selectedRole ? this.selectedRole : null).subscribe((resp)=>{
          this.tokenForUser = resp;
          //console.log(this.tokenForUser);
        })
       let cancelDrag = this.validationService.disableAssign(this.draggedCard, this.tokenForUser, this.selectedRole ? this.selectedRole : null);
        var targetDate = moment.utc(this.targetResolvingDate).format('YYYY-MM-DD');
        var currentDate = moment.utc(new Date()).format('YYYY-MM-DD');
        var actionDeadlineDate = null;
        if (this.draggedCard.targetDate) {
            actionDeadlineDate = moment.utc(this.draggedCard.targetDate).format('YYYY-MM-DD');
        }

        if(cancelDrag)
        {
          if ((this.selectedRole == null || this.selectedRole == undefined) && !this.tokenForUser.isActionOwner && !this.tokenForUser.isCreator) {
            this.throttledPopUpAssignAccessValidation();
            this.spinnerService.hide();
            return false;
          }
          else
          {
            if(((targetDate < currentDate && currentDate > actionDeadlineDate)
            || (currentDate < targetDate && actionDeadlineDate < currentDate)
            || (currentDate == targetDate && actionDeadlineDate < currentDate))
            && (this.draggedCard.type != "27") && this.draggedCard.targetDate){
              this.throttledPopUpValidActionDeadline();
              this.spinnerService.hide();
              return false;
            }
            else{
              this.throttledPopUpAssignValidation();
              this.spinnerService.hide();
              return false;
            }
          }
        }

        this.draggedCard.statusId = ActionItemStatus.InProgress;
        this.refreshItems();
        this.actionPlanService.assignActionCard(this.draggedCard).subscribe((resp)=>{
          this.refreshItems();

          this.messageService.add({
            severity: 'success',
            summary: 'Action was successfully assigned.'
          })
          this.spinnerService.hide();
        },
        ()=>{
          this.refreshItems();
          this.spinnerService.hide();
        })
        //console.log(this.draggedCard)
        //this.cardData.inProgress.push(this.draggedCard);
      }
      else if(this.draggedCard.statusId == 3)
      {
        this.permissionsService.getUserPermissionToken(this.draggedCard.id, this.selectedRole ? this.selectedRole : null).subscribe((resp)=>{
          this.tokenForUser = resp;
          //console.log(this.tokenForUser);
        })

        if(!this.tokenForUser.isCreator){
          this.throttledPopUpReopenAccessValidation();
          return false;
        }
        if(this.targetResolvingDate){
          var targetDate = moment.utc(this.targetResolvingDate).format('YYYY-MM-DD');
          var currentDate = moment.utc(new Date()).format('YYYY-MM-DD');
          actionDeadlineDate = moment.utc(this.draggedCard.targetDate).format('YYYY-MM-DD');

          if ((targetDate < currentDate && currentDate > actionDeadlineDate) && this.draggedCard.type != "27") {
            this.throttledPopUpReopenDatePassedValidation();
            return false;
          }
        }

        this.ref = this.dialogService.open(ReopenCardModalComponent, {
          header: 'Reopen Action',
          width: '60%',
          position: 'top',
          styleClass: 'reopen-card-modal'
        });

        this.ref.onClose.subscribe((resp) => {
          this.spinnerService.show();
          if(resp.result)
          {
            this.draggedCard.statusId = ActionItemStatus.InProgress;
            this.refreshItems();
            this.actionPlanService.reopenItem(this.draggedCard.id, resp.result).subscribe(()=>{
              this.refreshItems();
              this.messageService.add({
                severity: 'success',
                summary: 'Action successfully reopened.'
              })
              this.spinnerService.hide();
            },
            ()=>{
              this.refreshItems();
              this.spinnerService.hide();
            })
            //this.cardData.inProgress.push(this.draggedCard);
            this.draggedCard = null;
            this.sourceId = '';
          }
          else
          {
            this.refreshItems();
            this.spinnerService.hide();
            this.draggedCard = null;
            this.sourceId = '';
          }
        })
        return false;
      }
      //this.draggedCard = null;
      return false;
    }

    return false;
  }

  dropFromInProgressToDo(){
    if(this.draggedCard)
    {
      if(this.draggedCard.statusId == 2 || this.draggedCard.statusId == 4)
      {

        this.permissionsService.getUserPermissionToken(this.draggedCard.id, this.selectedRole ? this.selectedRole : null).subscribe((resp)=>{
          this.tokenForUser = resp;
          //console.log(this.tokenForUser);
        })
        let cancelDrag = this.validationService.disableReject(this.draggedCard, this.tokenForUser);
        if(cancelDrag){
          if (!this.tokenForUser.isActionOwner){
            this.throttledPopUpRejectAccessValidation();
            return false;
          }
          else{
            this.throttledPopUpRejectValidation();
            return false;
          }
        }

        this.ref = this.dialogService.open(RejectCardModalComponent, {
          header: 'Reject Action',
          width: '60%',
          position: 'top',
          styleClass: 'reject-card-modal'
        })

        this.ref.onClose.subscribe((res) =>{
          this.spinnerService.show();
          if(res.result)
          {
            this.draggedCard.statusId = ActionItemStatus.Todo;
            this.draggedCard['rejectReason'] = res.result;
            this.refreshItems();
            this.actionPlanService.rejectItem(this.draggedCard).subscribe(()=>{
              this.refreshItems();
              //console.log(this.draggedCard)
              this.messageService.add({
                severity: 'success',
                summary: 'Action successfully rejected'
              })
              this.spinnerService.hide();
            },
            ()=>{
              this.refreshItems();
              this.spinnerService.hide();
            })
            //this.cardData.todo.push(this.draggedCard);
            this.draggedCard = null;
            this.sourceId = '';
          }
          else
          {
            this.spinnerService.hide();
            this.refreshItems();
            this.draggedCard = null;
            this.sourceId = '';
          }
        })
      }
      //this.draggedCard = null;
    }

    return false;
  }

  dropFromInProgressToClosed(){
    if(this.draggedCard)
    {
      if(this.draggedCard.statusId == 2 || this.draggedCard.statusId == 4)
      {
        this.spinnerService.show();
        if (this.targetResolvingDate){
          var targetDate = moment.utc(this.targetResolvingDate).format('YYYY-MM-DD');
          var actionDeadlineDate = moment.utc(this.draggedCard.targetDate).format('YYYY-MM-DD');

          if ((actionDeadlineDate > targetDate) && this.draggedCard.type != "27") {
            this.throttledPopUpValidActionDeadline();
            this.spinnerService.hide();
            return false;
          }
        }

        this.permissionsService.getUserPermissionToken(this.draggedCard.id, this.selectedRole ? this.selectedRole : null).subscribe((resp)=>{
          this.tokenForUser = resp;
          let cancelDrag = this.validationService.disableComplete(this.draggedCard, this.tokenForUser);
          if (cancelDrag){
            if (!this.tokenForUser.isActionOwner){
              this.throttledPopUpCloseAccessValidation();
              this.spinnerService.hide();
            }
            else{
              this.throttledPopUpCloseValidation();
              this.spinnerService.hide();
            }
          }
          else {
            this.draggedCard.statusId = ActionItemStatus.Completed;
              this.refreshItems();
              this.actionPlanService.completeItem(this.draggedCard).subscribe(()=>{
                this.messageService.add({
                  severity: 'success',
                  summary: 'Action was successfully completed.'
                });
                this.refreshItems();
                this.spinnerService.hide();
              },
              ()=>{
                this.refreshItems();
                this.spinnerService.hide();
              })
          }
        })
        //this.cardData.closed.push(this.draggedCard);
        this.refreshItems();
      }
      // this.draggedCard = null;
      // this.sourceId = '';
    }
    //this.spinnerService.hide();
    return false;
  }

  deleteCard(idEmitted){
    // this.ref = this.dialogService.open(DeleteCardModalComponent, {
    //   header: 'Delete Action',
    //   width: '80%',
    //   position: 'top'
    // })

    // this.ref.onClose.subscribe((data) => {
    //   if(data) {
    //     if(data.result)
    //     {
    //       this.actionPlanService.deleteCard(idEmitted).subscribe((response)=>{
    //         this.messageService.add({
    //           severity: 'info',
    //           summary: 'Successfully deleted action'
    //         })

    //         this.refreshItems();
    //       })
    //     }
    //   }
    // })
    //console.log(idEmitted);
    //this.actionPlanService.deleteCard(idEmitted) //to be updated
    this.actionPlanService.deleteCard(idEmitted).subscribe((response)=>{
      this.messageService.add({
        severity: 'success',
        summary: 'Successfully deleted action'
      })

      this.refreshItems();
    })
  }


  throttledPopUpAssignAccessValidation(){
    this.messageService.add({
      severity: 'error',
      summary: 'You are not allowed to assign this action.'
    })
  }

  throttledPopUpValidActionDeadline(){
    this.messageService.add({
      severity: 'error',
      summary: 'Please Select Valid Action Deadline Date.'
    })
  }

  throttledPopUpAssignValidation(){
    this.messageService.add({
      severity: 'error',
      summary: 'Please fill required fields to assign this action'
    })
  }

  throttledPopUpReopenAccessValidation(){
    this.messageService.add({
      severity: 'error',
      summary: 'You are not allowed to reopen this action.'
    })
  }

  throttledPopUpReopenDatePassedValidation(){
    this.messageService.add({
      severity: 'error',
      summary: 'Target Resolution & Verification Deadline Date already passed so cannot reopen this Action.'
    })
  }

  throttledPopUpRejectAccessValidation(){
    this.messageService.add({
      severity: 'error',
      summary: 'You are not allowed to reject this action.'
    })
  }

  throttledPopUpRejectValidation(){
    this.messageService.add({
      severity: 'error',
      summary: 'Please fill required fields to reject this action.'
    })
  }

  throttledPopUpCloseAccessValidation(){
    this.messageService.add({
      severity: 'error',
      summary: 'You are not allowed to complete this action.'
    })
  }

  throttledPopUpCloseValidation(){
    this.messageService.add({
      severity: 'error',
      summary: 'Please fill required fields to complete this action.'
    })
  }

}
