import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TreeNode } from 'primeng/api';
import { ProductService } from 'src/app/services/product.service';
import { PrimeNGConfig } from "primeng/api";
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-product-popup',
  templateUrl: './product-popup.component.html',
  styleUrls: ['./product-popup.component.css']
})

export class ProductPopupComponent implements OnInit {

  files1: any = [];
  selectedNode!: TreeNode;
  resolvingUnitId: any;
  loading: boolean = false;

  constructor(
    private http: HttpClient,
   private productService: ProductService,
   private primengConfig: PrimeNGConfig,
   private config : DynamicDialogConfig,
    private readonly dialogRef: DynamicDialogRef) {
    }
  ngOnInit(): void {
    this.resolvingUnitId = this.config?.data;
    this.initiateTree();
  }

  initiateTree() {
    this.loading = true;
    this.productService.getProduct(false, this.resolvingUnitId).subscribe((files:any) => {
      if(files) {
        this.files1 = files;
        this.files1.map((res) => {
          res.label = res.name,
          res.key = res.name,
          res.name = res.name,
          res.data = res.flatName,
          res.children = [],
          res.leaf = res.hasSubClasses == true ? false : true,
          res.selectable = res.isSelectable
        });
      }
      this.loading = false;
    });
  }

  filterProcessStep($event){
      this.loading = true;
      let searchData = {
        textFilter: $event.target?.value,
        resolvingUnitId: this.resolvingUnitId,
        onlyActive: false,
        onlyWithPg: false
      };
      if($event.target.value) {
        this.productService.getFilteredProduct(searchData).subscribe((files) => {
          if(files){
            this.files1 = files;
            this.files1.map((res) => {
              res.label = res.name,
              res.data = res.flatName,
              res.key = res.name,
              res.expanded = res.hasSubClasses,
              res.children = this.recursiveForSearch(res.subClasses),
              res.id = res.id,
              res.leaf = res.hasSubClasses == true ? false : true,
              res.parentName = res.name;
            });
            this.loading = false;
          }
        })
      }
      else {
        this.initiateTree();
      }
    }

  // For search box purpose
  nodeSelect(event: any) {
    // this.selectedNode.data = node.originalEvent.target.innerText;
    const nodeSelectObj = {
      cid : event.node.cid,
      onlyActive: false,
      resolvingUnitId: this.resolvingUnitId
    }
    this.loading = true;
    this.productService.getProductClasses(nodeSelectObj).subscribe((res: any)=>{
      if(res){
        this.files1 = this.newH(res, this.files1, event.node.id);
        this.loading = false;
      }
    })
  }

  recursiveForSearch(childrenRecords) {
    childrenRecords.map((childRes) => {
      childRes.label = childRes.name,
      childRes.data = childRes.flatName,
      childRes.children = childRes.subClasses,
      childRes.id = childRes.id,
      childRes.expanded = childRes.hasSubClasses,
      childRes.leaf = childRes.hasSubClasses == true ? false : true,
      childRes.parentName = childRes.name
    })
    childrenRecords.map((hasChildren) => {
      if(hasChildren.hasSubClasses) {
        this.recursiveForSearch(hasChildren?.subClasses)
      }
    })
    return childrenRecords;
  }

  // Data are disabled
  enableAll() {
    this.files1.forEach((node: any) => {
      this.enableRecursive(node, false);
    })
  }

  private enableRecursive(node: any, isSelectable: boolean) {
    node.selectable = node.disabled;
    if (node.children) {
      node.children.forEach((childNode: any) => {
        this.enableRecursive(childNode, childNode.disabled);
      });
    }
  }

  selectAndClose() {
   // console.log("<><><>",this.selectedNode, this.selectedNode?.parent);
    if (this.selectedNode !== null && this.selectedNode !== undefined) {
      let selectedValue: any = this.selectedNode;
      var tooltip = '';
      let level = 0;
      if(this.selectedNode?.parent?.parent?.parent?.parent?.parent?.parent?.parent){
        level = 7;
      }
      else if(this.selectedNode?.parent?.parent?.parent?.parent?.parent?.parent){
        level = 6;
      }
      else if(this.selectedNode?.parent?.parent?.parent?.parent?.parent){
        level = 5;
      }
      else if(this.selectedNode?.parent?.parent?.parent?.parent){
        level = 4;
      }
      else if(this.selectedNode?.parent?.parent?.parent){
        level = 3;
      }
      else if(this.selectedNode?.parent?.parent){
        level = 2;
      }
      else if(this.selectedNode?.parent){
        level = 1;
      }
      var currentNode:any = this.selectedNode;
      var currenttreeNode = this.selectedNode;
      while (currenttreeNode !== undefined && currenttreeNode !== null) {
          tooltip = '<span class="spacer"></span>'.repeat(level) + (selectedValue==currentNode ? "<b>" + this.xssProtection(currentNode?.displayLabel) + "</b>" : currentNode.displayLabel) + '<br/>' + tooltip;
          currentNode = currenttreeNode?.parent;
          currenttreeNode = currenttreeNode?.parent;
          level--;
      }
      selectedValue.tooltipInfo = tooltip;
      this.dialogRef.close(selectedValue);
    }
  }

  onClose() {
    this.dialogRef.close();
  }

  newH(newData: any, h = this.files1, id){
    h.forEach((node) => {
        if(node.id == id){
         newData.map((res) => {
                  res.label = res.name,
                  res.data = res.flatName,
                  res.key = res.name,
                  // res.expandedIcon = 'pi pi-folder-open',
                  // res.collapsedIcon = 'pi pi-folder',
                  res.children = res.children,
                  res.id = res.id,
                  res.leaf = res.hasSubClasses == true ? false : true,
                  res.selectable = res.isSelectable
                });
          node.children = [...newData]
        } else if(node.children?.length) {
           this.newH(newData, node.children, id)
        }
    })

    return h
  }
  xssProtection(value) {
    var lt = /</g,
        gt = />/g,
        ap = /'/g,
        ic = /"/g;
    return value?.replace(lt, "&lt;").replace(gt, "&gt;").replace(ap, "&#39;").replace(ic, '&#34;');
  }
}
