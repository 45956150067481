import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import * as _ from 'lodash';
import { MdServiceService } from 'src/app/services/md-service.service';
import { MdRegionService } from 'src/app/services/md-region.service';
import { UsersService } from 'src/app/services/users/users.service';
import { AdminProcessOwnerService } from 'src/app/services/admin-process-owner.service';


@Component({
  selector: 'app-search-criteria-popup',
  templateUrl: './search-criteria-popup.component.html',
  styleUrls: ['./search-criteria-popup.component.css']
})
export class SearchCriteriaPopupComponent implements OnInit {

  selected: any = {
    divisions: ['PG'],
    bus: [],
    pgs: [],
    countries: [],
    regions: [],
    employees: []
  };

  isScopeEmpty: boolean = true;

  divisions: any;
  bulist: any;
  pglist: any;
  regions: any;
  countries: any;

  divisionsCopy: any;
  bulistCopy: any;
  pglistCopy:any;
  regionsCopy: any;
  countriesCopy: any;

  poSearchForm!: FormGroup;
  filteredSubstitude: any;
  employeesGeids:any=[];

  searchScope:any ={};

  constructor(private fb: FormBuilder,
    private readonly dialogRef: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private mdData: MdServiceService,
    private mdRegionService: MdRegionService,
    private usersService: UsersService,
    private AdminProcessOwnerService:AdminProcessOwnerService) {
       this.searchScope = config.data;
     }

  ngOnInit(): void {

    this.mdData.divisions().then((r) => {
      this.divisions = r;
      this.divisionsCopy = this.divisions;

      let div= [];
      this.divisions.find((obj)=>{
        if(obj.id === 'PG')
          div.push(obj.id)
      })
      this.poSearchForm.patchValue({
        business: div
      })
    });

    this.mdData.bu().then( (r)=> {

      this.bulist = r;
      this.divisions.reverse().forEach((div) => {
        this.bulist.unshift({ divCode: div.id, id: div.id + 'ALL', name: div.id + ' ALL' });
      });

      this.bulist.forEach((bu) => {
        bu.label = (bu.id.indexOf('ALL') == -1)
          ? '(' + bu.id + ') - ' + bu.name
          : bu.name;

      });
      this.bulistCopy = this.bulist;
    });

    this.mdData.pg().then( (r) =>{
      this.pglist = r;
      this.bulist.filter((item) => {
        return item.id.indexOf('ALL') == -1;
      }).reverse()
        .forEach((bu) => {
          this.pglist.unshift({ divCode: bu.divCode, buCode: bu.id, id: bu.id + 'ALL', name: bu.id + ' ALL' });
        });
      this.divisions.reverse().forEach((div) => {
        this.pglist.unshift({ divCode: div.id, buCode: div.id + 'ALL', id: div.id + 'ALL', name: div.id + ' ALL' });

      });

      this.pglist.forEach((pg) => {
        pg.label = (pg.id.indexOf('ALL') == -1)
          ? '(' + pg.id + ') - ' + pg.name
          : pg.name;

      });

      this.pglistCopy = this.pglist;
    });

    this.mdRegionService.regions().then( (r) => {
      this.regions = r;

      this.mdRegionService.countries().then( (r)=> {
        this.countries = r;

        this.regions.reverse().forEach((reg) => {
          this.countries.unshift({ id: reg.id + 'ALL', name: reg.name + ' ALL', rCode: reg.id });
        });

        this.countries.forEach((c) => {
          c.label = (c.id.indexOf('ALL') == -1)
            ? '(' + c.id + ') - ' + c.name
            : c.name;
        });

        this.countriesCopy = this.countries;
      });

      this.regions.map((reg)=>{
        reg.lableName = '(' + reg?.id + ') - ' + reg?.name;
      })
      this.regionsCopy = this.regions;
    });


    // Form

    this.poSearchForm = this.fb.group({
      'business': [],
      'country': [''],
      'bu': [''],
      'pg': [''],
      'region': [''],
      'employee': [''],
      'filteredSubstitude': [''],
    })

    if(this.searchScope){
      let bus = [];
      let pgs = [];
      let regions =[];
      let divs =[];
      let cuntys =[];
      let remps =[];
      if( this.searchScope?.searchquery?.bus){
        this.searchScope.searchquery.bus.forEach((e)=>{
          bus.push(e);
        })
      }
      if(this.searchScope?.searchquery?.divisions){
        this.searchScope.searchquery.divisions.forEach((e)=>{
          divs.push(e);
        })
      }
      if(this.searchScope?.searchquery?.pgs){
        this.searchScope.searchquery.pgs.forEach((e)=>{
          pgs.push(e);
        })
      }
      if(this.searchScope?.searchquery?.regions){
        this.searchScope.searchquery.regions.forEach((e)=>{
          regions.push(e);
        })
      }
      if(this.searchScope?.searchquery?.countries){
        this.searchScope.searchquery.countries.forEach((e)=>{
          cuntys.push(e);
        })
      }

      if(this.searchScope.selectedemps){
        this.searchScope.selectedemps.forEach((e)=>{
          remps.push(e);
        })
      }


      this.poSearchForm.patchValue({
        business : divs,
        bu: bus,
        pg: pgs,
        region : regions,
        country :cuntys,
        employee: remps
      })

    }

  }

  extractId(obj) {
    return _.map(obj, function (f) { return f.id; });
  }

  regChanged() {
    if (this.selected.countries.length > 0) {
      this.selected.countries = [];
    }
  };

  busChanged() {
    if (this.selected.pgs.length > 0) {
      this.selected.pgs = '';
    }

  };
  divChanged() {
    if (this.selected.bus.length > 0 || this.selected.pgs.length > 0) {
      this.selected.pgs = '';
      this.selected.bus = '';
    }
  };

  reset() {
    this.selected = {
      divisions: ['PG'],
      bus: [],
      pgs: [],
      countries: [],
      regions: [],
      employees: []
    };

    this.poSearchForm.reset();

    //Patching Division to PG for clear filter fields
    let div= [];
    this.divisions.find((obj)=>{
      if(obj.id === 'PG')
        div.push(obj.id)
    })
    this.poSearchForm.patchValue({
      business: div
    })

    this.filterSubstitude = null
    this.poSearchForm.controls['employee'].reset()
    //this.poSearchForm.get('employee').reset();

    this.isScopeEmpty = true;
  }


  cancel() {
    this.dialogRef.close();
  }

  ok() {
    let formatData= {
      divisions:this.poSearchForm.get('business').value ? this.poSearchForm.get('business').value : [],
      bus: this.poSearchForm.get('bu').value ? this.poSearchForm.get('bu').value : [],
      pgs:this.poSearchForm.get('pg').value ? this.poSearchForm.get('pg').value : [],
      regions: this.poSearchForm.get('region').value ? this.poSearchForm.get('region').value : [],
      countries:this.poSearchForm.get('country').value ? this.poSearchForm.get('country').value : [],
      employees:this.poSearchForm.get('employee').value ? this.poSearchForm.get('employee').value : []
    }
    let selectedEmployeeObjects = this.poSearchForm.get('employee').value ? this.poSearchForm.get('employee').value : [];
    selectedEmployeeObjects.forEach(emp => {
      this.employeesGeids.push(emp.id)
    });

    if(formatData){
      this.selected.divisions = formatData.divisions
      this.selected.bus = formatData.bus
      this.selected.pgs = formatData.pgs
      this.selected.countries = formatData.countries
      this.selected.regions = formatData.regions
      this.selected.employees =  this.employeesGeids
    }

    let createFlatQuery = this.selected;
    // if (createFlatQuery.employees.length > 0) {
    //   createFlatQuery.employees = this.extractId(createFlatQuery.employees);
    // }
    this.dialogRef.close({ query: createFlatQuery , emps : selectedEmployeeObjects });
  };

  filterSubstitude(query) {
    if (query.length > 1) {
      this.AdminProcessOwnerService.getFilteredUser(query).subscribe((res) => {
        this.filteredSubstitude = res;
        this.filteredSubstitude.map((data) => {
          data.name = data.name + '<' + data.email + '>';
        })
      })
    }
  }

  onChangeBusiness(event){
    this.poSearchForm.controls['bu'].reset();
    this.poSearchForm.controls['pg'].reset();
    this.bulist = this.bulistCopy;
    this.pglist = this.pglistCopy;
  }

  onChangeBu(event){
    this.poSearchForm.controls['pg'].reset();

    let filteredData: any = [];
    let query = event?.value;
    if(query.length > 0){
      for(let i=0; i<query.length; i++){
        this.pglistCopy?.filter((obj)=>{
          if(obj?.buCode == query[i])
            filteredData.push(obj)
        })
      }
      this.pglist = filteredData
    }
    else{
      this.pglist = this.pglistCopy;
    }
  }

  onRegionChange(event){
    this.poSearchForm.controls['country'].reset();

    let filteredData: any = [];
    let query = event?.value;
    if(query.length > 0){
      for(let i=0; i<query.length; i++){
        this.countriesCopy?.filter((obj)=>{
          if(obj?.rCode == query[i])
            filteredData.push(obj)
        })
      }
      this.countries = filteredData
    }
    else{
      this.countries = this.countriesCopy;
    }
  }
}

