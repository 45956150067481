import { Component, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MessageService, PrimeNGConfig } from 'primeng/api';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { AdminCustomerContactsService } from 'src/app/services/admin-customer-contacts.service';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-add-contact-popup',
  templateUrl: './add-contact-popup.component.html',
  styleUrls: ['./add-contact-popup.component.css']
})
export class AddContactPopupComponent implements OnInit {

  @Input() filterSearchCustomerDetails: any;
  @Input() custObj: any;
  @Input() searchCustData: any;
  @Output() receivingCustObject: any
  admAddContactForm: FormGroup;
  addContactObj: any;
  customerApidata: any;
  validation: any;
  hideShowBtnDiv = false;
  custDataResponse: any;

  adminAddContactRequired = 'Please fill out this field.';

  constructor(
    private fb: FormBuilder,
    private primengConfig: PrimeNGConfig,
    private dialogService: DialogService,
    private ref: DynamicDialogRef,
    private messageService: MessageService,
    private admCustomer: AdminCustomerContactsService,
    private sharedService: SharedService,
    private dialogConfig: DynamicDialogConfig,
  ) { }

  ngOnInit(): void {
    this.admAddContactForm = this.fb.group({
      fname: ['', [Validators.required, Validators.maxLength(100)]],
      lname: ['', [Validators.required, Validators.maxLength(100)]],
      email: ['', [Validators.required, Validators.email, Validators.maxLength(255)]],
      phone: [,[Validators.maxLength(100)]],
      isActive: []
    })

    this.receivingCustObject = this.sharedService.getObject();
    this.searchCustData = this.sharedService.getSearchObject();
  }

  addContacts() {
    this.addContactObj =
    {
      "customerContactModel": {
        "id": 0,
        "firstName": this.admAddContactForm.get('fname').value,
        "lastName": this.admAddContactForm.get('lname').value,
        "phone": this.admAddContactForm.get('phone').value,
        "email": this.admAddContactForm.get('email').value,
        "isActive": true,
        "customer": {
          "id": this.receivingCustObject.id,
          "guid": this.receivingCustObject.guid,
          "streetName": this.receivingCustObject.streetName,
          "city": this.receivingCustObject.city,
          "stateDistrict": this.receivingCustObject.stateDistrict,
          "countryCode": this.receivingCustObject.countryCode,
          "companyFullName": this.receivingCustObject.companyFullName
        }
      },
      "isEdit": true
    }

    this.customerApidata = {
      "id": 0,
      "firstName": this.admAddContactForm.get('fname').value,
      "lastName": this.admAddContactForm.get('lname').value,
      "phone": this.admAddContactForm.get('phone').value,
      "email": this.admAddContactForm.get('email').value,
      "isActive": true,
      "customer": {
        "id": this.receivingCustObject.id,
        "guid": this.receivingCustObject.guid,
        "streetName": this.receivingCustObject.streetName,
        "city": this.receivingCustObject.city,
        "stateDistrict": this.receivingCustObject.stateDistrict,
        "countryCode": this.receivingCustObject.countryCode,
        "companyFullName": this.receivingCustObject.companyFullName
      },
      "isEdit": true
    }

    this.admCustomer.getAddCustomers(this.addContactObj).subscribe((resp) => {
      const addedData = resp;
      if (addedData.isUniqe == false && addedData.isActive == true) {
        this.hideShowBtnDiv = true;
        this.validation = "Contact already exist.";
        this.dialogConfig.dismissableMask = false;
        return
      }
      else if (addedData.isUniqe == false && addedData.isActive == false) {
        this.hideShowBtnDiv = true;
        this.validation = "Contact already exist but it is deactivated.";
        return
      }
      if (addedData !== null) {
        this.admCustomer.getAfterAddingCustomer(this.customerApidata).subscribe((response) => {
          const addApiData = response;
        })
      }

      setTimeout(() => {
          this.admCustomer.getCustomerSearch(this.searchCustData).subscribe((value) => {
            this.custDataResponse = value;
          })
          if (addedData.isUniqe == true) {
            this.ref.close(this.searchCustData);
          }
      }, 1000)
    })
  }

  cancel() {
    this.ref.close()
  };
  keyPressNumbers(event) {
    var charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9
    if ((charCode < 48 || charCode > 57 )) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }

}
