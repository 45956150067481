import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AdminCustomerContactsService {

  constructor(private http: HttpClient) { }

  getFilteredCustomerDetails(query): Observable<any> {
    return this.http.get(`${environment.azure.baseUrl}api/v1/customer/findbywildcard?wildcard=${query}&accountType&customerhq&customerregion&customercity&page=1&size=26`);
  }

  getFilteredContactDetails(query): Observable<any> {
    return this.http.get(`${environment.azure.baseUrl}api/v1/customercontact/findbywildcard?wildcard=${query}`);
  }

  getCustomerSearch(data):Observable<any>{
    return this.http.post(`${environment.azure.baseUrl}api/v1/customercontact/search`,data);
  }

  getMetadata():Observable<any>{
    return this.http.get(`${environment.azure.baseUrl}api/v1/reports/metadata`)
  }

  getDivisions():Observable<any>{
    return this.http.get(`${environment.azure.baseUrl}api/v1/divisions/all`)
  }

  getAdvancedCustomers(data): Observable<any>{
    return this.http.post(`${environment.azure.baseUrl}/api/v1/customer/find`,data);
  }

  getAddCustomers(data): Observable<any>{
    return this.http.post(`${environment.azure.baseUrl}/api/v1/customercontact/ensureUniqe`,data);
  }

  getAfterAddingCustomer(dataCust): Observable<any>{
    return this.http.post(`${environment.azure.baseUrl}/api/v1/customercontact`,dataCust);
  }

  deleteContact(id:any): Observable<any>{
    return this.http.delete(`${environment.azure.baseUrl}api/v1/customercontact/`+id);
  }

  updateCustomerContact(updData:any): Observable<any>{
    return this.http.put(`${environment.azure.baseUrl}api/v1/customercontact/`,updData);
  }

  getCustomerByCode(customerId):Observable<any>{
    return this.http.get(`${environment.azure.baseUrl}api/v1/customer/findByCodeOrId?customerIdOrCode=${customerId}`);
  }
}
