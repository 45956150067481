import { Component, Input, OnInit } from '@angular/core';
import { ProcessOwnersService } from '../process-owners.service';
import { DynamicDialogRef, DialogService } from 'primeng/dynamicdialog';
import { DeleteProcessownerComponent } from '../delete-processowner/delete-processowner.component';
import { AdminProcessownersComponent } from '../admin-processowners/admin-processowners.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-po-itemlocation',
  templateUrl: './po-itemlocation.component.html',
  styleUrls: ['./po-itemlocation.component.css']
})
export class PoItemlocationComponent implements OnInit {

  @Input('itemScope') itemScope: any;
  deleteButtonHideForSearch:boolean = false;

  managersLoaded = {
    location: false,
    unitLocation: false
  };

  loadingManagers = {
    locationManagers: false,
    unitLocationManagers: false
  };

  canLoadManagers: boolean = true;
  expanded: boolean = true;

  constructor(private processOwnersService: ProcessOwnersService,
    private ref: DynamicDialogRef,
    private dialogService: DialogService,
    private admPOComponet:AdminProcessownersComponent,
    private router:Router) { }

  ngOnInit(): void {

  }

  expand() {
    this.expanded = true;
  };

  collapse() {
    this.expanded = false;
  };

  onExpandLocationManagers() {

    const CurrentURL= this.router.url;
    if(CurrentURL == '/posearch'){
      this.deleteButtonHideForSearch = true;
    }

    if (this.managersLoaded.location) {
      this.itemScope.showAllLocationManagers = true;
      return;
    }
    this.loadingManagers.locationManagers = true;
    this.processOwnersService.loadLocationManagers(this.itemScope).then(() => {
      this.itemScope.showAllLocationManagers = true;
      this.loadingManagers.locationManagers = false;
      this.managersLoaded.location = true;
    });
  };

  onExpandUnitLocationManagers() {

    const CurrentURL= this.router.url;
    if(CurrentURL == '/posearch'){
      this.deleteButtonHideForSearch = true;
    }
    
    if (this.managersLoaded.unitLocation) {
      this.itemScope.showAllUnitLocationManagers = true;
      return;
    }
    this.loadingManagers.unitLocationManagers = true;
    this.processOwnersService.loadLocationUnitManagers(this.itemScope).then(() => {
      this.itemScope.showAllUnitLocationManagers = true;
      this.loadingManagers.unitLocationManagers = false;
      this.managersLoaded.unitLocation = true;
    });
  }

  deletePerson(userDetails: any) {
    this.ref = this.dialogService.open(DeleteProcessownerComponent, {
      header: 'Delete Process Owner',
      width: '40%',
      position: 'top',
      keepInViewport: true,
      autoZIndex: true,
      styleClass: 'deleteProcessOwner',
      data: { user: userDetails }
    });

    this.ref.onClose.subscribe((data) => {
      if(data){
        this.admPOComponet.reloadSearchResults();
      }
    })
  }

}
