import { Component, Input, OnInit } from '@angular/core';
import { TransalationserviceService } from '../transalationservice.service';
import { MessageRecipientsService } from '../message-recipients.service';
import * as _ from 'lodash';
import { DynamicDialogRef, DialogService } from 'primeng/dynamicdialog';
import { AddSystemuserRoleComponent } from '../../systemusers/add-systemuser-role/add-systemuser-role.component';
import { TranslationMessageTemplateditComponent } from '../translation-message-templatedit/translation-message-templatedit.component';

@Component({
  selector: 'app-translation-message-item',
  templateUrl: './translation-message-item.component.html',
  styleUrls: ['./translation-message-item.component.css']
})
export class TranslationMessageItemComponent implements OnInit {

  @Input('msg') msg: any;
  @Input('operationName') operationName: any;
  @Input('isDisabled') isDisabled: boolean;
  @Input('allowEdit') allowEdit: boolean;
  @Input('selectedLanguage') selectedLanguage: any;

  constructor(private messageRecipientsService: MessageRecipientsService,
    private ref: DynamicDialogRef,
    private dialogService: DialogService) { }

  ngOnInit(): void {
    this.translateRecipients();

    this.messageRecipientsService.getMessageTypeName(this.msg.messageTypeId)
      .then( (data)=> {
        this.msg.messageTypeName = data.typeName;
      });
  }

  translateRecipients() {
    this.msg.recipients = [];
    this.msg.cc = [];

    this.messageRecipientsService.getDefaultMessageRecipients().then((data) => {
      _.forEach(this.msg.defaultRecipients, (code) => {
        let lookup = _.find(data, (recipient) => {
          return parseInt(code) === recipient.code;
        });
        if (undefined !== lookup) {
          this.msg.recipients.push(lookup);
        }
      });

      _.forEach(this.msg.defaultCC, (code) => {
        let lookup = _.find(data, (recipient) => {
          return parseInt(code) === recipient.code;
        });
        if (undefined !== lookup) {
          this.msg.cc.push(lookup);
        }
      });
    });
  }

  editMsgTemplate(msg){
    this.ref = this.dialogService.open(TranslationMessageTemplateditComponent, {
      header: `Edit message template : "${msg.name ? msg.name : msg?.engTranslation?.name }"`,
      width: '70%',
      position: 'top',
      keepInViewport: true,
      autoZIndex: true,
      styleClass: 'transalationEditModal',
      data: msg
    });

    this.ref.onClose.subscribe(() => {

    })
  }

  shouldShowEngTranslation() {
    return this.msg.culture !== this.msg.engTranslation.culture;
};

}
