<form [formGroup]="issueClosedForm">
  <p-toast [style]="{ marginTop: '5px' }"></p-toast>
  <div class="containerIssueClosed">

    <div class="row mb-1">
      <label class="remove-px margin-space" [pTooltip]="checkBoxToolTipInfo" tooltipPosition="top"
      tooltipStyleClass="toolTipClass">
        <input type="checkbox" formControlName="AwaitingCustomerExecution" (change)="onCheckboxEnabled($event)"
          value="issueClosedCheck" />
        <span> {{ "ResAwaitingCustomerExecution" | translate }}.</span>
      </label>
    </div>
      <div class="row mb-1 mt-2" *ngIf="showCalendarDiv">
        <div class="col d-flex">
          <div class="col-md-2 text-end">
            <label class="me-3">{{"ResExpectedExecutionDate" | translate }}:
              <span class="asteriskRequired"> </span></label>
          </div>
          <div class="col-md-8 mb-2 col-sm-5 calendar-inputfield">
            <input type="hidden" formControlName="ExpectedExecutionHiddenDate">
            <p-calendar (onSelect)="whenHappenedTextSelect()" #calendar dateFormat="yy-mm-dd"
              formControlName="ExpectedExecutionDate" class="customtextfield-s" [pTooltip]="whenHappenedText"
              [escape]="false" tooltipPosition="top" tooltipStyleClass="toolTipClass" [readonlyInput]="true"
              [required]="true" [minDate]="minDate"
              [ngClass]="this.issueClosedForm.controls['ExpectedExecutionDate'].invalid ? 'error-calendarInput' : ''">
              <!-- [ngClass]="{'error-calendar':  issueClosedDeadlineDateNotValid}" -->
            </p-calendar>


          </div>

        </div>
      </div>
    <div class="row my-2 form-group margin-space">
      <label class="mt-1 remove-px" for="comments" style="padding-left: 0px;">Customer comments/ Reason to change Expected Execution Date:<span class="asteriskRequired"
        *ngIf="this.issueClosedForm.controls['customerComments']?.validator"></span></label>
      <textarea formControlName="customerComments" class="form-control textarea-height2" cols="20"
        fieldlabel="CustomerFeedback" id="AreaAcceptance_CustomerFeedback" name="AreaAcceptance.CustomerFeedback"
        rows="2" [pTooltip]="commentsToolTipInfo" tooltipPosition="top" tooltipStyleClass="toolTipClass"
        [ngClass]="this.issueClosedForm.controls['customerComments'].validator ? 'error-inputbox' : ''"></textarea>
    </div>

    <!-- Starts Div added -->

    <div *ngIf="isExternal" class="margin-space">
      <div class="row">
        <fieldset class="border-0 p-0">
          <h6 class="inner-heading1">{{ "ComplaintForm_FeedbackSurvey" | translate }}</h6>
        </fieldset>
      </div>
      <div class="row">
        <label class="col-md-3 mt-2 remove-px text-right-closed">{{"Complaint_CustomerFeedbackSubscriptionStatus" | translate }}</label>
        <div class="col-md-3 px-0">
          <input title="custContactStatus" type="text" formControlName="customerContactStatus" tooltipPosition="top"
            tooltipStyleClass="toolTipClass" delay="500" class="form-control customtextfield-l width-full disabled"
            [(ngModel)]="customerStatus" [readOnly]="true">
        </div>
      </div>
      <div class="row mb-1">
        <label class="col-md-3 mt-2 remove-px text-right-closed">Send feedback email to customer</label>
        <div class="col-md-3 px-0">
          <p-dropdown [options]="feedBackValue" optionLabel="value" optionValue="id" formControlName="sendFeedback">
          </p-dropdown>
        </div>
      </div>
      <div class="row">
        <label class="col-md-3 mt-2 remove-px text-right-closed">Justification
          <span [ngClass]="issueClosedForm.get('sendFeedback')?.value == 'No' ? 'asteriskRequired' : ''"></span>
        </label>
        <div class="col-md-6 px-0">
          <textarea formControlName="CustomerFeedbackJustification" class="form-control textarea-height2" cols="20"
            fieldlabel="CustomerFeedback" id="AreaAcceptance_CustomerFeedbackJustification" [ngClass]="{ 'error-inputbox' : isSubmitted && this.issueClosedForm.controls['CustomerFeedbackJustification']?.invalid }"
            name="AreaAcceptance.CustomerFeedback" rows="2" maxlength="{{maxLength.CUSTOMER_FEEDBACK_JUSTIFICATION}}"></textarea>
          <input type="hidden" formControlName="isCustomerFeedbackJustification">
        </div>
      </div>
      <div class="row" [hidden]="!enableDiv">
        <label class="col-md-3 remove-px text-right-closed">
          {{ "Complaint_CustomerFeedbackSurveyStatus" | translate }}
        </label>
        <div class="col-md-6 px-0">
          <input title="custContactStatus" type="text" [pTooltip]="descForResponse" formControlName="closureConfirmationStatus" tooltipPosition="top"
                 tooltipStyleClass="toolTipClass" delay="500" class="form-control customtextfield-l width-full" [readOnly]="true"><br><br>
          <input type="hidden" formControlName="hiddenClosureConfirmationStatusCode">
        </div>
      </div>
      <div class="row" [hidden]="!enableResponseFeedback" style="margin-top: -5px;">
        <label class="col-md-3 remove-px text-right-closed">
          {{ "Excel_survey_response" | translate }}
        </label>
        <div class="col-md-6 px-0">
          <textarea formControlName="customerResponse" class="form-control textarea-height2" cols="20"
                    fieldlabel="customerResponse" id="AreaAcceptance_CustomerResponse" name="AreaAcceptance?.CustomerResponse"
                    rows="2" tooltipPosition="top" tooltipStyleClass="toolTipClass" [readOnly]="true"></textarea>
        </div>
      </div>
    </div>
      <fieldset class="border-0 p-0">
        <div class="inner-heading-wrapper">
          <h6 class="inner-heading1 mt-1">{{ "ComplaintForm_AttachmentsSectionName" | translate }}</h6>
        </div>
      </fieldset>
    <div class="row mb-1  attachments-section forAttachment margin-space">
      <div class="col-md-3 col-sm-3 ps-0 pe-0 me-3 text-end left-algn-mobile" *ngIf="acceptanceAttachments?.length > 0">
        <label class="mt-2 remove-px">{{ "ComplaintForm_UploadedAttachments" | translate }}</label>
      </div>
      <input type="hidden" formControlName="uploadedAcceptanceAttachments">
      <div class="col-md-8 col-sm-9 p-0 selectfile remove-px" *ngIf="acceptanceAttachments?.length > 0">
        <div class="already-uploaded-attachments-main">
          <div class="row file-row "
            *ngFor="let acceptanceAttachment of acceptanceAttachments; let acceptanceAttachmentIndex = index;">
            <div class="col-md-11 col-sm-11 filename-text filenamewidth1"><a class="fileInfo"
                [ngClass]="checkAcceptanceRemoved(acceptanceAttachmentIndex) ? 'to-be-removed' : ''"
                (click)="downloadAttachementFromAPI(acceptanceAttachment?.storeFileName,acceptanceAttachment?.displayName)" style="cursor: pointer;"
                target="_blank">{{acceptanceAttachment?.displayName}}</a>
            </div>
            <div class="col-md-1 col-sm-1 filenamewidth2">
              <!-- <button type="button" class="widgetButtoSmall attachButton"
                      (click)="uploadedAcceptanceAttachmentRemove(acceptanceAttachmentIndex)"> X </button> -->
              <i class="fa fa-window-close closebtn" aria-hidden="true"
                (click)="uploadedAcceptanceAttachmentRemove(acceptanceAttachmentIndex)"> </i>
            </div>

          </div>

        </div>
        <div class="row">
          <span class="downloadAllLink">
            <a href="{{downloadAllUrl}}" target="_blank">Download All</a>
          </span>
        </div>
      </div>
      <div class="col-md-3 col-sm-3 ps-0 pe-0 label-d-left-m-right mt-1">
        <label class="mt-2 forLabelAttach me-3">{{ "ComplaintForm_SpecifyAttachment" |
          translate:paramForAttachment }}</label>
      </div>
      <div class="col-md-8 col-sm-9 selectfile remove-px">
        <p-fileUpload #createUploaderClose chooseLabel="Select Files" chooseIcon="null"
          removeStyleClass="p-overlaypanel1" [multiple]="true" chooseStyleClass="p-overlaypanel"
          [showCancelButton]="true" chooseIcon="null" removeStyleClass="p-overlaypanel1" [maxFileSize]="60000000"
          [auto]="true" ms-5 [customUpload]="true" (uploadHandler)="myUploaderClose(createUploaderClose,$event)"
          [disabled]="!disableSection">

          <ng-template let-fileClose pTemplate="file">
            <ul class="p-upload-files p-reset">
              <li class="p-file row">
                <div class="col-md-11 col-sm-11 filenamewidth1" [pTooltip]="fileClose?.name" [escape]="false" tooltipPosition="top"
                  tooltipStyleClass="toolTipClass">
                  <span class="p-filename">{{fileClose?.name}}</span>
                </div>
                <div class="col-md-1 col-sm-1 filenamewidth2" [pTooltip]="removeText" [escape]="false" tooltipPosition="top"
                  tooltipStyleClass="toolTipClass">
                  <!-- <button type="button" class="widgetButtoSmall attachButton"
                          (click)="removeFileClose(fileClose, createUploaderClose)"> X </button> -->
                  <i class="fa fa-window-close closebtn" aria-hidden="true"
                    (click)="removeFileClose(fileClose, createUploaderClose)"> </i>
                </div>
              </li>
            </ul>
          </ng-template>
        </p-fileUpload>
      </div>
    </div>
    <br />
    <div [hidden]="classDetailsNew">
      <div class="d-grid gap-2 d-md-flex justify-content-md-end btnStyle" *ngIf="!classDetails">
        <div>
          <button class="col-md-1" [pTooltip]="saveToolTipInfo" tooltipPosition="top"
            [disabled]="setButtonVisibility([8]) "
            [ngClass]="setButtonVisibility([8]) == true || !disableBtn['notSaveClass_issueClose']? 'widgetButtonSmall grayButton' : 'widgetButtonSmall'"
            tooltipStyleClass="toolTipClass" (click)="onSubmit($event)"
            [disabled]="!disableBtn['notSaveClass_issueClose']">
            {{ "ButtonSave" | translate }}
          </button>
        </div>
        <div>
          <button class="col-md-1" [pTooltip]="returnToROToolTipInfo" tooltipPosition="top"
            [disabled]="setButtonVisibility([8]) "
            [ngClass]="setButtonVisibility([8]) == true || !disableBtn['notReturnToRo_issueClose']? 'widgetButtonLarge grayButton' : 'widgetButtonLarge'"
            tooltipStyleClass="toolTipClass" (click)="showEmailTemplate()">
            {{ "ButtonRequestAdditionalInformation" | translate }}
          </button>
        </div>

        <div *ngIf="showCalendarDiv">
          <div>
            <button type="submit" class="col-md-1" [pTooltip]="execPendingBtnToolTipInfo"
              [disabled]="setButtonVisibility([8]) || !disableBtn['completed_issueClose']"
              [ngClass]="setButtonVisibility([8]) == true || !disableBtn['completed_issueClose'] ? 'widgetButtonLarge grayButton' : 'widgetButtonLarge'"
              tooltipPosition="top" tooltipStyleClass="toolTipClass" (click)="btnExecutionPending($event)"
              [hidden]="shouldHideButton" title="Use this button to confirm that execution is pending by the customer.">
              {{ btnNameChange }}
            </button>
          </div>
        </div>
        <div *ngIf="!showCalendarDiv">
          <div>
            <button class="col-md-1" [disabled]="setButtonVisibility([8,13])"
              [disabled]="!disableBtn['notShowCalComplete_issueClose']"
              [ngClass]="setButtonVisibility([8,13]) == true || !disableBtn['notShowCalComplete_issueClose']? 'widgetButtonLarge grayButton' : 'widgetButtonLarge'"
              id="btnComplete" [pTooltip]="completedBtnToolTipInfo" tooltipPosition="top"
              tooltipStyleClass="toolTipClass" (click)="btnCompleted()">
              {{ btnNameChange }}
            </button>
          </div>
        </div>
        <div *ngIf="changeBtnCompleted">
          <button (click)="btnCompleted()" class="col-md-1 widgetButtonSmall" id="btnComplete"
            [ngClass]="!disableBtn['notShowCalComplete_issueClose']? 'widgetButtonSmall grayButton' : 'widgetButtonSmall'"
            [pTooltip]="completedBtnToolTipInfo" tooltipPosition="top"
            [disabled]="!disableBtn['notShowCalComplete_issueClose']" tooltipStyleClass="toolTipClass">
            {{ "ButtonComplete" | translate }}
          </button>
        </div>
      </div>
    </div>

    <div class="d-grid gap-2 d-md-flex justify-content-md-end btnStyle" *ngIf="classDetails">
      <div>
        <button class="col-md-1 widgetButtonSmall" [pTooltip]="saveToolTipInfo" tooltipPosition="top"
          [ngClass]="!disableBtn['notSaveClass_issueClose']? 'widgetButtonSmall grayButton' : 'widgetButtonSmall'"
          [disabled]="!disableBtn['notSaveClass_issueClose']" tooltipStyleClass="toolTipClass"
          (click)="onSubmit($event)">
          {{ "ButtonSave" | translate }}
        </button>
      </div>
      <div>
        <button class="col-md-1 widgetButtonLarge" [pTooltip]="returnToROToolTipInfo" tooltipPosition="left"
          tooltipStyleClass="toolTipClass" [disabled]="!disableBtn['notReturnToRo_issueClose']"
          [ngClass]="!disableBtn['notReturnToRo_issueClose']? 'grayButton' : ''"
          (click)="showEmailTemplate()">
          {{ "ButtonRequestAdditionalInformation" | translate }}
        </button>
      </div>

      <div *ngIf="showCalendarDiv">
        <div>
          <button type="submit" class="col-md-1" [pTooltip]="execPendingBtnToolTipInfo"
            tooltipPosition="top" tooltipStyleClass="toolTipClass" (click)="btnExecutionPendingExternal($event)"
            [disabled]="!disableBtn['completed_issueClose']"
            [ngClass]="!disableBtn['completed_issueClose']? 'widgetButtonLarge grayButton' : 'widgetButtonLarge'"
            [hidden]="shouldHideButton" title="Use this button to confirm that execution is pending by the customer.">
            {{ btnNameChange }}
          </button>

        </div>
      </div>
      <div *ngIf="!showCalendarDiv">
        <div>
          <button class="col-md-1" id="btnComplete" [pTooltip]="completedBtnToolTipInfo"
            tooltipPosition="top"
            [ngClass]="!disableBtn['notShowCalComplete_issueClose']? 'widgetButtonLarge grayButton' : 'widgetButtonLarge'"
            [disabled]="!disableBtn['notShowCalComplete_issueClose']" tooltipStyleClass="toolTipClass"
            (click)="showClosureDialog()">
            {{ btnNameChange }}
          </button>

        </div>
      </div>
      <div *ngIf="changeBtnCompleted">
        <button (click)="showClosureDialog()" class="col-md-1 widgetButtonMedium" id="btnComplete"
          [pTooltip]="completedBtnToolTipInfo"
          [ngClass]="!disableBtn['notShowCalComplete_issueClose']? 'widgetButtonSmall grayButton' : 'widgetButtonSmall'"
          [disabled]="!disableBtn['notShowCalComplete_issueClose']" tooltipPosition="top"
          tooltipStyleClass="toolTipClass">
          Completed
        </button>

      </div>
    </div>

    <div class="d-grid gap-2 d-md-flex justify-content-md-end btnStyle" *ngIf="classDetailsNew">

      <div>
        <button class="col-md-1 widgetButtonSmall" [pTooltip]="saveToolTipInfo" tooltipPosition="top"
          tooltipStyleClass="toolTipClass" (click)="onSubmit($event)" [disabled]="isButtonDisabled">
          {{ "ButtonSave" | translate }}
        </button>
      </div>
      <div>
        <button class="col-md-1 widgetButtonLarge" [pTooltip]="returnToROToolTipInfo" tooltipPosition="top"
          tooltipStyleClass="toolTipClass" (click)="showEmailTemplate()" [disabled]="isButtonDisabled">
          {{ "ButtonRequestAdditionalInformation" | translate }}
        </button>
      </div>
      <div *ngIf="!showCalendarDiv">
        <div>
          <button class="col-md-1 widgetButtonLarge" id="btnComplete" [pTooltip]="completedBtnToolTipInfo"
            tooltipPosition="top"
            [ngClass]="!disableBtn['notShowCalComplete_issueClose']? 'grayButton' : ''"
            [disabled]="!disableBtn['notShowCalComplete_issueClose']" tooltipStyleClass="toolTipClass"
            (click)="showClosureDialog()" [disabled]="isButtonDisabled">
            {{ btnNameChange }}
          </button>
        </div>
      </div>
    </div>
  </div>
</form>
