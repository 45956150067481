import { Component, OnInit } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import { DynamicDialogRef,DialogService,DynamicDialogConfig } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-pg-category-pop-up',
  templateUrl: './pg-category-pop-up.component.html',
  styleUrls: ['./pg-category-pop-up.component.css']
})
export class PgCategoryPopUpComponent implements OnInit {
  PGRCACatData: any;
  filteredGEIDData: any;
  filterData: any;
  addressData: any;
  constructor(public ref: DynamicDialogRef,
    public dialogService: DialogService,
    private primengConfig: PrimeNGConfig,
    public config: DynamicDialogConfig) {
      this.PGRCACatData = this.config.data;
     }

  ngOnInit(): void {
    // console.log('data COngfig',this.PGRCACatData)
    this.filterData = this.PGRCACatData.processOwner.filter(value => value.geid == this.PGRCACatData.employeeInfo.id)
    // console.log('filterData',this.filterData)

    for (let i = 0; i < this.PGRCACatData.processOwner.length; i++)
   {
    for (let j = 0; j < this.PGRCACatData.employeeInfo.length; j++)
    {
     if(this.PGRCACatData.processOwner[i].geid == this.PGRCACatData.employeeInfo[j].id)
     {
      this.PGRCACatData.processOwner[i]["displayName"] = this.PGRCACatData.employeeInfo[j].name;
     }
    }

 

   }
  }
  popOver(displayName,geid){
    // console.log('displayName',displayName)
    // console.log('geidddd',geid)
    for (let i = 0; i < this.PGRCACatData.employeeInfo.length; i++)
    {
     
       if(this.PGRCACatData.employeeInfo[i].id == geid){
        this.addressData=this.PGRCACatData.employeeInfo[i];
        // console.log('address Data',this.addressData)
       }
    }
  }
  onClose() {
    this.ref.close();
  }
}
