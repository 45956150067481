import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-issue-capture-description',
  templateUrl: './issue-capture-description.component.html',
  styleUrls: ['./issue-capture-description.component.css']
})
export class IssueCaptureDescriptionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
