import { Injectable } from '@angular/core';
import { MdRegionService } from 'src/app/services/md-region.service';
import { MdServiceService } from 'src/app/services/md-service.service';
import { SessionStorageServiceService } from 'src/app/services/session-storage-service.service';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class ProcessOwnersAuthService {
  userInfo = this.sessionStorage.getData('userInfo');
  userScopes: any = [];

  constructor(private sessionStorage: SessionStorageServiceService,
    private mdData: MdServiceService,
    private mdRegionService: MdRegionService) {
    this.refreshUserInfoData(this.userInfo);
  }

  refreshUserInfoData(data) {
    this.userInfo = data;
    this.userScopes = [];
    if(data.processOwnerScopes){
    data.processOwnerScopes.forEach(poScope => {
      let userScope = {
        div: null,
        bus: [],
        pgs: [],
        region: null,
        countries: []
      };

      if (poScope.pg) {
        userScope.pgs.push(poScope.pg);
      }
      else if (poScope.bu) {
        userScope.bus.push(poScope.bu);
        this.mdData.pg().then( (pgs) => {
          pgs.forEach(p => {
            if (p.buCode == poScope.bu) {
              userScope.pgs.push(p.id);
            }
          });
        });
      }
      else if (poScope.div) {
        userScope.div = poScope.div;

        this.mdData.bu().then((bus)=> {
          bus.forEach(b => {
            if (b.divCode == poScope.div) {
              userScope.bus.push(b.id);
            }
          });
        });

        this.mdData.pg().then( (pgs)=> {
          pgs.forEach(p => {
            if (p.divCode == poScope.div) {
              userScope.pgs.push(p.id);
            }
          });
        });
      }

      if (poScope.region) {
        userScope.region = poScope.region;

        this.mdRegionService.countries().then((countries)=> {
          countries.forEach(c => {
            if (c.rCode == poScope.region) {
              userScope.countries.push(c.id);
            }
          });
        });
      }

      if (poScope.country) {
        userScope.countries.push(poScope.country);
      }

      this.userScopes.push(userScope);
    });
  }
  }

  canManageProcessOwner(poScope) {
    if (this.userInfo.hasGlobalPermissions) {
      return true;
    }

    if (!poScope.div && !poScope.bu && !poScope.pg && !poScope.region && !poScope.country) {
      return false;
    }
    let canManage = false;
    if (this.userScopes.length == 0) {
      return false;
    }

    if ((poScope.div || poScope.bu || poScope.pg) // unit po
      && (!poScope.region && !poScope.country)) {

      if (poScope.pg) {
        this.userScopes.forEach(us => {
          if (us.pgs && us.pgs.indexOf(poScope.pg) > -1 && us.countries.length == 0 && !us.region)
            canManage = true;
        });
      } else if (poScope.bu) {
        this.userScopes.forEach(us => {
          if (us.bus && us.bus.indexOf(poScope.bu) > -1 && us.countries.length == 0 && !us.region)
            canManage = true;
        });
      } else if (poScope.div) {
        this.userScopes.forEach(us => {
          if (us.div && us.div == poScope.div && us.countries.length == 0 && !us.region)
            canManage = true;
        });
      }
    }

    else if ((poScope.div || poScope.bu || poScope.pg) // unit local po
      && (poScope.region || poScope.country)) {
      if (poScope.pg) {
        this.userScopes.forEach(us => {
          if (us.pgs && us.pgs.indexOf(poScope.pg) > -1) {
            if (poScope.country && (us.countries.length == 0 || us.countries.indexOf(poScope.country) > -1)) {
              canManage = true;
            } else if (poScope.region && ((!us.region && (!us.countries || us.countries.length == 0)) || us.region == poScope.region)) {
              canManage = true;
            }
          }
        });
      }

      else if (poScope.bu) {
        this.userScopes.forEach(us => {
          if (us.bus && us.bus.indexOf(poScope.bu) > -1)
            if (poScope.country && (us.countries.length == 0 || us.countries.indexOf(poScope.country) > -1)) {
              canManage = true;
            } else if (poScope.region && ((!us.region && (!us.countries || us.countries.length == 0)) || us.region == poScope.region)) {
              canManage = true;
            }
        });
      }


      else if (poScope.div) {
        this.userScopes.forEach(us => {
          if (us.div && us.div == poScope.div) {
            if (poScope.country && (us.countries.length == 0 || us.countries.indexOf(poScope.country) > -1)) {
              canManage = true;
            } else if (poScope.region && ((!us.region && (!us.countries || us.countries.length == 0)) || us.region == poScope.region)) {
              canManage = true;
            }
          }
        });
      }


      if (!canManage) { // check permissions as local managers

        this.userScopes.forEach(us => {
          if (!us.div && (!us.bus || us.bus.length == 0) && (!us.pgs || us.pgs.length == 0)) {
            if (poScope.country && us.countries.length != 0 && us.countries.indexOf(poScope.country) > -1) {
              canManage = true;
            } else if (poScope.region && us.region && us.region == poScope.region) {
              canManage = true;
            }
          }
        });
      }

    } else if ((!poScope.div && !poScope.bu && !poScope.pg) //  local po
      && (poScope.region || poScope.country)) {
      if (poScope.country) {
        this.userScopes.forEach(us => {
          if (us.pgs.length == 0 && us.bus.length == 0 && !us.div && us.countries.indexOf(poScope.country) > -1) {
            canManage = true;
          }
        });
      } else if (poScope.region) {

        this.userScopes.forEach(us => {
          if (us.pgs.length == 0 && us.bus.length == 0 && !us.div && us.region == poScope.region) {
            canManage = true;
          }
        });
      }
    }
    return canManage;
  }

  canManageUnit(unitScope) {
    let canManage = false;

    if (this.userInfo.hasGlobalPermissions) {
      return true;
    }

    if (unitScope.pg) {
      this.userScopes.forEach(us => {
        if (us.pgs && us.pgs.indexOf(unitScope.pg) > -1)
          canManage = true;
      });
    } else if (unitScope.bu) {
      this.userScopes.forEach(us => {
        if (us.bus && us.bus.indexOf(unitScope.bu) > -1)
          canManage = true;
      });
    } else if (unitScope.div) {
      this.userScopes.forEach(us => {
        if (us.div && us.div == unitScope.div)
          canManage = true;
      });
    }
    return canManage;
  }

  canManageLocation(locationScope) {
    if (this.userInfo.hasGlobalPermissions) {
      return true;
    }
    let canManage = false;

    if (locationScope.countries && locationScope.countries.length > 0) {

      this.userScopes.forEach(us => {
        if (us.pgs.length == 0 && us.bus.length == 0 && !us.div && us.countries.indexOf(locationScope.countries[0]) > -1) {
          canManage = true;
        }
      });
    } else if (locationScope.regions && locationScope.regions.length > 0) {
      this.userScopes.forEach(us => {
        if (us.pgs.length == 0 && us.bus.length == 0 && !us.div && us.region == locationScope.regions[0]) {
          canManage = true;
        }
      });
    }
    return canManage;
  }

  getUserScopes() {
    return this.userScopes;
  }

  getUserGeid() {
    return this.userInfo.geid;
  }

  getDivScopes(div) {
    if (!div) {
      return _.filter(this.userScopes, function (us) { return us.div != null; });
    }
    return _.filter(this.userScopes, function (us) { return us.div == div; });
  }

  getBuScopes(bu) {
    if (!bu) {
      return _.filter(this.userScopes, function (us) { return us.bus.length > 0; });
    }
    return _.filter(this.userScopes, function (us) { return us.bus.indexOf(bu) > -1; });
  }

  getPgScopes(pg) {
    if (!pg) {
      return _.filter(this.userScopes, function (us) { return us.pg != null; });
    }
    return _.filter(this.userScopes, function (us) { return us.pgs.indexOf(pg) > -1; });
  }

}
