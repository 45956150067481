<form [formGroup]="acccounableAddtreeForm">
    <div class="addtreePopup">
        <div class="row div-align-items-right">
            <div class="col-sm-4 acccounableAddpopupLabel label-bold">
                <label for="employee">{{'rupersonadd_rucselectedrus_mod_lbl'|translate}}</label>
            </div>
            <div class="col-sm-8">
                <input readonly=true type="text" pInputText class="disabled form-control acccounableAddInput"
                    style="height: 30px; width: 100%;"
                    value=" {{data.cardinfo.balId}}, {{data.cardinfo.divCode}}, {{data.cardinfo.buCode}},{{data.cardinfo.pgCode}}, {{data.cardinfo.address.countryCode}} " />
            </div>
        </div>

        <div class="row mt-2 div-align-items-right">
            <div class="col-sm-4 acccounableAddpopupLabel label-bold">
                <label for="employee"> Selected Process steps</label>
            </div>
            <div class="col-sm-8">
                <input readonly=true type="text" pInputText class="disabled form-control acccounableAddInput"
                    style="height: 30px; width: 100%;" value="{{data.cardData.name}}" />
            </div>
        </div>
        <div class="form-group addtree ">
            <div class="row mt-2 div-align-items-right">
                <div class="col-sm-4 acccounableAddpopupLabel label-bold">
                    <label for="employee"> Accountable Person</label>
                </div>
                <div class="col-sm-8">
                    <!-- <p-dropdown formControlName="employee" [options]="filteredSubstitude" optionLabel="email"
                        optionValue="id" [filter]="true" [autofocus]="false" (onFilter)="filterSubstitude($event)"
                         [autofocusFilter]="false"></p-dropdown> -->
                        <span class="multiselect-field_remove select-customername ">
                            <p-autoComplete class="border-0 substituteCustom text-space " [forceSelection]="true"
                                formControlName="employee" [multiple]="true" type="text" [suggestions]="filteredHubPO"
                                [dropdownIcon]="'none'" (onSelect)="customerSelect($event)"
                                (onUnselect)="customerGuidsUnselect($event)"
                                (completeMethod)="filterHubProcessOwner($event)" field="name"
                                placeholder="Please enter 2 charactars">
                                <ng-template let-filteredHubPO pTemplate="item">
                                  <div class="flex align-items-center gap-2" style="font-size: 12px;">
                                        <div class="row">
                                          <div class="col-8"><strong class="autocompleteMail">{{ filteredHubPO?.name }} {{ filteredHubPO?.isActive ? '':'(Inactive)' }}</strong></div>
                                          <div class="col-4 text-end autocompleteCity"><i>{{filteredHubPO?.city}}({{filteredHubPO.iso}})</i></div>
                                        </div>
                                        <div class="row">
                                          <div class="col">{{filteredHubPO?.email}}</div>
                                        </div>
                                  </div>
                              </ng-template>
                            </p-autoComplete>
                        </span>

                </div>
            </div>
        </div>

        <div class="buton mt-2" style="text-align: right;">

     <button type="button" class="widgetButtonLarge "
                (click)="onClose() ">{{'admin_ru_addpeson_close_h'|translate |uppercase}}</button>
                <button type="button" class=" ms-1" [disabled]="acccounableAddtreeForm.invalid"
                [ngClass]="acccounableAddtreeForm.valid ? 'widgetButtonLarge' : ' widgetButtonLargeDisabled'"
                (click)="onClickAdd()">{{'poadd_mod_add_btn'|translate |uppercase}}</button>
        </div>


    </div>

</form>
