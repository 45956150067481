import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ActionPlanLogService {

  constructor(private http: HttpClient) { }

  getActionLogs(actionId: any) {
    let params = new HttpParams()
        .set('actionId', actionId)

    return this.http.get<any>(`${environment.azure.baseUrl}/api/v1/actionplan/log`, {params})
  }
}
