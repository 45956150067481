<div class="mainDiv customer-contact-section customer-contact-selection-pop">
  <div class="companyDetails">
    <form [formGroup]="contactDetailsForm">

      <!-- <div style="padding-left: 10%;">
          <h5>{{'SearchForm_CustomerContactSearchGrpBoxSearchCriteria' | translate}}</h5>
        </div> -->

      <div class="mt-4 ps-0">
        <div class="row g-3 mb-2">
          <div class="divColoumnSpan tagColumn"> {{ "CustomerContactSearch_Customer" | translate }}
            <span class="asteriskRequired"> </span>
          </div>

          <div class="col inputClass">
            <input type="text" class="form-control widgetTextBoxMedium largetext-width2"
              formControlName="selectedCutomer"
              [ngClass]="this.contactDetailsForm.controls['selectedCutomer'].invalid ? 'error-inputbox' : ''">
          </div>
        </div>
        <div class="row g-3  mb-2">

          <div class="divColoumnSpan tagColumn label">{{'EmployeeSearch_EmployeeName' | translate}}</div>
          <div class="col inputClass">
            <input type="text" class="form-control widgetTextBoxMedium " formControlName="Firstname" />
          </div>
          <div class="divColoumnSpan tagColumn">{{'EmployeeSearch_EmployeeSurname' | translate}}</div>
          <div class="col inputClass">
            <input type="text" class="form-control widgetTextBoxMedium textBoxLastName" name='Lastname'
              formControlName="Lastname" />
          </div>
        </div>
        <div class="row g-3 mb-2">
          <div class="divColoumnSpan tagColumn">{{'CreateCustomerContact_CustomerContactEmail' | translate}}</div>
          <div class="col inputClass">
            <input type="text" class="form-control widgetTextBoxMedium" formControlName="Email" />
          </div>
          <div class="divColoumnSpan tagColumn">{{'CreateCustomerContact_CustomerContactPhone' | translate}}</div>
          <div class="col inputClass">
            <input type="text" class="form-control widgetTextBoxMedium" name='Phone' formControlName="Phone" />
          </div>
        </div>
        <br />

      </div>
    </form>
  </div>

  <div class="d-grid gap-2 d-md-flex justify-content-md-end custom-modify-btn">
    <a class="customModifyCCRPLink" (click)="onModifyCCRPContacts()">{{'ButtonLinkModifyCustomerContact'|translate}}</a>
    <button class="input widgetButtoMediumView" type="button" (click)="CustomerContactDataView(0,0,true)">
      {{'ButtonSearch' | translate}}
    </button>
    <button class="input widgetButtoMediumReset" type="button" (click)="reset()">
      {{'rpt_cust_reset_btn' | translate}}
    </button>
  </div>

  <div class="tablediv">
    <h5 class="mx-2 mt-2">{{ "SearchForm_CustomerContactSearchGridHeadline" | translate }}</h5>
    <div class="tableMargin">
      <div class="card ">
        <p-table [value]="filteredCompanyDetails" styleClass="p-datatable-gridlines" selectionMode="single"
          [paginator]="true" [rows]="rows" [lazy]="true" [totalRecords]="totalRecords" [loading]="loading"
          (onLazyLoad)="loadMoreResolvingData($event)" [showCurrentPageReport]="true" [(first)]="first"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [(selection)]="selectedProductData">
          <ng-template pTemplate="header" styleClass="table table-bordered table-striped table-hove">
            <tr>
              <th pSortableColumn="id" style="width:20%">{{'CustomerContactSearch_GridColumn_Id' |
                translate}}<p-sortIcon field="id"></p-sortIcon></th>
              <th pSortableColumn="firstName" style="width:20%">{{'CustomerContactSearch_GridColumn_FirstName' |
                translate}}<p-sortIcon field="firstName"></p-sortIcon></th>
              <th pSortableColumn="lastName" style="width:20%">{{'CustomerContactSearch_GridColumn_Surname' |
                translate}}<p-sortIcon field="lastName"></p-sortIcon></th>
              <th pSortableColumn="email" style="width:20%">{{'CustomerContactSearch_GridColumn_Email' |
                translate}}<p-sortIcon field="email"></p-sortIcon></th>
              <th pSortableColumn="phone" style="width:20%">{{'CustomerContactSearch_CustomerContactPhone' |
                translate}}<p-sortIcon field="phone"></p-sortIcon></th>
              <th pSortableColumn="source" style="width:30%">{{'CustomerContactSearch_GridColumn_Source' |
                translate}}<p-sortIcon field="source"></p-sortIcon></th>
            </tr>
          </ng-template>

          <ng-template pTemplate="body" let-dt let-rowIndex="rowIndex">
            <tr [ngClass]="{selected: rowIndex == selectedIndex }" [pSelectableRow]="dt" [pSelectableRowIndex]="rowIndex"  (click)="selectedRow(rowIndex)">
              <td>{{dt.id}}</td>
              <td>{{dt.firstName}}</td>
              <td>{{dt.surname}}</td>
              <td>{{dt.email}}</td>
              <td>{{dt.phone}}</td>
              <td>{{dt.source}}</td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage" let-columns>
            <tr>
              <td [attr.colspan]="5">
                No records found
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="paginatorright">

            <p-button type="button" icon="pi pi-refresh" (click)="refresh()" styleClass="p-button-text"></p-button>
          </ng-template>
        </p-table>

      </div>
    </div>
  </div>

  <div class="modal-footer my-2">
    <button [disabled]="selectedIndex === -1" class="widgetButtonLargeSelectClose"
      [ngClass]="selectedIndex !==-1 ? '' : ' grayButton'"
      type=" button" (click)="selectAndClose()"> {{'ButtonSelectClose' | translate}}</button>
  </div>
</div>
