import { Injectable } from '@angular/core';
import { MdRegionService } from 'src/app/services/md-region.service';
import { MdServiceService } from 'src/app/services/md-service.service';
import { SessionStorageServiceService } from 'src/app/services/session-storage-service.service';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class HubAuthService {
  userInfo: any;
  userScopes: any = [];
  userGeid: any;

  constructor(private sessionStorage: SessionStorageServiceService,
    private mdData: MdServiceService,
    private mdRegionService: MdRegionService) {
      this.userInfo = this.sessionStorage.getData('userInfo');
      this.userGeid = this.userInfo.geid;
      this.refreshUserInfoData(this.userInfo);
    }

    refreshUserInfoData(data){
      this.mdRegionService.countries().then((countries)=>{
        this.userScopes = [];

        data.processOwnerScopes.forEach(poScope => {
          var userScope = {
            div: null,
            bus: [],
            pgs: [],
            countries: [],
            divMgr: null,
            pgMgr: null,
            buMgr: null
          };

          if (!poScope.div && !poScope.bu && !poScope.pg) {
            userScope.div = 'ALL';
            userScope.bus.push('ALL');
            userScope.pgs.push('ALL');
          }
          else if (poScope.pg){
            this.mdData.pg().then((pgs)=>{
              pgs.forEach(p => {
                if (p.id == poScope.pg) {
                  userScope.div = p.divCode;
                  userScope.bus.push(p.buCode);
                  userScope.pgs.push(p.id);
                }
              });
              userScope.pgMgr = true;
            });
          }
          else if (poScope.bu){
            this.mdData.bu().then((bus)=>{
              bus.forEach(b => {
                if (b.id == poScope.bu) {
                  userScope.bus.push(b.id);
                  userScope.div = b.divCode;
                  userScope.buMgr = true;
                }
              });
            })

            this.mdData.pg().then((pgs)=>{
              pgs.forEach(p => {
                if (p.buCode == poScope.bu) {
                  userScope.pgs.push(p.id);
                }
              });
            })
          }
          else if (poScope.div){
            userScope.div = poScope.div;
            userScope.divMgr = true;

            this.mdData.bu().then((bus)=>{
              bus.forEach(b => {
                if (b.divCode == poScope.div) {
                  userScope.bus.push(b.id);
                }
              });
            })

            this.mdData.pg().then((pgs)=>{
              pgs.forEach(p => {
                if (p.divCode == poScope.div) {
                  userScope.pgs.push(p.id);
                }
              });
            })
          }

          if (!poScope.region && !poScope.country) {
            userScope.countries.push('ALL');
          }
          else if (poScope.region){
            countries.forEach(c => {
              if (c.rCode == poScope.region) {
                userScope.countries.push(c.id);
              }
            });
          }
          else if (poScope.country) {
            userScope.countries.push(poScope.country);
          }
          this.userScopes.push(userScope);
        });
      })
    }

    checkIfUserIsOnlyRegionOrCountryPO(userScope, hubScope){
      return userScope.div === 'ALL' && userScope.bus.indexOf('ALL') > -1 && userScope.pgs.indexOf('ALL') > -1
      && ((hubScope.isGlobal && userScope.countries.indexOf('ALL') > -1) || (!hubScope.isGlobal && userScope.countries.indexOf('ALL') > -1) || (!hubScope.isGlobal && hubScope.country && (userScope.countries.indexOf('ALL') > -1 || userScope.countries.indexOf(hubScope.country) > -1)));
    }

    buWhere(hubScope, us){
      return ((!hubScope.bu && us.divMgr) ||
      (us.bus.indexOf('ALL') > -1) ||
      (hubScope.bu && (us.bus.indexOf('ALL') > -1 || us.bus.indexOf(hubScope.bu) > -1)));
    }

    pgWhere(hubScope, us){
      return ((!hubScope.pg && (us.divMgr || us.buMgr)) ||
      (us.pgs.indexOf('ALL') > -1) ||
      (hubScope.pg && (us.pgs.indexOf('ALL') > -1 || us.pgs.indexOf(hubScope.pg) > -1)));
    }

    globalWhere(hubScope, us){
      return ((hubScope.isGlobal && us.countries.indexOf('ALL') > -1) ||
      (!hubScope.isGlobal && us.countries.indexOf('ALL') > -1) ||
      (!hubScope.isGlobal &&
          hubScope.country &&
          (us.countries.indexOf('ALL') > -1 || us.countries.indexOf(hubScope.country) > -1)));
    }

    canManageHub(hubScope, hubProcessOwners){
      if (this.userInfo.hasGlobalPermissions) {
        return true;
      }

      if (hubProcessOwners.indexOf(this.userGeid) > -1) { //user is Ru Process Owner
        return true;
      }

      let canManage: any = [];

      if (hubScope.div){
        this.userScopes.forEach(us => {
          let isOnlyRegionOrCountryProcessOwner = this.checkIfUserIsOnlyRegionOrCountryPO(us, hubScope);
          if (!isOnlyRegionOrCountryProcessOwner){
            if (
              (us.div == 'ALL' || us.div == hubScope.div)
                  && this.buWhere(hubScope, us)
                  && this.pgWhere(hubScope, us)
                  && this.globalWhere(hubScope, us)
              ){
                  canManage.push(true);
              }
          }
        });
      }
      else if (hubScope.pg && hubScope.pg === 'ABB'){
        this.userScopes.forEach(us => {
          let isOnlyRegionOrCountryProcessOwner = this.checkIfUserIsOnlyRegionOrCountryPO(us, hubScope);
          if (!isOnlyRegionOrCountryProcessOwner && (us.countries.indexOf('ALL') > -1 || (hubScope.country && us.countries.indexOf(hubScope.country) > -1))) {
            canManage.push(true);
          }
        });
      }

      return canManage.length > 0;
    }


    canManageHubUsageInBu(bu){
      if (this.userInfo.hasGlobalPermissions) return true;

      let matchingInfos = _.find(this.userInfo.processOwnerScopes, 
        function(info){
          return (info.div === bu.divCode || info.bu === bu.buCode) && info.region === null && info.country === null;
        });

        return matchingInfos !== undefined;
    }

    canManageHubs(hubInfos){
      let canManage = true;

      hubInfos.forEach(hubInfo => {
        canManage = canManage && this.canManageHub(hubInfo.scope, hubInfo.hubProcessOwnerGeids);
      });

      return canManage;
    }

}
