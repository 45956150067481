import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  private sharedObj: any;
  private sharedSearchObj: any
  private sharedDeactivatedObject: any;
  private sharedEditableCustObject: any;
  private sharedEditableContactObject: any;
  private langFormValue:any;


  setObject(obj: any) {
    this.sharedObj = obj;
  }
  getObject(): any {
    return this.sharedObj;
  }

  setSearchObject(obj: any) {
    this.sharedSearchObj = obj;
  }
  getSearchObject(): any {
    return this.sharedSearchObj;
  }

  setDeactivatedObject(obj: any) {
    this.sharedDeactivatedObject = obj;
  }
  getDeactivatedObject(): any {
    return this.sharedDeactivatedObject;
  }

  setEditableCustomerObject(obj: any) {
    this.sharedEditableCustObject = obj;
  }
  getEditableCustomerObject(): any {
    return this.sharedEditableCustObject;
  }

  getCustomerData() {
    return this.sharedSearchObj;
  }
  private _enableDiv = new BehaviorSubject<boolean>(false);

  get enableDiv(): boolean {
    return this._enableDiv.getValue();
  }

  set enableDiv(value: boolean) {
    this._enableDiv.next(value);
  }

  get enableDiv$() {
    return this._enableDiv.asObservable()
  }

  setSearchContactObject(obj: any) {
    this.sharedEditableContactObject = obj;
  }
  getSearchContactObject(): any {
    return this.sharedEditableContactObject;
  }
  
  getlangFormValue():any {
    return this.langFormValue;
  }

  setlangFormValue(obj:any){
    return this.langFormValue = obj;
  }

  constructor() { }
}
