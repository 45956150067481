<div class="row pt-2 active-employee-section">
  <label class="col-sm-2 ps-0 pe-0 mt-1 labelStyle">Active Employees</label>
  <div  class="col-sm-3 ps-0 pe-0 testwer">
    <button class="btn widgetButtonMedium" *ngIf="!IsExcelGenerating"
      (click)="exportActiveEmployee()">Export to XLS</button>
    <button class="btn widgetButtonMedium" *ngIf="IsExcelGenerating"
      ><i class="fa fa-circle-o-notch fa-spin"></i>&nbsp;Generating...</button>
  
  </div>
</div>
