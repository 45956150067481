import { Component, Input, OnInit, ViewChild, AfterContentInit, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { PrimeNGConfig } from "primeng/api";
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ReopenIssuePopupComponent } from '../reopen-issue-popup/reopen-issue-popup.component';
import { DatePipe } from '@angular/common';
import { FileUpload } from 'primeng/fileupload';
import { formatDate } from '@angular/common';
import { MessageService } from 'primeng/api';
import { ComplaintDetailsService } from 'src/app/services/complaintDetails/complaint-details.service';
import { ComplaintStatuses, PermissionCode, RoleCode } from 'src/app/enum';
import { AuthorizationService } from 'src/app/services/authorization.service';
import { environment } from 'src/environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';
import { DownloadAttachmentService } from 'src/app/services/download-attachment.service';
import { maxLengthConstants } from 'src/app/reusables/constants/maxlength.constants';

@Component({
  selector: 'app-effectiveness-and-sustainability',
  templateUrl: './effectiveness-and-sustainability.component.html',
  styleUrls: ['./effectiveness-and-sustainability.component.css'],
  providers: [DialogService, MessageService]
})
export class EffectivenessAndSustainabilityComponent implements OnInit, AfterContentInit, OnChanges {

  @ViewChild("calendar", { static: false }) private calendar: any;
  @Input() formGroupName!: string;
  @Input() isSubmitted: boolean = false;
  @Input() issueCaptureMainForm: FormGroup;
  @Input() complaintData !: any;
  @Output() confirmedEventPassed = new EventEmitter();
  @Output() reopenIssueEventPassed = new EventEmitter();
  @Output() saveEventPassed = new EventEmitter();
  @Input() complaintValidations: any = [];
  @Input() ActionNameForComplaints: string = "ClosureConfirmation";
  @Input() fieldNameForFailureMode: string = "None";
  issueDetectedText: string;
  effectivenessForm!: FormGroup
  fromDate; toDate;
  disableSection: any;
  paramForAttachment: any;

  effectivenessCalendarText = "To extend the date, click on the Change button and pick the date from the Calendar. Please note that the date must be at least one month greater than the currently selected date. The date should be entered in the following format: YYYY-MM-DD."
  effectivenesYesText = "Select this option when the corrective/preventive action was effective"
  effectivenesNoText = "Select this option when the corrective/preventive action was not effective"
  effectivenesNAText = "Select this option when the Effectiveness and Sustainability Verificationof Corrective Actions is not required"
  effectivenesSaveText = "Saves the issue."
  effectivenesReopenText = "Use this button to revert the issue to &quot;In Process&quot; status. Please note that Target Resolution and Verification date will be automatically extended by 14 days, ROT and COT will be cleared."
  effectivenesconifirmText = " Use this button to confirm your action."

  //for date picker
  pipe = new DatePipe('en-US');
  now = new Date(Date.now());
  now1 = this.now.setMonth(this.now.getMonth() + 9)
  now2 = this.now.setMonth(this.now.getMonth() + 1)
  // myDate = new Date(Date.now());
  myDate1 = this.datePipe.transform(this.now1, 'yyyy-MM-dd')
  myDate2 = this.datePipe.transform(this.now2, 'yyyy-MM-dd')
  submitted = false;
  previewSubmittedData: string;
  effectivenessDeadlineDateNotValid = false;

  removeText = 'Remove'
  issueEffectivenessAttachment: any = [];
  uploadedAttachments: any = [];
  uploadedAttachmentsHolder: any = [];
  issueEffectivenessAttachmentMerged: any = [];


  enumPermission = PermissionCode;
  enumRoleCode = RoleCode;
  enumComplaintStatuses = ComplaintStatuses;
  userAccessData = null;
  disableBtn: any = {};
  @Input() role : any;
  isSupportDesk : any;
  baseurl = environment.azure.baseUrl;
  downloadAllUrl = environment.azure.baseUrl+'api/v1/Attachment/downloadmany?storeFilesNames=';
  readonly maxLength = maxLengthConstants;

  constructor(private fb: FormBuilder,
    private primengConfig: PrimeNGConfig,
    private dialogService: DialogService,
    private rootFormGroup: FormGroupDirective,
    private ref: DynamicDialogRef,
    private datePipe: DatePipe,
    private messageService: MessageService,
    public authorizationService: AuthorizationService,
    private complaintDetailsService: ComplaintDetailsService,
    private spinner: NgxSpinnerService,
    private downloadAttachementService:DownloadAttachmentService
  ) { }

  ngOnInit(): void {
    this.paramForAttachment = { 0: '60 MB' };
    this.issueDetectedText = 'Select from tree in which process step was the issue detected.';

    this.effectivenessForm = this.rootFormGroup.control?.get(this.formGroupName) as FormGroup;

    this.effectivenessForm?.controls['DeadlineDate']?.valueChanges.subscribe((changedValue) => {
      if (changedValue) {
        const todayDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
        const updatedValue = this.datePipe.transform(changedValue, 'yyyy-MM-dd');
        this.effectivenessForm?.controls['effectiveChangedValue'].patchValue(new Date(updatedValue));
        const laterOneMonth = new Date(changedValue);
        const monthDate = this.datePipe.transform(laterOneMonth, 'yyyy-MM-dd');
        const d1 = new Date(this.datePipe.transform(new Date(), 'MM/dd/yyyy'));
        const d2 = new Date(this.datePipe.transform(laterOneMonth, 'MM/dd/yyyy'));

        const diffTime = d2.getTime() - d1.getTime();

        const diffDays = diffTime / (1000 * 60 * 60 * 24);

        if (diffDays < 30 && diffDays > 0) {
          this.effectivenessDeadlineDateNotValid = true
          this.effectivenessForm?.controls['DeadlineDate'].setErrors({ 'incorrect': true });
        } else {
          this.effectivenessDeadlineDateNotValid = false
          this.effectivenessForm?.controls['DeadlineDate'].setErrors(null);
        }

      }
    })

    this.authorizationService.userAccessDetail.subscribe(async detail => {
      this.userAccessData = detail;
      this.attachmentDisabled();
      this.authorizationBtnDisable();

      if (this.userAccessData && this.userAccessData['statusCode'] === this.enumComplaintStatuses.Completed &&
        this.complaintData && !this.complaintData?.areaComplaintEffectiveness?.isReadOnly) {
        this.effectivenessForm.controls['DeadlineDate'].enable();
        this.effectivenessForm.controls['IsEffective'].enable();
        this.effectivenessForm.controls['EffectivenessReason'].enable();
        this.disableBtn['file_upload_btn'] = false;
      }
      else if (this.userAccessData && this.userAccessData['statusCode'] === this.enumComplaintStatuses.Completed &&
      this.complaintData && this.complaintData?.areaComplaintEffectiveness?.isReadOnly){
        this.effectivenessForm.controls['DeadlineDate'].disable();
        this.effectivenessForm.controls['IsEffective'].disable();
        this.effectivenessForm.controls['EffectivenessReason'].disable();
        this.disableBtn['file_upload_btn'] = true;
      }

    });


  }
  changeIsEffective(){
    this.setFieldsValidation(this.ActionNameForComplaints);
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.setFieldsValidation(this.ActionNameForComplaints);
    this.isSupportDesk = this.role == "Support Desk" ? true : false;
  }

  ngAfterContentInit() {
    // console.log("++++",this.complaintData,this.effectivenessForm,this.complaintData?.areaComplaintEffectiveness?.attachments);
    if (this.complaintData != undefined && this.effectivenessForm) {
      let isEffectivnessOption;
      if (this.complaintData?.areaComplaintEffectiveness?.isEffective == 2) {
        isEffectivnessOption = 'NotApplicable'
      } else if (this.complaintData?.areaComplaintEffectiveness?.isEffective == 0) {
        isEffectivnessOption = 'Yes'
      } else if (this.complaintData?.areaComplaintEffectiveness?.isEffective == 1) {
        isEffectivnessOption = 'No'
      }
      this.uploadedAttachments = this.complaintData?.areaComplaintEffectiveness?.attachments;
      this.uploadedAttachmentsHolder = this.complaintData?.areaComplaintEffectiveness?.attachments;
      this.issueEffectivenessAttachmentMerged = this.complaintData?.areaComplaintEffectiveness?.attachments;
      this.effectivenessForm.patchValue({
        id: this.checkBlank(this.complaintData?.areaComplaintEffectiveness?.id),
        IsEffective: this.checkBlank(isEffectivnessOption),
        DeadlineDate: this.checkBlank(this.complaintData?.areaComplaintEffectiveness?.deadlineDate?.split("T", 1)[0]),
        EffectivenessReason: this.checkBlank(this.complaintData?.areaComplaintEffectiveness?.effectivenessReason),
        uploadedEffectivenessAttachments: this.issueEffectivenessAttachmentMerged
      });
      this.effectivenessCalendarText = 'Local: ' + formatDate(new Date(this.effectivenessForm.controls['DeadlineDate'].value), 'yyyy-MM-dd ', 'en');
      let attachmentData = {storFileName: '',displayName: ''};
      this.uploadedAttachments.forEach(attachments => {
        attachmentData.storFileName = attachmentData?.storFileName+(attachmentData?.storFileName=='' ? '' : '_&_')+attachments?.storeFileName;
        attachmentData.displayName = attachmentData?.displayName+(attachmentData?.displayName=='' ? '' : '_&_')+attachments?.displayName;
      });
      this.downloadAllUrl = this.downloadAllUrl+encodeURIComponent(attachmentData?.storFileName)+'&displayNames='+encodeURIComponent(attachmentData?.displayName);


    }
    this.setFieldsValidation(this.ActionNameForComplaints);
  }

  attachmentDisabled() {
    if (this.userAccessData) {
      switch (this.userAccessData['roleCode']) {
        case this.enumRoleCode.Complaint_Owner:
          this.disableSection = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [8], [311], this.userAccessData);
          break;
        case this.enumRoleCode.Complaint_Coordinator:
          this.disableSection = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [8], [311], this.userAccessData);
          break;
        case this.enumRoleCode.Global_CCRP_Process_Owner:
          this.disableSection = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [8], [311], this.userAccessData);
          break;
        case this.enumRoleCode.Support_Desk:
          this.disableSection = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [8], [311], this.userAccessData);
          break;
        case this.enumRoleCode.Complaint_Owner_Supervisor:
          this.disableSection = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [8], [311], this.userAccessData);
          break;
        case this.enumRoleCode.Complaint_Owner_Team:
          this.disableSection = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [8], [311], this.userAccessData);
          break;
        case this.enumRoleCode.Resolution_Owner:
          this.disableSection = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [8], [311], this.userAccessData);
          break;
        case this.enumRoleCode.Resolution_Owner_Team:
          this.disableSection = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [8], [311], this.userAccessData);
          break;
        case this.enumRoleCode.Ru_Process_Owner:
          this.disableSection = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [8], [311], this.userAccessData);
          break;
        case this.enumRoleCode.Resource_Editor:
          this.disableSection = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [8], [311], this.userAccessData);
          break;
        case this.enumRoleCode.Div_Process_Owner:
          this.disableSection = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [8], [311], this.userAccessData);
          break;
        case this.enumRoleCode.Bu_Process_Owner:
          this.disableSection = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [8], [311], this.userAccessData);
          break;
        case this.enumRoleCode.Pg_Process_Owner:
          this.disableSection = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [8], [311], this.userAccessData);
          break;
        case this.enumRoleCode.Hub_Process_Owner:
          this.disableSection = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [8], [311], this.userAccessData);
          break;
      }
      return this.disableSection
    }
  }

  authorizationBtnDisable() {
    if(this.complaintData?.sapComplaintInfo?.sapLockStatus == true && this.complaintData?.sapComplaintInfo?.isQnNumberGenerated == true){
      this['confirm_effectiveness'] = true;
      this['reOpen_effectiveness'] = true;
      this['saveText_effectiveness'] = true;
      return;
    }
    if (this.userAccessData) {
      switch (this.userAccessData['roleCode']) {
        case this.enumRoleCode.Complaint_Owner:
          this.disableBtn['confirm_effectiveness'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [8], [311], this.userAccessData);
          this.disableBtn['reOpen_effectiveness'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [8], [311], this.userAccessData);
          this.disableBtn['saveText_effectiveness'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [8], [311], this.userAccessData);
          break;
        case this.enumRoleCode.Complaint_Owner_Supervisor:
          this.disableBtn['confirm_effectiveness'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [8], [311], this.userAccessData);
          this.disableBtn['reOpen_effectiveness'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [8], [311], this.userAccessData);
          this.disableBtn['saveText_effectiveness'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [8], [311], this.userAccessData);
          break;
        case this.enumRoleCode.Complaint_Coordinator:
          this.disableBtn['confirm_effectiveness'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [8], [311], this.userAccessData);
          this.disableBtn['reOpen_effectiveness'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [8], [311], this.userAccessData);
          this.disableBtn['saveText_effectiveness'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [8], [311], this.userAccessData);
          break;
        case this.enumRoleCode.Resolution_Owner:
          this.disableBtn['confirm_effectiveness'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [8], [311], this.userAccessData);
          this.disableBtn['reOpen_effectiveness'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [8], [311], this.userAccessData);
          this.disableBtn['saveText_effectiveness'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [8], [311], this.userAccessData);
          break;
        case this.enumRoleCode.Support_Desk:
          this.disableBtn['confirm_effectiveness'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [8], [311], this.userAccessData);
          this.disableBtn['reOpen_effectiveness'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [8], [311], this.userAccessData);
          this.disableBtn['saveText_effectiveness'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [8], [311], this.userAccessData);
          break;
        case this.enumRoleCode.Complaint_Owner_Team:
          this.disableBtn['confirm_effectiveness'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [8], [311], this.userAccessData);
          this.disableBtn['reOpen_effectiveness'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [8], [311], this.userAccessData);
          this.disableBtn['saveText_effectiveness'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [8], [311], this.userAccessData);
          break;
        case this.enumRoleCode.Resolution_Owner_Team:
          this.disableBtn['confirm_effectiveness'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [8], [311], this.userAccessData);
          this.disableBtn['reOpen_effectiveness'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [8], [311], this.userAccessData);
          this.disableBtn['saveText_effectiveness'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [8], [311], this.userAccessData);
          break;
        case this.enumRoleCode.Bu_Process_Owner:
          this.disableBtn['confirm_effectiveness'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [8], [311], this.userAccessData);
          this.disableBtn['reOpen_effectiveness'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [8], [311], this.userAccessData);
          this.disableBtn['saveText_effectiveness'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [8], [311], this.userAccessData);
          break;
        case this.enumRoleCode.Pg_Process_Owner:
          this.disableBtn['confirm_effectiveness'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [8], [311], this.userAccessData);
          this.disableBtn['reOpen_effectiveness'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [8], [311], this.userAccessData);
          this.disableBtn['saveText_effectiveness'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [8], [311], this.userAccessData);
          break;
        case this.enumRoleCode.Hub_Process_Owner:
          this.disableBtn['confirm_effectiveness'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [8], [311], this.userAccessData);
          this.disableBtn['reOpen_effectiveness'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [8], [311], this.userAccessData);
          this.disableBtn['saveText_effectiveness'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [8], [311], this.userAccessData);
          break;
        case this.enumRoleCode.Global_CCRP_Process_Owner:
          this.disableBtn['confirm_effectiveness'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [8], [311], this.userAccessData);
          this.disableBtn['reOpen_effectiveness'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [8], [311], this.userAccessData);
          this.disableBtn['saveText_effectiveness'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [8], [311], this.userAccessData);
          break;
        case this.enumRoleCode.Div_Process_Owner:
          this.disableBtn['confirm_effectiveness'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [8], [311], this.userAccessData);
          this.disableBtn['reOpen_effectiveness'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [8], [311], this.userAccessData);
          this.disableBtn['saveText_effectiveness'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [8], [311], this.userAccessData);
          break;
      }
    }
  }

  checkBlank(content) {
    if (content == undefined || content == null) {
      return '';
    }
    else {
      return content;
    }
  }

  save() {
    if (this.effectivenessForm.valid) {
      this.previewSubmittedData = JSON.stringify(this.effectivenessForm.value)
    }
  }


  onSubmit($event: Event) {
    if (this.effectivenessForm.valid) {
      this.previewSubmittedData = JSON.stringify(this.effectivenessForm.value)
    }
    this.submitted = true;
    this.saveEventPassed.emit({ submitted: true })
    // console.log(this.effectivenessForm.value);
    if (this.issueCaptureMainForm.invalid) {
      // console.log('----', this.issueCaptureMainForm);
      $event.preventDefault();
    }

  }
  get fectch() {
    return this.effectivenessForm.controls;
  }
  Confirmed($event: Event) {
    //this.messageService.add({ severity: 'success', summary: 'Issue was succesfully saved', detail: '' });
    // code for Error msg (the field is mandatory to fill)
    this.isSubmitted = true;
    // this.effectivenessForm.disable;
    this.confirmedEventPassed.emit({ submitted: true })

  }
  openCalendar(event: any, check: any) {
    this.calendar.inputfieldViewChild.nativeElement.click();
    event.stopPropagation();
    this.effectivenessCalendarText = this.effectivenessForm.controls['DeadlineDate'].value;
  }
  effectivenessDateOnSelect() {
    this.effectivenessCalendarText = 'Local: ' + formatDate(new Date(this.effectivenessForm.controls['DeadlineDate'].value), 'yyyy-MM-dd ', 'en');
  }
  // for Reopen issue button and opens reopenpopup
  reopenIssue($event: Event) {

    this.ref = this.dialogService.open(ReopenIssuePopupComponent, {
      header: 'Reopen Issue',
      width: '40%',
      position: 'center',
      keepInViewport: true,
      autoZIndex: true,
      styleClass: 'reopen-issue-pop'
    });
    this.isSubmitted = false;
    this.ref.onClose.subscribe((receivedValue: any) => {
      if (receivedValue) {
        this.effectivenessForm.patchValue({
          reopenReason: receivedValue
        });
        this.isSubmitted = true;
        this.reopenIssueEventPassed.emit({ submitted: true })

      }
    })


    //  this.ref.onClose.subscribe((result) => {

    //           if (result) {

    //             this.messageService.add({ severity: 'success', summary: 'Message has been sent!' });

    //           }

    //         })

  }


  /*function setDate(arg0: any) {
    throw new Error('Function not implemented.');
  }

  function Save() {
    throw new Error('Function not implemented.');
  }*/


  myUploaderEffectiveness(createUploader, event) {
    let section = "issue-resolution";
    // console.log("------length=>",event.files.length);
    for (var i = 0; i < event.files.length; i++) {
      if (i >= this.issueEffectivenessAttachment?.length) {
        this.spinner.show();
        let formData = new FormData();
        formData.append("files", event.files[i]);
        this.complaintDetailsService.issueCaptureAttachmentSave(section, formData).subscribe((response: any) => {
          let attachIdChange = response[0];
          attachIdChange.id = (attachIdChange?.id)?.toString();
          // console.log("attachIdChange==>",attachIdChange);
          this.issueEffectivenessAttachment.push(attachIdChange);
          // console.log("issueEffectivenessAttachment=====>",this.issueEffectivenessAttachment);
          this.issueEffectivenessAttachmentMerged = [...this.uploadedAttachmentsHolder, ...this.issueEffectivenessAttachment];
          this.effectivenessForm.patchValue({
            uploadedEffectivenessAttachments: this.issueEffectivenessAttachmentMerged
          });
          this.spinner.hide();
        },
        (error) => {
            this.spinner.hide();
            this.messageService.add({
                severity: 'error',
                summary: error ? error : 'Something went wrong!'
            });
        });
      }
    }
  }

  uploadedAttachmentRemove(attachmentIndex) {
    // console.log("Effectiveness uploadedAttachmentRemove=>",(this.uploadedAttachmentsHolder.findIndex(attachment => attachment.id == this.uploadedAttachments[attachmentIndex]?.id)));
    if ((this.uploadedAttachmentsHolder.findIndex(attachment => attachment.id == this.uploadedAttachments[attachmentIndex]?.id)) == -1) {
      this.uploadedAttachmentsHolder.push(this.uploadedAttachments[attachmentIndex]);
    }
    else {
      this.uploadedAttachmentsHolder = this.uploadedAttachmentsHolder?.filter(attachment => attachment.id !== this.uploadedAttachments[attachmentIndex]?.id);
    }
    this.issueEffectivenessAttachmentMerged = [...this.uploadedAttachmentsHolder, ...this.issueEffectivenessAttachment];
    this.effectivenessForm.patchValue({
      uploadedEffectivenessAttachments: this.issueEffectivenessAttachmentMerged
    });
  }

  checkRemoved(attachmentIndex) {
    //  console.log("Effectiveness checkRemoved=>",(this.issueEffectivenessAttachment.findIndex(attachment => attachment.id == this.uploadedAttachments[attachmentIndex]?.id)));
    if ((this.uploadedAttachmentsHolder.findIndex(attachment => attachment.id == this.uploadedAttachments[attachmentIndex]?.id)) == -1) {
      return true;
    }
    else {
      return false;
    }
  }

  removeFileEffectiveness(file: File, uploader: FileUpload) {
    const index = uploader.files.indexOf(file);
    // console.log("index",index,uploader.files);
    uploader.remove(event, index);
    let filename = [this.issueEffectivenessAttachment[index]?.storeFileName];
    // console.log("filename=> ",filename);
    this.complaintDetailsService
      .removeAttachments(filename)
      .subscribe((res) => {
        // console.log("filter=> ",this.issueEffectivenessAttachment,filename);
        this.issueEffectivenessAttachment = this.issueEffectivenessAttachment.filter(attachment => attachment?.storeFileName !== filename[0]);
        this.issueEffectivenessAttachmentMerged = [...this.uploadedAttachmentsHolder, ...this.issueEffectivenessAttachment];
        this.effectivenessForm.patchValue({
          uploadedEffectivenessAttachments: this.issueEffectivenessAttachmentMerged
        });
      });
  }
  setFieldsValidation(action) {
   //  console.log("if")
    for (const controlName in this.effectivenessForm?.controls) {
      let validationData = this.complaintValidations.find(complaintValidation => complaintValidation.property?.toLowerCase() == controlName?.toLowerCase() && complaintValidation.complaintAction == action && complaintValidation?.complaintStatusIdsArray?.includes(this.issueCaptureMainForm?.get('headerSection').get('complaintStatusId')?.value?.toString()) && (this.userAccessData == null ? this.userAccessData == null : complaintValidation?.roleIdsArray?.includes(this.userAccessData!['roleCode']?.toString())))
      // console.log(this.complaintValidations,validationData,controlName);
      if (validationData) {
        // console.log(">>>>",this.issueCapture);
        const control = this.effectivenessForm.get(controlName);
        const validationRules = this.complaintValidations.filter(complaintValidation => complaintValidation.property?.toLowerCase() == controlName?.toLowerCase() && complaintValidation.complaintAction == action && complaintValidation?.complaintStatusIdsArray?.includes(this.issueCaptureMainForm?.get('headerSection').get('complaintStatusId')?.value?.toString()) && (this.userAccessData == null ? this.userAccessData == null : complaintValidation?.roleIdsArray?.includes(this.userAccessData!['roleCode']?.toString())));

        control.setValidators(this.getValidators(validationRules, this.fieldNameForFailureMode));
        control.updateValueAndValidity(); // Update the validation status
      }
      else {
        // console.log("else for: ", controlName);
        this.effectivenessForm.get(controlName).clearValidators();
        this.effectivenessForm.get(controlName).updateValueAndValidity();
      }
    }
  }
  private getValidators(rules: any[], fieldFailureModeValidation): any {
    let validators: Validators[] = [];
let index = 0;
    for (const rule of rules) {
      index = index+1;
        // console.log("<><><><<><>",rule.property,rule.depends,this.issueCapture.controls![rule.depends]?.value?.toString()?.toLowerCase() , rule.value?.toLowerCase() , this.issueDescription.controls![rule.depends]?.value?.toString()?.toLowerCase() , rule.value?.toLowerCase(), this.issueDescription.controls![rule.depends]?.value?.toString()?.toLowerCase() == rule.value?.toLowerCase() , this.issueRating.controls![rule.depends]?.value?.toString()?.toLowerCase() , rule.value?.toLowerCase() , this.additionalSubject.controls![rule.depends]?.value?.toString()?.toLowerCase() , rule.value?.toLowerCase())
        if (rule.depends != null) {
          // console.log("<><><><<><>",rule.property,rule.depends,this.issueCapture.controls![rule.depends]?.value?.toString()?.toLowerCase() , rule.value?.toLowerCase() , this.issueDescription.controls![rule.depends]?.value?.toString()?.toLowerCase() , rule.value?.toLowerCase(), this.issueDescription.controls![rule.depends]?.value?.toString()?.toLowerCase() == rule.value?.toLowerCase() , this.issueRating.controls![rule.depends]?.value?.toString()?.toLowerCase() , rule.value?.toLowerCase() , this.additionalSubject.controls![rule.depends]?.value?.toString()?.toLowerCase() , rule.value?.toLowerCase())
          if (fieldFailureModeValidation != "None" && rule.depends == "Validation" && rule.value == fieldFailureModeValidation) {
            validators.push(Validators.required);
          //  console.log("=======>>", rule.property, rule.depends, rule.value,);
            return validators;
          }
          else if ((this.issueCaptureMainForm.controls["issueCaptureForm"].get(rule.depends)?.value != undefined && this.issueCaptureMainForm.controls["issueCaptureForm"].get(rule.depends)?.value?.toString()?.toLowerCase() == rule.value?.toLowerCase()) || (this.issueCaptureMainForm.controls["issueDescriptionForm"].get(rule.depends)?.value != undefined && this.issueCaptureMainForm.controls["issueDescriptionForm"].get(rule.depends)?.value?.toString()?.toLowerCase() == rule.value?.toLowerCase()) || (this.issueCaptureMainForm.controls["ratingForm"].get(rule.depends)?.value != undefined && this.issueCaptureMainForm.controls["ratingForm"].get(rule.depends)?.value?.toString()?.toLowerCase() == rule.value?.toLowerCase()) || (this.issueCaptureMainForm.controls["additionalSubjectForm"].get(rule.depends)?.value != undefined && this.issueCaptureMainForm.controls["additionalSubjectForm"].get(rule.depends)?.value?.toString()?.toLowerCase() == rule.value?.toLowerCase()) || (this.issueCaptureMainForm.controls["issueResolutionForm"].get(rule.depends)?.value != undefined && this.issueCaptureMainForm.controls["issueResolutionForm"].get(rule.depends)?.value?.toString()?.toLowerCase() == rule.value?.toLowerCase()) || (this.issueCaptureMainForm.controls["issueAssignmentForm"].get(rule.depends)?.value != undefined && this.issueCaptureMainForm.controls["issueAssignmentForm"].get(rule.depends)?.value?.toString()?.toLowerCase() == rule.value?.toLowerCase()) || (this.issueCaptureMainForm.controls["issueClosedForm"].get(rule.depends)?.value != undefined && this.issueCaptureMainForm.controls["issueClosedForm"].get(rule.depends)?.value?.toString()?.toLowerCase() == rule.value?.toLowerCase()) || (this.issueCaptureMainForm.controls["effectivenessForm"].get(rule.depends)?.value != undefined && this.issueCaptureMainForm.controls["effectivenessForm"].get(rule.depends)?.value?.toString()?.toLowerCase() == rule.value?.toLowerCase())) {
            validators.push(Validators.required);
          //  console.log("=======", rule.property, rule.depends, rule.value,);
            return validators;
          }
          else {
          //  console.log("======= blank", validators);
          if(index==rules.length){
            return validators;
           }
          }
        }
        else {
          validators.push(Validators.required);
          return validators;
        }
      }
    }

  downloadAttachementFromAPI(storeFileName, displayName) {
    this.downloadAttachementService.downloadAttachement(storeFileName, displayName);
  }
  }
