<!-- <div> -->
    <form [formGroup]="emailForm">
        <div class="container">
            <div class="row mb-3">
                <label for="colFormLabelSm" class="col-sm-1 tagColumn col-form-label-sm">{{ "CreateEmail_EmailTo" | translate }}</label>
                <div class="col-sm-8">
                    <!-- <p-autoComplete formControlName="to" [suggestions]="emailList"
                        (completeMethod)="onRecipientChange($event)" field="promptLabel"
                        [multiple]="true"></p-autoComplete> -->
                  <input  type="text" class="form-control form-control-sm disabled" formControlName="to"
                  (completeMethod)="onRecipientChange($event)" field="promptLabel"
                        [multiple]="true" readonly="true">

                </div>
            </div>
            <div class="row mb-3">
                <label for="colFormLabelSm" class="col-sm-1 tagColumn col-form-label-sm">{{ "CreateEmail_EmailCC" | translate }}</label>
                <div class="col-sm-8">
                    <!-- <p-autoComplete formControlName="cc" [suggestions]="emailList"
                        (completeMethod)="onRecipientChange($event)" field="promptLabel"
                        [multiple]="true"></p-autoComplete> -->
                        <input type="text" class="form-control form-control-sm " formControlName="cc"
                        (completeMethod)="onRecipientChange($event)" field="promptLabel"
                              [multiple]="true">
                </div>
            </div>
            <div class="row mb-3">
                <label for="colFormLabelSm" class="col-sm-1 tagColumn col-form-label-sm">{{ "CreateEmail_EmailSubject" | translate }}</label>
                <div class="col-sm-8">
                    <input class="form-control form-control-sm" formControlName="subject">
                </div>
            </div>
            <div class="row mb-3">
                <label for="colFormLabelSm" class="col-sm-1 tagColumn col-form-label-sm">Template</label>
                <div class="col-sm-8"><select class="form-select form-control form-control-sm"
                        aria-label="Default select example" formControlName="template" (change)="template($event)">
                        <option value="4">New issue returned by Resolving Unit - Urgent attention required</option>
                        <option value="11" selected>Solution for issue returned for further action</option>
                        <option value="16">Solution has been rejected by Process Owner/Coordinator</option>
                        <option value="41">Issue reverted from InProcess to NotAssigned status</option>
                    </select>
                </div>
            </div>
            <div class="col-sm-11">
                <fieldset class="border p-2">
                    <legend class="float-none w-auto p-2 widgetFieldsetLegendSmall">{{ "ComplaintForm_AttachmentsSectionName" | translate }}
                    </legend>
                    <!-- {{ "ComplaintForm_SpecifyAttachment" | translate }} need to update below-->
                    <label for="myfile">{{ "ComplaintForm_SpecifyAttachment" | translate:paramForAttachment }}</label>
                    <input type="file" id="myfile" name="myfile" formControlName="attachment">
                    <input *ngIf="emailForm.get('attachment')?.value" type="reset" value="Remove">
                </fieldset>
            </div><br>
            <p-editor formControlName="textareaBody" [style]="{height: '150px'}"> </p-editor>
        </div>
    </form>
    <pre>{{emailForm.value | json}}</pre>
    <div class="d-grid gap-2 d-md-flex justify-content-md-end">
        <p-toast position="top-left"></p-toast>

        <button class="input widgetButtonMedium p-button-success" type="button" (click)="sendMail()"
            label="Top Left">{{ "CreateEmailForm_SendEmailDialogTitle" | translate }}</button>
        <button class="input widgetButtonMedium" type="button" (click)="onClose()">{{ "FormExitWithoutSave" | translate }}</button>
    </div>
<!-- </div> -->
