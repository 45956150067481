import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TreeNode } from 'primeng/api';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class ProductService {
  constructor(private http: HttpClient) {}

  getProduct(onlyActive:boolean = false , resolvingUnitId) {
    return this.http.get(`${environment.azure.baseUrl}api/v1/Data/GetProductClassesRoots?onlyActive=${onlyActive}&resolvingUnitId=${resolvingUnitId}`);
  }

  getProductClasses(productObj){
    return this.http.post(`${environment.azure.baseUrl}api/v1/Data/GetProductClasses?cid=${productObj.cid}&onlyActive=${productObj.onlyActive}&resolvingUnitId=${productObj.resolvingUnitId}`, productObj);
  }

  getFilteredProduct(productObj) {
    return this.http.post(`${environment.azure.baseUrl}api/v1/Data/SearchProductClassesBy?textFilter=${productObj?.textFilter}&onlyActive=${productObj.onlyActive}&onlyWithPg=${productObj.onlyWithPg}&resolvingUnitId=${productObj.resolvingUnitId}`, productObj);
  }
}
