import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class ExceptionsService {

  constructor(private http: HttpClient) { }

  checkActionResult(): Observable<any> {
    return this.http.get(`${environment.azure.baseUrl}api/v1/Exceptions/checkactionresult`);
  }

  notAuthenticated(): Observable<any> {
    return this.http.get(`${environment.azure.baseUrl}api/v1/Exceptions/notauthenticated`);
  }

  notAllowedOperation(): Observable<any> {
    return this.http.get(`${environment.azure.baseUrl}api/v1/Exceptions/notallowedoperation`);
  }

  confidentialNotAuthorizedOperation(): Observable<any> {
    return this.http.get(`${environment.azure.baseUrl}api/v1/Exceptions/confidentialnotauthorizedoperation`);
  }

  resourceNoLongerExist(): Observable<any> {
    return this.http.get(`${environment.azure.baseUrl}api/v1/Exceptions/resourcenolongerexist`);
  }

  html404(): Observable<any> {
    return this.http.get(`${environment.azure.baseUrl}api/v1/Exceptions/html404`);
  }

  invalidEmployee(): Observable<any> {
    return this.http.get(`${environment.azure.baseUrl}api/v1/Exceptions/invalidemployee`);
  }

  complaintNotFound(id): Observable<any> {
    return this.http.get(`${environment.azure.baseUrl}api/v1/Exceptions/complaintnotfound?id=` + id);
  }

  complaintHasBeenDeleted(id): Observable<any> {
    return this.http.get(`${environment.azure.baseUrl}api/v1/Exceptions/complainthasbeendeleted?id=` + id);
  }

  unknownException(): Observable<any> {
    return this.http.get(`${environment.azure.baseUrl}api/v1/Exceptions/unknown`);
  }

  sessionExpired(): Observable<any> {
    return this.http.get(`${environment.azure.baseUrl}api/v1/Exceptions/sessionexpired`);
  }

  tooManyRecords(): Observable<any> {
    return this.http.get(`${environment.azure.baseUrl}api/v1/Exceptions/toomanyrecords`);
  }

  fileNotFound(): Observable<any> {
    return this.http.get(`${environment.azure.baseUrl}api/v1/Exceptions/filenotfound`);
  }

  reportTimeOut(): Observable<any> {
    return this.http.get(`${environment.azure.baseUrl}api/v1/Exceptions/reporttimeout`);
  }

  registerComplaintActionSuccessRouteSettings(): Observable<any> {
    return this.http.get(`${environment.azure.baseUrl}api/v1/Exceptions/registercomplaintactionsuccessroutesettings`);
  }
}
