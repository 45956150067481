import { Component, OnInit } from '@angular/core';
import { ActionPlanLogService } from 'src/app/services/actionplan/action-plan-log.service';
import { filter } from 'lodash';
import { ActivatedRoute } from '@angular/router';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';

@Component({
  selector: 'app-action-plan-log',
  templateUrl: './action-plan-log.component.html',
  styleUrls: ['./action-plan-log.component.css']
})
export class ActionPlanLogComponent implements OnInit {
  logsFiltered : any[];
  logs : any[];
  searchTerm='';
  sortBy: string = '-createDate'
  actionId: any;
  complaintNumber;
  selectedRole: string;

  constructor(private logService: ActionPlanLogService,
    private route: ActivatedRoute,
    public breadcrumbService: BreadcrumbService) {
    //this.init();
  }

  ngOnInit(): void {
    this.complaintNumber = this.route.snapshot.paramMap.get('complaintNumber');
    this.actionId = this.route.snapshot.paramMap.get('id');
    this.selectedRole = this.route.snapshot.paramMap.get('selectedRole');

    this.breadcrumbService.menuItems = this.breadcrumbService.menuItems = [
      {label: 'ACTION PLAN ' + this.complaintNumber, routerLink: (this.selectedRole != null && this.selectedRole != undefined && this.selectedRole != '') ? 'issues/'+ this.complaintNumber +'/'+this.selectedRole +'/actionplan' : 'issues/'+ this.complaintNumber +'/actionplan'},
      {label: 'ACTION', routerLink: (this.selectedRole != null && this.selectedRole != undefined && this.selectedRole != '') ? 'issues/'+ this.complaintNumber +'/'+this.selectedRole + '/actionplan/card/' + this.actionId : 'issues/'+ this.complaintNumber + '/actionplan/card/' + this.actionId},
      {label: 'LOG'}
    ];

    if(this.actionId != null && this.actionId != undefined && this.actionId != '')
    {
      this.logService.getActionLogs(this.actionId).subscribe((resp) =>{
        this.logs = resp;
        //console.log(this.logs);
        //this.logs = this.logService.getLogs;
        //this.logsFiltered = this.logService.getLogs;
        this.logsFiltered = resp;
        this.logsFiltered.sort((a,b) =>{
        return b.creatorFullName-a.creatorFullName;
      })
      })
    }
  }

  // init(){
  // }

  onSearchValueChange(){
    this.logsFiltered = filter(this.logs,
      (log) =>{
        return log['subject'].toLowerCase().indexOf(this.searchTerm.toLowerCase()) > -1 ||
        log['body'].toLowerCase().indexOf(this.searchTerm.toLowerCase()) > -1 ||
        log['creatorEmail'].toLowerCase().indexOf(this.searchTerm) > -1;
      });
  }

  sortData(value){
    this.sortBy = value;
  }

}
