

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class AuthorizationService {

  isPermission = false;

  public userAccess = new BehaviorSubject<any>(null);
  userAccessDetail = this.userAccess.asObservable();

  constructor() { }

  setLoggedInUserData(userObj) {
    this.userAccess.next(userObj);
  }

  checkPermission(roleCodeArr, statusCodeArr, permissionArr, userData) {
    let count = 0;
    if (userData) {

      const currentStatusCode = userData['statusCode'];
      const currentRole = userData['roleCode'];
      const loggedinUserPermission = userData['permissions'];

      if (roleCodeArr && roleCodeArr.length > 0) {
        const index = roleCodeArr.indexOf(currentRole);
        if (index >= 0) {
          count = count + 1;
        }
      }

      if (statusCodeArr && statusCodeArr.length > 0) {
        const index = statusCodeArr.indexOf(currentStatusCode);
        if (index >= 0) {
          count = count + 1;
        }
      }
      if (permissionArr && permissionArr.length > 0 && userData['permissions']
        && loggedinUserPermission.length > 0) {

        const filterArr = userData['permissions'].filter((a) => a?.code == permissionArr.find((p) => p == a?.code));

        if (filterArr.length > 0) {
          count = count + 1;
        }
        else {
          count = count;
        }
      } else {
        count = count + 1;
      }
    }
    return count === 3 ? true : false;
  }


}
