import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProcessStepService {

  constructor(private http: HttpClient) { }

  loadProcessStepsTree(){
    return this.http.get<any>(`${environment.azure.baseUrl}api/v1/process-steps`);
  }

  save(treeItem){
    return this.http.put<any>(`${environment.azure.baseUrl}api/v1/process-steps`, treeItem);
  }

  addProcessStepsItem(treeItem){
    return this.http.post<any>(`${environment.azure.baseUrl}api/v1/process-steps`, treeItem);
  }

  deactivate(treeItem){
    return this.http.patch<any>(`${environment.azure.baseUrl}api/v1/process-steps`, treeItem);
  }

  getProcessStepsChildren(nodeId){
    return this.http.get<any>(`${environment.azure.baseUrl}api/v1/process-steps/` + nodeId);
  }

}
