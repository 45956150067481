<!-- <nav id="dropdownMenu">
    <ul>
      <li><a>Site actions <i class="fa-solid fa-angle-down"></i></a>
        <ul>
          <li ><a >Send Message</a></li>
          <li><a>View activity log</a></li>
          <li><a>CCRP Summary Report</a></li>
          <li><a>Report Templates <i class="fa-solid fa-angle-right"></i></a>
            <ul>
              <li><a>8D Report</a></li>
              <li><a>DMAIC Report</a></li>
              <li><a>PDCA Template</a></li>
            </ul>
          </li>
          <li><a>Overview in PowerPoint</a></li>
          <li><a>Change role</a></li>
        </ul>
      </li>
    </ul>
  </nav> -->


  <nav>
    <label for="btn-1" class="button">Site actions
      <span class="fas fa-caret-down"></span>
    </label>
    <input type="checkbox" id="btn-1">
    <ul class="menu">
      <li><a href="">Send Message</a></li>
      <li><a href="">View activity log</a></li>
      <li><a href="">CCRP Summary Report</a></li>
      <li>
        <label for="btn-2" class="first">Report Templates <span class="fas fa-caret-down"></span></label>
        <input type="checkbox" id="btn-2">
        <ul>
          <li><a href="">8D Report</a></li>
          <li><a href="">DMAIC Report</a></li>
          <li><a href="">PDCA Template</a></li>
        </ul>
      </li>
      <li><a href="">Overview in PowerPoint</a></li>
      <li><a href="">Change role</a></li>
    </ul>
  </nav>