<div class="ccrpcontainer">
  <!---Logos Section-->
  <div *ngIf="!isExternalPage">
    <div class="row">
      <div class="first-row" style="margin-top: 24px;margin-bottom:24px">
        <div>
          <div class="abb-logo"></div>
        </div>
        <div>
          <span class="energy-logo"></span>
        </div>
      </div>

      <div class="first-row">
        <div>
          <div class="abb-ccrp" style="margin-left: 0;">
          <a routerLink="/mydashboard" style="color:#ff0026; text-decoration: none;">Customer Care Response Process (CCRP)</a></div>
        </div>
        <div>
          <div class="yammer-notific-profile">
            <span class="clear-white-space yammer bg-transparent"><button class="mdc-button mdc-ripple-surface clear-white-space bg-transparent">
              <span>
                <a target="_blank" href="https://akira.hitachienergy.com/">
                  <img src="../assets/images/akira.svg" width="40" height="40">
                </a>
              </span>
            </button></span>
            <span class="clear-white-space yammer bg-transparent"><button class="mdc-button mdc-ripple-surface clear-white-space bg-transparent">
              <span>
                <a target="_blank" href="https://ise-customerissues.analytics.hitachienergy.com">
                  <img src="../assets/images/digital_brain.svg" width="40" height="40">
                </a>
              </span>
            </button></span>
            <!-- <span class="clear-white-space yammer bg-transparent"><button class="mdc-button mdc-ripple-surface clear-white-space bg-transparent" [pTooltip]="surveyText" tooltipPosition="bottom" [escape]="false">
              <span>
                <a target="_blank" href="https://forms.office.com/e/TeQNSDNeJQ">
                  <img src="../assets/images/speed.svg" width="40" height="40">
                </a>
              </span>
            </button></span> -->
            <!-- <i pTooltip="Please note: If you use stored CCRP Analyzer reports from before 29 Mar 2021, please click here to find out how to reconnect
                              Please read privacy notice here." class="fa fa-circle-question infoTooltipComplaintHeader " aria-hidden="true" style="cursor: pointer;"></i> -->
            <span class="clear-white-space yammer bg-transparent">
                    <button class="mdc-button mdc-ripple-surface clear-white-space bg-transparent" [pTooltip]="showYammerTooltip()" tooltipPosition="bottom" [escape]="false">
                      <span>
                        <a target="_blank" href="https://web.yammer.com/main/groups/eyJfdHlwZSI6Ikdyb3VwIiwiaWQiOiIyMzA3MTUzOTIxIn0/all">
                          <img src="../assets/images/viva_engage.svg" width="34" height="34">
                        </a>
                      </span>
                    </button>
                </span>
            <span class="">
                    <button container="body" class="mdc-button mdc-ripple-surface clear-white-space bg-transparent" type="button" [ngbPopover]="popContent" placement="bottom"> <img width="32" height="32"                                                                                                                                                              src="../assets/images/notification_important_new.svg">
                    </button>
                </span>
            <span>
                    <!-- <button type="button" ngbPopover="Manoj Desai (83897393997)" popoverTitle="hello, shiw fdaljad" placement="bottom-right"
                      class="mdc-icon-button user-profile-info mdc-ripple-surface--primary clear-white-space ng-scope"
                      style="border-radius: 90%; width: 40px; height: 40px; background: black; color: white; font-size: 18px"><span>{{this.userProfileData[0].employeeDetails.firstName.substring(0,1)}}{{this.userProfileData[0].employeeDetails.lastName.substring(0,1)}}</span></button> -->
                    <app-user-info-details [userData]="userProfileData"></app-user-info-details>
                </span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!---Notification Section-->
  <div class="row mt-1"  *ngIf="!isExternalPage">
        <span class="notification">
          <div id="release-notification"
               style="display: none; padding:15px; margin-bottom: 20px; border: 1px solid transparent; border-radius: 4px; background-color: #d9edf7; border-color: #bce8f1; color: #31708f;">
          </div>
          <!-- <div id="analyser-issue-notification"
            style="padding: 10px; margin-bottom: 20px; margin-top: 10px; border: 1px solid transparent; border-radius: 4px; background-color: #f2f2f2;
            border-color: #495057;
            color: #2d2d2d;box-shadow:1px 2px 5px #888888; ">
            <p>Please note: If you use stored CCRP Analyzer reports from before 29 Mar 2021, please click <a
                href="https://hitachipowergrids.sharepoint.com/:p:/r/sites/QCIO/ccrp/_layouts/15/Doc.aspx?sourcedoc=%7BD5E1CE92-2C3F-4994-88D9-1F2E98D3B430%7D&file=CCRP%20release%20-%20How%20to%20reconnect%20existing%20CCRP%20Analyzer%20reports_final.pptx&action=edit&mobileredirect=true"
                target="_blank" style="text-decoration:underline; color: #353030;"><b>here</b></a> to find out how to reconnect
            </p>
            <p>Please read privacy notice <a href="https://www.hitachienergy.com/cookie-notice"
                style="text-decoration:underline; color: #353030;"><b>here</b></a>. </p>
          </div> -->
          <div class="alert alert-warning alert-dismissible fade show" role="alert">
            <h4 class="text-danger text-bold">FYI: This Branch is pointed to Angular Version Upgradation to 18 Repo....  </h4>
            <div style="display: flex; align-items: center; place-content: flex-end; color: #664d00">
              <span style="width: 100%;"> <b>Note:</b> A new version of CCRP Analyzer is now Live ! Please follow the instructions in the <a
                  href="https://hitachipowergrids.sharepoint.com/:p:/r/sites/QCIO/ccrp/_layouts/15/Doc.aspx?sourcedoc=%7BB3129A78-0472-4F28-BE31-7C00095F0631%7D&file=Analyzer_Help_Document.pptx&action=edit&mobileredirect=true"
                  target="_blank" style="text-decoration:underline; color: #353030;"><b> Help Document</b> </a> to avoid any issues while accessing the New Analyzer. Please post your queries/issues in our <a
                  href="https://teams.microsoft.com/l/channel/19%3aLdeMGu_SGI7j6nllgDZq_f7wR2QFPJz-3b7YDyQOiFs1%40thread.tacv2/General?groupId=786f4ebb-81cc-4041-a8ba-0a90c641e2ee&tenantId=7831e6d9-dc6c-4cd1-9ec6-1dc2b4133195"
                  target="_blank" style="text-decoration:underline; color: #353030;"> <b>CCRP Analyzer Hyper Care Channel</b> </a>
                  for any support needed for New Analyzer.
              </span><img src="../assets/images/NewGif.gif" style="width: 50px; height: 25px; margin-left: 10px;" />
            </div>
            <p><b>{{notificationLabel}}</b> {{notificationValue}}</p>
            <div style="display: flex; align-items: center; place-content: flex-end; color: #664d00">
              <span style="width: 100%;"><b>Note:</b> CCRP Training
                  Modules are now available in MyLearning portal. Kindly
                  <a href="https://mylearning.hitachienergy.com/learningpage/1211_enUS/ExpertusONE_1" target="_blank"
                    style="text-decoration:underline; color: #353030;"> <b>click here</b> </a> for enrolling into the training
                  program.
              </span> <img src="../assets/images/NewGif.gif" style="width: 50px; height: 25px; margin-left: 10px;" />
            </div>
            <div style="display: flex; align-items: center; place-content: flex-end; color: #664d00">
              <span style="width: 100%;"><b>Note:</b> Please give your feedback on the CCRP Application
                  <a target="_blank" href="https://forms.office.com/e/TeQNSDNeJQ" style="text-decoration:underline; color: #353030;"> <b>here</b> </a>.
              </span> <img src="../assets/images/NewGif.gif" style="width: 50px; height: 25px; margin-left: 10px;" />
            </div>
            <!-- <div style="display: flex; align-items: center; place-content: flex-end;">
              <span style="width: 100%;">
                <marquee onmouseover="this.stop();" onmouseout="this.start();" style="color:#352c14e9"><b>Note:</b> Customer
                  search in new issue page in CCRP tool will not work as expected from
                  <b>24th May 08:00 AM CET to 27th May 08:00 AM CET</b>
                  due to a planned down time of GIS application.
                </marquee>
              </span> <img src="../assets/images/NewGif.gif" style="width: 50px; height: 25px; margin-left: 10px;" />
            </div> -->
            <span style="width:100%">Read privacy notice <a href="https://www.hitachienergy.com/cookie-notice"
                style="text-decoration:underline; color: #353030;"><b>here</b></a>. </span>
            <a type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"> </a>
          </div>

        </span>
  </div>
  <!---BreadCrumbs Section-->
  <p-breadcrumb [model]="this.breadcrumbService.menuItems" [home]="home"  *ngIf="!isExternalPage && !isHRGTPage"></p-breadcrumb>
  <!-- <div class="row">
    <breadcrumb #parent>
      <ol class="breadcrumb">
        <ng-template ngFor let-route [ngForOf]="parent.breadcrumbs">
          <li>
            <a routerLink="../">CCRP</a>
          </li>
          <li *ngIf="!route.terminal" class="breadcrumb-item">
            <a href="" [routerLink]="[route.url]">{{ route.displayName }}</a>
          </li>
          <li *ngIf="route.terminal" class="breadcrumb-item active" aria-current="page">{{ route.displayName }}</li>
        </ng-template>
      </ol>
    </breadcrumb>
  </div> -->
  <!---NavBar Section-->
  <app-ccrpnavbar  *ngIf="!isExternalPage && !isHRGTPage"></app-ccrpnavbar>
  <!---Main Router Outlet Section-->
  <div class="row" data-bs-spy="scroll" data-bs-offset="0" class="scrollspy-example">
    <app-loader></app-loader>
    <router-outlet></router-outlet>
    <!---Footer Section-->
    <div class="footer"  *ngIf="!isExternalPage">
      <div class="row">
        <div class="col-md-12  mt-auto py-2" style="position:fixed;width:100%;bottom:0;background:#ebebeb;left: 0px;z-index: 99;">
          <span style="padding-left:10px;"> © Hitachi Energy &#64;{{currentYearForFooter}}, All rights reserved </span>
          <span class="float-end" style="padding-right:10px;">Product Version: {{currentCCRPVersion}} </span>
        </div>

      </div>
    </div>
  </div>
</div>


<!-- notification-icon template -->
<ng-template #popContent >
  <div class="notification-info">
    <a href="https://hitachipowergrids.sharepoint.com/sites/QCIO/ccrp/SitePages/Release%20Notes.aspx" target="_blank">Release Notes</a>
  </div>
</ng-template>

<ngx-spinner bdColor="rgba(51, 51, 51, 0.3)" color="#676767" size="medium" type="ball-spin-clockwise">
  <p style="color: #676767; padding: 10px; font-weight: bold; ">Please Wait... </p>
</ngx-spinner>

<p-toast position="top-right">
  <ng-template let-message pTemplate="message">
    <i class="p-toast-message-icon pi pi-check"></i>&nbsp;&nbsp;
    <p innerHTML="{{message.summary}}"></p>
  </ng-template>
</p-toast>
