import * as i0 from '@angular/core';
import { EventEmitter, Component, ChangeDetectionStrategy, ViewEncapsulation, Optional, Input, ContentChildren, Output, NgModule } from '@angular/core';
import * as i2 from '@angular/common';
import { CommonModule } from '@angular/common';
import { trigger, transition, style, animate } from '@angular/animations';
import * as i1 from 'primeng/api';
import { PrimeTemplate } from 'primeng/api';
import { timer } from 'rxjs';
import * as i3 from 'primeng/ripple';
import { RippleModule } from 'primeng/ripple';
import { CheckIcon } from 'primeng/icons/check';
import { InfoCircleIcon } from 'primeng/icons/infocircle';
import { TimesCircleIcon } from 'primeng/icons/timescircle';
import { ExclamationTriangleIcon } from 'primeng/icons/exclamationtriangle';
import { TimesIcon } from 'primeng/icons/times';
const _c0 = (a0, a1) => ({
  showTransitionParams: a0,
  hideTransitionParams: a1
});
const _c1 = a0 => ({
  value: "visible",
  params: a0
});
function Messages_ng_container_1_div_1_span_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "span");
  }
  if (rf & 2) {
    const msg_r1 = i0.ɵɵnextContext().$implicit;
    i0.ɵɵclassMap("p-message-icon pi " + msg_r1.icon);
  }
}
function Messages_ng_container_1_div_1_span_3_CheckIcon_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "CheckIcon");
  }
}
function Messages_ng_container_1_div_1_span_3_InfoCircleIcon_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "InfoCircleIcon");
  }
}
function Messages_ng_container_1_div_1_span_3_TimesCircleIcon_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "TimesCircleIcon");
  }
}
function Messages_ng_container_1_div_1_span_3_ExclamationTriangleIcon_5_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "ExclamationTriangleIcon");
  }
}
function Messages_ng_container_1_div_1_span_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span", 10);
    i0.ɵɵelementContainerStart(1);
    i0.ɵɵtemplate(2, Messages_ng_container_1_div_1_span_3_CheckIcon_2_Template, 1, 0, "CheckIcon", 11)(3, Messages_ng_container_1_div_1_span_3_InfoCircleIcon_3_Template, 1, 0, "InfoCircleIcon", 11)(4, Messages_ng_container_1_div_1_span_3_TimesCircleIcon_4_Template, 1, 0, "TimesCircleIcon", 11)(5, Messages_ng_container_1_div_1_span_3_ExclamationTriangleIcon_5_Template, 1, 0, "ExclamationTriangleIcon", 11);
    i0.ɵɵelementContainerEnd();
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const msg_r1 = i0.ɵɵnextContext().$implicit;
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngIf", msg_r1.severity === "success");
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", msg_r1.severity === "info");
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", msg_r1.severity === "error");
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", msg_r1.severity === "warn");
  }
}
function Messages_ng_container_1_div_1_ng_container_4_span_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "span", 14);
  }
  if (rf & 2) {
    const msg_r1 = i0.ɵɵnextContext(2).$implicit;
    i0.ɵɵproperty("innerHTML", msg_r1.summary, i0.ɵɵsanitizeHtml);
  }
}
function Messages_ng_container_1_div_1_ng_container_4_span_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "span", 15);
  }
  if (rf & 2) {
    const msg_r1 = i0.ɵɵnextContext(2).$implicit;
    i0.ɵɵproperty("innerHTML", msg_r1.detail, i0.ɵɵsanitizeHtml);
  }
}
function Messages_ng_container_1_div_1_ng_container_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, Messages_ng_container_1_div_1_ng_container_4_span_1_Template, 1, 1, "span", 12)(2, Messages_ng_container_1_div_1_ng_container_4_span_2_Template, 1, 1, "span", 13);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const msg_r1 = i0.ɵɵnextContext().$implicit;
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", msg_r1.summary);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", msg_r1.detail);
  }
}
function Messages_ng_container_1_div_1_ng_template_5_span_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span", 18);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const msg_r1 = i0.ɵɵnextContext(2).$implicit;
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(msg_r1.summary);
  }
}
function Messages_ng_container_1_div_1_ng_template_5_span_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span", 19);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const msg_r1 = i0.ɵɵnextContext(2).$implicit;
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(msg_r1.detail);
  }
}
function Messages_ng_container_1_div_1_ng_template_5_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, Messages_ng_container_1_div_1_ng_template_5_span_0_Template, 2, 1, "span", 16)(1, Messages_ng_container_1_div_1_ng_template_5_span_1_Template, 2, 1, "span", 17);
  }
  if (rf & 2) {
    const msg_r1 = i0.ɵɵnextContext().$implicit;
    i0.ɵɵproperty("ngIf", msg_r1.summary);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", msg_r1.detail);
  }
}
function Messages_ng_container_1_div_1_button_7_Template(rf, ctx) {
  if (rf & 1) {
    const _r2 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "button", 20);
    i0.ɵɵlistener("click", function Messages_ng_container_1_div_1_button_7_Template_button_click_0_listener() {
      i0.ɵɵrestoreView(_r2);
      const i_r3 = i0.ɵɵnextContext().index;
      const ctx_r3 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r3.removeMessage(i_r3));
    });
    i0.ɵɵelement(1, "TimesIcon", 21);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    i0.ɵɵadvance();
    i0.ɵɵproperty("styleClass", "p-message-close-icon");
  }
}
function Messages_ng_container_1_div_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 5)(1, "div", 6);
    i0.ɵɵtemplate(2, Messages_ng_container_1_div_1_span_2_Template, 1, 2, "span", 7)(3, Messages_ng_container_1_div_1_span_3_Template, 6, 4, "span", 8)(4, Messages_ng_container_1_div_1_ng_container_4_Template, 3, 2, "ng-container", 3)(5, Messages_ng_container_1_div_1_ng_template_5_Template, 2, 2, "ng-template", null, 1, i0.ɵɵtemplateRefExtractor)(7, Messages_ng_container_1_div_1_button_7_Template, 2, 1, "button", 9);
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const msg_r1 = ctx.$implicit;
    const escapeOut_r5 = i0.ɵɵreference(6);
    const ctx_r3 = i0.ɵɵnextContext(2);
    i0.ɵɵclassMap("p-message p-message-" + msg_r1.severity);
    i0.ɵɵproperty("@messageAnimation", i0.ɵɵpureFunction1(11, _c1, i0.ɵɵpureFunction2(8, _c0, ctx_r3.showTransitionOptions, ctx_r3.hideTransitionOptions)));
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngIf", msg_r1.icon);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !msg_r1.icon);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !ctx_r3.escape)("ngIfElse", escapeOut_r5);
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("ngIf", ctx_r3.closable);
  }
}
function Messages_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, Messages_ng_container_1_div_1_Template, 8, 13, "div", 4);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r3 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngForOf", ctx_r3.messages);
  }
}
function Messages_ng_template_2_ng_container_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function Messages_ng_template_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 22)(1, "div", 6);
    i0.ɵɵtemplate(2, Messages_ng_template_2_ng_container_2_Template, 1, 0, "ng-container", 23);
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const ctx_r3 = i0.ɵɵnextContext();
    i0.ɵɵproperty("ngClass", "p-message p-message-" + ctx_r3.severity);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r3.contentTemplate);
  }
}
class Messages {
  constructor(messageService, el, cd) {
    this.messageService = messageService;
    this.el = el;
    this.cd = cd;
    this.closable = true;
    this.enableService = true;
    this.escape = true;
    this.showTransitionOptions = '300ms ease-out';
    this.hideTransitionOptions = '200ms cubic-bezier(0.86, 0, 0.07, 1)';
    this.valueChange = new EventEmitter();
    this.timerSubscriptions = [];
  }
  set value(messages) {
    this.messages = messages;
    this.startMessageLifes(this.messages);
  }
  ngAfterContentInit() {
    this.templates.forEach(item => {
      switch (item.getType()) {
        case 'content':
          this.contentTemplate = item.template;
          break;
        default:
          this.contentTemplate = item.template;
          break;
      }
    });
    if (this.messageService && this.enableService && !this.contentTemplate) {
      this.messageSubscription = this.messageService.messageObserver.subscribe(messages => {
        if (messages) {
          if (!Array.isArray(messages)) {
            messages = [messages];
          }
          const filteredMessages = messages.filter(m => this.key === m.key);
          this.messages = this.messages ? [...this.messages, ...filteredMessages] : [...filteredMessages];
          this.startMessageLifes(filteredMessages);
          this.cd.markForCheck();
        }
      });
      this.clearSubscription = this.messageService.clearObserver.subscribe(key => {
        if (key) {
          if (this.key === key) {
            this.messages = null;
          }
        } else {
          this.messages = null;
        }
        this.cd.markForCheck();
      });
    }
  }
  hasMessages() {
    let parentEl = this.el.nativeElement.parentElement;
    if (parentEl && parentEl.offsetParent) {
      return this.contentTemplate != null || this.messages && this.messages.length > 0;
    }
    return false;
  }
  clear() {
    this.messages = [];
    this.valueChange.emit(this.messages);
  }
  removeMessage(i) {
    this.messages = this.messages.filter((msg, index) => index !== i);
    this.valueChange.emit(this.messages);
  }
  get icon() {
    const severity = this.severity || (this.hasMessages() ? this.messages[0].severity : null);
    if (this.hasMessages()) {
      switch (severity) {
        case 'success':
          return 'pi-check';
        case 'info':
          return 'pi-info-circle';
        case 'error':
          return 'pi-times';
        case 'warn':
          return 'pi-exclamation-triangle';
        default:
          return 'pi-info-circle';
      }
    }
    return null;
  }
  ngOnDestroy() {
    if (this.messageSubscription) {
      this.messageSubscription.unsubscribe();
    }
    if (this.clearSubscription) {
      this.clearSubscription.unsubscribe();
    }
    this.timerSubscriptions?.forEach(subscription => subscription.unsubscribe());
  }
  startMessageLifes(messages) {
    messages?.forEach(message => message.life && this.startMessageLife(message));
  }
  startMessageLife(message) {
    const timerSubsctiption = timer(message.life).subscribe(() => {
      this.messages = this.messages?.filter(msgEl => msgEl !== message);
      this.timerSubscriptions = this.timerSubscriptions?.filter(timerEl => timerEl !== timerSubsctiption);
      this.valueChange.emit(this.messages);
      this.cd.markForCheck();
    });
    this.timerSubscriptions.push(timerSubsctiption);
  }
}
Messages.ɵfac = function Messages_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || Messages)(i0.ɵɵdirectiveInject(i1.MessageService, 8), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.ChangeDetectorRef));
};
Messages.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: Messages,
  selectors: [["p-messages"]],
  contentQueries: function Messages_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, PrimeTemplate, 4);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.templates = _t);
    }
  },
  hostAttrs: [1, "p-element"],
  inputs: {
    value: "value",
    closable: "closable",
    style: "style",
    styleClass: "styleClass",
    enableService: "enableService",
    key: "key",
    escape: "escape",
    severity: "severity",
    showTransitionOptions: "showTransitionOptions",
    hideTransitionOptions: "hideTransitionOptions"
  },
  outputs: {
    valueChange: "valueChange"
  },
  decls: 4,
  vars: 5,
  consts: [["staticMessage", ""], ["escapeOut", ""], ["role", "alert", 1, "p-messages", "p-component", 3, "ngStyle"], [4, "ngIf", "ngIfElse"], ["role", "alert", 3, "class", 4, "ngFor", "ngForOf"], ["role", "alert"], [1, "p-message-wrapper"], [3, "class", 4, "ngIf"], ["class", "p-message-icon", 4, "ngIf"], ["class", "p-message-close p-link", "type", "button", "pRipple", "", 3, "click", 4, "ngIf"], [1, "p-message-icon"], [4, "ngIf"], ["class", "p-message-summary", 3, "innerHTML", 4, "ngIf"], ["class", "p-message-detail", 3, "innerHTML", 4, "ngIf"], [1, "p-message-summary", 3, "innerHTML"], [1, "p-message-detail", 3, "innerHTML"], ["class", "p-message-summary", 4, "ngIf"], ["class", "p-message-detail", 4, "ngIf"], [1, "p-message-summary"], [1, "p-message-detail"], ["type", "button", "pRipple", "", 1, "p-message-close", "p-link", 3, "click"], [3, "styleClass"], ["role", "alert", 3, "ngClass"], [4, "ngTemplateOutlet"]],
  template: function Messages_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelementStart(0, "div", 2);
      i0.ɵɵtemplate(1, Messages_ng_container_1_Template, 2, 1, "ng-container", 3)(2, Messages_ng_template_2_Template, 3, 2, "ng-template", null, 0, i0.ɵɵtemplateRefExtractor);
      i0.ɵɵelementEnd();
    }
    if (rf & 2) {
      const staticMessage_r6 = i0.ɵɵreference(3);
      i0.ɵɵclassMap(ctx.styleClass);
      i0.ɵɵproperty("ngStyle", ctx.style);
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngIf", !ctx.contentTemplate)("ngIfElse", staticMessage_r6);
    }
  },
  dependencies: () => [i2.NgClass, i2.NgForOf, i2.NgIf, i2.NgTemplateOutlet, i2.NgStyle, i3.Ripple, CheckIcon, InfoCircleIcon, TimesCircleIcon, ExclamationTriangleIcon, TimesIcon],
  styles: [".p-message-wrapper{display:flex;align-items:center}.p-message-close{display:flex;align-items:center;justify-content:center}.p-message-close.p-link{margin-left:auto;overflow:hidden;position:relative}.p-messages .p-message.ng-animating{overflow:hidden}\n"],
  encapsulation: 2,
  data: {
    animation: [trigger('messageAnimation', [transition(':enter', [style({
      opacity: 0,
      transform: 'translateY(-25%)'
    }), animate('{{showTransitionParams}}')]), transition(':leave', [animate('{{hideTransitionParams}}', style({
      height: 0,
      marginTop: 0,
      marginBottom: 0,
      marginLeft: 0,
      marginRight: 0,
      opacity: 0
    }))])])]
  },
  changeDetection: 0
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(Messages, [{
    type: Component,
    args: [{
      selector: 'p-messages',
      template: `
        <div class="p-messages p-component" role="alert" [ngStyle]="style" [class]="styleClass">
            <ng-container *ngIf="!contentTemplate; else staticMessage">
                <div
                    *ngFor="let msg of messages; let i = index"
                    [class]="'p-message p-message-' + msg.severity"
                    role="alert"
                    [@messageAnimation]="{ value: 'visible', params: { showTransitionParams: showTransitionOptions, hideTransitionParams: hideTransitionOptions } }"
                >
                    <div class="p-message-wrapper">
                        <span
                            *ngIf="msg.icon"
                            [class]="'p-message-icon pi ' + msg.icon"
                        >
                        </span>
                        <span class="p-message-icon" *ngIf="!msg.icon">
                            <ng-container>
                                <CheckIcon *ngIf="msg.severity === 'success'"/>
                                <InfoCircleIcon *ngIf="msg.severity === 'info'"/>
                                <TimesCircleIcon *ngIf="msg.severity === 'error'"/>
                                <ExclamationTriangleIcon *ngIf="msg.severity === 'warn'"/>
                            </ng-container>
                        </span>
                        <ng-container *ngIf="!escape; else escapeOut">
                            <span *ngIf="msg.summary" class="p-message-summary" [innerHTML]="msg.summary"></span>
                            <span *ngIf="msg.detail" class="p-message-detail" [innerHTML]="msg.detail"></span>
                        </ng-container>
                        <ng-template #escapeOut>
                            <span *ngIf="msg.summary" class="p-message-summary">{{ msg.summary }}</span>
                            <span *ngIf="msg.detail" class="p-message-detail">{{ msg.detail }}</span>
                        </ng-template>
                        <button class="p-message-close p-link" (click)="removeMessage(i)" *ngIf="closable" type="button" pRipple>
                            <TimesIcon [styleClass]="'p-message-close-icon'"/>
                        </button>
                    </div>
                </div>
            </ng-container>
            <ng-template #staticMessage>
                <div [ngClass]="'p-message p-message-' + severity" role="alert">
                    <div class="p-message-wrapper">
                        <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
                    </div>
                </div>
            </ng-template>
        </div>
    `,
      animations: [trigger('messageAnimation', [transition(':enter', [style({
        opacity: 0,
        transform: 'translateY(-25%)'
      }), animate('{{showTransitionParams}}')]), transition(':leave', [animate('{{hideTransitionParams}}', style({
        height: 0,
        marginTop: 0,
        marginBottom: 0,
        marginLeft: 0,
        marginRight: 0,
        opacity: 0
      }))])])],
      changeDetection: ChangeDetectionStrategy.OnPush,
      encapsulation: ViewEncapsulation.None,
      host: {
        class: 'p-element'
      },
      styles: [".p-message-wrapper{display:flex;align-items:center}.p-message-close{display:flex;align-items:center;justify-content:center}.p-message-close.p-link{margin-left:auto;overflow:hidden;position:relative}.p-messages .p-message.ng-animating{overflow:hidden}\n"]
    }]
  }], function () {
    return [{
      type: i1.MessageService,
      decorators: [{
        type: Optional
      }]
    }, {
      type: i0.ElementRef
    }, {
      type: i0.ChangeDetectorRef
    }];
  }, {
    value: [{
      type: Input
    }],
    closable: [{
      type: Input
    }],
    style: [{
      type: Input
    }],
    styleClass: [{
      type: Input
    }],
    enableService: [{
      type: Input
    }],
    key: [{
      type: Input
    }],
    escape: [{
      type: Input
    }],
    severity: [{
      type: Input
    }],
    showTransitionOptions: [{
      type: Input
    }],
    hideTransitionOptions: [{
      type: Input
    }],
    templates: [{
      type: ContentChildren,
      args: [PrimeTemplate]
    }],
    valueChange: [{
      type: Output
    }]
  });
})();
class MessagesModule {}
MessagesModule.ɵfac = function MessagesModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || MessagesModule)();
};
MessagesModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: MessagesModule
});
MessagesModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [CommonModule, RippleModule, CheckIcon, InfoCircleIcon, TimesCircleIcon, ExclamationTriangleIcon, TimesIcon]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MessagesModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, RippleModule, CheckIcon, InfoCircleIcon, TimesCircleIcon, ExclamationTriangleIcon, TimesIcon],
      exports: [Messages],
      declarations: [Messages]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { Messages, MessagesModule };

