import { Component, OnInit, ViewChild } from '@angular/core';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { FileUpload } from 'primeng/fileupload';
import { ComplaintDetailsService } from 'src/app/services/complaintDetails/complaint-details.service';

@Component({
  selector: 'app-action-dialog-popup',
  templateUrl: './action-dialog-popup.component.html',
  styleUrls: ['./action-dialog-popup.component.css']
})
export class ActionDialogPopupComponent implements OnInit {
  @ViewChild('createUploaderManualEntry') createUploaderManualEntry!: FileUpload;
  actionDialogAttachment: any = [];
  // informationAttachments: any = [];
  // informationAttachmentsHolder: any = [];
  // issueAttachmentMerged: any = [];
  removeText= 'Remove';
  manualEntryObj: any = {
    subject: '',
    body: '',
    files: []
  }
  constructor(public ref: DynamicDialogRef,
    private complaintDetailsService : ComplaintDetailsService
  ) { }

  ngOnInit(): void {
  }

  saveEntry(entry: any) {
    this.ref.close(entry);
  }

  exit() {
      this.ref.close(null);
  }

  myUploader(createUploader,event) {
    let section = "issue-capture";
    // console.log("event file",event.files);
    this.manualEntryObj.files = []
    for  (let i =  0; i <  event.files.length; i++)  {
      if(i>=this.actionDialogAttachment?.length){
        // console.log("inside If",i,event.files[i]);
      let formData =  new  FormData();
      formData.append("fileUploadObj",  event.files[i]);
      this.complaintDetailsService.siteActionManualEntryAttachmentSave(formData).subscribe((response:any)=>{
        let attachIdChange = response.files[0];
        attachIdChange.id = (attachIdChange?.fileId)?.toString();
        // console.log("attachIdChange==>",attachIdChange);
        this.actionDialogAttachment.push(attachIdChange);
      //  console.log('ACTIONDIALOGLOG__________________', this.actionDialogAttachment)
        // this.issueAttachmentMerged = [...this.informationAttachmentsHolder, ...this.actionDialogAttachment ];
        this.actionDialogAttachment.forEach((attachment)=>{
          this.manualEntryObj.files.push(attachment.fileId);
        })
        // this.manualEntryObj.files = this.issueAttachmentMerged

        /* this.descForm.patchValue({
            uploadedAttachments: this.issueAttachmentMerged
          }); */
      })
    }
  }
  }

  removeFile(file: File, uploader: FileUpload) {
    const index = uploader.files.indexOf(file);
    uploader.remove(event,index);
     let filename = this.actionDialogAttachment[Number(index)]?.fileId
     this.complaintDetailsService.siteActionManualEntryRemoveAttachments(filename,1).subscribe((res)=>{
     // console.log('COMPLAIINTDETAILS+++++++++++',this.actionDialogAttachment,filename)
      this.actionDialogAttachment = this.actionDialogAttachment.filter(attachment => attachment?.fileId !== filename);
      // this.issueAttachmentMerged = [...this.informationAttachmentsHolder, ...this.actionDialogAttachment];
      this.actionDialogAttachment.forEach((attachment)=>{
        this.manualEntryObj.files.push(attachment.fileId);
      })
      // this.manualEntryObj.files = this.issueAttachmentMerged
      /* this.descForm.patchValue({
        uploadedAttachments: this.issueAttachmentMerged
      }); */
     })
  }

}
