import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TreeNode } from 'primeng/api';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PgrcacategoryServiceService {

  constructor(private http: HttpClient) { }

  getFiles(code,isActive) {

    // const code = { code: '2872', isActive: 'True' }
    return this.http
      .get<TreeNode>(`${environment.azure.baseUrl}/api/v1/PGCategory?code=${code}&isActive=${isActive}`)
      .toPromise()
      .then((res) => <TreeNode[]>res);
    // 2657
  }
  getData(geidData): Observable<any> {
    // return this.http.get(`${environment.azure.baseUrl}/api/dashboard/employeeinfo?geids=${geid}`)
    return this.http.post(`${environment.azure.baseUrl}/api/v1/dashboard/employeeinfo`,{geids : geidData})
  }
  getChildData(code,id,isActive): Observable<any> {
    return this.http.get(`${environment.azure.baseUrl}/api/v1/PGCategory/${code}/${id}?isActive=${isActive}`)
  }
}
