<div class="row issue-resolution-lesson-learned">
  <h6 class="inner-heading1">
    Lessons Learned
  </h6>
  <div>
    <form [formGroup]="issueForm">
      <div class="row mb-1">
        <div class="summarize-text">
          <label for="learn" style="
            padding-left: 0px;"> {{ "Complaint_Lesson_Learnt" | translate }}:</label>
          <textarea class="form-control customtextfield-l custom-textarea-auto" pInputTextarea [autoResize]="true"
            formControlName="learn" name="learned" class="form-control"
            [attr.disabled]="!disableFields['learn'] ? true : null"></textarea>
          <input type="hidden" formControlName="id">
          <input type="hidden" formControlName="complaintId">
          <input type="hidden" formControlName="isActive">
          <input type="hidden" formControlName="createDate">
        </div>
      </div>

      <div class="row mb-1">
        <div class="summarize-text">
          <label for="desc" style="padding-left: 0px;">{{ "Complaint_Lesson_Learnt_Description" | translate }}:</label>
          <textarea class="form-control customtextfield-l custom-textarea-auto " pInputTextarea [autoResize]="true"
            [attr.disabled]="!disableFields['desc'] ? true : null" formControlName="desc" name="desc"
            class="form-control"></textarea>
        </div>

        <p-toast></p-toast>
        <div class="pe-0">
          <div class="mt-2 float-end  mb-2 reset-lessonbtn" style="float: right !important;">
            <button [disabled]="!disableBtn['btnResetLessonLearnt_issueRess']"
              [ngClass]="!disableBtn['btnResetLessonLearnt_issueRess']?'grayButton': ''" class="widget-buttonlarge me-2"
              value="resetLessonForm" (click)="lessonLearnedReset()">{{
              "ButtonResetLessonLearnt" | translate }}</button>
            <!-- <div class="card"> -->
            <button [disabled]="!disableBtn['btnAddLessonLearnt_issueRes']"
              [ngClass]="!disableBtn['btnAddLessonLearnt_issueRes']?'grayButton': ''" class="widget-buttonlarge "
              *ngIf="!isEdit" type="submit" value="submit" (click)="ButtonAddLessonLearnt()">{{ "ButtonAddLessonLearnt"
              | translate }}</button>
            <button [disabled]="!disableBtn['btnUpdateLessonLearnt_issueRes']"
              [ngClass]="!disableBtn['btnUpdateLessonLearnt_issueRes']?'grayButton': ''" class="widget-buttonlarge"
              *ngIf="isEdit" value="reset" (click)="ButtonUpdateLessonLearnt()">{{
              "ButtonUpdateLessonLearnt" | translate }}</button>

            <!-- </div> -->
          </div>
        </div>
      </div>
    </form>

    <table class="table table-responsive mt-0 table-wrapper">
      <thead>
        <tr>
          <th class="text-center"><strong>Lessons Learned</strong></th>
          <th class="text-center"><strong>Description</strong></th>
          <th class="text-center"><strong>Action</strong></th>
        </tr>
      </thead>
      <ng-template [ngIf]="userdata">

        <tbody>


          <tr *ngFor="let learnlist of lessonLearntList; let i=index" class="issueResTableBody tablebodybackgroundclr">
            <td>{{learnlist.lessonLearnt}}</td>
            <td>{{learnlist.lessonLearntDescription}}</td>
            <td><a class="editLeft" (click)="editdata(learnlist,i)">{{ "ButtonEditSystemUser" | translate }}</a>
              <a class="deleteLeft ms-1" (click)="onDeleteLessonLearnt(learnlist.id,i)"> {{ "ButtonDeleteSystemUser" |
                translate }}</a>
            </td>

          </tr>

        </tbody>

      </ng-template>
    </table>
    <div class="my-2 lessonLearnedSubmit float-end">
      <button [disabled]="!disableBtn['submitLessonLearn_issueRes']"
        [ngClass]="!disableBtn['submitLessonLearn_issueRes']?'grayButton': ''" class="widget-buttonlarge"
        (click)="onLessonLearntRequest()">{{ "ButtonSubmitLessonLearnt" |
        translate }}</button>
    </div>
  </div>
</div>
