import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NewIssueService {

  constructor(public http: HttpClient) { }

  getCurrentUserDetails(): Observable<any> {
    return this.http.get(`${environment.azure.baseUrl}api/v1/Admin/currentuser`);
  }

  getTargetDateHistory(targetId): Observable<any> {
    return this.http.get(`${environment.azure.baseUrl}api/v1/targetdatelogs/` + targetId);
  }

  getTargetDateHistoryDownload(targetId): Observable<any> {
    return this.http.post(`${environment.azure.baseUrl}api/v1/targetdatelogs/` + targetId + `/download`, null, {responseType: "arraybuffer" });
  }

  getDPSLinkURLs() : Observable<any> {
    return this.http.get(`${environment.azure.baseUrl}api/v1/Complaint/GetDPSLinkUrls`)
  }
  setConfidential(id,setTo){
    return this.http.post(`${environment.azure.baseUrl}api/v1/Complaint/SetConfidential?id=${id}&setTo=${setTo}`, id)
  }
  checkProjectExists(projectNumber,projectName) : Observable<any> {
    return this.http.get(`${environment.azure.baseUrl}/api/v1/Data/GetProMISItemDetails?projectNumber=${projectNumber}&projectName=${projectName}`);
  }
  checkSAPQNEnable(key): Observable<any> {
    return this.http.get(environment.azure.baseUrl + 'api/v1/configuration/getByKey?key=' + key);
  }

  ValidateSerialNoFromDPS(serialObj:any){
    return this.http.post(`${environment.azure.baseUrl}api/v1/Complaint/ValidateSerialNoFromDPS`, serialObj);
  }
}



