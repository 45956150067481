<div class="poSettings">
<div class="row mt-3 remove-mt">
  <div class="col-md-3 pe-0">
    <label class="label-align-right  mt-1 poSettingsLabel label-bold">
      {{'dashb_settings_cmp_on_page_lbl'|translate}} :
    </label>
  </div>
  <div class="col-md-9">
    <select class="form-control form-control-sm" [(ngModel)]="pageSize" (change)="updatePageSize(pageSize)">
      <option value="5">5</option>
      <option value="10">10</option>
      <option value="15">15</option>
      <option value="20">20</option>
    </select>
  </div>
  <div class="col-md-3"></div>
</div>
<div class="row mt-3 mb-2 remove-bt">
  <div class="col-sm-7"></div>
  <div class="col-sm-5 text-end">
    <button class="widgetButtonMedium" (click)="cancel()">{{'dashb_settings_cancel_btn' | translate}}</button>
    <button class="widgetButtonMedium ms-1" type="submit" (click)="save()"
      [disabled]="!isDirty" [ngClass]="!isDirty?'grayButton':''">{{'dashb_settings_ok_btn' |
      translate}}</button>
  </div>
</div>
</div>
