import * as i0 from '@angular/core';
import { forwardRef, EventEmitter, Component, ChangeDetectionStrategy, ViewEncapsulation, Output, ContentChild, Input, ContentChildren, NgModule } from '@angular/core';
import * as i1 from '@angular/common';
import { CommonModule } from '@angular/common';
import { Header, PrimeTemplate, SharedModule } from 'primeng/api';
import { DomHandler } from 'primeng/dom';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import Quill from 'quill';
const _c0 = [[["p-header"]]];
const _c1 = ["p-header"];
function Editor_div_1_ng_container_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function Editor_div_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 3);
    i0.ɵɵprojection(1);
    i0.ɵɵtemplate(2, Editor_div_1_ng_container_2_Template, 1, 0, "ng-container", 4);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r0.headerTemplate);
  }
}
function Editor_div_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 3)(1, "span", 5)(2, "select", 6)(3, "option", 7);
    i0.ɵɵtext(4, "Heading");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(5, "option", 8);
    i0.ɵɵtext(6, "Subheading");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(7, "option", 9);
    i0.ɵɵtext(8, "Normal");
    i0.ɵɵelementEnd()();
    i0.ɵɵelementStart(9, "select", 10)(10, "option", 9);
    i0.ɵɵtext(11, "Sans Serif");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(12, "option", 11);
    i0.ɵɵtext(13, "Serif");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(14, "option", 12);
    i0.ɵɵtext(15, "Monospace");
    i0.ɵɵelementEnd()()();
    i0.ɵɵelementStart(16, "span", 5);
    i0.ɵɵelement(17, "button", 13)(18, "button", 14)(19, "button", 15);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(20, "span", 5);
    i0.ɵɵelement(21, "select", 16)(22, "select", 17);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(23, "span", 5);
    i0.ɵɵelement(24, "button", 18)(25, "button", 19);
    i0.ɵɵelementStart(26, "select", 20);
    i0.ɵɵelement(27, "option", 9);
    i0.ɵɵelementStart(28, "option", 21);
    i0.ɵɵtext(29, "center");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(30, "option", 22);
    i0.ɵɵtext(31, "right");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(32, "option", 23);
    i0.ɵɵtext(33, "justify");
    i0.ɵɵelementEnd()()();
    i0.ɵɵelementStart(34, "span", 5);
    i0.ɵɵelement(35, "button", 24)(36, "button", 25)(37, "button", 26);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(38, "span", 5);
    i0.ɵɵelement(39, "button", 27);
    i0.ɵɵelementEnd()();
  }
}
const EDITOR_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => Editor),
  multi: true
};
class Editor {
  constructor(el) {
    this.el = el;
    this.onTextChange = new EventEmitter();
    this.onSelectionChange = new EventEmitter();
    this.onInit = new EventEmitter();
    this.delayedCommand = null;
    this.onModelChange = () => {};
    this.onModelTouched = () => {};
    this.quillElements = null;
  }
  get isAttachedQuillEditorToDOM() {
    return this.quillElements?.editorElement?.isConnected;
  }
  ngAfterViewInit() {
    this.initQuillElements();
    if (this.isAttachedQuillEditorToDOM) {
      this.initQuillEditor();
    }
  }
  ngAfterViewChecked() {
    // The problem is inside the `quill` library, we need to wait for a new release.
    // Function `isLine` - used `getComputedStyle`, it was rewritten in the next release.
    // (We need to wait for a release higher than 1.3.7).
    // These checks and code can be removed.
    if (!this.quill && this.isAttachedQuillEditorToDOM) {
      this.initQuillEditor();
    }
    // Can also be deleted after updating `quill`.
    if (this.delayedCommand && this.isAttachedQuillEditorToDOM) {
      this.delayedCommand();
      this.delayedCommand = null;
    }
  }
  ngAfterContentInit() {
    this.templates.forEach(item => {
      switch (item.getType()) {
        case 'header':
          this.headerTemplate = item.template;
          break;
      }
    });
  }
  writeValue(value) {
    this.value = value;
    if (this.quill) {
      if (value) {
        const command = () => {
          this.quill.setContents(this.quill.clipboard.convert(this.value));
        };
        if (this.isAttachedQuillEditorToDOM) {
          command();
        } else {
          this.delayedCommand = command;
        }
      } else {
        const command = () => {
          this.quill.setText('');
        };
        if (this.isAttachedQuillEditorToDOM) {
          command();
        } else {
          this.delayedCommand = command;
        }
      }
    }
  }
  registerOnChange(fn) {
    this.onModelChange = fn;
  }
  registerOnTouched(fn) {
    this.onModelTouched = fn;
  }
  getQuill() {
    return this.quill;
  }
  get readonly() {
    return this._readonly;
  }
  set readonly(val) {
    this._readonly = val;
    if (this.quill) {
      if (this._readonly) this.quill.disable();else this.quill.enable();
    }
  }
  initQuillEditor() {
    this.initQuillElements();
    const {
      toolbarElement,
      editorElement
    } = this.quillElements;
    let defaultModule = {
      toolbar: toolbarElement
    };
    let modules = this.modules ? {
      ...defaultModule,
      ...this.modules
    } : defaultModule;
    this.quill = new Quill(editorElement, {
      modules: modules,
      placeholder: this.placeholder,
      readOnly: this.readonly,
      theme: 'snow',
      formats: this.formats,
      bounds: this.bounds,
      debug: this.debug,
      scrollingContainer: this.scrollingContainer
    });
    if (this.value) {
      this.quill.setContents(this.quill.clipboard.convert(this.value));
    }
    this.quill.on('text-change', (delta, oldContents, source) => {
      if (source === 'user') {
        let html = DomHandler.findSingle(editorElement, '.ql-editor').innerHTML;
        let text = this.quill.getText().trim();
        if (html === '<p><br></p>') {
          html = null;
        }
        this.onTextChange.emit({
          htmlValue: html,
          textValue: text,
          delta: delta,
          source: source
        });
        this.onModelChange(html);
        this.onModelTouched();
      }
    });
    this.quill.on('selection-change', (range, oldRange, source) => {
      this.onSelectionChange.emit({
        range: range,
        oldRange: oldRange,
        source: source
      });
    });
    this.onInit.emit({
      editor: this.quill
    });
  }
  initQuillElements() {
    if (!this.quillElements) {
      this.quillElements = {
        editorElement: DomHandler.findSingle(this.el.nativeElement, 'div.p-editor-content'),
        toolbarElement: DomHandler.findSingle(this.el.nativeElement, 'div.p-editor-toolbar')
      };
    }
  }
}
Editor.ɵfac = function Editor_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || Editor)(i0.ɵɵdirectiveInject(i0.ElementRef));
};
Editor.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: Editor,
  selectors: [["p-editor"]],
  contentQueries: function Editor_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, Header, 5);
      i0.ɵɵcontentQuery(dirIndex, PrimeTemplate, 4);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.toolbar = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.templates = _t);
    }
  },
  hostAttrs: [1, "p-element"],
  inputs: {
    style: "style",
    styleClass: "styleClass",
    placeholder: "placeholder",
    formats: "formats",
    modules: "modules",
    bounds: "bounds",
    scrollingContainer: "scrollingContainer",
    debug: "debug",
    readonly: "readonly"
  },
  outputs: {
    onTextChange: "onTextChange",
    onSelectionChange: "onSelectionChange",
    onInit: "onInit"
  },
  features: [i0.ɵɵProvidersFeature([EDITOR_VALUE_ACCESSOR])],
  ngContentSelectors: _c1,
  decls: 4,
  vars: 6,
  consts: [[3, "ngClass"], ["class", "p-editor-toolbar", 4, "ngIf"], [1, "p-editor-content", 3, "ngStyle"], [1, "p-editor-toolbar"], [4, "ngTemplateOutlet"], [1, "ql-formats"], [1, "ql-header"], ["value", "1"], ["value", "2"], ["selected", ""], [1, "ql-font"], ["value", "serif"], ["value", "monospace"], ["aria-label", "Bold", "type", "button", 1, "ql-bold"], ["aria-label", "Italic", "type", "button", 1, "ql-italic"], ["aria-label", "Underline", "type", "button", 1, "ql-underline"], [1, "ql-color"], [1, "ql-background"], ["value", "ordered", "aria-label", "Ordered List", "type", "button", 1, "ql-list"], ["value", "bullet", "aria-label", "Unordered List", "type", "button", 1, "ql-list"], [1, "ql-align"], ["value", "center"], ["value", "right"], ["value", "justify"], ["aria-label", "Insert Link", "type", "button", 1, "ql-link"], ["aria-label", "Insert Image", "type", "button", 1, "ql-image"], ["aria-label", "Insert Code Block", "type", "button", 1, "ql-code-block"], ["aria-label", "Remove Styles", "type", "button", 1, "ql-clean"]],
  template: function Editor_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef(_c0);
      i0.ɵɵelementStart(0, "div", 0);
      i0.ɵɵtemplate(1, Editor_div_1_Template, 3, 1, "div", 1)(2, Editor_div_2_Template, 40, 0, "div", 1);
      i0.ɵɵelement(3, "div", 2);
      i0.ɵɵelementEnd();
    }
    if (rf & 2) {
      i0.ɵɵclassMap(ctx.styleClass);
      i0.ɵɵproperty("ngClass", "p-editor-container");
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngIf", ctx.toolbar || ctx.headerTemplate);
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngIf", !ctx.toolbar && !ctx.headerTemplate);
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngStyle", ctx.style);
    }
  },
  dependencies: [i1.NgClass, i1.NgIf, i1.NgTemplateOutlet, i1.NgStyle],
  styles: [".p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options .ql-picker-item{width:auto;height:auto}\n"],
  encapsulation: 2,
  changeDetection: 0
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(Editor, [{
    type: Component,
    args: [{
      selector: 'p-editor',
      template: `
        <div [ngClass]="'p-editor-container'" [class]="styleClass">
            <div class="p-editor-toolbar" *ngIf="toolbar || headerTemplate">
                <ng-content select="p-header"></ng-content>
                <ng-container *ngTemplateOutlet="headerTemplate"></ng-container>
            </div>
            <div class="p-editor-toolbar" *ngIf="!toolbar && !headerTemplate">
                <span class="ql-formats">
                    <select class="ql-header">
                        <option value="1">Heading</option>
                        <option value="2">Subheading</option>
                        <option selected>Normal</option>
                    </select>
                    <select class="ql-font">
                        <option selected>Sans Serif</option>
                        <option value="serif">Serif</option>
                        <option value="monospace">Monospace</option>
                    </select>
                </span>
                <span class="ql-formats">
                    <button class="ql-bold" aria-label="Bold" type="button"></button>
                    <button class="ql-italic" aria-label="Italic" type="button"></button>
                    <button class="ql-underline" aria-label="Underline" type="button"></button>
                </span>
                <span class="ql-formats">
                    <select class="ql-color"></select>
                    <select class="ql-background"></select>
                </span>
                <span class="ql-formats">
                    <button class="ql-list" value="ordered" aria-label="Ordered List" type="button"></button>
                    <button class="ql-list" value="bullet" aria-label="Unordered List" type="button"></button>
                    <select class="ql-align">
                        <option selected></option>
                        <option value="center">center</option>
                        <option value="right">right</option>
                        <option value="justify">justify</option>
                    </select>
                </span>
                <span class="ql-formats">
                    <button class="ql-link" aria-label="Insert Link" type="button"></button>
                    <button class="ql-image" aria-label="Insert Image" type="button"></button>
                    <button class="ql-code-block" aria-label="Insert Code Block" type="button"></button>
                </span>
                <span class="ql-formats">
                    <button class="ql-clean" aria-label="Remove Styles" type="button"></button>
                </span>
            </div>
            <div class="p-editor-content" [ngStyle]="style"></div>
        </div>
    `,
      providers: [EDITOR_VALUE_ACCESSOR],
      changeDetection: ChangeDetectionStrategy.OnPush,
      encapsulation: ViewEncapsulation.None,
      host: {
        class: 'p-element'
      },
      styles: [".p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options .ql-picker-item{width:auto;height:auto}\n"]
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }];
  }, {
    onTextChange: [{
      type: Output
    }],
    onSelectionChange: [{
      type: Output
    }],
    toolbar: [{
      type: ContentChild,
      args: [Header]
    }],
    style: [{
      type: Input
    }],
    styleClass: [{
      type: Input
    }],
    placeholder: [{
      type: Input
    }],
    formats: [{
      type: Input
    }],
    modules: [{
      type: Input
    }],
    bounds: [{
      type: Input
    }],
    scrollingContainer: [{
      type: Input
    }],
    debug: [{
      type: Input
    }],
    onInit: [{
      type: Output
    }],
    templates: [{
      type: ContentChildren,
      args: [PrimeTemplate]
    }],
    readonly: [{
      type: Input
    }]
  });
})();
class EditorModule {}
EditorModule.ɵfac = function EditorModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || EditorModule)();
};
EditorModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: EditorModule
});
EditorModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [CommonModule, SharedModule]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(EditorModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule],
      exports: [Editor, SharedModule],
      declarations: [Editor]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { EDITOR_VALUE_ACCESSOR, Editor, EditorModule };

