import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PgrcaAdminServiceService {

  constructor(private http: HttpClient) { }
  getDivision():Observable<any> {
    return this.http.get(`${environment.azure.baseUrl}api/v1/divisions`);
    }
   getBuList(division): Observable<any> {
     return this.http.post(`${environment.azure.baseUrl}api/v1/Data/GetDivisionBUList?division=` + division, division);
   }
   getBU(data,data1):Observable<any> {
    return this.http.get(`${environment.azure.baseUrl}api/v1/divisions/${data}/bu/${data1}/pg`);
  }
  getDataForAdminPgrcaCat(pg):Observable<any>{
    return this.http.get(`${environment.azure.baseUrl}api/v1/categories/pg/${pg}`);
  }
  getDataForAdminPgrcaCatProcessOwner(data):Observable<any>{
    return this.http.post(`${environment.azure.baseUrl}api/v1/categories/pg/procesOwners`,data);
  }
  getDataForAdminPgrcaCatEmployeeInfo(data1):Observable<any>{
    return this.http.post(`${environment.azure.baseUrl}api/v1/dashboard/employeeinfo`,data1);
  }
  getDataForAdminPgrcaCatSubChild(pg,id):Observable<any>{
    return this.http.get(`${environment.azure.baseUrl}api/v1/categories/pg/${pg}/${id}`);
  }
  addChildPgrcaCat(pg,payloadData){
    const pgNumber = Number(pg);
    return this.http.post(`${environment.azure.baseUrl}api/v1/categories/pg/${pgNumber}`,payloadData)
  }
  editChildPgrcaCat(pg,payloadData):Observable<any>{
    return this.http.put(`${environment.azure.baseUrl}api/v1/categories/pg/${pg}`,payloadData)
  }
  deactivatePgrcaCat(pg,payloadData):Observable<any>{
    return this.http.patch(`${environment.azure.baseUrl}api/v1/categories/pg/${pg}`,payloadData)
  }
  // Admin failure mode
  getDataForAdminFailureMode(pg):Observable<any>{
    return this.http.get(`${environment.azure.baseUrl}api/v1/failuremode/pg/${pg}`);
  }
  getDataForAdminFailureModeSubChild(pg,id):Observable<any>{
    return this.http.get(`${environment.azure.baseUrl}api/v1/failuremode/pg/${pg}/${id}`);
  }
}

