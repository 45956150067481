import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root',
})
export class IssueResolutionServiceService {
  x= `${environment.API_URL}/issues`;
  file:any;
  constructor(private http: HttpClient) {}


  issueResolutionPostData(data:any) {

    return this.http.post(`${environment.API_URL}/issueResolutionPost`, data);

  }

  getEmployeeList(): Observable<any> {
    return this.http.get(`${environment.API_URL}/issues`);
  }

  // for lesson learned
  getdetails( )
{
  return this.http.get(this.x)
}

addEmployee(data:any): Observable<any>{
  return this.http.post(`${environment.API_URL}/issues`,data);
}
getEmployeeListLessonLearned(complaintId): Observable<any>{

    return this.http.get(`${environment.azure.baseUrl}api/v1/complaintLessonLearnt/lessonLearnts?complaintId=${complaintId}`);
}
addLessonLearned(data): Observable<any>{

    return this.http.post(`${environment.azure.baseUrl}api/v1/complaintLessonLearnt/addLessonLearnt`,data);
}
updatedetails(userdata:any):Observable<any>{
  // console.log(userdata)
  return this.http.put(`${`${environment.API_URL}/issues`}/${userdata.id}`,userdata);
    }
   deleteUser(id:number){
     return this.http.delete(`${this.x}/${id}`)

   }

   getActionItems(cId, pageNumber):Observable<any>{
      return this.http.post(`${environment.azure.baseUrl}api/v1/Data/GetActionItems?complaintNo=` + cId + `&page=` +pageNumber, cId);
   }

   getResolvingUnitBalId(ruId):Observable<any>{
    return this.http.get(`${environment.azure.baseUrl}api/v1/resolvingunit/GetResolvingUnit?resolvingunitId=` + ruId);
   }

   ratingConfiguration = {
    "scoringMatrixOriginalRating": {
      "id": 40725,
      "complaintId": 680248,
      "rpn": 3,
      "class": 0,
      "type": 0,
      "isActive": true,
      "selection": [
          {
              "id": 1,
              "categoryId": 1,
              "selectionId": 2,
              "sortOrder": 1,
              "isActive": true,
              "category": {
                  "id": 1,
                  "name": "Availability",
                  "domain": 1,
                  "sortOrder": 1,
                  "isActive": true,
                  "activateDate": "2020-06-02T07:17:09.757",
                  "deactivateDate": null,
                  "matrixSeverity": [
                      {
                          "id": 1,
                          "description": "Category not applicable",
                          "categoryId": 1,
                          "weight": 0,
                          "sortOrder": 1,
                          "isActive": true,
                          "activateDate": "2020-06-02T07:17:09.787",
                          "deactivateDate": null
                      },
                      {
                          "id": 2,
                          "description": "No impact to availability",
                          "categoryId": 1,
                          "weight": 1,
                          "sortOrder": 2,
                          "isActive": true,
                          "activateDate": "2020-06-02T07:17:09.787",
                          "deactivateDate": null
                      },
                      {
                          "id": 3,
                          "description": "Installed / delivered product or system unavailable for <1 day",
                          "categoryId": 1,
                          "weight": 3,
                          "sortOrder": 2,
                          "isActive": true,
                          "activateDate": "2020-06-02T07:17:09.787",
                          "deactivateDate": null
                      },
                      {
                          "id": 4,
                          "description": "1. Installed / delivered product or system unavailable for > 1 day but < 1 week 2. Unexpected service black out affecting public service (< 10 mins)",
                          "categoryId": 1,
                          "weight": 7,
                          "sortOrder": 3,
                          "isActive": true,
                          "activateDate": "2020-06-02T07:17:09.787",
                          "deactivateDate": null
                      },
                      {
                          "id": 5,
                          "description": "1. Installed / delivered product or system unavailable for more than 1 week 2. Unexpected service black out affect public service significantly (> 10 mins)",
                          "categoryId": 1,
                          "weight": 10,
                          "sortOrder": 4,
                          "isActive": true,
                          "activateDate": "2020-06-02T07:17:09.787",
                          "deactivateDate": null
                      }
                  ]
              },
              "severities": [
                  {
                      "id": 1,
                      "description": "Category not applicable",
                      "categoryId": 1,
                      "weight": 0,
                      "sortOrder": 1,
                      "isActive": true,
                      "activateDate": "2020-06-02T07:17:09.787",
                      "deactivateDate": null
                  },
                  {
                      "id": 2,
                      "description": "No impact to availability",
                      "categoryId": 1,
                      "weight": 1,
                      "sortOrder": 2,
                      "isActive": true,
                      "activateDate": "2020-06-02T07:17:09.787",
                      "deactivateDate": null
                  },
                  {
                      "id": 3,
                      "description": "Installed / delivered product or system unavailable for <1 day",
                      "categoryId": 1,
                      "weight": 3,
                      "sortOrder": 2,
                      "isActive": true,
                      "activateDate": "2020-06-02T07:17:09.787",
                      "deactivateDate": null
                  },
                  {
                      "id": 4,
                      "description": "1. Installed / delivered product or system unavailable for > 1 day but < 1 week 2. Unexpected service black out affecting public service (< 10 mins)",
                      "categoryId": 1,
                      "weight": 7,
                      "sortOrder": 3,
                      "isActive": true,
                      "activateDate": "2020-06-02T07:17:09.787",
                      "deactivateDate": null
                  },
                  {
                      "id": 5,
                      "description": "1. Installed / delivered product or system unavailable for more than 1 week 2. Unexpected service black out affect public service significantly (> 10 mins)",
                      "categoryId": 1,
                      "weight": 10,
                      "sortOrder": 4,
                      "isActive": true,
                      "activateDate": "2020-06-02T07:17:09.787",
                      "deactivateDate": null
                  }
              ],
              "activateDate": "2020-06-02T07:17:09.757",
              "deactivateDate": null
          },
          {
              "id": 2,
              "categoryId": 2,
              "selectionId": 7,
              "sortOrder": 2,
              "isActive": true,
              "category": {
                  "id": 2,
                  "name": "Functionality",
                  "domain": 1,
                  "sortOrder": 2,
                  "isActive": true,
                  "activateDate": "2020-06-02T07:17:09.757",
                  "deactivateDate": null,
                  "matrixSeverity": [
                      {
                          "id": 6,
                          "description": "Category not applicable",
                          "categoryId": 2,
                          "weight": 0,
                          "sortOrder": 1,
                          "isActive": true,
                          "activateDate": "2020-06-02T07:17:09.787",
                          "deactivateDate": null
                      },
                      {
                          "id": 7,
                          "description": "No significant effect",
                          "categoryId": 2,
                          "weight": 1,
                          "sortOrder": 2,
                          "isActive": true,
                          "activateDate": "2020-06-02T07:17:09.787",
                          "deactivateDate": null
                      },
                      {
                          "id": 8,
                          "description": "1. Quality of appearance unacceptable to customer 2. product performance decreased less than 10%",
                          "categoryId": 2,
                          "weight": 3,
                          "sortOrder": 2,
                          "isActive": true,
                          "activateDate": "2020-06-02T07:17:09.787",
                          "deactivateDate": null
                      },
                      {
                          "id": 9,
                          "description": "Loss or degradation of essential function necessary for normal work before end of warranty. (performance decreased by 10% or more but less than 30%)",
                          "categoryId": 2,
                          "weight": 7,
                          "sortOrder": 3,
                          "isActive": true,
                          "activateDate": "2020-06-02T07:17:09.787",
                          "deactivateDate": null
                      },
                      {
                          "id": 10,
                          "description": "Loss or degradation of essential function necessary to normal work before ending of warranty (performance decreased more than 30%)",
                          "categoryId": 2,
                          "weight": 10,
                          "sortOrder": 4,
                          "isActive": true,
                          "activateDate": "2020-06-02T07:17:09.787",
                          "deactivateDate": null
                      },
                      {
                          "id": 71,
                          "description": "Loss or degradation of essential function necessary for normal work out of warranty time and before end of service life",
                          "categoryId": 2,
                          "weight": 5,
                          "sortOrder": 2,
                          "isActive": true,
                          "activateDate": "2021-10-14T04:01:39.163",
                          "deactivateDate": null
                      }
                  ]
              },
              "severities": [
                  {
                      "id": 6,
                      "description": "Category not applicable",
                      "categoryId": 2,
                      "weight": 0,
                      "sortOrder": 1,
                      "isActive": true,
                      "activateDate": "2020-06-02T07:17:09.787",
                      "deactivateDate": null
                  },
                  {
                      "id": 7,
                      "description": "No significant effect",
                      "categoryId": 2,
                      "weight": 1,
                      "sortOrder": 2,
                      "isActive": true,
                      "activateDate": "2020-06-02T07:17:09.787",
                      "deactivateDate": null
                  },
                  {
                      "id": 8,
                      "description": "1. Quality of appearance unacceptable to customer 2. product performance decreased less than 10%",
                      "categoryId": 2,
                      "weight": 3,
                      "sortOrder": 2,
                      "isActive": true,
                      "activateDate": "2020-06-02T07:17:09.787",
                      "deactivateDate": null
                  },
                  {
                      "id": 9,
                      "description": "Loss or degradation of essential function necessary for normal work before end of warranty. (performance decreased by 10% or more but less than 30%)",
                      "categoryId": 2,
                      "weight": 7,
                      "sortOrder": 3,
                      "isActive": true,
                      "activateDate": "2020-06-02T07:17:09.787",
                      "deactivateDate": null
                  },
                  {
                      "id": 10,
                      "description": "Loss or degradation of essential function necessary to normal work before ending of warranty (performance decreased more than 30%)",
                      "categoryId": 2,
                      "weight": 10,
                      "sortOrder": 4,
                      "isActive": true,
                      "activateDate": "2020-06-02T07:17:09.787",
                      "deactivateDate": null
                  },
                  {
                      "id": 71,
                      "description": "Loss or degradation of essential function necessary for normal work out of warranty time and before end of service life",
                      "categoryId": 2,
                      "weight": 5,
                      "sortOrder": 2,
                      "isActive": true,
                      "activateDate": "2021-10-14T04:01:39.163",
                      "deactivateDate": null
                  }
              ],
              "activateDate": "2020-06-02T07:17:09.757",
              "deactivateDate": null
          },
          {
              "id": 3,
              "categoryId": 3,
              "selectionId": 12,
              "sortOrder": 3,
              "isActive": true,
              "category": {
                  "id": 3,
                  "name": "On Time Delivery",
                  "domain": 1,
                  "sortOrder": 3,
                  "isActive": true,
                  "activateDate": "2020-06-02T07:17:09.757",
                  "deactivateDate": null,
                  "matrixSeverity": [
                      {
                          "id": 11,
                          "description": "Category not applicable",
                          "categoryId": 3,
                          "weight": 0,
                          "sortOrder": 1,
                          "isActive": true,
                          "activateDate": "2020-06-02T07:17:09.787",
                          "deactivateDate": null
                      },
                      {
                          "id": 12,
                          "description": "1. No risk to OTD 2. Components are delayed being delivered to a project",
                          "categoryId": 3,
                          "weight": 1,
                          "sortOrder": 2,
                          "isActive": true,
                          "activateDate": "2020-06-02T07:17:09.787",
                          "deactivateDate": null
                      },
                      {
                          "id": 13,
                          "description": "Non-essential components / products are delivered late to a customer",
                          "categoryId": 3,
                          "weight": 3,
                          "sortOrder": 2,
                          "isActive": true,
                          "activateDate": "2020-06-02T07:17:09.787",
                          "deactivateDate": null
                      },
                      {
                          "id": 14,
                          "description": "Essential components / products are delivered late to a customer impacting customer performance negatively and / or triggering contractual consequences",
                          "categoryId": 3,
                          "weight": 7,
                          "sortOrder": 3,
                          "isActive": true,
                          "activateDate": "2020-06-02T07:17:09.787",
                          "deactivateDate": null
                      },
                      {
                          "id": 15,
                          "description": "Essential components / products are delivered late to a customer impacting customer performance severely financially / operational and / or triggering severe contractual consequences",
                          "categoryId": 3,
                          "weight": 10,
                          "sortOrder": 4,
                          "isActive": true,
                          "activateDate": "2020-06-02T07:17:09.787",
                          "deactivateDate": null
                      },
                      {
                          "id": 72,
                          "description": "Essential products are not delivered on time causing customer dissatisfaction",
                          "categoryId": 3,
                          "weight": 5,
                          "sortOrder": 2,
                          "isActive": true,
                          "activateDate": "2021-10-14T04:01:39.163",
                          "deactivateDate": null
                      }
                  ]
              },
              "severities": [
                  {
                      "id": 11,
                      "description": "Category not applicable",
                      "categoryId": 3,
                      "weight": 0,
                      "sortOrder": 1,
                      "isActive": true,
                      "activateDate": "2020-06-02T07:17:09.787",
                      "deactivateDate": null
                  },
                  {
                      "id": 12,
                      "description": "1. No risk to OTD 2. Components are delayed being delivered to a project",
                      "categoryId": 3,
                      "weight": 1,
                      "sortOrder": 2,
                      "isActive": true,
                      "activateDate": "2020-06-02T07:17:09.787",
                      "deactivateDate": null
                  },
                  {
                      "id": 13,
                      "description": "Non-essential components / products are delivered late to a customer",
                      "categoryId": 3,
                      "weight": 3,
                      "sortOrder": 2,
                      "isActive": true,
                      "activateDate": "2020-06-02T07:17:09.787",
                      "deactivateDate": null
                  },
                  {
                      "id": 14,
                      "description": "Essential components / products are delivered late to a customer impacting customer performance negatively and / or triggering contractual consequences",
                      "categoryId": 3,
                      "weight": 7,
                      "sortOrder": 3,
                      "isActive": true,
                      "activateDate": "2020-06-02T07:17:09.787",
                      "deactivateDate": null
                  },
                  {
                      "id": 15,
                      "description": "Essential components / products are delivered late to a customer impacting customer performance severely financially / operational and / or triggering severe contractual consequences",
                      "categoryId": 3,
                      "weight": 10,
                      "sortOrder": 4,
                      "isActive": true,
                      "activateDate": "2020-06-02T07:17:09.787",
                      "deactivateDate": null
                  },
                  {
                      "id": 72,
                      "description": "Essential products are not delivered on time causing customer dissatisfaction",
                      "categoryId": 3,
                      "weight": 5,
                      "sortOrder": 2,
                      "isActive": true,
                      "activateDate": "2021-10-14T04:01:39.163",
                      "deactivateDate": null
                  }
              ],
              "activateDate": "2020-06-02T07:17:09.757",
              "deactivateDate": null
          },
          {
              "id": 4,
              "categoryId": 4,
              "selectionId": 17,
              "sortOrder": 4,
              "isActive": true,
              "category": {
                  "id": 4,
                  "name": "Cyber Security",
                  "domain": 1,
                  "sortOrder": 4,
                  "isActive": true,
                  "activateDate": "2020-06-02T07:17:09.757",
                  "deactivateDate": null,
                  "matrixSeverity": [
                      {
                          "id": 16,
                          "description": "Category not applicable",
                          "categoryId": 4,
                          "weight": 0,
                          "sortOrder": 1,
                          "isActive": true,
                          "activateDate": "2020-06-02T07:17:09.787",
                          "deactivateDate": null
                      },
                      {
                          "id": 17,
                          "description": "Low impact incident. Remedial action may be required but not as a matter of urgency",
                          "categoryId": 4,
                          "weight": 1,
                          "sortOrder": 2,
                          "isActive": true,
                          "activateDate": "2020-06-02T07:17:09.787",
                          "deactivateDate": null
                      },
                      {
                          "id": 18,
                          "description": "Requires prompt remediation but is unlikely to result in business operations disruption, loss of sensitive data or impact on Human life",
                          "categoryId": 4,
                          "weight": 3,
                          "sortOrder": 2,
                          "isActive": true,
                          "activateDate": "2020-06-02T07:17:09.787",
                          "deactivateDate": null
                      },
                      {
                          "id": 19,
                          "description": "Requires immediate action. Sensitive data, human life and/or major business operations are likely to be affected",
                          "categoryId": 4,
                          "weight": 7,
                          "sortOrder": 3,
                          "isActive": true,
                          "activateDate": "2020-06-02T07:17:09.787",
                          "deactivateDate": null
                      },
                      {
                          "id": 20,
                          "description": "Requires immediate action. Sensitive data, human life and/or major business operations are already, or highly likely to be, affected",
                          "categoryId": 4,
                          "weight": 10,
                          "sortOrder": 4,
                          "isActive": true,
                          "activateDate": "2020-06-02T07:17:09.787",
                          "deactivateDate": null
                      }
                  ]
              },
              "severities": [
                  {
                      "id": 16,
                      "description": "Category not applicable",
                      "categoryId": 4,
                      "weight": 0,
                      "sortOrder": 1,
                      "isActive": true,
                      "activateDate": "2020-06-02T07:17:09.787",
                      "deactivateDate": null
                  },
                  {
                      "id": 17,
                      "description": "Low impact incident. Remedial action may be required but not as a matter of urgency",
                      "categoryId": 4,
                      "weight": 1,
                      "sortOrder": 2,
                      "isActive": true,
                      "activateDate": "2020-06-02T07:17:09.787",
                      "deactivateDate": null
                  },
                  {
                      "id": 18,
                      "description": "Requires prompt remediation but is unlikely to result in business operations disruption, loss of sensitive data or impact on Human life",
                      "categoryId": 4,
                      "weight": 3,
                      "sortOrder": 2,
                      "isActive": true,
                      "activateDate": "2020-06-02T07:17:09.787",
                      "deactivateDate": null
                  },
                  {
                      "id": 19,
                      "description": "Requires immediate action. Sensitive data, human life and/or major business operations are likely to be affected",
                      "categoryId": 4,
                      "weight": 7,
                      "sortOrder": 3,
                      "isActive": true,
                      "activateDate": "2020-06-02T07:17:09.787",
                      "deactivateDate": null
                  },
                  {
                      "id": 20,
                      "description": "Requires immediate action. Sensitive data, human life and/or major business operations are already, or highly likely to be, affected",
                      "categoryId": 4,
                      "weight": 10,
                      "sortOrder": 4,
                      "isActive": true,
                      "activateDate": "2020-06-02T07:17:09.787",
                      "deactivateDate": null
                  }
              ],
              "activateDate": "2020-06-02T07:17:09.757",
              "deactivateDate": null
          },
          {
              "id": 5,
              "categoryId": 5,
              "selectionId": 21,
              "sortOrder": 5,
              "isActive": true,
              "category": {
                  "id": 5,
                  "name": "Product Compliance",
                  "domain": 1,
                  "sortOrder": 5,
                  "isActive": true,
                  "activateDate": "2020-06-02T07:17:09.757",
                  "deactivateDate": null,
                  "matrixSeverity": [
                      {
                          "id": 21,
                          "description": "Category not applicable",
                          "categoryId": 5,
                          "weight": 0,
                          "sortOrder": 1,
                          "isActive": true,
                          "activateDate": "2020-06-02T07:17:09.787",
                          "deactivateDate": null
                      },
                      {
                          "id": 24,
                          "description": "Products adhere to standard but customers complained",
                          "categoryId": 5,
                          "weight": 7,
                          "sortOrder": 3,
                          "isActive": true,
                          "activateDate": "2020-06-02T07:17:09.787",
                          "deactivateDate": null
                      },
                      {
                          "id": 25,
                          "description": "1. Product or process modification requested by public authorities due to product or process violation of regulatory requirements,  like national, regional or international product regulations 2. Product compliance issue by continuously shipping products which are not complying to customer requirements",
                          "categoryId": 5,
                          "weight": 10,
                          "sortOrder": 4,
                          "isActive": true,
                          "activateDate": "2020-06-02T07:17:09.787",
                          "deactivateDate": null
                      }
                  ]
              },
              "severities": [
                  {
                      "id": 21,
                      "description": "Category not applicable",
                      "categoryId": 5,
                      "weight": 0,
                      "sortOrder": 1,
                      "isActive": true,
                      "activateDate": "2020-06-02T07:17:09.787",
                      "deactivateDate": null
                  },
                  {
                      "id": 24,
                      "description": "Products adhere to standard but customers complained",
                      "categoryId": 5,
                      "weight": 7,
                      "sortOrder": 3,
                      "isActive": true,
                      "activateDate": "2020-06-02T07:17:09.787",
                      "deactivateDate": null
                  },
                  {
                      "id": 25,
                      "description": "1. Product or process modification requested by public authorities due to product or process violation of regulatory requirements,  like national, regional or international product regulations 2. Product compliance issue by continuously shipping products which are not complying to customer requirements",
                      "categoryId": 5,
                      "weight": 10,
                      "sortOrder": 4,
                      "isActive": true,
                      "activateDate": "2020-06-02T07:17:09.787",
                      "deactivateDate": null
                  }
              ],
              "activateDate": "2020-06-02T07:17:09.757",
              "deactivateDate": null
          },
          {
              "id": 6,
              "categoryId": 6,
              "selectionId": 28,
              "sortOrder": 6,
              "isActive": true,
              "category": {
                  "id": 6,
                  "name": "OHS Risk",
                  "domain": 1,
                  "sortOrder": 6,
                  "isActive": true,
                  "activateDate": "2020-06-02T07:17:09.757",
                  "deactivateDate": null,
                  "matrixSeverity": [
                      {
                          "id": 27,
                          "description": "No risk of injury",
                          "categoryId": 6,
                          "weight": 1,
                          "sortOrder": 2,
                          "isActive": true,
                          "activateDate": "2020-06-02T07:17:09.787",
                          "deactivateDate": null
                      },
                      {
                          "id": 28,
                          "description": "Low risk of injury",
                          "categoryId": 6,
                          "weight": 3,
                          "sortOrder": 2,
                          "isActive": true,
                          "activateDate": "2020-06-02T07:17:09.787",
                          "deactivateDate": null
                      },
                      {
                          "id": 29,
                          "description": "1.High risk of injury with potential for hospitalization 2.Injury has already occurred (no hospitalization necessary)",
                          "categoryId": 6,
                          "weight": 7,
                          "sortOrder": 3,
                          "isActive": true,
                          "activateDate": "2020-06-02T07:17:09.787",
                          "deactivateDate": null
                      },
                      {
                          "id": 30,
                          "description": "Injury has already occurred (People hospitalized)",
                          "categoryId": 6,
                          "weight": 10,
                          "sortOrder": 4,
                          "isActive": true,
                          "activateDate": "2020-06-02T07:17:09.787",
                          "deactivateDate": null
                      }
                  ]
              },
              "severities": [
                  {
                      "id": 27,
                      "description": "No risk of injury",
                      "categoryId": 6,
                      "weight": 1,
                      "sortOrder": 2,
                      "isActive": true,
                      "activateDate": "2020-06-02T07:17:09.787",
                      "deactivateDate": null
                  },
                  {
                      "id": 28,
                      "description": "Low risk of injury",
                      "categoryId": 6,
                      "weight": 3,
                      "sortOrder": 2,
                      "isActive": true,
                      "activateDate": "2020-06-02T07:17:09.787",
                      "deactivateDate": null
                  },
                  {
                      "id": 29,
                      "description": "1.High risk of injury with potential for hospitalization 2.Injury has already occurred (no hospitalization necessary)",
                      "categoryId": 6,
                      "weight": 7,
                      "sortOrder": 3,
                      "isActive": true,
                      "activateDate": "2020-06-02T07:17:09.787",
                      "deactivateDate": null
                  },
                  {
                      "id": 30,
                      "description": "Injury has already occurred (People hospitalized)",
                      "categoryId": 6,
                      "weight": 10,
                      "sortOrder": 4,
                      "isActive": true,
                      "activateDate": "2020-06-02T07:17:09.787",
                      "deactivateDate": null
                  }
              ],
              "activateDate": "2020-06-02T07:17:09.757",
              "deactivateDate": null
          },
          {
              "id": 7,
              "categoryId": 7,
              "selectionId": 31,
              "sortOrder": 7,
              "isActive": true,
              "category": {
                  "id": 7,
                  "name": "Margin",
                  "domain": 1,
                  "sortOrder": 7,
                  "isActive": true,
                  "activateDate": "2020-06-02T07:17:09.757",
                  "deactivateDate": null,
                  "matrixSeverity": [
                      {
                          "id": 31,
                          "description": "Category not applicable",
                          "categoryId": 7,
                          "weight": 0,
                          "sortOrder": 1,
                          "isActive": true,
                          "activateDate": "2020-06-02T07:17:09.787",
                          "deactivateDate": null
                      },
                      {
                          "id": 32,
                          "description": "COPQ or penalties below 200 kUSD",
                          "categoryId": 7,
                          "weight": 1,
                          "sortOrder": 2,
                          "isActive": true,
                          "activateDate": "2020-06-02T07:17:09.787",
                          "deactivateDate": null
                      },
                      {
                          "id": 33,
                          "description": "COPQ or penalties from 200 kUSD to 850 kUSD",
                          "categoryId": 7,
                          "weight": 3,
                          "sortOrder": 2,
                          "isActive": true,
                          "activateDate": "2020-06-02T07:17:09.787",
                          "deactivateDate": null
                      },
                      {
                          "id": 34,
                          "description": "COPQ from 850 kUSD to 1.5 MUSD",
                          "categoryId": 7,
                          "weight": 7,
                          "sortOrder": 3,
                          "isActive": false,
                          "activateDate": "2020-06-02T07:17:09.787",
                          "deactivateDate": "2021-10-14T04:01:39.477"
                      },
                      {
                          "id": 35,
                          "description": "COPQ higher that 1.5 MUSD",
                          "categoryId": 7,
                          "weight": 10,
                          "sortOrder": 4,
                          "isActive": false,
                          "activateDate": "2020-06-02T07:17:09.787",
                          "deactivateDate": "2021-10-14T04:01:39.477"
                      },
                      {
                          "id": 73,
                          "description": "COPQ or penalties higher than 850 kUSD",
                          "categoryId": 7,
                          "weight": 5,
                          "sortOrder": 2,
                          "isActive": true,
                          "activateDate": "2021-10-14T04:01:39.163",
                          "deactivateDate": null
                      }
                  ]
              },
              "severities": [
                  {
                      "id": 31,
                      "description": "Category not applicable",
                      "categoryId": 7,
                      "weight": 0,
                      "sortOrder": 1,
                      "isActive": true,
                      "activateDate": "2020-06-02T07:17:09.787",
                      "deactivateDate": null
                  },
                  {
                      "id": 32,
                      "description": "COPQ or penalties below 200 kUSD",
                      "categoryId": 7,
                      "weight": 1,
                      "sortOrder": 2,
                      "isActive": true,
                      "activateDate": "2020-06-02T07:17:09.787",
                      "deactivateDate": null
                  },
                  {
                      "id": 33,
                      "description": "COPQ or penalties from 200 kUSD to 850 kUSD",
                      "categoryId": 7,
                      "weight": 3,
                      "sortOrder": 2,
                      "isActive": true,
                      "activateDate": "2020-06-02T07:17:09.787",
                      "deactivateDate": null
                  },
                  {
                      "id": 34,
                      "description": "COPQ from 850 kUSD to 1.5 MUSD",
                      "categoryId": 7,
                      "weight": 7,
                      "sortOrder": 3,
                      "isActive": false,
                      "activateDate": "2020-06-02T07:17:09.787",
                      "deactivateDate": "2021-10-14T04:01:39.477"
                  },
                  {
                      "id": 35,
                      "description": "COPQ higher that 1.5 MUSD",
                      "categoryId": 7,
                      "weight": 10,
                      "sortOrder": 4,
                      "isActive": false,
                      "activateDate": "2020-06-02T07:17:09.787",
                      "deactivateDate": "2021-10-14T04:01:39.477"
                  },
                  {
                      "id": 73,
                      "description": "COPQ or penalties higher than 850 kUSD",
                      "categoryId": 7,
                      "weight": 5,
                      "sortOrder": 2,
                      "isActive": true,
                      "activateDate": "2021-10-14T04:01:39.163",
                      "deactivateDate": null
                  }
              ],
              "activateDate": "2020-06-02T07:17:09.757",
              "deactivateDate": null
          },
          {
              "id": 8,
              "categoryId": 8,
              "selectionId": 37,
              "sortOrder": 8,
              "isActive": true,
              "category": {
                  "id": 8,
                  "name": "Reputation",
                  "domain": 1,
                  "sortOrder": 8,
                  "isActive": true,
                  "activateDate": "2020-06-02T07:17:09.757",
                  "deactivateDate": null,
                  "matrixSeverity": [
                      {
                          "id": 37,
                          "description": "No Impact to PG Reputation",
                          "categoryId": 8,
                          "weight": 1,
                          "sortOrder": 2,
                          "isActive": true,
                          "activateDate": "2020-06-02T07:17:09.787",
                          "deactivateDate": null
                      },
                      {
                          "id": 38,
                          "description": "Risk of Customer Complaint",
                          "categoryId": 8,
                          "weight": 3,
                          "sortOrder": 2,
                          "isActive": true,
                          "activateDate": "2020-06-02T07:17:09.787",
                          "deactivateDate": null
                      },
                      {
                          "id": 39,
                          "description": "Written evidence (letter / minutes of meeting) that customer executive considers 1.it a severe incident 2.Withdrawing future orders",
                          "categoryId": 8,
                          "weight": 7,
                          "sortOrder": 3,
                          "isActive": true,
                          "activateDate": "2020-06-02T07:17:09.787",
                          "deactivateDate": null
                      },
                      {
                          "id": 40,
                          "description": "1.Removed from customer´s \"approved vendor list“ 2.Loss of orders 3.Request by Senior BU Quality Manager (Hitachi)",
                          "categoryId": 8,
                          "weight": 10,
                          "sortOrder": 4,
                          "isActive": true,
                          "activateDate": "2020-06-02T07:17:09.787",
                          "deactivateDate": null
                      },
                      {
                          "id": 74,
                          "description": "1.Risk of Loss of Orders 2.Nonconformance causes significant loss of trust",
                          "categoryId": 8,
                          "weight": 5,
                          "sortOrder": 2,
                          "isActive": true,
                          "activateDate": "2021-10-14T04:01:39.163",
                          "deactivateDate": null
                      }
                  ]
              },
              "severities": [
                  {
                      "id": 37,
                      "description": "No Impact to PG Reputation",
                      "categoryId": 8,
                      "weight": 1,
                      "sortOrder": 2,
                      "isActive": true,
                      "activateDate": "2020-06-02T07:17:09.787",
                      "deactivateDate": null
                  },
                  {
                      "id": 38,
                      "description": "Risk of Customer Complaint",
                      "categoryId": 8,
                      "weight": 3,
                      "sortOrder": 2,
                      "isActive": true,
                      "activateDate": "2020-06-02T07:17:09.787",
                      "deactivateDate": null
                  },
                  {
                      "id": 39,
                      "description": "Written evidence (letter / minutes of meeting) that customer executive considers 1.it a severe incident 2.Withdrawing future orders",
                      "categoryId": 8,
                      "weight": 7,
                      "sortOrder": 3,
                      "isActive": true,
                      "activateDate": "2020-06-02T07:17:09.787",
                      "deactivateDate": null
                  },
                  {
                      "id": 40,
                      "description": "1.Removed from customer´s \"approved vendor list“ 2.Loss of orders 3.Request by Senior BU Quality Manager (Hitachi)",
                      "categoryId": 8,
                      "weight": 10,
                      "sortOrder": 4,
                      "isActive": true,
                      "activateDate": "2020-06-02T07:17:09.787",
                      "deactivateDate": null
                  },
                  {
                      "id": 74,
                      "description": "1.Risk of Loss of Orders 2.Nonconformance causes significant loss of trust",
                      "categoryId": 8,
                      "weight": 5,
                      "sortOrder": 2,
                      "isActive": true,
                      "activateDate": "2021-10-14T04:01:39.163",
                      "deactivateDate": null
                  }
              ],
              "activateDate": "2020-06-02T07:17:09.757",
              "deactivateDate": null
          },
          {
              "id": 9,
              "categoryId": 9,
              "selectionId": 41,
              "sortOrder": 9,
              "isActive": true,
              "category": {
                  "id": 9,
                  "name": "Mass media",
                  "domain": 1,
                  "sortOrder": 9,
                  "isActive": true,
                  "activateDate": "2020-06-02T07:17:09.757",
                  "deactivateDate": null,
                  "matrixSeverity": [
                      {
                          "id": 41,
                          "description": "Category not applicable",
                          "categoryId": 9,
                          "weight": 0,
                          "sortOrder": 1,
                          "isActive": true,
                          "activateDate": "2020-06-02T07:17:09.787",
                          "deactivateDate": null
                      },
                      {
                          "id": 44,
                          "description": "Incident reported by national wide media - tier 2 acc. to PG-CC-01",
                          "categoryId": 9,
                          "weight": 7,
                          "sortOrder": 3,
                          "isActive": true,
                          "activateDate": "2020-06-02T07:17:09.787",
                          "deactivateDate": null
                      },
                      {
                          "id": 45,
                          "description": "Incident reported by global or national wide media - tier 1 level acc. to PG-CC-01",
                          "categoryId": 9,
                          "weight": 10,
                          "sortOrder": 4,
                          "isActive": true,
                          "activateDate": "2020-06-02T07:17:09.787",
                          "deactivateDate": null
                      }
                  ]
              },
              "severities": [
                  {
                      "id": 41,
                      "description": "Category not applicable",
                      "categoryId": 9,
                      "weight": 0,
                      "sortOrder": 1,
                      "isActive": true,
                      "activateDate": "2020-06-02T07:17:09.787",
                      "deactivateDate": null
                  },
                  {
                      "id": 44,
                      "description": "Incident reported by national wide media - tier 2 acc. to PG-CC-01",
                      "categoryId": 9,
                      "weight": 7,
                      "sortOrder": 3,
                      "isActive": true,
                      "activateDate": "2020-06-02T07:17:09.787",
                      "deactivateDate": null
                  },
                  {
                      "id": 45,
                      "description": "Incident reported by global or national wide media - tier 1 level acc. to PG-CC-01",
                      "categoryId": 9,
                      "weight": 10,
                      "sortOrder": 4,
                      "isActive": true,
                      "activateDate": "2020-06-02T07:17:09.787",
                      "deactivateDate": null
                  }
              ],
              "activateDate": "2020-06-02T07:17:09.757",
              "deactivateDate": null
          },
          {
              "id": 10,
              "categoryId": 10,
              "selectionId": 48,
              "sortOrder": 10,
              "isActive": true,
              "category": {
                  "id": 10,
                  "name": "Design change",
                  "domain": 1,
                  "sortOrder": 10,
                  "isActive": true,
                  "activateDate": "2020-06-02T07:17:09.757",
                  "deactivateDate": null,
                  "matrixSeverity": [
                      {
                          "id": 46,
                          "description": "Category not applicable",
                          "categoryId": 10,
                          "weight": 0,
                          "sortOrder": 1,
                          "isActive": true,
                          "activateDate": "2020-06-02T07:17:09.787",
                          "deactivateDate": null
                      },
                      {
                          "id": 48,
                          "description": "Screening, or a part of inspection",
                          "categoryId": 10,
                          "weight": 3,
                          "sortOrder": 2,
                          "isActive": true,
                          "activateDate": "2020-06-02T07:17:09.787",
                          "deactivateDate": null
                      },
                      {
                          "id": 49,
                          "description": "Design change is needed, and selective tests must be repeated",
                          "categoryId": 10,
                          "weight": 7,
                          "sortOrder": 3,
                          "isActive": true,
                          "activateDate": "2020-06-02T07:17:09.787",
                          "deactivateDate": null
                      },
                      {
                          "id": 50,
                          "description": "Design change is needed which makes new/repeated type test necessary",
                          "categoryId": 10,
                          "weight": 10,
                          "sortOrder": 4,
                          "isActive": true,
                          "activateDate": "2020-06-02T07:17:09.787",
                          "deactivateDate": null
                      }
                  ]
              },
              "severities": [
                  {
                      "id": 46,
                      "description": "Category not applicable",
                      "categoryId": 10,
                      "weight": 0,
                      "sortOrder": 1,
                      "isActive": true,
                      "activateDate": "2020-06-02T07:17:09.787",
                      "deactivateDate": null
                  },
                  {
                      "id": 48,
                      "description": "Screening, or a part of inspection",
                      "categoryId": 10,
                      "weight": 3,
                      "sortOrder": 2,
                      "isActive": true,
                      "activateDate": "2020-06-02T07:17:09.787",
                      "deactivateDate": null
                  },
                  {
                      "id": 49,
                      "description": "Design change is needed, and selective tests must be repeated",
                      "categoryId": 10,
                      "weight": 7,
                      "sortOrder": 3,
                      "isActive": true,
                      "activateDate": "2020-06-02T07:17:09.787",
                      "deactivateDate": null
                  },
                  {
                      "id": 50,
                      "description": "Design change is needed which makes new/repeated type test necessary",
                      "categoryId": 10,
                      "weight": 10,
                      "sortOrder": 4,
                      "isActive": true,
                      "activateDate": "2020-06-02T07:17:09.787",
                      "deactivateDate": null
                  }
              ],
              "activateDate": "2020-06-02T07:17:09.757",
              "deactivateDate": null
          },
          {
              "id": 11,
              "categoryId": 11,
              "selectionId": 52,
              "sortOrder": 11,
              "isActive": true,
              "category": {
                  "id": 11,
                  "name": "Instances",
                  "domain": 2,
                  "sortOrder": 11,
                  "isActive": true,
                  "activateDate": "2020-06-02T07:17:09.757",
                  "deactivateDate": null,
                  "matrixSeverity": [
                      {
                          "id": 52,
                          "description": "Single instance of the nonconformity",
                          "categoryId": 11,
                          "weight": 1,
                          "sortOrder": 2,
                          "isActive": true,
                          "activateDate": "2020-06-02T07:17:09.787",
                          "deactivateDate": null
                      },
                      {
                          "id": 53,
                          "description": "Multiple instance of the nonconformity",
                          "categoryId": 11,
                          "weight": 3,
                          "sortOrder": 2,
                          "isActive": true,
                          "activateDate": "2020-06-02T07:17:09.787",
                          "deactivateDate": null
                      },
                      {
                          "id": 54,
                          "description": "1.Mass volume products: Nonconformity is recurring regularly 2.Projects: Same customer had same nonconformity in more than 1 project",
                          "categoryId": 11,
                          "weight": 7,
                          "sortOrder": 3,
                          "isActive": true,
                          "activateDate": "2020-06-02T07:17:09.787",
                          "deactivateDate": null
                      },
                      {
                          "id": 55,
                          "description": "1.Mass volume products: Nonconformity is in every or nearly every delivery 2.Projects: Several customers had the same nonconformity in more than one project",
                          "categoryId": 11,
                          "weight": 10,
                          "sortOrder": 4,
                          "isActive": true,
                          "activateDate": "2020-06-02T07:17:09.787",
                          "deactivateDate": null
                      },
                      {
                          "id": 75,
                          "description": "Nonconformity is recurring irregularly",
                          "categoryId": 11,
                          "weight": 5,
                          "sortOrder": 2,
                          "isActive": true,
                          "activateDate": "2021-10-14T04:01:39.163",
                          "deactivateDate": null
                      }
                  ]
              },
              "severities": [
                  {
                      "id": 52,
                      "description": "Single instance of the nonconformity",
                      "categoryId": 11,
                      "weight": 1,
                      "sortOrder": 2,
                      "isActive": true,
                      "activateDate": "2020-06-02T07:17:09.787",
                      "deactivateDate": null
                  },
                  {
                      "id": 53,
                      "description": "Multiple instance of the nonconformity",
                      "categoryId": 11,
                      "weight": 3,
                      "sortOrder": 2,
                      "isActive": true,
                      "activateDate": "2020-06-02T07:17:09.787",
                      "deactivateDate": null
                  },
                  {
                      "id": 54,
                      "description": "1.Mass volume products: Nonconformity is recurring regularly 2.Projects: Same customer had same nonconformity in more than 1 project",
                      "categoryId": 11,
                      "weight": 7,
                      "sortOrder": 3,
                      "isActive": true,
                      "activateDate": "2020-06-02T07:17:09.787",
                      "deactivateDate": null
                  },
                  {
                      "id": 55,
                      "description": "1.Mass volume products: Nonconformity is in every or nearly every delivery 2.Projects: Several customers had the same nonconformity in more than one project",
                      "categoryId": 11,
                      "weight": 10,
                      "sortOrder": 4,
                      "isActive": true,
                      "activateDate": "2020-06-02T07:17:09.787",
                      "deactivateDate": null
                  },
                  {
                      "id": 75,
                      "description": "Nonconformity is recurring irregularly",
                      "categoryId": 11,
                      "weight": 5,
                      "sortOrder": 2,
                      "isActive": true,
                      "activateDate": "2021-10-14T04:01:39.163",
                      "deactivateDate": null
                  }
              ],
              "activateDate": "2020-06-02T07:17:09.757",
              "deactivateDate": null
          },
          {
              "id": 12,
              "categoryId": 12,
              "selectionId": 57,
              "sortOrder": 12,
              "isActive": true,
              "category": {
                  "id": 12,
                  "name": "Resolution",
                  "domain": 2,
                  "sortOrder": 12,
                  "isActive": true,
                  "activateDate": "2020-06-02T07:17:09.757",
                  "deactivateDate": null,
                  "matrixSeverity": [
                      {
                          "id": 56,
                          "description": "Category not applicable",
                          "categoryId": 12,
                          "weight": 0,
                          "sortOrder": 1,
                          "isActive": true,
                          "activateDate": "2020-06-02T07:17:09.8",
                          "deactivateDate": null
                      },
                      {
                          "id": 57,
                          "description": "Correction known, corrective action known and planned or corrective action not necessary",
                          "categoryId": 12,
                          "weight": 1,
                          "sortOrder": 2,
                          "isActive": true,
                          "activateDate": "2020-06-02T07:17:09.8",
                          "deactivateDate": null
                      },
                      {
                          "id": 58,
                          "description": "Correction known, corrective action known but not planned",
                          "categoryId": 12,
                          "weight": 3,
                          "sortOrder": 2,
                          "isActive": true,
                          "activateDate": "2020-06-02T07:17:09.8",
                          "deactivateDate": null
                      },
                      {
                          "id": 59,
                          "description": "Correction known, corrective action unknown for systemic / repetitive fault",
                          "categoryId": 12,
                          "weight": 7,
                          "sortOrder": 3,
                          "isActive": true,
                          "activateDate": "2020-06-02T07:17:09.8",
                          "deactivateDate": null
                      },
                      {
                          "id": 60,
                          "description": "Neither correction nor Corrective action known for systemic / repetitive fault",
                          "categoryId": 12,
                          "weight": 10,
                          "sortOrder": 4,
                          "isActive": true,
                          "activateDate": "2020-06-02T07:17:09.8",
                          "deactivateDate": null
                      }
                  ]
              },
              "severities": [
                  {
                      "id": 56,
                      "description": "Category not applicable",
                      "categoryId": 12,
                      "weight": 0,
                      "sortOrder": 1,
                      "isActive": true,
                      "activateDate": "2020-06-02T07:17:09.8",
                      "deactivateDate": null
                  },
                  {
                      "id": 57,
                      "description": "Correction known, corrective action known and planned or corrective action not necessary",
                      "categoryId": 12,
                      "weight": 1,
                      "sortOrder": 2,
                      "isActive": true,
                      "activateDate": "2020-06-02T07:17:09.8",
                      "deactivateDate": null
                  },
                  {
                      "id": 58,
                      "description": "Correction known, corrective action known but not planned",
                      "categoryId": 12,
                      "weight": 3,
                      "sortOrder": 2,
                      "isActive": true,
                      "activateDate": "2020-06-02T07:17:09.8",
                      "deactivateDate": null
                  },
                  {
                      "id": 59,
                      "description": "Correction known, corrective action unknown for systemic / repetitive fault",
                      "categoryId": 12,
                      "weight": 7,
                      "sortOrder": 3,
                      "isActive": true,
                      "activateDate": "2020-06-02T07:17:09.8",
                      "deactivateDate": null
                  },
                  {
                      "id": 60,
                      "description": "Neither correction nor Corrective action known for systemic / repetitive fault",
                      "categoryId": 12,
                      "weight": 10,
                      "sortOrder": 4,
                      "isActive": true,
                      "activateDate": "2020-06-02T07:17:09.8",
                      "deactivateDate": null
                  }
              ],
              "activateDate": "2020-06-02T07:17:09.757",
              "deactivateDate": null
          },
          {
              "id": 13,
              "categoryId": 13,
              "selectionId": 61,
              "sortOrder": 13,
              "isActive": true,
              "category": {
                  "id": 13,
                  "name": "Affected customer",
                  "domain": 2,
                  "sortOrder": 13,
                  "isActive": true,
                  "activateDate": "2020-06-02T07:17:09.757",
                  "deactivateDate": null,
                  "matrixSeverity": [
                      {
                          "id": 61,
                          "description": "Category not applicable",
                          "categoryId": 13,
                          "weight": 0,
                          "sortOrder": 1,
                          "isActive": true,
                          "activateDate": "2020-06-02T07:17:09.8",
                          "deactivateDate": null
                      },
                      {
                          "id": 62,
                          "description": "No customer is affected by the nonconformity",
                          "categoryId": 13,
                          "weight": 1,
                          "sortOrder": 2,
                          "isActive": false,
                          "activateDate": "2020-06-02T07:17:09.8",
                          "deactivateDate": "2021-10-14T04:01:39.477"
                      },
                      {
                          "id": 63,
                          "description": "Single customer is affected by the nonconformity",
                          "categoryId": 13,
                          "weight": 3,
                          "sortOrder": 2,
                          "isActive": false,
                          "activateDate": "2020-06-02T07:17:09.8",
                          "deactivateDate": "2021-10-14T04:01:39.477"
                      },
                      {
                          "id": 64,
                          "description": "Multiple customers are highly affected by non-conformity",
                          "categoryId": 13,
                          "weight": 7,
                          "sortOrder": 3,
                          "isActive": true,
                          "activateDate": "2020-06-02T07:17:09.8",
                          "deactivateDate": null
                      },
                      {
                          "id": 65,
                          "description": "Recall of products needed",
                          "categoryId": 13,
                          "weight": 10,
                          "sortOrder": 4,
                          "isActive": true,
                          "activateDate": "2020-06-02T07:17:09.8",
                          "deactivateDate": null
                      },
                      {
                          "id": 76,
                          "description": "One customer is highly affected by the repair of its installed base",
                          "categoryId": 13,
                          "weight": 5,
                          "sortOrder": 2,
                          "isActive": true,
                          "activateDate": "2021-10-14T04:01:39.163",
                          "deactivateDate": null
                      }
                  ]
              },
              "severities": [
                  {
                      "id": 61,
                      "description": "Category not applicable",
                      "categoryId": 13,
                      "weight": 0,
                      "sortOrder": 1,
                      "isActive": true,
                      "activateDate": "2020-06-02T07:17:09.8",
                      "deactivateDate": null
                  },
                  {
                      "id": 62,
                      "description": "No customer is affected by the nonconformity",
                      "categoryId": 13,
                      "weight": 1,
                      "sortOrder": 2,
                      "isActive": false,
                      "activateDate": "2020-06-02T07:17:09.8",
                      "deactivateDate": "2021-10-14T04:01:39.477"
                  },
                  {
                      "id": 63,
                      "description": "Single customer is affected by the nonconformity",
                      "categoryId": 13,
                      "weight": 3,
                      "sortOrder": 2,
                      "isActive": false,
                      "activateDate": "2020-06-02T07:17:09.8",
                      "deactivateDate": "2021-10-14T04:01:39.477"
                  },
                  {
                      "id": 64,
                      "description": "Multiple customers are highly affected by non-conformity",
                      "categoryId": 13,
                      "weight": 7,
                      "sortOrder": 3,
                      "isActive": true,
                      "activateDate": "2020-06-02T07:17:09.8",
                      "deactivateDate": null
                  },
                  {
                      "id": 65,
                      "description": "Recall of products needed",
                      "categoryId": 13,
                      "weight": 10,
                      "sortOrder": 4,
                      "isActive": true,
                      "activateDate": "2020-06-02T07:17:09.8",
                      "deactivateDate": null
                  },
                  {
                      "id": 76,
                      "description": "One customer is highly affected by the repair of its installed base",
                      "categoryId": 13,
                      "weight": 5,
                      "sortOrder": 2,
                      "isActive": true,
                      "activateDate": "2021-10-14T04:01:39.163",
                      "deactivateDate": null
                  }
              ],
              "activateDate": "2020-06-02T07:17:09.757",
              "deactivateDate": null
          },
          {
              "id": 14,
              "categoryId": 14,
              "selectionId": 67,
              "sortOrder": 14,
              "isActive": true,
              "category": {
                  "id": 14,
                  "name": "Control",
                  "domain": 3,
                  "sortOrder": 14,
                  "isActive": true,
                  "activateDate": "2020-06-02T07:17:09.757",
                  "deactivateDate": null,
                  "matrixSeverity": [
                      {
                          "id": 67,
                          "description": "Automatic detection (as part of the process) of Non-Conformance",
                          "categoryId": 14,
                          "weight": 1,
                          "sortOrder": 2,
                          "isActive": true,
                          "activateDate": "2020-06-02T07:17:09.8",
                          "deactivateDate": null
                      },
                      {
                          "id": 68,
                          "description": "Manual detection (by process Operator) of Non-Conformance",
                          "categoryId": 14,
                          "weight": 3,
                          "sortOrder": 2,
                          "isActive": true,
                          "activateDate": "2020-06-02T07:17:09.8",
                          "deactivateDate": null
                      },
                      {
                          "id": 69,
                          "description": "Control detection (per plan) of Non-conformance",
                          "categoryId": 14,
                          "weight": 7,
                          "sortOrder": 3,
                          "isActive": true,
                          "activateDate": "2020-06-02T07:17:09.8",
                          "deactivateDate": null
                      },
                      {
                          "id": 70,
                          "description": "No opportunity for detection of Non-Conformance",
                          "categoryId": 14,
                          "weight": 10,
                          "sortOrder": 4,
                          "isActive": true,
                          "activateDate": "2020-06-02T07:17:09.8",
                          "deactivateDate": null
                      }
                  ]
              },
              "severities": [
                  {
                      "id": 67,
                      "description": "Automatic detection (as part of the process) of Non-Conformance",
                      "categoryId": 14,
                      "weight": 1,
                      "sortOrder": 2,
                      "isActive": true,
                      "activateDate": "2020-06-02T07:17:09.8",
                      "deactivateDate": null
                  },
                  {
                      "id": 68,
                      "description": "Manual detection (by process Operator) of Non-Conformance",
                      "categoryId": 14,
                      "weight": 3,
                      "sortOrder": 2,
                      "isActive": true,
                      "activateDate": "2020-06-02T07:17:09.8",
                      "deactivateDate": null
                  },
                  {
                      "id": 69,
                      "description": "Control detection (per plan) of Non-conformance",
                      "categoryId": 14,
                      "weight": 7,
                      "sortOrder": 3,
                      "isActive": true,
                      "activateDate": "2020-06-02T07:17:09.8",
                      "deactivateDate": null
                  },
                  {
                      "id": 70,
                      "description": "No opportunity for detection of Non-Conformance",
                      "categoryId": 14,
                      "weight": 10,
                      "sortOrder": 4,
                      "isActive": true,
                      "activateDate": "2020-06-02T07:17:09.8",
                      "deactivateDate": null
                  }
              ],
              "activateDate": "2020-06-02T07:17:09.757",
              "deactivateDate": null
          }
      ],
      "activateDate": "2023-03-02T13:03:30.2740182",
      "deactivateDate": null,
      "shouldScoringMatrixBeVisible": true,
      "isManualChange": false,
      "checkSum": "27121721283137414852576167"
  }
  }
}


