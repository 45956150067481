import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import {
  AbstractControl,
  FormBuilder,
  FormControlName,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ResolvingUnitServiceService } from 'src/app/services/resolving-unit-service.service';
import { PgrcaAdminServiceService } from '../pgrca-admin-service.service';

@Component({
  selector: 'app-pgrca-cat-deactivate-pop-up',
  templateUrl: './pgrca-cat-deactivate-pop-up.component.html',
  styleUrls: ['./pgrca-cat-deactivate-pop-up.component.css'],
})
export class PgrcaCatDeactivatePopUpComponent implements OnInit {
  PGRCACatData: any;
  pgrcaCatDataContent: any;
  deactivateContentdata: any;
  deactivatedata: any;
  pgrcaCatDataContentDuplicateScope: any;
  duplicateScope: any;

  constructor(
    private ref: DynamicDialogRef,
    private fb: FormBuilder,
    private ir: ResolvingUnitServiceService,
    private pgrcaAdminDeactivateService: PgrcaAdminServiceService,
    public config: DynamicDialogConfig
  ) {
    this.PGRCACatData = this.config.data;
    this.pgrcaCatDataContent = this.PGRCACatData.content.children;
    this.pgrcaCatDataContentDuplicateScope = this.PGRCACatData.content.parent.children;
  }

  ngOnInit(): void {}

  deactivateNode() {
    let payload : any= {
      id: this.PGRCACatData.content.id,
      parentId: this.PGRCACatData.content.parentId,
      name: this.PGRCACatData.content.name,
      isExpanded: this.PGRCACatData.content.isExpanded,
      hasChildren: this.PGRCACatData.content.hasChildren,
      areChildrenLoaded: this.PGRCACatData.content.areChildrenLoaded,
      shouldBeExpanded: this.PGRCACatData.content.shouldBeExpanded,
      isActive: false,
      isAdditionalActionRequired:
      this.PGRCACatData.content.isAdditionalActionRequired,
      validation: this.PGRCACatData.content.validation,
      isGeneric: this.PGRCACatData.content.isGeneric,
      tag:
      {
        id : this.PGRCACatData.content.tag?.id,
        isActive : this.PGRCACatData.content.tag?.isActive,
        name : this.PGRCACatData.content.tag?.name,
        parentId : this.PGRCACatData.content.tag?.parentId,
        parents : this.PGRCACatData.content.tag?.parents,
        pgId : this.PGRCACatData.content.tag?.pgId,
        productGroupCode : this.PGRCACatData.content.tag?.productGroupCode

      },
      children:
      this.PGRCACatData.content.children.length > 0 ?
      this.PGRCACatData.content.children.map((r) => ({
          areChildrenLoaded: r?.areChildrenLoaded,
          children:
          r.children.length > 0 ?
          r.children.map((child)=>({
            areChildrenLoaded:child.areChildrenLoaded,
            hasChildren:child.hasChildren,
            id:child.id,
            isActive:child.isActive,
            isAdditionalActionRequired:child.isAdditionalActionRequired,
            isExpanded:child.isExpanded,
            isGeneric:child.isGeneric,
            name:child.name,
            parentId:child.parentId,
            shouldBeExpanded:child.shouldBeExpanded,
            tag:child.tag,
            validation:child.validation,

          })) : [],
          duplicateScope:
          r.parent.children.length > 0 ?
          r.parent.children?.map((a)=>(
            {
              name:a?.name,
              isActive:a?.isActive,
              id:a?.id
            }
          )) : [],
          tag :r?.tag,
          hasChildren: r?.hasChildren,
          id: r?.id,
          isActive: r?.isActive,
          isAdditionalActionRequired: r?.isAdditionalActionRequired,
          isExpanded: r?.isExpanded,
          name : r?.name,
          label: r?.name,
          parentId : r?.parentId,
          shouldBeExpanded : r?.shouldBeExpanded,
          validation : r?.validation
        })
      ) : [],
    };

    const pg = this.PGRCACatData.pgValue;
    this.pgrcaAdminDeactivateService.deactivatePgrcaCat(pg, payload).subscribe((deactivateRes) => {
      this.deactivatedata = deactivateRes;
      if(deactivateRes){
        payload.nodesToUpdate = deactivateRes.nodesToUpdate;
          payload.state = deactivateRes.state;
          let concatdata = payload;
        this.ref.close(concatdata);
      }
    });
  }
  // childNLoop(childNode){
  //   childNode.map((a)=>({

  //   }))
  // }

  close() {
    this.ref.close();
  }
}
