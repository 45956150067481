<div class="row" *ngIf="!loginSuccess">
  <form [formGroup]="actionLoginForm">
    <div class="row">
      <div class="col-4"></div>
      <div class="col-4">
        <label class="label-bold">Username:</label>
        <input type="text" formControlName="username" class="form-control form-control-sm" />
      </div>
      <div class="col-4"></div>
    </div>
    <div class="row">
      <div class="col-4"></div>
      <div class="col-4">
        <label class="label-bold">Password:</label>
        <input type="text" formControlName="password" class="form-control form-control-sm" />
      </div>
      <div class="col-4"></div>
    </div>
    <div class="row mt-1">
      <div class="col-4"></div>
      <div class="col-4">
        <button class="widgetButtonMedium" type="button" (click)="login() ">Login</button>
      </div>
      <div class="col-4"></div>
    </div>
    <div class="row mt-1 text-center" *ngIf="errorMessage">
      <p>Please enter correct credentials</p>
    </div>
  </form>
</div>

<div class="row" *ngIf="loginSuccess">
  <div class="row">
    <div class="col-sm-4 mt-1">
      <label class="label-align-right label-bold">Complaint no:</label>
    </div>
    <div class="col-sm-4">
      <form [formGroup]="actionLogForm">
        <div class="row">
          <span class="input-group">
            <input formControlName="complaintNo" [disabled]="disableInput" placeholder="Please enter CCRP No"
              class="form-control form-control-sm" type="text" />
            <span class="input-group-btn">
              <button type="button" [disabled]="!actionLogForm.valid" class="widgetButtonMedium"
                (click)="searchActions()">{{ "ButtonSubmit" | translate
                }}</button>
            </span>
          </span>
        </div>
      </form>
    </div>
    <div class="col-sm-4"></div>
  </div>
  <div class="row mt-3" *ngIf="complaintNo">
    <a></a>
    <nav class="navbar navbar-default" role="navigation">
      <div class="row" style="text-align: center;">
        <div class="col-md-1">
          <a class="verticalAlign" routerLink="/mydashboard" style="text-decoration: none;">Menu</a>
        </div>
        <div class="col-md-1">
          <div class="dropdown verticalAlign">
            <a style="text-decoration: none;" (click)="openDropdown()">{{ "al_add_nav" | translate }}<b
                class="caret"></b></a>
            <div [ngStyle]="{'display':showDropdown ? 'block' : 'none' }" class="dropdown-content">
              <a (click)="openManualEntryPopup($event)">{{ "al_addmanual_nav" | translate }}</a>
            </div>
          </div>
        </div>
        <!-- //code for magnifier -->
        <div class="col-md-6 ">
          <form style="margin-top: 0; margin-bottom: 0;" class="navbar-form ng-pristine ng-valid" _lpchecked="1">
            <div class="form-group" style="display:inline;">
              <div class="input-group">
                <input [(ngModel)]="searchKeyword" (ngModelChange)="filterLogData($event)"
                  class="form-control ng-pristine ng-valid" name="search" placeholder="{{'al_search_ph' | translate}}"
                  autocomplete="off" type="text">
                <span class="input-group-addon button-set" style="background:white">
                  <span class="hitachiicon-magnifying_glass"></span>
                </span>
              </div>
            </div>
          </form>
        </div>
        <div class="col-md-1 col-xl-2 col-lg-2 mobile-space1">
          <span class="input-group">
            <select style="border-radius: 4px;" class="form-control" [(ngModel)]="orderBy" (change)="sortData()">
              <option value="type">{{'al_sort_type_asc' | translate}}</option>
              <option value="email">{{'al_sort_created_asc' | translate}}</option>
              <option value="subject">{{'al_sort_subject_asc' | translate}}</option>
              <option value="date">{{'al_sort_date_asc' | translate}}</option>
              <option value="-type">{{'al_sort_type_desc' | translate}}</option>
              <option value="-email">{{'al_sort_created_desc' | translate}}</option>
              <option value="-subject">{{'al_sort_subject_desc' | translate}}</option>
              <option value="-date">{{'al_sort_date_desc' | translate}}</option>
            </select>
          </span>
        </div>
        <!-- //Code for print Actionlog data-print -->
        <div class="col-md-1 col-xl-1 col-lg-1 mobile-space1">
          <div id="page">
            <a class="verticalAlign" (click)="onPrint('myDiv')" title="Print visible">
              <span class="hitachiicon-printer"></span>
            </a>
          </div>
        </div>
        <div class="col-md-1 col-xl-1 col-lg-1 mobile-space1">
          <a class="verticalAlign" (click)="expandAllRows()" title="Expand all">
            <span class="glyphicon glyphicon-collapse-down">
            </span>
          </a>
        </div>
      </div>
    </nav>
    <!-- //Code for LogDataShow in ui -->
    <div class="panel panel-default" style="margin-top: 10px; margin-bottom: 0px;">
      <div class="panel-heading">
        <ul class="nav nav-tabs flex-column flex-sm-row" style="cursor: pointer; border-bottom: none;">
          <li *ngFor="let log of logParams" class="nav-item flex-sm-fill" (click)="onlogSelection(log)"
            [ngClass]="{'active':log.selected}">
            <span class="fa admin-link"
              [ngClass]="{'fa-square-o':!log.selected, 'fa-check-square-o ':log.selected }"></span>
            <span> {{log.name}}</span>
            <span *ngIf="log.type == 1" class="log-count mt-1 ms-1"
              style="background-color: #80c4e1;">{{log.count}}</span>
            <span *ngIf="log.type == 8" class="log-count mt-1 ms-1"
              style="background-color: #5cb85c;">{{log.count}}</span>
            <span *ngIf="log.type == 2" class="log-count mt-1 ms-1"
              style="background-color: #093fb4;">{{log.count}}</span>
            <span *ngIf="log.type == 16" class="log-count mt-1 ms-1" style="background-color: red;">{{log.count}}</span>
            <span *ngIf="log.type == 4" class="log-count mt-1 ms-1"
              style="background-color: #ffb500">{{log.count}}</span>
          </li>
        </ul>
      </div>
    </div>
    <div id="myDiv" class="panel-body gray">

      <!-- for no logs -->
      <div *ngIf="logDataToShow.length == 0" class="row">
        <div class="col-lg-12 text-center">
          <h4 class="text-muted">{{'al_0_toshow_msg' | translate}}</h4>
        </div>
      </div>

      <!-- if logs are available -->
      <div *ngIf="logDataToShow.length > 0">
        <div class="row panel-body action-log-row underline" *ngFor="let log of logDataToShow; let i= index"
          (click)="expandRow(i)">
          <div *ngIf="!(selectedIndex == i || showAllBody)" class="row" style="padding-top: 3px;">
            <div class="col-lg-3 col-md-3 col-sm-3" style="padding-left: 0">
              <span> <span class="glyphicon glyphicon-user"></span>
                <span>{{log.createdBy.email}}</span></span>
            </div>
            <div class="col-lg-7 col-md-7 col-sm-7 log-subject word-wrap" style="padding-left: 0">
              <p>
                <span *ngIf="log.type == 1" class="log-subject"
                  style=" background-color: #80c4e1; color: #fff;">{{'al_act_sys_lbl' | translate}}</span>
                <span *ngIf="log.type == 4" class="log-subject"
                  style=" background-color: #ffb500; color: #fff;">{{'al_act_me_msg' | translate}}</span>
                <span *ngIf="log.type == 16" class="log-subject"
                  style=" background-color:red; color: #fff;">{{'al_act_ai_msg' | translate}}</span>
                <span *ngIf="log.type == 8" class="log-subject"
                  style=" background-color: #5cb85c; color: #fff;">{{'al_act_un_msg' | translate}}</span>
                <span *ngIf="log.type == 2" class="log-subject"
                  style=" background-color: #093fb4; color: #fff;">{{'al_act_sn_msg' | translate}}</span>&nbsp;
                <span class="action-log-subject" style="font-weight: bold;" [innerHTML]="log.subject"></span>&nbsp;

                <span *ngIf="log.isExternal && log.to">
                  <br /><b>to</b>: {{log.to}}
                </span>
                <span *ngIf="log.isExternal && log.cc">
                  <br /><b>cc</b>: {{log.cc}}
                </span>
                <span *ngIf="!log.isExternal" [innerHTML]="log.body | shortenComplaintSubject : 100"></span>
              </p>

              <!-- <div *ngIf="selectedIndex == i || showAllBody" class="arrow" style="display: flex;">
          <p style="width: 95%;" [innerHTML]="log.body"></p>
          <i (click)="collapseRow(i, $event)" class="fa fa-angle-double-up" title="Collapse"
            cc-disable-a-tag="isControlDisabled"></i>
        </div>       -->
            </div>

            <div class="col-lg-2 col-md-2 col-sm-2 text-right">
              <span class="fa fa-paperclip" *ngIf="log.attachments.length>0"></span>&nbsp;&nbsp;
              <span title="This message was sent on  {{log.date | date:'YYYY-MM-dd HH:mm:ss' }}">
                <i class="hitachiicon-calendar"> </i> {{log.date | date:'YYYY-MM-dd HH:mm:ss'}}
              </span>
            </div>
          </div>

          <div *ngIf="selectedIndex == i || showAllBody" class="row" style="background-color: #fff; padding-top: 3px;">
            <div class="row">
              <div class="col-lg-10 col-md-10 col-sm-10" style="padding: 0px;">
                <div style="display: inline">
                  <span class="glyphicon glyphicon-user"></span>
                  {{log.createdBy.name}} &lt;{{log.createdBy.email}}&gt;
                  <span *ngIf="log.type == 1" class="log-subject"
                    style=" background-color: #80c4e1; color: #fff;">{{'al_act_sys_lbl' | translate}}</span>
                  <span *ngIf="log.type == 4" class="log-subject"
                    style=" background-color: #ffb500; color: #fff;">{{'al_act_me_msg' | translate}}</span>
                  <span *ngIf="log.type == 16" class="log-subject"
                    style=" background-color:red; color: #fff;">{{'al_act_ai_msg' | translate}}</span>
                  <span *ngIf="log.type == 8" class="log-subject"
                    style=" background-color: #5cb85c; color: #fff;">{{'al_act_un_msg' | translate}}</span>
                  <span *ngIf="log.type == 2" class="log-subject"
                    style=" background-color: #093fb4; color: #fff;">{{'al_act_sn_msg' | translate}}</span>
                </div>
              </div>

              <div class="col-lg-2 col-md-2 col-sm-2 text-right" style="padding: 0px;">
                <i (click)="collapseRow(i, $event)" class="fa fa-angle-double-up" title="{{'al_collapse' | translate}}"
                  cc-disable-a-tag="isControlDisabled"></i>
                <span class="fa fa-paperclip ps-1" *ngIf="log.attachments.length>0"></span>&nbsp;&nbsp;
                <small><span class="hitachiicon-calendar"
                    title="{{'al_message_was_senton_msg' | translate}}"></span><span> {{log.date|date:'YYYY-MM-dd
                    HH:mm:ss'}}</span></small>
                <!-- <span title="This message was sent on  {{log.date | date:'YYYY-MM-dd HH:mm:ss' }}">
          <i style="text-align: right;" class="hitachiicon-calendar"> </i> {{log.date | date:'YYYY-MM-dd HH:mm:ss'}}
          </span> -->
              </div>
            </div>

            <div class="row">
              <div class="col-lg-12" *ngIf="log.to">
                to: {{log.to}}
              </div>
              <div class="col-lg-12" *ngIf="log.cc">
                cc: {{log.cc}}
              </div>

              <b><span [innerHTML]="log.subject"></span></b>
              <p style="width: 95%;" [innerHTML]="log.body"></p>
              <div *ngIf="log.attachments.length>0">
                <hr style="margin-top:4px;margin-bottom:4px">
                <span class="fa fa-paperclip"></span>&nbsp;&nbsp; | {{log.attachments.length}} {{'al_attachment_msg' |
                translate}} ({{'al_attachment_total' | translate}} {{ log.attachments | sumFloat | filesize }}) | <a
                  style="text-decoration: none;" target="_blank"
                  routerLink="{{(log.attachments[0]).url}},zip">{{'al_attachment_allaszip' | translate}}</a>

                <hr style="margin-top:4px;margin-bottom:4px">
                <ul class="list-unstyled">
                  <li *ngFor="let att of log.attachments ">
                    {{att.filename}} ({{att.size | filesize}})<small>
                      <a style="text-decoration: none;" target="_blank" routerLink="{{att.url}}"><span
                          class="glyphicon glyphicon-download-alt"></span>{{'al_attachment_download' | translate}}</a>
                    </small>
                  </li>

                </ul>
              </div>
            </div>
          </div>

          <!-- <div class="arrow col-lg-12 col-md-12 col-sm-12">





      <div style="width:100%;display: inline">&nbsp;</div> -->
          <!-- <span class="glyphicon glyphicon-user"></span>
        {{log.createdBy.name}} &lt;{{log.createdBy.email}}&gt; -->

          <!-- </div> -->


        </div>
      </div>
    </div>

    <!-- <app-complaint-action-log [InputCompalintNo]="inputComplaintNo"></app-complaint-action-log> -->
  </div>
</div>
