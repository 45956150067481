import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { LocalStorageServiceService } from 'src/app/services/local-storage-service.service';

@Component({
  selector: 'app-translation-settings',
  templateUrl: './translation-settings.component.html',
  styleUrls: ['./translation-settings.component.css']
})
export class TranslationSettingsComponent implements OnInit {

  isDirty: boolean = false;

  translationSettingsForm: FormGroup;
  pageSize = this.localStorageService.getFromLocalStorage("translationsPageSize") || 15;


  constructor(private fb: FormBuilder,
    private localStorageService: LocalStorageServiceService,
    public ref: DynamicDialogRef
  ) { }

  ngOnInit(): void {
    this.translationSettingsForm = this.fb.group({
      pageSize: [15]
    })
    if (this.localStorageService.getFromLocalStorage("translationsPageSize") != null && this.localStorageService.getFromLocalStorage("translationsPageSize") != "") {
      this.translationSettingsForm.controls['pageSize'].setValue(this.localStorageService.getFromLocalStorage("translationsPageSize"));
    }
  }

  updatePageSize = function (pageSize) {
    this.isDirty = true;
    this.pageSize = this.translationSettingsForm.controls['pageSize'].value;
  };

  save() {
    if (this.isDirty) {
      this.localStorageService.addToLocalStorage("translationsPageSize", this.pageSize);
      const pageSizeSave = { size: this.translationSettingsForm.controls['pageSize'].value };
      this.ref.close(pageSizeSave);
    }
  };

  cancel() {
    this.ref.close();
  }
}
