<div class="row">
    <div *ngIf="!reportGenerating" class="col-md-2 mt-3 mb-2">
        <a class="text-capitalize exportcls" (click)="getExcelDownloaded()"><span
                class="glyphicon glyphicon-save export-btn"></span><b>&nbsp;Export to Excel</b></a>
    </div>
    <div class="col-md-4 mt-3">
        <button class="widget-buttonlarge me-2" *ngIf="reportGenerating"><i
                class="fa fa-circle-o-notch fa-spin"></i>&nbsp;Generating Report...</button>
    </div>
    
</div>
<div>
    <span id="unique"> </span>
</div>