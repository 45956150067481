import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-po-details',
  templateUrl: './po-details.component.html',
  styleUrls: ['./po-details.component.css']
})
export class PoDetailsComponent implements OnInit, OnChanges {

  @Input('poScope') poScope:any;
  visibility:any = {};

  ngOnChanges(changes: SimpleChanges): void {
    this.ngOnInit();

  }

  constructor() { }

  ngOnInit(): void {

    this.visibility = {
      div: {
        unit: this.setVisibility('div', this.poScope.units),
        location: this.setVisibility('div', this.poScope.unitLocations)
      },
      bu: {
        unit: this.setVisibility('bu', this.poScope.units),
        location: this.setVisibility('bu', this.poScope.unitLocations)
      },
      pg: {
        unit: this.setVisibility('pg', this.poScope.units),
        location: this.setVisibility('pg', this.poScope.unitLocations)
      }
    }

  }

  setVisibility(fieldName, arrayinput=[]) {
    let result = false;
    arrayinput.forEach((unit) => {
        if (unit[fieldName] !== null && unit[fieldName] !== '') {
            result = true;
        }
    });

    return result;
};

}
