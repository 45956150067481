import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HelpdeskComponent } from './components/links/helpdesk/helpdesk.component';
import { NewIssueHomeComponent } from './components/new-issue/new-issue-home/new-issue-home.component';
import { MyissueActionPlanDashboardComponent } from './components/my-issue/myissue-action-plan-dashboard/myissue-action-plan-dashboard.component';
import { PageNotFoundComponent } from './reusables/page-not-found/page-not-found.component';
import { ComplaintNotFoundComponent } from './components/new-issue/complaint-not-found/complaint-not-found.component';

//msal authentication
import { MsalRedirectComponent } from '@azure/msal-angular';
import { MsalGuard } from '@azure/msal-angular';
import { ComplaintActionLogComponent } from './components/new-issue/complaint-action-log/complaint-action-log.component';
import { ReportsMenuComponent } from './components/reports/reports-menu/reports-menu.component';
import { AllInOneComponent } from './components/reports/all-in-one/all-in-one.component';
import { WeeklyReportComponent } from './components/reports/weekly-report/weekly-report.component';
import { ActionBoardComponent } from './components/new-issue/actionplan/action-board/action-board.component';
import { ActionPlanLogComponent } from './components/new-issue/actionplan/action-plan-log/action-plan-log.component';
import { ActionPlanCardEditComponent } from './components/new-issue/actionplan/action-plan-card-edit/action-plan-card-edit.component';
import { TargetDateHistoryComponent } from './components/new-issue/target-date-history/target-date-history.component';
// import { ActiveEmployeeComponent } from './components/admin/active-employee/active-employee.component';
import { GlobalSearchComponent } from './components/search/GlobalSearch/global-search/global-search.component';
import { AllInOneReportsDataOnCCRPNumberComponent } from './components/reports/all-in-one-reports-data-on-ccrpnumber/all-in-one-reports-data-on-ccrpnumber.component'
// import { AllInOneReportsDataOnCCRPNumberComponent } from './components/reports/all-in-one-reports-data-on-ccrpnumber/all-in-one-reports-data-on-ccrpnumber.component'
import { PGRCACategoryComponent } from './components/admin/pgrcaCategoryModule/pgrcacategory/pgrcacategory.component';
import { ProcessownerSearchComponent } from './components/search/processowner-search/processowner-search.component';
import { ActionlogentriesInternalComponent } from './reusables/actionlogentries-internal/actionlogentries-internal.component';
import { HubPOSearchComponent } from './components/search/hub-processowner-search/hub-posearch/hub-posearch.component';
import { RupersonSearchComponent } from './components/search/ruperson-search/ruperson-search.component';
import { ViewReportComponent } from './components/reports/view-report/view-report.component';
import { ComplaintHasBeenDeletedExceptionComponent } from './components/new-issue/complaint-has-been-deleted-exception/complaint-has-been-deleted-exception.component';
import { IssueSummaryreportComponent } from './components/new-issue/issue-summaryreport/issue-summaryreport.component';
import { StatusComponent } from './components/external/status/status.component';
import { SurveyTemplateComponent } from './components/external/survey-template/survey-template.component';
import { APP_BASE_HREF } from '@angular/common';
import { environment } from 'src/environments/environment';
import { HiConfirmComponent } from './components/external/hi-confirm/hi-confirm.component';
import { TesterLoginComponent } from './reusables/tester-login/tester-login.component';
import { AdminhomeComponent } from './components/admin/adminhome/adminhome.component';
import { CustomerContactsComponent } from './components/admin/admin-customer-contacts/customer-contacts/customer-contacts.component';
import { AdminSystemuserComponent } from './components/admin/systemusers/admin-systemuser/admin-systemuser.component';
import { TranslationsComponent } from './components/admin/admin-translations/translations/translations.component';
import { ActiveEmployeesComponent } from './components/admin/active-employee/active-employees.component';
import { HubsComponent } from './components/admin/admin-hubs/hubs/hubs.component';
import { AdminEscalationComponent } from './components/admin/escalation/admin-escalation/admin-escalation.component';
import { AdminProcessownersComponent } from './components/admin/processOwners/admin-processowners/admin-processowners.component';
import { ResolvingUnitsComponent } from './components/admin/resolving-unit/resolving-units/resolving-units.component';
import { AccountablePersonComponent } from './components/admin/accountablePerson/accountable-person/accountable-person.component';
import { GlobalRUComponent } from './components/admin/global-ru/global-ru.component';
import { AdminProcessStepsComponent } from './components/admin/processSteps/admin-process-steps/admin-process-steps.component';
import { AdminFailuremodeComponent } from './components/admin/failureMode/admin-failuremode/admin-failuremode.component';
import { HrgtErrorComponent } from './reusables/hrgt-error/hrgt-error.component';
import { MaintenanceErrorComponent } from './reusables/maintenance-error/maintenance-error.component';
import { SubscribeComponent } from './components/external/subscribe/subscribe.component';
import { DownloadEightDComponent } from './reusables/download-eight-d/download-eight-d.component';


const routes: Routes = [
        {path:'', loadChildren: () => import('./components/my-issue/myissue.module').then(m => m.MyIssueModule), canActivate:[MsalGuard]},
        {path:'helpdesk', component:HelpdeskComponent,title:'CCRP-Links', canActivate:[MsalGuard]},
        // {path:'admin', loadChildren: () => import('./components/admin/admin.module').then(m => m.AdminModule), canActivate:[MsalGuard]},
        {path:'admin', component:AdminhomeComponent, title:'CCRP-Admin', canActivate:[MsalGuard]},
        {path:'admin/customer-contacts', component:CustomerContactsComponent,canActivate:[MsalGuard]},
        {path:'admin/customer-contacts/:customerId/:complaintId', component:CustomerContactsComponent,canActivate:[MsalGuard]},
        {path:'admin/system-users', component:AdminSystemuserComponent, canActivate:[MsalGuard]},
        {path:'admin/translations', component:TranslationsComponent, canActivate:[MsalGuard]},
        {path:'admin/active-employee', component:ActiveEmployeesComponent,canActivate:[MsalGuard]},
        {path:'admin/hubs', component:HubsComponent,canActivate:[MsalGuard]},
        {path:'admin/escalation', component:AdminEscalationComponent,canActivate:[MsalGuard]},
        {path:'admin/processowners', component:AdminProcessownersComponent,canActivate:[MsalGuard]},
        {path:'admin/resolving-units', component:ResolvingUnitsComponent,canActivate:[MsalGuard]},
        {path:'admin/accountable-person', component:AccountablePersonComponent ,canActivate:[MsalGuard]},
        {path: 'admin/global-ru', component: GlobalRUComponent, canActivate:[MsalGuard]},
        {path: 'admin/process-steps', component: AdminProcessStepsComponent, canActivate:[MsalGuard]},
        {path: 'admin/failuremode', component: AdminFailuremodeComponent, canActivate:[MsalGuard]},
        {path: 'complaint', component:NewIssueHomeComponent, title: 'CCRP-Create new issue', canActivate:[MsalGuard]},
        {path: 'complaint/:id', component:NewIssueHomeComponent, canActivate:[MsalGuard]},
        {path: 'complaint/Deleted/:id', component:NewIssueHomeComponent, canActivate:[MsalGuard]},
        {path: 'complaint/:id/actionlog', component:ComplaintActionLogComponent, canActivate:[MsalGuard]},
        {path: 'Complaint', component:NewIssueHomeComponent, title: 'CCRP-Create new issue', canActivate:[MsalGuard]},
        {path: 'Complaint/:id', component:NewIssueHomeComponent, canActivate:[MsalGuard]},
        {path: 'Complaint/Deleted/:id', component:NewIssueHomeComponent, canActivate:[MsalGuard]},
        {path: 'Complaint/:id/actionlog', component:ComplaintActionLogComponent, canActivate:[MsalGuard]},
        {path: 'Exceptions/ComplaintNotFound/:id', component:ComplaintNotFoundComponent, canActivate:[MsalGuard]},
        {path: 'Exceptions/ConfidentialNotAuthorizedOperation/:id', component:ComplaintNotFoundComponent, canActivate:[MsalGuard]},
        {path: 'issues/:complaintNumber/:selectedRole/actionplan', component:ActionBoardComponent, canActivate:[MsalGuard]},
        {path: 'issues/:complaintNumber/actionplan', component:ActionBoardComponent, canActivate:[MsalGuard]},
        {path: 'issues/:complaintNumber/targetdatehistory', component: TargetDateHistoryComponent, canActivate:[MsalGuard]},
        {path: 'issues/:complaintNumber/:selectedRole/actionplan/card/', component:ActionPlanCardEditComponent, canActivate:[MsalGuard]},
        {path: 'issues/:complaintNumber/:selectedRole/actionplan', component:ActionBoardComponent, canActivate:[MsalGuard]},
        {path: 'issues/:complaintNumber/:selectedRole/actionplan/card/:id', component:ActionPlanCardEditComponent, canActivate:[MsalGuard]},
        {path: 'issues/:complaintNumber/actionplan/card/:id', component:ActionPlanCardEditComponent, canActivate:[MsalGuard]},
        {path: 'issues/:complaintNumber/:selectedRole/actionplan/card/:id/log', component:ActionPlanLogComponent, canActivate:[MsalGuard]},
        {path: 'issues/:complaintNumber/actionplan/card/:id/log', component:ActionPlanLogComponent, canActivate:[MsalGuard]},
        {path: 'reports', component: ReportsMenuComponent, title:'CCRP-Reports', canActivate:[MsalGuard]},
        {path: 'reports/AllInOne', component: AllInOneComponent, canActivate:[MsalGuard], data: {some_data: 'some value'}},
        {path: 'AllInOne/export',component:ViewReportComponent, canActivate:[MsalGuard]},
        {path: 'AllInOne/export/:id1',data: {some_data: 'some value'}, component:ViewReportComponent, canActivate:[MsalGuard]},
        {path: 'reports/weeklyreport', component: WeeklyReportComponent, canActivate:[MsalGuard]},
        {path: 'globalsearch', component: GlobalSearchComponent,title:'CCRP-Search', canActivate:[MsalGuard]},
        {path: 'search/hubProcessOwner', component: HubPOSearchComponent,title:'CCRP-Search', canActivate:[MsalGuard]},
        {path: 'search/coordinator', component: RupersonSearchComponent,title:'CCRP-Search', canActivate:[MsalGuard]},
        {path: 'admin/pg-categories', component: PGRCACategoryComponent, canActivate:[MsalGuard]},
        {path: 'Exceptions/ComplaintHasBeenDeleted/:complaintNumber', component: ComplaintHasBeenDeletedExceptionComponent, canActivate:[MsalGuard]},
        {path: 'posearch', component: ProcessownerSearchComponent,title:'CCRP-Search', canActivate:[MsalGuard]},
        {path: 'auth', component:MsalRedirectComponent},
        {path: 'AllInOneReportsData', component: AllInOneReportsDataOnCCRPNumberComponent},
        {path: 'actionnotifications', component: ActionlogentriesInternalComponent, canActivate:[MsalGuard]},
        {path: 'issuesummary/:id', component: IssueSummaryreportComponent,canActivate:[MsalGuard]},
        {path: 'hrgterror', component: HrgtErrorComponent,canActivate:[MsalGuard]},
        {path: 'maintenance', component: MaintenanceErrorComponent,canActivate:[MsalGuard]},
        {path: 'Status/:id', component: StatusComponent,title:'CCRP-External'},
        {path:  'survey/subscriptions/:id', component: SubscribeComponent,title:'CCRP-External'},
        {path: 'survey/:id', component: SurveyTemplateComponent,title:'CCRP-External'},
        {path: 'survey/:id/:rating', component: SurveyTemplateComponent,title:'CCRP-External'},
        {path: 'hi/confirm/:id', component: HiConfirmComponent,title:'CCRP-External'},        
        {path: 'testerlogin',component:TesterLoginComponent},
        {path: 'downloadEightD/:storeFileNames/:displayNames', component: DownloadEightDComponent,canActivate:[MsalGuard],title:'CCRP-External'},
        {path:'**', component:PageNotFoundComponent, canActivate:[MsalGuard]},

];

export function setUrl() {
  let urlLang = '';
  if(window.location.pathname.split('/')[1].length == 2  && window.location.pathname.split('/')[1] !== 'hi') {
    urlLang = '/' + window.location.pathname.split('/')[1];
  }
  else {
    urlLang = environment.default_culture;
  }
  return urlLang;
}

@NgModule({
  imports: [RouterModule.forRoot(routes, { initialNavigation : 'enabledBlocking'})],
  providers: [{provide: APP_BASE_HREF, useValue: setUrl()}],
  exports: [RouterModule]
})
export class AppRoutingModule { }
