import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToasterserviceService } from 'src/app/services/toasterservice.service';
import { environment } from 'src/environments/environment';
import { AdminSystemusersAuthserviceService } from './admin-systemusers-authservice.service';
import { EmployeeListService } from 'src/app/services/employee-list.service';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class AdminSystemusersServiceService {

  constructor(private http:HttpClient,
              private toaster:ToasterserviceService,
              private suAuthService:AdminSystemusersAuthserviceService,
              private empSerivce: EmployeeListService) { }

  getSystemUsers(){
  return this.http.get(`${environment.azure.baseUrl}api/v1/systemusers/search`);
  }

  deleteSystemUserRole(geid:string,roleId:number){
    let params = new HttpParams()
    .set('geid', geid)
    .set('roleId',roleId)
    return this.http.delete(`${environment.azure.baseUrl}api/v1/systemusers/delete`,{params});
  }

  addSystemUserRoles(geid:string, roles:any) {
    return this.http.post(`${environment.azure.baseUrl}api/v1/systemusers/add`,{ geid: geid, roleIds: roles });
  }
}
