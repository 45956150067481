import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { TransalationserviceService } from '../transalationservice.service';
import { ToasterserviceService } from 'src/app/services/toasterservice.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-translation-resource-item',
  templateUrl: './translation-resource-item.component.html',
  styleUrls: ['./translation-resource-item.component.css']
})
export class TranslationResourceItemComponent implements OnInit {

  oldItem: any = {};
  @Input('item') item: any;
  @Input('operationName') operationName:any;
  @Input('allowEdit') allowEdit:boolean;
  @Input('selectedLanguage') selectedLanguage:any;
  res: any;
  isInEditMode: boolean = false;
  @Input('isDisabled') isDisabled: boolean;

  editResourceForm!:FormGroup;

  constructor(private transaltionService:TransalationserviceService,
    private toaster:ToasterserviceService,
    private fb: FormBuilder) {
  }

  ngOnInit(): void {
    this.res = this.item;
    this.editResourceForm = this.fb.group({
      translation: ['',[Validators.required, Validators.maxLength(1000)]]
    });

    this.editResourceForm.patchValue({
      translation: this.res.translation
    })
  }

  edit(resource) {
    this.oldItem = resource;
    this.isInEditMode = true;
  }
  cancel() {
    this.isDisabled = true;
    this.res = this.oldItem;
    this.editResourceForm.patchValue({
      translation: this.res.translation
    })
    this.oldItem = undefined;
    this.isDisabled = false;
    this.isInEditMode = false;
  }

  update(resource) {
    this.isDisabled = true;

    let updatedResource = {
      "id": resource.id,
      "name": resource.name,
      "resourceCultureId": resource.resourceCultureId,
      "isNew": resource.isNew,
      "culture": resource.culture,
      "englishVersion": resource.englishVersion,
      "translation": this.editResourceForm.controls['translation'].value
    }

    this.transaltionService.updateResource(updatedResource)
        .subscribe( (data:any)=> {
        if (data.success) {
            this.item = this.res;
            this.toaster.showSuccess(this.operationName);
            //window.location.reload();
         //   this.updateElement({ item: this.item });
        } else {
            this.toaster.showError(data.failureMsg);
        }

        this.isInEditMode = false;
        this.isDisabled = false;
    }, function (error) {
        this.isDisabled = false;
    });
}

}


