
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DialogService, DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { AdminService } from 'src/app/services/admin.service';
import { ToasterserviceService } from 'src/app/services/toasterservice.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { RuAuthService } from '../ru-auth.service';




@Component({
  selector: 'app-resolving-units-deleteperson',
  templateUrl: './resolving-units-deleteperson.component.html',
  styleUrls: ['./resolving-units-deleteperson.component.css']
})
export class ResolvingUnitsDeletepersonComponent implements OnInit {

  resolvingunitdeletepersonForm!: FormGroup
  filteredSubstitude: any;
  userinfo: any;
  ruProcessOwnerResolvingUnitdata: Array<any> = [];
  coordinatorResolvingUnitdata: Array<any> = [];
  iscountprocessorowner: number = 0;
  iscoordinaterowner: number = 0;
  objectdata: { ruId: any; geid: any; ruPersonType: number; };

  constructor(
    private fb: FormBuilder,
    private readonly dialogRef: DynamicDialogRef,
    private adminService: AdminService,
    public config: DynamicDialogConfig,
    public toasterService: ToasterserviceService,
    private spinner: NgxSpinnerService,
    private ruAuthService: RuAuthService
  ) {
  }

  ngOnInit(): void {
    this.resolvingunitdeletepersonForm = this.fb.group({
    })
    this.userinfo = this.config.data;
  }
  // add() {

  // }
  onClose() {
    this.dialogRef.close();
  }

  filterSubstitude(event) {
    let query = event.value;
    this.adminService.getFilteredUser(query).subscribe((res) => {
      this.filteredSubstitude = res;

    })
  }

  delete() {
    this.spinner.show();
    this.objectdata = {
      "ruId": this.userinfo.cardinfo.id,
      "geid": this.userinfo.empinfo.id,
      "ruPersonType": this.userinfo.ruPersonType
    }

    let canManage = this.ruAuthService.canManageRu(this.userinfo.cardinfo.scope, this.userinfo.cardinfo.ruProcessOwnerGeids);

    if(canManage){
      if (this.userinfo.ruPersonType == 1) {
        if (this.userinfo.cardinfo.ruProcessOwnerGeids.length > 1) {
          this.adminService.deleteRupersonsinfo(this.objectdata).subscribe((res) => {
            const deletepersonInfo = res
            this.dialogRef.close(this.objectdata);
            this.toasterService.showSuccess('RU person has been successfully deleted');
  
          })
        }
        else {
          this.spinner.hide();
          return;
        }
      }
  
      else {
        this.adminService.deleteRupersonsinfo(this.objectdata).subscribe((res) => {
          const deletepersonInfo = res
          this.dialogRef.close(this.objectdata);
          this.toasterService.showSuccess('RU person has been successfully deleted');
          this.spinner.hide();
  
        })
      }
    }
    else{
      this.toasterService.showError('Cannot delete this RU Person. Insufficient permissions.');
    }

  }

  
  gethubDataProcess()
  {
    this.ruProcessOwnerResolvingUnitdata=this.config.data.ruProcessOwnerrr;
    this.coordinatorResolvingUnitdata=this.config.data.coordinatorProcessOwner;
    this.iscountprocessorowner=this.config.data.ruProcessOwnercount;
    this.iscoordinaterowner=this.config.data.coordinatorProcessOwnercount;
  }

}
