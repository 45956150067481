<nav class="navbar navbar-default" style="background-color: #ffffff;">
  <form [formGroup]="failureModeForm" class="admin-failuremode align-resp-mid-px">
    <div class="row">
      <div class="col-sm-1 failureModeLabel">
        <label class="label-align-right label-bold mt-1">{{'admin_item_search_dv_lb' | translate}}</label>
      </div>
      <div class="col-sm-10">
        <p-dropdown [options]="divisions" optionValue="id" formControlName="business" optionLabel="id"
          [filter]="true" [resetFilterOnHide]="true" filterBy="name" [showClear]="true" placeholder="Click or type to select Business" (onChange)="businessChange($event)">
        </p-dropdown>
      </div>
      <div class="col-sm-1"></div>
    </div>
    <div class="row mt-2">
      <div class="col-sm-1 failureModeLabel">
        <label class="label-align-right label-bold mt-1">{{'admin_item_search_bu_lb' | translate}}</label>
      </div>
      <div class="col-sm-10">
        <p-dropdown [options]="bulist" optionValue="id" formControlName="bu" optionLabel="labelName" [resetFilterOnHide]="true" [filter]="true"
          filterBy="name" [showClear]="true" placeholder="Click or type to select BU" (onChange)="buChanged($event)">
        </p-dropdown>
      </div>
      <div class="col-sm-1"></div>
    </div>
    <div class="row mt-2">
      <div class="col-sm-1 failureModeLabel">
        <label class="label-align-right label-bold mt-1">{{'admin_item_search_pg_lb' | translate}}</label>
      </div>
      <div class="col-sm-10">
        <p-dropdown [options]="pglist" optionValue="id" formControlName="pg" optionLabel="labelName" [resetFilterOnHide]="true" [filter]="true"
          filterBy="name" [showClear]="true" placeholder="Click or type to select PG" (onChange)="search($event)">
        </p-dropdown>
      </div>
      <div class="col-sm-1 btn-align-resp-left">
        <button class="btnFailureMode bg-transparent btn-align-resp" [disabled]="this.failureModeForm.get('pg').value === null || this.failureModeForm.get('pg').value === undefined || this.failureModeForm.get('pg').value === ''" (click)="showPGPopUp()">
          <i class="hitachiicon-users bg-transparent"></i>
        </button>
      </div>
    </div>
  </form>
</nav>
<div class="failureMode pb-4">
<app-failuremode-treeitem [collection]="items" [canManage]="allowManage" [disableActions]="isSearching"
[level]="-1" [pgCode]="this.selected.pg" levelValidationMessage="{{'admin_item_lvl_validation_msg' | translate}}"></app-failuremode-treeitem>

<div *ngIf="!hasRootChild && items" class="text-center">
  <span class="text-muted">{{'admin_item_search_no_childs_msg' | translate}}</span>
</div>

<div class="text-center" *ngIf="!items">
  <span class="text-muted">{{'admin_item_search_msg' | translate}}</span>
</div>
</div>
