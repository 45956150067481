import { Component, OnInit } from '@angular/core';
import { AdminSystemusersServiceService } from '../admin-systemusers-service.service';
import * as _ from 'lodash';
import { EmployeeListService } from 'src/app/services/employee-list.service';
import { ToasterserviceService } from 'src/app/services/toasterservice.service';
import { AdminSystemusersAuthserviceService } from '../admin-systemusers-authservice.service';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ReopenIssuePopupComponent } from 'src/app/components/new-issue/reopen-issue-popup/reopen-issue-popup.component';
import { DeleteSystemuserRoleComponent } from '../delete-systemuser-role/delete-systemuser-role.component';
import { AddSystemuserRoleComponent } from '../add-systemuser-role/add-systemuser-role.component';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';

@Component({
  selector: 'app-admin-systemuser',
  templateUrl: './admin-systemuser.component.html',
  styleUrls: ['./admin-systemuser.component.css']
})
export class AdminSystemuserComponent implements OnInit {
  order_mode: string = 'asc';
  isSearching: boolean = false;
  hasCriteriaSelected: boolean = false;
  systemUsers: any = [];
  systemUsersFiltered: any = [];
  search_keyword: string = "";
  order_by = 'userInfo.nameLatin';
  previousSearch = {
    isGlobalProcessOwner: true,
    isSupportDeskMember: true,
    isResourceEditor: true,
    isManagementRole: true,
    employee: null
  }

  criteria = {
    isGlobalProcessOwner: true,
    isSupportDeskMember: true,
    isResourceEditor: true,
    isManagementRole: true,
    employee: null
  };

  constructor(private adminSUservice: AdminSystemusersServiceService,
    private toaster: ToasterserviceService,
    private suAuthService: AdminSystemusersAuthserviceService,
    private empSerivce: EmployeeListService,
    private ref: DynamicDialogRef,
    private dialogService: DialogService,
    private breadcrumbService: BreadcrumbService
  ) { }

  ngOnInit(): void {
    this.breadcrumbService.menuItems = [
      { label: 'ADMIN PANEL', routerLink: 'admin' },
      { label: 'MANAGE SYSTEM USERS' }
    ]

    this.loadSystemUsers();
  }

  async loadSystemUsers() {
    this.adminSUservice.getSystemUsers().subscribe(
      (data: any) => {
        let geids = [];
        let currentUserManageScope!: any;
        currentUserManageScope = this.suAuthService.manageScope;

        for (let index = 0; index < data.length; index++) {
          data[index].canAddSuRole = this.suAuthService.canAddSuRole(data[index]);
          data[index].canManageGlobalProccessOwner = currentUserManageScope.globalProcessOwner;
          data[index].canManageSupportDeskMember = currentUserManageScope.supportDeskMember;
          data[index].canManageResourceEditor = currentUserManageScope.resourceEditor;
          data[index].canManageManagementRole = currentUserManageScope.managementRole;
          geids.push(data[index].geid);

          if (index == data.length - 1) {
            this.empSerivce.employeeDashboardDetails(_.uniq(geids)).then((empInfo: any) => {
              if (empInfo) {
                data.forEach((su: any) => {
                  su.userInfo = empInfo.find((e: any) => su.geid === e.id);
                });
                this.systemUsers = data;
                this.refreshView();
              }
            })
          }
        }
      },
      error => {
        this.toaster.showError('error while fetching records');
      }
    );
  };

  setOrderMode(mode) {
    this.order_mode = mode;
    this.refreshView();
  };

  isGlobalProcessOwnerClicked() {
    this.criteria.isGlobalProcessOwner = !this.criteria.isGlobalProcessOwner;
    this.refreshView();
  };

  isSupportDeskClicked() {
    this.criteria.isSupportDeskMember = !this.criteria.isSupportDeskMember;
    this.refreshView();
  };

  isResourceEditorClicked() {
    this.criteria.isResourceEditor = !this.criteria.isResourceEditor;
    this.refreshView();
  };

  isManagementRoleClicked() {
    this.criteria.isManagementRole = !this.criteria.isManagementRole;
    this.refreshView();
  };

  refreshView() {
    this.systemUsersFiltered.length = 0;
    this.systemUsersFiltered = [];
    let systemUsersTemp = [];
    if (this.search_keyword != '') {
      systemUsersTemp = this.systemUsers.filter(user => {
        const userString = JSON.stringify(user).toLowerCase();
        return userString.includes(this.search_keyword.toLocaleLowerCase());
      });
    } else {
      systemUsersTemp = this.systemUsers;
    }
    _.forEach(_.filter(systemUsersTemp, (su) => {
      return (this.criteria.isGlobalProcessOwner && su.isGlobalProcessOwner == this.criteria.isGlobalProcessOwner)
        || (this.criteria.isSupportDeskMember && su.isSupportDeskMember == this.criteria.isSupportDeskMember)
        || (this.criteria.isResourceEditor && su.isResourceEditor == this.criteria.isResourceEditor)
        || (this.criteria.isManagementRole && su.isManagementRole == this.criteria.isManagementRole)
        ;
    }),
      (su) => {
        this.systemUsersFiltered.push(su);
      }
    )
    this.systemUsersFiltered = _.orderBy(this.systemUsersFiltered, this.order_by, (this.order_mode === 'desc') ? 'desc' : 'asc');
    this.previousSearch.isGlobalProcessOwner = this.criteria.isGlobalProcessOwner
    this.previousSearch.isSupportDeskMember = this.criteria.isSupportDeskMember
    this.previousSearch.isResourceEditor = this.criteria.isResourceEditor
    this.previousSearch.isManagementRole = this.criteria.isManagementRole
    this.previousSearch.employee = this.search_keyword
  };

  onSearchValueChange() {
    this.refreshView();
  }

  deleteSystemRoleUser(inputData: any) {
    this.ref = this.dialogService.open(DeleteSystemuserRoleComponent, {
      header: 'Delete System User Role',
      width: '40%',
      position: 'top',
      keepInViewport: true,
      autoZIndex: true,
      styleClass: 'deleteSystemUser',
      data: { user: inputData }
    });

    this.ref.onClose.subscribe((isDeleted: any) => {
      if (isDeleted) {
        this.criteria.isGlobalProcessOwner = this.previousSearch.isGlobalProcessOwner
        this.criteria.isSupportDeskMember = this.previousSearch.isSupportDeskMember
        this.criteria.isResourceEditor = this.previousSearch.isResourceEditor
        this.criteria.isManagementRole = this.previousSearch.isManagementRole
        this.search_keyword = this.previousSearch.employee
        this.loadSystemUsers();
      }
    })
  }

  addSystemRoleUser(suScope?: any) {
    this.ref = this.dialogService.open(AddSystemuserRoleComponent, {
      header: suScope ? 'Add Role' : 'Add Person',
      width: '50%',
      position: 'top',
      keepInViewport: true,
      autoZIndex: true,
      styleClass: 'dialogue-popup addRole_sytemUser_admin',
      data: suScope
    });

    this.ref.onClose.subscribe((result) => {
      if (result.result == 'ok') {
        this.criteria.isGlobalProcessOwner = this.previousSearch.isGlobalProcessOwner
        this.criteria.isSupportDeskMember = this.previousSearch.isSupportDeskMember
        this.criteria.isResourceEditor = this.previousSearch.isResourceEditor
        this.criteria.isManagementRole = this.previousSearch.isManagementRole
        this.search_keyword = this.previousSearch.employee
        this.loadSystemUsers();
      }
    })
  }

}
