import { Injectable } from '@angular/core';
import { SessionStorageServiceService } from './session-storage-service.service';
import { MdServiceService } from './md-service.service';

@Injectable({
  providedIn: 'root'
})
export class GlobalRuAuthServiceService {
  userInfo: any;
  userScopes : any = [];
  userGeid: any;

  constructor(private sessionStorage: SessionStorageServiceService,
    private mdData: MdServiceService) { 
    this.userInfo = this.sessionStorage.getData('userInfo');
    this.userGeid = this.userInfo.geid;
    this.refreshUserInfoData(this.userInfo);
  }

  refreshUserInfoData(data){
    // var usScopes = [];
    data.processOwnerScopes.forEach(poScope => {
      var userScope = {
        div: null,
        bus: [],
        pgs: [],
        divMgr: null,
        pgMgr: null,
        buMgr: null
      };

      if (!poScope.region && !poScope.country){
        if (poScope.pg){
          this.mdData.pg().then((pgs)=>{
            pgs.forEach(p => {
              if(p.id == poScope.pg){
                userScope.div = p.divCode;
                userScope.bus.push(p.buCode);
                userScope.pgs.push(p.id);
              }
            });
            userScope.pgMgr = true;
          });
        }
        else if(poScope.bu){
          this.mdData.bu().then((bus)=>{
            bus.forEach(b => {
              if (b.id == poScope.bu) {
                userScope.bus.push(b.id);
                userScope.div = b.divCode;
                userScope.buMgr = true;
              }
            });
          })

          this.mdData.pg().then((pgs)=>{
            pgs.forEach(p => {
              if (p.buCode == poScope.bu) {
                userScope.pgs.push(p.id);
              }
            });
          })
        }
        else if(poScope.div){
          userScope.div = poScope.div;
          userScope.divMgr = true;

          this.mdData.bu().then((bus)=>{
            bus.forEach(b => {
              if (b.divCode == poScope.div) {
                userScope.bus.push(b.id);
              }
            });
          })

          this.mdData.pg().then((pgs)=>{
            pgs.forEach(p => {
              if (p.divCode == poScope.div) {
                userScope.pgs.push(p.id);
              }
            });
          })
        }
      }

      //usScopes.push(userScope);
      this.userScopes.push(userScope);
    });

    //return usScopes;
  }

  canManageGlobalRu(globalRuScope){
    if (this.userInfo.hasGlobalPermissions) {
      return true;
    }

    var canManage: any = [];

    if (globalRuScope.divCode){
      this.userScopes.forEach(us => {
        if ((us.div == globalRuScope.divCode)
                    && ((!globalRuScope.buCode && us.divMgr) || (globalRuScope.buCode != null && us.bus.indexOf(globalRuScope.buCode) > -1))
                    && ((!globalRuScope.pgCode && (us.divMgr || us.buMgr)) || (globalRuScope.pgCode != null && us.pgs.indexOf(globalRuScope.pgCode) > -1)))
                    {
                      canManage.push(true);
                    }
      });
    }

    return canManage.length > 0;
  }
}
