import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ProcessStepService } from '../process-step.service';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { AdminProcessStepsDeactivateModalComponent } from '../admin-process-steps-deactivate-modal/admin-process-steps-deactivate-modal.component';
import { AdminEditProcessStepsModalComponent } from '../admin-edit-process-steps-modal/admin-edit-process-steps-modal.component';
import { ToasterserviceService } from 'src/app/services/toasterservice.service';
import { AdminProcessStepsComponent } from '../admin-process-steps/admin-process-steps.component';

@Component({
  selector: 'app-process-step-item-member',
  templateUrl: './process-step-item-member.component.html',
  styleUrls: ['./process-step-item-member.component.css']
})
export class ProcessStepItemMemberComponent implements OnInit {

  MAX_CHILD_LEVEL = 10;
  @Input() member : any = [];
  @Input() allowActions: any;
  @Input() isSearching: any;
  @Input() lookup: any;
  @Input() level: any;
  @Input() message: any;

  // @Output() refreshFn : EventEmitter<any> = new EventEmitter<any>();

  constructor(private adminProcessStepsService: ProcessStepService,
    public ref: DynamicDialogRef,
    public dialogService: DialogService,
    public toasterService: ToasterserviceService,
    private adminProcessStepComp: AdminProcessStepsComponent) { }

  ngOnInit(): void {
  }

  edit(item){
    let itemToEdit = item;
    if (!item.duplicateScope)
        itemToEdit.duplicateScope = this.lookup;
    
    this.ref = this.dialogService.open(AdminEditProcessStepsModalComponent, {
      header: 'Edit category',
      styleClass: 'editProcessSteps',
      width: '50%',
      position: 'top',
      data: itemToEdit
    })

    this.ref.onClose.subscribe((data)=>{
      if(data?.result === 'ok'){
        this.member.isActive = data.model.isActive;
        this.member.name = data.model.name;

        this.adminProcessStepComp.refreshNodes(data.results);

        this.toasterService.showSuccess('Process Steps node has been updated.');
      }
    })
  }

  expand(action){
    this.member.isExpanded = !action;
    if(true ===  this.member.hasChildren && false === this.member.areChildrenLoaded && this.member.id != -1){
      
      this.adminProcessStepComp.blockProcessStepsUI();
      this.adminProcessStepsService.getProcessStepsChildren(this.member.id).subscribe((data)=>{
        this.member.areChildrenLoaded = true;
        this.member.children = data;

        let duplicated :any = [];

        data.forEach(obj => {
          duplicated.push({
            name: obj.name,
            isActive: obj.isActive,
            id: obj.id
          });
        });

        this.member.children.forEach(child => {
          child.duplicateScope = duplicated;
        });
        this.adminProcessStepComp.unblockProcessStepsUI();
      },
      ()=>{
        this.adminProcessStepComp.unblockProcessStepsUI();
      })
    }
  }

  remove(item){
    this.ref = this.dialogService.open(AdminProcessStepsDeactivateModalComponent, {
      header: 'Confirmation',
      styleClass: 'deactive-processStep-modal',
      width: '40%',
      position: 'top',
      data: item
    })

    this.ref.onClose.subscribe((data)=>{
      if(data?.result === 'ok'){
        this.member.isActive = data.model.isActive;
        this.member.name = data.model.name;
        
        this.adminProcessStepComp.refreshNodes(data.results);

        this.toasterService.showSuccess('Process step node has been updated.');
      }
    })
  }


  add(item){
    if (this.member.hasChildren && !this.member.areChildrenLoaded && this.member.id != -1){
      this.adminProcessStepsService.getProcessStepsChildren(this.member.id).subscribe((data)=>{
        this.member.areChildrenLoaded = true;
        this.member.children = data;

        let duplicated :any = [];
        data.forEach(obj => {
          duplicated.push({
            name: obj.name,
            isActive: obj.isActive,
            id: obj.id
          });
        });

        this.member.children.forEach(child => {
          child.duplicateScope = duplicated;
        });

        this.add(this.member);
        this.adminProcessStepComp.unblockProcessStepsUI();
      },
      ()=>{
        this.adminProcessStepComp.unblockProcessStepsUI();
      })
    } else if(this.level < this.MAX_CHILD_LEVEL){
      let itemToAdd = {
        categoryName: item.name,
        isActive: true,
        duplicateScope: item.children,
        parentId: item.id,
        hasChildren: false,
        children: []
      };

      this.ref = this.dialogService.open(AdminEditProcessStepsModalComponent, {
        header: itemToAdd.parentId === -1 ? "Add child to 'Main' category":"Add child to '" + itemToAdd.categoryName + "' category",
        width: '50%',
        styleClass: 'editProcessSteps',
        position: 'top',
        data: itemToAdd
      })

      this.ref.onClose.subscribe((data)=>{
        if(data?.result === 'ok'){
          this.member.hasChildren = true;
          this.member.isExpanded = true;
          this.member.children.push(data.model);

          this.adminProcessStepComp.refreshNodes(data.results);

          this.toasterService.showSuccess('Process step has been added successfuly.');
        }
      })
    }
  }

  // refreshChild(item){
  //   this.refreshFn.emit(item);
  // }

}
