import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { AdvancedCustomerSelectionPopupComponent } from '../advanced-customer-selection-popup/advanced-customer-selection-popup.component';
import { AdminCustomerContactsService } from 'src/app/services/admin-customer-contacts.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DeactivateContactPopupComponent } from '../deactivate-contact-popup/deactivate-contact-popup.component';
import { AddContactPopupComponent } from '../add-contact-popup/add-contact-popup.component';
import * as _ from 'lodash';
import { NgxSpinner, NgxSpinnerService } from 'ngx-spinner';
import { SharedService } from 'src/app/services/shared.service';
import { EditContactComponent } from '../edit-contact/edit-contact.component';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-customer-contacts',
  templateUrl: './customer-contacts.component.html',
  styleUrls: ['./customer-contacts.component.css', '../../../../../../src/styles.css']
})

export class CustomerContactsComponent implements OnInit {
  @Output() sendCustObj: EventEmitter<any> = new EventEmitter<any>();
  @Output() sendSearchObj: EventEmitter<any> = new EventEmitter<any>();
  @Output() sendDeactivatedObj: EventEmitter<any> = new EventEmitter<any>();
  @Output() sendCustomerEditObj: EventEmitter<any> = new EventEmitter<any>();
  @Output() sendContactObj: EventEmitter<any> = new EventEmitter<any>();

  @Output() filterSearchCustomerDetails: any = [];
  admCustomerContactForm!: FormGroup;
  order_mode = 'asc';
  order_by = 'customer.companyFullName';
  searchResultOrdered = [];
  pageSize = 12;
  isSearching = false;
  filteredCustomer: any = [];
  filteredContacts: any;
  data: any = { emails: [], guids: [] };
  receivedName: any;
  hasCriteriaSelected = false;
  isCriteriaSelectedEmpty = false;
  hideShowBtnDiv: boolean = true;
  showDeactivated = false;
  selected = {
    customer: {
      customers: []
    },
    customerContacts: []
  };

  filterIsActiveDetails: any = [];
  activeShowErrorMessage: boolean = false;
  activeFalseDeactivateErrorMessage: boolean = false;
  isContact = false;
  objCoustmercontact: any = [];
  filterCompanyDetails: any = [];
  filterIsNotActiveDetails :any = [];

  totalRecords: number;
  currentPage = 1;
  pagesCount = 0;
  numberOfCardsPerPage = 10;
  hubDetailsAsPerPagination: any = [];
  updatedFilteredDetails: any = [];
  showPagination: boolean = false;
  countActive:number = 0;

  advancedCustomerBtn = 'Advanced Customer Search Window';
  searchBtnMsg = 'Click to perform searching';
  addContactMsg = 'Click to add new contact';
  ascSortMsg = 'Ascending sort';
  descSortMsg = 'Descending sort';
  deactivateShowMsg = 'Show deactivated contacts';
  editMsg = 'Edit';
  deactivateIconMsg = 'Deactivate';
  updatedFromSalesForceTrueTooltipMsg = 'This customer contact is only editable in Salesforce';
  public customersName :any;
  backToIssueBtn : boolean = false;

  constructor(
    private fb: FormBuilder,
    private config: DynamicDialogConfig,
    private dialogService: DialogService,
    private ref: DynamicDialogRef,
    private admCustomer: AdminCustomerContactsService,
    private spinner: NgxSpinnerService,
    private sharedService: SharedService,
    private breadcrumbService: BreadcrumbService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {
    // console.log("???????",this.route.snapshot.paramMap.get('customerId'),this.route.snapshot.paramMap.get('complaintId'));
    if(this.route.snapshot.paramMap.get('customerId') && this.route.snapshot.paramMap.get('complaintId')){
      this.backToIssueBtn = true;
      this.admCustomer.getCustomerByCode(this.route.snapshot.paramMap.get('customerId')).subscribe((res) => {
        this.filteredCustomer = res;
        this.admCustomerContactForm.patchValue({
          customer: this.filteredCustomer
        });
        this.data.guids.push(this.route.snapshot.paramMap.get('customerId'));
        this.search();
      });
    }
    
    this.breadcrumbService.menuItems = [

      { label: 'ADMIN PANEL', routerLink: 'admin' },
      { label: 'MANAGE CUSTOMER CONTACT' }

    ]

    this.admCustomerContactForm = this.fb.group({
      customer: [''],
      contacts: ['']
    })
    this.admCustomer.getDivisions().subscribe((response) => {
      const divisions = response;
    })

    this.customersName = this.config.data;

  };

  filterCustomerDetails(event) {
    if (event.query.length > 2) {
      let query = event.query;
      this.admCustomer.getFilteredCustomerDetails(query).subscribe((res) => {
        this.filteredCustomer = res;
      })
    }
  };

  filterContactsDetails(event) {
    if (event.query.length > 2) {
      let query = event.query;
      this.admCustomer.getFilteredContactDetails(query).subscribe((res) => {
        this.filteredContacts = res;
      });
    }
  }

  customerSelect(event) {
    this.admCustomer, this.admCustomerContactForm.controls['customer'].value.forEach(customer => {
      if (!this.data.guids.includes(customer?.id)) {
        this.data.guids.push(customer?.id)
      }
    });
  }

  customerGuidsUnselect(event) {
    const unselectedGuids = event.id;
    const index = this.data.guids.findIndex(item => item === unselectedGuids);
    if (index !== -1) {
      this.data.guids.splice(index, 1)
    }
  }

  customerSelectContact(event) {
    this.admCustomer, this.admCustomerContactForm.controls['contacts'].value.forEach(contacts => {
      if (!this.data.emails.includes(contacts?.email)) {
        this.data.emails.push(contacts?.email)
      }
    });
  }

  customerEmailUnselect(event) {
    const unselectedEmail = event.email;
    const index = this.data.emails.findIndex(item => item === unselectedEmail);
    if (index !== -1) {
      this.data.emails.splice(index, 1)
    }
  }

  search() {
    this.spinner.show();
    this.hubDetailsAsPerPagination = [];
    this.updatedFilteredDetails= [];
    this.admCustomer.getCustomerSearch(this.data).subscribe((response) => {
      if (response.length > 0) {
        this.totalRecords = response.length;
        this.filterSearchCustomerDetails = response;
        this.updatedFilteredDetails = this.filterSearchCustomerDetails;
        this.updatedCards();
        this.pagesCount = this.totalRecords / this.numberOfCardsPerPage;
        this.showPagination = true;

        for (let i = 0; i < this.updatedFilteredDetails.length; i++) {
          this.updatedFilteredDetails[i].showContacts = true;
          this.updatedFilteredDetails[i].showDeactMsg = true;
          this.updatedFilteredDetails[i].isContact = true;
          this.countActive = 0;
          this.updatedFilteredDetails[i].filterIsNotActiveDetails = []
          for (let j = 0; j < this.updatedFilteredDetails[i].customerContacts.length; j++) {

            if (this.updatedFilteredDetails[i].customerContacts[j].isActive == false) {
              this.filterIsActiveDetails.push(this.updatedFilteredDetails[i].customerContacts[j]);
              this.updatedFilteredDetails[i].isActiveFalse = true;
            }
            else if (this.updatedFilteredDetails[i].customerContacts[j].isActive == true) {
              // this.filterIsNotActiveDetails.push(this.updatedFilteredDetails[i].customerContacts[j])
             this.hubDetailsAsPerPagination[i].filterIsNotActiveDetails.push(this.updatedFilteredDetails[i].customerContacts[j])

            }
          }
        }
        // console.log('abcd', this.admCustomerContactForm.controls['customer'].value)

        // console.log('///////////////',this.updatedFilteredDetails[i].filterIsNotActiveDetails)

        this.isSearching = false;
        this.isCriteriaSelectedEmpty = false;
        this.spinner.hide();
      }

      else {
        this.updatedFilteredDetails = [];
        this.isCriteriaSelectedEmpty = true;
        this.showPagination = false;
        this.spinner.hide();
      }
    })
  }

  setOrderMode(mode: string) {
    this.order_mode = mode;
    this.sortData();
  };

  refreshComplaintsWithSorting(orderMode: any, orderBy: any) {
    this.order_mode = orderMode;
    this.order_by = orderBy;
    this.refreshView();
  }

  sortData() {
    this.refreshComplaintsWithSorting(this.order_mode, this.order_by)
  };

  refreshView() {
    for (let i = 0; i < this.hubDetailsAsPerPagination.length; i++) {
      if (this.hubDetailsAsPerPagination[i].customer.companyFullName != null) {
        this.filterCompanyDetails.push(this.hubDetailsAsPerPagination[i].customer.companyFullName)
      }
    }
    this.searchResultOrdered = [];
    this.hubDetailsAsPerPagination = _.orderBy(this.hubDetailsAsPerPagination, this.order_by, (this.order_mode === 'desc') ? 'desc' : 'asc');
    this.hasCriteriaSelected = true;

  };

  reloadSearchResults = function () {
    this.search();
  };

  afterSelection = function (data) {
    this.selected.customer.customers = data.customers;
    if (data.customers.length > 0) {
      this.reloadSearchResults();
    } else {
      this.filterSearchCustomerDetails = [];
      this.reloadView();
    }
  }

  reloadView = function () {
    if (this.selected.customer.customers.length > 0 || this.selected.customerContacts.length > 0) {
      this.search();
    } else {
      this.filterSearchCustomerDetails = [];
      this.refreshView();
    }
  };

  setShowDeactivated = function (IndexNumber: number, event, CustomerDetails: any) {
    let objCoustmercontact = []
    for (let i = 0; i < this.filterSearchCustomerDetails[IndexNumber]?.customerContacts?.length; i++) {
      if (this.filterSearchCustomerDetails[IndexNumber]?.customerContacts[i].isActive == false) {
        objCoustmercontact.push(
          this.filterSearchCustomerDetails[IndexNumber]?.customerContacts[i]
        )
      }

      if (i == this.filterSearchCustomerDetails[IndexNumber]?.customerContacts?.length - 1) {
        this.filterSearchCustomerDetails[IndexNumber].deactivededCustomers = objCoustmercontact;
      }
    }
    if (CustomerDetails.showContacts == true) {
      CustomerDetails.showDeactMsg = false;
      CustomerDetails.showContacts = false;
    }
    else {
      CustomerDetails.showContacts = true;
      CustomerDetails.showDeactMsg = true;
    }
  };

  isActivatedTrue = function (IndexNumber: number) {
    let objActivecontacts = []
    for (let i = 0; i < this.filterSearchCustomerDetails[IndexNumber]?.customerContacts?.length; i++) {
      if (this.filterSearchCustomerDetails[IndexNumber]?.customerContacts[i].isActive == true) {
        objActivecontacts.push(
          this.filterSearchCustomerDetails[IndexNumber]?.customerContacts[i]
        )
      }

      if (i == this.filterSearchCustomerDetails[IndexNumber]?.customerContacts?.length - 1) {
        this.filterSearchCustomerDetails[IndexNumber].activatedCustomers = objActivecontacts;
      }
    }
    if (objActivecontacts.length <= 0) {
      this.activeShowErrorMessage = true
    }
    else if (objActivecontacts.length >= 1) {
      this.activeShowErrorMessage = false
    };

  };

  isActivatedFalseMessgage = function (IndexNumber: number) {
    let objCoustmercontact = []
    for (let i = 0; i < this.filterSearchCustomerDetails[IndexNumber]?.customerContacts?.length; i++) {
      if (this.filterSearchCustomerDetails[IndexNumber]?.customerContacts[i].isActive == false) {
        objCoustmercontact.push(
          this.filterSearchCustomerDetails[IndexNumber]?.customerContacts[i]
        )
      }

      if (i == this.filterSearchCustomerDetails[IndexNumber]?.customerContacts?.length - 1) {
        this.filterSearchCustomerDetails[IndexNumber].deactivededCustomers = objCoustmercontact;
      }
    }
    if (objCoustmercontact.length <= 0) {
      this.isContact = true;
    }
    else if (objCoustmercontact.length >= 1) {
      this.isContact = false;
    };
  };

  isCustomerContactEmail = function (contact) {
    if (contact.email && contact.email.length > 1)
      return true;
    return false;
  }

  advancedCustomerSelection() {
    this.ref = this.dialogService.open(AdvancedCustomerSelectionPopupComponent, {
      header: 'Customer selection ',
      width: '80%',
      position: 'top',
      keepInViewport: true,
      autoZIndex: true,
      styleClass: 'advanced-customer-selection-popup',
      data: this.admCustomerContactForm.controls['customer'].value
    });

    this.ref.onClose.subscribe((received: any) => {

      if (received) {
        this.filteredCustomer=[];
        this.data.guids=[];
        this.receivedName = received?.selectedCustomer
        this.filteredCustomer = [...this.filteredCustomer, ...this.receivedName]
        this.admCustomerContactForm.patchValue({ customer: this.receivedName })
        this.admCustomerContactForm.controls['customer'].value.forEach(customer => {
          if (!this.data.guids.includes(customer?.id)) {
            this.data.guids.push(customer?.id)
          }
        });
        this.admCustomer.getCustomerSearch(this.data).subscribe((resp) => {
          if (resp.length > 0) {
            this.hubDetailsAsPerPagination = resp;

            for (let i = 0; i < this.hubDetailsAsPerPagination.length; i++) {
              this.hubDetailsAsPerPagination[i].showContacts = true;
              this.hubDetailsAsPerPagination[i].showDeactMsg = true;
              this.hubDetailsAsPerPagination[i].isContact = true;


              for (let j = 0; j < this.hubDetailsAsPerPagination[i].customerContacts.length; j++) {
                if (this.hubDetailsAsPerPagination[i].customerContacts[j].isActive == false) {
                  this.filterIsActiveDetails.push(this.hubDetailsAsPerPagination[i].customerContacts[j])
                }
              }
            }
            this.showPagination = true;
            this.isSearching = false;
            this.isCriteriaSelectedEmpty = false;
            this.spinner.hide();
          }

          else {
            this.filterSearchCustomerDetails = [];
            this.updatedFilteredDetails = [];
            this.isCriteriaSelectedEmpty = true;
            this.showPagination = false;
            this.spinner.hide();
          }
        })
      }
    })
  }

  addContactPopup(custObj: any) {
    this.ref = this.dialogService.open(AddContactPopupComponent, {
      header: 'Add contact to ' + " " + custObj.companyFullName + "," + custObj.countryCode,
      width: '40%',
      position: 'top',
      keepInViewport: true,
      autoZIndex: true,
      styleClass: 'add-contact-popup',
      dismissableMask: false
    });
    this.sendCustObj.emit(custObj)
    this.sharedService.setObject(custObj);

    this.sendSearchObj.emit(this.data)
    this.sharedService.setSearchObject(this.data);

    this.ref.onClose.subscribe((searchCustData) => {
      if (searchCustData) {
        this.search();
      }
    });
  }

  deactivateContactPopup(id: any) {
    this.ref = this.dialogService.open(DeactivateContactPopupComponent, {
      header: 'Confirmation',
      width: '40%',
      position: 'top',
      keepInViewport: true,
      autoZIndex: true,
      styleClass: 'deactivate-contact-popup'
    })

    this.sendDeactivatedObj.emit(id)
    this.sharedService.setDeactivatedObject(id);


    this.ref.onClose.subscribe((receivedCustomerId) => {
      if (receivedCustomerId) {
        this.search()
      }
    })
  }

  editContact(custEditObj: any, contact: any) {
    this.ref = this.dialogService.open(EditContactComponent, {
      header: 'Edit contact for' + " " + contact.companyFullName + "," + contact.countryCode,
      width: '40%',
      position: 'top',
      keepInViewport: true,
      autoZIndex: true,
      styleClass: 'edit-contact-popup'
    });

    this.sendCustomerEditObj.emit(custEditObj)
    this.sharedService.setEditableCustomerObject(custEditObj);

    this.sendSearchObj.emit(this.data)
    this.sharedService.setSearchObject(this.data);

    this.sendContactObj.emit(contact);
    this.sharedService.setSearchContactObject(contact);

    this.ref.onClose.subscribe((editCustomerObj) => {
      if (editCustomerObj) {
        this.search()
      }
    })
  }

  goBackToIssue(){
    if(this.goBackToIssue){
      const complaintId = this.route.snapshot.paramMap.get('complaintId');
      this.router.navigateByUrl('/complaint/' + complaintId);
    }
  }

  // code for pagination

  updatedCards() {
    const startIndex = (this.currentPage - 1) * this.numberOfCardsPerPage;
    const endIndex = startIndex + this.numberOfCardsPerPage;
    this.hubDetailsAsPerPagination = this.updatedFilteredDetails.slice(startIndex, endIndex);

  }

  previousPage() {
    if (this.hasPrevious()) {
      return this.currentPage - 1;
    }
    return 0;
  };

  hasPrevious() {
    return this.currentPage > 1;
  };

  previousClicked() {
    if (this.hasPrevious()) {
      this.currentPage--;
      this.updatedCards();
    }
  };

  gotoPage(page) {
    this.currentPage = page;
    this.updatedCards();
  }

  showFirstPageSeparator() {
    return this.currentPage > 3;
  };

  hasNext() {
    return this.currentPage < this.pagesCount;
  };

  nextPage() {
    if (this.hasNext()) {
      return this.currentPage + 1;
    }
    return 0;
  };

  showFirstPage() {
    return this.hasPrevious() && this.previousPage() > 1;
  };

  showLastPageSeparator() {
    var result = this.hasNext() && this.nextPage() < this.pagesCount - 1;
    return result;
  };

  showLastPage() {
    return this.hasNext() && this.nextPage() < this.pagesCount;
  };

  nextClicked() {
    if (this.hasNext()) {
      this.currentPage++;
      this.updatedCards();
    }
  };

}
