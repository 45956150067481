import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TreeNode } from 'primeng/api';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DefectPopupService {

  constructor(private http: HttpClient) {}

  getFiles(payload) : any {
    return this.http
      // .get<TreeNode>('http://localhost:3000/defectTypePopupData')
      .post<TreeNode>(environment.azure.baseUrl + 'api/v1/Data/GetFailureModes?parentId=' + payload.parentId + '&resolvingUnitId='
      + payload.resolvingUnitId + '&balId=' + payload.balId + '&isDefectType=' + payload.isDefectType, payload)
      .toPromise()
      .then((res) => <TreeNode[]>res);
  }

  getDefectCause(payload) : any {
    return this.http
      .get<TreeNode>(environment.azure.baseUrl + 'api/v1/PGCategory?code=' + payload.code + '&buCode='
      + payload.buCode + '&resolvingUnitBalId=' + payload.resolvingUnitBalId + '&whereIsTheIssueBalId=' + payload.whereIsTheIssueBalId
      + '&isDefectCause=' + payload.isDefectCause + '&isActive=' + payload.isActive)
      .toPromise()
      .then((res) => <TreeNode[]>res);
  }

  getDefectCauseChildren(payload) {
    return this.http
      .get<TreeNode>(environment.azure.baseUrl + 'api/v1/PGCategory/' + payload.code + '/'
      + payload.buCode + '/' + payload.resolvingUnitBalId + '/' + payload.whereIsTheIssueBalId
      + '/' + payload.isDefectCause + '/' + payload.parentId + '?isActive=' + payload.isActive)
      .toPromise()
      .then((res) => <TreeNode[]>res);
  }
}
