import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TreeNode } from 'primeng/api';
import { PrimeNGConfig } from "primeng/api";
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FailuremodeService } from 'src/app/services/failuremode.service';

@Component({
  selector: 'app-failure-mode-popup',
  templateUrl: './failure-mode-popup.component.html',
  styleUrls: ['./failure-mode-popup.component.css']
})
export class FailureModePopupComponent implements OnInit {

  files1: any = [];
  selectedNode!: TreeNode | any;
  // textBoxEnable: boolean = false;
  selectCloseEnable: boolean = false;
  contactDetailsForm!: FormGroup;
  isButtonVisible: boolean = true;
  resolvingUnitId: any;
  loading: boolean = false;
  selectedBuCode: string = '';

  constructor(
    private http: HttpClient,
    private failureModeService:FailuremodeService ,
    private primengConfig: PrimeNGConfig,
    private readonly dialogRef: DynamicDialogRef,
    private fb: FormBuilder, private config : DynamicDialogConfig
  ) {
      this.resolvingUnitId = this.config.data?.resolvingUnitId;
      this.selectedBuCode = this.config.data?.selectedBuCode;
    }

  ngOnInit(): void {
   let passingData = new FormData();
    passingData.append("resolvingUnitId", this.resolvingUnitId);
    passingData.append("parentId", '');

    passingData.append("onlyActive", 'true');


    // this.failureModeService.getFiles(passingData).subscribe(files2 => this.files3 = files2);
    // setTimeout(() => {
    //   this.enableAll();
    // }, 500)
    this.getFailureMode();
    this.contactDetailsForm = this.fb.group({
      others: ['']
    })
  }

  getFailureMode(){
    let passingData1 = {resolvingUnitId: this.resolvingUnitId, parentId: 1, onlyActive: 'true'}
    this.loading = true;
      this.failureModeService.getFailureMode(passingData1).then(files => {
        this.loading = false;
        if(files) {
          if(this.selectedBuCode == 'PGHV') {
            this.files1 = files;
            this.files1 = this.files1.filter(item => item.name.includes('PGHV Failure'));
          }
          else {
            this.files1 = files;
          }
          this.files1.map((res) => {
             res.label = res.name,
             res.data = res.flatName,
             res.key = res.name,
            //  res.expandedIcon = 'pi pi-folder-open',
            //  res.collapsedIcon = 'pi pi-folder',
             res.children = [],
             res.id = res.id,
             res.leaf = res.hasSubClasses == true ? false : true,
             res.selectable = res.hasSubClasses ? false : true
          });
        }
      });
  }

  filterFailureMode($event){
     this.loading = true;
     let searchData = {
       filterText: $event.target?.value,
       resolvingUnitId: this.resolvingUnitId
     };
     if($event.target.value) {
       this.failureModeService.getFilteredProduct(searchData).subscribe((files) => {
         if(files){
           if(this.selectedBuCode == 'PGHV') {
             this.files1 = files;
             this.files1 = this.files1.filter(item => item.name.includes('PGHV Failure'));
           }
           else {
             this.files1 = files;
           }
           this.files1.map((res) => {
             res.label = res.name,
             res.data = res.flatName,
             res.key = res.name,
             res.expanded = res.shouldBeExpanded,
             res.children = this.recursiveForSearch(res.subClasses),
             res.id = res.id,
             res.leaf = res.hasSubClasses == true ? false : true,
             res.parentName = res.name;
           });
           this.loading = false;
         }
       }, err => this.loading = false)
     }
     else {
       this.getFailureMode();
     }
   }

  recursiveForSearch(childrenRecords) {
    childrenRecords.map((childRes) => {
      childRes.label = childRes.name,
      childRes.data = childRes.flatName,
      childRes.children = childRes.subClasses,
      childRes.id = childRes.id,
      childRes.expanded = childRes.shouldBeExpanded,
      childRes.leaf = childRes.hasSubClasses == true ? false : true,
      childRes.parentName = childRes.name
    })
    childrenRecords.map((hasChildren) => {
      if(hasChildren.hasSubClasses) {
        this.recursiveForSearch(hasChildren?.subClasses)
      }
    })
    return childrenRecords;
  }

  nodeSelect(event: any) {
    const nodeselection = event.node;
    if(nodeselection.hasSubClasses) {
      this.loading = true;
      this.failureModeService.getfailureModebyId(nodeselection.id, this.resolvingUnitId).then((res) => {
        if(res){
          this.files1 = this.newH(res, this.files1, nodeselection.id);
          this.loading = false;
        }
      })
    }
  }

  newH(newData: any, h ,id){
      h.forEach((node, index) => {
          if(node.id == id){
           newData.map((res) => {
                    res.label = res.name,
                    res.data = res.flatName,
                    res.key = res.name,
                    // res.expandedIcon = 'pi pi-folder-open',
                    // res.collapsedIcon = 'pi pi-folder',
                    res.children = res.hasSubClasses,
                    res.id = res.id,
                    res.leaf = res.hasSubClasses == true ? false : true
                  });
            node.children = [...newData]
          } else if(node.children.length) {
             this.newH(newData, node.children,id)
          }
      })

      return h
    }


  // Data are disabled
  enableAll() {
    this.files1.forEach((node: any) => {
      this.enableRecursive(node, false);
    })
  }

  private enableRecursive(node: any, isSelectable: boolean) {
    node.selectable = node.disabled;
    if (node.children) {
      node.children.forEach((childNode: any) => {
        this.enableRecursive(childNode, childNode.disabled);
      });
    }
  }

  selectAndClose() {
    if (this.selectedNode !== null && this.selectedNode !== undefined) {
      let selectedValue: any = this.selectedNode;
      selectedValue.description = selectedValue?.requiresDescription ? this.contactDetailsForm?.controls['others']?.value : null;
      var tooltip = '';
      let level = 0;
      if(this.selectedNode?.parent?.parent?.parent?.parent?.parent?.parent?.parent){
        level = 7;
      }
      else if(this.selectedNode?.parent?.parent?.parent?.parent?.parent?.parent){
        level = 6;
      }
      else if(this.selectedNode?.parent?.parent?.parent?.parent?.parent){
        level = 5;
      }
      else if(this.selectedNode?.parent?.parent?.parent?.parent){
        level = 4;
      }
      else if(this.selectedNode?.parent?.parent?.parent){
        level = 3;
      }
      else if(this.selectedNode?.parent?.parent){
        level = 2;
      }
      else if(this.selectedNode?.parent){
        level = 1;
      }

      tooltip = '<span class="spacer"></span>'.repeat(level) + '<b>' + this.xssProtection(selectedValue?.displayLabel) +
      (selectedValue?.requiresDescription === true ? "</b>: " + this.xssProtection(selectedValue?.description) : "</b>") + "<br/>";
      var currentNode:any = this.selectedNode?.parent;
      var currenttreeNode = this.selectedNode?.parent;
      level--;
      while (currenttreeNode !== undefined && currenttreeNode !== null) {
        tooltip = '<span class="spacer"></span>'.repeat(level) + this.xssProtection(currentNode?.displayLabel) + '<br/>' + tooltip;
        currentNode = currenttreeNode?.parent;
        currenttreeNode = currenttreeNode?.parent;
        level--;
      }
      selectedValue.tooltipInfo = tooltip;
      this.dialogRef.close(selectedValue);
    }
  }
  xssProtection(value) {
    var lt = /</g,
        gt = />/g,
        ap = /'/g,
        ic = /"/g;
    return value?.replace(lt, "&lt;").replace(gt, "&gt;").replace(ap, "&#39;").replace(ic, '&#34;');
}
  onClose() {
    this.dialogRef.close();
  }

  disableSelectClose(){
    if(this.selectedNode){
      if(this.selectedNode?.requiresDescription){
        if(this.contactDetailsForm?.controls['others']?.value?.length > 1)
          return true;
        else
          return false;
      }
      else{
        return true;
      }
    }
    else{
      return false;
    }
  }

}
