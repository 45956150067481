<div class="row search_criteira mt-2">
  <form [formGroup]="poSearchForm">
    <div class="row mt-2">
      <div class="col-sm-2">
        <label for="region" class="label-align-right label-bold"> {{'rusearch_mod_div_lbl'|translate}} </label>
      </div>
      <div class="col-sm-10">
        <p-multiSelect [options]="divisions" [showToggleAll]="false" class="countryDropdown" [resetFilterOnHide]="true" [filter]="true"
          optionValue="id" formControlName="business" optionLabel="id" display="chip" filterBy="name"
          dropdownIcon="none" placeholder="Click or type to select Business" (onChange)="onChangeBusiness($event)">
        </p-multiSelect>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-sm-2">
        <label for="region" class="label-align-right label-bold"> {{'rusearch_mod_bu_lbl'|translate}} </label>
      </div>
      <div class="col-sm-10 multiselect-field_remove">
        <p-multiSelect [options]="bulist" [showToggleAll]="false" class="countryDropdown" [resetFilterOnHide]="true" [filter]="true"
          optionValue="id" formControlName="bu" optionLabel="label" display="chip" filterBy="name"
          dropdownIcon="none" placeholder="Click or type to select BU" (onChange)="onChangeBu($event)">
        </p-multiSelect>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-sm-2">
        <label for="region" class="label-align-right label-bold"> {{'rusearch_mod_pg_lbl'|translate}}</label>
      </div>
      <div class="col-sm-10">
        <p-multiSelect [options]="pglist" [showToggleAll]="false" class="countryDropdown" [resetFilterOnHide]="true" [filter]="true"
          optionValue="id" formControlName="pg" optionLabel="label" display="chip" filterBy="name"
          dropdownIcon="none" placeholder="Click or type to select PG">
        </p-multiSelect>
      </div>
    </div>
    <!-- <hr /> -->

    <div class="row mt-2">
      <div class="col-sm-2">
        <label for="region" class="label-align-right label-bold"> {{'rusearch_mod_reg_lbl'|translate}}</label>
      </div>
      <div class="col-sm-10">
        <p-multiSelect [options]="regions" [showToggleAll]="false" class="countryDropdown" [resetFilterOnHide]="true" [filter]="true"
          optionValue="id" formControlName="region" optionLabel="lableName" display="chip" filterBy="name"
          dropdownIcon="none" placeholder="Click or type to select Region" (onChange)="onRegionChange($event)">
        </p-multiSelect>
      </div>
    </div>

    <div class="row mt-2">
      <div class="col-sm-2">
        <label for="region" class="label-align-right label-bold"> {{'rusearch_mod_country_lbl'|translate}}</label>
      </div>
      <div class="col-sm-10">
        <p-multiSelect [options]="countries" [showToggleAll]="false" class="countryDropdown" [resetFilterOnHide]="true" [filter]="true"
         dropdownIcon="none" optionValue="id" formControlName="country" optionLabel="label" display="chip" filterBy="name"
          placeholder="Click or type to select Country">
        </p-multiSelect>
      </div>
    </div>

    <!-- <hr /> -->

    <div class="row mt-2">
      <div class="col-sm-2">
        <label for="employee" class="label-align-right label-bold"> {{'rusearch_mod_employees_lbl'|translate}} </label>
      </div>
      <div class="col-sm-10 emplyee-multiselect">
        <p-autoComplete [forceSelection]="true" formControlName="employee" class="substituteCustom border-0"
          tooltipPosition="top" tooltipStyleClass="toolTipClass" [suggestions]="filteredSubstitude"
          (completeMethod)="filterSubstitude($event?.query)" field="name" [multiple]="true"
          placeholder="{{'system_user_mod_add_employee_ph'|translate}}">
          <ng-template let-filterSubstitude pTemplate="item">
            <div class="flex align-items-center gap-2" style="font-size: 12px;">
                  <div class="row">
                    <div class="col-8"><strong>{{ filterSubstitude?.name }}</strong></div>
                    <div class="col-4 text-end"><i>{{filterSubstitude?.city}}({{filterSubstitude.iso}})</i></div>
                  </div>
                  <div class="row">
                    <div class="col">{{filterSubstitude?.email}}</div>
                  </div>
            </div>
        </ng-template>
        </p-autoComplete>
      </div>
    </div>

    <!-- <hr /> -->
    <div class="row my-3">
      <div class="col-sm-4"></div>
      <div class="col-sm-8" style="text-align: right;">
        <button type="button" class="widgetButtoSmall"
          (click)="reset()">{{'admin_po_search_reset_btn'|translate}}</button>
        <button type="button" class="widgetButtoSmall ms-1"
          (click)="cancel() ">{{'rusearch_mod_cancel_btn'|translate}}</button>
        <button type="button" [disabled]="!(this.poSearchForm.get('bu').value != null && this.poSearchForm.get('bu').value != '') &&
                                          !(this.poSearchForm.get('business').value != null && this.poSearchForm.get('business').value != '') &&
                                          !(this.poSearchForm.get('pg').value != null && this.poSearchForm.get('pg').value != '') &&
                                          !(this.poSearchForm.get('region').value != null && this.poSearchForm.get('region').value != '') &&
                                          !(this.poSearchForm.get('country').value != null && this.poSearchForm.get('country').value != '') &&
                                          !(this.poSearchForm.get('employee').value != null && this.poSearchForm.get('employee').value != '')" class="widgetButtoSmall ms-1"
          (click)="ok()">{{'admin_ru_search_search_btn'|translate}}</button>

      </div>
    </div>
  </form>
</div>
