import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { TransalationAuthService } from '../transalation-auth.service';
import { LocalStorageServiceService } from 'src/app/services/local-storage-service.service';
import { filter } from 'lodash';
import * as _ from 'lodash';
import { NgxSpinnerService } from 'ngx-spinner';
import { TransalationserviceService } from '../transalationservice.service';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { PoSettingsComponent } from '../../processOwners/po-settings/po-settings.component';
import { ToasterserviceService } from 'src/app/services/toasterservice.service';
import { TranslationSettingsComponent } from '../translation-settings/translation-settings.component';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';

@Component({
  selector: 'app-translations',
  templateUrl: './translations.component.html',
  styleUrls: ['./translations.component.css']
})
export class TranslationsComponent implements OnInit {

  isControlDisabled = false;
  defaultSelectedFilter = true === this.translationAuthService.canManageTranslations() ? 'all' : 'new';
  tabs = {
    res: {
      isSelected: false,
      isLoaded: false,
      lang: '',
      filter: this.defaultSelectedFilter
    },
    msg: {
      isSelected: false,
      isLoaded: false,
      lang: '',
      filter: this.defaultSelectedFilter
    }
  };
  orderBy = {
    direction: 'asc',
    selectedOption: this.defaultSelectedFilter,
    options: []
  };
  culture = {
    selected: 'en',
    all: []
  };
  source = {
    resources: {
      sorted: [],
      all: []
    },
    messages: {
      sorted: [],
      all: []
    }
  };
  search = {
    searchKeyword: undefined,
    lookup: []
  };

  firstPage = 1;
  currentPage = 1;
  pagesCount = 1;
  itemsPerPage = this.ccLocalStorageService.getFromLocalStorage("translationsPageSize") || 15;
  allowManage = this.translationAuthService.canManageTranslations();

  constructor(private translationAuthService: TransalationAuthService,
    private ccLocalStorageService: LocalStorageServiceService,
    private spinner: NgxSpinnerService,
    private transalationService: TransalationserviceService,
    private dialogService: DialogService,
    private ref: DynamicDialogRef,
    private toaster:ToasterserviceService,
    private breadcrumbService:BreadcrumbService) { }

  ngOnInit(): void {
    this.breadcrumbService.menuItems= [
      { label: 'ADMIN PANEL', routerLink: 'admin'},
      { label: 'MANAGE TRANSLATIONS'}
    ]
    this.setupCultureCombobox();
    this.setActiveTab('res');
  }

  refreshItemsToPresent(table) {
    if (true === this.tabs.res.isSelected) {
      var resSource = this.source.resources.all;
      if (undefined !== table && null != table) {
        resSource = table;
      }
      if (this.search.searchKeyword !== '' && this.search.searchKeyword !== undefined) {
         resSource = resSource.filter(res => {
          const resString = JSON.stringify(res).toLowerCase();
          return resString.includes(this.search.searchKeyword.toLocaleLowerCase());
        });
      }
      if (null != resSource) {
        this.source.resources.sorted = resSource.slice(this.currentPage == 1 ?
          0 : (this.currentPage - 1) * this.itemsPerPage, (this.currentPage == 1 ? this.itemsPerPage : (this.currentPage - 1) * this.itemsPerPage + parseInt(this.itemsPerPage)));
      }
    }
    if (true === this.tabs.msg.isSelected) {
      var msgSource = this.source.messages.all;
      if (undefined !== table && null != table) {
        msgSource = table;
      }
      if (this.search.searchKeyword !== '' && this.search.searchKeyword !== undefined) {
          msgSource = msgSource.filter(msg => {
            const msgString = JSON.stringify(msg).toLowerCase();
            return msgString.includes(this.search.searchKeyword.toLocaleLowerCase());
          });
      }
      if (null != msgSource) {
        this.source.messages.sorted = msgSource.slice(this.currentPage == 1 ?
          0 : (this.currentPage - 1) * this.itemsPerPage, (this.currentPage == 1 ? this.itemsPerPage : (this.currentPage - 1) * this.itemsPerPage + parseInt(this.itemsPerPage)));
      }
    }
  };
  resetNavigation(table) {
    this.firstPage = 1;
    this.currentPage = 1;

    if (true === this.tabs.res.isSelected) {
      var resSource = this.source.resources.all;
      if (undefined !== table && null != table) {
        resSource = table;
      }
      if (this.search.searchKeyword !== '' && this.search.searchKeyword !== undefined) {
        resSource = resSource.filter(res => {
            const resString = JSON.stringify(res).toLowerCase();
            return resString.includes(this.search.searchKeyword.toLocaleLowerCase());
          });
      }

      this.pagesCount = (resSource != null && resSource.length > 0)
        ? Math.ceil(resSource.length / this.itemsPerPage) : 1;
    }
    if (true === this.tabs.msg.isSelected) {
      var msgSource = this.source.messages.all;
      if (undefined !== table && null != table) {
        msgSource = table;
      }
      if (this.search.searchKeyword !== '' && this.search.searchKeyword !== undefined) {
          msgSource = msgSource.filter(msg => {
            const msgString = JSON.stringify(msg).toLowerCase();
            return msgString.includes(this.search.searchKeyword.toLocaleLowerCase());
          });
      }

      this.pagesCount = (msgSource != null && msgSource.length > 0)
        ? Math.ceil(msgSource.length / this.itemsPerPage) : 1;
    }
  };

  setActiveTab(tabName) {
    if (tabName === 'res') {
      this.tabs.res.isSelected = true;
      this.tabs.msg.isSelected = false;
      this.setUpOrderByContent('res');
      this.setUpIntellisenseContent('res');

      if (false === this.tabs.res.isLoaded && this.tabs.res.lang !== this.culture.selected) {
        this.orderBy.selectedOption = this.tabs.res.filter;
        this.reloadResourcesTab();
        this.tabs.res.lang = this.culture.selected;
      } else if (true === this.tabs.res.isLoaded) {
        this.orderBy.selectedOption = this.tabs.res.filter;
        this.setFilters('res');
      }
    } else {
      this.tabs.msg.isSelected = true;
      this.tabs.res.isSelected = false;
      this.setUpOrderByContent('msg');
      this.setUpIntellisenseContent('msg');

      if (false === this.tabs.msg.isLoaded && this.tabs.msg.lang !== this.culture.selected) {
        this.orderBy.selectedOption = this.tabs.msg.filter;
        this.reloadMessageTemplatesTab();
        this.tabs.msg.lang = this.culture.selected;
      } else if (true === this.tabs.msg.isLoaded) {
        this.orderBy.selectedOption = this.tabs.msg.filter;
        this.setFilters('msg');
      }
    }
  };

  setOrderMode(orderMode) {
    this.orderBy.direction = orderMode;
    this.setFilters();
  };

  setFilters(name?) {
    if (true === this.tabs.res.isSelected || (undefined !== name && '' !== name && name === 'res')) {
      this.filtersSetUp();

      this.resetNavigation(this.source.resources.sorted);
      this.refreshItemsToPresent(this.source.resources.sorted);
    } else if (true === this.tabs.msg.isSelected || (undefined !== name && '' !== name && name === 'msg')) {
      this.filtersSetUp();

      this.resetNavigation(this.source.messages.sorted);
      this.refreshItemsToPresent(this.source.messages.sorted);
    }
  };

  refreshResourceItem(resourceModel) {
    this.source.resources.all.forEach((res) => {
      if (res.id === resourceModel.id) {
        res.translation = resourceModel.translation;
        return;
      }
    });
  };

  refreshMessageItem(messageModel) {
    this.source.messages.all.forEach((msg) => {
      if (msg.id === messageModel.id) {
        msg.body = messageModel.body;
        msg.subject = messageModel.subject;
        msg.defaultCC = messageModel.defaultCC;
        msg.defaultRecipients = messageModel.defaultRecipients;
        return;
      }
    });
  }


  filtersSetUp() {
    if (true === this.tabs.res.isSelected) {
      this.source.resources.sorted = _.orderBy(this.source.resources.all, 'name', (this.orderBy.direction == 'desc') ? 'desc' : 'asc');

      if (this.orderBy.selectedOption === 'new') {
        this.tabs.res.filter = 'new';
        this.source.resources.sorted = filter(this.source.resources.sorted,{ isNew: true });

      } else if (this.orderBy.selectedOption === 'empty') {
        this.tabs.res.filter = 'empty';
        this.source.resources.sorted = _.filter(this.source.resources.sorted, function (item) {
          return item.translation === '' || undefined === item.translation || null === item.translation;
        });
      } else {
        this.tabs.res.filter = 'all';
        this.orderBy.selectedOption = 'all';
      }
    }
    if (true === this.tabs.msg.isSelected) {
      this.source.messages.sorted = _.orderBy(this.source.messages.all, 'engTranslation.name', (this.orderBy.direction == 'desc') ? 'desc' : 'asc');

      if (this.orderBy.selectedOption === 'new') {
        this.tabs.msg.filter = 'new';
        this.source.messages.sorted = filter(this.source.messages.sorted,{ isNew: true });

      } else {
        this.tabs.msg.filter = 'all';
        this.orderBy.selectedOption = 'all';
      }
    }
  }

  setUpOrderByContent(tabName) {
    this.orderBy.options = [
      { value: 'all', desc: 'All' }, { value: 'new', desc: 'Only New' }
    ];
    if (tabName === 'res') {
      this.orderBy.options.push({ value: 'empty', desc: 'Only Empty' });
    }
  }

  setUpIntellisenseContent(tabName) {
    this.search.lookup = this.transalationService.getAutoCompleteTable(tabName);
  }

  setupCultureCombobox() {
    this.transalationService.getAllCultures()
    .subscribe((data) => {
        this.culture.all = data.cultures;
        this.culture.selected = data.currentCulture;
    }, (error) => {

    });
  }

  disableControls(disable) {
    this.isControlDisabled = disable;
    if (disable === true) {
      this.spinner.show();
    } else {
      this.spinner.hide();
    }
  }

  hasDataNewRecords(table) {
    let newRecords = _.filter(table, function (record) {
      return true === record.isNew;
    });

    return newRecords.length > 0;
  }

  reloadResourcesTab() {
    this.disableControls(true);
    this.transalationService.getResources(this.culture.selected)
      .subscribe((data) => {
        this.source.resources.all = data;
        if (data.length === 0 || false === this.hasDataNewRecords(data)) {
          this.tabs.res.filter = 'all';
          this.orderBy.selectedOption = 'all';
        }
        this.tabs.res.isLoaded = true;
        this.setOrderMode('asc');
        this.disableControls(false);
      }, (error) => {
        this.disableControls(false);
      });

  }

  reloadMessageTemplatesTab() {
    this.disableControls(true);

    this.transalationService.getMessageTemplates(this.culture.selected)
      .subscribe((data) => {
        this.source.messages.all = data;
        if (data.length === 0 || false === this.hasDataNewRecords(data)) {
          this.tabs.msg.filter = 'all';
          this.orderBy.selectedOption = 'all';
        }
        this.tabs.msg.isLoaded = true;
        this.setOrderMode('asc');
        this.disableControls(false);
      }, (error) => {
        this.disableControls(false);
      });
  };

  translationsSettings(){
     this.ref = this.dialogService.open(TranslationSettingsComponent , {
        header: 'Translation settings',
        width: '30%',
        position: 'top',
        keepInViewport: true,
        autoZIndex: true,
        styleClass: 'translationSettings-popup'
      });

    this.ref.onClose.subscribe((receivedValue: any) => {
      if (receivedValue) {
        this.disableControls(true);
        this.toaster.showSuccess('Settings saved');
        this.itemsPerPage = this.ccLocalStorageService.getFromLocalStorage("translationsPageSize") || 15;
        this.setFilters();
        this.disableControls(false);
      }
      })
  }


  //Pagination


  previousPage() {
    if (this.hasPrevious()) {
      return this.currentPage - 1;
    }
    return 0;
  };

  hasPrevious() {
    return this.currentPage > 1;
  };
  nextPage() {
    if (this.hasNext()) {
      return this.currentPage + 1;
    }
    return 0;
  };

  hasNext() {
    return this.currentPage < this.pagesCount;
  };
  showFirstPage() {
    return this.hasPrevious() && this.previousPage() > 1;
  };
  showFirstPageSeparator() {
    return this.currentPage > 3;
  };
  showLastPage() {
    return this.hasNext() && this.nextPage() < this.pagesCount;
  };
  showLastPageSeparator() {
    var result = this.hasNext() && this.nextPage() < this.pagesCount - 1;
    return result;
  };


  nextClicked() {
    if (this.hasNext()) {
      this.currentPage++;

      this.filtersSetUp();
      if (true === this.tabs.res.isSelected) {
        this.refreshItemsToPresent(this.source.resources.sorted);
      } else {
        this.refreshItemsToPresent(this.source.messages.sorted);
      }
    }
  };
  previousClicked() {
    if (this.hasPrevious()) {
      this.currentPage--;

      this.filtersSetUp();
      if (true === this.tabs.res.isSelected) {
        this.refreshItemsToPresent(this.source.resources.sorted);
      } else {
        this.refreshItemsToPresent(this.source.messages.sorted);
      }
    }
  };
  gotoPage(page) {
    this.currentPage = page;

    this.filtersSetUp();
    if (true === this.tabs.res.isSelected) {
      this.refreshItemsToPresent(this.source.resources.sorted);
    } else {
      this.refreshItemsToPresent(this.source.messages.sorted);
    }
  };

  searchText(inputSearchKeyWord:any){
   this.search.searchKeyword = inputSearchKeyWord;
   this.search = Object.assign({}, this.search);
   this.setFilters();
  }

  cultureChanged(newValue:any){
    if (newValue !== this.tabs.res.lang && true === this.tabs.res.isLoaded) {
      this.tabs.res.isLoaded = false;
      if (true === this.tabs.res.isSelected) {
          this.reloadResourcesTab();
          this.tabs.res.lang = this.culture.selected;
      }
  }
  if (newValue !== this.tabs.msg.lang && true === this.tabs.msg.isLoaded) {
      this.tabs.msg.isLoaded = false;
      if (true === this.tabs.msg.isSelected) {
          this.reloadMessageTemplatesTab();
          this.tabs.msg.lang = this.culture.selected;
      }
  }
  }

}
