import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { AdminHubsService } from 'src/app/services/admin-hubs.service';
import { ToasterserviceService } from 'src/app/services/toasterservice.service';
import { HubAuthService } from '../hub-auth.service';

@Component({
  selector: 'app-hub-delete-person-popup',
  templateUrl: './hub-delete-person-popup.component.html',
  styleUrls: ['./hub-delete-person-popup.component.css']
})
export class HubDeletePersonPopupComponent implements OnInit {

  deletePersonForm!: FormGroup;
  userinfo: any;
  objectdata: { hubId: number; geid: string; };
  canDelete: boolean = false;
  getrubsObjinfo: any;
  hubsForProcessOwner:any;
  filteredHubDetails:any;
  iscountprocessorowner:any;

  constructor(
    private fb: FormBuilder,
    private readonly dialogRef: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private adminHubsService: AdminHubsService,
    public toasterService: ToasterserviceService,
    private hubAuthService: HubAuthService
  ) {

  }

  ngOnInit(): void {
    this.deletePersonForm = this.fb.group({
    })
    this.userinfo = this.config.data;
    this.canDelete = this.userinfo.cardinfo.hubProcessOwnerGeids.length > 1;
    this.filteredHubDetails = this.config.data.hubsData;
  }

    // code for tooltip
    gethubDataProcess() {
      this.getrubsObjinfo = this.userinfo.empinfo;
      //this.iscountprocessorowner = empInfo.hubProcessOwnerIds.length;
      this.hubsForProcessOwner = [];
      this.userinfo.empinfo.hubProcessOwnerIds.forEach(hubId => {
        this.filteredHubDetails.forEach(element => {
          if (element.id == hubId) {
            this.hubsForProcessOwner.push(element.hubCode)
          }
        });
      });

      if (this.hubsForProcessOwner.length > 0) {
        this.iscountprocessorowner = this.hubsForProcessOwner.length;
      }
    }

  delete() {
    this.objectdata = {
      "hubId": this.userinfo.cardinfo.id,
      "geid": this.userinfo.empinfo.id
    }

    let canManage = this.hubAuthService.canManageHub(this.userinfo.cardinfo.scope, this.userinfo.cardinfo.hubProcessOwnerGeids);
    if(canManage){
      this.adminHubsService.deleteHubPerson(this.objectdata).subscribe((res) => {
        const deletepersonInfo = res
        this.dialogRef.close(this.objectdata);
        this.toasterService.showSuccess('Hub person has been successfully deleted');
      })
    }
    else{
      this.toasterService.showError('Cannot delete this Hub Person. Insufficient permissions.');
    }
    
  }

  onClose() {
    this.dialogRef.close();
  }
}
