<div class="current-confirmation-popup mainDiv">
  <div class="content" *ngIf="classData==1">
      <span innerHTML="{{'ComplaintForm_ConfirmationSubmitComplaintMessage_ClassA' | translate}}"></span>
   </div>
  <div class="content" *ngIf="classData==2">
    <span innerHTML="{{'ComplaintForm_ConfirmationSubmitComplaintMessage_ClassB' | translate}}"></span>
  </div>
  <div class="content" *ngIf="classData==3">
    <span innerHTML="{{'ComplaintForm_ConfirmationSubmitComplaintMessage_ClassI' | translate}}"></span>
  </div>
 <div class="modal-footer my-1">
   <button class="widgetButtoSmall" (click)="confirm()">{{ "HighImpact_Btn_Confirm" | translate }}</button>
      <button class="widgetButtoSmall ms-1" (click)="onClose()">{{ "ButtonCancel" | translate }}</button>
  </div>
</div>
