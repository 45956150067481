import { Component, OnInit } from '@angular/core';
import { ExternalpageEmitService } from '../externalpage-emit.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-hi-confirm',
  templateUrl: './hi-confirm.component.html',
  styleUrls: ['./hi-confirm.component.css']
})
export class HiConfirmComponent implements OnInit {
  encodedURI: any;
  inputURI: any;

  constructor(private externalPageService: ExternalpageEmitService,
    private route: ActivatedRoute,
    public router: Router,
    private spinnerService: NgxSpinnerService) { }

  ngOnInit(): void {
    this.spinnerService.show();
    this.externalPageService.setExternalPageFunction(true);
    this.inputURI = this.route.snapshot.paramMap.get('id');
    const decodedURI = atob(this.inputURI);
    this.encodedURI = btoa(decodedURI);

    this.externalPageService.hiConfirmation(this.encodedURI).subscribe(
      (data)=>{
        this.spinnerService.hide();
        window.location.href = data?.url;
      }
    )
  }

}
