import { Component, Input, OnInit } from '@angular/core';

export interface userProfileModal {
  geid: string
  displayGeid: string
  email: string
  userCountry: UserCountry
  accessPermissions: string[]
  processOwnerScopes: any[]
  resolvingUnitPersonScope: any[]
  accountManagerScope: any[]
  hasGlobalPermissions: boolean
  isSupportDeskMember: boolean
  isResourceEditor: boolean
  isGlobalCcrpProcOwner: boolean
  isRuProcessOwner: boolean
  hubProcessOwnerScope: any[]
  isManagementRole: boolean
  employeeDetails: EmployeeDetails
}

export interface UserCountry {
  name: string
  code: string
  region: any
  selected: boolean
  dataValue: string
  proMISDataValue: string
  dataText: string
}

export interface EmployeeDetails {
  id: string
  displayGeid: string
  email: string
  nameLn: any
  firstName: string
  lastName: string
  deactivateDate: any
  name: string
  nameLatin: string
  phone: any
  address: Address
  isActive: boolean
}

export interface Address {
  city: string
  cityUniqueKey: string
  cityLatin: string
  countryCode: string
  addressId: any
  address: string
  zipCode: string
}


@Component({
  selector: 'app-user-info-details',
  templateUrl: './user-info-details.component.html',
  styleUrls: ['./user-info-details.component.css']
})
export class UserInfoDetailsComponent implements OnInit {

  @Input('userData') userData!:userProfileModal;
  constructor() {
  }

  ngOnInit(): void {

  }

}
