<div class="issue-closure-pop">
  <div class="container issue-closure-dialog mb-2">
    <form [formGroup]="issueClosureConfirmForm">
      <div class="viewPositionContent mt-2">
        <p [innerHTML]="'ComplaintForm_ConfirmationCloseComplaintMessage' | translate"></p>
      </div>
    </form>
  </div>
  <div class="modal-footer mt-2">
    <button class="widgetButtonMedium" type="button" (click)="issueClose() ">{{ "ComplaintState_Completed" |
      translate }}</button>
    <button class="widgetButtonMedium ms-1" type="button" (click)="onClose() ">{{ "al_modme_cancel_btn" | translate
      }}</button>
  </div>
</div>
