import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs'
import { HubAuthService } from '../components/admin/admin-hubs/hub-auth.service';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class AdminHubsService {

  private sharedObj: any

  constructor(private http: HttpClient, private hubAuthService: HubAuthService) { }


  setObject(obj: any) {
    this.sharedObj = obj;
  }
  getObject(): any {
    return this.sharedObj;
  }

  getBuHubUsage(): Observable<any> {
    return this.http.get(`${environment.azure.baseUrl}api/v1/managehub/buhubusage`)
  }

  getChangeHubUsage(data): Observable<any> {
    return this.http.post(`${environment.azure.baseUrl}api/v1/managehub/changehubusage`, data)
  }

  getDivisionsAllList(): Observable<any> {
    return this.http.get(`${environment.azure.baseUrl}api/v1/divisions/all`)
  }

  getHubPOUser(passedData): Observable<any> {
    return this.http.get(environment.azure.baseUrl + 'api/v1/employee/findpagebywildcard?wildcard=' + passedData.wildcard + '&onlyActive=' + passedData.onlyActive + '&page=' + passedData.page + '&size=' + passedData.size);
  }

  getSearchHubDetails(data) {
    return this.http.post(`${environment.azure.baseUrl}api/v1/managehub/searchchecklimit`, data);
  }

  getSearchHubDetails1(data):Promise<any>{
    return new Promise<any>((resolve,reject)=>{
      this.http.post(`${environment.azure.baseUrl}api/v1/managehub/searchchecklimit`, data).subscribe((response: any)=>{
        let geids = [];
        response.hubInfo.forEach(item => {
          item.scope = { div: item.divCode, bu: item.buCode, leadCountryName: item.leadCountryName }
          item.canManage = this.hubAuthService.canManageHub(item.scope, item.hubProcessOwnerGeids);
          item.isSelected = false;

          item.hubProcessOwnerGeids.forEach(geid => {
            geids.push(geid);
          });
        });

        this.getHubPersonInfo(_.uniq(geids)).subscribe((personsInfo: any)=>{
          response.hubInfo.forEach(item => {
            let hubProcessOwners = [];
            item.hubProcessOwnerGeids.forEach(itemGeid => {
              // let processOwner = _.find(personsInfo, (item)=>{
              //   return itemGeid == item.id;
              // })
              let processOwner = personsInfo.find(ele => ele.id == itemGeid)
              hubProcessOwners.push(processOwner);
            });
            
            item.hubProcessOwners = hubProcessOwners;
          });

          resolve(response);
        })
      },
      (error)=>{
        reject(error);
      });
    })

  }

  getExportHubs(data) {
    return this.http.post(`${environment.azure.baseUrl}api/v1/managehub/exporthubs`, data, {
      responseType: 'arraybuffer'
    });
  }

  getHubPersonInfo(stringData) {
    return this.http.post(`${environment.azure.baseUrl}api/v1/managehub/hubpersonsinfo`, stringData);
  }

  getFindProcessOwner(params) {
    return this.http.post(`${environment.azure.baseUrl}api/v1/managehub/findprocessowners`, params);
  }

  getEmployeeInfo(geids) {
    let params = new HttpParams();
    let ab = '';
    geids.forEach((element, index, array) => {
      if (index == 0) {
        ab += '' + element
      }
      else {
        ab += ',' + element
      }
    })
    params = params.append('geids', ab);
    return this.http.post(`${environment.azure.baseUrl}api/v1/dashboard/employeeinfo`, { geids: ab });
  }

  addHubPerson(geId){
    return this.http.post(`${environment.azure.baseUrl}api/v1/managehub/addhubpersons`, geId);
  }

  deleteHubPerson(hubId) {
    return this.http.post(`${environment.azure.baseUrl}api/v1/managehub/deletehubperson`, hubId);
  }

}
