import { Component, Input, OnInit } from '@angular/core';
import { AdminEscalationTimeServiceService } from '../admin-escalation-time-service.service';
import { ToasterserviceService } from 'src/app/services/toasterservice.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-admin-inprogress-escalationtimes',
  templateUrl: './admin-inprogress-escalationtimes.component.html',
  styleUrls: ['./admin-inprogress-escalationtimes.component.css']
})
export class AdminInprogressEscalationtimesComponent implements OnInit {

  oldItem = {};
  isInEditMode: boolean = false;
  allowEdit:boolean = false;
  @Input('item') item: any;
  @Input('status') status: string;
  @Input('maxHoursRange') maxHoursRange: number;
  hours!: any;

  constructor(private escalationService: AdminEscalationTimeServiceService,
              private toaster:ToasterserviceService,
              private spinner:NgxSpinnerService) { }

  ngOnInit(): void {
    this.hours = this.escalationService.calculateHours(this.maxHoursRange);
    this.allowEdit = this.escalationService.canManage(this.item.divisionCode);
  }

  cancel() {
    this.isInEditMode = false;
    this.item = this.oldItem;
    this.oldItem = undefined;
  };

  edit(escalationTimeItem) {
    this.oldItem = { ...escalationTimeItem };
    this.isInEditMode = true;
  };

  save(escalationTimeItem) {
    this.spinner.show();
    this.escalationService.saveEscalations(escalationTimeItem).subscribe(
     (resp)=>{
      if (resp.success) {
        this.toaster.showSuccess('Item has been saved successfully');
        this.isInEditMode = false;
      } else {
        this.toaster.showError(resp.failureMsg);
      }
      this.spinner.hide();

     },
     err=>{
       this.toaster.showError('error');
       this.spinner.hide();
     }
    )
  };
}
