<div class="mainDiv proMisSelection proMis-project-selection-pop">
  <div class="companyDetails">
    <form [formGroup]="proMISProjectForm">
      <div class="mt-2">
        <div class="row mb-1">
          <div class="divColoumnSpan tagColumn">{{ "ProjectSearch_ProjectNumber" | translate }}</div>
          <div class="col">
            <input type="text" class="form-control widgetTextBoxMedium" formControlName="project" />
          </div>
          <div class="divColoumnSpan tagColumn">{{ "ProjectSearch_Divisions" | translate }}</div>
          <div class="col">
            <select id="inputCountry" class="form-select widgetTextBoxMedium" formControlName="Business"
              name="countryName" (change)="changeBU($event)">
              <option value="">--Select one--</option>
              <option *ngFor="let business of proMISBusinessArray" value="{{business.key}}">
                {{ business.value }}
              </option>
            </select>
          </div>
        </div>

        <div class="row mb-1">
          <div class="divColoumnSpan tagColumn">{{ "ProjectSearch_ProjectName" | translate }}</div>
          <div class="col">
            <input type="text" class="form-control widgetTextBoxMedium" formControlName="projectName" />
          </div>

          <div class="divColoumnSpan tagColumn">{{ "ProjectSearch_BusinessUnits" | translate }}</div>
          <div class="col">
            <select id="inputCountry" class="form-select form-control widgetTextBoxMedium" formControlName="Bu"
              (change)="changePG($event)">
              <option value="" selected>--Select one--</option>
              <option *ngFor="let bu of buList" value="{{bu.key}}">{{ bu.name }}</option>
            </select>
          </div>
        </div>

        <div class="row mb-1">
          <div class="divColoumnSpan tagColumn">{{ "ProjectSearch_Country" | translate }}</div>
          <div class="col">
            <select id="inputCountry" class="form-select form-control widgetTextBoxMedium" formControlName="country">
              <option value="" selected>--Select one...</option>
              <option *ngFor="let country of getCountryList" value="{{ country.name }} ({{ country?.id }})"> {{
                country.name }} ({{ country?.id }}) </option>
              <!-- <option *ngFor="let a of countryDropdownData">
                {{ a.country }}
              </option> -->
            </select>
          </div>

          <div class="divColoumnSpan tagColumn">{{ "ProjectSearch_ProductGroups" | translate}} </div>
          <div class="col">
            <select id="inputCountry" class="form-select form-control widgetTextBoxMedium" formControlName="Pg"
              name="countryName">
              <option value="" selected>--Select one--</option>
              <option *ngFor="let pg of puList| keyvalue">{{pg?.value}}</option>
            </select>
          </div>
        </div>
        <div class="modal-footer my-2" style="padding-top:5px;">
          <div class="row2-container">
            <button type="button" (click)="viewProjectDetails()" [ngClass]="
              this.proMISProjectForm.controls['project'].value !== '' ||
              this.proMISProjectForm.controls['projectName'].value !== '' ||
              this.proMISProjectForm.controls['country'].value !== '' ||
              this.proMISProjectForm.controls['Business'].value !== '' ||
              this.proMISProjectForm.controls['Bu'].value !== '' ||
              this.proMISProjectForm.controls['Pg'].value !== ''
                ? 'input widgetButtoMediumView red_button'
                : 'input widgetButtoMediumView '
            ">
              {{ "rpt_cust_view_btn" | translate }}
            </button>
            <button class="input widgetButtoMediumReset ms-1" type="button" (click)="reset()">
              {{ "rpt_cust_reset_btn" | translate }}
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>

  <div class="tablediv">
    <div class="tableMargin">
      <!-- prime ng Paginator with table -->
      <div class="row2-container">
        <div class="card">
          <p-table [value]="filteredProjectDetails" selectionMode="single" [paginator]="true" [rows]="rows"
            [showCurrentPageReport]="true" [(first)]="first" [tableStyle]="{ 'min-width': '50rem' }"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [lazy]="true"
            [totalRecords]="totalRecords" [loading]="loading" (onLazyLoad)="loadMoreResolvingData($event)"
            styleClass="p-datatable-gridlines">
            <ng-template pTemplate="header" styleClass="table table-bordered table-striped table-hove">

              <tr>

                <th pSortableColumn="projectNumber" style="width: 25%">

                  Project Number<p-sortIcon field="projectNumber"></p-sortIcon>

                </th>

                <th pSortableColumn="projectName" style="width: 25%">

                  Project Name<p-sortIcon field="projectName"></p-sortIcon>

                </th>

                <th pSortableColumn="projectManagerName" style="width: 25%">

                  Project Manager<p-sortIcon field="projectManagerName"></p-sortIcon>

                </th>

                <th pSortableColumn="projectManagerEmail" style="width: 25%">

                  Project Manager<p-sortIcon field="projectManagerEmail"></p-sortIcon>

                </th>

                <th pSortableColumn="siteCountry" style="width: 25%">

                  Site Country<p-sortIcon field="siteCountry"></p-sortIcon>

                </th>

                <th pSortableColumn="address" style="width: 25%">

                  Address<p-sortIcon field="address"></p-sortIcon>

                </th>

              </tr>

            </ng-template>




            <ng-template pTemplate="body" let-filter let-rowIndex="rowIndex">

              <tr [ngClass]="{ selectedRowIndex: rowIndex == selectedIndex }" (click)="selectedRow(rowIndex,filter)">

                <td>{{ filter.projectNumber }}</td>

                <td>{{ filter.projectName }}</td>

                <td>{{ filter.projectManagerName }}</td>

                <td>{{ filter.projectManagerEmail }}</td>

                <td>{{ filter.siteCountry }}</td>

                <td>{{ filter.address }}</td>

              </tr>


            </ng-template>
            <ng-template pTemplate="paginatorright">
              <p-button type="button" icon="pi pi-refresh" (click)="refresh()" styleClass="p-button-text"></p-button>
            </ng-template>
          </p-table>
          <ng-template #noResult>
            <p>{{ "PagingEmpty" | translate }}</p>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer my-2">
    <button [disabled]=" selectedIndex === -1" class="widgetButtonLargeSelectClose" [ngClass]="
      selectedIndex !== -1
        ? ''
        : 'grayButton'
    " type="button" (click)="selectAndClose()">
      {{ "select_ru_popup_btn_select" | translate }}
    </button>
    <button class="input widgetButtonLarge ms-1" type="button" (click)="onClose()">
      {{ "select_ru_popup_btn_close" | translate }}
    </button>
  </div>
</div>
