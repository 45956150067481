import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormGroupDirective } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import moment from 'moment';
import { MessageService } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { FileUpload } from 'primeng/fileupload';
import { ReportsService } from 'src/app/services/reports.service';
import { DatePipe } from '@angular/common';
import { SaveSettingsPopupComponent } from '../save-settings-popup/save-settings-popup.component';
import { ReportColumnService } from 'src/app/services/report-column.service';
import { ToasterserviceService } from 'src/app/services/toasterservice.service';
import * as XLSX from 'xlsx';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { NavigationExtras, Router } from '@angular/router';
import { SessionStorageServiceService } from 'src/app/services/session-storage-service.service';
import { LocalStorageServiceService } from 'src/app/services/local-storage-service.service';
@Component({
  selector: 'app-all-in-one',
  templateUrl: './all-in-one.component.html',
  styleUrls: ['./all-in-one.component.css']
})
export class AllInOneComponent implements OnInit {
  reportsForm!: FormGroup;
  @Input() formGroupName!: string;
  @ViewChild("calendar", { static: false }) private calendar: any;
  pipe = new DatePipe('en-US');
  @ViewChild('fileUpload') fileUpload: FileUpload;
  currentDate = new Date(Date.now());
  invalidDates: Date;
  //onemonth = this.currentDate.setMonth(this.currentDate.getMonth() - 1)
  //onemonth = this.currentDate.setMonth(this.currentDate.getMonth() - 1)
  //onemonth = this.currentDate.setMonth(this.currentDate.getMonth() - 1)
  constVariables = {
    tab: {
      byFilter: 1,
      byCcrpNo: 2
    }
  };
  currentSelectedTab= this.constVariables.tab.byFilter;
  //dateranges//
  selected = {
    hasHighImpactClassA: null,
    hasHighImpactClassB: null,
    hasHighImpactClassI: null,
    classType: null,
    dateType: 'ApprovedDate',
    startDate: moment().add('week', -1).toDate(),
    endDate: moment().add('week', 0).toDate()
  };
  dateRange = {
    endMinDate: null,
    startMaxDate: null
  };

  resloverBuData: { name: string; }[];
  countries: any[];
  selectedCountries: any[];
  getresolverBuData: any;
  activitiesData: any = [];
  regionData: any = [];
  filteredCountriesData: any;
  filteredCitiesData: any;
  countriesData: any = [];
  resoverPgData: any;
  filteredResloverPgData: any = [];
  ownerManagerLv1Data: any = [];
  ownerManagerLv2Data: any = [];
  ownerManagerLv3Data: any = [];
  issueStatusData: any;
  allInOneData: any;
  filteredCustomersData: any;
  users: any;
  customersData: any = [];
  citiesdata: any = [];
  cities: Array<any>;
  customercities: any = [];
  resolvercities: any = [];
  ownercities: any = [];
  reportsCalendarText: any;
  now: any;
  descriptiveNameData: any = [];
  getresolverBusinessData: any = [];
  filteredCustomerCountries: any = [];
  filteredCustomerCities: any = [];
  filteredOwnerCountries: any = [];
  filteredOwnerCities: any = [];
  custData:any=[];
  ccrpData:any=[];
  highImpactVarA:boolean=null;
  highImpactVarB:boolean= null;
  highImpactVarI:boolean= null;
  highImpactVar:boolean= null;
  rcaRequiredVar:boolean= null;
  verificationRequiredVar:boolean= null;
  containmentActionsVar:boolean= null;
  unconfirmedVar:boolean= null;
  isEdit:boolean= false;
  isDefault:any;
  IsExcelGenerating:boolean = false;
  allFormData: any;
  // Second Tab start
  enterCCRPManually: boolean = !true;
  uploadCCRPFile: boolean = !true;
  reportColumnData: any;
  getCCRPNumber: any;
  getCCRPNumberCCC: any;
  filterCCRPNumber: any;
  AllInOneURL: any = window.location.href;
  filteredIssueTicketNumber: any;
  inverted: boolean = false;
  radioChange: any;
  buttonSelected: boolean = !true;
  btnColorChange = false;
  btnColorChange2 = false;
  selectedCustomer: any;
  reportSettings: any;
  checkboxValueCount: number=0;
  reportNo: any;
  external: string;
  inAppropriateExcelFile:boolean = false;
  excelColumnFormatWarning:boolean =false;
  maximumCCRP:boolean = false;
  removeText= 'Remove'
  fileUploadSelectBtn: boolean = false;
  optionModel: any = null;
  optionModelResolver: any = null;
  selectedFileCheck:any;
  userInfoGeid: any;
  // Second Tab end
  constructor(

    private ref: DynamicDialogRef,
    private dialogService: DialogService,
    private messageService: MessageService,
    private reportService: ReportsService,
    private fb: FormBuilder,
    private rootFormGroup: FormGroupDirective,
    private reportColumnService : ReportColumnService,
    private toaster:ToasterserviceService,
    private breadcrumbService: BreadcrumbService,
    private router: Router,
    private sessionStorage: SessionStorageServiceService,
    private localStorage: LocalStorageServiceService
  ) {  }
  ngOnInit(): void {
    this.breadcrumbService.menuItems= [
      { label: 'REPORTS & DASHBOARDS', routerLink: 'reports'},
      { label: 'ALL IN ONE (REFRESHED EVERY FOUR HOURS)'}
    ]

    this.userInfoGeid = this.sessionStorage.getData('userInfo').geid;

    this.reportsForm = this.rootFormGroup.control?.get(this.formGroupName) as FormGroup;
    this.reportsForm = this.fb.group({
      'customerName': [''],
      'country': [''],
      'city': [''],
      'reports': [''],
      'region': [''],
      'lv1': [''],
      'lv2': [''],
      'lv3': [''],
      'resolver': [''],
      'activities': [''],
      'descriptiveNames': [''],
      'columns': [''],
      'status': [''],
      'resolvepg': [''],
      'resolveBusiness': [''],
      'resolveBu': [''],
      'coregion': [''],
      'ownercity': [''],
      'ownercountry': [''],
      'customercity': [''],
      'customercountry': [''],
      'resolverCountry': [''],
      'resolverCity': [''],
      'customerpg': [''],
      'dateRange': ['None'],
      'rangeStartDate':[''],
      'rangeEndDate':[''],
      'dateType': ['CreationDate'],
      'FilterCCRPTicketNumber': [''],
      'reportsColumn': [''],
      'highImpactA': false,
      'highImpactB': false,
      'highImpactI': false,
      'highImpact': false,
      'rcaRequired': false,
      'verificatonRequired': false,
      'containtmentAction': false,
      'unconfirmed': false,
      'ccrpCheck': [],
      'npxxx': [],
      'npmxx': [],
      'sfdc': [],
      'topQI': []
    });

    this.getSettings();
    this.allInOneData= this.reportColumnService.getColumn();
    this.issueStatusData = this.reportColumnService.getIssueStatus();
    this.defaultPatchedValues();
    this.reportService.getAllBuPg().subscribe((response) => {
      if (response) {
        this.getresolverBusinessData = response?.divisions;
        this.getresolverBuData = response?.bus;
        this.resoverPgData = response?.pgs;
        this.filteredResloverPgData = response?.pgs;
        this.getresolverBusinessData?.map((businessData) => {
          businessData.name = '(' + businessData?.id + ') ' + businessData?.name;
        })
        this.getresolverBuData.map((buData) => {
          buData.name = '(' + buData?.id + ') ' + buData?.name;
        })
        this.resoverPgData.map((pgData) => {
          pgData.name = '(' + pgData?.id + ') ' + pgData?.name;
        })
      }
    })

    this.reportService.getAllMdRegions().subscribe((response) => {
      if (response) {
        this.regionData = response?.regions;
        this.countriesData = response?.countries;
        this.resolvercities = response?.cities;
        this.descriptiveNameData = response?.descriptiveNames;
        this.activitiesData = response?.activities;
        this.regionData.map((regionData) => {
          regionData.name = '(' + regionData?.id + ') ' + regionData?.name;
        })
        this.countriesData.map((countriesData) => {
          countriesData.name = '(' + countriesData?.id + ') ' + countriesData?.name;
        })
        this.resolvercities.map((citiesData) => {
          citiesData.name = citiesData?.name + ' Region: ' + citiesData?.rCode + ' Country: ' + citiesData?.cCode;
        })
        this.filteredCountriesData = response?.countries;
        this.filteredCitiesData = response?.cities;
        this.filteredCustomerCountries = response?.countries;
        this.filteredCustomerCities = response?.cities;
        this.filteredOwnerCountries = response?.countries;
        this.filteredOwnerCities = response?.cities;
      }
    })


    this.reportService.gethrgtAll().subscribe((res) => {
      this.ownerManagerLv1Data = res?.level1
    })

    let sampleData = {
      "levels1": [],
      "levels2": [],
      "wildcard": ""
    }

    this.level3Service(sampleData);
    this.level2Service(sampleData);

    this.reportsForm.controls['rangeStartDate'].valueChanges.subscribe((start) => {
      this.invalidDates = start
    })
  }

  defaultPatchedValues(){
    this.reportsForm.get('resolveBusiness').setValue(['PG']);
    this.reportsForm.get('columns').setValue(['All']);
    this.reportsForm.get('rangeStartDate').setValue(moment().startOf('day').add('month', -1).toDate());
    this.reportsForm.get('rangeEndDate').setValue(new Date());
    this.reportsForm.get('reportsColumn').setValue(['All']);
    this.reportsForm.get('dateType').setValue('CreationDate');
    this.reportsForm.get('dateRange').setValue('None');
  }
  getSettings(){

    this.reportService.getReportSetting().subscribe((res) => {
      this.reportNo=res[0]?.reportId;
      this.reportSettings = res[0]?.settings;

      this.reportSettings.forEach(c => {
        if (c?.isDefault==true) {
          this.isDefault=c?.dataField;
        }
      })

      this.show(this?.isDefault)

    })
  }

  level2Service(level2Data){
    this.reportService.getLevel2(level2Data).subscribe((res) => {
      let level2Data = res
      level2Data.map((level2Data) => {
        level2Data.name = '(' + level2Data?.id + ') ' + level2Data?.name;
      })
      this.ownerManagerLv2Data = level2Data
    })
  }

  level3Service(level3data){
    this.reportService.getLevel3(level3data).subscribe((res) => {
      let level3Data = res
      level3Data.map((level3Data) => {
        level3Data.name = '(' + level3Data?.id + ') ' + level3Data?.name;
      })
      this.ownerManagerLv3Data = level3Data
    })
  }

  level1Change(event) {
    let level2data = {
      "levels1": this.checkBlank(this.reportsForm.controls['lv1'].value),
      "wildcard": "",
    }
    this.level2Service(level2data);
  }

  level2Change(event) {
    let ids = this.reportsForm.controls['lv2'].value;
    let cutIds = [];
    for (var i = 0; i < ids?.length; i++) {
      cutIds.push(ids[i].substring(0, 4))
    }
    let level3data = {
      "levels1": this.checkBlank(this.reportsForm.controls['lv1'].value),
      "levels2": cutIds,
      "wildcard": "",
    }

  }
  //Function to get the values of PG after BU Change
  resolverBuChange(event) {
    this.reportsForm.controls['resolvepg'].reset();
    this.filteredResloverPgData = this?.resoverPgData
    let filteredData: any = [];
    let query = event?.value;

    if (query?.length != 0) {
      for (let i = 0; i < query.length; i++) {
        this.resoverPgData.filter((obj) => {
          if (obj?.buCode == query[i]) {
            filteredData.push(obj)
          }
        })
      }
      this.filteredResloverPgData = filteredData.sort((a,b) => { return a.id - b.id});
    }
    else {
      this.filteredResloverPgData = this?.resoverPgData;
    }
  }

  resolverRegionChange(event) {
    this.reportsForm.controls['resolverCountry'].reset();
    this.reportsForm.controls['resolverCity'].reset();
    let filteredCountries: any = [];
    let filteredCities: any = [];

    let query = event?.value;
    if(query?.length != 0){
      for (let i = 0; i < query?.length; i++) {
        this.countriesData.filter((obj) => {
          if (obj.rCode == query[i]) {
            filteredCountries.push(obj)
          }
        })

        this.resolvercities.filter((obj) => {
          if (obj.rCode == query[i]) {
            filteredCities.push(obj)
          }
        })
      }
      this.filteredCountriesData = filteredCountries;
      this.filteredCitiesData = filteredCities;
    }
    else{
      this.filteredCountriesData = this.countriesData;
      this.filteredCitiesData = this.resolvercities;
    }
  }

  resolverCountryChange(event) {
    this.reportsForm.controls['resolverCity'].reset();
    let filteredCities: any = [];
    let query = event?.value;
    if(query?.length != 0){
      for (let i = 0; i < query?.length; i++) {
        this.resolvercities.filter((obj) => {
          if (obj.cCode == query[i]) {
            filteredCities.push(obj)
          }
        })
      }
      this.filteredCitiesData = filteredCities;
    }
    else{
      this.filteredCitiesData = this.resolvercities;
    }

  }

  customerRegionChange(event) {
    this.reportsForm.controls['customercountry'].reset();
    this.reportsForm.controls['customercity'].reset();
    let filteredCountries: any = [];
    let filteredCities: any = [];
    let query = event?.value;

    if(query?.length != 0){
      for (let i = 0; i < query?.length; i++) {
        this.countriesData.filter((obj) => {
          if (obj.rCode == query[i]) {
            filteredCountries.push(obj)
          }
        })

        this.resolvercities.filter((obj) => {
          if (obj.rCode == query[i]) {
            filteredCities.push(obj)
          }
        })
      }
      this.filteredCustomerCountries = filteredCountries;
      this.filteredCustomerCities = filteredCities;
    }
    else{
      this.filteredCustomerCountries = this.countriesData;
      this.filteredCustomerCities = this.resolvercities;
    }

  }

  customerCountryChange(event) {
    this.reportsForm.controls['customercity'].reset();
    let filteredCities: any = [];
    let query = event?.value;
    if(query?.length != 0){
      for (let i = 0; i < query.length; i++) {
        this.resolvercities.filter((obj) => {
          if (obj.cCode == query[i]) {
            filteredCities.push(obj)
          }
        })
      }
      this.filteredCustomerCities = filteredCities;
    }
    else{
      this.filteredCustomerCities = this.resolvercities;
    }
  }

  ownerRegionChange(event) {
    this.reportsForm.controls['ownercountry'].reset();
    this.reportsForm.controls['ownercity'].reset();
    let filteredCountries: any = [];
    let filteredCities: any = [];
    let query = event?.value;

    if(query?.length != 0){
      for (let i = 0; i < query?.length; i++) {
        this.countriesData.filter((obj) => {
          if (obj.rCode == query[i]) {
            filteredCountries.push(obj)
          }
        })

        this.resolvercities.filter((obj) => {
          if (obj.rCode == query[i]) {
            filteredCities.push(obj)
          }
        })
      }
      this.filteredOwnerCountries = filteredCountries;
      this.filteredOwnerCities = filteredCities;
    }
    else{
      this.filteredOwnerCountries = this.countriesData;
      this.filteredOwnerCities = this.resolvercities;
    }

  }

  ownerCountryChange(event) {
    this.reportsForm.controls['ownercity'].reset();
    let filteredCities: any = [];
    let query = event?.value;

    if(query?.length != 0){
      for (let i = 0; i < query?.length; i++) {
        this.resolvercities.filter((obj) => {
          if (obj.cCode == query[i]) {
            filteredCities.push(obj)
          }
        })
      }
      this.filteredOwnerCities = filteredCities;
    }
    else{
      this.filteredOwnerCities = this.resolvercities;
    }
  }

  checkBlank(content) {
    if (content == undefined || content == null) {
      return '';
    } else {
      return content;
    }
  }

  highImpactA(event:any) {

    if(event?.target?.checked){
      this.highImpactVarA = event?.target?.checked
      this.rcaRequiredVar = event?.target?.checked
      this.verificationRequiredVar = event?.target?.checked
      this.reportsForm.patchValue({
        highImpactB: false,
        highImpactI: false,
        rcaRequired: event?.target?.checked,
        verificatonRequired: event?.target?.checked
      })
    }
    else{
      this.highImpactVarA = event?.target?.checked
      this.reportsForm.patchValue({
        rcaRequired: false,
        verificatonRequired: false
      })
    }

  }

  highImpactB(event:any) {
    if(event?.target?.checked){
      this.highImpactVarB = event?.target?.checked
      this.rcaRequiredVar = event?.target?.checked
      this.verificationRequiredVar = event?.target?.checked
      this.reportsForm.patchValue({
        highImpactA: false,
        highImpactI: false,
        rcaRequired: event?.target?.checked,
        verificatonRequired: event?.target?.checked
      })
    }
    else{
      this.highImpactVarB= event?.target?.checked
      this.reportsForm.patchValue({
        rcaRequired: false,
        verificatonRequired: false
      })
    }
  }

  highImpactI(event:any) {
    if(event?.target?.checked){
      this.highImpactVarI = event?.target?.checked
      this.rcaRequiredVar = event?.target?.checked
      this.verificationRequiredVar = event?.target?.checked
      this.reportsForm.patchValue({
        highImpactA: false,
        highImpactB: false,
        highImpact: false,
        rcaRequired: event?.target?.checked,
        verificatonRequired: event?.target?.checked
      })
    }
    else{
      this.highImpactVarI= event?.target?.checked
      this.reportsForm.patchValue({
        rcaRequired: false,
        verificatonRequired: false
      })
    }
  }

  highImpact(event:any) {
    if(event?.target?.checked){
      this.highImpactVar = event?.target?.checked
      this.highImpactVarA= event?.target?.checked
      this.highImpactVarB= event?.target?.checked
      this.highImpactVarI= event?.target?.checked
      this.rcaRequiredVar = event?.target?.checked
      this.verificationRequiredVar = event?.target?.checked
      this.reportsForm.patchValue({
        highImpactA: event?.target?.checked,
        highImpactB: event?.target?.checked,
        highImpactI: event?.target?.checked,
        rcaRequired: event?.target?.checked,
        verificatonRequired: event?.target?.checked,
        unconfirmed: false
      })
    }
    else{
      this.highImpactVar= event?.target?.checked
      this.reportsForm.patchValue({
        highImpactA: false,
        highImpactB: false,
        highImpactI: false,
        rcaRequired: false,
        verificatonRequired: false
      })
    }
  }

  rca(event:any) {
    if(event?.target?.checked){
      this.rcaRequiredVar = event?.target?.checked
      this.reportsForm.patchValue({
        highImpactA: false,
        highImpactB: false,
        highImpactI: false,
        verificatonRequired: false,
        unconfirmed: false
      })
    }
    else{
      this.rcaRequiredVar= event?.target?.checked
      this.reportsForm.patchValue({
        highImpactA: false,
        highImpactB: false,
        highImpactI: false,
        highImpact: false,
        rcaRequired: false,
        verificatonRequired: false,
      })
    }
  }

  verification(event:any) {
    if(event?.target?.checked){
      this.verificationRequiredVar = event?.target?.checked
      this.reportsForm.patchValue({
        highImpactA: false,
        highImpactB: false,
        highImpactI: false,
        rcaRequired: false,
        unconfirmed: false
      })
    }
    else{
      this.verificationRequiredVar= event?.target?.checked
      this.reportsForm.patchValue({
        highImpactA: false,
        highImpactB: false,
        highImpactI: false,
        highImpact: false,
        rcaRequired: false,
        verificatonRequired: false
      })
    }
  }

  containtment(event:any) {
    if(event?.target?.checked){
      this.containmentActionsVar = event?.target?.checked;
      this.reportsForm.patchValue({
        highImpactA: false,
        highImpactB: false,
        highImpactI: false,
        rcaRequired: false,
        unconfirmed: false,
        verificatonRequired: false
      })
    }
    else{
      this.containmentActionsVar= null
    }
  }

  unconfirmed(event:any) {

    if(event?.target?.checked){

      this.unconfirmedVar=event?.target?.checked
      this.highImpactVarA = null
      this.highImpactVarB= null
      this.highImpactVarI= null
      this.highImpactVar= null
      this.rcaRequiredVar= null
      this.verificationRequiredVar= null
      this.reportsForm.patchValue({
        highImpactA: false,
        highImpactB: false,
        highImpactI: false,
        highImpact: false,
        rcaRequired: false,
        verificatonRequired: false
      })
    }
    else{
      this.rcaRequiredVar= null
      this.reportsForm.patchValue({
        highImpactA: false,
        highImpactB: false,
        highImpactI: false,
        highImpact: false,
        rcaRequired: false,
        verificatonRequired: false
      })
    }
  }

  setSelectedTab = function (tabName) {

    if (tabName && this.currentSelectedTab !== tabName) {
      this.currentSelectedTab = tabName;
      this.clear();

      if(this.isDefault) {
        this.existingValuePatch(this?.isDefault);
      }
      else {
        this.defaultPatchedValues();
      }
    }
  };

  existingValuePatch(reportObj) {
    let level2byCodeData = {
      "levels1": [
          "PG"
      ],
      "levels2": [
          "PGGB PG"
      ]
    }

    let level3byCodeData = {
      "levels1": [
        "PG"
      ],
      "levels2": [
        "PGGB PG"
      ],
      "levels3": [
        "2500 PGGB PG"
      ]
    }

    this.reportService.getLevel2byCodes(level3byCodeData).subscribe((res) => {
      let level3Data = res
    })
    let customerData: any = [];
    let customerNameData:any=[];
    if(reportObj?.customer?.guids){
      this.reportService.findCustomerbyGeids(reportObj?.customer?.guids).subscribe((res) => {
        customerData= res;
        for(let i=0; i<customerData.length;i++){
          if (!customerNameData.includes(customerData[i]?.name)) {
            customerNameData.push({"name":customerData[i]?.name})
          }
          if(i==customerData.length-1){
            this.reportsForm.patchValue({
              customerName:customerNameData,
            })
          }
        }
      })

    }

    if(reportObj?.isNps){
      let isNpsVal= reportObj?.isNps;
      this.reportsForm.patchValue({
        ccrpCheck: null,
        npxxx: null,
        npmxx: null,
        sfdc: null,
        topQI: null
      })
      if(isNpsVal>=16){
        isNpsVal= isNpsVal-16;
        this.reportsForm.patchValue({
          topQI:this.checkBlank(true)
        })
      }
      if(isNpsVal>=8){
        isNpsVal= isNpsVal-8;
        this.reportsForm.patchValue({
          sfdc:this.checkBlank(true)
        })
      }
      if(isNpsVal>=4){
        isNpsVal= isNpsVal-4;
        this.reportsForm.patchValue({
          npmxx:this.checkBlank(true)
        })
      }
      if(isNpsVal>=2){
        isNpsVal= isNpsVal-2;
        this.reportsForm.patchValue({
          npxxx:this.checkBlank(true)
        })
      }

      if(isNpsVal==1){
        isNpsVal= isNpsVal-1;
        this.reportsForm.patchValue({
          ccrpCheck:this.checkBlank(true)
        })
      }
    }
    else {
      this.reportsForm.patchValue({
        ccrpCheck: null,
        npxxx: null,
        npmxx: null,
        sfdc: null,
        topQI: null
      })
    }


    // this.reportsForm.get('resolveBu').setValue(reportObj.ru.bus);
    let ccrpNo = [];
    if(reportObj) {
      this.optionModel = reportObj?.customer?.isExternal;
      this.optionModelResolver = reportObj?.ru?.isGlobal;

      ccrpNo = reportObj?.ccrpNumbers?.map((no) => ({cno: no}));
    }

    if(ccrpNo.length > 0) {
      this.reportsForm.patchValue({
        FilterCCRPTicketNumber: ccrpNo,
        reportsColumn: this.checkBlank(reportObj?.columns?.allInOne)
      })
    }
    else {
      this.reportsForm.patchValue({
        resolveBusiness:['PG'],
        resolveBu:this.checkBlank(reportObj?.ru?.bus),
        resolvepg: this.checkBlank(reportObj?.ru?.pgs),
        activities:this.checkBlank(reportObj?.ru?.activities),
        descriptiveNames:this.checkBlank(reportObj?.ru?.descNames),
        region:this.checkBlank(reportObj?.ru?.regions),
        resolverCountry:this.checkBlank(reportObj?.ru?.countries),
        resolverCity:this.checkBlank(reportObj?.ru?.cities),
        customerpg:this.checkBlank(reportObj?.customer?.regions),
        customercountry:this.checkBlank(reportObj?.customer?.countries),
        customercity:this.checkBlank(reportObj?.customer?.cities),
        lv1:this.checkBlank(reportObj?.cmplOwner?.managmentLevel1),
        lv2:this.checkBlank(reportObj?.cmplOwner?.managmentLevel2),
        lv3:this.checkBlank(reportObj?.cmplOwner?.managmentLevel3),
        coregion:this.checkBlank(reportObj?.cmplOwner?.regions),
        ownercountry:this.checkBlank(reportObj?.cmplOwner?.countries),
        ownercity:this.checkBlank(reportObj?.cmplOwner?.cities),
        status:this.checkBlank(reportObj?.statuses),
        dateType:this.checkBlank(reportObj?.date?.kind),
        dateRange:this.checkBlank(reportObj?.date?.period),
        rangeStartDate:new Date(reportObj?.date?.startDate),
        rangeEndDate: new Date(reportObj?.date?.endDate),
        highImpactA:this.checkBlank(reportObj?.specialCases?.hasHighImpactClassA),
        highImpactB: this.checkBlank(reportObj?.specialCases?.hasHighImpactClassB),
        highImpactI: reportObj.specialCases.hasHighImpactClassI,
        highImpact:this.checkBlank(reportObj?.specialCases?.hasHighImpact),
        rcaRequired: this.checkBlank(reportObj?.specialCases?.rcaIsRequired),
        verificatonRequired: this.checkBlank(reportObj?.specialCases?.verificationRequired),
        containtmentAction:this.checkBlank(reportObj?.specialCases?.containmentActionsRequired),
        unconfirmed:this.checkBlank(reportObj?.specialCases?.unconfirmed),
        columns:this.checkBlank(reportObj?.columns?.allInOne)
      })
    }

  }

  reset = function () {
    this.selected.date.startDate = moment().startOf('day').add('month', -1).toDate(),
      this.selected.date.endDate = moment().endOf('day').toDate();
    this.selected.CCRPMode = false;
  };


  //radio buttons reports
  handleOptionChange(selectedOption: string | null) {
    this.external = selectedOption;
  }

  //radio buttons reports
  handleOptionChangeGlobal(selectedOption: string | null) {
    this.radioChange = selectedOption;
    if (this.radioChange == true) {
      this.reportsForm.get('activities')?.disable();
      this.reportsForm.get('descriptiveNames')?.disable();
      this.reportsForm.get('resolverCountry')?.disable();
      this.reportsForm.get('region')?.disable();
      this.reportsForm.get('resolverCity')?.disable();
    }
    else if (this.radioChange == false || this.radioChange == null) {
      this.reportsForm.get('activities')?.enable();
      this.reportsForm.get('descriptiveNames')?.enable();
      this.reportsForm.get('resolverCountry')?.enable();
      this.reportsForm.get('region')?.enable();
      this.reportsForm.get('resolverCity')?.enable();
    }
  }
  calculateTotal(value:number, event:any): void {
    if(event?.target?.checked) {
      this.checkboxValueCount = this.checkboxValueCount + value;
    }
    else{
      this.checkboxValueCount = this.checkboxValueCount - value;
    }
  }



  /* Fiter Owner */
  filterCustomersData(event) {
    const dataPasses = {
      wildcard: event.originalEvent.target.value, accountType: "", customerhq: "", customerregion: "",
      customercity: "", page: 1, size: 1600
    };

    this.reportService.getCustomer(dataPasses).subscribe((response) => {
      this.filteredCustomersData = response;
      this.filteredCustomersData.map((customerData) => {
        customerData.name = customerData?.name;
      })
    })
  }

  customerSelect(event) {
    this.reportsForm.controls['customerName'].value.forEach(customer => {
      if (!this.custData.includes(customer?.id)) {
        this.custData.push(customer?.id)
      }
    })
  }

  customerGuidsUnselect(event) {
    const unselectedGuids = event.id;
    const index = this.custData.findIndex(item => item === unselectedGuids);
    if (index !== -1) {
      this.custData.splice(index, 1)
    }
  }

  ccrpSelect(event){
    this.reportsForm.controls['FilterCCRPTicketNumber'].value.forEach(ccrp => {
      if (!this.ccrpData.includes(ccrp?.complaintNo)) {
        this.ccrpData.push(ccrp?.complaintNo)
      }
    })

  }

  ccrpUnselect(event){
    const unselectedCCRPId = event.complaintNo;
    const index = this.ccrpData.findIndex(item => item === unselectedCCRPId);
    if (index !== -1) {
      this.ccrpData.splice(index, 1)
    }
  }

  onClickenterCCRPManually() {
    this.selectedFileCheck = null;
    this.enterCCRPManually = true;
    this.fileUpload?.clear();
    this.uploadCCRPFile = !true;
    this.btnColorChange = true;
    this.btnColorChange2 = false;
    this.fileUploadSelectBtn = false;
    this.ccrpData = [];
    // this.inverted === true ? true : false;
  }
  onClickuploadCCRPFile() {
    this.reportsForm.controls['FilterCCRPTicketNumber'].reset();
    this.enterCCRPManually = !true;
    this.uploadCCRPFile = true;
    this.btnColorChange = false;
    this.btnColorChange2 = true;
    this.ccrpData = [];
    // this.inverted === true ? true : false;

  }

  myUploader(createUploaderIssueDesc,event){
    this.inAppropriateExcelFile = false;
    this.excelColumnFormatWarning = false;
    this.maximumCCRP = false;
    let selectedFileName= event.currentFiles[0].name;
    this.selectedFileCheck = event.currentFiles[0].name;
    if (selectedFileName !== null) {
      this.inAppropriateExcelFile= false
      var regex = /^([a-zA-Z0-9()\s_\\.\-:])+(.xls|.xlsx|.csv)$/;
      if (regex.test(selectedFileName)) {
        this.excelColumnFormatWarning=false;
        this.maximumCCRP= false;
        this.Upload(event.files[0],createUploaderIssueDesc );
          //$scope.showRemoveButton = true;
          // $scope.$apply(function () { $scope.fileName = selectedFileName; });
          // $scope.Upload(e.target.files[0]);
      }
      else {
        this.inAppropriateExcelFile= true
        createUploaderIssueDesc.clear();
        this.selectedFileCheck = null;
        // this.removeFile(createUploaderIssueDesc, event)
          // $scope.$apply(function () { $scope.wrongFileExtension = true; });

      }
  }
  }

  Upload(selectedFile,createUploaderIssueDesc ){
    let arrayBuffer;
    let file= selectedFile;
    let fileReader = new FileReader();
    fileReader.readAsArrayBuffer(file);
    fileReader.onload = (e) => {
        arrayBuffer = fileReader.result;
        var data = new Uint8Array(arrayBuffer);
        var arr = new Array();
        for(var i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);
        var bstr = arr.join("");
        var workbook = XLSX.read(bstr, {type:"binary"});
        var first_sheet_name = workbook.SheetNames[0];
        var worksheet = workbook.Sheets[first_sheet_name];
        var firstSheet = workbook.SheetNames[0];
        //Read Header
        var excelRows = XLSX.utils.sheet_to_json(workbook.Sheets[firstSheet]);
        if (excelRows != null && excelRows != undefined && excelRows.length != 0) {
            var headerNames:any = XLSX.utils.sheet_to_json(workbook.Sheets[firstSheet], { header: 1 })[0];
             if (headerNames[0] != null && headerNames[0] != undefined && headerNames.length == 1 && headerNames[0].indexOf("CCRP") > -1) {


                var json_object = JSON.stringify(excelRows);

                var obj = JSON.parse(json_object);
                if (obj != null && obj != undefined && obj.length > 0 && obj.length <= 500) {
                    var CCRPNumbers = [];
                    for (var i = 0; i < obj.length; i++) {
                        if (Object.keys(obj[i]).toString() === headerNames[0]) {
                            CCRPNumbers.push(Object.values(obj[i]).toString());
                            this.ccrpData=CCRPNumbers;
                        }
                    }
                    this.fileUploadSelectBtn = true;
                }
                else {
                  createUploaderIssueDesc.clear();
                  this.maximumCCRP= true;
                  this.fileUploadSelectBtn = false;
                }
            }
            else {
              this.excelColumnFormatWarning = true;
              createUploaderIssueDesc.clear();
              this.fileUploadSelectBtn = false;
              this.selectedFileCheck = null;
                //$scope.removeFile();
                //$scope.$apply(function () { $scope.wrongFileFormat = true; });
            }
        } else{
          createUploaderIssueDesc.clear();
          this.excelColumnFormatWarning = true;
          this.fileUploadSelectBtn = false;
          this.selectedFileCheck = null;
        }

    }
  }


  //date ranges//
  openCalendar(event: any, check: any) {
    this.calendar.inputfieldViewChild.nativeElement.click();
    event.stopPropagation();
    this.reportsCalendarText = this.reportsForm.controls['daterange'].value;

  }

  startDateChanged(event) {

    if (event && moment(event))
      this.dateRange.endMinDate = moment(event).startOf('day').format();

  }

  endDateChanged(event) {
    if (event && moment(event))
      this.dateRange.startMaxDate = moment(event).endOf('day').format();
  };

  // formatdatetime = function () {
  //   this.selected.startDate = moment(this.selected.startDate).format('YYYY-MM-DD');
  //   this.selected.endDate = moment(this.selected.endDate).format('YYYY-MM-DD');
  // };

  hasHighImpactAClicked = function () {
    this.selected.hasHighImpactClassA = !this.selected.hasHighImpactClassA;

  };


  hasHighImpactBClicked = function () {
    this.selected.hasHighImpactClassB = !this.selected.hasHighImpactClassB;
  };

  hasHighImpactIClicked = function () {
    this.selected.hasHighImpactClassI = !this.selected.hasHighImpactClassI;
  };

  clear = function () {
    this.reportsForm.reset();
    this.onClickenterCCRPManually();
    this.defaultPatchedValues();

  };

  //radio buttons
  /* filterDataCCRPNo() {
    this.filterCCRPNumber = this.getCCRPNumber.filter((value) => {
      return value == value.number;
    })
  } */

  filterCCRPTicketNumber(event) {
    let query = event.originalEvent.target.value;

    this.reportService.getComplaintSearch(query).subscribe((Response)=>{
        this.filteredIssueTicketNumber= Response
      this.filteredIssueTicketNumber.map((customerData) => {
        customerData.cno = customerData.complaintNo+ '    (' + customerData?.type+ '|' + customerData?.status+')';
      })
    })
   /*  this.filteredIssueTicketNumber = this.getCCRPNumber.filter((abc) =>
      abc.number?.toLowerCase()?.includes(query.toLowerCase())); */
  }

  removeFile(file: File, uploader: FileUpload) {
    const index = uploader.files.indexOf(file);
    uploader.remove(event, index);
    this.fileUploadSelectBtn = false;
    this.selectedFileCheck = null;
    this.ccrpData = [];
  }

  clearColumns(){
    if(this.reportsForm.controls['columns'].value=='All'){
      this.reportsForm.get('columns').setValue('');
    }
  }

  showSaveSettingPopup() {
  let reportColumnData;
  if(this.currentSelectedTab == 1) {
    reportColumnData = this.reportsForm.controls['columns'].value?this.reportsForm.controls['columns'].value:["ALL"]
  }
  else if(this.currentSelectedTab == 2) {
    reportColumnData = this.reportsForm.controls['reportsColumn'].value?this.reportsForm.controls['reportsColumn'].value:["ALL"]
  }

  let npsCount = null;
//   ccrpCheck: null,
//   npxxx: null,
//   npmxx: null,
//   sfdc: null,
//   topQI: null
  if(this.reportsForm.controls['ccrpCheck'].value == true) {
    npsCount = npsCount + 1;
  }
  else if(this.reportsForm.controls['ccrpCheck'].value == false){
    npsCount = npsCount - 1;
  }

  if(this.reportsForm.controls['npxxx'].value == true) {
    npsCount = npsCount + 2;
  }
  else if (this.reportsForm.controls['npxxx'].value == false){
    npsCount = npsCount - 2;
  }

  if(this.reportsForm.controls['npmxx'].value == true) {
      npsCount = npsCount + 4;
  }
  else if(this.reportsForm.controls['npmxx'].value == false){
    npsCount = npsCount - 4;
  }

  if(this.reportsForm.controls['sfdc'].value == true) {
      npsCount = npsCount + 8;
  }
  else if(this.reportsForm.controls['sfdc'].value == false) {
    npsCount = npsCount - 8;
  }

  if(this.reportsForm.controls['topQI'].value == true) {
      npsCount = npsCount + 16;
  }
  else if(this.reportsForm.controls['topQI'].value == false){
    npsCount = npsCount - 16;
  }

     this.allFormData=  {
        "cmplOwner": {
          "managmentLevel1":  this.reportsForm.controls['lv1'].value?this.reportsForm.controls['lv1'].value:[],
          "managmentLevel2":  this.reportsForm.controls['lv2'].value?this.reportsForm.controls['lv2'].value:[],
          "managmentLevel3": this.reportsForm.controls['lv3'].value?this.reportsForm.controls['lv3'].value:[],
          "countries": this.reportsForm.controls['ownercountry'].value?this.reportsForm.controls['ownercountry'].value:[],
          "regions": this.reportsForm.controls['coregion'].value?this.reportsForm.controls['coregion'].value:[],
          "cities": this.reportsForm.controls['ownercity'].value?this.reportsForm.controls['ownercity'].value:[],
        },
        "ru": {
          "divisions": [
            "PG"
          ],
          "bus": this.reportsForm.controls['resolveBu'].value?this.reportsForm.controls['resolveBu'].value:[],
          "pgs": this.reportsForm.controls['resolvepg'].value?this.reportsForm.controls['resolvepg'].value:[],
          "isGlobal": this.radioChange,
          "countries": this.reportsForm.controls['resolverCountry'].value?this.reportsForm.controls['resolverCountry'].value:[],
          "regions": this.reportsForm.controls['region'].value?this.reportsForm.controls['region'].value:[],
          "cities": this.reportsForm.controls['resolverCity'].value?this.reportsForm.controls['resolverCity'].value:[],
          "descNames": this.reportsForm.controls['descriptiveNames'].value?this.reportsForm.controls['descriptiveNames'].value:[],
          "activities": this.reportsForm.controls['activities'].value?this.reportsForm.controls['activities'].value:[],
        },
        "statuses": this.reportsForm.controls['status'].value?this.reportsForm.controls['status'].value:[],
        "employees": [ ],
        "employeeRoles": [
          "ALL"
      ],
        "date": {
          "kind": this.reportsForm.controls['dateType'].value,
          "period": this.reportsForm.controls['dateRange'].value,
          "startDate": this.reportsForm.controls['rangeStartDate'].value?this.reportsForm.controls['rangeStartDate'].value:moment().startOf('day').add('month', -1).toDate(),
            "endDate": this.reportsForm.controls['rangeEndDate'].value?this.reportsForm.controls['rangeEndDate'].value:this.currentDate,
        },
        "culture": "en",
        "isNps": npsCount,
        "customer": {
          "customers": [],
          "customersHq":[],
          "cities": this.reportsForm.controls['customercity'].value?this.reportsForm.controls['customercity'].value:[],
          "locations": [],
          "locationsJSON": null,
          "isExternal": this.external,
          "accountType": null,
          "regions": this.reportsForm.controls['customerpg'].value?this.reportsForm.controls['customerpg'].value:[],
          "countries": this.reportsForm.controls['customercountry'].value?this.reportsForm.controls['customercountry'].value:[],
          "guids":  this.custData?this.custData:[],
        },
        "showResultsBy": "month",
        "timeMinutesOffsett": -330,
        "columns": {
          "allInOne":
            reportColumnData
        },
        "specialCases": {
          "hasHighImpact": this.highImpactVar?this.highImpactVar:null,
          "rcaIsRequired": this.rcaRequiredVar?this.rcaRequiredVar:null,
          "verificationRequired": this.verificationRequiredVar?this.verificationRequiredVar:null,
          "containmentActionsRequired": this.containmentActionsVar?this.containmentActionsVar:null,
          "hasHighImpactClassA": this.highImpactVarA?this.highImpactVarA:null,
          "hasHighImpactClassB": this.highImpactVarB?this.highImpactVarB:null,
          "hasHighImpactClassI": this.highImpactVarI?this.highImpactVarI:null,
          "unconfirmed": this.unconfirmedVar?this.unconfirmedVar:null
        },
        "ccrpNumbers": this.ccrpData?this.ccrpData:[]
      }
    this.isEdit=false;
    this.ref = this.dialogService.open(SaveSettingsPopupComponent, {

      data: {
        data:this.allFormData,
        edit:this.isEdit
      },
      header: "Report Settings",
      position: 'top',
      width: '600px',
      styleClass: 'customer-contact-selection-pop'
    });

    this.ref.onClose.subscribe((recevied) => {
      if (recevied=='ok') {
        this.getSettings();
      }
    })
  }

  exportToExcel(){
    let exportToExcelData
    if(this.currentSelectedTab == 2) {
      // let ccrpNos : any = [];
      // this.reportsForm.controls['FilterCCRPTicketNumber']?.value?.forEach(element => {
      //   ccrpNos.push(element.cno);
      // });
    //  this.ccrpData = ccrpNos;

      let reportColumnData = this.reportsForm.controls['reportsColumn'].value
      exportToExcelData={
        "ccrpNumbers":
        this.ccrpData,
        "ru": {
          "divisions": [],
          "bus": [],
          "pgs": [],
          "isGlobal": null,
          "countries": [],
          "regions": [],
          "cities": [],
          "activities": [],
          "descNames": []
        },
        "cmplOwner": {
          "managmentLevel1": [],
          "managmentLevel2": [],
          "managmentLevel3": [],
          "countries": [],
          "regions": [],
          "cities": []
        },
        "statuses": [],
        "employees": [],
        "employeeRoles": [],
        "customer": {
          "isExternal": null,
          "accountType": null,
          "customers": [],
          "customersHq": [],
          "locations": [],
          "locationsJSON": null,
          "regions": [],
          "countries": [],
          "cities": [],
          "guids": [],
          "contacts": []
        },
        "timeMinutesOffsett": 0,
        "culture": "en",
        "showResultsBy": null,
        "date": {
          "kind": "CreationDate",
          "period": "None",
          "startDate": null,
          "endDate": null
        },
        "isNps": null,
        "columns": {
          "allInOne": reportColumnData
        },
        "specialCases": {
          "hasHighImpact": null,
          "rcaIsRequired": null,
          "verificationRequired": null,
          "containmentActionsRequired": null,
          "hasHighImpactClassA": null,
          "hasHighImpactClassB": null,
          "hasHighImpactClassI": null,
          "unconfirmed": null
        },
        "CCRPMode": false,
        "customerRegionsArray": null,
        "customerCitiesArray": null
      }

    }
    else {
      let reportColumnData = this.reportsForm.controls['columns'].value?this.reportsForm.controls['columns'].value:["ALL"]
      exportToExcelData=  {
        "complaintOwnerInfo": {
          "managmentLevel1":  this.reportsForm.controls['lv1'].value?this.reportsForm.controls['lv1'].value:[],
          "managmentLevel2":  this.reportsForm.controls['lv2'].value?this.reportsForm.controls['lv2'].value:[],
          "managmentLevel3": this.reportsForm.controls['lv3'].value?this.reportsForm.controls['lv3'].value:[],
          "countries": this.reportsForm.controls['ownercountry'].value?this.reportsForm.controls['ownercountry'].value:[],
          "regions": this.reportsForm.controls['coregion'].value?this.reportsForm.controls['coregion'].value:[],
          "cities": this.reportsForm.controls['ownercity'].value?this.reportsForm.controls['ownercity'].value:[],
        },
        "resolvingUnitInfo": {
          "divisions": [
            "PG"
          ],
          "bus": this.reportsForm.controls['resolveBu'].value?this.reportsForm.controls['resolveBu'].value:[],
          "pgs": this.reportsForm.controls['resolvepg'].value?this.reportsForm.controls['resolvepg'].value:[],
          "isGlobal": this.radioChange,
          "countries": this.reportsForm.controls['resolverCountry'].value?this.reportsForm.controls['resolverCountry'].value:[],
          "regions": this.reportsForm.controls['region'].value?this.reportsForm.controls['region'].value:[],
          "cities": this.reportsForm.controls['resolverCity'].value?this.reportsForm.controls['resolverCity'].value:[],
          "descriptiveNames": this.reportsForm.controls['descriptiveNames'].value?this.reportsForm.controls['descriptiveNames'].value:[],
          "activities": this.reportsForm.controls['activities'].value?this.reportsForm.controls['activities'].value:[],
        },
        "statuses": this.reportsForm.controls['status'].value?this.reportsForm.controls['status'].value:[],
        "employees": [ ],
        "employeeRoles": [
          "ALL"
      ],
        "date": {
          "timezone": 0,
          "kind": this.reportsForm.controls['dateType'].value,
          "period": this.reportsForm.controls['dateRange'].value,
          "startDate": this.reportsForm.controls['rangeStartDate'].value ? new Date(this.dateSet(this.reportsForm.controls['rangeStartDate']?.value)) :moment().startOf('day').add('month', -1).toDate(),
          "endDate": this.reportsForm.controls['rangeEndDate'].value ? new Date(this.dateSet(this.reportsForm.controls['rangeEndDate']?.value)) : this.currentDate,
        },
        "culture": "en",
        "isNps": this.checkboxValueCount==0?null:this.checkboxValueCount,
        "customer": {
          "customers": [],
          "customersHq":[],
          "cities": this.reportsForm.controls['customercity'].value?this.reportsForm.controls['customercity'].value:[],
          "locations": [],
          "locationsJSON": null,
          "isExternal": this.external,
          "accountType": null,
          "regions": this.reportsForm.controls['customerpg'].value?this.reportsForm.controls['customerpg'].value:[],
          "countries": this.reportsForm.controls['customercountry'].value?this.reportsForm.controls['customercountry'].value:[],
          "guids":  this.custData?this.custData:[],
        },
        "showResultsBy": "month",
        "timeMinutesOffsett": -330,
        "columns": {
          "allInOne":
            reportColumnData
        },
        "specialCases": {
          "hasHighImpact": this.highImpactVar?this.highImpactVar:null,
          "rcaIsRequired": this.rcaRequiredVar?this.rcaRequiredVar:null,
          "verificationRequired": this.verificationRequiredVar?this.verificationRequiredVar:null,
          "containmentActionsRequired": this.containmentActionsVar?this.containmentActionsVar:null,
          "hasHighImpactClassA": this.highImpactVarA?this.highImpactVarA:null,
          "hasHighImpactClassB": this.highImpactVarB?this.highImpactVarB:null,
          "hasHighImpactClassI": this.highImpactVarI?this.highImpactVarI:null,
          "unconfirmed": this.unconfirmedVar?this.unconfirmedVar:null
        },
        "ccrpNumbers": this.ccrpData?this.ccrpData:[]
      }

    }
    if(exportToExcelData.complaintOwnerInfo?.managmentLevel2?.length) {
      for(var i = 0; i < exportToExcelData.complaintOwnerInfo.managmentLevel2.length; i++) {
        exportToExcelData.complaintOwnerInfo.managmentLevel2[i] = exportToExcelData.complaintOwnerInfo.managmentLevel2[i].replace(/ /g, "_")
      }
    }
    if(exportToExcelData.complaintOwnerInfo?.managmentLevel3?.length) {
      for(var i = 0; i < exportToExcelData.complaintOwnerInfo.managmentLevel3.length; i++) {
        exportToExcelData.complaintOwnerInfo.managmentLevel3[i] = exportToExcelData.complaintOwnerInfo.managmentLevel3[i].replace(/ /g, "_")
      }
    }
    this.IsExcelGenerating = true;
    this.reportService.getExcelReport(exportToExcelData).subscribe((response: ArrayBuffer)=>{
      const blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const downloadLink = document.createElement('a');
      downloadLink.href = window.URL.createObjectURL(blob);
      //downloadLink.download = this.reportNo;
      downloadLink.click();
      this.IsExcelGenerating= false;
    });


  }

  viewReport(){
    let viewReportData = null;
    if(this.currentSelectedTab == 2) {
      let reportColumnData = this.reportsForm.controls['reportsColumn'].value
      viewReportData={
        "editorInfo": {
          "geid": this.userInfoGeid
        },
        "ccrpNumbers":
          this.ccrpData,
        "ru": {
          "divisions": [],
          "bus": [],
          "pgs": [],
          "isGlobal": null,
          "countries": [],
          "regions": [],
          "cities": [],
          "activities": [],
          "descNames": []
        },
        "cmplOwner": {
          "managmentLevel1": [],
          "managmentLevel2": [],
          "managmentLevel3": [],
          "countries": [],
          "regions": [],
          "cities": []
        },
        "statuses": [],
        "employees": [],
        "employeeRoles": [],
        "customer": {
          "isExternal": null,
          "accountType": null,
          "customers": [],
          "customersHq": [],
          "locations": [],
          "locationsJSON": null,
          "regions": [],
          "countries": [],
          "cities": [],
          "guids": [],
          "contacts": []
        },
        "timeMinutesOffsett": 0,
        "culture": "en",
        "showResultsBy": null,
        "date": {
          "kind": "CreationDate",
          "period": "None",
          "startDate": null,
          "endDate": null
        },
        "isNps": null,
        "columns": {
          "allInOne": reportColumnData
        },
        "specialCases": {
          "hasHighImpact": null,
          "rcaIsRequired": null,
          "verificationRequired": null,
          "containmentActionsRequired": null,
          "hasHighImpactClassA": null,
          "hasHighImpactClassB": null,
          "hasHighImpactClassI": null,
          "unconfirmed": null
        },
        "CCRPMode": false,
        "customerRegionsArray": null,
        "customerCitiesArray": null
      }
    }
    else {
      let reportColumnData = this.reportsForm.controls['columns'].value?this.reportsForm.controls['columns'].value:["ALL"];
      let L2String = this.reportsForm.controls['lv2'].value?this.reportsForm.controls['lv2'].value:[];
      let L3String = this.reportsForm.controls['lv3'].value?this.reportsForm.controls['lv3'].value:[];
      let Level2String = L2String.map((elem)=> elem.replace(/\s/g,'_'));
      let Level3String = L3String.map((elem)=> elem.replace(/\s/g,'_'));

      viewReportData =   {
        "editorInfo": {
          "geid": this.userInfoGeid
        },
        "complaintOwnerInfo": {
          "managmentLevel1":  this.reportsForm.controls['lv1'].value?this.reportsForm.controls['lv1'].value:[],
          "managmentLevel2":  Level2String ? Level2String :[] ,
          "managmentLevel3": Level3String ? Level3String :[],
          "countries": this.reportsForm.controls['ownercountry'].value?this.reportsForm.controls['ownercountry'].value:[],
          "regions": this.reportsForm.controls['coregion'].value?this.reportsForm.controls['coregion'].value:[],
          "cities": this.reportsForm.controls['ownercity'].value?this.reportsForm.controls['ownercity'].value:[],
        },
        "resolvingUnitInfo": {
          "divisions": [
            "PG"
          ],
          "bus": this.reportsForm.controls['resolveBu'].value?this.reportsForm.controls['resolveBu'].value:[],
          "pgs": this.reportsForm.controls['resolvepg'].value?this.reportsForm.controls['resolvepg'].value:[],
          "isGlobal": this.radioChange,
          "countries": this.reportsForm.controls['resolverCountry'].value?this.reportsForm.controls['resolverCountry'].value:[],
          "regions": this.reportsForm.controls['region'].value?this.reportsForm.controls['region'].value:[],
          "cities": this.reportsForm.controls['resolverCity'].value?this.reportsForm.controls['resolverCity'].value:[],
          "descriptiveNames": this.reportsForm.controls['descriptiveNames'].value?this.reportsForm.controls['descriptiveNames'].value:[],
          "activities": this.reportsForm.controls['activities'].value?this.reportsForm.controls['activities'].value:[],
        },
        "statuses": this.reportsForm.controls['status'].value?this.reportsForm.controls['status'].value:[],
        "employees": [ ],
        "employeeRoles": [
          "ALL"
      ],
        "date": {
          "timezone": 0,
          "kind": this.reportsForm.controls['dateType'].value,
          "period": this.reportsForm.controls['dateRange'].value,
          "startDate": this.reportsForm.controls['rangeStartDate'].value?this.reportsForm.controls['rangeStartDate'].value:moment().startOf('day').add('month', -1).toDate(),
          "endDate": this.reportsForm.controls['rangeEndDate'].value?this.reportsForm.controls['rangeEndDate'].value:this.currentDate,
        },
        "culture": "en",
        "isNps": this.checkboxValueCount==0?null:this.checkboxValueCount,
        "customer": {
          "customers": [],
          "customersHq":[],
          "cities": this.reportsForm.controls['customercity'].value?this.reportsForm.controls['customercity'].value:[],
          "locations": [],
          "locationsJSON": null,
          "isExternal": this.external,
          "accountType": null,
          "regions": this.reportsForm.controls['customerpg'].value?this.reportsForm.controls['customerpg'].value:[],
          "countries": this.reportsForm.controls['customercountry'].value?this.reportsForm.controls['customercountry'].value:[],
          "guids":  this.custData?this.custData:[],
        },
        "showResultsBy": "month",
        "timeMinutesOffsett": -330,
        "columns": {
          "allInOne":
            reportColumnData
        },
        "specialCases": {
          "hasHighImpact": this.highImpactVar?this.highImpactVar:null,
          "rcaIsRequired": this.rcaRequiredVar?this.rcaRequiredVar:null,
          "verificationRequired": this.verificationRequiredVar?this.verificationRequiredVar:null,
          "containmentActionsRequired": this.containmentActionsVar?this.containmentActionsVar:null,
          "hasHighImpactClassA": this.highImpactVarA?this.highImpactVarA:null,
          "hasHighImpactClassB": this.highImpactVarB?this.highImpactVarB:null,
          "hasHighImpactClassI": this.highImpactVarI?this.highImpactVarI:null,
          "unconfirmed": this.unconfirmedVar?this.unconfirmedVar:null
        },
        "ccrpNumbers": this.ccrpData?this.ccrpData:[]
      }

    }
    let id1=""; //4A8DE5F9-4EE1-4F18-A2CA-7D9C6C6218D7
    let id2=""; //k=MTc5MTA //
    const navigationExtras: NavigationExtras = {
      queryParams: {data: JSON.stringify(viewReportData)}
    }
    localStorage.removeItem('viewReportData');
    localStorage.setItem('viewReportData', JSON.stringify(viewReportData));
    window.open('AllInOne/export/','_blank')
    // this.router.navigate(['AllInOne/'], navigationExtras).then(result=>{
    //   window.open('AllInOne/export/' + id1 +'/'+ id2,'_blank')
    // });

  }

  // removeAllFromColumn() {
  //   if(this.reportsForm.controls['reportsColumn'].value[0] == 'All') {
  //     this.reportsForm.controls['reportsColumn'].patchValue([]);
  //   }
  // }

  changeColumnTabTwo(event){
    if(event?.value?.length===0){
      let selectArray = ['All'];
      this.reportsForm.controls['reportsColumn'].patchValue(selectArray);
    }
    else if(event?.itemValue != 'All'){
      let selectArray = [];
      event?.value?.forEach(element => {
        if(element != 'All')
          selectArray.push(element);
      });
      this.reportsForm.controls['reportsColumn'].patchValue(selectArray);
    }
    else if(event?.itemValue == 'All'){
      this.reportsForm.controls['reportsColumn'].patchValue(['All']);
    }
  }

  changeColumnTabOne(event){
    if(event?.value?.length===0){
      let selectArray = ['All'];
      this.reportsForm.controls['columns'].patchValue(selectArray);
    }
    else if(event?.itemValue != 'All'){
      let selectArray = [];
      event?.value?.forEach(element => {
        if(element != 'All')
          selectArray.push(element);
      });
      this.reportsForm.controls['columns'].patchValue(selectArray);
    }
    else if(event?.itemValue == 'All'){
      this.reportsForm.controls['columns'].patchValue(['All']);
    }
  }

  show(reportObj) {
    //this.currentSelectedTab= this.constVariables.tab.byFilter;
    if(reportObj) {
      if(reportObj?.ccrpNumbers?.length > 0) {
        this.currentSelectedTab = 2;
        this.onClickenterCCRPManually();
      }
      else {
        this.currentSelectedTab = 1;
      }
    }
    let level2byCodeData = {
      "levels1": [
          "PG"
      ],
      "levels2": [
          "PGGB PG"
      ]
    }
    this.reportService.getLevel2byCodes(level2byCodeData).subscribe((res) => {
    })

    let level3byCodeData = {
      "levels1": [
        "PG"
      ],
      "levels2": [
        "PGGB PG"
      ],
      "levels3": [
        "2500 PGGB PG"
      ]
    }

    this.reportService.getLevel2byCodes(level3byCodeData).subscribe((res) => {
      let level3Data = res
    })
    let customerData: any = [];
    let customerNameData:any=[];
    if(reportObj?.customer?.guids){
      this.reportService.findCustomerbyGeids(reportObj?.customer?.guids).subscribe((res) => {
        customerData= res;
        for(let i=0; i<customerData.length;i++){
          if (!customerNameData.includes(customerData[i]?.name)) {
            customerNameData.push({"name":customerData[i]?.name})
          }
          if(i==customerData.length-1){
            this.reportsForm.patchValue({
              customerName:customerNameData,
            })
          }
        }
      })

    }

    if(reportObj?.isNps){
      let isNpsVal= reportObj?.isNps;
      this.reportsForm.patchValue({
        ccrpCheck: null,
        npxxx: null,
        npmxx: null,
        sfdc: null,
        topQI: null
      })
      if(isNpsVal>=16){
        isNpsVal= isNpsVal-16;
        this.reportsForm.patchValue({
          topQI:this.checkBlank(true)
        })
      }
      if(isNpsVal>=8){
        isNpsVal= isNpsVal-8;
        this.reportsForm.patchValue({
          sfdc:this.checkBlank(true)
        })
      }
      if(isNpsVal>=4){
        isNpsVal= isNpsVal-4;
        this.reportsForm.patchValue({
          npmxx:this.checkBlank(true)
        })
      }
      if(isNpsVal>=2){
        isNpsVal= isNpsVal-2;
        this.reportsForm.patchValue({
          npxxx:this.checkBlank(true)
        })
      }

      if(isNpsVal==1){
        isNpsVal= isNpsVal-1;
        this.reportsForm.patchValue({
          ccrpCheck:this.checkBlank(true)
        })
      }
    }
    else {
      this.reportsForm.patchValue({
        ccrpCheck: null,
        npxxx: null,
        npmxx: null,
        sfdc: null,
        topQI: null
      })
    }


    // this.reportsForm.get('resolveBu').setValue(reportObj.ru.bus);
    let ccrpNo = [];
    if(reportObj) {
      this.optionModel = reportObj?.customer?.isExternal;
      this.optionModelResolver = reportObj?.ru?.isGlobal;

      ccrpNo = reportObj?.ccrpNumbers?.map((no) => ({cno: no}));
    }

    this.reportsForm.patchValue({
      resolveBusiness:['PG'],
      resolveBu:this.checkBlank(reportObj?.ru?.bus),
      resolvepg: this.checkBlank(reportObj?.ru?.pgs),
      activities:this.checkBlank(reportObj?.ru?.activities),
      descriptiveNames:this.checkBlank(reportObj?.ru?.descNames),
      region:this.checkBlank(reportObj?.ru?.regions),
      resolverCountry:this.checkBlank(reportObj?.ru?.countries),
      resolverCity:this.checkBlank(reportObj?.ru?.cities),
      customerpg:this.checkBlank(reportObj?.customer?.regions),
      customercountry:this.checkBlank(reportObj?.customer?.countries),
      customercity:this.checkBlank(reportObj?.customer?.cities),
      lv1:this.checkBlank(reportObj?.cmplOwner?.managmentLevel1),
      lv2:this.checkBlank(reportObj?.cmplOwner?.managmentLevel2),
      lv3:this.checkBlank(reportObj?.cmplOwner?.managmentLevel3),
      coregion:this.checkBlank(reportObj?.cmplOwner?.regions),
      ownercountry:this.checkBlank(reportObj?.cmplOwner?.countries),
      ownercity:this.checkBlank(reportObj?.cmplOwner?.cities),
      status:this.checkBlank(reportObj?.statuses),
      dateType:this.checkBlank(reportObj?.date?.kind),
      dateRange:this.checkBlank(reportObj?.date?.period),
      rangeStartDate:new Date(reportObj?.date?.startDate),
      rangeEndDate: new Date(reportObj?.date?.endDate),
      highImpactA:this.checkBlank(reportObj?.specialCases?.hasHighImpactClassA),
      highImpactB: this.checkBlank(reportObj?.specialCases?.hasHighImpactClassB),
      highImpactI: reportObj.specialCases.hasHighImpactClassI,
      highImpact:this.checkBlank(reportObj?.specialCases?.hasHighImpact),
      rcaRequired: this.checkBlank(reportObj?.specialCases?.rcaIsRequired),
      verificatonRequired: this.checkBlank(reportObj?.specialCases?.verificationRequired),
      containtmentAction:this.checkBlank(reportObj?.specialCases?.containmentActionsRequired),
      unconfirmed:this.checkBlank(reportObj?.specialCases?.unconfirmed),
      columns:this.checkBlank(reportObj?.columns?.allInOne),
      FilterCCRPTicketNumber: ccrpNo,
      reportsColumn: this.checkBlank(reportObj?.columns?.allInOne)
    })

  }

  editSettings(reportObj) {
    this.isEdit=true;
    this.ref = this.dialogService.open(SaveSettingsPopupComponent, {
      header: "Report Settings",
      position: 'top',
      width: '600px',
      styleClass: 'customer-contact-selection-pop',
      data: {
        data:reportObj,
        edit:this.isEdit
      },
    })

    this.ref.onClose.subscribe((recevied) => {
      if (recevied=='ok') {
        this.getSettings();
      }
    })

    let geidsData=
    [
      "9X70996003"
    ]
    this.reportService.findbyGeids(geidsData).subscribe((res) => {
    })
    this.getSettings()
  }

remove(c){
  this.reportService.reportSettingsDelete(this.reportNo,c.id).subscribe((res) => {
    this.getSettings()
    this.toaster.showSuccess('Items was successfully');
  })
}

dateSet(date){
 return new Date().setFullYear(date.getFullYear(), date.getMonth(), date.getDate())
}

}
