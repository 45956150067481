import { Component, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { LazyLoadEvent, MessageService, PrimeNGConfig } from 'primeng/api';
import { ResolvingUnitServiceService } from 'src/app/services/resolving-unit-service.service';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { SessionStorageServiceService } from 'src/app/services/session-storage-service.service';
import { ToasterserviceService } from 'src/app/services/toasterservice.service';
import { NgxSpinnerService } from 'ngx-spinner';

export enum PGTypes {
  HVDC = 2215,
  HVDCService = 2222,
}

@Component({
  selector: 'app-resolving-unit-select-pop-up',
  templateUrl: './resolving-unit-select-pop-up.component.html',
  styleUrls: ['./resolving-unit-select-pop-up.component.css']
})
export class ResolvingUnitSelectPopUpComponent implements OnInit {
  first: number = 0;
  firstIndex: number = 0;
  rows: number = 6;

  resolvingPopForm!: FormGroup;
  textboxEnabled = false;
  searchText: any;
  radioValues = 'Search by business activity location';
  isChecked: boolean = false;
  activityDropdownData: any;
  recentlySelected: boolean = false;

  filteredCompanyDetails: any;
  newArray: any = [];
  selectedIndex = -1;
  selectedCountry: String = '';
  states: Array<any> = [];
  cities: Array<any> = [];
  selectedRowData: any;
  companyDetails: Array<any> = [];
  selectedBu: String = '';
  pgName: any;
  country!: string;
  city!: string;
  buPgList: any;
  divisionData: any;
  getCountryList: any[];
  getCityList: any[];
  VerificationTeamData: any;
  public classData: any;
  highImpact: boolean;
  coordinatorData: any;
  totalRecords: any = 0;
  dynamicPageNumber: any;
  loading: boolean = false;
  viewClicked = false;
  rowSelectData: any;
  recievedProcessStepId:any;
  isAccountablePerson:Boolean=false;
  isRUPerson:Boolean = false;
  setHVDCFlag:boolean = false;
  constructor(private fb: FormBuilder, private resolvingUnitService: ResolvingUnitServiceService, private primengConfig: PrimeNGConfig,
    private readonly dialogRef: DynamicDialogRef, private sessionStorage: SessionStorageServiceService, private config: DynamicDialogConfig,
    private spinnerService: NgxSpinnerService,
    private messageService: MessageService,
    private toaster:ToasterserviceService) {
    // this.resolvingUnitService.getUsers().subscribe((response) => {
    //   this.companyDetails = response;
    // });
  }

  ngOnInit(): void {
    this.spinnerService.show();
    this.recentlySelected = true;
    this.getCountries();
    this.classData = this.config?.data?.matrxiData;
    this.recievedProcessStepId = this.config?.data?.processStep?.processStepId;
    // console.log(this.config.data,'Resolving Unit Class with Process Step');
    // this.getCountries();
    this.resolvingPopForm = this.fb.group({
      balIdRadioBtn: [''],
      balId: new FormControl({ value: '', disabled: true }),
      searchByBusinessLocation: [''],
      businessActivity: [''],
      local: ['local'],
      global: [''],
      business: ['PG'],
      country: [''],
      bu: [''],
      city: [''],
      pg: [''],
      activity: [''],
    });

    this.primengConfig.ripple = true;

    this.resolvingUnitService.getPG('PG').subscribe((response) => {
      this.buPgList = response;
    });
    this.resolvingUnitService.getResolvingUnitsRecentlySelected(this.sessionStorage.getData('userInfo').geid).subscribe((response) => {
      this.filteredCompanyDetails = response.data;
      this.totalRecords = response.count;
      this.spinnerService.hide();
      this.loading = false;
    })

    this.resolvingUnitService.getDivision().subscribe((response) => {
      this.divisionData = response;
    });
    this.resolvingUnitService
    .getActivity('PG')
    .subscribe((response) => {
      this.activityDropdownData = response;
    });
    this.resolvingPopForm.get('country')?.valueChanges.subscribe((newValue) => {
      const countryCode = newValue;
      if (countryCode) {
        this.resolvingUnitService.getAllCity(countryCode).subscribe((cities) => {
          if (cities) {
            this.getCityList = cities;
          }
        });
      }
    })


    this.resolvingPopForm
      .get('balIdRadioBtn')
      ?.valueChanges.subscribe((value) => {
        if (value === 'balRadio') {
          this.resolvingPopForm.get('balId')?.enable();

          this.resolvingPopForm.get('country')?.disable();
          this.resolvingPopForm.get('country')?.reset();

          this.resolvingPopForm.get('city')?.disable();
          this.resolvingPopForm.get('city')?.reset();

          this.resolvingPopForm.get('activity')?.disable();
          this.resolvingPopForm.get('activity')?.reset();

          this.resolvingPopForm.get('business')?.disable();
          this.resolvingPopForm.get('business')?.reset();

          this.resolvingPopForm.get('bu')?.disable();
          this.resolvingPopForm.get('bu')?.reset();

          this.resolvingPopForm.get('pg')?.disable();
          this.resolvingPopForm.get('pg')?.reset();

          this.resolvingPopForm.get('local')?.disable();

          this.resolvingPopForm.get('global')?.disable();
        } else if (value === 'Search by business activity location') {
          this.resolvingPopForm.get('country')?.enable();
          this.resolvingPopForm.get('city')?.enable();
          this.resolvingPopForm.get('activity')?.enable();
          this.resolvingPopForm.get('business')?.enable();
          this.resolvingPopForm.get('bu')?.enable();
          this.resolvingPopForm.get('pg')?.enable();
          this.resolvingPopForm.get('local')?.enable();
          this.resolvingPopForm.get('global')?.enable();
        }
      });

    this.resolvingPopForm.get('country')?.valueChanges.subscribe((value) => {
      this.resolvingPopForm.get('city')?.reset();
    });

    this.resolvingPopForm
      .get('local')
      ?.valueChanges.subscribe((selectedLocal) => {
        if (selectedLocal == 'local') {
          this.resolvingPopForm.get('country')?.enable();
          this.resolvingPopForm.get('city')?.enable();
          this.resolvingPopForm.get('activity')?.enable();
          this.resolvingPopForm.get('business')?.enable();
          this.resolvingPopForm.get('bu')?.enable();
          this.resolvingPopForm.get('pg')?.enable();
        } else if (selectedLocal == 'global') {
          this.resolvingPopForm.get('country')?.disable();
          this.resolvingPopForm.get('city')?.disable();
          this.resolvingPopForm.get('activity')?.disable();
        }
      });

    this.resolvingPopForm.get('balIdRadioBtn')?.valueChanges.subscribe((ballId) => {
      if (ballId == 'balRadio') {
        this.resolvingPopForm.get('business')?.disable();
        this.resolvingPopForm.get('bu')?.disable();
        this.resolvingPopForm.get('pg')?.disable();
        this.resolvingPopForm.get('country')?.disable();
        this.resolvingPopForm.get('city')?.disable();
        this.resolvingPopForm.get('activity')?.disable();
        this.resolvingPopForm.controls['business'].patchValue('PG');
      }
    });

    this.resolvingPopForm.get('searchByBusinessLocation')?.valueChanges.subscribe((ballId) => {
      if (ballId == 'Search by business activity location') {
        this.resolvingPopForm.get('business')?.enable();
        this.resolvingPopForm.get('bu')?.enable();
        this.resolvingPopForm.get('pg')?.enable();
        this.resolvingPopForm.get('country')?.enable();
        this.resolvingPopForm.get('city')?.enable();
        this.resolvingPopForm.get('activity')?.enable();
        this.resolvingPopForm.controls['business'].patchValue('PG');
      }
    });
  }

  tableheaders: string[] = [
    'BAL ID',
    'ABACUS',
    'MgmtOrg',
    'Product group',
    'Address',
    'Activity',
  ];

  onRadioChange() {
    const radioValue = this.resolvingPopForm.get('radio')?.value;
    this.textboxEnabled = radioValue === 'enable';
    const textboxControl = this.resolvingPopForm.get('balId');
    if (this.textboxEnabled) {
      textboxControl?.enable();
    } else {
      textboxControl?.disable();
    }
  }

  enableTextBox() {
    this.textboxEnabled = true;
  }

  loadMoreResolvingData(event: any) {
    if (this.viewClicked == true) {
      let skipRecord = 0;
      const pageNumber = (event.first + 6) / 6;
      skipRecord += event.first;
      this.loading = true;
      this.viewRuPopUpDetails(pageNumber, skipRecord);
    }
  }

  viewRuPopUpDetails(pageNumber = 1, skipRecord = 0) {
    this.spinnerService.show();
    this.recentlySelected = false;
    const bailIdValue = this.resolvingPopForm.get('balIdRadioBtn').value == 'balRadio' ? this.resolvingPopForm.controls['balId'].value.trim() : ''
    this.viewClicked = true;
    let formatedData = {
      "take": 6,
      "skip": skipRecord,
      "page": pageNumber,
      "pageSize": 6,
      "sort": [
        {
          "field": "BAL_Id",
          "dir": "asc"
        }
      ],
      "filter": {
        "logic": "and",
        "filters": [
          ]
      },
      "group": [],
    };
    if(this.radioValues == 'Search by business activity location'){
      formatedData.filter.filters.push({ "field": "DivisionCode", "operator": "eq", "value": this.checkBlank(this.resolvingPopForm.controls['business'].value) });
      formatedData.filter.filters.push({ "field": "BUCode", "operator": "eq", "value": this.checkBlank(this.resolvingPopForm.controls['bu'].value) });
      formatedData.filter.filters.push({ "field": "PGCode", "operator": "eq", "value": this.checkBlank(this.resolvingPopForm.controls['pg'].value) });
      if(this.resolvingPopForm.get('local').value=='local'){
        formatedData.filter.filters.push({ "field": "ActivityCode", "operator": "eq", "value": this.checkBlank(this.resolvingPopForm.controls['activity'].value) });
        formatedData.filter.filters.push({ "field": "CountryCode", "operator": "eq", "value": this.checkBlank(this.resolvingPopForm.controls['country'].value) });
        formatedData.filter.filters.push({ "field": "City", "operator": "eq", "value": this.checkBlank(this.resolvingPopForm.controls['city'].value) });
      }
      else{
        formatedData.filter.filters.push({"field": "IsGlobal", "operator": "eq", "value": "true"});
      }
    }
    else{
      if(bailIdValue!=''){
        formatedData.filter.filters.push({ "field": "BALId", "operator": "eq", "value": this.checkBlank(bailIdValue) });
      }
    }


    this.resolvingUnitService.getResolvingUnit(formatedData).subscribe((res: any) => {
      this.filteredCompanyDetails = res?.data
      this.totalRecords = res.count;
      this.coordinatorData=[];
      this.spinnerService.hide();
      this.loading = false;
    })

  }

  checkBlank(content) {
    if (content == undefined || content == null) {
      return '';
    } else {
      return content;
    }
  }
  pgChangeData(count: any) {
    let buText = this.resolvingPopForm.controls['bu'].value;
    let businessText = this.resolvingPopForm.controls['business'].value;


    this.resolvingUnitService
      .getActivity(businessText)
      .subscribe((response) => {
        this.activityDropdownData = response;
      });
    this.resolvingUnitService.getBU(businessText, buText).subscribe(
      (response) => {
        this.pgName = response;
      }
    )


    this.resolvingPopForm.controls['pg'].reset();
    if (this.selectedBu == '') {
      this.pgName = [];
    }
    this.pgName = this.buPgList.find(
      (con) => con.name == count.target.value
    )?.pgName;
  }

  // private map = new Map<string, string[]>([
  //   ['India', ['Delhi', 'Bangalore']],
  //   ['Qatar', ['Doha']],
  //   ['Afghanistan', ['Kabul']],
  //   ['Bahrain', ['Manama']],
  //   ['Bhutan', ['Kabul']],
  // ])

  // get countries(): string[] {
  //   // this.resolvingPopForm.controls['city'].reset();
  //   return Array.from(this.map.keys());
  // }

  // get citiesData(): string[] | undefined {
  //   return this.map.get(this.country);

  // }

  getCities() {
    let countyId = this.resolvingPopForm.controls['country'].value;
    this.resolvingUnitService.getAllCity(countyId).subscribe((cities) => {
      if (cities) {
        this.getCityList = cities;
      }
    });
  }

  getCountries() {
    this.resolvingUnitService.getAllCountry().subscribe((country) => {
      if (country) {
        this.getCountryList = country;
      }
    });
  }

  selectAndClose() {
    let resolvingUnitData = { selectedData: this.rowSelectData, coordinatorData: this.coordinatorData, vTeamData: this.VerificationTeamData, HVDCFlag: this.setHVDCFlag }
    this.dialogRef.close(resolvingUnitData);
  }

  localChanges() {
    this.resolvingPopForm
      .get('local')
      ?.valueChanges.subscribe((selectedLocal) => {
        if ((selectedLocal = 'local')) {
          this.resolvingPopForm.get('country')?.enable();
          this.resolvingPopForm.get('city')?.enable();
          this.resolvingPopForm.get('activity')?.enable();
          this.resolvingPopForm.get('business')?.enable();
          this.resolvingPopForm.get('bu')?.enable();
          this.resolvingPopForm.get('pg')?.enable();
        } else {
        }
      });
  }

  globalChanges() {
    this.resolvingPopForm
      .get('global')
      ?.valueChanges.subscribe((selectedLocal) => {
        if ((selectedLocal = 'global')) {
          this.resolvingPopForm.get('country')?.disable();
          this.resolvingPopForm.get('city')?.disable();
          this.resolvingPopForm.get('activity')?.disable();
        } else {
        }
      });
  }

  balIdChanges() {
    this.resolvingPopForm
      .get('balIdRadioBtn')
      ?.valueChanges.subscribe((ballId) => {
        if (ballId) {
          this.resolvingPopForm.get('business')?.disable();
          this.resolvingPopForm.get('bu')?.disable();
          this.resolvingPopForm.get('pg')?.disable();
        }
      });
  }

  onRowSelect(event) {
    this.selectedIndex = 0;
    this.spinnerService.show();
    this.rowSelectData = event?.data;
    let copyOfReceivedProcessStepId= null;

     if(this.recievedProcessStepId) {
          let pgId = event?.data?.pgCode;
          if (pgId == PGTypes.HVDC || pgId == PGTypes.HVDCService) {
            copyOfReceivedProcessStepId = this.recievedProcessStepId;
          }else{
            copyOfReceivedProcessStepId = null;
          }
      }else{
        copyOfReceivedProcessStepId = null;
      }

    const resolvingProcessId = { resolvingUnitId: event?.data?.dbId, processStepId: copyOfReceivedProcessStepId ? copyOfReceivedProcessStepId : '', isHightImpact: this.classData?.isHightImpact ? this.classData?.isHightImpact : false, matrixClass: this.classData?.matrixClass ? this.classData?.matrixClass : '' }
    this.resolvingUnitService.getCoOrdinator(resolvingProcessId).subscribe((coordinator) => {
      if (coordinator) {
        this.coordinatorData = coordinator.data;
        this.spinnerService.hide();
        if (this.recievedProcessStepId && coordinator.isRUPerson == true) {
          let setHVDCComplaint = { IsHVDCComplaint: false, processStepId: this.recievedProcessStepId ? this.recievedProcessStepId : null };
          localStorage.setItem('checkHVDCComplaint', JSON.stringify(setHVDCComplaint));
          this.toaster.showWarning('Accountable person not assign for this resolving unit.');
          this.isAccountablePerson = false;
          this.isRUPerson = true;
          this.setHVDCFlag = false;
        }
        if (this.recievedProcessStepId && coordinator.isAccountable == true) {
          var setHVDCComplaint = { IsHVDCComplaint: true, processStepId: this.recievedProcessStepId };
          localStorage.setItem('checkHVDCComplaint', JSON.stringify(setHVDCComplaint));
          this.isAccountablePerson = true;
          this.isRUPerson = false;
          this.setHVDCFlag = true;
        }
        else {
          var setHVDCComplaint = { IsHVDCComplaint: false, processStepId: this.recievedProcessStepId ? this.recievedProcessStepId : null };
          localStorage.setItem('checkHVDCComplaint', JSON.stringify(setHVDCComplaint));
          this.isRUPerson = true;
          this.setHVDCFlag = false;
        }
        // this.coordinatorData = coordinator.data;
        // if(coordinator.isAccountable){
        //   this.isAccountablePerson = true;
        //   this.isRUPerson = false;
        // }else{
        //   this.isAccountablePerson = false;
        //   this.isRUPerson = true;
        // }
      }
    },
    (error) => {
        this.spinnerService.hide();
        this.messageService.add({
            severity: 'error',
            summary: error ? error : 'Something went wrong!'
        });
    });

    this.resolvingUnitService
      .getVerificationTeam(resolvingProcessId)
      .subscribe((res) => {
        if (res) {
          this.VerificationTeamData = res.data;
        }
      },
      (error) => {
          this.spinnerService.hide();
          this.messageService.add({
              severity: 'error',
              summary: error ? error : 'Something went wrong!'
          });
      });
  }

  /* functionality of Paginator from primeng starts */
  next() {
    this.first = this.first + this.rows;
  }

  prev() {
    this.first = this.first - this.rows;
  }

  refresh() {
    this.first = 0;
  }

  isLastPage(): boolean {
    return this.filteredCompanyDetails
      ? this.first === this.filteredCompanyDetails.length - this.rows
      : true;
  }

  isFirstPage(): boolean {
    return this.filteredCompanyDetails ? this.first === 0 : true;
  }

  //   /* onPageChange(event: any) {
  //     event.first = 0;
  //     event.rows = 5;
  //     event.page = 1;
  //     event.pageCount = 5;
  //   } */

  // For resetting the form
  reset() {
    this.recentlySelected = true;
    this.isChecked = true;

    this.resolvingPopForm.reset();
    this.pgName = [];
    this.selectedIndex = -1;
    this.selectedRowData = '';

    this.resolvingPopForm.get('balId')?.disable();
    this.resolvingPopForm.get('business')?.enable();
    this.resolvingPopForm.get('country')?.enable();
    this.resolvingPopForm.get('city')?.enable();
    this.resolvingPopForm.get('bu')?.enable();
    this.resolvingPopForm.get('pg')?.enable();
    this.resolvingPopForm.get('activity')?.enable();
    this.resolvingPopForm.get('SearchByBusinessLocation')?.enable();
    this.coordinatorData=[];
    this.filteredCompanyDetails = null;

    this.resolvingPopForm.patchValue({
      business: '',
      bu: '',
      pg: '',
      country: '',
      city: '',
      activity: '',
      balId: '',
    });

    this.resolvingUnitService.getResolvingUnitsRecentlySelected(this.sessionStorage.getData('userInfo').geid).subscribe((response) => {
      this.filteredCompanyDetails = response.data;
      this.totalRecords = response.count;
      this.loading = false;
    })

    this.resolvingPopForm.controls['searchByBusinessLocation'].patchValue('Search by business activity location')
    this.resolvingPopForm.controls['local'].patchValue('local');
    // this.resolvingPopForm.controls['business'].patchValue('(PG)Power Grids');
    this.resolvingPopForm.controls['business'].patchValue('PG');
  }

  // for closing the dialog
  onClose() {
    this.dialogRef.close();
  }
}
