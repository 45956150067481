import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';

interface tokenModal{
  isCreator: boolean,
  isActionOwner: boolean
}

export interface IActionPlanPermissionsService {
  getUserPermissionToken(actionId, selectedRole);
  canAddNewAction(complaintNo);
}

@Injectable({
  providedIn: 'root'
})
export class ActionPlanPermissionsService implements IActionPlanPermissionsService {
  public static serviceId: string = "actionPlanPermissionsService";
  constructor(private http: HttpClient) { }

  canAddNewAction(complaintNo: any) {
    let params = new HttpParams()
        .set('complaintNo', complaintNo)

    let result = this.http.get<boolean>(`${environment.azure.baseUrl}api/v1/actionplan/board/token`, {params})
    return result;
  }

  result: any;
  getUserPermissionToken(actionId: any, selectedRole: any) {

    if (!actionId)
    {
      return { isCreator: true, isActionOwner: false };
    }
    else
    {
      let params = new HttpParams()
        .set('actionId', actionId)
        .set('selectedRole', selectedRole);
      this.result = this.http.get<any>(`${environment.azure.baseUrl}api/v1/actionplan/token`, {params})
    }
    return this.result;
  }
}
