<div class="row" *ngIf="!loginSuccess">
  <form [formGroup]="loginForm">
    <div class="row">
      <div class="col-4"></div>
      <div class="col-4">
        <label class="label-bold">Username:</label>
        <input type="text" formControlName="username" class="form-control form-control-sm" />
      </div>
      <div class="col-4"></div>
    </div>
    <div class="row">
      <div class="col-4"></div>
      <div class="col-4">
        <label class="label-bold">Password:</label>
        <input type="text" formControlName="password" class="form-control form-control-sm" />
      </div>
      <div class="col-4"></div>
    </div>
    <div class="row mt-1">
      <div class="col-4"></div>
      <div class="col-4">
        <button class="widgetButtonMedium" type="button" (click)="login() ">Login</button>
      </div>
      <div class="col-4"></div>
    </div>
    <div class="row mt-1 text-center" *ngIf="errorMessage">
      <p>Please enter correct credentials</p>
    </div>
  </form>
</div>


<div class="container mt-3" *ngIf="loginSuccess">
<form [formGroup]="testerLoginForm">
  <div class="mb-3">
    <div class="row">
      <div class="col-6">
        <label for="exampleInputEmail1" class="form-label" style="font-weight: bolder;">Email address :</label>
        <input type="email" placeholder="Please enter tester email" class="form-control form-control-sm" formControlName="testUserEmailId">
      </div>
      <div class="col-6"></div>
    </div>
  </div>
  <button (click)="testerLogin()" class="widgetButtonMedium">Login</button>
</form>
</div>
