import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class UsersService {

  constructor(private http: HttpClient) { }

  // Commented By Ram - As there is no api : EmailList and which makes unnecessary api call
  // getUsers(): Observable<any> {
  //   return this.http.get(`${environment.azure.baseUrl}/EmailList`);
  // }
  // getComplaintDetails(complaintId: number): Observable<any>{
  //   return this.http.get(`${environment.azure.baseUrl}/EmailList/${complaintId}`);
  // }
  getSupervisor(userGeid): Observable<any> {
    let data = new FormData();
    data.append("userGeid",userGeid);
    return this.http.post(`${environment.azure.baseUrl}api/v1/Data/GetEmployeeSupervisor?userGeid=${userGeid}`,data);
  }
  getFilteredUser(query): Observable<any> {
    return this.http.get(`${environment.azure.baseUrl}api/v1/employee/findbywildcardextended?wildcard=${query}&size=20`);
  }

  getFilteredUserActionBoard(query):Observable<any> {
    return this.http.get(`${environment.azure.baseUrl}api/v1/employee/findpagebywildcard?wildcard=${query}&onlyActive=true&page=1&size=50`);
  }
}

