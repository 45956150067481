<nav class="navbar navbar-default mt-3" style="background-color: #ffffff;" role="navigation">
  <div class="row mx-1 process-owner-section">
    <div *ngIf="!flagFromSearch" class="col-sm-1 add-people-btn">
      <button type="button" [hidden]="flagFromSearch" class="widgetButtoSmallPo mt-2 addWidgetBtnPOResp" (click)="addNewPersonWindow()" [disabled]="isSearching">
        {{'admin_po_addperson_btn'|translate}}
      </button>
    </div>
    <div [ngClass]="flagFromSearch ? 'col-sm-8': 'col-sm-7'">
      <span class="d-flex">
        <span [innerHTML]="descrHtml" class="form-control searchInfo ms-1 float-start" style="border-radius: 4px;">
        </span>
        <!-- <input class="form-control searchInfo" type="text" [innerHTML]="descrHtml" [placeholder]="descrHtml"> -->
        <button class="widgetButtoSmallPo search-criteria-btn float-start" (click)="criteriaWindow()" type="button" [disabled]="isSearching">
          {{'admin_po_search_btn'|translate}}
        </button>
      </span>
    </div>
    <div class="col-sm-3 sorttextfield">
      <span class="input-group" style="display: flex; text-align: left;">
        <select class="form-control" [(ngModel)]="order_by" style="width: 100%" (change)="refreshView()">
          <option value="scope.div">{{'admin_po_sort_div'|translate}}</option>
          <option value="scope.bu">{{'admin_po_sort_bu'|translate}}</option>
          <option value="scope.pg">{{'admin_po_sort_pg'|translate}}</option>
          <option value="scope.regionName">{{'admin_po_sort_region'|translate}}</option>
          <option value="scope.countryName">{{'admin_po_sort_country'|translate}}</option>
        </select>
        <span class="input-group-btn">
          <button *ngIf="order_mode=='asc'" (click)="setOrderMode('desc')"
            class="btn btn-default button-set dashboard-input-btn">
            <i class="hitachiicon-sort_up"></i>
          </button>
          <button *ngIf="order_mode=='desc'" (click)="setOrderMode('asc')"
            class="btn btn-default button-set dashboard-input-btn">
            <i class="hitachiicon-sort_down"></i>
          </button>
        </span>

      </span>
    </div>
    <div class="col-sm-1 px-0 setting-save-button">
      <div class="row">
          <button class="btn btn-default settingicon"  (click)="poSettings()" type="button"
            [disabled]="isSearching">
            <i class="hitachiicon-gear"></i>
          </button>

          <button class="btn btn-default download-save-icon float-end me-2" (click)="downloadProcessOwners()"
          role="button" [disabled]="!canDownload">
          <i class="hitachiicon-save"></i>
          </button>
       

      </div>
    </div>
  </div>
  </nav>

  <div class="row mt-2" *ngIf="!hasCriteriaSelected">
    <p class="text-center">
      <small>{{'admin_po_selectsearchcriteria_msg' | translate}}</small>
    </p>
  </div>
  <div class="row mt-2" *ngIf="hasCriteriaSelected">
    <div class="col-sm-12">
      <div class="panel panel-default">
        <div class="panel-body">
          <app-po-infosection [items]="poDataOrdered" [itemsPerPage]="poPageSize"
            datanoitemsmsg="{{'admin_po_no_items' | translate}}"
            (reloadresults)="reloadSearchResults()"></app-po-infosection>
        </div>
      </div>
    </div>
  </div>
