import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ExternalpageEmitService } from '../externalpage-emit.service';

@Component({
  selector: 'app-subscribe',
  templateUrl: './subscribe.component.html',
  styleUrls: ['./subscribe.component.css']
})
export class SubscribeComponent implements OnInit {

  encodedURI: any;
  inputURI: any;
  message:any;

  constructor(private externalPageService: ExternalpageEmitService,
    private route: ActivatedRoute,
    public router: Router,
    private fb: FormBuilder, private spinnerService: NgxSpinnerService) { }

  ngOnInit(): void {
    this.externalPageService.setExternalPageFunction(true);
    this.inputURI = this.route.snapshot.paramMap.get('id');
    const decodedURI = atob(this.inputURI);
    this.encodedURI = btoa(decodedURI);

    if (this.encodedURI) {
      this.spinnerService.show();
      this.externalPageService.getSubscriptionStatus(this.encodedURI).subscribe(
        (data: any) => {
          this.message=data.message;
          this.spinnerService.hide();
        },
        (err) => {
          this.spinnerService.hide();
        }
      );
    }
  }

  changeToSubscribe(){
    this.spinnerService.show();
    this.externalPageService.updateSubscriptionStatus(this.encodedURI).subscribe(
      (data: any) => {
        this.message=data.message;
        this.spinnerService.hide();
      },
      (err) => {
        this.spinnerService.hide();
      }
    );
  }
}
