<div class="rounded-1 deactive-processStep-modal" style="height: auto;">

    <div class="modal-body processStep_deactivate_message">
        {{'admin_process_steps_deactivate_confirm_msg' | translate}}
    </div>
      <!-- <hr /> -->
    <div class="modal-footer pt-2">
      <button class="widgetButtoSmall" (click)="cancel()">{{'admin_process_steps_deactivate_confirm_cancel_lb' | translate}}</button>
      <button class="widgetButtoSmall ms-2" [disabled]="isSaving" type="submit" (click)="ok()">{{'admin_process_steps_deactivate_confirm_ok_lb' | translate}}</button>
    </div>
  </div>
