<div class="mainDiv customer-name-section">
    <div class="companyDetails customer-name-pop">
      <form [formGroup]="companyDetailsForm">
        <div class="mt-0 ps-0">
          <div class="row g-3 inp mb-2" *ngIf="errorSiteIdSelection">
            {{'CustomerSearch_ServISUnavailable' | translate}}
          </div>
          <div class="row g-3 inp mb-2">
            <div class="divColoumnSpan tagColumn">
              {{'CustomerSearch_CompanyName' | translate}}
            </div>
            <div class="col">
              <input
                type="text"
                class="form-control widgetTextBoxMedium"
                formControlName="companyName"
                value=""
              />
            </div>
            <div class="divColoumnSpan tagColumn">{{'CustomerSearch_CompanyAddress' | translate}}</div>
            <div class="col">
              <input
                type="text"
                class="form-control widgetTextBoxMedium"
                name="companyAddress"
                formControlName="companyAddress"
                value=""
              />
            </div>
          </div>

          <div class="row g-3 inp mb-2">
            <div class="divColoumnSpan tagColumn">{{'select_ru_popup_country' | translate}}</div>
            <div class="col">
            <select
                id="inputCountry"
                class="form-select form-control widgetTextBoxMedium"
                formControlName="country"
                name="countryName"
              >
                <option value="" selected>{{'select_ru_popup_choose_country' | translate}}</option>
                <option *ngFor="let a of getcustomerSearchFiltersData" value="{{a.code}}">
                  {{ a.name }}
                </option>
            </select>
            </div>

            <div class="divColoumnSpan tagColumn">{{'select_ru_popup_city' | translate}}</div>
            <div class="col">
              <input
                type="text"
                class="form-control widgetTextBoxMedium"
                formControlName="city"
                value=""
              />
            </div>
          </div>

          <div class="row g-3 inp mb-2">
            <div class="divColoumnSpan tagColumn">{{'CustomerSearch_GlobalHqGuid' | translate}}</div>
            <div class="col">
              <input
                type="text"
                class="form-control widgetTextBoxMedium"
                formControlName="globalHQGuid"
                value=""
              />
            </div>

            <div class="divColoumnSpan tagColumn site">{{'CustomerSearch_SiteID' | translate}}</div>
            <div class="col d-flex">
              <input
                type="text"
                class="widgetTextBoxVerySmall"
                readonly="true"
                formControlName="siteID"
                value=""
              />
              <div class="ms-3 text-end">
                <!-- href="https://ib.abb.com/pages/index.php"
                  target="_blank" -->
                <a style="text-decoration: none;text-decoration:none;
                float:right;text-align:right !important;">
                  <button type="button" class="widgetButtoMediumReset" value="select" (click)="siteIdSelect()">
                    {{'ButtonSelect' | translate}}
                  </button>
                </a>
              </div>
            </div>
          </div>          

          <div class="row g-3 inp mb-0">
            <div class="divColoumnSpan tagColumn">{{'CustomerSearch_CompanyGUID' | translate}}</div>
            <div class="col">
              <input type="text"
                     class="form-control widgetTextBoxMedium"
                     formControlName="businessPartnerGUID"
                     value="" />
            </div>
            <!--US - 7559: Customer Selection -External | Show Customer - Type accordingly - Implementation   -- to check if customer type is external or internal   --added filter dropdown customer type to check for customer is external or internal-->
            <div class="divColoumnSpan tagColumn">Customer type:</div>
            <div class="col">
              <select id="inputCusttype" class="form-select form-control widgetTextBoxMedium"
                      formControlName="custtype" name="custtype">
                <option value="" selected>Both</option>
                <option value="true">Internal</option>
                <option value="false">External</option>
              </select>
            </div>
          </div>
        
          <div class="d-grid gap-2 d-md-flex justify-content-md-end mb-0 me-0 removebtnspace">
            <button type="button" class="widgetButtoMediumView" (click)="dataView()" [ngClass]="
                          this.companyDetailsForm.controls['companyName'].value !== '' ||
                          this.companyDetailsForm.controls['companyAddress'].value !== '' ||
                          this.companyDetailsForm.controls['country'].value !== '' ||
                          this.companyDetailsForm.controls['city'].value !== '' ||
                          this.companyDetailsForm.controls['globalHQGuid'].value !== '' ||
                          this.companyDetailsForm.controls['siteID'].value !== '' || 
                          this.companyDetailsForm.controls['businessPartnerGUID'].value !== ''? '': 'grayButton'"                        


                          [disabled]="this.companyDetailsForm.controls['companyName'].value === '' &&
                          this.companyDetailsForm.controls['companyAddress'].value === '' &&
                          this.companyDetailsForm.controls['country'].value === '' &&
                          this.companyDetailsForm.controls['city'].value === '' &&
                          this.companyDetailsForm.controls['globalHQGuid'].value === '' &&
                          this.companyDetailsForm.controls['siteID'].value === '' &&
                          this.companyDetailsForm.controls['businessPartnerGUID'].value === ''">
              {{'ButtonSearch' | translate}}
            </button>

            <!-- <span class="hSpaceTiny"></span> -->
            <button class="input widgetButtoMediumReset" type="button" (click)="reset()">
              {{'ButtonReset' | translate}}
            </button>
          </div>
        </div>
      </form>
    </div>

<!-- Table -->
    <div class="tablediv">
      <h5 *ngIf="recentlySelectedFlag" class="mt-2">{{'SearchForm_CustomerRecentlySelected' | translate}}</h5>
      <div class="tableMargin">
        <div class="card">
          <p-table
            [value]="filteredCompanyDetails"
            [lazy]="true" [totalRecords]="totalRecords" 
            [loading]="loading" 
            (onLazyLoad)="loadMoreResolvingData($event)"
            selectionMode="single"
            [paginator]="true"
            [rows]="rows"
            [showCurrentPageReport]="true"
            [(first)]="first"
            [tableStyle]
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
            styleClass="p-datatable-gridlines"
          >
            <ng-template
              pTemplate="header"
              styleClass="table table-bordered table-striped table-hove"
            >
            <tr>
              <th pSortableColumn="guid" style="width: 25%">
                GUID<p-sortIcon field="guid"></p-sortIcon>
              </th>
              <th pSortableColumn="companyFullName" style="width: 25%">
                FullName<p-sortIcon field="companyFullName"></p-sortIcon>
              </th>
              <th pSortableColumn="companyShortName" style="width: 25%">
                ShortName<p-sortIcon field="companyShortName"></p-sortIcon>
              </th>
              <th pSortableColumn="address" style="width: 25%">
                Address<p-sortIcon field="address"></p-sortIcon>
              </th>
              <th pSortableColumn="customerType" style="width: 25%">
                Customer Type<p-sortIcon field="customerType"></p-sortIcon>
              </th>
            </tr>
          </ng-template>

          <ng-template pTemplate="body" let-filter let-rowIndex="rowIndex">
            <tr
              [ngClass]="{ selectedRowIndex: rowIndex == selectedIndex }"
              (click)="selectedRow(rowIndex, filter)"
            >
              <td>{{ filter?.guid }}</td>
              <td>{{ filter?.companyFullName }}</td>
              <td>{{ filter?.companyShortName }}</td>
              <td>{{ filter?.address }}</td>
              <td>{{ filter?.customerType }}</td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage" let-columns>
            <tr>
              <td [attr.colspan]="5">
                No records found
              </td>
            </tr>
          </ng-template>

            <ng-template pTemplate="paginatorright">
              <p-button
                type="button"
                icon="pi pi-refresh"
                (click)="refresh()"
                styleClass="p-button-text"
              ></p-button>
            </ng-template>
          </p-table>

          <!-- </ng-template> -->
        </div>

        <div class="d-grid gap-2 d-md-flex justify-content-md-end mt-3 mb-3 removebtnspace">
          <button class="widgetButtonLargeSelectClose"
            [ngClass]="
              selectedIndex !== -1
                ? ''
                : 'grayButton'"
              [disabled]="selectedIndex === -1"
            type="button"
            (click)="selectAndClose()"
          >
            {{ "select_ru_popup_btn_select" | translate }}
          </button>
          <button
            class="input widgetButtonLarge"
            type="button"
            (click)="onClose()"
          >
            {{ "select_ru_popup_btn_close" | translate }}
          </button>
        </div>
      </div>
    </div>
    <!-- </div> -->
  </div>
