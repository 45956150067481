import { Component, OnInit } from '@angular/core';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';

@Component({
  selector: 'app-hub-posearch',
  templateUrl: './hub-posearch.component.html',
  styleUrls: ['./hub-posearch.component.css']
})
export class HubPOSearchComponent implements OnInit {

  constructor(private breadcrumbService: BreadcrumbService) { }

  ngOnInit(): void {
    this.breadcrumbService.menuItems= [
      { label: 'HUB PROCESS OWNER SEARCH'}
    ]
  }

}
