import { Component, OnInit } from '@angular/core';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { SessionStorageServiceService } from 'src/app/services/session-storage-service.service';
import { ResolvingUnitServiceService } from 'src/app/services/resolving-unit-service.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { MdServiceService } from 'src/app/services/md-service.service';
import { MdRegionService } from 'src/app/services/md-region.service';
import { ProcessOwnersAuthService } from '../process-owners-auth.service';
import { ToasterserviceService } from 'src/app/services/toasterservice.service';
import { ProcessOwnersService } from '../process-owners.service';
import { UsersService } from 'src/app/services/users/users.service';
import { AdminProcessOwnerService } from 'src/app/services/admin-process-owner.service';

@Component({
  selector: 'app-add-person',
  templateUrl: './add-person.component.html',
  styleUrls: ['./add-person.component.css']
})
export class POAddPersonComponent implements OnInit {

  addPersonForm!: FormGroup;

  selected: any = {
    division: 'PG',
    bu: '',
    pg: null,
    country: null,
    region: '',
    employee: null
  };

  canManageSelectedScope: boolean = false;
  isScopeEmpty: boolean = false;

  divisions: any;
  bulist: any;
  pglist: any;
  regions: any;
  countries: any;

  divisionsCopy: any;
  bulistCopy: any;
  pglistCopy: any;
  regionsCopy: any;
  countriesCopy: any;

  filteredOwner: any;


  constructor(private readonly dialogRef: DynamicDialogRef,
    private mdData: MdServiceService,
    private mdRegionService: MdRegionService,
    private poAuthService:ProcessOwnersAuthService,
    private toaster:ToasterserviceService,
    private processOwnersService: ProcessOwnersService,
    private fb: FormBuilder,
    private usersService: UsersService,
    private AdminProcessOwnerService:AdminProcessOwnerService
    ) {


     }

  ngOnInit(): void {

    //Calling Dropdown Values

      this.mdData.divisions().then((r)=> { this.divisions = r;
        this.divisionsCopy = this.divisions;

        this.addPersonForm.patchValue({
          business: 'PG'
        })

      });

      this.mdData.bu().then((r)=> { this.bulist = r;
        this.bulist.map((bu)=>{
          bu.labelName = '(' + bu?.id + ') - ' + bu?.name;
        });
        this.bulistCopy = this.bulist
      });

      this.mdData.pg().then((r)=>{ this.pglist = r;
        this.pglist.map((pg)=>{
          pg.labelName = '(' + pg?.id + ') - ' + pg?.name;
        });
        this.pglistCopy = this.pglist
      });

      this.mdRegionService.regions().then((r)=> { this.regions = r;
        this.regions.map((reg)=>{
          reg.lableName = '(' + reg?.id + ') - ' + reg?.name;
        })
        this.regionsCopy = this.regions
      });

      this.mdRegionService.countries().then((r)=> { this.countries = r;
        this.countries.map((country)=>{
          country.lableName = '(' + country?.id + ') - ' + country?.name + ' ' + country?.rCode;
        })
        this.countriesCopy = this.countries;
      });
    // Form Creation

    this.addPersonForm = this.fb.group({
      'business': [''],
      'country': [''],
      'bu': [''],
      'pg': [''],
      'region': [''],
      'employee': ['', Validators.required],
      'userGEID':['']

    });

    this.addPersonForm.get('employee').valueChanges.subscribe((status) => {
      if(status) {
        this.addPersonForm.patchValue({
          userGEID: status.id
        });
      }
    })

  }

  newPersonScope() {
    return {
      div: (this.selected.division !== '' && this.selected.bu === '' && this.selected.pg === '') ? this.selected.division : null,
      bu: (this.selected.bu !== '' && (this.selected.pg === '' || this.selected.pg === null)) ? this.selected.bu : null,
      pg: this.selected.pg ? this.selected.pg : null,
      region: (this.selected.region !== '' && this.selected.country === null) ? this.selected.region : null,
      country: this.selected.country ? this.selected.country : null
    }
  }

  regChanged = function () {
    this.selected.country = null;
  };

  buChanged = function () {
    this.selected.pg = null;

  };

  divChanged = function () {

    this.selected.pg = null;
    this.selected.bu = '';
  }

  cancel() {
    this.dialogRef.close();
  };

  checkBlank(content) {
    if (content == undefined || content == null) {
      return null;
    } else {
      return content;
    }
  }

  ok() {

    let formatData =
    {
      "geid": this.checkBlank(this.addPersonForm.controls['userGEID'].value),
      "div": this.checkBlank(this.addPersonForm.controls['business'].value),
      "bu": this.checkBlank(this.addPersonForm.controls['bu'].value),
      "pg": this.checkBlank(this.addPersonForm.controls['pg'].value),
      "region": this.checkBlank(this.addPersonForm.controls['region'].value),
      "country": this.checkBlank(this.addPersonForm.controls['country'].value)
    }

    if(formatData){
      this.selected.division = formatData.div
      this.selected.bu = formatData.bu
      this.selected.pg = formatData.pg
      this.selected.country = formatData.country
      this.selected.region = formatData.region
      this.selected.employee = formatData.geid
    }

    if (!this.selected.employee || this.isScopeEmpty) {
        this.toaster.showError('Cannot add Process Owner. Invalid data');
        return;
    }

    if (this.poAuthService.canManageProcessOwner(this.newPersonScope())) {
        this.processOwnersService.addProcessOwner(this.selected.employee, this.newPersonScope()).then((info)=> {
          this.dialogRef?.close({receivedValue: 'ok'});
          return
        });
    } else {
      this.toaster.showError('Cannot add this Process Owner. Insufficient permissions.');
        return;
    }
    this.dialogRef?.close({receivedValue: 'ok'});
};

filterOwner(query) {
  if (query.length > 1) {
    this.AdminProcessOwnerService.getFilteredUser(query).subscribe((res) => {
      this.filteredOwner = res;
      this.filteredOwner.map((data) => {
        data.name = data.name + '<' + data.email + '>';
      })
    })
  }
}

onChangeBusiness(event){
  this.addPersonForm.controls['bu'].reset();
  this.addPersonForm.controls['pg'].reset();
  this.pglist = this.pglistCopy;
  this.bulist = this.bulistCopy;
}

onChangeBu(event){
  this.addPersonForm.controls['pg'].reset();

  let filteredData: any = [];
  let query = event?.value;
  if(query != null){
    this.pglistCopy?.filter((obj)=>{
        if(obj?.buCode == query)
            filteredData.push(obj)
    })
    this.pglist = filteredData
  }
  else{
    this.pglist = this.pglistCopy;
  }
}

onChangeRegion(event){
  this.addPersonForm.controls['country'].reset();

  let filteredData: any = [];
  let query = event?.value;
  if(query != null){
    this.countriesCopy?.filter((obj)=>{
        if(obj?.rCode == query)
            filteredData.push(obj)
    })
    this.countries = filteredData
  }
  else{
    this.countries = this.countriesCopy;
  }
}

}

