import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { RuAuthService } from '../components/admin/resolving-unit/ru-auth.service';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class AdminService {
  x = `${environment.API_URL}/adminData`;

  constructor(private http: HttpClient, private ruAuthService: RuAuthService) { }

  getAdminList(): Observable<any> {
    return this.http.get(`http://localhost:3000/adminData`);
  }

  getFilteredUser(passedData): Observable<any> {
    return this.http.get(environment.azure.baseUrl + 'api/v1/employee/findpagebywildcard?wildcard=' + passedData.wildcard + '&onlyActive=' + passedData.onlyActive + '&page=' + passedData.page + '&size=' + passedData.size);

  }

  getRegions(): Observable<any> {

    return this.http.get(`${environment.azure.baseUrl}api/v1/regions`);

  }


  getSearchResolvingUnitDetails(data) {
    return this.http.post(`${environment.azure.baseUrl}api/v1/manageru/searchchecklimit`, data);
  }

  getSearchResolvingUnitDetails1(data){
    return new Promise<any>((resolve, reject)=>{
      return this.http.post(`${environment.azure.baseUrl}api/v1/manageru/searchchecklimit`, data).subscribe((response: any)=>{
        let geids : any = [];
        response.ruInfo.forEach(item => {
          item.scope = { div: item.divCode, bu: item.buCode, pg: item.pgCode, country: (item.address) ? item.address.countryCode : null, isGlobal: item.isGlobal }
          item.canManage = this.ruAuthService.canManageRu(item.scope, item.ruProcessOwnerGeids);
          item.isSelected = false;

          item.ruProcessOwnerGeids.forEach(geid => {
            geids.push(geid);
          });

          item.coordinatorGeids.forEach(geid => {
            geids.push(geid);
          });
        });

        this.getRupersonsinfo(_.uniq(geids)).subscribe((ruPersonsInfo:any)=>{
          response.ruInfo.forEach(item => {
            let ruProcessOwners :any = [];

            item.ruProcessOwnerGeids.forEach(ruPoGeid => {
              let ruProcessOwner = ruPersonsInfo.find(ruP=> ruPoGeid == ruP.id);
              if (item.primaryRuProcessOwnerGeid && ruProcessOwner){
                if (item.primaryRuProcessOwnerGeid == ruProcessOwner.id) {
                  ruProcessOwner.isPrimaryRuProcessOwner = true;
                }
              }

              ruProcessOwners.push(ruProcessOwner);
            });

            let coordinators : any = [];

            item.coordinatorGeids.forEach(coordGeid => {
              let coordinator = ruPersonsInfo.find(ruP=> coordGeid == ruP.id);
              if (item.primaryCoordinatorGeid && coordinator) {
                if (item.primaryCoordinatorGeid == coordinator.id) {
                    coordinator.isPrimaryCoordinator = true;
                }
              }

              coordinators.push(coordinator);
            });

            item.ruProcessOwners = ruProcessOwners;
            item.coordinators = coordinators;
          });

          resolve(response);
        })
      },
      (error)=>{
        reject(error);
      })
    })
  }

  getprocessownersDetails(data) {
    return this.http.post(`${environment.azure.baseUrl}api/v1/manageru/findprocessowners`, data);
  }

  getRupersonsinfo(data) {
    return this.http.post(`${environment.azure.baseUrl}api/v1/manageru/rupersonsinfo`, data);
  }

  getEmployeeInfo(geids) {
    let params = new HttpParams();
    let ab = '';
    geids.forEach((element, index, array) => {
      if (index == 0) {
        ab += '' + element
      }
      else {
        ab += ',' + element
      }
    })
    params = params.append('geids', ab);
    return this.http.post(`${environment.azure.baseUrl}api/v1/dashboard/employeeinfo`, { geids: ab });
  }


  deleteRupersonsinfo(data) {
    return this.http.post(`${environment.azure.baseUrl}api/v1/manageru/deleteruperson`, data);
  }


  addprimaryRuperson(data) {
    return this.http.put(`${environment.azure.baseUrl}api/v1/manageru/setPrimaryPerson`, data);
  }


  getRupersons(data) {
    return this.http.post(`${environment.azure.baseUrl}api/v1/manageru/addrupersons`, data);
  }


  getExportHubs(data,cultureName) {


    return this.http.post(`${environment.azure.baseUrl}api/v1/manageru/download?culture=${cultureName}`,data, {
      responseType: 'arraybuffer'
    });
  }

getcountry(regioncode): Observable<any> {

    return this.http.get(`${environment.azure.baseUrl}api/v1/regions/${regioncode}/countries`);

  }


  getUserpopuphovertDetails(data) {
    return this.http.post(`${environment.azure.baseUrl}api/v1//manageru/rusbyuser`, data);
  }

}
