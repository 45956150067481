import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { DatePipe } from '@angular/common';

// Module Imports
import { AppRoutingModule } from './app-routing.module';
import { HttpClient, HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { AccordionModule } from "primeng/accordion";

//Components Imports
import { AppComponent } from './app.component';
import { ccrpnavbarComponent } from './reusables/ccrpnavbar/ccrpnavbar.component';
import { HelpdeskComponent } from './components/links/helpdesk/helpdesk.component';
import { AdminhomeComponent } from './components/admin/adminhome/adminhome.component';
import { MyIssueDashboardComponent } from './components/my-issue/my-issue-dashboard/my-issue-dashboard.component';
import { IssuedetailsComponent } from './components/my-issue/issuedetails/issuedetails.component';
import { CustomdatePipe } from './reusables/pipes/customdate.pipe';
import { DaysagoPipe } from './reusables/pipes/daysago.pipe';
import { DaysleftPipe } from './reusables/pipes/daysleft.pipe';
import { MyissuesprogressbarComponent } from './reusables/myissuesprogressbar/myissuesprogressbar.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { EmployeeprofileComponent } from './reusables/employeeprofile/employeeprofile.component';
import { ArrayFirstElementObjPipe } from './reusables/pipes/array-first-element-obj.pipe';
import { FormGroupDirective, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActionFiltersSectionComponent } from './components/my-issue/action-filters-section/action-filters-section.component';
import { ActionfilteritemComponent } from './components/my-issue/actionfilteritem/actionfilteritem.component';
import { StatusfilterssectionComponent } from './components/my-issue/status-filters-section/statusfilterssection.component';
import { StatusfilteritemComponent } from './components/my-issue/statusfilteritem/statusfilteritem.component';
import { RolesFilterSectionComponent } from './components/my-issue/roles-filter-section/roles-filter-section.component';
import { RolefilteritemComponent } from './components/my-issue/rolefilteritem/rolefilteritem.component';
import { ClassFiltersSectionComponent } from './components/my-issue/class-filters-section/class-filters-section.component';
import { ClassfilteritemComponent } from './components/my-issue/classfilteritem/classfilteritem.component';
import { ComplaintsSectionNavBarComponent } from './components/my-issue/complaints-section-nav-bar/complaints-section-nav-bar.component';
import { PageNotFoundComponent } from './reusables/page-not-found/page-not-found.component';
import { MyissueDashboardSettingsComponent } from './components/my-issue/myissue-dashboard-settings/myissue-dashboard-settings.component';
import { DashboardCustInfoItemComponent } from './components/my-issue/dashboard-cust-info-item/dashboard-cust-info-item.component';
import { MyissueActionPlanDashboardComponent } from './components/my-issue/myissue-action-plan-dashboard/myissue-action-plan-dashboard.component';
import { MyActionsDashboardListComponent } from './components/my-issue/my-actions-dashboard-list/my-actions-dashboard-list.component';
import { MyactionsDashboardStatusFilterComponent } from './components/my-issue/myactions-dashboard-status-filter/myactions-dashboard-status-filter.component';
import { MyactionsDashboardRoleFilterComponent } from './components/my-issue/myactions-dashboard-role-filter/myactions-dashboard-role-filter.component';
import { UserInfoDetailsComponent } from './reusables/user-info-details/user-info-details/user-info-details.component';
import { ResolvingUnitSelectPopUpComponent } from './components/new-issue/resolving-unit-select-pop-up/resolving-unit-select-pop-up.component';
import { IssueClosedComponent } from './components/new-issue/issue-closed/issue-closed.component';
import { SendEmailComponent } from './components/new-issue/send-email-issue-closed/send-email.component';
import { EffectivenessAndSustainabilityComponent } from './components/new-issue/effectiveness-and-sustainability/effectiveness-and-sustainability.component';
import { ReopenIssuePopupComponent } from './components/new-issue/reopen-issue-popup/reopen-issue-popup.component';

//pipes
import { FilterPipe } from './reusables/pipes/filter.pipe';
import { OrderPipe } from './reusables/pipes/order.pipe';

//import {FileUploadModule} from 'primeng/fileupload';
//import { ButtonModule } from 'primeng/button';
import { AccessRestrictionConfidentialComponent } from './components/new-issue/access-restriction-confidential/access-restriction-confidential.component';
import { ChooseRolePopUpComponent } from './components/new-issue/choose-role-pop-up/choose-role-pop-up.component';

import { DropdownComponentComponent } from './reusables/dropdown-component/dropdown-component.component';
import { ComplaintNotFoundComponent } from './components/new-issue/complaint-not-found/complaint-not-found.component';

import { IssueCaptureDescriptionComponent } from './components/new-issue/issue-capture-description/issue-capture-description.component';
import { OriginalRatingConfigComponent } from './components/new-issue/original-rating-config/original-rating-config.component';
import { BrowserCacheLocation, InteractionType, IPublicClientApplication, PublicClientApplication } from '@azure/msal-browser';
import { environment } from 'src/environments/environment';
import { MsalBroadcastService, MsalGuard, MsalGuardConfiguration, MsalRedirectComponent, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MsalInterceptor, MsalModule } from '@azure/msal-angular';
import { NewIssueHomeComponent } from './components/new-issue/new-issue-home/new-issue-home.component';
import { ShortenComplaintSubjectPipe } from './reusables/pipes/shorten-complaint-subject.pipe';
import { NgxPaginationModule } from 'ngx-pagination';
import { ComplaintsSectionComponent } from './components/my-issue/complaints-section/complaints-section.component';
import { BreadcrumbComponent } from './reusables/breadcrumb/breadcrumb.component';
//import { BreadcrumbModule } from 'angular-crumbs';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DropdownModule } from 'primeng/dropdown';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslationHttpLoader } from './translation-http-loader';
import { UsersService } from './services/users/users.service';
import { IssueDescriptionComponent } from './components/new-issue/issue-description/issue-description.component';
import { BalIDSelectionPopUpComponent } from './components/new-issue/bal-idselection-pop-up/bal-idselection-pop-up.component';
import { ProcessStepPopupComponent } from './components/new-issue/process-step-popup/process-step-popup.component';
import { TreeModule } from 'primeng/tree';
import { CalendarModule } from 'primeng/calendar';
import { FileUploadModule } from 'primeng/fileupload';
import { ButtonModule } from 'primeng/button';
import { TooltipModule } from 'primeng/tooltip';
import { TreeSelectModule } from 'primeng/treeselect';
import { Dialog, DialogModule } from 'primeng/dialog';
import { TableModule } from 'primeng/table';
import { ChipsModule } from 'primeng/chips';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { FieldsetModule } from 'primeng/fieldset';
import { IssueCaptureComponent } from './components/new-issue/issue-capture/issue-capture.component';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { AdditionalSubjectComponent } from './components/new-issue/additional-subject/additional-subject.component';
import { ToastModule } from 'primeng/toast';
import { RippleModule } from 'primeng/ripple';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { LoaderComponent } from './reusables/loader/loader.component';
import { LoaderInterceptor } from './services/loader/loader.interceptor';
import { DialogService, DynamicDialogConfig, DynamicDialogModule, DynamicDialogRef } from 'primeng/dynamicdialog';
import { IssueAssignComponent } from './components/new-issue/issue-assign/issue-assign.component';
import { IssueSelectionComponent } from './components/new-issue/issue-selection/issue-selection.component';
import { ResolvingUnitServiceService } from './services/resolving-unit-service.service';
import { ModalDirective } from './reusables/directives/modal/modal.directive';
import { ContactpopupDirective } from './reusables/directives/contactpopup/contactpopup.directive';
import { CustomDatePipePipe } from './reusables/custom-date-pipe/custom-date-pipe.pipe';
import { CommonModule } from '@angular/common';
import { PopupPlaceHolderDirective } from './reusables/directives/popup-place-holder/popup-place-holder.directive';
import { ToolTipDirective } from './reusables/directives/tool-tip/tool-tip.directive';
import { ProductPopupComponent } from './components/new-issue/product-popup/product-popup.component';
import { ProductService } from './services/product.service';
import { IssueSelectionService } from './services/issue-selection.service';
import { ProMISProjectSelectPopUpComponent } from './components/new-issue/pro-misproject-select-pop-up/pro-misproject-select-pop-up.component';
import { SupplierSelectionPopupComponent } from './components/new-issue/supplier-selection-popup/supplier-selection-popup.component';
import { ProjectSelectionServiceService } from './services/project-selection-service.service';
import { SupplierSelectionServiceService } from './services/supplier-selection-service.service';
import { FailureModePopupComponent } from './components/new-issue/failure-mode-popup/failure-mode-popup.component';
import { FailuremodeService } from './services/failuremode.service';
import { CustomerNameSelectionPopupComponent } from './components/new-issue/customer-name-selection-popup/customer-name-selection-popup.component';
import { CustomerContactSelectionPopupComponent } from './components/new-issue/customer-contact-selection-popup/customer-contact-selection-popup.component';
import { EmailService } from './services/email.service';
import { SubmitConfirmationPopupComponent } from './components/new-issue/submit-confirmation-popup/submit-confirmation-popup.component';
import { MessagesModule } from 'primeng/messages';
import { MessageService } from 'primeng/api';
import { EmailServiceTempService } from './services/email-service-temp.service';
import { ComplaintActionLogComponent } from './components/new-issue/complaint-action-log/complaint-action-log.component';
import { ManualEntryComponent } from './components/new-issue/manual-entry/manual-entry.component';
import { ActionDialogPopupComponent } from './components/new-issue/action-dialog-popup/action-dialog-popup.component';
import { IssueResolutionComponent } from './components/new-issue/issue-resolution/issue-resolution.component';
import { IssueResolutionLesssonLearnedComponent } from './components/new-issue/issue-resolution-lessson-learned/issue-resolution-lessson-learned.component';
import { PGRCACategoryPopUpComponent } from './components/new-issue/pgrcacategory-pop-up/pgrcacategory-pop-up.component';
import { COPQCategoryPopUpComponent } from './components/new-issue/copqcategory-pop-up/copqcategory-pop-up.component';
import { CopqCategoryService } from './services/copq-category.service';
import { PgrcacategoryServiceService } from './services/pgrcacategory-service.service';
import { IssueResolutionServiceService } from './services/issue-resolution-service.service';
import { MultiSelectModule } from 'primeng/multiselect';
import { SendEmailIssueAssignComponent } from './components/new-issue/send-email-issue-assign/send-email-issue-assign.component';
import { SendEmailIssueAssignService } from './services/send-email-issue-assign.service';
import { EditorModule } from 'primeng/editor';
import { ActionPlanLogComponent } from './components/new-issue/actionplan/action-plan-log/action-plan-log.component';
import { DeleteCardModalComponent } from './components/new-issue/actionplan/delete-card-modal/delete-card-modal.component';
import { RejectCardModalComponent } from './components/new-issue/actionplan/reject-card-modal/reject-card-modal.component';
import { ReopenCardModalComponent } from './components/new-issue/actionplan/reopen-card-modal/reopen-card-modal.component';
import { ActionPlanCardComponent } from './components/new-issue/actionplan/action-plan-card/action-plan-card.component';
import { OrderByActionLogPipe } from './reusables/pipes/order-by-action-log.pipe';
import { RevertToNotAssignEmailComponent } from './components/new-issue/revert-to-not-assign-email/revert-to-not-assign-email.component';
import { RevertToNotAssignEmailServiceService } from './services/revert-to-not-assign-email-service.service';
import { ActionBoardComponent } from './components/new-issue/actionplan/action-board/action-board.component';
import { DragDropModule } from 'primeng/dragdrop';
import { EmployeeListService } from './services/employee-list.service';
import { SharedService } from './services/shared.service';
import { IssueClosureConfirmationDialogComponent } from './components/new-issue/issue-closure-confirmation-dialog/issue-closure-confirmation-dialog.component';
import { ReportsMenuComponent } from './components/reports/reports-menu/reports-menu.component';
import { AllInOneComponent } from './components/reports/all-in-one/all-in-one.component';
import { WeeklyReportComponent } from './components/reports/weekly-report/weekly-report.component';
import { MenubarModule } from 'primeng/menubar';
import { SendEmailSiteActionsComponent } from './components/new-issue/send-email-site-actions/send-email-site-actions.component';
import { ActionPlanCardEditComponent } from './components/new-issue/actionplan/action-plan-card-edit/action-plan-card-edit.component';
import { DefectPopupService } from './services/defect-popup.service';
import { RadiobuttonoptionsComponent } from './components/reports/radiobuttonoptions/radiobuttonoptions.component';
import { AllInOneReportsDataOnCCRPNumberComponent } from './components/reports/all-in-one-reports-data-on-ccrpnumber/all-in-one-reports-data-on-ccrpnumber.component';
import { DropZoneTextAreaComponent } from './reusables/drop-zone-text-area/drop-zone-text-area.component';
import { OrderByCardsPipe } from './reusables/pipes/order-by-cards.pipe';
import { AppInsightsService } from './services/app-insights.service';
import { ErrorInterceptor } from './services/error.service';
import { APIParameterInterceptor } from './services/ApiHeaderInterceptor.service';
import { AzureADUserStatusService } from './services/azure-aduser-status.service';
import { ReopenIssueService } from './services/reopenIssue/reopen-issue.service';
import { UserIdleModule } from 'angular-user-idle';
import { ExceptionsService } from './services/exceptions/exceptions.service'
import { UniqueActionNameWithinBoardService } from './services/actionplan/unique-action-name-within-board.service';
import { TargetDateHistoryComponent } from './components/new-issue/target-date-history/target-date-history.component';
import { TimelineModule } from 'primeng/timeline';
import { NgxSpinnerModule } from "ngx-spinner";
import { DeleteDraftComponent } from './components/new-issue/delete-draft/delete-draft.component';
import { AdminHubsService } from './services/admin-hubs.service';
import { GlobalRUComponent } from './components/admin/global-ru/global-ru.component';
import { AdminGobalRuService } from './services/admin-gobal-ru.service';
import { GlobalSearchComponent } from './components/search/GlobalSearch/global-search/global-search.component';
import { GlobalsearchDetailsComponent } from './components/search/GlobalSearch/globalsearch-details/globalsearch-details.component';
import { InfiniteScrollModule } from "ngx-infinite-scroll";

import { SaveSettingsPopupComponent } from './components/reports/save-settings-popup/save-settings-popup.component';
import { CheckboxModule } from 'primeng/checkbox';
import { FilesizePipe } from './reusables/pipes/filesize.pipe';
import { SumFloatPipe } from './reusables/pipes/sum-float.pipe';
import { CustomerContactsComponent } from './components/admin/admin-customer-contacts/customer-contacts/customer-contacts.component';
import { CardModule } from 'primeng/card';
//import { AdvancedCustomerSelectionPopupComponent } from './components/admin/advanced-customer-selection-popup/advanced-customer-selection-popup.component';
//import { HubsComponent } from './components/admin/admin-hubs/hubs/hubs.component';
import { AdminCustomerContactsService } from './services/admin-customer-contacts.service';
import { AddContactPopupComponent } from './components/admin/admin-customer-contacts/add-contact-popup/add-contact-popup.component';
import {InputTextModule} from 'primeng/inputtext';
import { DeactivateContactPopupComponent } from './components/admin/admin-customer-contacts/deactivate-contact-popup/deactivate-contact-popup.component';
// import { ActiveEmployeeComponent } from './components/admin/active-employee/active-employee.component';
import { ActiveEmployeeService } from './services/active-employee.service';
import { StatusPipe } from './reusables/pipes/status.pipe';
import { ActiveEmployeesComponent } from './components/admin/active-employee/active-employees.component';
import { KeyValuePipe } from './reusables/pipes/key-value.pipe';
import { AdminEscalationComponent } from './components/admin/escalation/admin-escalation/admin-escalation.component';
import { AdminEscalationTimeComponent } from './components/admin/escalation/admin-escalation-time/admin-escalation-time.component';
import { AdminInprogressEscalationtimesComponent } from './components/admin/escalation/admin-inprogress-escalationtimes/admin-inprogress-escalationtimes.component';
import { PGRCACategoryComponent } from './components/admin/pgrcaCategoryModule/pgrcacategory/pgrcacategory.component';
import { PgCategoryPopUpComponent } from './components/admin/pgrcaCategoryModule/pg-category-pop-up/pg-category-pop-up.component';
import { PgrcaCatAddPopUpComponent } from './components/admin/pgrcaCategoryModule/pgrca-cat-add-pop-up/pgrca-cat-add-pop-up.component';
import { PgrcaCatEditPopUpComponent } from './components/admin/pgrcaCategoryModule/pgrca-cat-edit-pop-up/pgrca-cat-edit-pop-up.component';
import { PgrcaCatDeactivatePopUpComponent } from './components/admin/pgrcaCategoryModule/pgrca-cat-deactivate-pop-up/pgrca-cat-deactivate-pop-up.component';

import { EditContactComponent } from './components/admin/admin-customer-contacts/edit-contact/edit-contact.component';
import {PaginatorModule} from 'primeng/paginator';
import { AdminSystemuserComponent } from './components/admin/systemusers/admin-systemuser/admin-systemuser.component';
import { AddSystemuserRoleComponent } from './components/admin/systemusers/add-systemuser-role/add-systemuser-role.component';
import { DeleteSystemuserRoleComponent } from './components/admin/systemusers/delete-systemuser-role/delete-systemuser-role.component';
import { SystemuserItemdetailsComponent } from './components/admin/systemusers/systemuser-itemdetails/systemuser-itemdetails.component';
import { PawelPipe } from './components/admin/systemusers/pawel.pipe';
// import { TranslationsComponent } from './components/admin/translations/translations.component';
// import { TranslationSettingsComponent } from './components/admin/translations/translation-settings/translation-settings.component';
import { OrderGlobalRUPipe } from './reusables/pipes/order-global-ru.pipe';
import { AdminProcessStepsComponent } from './components/admin/processSteps/admin-process-steps/admin-process-steps.component';
import { AdminEditProcessStepsModalComponent } from './components/admin/processSteps/admin-edit-process-steps-modal/admin-edit-process-steps-modal.component';
import { AdminProcessStepsDeactivateModalComponent } from './components/admin/processSteps/admin-process-steps-deactivate-modal/admin-process-steps-deactivate-modal.component';
import { AdminProcessownersComponent } from './components/admin/processOwners/admin-processowners/admin-processowners.component';
import { PoSettingsComponent } from './components/admin/processOwners/po-settings/po-settings.component';
import { POAddPersonComponent } from './components/admin/processOwners/po-addperson/add-person.component';
import { SearchCriteriaPopupComponent } from './components/admin/processOwners/search-criteria-popup/search-criteria-popup.component';
import { PoInfosectionComponent } from './components/admin/processOwners/po-infosection/po-infosection.component';
import { PoItemunitComponent } from './components/admin/processOwners/po-itemunit/po-itemunit.component';
import { PoItemlocationComponent } from './components/admin/processOwners/po-itemlocation/po-itemlocation.component';
import { PoDetailsComponent } from './components/admin/processOwners/po-details/po-details.component';
import { ProcessStepItemComponent } from './components/admin/processSteps/process-step-item/process-step-item.component';
import { ProcessStepItemMemberComponent } from './components/admin/processSteps/process-step-item-member/process-step-item-member.component';
import { HubsComponent } from './components/admin/admin-hubs/hubs/hubs.component';
import { HubStructurePopupComponent } from './components/admin/admin-hubs/hub-structure-popup/hub-structure-popup.component';
import { HubDeletePersonPopupComponent } from './components/admin/admin-hubs/hub-delete-person-popup/hub-delete-person-popup.component';
import { HubAddPersonPopupComponent } from './components/admin/admin-hubs/hub-add-person-popup/hub-add-person-popup.component';
import { AdvancedCustomerSelectionPopupComponent } from './components/admin/admin-customer-contacts/advanced-customer-selection-popup/advanced-customer-selection-popup.component';
import { TranslationsComponent } from './components/admin/admin-translations/translations/translations.component';
import { TranslationSettingsComponent } from './components/admin/admin-translations/translation-settings/translation-settings.component';
import { DeleteProcessownerComponent } from './components/admin/processOwners/delete-processowner/delete-processowner.component';
import { ProcessownerSearchComponent } from './components/search/processowner-search/processowner-search.component';
import { PgrcaAdminServiceService } from './components/admin/pgrcaCategoryModule/pgrca-admin-service.service';
import { TranslationResourceItemComponent } from './components/admin/admin-translations/translation-resource-item/translation-resource-item.component';
import { ActionlogentriesInternalComponent } from './reusables/actionlogentries-internal/actionlogentries-internal.component';
import { AdminFailuremodeComponent } from './components/admin/failureMode/admin-failuremode/admin-failuremode.component';
import { FailuremodeTreeitemComponent } from './components/admin/failureMode/failuremode-treeitem/failuremode-treeitem.component';
import { FailuremodeEditmodalComponent } from './components/admin/failureMode/failuremode-editmodal/failuremode-editmodal.component';
import { FailuremodeDeactivatemodalComponent } from './components/admin/failureMode/failuremode-deactivatemodal/failuremode-deactivatemodal.component';
import { ResolvingUnitsComponent } from './components/admin/resolving-unit/resolving-units/resolving-units.component';
import { ResolvingAddPersonPopupComponent } from './components/admin/resolving-unit/resolving-add-person-popup/resolving-add-person-popup.component';
import { ResolvingUnitsDeletepersonComponent } from './components/admin/resolving-unit/resolving-units-deleteperson/resolving-units-deleteperson.component';
import { ResolvingUnitsAddprimaryruinfoComponent } from './components/admin/resolving-unit/resolving-units-addprimaryruinfo/resolving-units-addprimaryruinfo.component';
import { AdminService } from './services/admin.service';
import { FailuremodeTreeitemmebmerComponent } from './components/admin/failureMode/failuremode-treeitemmebmer/failuremode-treeitemmebmer.component';

import { AdminAccountablePersonService } from './services/admin-accountable-person.service';
import { AdminProcessOwnerService } from './services/admin-process-owner.service';
import { AdminAccountableEditPopupComponent } from './components/admin/accountablePerson/admin-accountable-edit-popup/admin-accountable-edit-popup.component';
import { AdminAccountableTreeAddPopupComponent } from './components/admin/accountablePerson/admin-accountable-tree-add-popup/admin-accountable-tree-add-popup.component';
import { AccountablePersonComponent } from './components/admin/accountablePerson/accountable-person/accountable-person.component';
import { TranslationMessageItemComponent } from './components/admin/admin-translations/translation-message-item/translation-message-item.component';
import { TranslationMessageTemplateditComponent } from './components/admin/admin-translations/translation-message-templatedit/translation-message-templatedit.component';
import { FailuremodeEditPopUpComponent } from './components/admin/failureMode/failuremode-edit-pop-up/failuremode-edit-pop-up.component';
import { HubPOSearchComponent } from './components/search/hub-processowner-search/hub-posearch/hub-posearch.component';
import { RupersonSearchComponent } from './components/search/ruperson-search/ruperson-search.component';
import { ConfirmationComponent } from './components/admin/accountablePerson/confirmation/confirmation.component';
import { WorkCenterSelectionPopUpComponent } from './components/new-issue/work-center-selection-pop-up/work-center-selection-pop-up.component';
import { DefectTypePopupComponent } from './components/new-issue/defect-type-popup/defect-type-popup.component';
import { WorkCenterService } from './services/work-center.service';
import { CurrentratingConfirmPopupComponent } from './components/new-issue/currentrating-confirm-popup/currentrating-confirm-popup.component';
import { ViewReportComponent } from './components/reports/view-report/view-report.component';
import { ComplaintHasBeenDeletedExceptionComponent } from './components/new-issue/complaint-has-been-deleted-exception/complaint-has-been-deleted-exception.component';
import { IssueSummaryreportComponent } from './components/new-issue/issue-summaryreport/issue-summaryreport.component';
import { ExportAsModule } from 'ngx-export-as';
import { StatusComponent } from './components/external/status/status.component';
import { SurveyTemplateComponent } from './components/external/survey-template/survey-template.component';
import { SystemTimeoutWarningModalComponent } from './reusables/system-timeout-warning-modal/system-timeout-warning-modal.component';
import { HiConfirmComponent } from './components/external/hi-confirm/hi-confirm.component';
import { TesterLoginComponent } from './reusables/tester-login/tester-login.component';
import { SharedModule } from './shared.module';
import { NumberCommaDirective } from './reusables/pipes/number-comma.directive';
import { HrgtErrorComponent } from './reusables/hrgt-error/hrgt-error.component';
import { MaintenanceErrorComponent } from './reusables/maintenance-error/maintenance-error.component';
import { DefectCausePopupComponent } from './components/new-issue/defect-cause-popup/defect-cause-popup.component';
import { SubscribeComponent } from './components/external/subscribe/subscribe.component';
import { ComplaintLockWarningComponent } from './reusables/complaint-lock-warning/complaint-lock-warning.component';
import { maxLengthConstants } from './reusables/constants/maxlength.constants';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { DownloadEightDComponent } from './reusables/download-eight-d/download-eight-d.component';


//translation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslationHttpLoader(http);
}

@NgModule({ declarations: [
        AppComponent,
        ccrpnavbarComponent,
        HelpdeskComponent,
        AdminhomeComponent,
        // CustomdatePipe,
        //     DaysagoPipe,
        //     DaysleftPipe,
        PageNotFoundComponent,
        // ToolTipDirective,
        UserInfoDetailsComponent,
        FilterPipe,
        OrderPipe,
        AccessRestrictionConfidentialComponent,
        ChooseRolePopUpComponent,
        PopupPlaceHolderDirective,
        DropdownComponentComponent,
        ComplaintNotFoundComponent,
        IssueCaptureDescriptionComponent,
        OriginalRatingConfigComponent,
        NewIssueHomeComponent,
        // ShortenComplaintSubjectPipe,
        BreadcrumbComponent,
        IssueDescriptionComponent,
        BalIDSelectionPopUpComponent,
        ProcessStepPopupComponent,
        IssueCaptureComponent,
        AdditionalSubjectComponent,
        LoaderComponent,
        IssueAssignComponent,
        IssueSelectionComponent,
        ResolvingUnitSelectPopUpComponent,
        ModalDirective,
        ContactpopupDirective,
        // CustomDatePipePipe,
        ProductPopupComponent,
        ResolvingUnitSelectPopUpComponent,
        ProMISProjectSelectPopUpComponent,
        SupplierSelectionPopupComponent,
        FailureModePopupComponent,
        DefectTypePopupComponent,
        CustomerNameSelectionPopupComponent,
        CustomerContactSelectionPopupComponent,
        IssueClosedComponent,
        SendEmailComponent,
        SubmitConfirmationPopupComponent,
        EffectivenessAndSustainabilityComponent,
        ReopenIssuePopupComponent,
        ComplaintActionLogComponent,
        ActionDialogPopupComponent,
        ManualEntryComponent,
        IssueResolutionComponent,
        IssueResolutionLesssonLearnedComponent,
        PGRCACategoryPopUpComponent,
        COPQCategoryPopUpComponent,
        SendEmailIssueAssignComponent,
        ActionPlanLogComponent,
        DeleteCardModalComponent,
        RejectCardModalComponent,
        ReopenCardModalComponent,
        ActionPlanCardComponent,
        OrderByActionLogPipe,
        RevertToNotAssignEmailComponent,
        ActionBoardComponent,
        IssueClosureConfirmationDialogComponent,
        ReportsMenuComponent,
        AllInOneComponent,
        WeeklyReportComponent,
        SendEmailSiteActionsComponent,
        ActionPlanCardEditComponent,
        RadiobuttonoptionsComponent,
        AllInOneReportsDataOnCCRPNumberComponent,
        DropZoneTextAreaComponent,
        OrderByCardsPipe,
        TargetDateHistoryComponent,
        DeleteDraftComponent,
        GlobalRUComponent,
        GlobalSearchComponent,
        GlobalsearchDetailsComponent,
        SaveSettingsPopupComponent,
        FilesizePipe,
        SumFloatPipe,
        CustomerContactsComponent,
        AdvancedCustomerSelectionPopupComponent,
        AddContactPopupComponent,
        DeactivateContactPopupComponent,
        ActiveEmployeesComponent,
        StatusPipe,
        KeyValuePipe,
        AdminEscalationComponent,
        AdminEscalationTimeComponent,
        AdminInprogressEscalationtimesComponent,
        PGRCACategoryComponent,
        PgCategoryPopUpComponent,
        PgrcaCatAddPopUpComponent,
        PgrcaCatEditPopUpComponent,
        PgrcaCatDeactivatePopUpComponent,
        EditContactComponent,
        AdminSystemuserComponent,
        AddSystemuserRoleComponent,
        DeleteSystemuserRoleComponent,
        SystemuserItemdetailsComponent,
        PawelPipe,
        TranslationsComponent,
        TranslationResourceItemComponent,
        TranslationSettingsComponent,
        OrderGlobalRUPipe,
        AdminProcessStepsComponent,
        AdminEditProcessStepsModalComponent,
        AdminProcessStepsDeactivateModalComponent,
        AdminProcessownersComponent,
        PoSettingsComponent,
        POAddPersonComponent,
        SearchCriteriaPopupComponent,
        PoInfosectionComponent,
        PoItemunitComponent,
        PoItemlocationComponent,
        PoDetailsComponent,
        ProcessStepItemComponent,
        ProcessStepItemMemberComponent,
        HubsComponent,
        HubStructurePopupComponent,
        HubDeletePersonPopupComponent,
        HubAddPersonPopupComponent,
        DeleteProcessownerComponent,
        ProcessownerSearchComponent,
        ActionlogentriesInternalComponent,
        AdminFailuremodeComponent,
        FailuremodeTreeitemComponent,
        FailuremodeEditmodalComponent,
        FailuremodeDeactivatemodalComponent,
        ResolvingUnitsComponent,
        ResolvingUnitsDeletepersonComponent,
        ResolvingUnitsAddprimaryruinfoComponent,
        ResolvingAddPersonPopupComponent,
        AdminAccountableEditPopupComponent,
        AdminAccountableTreeAddPopupComponent,
        AccountablePersonComponent,
        FailuremodeTreeitemmebmerComponent,
        TranslationSettingsComponent,
        TranslationMessageItemComponent,
        TranslationMessageTemplateditComponent,
        FailuremodeEditPopUpComponent,
        HubPOSearchComponent,
        RupersonSearchComponent,
        ConfirmationComponent,
        WorkCenterSelectionPopUpComponent,
        CurrentratingConfirmPopupComponent,
        ViewReportComponent,
        ComplaintHasBeenDeletedExceptionComponent,
        IssueSummaryreportComponent,
        StatusComponent,
        SurveyTemplateComponent,
        SystemTimeoutWarningModalComponent,
        HiConfirmComponent,
        TesterLoginComponent,
        NumberCommaDirective,
        HrgtErrorComponent,
        MaintenanceErrorComponent,
        DefectCausePopupComponent,
        SubscribeComponent,
        ComplaintLockWarningComponent,
        DownloadEightDComponent
    ],
    bootstrap: [AppComponent, MsalRedirectComponent], imports: [NgxSpinnerModule,
        BrowserModule,
        AppRoutingModule,
        ReactiveFormsModule,
        FormsModule,
        NgbModule,
        SharedModule,
        NgxPaginationModule,
        BreadcrumbModule,
        BrowserAnimationsModule,
        DropdownModule,
        CardModule,
        PaginatorModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (HttpLoaderFactory),
                deps: [HttpClient]
            }
        }),
        TreeModule,
        CalendarModule,
        FileUploadModule,
        ButtonModule,
        TooltipModule,
        TreeSelectModule,
        DialogModule,
        TableModule,
        InputTextareaModule,
        FieldsetModule,
        AutoCompleteModule,
        ToastModule,
        RippleModule,
        ProgressSpinnerModule,
        DynamicDialogModule,
        CommonModule,
        MessagesModule,
        MultiSelectModule,
        EditorModule,
        AccordionModule,
        DragDropModule,
        MenubarModule,
        TimelineModule,
        ChipsModule,
        CheckboxModule,
        InputTextModule,
        MsalModule.forRoot(new PublicClientApplication({
            auth: {
                clientId: environment.azure.uiClienId,
                redirectUri: environment.azure.redirectUrl,
                postLogoutRedirectUri: environment.azure.redirectUrl,
                authority: 'https://login.microsoftonline.com/' + environment.azure.tenantId
            },
            cache: {
                cacheLocation: 'localStorage',
                storeAuthStateInCookie: false
            }
        }), {
            interactionType: InteractionType.Redirect,
            authRequest: {
                scopes: ['user.read']
            }
        }, {
            interactionType: InteractionType.Redirect,
            protectedResourceMap: new Map([
                [environment.azure.endpoint.root + environment.azure.endpoint.profile, ['user.read']],
                [environment.azure.baseUrl + 'api/v1/*', environment.azure.scopeUri],
                [environment.azure.baseUrl + 'api/external/*', null],
                [environment.azure.baseUrl + 'hi/*', null]
            ])
        }),
        //UserIdleModule.forRoot({idle: 60, timeout: 10, ping: 0}),
        InfiniteScrollModule,
        ExportAsModule,
        BsDatepickerModule.forRoot()], providers: [
        // {
        //   provide: HTTP_INTERCEPTORS,
        //   useClass: LoaderInterceptor,
        //   multi: true
        // },
        MsalService,
        MsalBroadcastService,
        MsalGuard,
        DatePipe,
        UsersService,
        DynamicDialogRef,
        DynamicDialogConfig,
        DialogService,
        MyIssueDashboardComponent,
        ResolvingUnitServiceService,
        ProductService,
        IssueSelectionService,
        ProjectSelectionServiceService,
        SupplierSelectionServiceService,
        FailuremodeService,
        EmailService,
        MessageService,
        EmailServiceTempService,
        IssueResolutionServiceService,
        CopqCategoryService,
        PgrcacategoryServiceService,
        SendEmailIssueAssignService,
        FormGroupDirective,
        RevertToNotAssignEmailServiceService,
        EmployeeListService,
        SharedService,
        DefectPopupService,
        AppInsightsService,
        ReopenIssueService,
        AdminCustomerContactsService,
        ActiveEmployeeService,
        AdminHubsService,
        AdminGobalRuService,
        AdminService,
        AdminAccountablePersonService,
        AdminProcessOwnerService,
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: APIParameterInterceptor,
            multi: true
        },
        { provide: HTTP_INTERCEPTORS, useClass: MsalInterceptor, multi: true },
        AzureADUserStatusService,
        ExceptionsService,
        UniqueActionNameWithinBoardService,
        PgrcaAdminServiceService,
        WorkCenterService,
        maxLengthConstants,
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule { }
