import { Component, OnInit } from '@angular/core';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { ProcessOwnersAuthService } from '../process-owners-auth.service';
import { ProcessOwnersService } from '../process-owners.service';
import { ToasterserviceService } from 'src/app/services/toasterservice.service';
import * as _ from 'lodash';
import { EmployeeListService } from 'src/app/services/employee-list.service';

@Component({
  selector: 'app-delete-processowner',
  templateUrl: './delete-processowner.component.html',
  styleUrls: ['./delete-processowner.component.css']
})
export class DeleteProcessownerComponent implements OnInit {

  poInfo:any = {};
  poGeid!:any;
  poScope!:any;

  constructor(private readonly dialogRef: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private poAuthService:ProcessOwnersAuthService,
    private processOwnersService:ProcessOwnersService,
    private toaster:ToasterserviceService,
    private employeeService: EmployeeListService,) {

      let geids = [];
      this.poGeid = config.data.user.geid;
      this.poScope = config.data.user.scope;
      geids.push(this.poGeid);
      this.employeeService.processOwnerdDetails(_.uniq(geids)).then((info)=> {
          if (info && info.length == 1) {
             this.poInfo = info[0];
             this.poInfo.scope = this.poScope;
          }

      });

     }

  ngOnInit(): void {
  }

  cancel() {
    this.dialogRef.close();
   };

   ok() {
    let canManage = this.poAuthService.canManageProcessOwner(this.poScope);

    if (canManage) {
        this.processOwnersService.deleteProcessOwner(this.poGeid, this.poScope).then((info) => {
          this.dialogRef.close({result: 'ok'});
        });

    }
    else {
       this.toaster.showError('Cannot delete this Process Owner. Insufficient permissions.');
    }
    this.dialogRef.close({result: 'ok'});
};

}
