import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { CognitivesearchServiceService } from '../cognitivesearch-service.service';
import * as _ from 'lodash';
import { ToasterserviceService } from 'src/app/services/toasterservice.service';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';

@Component({
  selector: 'app-global-search',
  templateUrl: './global-search.component.html',
  styleUrls: ['./global-search.component.css']
})
export class GlobalSearchComponent implements OnInit {

  viewModel:any;
  isSearching:boolean=false;
  msg_order = '-id';
  isLoadingMoreData:boolean = false;
  noResults:boolean = false;
  searchText:any="";

  constructor(private spinner:NgxSpinnerService,
              private cognitiveSearch:CognitivesearchServiceService,
              private toaster:ToasterserviceService,
              public breadcrumbService: BreadcrumbService) {
     this.viewModel = this.getEmptyViewModel();
   }

  ngOnInit(): void {
    this.breadcrumbService.menuItems = [
      {label: 'GLOBAL SEARCH'}
    ];
  }

  getEmptyViewModel():any {
    return {
        results: null,
        totalCount: 0,
        pageNbr: 1,
        pageSize: 300
    };
  };

  search() {
    this.isSearching = true;
    this.spinner.show();
    this.viewModel = this.getEmptyViewModel();
    if(this.searchText){
      this.cognitiveSearch.findForGlobalSearch(this.searchText, this.viewModel.pageNbr, this.viewModel.pageSize).subscribe(
        (data: any) => {

          this.noResults = data.totalCount === 0;

          this.isSearching = false;
          if (!_.isNull(data.error) && data.error.isError) {
            this.toaster.showError(data.error.message);
          } else {
            this.viewModel.results = data.results;
            this.viewModel.totalCount = data.totalCount;
          }
          this.spinner.hide();
        }
      )
    }else{
      this.toaster.showWarning('Please enter search text');
      this.spinner.hide();
      this.isSearching = false;
    }

  };

  nextPage() {
    if (_.isNull(this.viewModel.results) || _.isUndefined(this.viewModel.results)) return;
    if (this.isLoadingMoreData || this.viewModel.results.length >= this.viewModel.totalCount) return;

    this.isLoadingMoreData = true;
    this.viewModel.pageNbr += 1;

    this.viewModel = Object.assign({}, this.viewModel);

    this.cognitiveSearch.findForGlobalSearch(this.searchText, this.viewModel.pageNbr, this.viewModel.pageSize)
        .subscribe((data:any) =>{
            if (!_.isNull(data.error) && data.error.isError) {
              this.toaster.showError(data.error.message);
            }

            data.results.forEach((obj:any)=>{
              this.viewModel.results.push(obj);
            })

            this.isLoadingMoreData = false;
        });
};

}
