import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-radiobuttonoptions',
  templateUrl: './radiobuttonoptions.component.html',
  styleUrls: ['./radiobuttonoptions.component.css']
})
export class RadiobuttonoptionsComponent implements OnInit {

  @Input() labelClass:string;
  @Input() title:string;
  @Input() btnClass:string;
  @Input() allMsg:string;
  @Input() firstOptionMsg:string;
  @Input() secondOptionMsg:string;
  @Input() optionModel:any;
  @Input() inverted:boolean;
  @Input() disabled:boolean;
  @Output() optionModelChange =new EventEmitter<any>();
  constructor() {
   }

  ngOnInit(): void {
  }

  shouldBeDisabled():boolean{
    return this.disabled;
  }

  selectOption(option:any):void{
    this.optionModel = option;
     this.optionModelChange.emit(this.optionModel);
  }

  getFirstOption():any{
    return this.inverted === true ? false : true;
  }

  isOptionSelected(option:any):boolean{
     return this.optionModel === option;
  }

  getSecondOption():any{
    return this.inverted === true ? true : false;
    
    
    
  }
}
