<div class="save-setting-popup-reports">
  <form [formGroup]="saveSettingPopUp">
    <div class="row">
      <label class="col-sm-3 label_Class mt-1 text-end text-align-mobile label-bold">{{'rpt_mods_name_lbl'|translate}}</label>
      <div class="col-sm-9">
        <input formControlName="settingName" pInputText class="saveSetting form-control customtextfield-l">
      </div>
    </div>
    <div class="row mt-2">
      <label class="col-sm-3 label_Class text-end text-align-mobile label-bold">{{'rpt_mods_active_lbl'|translate}}</label>
      <div class="col-sm-9 fold-align-inline">
        <input formControlName="notifications" (change)="getCurrentUserProfile($event)" type="checkbox"
          class="float-start me-2 mt-1 check-align"><label>{{'rpt_mods_active_on'|translate}}</label>
      </div>
    </div>
    <div class="row mt-2 mb-2">
      <label class="col-sm-3 label_Class text-end text-align-mobile label-bold">
        {{'rpt_mods_filter_deafult_lbl'|translate}}
      </label>
      <div class="col-sm-9 fold-align-inline">
        <input formControlName="defaultSetting" type="checkbox" class="float-start me-2 mt-1 check-align"><label>
          {{'rpt_mods_filter_deafult_on'|translate}}
        </label>
      </div>
    </div>
    <div *ngIf="bottomSection" class="enableClass">
      <div class="row">
        <label class="col-sm-3 label_Class mt-1 text-end text-align-mobile text-align-mobile remove-px label-bold">{{'rpt_mods_recipients_lbl'|translate}}</label>
        <div class="col-sm-9 multiselect-field_remove employees-dropdown remove-px">
            <!-- <p-autoComplete [forceSelection]="true" formControlName="employees"
             <p-autoComplete [forceSelection]="true" formControlName="employees"
                    class="border-0" [suggestions]="employeesDetails"
                        (completeMethod)="filterEmployee($event)" field="email" [multiple]="true">
            </p-autoComplete> -->
            <p-autoComplete
            [forceSelection]="true"
            class="border-0"
            formControlName="employees" [multiple]="true" type="text"
            [suggestions]="employeesDetails" [dropdownIcon]="'none'"
            (completeMethod)="filterEmployee($event)"
            field="email" >
        </p-autoComplete>
        </div>


      </div>
      <div class="row mt-2">
        <label class="col-sm-3 label_Class mt-1 text-end text-align-mobile remove-px label-bold">{{'rpt_mods_others_lbl'|translate}}</label>
        <div class="col-sm-9 multiselect-field_remove employees-dropdown remove-px">
          <p-chips class="ps-0" formControlName="others" (onAdd)="validateEmail($event)" (onRemove)="onRemoveChip($event)" [addOnTab]="true" [addOnBlur]="true" separator="," [allowDuplicate]="false"></p-chips>
          <!-- <span *ngIf="this.saveSettingPopUp.get('others').errors?.['email']" class="error ms-0">Enter valid email</span> -->
        </div>
      </div>
      <div class="row mt-2">
        <label class="col-sm-3 label_Class mt-1 text-end text-align-mobile remove-px label-bold">{{'rpt_mods_subject_lbl'|translate}}</label>
        <div class="col-sm-9  remove-px">
          <input formControlName="subject" pInputText class="ps-1 saveSetting form-control customtextfield-l">
        </div>
      </div>
      <div class="row mt-2">
        <label class="col-sm-3 label_Class mt-1 text-end text-align-mobile remove-px label-bold">{{'rpt_mods_repeat_lbl'|translate}}</label>
        <div class="col-sm-9 remove-px">
          <p-dropdown formControlName="repeat" [options]="repeat" optionValue="code" optionLabel="name"></p-dropdown>
        </div>
      </div>
      <div class="row mt-2">
        <label class="col-sm-3 label_Class mt-1 text-end text-align-mobile remove-px label-bold">{{'rpt_mods_every_lbl'|translate}}</label>
        <div class="col-sm-4 remove-px">
          <p-dropdown formControlName="every" [options]="every" optionValue="value" optionLabel="name"></p-dropdown>
        </div>
        <div class="col-sm-5 ps-0">
          <span class="day_style" [ngSwitch]="this.saveSettingPopUp.get('repeat').value">
              <span *ngSwitchCase="'daily'">{{'rpt_mods_every_day' | translate}}</span>
              <span *ngSwitchCase="'eweek'">{{'rpt_mods_every_week' | translate}}</span>
              <span *ngSwitchCase="'emonth'">{{'rpt_mods_every_month' | translate}}</span>
              <span *ngSwitchCase="'eyear'">{{'rpt_mods_every_year' | translate}}</span>
          </span>
      </div>
      </div>

      <div class="row mt-2" *ngIf="this.saveSettingPopUp.get('repeat').value=='emonth'">
        <label class="col-sm-3 label_Class mt-1 text-end text-align-mobile remove-px label-bold">{{'rpt_mods_repp_lbl'|translate}}</label>
        <div class="col-sm-9">
          <div class="repeatMonth me-3">
          <input type="radio" class="widgetRadioButton repeatMonth remove-px" value="m" formControlName="repeatByMonth"><span>
            {{'rpt_mods_repp_dofm'|translate}}
          </span>
        </div>
        <div class="repeatMonth">
          <input type="radio" class="widgetRadioButton repeatMonth" value="w" formControlName="repeatByMonth"><span>
            {{'rpt_mods_repp_dow'|translate}}</span>
          </div>
        </div>
      </div>
      <div class="row mt-2" *ngIf="this.saveSettingPopUp.get('repeat').value=='eweek'">
        <label class="col-sm-3 label_Class mt-1 text-end text-align-mobile remove-px label-bold">{{'rpt_mods_repin_lbl'|translate}}</label>
        <div class="col-sm-9 pt-6">
          <label class="me-2 mt-2">
            <span class="dayName"> {{'rpt_mods_repin_0'|translate}}</span>
            <input type="checkbox" formControlName="repeatInSunday" (click)= "repeatDays(0, $event)">
          </label>
          <label class="me-2">
            <span class="dayName"> {{'rpt_mods_repin_1'|translate}}</span>
            <input type="checkbox" formControlName="repeatInMonday" (click)= "repeatDays(1, $event)">
          </label>
          <label class="me-2">
            <span class="dayName"> {{'rpt_mods_repin_2'|translate}}</span>
            <input type="checkbox" formControlName="repeatInTuesday" (click)= "repeatDays(2, $event)">
          </label>
          <label class="me-2">
            <span class="dayName"> {{'rpt_mods_repin_3'|translate}}</span>
            <input type="checkbox" formControlName="repeatInWed" (click)= "repeatDays(3, $event)">
          </label>
          <label class="me-2">
            <span class="dayName"> {{'rpt_mods_repin_4'|translate}}</span>
            <input type="checkbox" formControlName="repeatInThu" (click)= "repeatDays(4, $event)">
          </label>
          <label class="me-2">
            <span class="dayName"> {{'rpt_mods_repin_5'|translate}}</span>
            <input type="checkbox" formControlName="repeatInFriday" (click)= "repeatDays(5, $event)">
          </label>
          <label class="me-2">
            <span class="dayName"> {{'rpt_mods_repin_6'|translate}}</span>
            <input type="checkbox" formControlName="repeatInSaturday" (click)= "repeatDays(6, $event)">
          </label>
        </div>
      </div>
      <div class="row mt-2">
        <label class="col-sm-3 label_Class mt-1 text-end text-align-mobile remove-px label-bold">{{'rpt_mods_startdt_lbl'|translate}}</label>
        <div class="col-sm-4  remove-px">
          <div class="datefield-save">
          <p-calendar  dateFormat="yy-mm-dd" class="saveSetting" [showIcon]="false"
            formControlName="startday"></p-calendar>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer my-2">
      <button (click)="cancel($event)" class="widgetButtoSmall">{{'rpt_mods_cancel_btn'|translate}}</button>
      <button [disabled]="disableOk()" *ngIf="!isSaving" (click)="saveSetting()" class="widgetButtoSmall ms-1">{{'rpt_mods_ok_btn'|translate}}</button>
      <button class="widget-buttonlarge ms-1" *ngIf="isSaving"><i class="fa fa-circle-o-notch fa-spin"></i>&nbsp;Generating...</button>
    </div>
  </form>
</div>
