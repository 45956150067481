import { Component, OnInit } from '@angular/core';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';

@Component({
  selector: 'app-ruperson-search',
  templateUrl: './ruperson-search.component.html',
  styleUrls: ['./ruperson-search.component.css']
})
export class RupersonSearchComponent implements OnInit {

  constructor(private breadcrumbService: BreadcrumbService) { }

  ngOnInit(): void {
    this.breadcrumbService.menuItems= [
      { label: 'RU PERSON SEARCH'}
    ]
  }

}
