import { Injectable } from '@angular/core';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class MessageRecipientsService {

  recipients:any = [
    { code: 10, display: '%AccountManager%' },
    { code: 11, display: '%InterestedParties%' },
    { code: 12, display: '%CustomerContact%' },
    { code: 13, display: '%IssueOwner%' },
    { code: 14, display: '%Coordinators%' },
    { code: 15, display: '%ResolutionOwner%' },
    { code: 16, display: '%Supervisor%' },
    { code: 17, display: '%PgProcessOwner%' },
    { code: 18, display: '%IssueOwnerTeam%' },
    { code: 19, display: '%ResolutionOwnerTeam%' },
    { code: 20, display: '%BuProcessOwner%' },
    { code: 21, display: '%DivisionProcessOwner%' },
    { code: 22, display: '%DivisionPresident%' },
    { code: 23, display: '%BuManager%' },
    { code: 24, display: '%CountryManagingDirector%' },
    { code: 25, display: '%RegionPresident%' },
    { code: 26, display: '%GlobalCCRPProcessOwner%' },
    { code: 27, display: '%HubProcessOwner%' },
    { code: 28, display: '%BuHseManager%' },
    { code: 29, display: '%AdditionalNominatedPerson%' },
    { code: 30, display: '%IssueOwnerUnit%' }
  ];

  constructor() { }

  getDefaultMessageRecipients():Promise<any> {
    return new Promise((resolve,reject)=>{
      resolve(this.recipients);
    })
  }


  getMessageTypeName(messageTypeId):Promise<any> {

    return new Promise((resolve,reject)=>{
      let messageTypes = [
        { id: 1, typeName: 'System Message' },
        { id: 2, typeName: 'User Message' },
        { id: 3, typeName: 'Predefined Message' }
      ];

      let element = _.find(messageTypes, function (msg) {
        return msg.id == messageTypeId;
      });

      resolve(element);
    })



  }

  findByWildCard(wildcard) {
    let results = _.filter(this.recipients, function(rec) {
        return rec.display.toLowerCase().indexOf(wildcard.toLowerCase()) > -1;
    });

    return results;
}


}
