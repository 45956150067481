<div class="edit-customer-contact-popup-section">
    <form [formGroup]="admEditContactForm">
        <div class="row mt-3">
            <div class="col-sm-3 item-align-right edit-customer-contact-popup-label label-bold">
                <div class="float-end mt-1">{{'admin_cc_customers_crud_fn_lb' | translate}}</div>
            </div>
            <div class="col-sm-9">
                <input type="text" class="form-control" placeholder="{{'admin_cc_customers_crud_fn_ph' | translate}}"
                    formControlName="fname">
                    <small class="error" *ngIf="admEditContactForm.get('fname').touched && admEditContactForm.get('fname').errors?.['required']">
                        {{'admin_cc_customers_crud_fn_req_msg' | translate}}
                    </small>
                    <small class="error" *ngIf="admEditContactForm.get('fname').errors?.['maxlength']">
                        {{'admin_cc_customers_crud_fn_max_msg' | translate}}
                    </small>
            </div>
            <!-- <div class="col-sm-1"></div> -->
        </div>
        <div class="row mt-3">
            <div class="col-sm-3 item-align-right edit-customer-contact-popup-label label-bold">
                <div class="float-end mt-1">{{'admin_cc_customers_crud_ln_lb' | translate}}</div>
            </div>
            <div class="col-sm-9">
                <input type="text" class="form-control" placeholder="{{'admin_cc_customers_crud_ln_ph' | translate}}"
                    formControlName="lname">
                    <small class="error" *ngIf="admEditContactForm.get('lname').touched && admEditContactForm.get('lname').errors?.['required']">
                        {{'admin_cc_customers_crud_ln_req_msg' | translate}}
                    </small>
                    <small class="error" *ngIf="admEditContactForm.get('lname').errors?.['maxlength']">
                        {{'admin_cc_customers_crud_ln_max_msg' | translate}}
                    </small>
            </div>
            <!-- <div class="col-sm-1"></div> -->
        </div>
        <div class="row mt-3">
            <div class="col-sm-3 item-align-right edit-customer-contact-popup-label label-bold">
                <div class="float-end mt-1">{{'admin_cc_customers_crud_email_lb' | translate}}</div>
            </div>
            <div class="col-sm-9">
                <input type="text" class="form-control" placeholder="{{'admin_cc_customers_crud_email_ph' | translate}}"
                    formControlName="email">
                    <small class="error" *ngIf="admEditContactForm.get('email').touched && admEditContactForm.get('email').errors?.['required']">
                        {{'admin_cc_customers_crud_email_req_msg' | translate}}
                    </small>
                    <small class="error" *ngIf="admEditContactForm.get('email').errors?.['maxlength']">
                        {{'admin_cc_customers_crud_email_max_msg' | translate}}
                    </small>
                    <small class="error"
                        *ngIf="admEditContactForm.get('email').errors?.['email']">
                        {{'admin_cc_customers_crud_email_format_msg' | translate}}
                    </small>
            </div>
            <!-- <div class="col-sm-1"></div> -->
        </div>
        <!-- <p class="help-block error"
            *ngIf="admEditContactForm.get('email').dirty && admEditContactForm.get('email').invalid">
            <small class="error" *ngIf="admEditContactForm.get('email').errors['required']">
                {{'admin_cc_customers_crud_email_req_msg' | translate}}
            </small>
            <small class="error" *ngIf="admEditContactForm.get('email').errors['maxlength']">
                {{'admin_cc_customers_crud_email_max_msg' | translate}}
            </small>
            <small class="error" *ngIf="admEditContactForm.get('email').invalid">
                {{'admin_cc_customers_crud_email_format_msg' | translate}}
            </small>
        </p> -->
        <div class="row mt-3">
            <div class="col-sm-3 item-align-right edit-customer-contact-popup-label label-bold">
                <div class="float-end mt-1">{{'admin_cc_customers_crud_phone_lb' | translate}}</div>
            </div>
            <div class="col-sm-9">
                <input type="text" class="form-control"
                    placeholder="{{'admin_cc_customers_crud_phone_ph' | translate}}" formControlName="phone"
                    maxlength="15" (keypress)="keyPressNumbers($event)" >
            </div>
            <!-- <div class="col-sm-1"></div> -->
        </div>
        <div class="row mt-3">
            <div class="col-sm-3 item-align-right edit-customer-contact-popup-label label-bold">
                <div>{{'admin_cc_customers_crud_active_lb' | translate}}</div>
            </div>
            <div class="col-sm-9">
                <input type="checkbox" formControlNname="isActive" class="checkbox activecheckbox" [checked]="isChecked"
                    (change)="toggleCheckboxBoolean($event)" />
            </div>
            <!-- <div></div> -->
        </div>
        <div class="row mt-2">
            <div class="col-sm-4"></div>
            <div class="col-sm-4"></div>
            <div class="col-sm-4 div-align-items-right">
                <button type="button" class="input widgetButtonLarge" tooltipPosition="top" tooltipStyleClass="toolTipClass"
                    (click)="cancel()">{{'admin_cc_customers_crud_close_lb' | translate}}
                </button>
                <button class="input widgetButtonLarge ms-1" (click)="editAndSaveContacts()"
                    [disabled]="admEditContactForm.invalid"
                    [ngClass]="admEditContactForm.invalid? 'grayButton':''">{{'admin_cc_customers_crud_save_lb'
                    | translate}}
                </button>
            </div>
        </div>
    </form>
</div>