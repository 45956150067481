import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ArrayService {

  constructor() { }

  splitVertically = function (collection, columnsCount) {

    if (collection && collection.length <= columnsCount) {
      return [collection];
    }

    let columnsArray = new Array(columnsCount);
    let rowsNum = Math.ceil(collection.length / columnsCount);

    for (let i = 0; i < columnsCount; i++) {
      let rowsArray = [];
      for (let j = 0; j < rowsNum; j++) {
        let index = j + rowsNum * i;
        if (index < collection.length) {
          rowsArray[j] = collection[index];
        } else {
          break;
        }
      }
      columnsArray[i] = rowsArray;
    }
    return columnsArray;
  };
}
