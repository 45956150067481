import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProjectSelectionServiceService {

  constructor(private http: HttpClient) { }
  getProject(): Observable<any>{
     return this.http.get(`${environment.azure.baseUrl}api/v1/Popups/projectsearch`);
     }
 getBuList(division): Observable<any> {
      return this.http.post(`${environment.azure.baseUrl}api/v1/Data/GetDivisionBUList?division=` + division, division);
    }
  getProMISDetails(formatedData): Observable<any>{
    return this.http.post(`${environment.azure.baseUrl}api/v1/Data/GetProMISDetails`, formatedData);
  }
  getBuPgList(division, bu): Observable<any> {
      return this.http.post(`${environment.azure.baseUrl}api/v1/Data/GetDivisionBUPGList?division=` + division + ` &bu=` + bu, division);
    }
    }
