<div class="test-date-history">
  <h5 class="trdhistory-header-left">{{'trd_history_header' | translate }} <i *ngIf="targetHistory?.length > 0" style="font-size: small;" class="fa fa-info-circle"></i>
    <a class="pull-right" *ngIf="!IsExcelGenerating && targetHistory?.length > 0" (click)="downloadDateHistory()"><span class="glyphicon glyphicon-save"></span> Download</a>
    <span *ngIf="IsExcelGenerating" class="pull-right"><i class="fa fa-circle-o-notch fa-spin"></i>&nbsp;Generating...</span>
  </h5>
  <div *ngIf="targetHistory?.length == 0" class="mt-3">
    {{'no_data_trd_log_info' | translate}}
  </div>
  <div *ngIf="targetHistory?.length > 0">
    <p-timeline [value]="targetHistory" class="w-full md:w-20rem">
      <ng-template pTemplate="content" let-history>
        <span class="test-date">
          <strong>{{ history.currentTargetResolvingDate | date : 'yyyy-MM-dd' }}</strong>
        </span>
        <p class="trdhistory-content">{{history.reason}}</p>
        <p class="trdhistory-footer">{{history?.createDate | date : 'yyyy-MM-dd'}}: {{'trd_history_modify' | translate}} {{history.modifyBy}}</p>
      </ng-template>
    </p-timeline>
  </div>
</div>
