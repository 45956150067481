import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TreeNode } from 'primeng/api';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class ProcessService {

  constructor(private http: HttpClient) { }

  getFiles() {
    const parendId = new FormData();
    parendId.append('parentId', '');
    return this.http
      .post<TreeNode>(`${environment.azure.baseUrl}api/v1/Data/GetProcessSteps`, parendId)
      .toPromise()
      .then((res) => <TreeNode[]>res);
  }

  procesStepById(parentId) {
    return this.http
      .post<TreeNode>(`${environment.azure.baseUrl}api/v1/Data/GetProcessSteps?parentId=` + parentId, parentId)
      .toPromise()
      .then((res) => <TreeNode[]>res);
  }

  getFilteredProcessStepsTree(searchData){
    return this.http.post<TreeNode>(environment.azure.baseUrl+'api/v1/Data/GetFilteredProcessStepsTree?filterText='+searchData.filterText+''+(searchData.parentId!=null ? '&parentId='+searchData.parentId : ''), searchData)
    .toPromise().then((res) => <TreeNode[]>res);
  }
}
