<form [formGroup]="hubForm">
    <div class="hub">
        <p-accordion [expandIcon]="'pi pi-fw pi-chevron-down'" [collapseIcon]="'pi pi-fw pi-chevron-up'"
            [multiple]="true" [activeIndex]="expandIndex" class="w-full" class="headerBack">
            <p-accordionTab *ngIf="flagFromSearch" [selected]="isExpanded">
                <ng-template pTemplate="header">
                    <div class="flex align-items-center col-md-4 accordionHeaderHub">
                        <span class="vertical-align-middle"> {{'admin_hub_bus_h' | translate }}</span>
                    </div>
                    <div class="col-xs-1 mt-1">
                        <span class="vertical-align-middle">{{'admin_hub_bu_pg_change_h' | translate}}</span>
                    </div>
                </ng-template>

                <div *ngFor="let buHub of BUHubUsage; let i = index">
                    <div>
                        <button type="button" (click)="buHub.expanded = !buHub.expanded"
                            class="btn btn-default btn-xs admin-left-margin collapseBtn" *ngIf="buHub.expanded">
                            <i class="fa fa-angle-double-up" title="Collapse"></i>
                        </button>
                        <button type="button" (click)="buHub.expanded = !buHub.expanded"
                            class="btn btn-default btn-xs admin-left-margin expandBtn" *ngIf="!buHub.expanded">
                            <i class="fa fa-angle-double-down" title="Expand"></i>
                        </button>
                        <strong class="pgSpace">{{buHub.divCode}} - {{buHub.divName}}</strong>
                        <span *ngIf="buHub.expanded">
                            <div *ngFor="let state of buHub.busInfo">
                                <div class="row accordionRow">
                                    <div class="col-sm-4 buSpace">{{state.buCode }} - {{state.buName}}
                                        <span
                                            class="glyphicon glyphicon-exclamation-sign alert-info spaceExclamationSign"
                                            *ngIf="hugExclamationSign(state)" [pTooltip]="HubincativeTooltipInfo"
                                            [escape]="false" tooltipPosition="top" tooltipStyleClass="toolTipClass">
                                        </span>
                                    </div>
                                    <div class="col-sm-1 accordionToggleHub">
                                        <label class="switch" *ngIf="hubCheckBox(state)">
                                            <input type="checkbox" [formControlName]="state.buCode"
                                                (click)="showHubStructurePopup(state)">
                                            <span class="slider round">
                                            </span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </span>
                    </div>
                </div>
            </p-accordionTab>

            <p-accordionTab [selected]="isExpanded">
                <ng-template pTemplate="header">
                    <div class="flex align-items-center col-md-3">
                        <span class="vertical-align-middle"> {{'admin_hub_filter_h' | translate }}</span>
                    </div>
                </ng-template>
                <!-- first row -->
                <div class="row formRowMarginHub">
                    <div class="col-sm-4 marginResponsiveHUB">
                        <div class="lable-bold">{{'admin_hub_filter_division_lbl'|translate}}</div>
                        <div class="">
                            <p-dropdown [options]="division" optionValue="key" formControlName="business"
                                optionLabel="value" [showClear]="true" placeholder="Click or type to select Business" (onChange)="showSelectedHubCriteria()">
                            </p-dropdown>
                        </div>
                    </div>
                    <div class="col-sm-4 marginResponsiveHUB">
                        <div class="lable-bold">{{'admin_hub_filter_lead_country_lbl'|translate}}</div>
                        <div class="">
                            <p-dropdown [resetFilterOnHide]="true" formControlName="country" [options]="country" optionValue="id" optionLabel="name"
                                [filter]="true" filterBy="name" [showClear]="true"
                                placeholder="Click or type to select Lead Country">
                            </p-dropdown>
                        </div>
                    </div>
                    <div class="col-sm-4 marginResponsiveHUB">
                        <div class="lable-bold">{{'admin_hub_filter_hpo_lbl'|translate}}</div>
                        <div class="hubProcessOwnerDropdownDataDiv">
                            <p-dropdown formControlName="hubProcessOwner" [options]="filteredCustomer" optionLabel="email"
                                optionValue="id" [resetFilterOnHide]="true" [filter]="true" filterBy="email" filterPlaceholder="Please enter min 2 characters"
                                placeholder="Click or type to select Employee" [showClear]="true"
                                (onFilter)="filterCustomerDetails($event)">
                                <ng-template let-filteredCustomer pTemplate="item">
                                  <div class="flex align-items-center gap-2">
                                        <div class="row">
                                          <div class="col-8 p-1"><strong>{{ filteredCustomer?.name }} {{ filteredCustomer?.isActive ? '':'(Inactive)' }}</strong></div>
                                          <div class="col-4 p-2 text-end"><i>{{filteredCustomer?.city}}({{filteredCustomer.iso}})</i></div>
                                        </div>
                                        <div class="row">
                                          <div class="col p-1">{{filteredCustomer?.email}}</div>
                                        </div>
                                  </div>
                              </ng-template>
                            </p-dropdown>
                        </div>
                    </div>
                </div>
                <!-- <div class="row mt-2">
                    <div class="col-sm-4 lable-bold">{{'admin_hub_filter_division_lbl'|translate}}1</div>
                    <div class="col-sm-4 lable-bold">{{'admin_hub_filter_lead_country_lbl'|translate}}2</div>
                    <div class="col-sm-4 lable-bold">{{'admin_hub_filter_hpo_lbl'|translate}}3</div>
                </div>

                <div class="row mt-0 display: block">
                    <div class="col-sm-4">
                        <p-dropdown [options]="division" optionValue="key" formControlName="business"
                            optionLabel="value" [showClear]="true" placeholder="Click or type to select Business" (onChange)="showSelectedHubCriteria()">
                        </p-dropdown>
                    </div>
                    <div class="col-sm-4">
                        <p-dropdown [resetFilterOnHide]="true" formControlName="country" [options]="country" optionValue="id" optionLabel="name"
                            [filter]="true" filterBy="name" [showClear]="true"
                            placeholder="Click or type to select Lead Country">
                        </p-dropdown>
                    </div>
                    <div class="col-sm-4 hubProcessOwnerDropdownDataDiv">
                        <p-dropdown formControlName="hubProcessOwner" [options]="filteredCustomer" optionLabel="email"
                            optionValue="id" [resetFilterOnHide]="true" [filter]="true" filterBy="email" filterPlaceholder="Please enter min 2 characters"
                            placeholder="Click or type to select Employee" [showClear]="true"
                            (onFilter)="filterCustomerDetails($event)">
                            <ng-template let-filteredCustomer pTemplate="item">
                              <div class="flex align-items-center gap-2">
                                    <div class="row">
                                      <div class="col-8 p-1"><strong>{{ filteredCustomer?.name }} {{ filteredCustomer?.isActive ? '':'(Inactive)' }}</strong></div>
                                      <div class="col-4 p-2 text-end"><i>{{filteredCustomer?.city}}({{filteredCustomer.iso}})</i></div>
                                    </div>
                                    <div class="row">
                                      <div class="col p-1">{{filteredCustomer?.email}}</div>
                                    </div>
                              </div>
                          </ng-template>
                        </p-dropdown>
                    </div>
                </div> -->
                <!-- second row -->
                <div class="row formRowMarginHub">
                    <div class="col-sm-4 marginResponsiveHUB">
                        <div class="lable-bold">{{'admin_hub_filter_bu_lbl'|translate}}</div>
                        <div class="">
                            <p-dropdown [options]="bu" optionValue="key" formControlName="bu" optionLabel="value"
                                [filter]="true" [resetFilterOnHide]="true" filterBy="value" [showClear]="true"
                                placeholder="Click or type to select BU">
                            </p-dropdown>
                        </div>
                    </div>
                    <div class="col-sm-4 marginResponsiveHUB">
                        <div class="lable-bold">{{'admin_rufilter_pg_lbl'|translate}}</div>
                        <div class="">
                            <p-dropdown formControlName="pg" [options]="pg" optionValue="key" optionLabel="value"
                                [filter]="true" [resetFilterOnHide]="true" [autofocus]="false" filterBy="value" [showClear]="true"
                                placeholder="Click or type to select PG">
                            </p-dropdown>
                        </div>
                    </div>
                    <div class="col-sm-4 marginResponsiveHUB">
                        <div class="lable-bold">{{'admin_hub_filter_is_hpo_assigned_lbl'|translate}}</div>
                        <div class="comboBtn" style="display: flex;">
                            <button class="btn btn-default btnIrrevalent btnFontSizeHUB btnSize" value="null" style="border-top-right-radius: 0px;
                            border-bottom-right-radius: 0px;" role="button" (click)="onClickIrrelevant()"
                                [style.backgroundColor]="this.btnColorChange0 ? '#d9d9d9': '#ffffff'">
                                <span class="fa btnCircleOHub"
                                    [ngClass]="this.btnColorChange0 ?  'fa-check-circle-o' : 'fa-circle-o' "></span>
                                    <span class="buttonHUBValue">{{'admin_hub_poa_irrelevant_lbl'|translate}}</span>
                            </button>
                            <button class="btn btn-default btnYes btnFontSizeHUB btnSizeIrrevelant" value="true" style="border-top-right-radius: 0px;
                            border-bottom-right-radius: 0px; border-top-left-radius: 0px; border-bottom-left-radius: 0px;"
                                type="button" (click)="onClickYes()"
                                [style.backgroundColor]="this.btnColorChange1 ? '#d9d9d9': '#ffffff'">
                                <span class="fa"
                                    [ngClass]="this.btnColorChange1 ?  'fa-check-circle-o' : 'fa-circle-o' "></span>
                                {{'admin_hub_poa_yes_lbl'|translate}}
                            </button>
                            <button class="btn btn-default btnNo btnFontSizeHUB btnSize" value="false"
                                style="border-top-left-radius: 0px; border-bottom-left-radius: 0px;"
                                type="button" (click)="onClickNo()"
                                [style.backgroundColor]="this.btnColorChange2 ? '#d9d9d9': '#ffffff'">
                                <span class="fa"
                                    [ngClass]="this.btnColorChange2 ?  'fa-check-circle-o' : 'fa-circle-o' "></span>
                                {{'admin_hub_poa_no_lbl'|translate}}
                            </button>
                        </div>
                    </div>
                </div>
                <!-- <div class="row mt-2 ">
                    <div class="col-sm-4 lable-bold">{{'admin_hub_filter_bu_lbl'|translate}}</div>
                    <div class="col-sm-4 lable-bold">{{'admin_rufilter_pg_lbl'|translate}}</div>
                    <div class="col-sm-4 lable-bold">{{'admin_hub_filter_is_hpo_assigned_lbl'|translate}}</div>
                </div>

                <div class="row mt-0">
                    <div class="col-sm-4">
                        <p-dropdown [options]="bu" optionValue="key" formControlName="bu" optionLabel="value"
                            [filter]="true" [resetFilterOnHide]="true" filterBy="value" [showClear]="true"
                            placeholder="Click or type to select BU">
                        </p-dropdown>
                    </div>
                    <div class="col-sm-4">
                        <p-dropdown formControlName="pg" [options]="pg" optionValue="key" optionLabel="value"
                            [filter]="true" [resetFilterOnHide]="true" [autofocus]="false" filterBy="value" [showClear]="true"
                            placeholder="Click or type to select PG">
                        </p-dropdown>
                    </div>
                    <div class="col-sm-4 comboBtn" style="display: flex;">
                        <button class="btn btn-default" value="null" style="flex: 0.5; border-top-right-radius: 0px;
                        border-bottom-right-radius: 0px;" role="button" (click)="onClickIrrelevant()"
                            [style.backgroundColor]="this.btnColorChange0 ? '#d9d9d9': '#ffffff'">
                            <span class="fa"
                                [ngClass]="this.btnColorChange0 ?  'fa-check-circle-o' : 'fa-circle-o' "></span>
                            {{'admin_hub_poa_irrelevant_lbl'|translate}}
                        </button>
                        <button class="btn btn-default" value="true" style="flex: 0.5; border-top-right-radius: 0px;
                        border-bottom-right-radius: 0px; border-top-left-radius: 0px; border-bottom-left-radius: 0px;"
                            type="button" (click)="onClickYes()"
                            [style.backgroundColor]="this.btnColorChange1 ? '#d9d9d9': '#ffffff'">
                            <span class="fa"
                                [ngClass]="this.btnColorChange1 ?  'fa-check-circle-o' : 'fa-circle-o' "></span>
                            {{'admin_hub_poa_yes_lbl'|translate}}
                        </button>
                        <button class="btn btn-default" value="false"
                            style="flex: 0.5; border-top-left-radius: 0px; border-bottom-left-radius: 0px;"
                            type="button" (click)="onClickNo()"
                            [style.backgroundColor]="this.btnColorChange2 ? '#d9d9d9': '#ffffff'">
                            <span class="fa"
                                [ngClass]="this.btnColorChange2 ?  'fa-check-circle-o' : 'fa-circle-o' "></span>
                            {{'admin_hub_poa_no_lbl'|translate}}
                        </button>
                    </div>
                </div> -->
            <!-- Third row -->
            <div class="row formRowMarginHub">
                <div class="col-sm-4 marginResponsiveHUB">
                    <div class="lable-bold">{{'admin_hub_filter_belonging_countries_lbl'|translate}}</div>
                    <div class="multiselectWidthforcountry">
                        <p-multiSelect [resetFilterOnHide]="true" [options]="country" [showToggleAll]="false"
                            class="custom-ms p-multiselect p-component border-0" [filter]="true" optionValue="id"
                            formControlName="belongCountries" optionLabel="name" display="chip"
                            placeholder="Click or type to select Country">
                        </p-multiSelect>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="lable-bold"></div>
                    <div class=""></div>
                </div>
                <div class="col-sm-4 marginResponsiveHUB">
                    <div class="lable-bold">{{'admin_hub_filter_hub_status_lbl'|translate}}</div>
                    <div class="comboBtn" style="display: flex;">
                        <button class="btn btn-default btnFontSizeHUB btnSize" value="null" style="flex: 0.5;  border-top-right-radius: 0px;
                        border-bottom-right-radius: 0px;" type="button" (click)="onClickAll()"
                            [style.backgroundColor]="this.btnColorChange3 ? '#d9d9d9': '#ffffff'">
                            <span class="fa"
                                [ngClass]="this.btnColorChange3 ?  'fa-check-circle-o' : 'fa-circle-o' "></span>
                            {{'admin_hub_hs_all_lbl'|translate}}
                        </button>
                        <button class="btn btn-default btnFontSizeHUB btnSize" name="assignedProcessOwner" value="true" type="button"
                            style="flex: 0.5; border-top-right-radius: 0px;
                            border-bottom-right-radius: 0px; border-top-left-radius: 0px; border-bottom-left-radius: 0px;" role="button" (click)="onClickActive()"
                            [style.backgroundColor]="this.btnColorChange4 ? '#d9d9d9': '#ffffff'">
                            <span class="fa btnCircleO"
                                [ngClass]="this.btnColorChange4 ?  'fa-check-circle-o' : 'fa-circle-o' "></span>
                            {{'admin_hub_hs_active_lbl'|translate}}
                        </button>
                        <button class="btn btn-default btnFontSizeHUB btnSize" name="assignedProcessOwner" value="false" type="button"
                            style="flex: 0.5; border-top-left-radius: 0px; border-bottom-left-radius: 0px;"
                            (click)="onClickInActive()"
                            [style.backgroundColor]="this.btnColorChange5 ? '#d9d9d9': '#ffffff'">
                            <span class="fa btnCircleO"
                                [ngClass]="this.btnColorChange5 ?  'fa-check-circle-o' : 'fa-circle-o' "></span>
                            {{'admin_hub_hs_inactive_lbl'|translate}}
                        </button>
                    </div>
                </div>
            </div>
                <!-- <div class="row mt-2 ">
                    <div class="col-sm-4 lable-bold">{{'admin_hub_filter_belonging_countries_lbl'|translate}}</div>
                    <div class="col-sm-4 lable-bold"></div>
                    <div class="col-sm-4 lable-bold">{{'admin_hub_filter_hub_status_lbl'|translate}}</div>
                </div>

                <div class="row mt-0">
                    <div class="col-sm-4 multiselectWidthforcountry">
                        <p-multiSelect [resetFilterOnHide]="true" [options]="country" [showToggleAll]="false"
                            class="custom-ms p-multiselect p-component border-0" [filter]="true" optionValue="id"
                            formControlName="belongCountries" optionLabel="name" display="chip"
                            placeholder="Click or type to select Country">
                        </p-multiSelect>
                    </div>
                    <div class="col-sm-4"></div>
                    <div class="col-sm-4 comboBtn" style="display: flex;">
                        <button class="btn btn-default" value="null" style="flex: 0.5;  border-top-right-radius: 0px;
                        border-bottom-right-radius: 0px;" type="button" (click)="onClickAll()"
                            [style.backgroundColor]="this.btnColorChange3 ? '#d9d9d9': '#ffffff'">
                            <span class="fa"
                                [ngClass]="this.btnColorChange3 ?  'fa-check-circle-o' : 'fa-circle-o' "></span>
                            {{'admin_hub_hs_all_lbl'|translate}}
                        </button>
                        <button class="btn btn-default" name="assignedProcessOwner" value="true" type="button"
                            style="flex: 0.5; border-top-right-radius: 0px;
                            border-bottom-right-radius: 0px; border-top-left-radius: 0px; border-bottom-left-radius: 0px;" role="button" (click)="onClickActive()"
                            [style.backgroundColor]="this.btnColorChange4 ? '#d9d9d9': '#ffffff'">
                            <span class="fa"
                                [ngClass]="this.btnColorChange4 ?  'fa-check-circle-o' : 'fa-circle-o' "></span>
                            {{'admin_hub_hs_active_lbl'|translate}}
                        </button>
                        <button class="btn btn-default" name="assignedProcessOwner" value="false" type="button"
                            style="flex: 0.5; border-top-left-radius: 0px; border-bottom-left-radius: 0px;"
                            (click)="onClickInActive()"
                            [style.backgroundColor]="this.btnColorChange5 ? '#d9d9d9': '#ffffff'">
                            <span class="fa"
                                [ngClass]="this.btnColorChange5 ?  'fa-check-circle-o' : 'fa-circle-o' "></span>
                            {{'admin_hub_hs_inactive_lbl'|translate}}
                        </button>
                    </div>
                </div> -->

                <div class="row mt-3">
                    <div class="col-sm-4"></div>
                    <div class="col-sm-4"></div>
                    <div class="col-sm-4 div-align-items-right">
                        <button class="input widgetButtonLarge btnFontSizeHUB" (click)="reset()">{{'admin_ru_search_clear_btn' |
                            translate | uppercase}}
                        </button>
                        <button [disabled]="!(this.hubForm.get('hubProcessOwner').value != null && this.hubForm.get('hubProcessOwner').value != '') &&
                        !(this.hubForm.get('business').value != null && this.hubForm.get('business').value != '') &&
                        !(this.hubForm.get('bu').value != null && this.hubForm.get('bu').value != '') &&
                        !(this.hubForm.get('pg').value != null && this.hubForm.get('pg').value != '') &&
                        !(this.hubForm.get('belongCountries').value != null && this.hubForm.get('belongCountries').value != '') &&
                        !(this.hubForm.get('country').value != null && this.hubForm.get('country').value != '')"
                            class="widgetButtonLarge ms-1 btnFontSizeHUB" (click)="getSearchDetails()">{{'admin_ru_search_search_btn' |
                            translate | uppercase}}
                        </button>
                    </div>
                </div>

                <div class="forExportToExcel mt-2" *ngIf="showExportToExcel">
                    <a class="exportcls" *ngIf="!IsHubsExcelGenerating" (click)="clickExportToExcel()">
                        <span class="glyphicon glyphicon-save export-btn"></span>&nbsp;Export to excel
                    </a>
                    <span *ngIf="IsHubsExcelGenerating">
                        <i class="fa fa-circle-o-notch fa-spin"></i>&nbsp;Generating...
                    </span>
                </div>

                <div class="spacebtnExportToCard">
                    <div *ngIf="noResult" class="forNoResultText">
                        {{noResultInfo}}
                    </div>
                    <div *ngIf="!(this.hubForm.get('hubProcessOwner').value != null && this.hubForm.get('hubProcessOwner').value != '') &&
                    !(this.hubForm.get('business').value != null && this.hubForm.get('business').value != '') &&
                    !(this.hubForm.get('bu').value != null && this.hubForm.get('bu').value != '') &&
                    !(this.hubForm.get('pg').value != null && this.hubForm.get('pg').value != '') &&
                    !(this.hubForm.get('belongCountries').value != null && this.hubForm.get('belongCountries').value != '') &&
                    !(this.hubForm.get('country').value != null && this.hubForm.get('country').value != '')" class="forNoResultText">
                      {{'admin_hub_select_search_criteria_msg' | translate }}
                    </div>
                    <div class="row">
                        <div class="col-md-2 col-sm-3 ps-0 pe-0 forCard cardClassHub"
                            *ngFor="let hubData of hubDetailsAsPerPagination; let i = index">
                            <p-card class="p-card p-component mt-5 mb-5 mx-5" [style]="{ width: '100%' }">
                                <ng-template pTemplate="header">
                                    <div class="panel-heading">
                                        <h4 class="panel-title">
                                            <div class="cardFirstLine">
                                                <div>
                                                  <div class="cardHeaderResponsive">
                                                    <input *ngIf="flagFromSearch" type="checkbox" class="forCheckbox"
                                                        id="check" [checked]="hubData.selectedCheck"
                                                        formControlName="checkBox"
                                                        (click)="checkselectedval($event,hubData , i )">
                                                    <span class="">
                                                        <strong>{{'admin_hub_code_lbl' | translate}}: </strong>
                                                        <strong pTooltip="{{hubData.hubCode}}" tooltipPosition="top" class="cardHeadertitleAddPopup"> {{
                                                            hubData.hubCode }} </strong>
                                                    </span>
                                                  </div>
                                                    <br/>
                                                    <div class="btnOuter">
                                                    <span class="navbar-right addPopUpBtnHub">
                                                        <button *ngIf="flagFromSearch" type="button"
                                                            class="btn btn-sm btn-xs-Branding"
                                                            (click)="showHubAddPersonPopup(hubData, filteredHubDetails, i)">
                                                            {{'admin_hub_addperson_btn'|translate}}
                                                        </button>
                                                    </span>
                                                </div>

                                                    <div class="cardHeader cardSubHeaderResponsive">
                                                        <span>
                                                            <strong>{{'admin_hub_name_lbl' |
                                                                translate}}:</strong>
                                                            {{hubData.hubName}}, {{hubData.leadCountryName}}
                                                        </span>
                                                    </div>

                                                    <div *ngIf="hubDataPgCodeCheck(hubData)">
                                                        <span>
                                                            <strong>{{'cc_admin_hub_included_pgs' |
                                                                translate}}:</strong>
                                                            {{hubData.pgCodes.join(', ')}}
                                                        </span>
                                                    </div>

                                                </div>
                                            </div>
                                        </h4>
                                    </div>
                                </ng-template>

                                <ng-template pTemplate="body">
                                    <span>{{'admin_hubdetails_hubpersons_lbl' | translate}}:</span>
                                    <ul class="list-unstyled">
                                        <li *ngFor="let po of hubPersonInfo">
                                            <span class="process-owner">
                                                <span class="glyphicon glyphicon-user spacebtnIconandName"
                                                    *ngIf="hubDataIconCheck(po, hubData.hubProcessOwnerGeids)"></span>
                                                <span class="processOwner" [innerHtml]="hubDataProcess(po, hubData.hubProcessOwnerGeids)"
                                                    onMouseOver="this.style.color='#ff0000'"
                                                    (click)="gethubDataProcess(po, hubData)"
                                                    onMouseOut="this.style.color='#000000'" container="body"
                                                    [ngbPopover]="popContentHubProcessOwner"
                                                    [popoverTitle]="popoverTitleHubProcessOwner"></span>
                                                <span *ngIf="flagFromSearch">
                                                    <span class="glyphicon glyphicon-trash spacebtnNameandIcon"
                                                        *ngIf="hubDataIconbinCheck(po, hubData)"
                                                        (click)="showHubDeletePersonPopup(po, hubData)"
                                                        [pTooltip]="deleteProcessOwnerTooltipInfo" [escape]="false"
                                                        tooltipPosition="top" tooltipStyleClass="toolTipClass"></span>
                                                    <span class="glyphicon glyphicon-trash spacebtnNameandIcon"
                                                        *ngIf="hubPersonDelete(po, hubData)"
                                                        [pTooltip]="cantAbleToDeleteProcessOwner" [escape]="false"
                                                        tooltipPosition="top" tooltipStyleClass="toolTipClass"></span>
                                                </span>
                                            </span>
                                        </li>
                                    </ul>
                                    <div class="float-end">
                                        <button role="button" class="btn btn-default btn-xs glyphiconBtnHubs"
                                            (click)="showProcessOwners(hubData)" [pTooltip]="showProcessOwnerTooltip"
                                            [escape]="false" tooltipPosition="top" tooltipStyleClass="toolTipClass">
                                            <i class="glyphicon glyphicon-user"></i>
                                            <i class="fa fa-angle-down" *ngIf="!hubData.showContacts"></i>
                                            <i class="fa fa-angle-up" *ngIf="hubData.showContacts"></i>
                                        </button>
                                    </div>
                                    <div class="row col-s-12 col-md-12 col-lg-12">
                                        <div *ngIf="hubData.showContacts">
                                            <div>
                                                <span class="forProcessOwner">
                                                    {{'admin_hubdetails_hubprocowner_lbl' |translate}}:
                                                </span>
                                                <ul class="list-unstyled ms-3">
                                                    <li *ngFor="let k of hubEmployeeInfo(i); let m = index">
                                                        <span class="processOwner" (click)="getProcessOwnerclick(k.id, i)" container="body"
                                                            [ngbPopover]="popContentProcessOwner"
                                                            [popoverTitle]="popoverTitleProcessOwner"
                                                            onMouseOver="this.style.color='#ff0000'"
                                                            onMouseOut="this.style.color='#000000'">
                                                            {{k?.name ? k.name : null }}
                                                            {{this.getprocessownersDetails(this.filteredprocessownersDetails[m]?.res[m])}}
                                                        </span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </ng-template>
                                <!-- Hover Popup start -->
                                <ng-template #popoverTitleHubProcessOwner>
                                    <span class="popover-title popoverTitle">
                                        {{ this.getrubsObjinfo?.name }} ({{this.getrubsObjinfo?.displayGeid}})
                                    </span>
                                </ng-template>
                                <ng-template #popContentHubProcessOwner style="position: relative">
                                    <div>
                                        <div style="margin-top: -12px;">
                                            <span class="popoverCountry">
                                                {{this.getrubsObjinfo.address.countryCode}},
                                                {{this.getrubsObjinfo.address.city}},
                                                {{this.getrubsObjinfo.address.address}}
                                            </span>
                                            <span>
                                                <a href="https://maps.google.pl/?q={{ this.getrubsObjinfo.address.countryCode}}, {{ this.getrubsObjinfo.address.city}}, {{this.getrubsObjinfo.address.address}}"
                                                    target="_blank" class="gray-link">
                                                    <i class="hitachiicon-location"> </i>
                                                </a>
                                            </span>
                                            <br>
                                            <span>
                                                <i class="glyphicon glyphicon-envelope"></i>&nbsp;
                                                <a class="mail popoverMail" onMouseOver="this.style.color='#ff0000'"
                                                    onMouseOut="this.style.color='#000000'"
                                                    href="mailto:{{ this.getrubsObjinfo.email}}">{{this.getrubsObjinfo?.email}}</a>
                                            </span>
                                            <br>

                                            <span *ngIf="this.getrubsObjinfo.phone !== '' && this.getrubsObjinfo.phone !== null">
                                                <i class="glyphicon glyphicon-phone"></i>&nbsp;
                                                <a class="phone popoverPhone" onMouseOver="this.style.color='#ff0000'"
                                                    onMouseOut="this.style.color='#000000'"
                                                    href="tel:{{this.getrubsObjinfo.phone}}">{{this.getrubsObjinfo.phone}}</a>
                                            </span>

                                        </div>
                                        <strong class="associatedHubs mb-1">
                                            {{'admin_hub_person_associatedrus_lbl' |translate}}
                                        </strong>

                                        <div class="row mt-2">
                                            <div class="col-sm-12 process-owner" *ngIf="this.hubsForProcessOwner.length > 0">
                                                <span style="background-color: #f7f7f7;">
                                                    {{'admin_hub_person_hubprocessowner_lbl'|translate}}
                                                    ({{this.iscountprocessorowner}})
                                                </span>
                                            </div>
                                            <div>
                                                <div *ngFor="let hub of hubsForProcessOwner" class="associatedhub-list">
                                                    <small pTooltip="{{hub}}" tooltipPosition="top" placement="top">
                                                        {{hub}}
                                                    </small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </ng-template>
                                <!-- Hover Popup End -->

                                <!-- Hover for Process Owner -->
                                <ng-template #popoverTitleProcessOwner>
                                    <span class="popover-title popoverTitle">
                                        {{ this.getProcesOwnerhoverdata?.name }}
                                        ({{this.getProcesOwnerhoverdata?.displayGeid}})
                                    </span>
                                </ng-template>
                                <ng-template #popContentProcessOwner style="position: relative">
                                    <div>
                                        <div style="margin-top: -12px;">
                                            <span class="popoverCountry">
                                                {{this.getProcesOwnerhoverdata?.address?.countryCode}},
                                                {{this.getProcesOwnerhoverdata?.address?.city}},
                                                {{this.getProcesOwnerhoverdata?.address?.address}}
                                            </span>
                                            <span>
                                                <a href="https://maps.google.pl/?q={{ this.getProcesOwnerhoverdata?.address?.countryCode}}, {{ this.getProcesOwnerhoverdata?.address?.city}}, {{this.getProcesOwnerhoverdata?.address?.address}}"
                                                    target="_blank" class="gray-link">
                                                    <i class="hitachiicon-location"> </i>
                                                </a>
                                            </span>
                                            <br>
                                            <span>
                                                <i class="glyphicon glyphicon-envelope"></i>&nbsp;
                                                <a class="mail popoverMail" onMouseOver="this.style.color='#ff0000'"
                                                    onMouseOut="this.style.color='#000000'"
                                                    href="mailto:{{ this.getProcesOwnerhoverdata?.email}}">{{this.getProcesOwnerhoverdata?.email}}</a>
                                            </span>
                                            <br>

                                            <span *ngIf="this.getProcesOwnerhoverdata.phone !== '' && this.getProcesOwnerhoverdata.phone !== null">
                                                <i class="glyphicon glyphicon-phone"></i>&nbsp;
                                                <a class="phone popoverPhone" onMouseOver="this.style.color='#ff0000'"
                                                    onMouseOut="this.style.color='#000000'"
                                                    href="tel:{{this.getProcesOwnerhoverdata?.phone}}">{{this.getProcesOwnerhoverdata?.phone}}</a>
                                            </span>

                                        </div>
                                    </div>
                                </ng-template>
                            </p-card>
                        </div>
                    </div>
                </div>

                <div class="row" *ngIf="showExportToExcel">
                    <div class="col-sm-4"></div>
                    <div class="col-sm-4 div-align-items-center">
                        <ul class="pagination">
                            <li class="{{hasPrevious()?'':'disabled'}}" (click)="previousClicked()"><a>&laquo;</a></li>
                            <li *ngIf="showFirstPage()" (click)="gotoPage(1)"><a>1</a></li>
                            <li *ngIf="showFirstPageSeparator()"><span>...</span></li>
                            <li *ngIf="hasPrevious()" (click)="gotoPage(previousPage())"><a>{{previousPage()}}</a></li>
                            <li class="active"><a>{{currentPage}}</a></li>
                            <li *ngIf="hasNext()" (click)="gotoPage(nextPage())"><a>{{nextPage()}}</a></li>
                            <li *ngIf="showLastPageSeparator()"><span>...</span></li>
                            <li *ngIf="showLastPage()" (click)="gotoPage(pagesCount)"><a>{{pagesCount}}</a></li>
                            <li class="{{hasNext()?'':'disabled'}}"><a (click)="nextClicked()">&raquo;</a></li>
                        </ul>
                    </div>
                    <div class="col-sm-4"></div>
                </div>
            </p-accordionTab>
        </p-accordion>
    </div>
</form>
