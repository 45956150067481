<li class="mb-2">
    <!-- {{member |json}} -->
    <!-- {{allowActions}} -->
    <!-- {{level}} -->
    <div [ngSwitch]="member?.isActive">
        <span *ngIf="member?.isExpanded">
            <i class="fa fa-caret-down"
               pTooltip="{{'admin_item_hide_tp' | translate}}" tooltipPosition="top"
               style="text-decoration: none; cursor: pointer;"
               (click)="expand(member.isExpanded)"></i>
        </span>
        <span *ngIf="!member?.isExpanded && member?.hasChildren">
            <i class="fa fa-caret-right"
               style="text-decoration: none; cursor: pointer;"
               pTooltip="{{'admin_item_show_tp' | translate}}" tooltipPosition="top"
               (click)="expand(member.isExpanded)"></i>
        </span>

        <span *ngSwitchCase="false">
            <span class="text-muted"> {{member?.name}}</span>
            &nbsp;
            <a (click)="edit(member)"
                pTooltip="{{'admin_item_edit_tp' | translate}}" tooltipPosition="top"
               style="text-decoration: none; cursor: pointer;"
               *ngIf="member?.id>0 && allowActions">
                <i class="fa fa-edit iconStyle"></i>
            </a>
        </span>
        <span *ngSwitchDefault>
            {{member?.name}}
            &nbsp;
            <a (click)="addFm(member)"
                pTooltip="{{'admin_item_add_tp' | translate}}" tooltipPosition="top"
                *ngIf="allowActions && level < MAX_CHILD_LEVEL && (member?.tag?.isChildAllowed && level >= 2)"
               style="text-decoration: none; cursor: pointer;">
                <i class="fa fa-plus iconStyle"></i>
            </a> &nbsp;
            <a (click)="edit(member)"
                pTooltip="{{'admin_item_edit_tp' | translate}}" tooltipPosition="top"
               style="text-decoration: none; cursor: pointer;"
               *ngIf="member?.id>0 && allowActions && (member?.tag?.isChildAllowed && level >= 3)">
                <i class="fa fa-edit iconStyle"></i>
            </a> &nbsp;
            <a (click)="remove(member)"
                pTooltip="{{'admin_item_deactivate_tp' | translate}}" tooltipPosition="top"
               style="text-decoration: none; cursor: pointer;"
               *ngIf="member?.id>0 && allowActions && (member?.tag?.isChildAllowed && level >= 3)">
                <i class="fa fa-trash-o iconStyle"></i>
            </a> &nbsp;
        </span>
    </div>
</li>

<div *ngIf="member?.children">
    <app-failuremode-treeitem *ngIf="member?.isExpanded" [collection]="member?.children"
    [canManage]="allowActions" [level]="level"></app-failuremode-treeitem>
</div>
