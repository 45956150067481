<div class="panel panel-default">
  <div class="panel-heading">
    <span class="panel-title">
      <div class="row">
        <div class="col-sm-10">
          <span class="label dashboard-status-label label-default">{{item.issueStatus}}</span>
          |
          <a routerLink="../complaint/{{item.id}}" target="_blank">{{item.issueNumber}}</a>
          |
          <span appToolTip [tooltip]="item.subject" placement="top"><strong>{{'issuesubject_globalsearch' |
              translate}}:</strong> <span>{{item.subject | shortenComplaintSubject:45}}</span></span>
          |
          <span class="creation-datetext"><strong>{{'creationdate_globalsearch' | translate}}:</strong>
            {{item.creationDate}}</span>
        </div>
        <div class="col-sm-2">
          <span style="float: right;">
            <button role="button" class="btn btn-default btn-sm" (click)="changeVisibility()">
              <i class="fa fa-angle-double-down" *ngIf="areHitsHidden"></i>
              <i class="fa fa-angle-double-up" *ngIf="!areHitsHidden"></i>
            </button>
          </span>
        </div>
      </div>
    </span>
  </div>
  <div class="panel-body bg-white1" *ngIf="!areHitsHidden">
    <div class="search-container">
      <span class="well well-sm" style="display: inline-block; margin-left: 10px;" *ngFor="let h of item.highlights">
        <strong>{{ h.fieldName + '_globalsearch' | translate}}: </strong>
        <span *ngFor="let d of h.highlights">
          <span innerHTML="{{d}}"></span>
        </span>
      </span>
    </div>
  </div>
</div>
<div style='clear: both;'></div>
