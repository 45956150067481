import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SessionStorageServiceService } from 'src/app/services/session-storage-service.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AdminEscalationTimeServiceService {

  constructor(private http:HttpClient,private sessionStrg:SessionStorageServiceService) { }

  getAllEscalations(){
    return this.http.get(`${environment.azure.baseUrl}api/v1/escalations`);
  }

  saveEscalations(saveData){
    return this.http.post<any>(`${environment.azure.baseUrl}api/v1/escalations`,saveData);
  }

  calculateHours(maxHoursRange) {
    if (!maxHoursRange)
        maxHoursRange = 72;

    let hours = [];
    for (var i = 12; i <= maxHoursRange; i = i + 12) {
        hours.push(i.toString());
    }
    return hours;
  };

  userInfo = this.sessionStrg.getData('userInfo');

  canManage(divCode:any) {
    if (this.userInfo.hasGlobalPermissions || this.userInfo.isSupportDeskMember) {
      return true;
    }

    return false;
  };

}
