import { Component, OnInit } from '@angular/core';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { ReportsSettingsService } from 'src/app/services/reports-settings.service';
import { SessionStorageServiceService } from 'src/app/services/session-storage-service.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-reports-menu',
  templateUrl: './reports-menu.component.html',
  styleUrls: ['./reports-menu.component.css']
})
export class ReportsMenuComponent implements OnInit {

  userInfo: any;
  analyzerReportPath:any;
  analyzerArchiveReportPath:any;
  analyzerOldReportPath:any;
  //accessForSelectedRole: any;
  constructor(private reportSetngsService: ReportsSettingsService,
    public breadcrumbService: BreadcrumbService, private sessionStorage: SessionStorageServiceService) { }

  ngOnInit(): void {
    this.breadcrumbService.menuItems = [
      {label: 'REPORTS'}
    ];

    setTimeout(() => {
        this.userInfo = this.sessionStorage.getData('userInfo');
        //this.accessForSelectedRole = this.userInfo?.isSupportDeskMember;
    }, 1000)

    this.analyzerReportPath=environment.reports.analyzerReportPath;
    this.analyzerArchiveReportPath=environment.reports.analyzerArchiveReportPath;
    this.analyzerOldReportPath=environment.reports.analyzerOldReportPath;
  }

  checkAccessPermission(accessCode) {
    return this.userInfo?.accessPermissions?.includes(accessCode);
  }

}
