import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ExternalpageEmitService } from '../externalpage-emit.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-survey-template',
  templateUrl: './survey-template.component.html',
  styleUrls: ['./survey-template.component.css']
})
export class SurveyTemplateComponent implements OnInit {

  surveyForm: FormGroup;
  encodedURI: any;
  inputURI: any;
  receivedQuestionsData: any;
  rating:any;

  constructor(private externalPageService: ExternalpageEmitService,
    private route: ActivatedRoute,
    public router: Router,
    private fb: FormBuilder, private spinnerService: NgxSpinnerService) { }

  ngOnInit(): void {
    this.externalPageService.setExternalPageFunction(true);
    this.inputURI = this.route.snapshot.paramMap.get('id');
    this.rating = this.route.snapshot.paramMap.get('rating');
    const decodedURI = atob(this.inputURI);
    this.encodedURI = btoa(decodedURI);

    this.surveyForm = this.fb.group({
      question1: [],
      satisfactionRating: [,Validators.required],
      question2: [],
      effortRating: [],
      question3: [],
      feedbackComments: []
    })

    if(this.rating){
      this.surveyForm.patchValue({
        satisfactionRating : this.rating
      })
    }

    if (this.encodedURI) {
      this.spinnerService.show();
      this.externalPageService.getSurveyTemplateDetails(this.encodedURI).subscribe(
        (data: any) => {
          if (data?.url) {
            window.location.href = data?.url;
            this.spinnerService.hide();
          }
          else {
            this.receivedQuestionsData = data;

            this.surveyForm.patchValue({
              question1: 'question_' + this.receivedQuestionsData?.surveyQuestion[0]?.id,
              question2: 'question_' + this.receivedQuestionsData?.surveyQuestion[1]?.id,
              question3: 'question_' + this.receivedQuestionsData?.surveyQuestion[2]?.id
            });
          }

          this.spinnerService.hide();
        },
        (err) => {
          this.spinnerService.hide();
        }
      );
    }

  }

  submitSurvey() {

    let formatedData = {
      "culture": "en",
      "encodedParams": this.encodedURI,
      "surveyQuestionsInputs": [{
        "question": this.surveyForm.controls['question1']?.value,
        "answer": this.surveyForm.controls['satisfactionRating'].value ? this.surveyForm.controls['satisfactionRating'].value : ''
      },
      {
        "question": this.surveyForm.controls['question2']?.value,
        "answer": this.surveyForm.controls['effortRating'].value ? this.surveyForm.controls['effortRating'].value : ''
      },
      {
        "question": this.surveyForm.controls['question3']?.value,
        "answer": this.surveyForm.controls['feedbackComments'].value ? this.surveyForm.controls['feedbackComments'].value : ''
      }]
    }

    this.spinnerService.show();
    this.externalPageService.postSurveyTemplateDetails(formatedData).subscribe(
      (data) => {
        this.spinnerService.hide();
        window.location.href = data?.url;
      }
    )
  }

}
