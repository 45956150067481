import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MessageService, PrimeNGConfig } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { AdminCustomerContactsService } from 'src/app/services/admin-customer-contacts.service';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-edit-contact',
  templateUrl: './edit-contact.component.html',
  styleUrls: ['./edit-contact.component.css']
})
export class EditContactComponent implements OnInit {

  @Input() filterSearchCustomerDetails: any;
  @Input() custObj: any;
  @Input() searchCustData: any;
  @Input() ReceivedEditableData: any;
  admEditContactForm: FormGroup;
  receivingEditableCustomerObj: any;
  editCustomerObj: any;
  checkEnable: any;
  isChecked: boolean = false;

  constructor(
    private fb: FormBuilder,
    private primengConfig: PrimeNGConfig,
    private dialogService: DialogService,
    private ref: DynamicDialogRef,
    private messageService: MessageService,
    private admCustomer: AdminCustomerContactsService,
    private sharedService: SharedService,
  ) { }

  ngOnInit(): void {
    this.admEditContactForm = this.fb.group({
      fname: ['', [Validators.required, Validators.maxLength(100)]],
      lname: ['', [Validators.required, Validators.maxLength(100)]],
      email: ['', [Validators.email, Validators.required, Validators.maxLength(255)]],
      phone: [''],
      isActive: [false]
    })

    this.ReceivedEditableData = this.sharedService.getSearchContactObject();
    this.searchCustData = this.sharedService.getSearchObject();

    this.receivingEditableCustomerObj = this.sharedService.getEditableCustomerObject();
    if (this.receivingEditableCustomerObj.length > 0 || this.receivingEditableCustomerObj != null) {
      this.admEditContactForm.controls['fname'].patchValue(this.receivingEditableCustomerObj.firstName);
      this.admEditContactForm.controls['lname'].patchValue(this.receivingEditableCustomerObj.lastName);
      this.admEditContactForm.controls['email'].patchValue(this.receivingEditableCustomerObj.email);
      this.admEditContactForm.controls['phone'].patchValue(this.receivingEditableCustomerObj.phone);
      this.admEditContactForm.controls['isActive'].patchValue(this.receivingEditableCustomerObj.isActive);
      if (this.receivingEditableCustomerObj.isActive == true) {
        this.isChecked = true;
      }
    }
  }
  toggleCheckboxBoolean(event) {
    this.checkEnable = this.admEditContactForm.get('isActive')
    if (this.checkEnable) {
      this.admEditContactForm.controls['isActive'].setValue(!this.checkEnable.value)
      this.isChecked = event.target.checked;
    }
  }

  editAndSaveContacts() {
    this.editCustomerObj =
    {
      "id": this.receivingEditableCustomerObj.id,
      "firstName": this.admEditContactForm.controls['fname'].value,
      "lastName": this.admEditContactForm.controls['lname'].value,
      "phone": this.admEditContactForm.controls['phone'].value ? this.admEditContactForm.controls['phone'].value?.toString() : '',
      "email": this.admEditContactForm.controls['email'].value,
      "isActive": this.isChecked,
      "customer": {
        "id": this.ReceivedEditableData.id,
        "guid": this.ReceivedEditableData.guid,
        "streetName": this.ReceivedEditableData.streetName,
        "city": this.ReceivedEditableData.city,
        "stateDistrict": this.ReceivedEditableData.stateDistrict,
        "countryCode": this.ReceivedEditableData.countryCode,
        "companyFullName": this.ReceivedEditableData.companyFullName
      }
    }
    this.admCustomer.updateCustomerContact(this.editCustomerObj).subscribe((resp) => {
      const updatedData = resp;
      if (updatedData == null) {
        this.admCustomer.getCustomerSearch(this.searchCustData)
        this.ref.close(this.searchCustData);
      }
    })
  }

  cancel() {
    this.ref.close();
  }
  keyPressNumbers($event) {
    // var charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9
    if (($event.keyCode < 48 || $event.keyCode > 57) ||  ($event.key == '-' || $event.key == '+'  )) {
      event.preventDefault();
      return false;
    
    } else {
      return true;
    }
    
  }
  

}
