import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { AdUserProfile } from '../models/Common/profile.model';

const GRAPH_ENDPOINT = 'https://graph.microsoft.com/v1.0/me';
const GRAPH_ENDPOINT_PIC = 'https://graph.microsoft.com/v1.0/me/photo/$value';

@Injectable({
  providedIn: 'root'
})
export class AzureADUserStatusService {

  // Create this component to share isUser Logged in
  isUserLoggedIn:Subject<boolean> = new Subject<boolean>();

  constructor(private httpClient:HttpClient) { }

  // We can get User Profile from here if required from Azure AD
  getUserProfile(){
    return this.httpClient.get<AdUserProfile>(GRAPH_ENDPOINT);
  }

  getUserProfilePic(){
    return this.httpClient.get(GRAPH_ENDPOINT_PIC,
      {responseType:'blob'});
  }
}
