<form [formGroup]="resolvingunitaddprimaryForm">

    <div class="resolvingunitaddprimaryinfo-popup">







        <div class="panel-title ">

            <!-- <div>

            <span class="glyphicon glyphicon-home"></span>

            <small>{{userinfo.cardinfo.divCode}}, {{userinfo.cardinfo.buCode}}, {{userinfo.cardinfo.pgCode}} {{userinfo.cardinfo.pgName}}</small>

            <small>{{userinfo.cardinfo.balId}}</small><small ng-hide="itemScope.isGlobal">, {{userinfo.cardinfo.abacusCode}}, {{userinfo.cardinfo.laName}}</small><small *ngIf="userinfo.isGlobal">, GLOBAL</small>

        </div> -->

            <div>
                <span class="glyphicon glyphicon-home setprimarytitle"></span>
                <span class="settitlecontent">{{ userinfo.cardinfo.divCode }},
                    <span *ngIf="!userinfo.cardinfo.isGlobal">{{ userinfo.cardinfo.buCode }},
                    {{ userinfo.cardinfo.pgCode }},
                    {{ userinfo.cardinfo.pgName }},</span>
                    <span>{{ userinfo.cardinfo.balId }},</span>
                    <span *ngIf="!userinfo.cardinfo.isGlobal">{{ userinfo.cardinfo.abacusCode }},
                    {{ userinfo.cardinfo.laName }}</span>
                    <span *ngIf="userinfo.cardinfo.isGlobal"> GLOBAL</span></span>
            </div>
            <div *ngIf="!userinfo.cardinfo.isGlobal">
                <span class="setAddressContent">{{userinfo.cardinfo.address.city}},
                    {{userinfo.cardinfo.address.address}},
                    {{userinfo.cardinfo.address.countryCode}}</span>
                <a href="https://maps.google.pl/?q= {{userinfo.cardinfo.address.city}}, {{userinfo.cardinfo.address.address}}, {{userinfo.cardinfo.address.countryCode}}"
                    target="_blank" class="gray-link" uib-tooltip="Google Maps view">
                    <i class="hitachiicon-location"> </i>
                </a>
                
            </div>

        <!-- </div> -->
        




        <!-- <div class="primaryheader"> -->

            <div class="col-md-12">
                
                <p class="control-label mb-0 primaryheaderAddPrimaryInfoResolvingUnit">

                    {{'primaryrucoordinator_question_part_mod_lbl'|translate}}

                </p>

                <strong  onMouseOut="this.style.color='#000000'"
                onMouseOver="this.style.color='#ff0000'"
                onMouseOut="this.style.color='#000000'"
                [ngbPopover]="popContentruProcessOwner"
                [popoverTitle]="popoverTitleContentruProcessOwne" [placement]="'bottom'"
                container="body" class="processOwner"
                (click)="gethubDataProcess()"> {{ userinfo.empinfo.name }}</strong></div>
            </div>

        <!-- </div> -->






   <!-- Hover Popup start -->
                                 
   <ng-template #popoverTitleContentruProcessOwne>
                                   
    <span class="popover-title">
        {{ this.userinfo.getrubsObjinfo.name }}({{this.userinfo.getrubsObjinfo.displayGeid}})
       
    </span>

</ng-template>

<ng-template #popContentruProcessOwner style="position: relative">
 
    <div>
        <div>
            <!-- {{'admin_hubdetails_hubprocowner_lbl' |translate}}: -->
            <span>
                {{this.userinfo.getrubsObjinfo.address.countryCode}},
                {{this.userinfo.getrubsObjinfo.address.address}}
            </span>
            <a href="https://maps.google.pl/?q={{ this.userinfo.getrubsObjinfo.address.countryCode}},  {{this.userinfo.getrubsObjinfo.address.address}}"
                target="_blank" class="gray-link">
                <i class="hitachiicon-location"> </i>
            </a>
            <br>
            <span *ngIf="this.userinfo.getrubsObjinfo.email">
                <i class="glyphicon glyphicon-envelope"></i>&nbsp;
                <a class="mail" onMouseOver="this.style.color='#ff0000'"
                    onMouseOut="this.style.color='#000000'"
                    href="mailto:{{ this.userinfo.getrubsObjinfo.email}}">{{this.userinfo.getrubsObjinfo?.email}}</a>
            </span>
            <br>
            <span *ngIf="this.userinfo.getrubsObjinfo.phone">
                <i class="glyphicon glyphicon-phone"></i>&nbsp;
                <a class="phone" onMouseOver="this.style.color='#ff0000'"
                    onMouseOut="this.style.color='#000000'"
                    href="tel:{{this.userinfo.getrubsObjinfo.phone}}">{{this.userinfo.getrubsObjinfo.phone}}</a>
            </span>
        </div>
        <div>
        </div>
    </div>
    <br>
    <span> <strong><b style="font-weight: bold;">Associated RUs:</b></strong>
        <p style="background-color: #f7f7f7;">
            {{'admin_hubdetails_hubprocowner_lbl' |translate}}
            ({{this.iscountprocessorowner}})&nbsp;
           
        </p>

        <div class="row mt-2"
            *ngFor="let  ruProcessOwner of ruProcessOwnerResolvingUnitdata">
           
            <div class="col-sm-7">
              
            </div>
            <div >
                <small class="ng-binding">
                    {{ruProcessOwner.divCode}},
                    {{ruProcessOwner.buCode}},
                    {{ruProcessOwner.pgCode}}
                    {{' ' }}
                    {{ruProcessOwner.balId}},
                    {{ruProcessOwner.abacusCode}},

                </small>
                <small class="ng-binding">
                    {{ruProcessOwner.laName}},
                    {{' ' }}
                    {{ruProcessOwner?.address?.city}},
                    {{ruProcessOwner?.address?.address}}

                    {{ruProcessOwner?.address?.countryCode}},
                </small>
            </div>
        </div>
        <p style="background-color: #f7f7f7;">Coordinator&nbsp;
            ({{this.iscoordinaterowner}})&nbsp;
          
        </p>
        <div class="row mt-2"
            *ngFor="let  rucoordinatorProcessOwner of coordinatorResolvingUnitdata">


            <div class="col-sm-6">
                
            </div>
            <div>
                <small class="ng-binding">
                    {{rucoordinatorProcessOwner.divCode}},
                    {{rucoordinatorProcessOwner.buCode}},
                    {{rucoordinatorProcessOwner.pgCode}}
                    {{' ' }}
                    {{rucoordinatorProcessOwner.balId}},
                    {{rucoordinatorProcessOwner.abacusCode}},
                </small>
                <small class="ng-binding">
                    {{rucoordinatorProcessOwner.laName}},
                    {{' ' }},
                    {{rucoordinatorProcessOwner?.address?.city}},
                    {{rucoordinatorProcessOwner?.address?.address}}
                    {{rucoordinatorProcessOwner?.address?.countryCode}},
                </small>
            </div>
        </div>
    </span>
</ng-template>
<!-- Hover Popup End -->


        <div class="modal-footer">

            <button class="input widgetButtonLarge btnAddPrimaryPopUp" type="button"
                (click)="onClose()">{{'rupersondelete_mod_cancel_btn'|translate}}</button>

            <button class="input widgetButtonLarge btnAddPrimaryPopUp mx-2" type="button"
                (click)="addprimaryperson()">{{'rupersondelete_mod_ok_btn'|translate}}

            </button>

        </div>

    </div>




</form>