<div class="row mt-2 addPerson-popup addPerson_popup_ProcessOwner">
  <form [formGroup]="addPersonForm">
    <div class="row mt-2">
      <div class="col-sm-2">
        <label class="label-align-right label-bold"> {{'rusearch_mod_employees_lbl'|translate}} </label>
      </div>
      <div class="col-sm-10">
        <p-autoComplete [forceSelection]="true" [minLength]=1 formControlName="employee"
          placeholder="{{'system_user_mod_add_employee_ph'|translate}}" class="substituteCustom border-0" type="text"
          [suggestions]="filteredOwner" (completeMethod)="filterOwner($event?.query)" field="name">
          <ng-template let-filteredOwner pTemplate="selectedItem">
          </ng-template>
          <ng-template let-filteredOwner pTemplate="item">
            <div class="flex align-items-center gap-2" style="font-size: 12px;">
                  <div class="row">
                    <div class="col-8"><strong>{{ filteredOwner?.name }}</strong></div>
                    <div class="col-4 text-end"><i>{{filteredOwner?.city}}({{filteredOwner.iso}})</i></div>
                  </div>
                  <div class="row">
                    <div class="col">{{filteredOwner?.email}}</div>
                  </div>
            </div>
        </ng-template>
        </p-autoComplete>
        <span class="error" *ngIf="this.addPersonForm.get('employee').errors?.['required']">{{'podelete_mod_employeeempty_msg'|translate}}</span>
      </div>
    </div>
    <!-- <hr /> -->
    <div class="row mt-2">
      <div class="col-sm-2">
        <label for="bussines" class="label-align-right label-bold"> {{'rusearch_mod_div_lbl'|translate}} </label>
      </div>
      <input type="hidden" formControlName="userGEID" />
      <div class="col-sm-10">
        <p-dropdown [options]="divisions" optionValue="id" formControlName="business" optionLabel="id" [resetFilterOnHide]="true" [filter]="true"
          filterBy="name" [showClear]="true" placeholder="Click or type to select Business" (onChange)="onChangeBusiness($event)">
        </p-dropdown>
      </div>
    </div>

    <div class="row mt-2">
      <div class="col-sm-2">
        <label for="bu" class="label-align-right label-bold"> {{'rusearch_mod_bu_lbl'|translate}} </label>
      </div>
      <div class="col-sm-10">
        <p-dropdown [options]="bulist" optionValue="id" formControlName="bu" optionLabel="labelName" [resetFilterOnHide]="true" [filter]="true"
          filterBy="name" [showClear]="true" placeholder="Click or type to select BU" (onChange)="onChangeBu($event)">
        </p-dropdown>
      </div>
    </div>

    <div class="row mt-2">
      <div class="col-sm-2">
        <label for="pg" class="label-align-right label-bold"> {{'rusearch_mod_pg_lbl'|translate}}</label>
      </div>
      <div class="col-sm-10">
        <p-dropdown [options]="pglist" optionValue="id" formControlName="pg" optionLabel="labelName" [resetFilterOnHide]="true" [filter]="true"
          filterBy="name" [showClear]="true" placeholder="Click or type to select PG">
        </p-dropdown>
      </div>
    </div>
    <!-- <hr /> -->

    <div class="row mt-2">
      <div class="col-sm-2">
        <label for="region" class="label-align-right label-bold"> {{'rusearch_mod_reg_lbl'|translate}}</label>
      </div>
      <div class="col-sm-10">
        <p-dropdown [options]="regions" optionValue="id" formControlName="region" optionLabel="lableName" [resetFilterOnHide]="true" [filter]="true"
          filterBy="name" [showClear]="true" placeholder="Click or type to select Region" (onChange)="onChangeRegion($event)">
        </p-dropdown>
      </div>
    </div>

    <div class="row mt-2">
      <div class="col-sm-2">
        <label for="country" class="label-align-right label-bold"> {{'rusearch_mod_country_lbl'|translate}}</label>
      </div>
      <div class="col-sm-10">
        <p-dropdown [options]="countries" optionValue="id" formControlName="country" optionLabel="lableName"
          [showClear]="true" multiple="true" [resetFilterOnHide]="true" [filter]="true" filterBy="name"
          placeholder="Click or type to select country">
        </p-dropdown>
        <span class="error" *ngIf="!(this.addPersonForm.get('business').value != null && this.addPersonForm.get('business').value != '') &&
                                    !(this.addPersonForm.get('bu').value != null && this.addPersonForm.get('bu').value != '') &&
                                    !(this.addPersonForm.get('pg').value != null && this.addPersonForm.get('pg').value != '') &&
                                    !(this.addPersonForm.get('region').value != null && this.addPersonForm.get('region').value != '') &&
                                    !(this.addPersonForm.get('country').value != null && this.addPersonForm.get('country').value != '')">{{'podelete_mod_scopeempty_msg'|translate}}</span>
      </div>
    </div>
    <!-- <hr class="mt-3" /> -->

    <div class="modal-footer my-4">
      <!-- <div class="col-sm-3"> -->
        <button type="button" class="widgetButtoSmall" (click)="cancel()">{{'rusearch_mod_cancel_btn'|translate}}</button>
        <button type="button" class="widgetButtoSmall ms-1" [disabled]="this.addPersonForm.get('employee').errors?.['required'] ||
        !(this.addPersonForm.get('business').value != null && this.addPersonForm.get('business').value != '') &&
        !(this.addPersonForm.get('bu').value != null && this.addPersonForm.get('bu').value != '') &&
        !(this.addPersonForm.get('pg').value != null && this.addPersonForm.get('pg').value != '') &&
        !(this.addPersonForm.get('region').value != null && this.addPersonForm.get('region').value != '') &&
        !(this.addPersonForm.get('country').value != null && this.addPersonForm.get('country').value != '')" (click)="ok()">{{'poadd_mod_add_btn'|translate}}</button>
      <!-- </div> -->
    </div>
  </form>
</div>
