<form [formGroup]="resolvingunitaddpersonForm">
    <div class="resolvingunitaddperson-popup">
        <div class="row mt-2">
            <div class="col-sm-3 div-align-items-right addPopLabel label-bold">
                {{'rupersonadd_rucselectedrus_mod_lbl'|translate}}
            </div>
            <div class="col-sm-9">
                <span class="multiselect-field_remove addPopInput">
                    <p-autoComplete class="substituteCustom" [forceSelection]="true" formControlName="hubIds"
                        [multiple]="true" type="text" field="hubsData" [suggestions]="this.cardData">
                    </p-autoComplete>
                </span>
            </div>
        </div>


        <div class="row mt-2">
            <div class="col-sm-3 div-align-items-right addPopLabel label-bold">
                {{'rupersonadd_ruprocowners_mod_lbl'|translate}}
            </div>
            <div class="col-sm-9">
                <span class="multiselect-field_remove addPopInput">
                    <p-autoComplete class="substituteCustom" [forceSelection]="true" formControlName="processOwner"
                        [multiple]="true" type="text" [suggestions]="filteredSubstitude" [dropdownIcon]="'none'"
                        (completeMethod)="filterSubstitude($event)" field="name" (onSelect)="customerSelect($event)"
                        placeholder="Start typing to select RU Process Owners"
                        (onUnselect)="customerGuidsUnselect($event)">
                        <ng-template let-filteredSubstitute pTemplate="item">
                          <div class="flex align-items-center gap-2">
                                <div class="row">
                                  <div class="col-8 text-bold"><strong>{{ filteredSubstitute?.name }}</strong></div>
                                  <div class="col-4 text-end"><i>{{filteredSubstitute?.city}}({{filteredSubstitute.iso}})</i></div>
                                </div>
                                <div class="row">
                                  <div class="col">{{filteredSubstitute?.email}}</div>
                                </div>
                          </div>
                      </ng-template>
                    </p-autoComplete>
                </span>
            </div>
        </div>
        <div class="row mt-2">
            <div class="col-sm-3 div-align-items-right addPopLabel label-bold">
                {{'rupersonadd_rucoordinators_mod_lbl'|translate}}
            </div>
            <div class="col-sm-9">
                <span class="multiselect-field_remove addPopInput">
                    <p-autoComplete class="substituteCustom" [forceSelection]="true" formControlName="coordinator"
                        [multiple]="true" type="text" [suggestions]="filteredSubstitude" [dropdownIcon]="'none'"
                        (completeMethod)="filterSubstitude($event)" field="name"
                        (onSelect)="customerSelectcoordinator($event)" placeholder="Start typing to select Coordinators"
                        (onUnselect)="customerGuidsUnselectcoordinator($event)">
                        <ng-template let-filteredSubstitute pTemplate="item">
                          <div class="flex align-items-center gap-2">
                                <div class="row">
                                  <div class="col-8 text-bold"><strong>{{ filteredSubstitute?.name }}</strong></div>
                                  <div class="col-4 text-end"><i>{{filteredSubstitute?.city}}({{filteredSubstitute.iso}})</i></div>
                                </div>
                                <div class="row">
                                  <div class="col">{{filteredSubstitute?.email}}</div>
                                </div>
                          </div>
                      </ng-template>
                    </p-autoComplete>
                </span>
            </div>
        </div>

        <div class="row mt-2">
            <div class="col-sm-4"></div>
            <div class="col-sm-4"></div>
            <div class="col-sm-4 div-align-items-right pe-4 firefoxalign">
                <button type="button" class="input widgetButtonLarge btnAddPopRU" (click)="onClose()">Close</button>
                <button type="button"
                [disabled]=" this.resolvingunitaddpersonForm.invalid ||
                 !(
                        this.resolvingunitaddpersonForm.get('processOwner').value != null && this.resolvingunitaddpersonForm.get('processOwner').value != '' ||
                        this.resolvingunitaddpersonForm.get('coordinator').value != null && this.resolvingunitaddpersonForm.get('coordinator').value != '')"
                class="widgetButtonLarge ms-1 btnAddPopRU" (click)="add()">Add</button>
            </div>
        </div>
    </div>
</form>
