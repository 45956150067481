import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-globalsearch-details',
  templateUrl: './globalsearch-details.component.html',
  styleUrls: ['./globalsearch-details.component.css']
})
export class GlobalsearchDetailsComponent implements OnInit {

  areHitsHidden:boolean = true;
  @Input('item') item:any;

  constructor() { }

  ngOnInit(): void {
  }

  changeVisibility = function() {
    this.areHitsHidden = !this.areHitsHidden;
  }

}
