<div class="additional_subject_section">
  <form [formGroup]="additionalSubjectForm">
    <div class="inner-heading-wrapper">
      <h6 class="inner-heading1">{{ "ComplaintForm_ResolvingUnitSelectionName" | translate }}</h6>
    </div>
    <div class="row mb-1 additional-info">
      <div class="col remove-px">
        <div>
          <label>{{ "Complaint_ResolvingUnit" | translate }}<span class="asteriskRequired"></span></label>
        </div>
        <div>
          <input [pTooltip]="resolvingUnitTooltipText" [escape]="false" tooltipPosition="top"
            tooltipStyleClass="toolTipClass" type="text" class="form-control customtextfield-l resolvingUnitDisabled"
            pInputText
            [ngClass]="isSubmitted && this.additionalSubjectForm.controls['resolvingUnit'].invalid ? 'error-inputbox' : ''"
            formControlName="resolvingUnit" readonly="true" />
          <input type="hidden" formControlName="resolvingUnitArray">
          <button type="button" class="col-sm-1 widgetButtoSmall ms-2 mt-0 remove-ms add-mt-smalldevice"
            (click)="showResolvingUnitComponentPopup()">
            {{
            this.additionalSubjectForm.controls["resolvingUnit"].value == ""
            ? "Select"
            : "Change"
            }}
          </button>
        </div>
        <!-- [disabled]="!disableBtn['resolvingUnit']"
                      [ngClass]="!disableBtn['resolvingUnit']?'grayButton': ''"  -->
      </div>
      <div class="col remove-px">
        <div>
          <label>{{ "Complaint_ProposedResolutionOwner" | translate }}<span class="asteriskRequired"
              *ngIf="this.additionalSubjectForm.controls['proposedResolutionOwner']?.validator"></span></label>
        </div>
        <div class="multiselect-field_remove2">
          <p-autoComplete [forceSelection]="true" [minLength]=2 [pTooltip]="proposedResolutionOwnerText"
            [escape]="false" tooltipPosition="top" tooltipStyleClass="toolTipClass"
            class="form-control customtextfield-l border-0 propose-resolution-owner resolution-owner mt-0 remove-pe"
            [delay]="500" [minLength]="3"
            [ngClass]="{'error-autocomplete':isSubmitted && this.additionalSubjectForm.controls['proposedResolutionOwner'].invalid }"
            formControlName="proposedResolutionOwner" [multiple]="true" type="text" [suggestions]="filteredOwner"
            (completeMethod)="filterOwner($event?.query)" field="selectedLabel">
            <ng-template let-filteredOwner pTemplate="selectedItem">
              <div (mouseover)="ownerGotFocus(filteredOwner.tooltipInfo)" (mouseout)="ownerLostFocus()">
                {{filteredOwner.selectedLabel }}</div>
              <span *ngIf="!readonlyObj?.proposedResolutionOwnerReadOnly"
                class="p-autocomplete-token-icon pi pi-times-circle ng-tns-c68-7 ng-star-inserted"
                (click)="clearOwner()"></span>
            </ng-template>

            <ng-template let-filteredOwner pTemplate="item">
              <div class="flex align-items-center gap-2">
                <div>{{ filteredOwner.promptLabel }}</div>
              </div>
            </ng-template>
          </p-autoComplete>
        </div>
      </div>
    </div>

    <div class="row mb-1 additional-info">
      <div class="col remove-px">
        <div>
          <label class="label-class1">{{ "Complaint_ComplaintCoordinator" | translate
            }}<span class="asteriskRequired"></span></label>
        </div>
        <!-- <div class="multiselect-field"> -->
        <!-- <input type="text" class="form-control customtextfield-l" pInputText [ngClass]="
            isSubmitted &&
            this.additionalSubjectForm.controls['Coordinators'].invalid
              ? 'error-inputbox'
              : ''
          " formControlName="Coordinators"/> -->
        <div class="multiselect-field_remove issue-coordinator">
          <p-autoComplete formControlName="Coordinators"
            [ngClass]="{'error-autocomplete':isSubmitted && this.additionalSubjectForm.controls['Coordinators'].invalid}"
            class="form-control border-0 remove-ps coordinator-email readonlyclass" [pTooltip]="issueCoordinatorText"
            [escape]="false" tooltipStyleClass="toolTipClass-ROPerson" [suggestions]="coordinators" field="displayLabel"
            [multiple]="true" [readonly]="true">
            <!-- <ng-template let-coordinators pTemplate="item">
                                      <div class="flex align-items-center gap-2">
                                          <div>{{ coordinators.promptLabel }}</div>
                                      </div>
                                  </ng-template> -->
            <ng-template let-coordinators pTemplate="selectedItem">
              <div (mouseover)="coordinatorGotFocus(coordinators?.tooltipInfo)" (mouseout)="coordinatorLostFocus()">
                {{coordinators?.displayLabel }}</div>
            </ng-template>
          </p-autoComplete>
        </div>
        <!-- </div> -->
      </div>

    </div>
    <input type="hidden" formControlName="IsBUExceptionFieldsMandatory">
    <input type="hidden" formControlName="BuCode">

    <div class="row mb-1 additional-info readonly" hidden>
      <div class="col">
        <div>
          <label class="label-class1">Verification Team<span class="asteriskRequired"
              *ngIf="this.additionalSubjectForm.controls['verificationTeam']?.validator"></span></label>
        </div>
        <div class="multiselect-field">
          <p-autoComplete formControlName="verificationTeam" class="form-control customtextfield-l readonlyclass"
            field="displayLabel" [multiple]="true" [readonly]="true"
            [ngClass]="isSubmitted && this.additionalSubjectForm.controls['verificationTeam'].invalid ? 'error-autocomplete' : ''">
          </p-autoComplete>
        </div>
      </div>

    </div>
    <input type="hidden" formControlName="hiddenIsHVDCComplaint" />
    <input type="hidden" formControlName="hiddenPreviousClass" />
    <div *ngIf="complaintData?.header?.salesForceId">
      <div class="inner-heading-wrapper">
        <h6 class="inner-heading1">{{'ComplaintForm_SectionSalesForceDisplayName'| translate}}</h6>
      </div>
      <div class="row mb-1">
        <div class="col salesForceInfo additional-info">
          <div *ngIf="complaintData?.salesForceComplaintInfo?.originatingCountry">
            <span class="salesForceLabel">{{'Complaint_SFOriginatingCountry' | translate}}:
            </span>{{complaintData?.salesForceComplaintInfo?.originatingCountry}}
          </div>
          <div *ngIf="complaintData?.salesForceComplaintInfo?.division">
            <span class="salesForceLabel">{{'Complaint_SFDivision' | translate}}:
            </span>{{complaintData?.salesForceComplaintInfo?.division}}
          </div>
          <div *ngIf="complaintData?.salesForceComplaintInfo?.businessUnit">
            <span class="salesForceLabel">{{'Complaint_SFBusinessUnit' | translate}}:
            </span>{{complaintData?.salesForceComplaintInfo?.businessUnit}}
          </div>
          <div *ngIf="complaintData?.salesForceComplaintInfo?.productGroup">
            <span class="salesForceLabel">{{'Complaint_SFProductGroup' | translate}}:
            </span>{{complaintData?.salesForceComplaintInfo?.productGroup}}
          </div>
          <div *ngIf="complaintData?.salesForceComplaintInfo?.product">
            <span class="salesForceLabel">{{'Complaint_SFProduct' | translate}}:
            </span>{{complaintData?.salesForceComplaintInfo?.product}}
          </div>
          <div *ngIf="complaintData?.salesForceComplaintInfo?.abbOrderNumber">
            <span class="salesForceLabel">{{'Complaint_SFOrderNumber' | translate}}:
            </span>{{complaintData?.salesForceComplaintInfo?.abbOrderNumber}}
          </div>
          <div *ngIf="complaintData?.salesForceComplaintInfo?.salesOrderNumber">
            <span class="salesForceLabel">{{'Complaint_SFSalesOrderNumber' | translate}}:
            </span>{{complaintData?.salesForceComplaintInfo?.salesOrderNumber}}
          </div>
          <div *ngIf="complaintData?.salesForceComplaintInfo?.purchaseOrderNumber">
            <span class="salesForceLabel">{{'Complaint_SFSalesOrderNumber' | translate}}:
            </span>{{complaintData?.salesForceComplaintInfo?.purchaseOrderNumber}}
          </div>
        </div>
      </div>
      <!-- attachment from salesforce -->
      <div *ngIf="complaintData?.salesForceComplaintInfo?.attachments?.length > 0"
        class="row mb-1 me-5 attachments-section">
        <div class="col-md-3 col-sm-3 ps-0 pe-0 text-end">
          <label>Links to attachments from SalesForce</label>
        </div>
        <div class="col-md-8 col-sm-9 p-0 file-row">
          <div class="row"
            *ngFor="let informationAttachment of complaintData?.salesForceComplaintInfo?.attachments; let attachmentIndex = index;">
            <a class="fileInfo ms-1 mb-1" href="{{informationAttachment?.url}}"
              target="_blank">{{informationAttachment?.displayName}}</a>
          </div>
        </div>
      </div>
    </div>
    <div class="inner-heading-wrapper">
      <h6 class="inner-heading1">{{'ComplaintForm_WhatIsCustomerComplainingAboutName'| translate}}</h6>
    </div>
    <div class="row mb-1 additional-info">
      <div class="col">
        <div>
          <label>{{ "Complaint_Product" | translate }}<span class="asteriskRequired"></span></label>
        </div>
        <div>
          <input type="text" class="form-control customtextfield-l  additionalProduct productSection inputFieldDisabled"
            pInputText formControlName="ABBProductCategory" readonly="true" [pTooltip]="productInputText"
            [escape]="false" tooltipPosition="top" tooltipStyleClass="toolTipClass"
            [ngClass]="isSubmitted && this.additionalSubjectForm.controls['ABBProductCategory'].invalid ? 'error-inputbox' : ''" />
          <input type="hidden" formControlName="issueProductArray">
          <button class="col-sm-1 widgetButtoSmall ms-2 mt-0 remove-ms add-mt-smalldevice"
            (click)="productPopupDialog()" [disabled]="additionalSubjectForm.controls['resolvingUnit'].invalid"
            [ngClass]="this.additionalSubjectForm.controls['resolvingUnit'].invalid ? 'grayButton' : ''"
            [pTooltip]="this.additionalSubjectForm.controls['ABBProductCategory'].value=='' ? productButtonText : ''"
            [escape]="false" tooltipPosition="top" tooltipStyleClass="toolTipClass">
            {{this.additionalSubjectForm.controls['ABBProductCategory'].value=="" ? "Select" : "Change"}}
          </button>
        </div>
        <!-- [ngClass]="!disableBtn['issueProduct']?'grayButton': ''"
              [disabled]="!disableBtn['issueProduct']" -->
      </div>
      <div class="col">
        <div>
          <label>{{ "Complaint_FailureMode" | translate }}<span class="asteriskRequired"></span></label>
        </div>
        <div>
          <input type="text" readonly="true" [pTooltip]="failureModeInputText" [escape]="false" tooltipPosition="top"
            tooltipStyleClass="toolTipClass"
            class="form-control customtextfield-l additionalFailureMode productSection inputFieldDisabled"
            [ngClass]="isSubmitted && this.additionalSubjectForm.controls['failureMode'].invalid ? 'error-inputbox' : ''"
            pInputText formControlName="failureMode" maxlength="{{maxLength.FAILURE_MODE_DESC}}" />
          <input type="hidden" formControlName="failureModeBind">
          <button class="col-sm-1 widgetButtoSmall ms-2 mt-0 remove-ms add-mt-smalldevice" (click)="showComponent()"
            [disabled]="additionalSubjectForm.controls['resolvingUnit'].invalid"
            [ngClass]="this.additionalSubjectForm.controls['resolvingUnit'].invalid ? 'grayButton' : ''"
            [pTooltip]="this.additionalSubjectForm.controls['failureMode'].value=='' ? productButtonText : ''"
            [escape]="false" tooltipPosition="top" tooltipStyleClass="toolTipClass">
            {{this.additionalSubjectForm.controls['failureMode'].value=="" ? "Select" :
            "Change"}}

          </button>
        </div>
        <!-- [ngClass]="!disableBtn['failureMode']?'grayButton': ''"
              [disabled]="!disableBtn['failureMode']" -->
      </div>
    </div>
    <div class="row mb-1 additional-info" *ngIf="isEnableSAPQN || this.complaintData?.header?.sapId">
      <div class="col">
        <div>
          <label>{{ "Complaint_DefectType/DefectCodeGroupType" | translate }}<span class="asteriskRequired"
              *ngIf="this.additionalSubjectForm.controls['DefectTypeandDefectCodeGroupType']?.validator"></span></label>
        </div>
        <div>
          <input type="text" readonly="true" [pTooltip]="defectTypeTooltip" [escape]="false" tooltipPosition="top"
            tooltipStyleClass="toolTipClass" class="form-control customtextfield-l productSection resolvingUnitDisabled"
            [ngClass]="isSubmitted && this.additionalSubjectForm.controls['DefectTypeandDefectCodeGroupType'].invalid ? 'error-inputbox' : ''"
            pInputText formControlName="DefectTypeandDefectCodeGroupType" />
          <button class="col-sm-1 widgetButtoSmall ms-2 mt-0 remove-ms add-mt-smalldevice"
            (click)="showDefectTypePopup()" [disabled]="disableDefectType()"
            [ngClass]="disableDefectType() ? 'grayButton' : ''"
            [pTooltip]="this.additionalSubjectForm.controls['DefectTypeandDefectCodeGroupType'].value=='' ? productButtonText : ''"
            [escape]="false" tooltipPosition="top" tooltipStyleClass="toolTipClass">
            {{this.additionalSubjectForm.controls['DefectTypeandDefectCodeGroupType'].value=="" ? "Select" :
            "Change"}}</button>
          <input type="hidden" formControlName="defectTypeHiddenField">
          <input type="hidden" formControlName="defectCauseHiddenFieldAdnSub">
        </div>
      </div>
      <div class="col"></div>
    </div>
    <!-- <div class="inner-heading-wrapper">
      <h6 class="inner-heading1">{{ "ComplaintForm_ResolvingSubjectInformation" | translate }} </h6>
    </div> -->
    <div class="row mb-1">
      <div class="col additional-info">
        <div>
          <label> <span class="float-start">{{ "Complaint_ProductSerialNo" | translate }}<span class="asteriskRequired"
                *ngIf="this.additionalSubjectForm.controls['abbProductSerialNo']?.validator || this.fieldNameForFailureMode=='SerialNo'"></span>
            </span> <i [pTooltip]="serialNoText" [escape]="false" tooltipPosition="top" tooltipStyleClass="toolTipClass"
              class="pi pi-info-circle ms-2 infoIcon"></i></label>
        </div>

        <div>
          <input type="text" [pTooltip]="serialTooltipText" [escape]="false" tooltipPosition="top"
            tooltipStyleClass="toolTipClass"
            [ngClass]="isSubmitted && this.additionalSubjectForm.controls['abbProductSerialNo'].invalid ? 'error-inputbox' : ''"
            class="form-control customtextfield-s" pInputText formControlName="abbProductSerialNo"
            (keyup)="splitSerialNo() " maxlength="{{maxLength.PRODUCT_SERIAL_NO}}" />
          <button class="col-sm-1 widgetButtoSmall ms-2 mt-0 remove-ms add-mt-smalldevice"
                  [disabled]="serialNumberDisable()"
                  [ngClass]="serialNumberDisable() ? 'grayButton' : ''"
                  (click)="serialNumberVerify()"
                  *ngIf="buttonVisible()"
                  >{{!isVerified ? 'Verify' : 'Verified'}}
          </button>
          <input type="hidden" formControlName="isVerifiedSerial">
          <!-- <div *ngIf="this.additionalSubjectForm.controls['abbProductSerialNo'].invalid">
                      <span class="mandatory" style="vertical-align:middle;float:left;margin-top:-2px;">*</span>
                  </div> -->
                  <div class="serialNoHideShow">
                    <div class="">

                      <div *ngIf="isVerified">
                        <!-- <label> -->
                        <span *ngFor="let serialNo of validSerialNos; let serialNoIndex = index;" class="serial-no-class"
                          [ngStyle]="{'color': this.additionalSubjectForm.controls['BuCode']?.value! == 'PGHV' ? 'green' : 'black' }"
                          (click)="serialNoRedirection(serialNo)">{{ serialNo.trim() }}</span>
                        <span *ngFor="let serialNo of inValidSerialNos; let serialNoIndex = index;" class="serial-no-class"
                          style="color: red;"><span [pTooltip]="InvalidSerialNoText" [escape]="false" tooltipPosition="top"
                            tooltipStyleClass="toolTipClass" (click)="serialNoRedirection(serialNo)">{{ serialNo.trim() }}</span><button
                            [pTooltip]="RemoveSerialNoText" [escape]="false" tooltipPosition="top" tooltipStyleClass="toolTipClass"
                            (click)="removeInvalidSerailNo(serialNoIndex)" class="removeSerialBtn" *ngIf="serialNo">X</button></span>
                        <span *ngFor="let serialNo of alreadyMappedSerialNos; let serialNoIndex = index;" class="serial-no-class"
                          style="color: red;"><span [pTooltip]="alreadyMappedSerialNosText" [escape]="false" tooltipPosition="top"
                            tooltipStyleClass="toolTipClass" (click)="serialNoRedirection(serialNo)">{{ serialNo.trim() }}</span><button
                            [pTooltip]="RemoveSerialNoText" [escape]="false" tooltipPosition="top" tooltipStyleClass="toolTipClass"
                            (click)="removeAlreadyMappedSerailNo(serialNoIndex)" class="removeSerialBtn" *ngIf="serialNo">X</button></span>
                        <!-- </label> -->
                      </div>
                      <div *ngIf="!isVerified">
                        <!-- <label> -->
                        <span *ngFor="let serialNo of serialNoArray; let serialNoIndex = index;" class="serial-no-class"
                          (click)="serialNoRedirection(serialNo)">{{ serialNo.trim() }}</span>
                        <!-- </label> -->
                      </div>
                    </div>
                  </div>

        </div>
      </div>
      <div class="col additional-info">
        <div>
          <label> <span class="float-start">{{ "Complaint_ResPurchaseOrderNo" | translate }}<span
                class="asteriskRequired"
                *ngIf="this.additionalSubjectForm.controls['purchaseOrderNo']?.validator || this.fieldNameForFailureMode=='PurchaseOrder'"></span>
            </span> <i [pTooltip]="purchaseOrderText" [escape]="false" tooltipPosition="top"
              tooltipStyleClass="toolTipClass" class="pi pi-info-circle ms-2 infoIcon"></i></label>
        </div>
        <div>
          <input type="text" [pTooltip]="purchaseOrderTooltipText" [escape]="false" tooltipPosition="top"
            tooltipStyleClass="toolTipClass" class="form-control customtextfield-s" pInputText
            formControlName="purchaseOrderNo"
            [ngClass]="isSubmitted && this.additionalSubjectForm.controls['purchaseOrderNo'].invalid ? 'error-inputbox' : ''"
            maxlength="{{maxLength.PURCHASE_ORDER_NO}}" />
          <!-- <div *ngIf="this.additionalSubjectForm.controls['purchaseOrderNo'].invalid">
                      <span class="mandatory" style="vertical-align:middle;float:left;margin-top:-2px;">*</span>
                  </div> -->

        </div>
      </div>
    </div>
    <!-- <div > -->



    <!-- </div> -->
    <div class="row mb-1">
      <div class="col additional-info">
        <div>
          <label> <span class="float-start">{{ "Complaint_ProjectNumber" | translate }}<span class="asteriskRequired"
                *ngIf="this.additionalSubjectForm.controls['abbProjectNumber']?.validator || this.fieldNameForFailureMode=='ABBProject'"></span>
            </span>
            <i [pTooltip]="projectITooltip" [escape]="false" tooltipPosition="top" tooltipStyleClass="toolTipClass"
              class="pi pi-info-circle ms-2 infoIcon"></i></label>
        </div>
        <div>
          <input type="text" [pTooltip]="projectTooltipText" [escape]="false" tooltipPosition="top"
            tooltipStyleClass="toolTipClass" class="form-control customtextfield-s  productSection" pInputText
            formControlName="abbProjectNumber" maxlength="{{maxLength.PROJECT_NUMBER}}"
            [ngClass]="isSubmitted && this.additionalSubjectForm.controls['abbProjectNumber'].invalid ? 'error-inputbox' : ''" />
          <!-- <div *ngIf="this.additionalSubjectForm.controls['abbProjectNumber'].invalid">
                      <span class="mandatory" style="vertical-align:middle;float:left;margin-top:-2px;">*</span>
                  </div> -->

          <button class="col-sm-1 widgetButtoSmall ms-2 mt-0 remove-ms" (click)="proMISSelectionPopUp()">
            {{
            this.additionalSubjectForm.controls["abbProjectNumber"].value == ""
            ? "Select"
            : "Change"
            }}
          </button>
          <!-- [ngClass]="!disableBtn['project']?'grayButton': ''"
                  [disabled]="!disableBtn['project']" -->
        </div>
      </div>
      <div class="col additional-info">
        <div>
          <label> <span class="float-start">{{ "Complaint_ProjectName" | translate }}<span class="asteriskRequired"
                *ngIf="this.additionalSubjectForm.controls['abbProjectName']?.validator || this.fieldNameForFailureMode=='ABBProject'"></span>
            </span>
            <i class="pi pi-info-circle ms-2 infoIcon" [pTooltip]="projectNameText" [escape]="false"
              tooltipPosition="top" tooltipStyleClass="toolTipClass"></i></label>
        </div>
        <div>
          <input [pTooltip]="projectNameTooltipText" [escape]="false" tooltipPosition="top"
            tooltipStyleClass="toolTipClass" type="text" class="form-control customtextfield-s" pInputText
            formControlName="abbProjectName" (blur)="projectCheck()" maxlength="{{maxLength.PROJECT_NAME}}"
            [ngClass]="isSubmitted && this.additionalSubjectForm.controls['abbProjectName'].invalid ? 'error-inputbox' : ''" />
          <!-- <div *ngIf="this.additionalSubjectForm.controls['abbProjectName'].invalid">
                      <span class="mandatory" style="vertical-align:middle;float:left;margin-top:-2px;">*</span>
                  </div> -->

        </div>
      </div>
    </div>

    <div class="row additional-info country-info">
      <div class="col">
        <div>
          <label>{{ "Complaint_ArticleNumber" | translate }}<span class="asteriskRequired"
              *ngIf="this.additionalSubjectForm.controls['articleNumber']?.validator"></span></label>
        </div>
        <div>
          <input type="text" [pTooltip]="articleNumberText" [escape]="false" tooltipPosition="top"
            tooltipStyleClass="toolTipClass" pInputText class="form-control customtextfield-l"
            formControlName="articleNumber"
            [ngClass]="isSubmitted && this.additionalSubjectForm.controls['articleNumber'].invalid ? 'error-inputbox' : ''"
            maxlength="{{maxLength.ARTICLE_NUMBER}}" />
        </div>
      </div>
      <div class="col">
        <div>
          <label>{{ "Complaint_Quantity_No" | translate }}<span class="asteriskRequired"
              *ngIf="this.additionalSubjectForm.controls['quantity']?.validator"></span></label>
        </div>
        <div class="quantity-field">
          <input [pTooltip]="quantityText" [escape]="false" tooltipPosition="top" tooltipStyleClass="toolTipClass"
            type="number" min="1" max="2147483647" onkeydown="return event.keyCode !== 69 && event.keyCode !== 189"
            pInputText
            [ngClass]="(isSubmitted && this.additionalSubjectForm.controls['quantity'].invalid) || ((this.additionalSubjectForm.controls['quantity']?.value != '' && this.additionalSubjectForm.controls['quantity']?.value != null  ) && (this.additionalSubjectForm.controls['quantity']?.value < 1 || this.additionalSubjectForm.controls['quantity']?.value > 2147483647 ))    ? 'error-inputbox' : ''"
            class="form-control customtextfield-xs" formControlName="quantity" />
        </div>
      </div>
    </div>
    <div class="row mb-1 additional-info">
      <div class="col">
        <div>
          <label>{{ "Complaint_CustomerReference" | translate }}<span class="asteriskRequired"
              *ngIf="this.additionalSubjectForm.controls['abbCustomerReference']?.validator"></span></label>
        </div>
        <div>
          <input type="text" [pTooltip]="customerReferenceText" [escape]="false" tooltipPosition="top"
            tooltipStyleClass="toolTipClass" pInputText class="form-control customtextfield-l"
            [ngClass]="isSubmitted && this.additionalSubjectForm.controls['abbCustomerReference'].invalid ? 'error-inputbox' : ''"
            formControlName="abbCustomerReference" maxlength="{{maxLength.CUSTOMER_REFERENCE_NUMBER}}" />
        </div>
      </div>
      <div class="col">
        <div>
          <label>{{ "Complaint_SupplierCustomerName" | translate }}<span class="asteriskRequired"
              *ngIf="this.additionalSubjectForm.controls['supplier']?.validator"></span></label>
        </div>
        <div>
          <input type="hidden" formControlName="supplierData">
          <input [pTooltip]="supplierExternalOnlyText" [escape]="false" tooltipPosition="top"
            tooltipStyleClass="toolTipClass" type="text" pInputText
            [ngClass]="isSubmitted && this.additionalSubjectForm.controls['supplier'].invalid ? 'error-inputbox' : ''"
            class="form-control customtextfield-l  additonal_supplier productSection inputFieldDisabled"
            formControlName="supplier" readonly="true" />
          <button class="col-sm-1 widgetButtoSmall ms-2 mt-0 remove-ms add-mt-smalldevice"
            (click)="supplierSelectionPopUp()">
            {{
            this.additionalSubjectForm.controls["supplier"].value == ""
            ? "Select"
            : "Change"
            }}
          </button>
        </div>
        <!-- [ngClass]="!disableBtn['supplier']?'grayButton': ''"
              [disabled]="!disableBtn['supplier']" -->
      </div>
    </div>

    <div class="row mb-1 additional-info" *ngIf="complaintCreatedDate<ncrLiveDate">
      <div class="col mt-1">
        <div>
          <label>{{ "Complaint_DeliveryDate" | translate }}<span class="asteriskRequired"
              *ngIf="this.additionalSubjectForm.controls['deliveryDate']?.validator"></span></label>
        </div>
        <div class="datefield">
          <p-calendar (onSelect)="deliveryDateOnSelect()" dateFormat="yy-mm-dd" [readonlyInput]="true"
            class="form-control customtextfield-l border-0" #calendar1 formControlName="deliveryDate"
            [ngClass]="{'error-calendarInput': isSubmitted && this.additionalSubjectForm.controls['deliveryDate'].errors}"
            [pTooltip]="deliveryDateText" [escape]="false" tooltipPosition="top"
            tooltipStyleClass="toolTipClass"></p-calendar>

        </div>
      </div>
      <div class="col mt-1">
        <div>
          <label>{{ "Complaint_CommissioningDate" | translate }}<span class="asteriskRequired"
              *ngIf="this.additionalSubjectForm.controls['commissionDate']?.validator"></span></label>
        </div>
        <div class="datefield">
          <p-calendar (onSelect)="commissioningDateOnSelect()" dateFormat="yy-mm-dd" [readonlyInput]="true"
            class="form-control customtextfield-l border-0" #calendar2 formControlName="commissionDate"
            [ngClass]="{'error-calendarInput': isSubmitted && this.additionalSubjectForm.controls['commissionDate'].errors}"
            [pTooltip]="commissioningDateText" [escape]="false" tooltipPosition="top"
            tooltipStyleClass="toolTipClass"></p-calendar>
          <!-- <button [pTooltip]="commissioningDateText" [escape]="false" tooltipPosition="top"
                    tooltipStyleClass="toolTipClass" [showIcon]="true" type="button" class="widgetButtoSmall ms-2 mt-2 date-btn"
                    (click)="openCalendarCommissioningDate($event, 'selection')">Select</button> -->
        </div>
      </div>
    </div>

    <div class="row mb-1 additional-info country-info">
      <div class="col">
        <div>
          <label>{{ "Complaint_ManufacturingDate" | translate }}<span class="asteriskRequired"
              *ngIf="this.additionalSubjectForm.controls['ManufacturingDate']?.validator"></span></label>
        </div>
        <div class="datefield">
          <p-calendar (onSelect)="manfacturingDateOnSelect()" dateFormat="yy-mm-dd" [readonlyInput]="true"
            class="form-control customtextfield-l border-0" #calendar3 formControlName="ManufacturingDate"
            [ngClass]="{'error-calendarInput': isSubmitted && this.additionalSubjectForm.controls['ManufacturingDate'].errors}"
            [pTooltip]="manufacturingDateText" [escape]="false" tooltipPosition="top"
            tooltipStyleClass="toolTipClass"></p-calendar>
          <!-- <button type="button" [pTooltip]="manufacturingDateText" [escape]="false" tooltipPosition="top"
                      tooltipStyleClass="toolTipClass" class="widgetButtoSmall ms-2 mt-2 date-btn"
                      (click)="openCalendarManufactureDate($event, 'selection')">Select</button> -->
        </div>
      </div>
      <div class="col">
        <div>
          <label *ngIf="complaintCreatedDate<ncrLiveDate">{{ "Complaint_WarrantyReferenceDesc" | translate }}<span class="asteriskRequired"
              *ngIf="this.additionalSubjectForm.controls['warrantyReference']?.validator"></span></label>
          <label *ngIf="!(complaintCreatedDate<ncrLiveDate)">Warranty Lifecycle<span class="asteriskRequired"
              *ngIf="this.additionalSubjectForm.controls['warrantyReference']?.validator"></span></label>
        </div>
        <div *ngIf="complaintCreatedDate<ncrLiveDate">
              <textarea pTooltip="Warranty Reference" [escape]="false" tooltipPosition="top"
                  tooltipStyleClass="toolTipClass" pInputTextarea [autoResize]="true"
                  [ngClass]="{'error-inputbox': isSubmitted && this.additionalSubjectForm.controls['warrantyReference'].errors}"
                  class="form-control customtextfield-l" formControlName="warrantyReference"></textarea>
          </div>
        <div *ngIf="!(complaintCreatedDate<ncrLiveDate)">
          <p-dropdown placeholder="Select one" pTooltip="Warranty Reference" [escape]="false" tooltipPosition="top"
            tooltipStyleClass="toolTipClass" [options]="WarrentyLifeCycleOptions"
            [ngClass]="{'error-inputbox': isSubmitted && this.additionalSubjectForm.controls['warrantyLifeCycleId'].errors}"
            formControlName="warrantyLifeCycleId" optionLabel="number" optionValue="value"></p-dropdown>
        </div>
      </div>
    </div>

    <div class="row mb-1 additional-info">
      <div class="col">
        <div>
          <label>{{ "Complaint_WarrantyEndDate" | translate}}<span class="asteriskRequired"
              *ngIf="this.additionalSubjectForm.controls['warrantyEndDate']?.validator"></span></label>
        </div>
        <div class="datefield">
          <p-calendar (onSelect)="warrantyEndOnSelect()" dateFormat="yy-mm-dd" [readonlyInput]="true"
            class="form-control customtextfield-l border-0" #calendar5 formControlName="warrantyEndDate"
            [ngClass]="{'error-calendarInput': isSubmitted && this.additionalSubjectForm.controls['warrantyEndDate'].errors}"
            [pTooltip]="warrantyEndDateText" [escape]="false" tooltipPosition="top"
            tooltipStyleClass="toolTipClass"></p-calendar>
          <!-- <button [pTooltip]="warrantyEndDateText" [escape]="false" tooltipPosition="top"
                      tooltipStyleClass="toolTipClass" type="button" class="widgetButtoSmall ms-2 mt-2 date-btn"
                      (click)="openCalendarWarrantyEnd($event, 'selection')">Select</button> -->

        </div>
      </div>
      <div class="col">
        <div *ngIf="complaintCreatedDate<ncrLiveDate">
          <label>{{ "Complaint_WarrantyStartDate" | translate }}<span class="asteriskRequired"
              *ngIf="this.additionalSubjectForm.controls['warrantyStartDate']?.validator"></span></label>
        </div>
        <div class="datefield" *ngIf="complaintCreatedDate<ncrLiveDate">
          <p-calendar (onSelect)="warrantyStartOnSelect()" dateFormat="yy-mm-dd" [readonlyInput]="true"
            class="form-control customtextfield-l border-0" #calendar4 formControlName="warrantyStartDate"
            [ngClass]="{'error-calendarInput': isSubmitted && this.additionalSubjectForm.controls['warrantyStartDate'].errors}"
            [pTooltip]="warrantyStartDateText" [escape]="false" tooltipPosition="top"
            tooltipStyleClass="toolTipClass"></p-calendar>
          <!-- <button [pTooltip]="warrantyStartDateText" [escape]="false" tooltipPosition="top"
                    tooltipStyleClass="toolTipClass" type="button" class="widgetButtoSmall ms-2 mt-2 date-btn"
                    (click)="openCalendarWarrantyStart($event, 'selection')">Select</button> -->
        </div>
      </div>
    </div>
    <div class="row mb-1 additional-info" *ngIf="complaintCreatedDate<ncrLiveDate">
      <div class="col">
        <div>
          <label>{{ "Complaint_Other_Document_ID" | translate }}<span class="asteriskRequired"
              *ngIf="this.additionalSubjectForm.controls['documentID']?.validator"></span></label>
        </div>
        <div>
          <input type="text" [pTooltip]="otherDocumentIDText" [escape]="false" tooltipPosition="top"
            [ngClass]="{'error-inputbox': isSubmitted && this.additionalSubjectForm.controls['documentID'].errors}"
            tooltipStyleClass="toolTipClass" pInputText class="form-control customtextfield-l"
            formControlName="documentID" maxlength="{{maxLength.OTHER_DOCUMENT_ID}}" />
        </div>
      </div>

      <div class="col">
        <div>
          <label>{{ "Complaint_QNumberIssuingOrganization" | translate }}<span class="asteriskRequired"
              *ngIf="this.additionalSubjectForm.controls['qNumberIssue']?.validator"></span></label>
        </div>
        <div>
          <input type="text" [pTooltip]="QnumberText" [escape]="false" tooltipPosition="top"
            [ngClass]="{'error-inputbox': isSubmitted && this.additionalSubjectForm.controls['qNumberIssue'].errors}"
            tooltipStyleClass="toolTipClass" pInputText class="form-control customtextfield-l"
            formControlName="qNumberIssue" maxlength="{{maxLength.Q_NUMBER}}" />
        </div>
      </div>
    </div>
    <div class="row mb-1 additional-info" *ngIf="complaintCreatedDate<ncrLiveDate">
      <div class="col">
        <div>
          <label>{{ "Complaint_ItemDesignation" | translate }}<span class="asteriskRequired"
              *ngIf="this.additionalSubjectForm.controls['itemDesignation']?.validator"></span> </label>
        </div>
        <div>
          <input type="text" type="text" [pTooltip]="itemDesignationText" [escape]="false" tooltipPosition="top"
            tooltipStyleClass="toolTipClass" pInputText
            [ngClass]="isSubmitted && this.additionalSubjectForm.controls['itemDesignation'].invalid ? 'error-inputbox' : ''"
            class="form-control customtextfield-l" formControlName="itemDesignation"
            maxlength="{{maxLength.ITEM_DESIGNATION}}" />
        </div>
      </div>
      <div class="col">
      </div>
    </div>

    <div class="row mb-0">
      <div class="col-md-6 remove-px">
        <label>
          <input type="checkbox" formControlName="SendCopyToParties" class="float-start mt-1 me-1 check-parties"
            [pTooltip]="partiesConnectedCheckboxText" [escape]="false" tooltipPosition="left"
            [ngClass]="{'error-inputbox': isSubmitted && this.additionalSubjectForm.controls['SendCopyToParties'].errors}"
            tooltipStyleClass="toolTipClass" />
          <span class="sentto-text"> {{'Complaint_SendCopyToParties' | translate}}
            <span class="asteriskRequired"
              *ngIf="this.additionalSubjectForm.controls['SendCopyToParties'].value==true"></span>
          </span>

        </label>

      </div>
    </div>
    <div class="row mb-1">
      <div class="multiselect-field_remove sendcopy-ms remove-px">
        <!-- [ngClass]="isSubmitted || this.additionalSubjectForm.controls['SendCopyToParties'].value == ture ? 'error-autocomplete' : ''" -->
        <p-autoComplete formControlName="PartiesReference" [readonly]="readonlyObj?.PartiesReferenceReadOnly"
          [ngClass]="{ 'error-autocomplete' : isSubmitted && this.additionalSubjectForm.controls['PartiesReference']?.invalid , 'disabled' : this.additionalSubjectForm.controls['SendCopyToParties'].value==false, 'readonlyclass opacity-secondary':'readonlyObj?.PartiesReferenceReadOnly' }"
          class="border-0 partiesTextBoxCustom" [showClear]="true" [pTooltip]="partiesConnectedText" [escape]="false"
          tooltipPosition="left" tooltipStyleClass="toolTipClass" [suggestions]="filteredPartiesConnected"
          field="promptLabel" [multiple]="true" (completeMethod)="filterPartiesConnected($event.query)" [minLength]="3"
          [delay]="500"
          [disabled]="this.additionalSubjectForm?.controls['SendCopyToParties'].value==false ? true : false">
          <ng-template let-filteredPartiesConnected pTemplate="selectedItem">
            <div (mouseover)="partiesConectedGotFocus(filteredPartiesConnected?.tooltipInfo)"
              (mouseout)="partiesConectedLostFocus()">
              {{ filteredPartiesConnected.selectedLabel }}
            </div>
          </ng-template>
        </p-autoComplete>
      </div>
    </div>

  </form>
</div>
