import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CustomerSelectionServiceService {

  constructor(private http: HttpClient) { }

     getUsers(): Observable<any>{
       return this.http.get(`${environment.API_URL}/companyDetailsjsData`);
     }
     // US - 7559: Customer Selection -External | Show Customer - Type accordingly - Implementation   -- to check if customer type is external or internal   --added filter custtype to check for customer is external or internal
     //removed / which came before api/v1/filters/customerSearchFilters url below
     getCustomerSearchFiltersDetails(): Observable<any> {
      return this.http.get(`${environment.azure.baseUrl}api/v1/filters/customerSearchFilters`)
    }

    GetCustomersRecentlySelected(CurrentUserGeid): Observable<any> {
      return this.http.get(`${environment.azure.baseUrl}api/v1/Data/GetCustomersRecentlySelected?CurrentUserGeid=${CurrentUserGeid}`);
    }
    GetCustomers(Data): Observable<any>{
      return this.http.post(`${environment.azure.baseUrl}api/v1/Data/GetCustomers`,Data);
    }

}
