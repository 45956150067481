<div class="rounded-1 delete-card-modal" style="height: auto;">

  <div class="modal-body">
    <span class="asteriskRequired">{{'cc_action_plan_delete_modal_body' | translate}}</span>
  </div>
    <!-- <hr /> -->
  <div class="modal-footer">
    <button class="btn widgetButtoSmall" (click)="cancel()">{{'cc_action_plan_delete_modal_button_cancel' | translate}}</button>
    <button class="btn widgetButtoSmall" type="submit" (click)="save()">{{'cc_action_plan_delete_modal_button_delete' | translate}}</button>
  </div>
</div>
  