import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DialogService, DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { AdminService } from 'src/app/services/admin.service';
import { ToasterserviceService } from 'src/app/services/toasterservice.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { RuAuthService } from '../ru-auth.service';

@Component({
  selector: 'app-resolving-units-addprimaryruinfo',
  templateUrl: './resolving-units-addprimaryruinfo.component.html',
  styleUrls: ['./resolving-units-addprimaryruinfo.component.css']
})
export class ResolvingUnitsAddprimaryruinfoComponent implements OnInit {
  resolvingunitaddprimaryForm!: FormGroup
  filteredSubstitude: any;
  userinfo: any;
  ruProcessOwnerResolvingUnitdata: Array<any> = [];
  coordinatorResolvingUnitdata: Array<any> = [];
  iscountprocessorowner: number = 0;
  iscoordinaterowner: number = 0;
  objectdata: { ruId: any; geid: any; ruPersonType: number; };
  constructor(
    private fb: FormBuilder,
    private readonly dialogRef: DynamicDialogRef,
    private adminService: AdminService,
    public config: DynamicDialogConfig,
    public toasterService: ToasterserviceService,
    private spinner: NgxSpinnerService,
    private ruAuthService: RuAuthService
  ) {

  }

  ngOnInit(): void {
    this.resolvingunitaddprimaryForm = this.fb.group({
    })
    this.userinfo = this.config.data;
   
  }

  onClose() {
    this.dialogRef.close();
  }

  addprimaryperson() {
    this.spinner.show();
    this.objectdata = {
      "ruId": this.userinfo.cardinfo.id,
      "geid": this.userinfo.empinfo.id,
      "ruPersonType": this.userinfo.ruPersonType
    }

    let canManage = this.ruAuthService.canManageRu(this.userinfo.cardinfo.scope, this.userinfo.cardinfo.ruProcessOwnerGeids);

    if(canManage){
      if (this.userinfo.ruPersonType == 1) {
     
        this.adminService.addprimaryRuperson(this.objectdata).subscribe((res) => {
          const deletepersonInfo = res
          this.dialogRef.close(this.objectdata);
          this.toasterService.showSuccess('Primary person has been assigned');
          this.spinner.hide();

        })
      }
      else {
        this.adminService.addprimaryRuperson(this.objectdata).subscribe((res) => {
          const deletepersonInfo = res
          this.dialogRef.close(this.objectdata);
          this.toasterService.showSuccess('Primary person has been assigned');
          this.spinner.hide();

        })
      }
    }
    else{
      this.toasterService.showError('Cannot delete this RU Person. Insufficient permissions.');
    }

  }

  gethubDataProcess()
  {
    this.ruProcessOwnerResolvingUnitdata=this.config.data.ruProcessOwnerrr;
    this.coordinatorResolvingUnitdata=this.config.data.coordinatorProcessOwner;
    this.iscountprocessorowner=this.config.data.ruProcessOwnercount;
    this.iscoordinaterowner=this.config.data.coordinatorProcessOwnercount;
  }
}
