import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ProcessStepService } from '../process-step.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-admin-process-steps-deactivate-modal',
  templateUrl: './admin-process-steps-deactivate-modal.component.html',
  styleUrls: ['./admin-process-steps-deactivate-modal.component.css']
})
export class AdminProcessStepsDeactivateModalComponent implements OnInit {

  isSaving = false;
  item;

  constructor(public ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private processStepsService: ProcessStepService,
    private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.item = this.config.data;
  }

  cancel(){
    this.ref.close({result : 'none'});
  }

  ok(){
    this.isSaving = true;
    this.item.isActive = false;
    this.spinner.show();
    this.processStepsService.deactivate(this.item).subscribe((data)=>{
      if(data){
        this.isSaving = false;
        
        this.ref.close({
          result: 'ok',
          model: this.item,
          results: data
        });
        this.spinner.hide();
      }
      else{
        this.isSaving = false;
        this.ref.close();
        this.spinner.hide();
      }
    },
     ()=>{
      this.isSaving = false;
      this.spinner.hide();
     })
  }

}
