import { ActionPlanCard } from "./ActionPlanCard";


export class ActionPlanRows {
    todo: ActionPlanCard[];
    inProgress: ActionPlanCard[];
    closed: ActionPlanCard[];

    constructor() {
      this.closed = [];
      this.inProgress = [];
      this.todo = [];
    }
}