import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { SerialNoFindService } from '../../services/serial-no-find.service'

@Component({
  selector: 'app-ccrpnavbar',
  templateUrl: './ccrpnavbar.component.html',
  styleUrls: ['./ccrpnavbar.component.css']
})
export class ccrpnavbarComponent implements OnInit {

  complaintNumber:string='';
  filteredSerialNo: any;
  enteredSerialNo: any;
  enteredSerialNoModel: any;

  constructor(private router:Router,
  private serialNoService: SerialNoFindService) { }

  ngOnInit(): void {
  }

  isActive(link:string): boolean{
    return this.router.isActive(link,false);
  }

  SNCRPURL:string = environment.SNCRPURL;

  navigateToComplaint(){
    if(this.complaintNumber){
      this.router.navigateByUrl('complaint/' + this.complaintNumber).then(() => {
        window.location.reload();
      });
    }
  }

  filteredSerialNoSearch(event) {
    const srNo = event?.query;
      this.serialNoService.findCCRP(srNo).subscribe((res) => {
          this.filteredSerialNo = res?.complaintNo;
          this.enteredSerialNo = res?.productSerialNumber;
          if(!this.filteredSerialNo.length) {
            this.filteredSerialNo = ['No Record Found'];
          }
      }, (err) => {
        this.filteredSerialNo = [];
      })
  }

  onSelect(selectedItem) {
    if(selectedItem !== 'No Record Found') {
      this.enteredSerialNoModel = this.enteredSerialNo;
      window.open('/complaint/' + selectedItem, '_blank');
      this.enteredSerialNoModel ='';
    }
  }

}
