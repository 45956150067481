import { Injectable } from '@angular/core';
import { LocalStorageServiceService } from './local-storage-service.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MdServiceService {
  cacheKey = 'mdData_20200117';
  storage:boolean = false ;
  cacheData;

  constructor(private localStorage: LocalStorageServiceService,
    private http: HttpClient) {
      if(this.localStorage.getFromLocalStorage(this.cacheKey)){
        this.storage = true;
      }
  }

  loadMdData() {
    if (this.storage) {
      this.cacheData = this.localStorage.getFromLocalStorage(this.cacheKey);
    }
    else {
      this.http.get<any>(`${environment.azure.baseUrl}api/v1/divisions/all`).subscribe((data) => {
        this.localStorage.addToLocalStorage(this.cacheKey, data, { days: 1 });
        this.cacheData = data;
        this.storage = true;
      })
    }

    return {
      divisions: this.cacheData?.divisions,
      bu: this.cacheData?.bus,
      buClosed: this.cacheData?.buClosed,
      pg: this.cacheData?.pgs,
      pgClosed: this.cacheData?.pgClosed
    }
  }


  pg(): Promise<any> {
    return new Promise((resolve, reject) => {
      resolve(this.loadMdData()?.pg)
    });
  }

  divisions(): Promise<any> {
    return new Promise((resolve, reject) => {
      resolve(this.loadMdData()?.divisions)
    });
  }

  bu(): Promise<any> {
    return new Promise((resolve, reject) => {
      resolve(this.loadMdData()?.bu)
    });
  }

  buClosed(): Promise<any> {
    return new Promise((resolve, reject) => {
      resolve(this.loadMdData()?.buClosed)
    });
  }

  pgClosed(): Promise<any> {
    return new Promise((resolve, reject) => {
      resolve(this.loadMdData()?.pgClosed)
    });
  }
}
