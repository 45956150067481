<div class="panel panel-default translation-message">
  <div class="panel-heading">
      <span class="panel-title">
          <div class="row">
              <div class="col-sm-11 translation_message_heading">
                  <strong>
                      <strong>{{msg.engTranslation.name}}</strong>
                      <strong class="text-muted ms-1">| {{msg.messageTypeName}}</strong>
                  </strong>
              </div>
              <div class="col-sm-1 translationMessageEditBtn">
                <span class="label-align-right mt-1">
                  <button type="button" class="widgetButtonSmall"
                          *ngIf="allowEdit"
                          [disabled]="isDisabled"
                          (click)="editMsgTemplate(msg) ">
                      {{'admin_translations_msg_edit_btn' | translate}}
                  </button>
                  </span>
              </div>
          </div>
      </span>
  </div>

  <div class="panel-body">
      <div class="row">
              <div class="col-sm-1 pe-4 translationMessageAlign">
                  <label class="label-align-right label-bold">
                      {{'admin_translations_msg_subject_lb' | translate}}
                  </label>
              </div>
              <div class="col-sm-11 wordBreakTranslationMessage">
                  <p *ngIf="msg.subject"><strong [innerHTML]="msg.subject"></strong></p>
                  <p *ngIf="msg.subject == null || msg.subject === ''" class="bg-warning">
                      <span class="text-muted">
                          {{'admin_translations_msg_subject_ne_lb' | translate}}
                      </span>
                  </p>

                  <span *ngIf="shouldShowEngTranslation()">
                      <span class="label label-primary">EN</span>
                      <i [innerHTML]="msg.engTranslation.subject"></i>
                  </span>
              </div>
              </div>

          <div class="row">
              <hr />
          </div>

          <div class="row">
              <div class="col-sm-1 pe-4 translationMessageAlign">
                  <label class="label-align-right label-bold">
                      {{'admin_translations_msg_body_lb' | translate}}
                  </label>
              </div>
              <div class="col-sm-11 wordBreakTranslationMessage">
                  <p *ngIf="msg.body" [innerHTML]="msg.body"></p>
                  <p *ngIf="msg.body == null || msg.body === ''" class="bg-warning">
                      <span class="text-muted">
                          {{'admin_translations_msg_body_ne_lb' | translate}}
                      </span>
                  </p>

                  <span *ngIf="shouldShowEngTranslation()">
                      <span class="label label-primary">EN</span>
                      <i [innerHTML]="msg.engTranslation.body"></i>
                  </span>
              </div>
          </div>

          <div class="row">
              <hr />
          </div>
          <div class="row">
            <div class="col-sm-6"></div>
            <div class="col-sm-6">
              <small class="label-align-right">
                  {{'admin_translations_msg_sendto_lb' | translate}}
                  <span *ngIf="msg.recipients">
                  <span *ngFor="let rec of msg.recipients">
                      <label class="label label-default">{{rec.display}} &nbsp;</label>
                  </span>
                </span>
                  <span *ngIf="msg.recipients.length < 1"
                        class="bg-warning">
                      <span class="text-muted">
                          {{'admin_translations_msg_sendto_ne_lb' | translate}}
                      </span>
                  </span>
              </small>
          </div>
          </div>
          <div class="row">
            <div class="col-sm-6"></div>
            <div class="col-sm-6 label-align-right">
              <small class="label-align-right">
                  {{'admin_translations_msg_sendcc_lb' | translate}}
                  <span *ngIf="msg.cc">
                  <span *ngFor="let rec of msg.cc">
                      <label class="label label-default">{{rec.display}} &nbsp;</label>
                  </span>
                </span>
                  <span *ngIf="msg.cc.length < 1"
                        class="bg-warning">
                      <span class="text-muted">
                          {{'admin_translations_msg_sendcc_ne_lb' | translate}}
                      </span>
                  </span>
              </small>
          </div>
          </div>


      </div>
  </div>
