import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EmployeeDetails } from 'src/app/reusables/user-info-details/user-info-details/user-info-details.component';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserInfoDetailsService {

  constructor(private httpClient:HttpClient) { }

  getUserInfo(userEmail: string) {
    let params = new HttpParams()
      .set('username', userEmail)
    if (userEmail) {
      return this.httpClient.get<any>(`${environment.azure.baseUrl}api/v1/Config/GetUserProfile`, { params });
    }else{
      return null;
    }
  }

  getEmployeeDetails(query: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.httpClient.post(`${environment.azure.baseUrl}api/v1/dashboard/employeeinfo`, { geids: query} ).toPromise().then((data: any) => {
        resolve(data);
      }).catch((error: any) => {
        reject(error);
      })
    });
    }

}
