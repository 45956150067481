<li class="mb-2">
    <div [ngSwitch]="member.isActive">
        <span *ngIf="member.isExpanded">
            <i pTooltip="{{'admin_process_steps_item_hide_tp' | translate}}" tooltipPosition="top"
                class="fa fa-caret-down"
               style="text-decoration: none; cursor: pointer;"
               (click)="expand(member.isExpanded)"></i>
        </span>
        <span *ngIf="!member.isExpanded && member.hasChildren">
            <i pTooltip="{{'admin_process_steps_item_show_tp' | translate}}" tooltipPosition="top"
                class="fa fa-caret-right"
               style="text-decoration: none; cursor: pointer;"
               (click)="expand(member.isExpanded)"></i>
        </span>

        <span *ngSwitchCase="false">
            <span class="text-muted"> {{member.name}}</span>
            &nbsp;
            <a pTooltip="{{'admin_process_steps_item_edit_tp' | translate}}" tooltipPosition="top"
                (click)="edit(member)"
               style="text-decoration: none; cursor: pointer;"
               *ngIf="member.id>0 && allowActions">
                <i class="fa fa-edit iconStyle"></i>
            </a>
        </span>

        <span *ngSwitchDefault>
            {{member.name}}
            &nbsp;
            <a pTooltip="{{'admin_process_steps_item_add_tp' | translate}}" tooltipPosition="top"
                (click)="add(member)"
               *ngIf="allowActions && level < MAX_CHILD_LEVEL"
               style="text-decoration: none; cursor: pointer;">
                <i class="fa fa-plus iconStyle"></i>
            </a> &nbsp;
            <a pTooltip="{{'admin_process_steps_item_edit_tp' | translate}}" tooltipPosition="top"
                (click)="edit(member)"
               style="text-decoration: none; cursor: pointer;"
               *ngIf="member.id>0 && allowActions">
                <i class="fa fa-edit iconStyle"></i>
            </a> &nbsp;

            <a pTooltip="{{'admin_process_steps_item_deactivate_tp' | translate}}" tooltipPosition="top"
                (click)="remove(member)"
               style="text-decoration: none; cursor: pointer;"
               *ngIf="member.id>0 && allowActions">
                <i class="fa fa-trash-o iconStyle"></i>
            </a> &nbsp;
        </span>
    </div>
</li>
<div *ngIf="member.children">
    <app-process-step-item *ngIf="member.isExpanded" [collection]='member.children' [level]="level" [canManage]='allowActions'></app-process-step-item>
</div>
