<div class="advanced-customer-pop-selection">
    <form [formGroup]="advancedCustomerSelectionForm">
        <div class="row">
            <div class="col-sm-2 mt-4 item-align-right customerContAdvancedCustLabel label-bold">
                {{'rpt_cust_cust_name_lbl'|translate}}
            </div>
            <div class="col-sm-4 mt-4 customerContAdvancedCustInput">
                <input type="text" class="form-control"  (keyup)="chageval($event)" formControlName="customerName" />

                <span *ngIf="customerName.dirty && this.advancedCustomerSelectionForm.get('customerName').value?.length <2 && this.customer==true"
                class="col-sm-offset-2 admin-error-msg-validname">{{ 'rpt_cust_validation_name_len_ph' | translate}}</span>
            </div>


            <div class="col-sm-2 mt-4 item-align-right customerContAdvancedCustLabel label-bold">
                {{'rpt_cust_cust_addr_lbl'|translate}}
            </div>
            <div class="col-sm-4 mt-4 customerContAdvancedCustInput">
                <input type="text" tooltipPosition="top" tooltipStyleClass="toolTipClass" p class="form-control"
                    formControlName="address" />
            </div>
        </div>
        <div class="row customerContAdvancedDiv">
            <div class="col-sm-2 mt-3 item-align-right customerContAdvancedCustLabel label-bold">
                {{'rpt_cust_country_lbl'|translate}}
            </div>
            <div class="col-sm-4 mt-3 customerContAdvancedCustInput">
                <p-dropdown formControlName="country" [options]="country" optionValue="id" optionLabel="name"
                    [filter]="true" filterBy="name" [showClear]="true"
                    placeholder="Click or type to select Lead Country" [resetFilterOnHide]="true">
                </p-dropdown>
            </div>

            <div class="col-sm-2 mt-3 item-align-right customerContAdvancedCustLabel label-bold">
                {{'rpt_cust_city_lbl'|translate}}
            </div>
            <div class="col-sm-4 mt-3 customerContAdvancedCustInput">
                <input type="text" class="form-control" formControlName="city" />
            </div>

        </div>
        <div class="row">
            <div class="col-sm-2 mt-2 item-align-right customerContAdvancedCustLabel label-bold">
                {{'rpt_cust_zip_lbl'|translate}}
            </div>
            <div class="col-sm-4 mt-2 customerContAdvancedCustInput">
                <input type="text" class="form-control" formControlName="zipcode" />
            </div>

            <div class="col-sm-2 mt-2 item-align-right customerContAdvancedCustLabel label-bold">
                {{'rpt_cust_state_lbl'|translate}}
            </div>
            <div class="col-sm-4 mt-2 customerContAdvancedCustInput">
                <input type="text" tooltipPosition="top" tooltipStyleClass="toolTipClass" p class="form-control"
                    formControlName="state" />
            </div>
        </div>
        <div class="row">
            <div class="col-sm-2 mt-3 item-align-right customerContAdvancedCustLabel label-bold">
                {{'rpt_cust_parentguid_lbl'|translate}}
            </div>
            <div class="col-sm-4 mt-3 customerContAdvancedCustInput">
                <input type="text" class="form-control" formControlName="businessPartnerGUID" />
            </div>

            <div class="col-sm-2 mt-3 item-align-right customerContAdvancedCustLabel label-bold">
                {{'rpt_cust_tuid_lbl'|translate}}
            </div>
            <div class="col-sm-4 mt-3 customerContAdvancedCustInput">
                <input type="text" tooltipPosition="top" tooltipStyleClass="toolTipClass" class="form-control"
                    formControlName="tuid" />
            </div>
        </div>

        <div class="row mt-3">
            <div class="col-sm-4"></div>
            <div class="col-sm-4"></div>
            <div class="col-sm-4 div-align-items-right">
                <button class="input widgetButtonLarge" (click)="reset()">{{'rpt_cust_reset_btn'|translate}}
                </button>
                <button class="input widgetButtonLarge ms-1"
                    [disabled]="!(this.advancedCustomerSelectionForm.get('customerName').value?.length >=2 ||
                                this.advancedCustomerSelectionForm.get('country').value ||
                                this.advancedCustomerSelectionForm.get('address').value ||
                                this.advancedCustomerSelectionForm.get('city').value ||
                                this.advancedCustomerSelectionForm.get('zipcode').value ||
                                this.advancedCustomerSelectionForm.get('state').value ||
                                this.advancedCustomerSelectionForm.get('businessPartnerGUID').value ||
                                this.advancedCustomerSelectionForm.get('tuid').value)"
                    (click)="viewAdvancedCustomers()">{{'rpt_cust_view_btn'|translate}}
                </button>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12 mt-2" style="display:block">
                <p-autoComplete formControlName="clear"  placeholder="Please enter atleast 2 characters"  [forceSelection]="true" [minLength]=2 [multiple]="true"
                    type="text" [suggestions]="filteredCustomer" (completeMethod)="filterCustomerDetails($event)"
                    (onSelect)="customerSelect($event)" (onUnselect)="customerGuidsUnselect($event)" field="name" class="customerSelection">
                    <ng-template let-filteredCustomer pTemplate="item">
                      <div class="flex align-items-center gap-2">
                            <div class="row">
                              <div class="col-10"><b>{{ filteredCustomer?.name }} <span *ngIf="filteredCustomer?.isActive === false">(Not validated)</span></b></div>
                              <div class="col-2 text-end"><i>{{filteredCustomer?.type}}</i></div>
                            </div>
                            <div class="row mt-1">
                              <div class="col">{{filteredCustomer?.addr}},{{filteredCustomer?.city}},{{filteredCustomer?.iso}}</div>
                            </div>
                      </div>
                  </ng-template>
                  </p-autoComplete>
                <!-- <span *ngIf="clearname.dirty && this.advancedCustomerSelectionForm.get('clear').value?.length <2 && this.clearcustomername==true"
                class="col-sm-offset-2 admin-error-msg">{{ 'rpt_cust_validation_name_len_ph' | translate}}</span> -->

                <ng-template let-filteredCustomer pTemplate="item">
                    <div class="flex align-items-center gap-2">
                        <div>{{ filteredCustomer?.name }}</div>
                    </div>
                </ng-template>
            </div>
        </div>
        <div class="row">
            <div class="mt-2" style="text-align: right;">
                <button class="widgetButtonLarge"
                    (click)="clearAllSelectedCustomers()">{{'rpt_cust_clearall_btn'|translate}}</button>
            </div>
        </div>
        <div class="text-center" *ngIf="totalRecords === 0 && noRecords"><h6>{{'rpt_cust_notfound_msg'|translate}}</h6></div>
        <div class="card advanced-customer-selection-pagination mt-2">
            <p-table
            [value]="filteredCustomers"
            [lazy]="true"
            [totalRecords]="totalRecords"
            [loading]="loading"
            (onLazyLoad)="onPageChange($event)"
            selectionMode="single"
            [paginator]="true"
            [rows]="rows"
            [showCurrentPageReport]="true"
            [(first)]="first"
            [tableStyle]="{ 'min-width': '50rem' }"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
            styleClass="p-datatable-gridlines"

                     >
                <ng-template pTemplate="header">
                    <tr>
                        <th style="width:25%">Full name</th>
                        <th style="width:40%">Information</th>
                        <th style="width:20%">Id</th>
                        <th style="width:15%">Status <th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-filteredCustomers let-rowIndex="rowIndex">
                    <tr [ngClass]="{ selectedRowIndex: rowIndex == selectedIndex }" (click)="selectedRow(rowIndex)">
                        <td>{{ filteredCustomers.name }}</td>
                        <td>{{ filteredCustomers.addr }} ,{{filteredCustomers.city }},
                            {{filteredCustomers.zipCode}},
                            {{filteredCustomers.iso}}</td>
                        <td>{{ filteredCustomers.id }}</td>
                        <td>{{ filteredCustomers.status | status }}</td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
        <div class="row mt-3">
            <div class="col-sm-4"></div>
            <div class="col-sm-4"></div>
            <div class="col-sm-4 div-align-items-right">
                <button type="button" class="input widgetButtonLarge" (click)="cancelModal()">{{'rpt_cust_cancel_btn'|translate}}
                </button>
                <button type="button" class="input widgetButtonLarge ms-1"
                    (click)="afterSelectClose()">{{'rpt_cust_ok_btn'|translate}}
                </button>
            </div>
        </div>
    </form>
</div>
