<div class="form-group radio-btn-section mb-0 mt-0 me-4">
  <label class="control-label pe-3 text-end {{labelClass}}">{{title}}</label>
  <div>
    <div class="btn-group {{btnClass}}">
      <a class="btn btn-default"
      [ngClass]="{'active': isOptionSelected(null) }"
       (click)="selectOption(null)"   role="button">
        <span class="fa" [ngClass]="{'fa-circle-o': !isOptionSelected(null), 'fa-check-circle-o': isOptionSelected(null)}"></span>
        {{allMsg}}
      </a>
      <a class="btn btn-default"
      [ngClass]="{'active': isOptionSelected(getFirstOption()) }"
      (click)="selectOption(getFirstOption())"  role="button">
        <span class="fa"  value="local"
          [ngClass]="{'fa-circle-o': !isOptionSelected(getFirstOption()), 'fa-check-circle-o': isOptionSelected(getFirstOption()) }"></span>
        {{firstOptionMsg}}
      </a>
      <a class="btn btn-default"
      [ngClass]="{'active': isOptionSelected(getSecondOption()) }"
      (click)="selectOption(getSecondOption())" value="global"
      role="button">
        <span class="fa"
          [ngClass]="{'fa-circle-o': !isOptionSelected(getSecondOption()), 'fa-check-circle-o': isOptionSelected(getSecondOption()) }"></span>
        <!-- Global -->
        {{secondOptionMsg}}
      </a>
    </div>
  </div>
</div>
