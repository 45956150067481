import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CountriesServiceService {

  constructor(private http: HttpClient) { }

  getCountries(): Observable<any> {
    return this.http.get(`${environment.azure.baseUrl}api/v1/mdregion/countries`);
  }

  setCountryFillIn(guId): Observable<any> {
    return this.http.get(`${environment.azure.baseUrl}api/v1/customer/` + guId)
  }
}
