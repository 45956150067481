import { Component, OnInit } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import {MenuItem} from 'primeng/api';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.css']
})
export class BreadcrumbComponent implements OnInit {
  home: MenuItem = {label: 'CCRP', routerLink: '/mydashboard'};

  menuItems: MenuItem[] = [
    {label: 'DASHBOARD', routerLink: '/mydashboard'},
    {label: 'MY DASHBOARD'}
  ];
  currentRoute : string;

  constructor(private router: Router) {
    this.router.events.subscribe((event : Event) => {
      if(event instanceof NavigationEnd){
        this.currentRoute = event.url;
        //console.log(event);

        if(this.currentRoute.includes('/complaint/')){
          this.menuItems = [
            {label: 'NEW ISSUE'}
          ]
        }

        switch(this.currentRoute){
          case '/mydashboard':
            {
              this.menuItems = [
                {label: 'DASHBOARD', routerLink: '/mydashboard'},
                {label: 'MY DASHBOARD'}
              ];
            }
            break;
          case '/admin':
            {
              this.menuItems = [
                {label: 'ADMIN'}
              ];
            }
            break;
          case '/myactionplan':
            {
              this.menuItems = [
                {label: 'DASHBOARD', routerLink: '/mydashboard'},
                {label: 'ACTION PLAN DASHBOARD'}
              ]
            }
            break;
          case '/complaint':
            {
              this.menuItems = [
                {label: 'NEW ISSUE'}
              ]
            }
            break;
          case '/complaint/*':
            {
              this.menuItems = [
                {label: 'NEW ISSUE'}
              ]
            }
            break;
          case '/helpdesk':
            {
              this.menuItems = [
                {label: 'HELP DESK'}
              ]
            }
            break;
          case '/reports':
              {
                this.menuItems = [
                  {label: 'REPORTS'}
                ];
              }
            break;
            case '/WeeklyReport':
              {
                this.menuItems = [
                  {label: 'REPORTS', routerLink: '/reports'},
                  {label: 'WEEKLY REPORT'}
                ];
              }
            break;
            case '/admin/customer-contacts':
              {
                this.menuItems = [
                  {label: 'ADMIN PANEL', routerLink: '/admin/customer-contacts'},
                  {label: 'MANAGE CUSTOMER CONTACT'}
                ]
              }
              break;
              case '/admin/hubs':
              {
                this.menuItems = [
                  {label: 'ADMIN PANEL', routerLink: '/admin/hubs'},
                  {label: 'MANAGE HUBS'}
                ]
              }
              break;
        }
      }
    });
  }

  ngOnInit(): void {
  }

}
