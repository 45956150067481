import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EmployeeListService } from 'src/app/services/employee-list.service';
import { LocalStorageServiceService } from 'src/app/services/local-storage-service.service';
import { MdRegionService } from 'src/app/services/md-region.service';
import { MdServiceService } from 'src/app/services/md-service.service';
import { ToasterserviceService } from 'src/app/services/toasterservice.service';
import { environment } from 'src/environments/environment';
import { ProcessOwnersAuthService } from './process-owners-auth.service';
import { SessionStorageServiceService } from 'src/app/services/session-storage-service.service';
import * as _ from 'lodash';
import { ArrayService } from 'src/app/services/array.service';
import { find, cloneDeep} from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class ProcessOwnersService {

  constructor(private http: HttpClient,
    private ccLocalStorageService: LocalStorageServiceService,
    private mdData: MdServiceService,
    private mdRegionService: MdRegionService,
    private employeeService: EmployeeListService,
    private toasterService: ToasterserviceService,
    private poAuthService: ProcessOwnersAuthService,
    private sessionStorage: SessionStorageServiceService,
    private arrayService:ArrayService) { }


    searchForProcessOwners(query):Promise<any>{
      return new Promise((resolve,reject)=>{
        this.http.post<any>(`${environment.azure.baseUrl}api/v1/processowner/search`, query).subscribe(
          (data)=>{
            let geids = [];
            let countryCodes = [];
            data.forEach((item) =>{

              if(item.unitManagers){
                item.unitManagers.forEach((mgr)=> {
                  geids.push(mgr.geid);
                });
              }
              item.unitLocationManagers.forEach((mgr)=> {
                geids.push(mgr.geid);
                if (mgr.country) {
                  countryCodes.push(mgr.country);
                }

              });
              item.locationManagers.forEach((mgr)=> {
                geids.push(mgr.geid);
                if (mgr.country) {
                  countryCodes.push(mgr.country);
                }
              });

              //get scope unit description
              item.scope.unitdescr = '';
              if (item.scope.pg) {
                this.mdData.pg().then((pgs) => {
                  pgs.forEach((p)=> {
                    if (p.id == item.scope.pg) {
                      item.scope.unitdescr = p.divCode + ' | ' + p.buCode + ' | ' + p.id + ' (' + p.name + ')';
                    }
                  });
                });
              }
              else if (item.scope.bu) {
                this.mdData.bu().then((bus) => {
                  bus.forEach((b)=> {
                    if (b.id == item.scope.bu) {
                      item.scope.unitdescr = b.divCode + ' | ' + b.id + ' (' + b.name + ')';
                    }
                  });
                });
              }
              else if (item.scope.div) {
                this.mdData.divisions().then((divs) => {
                 divs.forEach((d)=> {
                    if (d.id == item.scope.div) {
                      item.scope.unitdescr = d.id + ' (' + d.name + ')';
                    }
                  });
                });
              }

              //get scope location description

              item.scope.locationdescr = '';
              if (item.scope.countries && item.scope.countries.length > 0) {
                this.mdRegionService.countries().then((countries) => {

                  countries.forEach((c)=> {
                    if (item.scope.countries.indexOf(c.id) > -1) {
                      item.scope.locationdescr += c.name + ', ';
                      item.scope.countryName = c.name;
                    }
                  });
                  if (item.scope.locationdescr
                    && item.scope.locationdescr.length > 0
                    && item.scope.locationdescr.lastIndexOf(', ') == (item.scope.locationdescr.length - 2)) {
                    item.scope.locationdescr = item.scope.locationdescr.slice(0, -2);
                  }
                });
              }
              else if (item.scope.regions) {
                this.mdRegionService.regions().then((regions) => {
                  regions.forEach((r)=> {
                    if (item.scope.regions.indexOf(r.id) > -1) {
                      item.scope.locationdescr += r.name + ', ';
                      item.scope.regionName = r.name;
                    }
                  });
                  if (item.scope.locationdescr
                    && item.scope.locationdescr.length > 0
                    && item.scope.locationdescr.lastIndexOf(', ') == (item.scope.locationdescr.length - 2)) {
                    item.scope.locationdescr = item.scope.locationdescr.slice(0, -2);
                  }
                });
              }
            }

            );

            resolve(data);
          },
          err=>{
            this.toasterService.showError('Some thing went wrong please try again')
          }
        )
      })
    }

    deleteProcessOwner(geid, scope):Promise<any>{
     return new Promise<any>((resolve,reject)=>{
      this.http.post(`${environment.azure.baseUrl}api/v1/processowner/delete`,{ geid: geid, div: scope.div, bu: scope.bu, pg: scope.pg, region: scope.region, country: scope.country }).subscribe(
        (data:any)=>{
          if(data.success){
            this.toasterService.showSuccess('Process Owner has been successfully deleted');
            if (geid == this.poAuthService.getUserGeid()) {
              this.refreshUserProfile().then((userProfile)=>{
                this.poAuthService.refreshUserInfoData(userProfile);
                resolve(data);
               });

            }else{
              resolve(data);
            }
          }else{
            this.toasterService.showError(data.failureMsg);
          }
        },
        err =>{
          this.toasterService.showError('Delete operation failed');
        }
      )
     })
    }
    addProcessOwner(geid, scope):Promise<any>{
      // console.log(geid,scope);
      return new Promise<any>((resolve,reject)=>{
        this.http.post<any>(`${environment.azure.baseUrl}api/v1/processowner/add`, {geid: geid, div: scope.div, bu: scope.bu, pg: scope.pg, region: scope.region, country: scope.country})
        .subscribe((data : any)=>{
          if (data.success){
            this.toasterService.showSuccess('Process Owner has been successfully created');
            if (geid == this.poAuthService.getUserGeid()) {
              this.refreshUserProfile().then((userProfile)=>{
                this.poAuthService.refreshUserInfoData(userProfile);
                resolve(data);
               });

            }else{
              resolve(data);
            }
          }else {
            this.toasterService.showError(data.failureMsg);
          }
          resolve(data);
        },
        (err)=>{
          this.toasterService.showError('Add operation failed');
        })
      })

    }

    refreshUserProfile():Promise<any>{
      return new Promise<any>((resolve,reject)=>{
        this.http.get<any>(`${environment.azure.baseUrl}api/v1/config/RefreshUserProfile`).subscribe((data)=>{
            this.sessionStorage.setData('userInfo', data);
            resolve(data);
          },
          (error)=>{
            reject(error);
          }
        );
      });
    }

    getCountriesDetails(countryCodes){
      let countries = [];
      let ctr = _.filter(countryCodes, (c) => { return c.indexOf('ALL') == -1; });

      this.mdRegionService.countriesByCode(ctr).then((coInfo) =>{
        coInfo.forEach(c => {
          countries.push(c);
        });

        _.filter(countryCodes, (c) => { return c.indexOf('ALL') > -1; }).forEach(ccCode => {
          countries.push({ id: ccCode, name: ccCode, rCode: ccCode.substring(0, ccCode.indexOf('ALL')) });
        });
      });

      return countries;
    }

    loadUnitManagers(itemScope):Promise<any>{
     return new Promise<any>((resolve,reject)=>{
      this.employeeService.processOwnerdDetails(_.uniq(
        _.map(itemScope.unitManagers,
          (mgr) => {
            return mgr.geid;
          })
      )).then( (poInfo) => {
        let unitManagers = [];

        itemScope.unitManagers.forEach(
           (mgr) => {
            // let unitManager = _.find(poInfo,
            //   (po) => {
            //     return mgr.geid == po.id;
            //   });
            const unitManager= cloneDeep(find(poInfo,(po)=> mgr.geid == po.id))


            if (!_.isUndefined(unitManager) && !_.isNull(unitManager)) {
              unitManager.scope =
                {
                  div: mgr.div ? mgr.div : null,
                  bu: mgr.bu ?  mgr.bu : null ,
                  pg: mgr.pg ? mgr.pg : null,
                  region: mgr.region ? mgr.region : null,
                  country: mgr.country ? mgr.country : null
                }
              unitManager.canManage = this.poAuthService.canManageProcessOwner(unitManager.scope);

              unitManagers.push(unitManager);
            }
          }
        );

        itemScope.unitManagers = unitManagers;

        itemScope.unitManagersOrderedVertically = this.arrayService.splitVertically( _.orderBy(itemScope.unitManagers,
            ['scope.div', 'scope.bu', 'scope.pg', 'name']), 3);
      });
      resolve(itemScope);
     })
    }


    loadLocationUnitManagers(itemScope):Promise<any>{
      return new Promise((resolve,reject)=>{
        this.mdRegionService.countriesByCode(_.uniq(
          _.map(itemScope.unitLocationManagers,
            (mgr)=> {
              return mgr.country;
            })
        )).then( (countryDetails:any) => {
          this.employeeService.processOwnerdDetails(_.uniq(
            _.map(itemScope.unitLocationManagers,
              (mgr) => {
                return mgr.geid;
              })
          )).then( (poInfo) => {
            let unitLocationManagers = [];
            itemScope.unitLocationManagers.forEach(
              (mgr) => {

                // var unitLocationManager = _.find(poInfo,
                //   (po) => {
                //    return mgr.geid == po.id;
                //  });
                const unitLocationManager= cloneDeep(find(poInfo,(po)=> mgr.geid == po.id))

                if (!_.isUndefined(unitLocationManager) && !_.isNull(unitLocationManager)) {
                  unitLocationManager.scope =
                    {
                      div: mgr.div ? mgr.div : null,
                      bu: mgr.bu ? mgr.bu : null,
                      pg: mgr.pg ? mgr.pg : null,
                      region: mgr.region ? mgr.region :null ,
                      country: mgr.country ? mgr.country : null
                    }
                 unitLocationManager.canManage = this.poAuthService.canManageProcessOwner(unitLocationManager.scope);
                 if (unitLocationManager.scope.country) {
                    unitLocationManager.scope.countryName =
                      (_.find(countryDetails,
                         (c) => { return c.id == unitLocationManager.scope.country; }))?.name;
                  }

                  unitLocationManagers.push(unitLocationManager);
                }
              }
            )


            itemScope.unitLocationManagers = unitLocationManagers;

            itemScope.unitLocationManagersOrderedVertically = this.arrayService.splitVertically(_.orderBy(itemScope.unitLocationManagers,
                [
                  'scope.div', 'scope.bu', 'scope.pg', '-scope.region', 'scope.countryName',
                  'name'
                ]),
                3);
            resolve(itemScope);

          });
        });
      })
    }

    loadLocationManagers(itemScope):Promise<any>{
      return new Promise((resolve,reject)=>{
        this.mdRegionService.countriesByCode(_.uniq(_.map(itemScope.locationManagers, (mgr) => {
          return mgr.country;
        }))).then((countryDetails) => {
          this.employeeService.processOwnerdDetails(_.uniq(
            _.map(itemScope.locationManagers, (mgr)=> {
              return mgr.geid;
            })
          )).then((poInfo) => {
            let locationManagers = [];
            itemScope.locationManagers.forEach( (mgr) => {
              // var locationManager = _.find(poInfo,  (po) => {
              //   return mgr.geid == po.id;
              // });

              const locationManager= cloneDeep(find(poInfo,(po)=> mgr.geid == po.id))


              if (!_.isUndefined(locationManager) && !_.isNull(locationManager)) {
                locationManager.scope = { div: mgr.div, bu: mgr.bu, pg: mgr.pg, region: mgr.region, country: mgr.country }
              locationManager.canManage = this.poAuthService.canManageProcessOwner(locationManager.scope);
                if (locationManager.scope.country) {
                  locationManager.scope.countryName = (_.find(countryDetails, (c) => { return c.id == locationManager.scope.country; })).name;
                }
                locationManagers.push(locationManager);
              }
            });

           itemScope.locationManagersOrderedVertically = this.arrayService.splitVertically(_.orderBy(locationManagers, ['-scope.region', 'scope.countryName', 'name']), 3);

            if (itemScope.type == 1) { //unit
              itemScope.canManage = this.poAuthService.canManageUnit(itemScope.scope);
            } else if (itemScope.type == 2) { //location
              itemScope.canManage = this.poAuthService.canManageLocation(itemScope.scope);
            }
            resolve(itemScope);
          });
        });
      })
    }

    downloadProcessOwners(query){
      return this.http.post(`${environment.azure.baseUrl}api/v1/processowner/download`,query,{
        responseType: 'arraybuffer'
      });
    }

}
