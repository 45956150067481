<form [formGroup]="resolvingPopForm">
    <div class="popup resolving-unit-pop">
        <div class="container">
            <div class="form-check select-search mt-1">
                <input type="radio" [(ngModel)]="radioValues" value="balRadio" formControlName="balIdRadioBtn"
                    (click)="enableTextBox()" class="select-balId">
                <label class="widgetRadioButton float-start" (change)="onRadioChange()">
                  {{ "RUSearchPolicyBalId" | translate }}
                </label>
                <div class="col resolvingPopDiv ms-1 mt-1">
                    <!-- <label class="divColoumnSpan tagColumn">{{ "ResolvingUnitSearch_BALId" | translate }}</label> -->
                    <input type="text" class="form-control form-control-sm textBoxForBalId" placeholder="BAL ID" formControlName="balId" [disabled]="!textboxEnabled">
                </div>
            </div>

            <div class="form-check select-search  mt-3">
                <input type="radio" [(ngModel)]="radioValues" value="Search by business activity location"
                    formControlName="searchByBusinessLocation" (change)="onRadioChange()">
                <label class="widgetRadioButton">
                  {{ "RUSearchPolicyBAL" | translate }}
                </label>
            </div>
            <div>
                <label class="divColoumnSpan tagColumn ms-1">{{ "ResolvingUnitSearch_BusinessActivity" | translate }}</label>
                <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" id="inlineRadio1" value="local"
                        formControlName="local">
                    <label class="form-check-label" for="inlineRadio1">{{ "select_ru_popup_check_local" | translate }}</label>
                </div>

                <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" id="inlineRadio2" value="global"
                        formControlName="local">
                    <label class="form-check-label" for="inlineRadio2">{{ "select_ru_popup_check_global" | translate }}</label>
                </div>

                <div class="row inp mb-1 ms-1">
                    <div class="divColoumnSpan tagColumn ">{{ "select_ru_popup_division" | translate }}</div>
                    <div class="col remove-px">
                        <select type="text" class="form-select form-control widgetTextBoxMedium"
                            aria-label="Default select example" formControlName="business">
                            <option value="">--Select one--</option>
                            <option *ngFor="let div of divisionData" value={{div?.key}}> {{ div?.value }} </option>
                        </select>
                    </div>

                    <div class="divColoumnSpan tagColumn">{{ "select_ru_popup_country" | translate }}</div>
                    <div class="col remove-px"><select id="inputCountry" class="form-select form-control widgetTextBoxMedium"
                            formControlName="country" aria-labelledby="country"
                           >
                            <option value="" selected>--Select one--</option>
                            <option *ngFor="let country of getCountryList" [value]="country?.id"> {{ country?.name }} </option>
                        </select>
                    </div>
                </div>

                <div class="row  inp mb-1 ms-1">
                    <div class="divColoumnSpan tagColumn">{{ "select_ru_popup_bu" | translate }}</div>
                    <div class="col remove-px"><select type="text" class="form-select form-control widgetTextBoxMedium"
                            formControlName="bu" aria-labelledby="bu" [(ngModel)]="selectedBu"
                            (change)="pgChangeData ($event)">
                            <option value="" selected>--Select one--</option>
                            <option *ngFor="let count of buPgList" value={{count?.key}}>{{count.value}} </option>
                        </select>
                    </div>

                    <div class="divColoumnSpan tagColumn"> {{ "select_ru_popup_city" | translate }}</div>
                    <div class="col remove-px">
                        <select class="form-select form-control widgetTextBoxMedium" formControlName="city" name="city"
                            [(ngModel)]="city">
                            <option value="" selected>--Select one--</option>
                            <option *ngFor="let city of getCityList" value={{city?.id}}> {{ city?.name }}({{ city?.cCode}}) </option>
                        </select>
                    </div>
                </div>

                <div class="row  inp mb-1 ms-1">
                    <div class="divColoumnSpan tagColumn">{{ "select_ru_popup_pg" | translate }}</div>
                    <div class="col remove-px"><select type="text" class="form-select form-control widgetTextBoxMedium"
                            aria-label="Default select example" formControlName="pg">
                            <option value="" selected>--Select one--</option>
                            <option *ngFor="let pgDetails of pgName" value={{pgDetails?.key}}>{{pgDetails.value}} </option>
                        </select></div>

                    <div class="divColoumnSpan tagColumn">{{ "select_ru_popup_activity" | translate }}</div>
                    <div class="col remove-px"><select type="text" class="form-select form-control widgetTextBoxMedium"
                            aria-label="Default select example" formControlName="activity">
                            <option value="" selected>--Select one--</option>
                            <option *ngFor="let b of activityDropdownData" value={{b?.key}}>
                                {{ b.value }}
                            </option>
                        </select>
                    </div>
                </div>
            </div>

            <div class="modal-footer my-2">
                <button class="input widgetButtonMedium" type="button" (click)="viewRuPopUpDetails()">{{ "select_ru_popup_btn_view" | translate }}</button>
                <button class="input widgetButtonMedium ms-1" type="button" (click)="reset()">{{ "select_ru_popup_btn_reset" | translate }}</button>
            </div>
        </div>

        <div class="tablediv">
            <h5 class="mx-2 mt-2" *ngIf="recentlySelected">{{ "SearchForm_ResolvingUnitRecentlySelected" | translate }}</h5>
        <div class="tableMargin">
        <div class="card ">
            <p-table [value]="filteredCompanyDetails" [lazy]="true" [totalRecords]="totalRecords" [loading]="loading" (onLazyLoad)="loadMoreResolvingData($event)"
                     selectionMode="single" [paginator]="true" [rows]="rows" [showCurrentPageReport]="true" [(first)]="first"
                     (onRowSelect)="onRowSelect($event)"
                     currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                      styleClass="p-datatable-gridlines">
                <ng-template pTemplate="header" styleClass="table table-bordered table-striped table-hove">
                    <tr>
                        <th pSortableColumn="balId" style="width:10%">{{ "ResolvingUnitSearch_GridColumn_BALId" | translate }}<p-sortIcon field="balId"></p-sortIcon>
                        </th>
                        <th pSortableColumn="abacus" style="width:10%">{{ "ResolvingUnitSearch_GridColumn_AbacusCode" | translate }}<p-sortIcon field="abacus"></p-sortIcon>
                        </th>
                        <th pSortableColumn="mgmtOrg" style="width:10%">{{ "ResolvingUnitSearch_GridColumn_MgmtOrg" | translate }}<p-sortIcon field="mgmtOrg"></p-sortIcon>
                        </th>
                        <th pSortableColumn="productGroup" style="width:25%">{{ "ResolvingUnitSearch_GridColumn_ProductGroup" | translate }}<p-sortIcon
                                field="productGroup"></p-sortIcon></th>
                        <th pSortableColumn="address" style="width:25%">{{ "ResolvingUnitSearch_GridColumn_Address" | translate }}<p-sortIcon field="address"></p-sortIcon>
                        </th>
                        <th pSortableColumn="activity" style="width:20%">{{ "ResolvingUnitSearch_GridColumn_Activity" | translate }}<p-sortIcon
                                field="activity"></p-sortIcon></th>
                    </tr>
                </ng-template>

                <ng-template pTemplate="body" let-c let-rowIndex="rowIndex">
                    <tr [ngClass]="{ selectedBalIdRow: rowIndex == selectedIndex }" [pSelectableRow]="c">

                        <td>{{ c.baL_Id }} </td>
                        <td>{{ c.abacusCode }}</td>
                        <td>{{ c.mgmtOrg}}</td>
                        <td>{{ c.productGroup }}</td>
                        <td>{{ c.address }}</td>
                        <td>{{ c.activity}}</td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="paginatorright">

                    <p-button type="button" icon="pi pi-refresh" (click)="refresh()"
                        styleClass="p-button-text"></p-button>
                </ng-template>
            </p-table>

            <div *ngIf="rowSelectData" class="mt-2 mb-2 ms-2">

                <div class="trEmail">
                  <span class="ms-1">
                    <small *ngIf="isAccountablePerson" class="label-bold">{{'ResolvingUnitSearch_AccountablePersons' | translate}} :</small>
                      <small *ngIf="isRUPerson" class="label-bold">{{'ResolvingUnitSearch_ResolvingUnitPersons' | translate}} :</small></span>
                    <td class="selected-row tdEmail ms-1 my-1" *ngFor="let coordinator of coordinatorData">
                        {{ coordinator?.displayLabel }}
                        <!-- <span class="custom-tooltip">{{selectedRowData.balId}}</span> -->
                    </td>
                  </div>
            </div>
        </div>
        </div>
        </div>


        <div class="modal-footer resolvingTableBtnAlign mt-2 mb-2">
            <button [disabled]="selectedIndex === -1" class="widgetButtonLargeSelectClose"
                [ngClass]="selectedIndex !== -1 ? '' : ' grayButton'"
                type="button" (click)="selectAndClose()"> {{ "select_ru_popup_btn_select" | translate }}
            </button>
            <button class="input widgetButtonLarge ms-1" type="button" (click)="onClose()">{{ "select_ru_popup_btn_close" | translate }}</button>
        </div>
    </div>
</form>
