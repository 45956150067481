import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MessageService, PrimeNGConfig } from 'primeng/api';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { RevertToNotAssignEmailServiceService } from 'src/app/services/revert-to-not-assign-email-service.service';

@Component({
  selector: 'app-revert-to-not-assign-email',
  templateUrl: './revert-to-not-assign-email.component.html',
  styleUrls: ['./revert-to-not-assign-email.component.css'],
  providers: [MessageService]
})
export class RevertToNotAssignEmailComponent implements OnInit {
  complaintId !: number;
  complaintData !: any;
  emailForm!: FormGroup;
  emailList: any = [];
  emailRequest: any = {};
  allEmailList: any = [];
  paramForAttachment: any;

  emailList1 = [{ id: 1, name: 'Coordinator' },
  { id: 2, name: 'ResolutionOwner' },
  { id: 3, name: 'ResolutionOwnerTeam' },
  { id: 4, name: 'AccountManager' }];

  constructor(private fb: FormBuilder,
    private primengConfig: PrimeNGConfig,
    private readonly dialogRef: DynamicDialogRef,
    private emailService: RevertToNotAssignEmailServiceService,
    private messageService: MessageService,
  ) { }

  ngOnInit(): void {
    this.paramForAttachment = {0: '30 MB'};
    this.primengConfig.ripple = true;
    this.emailForm = this.fb.group({
      to: [[], [Validators.required]],
      cc: [[]/*, [Validators.required]*/],
      subject: ['', [Validators.required]],
      template: ['11', [Validators.required]],
      attachment: ['', [Validators.required]],
      format: ['', [Validators.required]],
      textareaBody: ['', []],

    });

    this.emailService.getEmployeeDetails().subscribe((res: any) => {
      this.allEmailList = res;
      // console.log('All', this.allEmailList)
    })

  }

  onRecipientChange(event: any) {
    // console.log("abcd")
    // console.log(event.query);
    this.emailService.getEmpDetails(event.query).subscribe((res: any) => {
      // console.log("mail send. response " + res);
      this.emailList = res;
      // this.emailList = this.emailList.push('res')
      // this.emailForm.get('to')?.patchValue(this.emailList)
    })
  }

  subjectInc() {
    this.emailService.getSubject(this.complaintId).subscribe((res: any) => {
      // console.log("mail send. response " + res);
      this.emailForm.get('subject')?.patchValue(res.subject);
    });

  }


  template(temp: any) {
    this.emailService._getTemplate(temp.target.value)?.subscribe((res: any) => {
      // console.log("templatebody. response " + res.body);
      // this.emailForm.get('to')?.patchValue(res.defaultRecipients != null ? res.defaultRecipients.length > 0 ? res.defaultRecipients[0] : '' : '');
      // this.emailForm.get('cc')?.patchValue(res.defaultCC != null ? res.defaultCC.length > 0 ? res.defaultCC[0] : '' : '');

      this.emailForm.get('to')?.patchValue(res.defaultRecipients != null ? res.defaultRecipients.length > 0 ? res.defaultRecipients : [] : []);

      this.emailForm.get('cc')?.patchValue(res.defaultCC != null ? res.defaultCC.length > 0 ? res.defaultCC : [] : []);
      this.emailForm.get('subject')?.patchValue(res.subject);
      this.emailForm.get('textareaBody')?.patchValue(res.body);
      // console.log('preview', res.defaultRecipients[0]);
      // let tovalue = this.emailList1.filter(list => list.name == res.defaultRecipients[0])
      // this.emailForm.patchValue({
      //   to: tovalue

      // })
      // let tovalue: any [] =[];
      // res.defaultRecipients.forEach((recipient: any) => {
      //   console.log('View', this.allEmailList[0].promptLabel)
      //   let tovalue;
      //   this.allEmailList.forEach((list: any) => {
      //     console.log(list.promptLabel, recipient, list.promptLabel == recipient)
      //     if (list.promptLabel == recipient) {
      //       return tovalue = list;
      //     }
      //   });
      //   console.log('TO', tovalue);
      //   this.emailForm.patchValue({
      //     to: tovalue

      //   })
      // });

              // let tovalue = this.allEmailList.filter((list: any) => list.promptLabel == recipient);


    });
  }

  sendMail() {
    // this.emailRequest.to = this.emailForm.get('to')?.value.map((i: { selectedLabel: any; }) => i.selectedLabel);
    // this.emailRequest.cc = this.emailForm.get('cc')?.value.map((i: { selectedLabel: any; }) => i.selectedLabel);
    // this.emailRequest.subject = this.emailForm.get('subject')?.value;
    // console.log('EmailSentValues',this.emailForm.value);
   // this.dialogRef.close('this.emailRequest');
    this.dialogRef.close('result');


    // this.messageService.add({ severity: 'success', summary: 'Service Message', detail: 'Via MessageService' });

  }


  onConfirm() {
    this.messageService.clear('c');
  }

  onReject() {
    this.messageService.clear('c');
  }

  clear() {
    this.messageService.clear();
  }
  onClose() {
    this.dialogRef.close();
  }


}

