import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { AdminHubsService } from 'src/app/services/admin-hubs.service';
import { ToasterserviceService } from 'src/app/services/toasterservice.service';
import { HubAuthService } from '../hub-auth.service';

@Component({
  selector: 'app-hub-add-person-popup',
  templateUrl: './hub-add-person-popup.component.html',
  styleUrls: ['./hub-add-person-popup.component.css']
})
export class HubAddPersonPopupComponent implements OnInit {

  addPersonForm!: FormGroup;
  filteredHubProcessOwner: any[]
  filteredHubPO: any;
  data: any = { guids: [] };
  objectdata: { hubIds: any; hubProcessOwnerGeids: string; };
  userinfo: any;
  hubCardId: Array<any> = [];
  cardData: Array<any> = [];
  suggestions: string[];
  selectedHubs: any = [];

  constructor(
    private fb: FormBuilder,
    private readonly dialogRef: DynamicDialogRef,
    private adminHubsService: AdminHubsService,
    private config: DynamicDialogConfig,
    public toasterService: ToasterserviceService,
    private hubAuthService: HubAuthService
  ) { }

  ngOnInit(): void {

    this.addPersonForm = this.fb.group({
      'processOwner': ['',Validators.required],
      // 'selectedHub': ['',],
      "hubIds": ['',Validators.required],
      // "hubProcessOwnerGeids": this.fb.array([])
    })
    this.userinfo = this.config.data;
    this.selectedHubs = this.config.data?.selectedHubs;
    this.addPersonForm.patchValue({
      hubIds: this.selectedHubs
    })

    this.userinfo.hubArray.forEach(element => {
      this.cardData.push({ id: element.id, value: element.divCode + ', ' + element.buCode + ', ' + element.leadCountryName })
    });
  }

  customerSelect(event) {
    this.addPersonForm.controls['processOwner'].value.forEach(customer => {
      if (!this.data.guids.includes(customer?.id)) {
        this.data.guids.push(customer?.id)
      }
    });
  }

  customerGuidsUnselect(event) {
    const unselectedGuids = event.id;
    const index = this.data.guids.findIndex(item => item === unselectedGuids);
    if (index !== -1) {
      this.data.guids.splice(index, 1)
    }
  }

  removecardid(event:any) {
    this.userinfo;
    const unselecteremovedGuids = event.id;
    const index = this.selectedHubs.findIndex(item => item.id == unselecteremovedGuids);
    if (index !== -1) {
      this.userinfo.selectedHubs.splice(index, 1)
    }
  }

  filterHubProcessOwner(event) {
    const dataPasses = {
      wildcard: event.originalEvent.target.value,
      onlyActive: true,
      page: 1,
      size: 26
    };
    if(event.originalEvent.target.value.length>1){
      this.adminHubsService.getHubPOUser(dataPasses).subscribe((response) => {
        this.filteredHubPO = response;
        this.filteredHubPO.map((data) =>{
          data.name = data.name + '<' + data.email + '>';
        })
      })
    }
  }

  add() {
    this.objectdata = {
      // "hubIds": this.userinfo.hubIds,
      "hubIds": this.selectedHubs.map((a) => (a.id)),
      "hubProcessOwnerGeids": this.data.guids
    }

    let selectedHubs: any = [];
    this.userinfo.cardinfo.forEach(item => {
      if (item.id > -1) {
        selectedHubs.push(item);
      }
    });

    if(this.hubAuthService.canManageHubs(selectedHubs)){
      this.adminHubsService.addHubPerson(this.objectdata).subscribe((res) => {
        const deletepersonInfo = res
        this.dialogRef.close(this.objectdata);
        this.toasterService.showSuccess('Hub person has been successfully created');
      })
    }
    else {
      this.toasterService.showError('Cannot add this Hub Persons. Insufficient permissions.');
    }
  }

  onClose() {
    this.dialogRef.close();
  }
}
