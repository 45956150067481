import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { NewIssueService } from 'src/app/services/newIssue/new-issue.service';

@Component({
  selector: 'app-target-date-history',
  templateUrl: './target-date-history.component.html',
  styleUrls: ['./target-date-history.component.css']
})
export class TargetDateHistoryComponent implements OnInit {
  targetHistory: any;
  complaintNumber: any;
  IsExcelGenerating : boolean = false;

  constructor(private newIssueService: NewIssueService,
    private route: ActivatedRoute,
    private breadcrumbService: BreadcrumbService) { }

  ngOnInit(): void {
    this.breadcrumbService.menuItems = this.breadcrumbService.menuItems = [
      {label: 'TARGET RESOLVING DATE CHANGE LOG'}
    ];

    this.complaintNumber = this.route.snapshot.paramMap.get('complaintNumber');

    //const targetId = '586366';
    this.newIssueService.getTargetDateHistory(this.complaintNumber).subscribe((response) => {
         this.targetHistory = response;
    })
  }

  downloadDateHistory() {
    this.IsExcelGenerating = true;
    //const targetId = '586366';
    this.newIssueService.getTargetDateHistoryDownload(this.complaintNumber).subscribe((response:ArrayBuffer) => {
      const blob = new Blob([response],{type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
      const downloadLink = document.createElement('a');
      downloadLink.href = window.URL.createObjectURL(blob);
      downloadLink.download ='TargetDataLog';
      downloadLink.click();
      this.IsExcelGenerating = false;
    })
  }
}
