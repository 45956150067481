<a class="employee EmployeePOAdmin" style="padding-left: 5px; cursor: pointer;" [autoClose]="true" (resize)="true" container="body"
  [ngbPopover]="popContent" popoverTitle="{{poScope.name}}&nbsp;&#40;{{poScope.displayGeid}}&#41;"
  [placement]="'auto'" onMouseOver="this.style.color='#ff0000'" onMouseOut="this.style.color='#000000'">
  {{poScope?.name}}
</a> -
<small class="employeDetails">{{poScope?.scope?.div}}
  {{poScope?.scope?.bu}} {{poScope?.scope?.pg}} {{poScope?.scope?.region}} {{poScope?.scope?.countryName}}
</small>
<ng-template #popContent style="position:relative;">

  <small *ngIf="poScope.address.countryCode">
    <cite>
      {{poScope.address.countryCode}}, {{poScope.address.city}}, {{poScope.address.address}}
      <a href="https://maps.google.pl/?q={{poScope.address.country}}, {{poScope.address.city}}, {{poScope.address.address}}"
        target="_blank" class="gray-link">
        <i class="hitachiicon-location"></i>
      </a>
    </cite>
  </small>
  <p class="mb-1">
    <small>
      <i class="glyphicon glyphicon-envelope"></i><a onMouseOver="this.style.color='#ff0000'"
      onMouseOut="this.style.color='#000000'" href="mailto:{{poScope.email}}" class="ms-1 ">{{poScope.email}}</a>
      <br />
      <i *ngIf="poScope.phone" class="glyphicon glyphicon-phone"></i><a *ngIf="poScope.phone" onMouseOver="this.style.color='#ff0000'"
      onMouseOut="this.style.color='#000000'" href="tel:{{poScope.phone}}"
        class="ms-1">{{poScope.phone}}</a>
    </small>
  </p>

  <div *ngIf="poScope.units.length > 0  || poScope.unitLocations.length >0">
    <strong>{{'admin_po_info_roles_lbl' | translate}}</strong>
    <div class="row " *ngIf="visibility.div.unit || visibility.div.location">
      <div class="col-sm-4 text-muted">Business</div>
      <div class="col-sm-8">
        <ul *ngIf="visibility.div.unit" class="list-unstyled mb-0">
          <li *ngFor="let u of poScope.units">
            <small *ngIf="u.div">{{u.div}}</small>
          </li>
        </ul>
        <ul *ngIf="visibility.div.location" class="list-unstyled mb-0">
          <li *ngFor="let ul of poScope.unitLocations | order :['region','countryName']">
            <small *ngIf="ul.div">{{ul.div}} {{ul.region}} {{ul.countryName}}</small>
          </li>
        </ul>
      </div>
    </div>
    <div class="row" *ngIf="visibility.bu.unit || visibility.bu.location">
      <div class="col-sm-4 text-muted">BU</div>
      <div class="col-sm-8">
        <ul *ngIf="visibility.bu.unit" class="list-unstyled mb-0">
          <li *ngFor="let u of poScope.units">
            <small *ngIf="u.bu">{{u.bu}}</small>
          </li>
        </ul>
        <ul *ngIf="visibility.bu.location" class="list-unstyled mb-0">
          <li *ngFor="let ul of poScope.unitLocations | order :['region','countryName']">
            <small *ngIf="ul.bu">{{ul.bu}} {{ul.region}} {{ul.countryName}}</small>
          </li>
        </ul>
      </div>
    </div>
    <div class="row" *ngIf="visibility.pg.unit || visibility.pg.location">
      <div class="col-sm-4 text-muted">PG</div>
      <div class="col-sm-8">
        <ul *ngIf="visibility.pg.unit" class="list-unstyled mb-0">
          <li *ngFor="let u of poScope.units">
            <small *ngIf="u.pg">{{u.pg}}</small>
          </li>
        </ul>
        <ul *ngIf="visibility.pg.location" class="list-unstyled mb-0">
          <li *ngFor="let ul of poScope.unitLocations | order :['region','countryName']">
            <small *ngIf="ul.pg"> {{ul.pg}} {{ul.region}} {{ul.countryName}}</small>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div *ngIf="poScope.locations">
    <strong> {{'admin_po_regioncountry_po_single_lbl' | translate}}:</strong>
    <div class="row">
      <div class="col-sm-4">&nbsp;</div>
      <div class="col-sm-8">
        <ul class="list-unstyled mb-0">
          <li *ngFor="let l of poScope.locations | order :['region','countryName']">
            <small> {{l.region}} {{l.countryName}}</small>
          </li>
        </ul>
      </div>
    </div>

  </div>

</ng-template>
