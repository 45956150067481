<div class="row mb-2 mt-2">
    <div class="col-sm-3">
        <div class="dropdown-actionplan" *ngIf="canAddNewActions">
            <span class="action-plan-new-action-link" (click)="goToCreateNewCard()">
                <i style="color: #ff0026;" class="fa fa-plus"></i>
                <b>{{'cc_action_plan_add_new_action' | translate}}</b>
            </span>
            <ul class="dropdown-content" style="list-style: none; padding-left: 5px; width: 250px;">
                <li *ngFor="let type of newActionTypes | filter: 'isSelectable' : false" style="height: 20px; cursor: pointer;">
                    {{type.name}}
                    <ul class="action-plan-type-sub" style="list-style: none; padding-left: 0; width: 250px;">
                        <li *ngFor="let child of type.children" (click)="goToCreateNewCard(child.id)">
                            <a (click)="goToCreateNewCard(child.id)">{{child.name}}</a>
                        </li>
                    </ul>
                </li>
                <li *ngFor="let type of newActionTypes | filter: 'isSelectable' : true">
                    <a (click)="goToCreateNewCard(type.id)">
                        {{type.name}}
                    </a>
                </li>
            </ul>
        </div>
    </div>
    <div class="col-sm-7"></div>
    <div class="col-sm-2">
        <a class="backLocationLink" (click)="onBackLocation()">{{'cc_action_plan_back_to_ccrp' | translate}}</a>
    </div>
</div>

<div class="row heading">
    <div class="col-sm-4 mobileWidth">
        <h3 style="margin-left:20px" class="mobilePadding">
            {{'cc_action_plan_header_todo' | translate}}
        </h3>
    </div>
    <div class="col-sm-4 mobileWidth">
        <h3 class="mobilePadding">
            {{'cc_action_plan_header_in_progress' | translate}}
        </h3>
    </div>
    <div class="col-sm-4 mobileWidth">
        <h3 class="mobilePadding">
            {{'cc_action_plan_header_closed' | translate}}
        </h3>
    </div>
</div>


<div style="display: table; width: 100%; border-spacing: 10px;">
    <div id="todo" class="action-plan-board-container" (onDrop)="dropFromInProgressToDo()" [pDroppable]='["inprogress"]'>
        <div *ngFor="let card of cardData?.todo">
            <app-action-plan-card pDraggable="draft" (onDragStart)="dragStart(card, 'todo')" (deleteCard)="deleteCard($event)" [item]="card" [selectedRole]="selectedRole"></app-action-plan-card>
        </div>
    </div>

    <div id="inprogress" class="action-plan-board-container" (onDrop)="dropFromToDoAndClosed()" [pDroppable]='["draft", "closed"]'>
        <div *ngFor="let card of cardData?.inProgress">
            <app-action-plan-card pDraggable="inprogress" (onDragStart)="dragStart(card, 'inprogress')" [item]="card" [selectedRole]="selectedRole"></app-action-plan-card>
        </div>
    </div>

    <div id="done" class="action-plan-board-container" (onDrop)="dropFromInProgressToClosed()" [pDroppable]='["inprogress"]'>
        <div *ngFor="let card of cardData?.closed">
            <app-action-plan-card pDraggable="closed" (onDragStart)="dragStart(card, 'done')" [item]="card" [selectedRole]="selectedRole"></app-action-plan-card>
        </div>
    </div>
</div>


<p-toast position="top-right"></p-toast>
