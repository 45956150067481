import * as i0 from '@angular/core';
import { EventEmitter, Component, ViewEncapsulation, ChangeDetectionStrategy, Input, Output, ViewChild, Inject, ContentChildren, NgModule } from '@angular/core';
import * as i1 from '@angular/common';
import { DOCUMENT, CommonModule } from '@angular/common';
import * as i3 from 'primeng/api';
import { PrimeTemplate, SharedModule } from 'primeng/api';
import { UniqueComponentId, ZIndexUtils, ObjectUtils } from 'primeng/utils';
import * as i2 from 'primeng/ripple';
import { RippleModule } from 'primeng/ripple';
import { trigger, state, style, transition, animate, query, animateChild } from '@angular/animations';
import { CheckIcon } from 'primeng/icons/check';
import { InfoCircleIcon } from 'primeng/icons/infocircle';
import { TimesCircleIcon } from 'primeng/icons/timescircle';
import { ExclamationTriangleIcon } from 'primeng/icons/exclamationtriangle';
import { TimesIcon } from 'primeng/icons/times';
const _c0 = ["container"];
const _c1 = a0 => [a0, "p-toast-message"];
const _c2 = (a0, a1, a2, a3) => ({
  showTransformParams: a0,
  hideTransformParams: a1,
  showTransitionParams: a2,
  hideTransitionParams: a3
});
const _c3 = a0 => ({
  value: "visible",
  params: a0
});
const _c4 = a0 => ({
  $implicit: a0
});
function ToastItem_ng_container_3_span_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "span");
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵclassMap("p-toast-message-icon pi " + ctx_r1.message.icon);
  }
}
function ToastItem_ng_container_3_span_2_CheckIcon_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "CheckIcon");
  }
}
function ToastItem_ng_container_3_span_2_InfoCircleIcon_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "InfoCircleIcon");
  }
}
function ToastItem_ng_container_3_span_2_TimesCircleIcon_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "TimesCircleIcon");
  }
}
function ToastItem_ng_container_3_span_2_ExclamationTriangleIcon_5_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "ExclamationTriangleIcon");
  }
}
function ToastItem_ng_container_3_span_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span", 11);
    i0.ɵɵelementContainerStart(1);
    i0.ɵɵtemplate(2, ToastItem_ng_container_3_span_2_CheckIcon_2_Template, 1, 0, "CheckIcon", 3)(3, ToastItem_ng_container_3_span_2_InfoCircleIcon_3_Template, 1, 0, "InfoCircleIcon", 3)(4, ToastItem_ng_container_3_span_2_TimesCircleIcon_4_Template, 1, 0, "TimesCircleIcon", 3)(5, ToastItem_ng_container_3_span_2_ExclamationTriangleIcon_5_Template, 1, 0, "ExclamationTriangleIcon", 3);
    i0.ɵɵelementContainerEnd();
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngIf", ctx_r1.message.severity === "success");
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r1.message.severity === "info");
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r1.message.severity === "error");
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r1.message.severity === "warn");
  }
}
function ToastItem_ng_container_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, ToastItem_ng_container_3_span_1_Template, 1, 2, "span", 6)(2, ToastItem_ng_container_3_span_2_Template, 6, 4, "span", 7);
    i0.ɵɵelementStart(3, "div", 8)(4, "div", 9);
    i0.ɵɵtext(5);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(6, "div", 10);
    i0.ɵɵtext(7);
    i0.ɵɵelementEnd()();
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r1.message.icon);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !ctx_r1.message.icon);
    i0.ɵɵadvance(3);
    i0.ɵɵtextInterpolate(ctx_r1.message.summary);
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate(ctx_r1.message.detail);
  }
}
function ToastItem_ng_container_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function ToastItem_button_5_span_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "span");
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵclassMap("p-toast-message-icon pi " + ctx_r1.message.closeIcon);
  }
}
function ToastItem_button_5_TimesIcon_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "TimesIcon", 14);
  }
  if (rf & 2) {
    i0.ɵɵproperty("styleClass", "p-toast-icon-close-icon");
  }
}
function ToastItem_button_5_Template(rf, ctx) {
  if (rf & 1) {
    const _r3 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "button", 12);
    i0.ɵɵlistener("click", function ToastItem_button_5_Template_button_click_0_listener($event) {
      i0.ɵɵrestoreView(_r3);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.onCloseIconClick($event));
    })("keydown.enter", function ToastItem_button_5_Template_button_keydown_enter_0_listener($event) {
      i0.ɵɵrestoreView(_r3);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.onCloseIconClick($event));
    });
    i0.ɵɵtemplate(1, ToastItem_button_5_span_1_Template, 1, 2, "span", 6)(2, ToastItem_button_5_TimesIcon_2_Template, 1, 1, "TimesIcon", 13);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r1.message.closeIcon);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !ctx_r1.message.closeIcon);
  }
}
function Toast_p_toastItem_2_Template(rf, ctx) {
  if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "p-toastItem", 3);
    i0.ɵɵlistener("onClose", function Toast_p_toastItem_2_Template_p_toastItem_onClose_0_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.onMessageClose($event));
    })("@toastAnimation.start", function Toast_p_toastItem_2_Template_p_toastItem_animation_toastAnimation_start_0_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.onAnimationStart($event));
    })("@toastAnimation.done", function Toast_p_toastItem_2_Template_p_toastItem_animation_toastAnimation_done_0_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.onAnimationEnd($event));
    });
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const msg_r3 = ctx.$implicit;
    const i_r4 = ctx.index;
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵproperty("message", msg_r3)("index", i_r4)("template", ctx_r1.template)("@toastAnimation", undefined)("showTransformOptions", ctx_r1.showTransformOptions)("hideTransformOptions", ctx_r1.hideTransformOptions)("showTransitionOptions", ctx_r1.showTransitionOptions)("hideTransitionOptions", ctx_r1.hideTransitionOptions);
  }
}
class ToastItem {
  constructor(zone) {
    this.zone = zone;
    this.onClose = new EventEmitter();
  }
  ngAfterViewInit() {
    this.initTimeout();
  }
  initTimeout() {
    if (!this.message.sticky) {
      this.zone.runOutsideAngular(() => {
        this.timeout = setTimeout(() => {
          this.onClose.emit({
            index: this.index,
            message: this.message
          });
        }, this.message.life || 3000);
      });
    }
  }
  clearTimeout() {
    if (this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = null;
    }
  }
  onMouseEnter() {
    this.clearTimeout();
  }
  onMouseLeave() {
    this.initTimeout();
  }
  onCloseIconClick(event) {
    this.clearTimeout();
    this.onClose.emit({
      index: this.index,
      message: this.message
    });
    event.preventDefault();
  }
  ngOnDestroy() {
    this.clearTimeout();
  }
}
ToastItem.ɵfac = function ToastItem_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || ToastItem)(i0.ɵɵdirectiveInject(i0.NgZone));
};
ToastItem.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: ToastItem,
  selectors: [["p-toastItem"]],
  viewQuery: function ToastItem_Query(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵviewQuery(_c0, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.containerViewChild = _t.first);
    }
  },
  hostAttrs: [1, "p-element"],
  inputs: {
    message: "message",
    index: "index",
    template: "template",
    showTransformOptions: "showTransformOptions",
    hideTransformOptions: "hideTransformOptions",
    showTransitionOptions: "showTransitionOptions",
    hideTransitionOptions: "hideTransitionOptions"
  },
  outputs: {
    onClose: "onClose"
  },
  decls: 6,
  vars: 21,
  consts: [["container", ""], [3, "mouseenter", "mouseleave", "ngClass"], ["role", "alert", "aria-live", "assertive", "aria-atomic", "true", 1, "p-toast-message-content", 3, "ngClass"], [4, "ngIf"], [4, "ngTemplateOutlet", "ngTemplateOutletContext"], ["type", "button", "class", "p-toast-icon-close p-link", "pRipple", "", 3, "click", "keydown.enter", 4, "ngIf"], [3, "class", 4, "ngIf"], ["class", "p-toast-message-icon", 4, "ngIf"], [1, "p-toast-message-text"], [1, "p-toast-summary"], [1, "p-toast-detail"], [1, "p-toast-message-icon"], ["type", "button", "pRipple", "", 1, "p-toast-icon-close", "p-link", 3, "click", "keydown.enter"], [3, "styleClass", 4, "ngIf"], [3, "styleClass"]],
  template: function ToastItem_Template(rf, ctx) {
    if (rf & 1) {
      const _r1 = i0.ɵɵgetCurrentView();
      i0.ɵɵelementStart(0, "div", 1, 0);
      i0.ɵɵlistener("mouseenter", function ToastItem_Template_div_mouseenter_0_listener() {
        i0.ɵɵrestoreView(_r1);
        return i0.ɵɵresetView(ctx.onMouseEnter());
      })("mouseleave", function ToastItem_Template_div_mouseleave_0_listener() {
        i0.ɵɵrestoreView(_r1);
        return i0.ɵɵresetView(ctx.onMouseLeave());
      });
      i0.ɵɵelementStart(2, "div", 2);
      i0.ɵɵtemplate(3, ToastItem_ng_container_3_Template, 8, 4, "ng-container", 3)(4, ToastItem_ng_container_4_Template, 1, 0, "ng-container", 4)(5, ToastItem_button_5_Template, 3, 2, "button", 5);
      i0.ɵɵelementEnd()();
    }
    if (rf & 2) {
      i0.ɵɵclassMap(ctx.message.styleClass);
      i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction1(10, _c1, "p-toast-message-" + ctx.message.severity))("@messageState", i0.ɵɵpureFunction1(17, _c3, i0.ɵɵpureFunction4(12, _c2, ctx.showTransformOptions, ctx.hideTransformOptions, ctx.showTransitionOptions, ctx.hideTransitionOptions)));
      i0.ɵɵattribute("id", ctx.message.id);
      i0.ɵɵadvance(2);
      i0.ɵɵproperty("ngClass", ctx.message.contentStyleClass);
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngIf", !ctx.template);
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngTemplateOutlet", ctx.template)("ngTemplateOutletContext", i0.ɵɵpureFunction1(19, _c4, ctx.message));
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngIf", ctx.message.closable !== false);
    }
  },
  dependencies: () => [i1.NgClass, i1.NgIf, i1.NgTemplateOutlet, i2.Ripple, CheckIcon, InfoCircleIcon, TimesCircleIcon, ExclamationTriangleIcon, TimesIcon],
  encapsulation: 2,
  data: {
    animation: [trigger('messageState', [state('visible', style({
      transform: 'translateY(0)',
      opacity: 1
    })), transition('void => *', [style({
      transform: '{{showTransformParams}}',
      opacity: 0
    }), animate('{{showTransitionParams}}')]), transition('* => void', [animate('{{hideTransitionParams}}', style({
      height: 0,
      opacity: 0,
      transform: '{{hideTransformParams}}'
    }))])])]
  },
  changeDetection: 0
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ToastItem, [{
    type: Component,
    args: [{
      selector: 'p-toastItem',
      template: `
        <div
            #container
            [attr.id]="message.id"
            [class]="message.styleClass"
            [ngClass]="['p-toast-message-' + message.severity, 'p-toast-message']"
            [@messageState]="{ value: 'visible', params: { showTransformParams: showTransformOptions, hideTransformParams: hideTransformOptions, showTransitionParams: showTransitionOptions, hideTransitionParams: hideTransitionOptions } }"
            (mouseenter)="onMouseEnter()"
            (mouseleave)="onMouseLeave()"
        >
            <div class="p-toast-message-content" role="alert" aria-live="assertive" aria-atomic="true" [ngClass]="message.contentStyleClass">
                <ng-container *ngIf="!template">
                    <span
                        *ngIf="message.icon"
                        [class]="'p-toast-message-icon pi ' + message.icon"
                    ></span>
                    <span class="p-toast-message-icon" *ngIf="!message.icon">
                        <ng-container>
                            <CheckIcon *ngIf="message.severity === 'success'"/>
                            <InfoCircleIcon *ngIf="message.severity === 'info'"/>
                            <TimesCircleIcon *ngIf="message.severity === 'error'"/>
                            <ExclamationTriangleIcon *ngIf="message.severity === 'warn'"/>
                        </ng-container>
                    </span>
                    <div class="p-toast-message-text">
                        <div class="p-toast-summary">{{ message.summary }}</div>
                        <div class="p-toast-detail">{{ message.detail }}</div>
                    </div>
                </ng-container>
                <ng-container *ngTemplateOutlet="template; context: { $implicit: message }"></ng-container>
                <button type="button" class="p-toast-icon-close p-link" (click)="onCloseIconClick($event)" (keydown.enter)="onCloseIconClick($event)" *ngIf="message.closable !== false" pRipple>
                    <span
                        *ngIf="message.closeIcon"
                        [class]="'p-toast-message-icon pi ' + message.closeIcon"
                    ></span>
                    <TimesIcon *ngIf="!message.closeIcon" [styleClass]="'p-toast-icon-close-icon'"/>
                </button>
            </div>
        </div>
    `,
      animations: [trigger('messageState', [state('visible', style({
        transform: 'translateY(0)',
        opacity: 1
      })), transition('void => *', [style({
        transform: '{{showTransformParams}}',
        opacity: 0
      }), animate('{{showTransitionParams}}')]), transition('* => void', [animate('{{hideTransitionParams}}', style({
        height: 0,
        opacity: 0,
        transform: '{{hideTransformParams}}'
      }))])])],
      encapsulation: ViewEncapsulation.None,
      changeDetection: ChangeDetectionStrategy.OnPush,
      host: {
        class: 'p-element'
      }
    }]
  }], function () {
    return [{
      type: i0.NgZone
    }];
  }, {
    message: [{
      type: Input
    }],
    index: [{
      type: Input
    }],
    template: [{
      type: Input
    }],
    showTransformOptions: [{
      type: Input
    }],
    hideTransformOptions: [{
      type: Input
    }],
    showTransitionOptions: [{
      type: Input
    }],
    hideTransitionOptions: [{
      type: Input
    }],
    onClose: [{
      type: Output
    }],
    containerViewChild: [{
      type: ViewChild,
      args: ['container']
    }]
  });
})();
class Toast {
  constructor(document, renderer, messageService, cd, config) {
    this.document = document;
    this.renderer = renderer;
    this.messageService = messageService;
    this.cd = cd;
    this.config = config;
    this.autoZIndex = true;
    this.baseZIndex = 0;
    this.position = 'top-right';
    this.preventOpenDuplicates = false;
    this.preventDuplicates = false;
    this.showTransformOptions = 'translateY(100%)';
    this.hideTransformOptions = 'translateY(-100%)';
    this.showTransitionOptions = '300ms ease-out';
    this.hideTransitionOptions = '250ms ease-in';
    this.onClose = new EventEmitter();
    this.id = UniqueComponentId();
  }
  ngOnInit() {
    this.messageSubscription = this.messageService.messageObserver.subscribe(messages => {
      if (messages) {
        if (Array.isArray(messages)) {
          const filteredMessages = messages.filter(m => this.canAdd(m));
          this.add(filteredMessages);
        } else if (this.canAdd(messages)) {
          this.add([messages]);
        }
      }
    });
    this.clearSubscription = this.messageService.clearObserver.subscribe(key => {
      if (key) {
        if (this.key === key) {
          this.messages = null;
        }
      } else {
        this.messages = null;
      }
      this.cd.markForCheck();
    });
  }
  ngAfterViewInit() {
    if (this.breakpoints) {
      this.createStyle();
    }
  }
  add(messages) {
    this.messages = this.messages ? [...this.messages, ...messages] : [...messages];
    if (this.preventDuplicates) {
      this.messagesArchieve = this.messagesArchieve ? [...this.messagesArchieve, ...messages] : [...messages];
    }
    this.cd.markForCheck();
  }
  canAdd(message) {
    let allow = this.key === message.key;
    if (allow && this.preventOpenDuplicates) {
      allow = !this.containsMessage(this.messages, message);
    }
    if (allow && this.preventDuplicates) {
      allow = !this.containsMessage(this.messagesArchieve, message);
    }
    return allow;
  }
  containsMessage(collection, message) {
    if (!collection) {
      return false;
    }
    return collection.find(m => {
      return m.summary === message.summary && m.detail == message.detail && m.severity === message.severity;
    }) != null;
  }
  ngAfterContentInit() {
    this.templates.forEach(item => {
      switch (item.getType()) {
        case 'message':
          this.template = item.template;
          break;
        default:
          this.template = item.template;
          break;
      }
    });
  }
  onMessageClose(event) {
    this.messages.splice(event.index, 1);
    this.onClose.emit({
      message: event.message
    });
    this.cd.detectChanges();
  }
  onAnimationStart(event) {
    if (event.fromState === 'void') {
      this.renderer.setAttribute(this.containerViewChild.nativeElement, this.id, '');
      if (this.autoZIndex && this.containerViewChild.nativeElement.style.zIndex === '') {
        ZIndexUtils.set('modal', this.containerViewChild.nativeElement, this.baseZIndex || this.config.zIndex.modal);
      }
    }
  }
  onAnimationEnd(event) {
    if (event.toState === 'void') {
      if (this.autoZIndex && ObjectUtils.isEmpty(this.messages)) {
        ZIndexUtils.clear(this.containerViewChild.nativeElement);
      }
    }
  }
  createStyle() {
    if (!this.styleElement) {
      this.styleElement = this.renderer.createElement('style');
      this.styleElement.type = 'text/css';
      this.renderer.appendChild(this.document.head, this.styleElement);
      let innerHTML = '';
      for (let breakpoint in this.breakpoints) {
        let breakpointStyle = '';
        for (let styleProp in this.breakpoints[breakpoint]) {
          breakpointStyle += styleProp + ':' + this.breakpoints[breakpoint][styleProp] + ' !important;';
        }
        innerHTML += `
                    @media screen and (max-width: ${breakpoint}) {
                        .p-toast[${this.id}] {
                           ${breakpointStyle}
                        }
                    }
                `;
      }
      this.renderer.setProperty(this.styleElement, 'innerHTML', innerHTML);
    }
  }
  destroyStyle() {
    if (this.styleElement) {
      this.renderer.removeChild(this.document.head, this.styleElement);
      this.styleElement = null;
    }
  }
  ngOnDestroy() {
    if (this.messageSubscription) {
      this.messageSubscription.unsubscribe();
    }
    if (this.containerViewChild && this.autoZIndex) {
      ZIndexUtils.clear(this.containerViewChild.nativeElement);
    }
    if (this.clearSubscription) {
      this.clearSubscription.unsubscribe();
    }
    this.destroyStyle();
  }
}
Toast.ɵfac = function Toast_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || Toast)(i0.ɵɵdirectiveInject(DOCUMENT), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i3.MessageService), i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i3.PrimeNGConfig));
};
Toast.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: Toast,
  selectors: [["p-toast"]],
  contentQueries: function Toast_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, PrimeTemplate, 4);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.templates = _t);
    }
  },
  viewQuery: function Toast_Query(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵviewQuery(_c0, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.containerViewChild = _t.first);
    }
  },
  hostAttrs: [1, "p-element"],
  inputs: {
    key: "key",
    autoZIndex: "autoZIndex",
    baseZIndex: "baseZIndex",
    style: "style",
    styleClass: "styleClass",
    position: "position",
    preventOpenDuplicates: "preventOpenDuplicates",
    preventDuplicates: "preventDuplicates",
    showTransformOptions: "showTransformOptions",
    hideTransformOptions: "hideTransformOptions",
    showTransitionOptions: "showTransitionOptions",
    hideTransitionOptions: "hideTransitionOptions",
    breakpoints: "breakpoints"
  },
  outputs: {
    onClose: "onClose"
  },
  decls: 3,
  vars: 5,
  consts: [["container", ""], [3, "ngClass", "ngStyle"], [3, "message", "index", "template", "showTransformOptions", "hideTransformOptions", "showTransitionOptions", "hideTransitionOptions", "onClose", 4, "ngFor", "ngForOf"], [3, "onClose", "message", "index", "template", "showTransformOptions", "hideTransformOptions", "showTransitionOptions", "hideTransitionOptions"]],
  template: function Toast_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelementStart(0, "div", 1, 0);
      i0.ɵɵtemplate(2, Toast_p_toastItem_2_Template, 1, 8, "p-toastItem", 2);
      i0.ɵɵelementEnd();
    }
    if (rf & 2) {
      i0.ɵɵclassMap(ctx.styleClass);
      i0.ɵɵproperty("ngClass", "p-toast p-component p-toast-" + ctx.position)("ngStyle", ctx.style);
      i0.ɵɵadvance(2);
      i0.ɵɵproperty("ngForOf", ctx.messages);
    }
  },
  dependencies: [i1.NgClass, i1.NgForOf, i1.NgStyle, ToastItem],
  styles: [".p-toast{position:fixed;width:25rem}.p-toast-message{overflow:hidden}.p-toast-message-content{display:flex;align-items:flex-start}.p-toast-message-text{flex:1 1 auto}.p-toast-top-right{top:20px;right:20px}.p-toast-top-left{top:20px;left:20px}.p-toast-bottom-left{bottom:20px;left:20px}.p-toast-bottom-right{bottom:20px;right:20px}.p-toast-top-center{top:20px;left:50%;transform:translate(-50%)}.p-toast-bottom-center{bottom:20px;left:50%;transform:translate(-50%)}.p-toast-center{left:50%;top:50%;min-width:20vw;transform:translate(-50%,-50%)}.p-toast-icon-close{display:flex;align-items:center;justify-content:center;overflow:hidden;position:relative}.p-toast-icon-close.p-link{cursor:pointer}\n"],
  encapsulation: 2,
  data: {
    animation: [trigger('toastAnimation', [transition(':enter, :leave', [query('@*', animateChild())])])]
  },
  changeDetection: 0
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(Toast, [{
    type: Component,
    args: [{
      selector: 'p-toast',
      template: `
        <div #container [ngClass]="'p-toast p-component p-toast-' + position" [ngStyle]="style" [class]="styleClass">
            <p-toastItem
                *ngFor="let msg of messages; let i = index"
                [message]="msg"
                [index]="i"
                (onClose)="onMessageClose($event)"
                [template]="template"
                @toastAnimation
                (@toastAnimation.start)="onAnimationStart($event)"
                (@toastAnimation.done)="onAnimationEnd($event)"
                [showTransformOptions]="showTransformOptions"
                [hideTransformOptions]="hideTransformOptions"
                [showTransitionOptions]="showTransitionOptions"
                [hideTransitionOptions]="hideTransitionOptions"
            ></p-toastItem>
        </div>
    `,
      animations: [trigger('toastAnimation', [transition(':enter, :leave', [query('@*', animateChild())])])],
      changeDetection: ChangeDetectionStrategy.OnPush,
      encapsulation: ViewEncapsulation.None,
      host: {
        class: 'p-element'
      },
      styles: [".p-toast{position:fixed;width:25rem}.p-toast-message{overflow:hidden}.p-toast-message-content{display:flex;align-items:flex-start}.p-toast-message-text{flex:1 1 auto}.p-toast-top-right{top:20px;right:20px}.p-toast-top-left{top:20px;left:20px}.p-toast-bottom-left{bottom:20px;left:20px}.p-toast-bottom-right{bottom:20px;right:20px}.p-toast-top-center{top:20px;left:50%;transform:translate(-50%)}.p-toast-bottom-center{bottom:20px;left:50%;transform:translate(-50%)}.p-toast-center{left:50%;top:50%;min-width:20vw;transform:translate(-50%,-50%)}.p-toast-icon-close{display:flex;align-items:center;justify-content:center;overflow:hidden;position:relative}.p-toast-icon-close.p-link{cursor:pointer}\n"]
    }]
  }], function () {
    return [{
      type: Document,
      decorators: [{
        type: Inject,
        args: [DOCUMENT]
      }]
    }, {
      type: i0.Renderer2
    }, {
      type: i3.MessageService
    }, {
      type: i0.ChangeDetectorRef
    }, {
      type: i3.PrimeNGConfig
    }];
  }, {
    key: [{
      type: Input
    }],
    autoZIndex: [{
      type: Input
    }],
    baseZIndex: [{
      type: Input
    }],
    style: [{
      type: Input
    }],
    styleClass: [{
      type: Input
    }],
    position: [{
      type: Input
    }],
    preventOpenDuplicates: [{
      type: Input
    }],
    preventDuplicates: [{
      type: Input
    }],
    showTransformOptions: [{
      type: Input
    }],
    hideTransformOptions: [{
      type: Input
    }],
    showTransitionOptions: [{
      type: Input
    }],
    hideTransitionOptions: [{
      type: Input
    }],
    breakpoints: [{
      type: Input
    }],
    onClose: [{
      type: Output
    }],
    containerViewChild: [{
      type: ViewChild,
      args: ['container']
    }],
    templates: [{
      type: ContentChildren,
      args: [PrimeTemplate]
    }]
  });
})();
class ToastModule {}
ToastModule.ɵfac = function ToastModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || ToastModule)();
};
ToastModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: ToastModule
});
ToastModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [CommonModule, RippleModule, CheckIcon, InfoCircleIcon, TimesCircleIcon, ExclamationTriangleIcon, TimesIcon, SharedModule]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ToastModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, RippleModule, CheckIcon, InfoCircleIcon, TimesCircleIcon, ExclamationTriangleIcon, TimesIcon],
      exports: [Toast, SharedModule],
      declarations: [Toast, ToastItem]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { Toast, ToastItem, ToastModule };

