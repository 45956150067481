<div class="row ccpanel">
  <div class="col-md-12">
    <div class="panel panel-default adminEscalationMainDiv" style="margin-top: 30px;">
      <div class="panel-heading">
        <ul class="nav nav-tabs flex-row-align flex-column flex-sm-row">
          <li class="nav-item flex-sm-fill">
            <a class="nav-link" [ngClass]="{'active': isInProcessViewSelected}"
              (click)="showInprocessView()">{{'admin_et_ipo_tab_lb' | translate}}</a>
          </li>
          <li class="nav-item flex-sm-fill">
            <a class="nav-link" [ngClass]="{'active': isDraftViewSelected}"
              (click)="showDraftView()">{{'admin_et_draft_tab_lb' | translate}}</a>
          </li>
          <li class="nav-item flex-sm-fill">
            <a class="nav-link" [ngClass]="{'active': isNotAssignedViewSelected}"
              (click)="showNotAssignedView()">{{'admin_et_notas_tab_lb' | translate}}</a>
          </li>
        </ul>
      </div>
      <div class="panel-body bg-white1 pb-0">
        <div class="row  pb-0">

          <div class="col-s-12 col-md-12 col-sm-12 col-lg-12">
            <div class="panel panel-default mt-0">
              <div class="panel-body pt-0 ps-0 pe-0 phone_panel">
                <div *ngIf="isInProcessViewSelected">
                  <div class="col-sm-12 col-border">
                    <div class="table-responsive">
                    <table class="table table-striped">
                      <thead>
                        <tr>
                          <th class="col-sm-2"></th>
                          <th class="col-sm-1">{{'admin_et_ipo_tab_hit1_lb' | translate}} (h)</th>
                          <th class="col-sm-1">{{'admin_et_ipo_tab_hit2_lb' | translate}} (h)</th>
                          <th class="col-sm-1">{{'admin_et_ipo_tab_n1_lb' | translate}} (h)</th>
                          <th class="col-sm-1">{{'admin_et_ipo_tab_n2_lb' | translate}} (h)</th>
                          <th class="col-sm-4"></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let et of escalationTimes">
                          <app-admin-inprogress-escalationtimes style="display: contents;" [item]="et" [maxHoursRange]="72">
                           </app-admin-inprogress-escalationtimes>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  </div>

                  <div class="col-sm-12 well well-sm">
                    <dl>
                      <dt>{{'admin_et_ipo_tab_hit1_lb' | translate}}</dt>
                      <dd>{{'admin_et_ipo_tab_hit1_info_msg' | translate}}</dd>
                    </dl>
                    <dl>
                      <dt>{{'admin_et_ipo_tab_hit2_lb' | translate}}</dt>
                      <dd>{{'admin_et_ipo_tab_hit2_info_msg' | translate}}</dd>
                    </dl>
                    <dl>
                      <dt>{{'admin_et_ipo_tab_n1_lb' | translate}}</dt>
                      <dd>{{'admin_et_ipo_tab_n1_info_msg' | translate}}</dd>
                    </dl>
                    <dl>
                      <dt>{{'admin_et_ipo_tab_n2_lb' | translate}}</dt>
                      <dd>{{'admin_et_ipo_tab_n2_info_msg' | translate}}</dd>
                    </dl>
                  </div>
                </div>
                <div *ngIf="isDraftViewSelected">
                  <div class="col-sm-12 col-border">
                    <div *ngFor="let et of escalationTimes">
                      <app-admin-escalation-time [item]="et" [status]="'draft'" [maxHoursRange]="120">
                      </app-admin-escalation-time>
                    </div>
                  </div>
                </div>
                <div *ngIf="isNotAssignedViewSelected">
                  <div class="col-sm-12 col-border">
                    <div *ngFor="let et of escalationTimes">
                      <app-admin-escalation-time [item]="et" [status]="'notAssigned'" [maxHoursRange]="120">
                      </app-admin-escalation-time>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
