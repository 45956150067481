<div>
    <h2 class="widgetH2">{{ "FailureModeSearch_SelectInfo" | translate }}</h2>

    <p-tree [value]="files5" scrollHeight="290px" selectionMode="single" [(selection)]="selectedNode"
        filterPlaceholder="Search by Name (Type at least 3 character)" (onNodeExpand)="nodeSelect($event)" [loading]="loading">
    </p-tree>

    <div class="modal-footer mt-2">
        <button (click)="selectAndClose()" [disabled]="!(selectedNode && selectedNode.data)"
            [ngClass]="selectedNode && selectedNode.data ? ' widgetButtonLargeSelectClose red_button' : ' widgetButtonLargeSelectClose'">
            {{ "select_ru_popup_btn_select" | translate }}</button>
    </div>
</div>
