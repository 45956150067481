<nav class="navbar navbar-expand-lg navbar-light">
  <div class="container-fluid navbar-wrapper">
    <button class="navbar-toggler ms-auto ms-sm-2" type="button" data-bs-toggle="collapse" data-bs-target="#ccrpnavbar"
      aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span></span>
      <span></span>
      <span></span>
    </button>
    <div class="collapse navbar-collapse" id="ccrpnavbar">
      <ul class="navbar-nav">

        <li class="nav-item dropdown ">
          <a class="nav-link dropdown-toggle" [ngClass]="{'active': isActive('/mydashboard') || isActive('/myactionplan')}" id="navbarDropdown" role="button" data-bs-toggle="dropdown"
            aria-expanded="false">
            MY ISSUES
          </a>
          <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
            <li class="dd-list-item"><a class="dropdown-item" routerLink="/mydashboard">MY DASHBOARD</a></li>
            <li class="dd-list-item"><a class="dropdown-item" routerLink="/myactionplan" >ACTION PLAN DASHBOARD</a></li>
          </ul>
        </li>
        <li class="nav-item">
          <a class="nav-link" aria-current="page" routerLink="/complaint" routerLinkActive="active">NEW ISSUE</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/reports" routerLinkActive="active">REPORTS & DASHBOARD</a>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" id="navbarDropdown" [ngClass]="{'active': isActive('/globalsearch') || isActive('/search') || isActive('/posearch')   }" role="button" data-bs-toggle="dropdown"
            aria-expanded="false">
            SEARCH
          </a>
          <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
            <li class="dd-list-item"><a routerLink="/globalsearch" class="dropdown-item">GLOBAL SEARCH</a></li>
            <li class="dd-list-item"><a routerLink="/search/coordinator" class="dropdown-item">RU PERSON SEARCH</a></li>
            <li class="dd-list-item"><a routerLink="/posearch" class="dropdown-item">PROCESS OWNER SEARCH</a></li>
            <li class="dd-list-item"><a routerLink="/search/hubProcessOwner" class="dropdown-item">HUB PROCESS OWNER SEARCH</a></li>
            <li class="dd-list-item">
              <div class="dropdown-item">
                <div class="row">
                  <div class="col-10">
                    <input placeholder="CCRP No." type="text" class="form-control form-control-sm searchfield"
                       [(ngModel)]="complaintNumber"  id="complaintNoIdSearch"  title="Write CCRP number and click go, to see issue" />
                  </div>
                  <div class="col-1">
                    <button class="btn searchbuttoninnavbar searchfield-btn" type="button" role="button" (click)="navigateToComplaint()">Go!</button>
                  </div>
                  <div class="col-1"></div>
                </div>
              </div>
          </li>
          <li class="dd-list-item">
            <div class="dropdown-item">
              <p-autoComplete [showClear]="true"
                class="border-0 serialNoCustom" [pTooltip]="" [escape]="false" placeholder="Serial No."
                tooltipPosition="top" tooltipStyleClass="toolTipClass" [suggestions]="filteredSerialNo" [(ngModel)]="enteredSerialNoModel"
                (completeMethod)="filteredSerialNoSearch($event)" (onSelect)="onSelect($event)" [minLength]="3" [delay]="500">
                <ng-template let-filteredSerialNo pTemplate="item">
                  <div class="flex align-items-center gap-2 mb-2">
                    <div><a>{{ filteredSerialNo }}</a></div>
                  </div>
                </ng-template>
                </p-autoComplete>
            </div>
          </li>
          </ul>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="https://hitachipowergrids.sharepoint.com/sites/QCIO/ccrp/SitePages/CCRP-Training.aspx" target="_blank" >TRAININGS</a>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-bs-toggle="dropdown"
            aria-expanded="false">
            LINKS
          </a>
          <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
            <li class="dd-list-item"><a class="dropdown-item" href="https://hitachipowergrids.sharepoint.com/sites/QCIO/ccrp/SitePages/Process-Flow.aspx"
              target="_blank">CCRP PROCESS FLOW</a></li>
            <li class="dd-list-item"><a class="dropdown-item" href="https://hitachipowergrids.sharepoint.com/sites/QCIO/ccrp" target="_blank">CCRP SHAREPOINT</a></li>
            <li class="dd-list-item"><a class="dropdown-item" href="https://hitachipowergrids.sharepoint.com/sites/QCIO/ccrp/SitePages/FAQ-2.aspx" target="_blank">FAQ</a></li>
            <li class="dd-list-item"><a class="dropdown-item" href="https://hitachipowergrids.sharepoint.com/sites/GlobalL6SLCC"
              target="_blank">LEAN 6SIGMA</a></li>
            <li class="dd-list-item"><a class="dropdown-item" href="https://www.hitachienergy.com/cookie-notice"
              target="_blank">PRIVACY NOTES</a></li>
            <li class="dd-list-item"><a class="dropdown-item" href="https://hitachipowergrids.sharepoint.com/sites/QCIO/ccrp/SitePages/Release%20Notes.aspx"
              target="_blank">RELEASE NOTES</a></li>
            <li class="dd-list-item"><a class="dropdown-item" href="{{SNCRPURL}}" target="_blank">SNCRP</a></li>
          </ul>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" [ngClass]="{'active': isActive('/helpdesk')}" id="navbarDropdown" role="button" data-bs-toggle="dropdown"
            aria-expanded="false">
            HELP
          </a>
          <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
            <li class="dd-list-item"><a class="dropdown-item" routerLink="/helpdesk" >HELP DESK</a></li>
            <li class="dd-list-item"><a class="dropdown-item" href="../../../../assets/files/Analyzer_Access_Document.docx"  target="_blank">{{'Analyzer Access Document' | uppercase}}</a></li>
          </ul>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/admin" routerLinkActive="active" >ADMIN</a>
        </li>
      </ul>
    </div>
  </div>
</nav>
