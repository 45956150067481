import { Injectable } from '@angular/core';
import { ActionPlanCard } from 'src/app/models/new-issue/actionplan/ActionPlanCard';
import { DisableModel } from 'src/app/models/new-issue/actionplan/DisableModel';
import { ActionPlanPermissionsService, IActionPlanPermissionsService } from './action-plan-permissions.service';
import { ActionItemStatus } from 'src/app/components/my-issue/myissue-action-plan-dashboard/myissue-action-plan-dashboard.component';
import moment from 'moment';

export interface IActionItemValidationService {
  canAddNewAction(complaintNo);
  disableAssign(model,token, selectedRole): boolean;
  disableComplete(model, token): boolean;
  runValidationCheck(isDisabled: DisableModel, item: ActionPlanCard, userEmail: string, selectedRole: string);
  disableSave(selected, token, selectedRole): boolean;
  disableReject(model, token): boolean;
}

// declare var moment;

@Injectable({
  providedIn: 'root'
})
export class ActionItemValidationService implements IActionItemValidationService{
  public static serviceId = "actionItemValidationService";
  constructor(private permissionsService: ActionPlanPermissionsService) { }

  canAddNewAction(complaintNo: any) {
    return this.permissionsService.canAddNewAction(complaintNo);
  }

  disableAssign(selected: any, token: any, selectedRole: any): boolean {
    if ((selectedRole != null && selectedRole != undefined) || (token.isActionOwner || token.isCreator))
      return !selected || !selected.name || !selected.targetDate || moment(selected.targetDate) < moment().startOf('day') || !selected.actionOwner;
    else
      return true;
  }


  disableSave(selected: any, token: any, selectedRole: any): boolean {
    if (selected.statusId == ActionItemStatus.New) {
      return !token.isCreator || !selected || selected.type == undefined || selected.type == null || selected.name == undefined || selected.name == null;
    }
    else {
      if ((selectedRole != null && selectedRole != undefined) || (token.isActionOwner || token.isCreator))
          return !selected || selected.type == undefined || selected.type == null || selected.name == undefined || selected.name == null || selected.targetDate == null || selected.targetDate == undefined;
      else
          return true;
    }
  }

  disableComplete(selected: any, token: any): boolean {
    if (token.isActionOwner)
      return !selected || !selected.activities || !(selected.activities.length > 0) || !selected.name || selected.targetDate == null ||
        selected.targetDate == undefined || !selected.type;
    else
      return true;
  }

  disableReject(selected: any, token: any): boolean {
    if (token.isActionOwner)
      return !selected || !selected.name || selected.targetDate == null || selected.targetDate == undefined || !selected.type;
    else
      return true;
  }

  runValidationCheck(isDisabled: DisableModel, item: ActionPlanCard, userEmail: string, selectedRole: string) {
    if(!item?.id) {
      const token = { isCreator: true, isActionOwner: false };
      this.validationFunction(item, token, isDisabled);
    }
    else {
        this.permissionsService.getUserPermissionToken(item?.id, selectedRole).subscribe((token)=>{
          this.validationFunction(item, token, isDisabled);
        })
    }
  }


  validationFunction(item, token, isDisabled?) {
    if ((token.isCreator && (item.statusId == ActionItemStatus.InProgress || item.statusId == ActionItemStatus.InProgressOverdue) &&
        item.actionOwner && item.actionOwner.deactivateDate)
        ||
        (token.isActionOwner && (item.statusId == ActionItemStatus.InProgress || item.statusId == ActionItemStatus.InProgressOverdue))) {
        isDisabled.actionOwner = false;
    } else {
        isDisabled.actionOwner = !(item.statusId == ActionItemStatus.New || item.statusId == ActionItemStatus.Todo || item.isRejected == true);
    }

    if (token.isCreator &&
        item.statusId != ActionItemStatus.Completed &&
        item.actionCreator &&
        item.actionCreator.deactivateDate) {
        isDisabled.actionCreator = false;
    } else {
        isDisabled.actionCreator = true;
    }

    if (((item.statusId == ActionItemStatus.InProgress || item.statusId == ActionItemStatus.InProgressOverdue) && (token.isActionOwner)) || (item.statusId == ActionItemStatus.Todo) || (item.statusId == ActionItemStatus.New))
        isDisabled.interestedParties = false;

    else
        isDisabled.interestedParties = true;
    /*isDisabled.interestedParties = item.statusId == ActionItemStatus.Completed || !(item.statusId != ActionItemStatus.Completed && token.isCreator);*/


    if ((token.isActionOwner && (item.statusId == ActionItemStatus.InProgress || item.statusId == ActionItemStatus.InProgressOverdue))
        || item.statusId == ActionItemStatus.Todo)
        isDisabled.actionName = false;
    else
        isDisabled.actionName = (item.statusId != ActionItemStatus.New);

    isDisabled.actionType = !(item.statusId == ActionItemStatus.Todo) &&
        !(token.isActionOwner && (item.statusId == ActionItemStatus.InProgress || item.statusId == ActionItemStatus.InProgressOverdue))
        && (item.statusId == ActionItemStatus.InProgress || item.statusId == ActionItemStatus.InProgressOverdue
            || item.statusId == ActionItemStatus.Completed ||
            !((item.statusId == ActionItemStatus.New || item.statusId == ActionItemStatus.Todo) && token.isCreator === true));

    isDisabled.targetDate = !(item.statusId == ActionItemStatus.Todo) && !(item.statusId == ActionItemStatus.New) &&
        !(token.isActionOwner && (item.statusId == ActionItemStatus.InProgress || item.statusId == ActionItemStatus.InProgressOverdue));

    isDisabled.description = !(token.isActionOwner && (item.statusId == ActionItemStatus.InProgress
        || item.statusId == ActionItemStatus.InProgressOverdue)) && !(item.statusId == ActionItemStatus.New || item.statusId == ActionItemStatus.Todo);

    isDisabled.activities = !(item.statusId == ActionItemStatus.Todo) && (item.statusId == ActionItemStatus.New ||
        item.statusId == ActionItemStatus.Completed ||
        !((item.statusId == ActionItemStatus.InProgress || item.statusId == ActionItemStatus.InProgressOverdue) || token.isActionOwner));

  }
}
