import { Component, OnInit } from '@angular/core';
import { DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-complaint-lock-warning',
  templateUrl: './complaint-lock-warning.component.html',
  styleUrls: ['./complaint-lock-warning.component.css']
})
export class ComplaintLockWarningComponent implements OnInit {

  constructor(public ref: DynamicDialogRef) { }

  ngOnInit(): void {
  }

  cancel(){
    this.ref.close({result : 'cancel'});
  }

  ok(){
    this.ref.close({result : 'ok'});
  }

}
