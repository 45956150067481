<div class="translation-mainClass">
<div class="row">
  <nav class="navbar navbar-default" style="background-color: #ffffff;">
    <div class="row mx-1">
      <div class="col-sm-2 translationRowMargin">
        <span class="input-group-btn">
          <select [disabled]="isControlDisabled" class="form-control" [(ngModel)]="culture.selected" (ngModelChange)="cultureChanged($event)">
            <option *ngFor="let c of culture.all" value="{{c.culture}}">
              {{c.languageName}}
            </option>
          </select>
        </span>
      </div>
      <div class="col-sm-7 translationRowMargin">
        <div class="input-group">
          <input type="text" (ngModelChange)="searchText($event)" [(ngModel)]="search.searchKeyword" [disabled]="isControlDisabled"
            placeholder="{{'admin_translations_seach_placeholder' | translate}}" class="form-control search-input" />
          <div class="input-group-addon input-group-btn button-setforsearch magnifierBtn" type="submit">
            <span class="hitachiicon-magnifying_glass"></span>
          </div>
        </div>
      </div>
      <div class="col-sm-2 translationRowMargin">
        <div class="input-group">
          <select [disabled]="isControlDisabled" (change)="setFilters()" class="form-control"
            [(ngModel)]="orderBy.selectedOption">
            <option value="all">All</option>
            <option value="new">Only New</option>
            <option *ngIf="!tabs.msg.isSelected" value="empty">Only Empty</option>
          </select>
          <span class="input-group-btn">
            <button class="input-group-addon button-set" *ngIf="orderBy.direction === 'asc'"
              (click)="setOrderMode('desc')" [disabled]="isControlDisabled">
              <i class="hitachiicon-sort_up"></i>
            </button>
            <button class="input-group-addon  button-set" *ngIf="orderBy.direction === 'desc'"
              (click)="setOrderMode('asc')" [disabled]="isControlDisabled">
              <i class="hitachiicon-sort_down"></i>
            </button>
          </span>
        </div>
      </div>
      <div class="col-sm-1">
        <button class="btn btn-default label-align-right gearIconClass" type="button" role="button" (click)="translationsSettings()"
          title="Settings" [disabled]="isControlDisabled">
          <i class="hitachiicon-gear"></i>
        </button>
      </div>
    </div>
  </nav>
</div>
<div class="row mt-1 panel-heading">
  <p >{{'admin_translations_color_desc' | translate}}</p>
</div>
<div class="row bg-white1 mt-1">
  <div class="panel panel-default">
    <div class="panel-heading">
      <ul class="nav nav-tabs flex-column flex-sm-row">
        <li class="nav-item flex-sm-fill">
          <a class="nav-link" [ngClass]="{'active': tabs.res.isSelected}"
            (click)="setActiveTab('res')">{{'admin_translations_res_tab_lb' | translate}}</a>
        </li>
        <li class="nav-item flex-sm-fill">
          <a class="nav-link" [ngClass]="{'active': tabs.msg.isSelected}"
            (click)="setActiveTab('msg')">{{'admin_translations_msg_tab_lb' | translate}}</a>
        </li>
      </ul>
    </div>
    <div class="panel-body bg-white1 pb-0">
      <div class="row  pb-0" *ngIf="tabs.res.isSelected">
        <div class="table-responsive">
        <table class="table table-striped table-hover">
          <thead>
              <tr>
                  <th class="col-sm-3 label-bold">{{'admin_translations_res_tab_res_name' | translate}}</th>
                  <th class="col-sm-6 label-bold">{{'admin_translations_res_tab_trans' | translate}}</th>
                  <th class="col-sm-3"></th>
              </tr>
          </thead>
          <tbody>
              <tr *ngFor="let res of source.resources.sorted">
                <app-translation-resource-item style="display: contents;" [item]="res" operationName="{{'admin_translations_save_success_msg' | translate}}"
                 [allowEdit]="allowManage" [selectedLanguage]="culture.selected" [isDisabled]="isControlDisabled">
                </app-translation-resource-item>
               </tr>
          </tbody>
      </table>
    </div>
      </div>
      <div class="row  pb-0" *ngIf="tabs.msg.isSelected">
        <div *ngFor="let msg of source.messages.sorted">
          <app-translation-message-item [msg]="msg" operationName="{{'admin_translations_save_msg_title' | translate}}"
          [allowEdit]="allowManage" [isDisabled]="isControlDisabled" [selectedLanguage]="culture.selected"
          ></app-translation-message-item>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-12 text-center">
    <ul class="pagination">
          <li class="{{hasPrevious()?'':'disabled'}}" (click)="previousClicked()"><a >&laquo;</a></li>
        <li *ngIf="showFirstPage()" (click)="gotoPage(firstPage)"><a >{{firstPage}}</a></li>
       <li *ngIf="showFirstPageSeparator()"><span>...</span></li>
        <li *ngIf="hasPrevious()" (click)="gotoPage(previousPage())"><a >{{previousPage()}}</a></li>
        <!-- <li *ngIf="!hasPrevious()"><span>&nbsp;</span></li> -->
        <li class="active"><a >{{currentPage}}</a></li>
        <li *ngIf="hasNext()" (click)="gotoPage(nextPage())"><a >{{nextPage()}}</a></li>
        <!-- <li *ngIf="!hasNext()"><span>&nbsp;</span></li> -->
        <li *ngIf="showLastPageSeparator()"><span>...</span></li>
        <li *ngIf="showLastPage()" (click)="gotoPage(pagesCount)"><a >{{pagesCount}}</a></li>
        <li class="{{hasNext()?'':'disabled'}}"><a  (click)="nextClicked()">&raquo;</a></li>
    </ul>
</div>
</div>
</div>
