import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-all-in-one-reports-data-on-ccrpnumber',
  templateUrl: './all-in-one-reports-data-on-ccrpnumber.component.html',
  styleUrls: ['./all-in-one-reports-data-on-ccrpnumber.component.css']
})
export class AllInOneReportsDataOnCCRPNumberComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
