import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ReportsService } from 'src/app/services/reports.service';
import { ToasterserviceService } from 'src/app/services/toasterservice.service';

@Component({
  selector: 'app-save-settings-popup',
  templateUrl: './save-settings-popup.component.html',
  styleUrls: ['./save-settings-popup.component.css']
})
export class SaveSettingsPopupComponent implements OnInit {
  repeat: any[];
  saveSettingPopUp!: FormGroup;
  bottomSection: boolean = false;
  employeesDetails: any;
  every: any;
  monthEnable: any;
  weekEnable: any;
  currentEmployeeDetails: any;
  today: Date;
  reportData: any;
  isChecked: boolean;
  isActive: boolean = false;
  isEdit: boolean;
  postDataVar: any;
  empGeid: any = [];
  empData: any = [];
  toPerson: any = [];
  othersPerson: any = [];
  daysArr: any = [];
  isSaving: boolean = false;

  emailsList:any = [];
  constructor(private fb: FormBuilder, private dialogRef: DynamicDialogRef, private saveService: ReportsService,
    private config: DynamicDialogConfig, private toaster: ToasterserviceService,
    private datePipe: DatePipe,
    ) { }

  ngOnInit(): void {
    this.reportData = this.config.data.data;
    this.isEdit = this.config.data.edit;
    this.isActive = this.config.data?.isActive;

    this.saveSettingPopUp = this.fb.group({
      settingName: ['', Validators.required],
      notifications: [],
      defaultSetting: [],
      employees: [],
      others: [''],
      subject: [],
      repeat: ['daily'],
      repeatByMonth: [],
      repeatInSunday: [],
      repeatInMonday: [],
      repeatInTuesday: [],
      repeatInWed: [],
      repeatInThu: [],
      repeatInFriday: [],
      repeatInSaturday: [],
      every: ['1'],
      startday: []
    })
    this.today = new Date();
    this.repeat = [{ name: 'daily', code: 'daily' }, { name: 'yearly', code: 'eyear' }, { name: 'monthly', code: 'emonth' }, { name: 'weekly', code: 'eweek' }]

    this.every = [{ name: 1, value: 1 },
    { name: 2, value: 2 }, { name: 3, value: 3 }, { name: 4, value: 4 }, { name: 5, value: 5 }, { name: 6, value: 6 },
    { name: 7, value: 7 }, { name: 8, value: 8 }, { name: 9, value: 9 }, { name: 10, value: 10 }, { name: 11, value: 11 },
    { name: 12, value: 12 }, { name: 13, value: 13 }, { name: 14, value: 14 }, { name: 15, value: 15 }, { name: 16, value: 16 },
    { name: 17, value: 17 }, { name: 18, value: 18 }, { name: 19, value: 19 }, { name: 20, value: 20 }, { name: 21, value: 21 },
    { name: 22, value: 22 }, { name: 23, value: 23 }, { name: 24, value: 24 }, { name: 25, value: 25 }, { name: 26, value: 26 },
    { name: 27, value: 27 }, { name: 28, value: 28 }, { name: 29, value: 29 }, { name: 30, value: 30 }]

    this.saveSettingPopUp.get('repeat').valueChanges.subscribe((changes) => {
      if (changes.code == 'emonth') {
        this.monthEnable = true;
        this.weekEnable = false;
      }
      else if (changes.code == 'eweek') {
        this.monthEnable = false;
        this.weekEnable = true;
      }
      else if (changes.code == 'daily') {
        this.monthEnable = false;
        this.weekEnable = false;
      }
      else if (changes.code == 'eyear') {
        this.monthEnable = false;
        this.weekEnable = false;
      }
    })

    this.saveSettingPopUp.get('notifications').valueChanges.subscribe((changes) => {
      this.bottomSection = changes;
    })

    if (this.config.data.data) {
      this.saveSettingPopUp.patchValue({
        settingName: this.config.data.data.name,
        notifications: this.config.data.data.isActive,
        defaultSetting: this.config.data.data.isDefault,
      })
      if (this.isEdit == true) {
        this.saveSettingPopUp.get('settingName').disable()
      }
      if (this.config.data.data.isActive) {
        this.saveSettingPopUp.patchValue({
          subject: this.config.data.data.subject,
          repeat: this.config.data.data.repeatType,
          repeatByMonth: this.config.data.data.repeatBy,
          every: this.config.data.data.repeatAsVal,
          startday: this.config.data.data.startDate?.split("T", 1)[0]
        })
        if (this.config.data.data.repeatDays) {
          this.daysArr = this.config.data.data.repeatDays;
          if (this.daysArr.includes(6)) {
            this.saveSettingPopUp.patchValue({
              repeatInSaturday: true
            })
          }
          if (this.daysArr.includes(5)) {
            this.saveSettingPopUp.patchValue({
              repeatInFriday: true
            })
          }
          if (this.daysArr.includes(4)) {
            this.saveSettingPopUp.patchValue({
              repeatInThu: true
            })
          }
          if (this.daysArr.includes(3)) {
            this.saveSettingPopUp.patchValue({
              repeatInWed: true
            })
          }
          if (this.daysArr.includes(2)) {
            this.saveSettingPopUp.patchValue({
              repeatInTuesday: true
            })
          }
          if (this.daysArr.includes(1)) {
            this.saveSettingPopUp.patchValue({
              repeatInMonday: true
            })
          }
          if (this.daysArr.includes(0)) {
            this.saveSettingPopUp.patchValue({
              repeatInSunday: true
            })
          }
        }
      }


      this.getCurrentUser();
      //this.saveSettingPopUp.get('startday').patchValue(this.today);
    }
    if (this.isEdit == true) {
      let empData = this.config.data.data.to;
      empData.forEach(element => {
        if (element.geid != 'null') {
          this.toPerson.push(element)
        }
        else {
          this.othersPerson.push(element.email)
        }
      });
      this.saveSettingPopUp.patchValue({
        employees: this.toPerson,
        others: this.othersPerson,
      })

    }

    this.saveSettingPopUp?.controls['startday']?.valueChanges.subscribe((changedValue) => {
      if (changedValue) {
        const updatedValue = this.datePipe.transform(changedValue, 'yyyy-MM-dd');
        this.saveSettingPopUp?.controls['startday'].patchValue(new Date(updatedValue));
      }
    })
  }


  repeatDays(value: number, event: any): void {
    if (event?.target?.checked) {
      if (!this.daysArr.includes(value))
        this.daysArr.push(value)
    }
    else {
      const index = this.daysArr.indexOf(value);
      if (index > -1) { // only splice array when item is found
        this.daysArr.splice(index, 1); // 2nd parameter means remove one item only
      }
    }
    // console.log('daysarr', this.daysArr);

  }

  getCurrentUser() {
    this.saveService.getCurrentUser().subscribe((response) => {
      this.employeesDetails = response;

      let employeeDetail = [];
      employeeDetail.push({
        geid: response?.id,
        email: response?.email,
        id: response?.id
      })
      if (this.isEdit != true) {
        this.saveSettingPopUp.get('employees').patchValue(employeeDetail)
      }
    })
  }
  filterEmployee(event) {
    const dataPasses = { wildcard: event.originalEvent.target.value, onlyActive: false, page: 1, size: 50 };
    this.saveService.getEmployeesReports(dataPasses).subscribe((response) => {
      this.employeesDetails = response;
    })
  }

  getCurrentUserProfile(event) {
    this.isActive = event?.target?.checked;
    this.saveSettingPopUp.controls['subject'].setValidators(Validators.required);
    this.isChecked = true;
    this.saveSettingPopUp.get('startday').patchValue(this.today);
    if (event.target.checked) {
      this.getCurrentUser();
    }
  }

  postdata(name) {
    if (this.saveSettingPopUp.valid) {
      if (this.isEdit == false) {
        let to: any = [];
        if (this.saveSettingPopUp.get('employees').value) {
          // console.log('NewSettingSave', this.saveSettingPopUp.get('employees').value);
          this.saveSettingPopUp.get('employees').value.forEach(element => {
            to.push({
              geid: element?.id,
              email: element?.email
            })
          });
          // console.log('to', to);
        }
        // console.log('others', this.saveSettingPopUp.get('others').value);

        if (this.saveSettingPopUp.get('others').value) {
          this.saveSettingPopUp.get('others').value.forEach(element => {
            to.push({
              geid: 'null',
              email: element
            })
          });
        }

        this.postDataVar = {
          "reportId": "4A8DE5F9-4EE1-4F18-A2CA-7D9C6C6218D7",
          "settings": {
            "id": 0,
            "name": name,
            "date": "2023-06-20T12:35:15.049Z",
            "repeatType": this.saveSettingPopUp.get('repeat').value ? this.saveSettingPopUp.get('repeat').value : "eyear",
            "repeatBy": this.saveSettingPopUp.get('repeatByMonth').value ? this.saveSettingPopUp.get('repeatByMonth').value : "m",
            "subject": this.saveSettingPopUp.get('subject').value,
            "isActive": this.isActive,
            "isDefault": this.saveSettingPopUp.get('defaultSetting').value ? this.saveSettingPopUp.get('defaultSetting').value : false,
            "to": to,
            "startDate": this.saveSettingPopUp.get('startday').value ? this.saveSettingPopUp.get('startday').value : this.today,
            "repeatAsVal": this.saveSettingPopUp.get('every').value ? this.saveSettingPopUp.get('every').value : 0,
            "repeatDays": this.saveSettingPopUp.get('repeat').value == 'eweek' ? this.daysArr : [0],
            "nextSendDate": "2023-06-20T12:35:15.049Z",
            "dataField": this.reportData
          }
        }
      }
      else {
        //getting edit value for to field
        this.toPerson = [];
        this.toPerson = this.saveSettingPopUp.get('employees').value;
        if (this.toPerson) {
          this.toPerson.forEach(element => {
            if (element.email) {
              if (element.displayGeid) {
                if (this.toPerson.includes(element.email))
                  this.toPerson.push({
                    geid: element?.id,
                    email: element?.email
                  })
              }
            }
          });

          this.toPerson.map((element) => {
            if (element.geid)
              element.id = element.geid;
          })
        }

        //for others
        this.othersPerson = [];
        let comboVar = [];
        this.saveSettingPopUp.get('others').value.forEach(element => {
          comboVar.push({
            geid: "null",
            email: element
          })
        })
        //for combo

        this.toPerson.forEach(element => {
          comboVar.push({
            geid: element.id,
            email: element.email
          })
        })
        this.postDataVar = {
          "reportId": "4A8DE5F9-4EE1-4F18-A2CA-7D9C6C6218D7",
          "settings": {
            "id": this?.config?.data?.data?.id,
            "name": this?.config?.data?.data?.name,
            "date": this?.config?.data?.data?.date,
            "repeatType": this.saveSettingPopUp.get('repeat').value,
            "repeatBy": this.saveSettingPopUp.get('repeatByMonth').value ? this.saveSettingPopUp.get('repeatByMonth').value : "m",
            "subject": this.saveSettingPopUp.get('subject').value,
            "isActive": this.saveSettingPopUp.get('notifications')?.value,
            "isDefault": this.saveSettingPopUp.get('defaultSetting').value,
            "to": comboVar,
            "startDate": this.saveSettingPopUp.get('startday').value == null ? this?.config?.data?.data?.startDate : this.saveSettingPopUp.get('startday').value,
            "repeatAsVal": this.saveSettingPopUp.get('every').value,
            "repeatDays": this.saveSettingPopUp.get('repeat').value == 'eweek' ? this.daysArr : [0],
            "nextSendDate": this?.config?.data?.data?.nextSendDate,
            "dataField": this?.config?.data?.data?.dataField
          }
        }
      }
      this.saveService.saveReportSetting(this.postDataVar).subscribe((response) => {
        if (response) {
          this.dialogRef.close('ok')
          this.toaster.showSuccess('Settings saved');
          this.isSaving = false;
        }
      })

      /* this.saveService.getReportSetting().subscribe((res) =>{
        setting= res.settings
      })  */
    }

  }


  saveSetting() {
    // For Unique settings name
    this.isSaving = true;
    if (this.isEdit == false) {
      let currentName = this.saveSettingPopUp.get('settingName').value;
      let currentNameCopy = currentName
      this.saveService.getReportSetting().subscribe((res) => {
        if (res.length != 0) {
          let reportNo = res[0].settings;
          if (reportNo.find(o => o.name == currentName) != undefined) {
            for (let i = 1; i <= reportNo.length; i++) {
              currentName = currentNameCopy + ' (' + i + ')'
              if (reportNo.find(o => o.name == currentName) != undefined) {
                continue
              }
              else {
                this.saveSettingPopUp.patchValue({
                  settingName: currentName,
                })
                this.postdata(currentName)

                break;
              }
            }
          }
          else {
            this.postdata(currentName)
          }
        }
        else {
          this.postdata(currentName)
        }

      })
    }
    else {
      this.postdata(this.config.data.data.name)
    }
    //unique name end

  }

  cancel(e) {
    e.preventDefault();
    this.dialogRef.close(false);
  }

  disableOk() {
    if (this.saveSettingPopUp.get('notifications').value == true) {
      if (!this.saveSettingPopUp.get('subject').value) {
        return true;
      }
      else if (this.saveSettingPopUp.get('repeat').value == 'eweek' &&
        (!this.saveSettingPopUp.get('repeatInSunday').value &&
          !this.saveSettingPopUp.get('repeatInMonday').value &&
          !this.saveSettingPopUp.get('repeatInTuesday').value &&
          !this.saveSettingPopUp.get('repeatInWed').value &&
          !this.saveSettingPopUp.get('repeatInThu').value &&
          !this.saveSettingPopUp.get('repeatInFriday').value &&
          !this.saveSettingPopUp.get('repeatInSaturday').value)) {
        return true;
      }
      else {
        return false;
      }
    }
    else {
      if (this.saveSettingPopUp.get('settingName').value) {
        return false;
      }
      else
        return true;
    }
  }

  validateEmail(emailEvent: any) {
    const enteredEmail = emailEvent.value;
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(enteredEmail)) {
      this.emailsList.push(enteredEmail);
      this.saveSettingPopUp.patchValue({
        others: this.emailsList
      })
    }else{
      this.saveSettingPopUp.patchValue({
        others: this.emailsList
      });
      this.toaster.showWarning('Please enter valid email')
    }
  }

  onRemoveChip(email:any){
    this.emailsList = this.emailsList.filter(val => {return val != email.value});
    this.saveSettingPopUp.patchValue({
      others: this.emailsList
    });
  }
}
