import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ExternalpageEmitService {

  public isExternalPage = new BehaviorSubject<boolean>(null);
  isSETExternalPage = this.isExternalPage.asObservable();

  public isHRGTPage = new BehaviorSubject<boolean>(null);
  isSETHRGTPage = this.isHRGTPage.asObservable();

  constructor(private http:HttpClient) { }

  setExternalPageFunction(external) {
    this.isExternalPage.next(external);
  }

  setHRGTPageFunction(hrgt) {
    this.isHRGTPage.next(hrgt);
  }

  getSurveyTemplateDetails(encodedParams){
    let params = new HttpParams()
    .set('culture', 'en')
    .set('encodedParams',encodedParams)
    .set('score','')
    return this.http.get<any>(`${environment.azure.baseUrl}api/external/survey/Index`,{params})
  }

  postSurveyTemplateDetails(questionsCollection:any){
    return this.http.post<any>(`${environment.azure.baseUrl}api/external/survey/Index`,questionsCollection);
  }

  hiConfirmation(encodedParams){
    let params = new HttpParams()
    .set('culture', 'en')
    .set('encodedParams',encodedParams)
    return this.http.get<any>(`${environment.azure.baseUrl}hi/Confirm`,{params})
  }

  getSubscriptionStatus(encodedEmail){

    let formatData = {
     'culture':'en',
     'encodedEmail':encodedEmail
    }
    return this.http.post<any>(`${environment.azure.baseUrl}api/external/subscription/Unsubscribe`,formatData);
  }

  updateSubscriptionStatus(encodedEmail){
    let formatData = {
      'culture':'en',
      'encodedEmail':encodedEmail
     }
     return this.http.post<any>(`${environment.azure.baseUrl}api/external/subscription/Subscribe`,formatData);
  }
}
