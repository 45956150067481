import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ProcessService } from 'src/app/services/process.service';
import { HttpClient } from '@angular/common/http';
import { TreeNode } from 'primeng/api';
import { PrimeNGConfig } from "primeng/api";
import { DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-process-step-popup',
  templateUrl: './process-step-popup.component.html',
  styleUrls: ['./process-step-popup.component.css']
})

export class ProcessStepPopupComponent implements OnInit {

  files1: any[] = [];
  selectedNode!: TreeNode;
  loading: boolean = false;
  constructor(private http: HttpClient,
    private processService: ProcessService,
    private primengConfig: PrimeNGConfig,
    private readonly dialogRef: DynamicDialogRef) { }

  ngOnInit(): void {
   this.getProcessStep();
  }

  getProcessStep(){
    this.loading = true;
    this.processService.getFiles().then(files => {
      if(files) {
        this.files1 = files;
        this.files1.map((res) => {
          res.label = res.name,
          res.data = res.flatName,
          // res.expandedIcon = 'pi pi-folder-open',
          // res.collapsedIcon = 'pi pi-folder',
          res.children = [],
          res.id = res.id,
          res.leaf = res.hasSubClasses == true ? false : true,
          res.parentName = res.name
        });
        this.loading = false;
      }
    });
  }

  filterProcessStep($event){
    this.loading = true;
    let searchData = {
      filterText: $event.target?.value,
      parentId: null
    };
    if($event.target.value) {
      this.processService.getFilteredProcessStepsTree(searchData).then(files=>{
        if(files){
          this.files1 = files;
          this.files1.map((res) => {
            res.label = res.name,
            res.data = res.flatName,
            res.key = res.name,
            res.expanded = res.shouldBeExpanded,
            res.children = this.recursiveForSearch(res.subClasses),
            res.id = res.id,
            res.leaf = res.hasSubClasses == true ? false : true,
            res.parentName = res.name;
          });
          this.loading = false;
        }
      })
    }
    else {
      this.getProcessStep();
    }
  }

  // In search box for parent/child purpose
  nodeSelect(event: any) {
    const nodeselection = event.node;
    if(nodeselection.hasSubClasses) {
      this.loading = true;
      this.processService.procesStepById(nodeselection.id).then((res) => {
        if(res){
          this.files1 = this.newH(res, this.files1, nodeselection.id)
          this.loading = false;
        }
      })
    }
  }

  newH(newData: any, h ,id){
      h.forEach((node, index) => {
          if(node.id == id){
          const New = []
           newData.map((res) => {
                    res.label = res.name,
                    res.data = res.flatName,
                    // res.expandedIcon = 'pi pi-folder-open',
                    // res.collapsedIcon = 'pi pi-folder',
                    res.children = res.hasSubClasses,
                    res.id = res.id,
                    res.leaf = res.hasSubClasses == true ? false : true,
                    res.parentName = res.parent
                  });
            node.children = [...newData]
          } else if(node.children.length) {
             this.newH(newData, node.children,id)
          }
      })

      return h
    }

  recursiveForSearch(childrenRecords) {
    childrenRecords.map((childRes) => {
      childRes.label = childRes.name,
      childRes.data = childRes.flatName,
      childRes.children = childRes.subClasses,
      childRes.id = childRes.id,
      childRes.expanded = childRes.shouldBeExpanded,
      childRes.leaf = childRes.hasSubClasses == true ? false : true,
      childRes.parentName = childRes.name
    })
    childrenRecords.map((hasChildren) => {
      if(hasChildren.hasSubClasses) {
        this.recursiveForSearch(hasChildren?.subClasses)
      }
    })
    return childrenRecords;
  }

  selectAndClose() {
  //  console.log("<><><>",this.selectedNode, this.selectedNode?.parent);
    if (this.selectedNode !== null && this.selectedNode !== undefined) {
      let selectedValue: any = this.selectedNode;
      var tooltip = '';
      let level = 0;
      if(this.selectedNode?.parent?.parent?.parent?.parent?.parent?.parent?.parent){
        level = 7;
      }
      else if(this.selectedNode?.parent?.parent?.parent?.parent?.parent?.parent){
        level = 6;
      }
      else if(this.selectedNode?.parent?.parent?.parent?.parent?.parent){
        level = 5;
      }
      else if(this.selectedNode?.parent?.parent?.parent?.parent){
        level = 4;
      }
      else if(this.selectedNode?.parent?.parent?.parent){
        level = 3;
      }
      else if(this.selectedNode?.parent?.parent){
        level = 2;
      }
      else if(this.selectedNode?.parent){
        level = 1;
      }
      if (selectedValue?.displayLabel.length > 70) {
          tooltip = selectedValue?.displayLabel.substring(selectedValue?.displayLabel.length - 57, selectedValue?.displayLabel.length) + '...';
      }
      else {
          tooltip = selectedValue?.displayLabel;
      }
      tooltip = '<span class="spacer"></span>'.repeat(level) + '<b>' + this.xssProtection(tooltip) +
          (selectedValue?.requiresDescription === true ? "</b>: " + this.xssProtection(selectedValue?.description) : "</b>") + "<br/>";
      let displayLabel = this.xssProtection(selectedValue?.displayLabel) +
          (selectedValue?.requiresDescription === true ? this.xssProtection(selectedValue?.description) : '');
      var currentNode:any = this.selectedNode?.parent;
      var currenttreeNode = this.selectedNode?.parent;
      level--;
      while (currenttreeNode !== undefined && currenttreeNode !== null) {
          displayLabel = this.xssProtection(currentNode?.displayLabel) + '/' + displayLabel;
          if (currentNode?.displayLabel?.length > 60) {
              currentNode.displayLabel = currentNode?.displayLabel.substring(displayLabel?.length - 57, displayLabel?.length) + '...';
          }
          tooltip = '<span class="spacer"></span>'.repeat(level) + this.xssProtection(currentNode?.displayLabel) + '<br/>' + tooltip;
          currentNode = currenttreeNode?.parent;
          currenttreeNode = currenttreeNode?.parent;
          level--;
      }
      if (displayLabel?.length > 60) {
          selectedValue.displayLabel = '...' + displayLabel.substring(displayLabel?.length - 57, displayLabel?.length);
      } else {
          selectedValue.displayLabel = displayLabel;
      }
      selectedValue.tooltipInfo = tooltip;
      this.dialogRef.close(selectedValue);
    }
  }
  xssProtection(value) {
    var lt = /</g,
        gt = />/g,
        ap = /'/g,
        ic = /"/g;
    return value.replace(lt, "&lt;").replace(gt, "&gt;").replace(ap, "&#39;").replace(ic, '&#34;');
}
}
