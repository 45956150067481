<form [formGroup]="resolvingPopForm">
  <div class="popup bal-id-popup">
    <div class="container">
      <div class="form-check select-search ps-0">
        <input
          type="radio"
          value="balRadio"
          formControlName="balIdRadioBtn"
          (click)="enableTextBox()"
          class="select-balId"
        />
        <label class="widgetRadioButton float-start mt-2 me-2" (change)="onRadioChange()">
          {{ "RUSearchPolicyBalId" | translate }}
        </label>
        <div class="balDiv float-start">
          <!-- <label class="divColoumnSpan tagColumn" style="margin-left: -10px"
            >BAL ID</label -->

          <input
            type="text"
            class="textBoxForBalId mt-1"
            formControlName="balId" placeholder="BAL ID"
            [disabled]="!textboxEnabled"
          />
        </div>
      </div>
      <div class="form-check select-search  ps-0 mt-3">
        <input
          type="radio"
          value="Search by business activity location"
          formControlName="searchByBusinessLocation"
          (change)="onRadioChange()"
        />
        <label class="widgetRadioButton">
          {{ "RUSearchPolicyBAL" | translate }}
        </label>
      </div>
      <div>
        <label class="divColoumnSpan tagColumn mt-0" style="padding-left: 30px">{{
          "ResolvingUnitSearch_BusinessActivity" | translate
        }}</label
        >&nbsp;
        <div class="form-check form-check-inline">
          <input
            class="form-check-input"
            type="radio"
            id="inlineRadio1"
            value="local"
            formControlName="local"
            [checked]="isChecked"
          />
          <label class="form-check-label" for="inlineRadio1">{{
            "BusinessActivityTypeLocal" | translate
          }}</label>
        </div>

        <div class="form-check form-check-inline">
          <input
            class="form-check-input"
            type="radio"
            id="inlineRadio2"
            value="global"
            formControlName="local"
          />
          <label class="form-check-label" for="inlineRadio2">{{
            "BusinessActivityTypeGlobal" | translate
          }}</label>
        </div>

        <div class="row  inp mb-1 ms-1">
          <div class="divColoumnSpan tagColumn">
            {{ "ResolvingUnitSearch_EmployeeDivision" | translate }}
          </div>
          <div class="col remove-px">
            <select
              type="text"
              class="form-select form-control widgetTextBoxMedium"
              aria-label="Default select example"
              formControlName="business"
            >
              <option value="">--Select one--</option>
              <option
                *ngFor="let div of divisionData"
                value="{{ div?.key }}"
                selected
              >
                {{ div.value }}
              </option>
            </select>
          </div>

          <div class="divColoumnSpan tagColumn">
            {{ "ResolvingUnitSearch_Country" | translate }}
          </div>
          <div class="col remove-px">
            <select
              type="text"
              class="form-select form-control widgetTextBoxMedium"
              formControlName="country"
              aria-labelledby="country"
            >
              <option value="" selected>--Select one--</option>
              <option
                *ngFor="let country of getCountryList"
                value="{{ country.id }}"
              >
                {{ country.name }}
              </option>
            </select>
          </div>
        </div>

        <div class="row  inp mb-1 ms-1">
          <div class="divColoumnSpan tagColumn">
            {{ "ResolvingUnitSearch_EmployeeBU" | translate }}
          </div>
          <div class="col remove-px">
            <select
              type="text"
              class="form-select form-control widgetTextBoxMedium"
              formControlName="bu"
              aria-labelledby="bu"
              (change)="pgChangeData($event)"
            >
              <option value="" selected>--Select one--</option>
              <option *ngFor="let count of buPgList" value="{{ count?.key }}">
                {{ count.name }}
              </option>
            </select>
          </div>

          <div class="divColoumnSpan tagColumn">
            {{ "ResolvingUnitSearch_City" | translate }}
          </div>
          <div class="col remove-px">
            <select
              class="form-select form-control widgetTextBoxMedium"
              formControlName="city"
              name="city"
            >
              <option value="" selected>--Select one--</option>
              <option *ngFor="let city of getCityList">{{ city.name }}</option>
            </select>
          </div>
        </div>

        <div class="row  inp mb-1 ms-1">
          <div class="divColoumnSpan tagColumn">
            {{ "ResolvingUnitSearch_EmployeePG" | translate }}
          </div>
          <div class="col remove-px">
            <select
              type="text"
              class="form-select form-control widgetTextBoxMedium"
              aria-label="Default select example"
              formControlName="pg"
            >
              <option value="" selected>--Select one--</option>
              <option
                *ngFor="let pgDetails of pgName | keyvalue"
                value="{{ pgDetails?.key }}"
              >
                {{ pgDetails?.value }}
              </option>
            </select>
          </div>

          <div class="divColoumnSpan tagColumn">
            {{ "ResolvingUnitSearch_EmployeeActivity" | translate }}
          </div>
          <div class="col remove-px">
            <select
              type="text"
              class="form-select form-control widgetTextBoxMedium"
              aria-label="Default select example"
              formControlName="activity"
            >
              <option value="" selected>--Select one--</option>
              <option
                *ngFor="let b of activityDropdownData"
                value="{{ b?.key }}"
              >
                {{ b.value }}
              </option>
            </select>
          </div>
        </div>
      </div>

      <div
        class="modal-footer my-1"
      >
        <button
          class="input widgetButtonLarge"
          type="button"
          (click)="viewRuPopUpDetails()"
        >
          {{ "ButtonSearch" | translate }}
        </button>
        <button class="input widgetButtonLarge ms-1" type="button" (click)="reset()">
          {{ "ButtonReset" | translate }}
        </button>
      </div>
    </div>

    <div class="tablediv">
      <h5 *ngIf="recentlySelected" class="mx-2 mt-0">
        {{ "SearchForm_BalRecentlySelected" | translate }}
      </h5>
      <div class="tableMargin">
        <div class="card">
          <p-table
            [value]="filteredCompanyDetails"
            [lazy]="true"
            [totalRecords]="totalRecords"
            [loading]="loading"
            (onLazyLoad)="loadMoreResolvingData($event)"
            selectionMode="single"
            [paginator]="true"
            [rows]="rows"
            [showCurrentPageReport]="true"
            [(first)]="first"
            [tableStyle]="{ 'min-width': '50rem' }"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
            styleClass="p-datatable-gridlines"
          >
            <ng-template
              pTemplate="header"
              styleClass="table table-bordered table-striped table-hove"
            >
              <tr>
                <th pSortableColumn="balId" style="width: 15%">
                  {{ "ResolvingUnitSearch_GridColumn_BALId" | translate
                  }}<p-sortIcon field="balId"></p-sortIcon>
                </th>
                <th pSortableColumn="abacus" style="width: 20%">
                  {{ "ResolvingUnitSearch_GridColumn_AbacusCode" | translate
                  }}<p-sortIcon field="abacus"></p-sortIcon>
                </th>
                <th pSortableColumn="mgmtOrg" style="width: 15%">
                  {{ "ResolvingUnitSearch_GridColumn_MgmtOrg" | translate
                  }}<p-sortIcon field="mgmtOrg"></p-sortIcon>
                </th>
                <th pSortableColumn="productGroup" style="width: 25%">
                  {{ "ResolvingUnitSearch_GridColumn_ProductGroup" | translate
                  }}<p-sortIcon field="productGroup"></p-sortIcon>
                </th>
                <th pSortableColumn="address" style="width: 15%">
                  {{ "ResolvingUnitSearch_GridColumn_Address" | translate
                  }}<p-sortIcon field="address"></p-sortIcon>
                </th>
                <th pSortableColumn="activity" style="width: 15%">
                  {{ "ResolvingUnitSearch_GridColumn_Activity" | translate
                  }}<p-sortIcon field="activity"></p-sortIcon>
                </th>
              </tr>
            </ng-template>

            <ng-template pTemplate="body" let-c let-rowIndex="rowIndex">
              <tr
                [ngClass]="{ selectedBalIdRow: rowIndex == selectedIndex }"
                (click)="selectedRow(rowIndex)"
              >
                <td>{{ c.baL_Id }}</td>
                <td>{{ c.abacusCode }}</td>
                <td>{{ c.mgmtOrg }}</td>
                <td>{{ c.productGroup }}</td>
                <td>{{ c.address }}</td>
                <td>{{ c.activity }}</td>
              </tr>
            </ng-template>
            <ng-template pTemplate="paginatorright">
              <p-button
                type="button"
                icon="pi pi-refresh"
                (click)="refresh()"
                styleClass="p-button-text"
              ></p-button>
            </ng-template>
          </p-table>
        </div>
        <div
          class="modal-footer my-2"
        >
          <button [disabled]="selectedIndex === -1" class="widgetButtonLargeSelectClose"
            [ngClass]="
              selectedIndex !== -1
                ? ''
                : 'grayButton'"
            type="button"
            (click)="selectAndClose()"
          >
            {{ "ButtonSelectClose" | translate }}
          </button>
          <button
            class="input widgetButtonLarge ms-1"
            type="button"
            (click)="onClose()"
          >
            {{ "select_ru_popup_btn_close" | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</form>
