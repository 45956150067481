import { Injectable } from '@angular/core';
import { MdRegionService } from 'src/app/services/md-region.service';
import { MdServiceService } from 'src/app/services/md-service.service';
import { SessionStorageServiceService } from 'src/app/services/session-storage-service.service';

@Injectable({
  providedIn: 'root'
})
export class FailuremodeAuthService {

  userInfo: any = {};
  userScopes = [];

  constructor(private mdData: MdServiceService,
    private mdRegionService: MdRegionService,
    private sessionStorageService: SessionStorageServiceService) {
      this.userInfo = this.sessionStorageService.getData('userInfo');
      this.init();
    }

    init() {
      this.userScopes = [];
      this.userInfo.processOwnerScopes.forEach((poScope) => {
          let userScope = {
              div: null,
              bus: [],
              pgs: [],
              region: null,
              countries: []
          };
          if (poScope.pg) {
              userScope.pgs.push(poScope.pg);
          }
          else if (poScope.bu) {
              userScope.bus.push(poScope.bu);

              this.mdData.pg().then((pgs)=> {
                  pgs.forEach((p) => {
                      if (p.buCode == poScope.bu) {
                          userScope.pgs.push(p.id);
                      }
                  });
              });
          }
          else if (poScope.div) {
              userScope.div = poScope.div;

              this.mdData.bu().then((bus) => {
                 bus.forEach((b) => {
                      if (b.divCode == poScope.div) {
                          userScope.bus.push(b.id);
                      }
                  });
              });
              this.mdData.pg().then((pgs)=> {

                  pgs.forEach((p) => {
                      if (p.divCode == poScope.div) {
                          userScope.pgs.push(p.id);
                      }
                  });
              });
          }

          if (poScope.region) {
              userScope.region = poScope.region;

              this.mdRegionService.countries().then((countries) => {
                 countries.forEach((c) => {;
                      if (c.rCode == poScope.region) {
                          userScope.countries.push(c.id);
                      }
                  });
              });
          }

          if (poScope.country) {
              userScope.countries.push(poScope.country);
          }
          this.userScopes.push(userScope);
      });
    };

    canManagePgCategory(poScope) {
      if (this.userInfo.hasGlobalPermissions || this.userInfo.isSupportDeskMember) {
          return true;
      }
      if (!poScope.div && !poScope.bu && !poScope.pg) {
          return false;
      } if (this.userScopes.length == 0) {
          return false;
      }

      var canManage = false;
      if (poScope.div || poScope.bu || poScope.pg) {
          if (poScope.pg) {
             this.userScopes.forEach( (us)=> {
                  if (us.pgs && us.pgs.indexOf(poScope.pg) > -1 && us.countries.length == 0 && !us.region)
                      canManage = true;
              });
          } else if (poScope.bu) {
             this.userScopes.forEach((us) => {
                  if (us.bus && us.bus.indexOf(poScope.bu) > -1 && us.countries.length == 0 && !us.region)
                      canManage = true;
              });
          } else if (poScope.div) {
              this.userScopes.forEach((us) => {
                  if (us.div && us.div == poScope.div && us.countries.length == 0 && !us.region)
                      canManage = true;
              });
          }
      }

      return canManage;
  };


}
