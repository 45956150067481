import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'keyValue'
})
export class KeyValuePipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    if(!value) return value;
    const keyValueArray =[];
    
    for(let key in value){
      if(value.hasOwnProperty(key)){
        keyValueArray.push({key:key, value:value[key]});
      }
    }
    return keyValueArray;
  }

}
