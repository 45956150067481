import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { SessionStorageServiceService } from 'src/app/services/session-storage-service.service';
import { ToasterserviceService } from 'src/app/services/toasterservice.service';
import { UserInfoDetailsService } from 'src/app/services/userInfoDetails/user-info-details.service';
import { userProfileModal, EmployeeDetails } from '../user-info-details/user-info-details/user-info-details.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-tester-login',
  templateUrl: './tester-login.component.html',
  styleUrls: ['./tester-login.component.css']
})
export class TesterLoginComponent implements OnInit {

  testerLoginForm!: FormGroup;
  userInformationDetails!: userProfileModal;
  employeeDetails: EmployeeDetails;
  loginForm!: FormGroup;
  loginSuccess:boolean = false;
  errorMessage:boolean = false;

  constructor(private fb: FormBuilder,
    private toaster: ToasterserviceService,
    private userInfoDetails: UserInfoDetailsService,
    private sessionStorage: SessionStorageServiceService,
    private route: Router,
    private spinner: NgxSpinnerService,) { }

  ngOnInit(): void {
    this.testerLoginForm = this.fb.group({
      testUserEmailId: ['']
    })
    this.loginForm = this.fb.group({
      username: ['',Validators.required],
      password: ['',Validators.required]
    })
    this.loginSuccess = environment.displayActionNotification;
  }

  testerLogin() {
    this.spinner.show();
    let testerEmailId = this.testerLoginForm.controls['testUserEmailId'].value;
    if (testerEmailId) {

      this.userInfoDetails.getUserInfo(testerEmailId).subscribe((resp) => {
        this.userInformationDetails = resp;
        this.sessionStorage.setData('userInfo', resp);

        this.userInfoDetails.getEmployeeDetails(this.sessionStorage.getData('userInfo').geid).then((resp) => {
          this.employeeDetails = resp[0];
          this.userInformationDetails.employeeDetails = this.employeeDetails;

          this.userInformationDetails.processOwnerScopes.forEach((poScope) => {
            if (!poScope.div && !poScope.bu && !poScope.pg) {
              poScope.location = true;
            } else {
              poScope.unit = true;
            }
          });
          this.sessionStorage.setData('userInfo', this.userInformationDetails);
          if (this.userInformationDetails) {
            this.route.navigate(['/mydashboard'])
              .then(() => {
                window.location.reload();
              });
          }
          setTimeout(() => this.spinner.hide(), 1);
        },
          (error) => {
            setTimeout(() => this.spinner.hide(), 1);
            this.toaster.showError('Error while fetching employee details');
          }
        )
      },
        (err) => {
          this.toaster.showError('Error while fetching employee details')
          setTimeout(() => this.spinner.hide(), 1);
        });

    } else {
      this.toaster.showError('Please enter correct email')
    }
  }

  login(){
    let usernamevalue = this.loginForm.controls['username'].value;
    let passwordvalue = this.loginForm.controls['password'].value;
    if(usernamevalue === 'ccrprewrite' && passwordvalue === 'ccrprewrite'){
      this.loginSuccess=true;
      this.errorMessage = false;
    }else{
      this.loginSuccess=false;
      this.errorMessage = true;
    }
  }

}
