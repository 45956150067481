import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { config } from 'rxjs';
import { AdminSystemusersAuthserviceService } from '../admin-systemusers-authservice.service';
import { UsersService } from 'src/app/services/users/users.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ToasterserviceService } from 'src/app/services/toasterservice.service';
import { AdminSystemusersServiceService } from '../admin-systemusers-service.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { AdminProcessOwnerService } from 'src/app/services/admin-process-owner.service';


@Component({
  selector: 'app-add-systemuser-role',
  templateUrl: './add-systemuser-role.component.html',
  styleUrls: ['./add-systemuser-role.component.css']
})
export class AddSystemuserRoleComponent implements OnInit {

  addSystemUserForm!: FormGroup;

  suScope:any;
  selected:any   = {
    employee: null,
    globalProcessOwner: false,
    supportDeskMember: false,
    resourceEditor: false,
    managementRole: false
  };

  readonlyFields = {
    employee: false,
    globalProcessOwner: false,
    supportDeskMember: false,
    resourceEditor: false,
    managementRole: false
 };

 roleSelected:boolean = false;

 currentUserManageScope!:any;
 filteredOwner: any;
 issueOwnerText = "Issue Owner name. The name should be entered in the following format: firstname.lastname@hitachienergy.com";

 employeeSelected:boolean = false;

  constructor(private readonly dialogRef: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private suAuthService:AdminSystemusersAuthserviceService,
    private usersService: UsersService,
    private fb:FormBuilder,
    private toaster: ToasterserviceService,
    private spinner: NgxSpinnerService,
    private systemUserService:AdminSystemusersServiceService,
    private AdminProcessOwnerService:AdminProcessOwnerService
    ) {
      this.suScope = config.data;
      if (this.suScope) {

      //  this.selected.employee = this.suScope?.userInfo?.id;
        this.selected.globalProcessOwner = this.suScope?.isGlobalProcessOwner;
        this.selected.supportDeskMember = this.suScope?.isSupportDeskMember;
        this.selected.resourceEditor = this.suScope?.isResourceEditor;
        this.selected.managementRole = this.suScope?.isManagementRole;

        this.readonlyFields.employee = true;
        this.readonlyFields.globalProcessOwner = this.suScope?.isGlobalProcessOwner ;
        this.readonlyFields.supportDeskMember = this.suScope?.isSupportDeskMember;
        this.readonlyFields.resourceEditor = this.suScope?.isResourceEditor;
        this.readonlyFields.managementRole = this.suScope?.isManagementRole;
    }

    this.currentUserManageScope = this.suAuthService.manageScope;

    if (!this.currentUserManageScope.globalProcessOwner) {
        this.readonlyFields.globalProcessOwner = true;
    }
    if (!this.currentUserManageScope.globalProcessOwner && !this.currentUserManageScope.supportDeskMember) {
        this.readonlyFields.supportDeskMember = true;
    }
    if (!this.currentUserManageScope.globalProcessOwner && !this.currentUserManageScope.supportDeskMember && !this.currentUserManageScope.resourceEditor) {
        this.readonlyFields.resourceEditor = true;
    }
    if (!this.currentUserManageScope.managementRole) {
        this.readonlyFields.managementRole = true;
    }
    }


  ngOnInit(): void {
    this.addSystemUserForm = this.fb.group({
      systemUserEmail: [''],
      userGEID:['']
  });

  if(this.suScope){
    let fullEmail = this.suScope?.userInfo?.firstName +' '+this.suScope?.userInfo?.lastName + '<'+ this.suScope?.userInfo?.email +'>'
    let selectedSystemuser = {name:  fullEmail};
    this.addSystemUserForm.patchValue({
      systemUserEmail:selectedSystemuser,
      userGEID: this.suScope?.userInfo?.id
    });
    this.employeeSelected = true;
  }

  this.addSystemUserForm.get('systemUserEmail').valueChanges.subscribe((status) => {
    if(status) {
      this.addSystemUserForm.patchValue({
        userGEID: status.id
      });
      this.employeeSelected = true;
    }
  })

  this.enableButtons();
  }

 globalProcessOwnerClicked() {
    this.selected.globalProcessOwner = !this.selected.globalProcessOwner;
    this.enableButtons();
  };

supportDeskClicked(){
    this.selected.supportDeskMember = !this.selected.supportDeskMember;
    this.enableButtons();
};

resourceEditorClicked(){
    this.selected.resourceEditor = !this.selected.resourceEditor;
    this.enableButtons();
};
managementRoleClicked() {
    this.selected.managementRole = !this.selected.managementRole;
    this.enableButtons();
};

cancel(){
  this.dialogRef.close({result : 'none'});
}

  ok() {
    this.spinner.show();
    let currentUserGEID = this.addSystemUserForm.get('userGEID').value;
    let roles = [];
    if (this.suScope) { //edit user
      if (this.selected.globalProcessOwner && this.selected.globalProcessOwner != this.suScope.isGlobalProcessOwner) {
        roles.push(1);
      }
      if (this.selected.supportDeskMember && this.selected.supportDeskMember != this.suScope.isSupportDeskMember) {
        roles.push(2);
      }
      if (this.selected.resourceEditor && this.selected.resourceEditor != this.suScope.isResourceEditor) {
        roles.push(14);
      }
      if (this.selected.managementRole && this.selected.managementRole != this.suScope.isManagementRole) {
        roles.push(21);
      }
    } else {
      if (this.selected.globalProcessOwner) {
        roles.push(1);
      }
      if (this.selected.supportDeskMember) {
        roles.push(2);
      }
      if (this.selected.resourceEditor) {
        roles.push(14);
      }
      if (this.selected.managementRole) {
        roles.push(21);
      }
    }

    if (!currentUserGEID
      || (roles.length == 0)
      ) {
      this.toaster.showError('Cannot add System User Role. Invalid data.');
      return;
    }

    this.systemUserService.addSystemUserRoles(currentUserGEID,roles).subscribe(
      (resp:any)=>{
       if(resp.success){
        this.toaster.showSuccess('System user roles added');
        this.dialogRef.close({result : 'ok'});
        this.spinner.hide();
       }else{
        this.toaster.showError(resp.failureMsg);
        this.spinner.hide();
       }

      },
      err =>{
        this.toaster.showError('Add operation failed');
        this.spinner.hide();
      }

    )


  }

  filterOwner(query) {
    if (query.length > 1) {
      this.AdminProcessOwnerService.getFilteredUser(query).subscribe((res) => {
        this.filteredOwner = res;
        this.filteredOwner.map((data) => {
          data.name = data.name + '<' + data.email + '>';
        })
      })
    }
  }


  enableButtons() {
    if (this.suScope) {

      this.roleSelected = (this.selected.globalProcessOwner != this.suScope.isGlobalProcessOwner)
        || (this.selected.supportDeskMember != this.suScope.isSupportDeskMember)
        || (this.selected.resourceEditor != this.suScope.isResourceEditor)
        || (this.selected.managementRole != this.suScope.isManagementRole);
    } else {
      this.roleSelected = this.selected.globalProcessOwner
        || this.selected.supportDeskMember
        || this.selected.resourceEditor
        || this.selected.managementRole;

    }
}






}
