<div class="row transalationEditModal">
  <div class="modal-body">
    <form class="form-horizontal" [formGroup]="msgTemplateForm">
      <div class="row mt-1">
        <!--Subject-->
        <div class="col-sm-1">
          <label class="label-align-right label-bold mt-1 pe-2">{{'admin_translations_msg_subject_lb' | translate}}</label>
        </div>
        <div class="col-sm-11">
          <input type="text" class="form-control form-control-sm" [readonly]="isDisabled"
            placeholder="{{'admin_translations_msg_modal_subject_inp_ph' | translate}}" name="subject"
            formControlName="subject" required />
            <span class="error" *ngIf="this.msgTemplateForm.get('subject').touched && this.msgTemplateForm.get('subject').errors?.['required']">{{'admin_translations_msg_modal_subject_req_lb' | translate}}<br></span>
            <span class="error" *ngIf="this.msgTemplateForm.get('subject').errors?.['maxlength']">{{'admin_translations_msg_modal_subject_toolong_lb' | translate}}<br></span>
            <p *ngIf="shouldShowEngTranslation()" style="padding-top: 10px;">
              <span class="label label-primary">EN</span>
              <i [innerHTML]="message.engTranslation.subject"></i>
          </p>
          </div>
      </div>
      <div class="row mt-1">
        <div class="col-sm-1">
          <label class="label-align-right label-bold mt-1 pe-2">{{'admin_translations_msg_body_lb' | translate}}</label>
        </div>
        <div class="col-sm-11">
          <p-editor formControlName="body" [style]="{height: '200px'}"> </p-editor>
          <span class="error" *ngIf="this.msgTemplateForm.get('body').touched && this.msgTemplateForm.get('body').errors?.['required']">{{'admin_translations_msg_modal_body_req_lb' | translate}}<br></span>
          <p *ngIf="shouldShowEngTranslation()" style="padding-top: 10px;">
            <span class="label label-primary">EN</span>
            <i [innerHTML]="message.engTranslation.body"></i>
        </p>
        </div>
      </div>
      <div class="row mt-1">
        <div class="col-sm-1">
          <label class="label-align-right label-bold pe-2 editlabel">{{'admin_translations_msg_modal_to_lb' | translate}}</label>
        </div>
        <div class="col-sm-11">
          <p-multiSelect [options]="message.allRecipients" [showToggleAll]="false" [resetFilterOnHide]="true" [filter]="true" optionValue="code"
            formControlName="toRecipients" optionLabel="display" display="chip" filterBy="display" dropdownIcon="none"
            placeholder="{{'admin_translations_msg_modal_recipients_ph' | translate}}">
          </p-multiSelect>
        </div>
      </div>
      <div class="row mt-1">
        <div class="col-sm-1">
          <label class="label-align-right label-bold pe-2 editlabel">{{'admin_translations_msg_modal_cc_lb' | translate}}</label>
        </div>
        <div class="col-sm-11">
          <p-multiSelect [options]="message.allRecipients" [showToggleAll]="false" [resetFilterOnHide]="true" [filter]="true" optionValue="code"
            formControlName="ccRecipients" optionLabel="display" display="chip" filterBy="display" dropdownIcon="none"
            placeholder="{{'admin_translations_msg_modal_recipients_ph' | translate}}">
          </p-multiSelect>
        </div>
      </div>
    </form>
  </div>

  <div class="row my-3">
    <div class="col-sm-10">
      <span class="bg-warning leftAlignedText">
        <strong>{{'admin_translations_msg_modal_placeholder_info' | translate}}</strong>
      </span>
    </div>
  </div>
    <div class="modal-footer my-2">
      <span class="label-align-right">
        <button class="widgetButtoSmall" title="{{'admin_translations_msg_modal_cancel_btn_title' | translate}}"
          (click)="closeModal()">
          {{'admin_translations_msg_modal_cancel_btn_lb' | translate}}
        </button>
        <button  [ngClass]="msgTemplateForm.invalid ? 'widgetButtoSmall grayButton ms-1' : 'widgetButtoSmall ms-1'"
         role="button" [disabled]="msgTemplateForm.invalid"
          title="{{'admin_translations_msg_modal_save_btn_title' | translate}}" (click)="update()">
          {{'admin_translations_msg_modal_save_btn_lb' | translate}}
        </button>
      </span>
    </div>
</div>
