<div class="pgrcaCatEdit">
    <form [formGroup]="pgrcaEditPopForm">
        
        <div>
            <div class="input-group mt-3 mobile-display-cls">
            <label class="control-label col-sm-2 me-0 pe-3 mt-1 test-al-lbl1" for=""> <b>{{'admin_pg_categories_addedit_name_lb' | translate}}</b></label>
            <div class="justify-content-center col-sm-10">
                <input class="form-control col-md-12 w-100" type="text" name="" value=""
                formControlName="editName"
                [ngClass]="{'form-control-error': ( !this.pgrcaEditPopForm.get('editName').valid) || validationItem.hasErrors}"
                />
                <span class="error" *ngIf="this.pgrcaEditPopForm.get('editName').errors?.['required']">{{'admin_pg_categories_addedit_name_msg_req' | translate}}<br></span>
            <span class="error" *ngIf="this.pgrcaEditPopForm.get('editName').errors?.['maxlength']">{{'admin_pg_categories_addedit_name_msg_maxlength' | translate}}<br></span>
            <span *ngIf="this.pgrcaEditPopForm.get('editName').dirty && validationItem.hasErrors">
                <span class="error" *ngIf="validationItem.isActiveItem">{{'admin_process_steps_addedit_iefp_active_msg' | translate}}</span>
                <span class="error" *ngIf="!validationItem.isActiveItem">{{'admin_process_steps_addedit_iefp_notactive_msg' | translate}}</span>
            </span>
                
                <!-- [class.is-invalid]="f['editName'].invalid && (f['editName'].dirty || f['editName'].touched)"               
                    <div *ngIf="f['editName'].invalid && (f['editName'].dirty || f['editName'].touched)" class="invalid-feedback">
                        <div *ngIf="f['editName'].errors?.['required']">
                            {{'admin_pg_categories_addedit_name_msg_req' | translate}}
                        </div>
                    </div> -->
            </div>
        </div>
            <div class="input-group mt-2">
            <label class="form-check-label col-sm-2 pe-3 me-0 test-al-lbl2" for=""><b>{{'admin_cc_customers_crud_active_lb' | translate}}</b></label>
            <div class="justify-content-center">
                <input class="form-check-input" type="checkbox" 
                formControlName="checkBoxEdit" 
                />
               
                
            </div>
        </div>
        <div class="modal-footer" >
            <button class="widgetButtoSmall mx-2" type="button" (click)="close()">{{'admin_process_steps_addedit_cancel_btn_lb' | translate}}</button>
            <button (click)="editData()" type="button" class="widgetButtoSmall"
            [disabled]="this.pgrcaEditPopForm.invalid || !this.pgrcaEditPopForm.dirty || validationItem.hasErrors" 
            >{{'admin_process_steps_addedit_save_btn_lb' | translate}}</button>
        </div>
        </div>
    </form>
</div>
