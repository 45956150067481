import { Component, OnInit } from '@angular/core';
import { MsalService } from '@azure/msal-angular';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.css']
})
export class PageNotFoundComponent implements OnInit {

  constructor(public msalService: MsalService) { }

  ngOnInit(): void {
  }

  // goToLogin(){
  //   this.msalService.loginRedirect();
  // }

}
