import { Directive ,ViewContainerRef} from '@angular/core';

@Directive({
  selector: '[appContactpopup]'
})
export class ContactpopupDirective {

  constructor(public viewContainerRef: ViewContainerRef) { } 

}
