import * as i0 from '@angular/core';
import { PLATFORM_ID, Directive, Inject, Optional, NgModule } from '@angular/core';
import { isPlatformBrowser, DOCUMENT, CommonModule } from '@angular/common';
import { DomHandler } from 'primeng/dom';
import * as i1 from 'primeng/api';
class Ripple {
  constructor(document, platformId, renderer, el, zone, config) {
    this.document = document;
    this.platformId = platformId;
    this.renderer = renderer;
    this.el = el;
    this.zone = zone;
    this.config = config;
  }
  ngAfterViewInit() {
    if (isPlatformBrowser(this.platformId)) {
      if (this.config && this.config.ripple) {
        this.zone.runOutsideAngular(() => {
          this.create();
          this.mouseDownListener = this.renderer.listen(this.el.nativeElement, 'mousedown', this.onMouseDown.bind(this));
        });
      }
    }
  }
  onMouseDown(event) {
    let ink = this.getInk();
    if (!ink || this.document.defaultView.getComputedStyle(ink, null).display === 'none') {
      return;
    }
    DomHandler.removeClass(ink, 'p-ink-active');
    if (!DomHandler.getHeight(ink) && !DomHandler.getWidth(ink)) {
      let d = Math.max(DomHandler.getOuterWidth(this.el.nativeElement), DomHandler.getOuterHeight(this.el.nativeElement));
      ink.style.height = d + 'px';
      ink.style.width = d + 'px';
    }
    let offset = DomHandler.getOffset(this.el.nativeElement);
    let x = event.pageX - offset.left + this.document.body.scrollTop - DomHandler.getWidth(ink) / 2;
    let y = event.pageY - offset.top + this.document.body.scrollLeft - DomHandler.getHeight(ink) / 2;
    this.renderer.setStyle(ink, 'top', y + 'px');
    this.renderer.setStyle(ink, 'left', x + 'px');
    DomHandler.addClass(ink, 'p-ink-active');
    this.timeout = setTimeout(() => {
      let ink = this.getInk();
      if (ink) {
        DomHandler.removeClass(ink, 'p-ink-active');
      }
    }, 401);
  }
  getInk() {
    const children = this.el.nativeElement.children;
    for (let i = 0; i < children.length; i++) {
      if (typeof children[i].className === 'string' && children[i].className.indexOf('p-ink') !== -1) {
        return children[i];
      }
    }
    return null;
  }
  resetInk() {
    let ink = this.getInk();
    if (ink) {
      DomHandler.removeClass(ink, 'p-ink-active');
    }
  }
  onAnimationEnd(event) {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    DomHandler.removeClass(event.currentTarget, 'p-ink-active');
  }
  create() {
    let ink = this.renderer.createElement('span');
    this.renderer.addClass(ink, 'p-ink');
    this.renderer.appendChild(this.el.nativeElement, ink);
    if (!this.animationListener) {
      this.animationListener = this.renderer.listen(ink, 'animationend', this.onAnimationEnd.bind(this));
    }
  }
  remove() {
    let ink = this.getInk();
    if (ink) {
      this.mouseDownListener();
      this.animationListener();
      this.mouseDownListener = null;
      this.animationListener = null;
      DomHandler.removeElement(ink);
    }
  }
  ngOnDestroy() {
    if (this.config && this.config.ripple) {
      this.remove();
    }
  }
}
Ripple.ɵfac = function Ripple_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || Ripple)(i0.ɵɵdirectiveInject(DOCUMENT), i0.ɵɵdirectiveInject(PLATFORM_ID), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone), i0.ɵɵdirectiveInject(i1.PrimeNGConfig, 8));
};
Ripple.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: Ripple,
  selectors: [["", "pRipple", ""]],
  hostAttrs: [1, "p-ripple", "p-element"]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(Ripple, [{
    type: Directive,
    args: [{
      selector: '[pRipple]',
      host: {
        class: 'p-ripple p-element'
      }
    }]
  }], function () {
    return [{
      type: Document,
      decorators: [{
        type: Inject,
        args: [DOCUMENT]
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [PLATFORM_ID]
      }]
    }, {
      type: i0.Renderer2
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }, {
      type: i1.PrimeNGConfig,
      decorators: [{
        type: Optional
      }]
    }];
  }, null);
})();
class RippleModule {}
RippleModule.ɵfac = function RippleModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || RippleModule)();
};
RippleModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: RippleModule
});
RippleModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [CommonModule]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(RippleModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule],
      exports: [Ripple],
      declarations: [Ripple]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { Ripple, RippleModule };

