import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { FailuremodeService } from '../failuremode.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-failuremode-edit-pop-up',
  templateUrl: './failuremode-edit-pop-up.component.html',
  styleUrls: ['./failuremode-edit-pop-up.component.css']
})
export class FailuremodeEditPopUpComponent implements OnInit {

  item:any;
  text:any;
  isSaving:boolean = false;
  validationItem:any = {
      hasErrors: false,
      isActiveItem: true
  };

  failureModeEditModal !: FormGroup;
  pgCode:any;

  constructor(private formBuilder: FormBuilder,
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private adminFailureModeService: FailuremodeService) { }

  ngOnInit(): void {
    this.failureModeEditModal = this.formBuilder.group({
      itemName : new FormControl('', [Validators.required, Validators.maxLength(60)]),
      isActiveCheck : new FormControl()
    })
    this.item = this.config.data.pgCategoryItem;
    this.pgCode = this.config.data.pgCode;
    // console.log(this.config.data)
    // console.log(this.item);

    if(this.config.data.pgCategoryItem.id !== undefined){
      this.failureModeEditModal.patchValue({
        itemName : this.config.data.pgCategoryItem.name,
        isActiveCheck : this.config.data.pgCategoryItem.isActive
      })
    }


    this.failureModeEditModal.get('itemName').valueChanges.subscribe(val =>{
      this.validationItem.isActiveItem = true;
      let foundItem : any = [];
      foundItem = _.filter(this.item.duplicateScope, (sc)=> {
        return sc.name.toUpperCase() === val.toUpperCase();
      });

      if(foundItem.length){
        if(foundItem.length === 1){
          let process = foundItem[0];
          if(!process.isActive){
            this.validationItem.isActiveItem = false;
          }
        }
        this.validationItem.hasErrors = true;
      }
      else{
        this.validationItem.hasErrors = false;
      }
    })
  }

  cancel(){
    this.ref.close({result : 'none'});
  }

  save(){

    if (this.item.id !== undefined){
      this.item.isActive = this.failureModeEditModal.get('isActiveCheck').value;
      this.item.name = this.failureModeEditModal.get('itemName').value;
      this.adminFailureModeService.save(this.item, this.pgCode).subscribe((data: any)=>{
        if(!data.isError){
          this.ref.close({
            result: 'ok',
            model: this.item,
            results: data.queryResults
          })
        }
      })
    } else {
      this.item.name = this.failureModeEditModal.get('itemName').value;
      this.adminFailureModeService.addItem(this.item, this.pgCode).subscribe((data: any)=>{
        if (!data.isError){
          this.ref.close({
            result: 'ok',
            model: data.queryResults
          })
        }
      })
    }
  }

}
