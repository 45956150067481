<nav class="navbar navbar-default" style="padding: 10px; margin: 15px 5px !important;">
    <div class="row">
     <div class="col-sm-12 col-md-8 col-xl-8 col-lg-7 mobile-space1">
        <div class="form-group">
          <div class="input-group">
            <input [(ngModel)]="searchTerm" (ngModelChange)="onSearchValueChange()"
              class="form-control" name="search"
              autocomplete="off" type="text">
            <span class="input-group-addon button-set " style="background: #d9d9d9; margin: 2px; width: 44px;">
              <span class="hitachiicon-magnifying_glass"></span>
            </span>
          </div>
        </div>
    </div>
    <div class="col-sm-5 col-md-3 col-xl-3 col-lg-3 mobile-space1">
        <span class="input-group dashboard-sort-control button-set">
          <select class="form-control" [(ngModel)]="sortBy" (ngModelChange)="sortData($event)" style="width:100%">
            <option value="createDate">Change Time [A-Z]</option>
            <option value="-createDate">Change Time [Z-A]</option>
            <option value="creatorFullName">Created By [A-Z]</option>
            <option value="-creatorFullName">Created By [Z-A]</option>
          </select>    
        </span>
    
    </div>
    </div>
    </nav>
    
    <div *ngIf="!logsFiltered || logsFiltered?.length == 0">
        <p style="text-align: center; margin-top: 20px; margin-bottom: 20px; font-size: 24px;">{{'cc_actionitemlog_nothingtoshow' | translate }}</p>
    </div>

    <div *ngIf="logsFiltered?.length > 0">
        <div class="row" style="padding-left: 15px;">
            <div class="col-lg-3 col-xl-3 col-md-4 col-sm-5 alignCentreHeader">
                <h4>{{'cc_actionitemlog_creator' | translate }}</h4>
            </div>
            <div class="col-lg-7 col-xl-7 col-md-6 col-sm-7 alignCentreHeader" style="padding-left: 5px;">
                <h4>{{'cc_actionitemlog_content' | translate }}</h4>
            </div>
            <div class="col-lg-2 col-xl-2 col-md-2 alignCentreHeader">
                <h4>{{'cc_actionitemlog_createdate' | translate }}</h4>
            </div>
        </div>
        <div *ngFor="let log of logsFiltered | orderByActionLog : sortBy" class="gray" style="border-top: 0.1px solid #dddddd; background: #f1f1f1; padding-top: 5px !important; padding-bottom: 5px !important; margin-bottom: 0px !important; padding-right: 10px; cursor: pointer;">
            <div class="row gray underline underline-fix" (click)="log.expand = !log.expand;">
                <div class="col-lg-3 col-xl-3 col-md-4 col-sm-5">
                    <i *ngIf="!log.expand" class="fa fa-chevron-down"></i>
                    <i *ngIf="log.expand" class="fa fa-chevron-up"></i>
                    {{log.creatorEmail}}
                </div>
                <div class="col-lg-7 col-xl-7 col-md-6 col-sm-7">
                    <strong>{{log.subject}}</strong>
                    
                </div>
                <div class="col-lg-2 col-xl-2 col-md-2" >
                    {{log?.createDate | date:'YYYY-MM-dd HH:mm:ss' }}
                </div>
            </div>
            <div class="row" *ngIf="log.expand" style="background: white; padding-left: 25px;">
                <div class="col-lg-12" style="padding-left: 10px; padding-right: 10px;">
                    <p [innerHTML]="log.body"></p>
                </div>
            </div>
        </div>
    </div>

