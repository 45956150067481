<div>
    <div class="pgCategoryProcessOwner" style="z-index:10">
        <ul class="list-group"  >
            <li class="list-group-item align-font-px" *ngFor="let po of PGRCACatData.processOwner">
                <span class="displayName"
                [autoClose]="true"
                (resize)="(true)"
                container="body"
                [ngbPopover]="popContent"
                [popoverTitle]="popoverTitle"
                [placement]="'right'"
                (click)="popOver(po.displayName,po.geid)"
                >{{po.displayName}}</span>
                <ng-template #popoverTitle>
                    <span class="popover-title">
                      {{this.addressData?.name}} ({{this.addressData?.id}})
                    </span>
                  </ng-template>
                  <ng-template #popContent style="position: relative">
                    <div>
                        <div>
                            <small>
                                {{this.addressData.address.countryCode}},{{this.addressData.address.city}}{{this.addressData.address.address}}
                            </small>
                            <span
                            ><a
                            href="https://maps.google.pl/?q={{ this.addressData.address.countryCode}}, {{ this.addressData.address.city}}, {{this.addressData.address.address}}"
                            target="_blank"
                            class="gray-link">
                            <i class="hitachiicon-location"> </i> </a
                            ></span>
                        </div>
                        <div>
                            <small>
                                <i class="glyphicon-mail"></i> <a href="mailto:{{ this.addressData?.email }}" class="admin-icon-a">{{this.addressData?.email}}</a>
                            </small>
                        </div>
                        <div>
                            <small>{{this.addressData?.phone}}</small>
                        </div>
                    </div>
                  </ng-template>
                <span *ngIf="po.pg" class="pgrcaBadge" style="float: right; margin-right:5px"><p class="badges mx-2 mr-2">PG: {{po.pg}}</p></span>
                <span *ngIf="po.bu" class="pgrcaBadge" style="float: right; margin-right:5px"><p class="badges mx-2 mr-2">BU: {{po.bu}}</p></span>
                <span *ngIf="po.div" class="pgrcaBadge" style="float: right; margin-right:5px"><p class="badges mx-2 mr-2">Business:{{po.div}}</p></span>
    
            </li>
        </ul>
        <div class="modal-footer"> 
            <button  type="button" class="widgetButtoSmall" (click)="onClose()">OK</button>
        </div>
    </div>
</div>


