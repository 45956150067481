import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormControlName,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ResolvingUnitServiceService } from 'src/app/services/resolving-unit-service.service';
import * as _ from 'lodash';
import { PgrcaAdminServiceService } from '../pgrca-admin-service.service';

@Component({
  selector: 'app-pgrca-cat-add-pop-up',
  templateUrl: './pgrca-cat-add-pop-up.component.html',
  styleUrls: ['./pgrca-cat-add-pop-up.component.css'],
})
export class PgrcaCatAddPopUpComponent implements OnInit {
  pgrcaAddPopForm!: FormGroup;
  PGRCACatData: any;
  pgrcaCatDataContent: any;
  addContentdata: any;
  addData: any;
  submitted: boolean = false;
  nodeChild: any;
  validationItem = {
    hasErrors: false,
    isActiveItem: true,
  };
  constructor(
    private dialogRef: DynamicDialogRef,
    private fb: FormBuilder,
    private ir: ResolvingUnitServiceService,
    private pgrcaAdminAddService: PgrcaAdminServiceService,
    public config: DynamicDialogConfig
  ) {
    this.PGRCACatData = this.config.data;
    // console.log(this.PGRCACatData);
    this.pgrcaCatDataContent = this.PGRCACatData.content.children;
    // console.log('add data config', this.PGRCACatData);
  }

  ngOnInit(): void {
    this.pgrcaAddPopForm = this.fb.group({
      addName: new FormControl('', [
        Validators.required,
        Validators.maxLength(100),
      ]),
    });
    this.pgrcaAdminAddService
      .getDataForAdminPgrcaCatSubChild(
        this.PGRCACatData.pgValue,
        this.PGRCACatData.content.id
      )
      .subscribe((res) => {
        this.nodeChild = res;
        // console.log('nodeChild Data', this.nodeChild);
        for (let i = 0; i < this.nodeChild.length; i++) {
          this.nodeChild[i].duplicateScope = res.map((a) => {
            return { id: a.id, isActive: a.isActive, name: a.name };
          });
        }
      });
    // debugger;
    this.pgrcaAddPopForm.get('addName').valueChanges.subscribe((val) => {
      this.validationItem.isActiveItem = true;
      let foundItem = _.filter(this.nodeChild, (sc) => {
        return sc.name.toUpperCase() === val.toUpperCase();
      });
      if (foundItem.length) {
        if (foundItem.length === 1) {
          let process = foundItem[0];
          if (!process.isActive) {
            this.validationItem.isActiveItem = false;
          }
        }
        this.validationItem.hasErrors = true;
      } else {
        this.validationItem.hasErrors = false;
      }
    });
  }
  // get f() {
  //   return this.pgrcaAddPopForm.controls;
  // }
  get f(): { [key: string]: AbstractControl } {
    return this.pgrcaAddPopForm.controls;
  }
  onSubmit() {
    this.submitted = true;

    if (this.pgrcaAddPopForm.invalid) {
      return;
    }
    // debugger
    // let dublicateScopeValues = [];
    let payloadData = {
      categoryName: this.PGRCACatData.content.name,
      isActive: this.PGRCACatData.content.isActive,
      duplicateScope: this.nodeChild,
      parentId: this.PGRCACatData.content.id,
      hasChildren: this.PGRCACatData.content.hasChildren,
      children: [],
      name: this.pgrcaAddPopForm.controls['addName'].value,
    };
    // console.log('---->', this.PGRCACatData);
    // for (let i = 0; i < this.pgrcaCatDataContent.length; i++) {
    //   this.addContentdata = this.pgrcaCatDataContent[i];
    //   console.log('for loop data', this.addContentdata);
    // payloadData.duplicateScope.push({
    /* id: this.addContentdata.id,
        parentId: this.addContentdata.parentId,
        name: this.addContentdata.name,
        isExpanded: this.addContentdata.isExpanded,
        hasChildren: this.addContentdata.hasChildren,
        areChildrenLoaded: this.addContentdata.areChildrenLoaded,
        shouldBeExpanded: this.addContentdata.shouldBeExpanded,
        isActive: this.addContentdata.isActive,
        isAdditionalActionRequired:
          this.addContentdata.isAdditionalActionRequired,
        validation: this.addContentdata.validation,
        isGeneric: this.addContentdata.isGeneric,
        tag: {
          id: this.addContentdata.tag.id,
          pgId: this.addContentdata.tag.pgId,
          name: this.addContentdata.tag.name,
          parentId: this.addContentdata.tag.parentId,
          isActive: this.addContentdata.tag.isActive,
          productGroupCode: this.addContentdata.tag.productGroupCode,
          parents: this.addContentdata.tag.parents,
        },
        children: [],
        duplicateScope: [
          {
            name: this.addContentdata.parent.children.name,
            isActive: this.addContentdata.parent.children.isActive,
            id: this.addContentdata.parent.children.id,
          },
        ], */
    // });
    // console.log('payload data', payloadData);
    // if (i == this.pgrcaCatDataContent.length - 1) {
    const pg = this.PGRCACatData.pgValue;
    this.pgrcaAdminAddService.addChildPgrcaCat(pg, payloadData).subscribe((a) => {
      this.addData = a;
      this.dialogRef.close(a);
    });
    // }
    // if(this.addData != ''){
    //   this.dialogRef.close(this.addData);
    // }
    // }

    // if (this.addData != '') {
    //   this.dialogRef.close(this.addData);
    // }
    /* console.log('---->', this.PGRCACatData);
    for (let i = 0; i < this.pgrcaCatDataContent.length; i++) {
      this.addContentdata = this.pgrcaCatDataContent[i];
      console.log('for loop data', this.addContentdata);
    }

      let payloadData ={
        "categoryName": this.PGRCACatData.content.name,
        "isActive": this.PGRCACatData.content.isActive,
        "duplicateScope":
      [
        {
            "id": this.addContentdata.id,
            "parentId": this.addContentdata.parentId,
            "name": this.addContentdata.name,
            "isExpanded": this.addContentdata.isExpanded,
            "hasChildren": this.addContentdata.hasChildren,
            "areChildrenLoaded": this.addContentdata.areChildrenLoaded,
            "shouldBeExpanded": this.addContentdata.shouldBeExpanded,
            "isActive": this.addContentdata.isActive,
            "isAdditionalActionRequired": this.addContentdata.isAdditionalActionRequired,
            "validation": this.addContentdata.validation,
            "isGeneric": this.addContentdata.isGeneric,
            "tag": {
                "id": this.addContentdata.tag.id,
                "pgId": this.addContentdata.tag.pgId,
                "name": this.addContentdata.tag.name,
                "parentId": this.addContentdata.tag.parentId,
                "isActive": this.addContentdata.tag.isActive,
                "productGroupCode": this.addContentdata.tag.productGroupCode,
                "parents": this.addContentdata.tag.parents
            },
            "children": [],
            "duplicateScope": [
                {
                    "name": this.addContentdata.parent.children[0].name,
                    "isActive": this.addContentdata.parent.children[0].isActive,
                    "id": this.addContentdata.parent.children[0].id
                },
            ]
          }
      ],

      "parentId": this.PGRCACatData.content.id,
      "hasChildren": this.PGRCACatData.content.hasChildren,
      // "children": [this.PGRCACatData.content.children],
      "children": [],
      "name": this.pgrcaAddPopForm.controls['addName'].value
    }

    console.log('payloadData', payloadData);
    const pg=this.PGRCACatData.pgValue;
    this.ir.addChildPgrcaCat(pg,payloadData).subscribe((a)=>{
      console.log('post data',a)
      this.addData=a
      return a
    }) */
  }
  close() {
    this.dialogRef.close();
  }
}
