<div class="inner-heading-wrapper">
  <h6 class="inner-heading1">{{ "ComplaintForm_OriginalRatingScoringMatrixHeader" | translate }} </h6>
</div>

<div class="container-fluid originalMainDiv remove-px">
  <form [formGroup]="ratingForm">

    <div class="row" *ngIf="complaintCreatedDate<ncrLiveDate">
      <div class="col-md-2 col-sm-3 ms-2 remove-px remove-ms">
        <h6 class="inner-heading1 bg-transparent remove-px">{{ "Excel_weekly_severity" | translate }}</h6>
      </div>
    </div>

    <div class="row mb-2">
      <div class="col-md-2 col-sm-3 ps-0 pe-0 ratingLabel">
        <label>{{ availabiltyLabel }}</label>
      </div>
      <div class="col-md-9 col-sm-9 remove-px">
        <p-dropdown [options]="availabilityRatingItems" formControlName="availabilityRating" optionValue="value"
          optionLabel="text" (onChange)="calculateRpnAndImpactRating()"></p-dropdown>
      </div>
    </div>

    <div class="row mb-2">
      <div class="col-md-2 col-sm-3 ps-0 pe-0">
        <label>{{FunctionalityLabel}}</label>
      </div>
      <div class="col-md-9 col-sm-9 remove-px">
        <p-dropdown [options]="functionalityRatingItems" formControlName="functionalityRating" optionValue="value"
          optionLabel="text" (onChange)="calculateRpnAndImpactRating()"></p-dropdown>
      </div>
    </div>

    <div class="row mb-2">
      <div class="col-md-2 col-sm-3 ps-0 pe-0">
        <label>{{On_Time_DeliveryLabel}}</label>
      </div>
      <div class="col-md-9 col-sm-9 remove-px">
        <p-dropdown [options]="onTimeDeliveryRatingItems" formControlName="onTimeDeliveryRating" optionValue="value"
          optionLabel="text" (onChange)="calculateRpnAndImpactRating()"></p-dropdown>
      </div>
    </div>


    <div class="row mb-2">
      <div class="col-md-2 col-sm-3 ps-0 pe-0">
        <label>{{Cyber_SecurityLabel}}</label>
      </div>
      <div class="col-md-9 col-sm-9 remove-px">
        <p-dropdown [options]="cyberSecurityRatingItems" formControlName="cyberSecurityRating" optionValue="value"
          optionLabel="text" (onChange)="calculateRpnAndImpactRating()"></p-dropdown>
      </div>
    </div>


    <div class="row mb-2">
      <div class="col-md-2 col-sm-3 ps-0 pe-0">
        <label>{{Product_ComplianceLabel}}</label>
      </div>
      <div class="col-md-9 col-sm-9 remove-px">
        <p-dropdown [options]="productComplianceRatingItems" formControlName="productComplianceRating"
          optionValue="value" optionLabel="text" (onChange)="calculateRpnAndImpactRating()"></p-dropdown>
      </div>
    </div>


    <div class="row mb-2">
      <div class="col-md-2 col-sm-3 ps-0 pe-0">
        <label>{{OHS_RiskLabel}}</label>
      </div>
      <div class="col-md-9 col-sm-9 remove-px">
        <p-dropdown [options]="ohsRiskRatingItems" formControlName="ohsRiskRating" optionValue="value"
          optionLabel="text" (onChange)="calculateRpnAndImpactRating()"></p-dropdown>
      </div>
    </div>


    <div class="row mb-2">
      <div class="col-md-2 col-sm-3 ps-0 pe-0">
        <label>{{MarginLabel}}</label>
      </div>
      <div class="col-md-9 col-sm-9 remove-px">
        <p-dropdown [options]="marginRatingItems" formControlName="marginRating" optionValue="value" optionLabel="text"
          (onChange)="calculateRpnAndImpactRating()"></p-dropdown>
      </div>
    </div>


    <div class="row mb-2">
      <div class="col-md-2 col-sm-3 ps-0 pe-0">
        <label>{{ReputationLabel}}</label>
      </div>
      <div class="col-md-9 col-sm-9 remove-px">
        <p-dropdown [options]="reputationRatingItems" formControlName="reputationRating" optionValue="value"
          optionLabel="text" (onChange)="calculateRpnAndImpactRating()"></p-dropdown>
      </div>
    </div>


    <div class="row mb-2">
      <div class="col-md-2 col-sm-3 ps-0 pe-0">
        <label>{{Mass_mediaLabel}}</label>
      </div>
      <div class="col-md-9 col-sm-9 remove-px">
        <p-dropdown [options]="massMediaRatingItems" formControlName="massMediaRating" optionValue="value"
          optionLabel="text" (onChange)="calculateRpnAndImpactRating()"></p-dropdown>
      </div>
    </div>


    <div class="row mb-2">
      <div class="col-md-2 col-sm-3 ps-0 pe-0">
        <label>{{Design_changeLabel}}</label>
      </div>
      <div class="col-md-9 col-sm-9 remove-px">
        <p-dropdown [options]="designChangeRatingItems" formControlName="designChangeRating" optionValue="value"
          optionLabel="text" (onChange)="calculateRpnAndImpactRating()"></p-dropdown>
      </div>
    </div>

    <div class="row" *ngIf="complaintCreatedDate<ncrLiveDate">
      <div class="col-md-2 col-sm-3 ms-2 remove-ms remove-px">
        <h6 class="inner-heading1 bg-transparent remove-ms remove-px"> Occurrence</h6>
      </div>
    </div>

    <div class="row mb-2">
      <div class="col-md-2 col-sm-3 ps-0 pe-0">
        <label>{{InstancesLabel}}</label>
      </div>
      <div class="col-md-9 col-sm-9 remove-px">
        <p-dropdown [options]="instancesRatingItems" formControlName="instancesRating" optionValue="value"
          optionLabel="text" (onChange)="calculateRpnAndImpactRating()"></p-dropdown>
      </div>
    </div>


    <div class="row mb-2">
      <div class="col-md-2 col-sm-3 ps-0 pe-0">
        <label>{{ResolutionLabel}}</label>
      </div>
      <div class="col-md-9 col-sm-9 remove-px">
        <p-dropdown [options]="resolutionRatingItems" formControlName="resolutionRating" optionValue="value"
          optionLabel="text" (onChange)="calculateRpnAndImpactRating()"></p-dropdown>
      </div>
    </div>


    <div class="row mb-2">
      <div class="col-md-2 col-sm-3 ps-0 pe-0">
        <label>{{Affected_customerLabel}}</label>
      </div>
      <div class="col-md-9 col-sm-9 remove-px">
        <p-dropdown [options]="affectedCustomerRatingItems" formControlName="affectedCustomerRating" optionValue="value"
          optionLabel="text" (onChange)="calculateRpnAndImpactRating()"></p-dropdown>
      </div>
    </div>


    <div class="row" *ngIf="complaintCreatedDate<ncrLiveDate" >
      <div class="col-md-2 col-sm-3 ms-2 remove-ms remove-px">
        <h6 class="inner-heading1 bg-transparent remove-ms remove-px">Detection</h6>
      </div>
    </div>

    <div class="row mb-2">
      <div class="col-md-2 col-sm-3 ps-0 pe-0">
        <label>{{ControlLabel}}</label>
      </div>
      <div class="col-md-9 col-sm-9 remove-px">
        <p-dropdown [options]="controlRatingItems" formControlName="controlRating" optionValue="value"
          optionLabel="text" (onChange)="calculateRpnAndImpactRating()"></p-dropdown>
      </div>
    </div>


    <!-- <hr class="form-group" /> -->
    <div class="row form-group mb-2">
      <label class="col-md-2 col-sm-3 control-label clear-left-padding pe-0 text-right ratng-label">
        {{'Rating result' | translate }}
      </label>
      <div class="col-md-4 col-sm-5 clear-right-padding remove-px ratinginput">
        <input type="hidden" formControlName="HasHightImpactOnABB" />
        <input type="hidden" formControlName="classId">
        <input type="hidden" class="form-control raisedInput disabled" formControlName="classValue" readonly />
        <input type="text" [hidden]="showUnconfirmedRating" class="form-control raisedInput disabled"
          disabled="disabled" value="Unconfirmed" />
        <input type="text" [hidden]="!showUnconfirmedRating" class="form-control raisedInput disabled"
          formControlName="impactRating" readonly />
      </div>
    </div>
    <!-- <div class="row form-group mb-1">
      <label class="col-md-2 col-sm-3 control-label clear-left-padding  pe-0 text-right">
      </label>
      <div class="col-md-4 col-sm-5 clear-right-padding">
        <input type="text" class="form-control raisedInput disabled" formControlName="impactRating"
          readonly />
      </div>
    </div> -->
    <!--    <div class="row form-group mb-1">-->
    <!--      <label class="col-md-2 col-sm-3 control-label clear-left-padding  pe-0 text-right">-->
    <!--        {{'rptv_rpn' | translate}}-->
    <!--      </label>-->
    <div class="col-md-4 col-sm-5 clear-right-padding remove-px">
      <input type="hidden" class="form-control raisedInput" name="rpn" formControlName="rpn" readonly />
    </div>
    <!--    </div>-->

    <div class="row">
      <div class="col-md-2 col-sm-3 control-label clear-left-padding pe-0 text-right remove-ps">
        <label>Justification </label>
      </div>
      <div class="col-md-4 col-sm-5 justification-textarea remove-px">
        <textarea class="form-control disabled" name="" id="" cols="20" rows="2" readonly></textarea>
        <input type="hidden" formControlName="IsComplaintDescriptionSectionMandatory">
      </div>
    </div>

  </form>
</div>

<!-- <p-toast position="top-right"></p-toast> -->
