<form [formGroup]="hubStructureForm">
    <div class="structure-pop">
        <strong class="textalign">{{'admin_hub_change_usage_modal_body_pt1'|translate}} {{this.receivingbuCode.buCode}} 
            <span *ngIf="!this.changesToPg">{{'admin_hub_change_usage_modal_body_pt2_topg'|translate}}</span>
            <span *ngIf="this.changesToPg">{{'admin_hub_change_usage_modal_body_pt2_tobu'|translate}}</span>
        </strong>

        <div class="mt-2 d-grid gap-2 d-md-flex justify-content-md-end structureBtnDivHub">
            <button class="input widgetButtonLarge" type="button" (click)="onClose()">{{'ButtonCancel' | translate }}</button>
            <button class="input widgetButtonLarge mx-2" type="button" (click)="confirm()">{{'ButtonReopenIssueComplaint' | translate }}
            </button>
        </div>
    </div>
</form>