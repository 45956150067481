<div class="deleteProcessOwner">
<div class="row">
<div class="row deleteProcessOwnerFont">
      <strong class="control-label">{{'podelete_question_part_mod_lbl'|translate}}</strong>
</div>
<div class="row deleteProcessOwnerFont">
      <span><app-po-details [poScope]="poInfo"></app-po-details></span>
</div>
</div>
<div class="modal-footer my-2">
  <!-- <div class="col-sm-9"></div>
  <div class="col-sm-3"> -->
  <button type="button" class="widgetButtoSmall" (click)="cancel()">{{'podelete_mod_cancel_btn'|translate}}</button>
  <button type="button" class="widgetButtoSmall ms-1" (click)="ok()">{{'podelete_mod_ok_btn'|translate}}</button>
</div>
</div>