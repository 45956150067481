import { Component, OnInit, Input } from '@angular/core';
import { AdminFailuremodeComponent } from '../admin-failuremode/admin-failuremode.component';
import { FailuremodeService } from '../failuremode.service';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ToasterserviceService } from 'src/app/services/toasterservice.service';
import { FailuremodeEditmodalComponent } from '../failuremode-editmodal/failuremode-editmodal.component';
import { FailuremodeDeactivatemodalComponent } from '../failuremode-deactivatemodal/failuremode-deactivatemodal.component';
import { AdminEditProcessStepsModalComponent } from '../../processSteps/admin-edit-process-steps-modal/admin-edit-process-steps-modal.component';
import { FailuremodeEditPopUpComponent } from '../failuremode-edit-pop-up/failuremode-edit-pop-up.component';

@Component({
  selector: 'app-failuremode-treeitemmebmer',
  templateUrl: './failuremode-treeitemmebmer.component.html',
  styleUrls: ['./failuremode-treeitemmebmer.component.css']
})
export class FailuremodeTreeitemmebmerComponent implements OnInit {

  @Input() member : any = [];
  @Input() allowActions: any;
  @Input() isSearching: any;
  @Input() lookup: any;
  @Input() level: any;
  @Input() message: any;
  @Input() pgCodeValue: any;

  MAX_CHILD_LEVEL = 6;
  AddRunningCount = 0;
  EditRunningCount = 0;

  constructor(private failureModeComp: AdminFailuremodeComponent,
    private failureModeService: FailuremodeService,
    public ref: DynamicDialogRef,
    public dialogService: DialogService,
    public toasterService: ToasterserviceService) { }

  ngOnInit(): void {
    // if(this.level >= 2)
    //   this.allowActions = true
    //this.pgCodeValue = this.failureModeComp.selected.pg; //need to update this
    this.pgCodeValue = this.failureModeComp.selected.pg;
  }

  clearAddRequestCount(){
    this.AddRunningCount = 0;
    this.EditRunningCount = 0;
  }

  expand(action){
    this.member.isExpanded = !action;

    if (true === this.member.hasChildren && false === this.member.areChildrenLoaded  && this.member.id != -1){
      this.failureModeComp.blockPgcategoryUI();

      this.failureModeService.getChildren(this.pgCodeValue, this.member.id).subscribe((data)=>{
        this.member.areChildrenLoaded = true;
        this.member.children = data;

        let duplicated :any = [];

        data.forEach(obj => {
          duplicated.push({
            name: obj.name,
            isActive: obj.isActive,
            id: obj.id
          });
        });

        this.member.children.forEach(child => {
          child.duplicateScope = duplicated;
        });

        this.failureModeComp.unblockPgCategoryUI();
      },
      ()=>{
        this.failureModeComp.unblockPgCategoryUI();
      })
    }
  }

  addFm(item){
    this.failureModeComp.blockPgcategoryUI();
    this.AddRunningCount += 1;
    setTimeout(() => {
      this.setUpAddModal(item);
    }, 1);
    setTimeout(() => {
      this.clearAddRequestCount();
    }, 1500, false);
  }

  setUpAddModal(item){
    if (this.AddRunningCount > 1) {
      this.failureModeComp.unblockPgCategoryUI();
      return;
    }

    if (this.level < this.MAX_CHILD_LEVEL){
      this.failureModeService.getChildren(this.pgCodeValue, this.member.id).subscribe((data: any)=>{
        this.member.areChildrenLoaded = true;
        this.member.children = data;

        if(data.length != 0){
        let duplicated : any = [];
        data.forEach(obj => {
          duplicated.push({
            name: obj.name,
            isActive: obj.isActive,
            id: obj.id
          });
        });

        this.member.children.forEach(child => {
          child.duplicateScope = duplicated;
        });
        }else{
          //duplicated = [];
          this.member.children.duplicateScope = []
        }

        this.openAddModal(this.member);

        this.failureModeComp.unblockPgCategoryUI();
      },
      ()=>{
        this.failureModeComp.unblockPgCategoryUI();
      })
    }
  }

  openAddModal(item){
    this.failureModeComp.unblockPgCategoryUI();

    let itemToAdd :any = {
      categoryName: item.name,
      isActive: true,
      duplicateScope: item.children,
      parentId: item.id,
      hasChildren: false,
      children: [],
      tag: { isChildAllowed: true }
    };

    this.ref = this.dialogService.open(FailuremodeEditPopUpComponent, {
      header: "Add child to '" + itemToAdd.categoryName + "' item",
      width: '50%',
      styleClass: 'editFailureModeSteps',
      position: 'top',
      data: {
        pgCategoryItem: itemToAdd,
        pgCode : this.pgCodeValue
      }
    })

    this.AddRunningCount = 0;

    this.ref.onClose.subscribe((data)=>{
      if(data?.result === 'ok'){
        this.member.hasChildren = true;
        this.member.isExpanded = true;
        this.member.children.push(data.model);

        this.failureModeComp.refreshNodes(data.results);
        this.toasterService.showSuccess('Node has been added successfuly.');
      }
      // else{
      //   this.toasterService.showError('Cannot add node.');
      // }
    })
  }

  edit(item){
    this.failureModeComp.blockPgcategoryUI();

    this.EditRunningCount += 1;
    setTimeout(() => {
      this.setUpEditModal(item)
    }, 1);
    setTimeout(() => {
      this.clearAddRequestCount()
    }, 1500, false);
  }

  setUpEditModal(item){
    if (this.EditRunningCount > 1) {
      this.failureModeComp.unblockPgCategoryUI();
      return;
    }

    this.failureModeService.getChildren(this.pgCodeValue, item.parentId).subscribe((data)=>{
      item.duplicateScope = data;
      this.openEditModal(item);
    })
  }

  remove(item){
    this.ref = this.dialogService.open(FailuremodeDeactivatemodalComponent, {
      header: 'Confirmation',
      styleClass: 'deactive-failureMode-modal',
      width: '40%',
      position: 'top',
      data: {
        pgCategoryItem: item,
        pgCode: this.pgCodeValue
      }
    })

    this.ref.onClose.subscribe((data)=>{
      if(data?.result === 'ok'){
        this.member.isActive = data.model.isActive;
        this.member.name = data.model.name;

        this.failureModeComp.refreshNodes(data.results);

        this.toasterService.showSuccess('Node has been updated.');
      }
    })
  }

  openEditModal(item){
    this.failureModeComp.unblockPgCategoryUI();

    this.failureModeComp.refreshNodes(item);

    let itemToEdit = item;

    this.ref = this.dialogService.open(FailuremodeEditPopUpComponent, {
      header: 'Edit item',
      styleClass: 'editFailureModeSteps',
      width: '50%',
      position: 'top',
      data: {
        pgCategoryItem: itemToEdit,
        pgCode: this.pgCodeValue
      }
    })

    this.EditRunningCount = 0;
    this.ref.onClose.subscribe((data)=>{
      if(data?.result === 'ok'){
        this.member.isActive = data.model.isActive;
        this.member.name = data.model.name;

        this.failureModeComp.refreshNodes(data.results);

        this.toasterService.showSuccess('Node has been updated.');
      }
    })
  }

}