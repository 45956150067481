import { Component, OnInit } from '@angular/core';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { LocalStorageServiceService } from 'src/app/services/local-storage-service.service';
import { ToasterserviceService } from 'src/app/services/toasterservice.service';

@Component({
  selector: 'app-po-settings',
  templateUrl: './po-settings.component.html',
  styleUrls: ['./po-settings.component.css']
})
export class PoSettingsComponent implements OnInit {

  canSave:boolean = true;

  isDirty:boolean = false;
  pageSize:number = this.lclStorageService.getFromLocalStorage("poGroupsPageSize") || 10;
  constructor(private lclStorageService:LocalStorageServiceService,
    private readonly dialogRef: DynamicDialogRef,
    private toaster:ToasterserviceService ) { }

  ngOnInit(): void {
  }

  cancel() {
    this.dialogRef.close();
  };

  updatePageSize(pageSize) {
      this.isDirty = true;
      this.pageSize = pageSize;
  };


   save() {
      if (this.isDirty) {
          this.lclStorageService.addToLocalStorage("poGroupsPageSize", this.pageSize, { months: 6 });
          //this.toaster.showSuccess('Settings saved');
      }

      this.dialogRef.close(this.pageSize);

  };

}
