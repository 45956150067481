
<td class="col-sm-2 row-gap-top-inprogress">
  <label class="label-bold">{{item.divisionName}}</label>
</td>
<td class="col-sm-1">
  <select [disabled]="!isInEditMode" class="form-control escalation-settings-control"
          [(ngModel)]="item.highImpactLvl1">
      <option *ngFor="let h of hours"
              value="{{h}}">
          {{h}}
      </option>
  </select>
</td>
<td class="col-sm-1">
  <select [disabled]="!isInEditMode" class="form-control escalation-settings-control"
          [(ngModel)]="item.highImpactLvl2">
      <option *ngFor="let h of hours"
              value="{{h}}">
          {{h}}
      </option>
  </select>
</td>
<td class="col-sm-1">
  <select [disabled]="!isInEditMode" class="form-control escalation-settings-control"
          [(ngModel)]="item.normalLvl1">
      <option *ngFor="let h of hours"
              value="{{h}}">
          {{h}}
      </option>
  </select>
</td>
<td class="col-sm-1">
  <select [disabled]="!isInEditMode" class="form-control escalation-settings-control"
          [(ngModel)]="item.normalLvl2">
      <option *ngFor="let h of hours"
              value="{{h}}">
          {{h}}
      </option>
  </select>
</td>
<td class="col-sm-4 admin-inProgress-section ">
  <button *ngIf="!isInEditMode && allowEdit" class="btn widgetButtonSmall" (click)="edit(item)">Edit</button>
  <button *ngIf="isInEditMode" class="btn widgetButtonSmall"  (click)="save(item)">Save</button>
  <button *ngIf="isInEditMode" class="btn widgetButtonSmall cancel-row-gap"  (click)="cancel()">Cancel</button>
</td>
