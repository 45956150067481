import { Injectable } from '@angular/core';
import { PermissionCode, RoleCode } from '../enum';
import { ComplaintStatuses } from './dashboard.service';
import { AuthorizationService } from './authorization.service';

@Injectable({
  providedIn: 'root'
})

export class AuthorizationMiddleWareService {

  enumPermission = PermissionCode;
  enumRoleCode = RoleCode;
  enumComplaintStatuses: any = ComplaintStatuses;
  setPermission: any = {
    issueCaptureForm: {}, issueDescriptionForm: {}, ratingForm: {},
    additionalSubjectForm: {}, issueAssignmentForm: {}, issueResolutionForm: {},
    issueClosedForm: {}, effectivenessForm: {}
  };
  constructor(private authorizationService: AuthorizationService) { }

  checkSetPermissionData() {
    return this.setPermission;
  }

  commonMethodSetPermission(userAccessData, userRole, complainStatus = [], permissionCode = []) {
    //Issue Capture Form Controls
    this.setPermission['issueCaptureForm']['PrimaryComplaintOwner'] = this.authorizationService.checkPermission([userRole], complainStatus['PrimaryComplaintOwner'], permissionCode['PrimaryComplaintOwner'], userAccessData);
    this.setPermission['issueCaptureForm']['substitute'] = this.authorizationService.checkPermission([userRole], complainStatus['substitute'], permissionCode['substitute'], userAccessData);
    this.setPermission['issueCaptureForm']['supervisor'] = this.authorizationService.checkPermission([userRole], complainStatus['supervisor'], permissionCode['supervisor'], userAccessData);
    this.setPermission['issueCaptureForm']['CustomerType'] = this.authorizationService.checkPermission([userRole], complainStatus['CustomerType'], permissionCode['CustomerType'], userAccessData);
    this.setPermission['issueCaptureForm']['customerNameData'] = this.authorizationService.checkPermission([userRole], complainStatus['customerNameData'], permissionCode['customerNameData'], userAccessData);
    this.setPermission['issueCaptureForm']['affectedCustomer'] = this.authorizationService.checkPermission([userRole], complainStatus['affectedCustomer'], permissionCode['affectedCustomer'], userAccessData);
    this.setPermission['issueCaptureForm']['affectedCustomerHidden'] = this.authorizationService.checkPermission([userRole], complainStatus['affectedCustomerHidden'], permissionCode['affectedCustomerHidden'], userAccessData);
    this.setPermission['issueCaptureForm']['Customer'] = this.authorizationService.checkPermission([userRole], complainStatus['Customer'], permissionCode['Customer'], userAccessData);
    this.setPermission['issueCaptureForm']['customerNameHidden'] = this.authorizationService.checkPermission([userRole], complainStatus['customerNameHidden'], permissionCode['customerNameHidden'], userAccessData);
    this.setPermission['issueCaptureForm']['CustomerContact'] = this.authorizationService.checkPermission([userRole], complainStatus['CustomerContact'], permissionCode['CustomerContact'], userAccessData);
    this.setPermission['issueCaptureForm']['customerContactNameHidden'] = this.authorizationService.checkPermission([userRole], complainStatus['customerContactNameHidden'], permissionCode['customerContactNameHidden'], userAccessData);
    this.setPermission['issueCaptureForm']['Subject'] = this.authorizationService.checkPermission([userRole], complainStatus['Subject'], permissionCode['Subject'], userAccessData);

    //Issue Description Form Controls
    this.setPermission['issueDescriptionForm']['Reason'] = this.authorizationService.checkPermission([userRole], complainStatus['Reason'], permissionCode['Reason'], userAccessData);
    this.setPermission['issueDescriptionForm']['WhatIssueDescription'] = this.authorizationService.checkPermission([userRole], complainStatus['WhatIssueDescription'], permissionCode['WhatIssueDescription'], userAccessData);
    this.setPermission['issueDescriptionForm']['whyIssueDescription'] = this.authorizationService.checkPermission([userRole], complainStatus['whyIssueDescription'], permissionCode['whyIssueDescription'], userAccessData);
    this.setPermission['issueDescriptionForm']['howIssueDescription'] = this.authorizationService.checkPermission([userRole], complainStatus['howIssueDescription'], permissionCode['howIssueDescription'], userAccessData);
    this.setPermission['issueDescriptionForm']['issueStep'] = this.authorizationService.checkPermission([userRole], complainStatus['issueStep'], permissionCode['issueStep'], userAccessData);
    this.setPermission['issueDescriptionForm']['inWhichProcessStep'] = this.authorizationService.checkPermission([userRole], complainStatus['inWhichProcessStep'], permissionCode['inWhichProcessStep'], userAccessData);
    this.setPermission['issueDescriptionForm']['whereIssueDescription'] = this.authorizationService.checkPermission([userRole], complainStatus['whereIssueDescription'], permissionCode['whereIssueDescription'], userAccessData);
    this.setPermission['issueDescriptionForm']['WorkCentreandPlant'] = this.authorizationService.checkPermission([userRole], complainStatus['WorkCentreandPlant'], permissionCode['WorkCentreandPlant'], userAccessData);
    this.setPermission['issueDescriptionForm']['whenIssueDescription'] = this.authorizationService.checkPermission([userRole], complainStatus['whenIssueDescription'], permissionCode['whenIssueDescription'], userAccessData);
    this.setPermission['issueDescriptionForm']['customerExpectedResolutionDescription'] = this.authorizationService.checkPermission([userRole], complainStatus['customerExpectedResolutionDescription'], permissionCode['customerExpectedResolutionDescription'], userAccessData);
    this.setPermission['issueDescriptionForm']['uploadedAttachments'] = this.authorizationService.checkPermission([userRole], complainStatus['uploadedAttachments'], permissionCode['uploadedAttachments'], userAccessData);
    this.setPermission['issueDescriptionForm']['ContainmentActionsId'] = this.authorizationService.checkPermission([userRole], complainStatus['ContainmentActionsId'], permissionCode['ContainmentActionsId'], userAccessData);
    this.setPermission['issueDescriptionForm']['IsContainmentActionRequired'] = this.authorizationService.checkPermission([userRole], complainStatus['IsContainmentActionRequired'], permissionCode['IsContainmentActionRequired'], userAccessData);
    this.setPermission['issueDescriptionForm']['deadline'] = this.authorizationService.checkPermission([userRole], complainStatus['deadline'], permissionCode['deadline'], userAccessData);
    this.setPermission['issueDescriptionForm']['ContainmentActionDescription'] = this.authorizationService.checkPermission([userRole], complainStatus['ContainmentActionDescription'], permissionCode['ContainmentActionDescription'], userAccessData);
    this.setPermission['issueDescriptionForm']['containmentExecutedDate'] = this.authorizationService.checkPermission([userRole], complainStatus['containmentExecutedDate'], permissionCode['containmentExecutedDate'], userAccessData);
    this.setPermission['issueDescriptionForm']['EightDRCAIsRequiredByCustomer'] = this.authorizationService.checkPermission([userRole], complainStatus['EightDRCAIsRequiredByCustomer'], permissionCode['EightDRCAIsRequiredByCustomer'], userAccessData);
    this.setPermission['issueDescriptionForm']['TargetResolvingDate'] = this.authorizationService.checkPermission([userRole], complainStatus['TargetResolvingDate'], permissionCode['TargetResolvingDate'], userAccessData);
    this.setPermission['issueDescriptionForm']['targetResolutionHiddenDate'] = this.authorizationService.checkPermission([userRole], complainStatus['targetResolutionHiddenDate'], permissionCode['targetResolutionHiddenDate'], userAccessData);
    this.setPermission['issueDescriptionForm']['dateChangeReason'] = this.authorizationService.checkPermission([userRole], complainStatus['dateChangeReason'], permissionCode['dateChangeReason'], userAccessData);
    this.setPermission['issueDescriptionForm']['changeDescription'] = this.authorizationService.checkPermission([userRole], complainStatus['changeDescription'], permissionCode['changeDescription'], userAccessData);
    this.setPermission['issueDescriptionForm']['targetResolvingDateChangeCount'] = this.authorizationService.checkPermission([userRole], complainStatus['targetResolvingDateChangeCount'], permissionCode['targetResolvingDateChangeCount'], userAccessData);
    this.setPermission['issueDescriptionForm']['changeReasonId'] = this.authorizationService.checkPermission([userRole], complainStatus['changeReasonId'], permissionCode['changeReasonId'], userAccessData);
    this.setPermission['issueDescriptionForm']['targetResolvingDateInitialValue'] = this.authorizationService.checkPermission([userRole], complainStatus['targetResolvingDateInitialValue'], permissionCode['targetResolvingDateInitialValue'], userAccessData);
    // dateChangeReason: [],
    // changeDescription: [],
    // targetResolvingDateChangeCount: [],
    // changeReasonId: []
    //Additional Subject Form Control
    this.setPermission['additionalSubjectForm']['resolvingUnit'] = this.authorizationService.checkPermission([userRole], complainStatus['resolvingUnit'], permissionCode['resolvingUnit'], userAccessData);
    this.setPermission['additionalSubjectForm']['resolvingUnitArray'] = this.authorizationService.checkPermission([userRole], complainStatus['resolvingUnitArray'], permissionCode['resolvingUnitArray'], userAccessData);
    this.setPermission['additionalSubjectForm']['proposedResolutionOwner'] = this.authorizationService.checkPermission([userRole], complainStatus['proposedResolutionOwner'], permissionCode['proposedResolutionOwner'], userAccessData);
    this.setPermission['additionalSubjectForm']['Coordinators'] = this.authorizationService.checkPermission([userRole], complainStatus['Coordinators'], permissionCode['Coordinators'], userAccessData);
    this.setPermission['additionalSubjectForm']['verificationTeam'] = this.authorizationService.checkPermission([userRole], complainStatus['verificationTeam'], permissionCode['verificationTeam'], userAccessData);
    this.setPermission['additionalSubjectForm']['ABBProductCategory'] = this.authorizationService.checkPermission([userRole], complainStatus['ABBProductCategory'], permissionCode['ABBProductCategory'], userAccessData);
    this.setPermission['additionalSubjectForm']['issueProductArray'] = this.authorizationService.checkPermission([userRole], complainStatus['issueProductArray'], permissionCode['issueProductArray'], userAccessData);
    this.setPermission['additionalSubjectForm']['failureMode'] = this.authorizationService.checkPermission([userRole], complainStatus['failureMode'], permissionCode['failureMode'], userAccessData);
    this.setPermission['additionalSubjectForm']['failureModeBind'] = this.authorizationService.checkPermission([userRole], complainStatus['failureModeBind'], permissionCode['failureModeBind'], userAccessData);
    this.setPermission['additionalSubjectForm']['DefectTypeandDefectCodeGroupType'] = this.authorizationService.checkPermission([userRole], complainStatus['DefectTypeandDefectCodeGroupType'], permissionCode['DefectTypeandDefectCodeGroupType'], userAccessData);
    this.setPermission['additionalSubjectForm']['abbProductSerialNo'] = this.authorizationService.checkPermission([userRole], complainStatus['abbProductSerialNo'], permissionCode['abbProductSerialNo'], userAccessData);
    this.setPermission['additionalSubjectForm']['purchaseOrderNo'] = this.authorizationService.checkPermission([userRole], complainStatus['purchaseOrderNo'], permissionCode['purchaseOrderNo'], userAccessData);
    this.setPermission['additionalSubjectForm']['abbProjectNumber'] = this.authorizationService.checkPermission([userRole], complainStatus['abbProjectNumber'], permissionCode['abbProjectNumber'], userAccessData);
    this.setPermission['additionalSubjectForm']['abbProjectName'] = this.authorizationService.checkPermission([userRole], complainStatus['abbProjectName'], permissionCode['abbProjectName'], userAccessData);
    this.setPermission['additionalSubjectForm']['CountryCodeWhereTheIssueHappen'] = this.authorizationService.checkPermission([userRole], complainStatus['CountryCodeWhereTheIssueHappen'], permissionCode['CountryCodeWhereTheIssueHappen'], userAccessData);
    this.setPermission['additionalSubjectForm']['quantity'] = this.authorizationService.checkPermission([userRole], complainStatus['quantity'], permissionCode['quantity'], userAccessData);
    this.setPermission['additionalSubjectForm']['articleNumber'] = this.authorizationService.checkPermission([userRole], complainStatus['articleNumber'], permissionCode['articleNumber'], userAccessData);
    this.setPermission['additionalSubjectForm']['itemDesignation'] = this.authorizationService.checkPermission([userRole], complainStatus['itemDesignation'], permissionCode['itemDesignation'], userAccessData);
    this.setPermission['additionalSubjectForm']['abbCustomerReference'] = this.authorizationService.checkPermission([userRole], complainStatus['abbCustomerReference'], permissionCode['abbCustomerReference'], userAccessData);
    this.setPermission['additionalSubjectForm']['supplier'] = this.authorizationService.checkPermission([userRole], complainStatus['supplier'], permissionCode['supplier'], userAccessData);
    this.setPermission['additionalSubjectForm']['deliveryDate'] = this.authorizationService.checkPermission([userRole], complainStatus['deliveryDate'], permissionCode['deliveryDate'], userAccessData);
    this.setPermission['additionalSubjectForm']['commissionDate'] = this.authorizationService.checkPermission([userRole], complainStatus['commissionDate'], permissionCode['commissionDate'], userAccessData);
    this.setPermission['additionalSubjectForm']['ManufacturingDate'] = this.authorizationService.checkPermission([userRole], complainStatus['ManufacturingDate'], permissionCode['ManufacturingDate'], userAccessData);
    this.setPermission['additionalSubjectForm']['warrantyReference'] = this.authorizationService.checkPermission([userRole], complainStatus['warrantyReference'], permissionCode['warrantyReference'], userAccessData);
    this.setPermission['additionalSubjectForm']['warrantyStartDate'] = this.authorizationService.checkPermission([userRole], complainStatus['warrantyStartDate'], permissionCode['warrantyStartDate'], userAccessData);
    this.setPermission['additionalSubjectForm']['warrantyEndDate'] = this.authorizationService.checkPermission([userRole], complainStatus['warrantyEndDate'], permissionCode['warrantyEndDate'], userAccessData);
    this.setPermission['additionalSubjectForm']['documentID'] = this.authorizationService.checkPermission([userRole], complainStatus['documentID'], permissionCode['documentID'], userAccessData);
    this.setPermission['additionalSubjectForm']['qNumberIssue'] = this.authorizationService.checkPermission([userRole], complainStatus['qNumberIssue'], permissionCode['qNumberIssue'], userAccessData);
    this.setPermission['issueResolutionForm']['ProductAlreadyDelivered'] = this.authorizationService.checkPermission([userRole], complainStatus['ProductAlreadyDelivered'], permissionCode['ProductAlreadyDelivered'], userAccessData);
    this.setPermission['issueResolutionForm']['NoOfUnitsShipped'] = this.authorizationService.checkPermission([userRole], complainStatus['NoOfUnitsShipped'], permissionCode['NoOfUnitsShipped'], userAccessData);
    this.setPermission['additionalSubjectForm']['PartiesReference'] = this.authorizationService.checkPermission([userRole], complainStatus['PartiesReference'], permissionCode['PartiesReference'], userAccessData);
    this.setPermission['additionalSubjectForm']['SendCopyToParties'] = this.authorizationService.checkPermission([userRole], complainStatus['SendCopyToParties'], permissionCode['SendCopyToParties'], userAccessData);
    //this.setPermission['additionalSubjectForm']['uploadedPDCAAttachments'] = this.authorizationService.checkPermission([userRole],complainStatus['uploadedPDCAAttachments'], permissionCode['uploadedPDCAAttachments'], userAccessData);
    //this.setPermission['additionalSubjectForm']['uploadedSummaryAttachments'] = this.authorizationService.checkPermission([userRole],complainStatus['uploadedSummaryAttachments'], permissionCode['uploadedSummaryAttachments'], userAccessData);
    this.setPermission['additionalSubjectForm']['hiddenIsHVDCComplaint'] = this.authorizationService.checkPermission([userRole], complainStatus['hiddenIsHVDCComplaint'], permissionCode['hiddenIsHVDCComplaint'], userAccessData);
    this.setPermission['additionalSubjectForm']['warrantyLifeCycleId'] = this.authorizationService.checkPermission([userRole], complainStatus['warrantyLifeCycleId'], permissionCode['warrantyLifeCycleId'], userAccessData);

    //Issue Assignment
    this.setPermission['issueAssignmentForm']['PrimaryResolutionOwner'] = this.authorizationService.checkPermission([userRole], complainStatus['PrimaryResolutionOwner'], permissionCode['PrimaryResolutionOwner'], userAccessData);
    this.setPermission['issueAssignmentForm']['resolutionOwnerTeam'] = this.authorizationService.checkPermission([userRole], complainStatus['resolutionOwnerTeam'], permissionCode['resolutionOwnerTeam'], userAccessData);
    this.setPermission['issueAssignmentForm']['ccrpNo'] = this.authorizationService.checkPermission([userRole], complainStatus['ccrpNo'], permissionCode['ccrpNo'], userAccessData);
    this.setPermission['issueAssignmentForm']['quickFixComplaint'] = this.authorizationService.checkPermission([userRole], complainStatus['quickFixComplaint'], permissionCode['quickFixComplaint'], userAccessData);
    this.setPermission['issueAssignmentForm']['quickFixVariant'] = this.authorizationService.checkPermission([userRole], complainStatus['quickFixVariant'], permissionCode['quickFixVariant'], userAccessData);
    this.setPermission['issueAssignmentForm']['verifiedCheckBox'] = this.authorizationService.checkPermission([userRole], complainStatus['verifiedCheckBox'], permissionCode['verifiedCheckBox'], userAccessData);
    this.setPermission['issueAssignmentForm']['coordinatorList'] = this.authorizationService.checkPermission([userRole], complainStatus['coordinatorList'], permissionCode['coordinatorList'], userAccessData);
    this.setPermission['issueAssignmentForm']['coordinatorName'] = this.authorizationService.checkPermission([userRole], complainStatus['coordinatorName'], permissionCode['coordinatorName'], userAccessData);
    this.setPermission['issueAssignmentForm']['AddNominatedPerson'] = this.authorizationService.checkPermission([userRole], complainStatus['AddNominatedPerson'], permissionCode['AddNominatedPerson'], userAccessData);
    this.setPermission['issueAssignmentForm']['solutionWillBeValidatedByCoordinator'] = this.authorizationService.checkPermission([userRole], complainStatus['solutionWillBeValidatedByCoordinator'], permissionCode['solutionWillBeValidatedByCoordinator'], userAccessData);
    this.setPermission['issueAssignmentForm']['QuickFixRCANotReqDescription'] = this.authorizationService.checkPermission([userRole], complainStatus['QuickFixRCANotReqDescription'], permissionCode['QuickFixRCANotReqDescription'], userAccessData);


    // RCA Section
    this.setPermission['issueResolutionForm']['EstimatedCostView'] = this.authorizationService.checkPermission([userRole], complainStatus['EstimatedCostView'], permissionCode['EstimatedCostView'], userAccessData);
    this.setPermission['issueResolutionForm']['ImpactOnInstalledBase'] = this.authorizationService.checkPermission([userRole], complainStatus['ImpactOnInstalledBase'], permissionCode['ImpactOnInstalledBase'], userAccessData);
    this.setPermission['issueResolutionForm']['TotalInstalledBase'] = this.authorizationService.checkPermission([userRole], complainStatus['TotalInstalledBase'], permissionCode['TotalInstalledBase'], userAccessData);
    this.setPermission['issueResolutionForm']['QNumberResolvingUnit'] = this.authorizationService.checkPermission([userRole], complainStatus['QNumberResolvingUnit'], permissionCode['QNumberResolvingUnit'], userAccessData);
    // this.setPermission['issueResolutionForm']['RCACorrectiveActions'] = this.authorizationService.checkPermission([userRole], complainStatus['RCACorrectiveActions'], permissionCode['RCACorrectiveActions'], userAccessData);
    this.setPermission['issueResolutionForm']['SpecificationName'] = this.authorizationService.checkPermission([userRole], complainStatus['SpecificationName'], permissionCode['SpecificationName'], userAccessData);
    this.setPermission['issueResolutionForm']['howProductFailMeetSpecification'] = this.authorizationService.checkPermission([userRole], complainStatus['howProductFailMeetSpecification'], permissionCode['howProductFailMeetSpecification'], userAccessData);
    this.setPermission['issueResolutionForm']['ContainmentActionsExecution'] = this.authorizationService.checkPermission([userRole], complainStatus['ContainmentActionsExecution'], permissionCode['ContainmentActionsExecution'], userAccessData);
    this.setPermission['issueResolutionForm']['WhatContainmentActionsWhereTaken'] = this.authorizationService.checkPermission([userRole], complainStatus['WhatContainmentActionsWhereTaken'], permissionCode['WhatContainmentActionsWhereTaken'], userAccessData);
    this.setPermission['issueResolutionForm']['whatWasTheRootCauseOfThisIssue'] = this.authorizationService.checkPermission([userRole], complainStatus['whatWasTheRootCauseOfThisIssue'], permissionCode['whatWasTheRootCauseOfThisIssue'], userAccessData);
    this.setPermission['issueResolutionForm']['CategoryKeyword'] = this.authorizationService.checkPermission([userRole], complainStatus['CategoryKeyword'], permissionCode['CategoryKeyword'], userAccessData);
    this.setPermission['issueResolutionForm']['howDidWeResolveThisIssue'] = this.authorizationService.checkPermission([userRole], complainStatus['howDidWeResolveThisIssue'], permissionCode['howDidWeResolveThisIssue'], userAccessData);
    this.setPermission['issueResolutionForm']['chipData'] = this.authorizationService.checkPermission([userRole], complainStatus['chipData'], permissionCode['chipData'], userAccessData);
    this.setPermission['issueResolutionForm']['Upload'] = this.authorizationService.checkPermission([userRole], complainStatus['Upload'], permissionCode['Upload'], userAccessData);
    this.setPermission['issueResolutionForm']['ActualRUCost'] = this.authorizationService.checkPermission([userRole], complainStatus['ActualRUCost'], permissionCode['ActualRUCost'], userAccessData);
    this.setPermission['issueResolutionForm']['COPQCategory'] = this.authorizationService.checkPermission([userRole], complainStatus['COPQCategory'], permissionCode['COPQCategory'], userAccessData);
    this.setPermission['issueResolutionForm']['COPQCategoryAllData'] = this.authorizationService.checkPermission([userRole], complainStatus['COPQCategoryAllData'], permissionCode['COPQCategoryAllData'], userAccessData);
    this.setPermission['issueResolutionForm']['pgCategory'] = this.authorizationService.checkPermission([userRole], complainStatus['pgCategory'], permissionCode['pgCategory'], userAccessData);
    this.setPermission['issueResolutionForm']['PGRCACategoryAllData'] = this.authorizationService.checkPermission([userRole], complainStatus['PGRCACategoryAllData'], permissionCode['PGRCACategoryAllData'], userAccessData);
    this.setPermission['issueResolutionForm']['learn'] = this.authorizationService.checkPermission([userRole], complainStatus['learn'], permissionCode['learn'], userAccessData);
    this.setPermission['issueResolutionForm']['desc'] = this.authorizationService.checkPermission([userRole], complainStatus['desc'], permissionCode['desc'], userAccessData);
    this.setPermission['issueResolutionForm']['id'] = this.authorizationService.checkPermission([userRole], complainStatus['id'], permissionCode['id'], userAccessData);
    this.setPermission['issueResolutionForm']['Rating'] = this.authorizationService.checkPermission([userRole], complainStatus['Rating'], permissionCode['Rating'], userAccessData);
    this.setPermission['issueResolutionForm']['issueAssigned'] = this.authorizationService.checkPermission([userRole], complainStatus['issueAssigned'], permissionCode['issueAssigned'], userAccessData);
    this.setPermission['issueResolutionForm']['selectRole'] = this.authorizationService.checkPermission([userRole], complainStatus['selectRole'], permissionCode['selectRole'], userAccessData);
    this.setPermission['issueResolutionForm']['selectRole1'] = this.authorizationService.checkPermission([userRole], complainStatus['selectRole1'], permissionCode['selectRole1'], userAccessData);
    this.setPermission['issueResolutionForm']['issueStatus'] = this.authorizationService.checkPermission([userRole], complainStatus['issueStatus'], permissionCode['issueStatus'], userAccessData);
    this.setPermission['issueResolutionForm']['availabilityRating'] = this.authorizationService.checkPermission([userRole], complainStatus['availabilityRating'], permissionCode['availabilityRating'], userAccessData);
    this.setPermission['issueResolutionForm']['functionalityRating'] = this.authorizationService.checkPermission([userRole], complainStatus['functionalityRating'], permissionCode['functionalityRating'], userAccessData);
    this.setPermission['issueResolutionForm']['onTimeDeliveryRating'] = this.authorizationService.checkPermission([userRole], complainStatus['onTimeDeliveryRating'], permissionCode['onTimeDeliveryRating'], userAccessData);
    this.setPermission['issueResolutionForm']['cyberSecurityRating'] = this.authorizationService.checkPermission([userRole], complainStatus['cyberSecurityRating'], permissionCode['cyberSecurityRating'], userAccessData);
    this.setPermission['issueResolutionForm']['productComplianceRating'] = this.authorizationService.checkPermission([userRole], complainStatus['productComplianceRating'], permissionCode['productComplianceRating'], userAccessData);
    this.setPermission['issueResolutionForm']['ohsRiskRating'] = this.authorizationService.checkPermission([userRole], complainStatus['ohsRiskRating'], permissionCode['ohsRiskRating'], userAccessData);
    this.setPermission['issueResolutionForm']['marginRating'] = this.authorizationService.checkPermission([userRole], complainStatus['marginRating'], permissionCode['marginRating'], userAccessData);
    this.setPermission['issueResolutionForm']['reputationRating'] = this.authorizationService.checkPermission([userRole], complainStatus['reputationRating'], permissionCode['reputationRating'], userAccessData);
    this.setPermission['issueResolutionForm']['massMediaRating'] = this.authorizationService.checkPermission([userRole], complainStatus['massMediaRating'], permissionCode['massMediaRating'], userAccessData);
    this.setPermission['issueResolutionForm']['designChangeRating'] = this.authorizationService.checkPermission([userRole], complainStatus['designChangeRating'], permissionCode['designChangeRating'], userAccessData);
    this.setPermission['issueResolutionForm']['instancesRating'] = this.authorizationService.checkPermission([userRole], complainStatus['instancesRating'], permissionCode['instancesRating'], userAccessData);
    this.setPermission['issueResolutionForm']['resolutionRating'] = this.authorizationService.checkPermission([userRole], complainStatus['resolutionRating'], permissionCode['resolutionRating'], userAccessData);
    this.setPermission['issueResolutionForm']['affectedCustomerRating'] = this.authorizationService.checkPermission([userRole], complainStatus['affectedCustomerRating'], permissionCode['affectedCustomerRating'], userAccessData);
    this.setPermission['issueResolutionForm']['controlRating'] = this.authorizationService.checkPermission([userRole], complainStatus['controlRating'], permissionCode['controlRating'], userAccessData);
    this.setPermission['issueResolutionForm']['impactRating'] = this.authorizationService.checkPermission([userRole], complainStatus['impactRating'], permissionCode['impactRating'], userAccessData);
    this.setPermission['issueResolutionForm']['classValue'] = this.authorizationService.checkPermission([userRole], complainStatus['classValue'], permissionCode['classValue'], userAccessData);
    this.setPermission['issueResolutionForm']['rpn'] = this.authorizationService.checkPermission([userRole], complainStatus['rpn'], permissionCode['rpn'], userAccessData);
   // this.setPermission['issueResolutionForm']['JustificationCurrentRca'] = this.authorizationService.checkPermission([userRole], complainStatus['JustificationCurrentRca'], permissionCode['JustificationCurrentRca'], userAccessData);
   // this.setPermission['issueResolutionForm']['UploadRCAAttachments'] = this.authorizationService.checkPermission([userRole], complainStatus['UploadRCAAttachments'], permissionCode['UploadRCAAttachments'], userAccessData);
    this.setPermission['issueResolutionForm']['resolutionAttachments'] = this.authorizationService.checkPermission([userRole], complainStatus['resolutionAttachments'], permissionCode['resolutionAttachments'], userAccessData);
    this.setPermission['issueResolutionForm']['manualChangeClass'] = this.authorizationService.checkPermission([userRole], complainStatus['manualChangeClass'], permissionCode['manualChangeClass'], userAccessData);
    this.setPermission['issueResolutionForm']['ReviewRequired'] = this.authorizationService.checkPermission([userRole], complainStatus['ReviewRequired'], permissionCode['ReviewRequired'], userAccessData);
    this.setPermission['issueResolutionForm']['eightDReportRequired'] = this.authorizationService.checkPermission([userRole], complainStatus['eightDReportRequired'], permissionCode['eightDReportRequired'], userAccessData);
    this.setPermission['issueResolutionForm']['EightDJustificationCurrent'] = this.authorizationService.checkPermission([userRole], complainStatus['EightDJustificationCurrent'], permissionCode['EightDJustificationCurrent'], userAccessData);
    this.setPermission['issueResolutionForm']['reviewRequiredJustification'] = this.authorizationService.checkPermission([userRole], complainStatus['reviewRequiredJustification'], permissionCode['reviewRequiredJustification'], userAccessData);
    this.setPermission['issueResolutionForm']['DefectCauseDefectCauseCodeGroup'] = this.authorizationService.checkPermission([userRole], complainStatus['DefectCauseDefectCauseCodeGroup'], permissionCode['DefectCauseDefectCauseCodeGroup'], userAccessData);

    // Issue closed
    this.setPermission['issueClosedForm']['AwaitingCustomerExecution'] = this.authorizationService.checkPermission([userRole], complainStatus['AwaitingCustomerExecution'], permissionCode['AwaitingCustomerExecution'], userAccessData);
    this.setPermission['issueClosedForm']['customerComments'] = this.authorizationService.checkPermission([userRole], complainStatus['customerComments'], permissionCode['customerComments'], userAccessData);
    this.setPermission['issueClosedForm']['ExpectedExecutionDate'] = this.authorizationService.checkPermission([userRole], complainStatus['ExpectedExecutionDate'], permissionCode['ExpectedExecutionDate'], userAccessData);
    this.setPermission['issueClosedForm']['customerContactStatus'] = this.authorizationService.checkPermission([userRole], complainStatus['customerContactStatus'], permissionCode['customerContactStatus'], userAccessData);
    this.setPermission['issueClosedForm']['sendFeedback'] = this.authorizationService.checkPermission([userRole], complainStatus['sendFeedback'], permissionCode['sendFeedback'], userAccessData);
    this.setPermission['issueClosedForm']['CustomerFeedbackJustification'] = this.authorizationService.checkPermission([userRole], complainStatus['CustomerFeedbackJustification'], permissionCode['CustomerFeedbackJustification'], userAccessData);
    this.setPermission['issueClosedForm']['customerResponse'] = this.authorizationService.checkPermission([userRole], complainStatus['customerResponse'], permissionCode['customerResponse'], userAccessData);
    this.setPermission['issueClosedForm']['uploadedAcceptanceAttachments'] = this.authorizationService.checkPermission([userRole], complainStatus['uploadedAcceptanceAttachments'], permissionCode['uploadedAcceptanceAttachments'], userAccessData);

    // Effectiveness
    this.setPermission['effectivenessForm']['id'] = this.authorizationService.checkPermission([userRole], complainStatus['id'], permissionCode['id'], userAccessData);
    this.setPermission['effectivenessForm']['IsEffective'] = this.authorizationService.checkPermission([userRole], complainStatus['IsEffective'], permissionCode['IsEffective'], userAccessData);
    this.setPermission['effectivenessForm']['DeadlineDate'] = this.authorizationService.checkPermission([userRole], complainStatus['DeadlineDate'], permissionCode['DeadlineDate'], userAccessData);
    this.setPermission['effectivenessForm']['effectiveChangedValue'] = this.authorizationService.checkPermission([userRole], complainStatus['effectiveChangedValue'], permissionCode['effectiveChangedValue'], userAccessData);
    this.setPermission['effectivenessForm']['EffectivenessReason'] = this.authorizationService.checkPermission([userRole], complainStatus['EffectivenessReason'], permissionCode['EffectivenessReason'], userAccessData);
    this.setPermission['effectivenessForm']['reopenReason'] = this.authorizationService.checkPermission([userRole], complainStatus['reopenReason'], permissionCode['reopenReason'], userAccessData);
    this.setPermission['effectivenessForm']['uploadedEffectivenessAttachments'] = this.authorizationService.checkPermission([userRole], complainStatus['uploadedEffectivenessAttachments'], permissionCode['uploadedEffectivenessAttachments'], userAccessData);
  }
}
