import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { AdminFailuremodeComponent } from '../admin-failuremode/admin-failuremode.component';

@Component({
  selector: 'app-failuremode-treeitem',
  templateUrl: './failuremode-treeitem.component.html',
  styleUrls: ['./failuremode-treeitem.component.css']
})
export class FailuremodeTreeitemComponent implements OnInit, OnChanges {

  @Input() collection: any = [];
  @Input() canManage: any;
  @Input() disableActions: any;
  @Input() level:any;
  @Input() levelValidationMessage:any;
  @Input() pgCode: any;

  lookup : any = [];
  itemLevel;

  constructor(private failureModeComp: AdminFailuremodeComponent) { }

  ngOnInit(): void {
    this.updateLookup();

    this.itemLevel = parseInt(this.level, 6) + 1;

    this.pgCode = this.failureModeComp.selected.pg;
  }

  ngOnChanges(){
    this.ngOnInit();
  }

  updateLookup(){
    if(this.collection === undefined || this.collection?.length === 0)
      return;
    
    this.lookup = [];

    this.collection?.forEach(obj => {
      this.lookup.push({
        name: obj.name,
        isActive: obj.isActive,
        id: obj.id
      });
    });
  }
  
}
