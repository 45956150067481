<div class="col-md-12 searchCriteriaPo">
  <div class="panel panel-default cardMainDivPosearch">
    <div class="panel-heading">
      <div class="row">
        <div class="col-sm-11">
          <span class="panel-title" [ngClass]="{manage: itemScope.canManage, simple: !itemScope.canManage}"
            style="overflow: auto;">
            <span class="input-group">
              <span>{{itemScope.scope.unitdescr}} </span>
              <span class="admin-normalize-font">
                <small style="padding-left: 5px !important;">{{itemScope.scope.locationdescr}}</small>
              </span>
            </span>
          </span>
        </div>
        <div class="col-sm-1">
          <div class="row">
            <div class="col-sm-6">
              <span *ngIf="itemScope.canManage">
                <!-- <button type="button" class="btn btn-default">
                  {{'admin_po_addperson_btn'|translate}}
                </button> Commented This code As per discussion with Ajay-->
                <button type="button" (click)="collapse()" *ngIf="expanded">
                  <i class="pi pi-fw pi-chevron-up" title="Collapse"></i>
                </button>
                <button type="button" (click)="expand()" *ngIf="!expanded">
                  <i class="pi pi-fw pi-chevron-down" title="Expand"></i>
                </button>
              </span>
            </div>
            <div class="col-sm-6 ml-2">
              <span class="input-group" *ngIf="!itemScope.canManage">
                <button type="button" (click)="collapse()" class="btn btn-default btn-sm" *ngIf="expanded">
                  <i class="pi pi-fw pi-chevron-up" title="Collapse"></i>
                </button>
                <button type="button" (click)="expand()" class="btn btn-default btn-sm" *ngIf="!expanded">
                  <i class="pi pi-fw pi-chevron-down" title="Expand"></i>
                </button>
              </span>
            </div>

          </div>
        </div>
      </div>

    </div>
    <div class="panel-body" *ngIf="expanded">
      <div>
        <div class="row whitewell">
          <div class="row">
            <div class="col-sm-4 " *ngIf="itemScope.scope.bu == null && itemScope.scope.div">
              <strong>{{'admin_po_div_po_lbl' |
                translate}}:</strong>
            </div>
            <div class="col-sm-4" *ngIf="itemScope.scope.pg == null && itemScope.scope.bu">
              <strong>{{'admin_po_bu_po_lbl' |
                translate}}:</strong>
            </div>
            <div class="col-sm-4" *ngIf="itemScope.scope.pg">
              <strong>{{'admin_po_pg_po_lbl' | translate}}:</strong>
            </div>
            <div class="col-sm-4">
              <a class="admin-link" [ngClass]="{disabled: !canLoadManagers}"
                *ngIf="!itemScope.showAllUnitManagers && !loadingManagers.unitManagers && itemScope.unitManagers.length && itemScope.unitManagers.length > 0"
                (click)="onExpandUnitManagers()">({{itemScope.unitManagers.length}} {{'admin_po_x_people_seemore_lbl'
                |translate}})
              </a>
              <span *ngIf="loadingManagers.unitManagers">
                <small>{{'admin_po_loading_managers' | translate}} </small>
                <i class="fa fa-spinner fa-spin" aria-hidden="true"></i>
              </span>
            </div>
            <div class="col-sm-4 pe-1">
              <span class="btn btn-default btn-sm" *ngIf="itemScope.showAllUnitManagers">
                <i class="fa fa-angle-double-up"
                  (click)="itemScope.showAllUnitManagers = false" title="Collapse"></i>
              </span>
            </div>
          </div>
        </div>
        <div class="row ">
          <div *ngIf="!itemScope.unitManagers.length" class="col-xs-12 col-sm-6 col-md-4 ">
            <span>{{'admin_po_info_none_lbl' | translate}}</span>
          </div>
          <div class="col-sm-12 " *ngIf="itemScope.showAllUnitManagers">
            <div class="rows processOwnerDetailRowClass">
            <div class="column" *ngFor="let items of  itemScope.unitManagersOrderedVertically">
              <div  class="element" *ngFor="let um of  items">
                <small><span class="glyphicon glyphicon-user"></span></small>
                <span><app-po-details [poScope]="um"></app-po-details></span>
                <a class="admin-link" *ngIf="um.canManage">
                  <i class="fa fa-trash-o admin-icon-a" style="color: grey" *ngIf="!canDeleteLastProcessOwner()"></i>
                  <span *ngIf="!deleteButtonHideForSearch">  <i class="fa fa-trash-o admin-icon-a" (click)="deletePerson({geid: um.id, scope: um.scope})"
                    *ngIf="canDeleteLastProcessOwner()"></i></span>
                </a>
              </div>
            </div>
          </div>
          </div>
        </div>
      </div>
      <hr />
      <div>
        <div class="row whitewell">
          <div class="row">
            <div class="col-sm-4" *ngIf="itemScope.scope.bu == null && itemScope.scope.div">
              <strong>{{'admin_po_div_rc_po_lbl' | translate}}:</strong>
            </div>
            <div class="col-sm-4" *ngIf="itemScope.scope.pg == null && itemScope.scope.bu">
              <strong>{{'admin_po_bu_rc_po_lbl' | translate}}:</strong>
            </div>
            <div class="col-sm-4" *ngIf="itemScope.scope.pg">
              <strong class="col-md-4">{{'admin_po_pg_rc_po_lbl' | translate}}:</strong>
            </div>
            <div class="col-sm-4">
              <a class="admin-link" [ngClass]="{disabled: !canLoadManagers}"
                *ngIf="!itemScope.showAllUnitLocationManagers && !loadingManagers.unitLocationManagers && itemScope.unitLocationManagers.length && itemScope.unitLocationManagers.length > 0"
                (click)="onExpandUnitLocationManagers()">
                ({{itemScope.unitLocationManagers.length}} {{'admin_po_x_people_seemore_lbl' |translate}})
              </a>
              <span *ngIf="loadingManagers.unitLocationManagers">
                <small>{{'admin_po_loading_managers' | translate}} </small>
                <i class="fa fa-spinner fa-spin" aria-hidden="true"></i>
              </span>
            </div>
            <div class="col-sm-4 pe-1">
              <span class="btn btn-default btn-sm" *ngIf="itemScope.showAllUnitLocationManagers">
                <i class="fa fa-angle-double-up"
                  (click)="itemScope.showAllUnitLocationManagers = false" title="Collapse"></i>
              </span>
            </div>
          </div>
        </div>
        <div class="row">
          <div *ngIf="!itemScope.unitLocationManagers.length" class="col-xs-12 col-sm-6 col-md-4 ">
            <span>{{'admin_po_info_none_lbl' | translate}}</span>
          </div>
          <div class="col-sm-12" *ngIf="itemScope.showAllUnitLocationManagers">
            <div class="rows processOwnerDetailRowClass">
            <div class="column" *ngFor="let items of itemScope.unitLocationManagersOrderedVertically">
              <div class="element" *ngFor="let ulm of  items">
                <small><span class="glyphicon glyphicon-user"></span></small>
                <span><app-po-details [poScope]="ulm"></app-po-details></span>
                <a class="admin-link" *ngIf="ulm.canManage" (click)="deletePerson({geid: ulm.id, scope: ulm.scope})">
                  <span *ngIf="!deleteButtonHideForSearch"><i class=" fa fa-trash-o admin-icon-a" *ngIf="ulm.canManage"></i></span>
                </a>
              </div>
            </div>
          </div>
          </div>
        </div>
      </div>
      <hr />

      <div>
        <div class="row whitewell">
          <div class="row">
            <div class="col-sm-4">
              <strong>{{'admin_po_rc_po_lbl' | translate}}:</strong>
            </div>
            <div class="col-sm-4">
              <a class="admin-link" [ngClass]="{disabled: !canLoadManagers}"
                *ngIf="!itemScope.showAllLocationManagers && !loadingManagers.locationManagers && itemScope.locationManagers.length && itemScope.locationManagers.length > 0"
                (click)="onExpandLocationManagers()">
                ({{itemScope.locationManagers.length}} {{'admin_po_x_people_seemore_lbl' |translate}})
              </a>
              <span *ngIf="loadingManagers.locationManagers">
                <small>{{'admin_po_loading_managers' | translate}} </small>
                <i class="fa fa-spinner fa-spin" aria-hidden="true"></i>
              </span>
            </div>
            <div class="col-sm-4 pe-1">
              <span class="btn btn-default btn-sm" *ngIf="itemScope.showAllLocationManagers">
                <i class="fa fa-angle-double-up"
                  (click)="itemScope.showAllLocationManagers = false" title="Collapse"></i>
              </span>
            </div>
          </div>
        </div>

        <div class="row">
          <div *ngIf="!itemScope.locationManagers.length" class="col-xs-12 col-sm-6 col-md-4">
            <span>{{'admin_po_info_none_lbl' | translate}}</span>

          </div>
          <div class="col-sm-12-" *ngIf="itemScope.showAllLocationManagers">
            <div class="rows processOwnerDetailRowClass">
            <div class="column" *ngFor="let items of itemScope.locationManagersOrderedVertically">
              <div class="element" *ngFor="let lm of  items">
                <small><span class="glyphicon glyphicon-user"></span></small>
                <span ><app-po-details [poScope]="lm"></app-po-details></span>
                <a class="admin-link" *ngIf="lm.canManage" (click)="deletePerson({geid: lm.id, scope: lm.scope})">
                  <span *ngIf="!deleteButtonHideForSearch"><i class=" fa fa-trash-o admin-icon-a" *ngIf="lm.canManage"></i></span>
                </a>
              </div>
            </div>
          </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>
