<div class="mainDiv supplier-selection-section">

  <div class="supplier-pop">

    <div class="companyDetails supplier-company-details">

      <form [formGroup]="companyDetailsForm">

        <br />

        <div style="padding-left: 0%">

          <div class="row g-3 inp mb-2">

            <!-- company name -->

            <div class="divColoumnSpan tagColumn" style="text-indent: 10%">

              {{ "CustomerSearch_CompanyName" | translate }}

            </div>

            <div class="col">

              <input type="text" class="form-control widgetTextBoxMedium" formControlName="companyName" value="" />

            </div>

            <!-- company address -->

            <div class="divColoumnSpan tagColumn">{{ "CustomerSearch_CompanyAddress" | translate }}</div>

            <div class="col">

              <input type="text" class="form-control widgetTextBoxMedium" name="companyAddress"

                formControlName="companyAddress" value="" />

            </div>

          </div>




          <div class="row g-3 inp mb-2">

            <!-- global -->

            <div class="divColoumnSpan tagColumn mobilePadding">{{ "CustomerSearch_GlobalHqGuid" | translate }}</div>

            <div class="col">

              <input type="text" class="form-control widgetTextBoxMedium" formControlName="globalHQGuid" value="" />

            </div>

            <!-- country -->

            <div class="divColoumnSpan tagColumn">{{ "CustomerSearch_CompanyCountry" | translate }}</div>

            <div class="col">

              <select id="inputCountry" class="form-select form-control widgetTextBoxMedium" formControlName="country"

                name="countryName">

                <option value="" selected>Select Country...</option>

                <option *ngFor="let a of countryDropdownData" value={{a.code}}>

                  <!-- {{ a }} -->

                  {{ a.name }}

                </option>

              </select>

            </div>

          </div>




          <div class="row g-3 inp mb-2">

             <!-- business -->

             <div class="divColoumnSpan tagColumn mobilePadding">{{ "CustomerSearch_CompanyGUID" | translate }}</div>

             <div class="col">

               <input type="text" class="form-control widgetTextBoxMedium" formControlName="businessPartnerGUID"

                 value="" />

             </div>

            <!-- city -->

            <div class="divColoumnSpan tagColumn">{{ "CustomerSearch_CompanyCity" | translate }}</div>

            <div class="col">

              <input type="text" class="form-control widgetTextBoxMedium" formControlName="city" value="" />

            </div>

          </div>

          <div class="modal-footer my-2">
            <button type="button" class="widgetButtoMediumView" (click)="supplierDataOnView()"
            [disabled]="this.companyDetailsForm.controls['companyName'].value === '' &&
            this.companyDetailsForm.controls['companyAddress'].value === '' &&
            this.companyDetailsForm.controls['country'].value === '' &&
            this.companyDetailsForm.controls['city'].value  === '' &&
            this.companyDetailsForm.controls['globalHQGuid'].value === '' &&
            this.companyDetailsForm.controls['businessPartnerGUID'].value === ''"
             [ngClass]="
            this.companyDetailsForm.controls['companyName'].value !== '' ||
            this.companyDetailsForm.controls['companyAddress'].value !== '' ||
            this.companyDetailsForm.controls['country'].value !== '' ||
            this.companyDetailsForm.controls['city'].value  !== '' ||
            this.companyDetailsForm.controls['globalHQGuid'].value !== '' ||
            this.companyDetailsForm.controls['businessPartnerGUID'].value !== ''
                ? ''
                : 'grayButton'
            ">
              {{ "select_ru_popup_btn_view" | translate }}
            </button>
            <!-- <span class="hSpaceTiny"></span> -->
            <button class="input widgetButtoMediumReset ms-1" type="button" (click)="reset()">
              {{ "select_ru_popup_btn_reset" | translate }}
            </button>
          </div>
        </div>
      </form>
    </div>

    <div class="tablediv">
      <h5 class="mx-2 mt-2">{{ "SearchForm_CustomerRecentlySelected" | translate }}</h5>
      <div class="tableMargin">

        <!-- prime ng Paginator with table -->

        <div class="card">
          <p-table [value]="filteredCompanyDetails" selectionMode="single" [paginator]="true" [rows]="rows"
          [lazy]="true" [totalRecords]="totalRecords" [loading]="loading" (onLazyLoad)="loadMoreResolvingData($event)"
            [showCurrentPageReport]="true" [(first)]="first" [tableStyle]="{ 'min-width': '50rem' }"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
            styleClass="p-datatable-gridlines">


            <ng-template pTemplate="header" styleClass="table table-bordered table-striped table-hove">
              <tr>
                <th pSortableColumn="guid" style="width: 25%">
                  GUID<p-sortIcon field="guid"></p-sortIcon>
                </th>
                <th pSortableColumn="companyFullName" style="width: 25%">
                  FullName<p-sortIcon field="companyFullName"></p-sortIcon>
                </th>
                <th pSortableColumn="companyShortName" style="width: 25%">
                  ShortName<p-sortIcon field="companyShortName"></p-sortIcon>
                </th>
                <th pSortableColumn="address" style="width: 25%">
                  Address<p-sortIcon field="address"></p-sortIcon>
                </th>
                <th pSortableColumn="customerType" style="width: 25%">
                  Customer Type<p-sortIcon field="customerType"></p-sortIcon>
                </th>
              </tr>
            </ng-template>

            <ng-template pTemplate="body" let-filter let-rowIndex="rowIndex">
              <tr [ngClass]="{ selectedRowIndex: rowIndex == selectedIndex }" (click)="selectedRow(rowIndex)">
                <td>{{ filter.guid }}</td>
                <td>{{ filter.companyFullName }}</td>
                <td>{{ filter.companyShortName }}</td>
                <td>{{ filter.address }}</td>
                <td>{{ filter.customerType }}</td>
              </tr>
            </ng-template>
            <ng-template pTemplate="paginatorright">
              <p-button type="button" icon="pi pi-refresh" (click)="refresh()" styleClass="p-button-text"></p-button>
            </ng-template>
          </p-table>
          <ng-template #noResult>
            <p>{{ "PagingEmpty" | translate }}</p>
          </ng-template>

        </div>

        <div class="modal-footer my-2">
          <button [disabled]="selectedIndex === -1" class="widgetButtonLargeSelectClose" [ngClass]="
            selectedIndex !== -1
              ? ''
              : 'grayButton'
          " type="button" (click)="selectAndClose()">
            {{ "select_ru_popup_btn_select" | translate }}
          </button>
          <button class="input widgetButtonLarge ms-1" type="button" (click)="onClose()">
            {{ "select_ru_popup_btn_close" | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
