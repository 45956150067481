<div class="AllInOneReportsDataParentDiv">
    <div>
       
        <h3>CCRP : All in One</h3>
        <table class="table table-bordered">
            <thead>
              <tr class="tableHeadData">
                <th scope="col"> CCRP No.</th>
                <th scope="col">Issue Status</th>
                <th scope="col">Confidential</th>
                <th scope="col">High Impact</th>
                <th scope="col">High Impact Category</th>
                <th scope="col">Impact Reason</th>
                <th scope="col">Creation Date (UTC)</th>
                <th scope="col">Days left to Target Resolution / Verification Date</th>
                <th scope="col">Update Date (UTC)</th>
                <th scope="col">Issue Source</th>
                <th scope="col">Issue Owner</th>
                <th scope="col">Issue Owner Substitutes</th>
                <th scope="col">Issue Owner Supervisor</th>
                <th scope="col">Issue Owner Mngr Lv1</th>
                <th scope="col">Issue Owner Mngr Lv2</th>
                <th scope="col">Issue Owner Mngr Lv3</th>
                <th scope="col">Issue Owner Country</th>
                <th scope="col">Issue Owner City</th>
                <th scope="col">Issue Type</th>
                <th scope="col">Customer name</th>
                <th scope="col">Customer contact name</th>
                <th scope="col">Customer HQ</th>
                <th scope="col">Customer Location</th>
                <th scope="col">Customer Leading Business Partner Segment</th>
                <th scope="col">Customer Industry Usage</th>
                <th scope="col">Customer Channel Class</th>
                <th scope="col">Customer Contact Email</th>
                <th scope="col">Issue Subject</th>
                <th scope="col">Issue Summary</th>
                <th scope="col">What is happening?</th>
                <th scope="col">Why is this a problem?</th>
                <th scope="col">How do we know we have a problem?</th>
                <th scope="col">In which Process Step was the issue detected?lvl1</th>
                <th scope="col">Where was the issue detected?</th>
                <th scope="col">When does/did the problem happen?</th>
                <th scope="col">What is the impact on the customer and what are the customer's expectations with respect to the resolution of this case?</th>
                <th scope="col">Complaint Attachments</th>
                <th scope="col">Containment Actions Required</th>
                <th scope="col">Containment Actions Deadline (UTC)</th>
                <th scope="col">Please explain what containment actions are required?</th>
                <th scope="col">RCA and corrective actions required</th>
                <th scope="col">Target Resolution / Verification Date (UTC)</th>
                <th scope="col">Original Rating Class</th>
                <th scope="col">Original Rating RPN</th>
                <th scope="col">No Report to Hitachi</th>
                <th scope="col">Justification</th>
                <th scope="col">Resolving Unit Business</th>
                <th scope="col">Resolving Unit BU</th>
                <th scope="col">Resolving Unit BU Code</th>
                <th scope="col">Resolving Unit PG</th>
                <th scope="col">Resolving Unit PG Code</th>
                <th scope="col">Resolving Unit Descriptive Name</th>
                <th scope="col">Resolving Unit Region</th>
                <th scope="col">Resolving Unit Country</th>
                <th scope="col">Resolving Unit City</th>
                <th scope="col">Resolving Unit Activity</th>
                <th scope="col">Abacus Code</th>
                <th scope="col">Resolving Unit BALID</th>
                <th scope="col">Resolving Unit BA Type</th>
                <th scope="col">Proposed Resolution Owner</th>
                <th scope="col">Issue coordinators</th>
                <th scope="col">Product Name1</th>
                <th scope="col">Product Name 2</th>
                <th scope="col">Product Name 3</th>
                <th scope="col">Failure Mode Lvl_1</th>
                <th scope="col">Failure Mode Lvl_2</th>
                <th scope="col">Failure Mode description</th>
                <th scope="col">Serial No.</th>
                <th scope="col">Purchase Order No.</th>
                <th scope="col">Project ID</th>
                <th scope="col">Project Name</th>
                <th scope="col">Country where the issue happened</th>
                <th scope="col">Quantity</th>
                <th scope="col">Article Number</th>
                <th scope="col">Item Designation</th>
                <th scope="col">Customer Reference</th>
                <th scope="col">Supplier</th>
                <th scope="col">Product already delivered?</th>
                <th scope="col">Number of units already shipped</th>
                <th scope="col">Delivery Date (UTC)</th>
                <th scope="col">Commissioning Date (UTC)</th>
                <th scope="col">Manufacturing Date (UTC)</th>
                <th scope="col">Warranty Reference</th>
                <th scope="col">Warranty Start Date (UTC)</th>
                <th scope="col">Warranty End Date (UTC)</th>
                <th scope="col">Other/Document ID</th>
                <th scope="col">Q-Number Issuing Organization</th>
                <th scope="col">Send a copy to all interested parties connected with this issue</th>
                <th scope="col">Submit Date (UTC)</th>
                <th scope="col">Issue capture data percentage (%)</th>
                <th scope="col">Resolution Owner</th>
                <th scope="col">Resolution Owner Team</th>
                <th scope="col">Create RCA and corrective actions</th>
                <th scope="col">Link to issue with the same RCA</th>
                <th scope="col">RCA and corrective actions not required</th>
                <th scope="col">Is solution in-process verification required?</th>
                <th scope="col">In-process solution verification done by</th>
                <th scope="col">Return To Issue Owner Date (UTC)</th>
                <th scope="col">Assigned Date (UTC)</th>
                <th scope="col">Estimated cost kUSD$</th>
                <th scope="col">Impact on the Installed Base</th>
                <th scope="col">Total Installed Base</th>
                <th scope="col">Q-Number Resolving Unit</th>
                <th scope="col">Current Rating Class</th>
                <th scope="col">Current Rating RPN</th>
                <th scope="col">Specification Name</th>
                <th scope="col">How does the product fail to meet the specification?</th>
                <th scope="col">Category Keyword</th>
                <th scope="col">What containment actions were taken?</th>
                <th scope="col">What was the root cause of this issue?</th>
                <th scope="col">How did we resolve this issue?</th>
                <th scope="col">Upload RCA</th>
                <th scope="col">Attachment Summary Overview</th>
                <th scope="col">Containment Actions Execution Date</th>
                <th scope="col">OPQ Category</th>
                <th scope="col">COPQ Category</th>
                <th scope="col">PG RCA Category Name1</th>
                <th scope="col">Resolved Date (UTC)</th>
                <th scope="col">Return To RO Date (UTC)</th>
                <th scope="col">Resolution Verified Date (UTC)</th>
                <th scope="col">Solution sent to Issue Owner date (UTC)</th>
                <th scope="col">The solution is waiting for execution by the customer</th>
                <th scope="col">Expected Execution Date (UTC)</th>
                <th scope="col">Customer Closure Comments</th>
                <th scope="col">(Closure) Attachments</th>
                <th scope="col">Return To RO Date (UTC)</th>
                <th scope="col">Completion Date (UTC)</th>
                <th scope="col">Corrective Action Effective and Sustainable</th>
                <th scope="col">Effectiveness and Sustainability Check Date (UTC)</th>
                <th scope="col">Effectiveness Deadline Date (UTC)</th>
                <th scope="col">Describe objective evidence as to why the corrective / preventive action was not effective or not required?</th>
                <th scope="col">(Objective Evidence) Attachment</th>
                <th scope="col">Completion After Customer Execution Date (UTC)</th>
                <th scope="col">Reopen Issue Date (UTC)</th>
                <th scope="col">Customer Confirmed Date (UTC)</th>
                <th scope="col">Customer Satisfaction Score</th>
                <th scope="col">Customer Effort Score</th>
                <th scope="col">Customer Feedback</th>
                <th scope="col">Issue Reason from NPS</th>
                <th scope="col">Potential Business Impact (kUSD)</th>
                <th scope="col">Customer GUID</th>
             </tr>
               
            </thead>
            <tbody>
              <tr>
                <th scope="row"></th>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </tbody>  
          </table>
    </div>
</div>
