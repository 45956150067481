import { Component, OnInit } from '@angular/core';
import { DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-delete-card-modal',
  templateUrl: './delete-card-modal.component.html',
  styleUrls: ['./delete-card-modal.component.css']
})
export class DeleteCardModalComponent implements OnInit {
  constructor(public ref: DynamicDialogRef) { }

  ngOnInit(): void {
  }

  cancel(){
    this.ref.close({result: false});
  }

  save() {
    this.ref.close({result: true});
  }

}
