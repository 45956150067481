<form [formGroup]="processStepModal" class="editProcessSteps">
    <div class="row">
        <label class="col-sm-2 control-label processStepsLabel label-bold">{{'admin_process_steps_addedit_name_lb' | translate}}</label>
        <div class="col-sm-10 ps-0 processStepsInput">
            <input type="text" class="form-control" formControlName="itemName" placeholder="{{'admin_process_steps_addedit_name_ph' | translate}}"
                [ngClass]="{'form-control-error': (this.processStepModal.get('itemName').touched && !this.processStepModal.get('itemName').valid) || validationItem.hasErrors}">
                <span class="error" *ngIf="this.processStepModal.get('itemName').touched && this.processStepModal.get('itemName').errors?.['required']">{{'admin_process_steps_addedit_name_msg_req' | translate}}<br></span>
                <span class="error" *ngIf="this.processStepModal.get('itemName').errors?.['maxlength']">{{'admin_process_steps_addedit_name_msg_maxlength' | translate}}<br></span>
                <span *ngIf="this.processStepModal.get('itemName').dirty && validationItem.hasErrors">
                    <span class="error" *ngIf="validationItem.isActiveItem">{{'admin_process_steps_addedit_iefp_active_msg' | translate}}</span>
                    <span class="error" *ngIf="!validationItem.isActiveItem">{{'admin_process_steps_addedit_iefp_notactive_msg' | translate}}</span>
                </span>
        </div>
    </div>

    <div class="row pt-1 isActiveProcessStepEdit" *ngIf="this.config.data.id !== undefined">
        <label class="col-sm-2 control-label processStepsLabel label-bold">{{'admin_process_steps_addedit_isactive_lb' | translate}}</label>
        <div class="col-sm-10 ps-0 inputBox processStepsInput">
            <input type="checkbox" formControlName="isActiveCheck">
        </div>
    </div>

    <div class="modal-footer mt-4">
        <button class="widgetButtoSmall" type="button" (click)="cancel()">{{'admin_process_steps_addedit_cancel_btn_lb' | translate}}</button>
        <button class="widgetButtoSmall ms-2" [disabled]="this.processStepModal.invalid || !this.processStepModal.dirty || validationItem.hasErrors" (click)="save()">{{'admin_process_steps_addedit_save_btn_lb' | translate}}</button>
    </div>
</form>
