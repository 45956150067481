import { Component,  OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { PrimeNGConfig, LazyLoadEvent } from 'primeng/api';

import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { SupplierSelectionServiceService } from 'src/app/services/supplier-selection-service.service';

@Component({
  selector: 'app-supplier-selection-popup',
  templateUrl: './supplier-selection-popup.component.html',
  styleUrls: ['./supplier-selection-popup.component.css'],
})
export class SupplierSelectionPopupComponent implements OnInit {

  companyDetails: Array<any> = [];
  companyDetailsForm!: FormGroup;

  filteredCompanyDetails: any;
  first: number = 0;
  rows: number = 10;
  countryDropdownData: any ;
  custSimilar:any;

  tableheaders: string[] = [
    'GUID',
    'FullName',
    'ShortName',
    'Address',
    'Customer Type',
  ];
  totalRecords: any = 0 ;
  loading: boolean = false;
  viewClicked: boolean = false;

  constructor(private fb: FormBuilder,
    private primengConfig: PrimeNGConfig,
    private readonly dialogRef: DynamicDialogRef,
    private supplierService:SupplierSelectionServiceService
    ) {

      this.supplierService.getCustomerSimilarSearch().subscribe(( resp)=> {
        if(resp){
          this.countryDropdownData = resp.countries;
        }

      })
      this.loading = true;
      this.supplierService.getSuppliersRecentlySelected().subscribe((res)=>{
        this.filteredCompanyDetails = res.data
        this.loading = false;
      })
    }


  ngOnInit(): void {
    this.companyDetailsForm = this.fb.group({
      companyName: [''],
      companyAddress: [''],
      country: [''],
      city: [''],
      globalHQGuid: [''],
      siteID: [''],
      businessPartnerGUID: [''],
    });
    this.primengConfig.ripple = true;
  }

  refresh() {
    // this.firstIndex = 0;
    this.first = 0;
  }
  /*  functionality of Paginator from primeng End */

  reset() {

    this.companyDetailsForm.reset();
    this.filteredCompanyDetails = null;
    this.selectedIndex=-1

    this.companyDetailsForm.patchValue({
      companyName: '',
      companyAddress: '',
      country: '',
      city: '',
      globalHQGuid: '',
      siteID: '',
      businessPartnerGUID: '',
    });
    this.loading = true;
    this.supplierService.getSuppliersRecentlySelected().subscribe((res)=>{
      this.filteredCompanyDetails = res.data
      this.loading = false;
    })

  }

  selectedIndex = -1;
  selectedRow(index: number) {
    this.selectedIndex = index;
  }
  selectAndClose() {
    this.dialogRef.close(this.filteredCompanyDetails[this.selectedIndex]);
  }

  onClose() {
    this.dialogRef.close();
  }

  loadMoreResolvingData(event: any) {
    if(this.viewClicked==true){
    let skipRecord = 0;
    const pageNumber = (event.first + 10) / 10;
    skipRecord += event.first;
    this.loading = true;
    this.supplierDataOnView(pageNumber, skipRecord);
  }
  }

  supplierDataOnView(pageNumber=1, skipRecord=0) {
    this.loading = true;
    this.viewClicked=true;
    let customerData = {
      "take":10,
      "skip":skipRecord,
      "page":pageNumber,
      "pageSize":10,
      "filter":
        {
        "logic":"and",
          "filters":
          [
            {
              "field":"SelectedFilters",
              "value":"{\"guid\":\""+ this.checkBlank(this.companyDetailsForm.controls['businessPartnerGUID'].value) + "\",\"legalName\":\""+ this.checkBlank(this.companyDetailsForm.controls['companyName'].value) + "\",\"street\":\""+this.checkBlank(this.companyDetailsForm.controls['companyAddress'].value)+"\",\"country\": \""+ this.checkBlank(this.companyDetailsForm.controls['country'].value) + "\" ,\"city\": \"" + this.checkBlank(this.companyDetailsForm.controls['city'].value) + "\" ,\"zipCode\":null,\"state\":null,\"globalHqGuid\":\""+ this.checkBlank(this.companyDetailsForm.controls['globalHQGuid'].value) +"\",\"companyRelation\":\"supplier,customerAndSupplier,undesignated\",\"isInternalBusinessPartner\":false}",
              "siteId":null,
              "siteName":null
            }
          ]
        },
        "group":[]

  }
    this.supplierService.GetCustomers(customerData)
      .subscribe((res: any) => {
        this.filteredCompanyDetails = res?.data;
        this.totalRecords = res?.count;
        this.loading = false;
      });

  }
  checkBlank(content) {
    if (content == undefined || content == null) {
      return '';
    } else {
      return content;
    }
  }
}
