<div class="issue_capture_section">
    <form autocomplete="off" [formGroup]="issueCapture">
        <div class="row mb-1 issue_capture_section1">
            <div class="col mt-2">
                <div class="">
                    <label class="supervisor-label issueowner-label">{{'Complaint_ComplaintOwner' | translate}}<span
                        class="asteriskRequired asterik2-label"></span>
                    </label>
                </div>

                <!--
                <div *ngIf="userAccessData ? isAllow([ enumRoleCode.Complaint_Owner],
                   [enumComplaintStatuses.New, enumComplaintStatuses.Draft] ,
                    [enumPermission.EDIT_Complaint_Capture_Section] ) : ''">
                    -------------------HELLO-------------------
                </div> -->
                <!--
                <div *ngIf="allow['IssueOwner']">
                    -------------------HELLO-------------------
                </div> -->

                <div class="multiselect-field_remove">
                    <p-autoComplete [forceSelection]="true" [minLength]=2 [pTooltip]="issueOwnerText" [escape]="false"
                        tooltipPosition="top" tooltipStyleClass="toolTipClass"
                        class="form-control customtextfield-l text-width2  border-0 issueOwner-field mt-0" [readonly]="readonlyObj?.PrimaryComplaintOwnerReadOnly"
                        [ngClass]="{'error-autocomplete' : isSubmitted && this.issueCapture.controls['PrimaryComplaintOwner'].invalid, 'readonlyclass' : readonlyObj?.PrimaryComplaintOwnerReadOnly}"
                        formControlName="PrimaryComplaintOwner" [multiple]="true" type="text"
                        [suggestions]="filteredOwner" (onSelect)="mapSupervisor()"
                        (completeMethod)="filterOwner($event?.query)" field="selectedLabel"
                        [readonly]="this.issueCapture.controls['PrimaryComplaintOwner'].value?.length==1 ? true : false">
                        <ng-template let-filteredOwner pTemplate="selectedItem">
                            <div (mouseover)="ownerGotFocus(filteredOwner.tooltipInfo)" (mouseout)="ownerLostFocus()">
                                {{filteredOwner.selectedLabel }}</div>
                            <span class="p-autocomplete-token-icon pi pi-times-circle ng-tns-c68-7 ng-star-inserted"
                            *ngIf="!readonlyObj?.PrimaryComplaintOwnerReadOnly" (click)="clearOwner()"></span>
                        </ng-template>
                        <ng-template let-filteredOwner pTemplate="item">
                            <div class="flex align-items-center gap-2 mb-2">
                                <div>{{ filteredOwner?.promptLabel }}</div>
                            </div>
                        </ng-template>
                    </p-autoComplete>
                </div>

            </div>

            <div class="col mt-2">
                <div class="">
                    <label>{{'Complaint_SubstituteSupervisor' | translate}}</label>
                </div>
                <div class="autocomplete-textbox">
                    <!-- <input class="form-control customtextfield-l" formControlName="supervisor" type="text"
                        [pTooltip]="supervisorText" [escape]="false" tooltipPosition="top" [disabled]=""
                        tooltipStyleClass="toolTipClass" delay="500" readonly> -->
                    <p-autoComplete [readonly]="true" id="supervisorField" (onSelect)="selectSupervisorTooltipText()"
                        [pTooltip]="supervisorText" [escape]="false" tooltipPosition="top"
                        tooltipStyleClass="toolTipClass" [suggestions]="filteredSupervisor"
                        class="form-control customtextfield-l text-width2 border-0 readonlyclass" formControlName="supervisor"
                        [multiple]="false" field="DisplayLabel">

                        <!-- <ng-template let-filteredOwner pTemplate="item">
                            <div class="flex align-items-center gap-2">
                                <div>{{ filteredOwner?.promptLabel }}</div>
                            </div>
                        </ng-template> -->
                    </p-autoComplete>


                </div>
            </div>

        </div>
        <!-- <div class="row mb-1">

        </div> -->
        <div class="row mb-1 substitute-section">
            <div class="col">
                <div class="">
                    <label class="substitute-label">{{'Complaint_ComplaintOwnerTeam' | translate}}<span
                            class="asteriskRequired"
                            *ngIf="this.issueCapture.controls['substitute']?.validator"></span></label>
                </div>
                <div class="multiselect-field_remove">
                    <p-autoComplete [forceSelection]="true" formControlName="substitute" [readonly]="readonlyObj?.substituteReadOnly"
                    class="border-0 substituteCustom" [pTooltip]="substituteText" [escape]="false" [ngClass]="{'readonlyclass' : readonlyObj?.substituteReadOnly}"
                    tooltipPosition="top" tooltipStyleClass="toolTipClass" [suggestions]="filteredSubstitude"
                    (completeMethod)="filterSubstitude($event)" field="selectedLabel" [multiple]="true" [minLength]="3" [delay]="500">
                        <ng-template let-filteredSubstitude pTemplate="item">
                            <div class="flex align-items-center gap-2 mb-2">
                                <div>{{ filteredSubstitude.promptLabel }}</div>
                            </div>
                        </ng-template>
                        <ng-template let-filteredSubstitude pTemplate="selectedItem">
                            <div (mouseover)="substituteGotFocus(filteredSubstitude.tooltipInfo)"
                                (mouseout)="substituteLostFocus()">
                                {{filteredSubstitude?.selectedLabel }}</div>
                        </ng-template>
                    </p-autoComplete>
                </div>
            </div>


        </div>
        <div class="inner-heading-wrapper">
        <h6 class="inner-heading1"> {{'ComplaintForm_CustomerSelectionSubsectionName' | translate}} </h6>
       </div>
        <div class="row mb-1" >
            <div class="col-md-2 col-sm-3 ps-0 pe-0">
                <label class="mt-0 issue-type-label">{{'ReportsFilter_CustomerType' | translate}}
                    <span class="asteriskRequired" *ngIf="this.issueCapture.controls['CustomerType']?.validator"></span>
                </label>
            </div>
            <div class="col-md-8 col-sm-9 issue-type-container">

                <div class="issue-type">
                    <input type="radio" class="widgetRadioButton" (change)="CustomerTypeEvent()" value="External"
                        formControlName="CustomerType" id="externalRadio" [pTooltip]="externalText" [escape]="false"
                        tooltipPosition="top" tooltipStyleClass="toolTipClass" delay="500">
                    <span>{{'CustomerTypeExternal' | translate}}</span>
                </div>
                <div class="issue-type">
                    <input type="radio" class="widgetRadioButton" (change)="CustomerTypeEvent()" value="Internal"
                        formControlName="CustomerType" id="internalRadio" [pTooltip]="internalText" [escape]="false"
                        tooltipPosition="top" tooltipStyleClass="toolTipClass" delay="500">
                    <span> {{'CustomerTypeInternal' | translate}} </span>
                </div>
            </div>
        </div>
<div class="customer-selection-field2">
        <div class="row mb-1" *ngIf="this.issueCapture?.controls['CustomerType']?.value=='Internal'">
            <div class="col-md-2 col-sm-3 ps-0 pe-0">
                <label>{{'Complaint_ReferredCustomerName' | translate}}
                    <span class="asteriskRequired"
                        *ngIf="this.issueCapture.controls['affectedCustomer']?.validator"></span></label>
            </div>
            <div class="col-md-8 col-sm-9 remove-px input-icons">
                <i class="fa fa-times icon" aria-hidden="true" *ngIf="this.issueCapture.controls['affectedCustomer'].value!='' && this.issueCapture.controls['affectedCustomer'].value!=null && (this.complaintData?.header.complaintStatus==2 || this.complaintData==undefined)" (click)="clearCustomer()"></i>
                <input readonly class="form-control customtextfield-l resolutionCategory input-field inputFieldDisabled"
                    [pTooltip]="affectedCustomerText" [escape]="false" tooltipPosition="top"
                    tooltipStyleClass="toolTipClass" delay="500"
                    [ngClass]="isSubmitted && this.issueCapture.controls['affectedCustomer'].invalid ? 'error-inputbox' : ''"
                    type="text" formControlName="affectedCustomer">
                <button type="button" class="col-sm-1 widgetButtoSmall customerSelectButton ms-2 mt-1"
                    (click)="showComponentAffectedCustomer()">{{
                    this.issueCapture.controls['affectedCustomer'].value == '' ? 'Select' : 'Change'}}</button>
              <input type="hidden" formControlName="affectedCustomerHidden">
            </div>
            <!-- [disabled]="disableBtn['affectedCustomer'] ? true : null" -->
        </div>
    </div>
        <div *ngIf="this.issueCapture?.controls['CustomerType']?.value=='External'" class="customer-selection-field">
            <div class="row">
                <div class="col-md-2 col-sm-3 ps-0 pe-0">
                    <label>{{'Complaint_CustomerName' | translate}}<span class="asteriskRequired"
                            *ngIf="this.issueCapture.controls['Customer']?.validator"></span></label>
                </div>
                <!-- Customer Name label & input Field & Button -->
                <div class="col-md-8 col-sm-9 mt-1 remove-px remove-mt">
                    <input type="hidden" formControlName="customerNameData">
                    <input readonly class="form-control customtextfield-l remove-mt inputFieldDisabled" [disabled]=""
                        [pTooltip]="customerNameText" [escape]="false" tooltipPosition="top"
                        tooltipStyleClass="toolTipClass" delay="500"
                        [ngClass]="{'error-inputbox': isSubmitted && f['Customer'].errors}" type="text"
                        formControlName="Customer">
                    <input type="hidden" formControlName="customerNameHidden">
                    <button type="button" class="col-sm-1 widgetButtoSmall customerSelectButton ms-2 mt-1"
                    [disabled]="issueCapture.controls['Customer']?.status == 'DISABLED'"
                    [ngClass]="issueCapture.controls['Customer']?.status == 'DISABLED'
                      ? '  grayButton '
                      : '  '
                    "
                    (click)="showComponentCustomer()">{{
                        this.issueCapture.controls['Customer'].value == '' ? 'Select' : 'Change'}}</button>
                </div>
                <!-- [disabled]="!disableBtn['customerName']"
                [ngClass]="!disableBtn['customerName']
                ? '  grayButton '
                : '  '" -->
            </div>
            <div class="row">
                <div class="col-md-2 col-sm-3 ps-0 pe-0">
                    <label>{{'Complaint_CustomerContactName' | translate}}<span class="asteriskRequired"
                            *ngIf="this.issueCapture.controls['CustomerContact']?.validator"></span></label>
                </div>
                <div class="col-md-8 col-sm-9 mt-1 remove-px remove-mt">
                    <input readonly class="form-control customtextfield-l customerContactNameClass remove-mt inputFieldDisabled"
                        [ngClass]="{'error-inputbox' : (isSubmitted && this.issueCapture.controls['CustomerContact'].invalid) || (isSubmitted && this.issueCapture.get('customerContactNameHidden').value?.isValid==false && this.complaintData?.header?.complaintStatus==7)}"
                        [pTooltip]="customerContactNameText" [escape]="false" tooltipPosition="top"
                tooltipStyleClass="toolTipClass" delay="500" type="text" formControlName="CustomerContact">
                    <input type="hidden" formControlName="customerContactNameHidden">
                    <button type="button" class="col-sm-1 widgetButtoSmall customerSelectButton ms-2 mt-1"
                        (click)="showComponentCustomerContact()" [ngClass]="this.issueCapture.controls['Customer'].invalid ? '  grayButton '
                      : '  '
                  " [disabled]="issueCapture.controls['Customer'].invalid">{{
                        this.issueCapture.controls['CustomerContact'].value == '' ? 'Select' : 'Change'}}</button>
                </div>
                <!-- [disabled]="!disableBtn['customerContactName']"
                [ngClass]="!disableBtn['customerContactName']
              ? '  grayButton ': '  '" -->
            </div>
        </div>
        <div class="row" *ngIf="this.issueCapture?.controls['CustomerType']?.value=='External'">
            <label class="col-sm-9 offset-sm-2 remove-px">
                <!-- <span class="mandatory" style="vertical-align:middle;float:left;margin-top:-2px;">*</span> -->
                <span class="w-75"> {{'Complaint_SendCustomerConfirmation' | translate}}</span>
            </label>
        </div>
    <div class="inner-heading-wrapper">
        <h6 class="inner-heading1">{{'ComplaintForm_ComplaintInformationName' | translate}}</h6>
    </div>
        <!-- <div class="row mb-1">
            <div class="col-md-9 col-sm-9 ps-0 pe-0 mb-2">
            </div>
        </div> -->
        <div class="row mb-1">
            <div class="summarize-text remove-px">
                <label class="float-start large-sm-text mt-0">{{'ComplaintForm_ComplaintSubjectName' |
                    translate}} <span class="asteriskRequired" *ngIf="this.issueCapture.controls['Subject']?.validator"></span>
                    {{ (this.issueCapture?.controls['CustomerType']?.value=='External' ? 'ComplaintForm_ComplaintSubjectMeaningfulForCustomer' : '') | translate}}</label>
                <textarea [pTooltip]="issueSubjectText" [escape]="false" tooltipPosition="left"
                    tooltipStyleClass="toolTipClass" delay="500" class="form-control customtextfield-l mt-0"
                    [ngClass]="isSubmitted && this.issueCapture.controls['Subject'].invalid ? 'error-inputbox' : ''"
                    formControlName="Subject" maxlength="{{maxLength.SUBJECT}}">
                </textarea>
            </div>
        </div>
    </form>
</div>
