import { Component, OnInit } from '@angular/core';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-delete-draft',
  templateUrl: './delete-draft.component.html',
  styleUrls: ['./delete-draft.component.css']
})
export class DeleteDraftComponent implements OnInit {

  
deleteform!: FormGroup;
constructor(
private readonly dialogRef: DynamicDialogRef, private fb: FormBuilder){}

ngOnInit(): void {
  this.deleteform = this.fb.group({
    reason: ['', Validators.required],
  });

}

popupClose(action: boolean) {
  this.dialogRef.close({reason: this.deleteform?.controls['reason']?.value, action: action});
}

}
