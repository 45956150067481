<div class="admin-customer-contact-main-page">
<form [formGroup]="admCustomerContactForm">
    <div class="panel-body bg-white1 admin-customer-contacts-main-section">
        <div class="row">
            <div class="col-sm-1 mt-1 item-align-right customerContactsMainLabel">
                <strong>{{'admin_cc_customers_sp_lbl' | translate}}</strong>
            </div>
            <div class="col-sm-7 customerInput" style="display:block">
                <p-autoComplete [forceSelection]="true" [minLength]=2 formControlName="customer"
                    placeholder="{{'admin_cc_customers_sp_ph' | translate}}" [multiple]="true" type="text"
                    [suggestions]="filteredCustomer" (completeMethod)="filterCustomerDetails($event)" class="customerSelection"
                    (onSelect)="customerSelect($event)" (onUnselect)="customerGuidsUnselect($event)" field="name">
                    <ng-template let-filteredCustomer pTemplate="item">
                        <div class="flex align-items-center gap-2">
                              <div class="row">
                                <div class="col-10"><b>{{ filteredCustomer?.name }} <span *ngIf="filteredCustomer?.isActive === false">(Not validated)</span></b></div>
                                <div class="col-2 text-end"><i>{{filteredCustomer?.type}}</i></div>
                              </div>
                              <div class="row mt-1">
                                <div class="col">{{filteredCustomer?.addr}},{{filteredCustomer?.city}},{{filteredCustomer?.iso}}</div>
                              </div>
                        </div>
                    </ng-template>
                </p-autoComplete>
            </div>
            <div class="col-sm-3 custContactLabelBtn">
                <button class="widgetButtonLarge" type="button" (click)="advancedCustomerSelection()"
                    [pTooltip]="advancedCustomerBtn" [escape]="false" tooltipPosition="top" tooltipZIndex="auto"
                    tooltipStyleClass="toolTipClass">
                    {{'admin_cc_customers_advanced_sp_btn' | translate}}
                </button>
            </div>

        </div>

        <div class="row mt-2">
            <div class="col-sm-1 mt-1 item-align-right customerContactsMainLabel">
                <strong>{{'admin_cc_contacts_sp_lbl' |translate}}</strong>
            </div>
            <div class="col-sm-7 contactsInput">
                <p-autoComplete [forceSelection]="true" [minLength]=2 formControlName="contacts"
                    placeholder="{{'admin_cc_contacts_sp_ph' | translate}}" [multiple]="true" type="text"
                    [suggestions]="filteredContacts" (completeMethod)="filterContactsDetails($event)" class="customerSelection"
                    (onSelect)="customerSelectContact($event)" (onUnselect)="customerEmailUnselect($event)"
                    field="email">
                    <ng-template let-filteredContacts pTemplate="item">
                        <div class="flex align-items-center gap-2">
                            <div>{{ filteredContacts?.email }}</div>
                        </div>
                    </ng-template>
                </p-autoComplete>
            </div>
            <div class="col-sm-4 contactsBtnSort">
                <div class="row">
                    <div class="col-sm-4 contactsBtn custContactLabelBtn">
                        <!-- <button type="button" [disabled]="admCustomerContactForm.controls['customer'].value.length < 0 ||
                        admCustomerContactForm.controls['contacts'].value.length < 0"
                            [ngClass]="admCustomerContactForm.get('customer').value.length !== 0 ? 'widgetButtonLarge' : ' widgetButtonLargeDisabled'"
                            (click)="search()" [pTooltip]="searchBtnMsg" [escape]="false" tooltipPosition="top"
                            tooltipZIndex="auto" tooltipStyleClass="toolTipClass">{{'admin_cc_customers_search_sp_btn' |
                            translate}}
                        </button> -->

                        <button type="button"
                            [disabled]="!(this.admCustomerContactForm.get('customer').value !=null && this.admCustomerContactForm.get('customer').value != '') &&
                        !(this.admCustomerContactForm.get('contacts').value != null && this.admCustomerContactForm.get('contacts').value != '')"
                            class="widgetButtonLarge" (click)="search()" [pTooltip]="searchBtnMsg" [escape]="false"
                            tooltipPosition="top" tooltipZIndex="auto"
                            tooltipStyleClass="toolTipClass">{{'admin_cc_customers_search_sp_btn' |
                            translate}}
                        </button>
                    </div>
                    <div class="col-sm-8 custContactSort">
                        <span class="input-group custContactInputsort">
                            <input type="text" class="form-control sorting-input-width"
                                value="{{'admin_cc_sort_cn' | translate}}" readonly />
                            <span class="input-group-btn">
                                <button *ngIf="order_mode=='asc'" (click)="setOrderMode('desc')"
                                    class="input-group-addon" [pTooltip]="ascSortMsg" [escape]="false"
                                    tooltipPosition="top" tooltipStyleClass="toolTipClass">
                                    <i class="hitachiicon-sort_up"></i>
                                </button>
                                <button *ngIf="order_mode=='desc'" (click)="setOrderMode('asc')"
                                    class="input-group-addon" [pTooltip]="descSortMsg" [escape]="false"
                                    tooltipPosition="top" tooltipStyleClass="toolTipClass">
                                    <i class="hitachiicon-sort_down"></i>
                                </button>
                            </span>
                        </span>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="backToIssueBtn" class="row mt-3">
            <button (click)="goBackToIssue()" class="widgetButtonLargeSpl">{{'admin_cc_return_to_complaint'|translate}}</button>
        </div>

        <div class="row mt-3" *ngIf="isCriteriaSelectedEmpty">
            <div class="col-sm-4"></div>
            <div class="col-sm-4" style="text-align: center;"><strong>{{'admin_cc_customers_sr_no_results_msg' | translate}}</strong></div>
            <div class="col-sm-4"></div>
        </div>
    </div>
    <p-card class="admin-customer-contacts-section">
        <div class="flex justify-content-center">
            <div class="row">
                <div class="col-xs-12 col-sm-6 col-md-4 custContactCardClass"
                    *ngFor="let CustomerDetails of hubDetailsAsPerPagination;let indexOfelement=index">
                    <p-card class="p-card p-component mt-2 mb-2 mx-5">
                        <ng-template pTemplate="header">
                            <div>
                                <span>
                                    <i class="hitachiicon-id">
                                    </i>
                                    <p class="custGuid">{{CustomerDetails.customer.guid || CustomerDetails.customer.tuid
                                        || 'N/A'}}</p>
                                    <button type="button" class="btn btn-default btn-sm btn-xs-Branding"
                                        (click)="addContactPopup(CustomerDetails.customer)" [pTooltip]="addContactMsg"
                                        [escape]="false" tooltipPosition="top" tooltipStyleClass="toolTipClass">
                                        Add contact </button>
                                </span>
                                <strong
                                    class="headerDataPatch">{{CustomerDetails.customer.companyFullName}},{{CustomerDetails.customer.countryCode}}</strong>
                                <p class="headerDataPatchAddress">
                                    {{CustomerDetails.customer.city}},{{CustomerDetails.customer.streetName}}</p>
                            </div>
                        </ng-template>
                        <h6 class="ps-1">{{'admin_cc_customers_sr_contacts_lb' | translate}}</h6>
                        <ng-template pTemplate="body">
                            <div *ngFor="let customerContacts of CustomerDetails.customerContacts">
                                <div *ngIf="customerContacts.isActive == true">
                                    <ul>
                                        <li class="cardPODetails"><strong
                                                *ngIf="customerContacts.email  && customerContacts.email.length > 1">
                                                {{customerContacts.firstName}}
                                                {{customerContacts.lastName}}</strong>
                                            <strong *ngIf="customerContacts.email == null || customerContacts.email == ''" class="nameColorShade"
                                                tooltipPosition="top"  tooltipStyleClass="toolTipClass" [pTooltip]="'Email is missing'">
                                                {{customerContacts.firstName}}
                                                {{customerContacts.lastName}}</strong>

                                            <span *ngIf="customerContacts.updatedFromSalesForce == true"
                                                [pTooltip]="updatedFromSalesForceTrueTooltipMsg" [escape]="false"
                                                tooltipPosition="top" tooltipStyleClass="toolTipClass" class="fa-stack"
                                                style="font-size: 10px;">
                                                <i class="fa fa-pencil fa-stack-1x"></i>
                                                <i class="fa fa-ban fa-stack-2x fa-rotate-90 text-danger"></i>
                                            </span>
                                            <span *ngIf="customerContacts.updatedFromSalesForce == false">
                                                <a class="fa fa-pencil-square-o pencilShade"
                                                    style="text-decoration: none; cursor: pointer;" [pTooltip]="editMsg"
                                                    [escape]="false" tooltipPosition="top"
                                                    tooltipStyleClass="toolTipClass" onMouseOver="this.style.color='#ff0000'" onMouseOut="this.style.color='#000000'"
                                                    (click)="editContact(customerContacts,CustomerDetails.customer)">
                                                </a>

                                                <a class="fa fa-trash-o pencilShade" onMouseOver="this.style.color='#ff0000'"
                                                    style="text-decoration: none; cursor: pointer;" onMouseOut="this.style.color='#000000'"
                                                    [pTooltip]="deactivateIconMsg" [escape]="false"
                                                    tooltipPosition="top" tooltipStyleClass="toolTipClass"
                                                    (click)="deactivateContactPopup(customerContacts.id)"></a>
                                            </span>
                                        </li>
                                        <div>
                                            <p>
                                                <a *ngIf="!(customerContacts.email === null || customerContacts.email === '')" class="mail popoverMail cardPODetails" onMouseOver="this.style.color='#ff0000'"
                                                    onMouseOut="this.style.color='#000000'"
                                                    href="mailto:{{customerContacts.email}}">{{customerContacts.email}}<br></a>
                                                <small *ngIf="!(customerContacts.phone === null || customerContacts.phone === '')" class="phoneFontSize cardPODetails">
                                                    <i class="fa fa-phone"></i>
                                                    {{customerContacts.phone}}
                                                </small>
                                            </p>
                                        </div>
                                    </ul>
                                </div>
                            </div>
                            <!-- {{this.updatedFilteredDetails[indexOfelement].filterIsNotActiveDetails?.length|json}} -->
                            <div *ngIf="CustomerDetails.customerContacts?.length == 0  || this.hubDetailsAsPerPagination[indexOfelement]?.filterIsNotActiveDetails?.length == 0"
                                class="mb-5 text-center text-muted">
                                {{'admin_cc_no_active_contact_msg' | translate}}
                            </div>
                            <div class="row deactivatedDivAlign" *ngIf="this.hubDetailsAsPerPagination[indexOfelement]?.isActiveFalse === true"
                                [hidden]="CustomerDetails.customerContacts.length == 0 || isContact">
                                <div class="text-right col-xs-12 col-sm-6 col-md-4 mb-2 deactivateBtnCustContact">
                                    <button role="button" class="btnDeactivatedAlignment btnForCard btn-default" tooltip-trigger="mouseenter"
                                        tooltip-placement="left"
                                        (click)="setShowDeactivated(indexOfelement,$event,CustomerDetails)"
                                        [pTooltip]="deactivateShowMsg" [escape]="false" tooltipPosition="top"
                                        tooltipStyleClass="toolTipClass">
                                        <i class="fa fa-trash pencilShade"></i>
                                        <i class="fa fa-angle-down" *ngIf="!showDeactivated"></i>
                                        <i class="fa fa-angle-up" *ngIf="showDeactivated"></i>
                                    </button>
                                    <div *ngIf="!CustomerDetails.showDeactMsg" class="col-sm-5 deactivatedHiddenLabel">
                                        <h6 class="ps-1">{{'admin_cc_customers_sr_deactivated_lb' | translate}}</h6>
                                    </div>
                                </div>
                            </div>

                            <div *ngFor="let deActivatedCustomers of CustomerDetails?.deactivededCustomers">
                                <strong>{{deActivatedCustomers.showLabelDeactivated}}</strong>
                                <div style="max-height: 300px; overflow-y: auto;" *ngIf="!CustomerDetails.showContacts">
                                    <ul>
                                        <li *ngIf="deActivatedCustomers">
                                            <div class=" text-muted">
                                                <strong>{{deActivatedCustomers.firstName}}
                                                    {{deActivatedCustomers.lastName}}</strong>
                                                <a class="fa fa-pencil-square-o pencilShade"
                                                    style="text-decoration: none; cursor: pointer;" [pTooltip]="editMsg"
                                                    [escape]="false" tooltipPosition="top"
                                                    tooltipStyleClass="toolTipClass"
                                                    (click)="editContact(deActivatedCustomers,CustomerDetails.customer)"></a>

                                                <p class="text-muted">
                                                    <a class="mail popoverMail" onMouseOver="this.style.color='#ff0000'"
                                                        onMouseOut="this.style.color='#000000'" style="text-decoration: none;"
                                                        href="mailto:{{deActivatedCustomers.email}}"
                                                        class="text-muted">{{deActivatedCustomers.email}}</a><br>
                                                    <small class="phoneFontSize"
                                                        *ngIf="!(deActivatedCustomers.phone === null || deActivatedCustomers.phone === '')">
                                                        <i class="fa fa-phone"></i>
                                                        {{deActivatedCustomers.phone}}
                                                    </small>
                                                </p>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </ng-template>
                    </p-card>
                </div>
                <div class="row" *ngIf="showPagination">
                    <div class="col-sm-4"></div>
                    <div class="col-sm-4 div-align-items-center">
                        <ul class="pagination">
                            <li class="{{hasPrevious()?'':'disabled'}}" (click)="previousClicked()"><a>&laquo;</a></li>
                            <li *ngIf="showFirstPage()" (click)="gotoPage(1)"><a>1</a></li>
                            <li *ngIf="showFirstPageSeparator()"><span>...</span></li>
                            <li *ngIf="hasPrevious()" (click)="gotoPage(previousPage())"><a>{{previousPage()}}</a></li>
                            <li class="active"><a>{{currentPage}}</a></li>
                            <li *ngIf="hasNext()" (click)="gotoPage(nextPage())"><a>{{nextPage()}}</a></li>
                            <li *ngIf="showLastPageSeparator()"><span>...</span></li>
                            <li *ngIf="showLastPage()" (click)="gotoPage(pagesCount)"><a>{{pagesCount}}</a></li>
                            <li class="{{hasNext()?'':'disabled'}}"><a (click)="nextClicked()">&raquo;</a></li>
                        </ul>
                    </div>
                    <div class="col-sm-4"></div>
                </div>
            </div>
        </div>
    </p-card>
</form>
</div>
