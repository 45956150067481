import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { AdminProcessOwnerService } from 'src/app/services/admin-process-owner.service';
import { AdminAccountablePersonService } from 'src/app/services/admin-accountable-person.service';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { MessageService } from 'primeng/api';
import { AdminHubsService } from 'src/app/services/admin-hubs.service';
import { AccPerAuthService } from '../acc-per-auth.service';
import { ToasterserviceService } from 'src/app/services/toasterservice.service';



@Component({
  selector: 'app-admin-accountable-tree-add-popup',
  templateUrl: './admin-accountable-tree-add-popup.component.html',
  styleUrls: ['./admin-accountable-tree-add-popup.component.css']
})
export class AdminAccountableTreeAddPopupComponent implements OnInit {
  acccounableAddtreeForm!: FormGroup;
  @ViewChild('myForm', { static: false }) MyForm: NgForm;
  addAccountablePerson: any;
  data: any;
  ruid: any;
  filteredHubPO: any;
  hubPOdata: any;
  guid: any=[];

  constructor(private adminProcessOwnerService: AdminProcessOwnerService,
    private adminAccountablePersonService: AdminAccountablePersonService,
    private ref: DynamicDialogRef, public config: DynamicDialogConfig,
    private fb: FormBuilder, private messageService: MessageService,
    private readonly dialogRef: DynamicDialogRef,
    private adminHubsService:AdminHubsService,
    private apAuthService: AccPerAuthService,
    private toasterService: ToasterserviceService) {

    // for (let index = 0; index < this.data.ruId.length; index++) {
    //   this.accountableIdData = [index];

    // }
  }

  filteredSubstitude: any[]

  ngOnInit(): void {
    this.data = this.config.data;
    this.acccounableAddtreeForm = this.fb.group({
      'employee': ['',Validators.required],

    })
  }


  filterHubProcessOwner(event) {
    const dataPasses = {
      wildcard: event.originalEvent.target.value,
      onlyActive: true,
      page: 1,
      size: 26
    };
    if (event.originalEvent.target.value.length > 1) {
      this.adminHubsService.getHubPOUser(dataPasses).subscribe((response) => {

        this.filteredHubPO = response;
        console.log(response, 'hubpo user');

        this.filteredHubPO.map((data) => {
          data.name = data.name + '<' + data.email + '>';
        })
        this.filteredHubPO.value.forEach(customer => {
          if (!this.hubPOdata.guids.includes(customer?.id)) {
            this.hubPOdata.guids.push(customer?.id)
          }
        });

      })
    }

  }
  customerSelect(event) {
    this.acccounableAddtreeForm.controls['employee'].value.forEach(customer => {
      if (!this.guid.includes(customer?.id)) {
        this.guid.push(customer?.id)


      }
    });
  }

  customerGuidsUnselect(event) {
    const unselectedGuids = event.id;
    const index = this.guid.findIndex(item => item === unselectedGuids);
    if (index !== -1) {
      this.guid.splice(index, 1)
    }
  }


  onClickAdd() {
    let selectedRus : any = [];
    selectedRus.push(this.data.cardinfo);

    const ruIds = this.data.ruId.map((r: any) => (r.id));
    const ruSelectedIds = ruIds[this.config.data?.cardIndex];
    const processStepId = this.config.data?.cardData?.id;

    let formatData = {
      "ruIds": ruSelectedIds,
      // "accountablePersonGeids": [this.acccounableAddtreeForm.controls['employee']?.value],
      // "accountablePersonGeids": [this.acccounableAddtreeForm.controls['employee']?.value],
      "accountablePersonGeids":this.guid,
      "processStepIds": processStepId,
      "isActive": true
    }


    if (formatData.accountablePersonGeids.length == 0) {
      this.messageService.add({ severity: 'success', summary: 'Cannot Add Accountable Person ', detail: ' ' });
      setTimeout(() => {
      }, 1000)
      return
    }

    if(this.apAuthService.canManageRus(selectedRus)){

      this.adminAccountablePersonService.addAccountablePerson(formatData).subscribe((res) => {
        this.addAccountablePerson = res;
        this.toasterService.showSuccess('Accountable Person(s) has been added sucessfuly');
      })
      // this.messageService.add({ severity: 'success', summary: 'Accountable Person(s) has been added sucessfuly', detail: ' ' });
      // setTimeout(() => {

      // }, 1000)
      this.dialogRef.close('response');
    }
    else{
      this.toasterService.showError('Cannot add this Ac Person(s). Insufficient permissions.');
    }
  }

  onClose() {
    this.dialogRef.close();
  }

  checkBlank(content) {
    if (content == undefined || content == null) {
      return '';
    } else {
      return content;
    }
  }

}
