import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { NgxSpinner, NgxSpinnerService } from 'ngx-spinner';
import { MdRegionService } from 'src/app/services/md-region.service';
import { MdServiceService } from 'src/app/services/md-service.service';
import { filter } from 'lodash';
import { FailuremodeService } from '../failuremode.service';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { FailuremodeAuthService } from '../failuremode-auth.service';
import { PgrcaAdminServiceService } from '../../pgrcaCategoryModule/pgrca-admin-service.service';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { PgCategoryPopUpComponent } from '../../pgrcaCategoryModule/pg-category-pop-up/pg-category-pop-up.component';

@Component({
  selector: 'app-admin-failuremode',
  templateUrl: './admin-failuremode.component.html',
  styleUrls: ['./admin-failuremode.component.css']
})
export class AdminFailuremodeComponent implements OnInit {

  divisions: any;
  buOrgList: any;
  bulist: any;
  pgOrgList: any;
  pglist: any;

  selected: any = {
    division: 'PG',
    bu: '',
    pg: ''
  }

  cachedPg: any = {
    pg: '',
    items: []
  };

  isSearching: boolean = false;
  allowManage: boolean = false;

  items: any;

  hasRootChild;

  failureModeForm!: FormGroup;

  divisionsCopy: any;
  buListCopy: any;
  pgListCopy: any;

  constructor(private mdData: MdServiceService,
    private mdRegionService: MdRegionService,
    private spinner: NgxSpinnerService,
    private fb: FormBuilder,
    private failureModeService:FailuremodeService,
    private breadcrumbService: BreadcrumbService,
    private failureModeAuthService: FailuremodeAuthService,
    private pgrcaAdminService: PgrcaAdminServiceService,
    private ref: DynamicDialogRef,
    private dialogService: DialogService) { }

  ngOnInit(): void {
    this.breadcrumbService.menuItems = [
      { label: 'ADMIN PANEL', routerLink: 'admin'},
      { label: 'MANAGE FAILURE MODE'}
    ];

    this.failureModeAuthService.init();

    this.mdData.divisions().then((r) => {
    this.divisions = r;
    this.divisionsCopy = this.divisions;

    this.failureModeForm.patchValue({
        business: 'PG'
      })
    });

    this.mdData.bu().then((r) => {
      this.buOrgList = r;
      this.buOrgList.map((bu)=>{
        bu.labelName = '(' + bu?.id + ') - ' + bu?.name;
      })
      this.bulist = this.buOrgList;
      this.buListCopy = this.bulist;
    });

    this.mdData.pg().then((r) => {
      this.pgOrgList = r;
      this.pgOrgList.map((pg)=>{
        pg.labelName = '(' + pg?.id + ') - ' + pg?.name + ' (' + pg?.buCode +')';
      })
      this.pglist = this.pgOrgList;
      this.pgListCopy = this.pglist;
    });


    this.failureModeForm = this.fb.group({
      'business': [''],
      'bu': [''],
      'pg': [''],
    });
    // this.failureModeForm.get('pg').valueChanges.subscribe((val)=>{
    //   console.log(val)
    // })
  }


  divChanged() {
    this.selected.bu = '';
    this.selected.pg = '';
    this.items = undefined;

    this.refreshBus();
    this.refreshPgs();
  };

  businessChange(event){
    this.failureModeForm.controls['bu'].reset();
    this.failureModeForm.controls['pg'].reset();
    this.selected.bu = '';
    this.selected.pg = '';
    this.items = undefined;

    this.bulist = this.buListCopy;
    this.pglist = this.pgListCopy;
  }

  buChanged(event) {
    this.failureModeForm.controls['pg'].reset();
    this.selected.pg = '';
    this.items = undefined;

    let filteredData: any = [];
    let query = event?.value;
    if(query != null){
      this.pgListCopy?.filter((obj)=>{
        if(obj?.buCode == query)
            filteredData.push(obj)
      })
      this.pglist = filteredData
    }
    else{
      this.pglist = this.pgListCopy;
    }

    //this.refreshPgs();
  };

  refreshBus() {
    this.bulist = this.buOrgList;
    if (this.selected.division.length == 0) {
      return;
    }
    // this.bulist = $filter('idcodeFilter')(this.bulist, 'divCode', this.selected.division);
    this.bulist = filter(this.bulist,{'divCode': this.selected.division});

  };
  refreshPgs() {
    this.pglist = this.pgOrgList;
    if (this.selected.division.length > 0) {
      // this.pglist = $filter('idcodeFilter')(this.pglist, 'divCode', this.selected.division);
      this.pglist = filter(this.pglist,{'divCode': this.selected.division});
    }
    if (this.selected.bu.length > 0) {
      //this.pglist = $filter('idcodeFilter')(this.pglist, 'buCode', this.selected.bu);
      this.pglist = filter(this.pglist,{'buCode': this.selected.bu});

    }
  };

  findIdInTree(element, matchingId) {
    if (element.id == matchingId) {
      return element;
    } else if (element.children != null) {
      var result = null;
      for (var i = 0; result == null && i < element.children.length; i++) {
        result = this.findIdInTree(element.children[i], matchingId);
      }
      return result;
    }
    return null;
  };

  setNodesState(nodeItem) {
    if (null != nodeItem.nodesToUpdate) {
      for (var i = 0; i < nodeItem.nodesToUpdate.length; i++) {
        var node = this.findIdInTree(this.items[0], nodeItem.nodesToUpdate[i]);
        if (undefined !== node && node !== null) {
          node.isActive = nodeItem.state;
        }
      }
    }
  }

  unblockPgCategoryUI() {
    this.isSearching = false;
    this.spinner.hide();
  };

  blockPgcategoryUI() {
    this.isSearching = true;
    this.spinner.show();
  };

  refreshNodes(item) {
    if (undefined !== item) {
      this.setNodesState(item);
    }
    this.hasRootChild = this.items[0].isExpanded || false;
  };

  search(pgId) {

    this.selected.division = this.failureModeForm.get('business').value;
    this.selected.bu = this.failureModeForm.get('bu').value;
    this.selected.pg = this.failureModeForm.get('pg').value;
    this.selected = Object.assign({}, this.selected);

    this.getProcessOwnerData();

    this.isSearching = true;
    this.spinner.show();
    this.refreshResults(pgId.value);

  };

  refreshResults(pgId) {
    this.spinner.show();
    this.isSearching = true;
    this.items = undefined;
    if (this.cachedPg.pg === pgId) {
      this.cachedPg.items = this.items;
    } else {
      this.cachedPg.pg = '';
      this.cachedPg.items = [];
    }

    this.failureModeService.loadTree(this.selected.pg).subscribe(
      (data) => {
        this.spinner.hide();
        this.isSearching = false;
        if (data.length > 0) {
          if(this.failureModeForm.get('bu')?.value == 'PGHV') {
             this.items = data;
             this.items[0].children = this.items[0]?.children.filter(item => item.name.includes('PGHV Failure'));
           }
           else {
             this.items = data;
           }
          this.hasRootChild = data[0].isExpanded;
          this.allowManage = this.failureModeAuthService.canManagePgCategory({ pg: this.selected.pg });
          this.cachedPg.pg = pgId;
        }
      },
      (err) => {
        this.isSearching = false;
        this.spinner.hide();
      }
    );

  }


  pgrcaCatProcessOwner: any;
  pgrcaCatProcessOwnerGeidData: any;
  pgrcaCatProcessOwnerGeid = [];
  data1: { geids: any };
  pgrcaCatEmployeeInfo: any;


  getProcessOwnerData(){
    const data = {
      division: this.failureModeForm.get('business').value,
      bu: this.failureModeForm.get('bu').value,
      pg: this.failureModeForm.get('pg').value,
    };

    this.pgrcaAdminService.getDataForAdminPgrcaCatProcessOwner(data).subscribe((res)=>{
      this.pgrcaCatProcessOwner = res;
      this.pgrcaCatProcessOwnerGeidData = res.map((a) => a.geid);

      for (let i = 0; i < this.pgrcaCatProcessOwnerGeidData.length; i++){
        this.pgrcaCatProcessOwnerGeid.push(
          this.pgrcaCatProcessOwnerGeidData[i]
        );

        this.data1 = {
          geids: this.pgrcaCatProcessOwnerGeid.toString(),
        };

      }
      this.pgrcaAdminService.getDataForAdminPgrcaCatEmployeeInfo(this.data1).subscribe((res)=>{
        this.pgrcaCatEmployeeInfo = res;
      })
    })
  }

  showPGPopUp(){
    this.ref = this.dialogService.open(PgCategoryPopUpComponent, {
      header: 'Process Owners',
      styleClass: 'pgCategoryProcessOwner',
      position: 'top',
      width: '50%',
      data: {
        processOwner: this.pgrcaCatProcessOwner,
        employeeInfo: this.pgrcaCatEmployeeInfo,
      }
    })
  }
}
