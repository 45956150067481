<div class="processStep">
    <app-process-step-item [collection]='items' [canManage]='allowManage' [disableActions]='isSearching'
    [level]="-1" levelValidationMessage="{{'admin_process_steps_item_lvl_validation_msg' | translate}}"
    ></app-process-step-item>



<div *ngIf="!hasRootChild && items" class="text-center">
    <span class="text-muted">{{'admin_process_steps_search_no_childs_msg' | translate}}</span>
</div>

<div class="text-center" *ngIf="!items">
    <span class="text-muted">{{'admin_process_steps_search_msg' | translate}}</span>
</div>

</div>