<div class="globalRUMainDiv">
<div class="panel-heading mt-3">
  <div class="row ms-1 headingMainDivGlobalRU">
    <div class="col-sm-6 businessActvtyGlobalRU">Business Activity</div>
    <div class="col-sm-1 alignCenter activeGlobalRU">Is Active</div>
    <div class="class-sm-5 emptyClassGlobalRU"></div>
  </div>
</div>
<div class="panel-body pt-2 pb-2">
    <div class="row mb-2 ms-1 globalRUBodyDiv" *ngFor="let gru of globalRus | orderGlobalRU : ['divCode', 'buCode'] | filter : 'canManage' : true">
        <span class="col-sm-6 globalRULabelBody" [ngClass]="{ 'item-selected': gru.isDirty }">
            <span class="spacerMedium" *ngIf="gru.isBuBAL">&nbsp;</span>
            <span class="spacerBig" *ngIf="gru.isPgBAL">&nbsp;</span>
            <label class="control-label spacerDesc">{{ gru.description }}</label>
        </span>
        <span class="col-sm-1 alignCenter globalRUBodyisActive">
            <span class="fa admin-link" (click)="markSelection(gru)" *ngIf="gru.canManage && gru.hasGlobalRU"
            [ngClass]="{
                'fa-square-o': !gru.isSelected,
                'fa-check-square-o': gru.isSelected
                }">
            </span>
        </span>
        <span class="col-sm-5 globalRUBodyBtn">
            <button class="widgetButtoSmall" (click)="updateActivity(gru)" *ngIf="gru.isDirty" [disabled]="isLoading">Update</button>
            <button class="widgetButtoSmall" (click)="createBAL(gru)" *ngIf="!gru.hasGlobalRU">Create</button>
        </span>
    </div>
</div>
</div>