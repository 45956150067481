import {
  Component,
  OnInit,
  Input,
  Output,
  SimpleChanges,
  OnChanges,
  AfterViewInit,
  EventEmitter,
} from '@angular/core';
import { FormControl, FormGroupDirective, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { IncidentReviewerAndImpactDataModel } from 'src/app/models/new-issue/incident-reviewer-and-impact-data.model';
import { OriginalConfigurationService } from 'src/app/services/original-configuration.service';
import { StaticvalueConstants } from 'src/app/reusables/constants/staticvalue.constants';
import { ComplaintStatuses, PermissionCode, RoleCode } from 'src/app/enum';
import { AuthorizationService } from 'src/app/services/authorization.service';
import { MatrixClass } from 'src/app/models/new-issue/MatrixClass';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-original-rating-config',
  templateUrl: './original-rating-config.component.html',
  styleUrls: ['./original-rating-config.component.css'],
})
export class OriginalRatingConfigComponent implements OnInit, OnChanges {
  public impactRatingItems!: Array<{ id: string; text: string }>;
  isClassShown = true;
  isImpactRatingShown = false;
  public availabilityRatingItems!: Array<{
    value: number;
    text: string;
    weight: number;
  }>;
  public functionalityRatingItems!: Array<{
    value: number;
    text: string;
    weight: number;
  }>;
  public onTimeDeliveryRatingItems!: Array<{
    value: number;
    text: string;
    weight: number;
  }>;
  public cyberSecurityRatingItems!: Array<{
    value: number;
    text: string;
    weight: number;
  }>;
  public productComplianceRatingItems!: Array<{
    value: number;
    text: string;
    weight: number;
  }>;
  public ohsRiskRatingItems!: Array<{
    value: number;
    text: string;
    weight: number;
  }>;
  public marginRatingItems!: Array<{
    value: number;
    text: string;
    weight: number;
  }>;
  public reputationRatingItems!: Array<{
    value: number;
    text: string;
    weight: number;
  }>;
  public massMediaRatingItems!: Array<{
    value: number;
    text: string;
    weight: number;
  }>;
  public designChangeRatingItems!: Array<{
    value: number;
    text: string;
    weight: number;
  }>;
  public instancesRatingItems!: Array<{
    value: number;
    text: string;
    weight: number;
  }>;
  public resolutionRatingItems!: Array<{
    value: number;
    text: string;
    weight: number;
  }>;
  public affectedCustomerRatingItems!: Array<{
    value: number;
    text: string;
    weight: number;
  }>;
  public controlRatingItems!: Array<{
    value: number;
    text: string;
    weight: number;
  }>;

  @Input() public formData!: IncidentReviewerAndImpactDataModel;
  public ratingForm!: FormGroup;
  @Input() formGroupName!: string;
  @Input() isSubmitted: boolean = false;
  @Input() complaintData!: any;
  @Input() issueCaptureMainForm: FormGroup;
  @Output() classOriginalValue = new EventEmitter();
  @Input() complaintValidations: any = [];
  @Input() ActionNameForComplaints: string = "Submit";
  @Input() fieldNameForFailureMode: string = "None";

  getMatrixTreeActiveVariable: any;
  availabilty: any;
  Functionality: any;
  On_Time_Delivery: any;
  Cyber_Security: any;
  Product_Compliance: any;
  OHS_Risk: any;
  Margin: any;
  Reputation: any;
  Mass_media: any;
  Design_change: any;
  Instances: any;
  Affected_customer: any;
  Resolution: any;
  Control: any;

  availabiltyLabel: any;
  FunctionalityLabel: any;
  On_Time_DeliveryLabel: any;
  ControlLabel: any;
  Affected_customerLabel: any;
  ResolutionLabel: any;
  InstancesLabel: any;
  Design_changeLabel: any;
  Mass_mediaLabel: any;
  ReputationLabel: any;
  MarginLabel: any;
  OHS_RiskLabel: any;
  Product_ComplianceLabel: any;
  Cyber_SecurityLabel: any;
  availabilityRatingConf!: any[];
  functionalityRatingConf!: any[];
  onTimeDeliveryRatingConf!: any[];
  cyberSecurityRatingConf!: any[];
  productComplianceRatingConf!: any[];
  ohsRiskRatingConf!: any[];
  marginRatingConf!: any[];
  reputationRatingConf!: any[];
  massMediaRatingConf!: any[];
  designChangeRatingConf!: any[];
  instancesRatingConf!: any[];
  resolutionRatingConf!: any[];
  affectedCustomerRatingConf!: any[];
  controlRatingConf!: any[];

  enumPermission = PermissionCode;
  enumRoleCode = RoleCode;
  enumComplaintStatuses = ComplaintStatuses;
  userAccessData = null
  @Input() public currentIssueStatus: any;
  @Input() public currentUserRole: any;
  showUnconfirmedRating: boolean = false;
  alreadyPatched: boolean = false;
  complaintCreatedDate:any = new Date();
  ncrLiveDate:any = new Date(environment.ncrLiveDate);

  constructor(
    private originalConfigService: OriginalConfigurationService,
    private rootFormGroup: FormGroupDirective,
    private messageService: MessageService,
    private authorizationService: AuthorizationService,

  ) {}

  ngOnInit(): void {
    this.ratingForm = this.rootFormGroup.control.get(
      this.formGroupName
    ) as FormGroup;
    this.originalConfigService.ratingConfiguration().subscribe((response) => {
      this.getMatrixTreeActiveVariable = response;

      this.availabilty = this.getMatrixTreeActiveVariable.data[0].matrixSeverity;
      this.availabiltyLabel = this.getMatrixTreeActiveVariable.data[0].name;
      this.Functionality = this.getMatrixTreeActiveVariable.data[1].matrixSeverity;
      this.FunctionalityLabel = this.getMatrixTreeActiveVariable.data[1].name;
      this.On_Time_Delivery = this.getMatrixTreeActiveVariable.data[2].matrixSeverity;
      this.On_Time_DeliveryLabel = this.getMatrixTreeActiveVariable.data[2].name;
      this.Cyber_Security = this.getMatrixTreeActiveVariable.data[3].matrixSeverity;
      this.Cyber_SecurityLabel = this.getMatrixTreeActiveVariable.data[3].name;
      this.Product_Compliance = this.getMatrixTreeActiveVariable.data[4].matrixSeverity;
      this.Product_ComplianceLabel = this.getMatrixTreeActiveVariable.data[4].name;
      this.OHS_Risk = this.getMatrixTreeActiveVariable.data[5].matrixSeverity;
      this.OHS_RiskLabel = this.getMatrixTreeActiveVariable.data[5].name;
      this.Margin = this.getMatrixTreeActiveVariable.data[6].matrixSeverity;
      this.MarginLabel = this.getMatrixTreeActiveVariable.data[6].name;
      this.Reputation = this.getMatrixTreeActiveVariable.data[7].matrixSeverity;
      this.ReputationLabel = this.getMatrixTreeActiveVariable.data[7].name;
      this.Mass_media = this.getMatrixTreeActiveVariable.data[8].matrixSeverity;
      this.Mass_mediaLabel = this.getMatrixTreeActiveVariable.data[8].name;
      this.Design_change = this.getMatrixTreeActiveVariable.data[9].matrixSeverity;
      this.Design_changeLabel = this.getMatrixTreeActiveVariable.data[9].name;
      this.Instances = this.getMatrixTreeActiveVariable.data[10].matrixSeverity;
      this.InstancesLabel = this.getMatrixTreeActiveVariable.data[10].name;
      this.Resolution = this.getMatrixTreeActiveVariable.data[11].matrixSeverity;
      this.ResolutionLabel = this.getMatrixTreeActiveVariable.data[11].name;
      this.Affected_customer = this.getMatrixTreeActiveVariable.data[12].matrixSeverity;
      this.Affected_customerLabel = this.getMatrixTreeActiveVariable.data[12].name;
      this.Control = this.getMatrixTreeActiveVariable.data[13].matrixSeverity;
      this.ControlLabel = this.getMatrixTreeActiveVariable.data[13].name;

      //   this.calculateRpnAndImpactRating(false);

      this.availabilityRatingItems = this.availabilty.sort((a, b) => { return a.weight - b.weight })
        .filter((a) => a.isActive === true)
        .map((a) => {
          return { value: a.id, text: a.description, weight: a.weight };
        });

      this.functionalityRatingItems = this.Functionality.sort((a, b) => { return a.weight - b.weight }).filter(
        (a) => a.isActive === true
      ).map((a) => {
        return { value: a.id, text: a.description, weight: a.weight };
      });

      this.onTimeDeliveryRatingItems = this.On_Time_Delivery.sort((a, b) => { return a.weight - b.weight }).filter(
        (a) => a.isActive === true
      ).map((a) => {
        return { value: a.id, text: a.description, weight: a.weight };
      });

      this.cyberSecurityRatingItems = this.Cyber_Security.sort((a, b) => { return a.weight - b.weight }).filter(
        (a) => a.isActive === true
      ).map((a) => {
        return { value: a.id, text: a.description, weight: a.weight };
      });

      this.productComplianceRatingItems = this.Product_Compliance.sort((a, b) => { return a.weight - b.weight }).filter(
        (a) => a.isActive === true
      ).map((a) => {
        return { value: a.id, text: a.description, weight: a.weight };
      });

      this.ohsRiskRatingItems = this.OHS_Risk.sort((a, b) => { return a.weight - b.weight }).filter(
        (a) => a.isActive === true
      ).map((a) => {
        return { value: a.id, text: a.description, weight: a.weight };
      });

      this.marginRatingItems = this.Margin.sort((a, b) => { return a.weight - b.weight }).filter(
        (a) => a.isActive === true
      ).map((a) => {
        return { value: a.id, text: a.description, weight: a.weight };
      });

      this.reputationRatingItems = this.Reputation.sort((a, b) => { return a.weight - b.weight }).filter(
        (a) => a.isActive === true
      ).map((a) => {
        return { value: a.id, text: a.description, weight: a.weight };
      });

      this.massMediaRatingItems = this.Mass_media.sort((a, b) => { return a.weight - b.weight }).filter(
        (a) => a.isActive === true
      ).map((a) => {
        return { value: a.id, text: a.description, weight: a.weight };
      });

      this.designChangeRatingItems = this.Design_change.sort((a, b) => { return a.weight - b.weight }).filter(
        (a) => a.isActive === true
      ).map((a) => {
        return { value: a.id, text: a.description, weight: a.weight };
      });

      this.instancesRatingItems = this.Instances.sort((a, b) => { return a.weight - b.weight }).filter(
        (a) => a.isActive === true
      ).map((a) => {
        return { value: a.id, text: a.description, weight: a.weight };
      });

      this.resolutionRatingItems = this.Resolution.sort((a, b) => { return a.weight - b.weight }).filter(
        (a) => a.isActive === true
      ).map((a) => {
        return { value: a.id, text: a.description, weight: a.weight };
      });

      this.affectedCustomerRatingItems = this.Affected_customer.sort((a, b) => { return a.weight - b.weight }).filter(
        (a) => a.isActive === true
      ).map((a) => {
        return { value: a.id, text: a.description, weight: a.weight };
      });

      this.controlRatingItems = this.Control.sort((a, b) => { return a.weight - b.weight }).filter(
        (a) => a.isActive === true
      ).map((a) => {
        return { value: a.id, text: a.description, weight: a.weight };
      });

      if (this.complaintData == undefined) {
        this.ratingForm.patchValue({
          availabilityRating: this.availabilityRatingItems[0],
          functionalityRating: this.functionalityRatingItems[0],
          onTimeDeliveryRating: this.onTimeDeliveryRatingItems[0],
          cyberSecurityRating: this.cyberSecurityRatingItems[0],
          productComplianceRating: this.productComplianceRatingItems[0],
          ohsRiskRating: this.ohsRiskRatingItems[0],
          marginRating: this.marginRatingItems[0],
          reputationRating: this.reputationRatingItems[0],
          massMediaRating: this.massMediaRatingItems[0],
          designChangeRating: this.designChangeRatingItems[0],
          instancesRating: this.instancesRatingItems[0],
          resolutionRating: this.resolutionRatingItems[0],
          affectedCustomerRating: this.affectedCustomerRatingItems[0],
          controlRating: this.controlRatingItems[0],
        });
      } else {
        this.mapRating();
        this.onShowNewMatrixOriginalRating_Rpn('old_Rpn');
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    this.setFieldsValidation(this.ActionNameForComplaints);
    if (this.complaintData != undefined && this.alreadyPatched==false) {
      this.mapRating();
      this.onShowNewMatrixOriginalRating_Rpn('old_Rpn');
      this.alreadyPatched=true;
    }
    this.authorizationService.userAccessDetail.subscribe(async detail => {
      this.userAccessData = detail;
    });
  }

  setFieldsValidation(action) {
   // console.log("if")
    for (const controlName in this.issueCaptureMainForm?.get('issueCaptureForm')['controls']) {
      let validationData = this.complaintValidations.find(complaintValidation => complaintValidation.property?.toLowerCase() == controlName?.toLowerCase() && complaintValidation.complaintAction == action && complaintValidation?.complaintStatusIdsArray?.includes(this.issueCaptureMainForm?.get('headerSection').get('complaintStatusId')?.value?.toString())  && (this.userAccessData==null ? this.userAccessData==null : complaintValidation?.roleIdsArray?.includes(this.userAccessData!['roleCode']?.toString())))
      // console.log(this.complaintValidations,validationData,controlName);
      if (validationData) {
        // console.log(">>>>",this.issueCapture);
        const control = this.issueCaptureMainForm?.get('issueCaptureForm').get(controlName);
        const validationRules = this.complaintValidations.filter(complaintValidation => complaintValidation.property?.toLowerCase() == controlName?.toLowerCase() && complaintValidation.complaintAction == action && complaintValidation?.complaintStatusIdsArray?.includes(this.issueCaptureMainForm?.get('headerSection').get('complaintStatusId')?.value?.toString())  && (this.userAccessData==null ? this.userAccessData==null : complaintValidation?.roleIdsArray?.includes(this.userAccessData!['roleCode']?.toString())));

        control.setValidators(this.getValidators(validationRules,this.fieldNameForFailureMode));
        control.updateValueAndValidity(); // Update the validation status
      }
      else {
        // console.log("else for: ", controlName);
        this.issueCaptureMainForm?.get('issueCaptureForm').get(controlName).clearValidators();
        this.issueCaptureMainForm?.get('issueCaptureForm').get(controlName).updateValueAndValidity();
      }
    }
    for (const controlName in this.issueCaptureMainForm?.get('issueDescriptionForm')['controls']) {
      let validationData = this.complaintValidations.find(complaintValidation => complaintValidation.property?.toLowerCase() == controlName?.toLowerCase() && complaintValidation.complaintAction == action && complaintValidation?.complaintStatusIdsArray?.includes(this.issueCaptureMainForm?.get('headerSection').get('complaintStatusId')?.value?.toString())  && (this.userAccessData==null ? this.userAccessData==null : complaintValidation?.roleIdsArray?.includes(this.userAccessData!['roleCode']?.toString())))
      // console.log(this.complaintValidations,validationData,controlName);
      if (validationData) {
        // console.log(">>>>",this.issueCapture);
        const control = this.issueCaptureMainForm?.get('issueDescriptionForm').get(controlName);
        const validationRules = this.complaintValidations.filter(complaintValidation => complaintValidation.property?.toLowerCase() == controlName?.toLowerCase() && complaintValidation.complaintAction == action && complaintValidation?.complaintStatusIdsArray?.includes(this.issueCaptureMainForm?.get('headerSection').get('complaintStatusId')?.value?.toString())  && (this.userAccessData==null ? this.userAccessData==null : complaintValidation?.roleIdsArray?.includes(this.userAccessData!['roleCode']?.toString())));

        control.setValidators(this.getValidators(validationRules,this.fieldNameForFailureMode));
        control.updateValueAndValidity(); // Update the validation status
      }
      else {
          this.issueCaptureMainForm?.get('issueDescriptionForm').get(controlName).clearValidators();
          this.issueCaptureMainForm?.get('issueDescriptionForm').get(controlName).updateValueAndValidity();
      }
    }
    for (const controlName in this.issueCaptureMainForm?.get('ratingForm')['controls']) {
      let validationData = this.complaintValidations.find(complaintValidation => complaintValidation.property?.toLowerCase() == controlName?.toLowerCase() && complaintValidation.complaintAction == action && complaintValidation?.complaintStatusIdsArray?.includes(this.issueCaptureMainForm?.get('headerSection').get('complaintStatusId')?.value?.toString())  && (this.userAccessData==null ? this.userAccessData==null : complaintValidation?.roleIdsArray?.includes(this.userAccessData!['roleCode']?.toString())))
      // console.log(this.complaintValidations,validationData,controlName);
      if (validationData) {
        // console.log(">>>>",this.issueCapture);
        const control = this.issueCaptureMainForm?.get('ratingForm').get(controlName);
        const validationRules = this.complaintValidations.filter(complaintValidation => complaintValidation.property?.toLowerCase() == controlName?.toLowerCase() && complaintValidation.complaintAction == action && complaintValidation?.complaintStatusIdsArray?.includes(this.issueCaptureMainForm?.get('headerSection').get('complaintStatusId')?.value?.toString())  && (this.userAccessData==null ? this.userAccessData==null : complaintValidation?.roleIdsArray?.includes(this.userAccessData!['roleCode']?.toString())));

        control.setValidators(this.getValidators(validationRules,this.fieldNameForFailureMode));
        control.updateValueAndValidity(); // Update the validation status
      }
      else {
        // console.log("else for: ", controlName);
        this.issueCaptureMainForm?.get('ratingForm').get(controlName).clearValidators();
        this.issueCaptureMainForm?.get('ratingForm').get(controlName).updateValueAndValidity();
      }
    }
    for (const controlName in this.issueCaptureMainForm?.get('additionalSubjectForm')['controls']) {
      let validationData = this.complaintValidations.find(complaintValidation => complaintValidation.property?.toLowerCase() == controlName?.toLowerCase() && complaintValidation.complaintAction == action && complaintValidation?.complaintStatusIdsArray?.includes(this.issueCaptureMainForm?.get('headerSection').get('complaintStatusId')?.value?.toString())  && (this.userAccessData==null ? this.userAccessData==null : complaintValidation?.roleIdsArray?.includes(this.userAccessData!['roleCode']?.toString())))
      // console.log(this.complaintValidations,validationData,controlName);
      if (validationData) {
        // console.log(">>>>",this.issueCapture);
        const control = this.issueCaptureMainForm?.get('additionalSubjectForm').get(controlName);
        const validationRules = this.complaintValidations.filter(complaintValidation => complaintValidation.property?.toLowerCase() == controlName?.toLowerCase() && complaintValidation.complaintAction == action && complaintValidation?.complaintStatusIdsArray?.includes(this.issueCaptureMainForm?.get('headerSection').get('complaintStatusId')?.value?.toString())  && (this.userAccessData==null ? this.userAccessData==null : complaintValidation?.roleIdsArray?.includes(this.userAccessData!['roleCode']?.toString())));

        control.setValidators(this.getValidators(validationRules,this.fieldNameForFailureMode));
        control.updateValueAndValidity(); // Update the validation status
      }
      else {
        // console.log("else for: ", controlName);
        this.issueCaptureMainForm?.get('additionalSubjectForm').get(controlName).clearValidators();
        this.issueCaptureMainForm?.get('additionalSubjectForm').get(controlName).updateValueAndValidity();
      }
    }
  }
  private getValidators(rules: any[], fieldFailureModeValidation): any {
    let validators: Validators[] = [];
    let index = 0;
    for (const rule of rules) {
      index = index + 1;
      if (rule.depends != null) {
        // console.log("<><><><<><>",rule.property,rule.depends,this.issueCapture.controls![rule.depends]?.value?.toString()?.toLowerCase() , rule.value?.toLowerCase() , this.issueDescription.controls![rule.depends]?.value?.toString()?.toLowerCase() , rule.value?.toLowerCase(), this.issueDescription.controls![rule.depends]?.value?.toString()?.toLowerCase() == rule.value?.toLowerCase() , this.issueRating.controls![rule.depends]?.value?.toString()?.toLowerCase() , rule.value?.toLowerCase() , this.additionalSubject.controls![rule.depends]?.value?.toString()?.toLowerCase() , rule.value?.toLowerCase())
        if(fieldFailureModeValidation!="None" && rule.depends=="Validation" && rule.value == fieldFailureModeValidation){
          validators.push(Validators.required);
          // console.log("=======>>", rule.property, rule.depends, rule.value,);
          return validators;
        }
       else if ((this.issueCaptureMainForm.controls["issueCaptureForm"].get(rule.depends)?.value!=undefined && this.issueCaptureMainForm.controls["issueCaptureForm"].get(rule.depends)?.value?.toString()?.toLowerCase() == rule.value?.toLowerCase()) || (this.issueCaptureMainForm.controls["issueDescriptionForm"].get(rule.depends)?.value!=undefined && this.issueCaptureMainForm.controls["issueDescriptionForm"].get(rule.depends)?.value?.toString()?.toLowerCase() == rule.value?.toLowerCase()) || (this.issueCaptureMainForm.controls["ratingForm"].get(rule.depends)?.value!=undefined && this.issueCaptureMainForm.controls["ratingForm"].get(rule.depends)?.value?.toString()?.toLowerCase() == rule.value?.toLowerCase()) || (this.issueCaptureMainForm.controls["additionalSubjectForm"].get(rule.depends)?.value!=undefined && this.issueCaptureMainForm.controls["additionalSubjectForm"].get(rule.depends)?.value?.toString()?.toLowerCase() == rule.value?.toLowerCase())) {
          validators.push(Validators.required);
          // console.log("=======", rule.property, rule.depends, rule.value,);
          return validators;
        }
        else {
          if(index==rules?.length){
            return validators;
          }
        }
      }
      else {
        validators.push(Validators.required);
        return validators;
      }
    }
  }


  mapRating() {
    this.complaintCreatedDate = new Date(this.complaintData?.header?.createDate);
    this.ratingForm.patchValue({
      availabilityRating: this.complaintData?.areaComplaintCollection?.complaintInformationSection?.matrixOriginalRatingViewModel?.matrixSelection[0]?.selectionId,
      functionalityRating: this.complaintData?.areaComplaintCollection?.complaintInformationSection?.matrixOriginalRatingViewModel?.matrixSelection[1]?.selectionId,
      onTimeDeliveryRating: this.complaintData?.areaComplaintCollection?.complaintInformationSection?.matrixOriginalRatingViewModel?.matrixSelection[2]?.selectionId,
      cyberSecurityRating: this.complaintData?.areaComplaintCollection?.complaintInformationSection?.matrixOriginalRatingViewModel?.matrixSelection[3]?.selectionId,
      productComplianceRating: this.complaintData?.areaComplaintCollection?.complaintInformationSection?.matrixOriginalRatingViewModel?.matrixSelection[4]?.selectionId,
      ohsRiskRating: this.complaintData?.areaComplaintCollection?.complaintInformationSection?.matrixOriginalRatingViewModel?.matrixSelection[5]?.selectionId,
      marginRating: this.complaintData?.areaComplaintCollection?.complaintInformationSection?.matrixOriginalRatingViewModel?.matrixSelection[6]?.selectionId,
      reputationRating: this.complaintData?.areaComplaintCollection?.complaintInformationSection?.matrixOriginalRatingViewModel?.matrixSelection[7]?.selectionId,
      massMediaRating: this.complaintData?.areaComplaintCollection?.complaintInformationSection?.matrixOriginalRatingViewModel?.matrixSelection[8]?.selectionId,
      designChangeRating: this.complaintData?.areaComplaintCollection?.complaintInformationSection?.matrixOriginalRatingViewModel?.matrixSelection[9]?.selectionId,
      instancesRating: this.complaintData?.areaComplaintCollection?.complaintInformationSection?.matrixOriginalRatingViewModel?.matrixSelection[10]?.selectionId,
      resolutionRating: this.complaintData?.areaComplaintCollection?.complaintInformationSection?.matrixOriginalRatingViewModel?.matrixSelection[11]?.selectionId,
      affectedCustomerRating: this.complaintData?.areaComplaintCollection?.complaintInformationSection?.matrixOriginalRatingViewModel?.matrixSelection[12]?.selectionId,
      controlRating: this.complaintData?.areaComplaintCollection?.complaintInformationSection?.matrixOriginalRatingViewModel?.matrixSelection[13]?.selectionId,
      rpn: this.complaintData?.areaComplaintCollection?.complaintInformationSection?.matrixOriginalRatingViewModel?.rpn,
      classValue: this.complaintData?.areaComplaintCollection?.complaintInformationSection?.matrixOriginalRatingViewModel?.class,
      // classId: this.complaintData?.areaComplaintCollection?.complaintInformationSection?.matrixOriginalRatingViewModel?.class,
      impactRating: this.complaintData?.areaComplaintCollection?.complaintInformationSection?.matrixOriginalRatingViewModel?.classFriendlyResult,
      HasHightImpactOnABB: this.complaintData?.areaComplaintCollection?.complaintInformationSection?.highImpact?.hasHightImpactOnABB,
      IsComplaintDescriptionSectionMandatory: this.complaintData?.areaComplaintCollection?.complaintInformationSection?.descriptionSection?.isComplaintDescriptionSectionMandatory
    });
  }

  public calculateRpnAndImpactRating(showMessage: boolean = true) {
    let formatedData = {
      MatrixSelection: [],
      Rpn: 0,
      Class: null,
      ClassFriendlyResult: null,
      ShouldScoringMatrixBeVisible: false,
      ShouldScoringMatrixHeaderInfoBeVisible: false,
      ReviewRequired: false,
      VerificationInformation: { IsVisible: false, Status: null },
      DisplayName: null,
      matrixSelection: [
        {
          Category: {
            Id: this.getMatrixTreeActiveVariable.data[0].id,
            Name: this.getMatrixTreeActiveVariable.data[0].name,
            Domain: this.getMatrixTreeActiveVariable.data[0].domain,
            SortOrder: this.getMatrixTreeActiveVariable.data[0].sortOrder
          },
          Severities: this.getMatrixTreeActiveVariable.data[0].matrixSeverity,
          SelectionId: this.ratingForm.controls['availabilityRating'].value,
          SortOrder: 1,
          DisplayName: null,
        },
        {
          Category: {
            Id: this.getMatrixTreeActiveVariable.data[1].id,
            Name: this.getMatrixTreeActiveVariable.data[1].name,
            Domain: this.getMatrixTreeActiveVariable.data[1].domain,
            SortOrder: this.getMatrixTreeActiveVariable.data[1].sortOrder
          },
          Severities: this.getMatrixTreeActiveVariable.data[1].matrixSeverity,
          SelectionId: this.ratingForm.controls['functionalityRating'].value,
          SortOrder: 2,
          DisplayName: null,
        },
        {
          Category: {
            Id: this.getMatrixTreeActiveVariable.data[2].id,
            Name: this.getMatrixTreeActiveVariable.data[2].name,
            Domain: this.getMatrixTreeActiveVariable.data[2].domain,
            SortOrder: this.getMatrixTreeActiveVariable.data[2].sortOrder
          },
          Severities: this.getMatrixTreeActiveVariable.data[2].matrixSeverity,
          SelectionId: this.ratingForm.controls['onTimeDeliveryRating'].value,
          SortOrder: 3,
          DisplayName: null,
        },
        {
          Category: {
            Id: this.getMatrixTreeActiveVariable.data[3].id,
            Name: this.getMatrixTreeActiveVariable.data[3].name,
            Domain: this.getMatrixTreeActiveVariable.data[3].domain,
            SortOrder: this.getMatrixTreeActiveVariable.data[3].sortOrder
          },
          Severities: this.getMatrixTreeActiveVariable.data[3].matrixSeverity,
          SelectionId: this.ratingForm.controls['cyberSecurityRating'].value,
          SortOrder: 4,
          DisplayName: null,
        },
        {
          Category: {
            Id: this.getMatrixTreeActiveVariable.data[4].id,
            Name: this.getMatrixTreeActiveVariable.data[4].name,
            Domain: this.getMatrixTreeActiveVariable.data[4].domain,
            SortOrder: this.getMatrixTreeActiveVariable.data[4].sortOrder
          },
          Severities: this.getMatrixTreeActiveVariable.data[4].matrixSeverity,
          SelectionId: this.ratingForm.controls['productComplianceRating'].value,
          SortOrder: 5,
          DisplayName: null,
        },
        {
          Category: {
            Id: this.getMatrixTreeActiveVariable.data[5].id,
            Name: this.getMatrixTreeActiveVariable.data[5].name,
            Domain: this.getMatrixTreeActiveVariable.data[5].domain,
            SortOrder: this.getMatrixTreeActiveVariable.data[5].sortOrder
          },
          Severities: this.getMatrixTreeActiveVariable.data[5].matrixSeverity,
          SelectionId: this.ratingForm.controls['ohsRiskRating'].value,
          SortOrder: 6,
          DisplayName: null,
        },
        {
          Category: {
            Id: this.getMatrixTreeActiveVariable.data[6].id,
            Name: this.getMatrixTreeActiveVariable.data[6].name,
            Domain: this.getMatrixTreeActiveVariable.data[6].domain,
            SortOrder: this.getMatrixTreeActiveVariable.data[6].sortOrder
          },
          Severities: this.getMatrixTreeActiveVariable.data[6].matrixSeverity,
          SelectionId: this.ratingForm.controls['marginRating'].value,
          SortOrder: 7,
          DisplayName: null,
        },
        {
          Category: {
            Id: this.getMatrixTreeActiveVariable.data[7].id,
            Name: this.getMatrixTreeActiveVariable.data[7].name,
            Domain: this.getMatrixTreeActiveVariable.data[7].domain,
            SortOrder: this.getMatrixTreeActiveVariable.data[7].sortOrder
          },
          Severities: this.getMatrixTreeActiveVariable.data[7].matrixSeverity,
          SelectionId: this.ratingForm.controls['reputationRating'].value,
          SortOrder: 8,
          DisplayName: null,
        },
        {
          Category: {
            Id: this.getMatrixTreeActiveVariable.data[8].id,
            Name: this.getMatrixTreeActiveVariable.data[8].name,
            Domain: this.getMatrixTreeActiveVariable.data[8].domain,
            SortOrder: this.getMatrixTreeActiveVariable.data[8].sortOrder
          },
          Severities: this.getMatrixTreeActiveVariable.data[8].matrixSeverity,
          SelectionId: this.ratingForm.controls['massMediaRating'].value,
          SortOrder: 9,
          DisplayName: null,
        },
        {
          Category: {
            Id: this.getMatrixTreeActiveVariable.data[9].id,
            Name: this.getMatrixTreeActiveVariable.data[9].name,
            Domain: this.getMatrixTreeActiveVariable.data[9].domain,
            SortOrder: this.getMatrixTreeActiveVariable.data[9].sortOrder
          },
          Severities: this.getMatrixTreeActiveVariable.data[9].matrixSeverity,
          SelectionId: this.ratingForm.controls['designChangeRating'].value,
          SortOrder: 10,
          DisplayName: null,
        },
        {
          Category: {
            Id: this.getMatrixTreeActiveVariable.data[10].id,
            Name: this.getMatrixTreeActiveVariable.data[10].name,
            Domain: this.getMatrixTreeActiveVariable.data[10].domain,
            SortOrder: this.getMatrixTreeActiveVariable.data[10].sortOrder
          },
          Severities: this.getMatrixTreeActiveVariable.data[10].matrixSeverity,
          SelectionId: this.ratingForm.controls['instancesRating'].value,
          SortOrder: 11,
          DisplayName: null,
        },
        {
          Category: {
            Id: this.getMatrixTreeActiveVariable.data[11].id,
            Name: this.getMatrixTreeActiveVariable.data[11].name,
            Domain: this.getMatrixTreeActiveVariable.data[11].domain,
            SortOrder: this.getMatrixTreeActiveVariable.data[11].sortOrder
          },
          Severities: this.getMatrixTreeActiveVariable.data[11].matrixSeverity,
          SelectionId: this.ratingForm.controls['resolutionRating'].value,
          SortOrder: 12,
          DisplayName: null,
        },
        {
          Category: {
            Id: this.getMatrixTreeActiveVariable.data[12].id,
            Name: this.getMatrixTreeActiveVariable.data[12].name,
            Domain: this.getMatrixTreeActiveVariable.data[12].domain,
            SortOrder: this.getMatrixTreeActiveVariable.data[12].sortOrder
          },
          Severities: this.getMatrixTreeActiveVariable.data[12].matrixSeverity,
          SelectionId: this.ratingForm.controls['affectedCustomerRating'].value,
          SortOrder: 13,
          DisplayName: null,
        },
        {
          Category: {
            Id: this.getMatrixTreeActiveVariable.data[13].id,
            Name: this.getMatrixTreeActiveVariable.data[13].name,
            Domain: this.getMatrixTreeActiveVariable.data[13].domain,
            SortOrder: this.getMatrixTreeActiveVariable.data[13].sortOrder
          },
          Severities: this.getMatrixTreeActiveVariable.data[13].matrixSeverity,
          SelectionId: this.ratingForm.controls['controlRating'].value,
          SortOrder: 14,
          DisplayName: null,
        },
      ],
      complaintId : this.complaintData?.header?.id || null
    };


    this.originalConfigService
      .classifyOriginalRating(formatedData)
      .subscribe((response) => {
        if (response) {
          this.ratingForm.patchValue({
            rpn: response?.rpn,
            impactRating: response?.classFriendlyResult,
            classValue: response?.class,
            classId: response?.class,
            HasHightImpactOnABB: response?.isHighImpact
          });
          if (this.complaintData == undefined) {
            if (response?.class != 1) {
              this.onShowNewMatrixOriginalRating_Rpn('new_Rpn');
              this.onOriginalRatingUnconfirm();
            }
            else {
              this.onShowNewMatrixOriginalRating_Rpn('old_Rpn');
              //  $('#' + '@Html.GetIdFor(m => m.AreaComplaintCollection.ComplaintInformationSection.MatrixOriginalRatingViewModel.ClassFriendlyResult)').val(response.classFriendlyResult);
            }
          }
          else {
            this.complaintData.areaComplaintResolution.matrixCurrentRatingViewModel.class = response?.class;
            this.complaintData.areaComplaintResolution.matrixCurrentRatingViewModel.classFriendlyResult = response?.classFriendlyResult;
           // console.log("original rating>>>>>",this.complaintData.areaComplaintResolution.matrixCurrentRatingViewModel.class, this.complaintData.areaComplaintResolution.matrixCurrentRatingViewModel.classFriendlyResult);
            if (response.class != 1) {
              this.onOriginalRatingUnconfirm();
              this.onShowNewMatrixOriginalRating_Rpn('new_Rpn');
            }
            else {
              this.onShowNewMatrixOriginalRating_Rpn('old_Rpn');
            }
         }
         if(response.isHighImpact==true){
          this.ratingForm.patchValue({
            IsComplaintDescriptionSectionMandatory:true
          })}
          else{this.ratingForm.patchValue({
            IsComplaintDescriptionSectionMandatory:null
          })}

          this.issueCaptureMainForm.controls["additionalSubjectForm"].patchValue({
            isNoofUnitsShippedRequired: (this.issueCaptureMainForm.controls["ratingForm"].get('IsComplaintDescriptionSectionMandatory')?.value==true && this.issueCaptureMainForm.controls["additionalSubjectForm"].get('productAlreadyDelivered')?.value?.value=='Yes' ? true : false)
          });
          //console.log(">>>>",this.ratingForm.get('IsComplaintDescriptionSectionMandatory'));
          this.setFieldsValidation(this.ActionNameForComplaints);
        this.classOriginalValue.emit({ matrixClass: response?.classFriendlyResult, isHightImpact:  response?.isHighImpact  });
        if(showMessage){
          this.messageService.add({
            severity: 'success',
            summary: 'Rating configuration updated',
          });
        }

        }

      }, (error) => {
        this.messageService.add({
            severity: 'error',
            summary: error
        })
    });
  }

  onOriginalRatingUnconfirm() {
    // $('#' + '@Html.GetIdFor(m => m.AreaComplaintCollection.ComplaintInformationSection.MatrixOriginalRatingViewModel.ClassFriendlyResult)').val("Unconfirmed");
  }
  // Current rating to assign Unconfirm
  onCurrentRatingUnconfirm() {
    // $('#' + '@Html.GetIdFor(m => m.AreaComplaintResolution.MatrixCurrentRatingViewModel.ClassFriendlyResult)').val("Unconfirmed");
  }
  onShowNewMatrixOriginalRating_Rpn(value) {
    if (value == 'new_Rpn') {
      this.showUnconfirmedRating = false;
    } else {
      // // if(this.complaintData?.areaComplaintCollection?.complaintInformationSection?.matrixOriginalRatingViewModel?.class == 1 ){
      // //   this.showUnconfirmedRating = true;
      // // }else{
      //   this.showUnconfirmedRating = false;
      // }

      let userAccessData;
      if (this.complaintData != undefined) {
        this.authorizationService.userAccessDetail.subscribe(async detail => {
          if (detail) {
            userAccessData = detail;
            let currselectedRole = userAccessData?.roleCode;
            // let matrixClassABIC = this.complaintData?.areaComplaintResolution.matrixCurrentRatingViewModel.class;
            // let matrixClass = this.complaintData?.areaComplaintResolution.matrixCurrentRatingViewModel.classFriendlyResult;
            // let verification = this.complaintData?.areaComplaintResolution.matrixCurrentRatingViewModel.verificationInformation.status;
            let matrixClassABIC = this.complaintData?.areaComplaintResolution.matrixCurrentRatingViewModel.class;
            let matrixClass = this.complaintData?.areaComplaintResolution.matrixCurrentRatingViewModel.classFriendlyResult;
            let verification =  this.complaintData?.areaComplaintResolution.matrixCurrentRatingViewModel.verificationInformation.status
            let complaintStatus = this.complaintData?.header.complaintStatus;
            let isRoleExist = false;
            if (matrixClassABIC == MatrixClass.A || verification != 'Unconfirmed') {
              this.showUnconfirmedRating = true;
            }
            if ((matrixClassABIC == MatrixClass.B || matrixClassABIC == MatrixClass.I) && (currselectedRole == RoleCode.Global_CCRP_Process_Owner || currselectedRole == RoleCode.Support_Desk || currselectedRole == RoleCode.Div_Process_Owner || currselectedRole == RoleCode.Pg_Process_Owner || currselectedRole == RoleCode.Bu_Process_Owner || currselectedRole == RoleCode.Hub_Process_Owner)) {
              this.showUnconfirmedRating = true;
              isRoleExist = true;
            }

            if (verification == 'Unconfirmed' && isRoleExist == false && matrixClassABIC != MatrixClass.A) {
              this.showUnconfirmedRating = false;
            }

            if (matrixClassABIC == MatrixClass.C) {
              if (complaintStatus == ComplaintStatuses.New || complaintStatus == ComplaintStatuses.Draft || complaintStatus == ComplaintStatuses.NotAssigned) {
                this.showUnconfirmedRating = false;
              }
              else {
                this.showUnconfirmedRating = true;
              }
            }

          }
        });
      } else {
        this.showUnconfirmedRating = true;
      }

    }
  }


}
