<div class="add-customer-contact-popup-section">
  <form [formGroup]="admAddContactForm">
    <div class="row mt-3">
      <div class="col-sm-3 item-align-right add-customer-contact-popup-label label-bold">
        <div class="float-end mt-1">{{'admin_cc_customers_crud_fn_lb' | translate}}</div>
      </div>
      <div class="col-sm-9 add-customer-contact-popup-custInput">
        <input type="text" class="form-control" placeholder="{{'admin_cc_customers_crud_fn_ph' | translate}}"
          formControlName="fname" [pTooltip]="adminAddContactRequired" [escape]="false" tooltipPosition="bottom"
          tooltipStyleClass="toolTipClass"
          [ngClass]="{'ng-dirty':admAddContactForm.get('fname').dirty && admAddContactForm.get('fname').invalid}">
        <!-- <p class="help-block error mt-0 mb-0"
                        *ngIf="admAddContactForm.get('fname').touched"> -->
        <small class="error"
          *ngIf="admAddContactForm.get('fname').touched && admAddContactForm.get('fname').errors?.['required']">
          {{'admin_cc_customers_crud_fn_req_msg' | translate}}
        </small>
        <small class="error" *ngIf="admAddContactForm.get('fname').errors?.['maxlength']">
          {{'admin_cc_customers_crud_fn_max_msg' | translate}}
        </small>
        <!-- </p> -->
      </div>
      <!-- <div class="col-sm-1"></div> -->
    </div>


    <div class="row mt-2">
      <div class="col-sm-3 item-align-right add-customer-contact-popup-label label-bold">
        <div class="float-end mt-1">{{'admin_cc_customers_crud_ln_lb' | translate}}</div>
      </div>
      <div class="col-sm-9">
        <input type="text" class="form-control" placeholder="{{'admin_cc_customers_crud_ln_ph' | translate}}"
          formControlName="lname" [pTooltip]="adminAddContactRequired" [escape]="false" tooltipPosition="bottom"
          tooltipStyleClass="toolTipClass"
          [ngClass]="{'ng-dirty':admAddContactForm.get('lname').dirty && admAddContactForm.get('lname').invalid}">
        <small class="error"
          *ngIf="admAddContactForm.get('lname').touched && admAddContactForm.get('lname').errors?.['required']">
          {{'admin_cc_customers_crud_ln_req_msg' | translate}}
        </small>
        <small class="error" *ngIf="admAddContactForm.get('lname').errors?.['maxlength']">
          {{'admin_cc_customers_crud_ln_max_msg' | translate}}
        </small>
      </div>
      <!-- <div class="col-sm-1"></div> -->
    </div>

    <!-- <p class="help-block error"
            *ngIf="admAddContactForm.get('lname').dirty && admAddContactForm.get('lname').invalid">
            <small class="error" *ngIf="admAddContactForm.get('lname').errors['required']">
                {{'admin_cc_customers_crud_ln_req_msg' | translate}}
            </small>
            <small class="error" *ngIf="admAddContactForm.get('lname').errors['maxlength']">
                {{'admin_cc_customers_crud_ln_max_msg' | translate}}
            </small>
        </p> -->

    <div class="row mt-2">
      <div class="col-sm-3 item-align-right add-customer-contact-popup-label label-bold">
        <div class="float-end mt-1">{{'admin_cc_customers_crud_email_lb' | translate}}</div>
      </div>
      <div class="col-sm-9">
        <input type="email" class="form-control" placeholder="{{'admin_cc_customers_crud_email_ph' | translate}}"
          formControlName="email" [pTooltip]="adminAddContactRequired" [escape]="false" tooltipPosition="bottom"
          tooltipStyleClass="toolTipClass">
        <small class="error"
          *ngIf="admAddContactForm.get('email').touched && admAddContactForm.get('email').errors?.['required']">
          {{'admin_cc_customers_crud_email_req_msg' | translate}}
        </small>
        <small class="error" *ngIf="admAddContactForm.get('email').errors?.['maxlength']">
          {{'admin_cc_customers_crud_email_max_msg' | translate}}
        </small>
        <small class="error" *ngIf="admAddContactForm.get('email').errors?.['email']">
          {{'admin_cc_customers_crud_email_format_msg' | translate}}
        </small>
      </div>
      <!-- <div class="col-sm-1"></div> -->
    </div>

    <!-- <p class="help-block error"
            *ngIf="admAddContactForm.get('email').dirty && admAddContactForm.get('email').invalid">
            <small class="error" *ngIf="admAddContactForm.get('email').errors['required']">
                {{'admin_cc_customers_crud_email_req_msg' | translate}}
            </small>
            <small class="error" *ngIf="admAddContactForm.get('email').errors['maxlength']">
                {{'admin_cc_customers_crud_email_max_msg' | translate}}
            </small>
            <small class="error"
                *ngIf="admAddContactForm.get('email').errors['email'] && !admAddContactForm.get('email').errors['required']">
                {{'admin_cc_customers_crud_email_format_msg' | translate}}
            </small>
        </p>
        <p class="help-block error"
            *ngIf="admAddContactForm.get('email').pristine && admAddContactForm.get('email').invalid">
            <small class="error" *ngIf="admAddContactForm.get('email').errors['email']">
                {{'admin_cc_customers_crud_email_format_msg' | translate}}
            </small>
        </p> -->

    <div class="row mt-2">
      <div class="col-sm-3 item-align-right add-customer-contact-popup-label label-bold">
        <div class="float-end mt-1">{{'admin_cc_customers_crud_phone_lb' | translate}}</div>
      </div>
      <div class="col-sm-9">
        <input type="text" class="form-control" placeholder="{{'admin_cc_customers_crud_phone_ph' | translate}}"
          formControlName="phone">
        <p class="help-block error"
          *ngIf="admAddContactForm.get('phone').dirty && admAddContactForm.get('phone').invalid">
          <small class="error" *ngIf="admAddContactForm.get('phone').errors['maxlength']">
            {{'admin_cc_customers_crud_phone_max_msg' | translate}}
          </small>
        </p>
      </div>
      <!-- <div class="col-sm-1"></div> -->
    </div>



    <div class="row mt-2">
      <div class="col-sm-3 item-align-right add-customer-contact-popup-label label-bold">
        <div>{{'admin_cc_customers_crud_active_lb' |translate}}</div>
      </div>
      <div class="col-sm-8">
        <input type="checkbox" formControlNname="isActive" class="checkbox activecheckbox" checked
          disabled="customerContact.id === undefined" />
      </div>
      <div class="col-sm-1"></div>
    </div>
    <div class="row mt-2" *ngIf="hideShowBtnDiv">
      <div class="col-md-3"></div>
      <div class="col-md-9"> <strong class="error-validation">{{validation}} </strong>
      </div>
      <!-- <div class="col-md-4"></div> -->
    </div>

    <div class="row mt-2">
      <div class="col-sm-4"></div>
      <div class="col-sm-4"></div>
      <div class="col-sm-4 div-align-items-right">
        <button type="button" class="input widgetButtonLarge" (click)="cancel()">{{'admin_cc_customers_crud_close_lb' |
          translate}}
        </button>
        <button class="input widgetButtonLarge ms-1" [disabled]="admAddContactForm.invalid"
          [ngClass]="admAddContactForm.invalid? 'grayButton':''"
          (click)="addContacts()">{{'admin_cc_customers_crud_save_lb'
          | translate}}
        </button>
      </div>
    </div>
  </form>
</div>
