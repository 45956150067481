import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CognitivesearchServiceService {

  constructor(private httpClient:HttpClient) { }

  findForGlobalSearch(searchTerm, pageNbr, pageSize) {
    pageNbr = pageNbr || 1;
    pageSize = pageSize || 1000;
    let wildcard = btoa(searchTerm);

    let params = new HttpParams()
      .set('wildcard', wildcard)
      .set('page', pageNbr)
      .set('size', pageSize);
    return this.httpClient.get(`${environment.azure.baseUrl}api/v1/CognitiveSearch/complaints`, { params });

  }

}
