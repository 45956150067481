import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ToasterserviceService } from './toasterservice.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable({
  providedIn: 'root'
})
export class DownloadAttachmentService {

  constructor(private http: HttpClient,private toaster:ToasterserviceService,
    private spinner: NgxSpinnerService) { }
    //US:-7560--Attachments | Files attached should be viewed with downloading - Implementation | RITM0368385
     getMimeTypeFromFileName(fileName: string): string {
      const ext = fileName.split('.').pop();
      switch (ext) {
        case 'jpg':
        case 'jpeg':
          return 'image/jpeg';
        case 'png':
          return 'image/png';
        case 'pdf':
          return 'application/pdf';
          case "txt":
            return "text/Plain";
            case "doc":
                case "docx":
                    return "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
                case "xls":
                case "xlsx":
                    return "application/vnd.ms-excel";

        // Add more cases for other file types as needed
        default:
          return 'application/octet-stream'; // Default to binary data
      }
    }

  downloadAttachement(storeFileName, displayName) {
    this.spinner.show();
    this.http.get(`${environment.azure.baseUrl}api/v1/Attachment/Download?storeFileName=${storeFileName}&displayName=${encodeURIComponent(displayName)}`, {
      responseType: 'arraybuffer'
    }).subscribe((response: ArrayBuffer) => {
      //US:-7560--Attachments | Files attached should be viewed with downloading - Implementation | RITM0368385
      var fileExt = this.getMimeTypeFromFileName(displayName.toLowerCase());
      if (fileExt == 'image/jpeg' || fileExt == 'image/png' || fileExt == 'application/pdf' || fileExt == 'text/Plain') {
        const blob = new Blob([response], { type: fileExt });
        const downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(blob);
        window.open(downloadLink.href, '_blank');
        URL.revokeObjectURL(downloadLink.href);
      }
      else {
        const blob = new Blob([response], { type: 'blob' });
        const downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(blob);
        downloadLink.download = displayName;
        downloadLink.click();
      }
      this.spinner.hide();
    },
      (err) => {
        this.toaster.showError('Error while downloading file');
        this.spinner.hide();
      });
  }

  downloadEightDReport(complaintId:number){
    return this.http.get(`${environment.azure.baseUrl}api/v1/reportgenerator/GetRaport8D/${complaintId}`,{
      responseType: 'arraybuffer'
    });
  }
}
