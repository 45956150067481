import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'orderGlobalRU'
})
export class OrderGlobalRUPipe implements PipeTransform {

  transform(data: any[], key: string[]): any[] {
    if(!data || data.length === 0) {
      return [];
    }

    return data.sort((a, b) => {
      for(let i = 0; i<key.length; i++)
      {
        if(a[key[i]] < b[key[i]])
          return -1;
        if(a[key[i]] > b[key[i]])
          return 1;
      }
      return 0;
    });
  }

}
