import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class AdminProcessOwnerService {

  constructor(private http: HttpClient) { }
  getFilteredUser(query): Observable<any> {
    return this.http.get(`${environment.azure.baseUrl}api/v1/employee/findpagebywildcard?wildcard=${query}&onlyActive=true&page=1&size=20`);
  }

  getRegions(): Observable<any> {
    return this.http.get(`${environment.azure.baseUrl}api/v1/regions`);
  }
  // getSearchcreaiteria(query): Observable<any> {
  //     return this.http.get(`${environment.azure.baseUrl}api/v1/processowner/search?Divisions=${query}
  //     &Bus=${query}&Pgs=${query}&Regions=${query}&Countries=${query}`);
  //   }


  getSearchCriteria(formatData): Observable<any> {
    return this.http.post(`${environment.azure.baseUrl}api/v1/processowner/search`, formatData);

  }


  getAddperson(addData): Observable<any> {
    return this.http.post(`${environment.azure.baseUrl}api/v1/processowner/add`, addData);
  }

  getcountry(regioncode): Observable<any> {
    return this.http.get(`${environment.azure.baseUrl}api/v1/regions/${regioncode}/countries`);
  }

}
