<div>
    <div class="dropzone" style="min-height: 50px; border-style: dashed; border-width: 2px;">
        <p-fileUpload [multiple]="true" [showUploadButton]="false" [showCancelButton]="false"></p-fileUpload></div>
    <span style="font-size: 9px; color: gray">{{'cc_actionplan_accepted_files' | translate}}: *.pdf, *.xls, *xlsx, *.png, *.jpeg, *.csv</span>
    
    <!-- <div id="previewTemplate" style="display: none">
        <div class="dz-preview dz-file-preview" style="margin-right: 5px;">
            <div class="dz-progress">
                <span data-dz-uploadprogress>
                    <i class="fa fa-spinner fa-spin"></i>
                </span>
            </div>
            <i class="fa fa-paperclip"></i>
            <span class="dz-error-mark"><span><i class="fa fa-exclamation-circle" style="color: #FFA201"></i></span></span>
            <div class="dz-error-message"><span data-dz-errormessage></span></div>
            <span data-dz-name style="cursor: pointer; pointer-events: all !important;" ng-click="ctrl.downloadFile($event)"></span>
            <span ng-click="ctrl.removeFile($event)" style="cursor: pointer"> &times;</span>
        </div>
    </div> -->
</div>


<!-- ng-attr-id="{{'dropzone-'+ ctrl.dropzoneId}}" ng-class="{'dz-disabled': isDisabled == true}"
data-sectionid="{{sectionId}}" -->