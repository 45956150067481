import { Component, Input, OnInit } from '@angular/core';
import { ProcessOwnersService } from '../process-owners.service';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DeleteSystemuserRoleComponent } from '../../systemusers/delete-systemuser-role/delete-systemuser-role.component';
import { DeleteProcessownerComponent } from '../delete-processowner/delete-processowner.component';
import { AdminProcessownersComponent } from '../admin-processowners/admin-processowners.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-po-itemunit',
  templateUrl: './po-itemunit.component.html',
  styleUrls: ['./po-itemunit.component.css']
})
export class PoItemunitComponent implements OnInit {
  deleteButtonHideForSearch:boolean = false;
  @Input('itemScope') itemScope: any;

  managersLoaded: any = {
    location: false,
    unitLocation: false,
    unit: false
  };

  loadingManagers: any = {
    locationManagers: false,
    unitLocationManagers: false,
    unitManagers: false
  };

  canLoadManagers: boolean = true;
  expanded: boolean = true;


  constructor(private processOwnersService: ProcessOwnersService,
    private ref: DynamicDialogRef,
    private dialogService: DialogService,
    private admPOComponet: AdminProcessownersComponent,
    private router:Router) { }

  ngOnInit(): void {
    const CurrentURL= this.router.url;
    // console.log(CurrentURL);
  }

  expand = function () {
    this.expanded = true;
  };

  collapse = function () {
    this.expanded = false;
  };

  canDeleteLastProcessOwner() {
    return this.itemScope.scope.pg != null || this.itemScope.unitManagers.length > 1;
  }

  onExpandLocationManagers() {

    const CurrentURL= this.router.url;
    if(CurrentURL == '/posearch'){
      this.deleteButtonHideForSearch = true;
    }

    if (this.managersLoaded.location) {
      this.itemScope.showAllLocationManagers = true;
      return;
    }
    this.loadingManagers.locationManagers = true;
    this.processOwnersService.loadLocationManagers(this.itemScope).then( ()=> {
      this.itemScope.showAllLocationManagers = true;
      this.loadingManagers.locationManagers = false;
      this.managersLoaded.location = true;
    });
  }

  onExpandUnitLocationManagers() {

    const CurrentURL= this.router.url;
    if(CurrentURL == '/posearch'){
      this.deleteButtonHideForSearch = true;
    }

    if (this.managersLoaded.unitLocation) {
      this.itemScope.showAllUnitLocationManagers = true;
      return;
    }
    this.loadingManagers.unitLocationManagers = true;
    this.processOwnersService.loadLocationUnitManagers(this.itemScope).then(()=> {
      this.itemScope.showAllUnitLocationManagers = true;
      this.loadingManagers.unitLocationManagers = false;
      this.managersLoaded.unitLocation = true;
    });
  }

  onExpandUnitManagers() {

    const CurrentURL= this.router.url;
    if(CurrentURL == '/posearch'){
      this.deleteButtonHideForSearch = true;
    }

    if (this.managersLoaded.unit) {
      this.itemScope.showAllUnitManagers = true;
      return;
    }

    this.loadingManagers.unitManagers = true;

    this.processOwnersService.loadUnitManagers(this.itemScope).then(()=> {
      this.itemScope.showAllUnitManagers = true;
      this.loadingManagers.unitManagers = false;
      this.managersLoaded.unit = true;
    });
  }

  deletePerson(userDetails:any){
      this.ref = this.dialogService.open(DeleteProcessownerComponent, {
        header: 'Delete Process Owner',
        width: '40%',
        position: 'top',
        keepInViewport: true,
        autoZIndex: true,
        styleClass: 'deleteProcessOwner',
        data: {user: userDetails}
      });

      this.ref.onClose.subscribe((data) =>
      {
        if(data){
          this.admPOComponet.reloadSearchResults();
        }
      })
  }
}
