<td class="col-sm-3 resources_td_field">
  <span class="resoucesname-td">
    <label class="resourceNameLabelTranslation"  style="display: flex; cursor: pointer;" pTooltip="{{res.name}}" tooltipPosition="top">{{res.name }}</label>
  </span>
</td>

<td class="col-sm-7 translation-field">
   <form [formGroup]="editResourceForm">
      <div class="form-group">
          <input type="text" [readonly]="isDisabled || !isInEditMode"
                [ngClass]="{'inputDisabled': isDisabled || !isInEditMode }"
                 *ngIf="isInEditMode || (res.translation !== '' && res.translation !== undefined && res.translation !== null)"
                 class="form-control form-control-sm translationInput"
                 placeholder="{{'admin_translations_res_tra_ph' | translate}}"
                 formControlName="translation"
                 required />
                <p *ngIf="!isInEditMode && (res.translation === '' || res.translation === undefined || res.translation === null)"
                  class="bg-warning">
                  <span class="text-muted">
                    {{'admin_translations_res_tra_ph' | translate}}
                  </span>
                </p>
                <span *ngIf="isInEditMode">
                  <span class="error"
                    *ngIf="this.editResourceForm.get('translation').touched && this.editResourceForm.get('translation').errors?.['required']">{{'admin_translations_res_tra_req_msg'
                    | translate}}<br></span>
                  <span class="error"
                    *ngIf="this.editResourceForm.get('translation').errors?.['maxlength']">{{'admin_translations_res_tra_toolong_msg'
                    | translate}}<br></span>
                </span>
         </div>
      </form>

  <div style="display: table-cell; " *ngIf="res.englishVersion && (selectedLanguage !== 'en')">
      <span class="label label-primary" style="color: #ffffff; font-size: 12px; padding: 3px;">EN</span>
      <small style="padding-left: 5px !important;">{{res.englishVersion}}</small>
  </div>
</td>

<td class="col-sm-2 editbtn-td">
  <span class="label-align-right btntdTranslation">
  <button *ngIf="!isInEditMode && allowEdit"
          title="{{'admin_translations_res_edit_title' | translate}}"
          [disabled]="isDisabled"
          class="widgetButtonSmall  " (click)="edit(item)">
      {{'admin_translations_res_edit_btn' | translate}}
  </button>
  <button *ngIf="isInEditMode"
          title="{{'admin_translations_res_cancel_title' | translate}}"
          [disabled]="isDisabled"
          class="widgetButtonSmall" (click)="cancel()">
      {{'admin_translations_res_cancel_btn' | translate}}
  </button>
  <button *ngIf="isInEditMode"
          title="{{'admin_translations_res_save_title' | translate}}"
          [disabled]="isDisabled || editResourceForm.invalid"
          [ngClass]="isDisabled || editResourceForm.invalid ? 'widgetButtonSmall grayButton' : 'widgetButtonSmall'"
          (click)="update(res)">
      {{'admin_translations_res_save_btn' | translate}}
  </button>
</span>
</td>
