import { Component, OnInit } from '@angular/core';
import { ExternalpageEmitService } from '../externalpage-emit.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-status',
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.css']
})
export class StatusComponent implements OnInit {
  isExternalPage:boolean=false;
  routeURL:string;

  constructor(private externalPageService:ExternalpageEmitService,
    private route: ActivatedRoute,
    public router: Router,) { }

  ngOnInit(): void {
   this.externalPageService.setExternalPageFunction(true);
   this.routeURL= this.route.snapshot.paramMap.get('id');
  }

}
