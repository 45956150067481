<div class="exceptionMessage">
  <h4>
    Your WORKDAY profile is inactive. Please contact your HR-OPS team to get it rectified. Raise a  ticket
    <a
      href=" https://eur04.safelinks.protection.outlook.com/?url=https%3A%2f%2fhitachienergy.service-now.com%2fmy_hr%3Fid%3Dhr_sc_cat_item%26sys_id%3D56f133db875b38d09273cbf20cbb35e7%26sysparm_category%3Dae5ee8e81b01f41027fb337a9b4bcbc1&data=05%7C02%7Ctarun.chauhan%40hitachienergy.com%7C122d3fb925344230503c08dc2964a426%7C7831e6d9dc6c4cd19ec61dc2b4133195%7C0%7C0%7C638430759418891222%7CUnknown%7CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7C0%7C%7C%7C&sdata=uv2FhSqhLTKZ8QgUE5kZoufA2578jnPok8C1KvJ5jbo%3D&reserved=0"
      target="_blank"
      >here</a
    > for the same with help of any Hitachi Energy Employee who has valid Hitachi Energy Email ID
  </h4>
</div>
<div class="HRGTContent">
<span>While raising ticket make following selections and enter description as given below:</span>
  <table class="table table-responsive mt-2">
    <tr>
      <td class="headerData">Topic Category:</td><td>Employee Lifecycle</td>
    </tr>
    <tr>
      <td class="headerData">HR Service:</td><td>General Query</td>
    </tr>
    <tr>
      <td class="headerData">Short Description:</td><td>Email Missing in WORKDAY</td>
    </tr>
    <tr>
      <td class="headerData">Description:</td><td>LDAPID – ‘USER’S LDAPID’ .<br>
        Not able to access CCRP Application as email ID is missing in WORKDAY.
   </td>
    </tr>
  </table>
</div>
