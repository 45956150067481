import { Component, OnInit } from '@angular/core';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';

@Component({
  selector: 'app-helpdesk',
  templateUrl: './helpdesk.component.html',
  styleUrls: ['./helpdesk.component.css']
})
export class HelpdeskComponent implements OnInit {

  constructor(public breadcrumbService: BreadcrumbService) { }

  ngOnInit(): void {
    this.breadcrumbService.menuItems = [
      {label: 'HELP DESK'}
    ]
  }

}
