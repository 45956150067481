<form [formGroup]="failureModeEditModal" class="editFailureModeSteps">
  <div class="row">
      <label class="col-sm-2 control-label label-bold remove-px">{{'admin_item_addedit_name_lb' | translate}}</label>
      <div class="col-sm-10 ps-0">
          <input type="text" class="form-control" formControlName="itemName" placeholder="{{'admin_item_addedit_name_ph' | translate}}"
              [ngClass]="{'form-control-error': (this.failureModeEditModal.get('itemName').touched && !this.failureModeEditModal.get('itemName').valid) || validationItem.hasErrors}">
              <span class="error" *ngIf="this.failureModeEditModal.get('itemName').touched && this.failureModeEditModal.get('itemName').errors?.['required']"> {{'admin_item_addedit_name_msg_req' | translate}}<br></span>
              <span class="error" *ngIf="this.failureModeEditModal.get('itemName').errors?.['maxlength']"> {{'admin_item_addedit_name_msg_maxlength_60' | translate}}<br></span>
              <span *ngIf="this.failureModeEditModal.get('itemName').dirty && validationItem.hasErrors">
                  <span class="error" *ngIf="validationItem.isActiveItem">{{'admin_item_addedit_iefp_active_msg' | translate}}</span>
                  <span class="error" *ngIf="!validationItem.isActiveItem"> {{'admin_item_addedit_iefp_notactive_msg' | translate}}</span>
              </span>
      </div>
  </div>

  <div class="row pt-1" *ngIf="this.config.data.id !== undefined">
      <label class="col-sm-2 control-label">{{'admin_item_addedit_isactive_lb' | translate}}</label>
      <div class="col-sm-10 ps-0 inputBox">
          <input type="checkbox" formControlName="isActiveCheck">
      </div>
  </div>

  <div class="modal-footer mt-4">
      <button class="widgetButtoSmall" type="button" (click)="cancel()">{{'admin_item_addedit_cancel_btn_ph' | translate}}</button>
      <button class="widgetButtoSmall ms-2" [disabled]="this.failureModeEditModal.invalid || !this.failureModeEditModal.dirty || validationItem.hasErrors" (click)="save()">{{'admin_item_addedit_save_btn_lb' | translate}}</button>
  </div>
</form>
