<div class="deactivate-contact-section">
    <div class="modal-body deactlblQn">
        <p>{{'admin_cc_customers_crud_deactivate_question_lb' | translate}}</p>
    </div>
    <div class="modal-footer">
        <button class="widgetButtonSmall" (click)="cancel()">
            {{'admin_cc_customers_crud_close_lb' | translate}}
        </button>
        <button class="widgetButtonSmall ms-1 btnClassDeactivateCC" (click)="deleteConfirmation()">
            {{'admin_cc_customers_crud_ok_lb' | translate}}
        </button>
    </div>
</div>