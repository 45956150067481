import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef, DialogService } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-currentrating-confirm-popup',
  templateUrl: './currentrating-confirm-popup.component.html',
  styleUrls: ['./currentrating-confirm-popup.component.css']
})
export class CurrentratingConfirmPopupComponent implements OnInit {
  classData: any;
  constructor( public ref: DynamicDialogRef,
    public dialogService: DialogService,
    public config: DynamicDialogConfig) { }

  ngOnInit(): void {
      this.classData = this.config?.data?.manualClassChangedTo;
  }

  onClose() {
    this.ref.close();
  }

  confirm() {
    this.ref.close(true);
  }

}
