import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ExternalpageEmitService } from 'src/app/components/external/externalpage-emit.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-download-eight-d',
  templateUrl: './download-eight-d.component.html',
  styleUrls: ['./download-eight-d.component.css']
})
export class DownloadEightDComponent implements OnInit {

  storeFileNames:any;
  displayNames:any;
  downloadAllUrl = environment.azure.baseUrl+'api/v1/Attachment/downloadmany?storeFilesNames=';
  constructor(private externalPageService: ExternalpageEmitService,
    private route: ActivatedRoute,
    public router: Router,
    private fb: FormBuilder, private spinnerService: NgxSpinnerService) { }

  ngOnInit(): void {
    this.externalPageService.setExternalPageFunction(true);
    this.storeFileNames = atob(this.route.snapshot.paramMap.get('storeFileNames'));
    console.log(this.storeFileNames);
    this.displayNames = atob(this.route.snapshot.paramMap.get('displayNames'));
    console.log(this.displayNames)
    this.spinnerService.show();
    this.downloadAllUrl = this.downloadAllUrl+this.storeFileNames+'&displayNames='+this.displayNames;
    //window.location.href=this.downloadAllUrl;
    window.open(this.downloadAllUrl, "_blank");

    this.spinnerService.hide();
  }

}
