<div class="panel panel-default systemUser-card-mainClass">
  <div class="panel-heading">
      <h4 class="panel-title">
          <div>
              <small class="admin-color-normal">
                  <span class="glyphicon glyphicon-user"></span>
                  <strong class="ms-1 cardHeaderCustomerContact">{{itemScope?.userInfo?.name}}</strong>
                  <span style="float: right !important;" >
                    <button type="button"  [ngClass]="!itemScope.canAddSuRole == true? 'widgetButtonMedium grayButton' : 'widgetButtonMedium'" (click)="addSystemRoleUser(itemScope)" [disabled]="!itemScope.canAddSuRole">
                        <i class="fa fa-ban" style="color:#f9f7f7;" *ngIf="!itemScope.canAddSuRole"></i>
                        {{'system_user_addrole_btn'|translate}}
                    </button>
                </span>
              </small>
              <div *ngIf="itemScope?.userInfo?.address?.countryCode">
                  <small class="cardHeaderCustomerContact">{{itemScope?.userInfo?.address?.city}}, {{itemScope?.userInfo?.address?.address}}, {{itemScope?.userInfo?.address?.countryCode}}</small>
              </div>
          </div>
      </h4>
  </div>
  <div class="panel-body bg-white1">
          <span style="margin-left: 6px;">{{'system_user_detailsinfo_roles_lbl'|translate}}</span>
          <ul>
              <li *ngIf="itemScope.isGlobalProcessOwner">
                  <label>{{'system_user_sr_globalpo_lbl'|translate}}</label>
                  <a *ngIf="itemScope.canManageGlobalProccessOwner" (click)="deleteSystemRoleUser({suScope: itemScope.userInfo, roleId : 1})">
                      <i class=" fa fa-trash-o admin-icon-a  admin-link ms-1 " ></i>
                  </a>
              </li>
              <li *ngIf="itemScope.isSupportDeskMember">
                  <label>{{'system_user_sr_supportdesk_lbl'|translate}}</label>
                  <a *ngIf="itemScope.canManageSupportDeskMember" (click)="deleteSystemRoleUser({suScope: itemScope.userInfo, roleId : 2})">
                      <i class=" fa fa-trash-o admin-icon-a  admin-link ms-1"></i>
                  </a>
              </li>
              <li *ngIf="itemScope.isResourceEditor">
                  <label>{{'system_user_sr_langeditor_lbl'|translate}}</label>
                  <a *ngIf="itemScope.canManageResourceEditor" (click)="deleteSystemRoleUser({suScope: itemScope.userInfo, roleId : 14})">
                      <i class=" fa fa-trash-o admin-icon-a  admin-link ms-1"></i>
                  </a>
              </li>
              <li *ngIf="itemScope.isManagementRole">
                  <label>{{'system_user_sr_management_role_lbl'|translate}}</label>
                  <a *ngIf="itemScope.canManageManagementRole" (click)="deleteSystemRoleUser({suScope: itemScope.userInfo, roleId : 21})">
                      <i class=" fa fa-trash-o admin-icon-a  admin-link ms-1"></i>
                  </a>
              </li>

          </ul>
  </div>
</div>
