<div class="system_user mt-2 systemUser-addRole-mainClass">
  <div class="modal-body">
    <form class="form-horizontal" [formGroup]="addSystemUserForm">
      <input type="hidden" formControlName="userGEID" />
      <div class="row">
        <div class="col-sm-2">
          <label class="label-align-right label-bold mt-1">{{'system_user_mod_add_employee_lbl'|translate}}</label>
        </div>
        <div class="col-sm-10">
          <p-autoComplete [forceSelection]="true" [minLength]=1 formControlName="systemUserEmail"
            [disabled]="readonlyFields.employee"
            [ngClass]=" readonlyFields.employee ? 'inputDisabled': '' "
            placeholder="{{'system_user_mod_add_employee_ph'|translate}}" class="substituteCustom" type="text"
            [suggestions]="filteredOwner" (completeMethod)="filterOwner($event?.query)" field="name">
            <ng-template let-filteredOwner pTemplate="selectedItem">
            </ng-template>
            <ng-template let-filteredOwner pTemplate="item">
              <div class="flex align-items-center gap-2">
                    <div class="row">
                      <div class="col-8"><strong class="autocompleteMailSystemUser">{{ filteredOwner?.name }}</strong></div>
                      <div class="col-4 text-end autocompleteCitySystemUser"><i>{{filteredOwner?.city}}({{filteredOwner.iso}})</i></div>
                    </div>
                    <div class="row">
                      <div class="col">{{filteredOwner?.email}}</div>
                    </div>
              </div>
          </ng-template>
          </p-autoComplete>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-sm-2"></div>
        <div class="col-sm-10">
          <button type="button" class="btn btn-default btn-default-borderless emp-role" (click)="globalProcessOwnerClicked()"
            [disabled]="readonlyFields.globalProcessOwner">
            <span class="fa"
              [ngClass]="{'fa-square-o ':!selected.globalProcessOwner, 'fa-check-square-o ':selected.globalProcessOwner }"></span>
            {{'system_user_sr_globalpo_lbl'|translate}}
          </button>
        </div>
      </div>
      <div class="row mt-1">
        <div class="col-sm-2"></div>
        <div class="col-sm-10">
          <button type="button" class="btn btn-default btn-default-borderless emp-role" (click)="supportDeskClicked()"
            [disabled]="readonlyFields.supportDeskMember">
            <span class="fa"
              [ngClass]="{'fa-square-o ':!selected.supportDeskMember, 'fa-check-square-o ':selected.supportDeskMember }"></span>
            {{'system_user_sr_supportdesk_lbl'|translate}}
          </button>
        </div>
      </div>
      <div class="row mt-1">
        <div class="col-sm-2"></div>
        <div class="col-sm-10">
          <button type="button" class="btn btn-default btn-default-borderless emp-role" (click)="resourceEditorClicked()"
            [disabled]="readonlyFields.resourceEditor">
            <span class="fa"
              [ngClass]="{'fa-square-o ':!selected.resourceEditor, 'fa-check-square-o ':selected.resourceEditor }"></span>
            {{'system_user_sr_langeditor_lbl'|translate}}
          </button>
        </div>
      </div>
      <div class="row mt-1">
        <div class="col-sm-2"></div>
        <div class="col-sm-10">
          <button type="button" class="btn btn-default btn-default-borderless emp-role" (click)="managementRoleClicked()"
            [disabled]="readonlyFields.managementRole">
            <span class="fa"
              [ngClass]="{'fa-square-o ':!selected.managementRole, 'fa-check-square-o ':selected.managementRole }"></span>
            {{'system_user_sr_management_role_lbl'|translate}}
          </button>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer mb-2">
      <button type="button" class="widgetButtoSmall" (click)="cancel()">{{'system_user_cancel_btn'|translate}}</button>
      <button type="button" (click)="ok()"
      [ngClass]="!roleSelected || !employeeSelected ? 'widgetButtoSmall grayButton ms-1' : 'widgetButtoSmall ms-1'"
      [disabled]="!roleSelected || !employeeSelected">{{'system_user_save_btn'|translate}}</button>
  </div>
</div>
