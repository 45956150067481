import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pawel'
})
export class PawelPipe implements PipeTransform {

  transform(value:any[],factor:number): any[] {
    const iterations = Math.floor(value.length/3)+1;
    return Array(iterations).fill(null).map((_,index)=> index+1);
  }

}
