import { Pipe, PipeTransform } from '@angular/core';
import { orderBy } from 'lodash';

@Pipe({
  name: 'orderByActionLog'
})
export class OrderByActionLogPipe implements PipeTransform {

  transform(data: any[], key: string): any[] {
    if(key === 'createDate')
    {
      return orderBy(data, 'createDate', 'asc');
    }
    if (key === 'creatorFullName')
    {
      return orderBy(data, ['creatorFullName','createDate'], ['asc','asc']);
    }
    if(key === '-creatorFullName'){
      return orderBy(data, ['creatorFullName','createDate'], ['desc','asc']);
    }
    return orderBy(data, 'createDate', 'desc');
  }

}
