import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ToasterserviceService } from 'src/app/services/toasterservice.service';
import { ProcessStepService } from '../process-step.service';

@Component({
  selector: 'app-process-step-item',
  templateUrl: './process-step-item.component.html',
  styleUrls: ['./process-step-item.component.css']
})
export class ProcessStepItemComponent implements OnInit {

  @Input() collection: any = [];
  @Input() canManage: any;
  @Input() disableActions: any;
  @Input() level:any;
  @Input() levelValidationMessage:any;

  //@Output() refreshNodes : EventEmitter<any> = new EventEmitter<any>();

  lookup : any = [];
  itemLevel;

  constructor(private toaster: ToasterserviceService,
    private adminProcessStepsService: ProcessStepService) { }

  ngOnInit(): void {
    this.collection.forEach(obj => {
      this.lookup.push({
        name: obj.name,
        isActive: obj.isActive,
        id: obj.id
      })
    });

    this.itemLevel = parseInt(this.level, 10) + 1;
  }

  // refreshItem(event: any){
  //   this.refreshNodes.emit(event);
  // }

}
