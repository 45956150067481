import { Injectable } from '@angular/core';
import { SessionStorageServiceService } from 'src/app/services/session-storage-service.service';

@Injectable({
  providedIn: 'root'
})
export class AdminSystemusersAuthserviceService {
  userInfo: any = {};
  userGeid: string = '';
  manageScope: any = {};

  constructor(private sessionStorageService: SessionStorageServiceService) {
    this.userInfo = this.sessionStorageService.getData('userInfo');
    this.refreshUserInfoData(this.userInfo);
  }

  refreshUserInfoData(data) {
    this.userInfo = data;
    this.userGeid = data.geid;

    this.manageScope = {
      globalProcessOwner: data.isGlobalCcrpProcOwner,
      supportDeskMember: data.isGlobalCcrpProcOwner || data.isSupportDeskMember,
      resourceEditor: data.isGlobalCcrpProcOwner || data.isSupportDeskMember || data.isResourceEditor,
      managementRole: data.isGlobalCcrpProcOwner

    };
  }

  canAddSuRole(suScope:any) {

    if (this.manageScope.globalProcessOwner) { //Global CCRP Proc Owner can manage all roles
        return true;
    } else if (this.manageScope.supportDeskMember && (!suScope.isSupportDeskMember || !suScope.isResourceEditor)) { //SupportDesk member can manage support desk && resource editor roles
        return true;
    }
    else if (this.manageScope.resourceEditor &&  !suScope.isResourceEditor) { //Resource editor can manage resource editor roles
        return true;
    }
    return false;
}

refreshUserInfo = () => this.refreshUserInfoData;

currentUser = () => this.userGeid;


}
