import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

export interface IActionTypesService {
  getActionTypes(actionCreatedDate);
}

@Injectable({
  providedIn: 'root'
})
export class ActionTypesService implements IActionTypesService {
  static serviceId = "actionTypesService";

  constructor(private http: HttpClient) { }

  getActionTypes(actionCreatedDate: any) {
    let params = new HttpParams()
        .set('actionItemCreatedDate', actionCreatedDate)

    return this.http.get<any>(`${environment.azure.baseUrl}/api/v1/actionplan/board/actiontypes`, {params})
  }

  // getActionTypes(){
  //   return this.http.get<any>(`${environment.API_URL}/actionTypes`);
  // }
}
