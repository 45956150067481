import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { ExceptionsService } from 'src/app/services/exceptions/exceptions.service';

@Component({
  selector: 'app-complaint-has-been-deleted-exception',
  templateUrl: './complaint-has-been-deleted-exception.component.html',
  styleUrls: ['./complaint-has-been-deleted-exception.component.css']
})
export class ComplaintHasBeenDeletedExceptionComponent implements OnInit {

  complaintNo : any;
  exceptionObject: any;

  constructor(private breadcrumbService: BreadcrumbService,
    private exceptionService: ExceptionsService,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.breadcrumbService.menuItems = [
      { label: 'NEW ISSUE'},
    ];

    this.complaintNo = this.route.snapshot.paramMap.get('complaintNumber')

    this.exceptionService.complaintHasBeenDeleted(this.complaintNo).subscribe((response)=>{
      this.exceptionObject = response;
    })

    this.spinner.hide();
  }

}
