import { Component, OnInit } from '@angular/core';
import { FailuremodeService } from '../failuremode.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import * as _ from 'lodash';
import { AdminFailuremodeComponent } from '../admin-failuremode/admin-failuremode.component';

@Component({
  selector: 'app-failuremode-editmodal',
  templateUrl: './failuremode-editmodal.component.html',
  styleUrls: ['./failuremode-editmodal.component.css']
})
export class FailuremodeEditmodalComponent implements OnInit {

  item:any;
  text:any;
  isSaving:boolean = false;
  validationItem:any = {
      hasErrors: false,
      isActiveItem: true
  };

  failureModeEditModal !: FormGroup;
  pgCode:any;

  constructor(public config: DynamicDialogConfig,
    private formBuilder: FormBuilder,
    private ref: DynamicDialogRef,
    private failureModeService:FailuremodeService,
    private test: AdminFailuremodeComponent) { }

  ngOnInit(): void {
    this.pgCode = this.test.selected.pg; //need to check
    this.item = this.config.data.pgCategoryItem;
    this.text = this.config.data.pgCategoryItem.name

    this.failureModeEditModal = this.formBuilder.group({
      itemName : new FormControl('', [Validators.required, Validators.maxLength(60)]),
      isActiveCheck : new FormControl()
    })

    if(this.config.data.pgCategoryItem.id !== undefined){
      this.failureModeEditModal.patchValue({
        itemName : this.config.data.pgCategoryItem.name,
        isActiveCheck : this.config.data.pgCategoryItem.isActive
      })
    }

    this.failureModeEditModal.get('itemName').valueChanges.subscribe(val =>{
      this.validationItem.isActiveItem = true;
      let foundItem : any = [];
      foundItem = _.filter(this.item.duplicateScope, (sc)=> {
        return sc.name.toUpperCase() === this.item.name.toUpperCase() && sc.id !== this.item.id;
      });

      if(foundItem.length){
        if(foundItem.length === 1){
          let process = foundItem[0];
          if(!process.isActive){
            this.validationItem.isActiveItem = false;
          }
        }
        this.validationItem.hasErrors = true;
      }
      else{
        this.validationItem.hasErrors = false;
      }
    })

  }

  cancel(){
    this.ref.close({result : 'none'});
  }

  reloadDuplicateScopeAfterException(item) {
   this.failureModeService.getChildren(this.pgCode, item.parentId).subscribe(
    (data)=>{
      this.item.duplicateScope = data;
    },
    (err)=>{

    }
   )
  }

  save() {

    // if (this.isSaving) return;

    // this.isSaving = true;
    // if (this.item.id !== undefined) {
    //   this.item.isActive = this.failureModeEditModal.get('isActiveCheck').value;
    //   this.item.name = this.failureModeEditModal.get('itemName').value;
    //     this.failureModeService.save(this.item, this.pgCode)
    //         .subscribe((data:any) => {
    //             if (!data.isError) {
    //                 this.isSaving = false;
    //                 this.ref.close({
    //                     result: 'ok',
    //                     model: this.item,
    //                     results: data.queryResults
    //                 });
    //             } else {
    //                 this.isSaving = false;
    //                 this.ref.close({
    //                     msg: data.message
    //                 });
    //             }
    //         }, (error) => {
    //             this.isSaving = false;
    //             this.reloadDuplicateScopeAfterException(this.item);
    //         });
    // } else {
    //   this.item.name = this.failureModeEditModal.get('itemName').value;
    //     this.failureModeService.addItem(this.item, this.pgCode)
    //         .subscribe((data:any)=> {
    //             if (!data.isError) {
    //                 this.isSaving = false;
    //                 this.ref.close({
    //                     result: 'ok',
    //                     model: data.queryResults
    //                 });
    //             } else {
    //                 this.isSaving = false;
    //                 this.ref.close({
    //                     msg: data.message
    //                 });
    //             }
    //         }, function(error) {
    //             this.isSaving = false;
    //             this.reloadDuplicateScopeAfterException(this.item);
    //         });
    // }
};


}
