import { Component, OnInit } from '@angular/core';
import { ProcessStepService } from '../process-step.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';

@Component({
  selector: 'app-admin-process-steps',
  templateUrl: './admin-process-steps.component.html',
  styleUrls: ['./admin-process-steps.component.css']
})
export class AdminProcessStepsComponent implements OnInit {

  isSearching = false;
  allowManage = true;
  items : any = [];
  hasRootChild;

  cachedProcessSteps = {
    items : [] 
  }

  constructor(private adminProcessStepsService: ProcessStepService,
    private spinnerService: NgxSpinnerService,
    private breadcrumbService: BreadcrumbService) { }

  ngOnInit(): void {
    this.breadcrumbService.menuItems = [
      { label: 'ADMIN PANEL', routerLink: 'admin'},
      { label: 'MANAGE PROCESS STEPS'}
    ];

    this.refreshResults();
  }

  refreshResults(){
    this.spinnerService.show();
    this.isSearching = true;

    this.cachedProcessSteps.items = this.items;

    this.adminProcessStepsService.loadProcessStepsTree().subscribe((data)=>{
      this.items = data;
      this.hasRootChild = data[0].isExpanded;
      this.cachedProcessSteps.items = data;

      this.isSearching = false;
      this.spinnerService.hide();
    },
    ()=>{
      this.isSearching = false;
      this.spinnerService.hide();
    })
  }

  search(){
    this.isSearching = true;
    this.spinnerService.show();
    this.refreshResults();
  }

  refreshNodes(item){
    if (undefined !== item) {
      this.setNodesState(item);
    }

    this.hasRootChild = this.items[0].isExpanded || false;
  }

  blockProcessStepsUI(){
    this.isSearching = true;
    this.spinnerService.show();
  }

  unblockProcessStepsUI(){
    this.isSearching = false;
    this.spinnerService.hide();
  }

  setNodesState(nodeItem){
    if(null != nodeItem.nodesToUpdate){
      for(let i=0; i<nodeItem.nodesToUpdate.length; i++){
        let node : any = this.findIdInTree(this.items[0], nodeItem.nodesToUpdate[i]);
        if (undefined !== node && node !== null) {
          node.isActive = nodeItem.state;
        }
      }
    }
  }

  findIdInTree(element, matchingId){
    if (element.id == matchingId) {
      return element;
    } else if(element.children != null){
      let result = null;
        for (let i = 0; result == null && i < element.children.length; i++) {
          result = this.findIdInTree(element.children[i], matchingId);
      }
      return result;
    }
    return null;
  }
}
