<div class="reopen-issue-section">
    <div class="container reopen-issue-pop">
        <form [formGroup]="reopenIssueForm">
            <p class="mb-0" *ngIf="flagForRejectComplaint"><label for="w3review" class="forReopen">Please specify reject reason<span class="asteriskRequired"></span>:</label></p>
            <p class="mb-0" *ngIf="!flagForRejectComplaint"><label for="w3review" class="forReopen">{{ "ComplaintForm_ReopenIssueReasonHeader" | translate }}<span class="asteriskRequired"></span>:</label></p>
            <textarea formControlName="reopenIssueText" pInputTextarea [autoResize]="true"
                class="form-control textAreaAlignReopenIssue textarea-height mb-2">
            </textarea>
            <div class="modal-footer mt-2">
                <button class="col-md-1 widgetButtonMedium" type="button" (click)="onClose() ">{{ "ButtonCancel" | translate }}</button>
                <button class="col-md-1 ms-1 me-0 widgetButtonMedium"
                [ngClass]="reopenIssueForm.valid ? 'widgetButtonMedium' : 'widgetButtonMedium grayButton'"
                type="button" [disabled]="!reopenIssueForm.valid" (click)="confirm() ">{{ "ButtonReopenIssueComplaint" | translate }}</button>
            </div>
        </form>
    </div>
</div>
