<div class="mainDiv">
  <div class="content" *ngIf="classData=='High Impact Class A'">
      <span innerHTML="{{'ComplaintForm_ConfirmationSubmitComplaintMessage_ClassA_Capture' | translate}}"></span>
      <span innerHTML="{{'ComplaintForm_ConfirmationSubmitComplaintMessage_ClassA' | translate}}"></span>
      <span *ngIf="confidentialFlag" innerHTML="{{'ComplaintForm_ConfirmationSubmitConfidentialComplaintMessage' | translate}}"></span>
   </div>

  <div class="content" *ngIf="classData=='High Impact Class B'">
    <span innerHTML="{{'ComplaintForm_ConfirmationSubmitComplaintMessage_ClassB_Capture' | translate}}"></span>
    <span innerHTML="{{'ComplaintForm_ConfirmationSubmitComplaintMessage_ClassB' | translate}}"></span>
    <span *ngIf="confidentialFlag" innerHTML="{{'ComplaintForm_ConfirmationSubmitConfidentialComplaintMessage' | translate}}"></span>
  </div>

  <div class="content" *ngIf="classData=='Class C' || classData == undefined ">
    <span innerHTML="{{'ComplaintForm_ConfirmationSubmitComplaintMessage' | translate}}"></span>
    <span *ngIf="confidentialFlag" innerHTML="{{'ComplaintForm_ConfirmationSubmitConfidentialComplaintMessage' | translate}}"></span>
  </div>

  <div class="content" *ngIf="classData=='High Impact Class I'">
    <span innerHTML="{{'ComplaintForm_ConfirmationSubmitComplaintMessage_ClassI_Capture' | translate}}"></span>
    <span innerHTML="{{'ComplaintForm_ConfirmationSubmitComplaintMessage_ClassI' | translate}}"></span>
    <span *ngIf="confidentialFlag" innerHTML="{{'ComplaintForm_ConfirmationSubmitConfidentialComplaintMessage' | translate}}"></span>
  </div>

 <div class="modal-footer my-1">
   <button class="widgetButtoSmall" (click)="confirm()">{{ "HighImpact_Btn_Confirm" | translate }}</button>
      <button class="widgetButtoSmall ms-1" (click)="onClose()">{{ "ButtonCancel" | translate }}</button>
  </div>
</div>
