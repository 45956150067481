import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {

  transform(data: any[], key: string, value: boolean): any[] {
    if(!data || data.length === 0) {
      return [];
    }

    return data.filter(item => item[key] === value)
  }


}
