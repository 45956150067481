<div class="container">
  <div class="row mx-2 mt-3">
    <div class="col-md-3 col-lg-2  col-sm-6">
      <a class="text-capitalize exportcls" (click)="exportToPDF()"><span
          class="glyphicon glyphicon-save export-btn"></span><b>&nbsp;Export to PDF</b></a>
    </div>
    <div class="col-md-3 col-lg-2 col-sm-6">
      <a class="text-capitalize exportcls" (click)="exportToWord()"><span
          class="glyphicon glyphicon-save export-btn"></span><b>&nbsp;Export to Word</b></a>
    </div>
    <div class="col"></div>
  </div>
  <div class="row mx-2 mt-3" id="SummaryReport">
    <div class="row mt-1">
      <span class="abb-logo" style="float: right !important;"></span>
    </div>
    <div class="row mt-1">
      <h4 class="text-center">
        <b>CCRP Summary Report</b>
      </h4>
    </div>
    <div class="row mt-1 ms-1">
      <table class="table table-bordered border-secondary table-responsive">
        <tr style="background-color:#dddddd;">
          <th colspan="2">Basic Information</th>
        </tr>
        <tr>
          <td class="headertd">CCRP No.</td>
          <td class="complaintdata">{{complaintData?.issueNumber}}</td>
        </tr>
        <tr>
          <td class="headertd">Issue type:</td>
          <td class="complaintdata">{{complaintData?.isCustomerExternal ? 'External' : 'Internal' }}</td>
        </tr>
        <tr>
          <td class="headertd">Affected Customer:</td>
          <td class="complaintdata">{{complaintData?.isCustomerExternal ? complaintData?.customer :
            complaintData?.referredCustomer}}</td>
        </tr>
        <tr>
          <td class="headertd">CCRP Creation Date (UTC):</td>
          <td class="complaintdata">{{complaintData?.creationDate | date:"yyyy-MM-dd hh:mm:ss a":"+0000"}}</td>
        </tr>
        <tr>
          <td class="headertd">CCRP Report Date:</td>
          <td class="complaintdata">{{complaintData?.reportDate | customdate}}</td>
        </tr>
        <tr>
          <td class="headertd">Reported by:</td>
          <td class="complaintdata">{{complaintData?.issueOwner}}</td>
        </tr>
      </table>

      <table class="table table-bordered border-secondary">
        <tr style="background-color:#dddddd;">
          <th colspan="2">Resolution Team</th>
        </tr>
        <tr>
          <td class="headertd">Issue Owner:</td>
          <td class="complaintdata">{{complaintData?.issueOwner}}</td>
        </tr>
        <tr>
          <td class="headertd">Resolution Owner:</td>
          <td class="complaintdata">{{complaintData?.resolutionOwner}}</td>
        </tr>
        <tr>
          <td class="headertd">Resolution Team:</td>
          <td class="complaintdata">{{complaintData?.resolutionOwnerTeam}}</td>
        </tr>
        <tr>
          <td class="headertd">Responsible organization:</td>
          <td class="complaintdata">{{complaintData?.responsibleOrganization}}</td>
        </tr>
      </table>

      <table class="table table-bordered border-secondary">
        <tr style="background-color:#dddddd;">
          <th colspan="2">Detailed Information</th>
        </tr>
        <tr>
          <td class="headertd">Product Name:</td>
          <td class="complaintdata">{{complaintData?.productName}}</td>
        </tr>
        <tr>
          <td class="headertd">Manufacturing date:</td>
          <td class="complaintdata">{{complaintData?.manufacturingDate | customdate}}</td>
        </tr>
        <tr>
          <td class="headertd">Government:</td>
          <td class="complaintdata">{{complaintData?.government}}</td>
        </tr>
      </table>


      <table class="table table-bordered border-secondary">
        <tr style="background-color:#dddddd;">
          <th colspan="2">Original Rating</th>
        </tr>
        <tr>
          <td class="headertd">Mass Media:</td>
          <td class="complaintdata">{{complaintData?.originalRatingMassMedia}}</td>
        </tr>
        <tr>
          <td class="headertd">OHS Risk:</td>
          <td class="complaintdata">{{complaintData?.originalRatingOhsRisk}}</td>
        </tr>
        <tr>
          <td class="headertd">Compliance:</td>
          <td class="complaintdata">{{complaintData?.originalRatingCompliance}}</td>
        </tr>
      </table>


      <table class="table table-bordered border-secondary">
        <tr style="background-color:#dddddd;">
          <th colspan="2">Current Rating</th>
        </tr>
        <tr>
          <td class="headertd">Mass Media:</td>
          <td class="complaintdata">{{complaintData?.currentRatingMassMedia}}</td>
        </tr>
        <tr>
          <td class="headertd">OHS Risk:</td>
          <td class="complaintdata">{{complaintData?.currentRatingOhsRisk}}</td>
        </tr>
        <tr>
          <td class="headertd">Compliance:</td>
          <td class="complaintdata">{{complaintData?.currentRatingCompliance}}</td>
        </tr>
      </table>

      <div style="page-break-before: always;"></div>

      <table class="table table-bordered border-secondary mt-4">
        <tr style="background-color:#dddddd;">
          <th colspan="2">Issue Summary</th>
        </tr>
        <tr>
          <td class="headertd">Subject:</td>
          <td class="complaintdata">{{complaintData?.subject}}</td>
        </tr>
        <tr>
          <td colspan="2"><b>Issue Description:</b> <br> <span
              class="complaintdata whiteCls">{{complaintData?.description}} </span>
          </td>
        </tr>
        <tr>
          <td colspan="2"><b>What is the problem?</b> <br> <span class="complaintdata whiteCls">
              {{complaintData?.whatIssueDescription}}
            </span></td>
        </tr>
        <tr *ngIf="complaintCreatedDate<ncrLiveDate">
          <td colspan="2"><b>Why is this a problem?</b> <br> <span
              class="complaintdata whiteCls">{{complaintData?.whyIssueDescription}}
            </span></td>
        </tr>
        <tr *ngIf="complaintCreatedDate<ncrLiveDate">
          <td colspan="2"><b>How do we know we have a problem?</b> <br> <span
              class="complaintdata whiteCls">{{complaintData?.howIssueDescription}} </span></td>
        </tr>
        <tr>
          <td colspan="2"><b>Where does/did the problem happen?</b> <br> <span class="complaintdata whiteCls">
              {{complaintData?.whereIssueDescription}} </span></td>
        </tr>
        <tr>
          <td colspan="2"><b>When does/did the problem happen?</b> <br> <span
              class="complaintdata whiteCls">{{complaintData?.whenIssueDescription | customdate}} </span></td>
        </tr>
        <tr>
          <td colspan="2"><b class="headertd">What is the impact on the customer and what are the customer's
              expectations with respect to
              the
              resolution of this case?</b><br> <span
              class="complaintdata whiteCls">{{complaintData?.whatIsCustomerExpectationDescription}} </span></td>
        </tr>

      </table>

      <table class="table table-bordered border-secondary">
        <tr style="background-color:#dddddd;">
          <th colspan="2">Issue Resolution</th>
        </tr>
        <tr *ngIf="complaintCreatedDate<ncrLiveDate">
          <td colspan="2"><b>What containment actions were taken?</b> <br><span
              class="complaintdata">{{complaintData?.shortTermActions}}</span></td>
        </tr>
        <tr>
          <td colspan="2"><b>What was the root cause of this issue?</b> <br><span
              class="complaintdata">{{complaintData?.whatWasTheRootCause}}</span></td>
        </tr>
        <tr>
          <td colspan="2"><b>Details of Correction provided?</b> <br><span
              class="complaintdata">{{complaintData?.howDidWeResolveTheIssue}}</span></td>
        </tr>
        <tr>
          <td colspan="2"><b>RCA Category</b> <br><span class="complaintdata">{{complaintData?.pgRcaCategory}}</span>
          </td>
        </tr>
      </table>

      <table class="table table-bordered border-secondary">
        <tr style="background-color:#dddddd;">
          <th colspan="2">Customer Comments</th>
        </tr>
        <tr>
          <td colspan="2" class="complaintdata">{{complaintData?.customerComments}}</td>
        </tr>
      </table>

      <table class="table table-bordered border-secondary">
        <tr style="background-color:#dddddd;">
          <th colspan="2">Customer Feedback</th>
        </tr>
        <tr>
          <td colspan="2" class="complaintdata">{{complaintData?.feedbackResults}}</td>
        </tr>
      </table>

    </div>
    <footer class="row">
      <div class="col-2 ms-1">{{complaintData?.issueNumber}}</div>
      <div class="col-6">CCRP Summary Report</div>
      <div class="col text-end"><b>HITACHI ENERGY</b></div>
    </footer>
  </div>
</div>
