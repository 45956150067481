<div class="row subscriptionpage">
  <div class="row">
    <div class="col-2"></div>
    <div class="col-8">
        <p-card>
          <div class="row" *ngIf="message == 'AlreadyUnsubscribed'">
            <!-- for high impact confirmation if already responded -->
            <div class="row">
              <h2 class="text-left">
                <strong>{{'Survey_AlreadyUnsubscribed' | translate}}</strong>
              </h2>
              <p class="text-left">{{'Survey_AlreadyUnsubscribed_Resubscribe' | translate}}</p>
            </div>
            <div class="row ms-1">
              <button type="submit" (click)="changeToSubscribe()" class="widgetButtoSmall">{{'Survey_SubscribeButton' | translate}}</button>
            </div>
          </div>
          <div class="row" *ngIf="message == 'Subscribe'">
            <div class="row">
              <h2 class="text-left"><strong>{{'Survey_Subscribe_Success' | translate}}</strong></h2>
            </div>
          </div>
          <div class="row" *ngIf="message == 'Resubscribe'">
            <!-- for high impact confirmation if already responded -->
            <div class="row">
              <h2 class="text-left">
                <strong>{{'Survey_ResubscribeText' | translate}}</strong>
              </h2>
              <p class="text-left">{{'Survey_ResubscribeSubscribe' | translate}}</p>
            </div>
            <div class="row ms-1">
              <button type="submit" (click)="changeToSubscribe()" class="widgetButtoSmall">{{'Survey_SubscribeButton' | translate}}</button>
            </div>
          </div>
          <div class="row" *ngIf="message == 'Unsubscribe'">
            <div class="row">
              <h2 class="text-left">
                <strong>{{'Survey_Unsubscribe_Success' | translate}}</strong>
              </h2>
              <p class="text-left">{{'Survey_Unsubscribe_Resubscribe' | translate}}</p>
            </div>
            <div class="row ms-1">
              <button type="submit" (click)="changeToSubscribe()" class="widgetButtoSmall">{{'Survey_SubscribeButton' | translate}}</button>
            </div>
          </div>
        </p-card>
    </div>
    <div class="col-2"></div>
  </div>
</div>

