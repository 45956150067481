<div class="thumbnail action-plan-card cursorOnCard">
    <!-- <div style="background-color: #d9d9d9;">
        <h4 class="action-plan-card-header">
            <b appToolTip class="item-label" [tooltip]="item.name" placement="top">{{item.name | shortenComplaintSubject: 30}}</b>&nbsp;
            <i appToolTip *ngIf="item.isRejected == true" class="fa fa-warning" style="color: darkorange" tooltip="{{'cc_actionplan_reject_info_warn' | translate}}" placement="top"></i>
            <i appToolTip *ngIf="item.isReopened == true" class="fa fa-warning" style="color: darkorange" tooltip="{{'cc_actionplan_reopened_info_warn' | translate}}" placement="top"></i>
        </h4>
        <div class="action-plan-card-owner">{{item.ownerName}}</div>
     </div> -->

    <div class="caption">
       <h4 class="action-plan-card-header">
            <b  class="item-label" pTooltip="{{item.name}}" placement="top">{{item.name | shortenComplaintSubject: 30}}</b>&nbsp;
            <i  *ngIf="item.isRejected == true" class="fa fa-warning" style="color: darkorange" pTooltip="{{'cc_actionplan_reject_info_warn' | translate}}" placement="top"></i>
            <i  *ngIf="item.isReopened == true" class="fa fa-warning" style="color: darkorange" pTooltip="{{'cc_actionplan_reopened_info_warn' | translate}}" placement="top"></i>
        </h4>
        <div class="action-plan-card-owner">{{item.ownerName}}</div>
        <span class="action-plan-card-type">{{item.typeName}}</span>
        <hr style="margin-bottom: 10px" />
        <div style="display: block; min-height: 15px;">
            <span style="float: right; font-size: 18px;">
                 <a class="iconStyle" style="text-decoration: none;" routerLink="/issues/{{item.complaintNo}}/{{selectedRole}}/actionplan/card/{{item.id}}">
                    <i *ngIf="item.statusId != 3"
                       style="cursor: pointer"
                       class="fa fa-edit"></i>
                </a>
                <i *ngIf="item.statusId == 1 && item.permission.isCreator" style="cursor: pointer;" (click)="ondeleteClicked(item.id)" class="hitachiicon-bin"></i>
                <a style="text-decoration: none;" class="iconStyle">
                    <i *ngIf="item.statusId == 3" style="cursor: pointer" class="fa fa-external-link" routerLink="/issues/{{item.complaintNo}}/{{selectedRole}}/actionplan/card/{{item.id}}"></i>
                </a>

            </span>
            <span *ngIf="item.statusId != 3" style="float: left; color: #686868; font-size: 11px" class="{{item.status}}">{{item.targetDate | date: 'dd-MM-yyyy'}}</span>
            <span *ngIf="item.statusId == 3">{{'cc_close_info' | translate}} {{item.lastUpdateDate | date: 'dd-MM-yyyy'}}</span>
        </div>
    </div>
</div>
