import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LocalStorageServiceService } from './local-storage-service.service';
import { environment } from 'src/environments/environment';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class MdRegionService {
  cacheKey = 'mdregion_20200117';
  storage:boolean = false ;
  regCountData;

  constructor(private http: HttpClient,
    private localStorage: LocalStorageServiceService) {
      if(this.localStorage.getFromLocalStorage(this.cacheKey)){
        this.storage = true;
      }
  }

  loadMdData(){
    if (this.storage) {
       //this.regCountData = this.storage;
       this.regCountData = this.localStorage.getFromLocalStorage(this.cacheKey);

    }
    else {
      this.http.get<any>(`${environment.azure.baseUrl}api/v1/mdregion/all`).subscribe((data)=>{

        data.cities.forEach(city => {
          let c = _.find(data.countries, function (cc) { return city.cCode == cc.id; });
          if (c !== undefined) {
              city.cName = c.name;
            }
        });

        this.localStorage.addToLocalStorage(this.cacheKey, data, { days: 7 });
        this.regCountData = data;
        this.storage = true;
      })
    }

    return {
      regions: this.regCountData?.regions,
      // regionsByCode: function(codes) { return this.byCodes('regions', codes); },
      countries: this.regCountData?.countries,
      countriesByCode: function(codes) { return this.byCodes('countries', codes); },
        // cities: function (r, c, t) {
        //     return cityCacheSeq.get(('s' + r + c) + t, t, function (term) { return loadCityData(r, c, term); });
        // },
      // citiesByCode: function(codes) { return this.byCodes('cities', codes); },
      activities: this.regCountData?.activities,
      // activitiesByCode: function (codes) { return this.byCodes('activities', codes); },
      descriptiveNames: this.regCountData?.descriptiveNames,
      // descriptiveNamesByCode: function (codes) { return this.byCodes('descriptiveNames', codes); }
    }
  }

  getCountryOnly() {
    return this.http.get<any>(`${environment.azure.baseUrl}api/v1/mdregion/all`)
  }

  byCodes = function(part, codes) {
    return this.regCountData.then(function (r) { return _.filter(r[part], function (cd) { return codes.indexOf(cd.id) > -1; }); });
  };

  filterDataByCodes(part,codes){
    const propertyData = this.regCountData[part];
    const filteredData = propertyData.filter(item => codes.includes(item.id));
    return filteredData;
  }

  filterCityDataByCodes(part,codes){
    const propertyData = this.regCountData[part];
    const filteredData = propertyData.filter(item => codes.includes(item.cCode));
    return filteredData;
  }


  regions(): Promise<any> {
    return new Promise((resolve, reject) => {
      resolve(this.loadMdData().regions)
    });
  }

  countries(): Promise<any> {
    return new Promise((resolve, reject) => {
      resolve(this.loadMdData().countries)
    });
  }

  countriesByCode(codes:any): Promise<any> {
    return new Promise((resolve, reject) => {
      resolve(this.filterDataByCodes('countries', codes))
    });
  }

  citiesByCode(codes:any): Promise<any> {
    return new Promise((resolve, reject) => {
      resolve(this.filterCityDataByCodes('cities', codes))
    });
  }

}
