import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
//import { AppInsights } from 'applicationinsights-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { AngularPlugin } from '@microsoft/applicationinsights-angularplugin-js';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
@Injectable()
export class AppInsightsService {
  // private config: Microsoft.ApplicationInsights.IConfig = {
  //   instrumentationKey: environment.appInsights.instrumentationKey
  // }
  // appInsights: ApplicationInsights;
  private angularPlugin = new AngularPlugin();
  private appInsights = new ApplicationInsights({
    config: {
      instrumentationKey: environment.appInsights.instrumentationKey,
      extensions: [this.angularPlugin],
      extensionConfig: {
        [this.angularPlugin.identifier]: {
          router: this.router,
        },
      },
    },
  });
  constructor(private router: Router, private http: HttpClient) {
    // if(!AppInsights.config) {
    //   AppInsights.downloadAndSetup(this.config);
    // }
    this.appInsights.loadAppInsights();
  }
  // logPageView(name: string, url?: string, properties?: any, measurements?: any, duration?: number) {
  //   AppInsights.trackPageView(name, url, properties, measurements, duration);
  // }
  // logEvent(name: string, properties?: any, measurements?: any) {
  //   AppInsights.trackEvent(name, properties, measurements);
  // }
  trackTrace(message: string): void {
    this.appInsights.trackTrace({ message });
  }

  setUserId(userId: string) {
    this.appInsights.setAuthenticatedUserContext(userId ? userId : 'External page user');
  }

}
